import React, { Component } from 'react';
import { Dialog, DialogActions, DialogContent, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import AppBarModal from '../../general/AppBarModal';
import BuscarSelect from '../../general/BuscarSelect';
import TareasTerminadas from '../../general/TareasTerminadas';
import emma_w from '../../../global/imagenes/emma_w.png';
import { consulta, obtenerValoresFiltros, transformar } from '../../../global/js/funciones';
import { ESTADOS, PROGRAMAS, TIPOS_SOLICITUDES } from '../Helpers';
import { BtnForm } from '../../general/BotonesAccion';
import InputBuscar from "../../general/InputBuscar";
import BuscarPersona from "../../general/BuscarPersona";
const API_URI = `api/v1.0/invitadosVirtual/`;

class GestFiltros extends Component {
  constructor(props) {
    super(props);
    this.state = {
      estados: [],
      periodos: [],
      programas: [],
      tipos: [],
      estado_actual: "",
      tipo: "",
      cargando: true,
      periodo: null,
      programa: null,
      buscarEstudiante: false,
      estudiante: {
        id: 0,
        nombre: "",
      },
    };
  }

  async componentDidUpdate({ modalFiltro }) {
    let { modalFiltro: modalFiltroNew } = this.props;
    if (modalFiltro !== modalFiltroNew) {
      this.setState({ cargando: true });
      let periodos = await this.obtenerPeriodos();
      let tipos = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_SOLICITUDES }, { 'llave': 'estado', 'valor': 1 }]);
      let programas = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': PROGRAMAS }, { 'llave': 'estado', 'valor': 1 }]);
      let estados = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': ESTADOS }, { 'llave': 'estado', 'valor': 1 }]);
      this.setState({
        'periodos': transformar(periodos, 'periodo', 'periodo'),
        'programas': transformar(programas),
        tipos,
        estados,
        cargando: false,
      })
    }
  }

    obtenerPeriodos = () => {
        return new Promise((resolve) => {
          consulta(`${API_URI}periodos`, null, null, (error, estado, resp) => {
            resolve(resp);
          });
        });
    }

    onChange = ({ target }) => {
        let { value, name } = target;
        this.setState({ [name]: value });
    }

    onSubmit = e => {
        let { filtrar, actualizarMensaje, mostrarModalFiltros, tipoSolicitud, mostrar_data_filtrada } = this.props;
        let { tipo, programa, periodo, estado_actual, estudiante } = this.state;
        tipoSolicitud = (tipoSolicitud === "Tod_Inv_Vir") ? "" : tipoSolicitud;
        let filtros = [];
        if (tipo !== '') filtros.push({ llave: "tipo_solicitud__id", valor: tipo });
        if (programa !== null) filtros.push({ 'llave': 'programa', 'valor': programa.value });
        if (periodo !== null) filtros.push({ 'llave': 'periodo', 'valor': periodo.value });
        if (estado_actual !== '') filtros.push({ llave: "estado_solicitud__id", valor: estado_actual });
        else filtros.push({ llave: "estado_solicitud__codigo", valor: tipoSolicitud });
        if (filtros.length > 0){
          if (estudiante.id) filtros.push({ 'llave': 'persona', 'valor': estudiante.id });
          mostrarModalFiltros(false)
          filtrar(filtros);
          mostrar_data_filtrada(true);
        } else actualizarMensaje({ titulo: 'Seleccione Algún filtro', tipo: 'info', mostrar: true, tiempo: 6000 });
        e.preventDefault();
    }

    resetState = () => {
      let { limpiar, mostrarModalFiltros, mostrar_data_filtrada } = this.props;
      this.setState({
        periodo: null,
        programa: null,
        tipo: '',
        estado_actual: '',
        estudiante: {
          id: 0,
          nombre: ''
        }
      })
      mostrar_data_filtrada(false)
      limpiar();
      mostrarModalFiltros(false);
    }

  render () {
    let { modalFiltro, mostrarModalFiltros, mostrar_data_filtrada } = this.props;
    let { cargando, periodos, periodo, programas, programa, tipos, tipo, estados, estado_actual, estudiante, buscarEstudiante } = this.state;
    return (
      <Dialog fullWidth = { true } maxWidth = "sm" open = { modalFiltro } onClose = { () => mostrarModalFiltros(false) && mostrar_data_filtrada(false) } aria-labelledby = "alert-dialog-title" aria-describedby = "alert-dialog-description">
        <AppBarModal titulo = '¡ Aplicar Filtros !' mostrarModal = { () => mostrarModalFiltros(false) } titulo_accion = "Limpiar" accion = { this.resetState } />
        <DialogContent className = 'scroll'>
          {
            !cargando ?
              <Grid container spacing = { 3 } className = 'margin_cont'>
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                  <InputBuscar
                    id='estudiante'
                    label='Estudiante'
                    placeholder='Clic aquí para buscar'
                    value={estudiante.nombre}
                    callback={() => this.setState({ buscarEstudiante: true })} />
                </Grid>
                <Grid item xs = { 12 } xl = { 12 } sm = { 12 } md = { 12 } lg = { 12 }>
                  <BuscarSelect
                    datos = { programas }
                    cambiarEstado = { (value) => this.setState({ programa: value }) }
                    valor = { programa }
                    id = 'select-programa'
                    nombre = 'Programa'
                    placeholder = 'Seleccione Programa'
                  />
                </Grid>
                <Grid item xs = { 12 } xl = { 12 } sm = { 12 } md = { 12 } lg = { 12 }>
                  <FormControl className = 'form-control'>
                    <InputLabel htmlFor = "tipo">Seleccione Tipo de Estudiante</InputLabel>
                    <Select
                      inputProps = { { name: 'tipo', id: 'tipo', } }
                      value = { tipo }
                      onChange = { this.onChange }>
                      { tipos.map(({ id, nombre }) => <MenuItem key = { id } value = { id }>{ nombre }</MenuItem>) }
                    </Select>
                  </FormControl>
                </Grid>
                {/* <Grid item={3} xs = { 12 } xl = { 12 } sm = { 12 } md = { 12 } lg = { 12 }>
                  <FormControl className = 'form-control'>
                    <InputLabel htmlFor = "estado_actual">Seleccione Estado</InputLabel>
                    <Select
                      inputProps = { {
                        name: 'estado_actual',
                        id: 'estado_actual',
                      } }
                      value = { estado_actual }
                      onChange = { this.onChange }>
                      {
                        estados.map(({ id, nombre }) => <MenuItem key = { id } value = { id }>{ nombre }</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                </Grid> */}
              </Grid>
              : <TareasTerminadas mensaje = 'Cargando..' marginTop = '7%' imagen = { emma_w } widthImg = "7%" />
          }
        </DialogContent>
        <DialogActions>
          <BtnForm texto = "CANCELAR" callback = { () => mostrarModalFiltros(false) && mostrar_data_filtrada(false) } />
          { !cargando && <BtnForm texto = "FILTRAR" callback = { this.onSubmit } /> }
        </DialogActions>

        <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={buscarEstudiante} onClose={() => this.setState({ buscarEstudiante: false })}>
          <AppBarModal titulo={'¡ Buscar Estudiante !'} mostrarModal={() => this.setState({ buscarEstudiante: false })} titulo_accion="CERRAR" accion={() => this.setState({ buscarEstudiante: false })} />
          <DialogContent style={{ padding: '0' }} className='scroll'>
            <BuscarPersona callback={({ id, primer_nombre, primer_apellido, segundo_apellido }) => this.setState({
              estudiante: {
                id,
                nombre: `${primer_nombre} ${primer_apellido} ${segundo_apellido}`,
              }, buscarEstudiante: false
            })} />
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => this.setState({ buscarEstudiante: false })} />
          </DialogActions>
        </Dialog>

      </Dialog>
      
    )
  }
}

export default GestFiltros;
