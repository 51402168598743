import React from 'react';
import { BottomNavigation, Hidden, BottomNavigationAction, Tabs, Tab, Badge } from '@material-ui/core';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import LoopIcon from '@material-ui/icons/Loop';
import FormatListNumberedRtlIcon from '@material-ui/icons/FormatListNumberedRtl';
import Procesos from '@material-ui/icons/ImportContacts';
import Hv from '@material-ui/icons/SpeakerNotes';
import Matriculados from '@material-ui/icons/VerifiedUser';
import PracticasNotificaciones from '../PracticasNotificaciones';
import Notificaciones from '@material-ui/icons/NotificationImportant';
import SettingsIcon from '@material-ui/icons/Settings';
import HistoryIcon from '@material-ui/icons/History';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import EventNoteIcon from '@material-ui/icons/EventNote';

const clase = (item, selec, tipo = '') => item === selec ? `item_selec${tipo}` : `item_modulo`;

export default function MenuPracticas({ administrador, notificaciones = [], sele = 0, seleccionar = () => { }, cargandoNotif }) {
  const pintar = (app, claseN = '') => {
    if (!app) {
      return (
        <div className={claseN}>

          <BottomNavigation showLabels className={clase('procesos', sele)} onClick={() => seleccionar('procesos')}>
            <BottomNavigationAction label={'Procesos'} icon={<Procesos className='menu_icono' />} />
          </BottomNavigation>

          <PracticasNotificaciones cargando={cargandoNotif} notificaciones={notificaciones} render={(click) => (<BottomNavigation showLabels className={clase('notificacion', sele)} onClick={(e) => { click(e); seleccionar('notificacion'); }}> <BottomNavigationAction label={'Notificación'} icon={<Badge badgeContent={notificaciones.length} color="secondary"><Notificaciones /></Badge>} /></BottomNavigation>)} />

          <BottomNavigation showLabels className={clase('hojas', sele)} onClick={() => seleccionar('hojas')}>
            <BottomNavigationAction label={'Hojas Vida'} icon={<Hv className='menu_icono' />} />
          </BottomNavigation>

          <BottomNavigation showLabels className={clase('estudiantes', sele)} onClick={() => seleccionar('estudiantes')}>
            <BottomNavigationAction label={'Matriculados'} icon={<Matriculados className='menu_icono' />} />
          </BottomNavigation>

          {
            administrador &&
            <BottomNavigation showLabels className={clase('administrar', sele)} onClick={() => seleccionar('administrar')}>
              <BottomNavigationAction label={'Administrar'} icon={<SettingsIcon className='menu_icono' />} />
            </BottomNavigation>

          }

        </div>
      )
    } else {
      return (
        <div className={claseN}>
          <Tabs value={sele} variant='scrollable' scrollButtons="off" >
            <Tab label='Procesos' icon={<Procesos className='menu_icono' />} className={clase('procesos', sele, '_app')} value='procesos' onClick={() => seleccionar('procesos')} />
            <PracticasNotificaciones notificaciones={notificaciones} horizontal='left' render={(click) => <Tab onClick={(e) => { click(e); seleccionar('notificacion'); }} label={'Notificación'} icon={<Badge badgeContent={notificaciones.length} color="secondary"><Notificaciones /></Badge>} className={clase('notificaciones', sele, '_app')} value='notificaciones' />} />
            <Tab label='Hojas Vida' icon={<Hv className='menu_icono' />} className={clase('hojas', sele, '_app')} value='hojas' onClick={() => seleccionar('hojas')} />
            <Tab label='Matriculados' icon={<Matriculados className='menu_icono' />} className={clase('estudiantes', sele, '_app')} value='estudiantes' onClick={() => seleccionar('estudiantes')} />
            {administrador && <Tab label='Administrar' icon={<SettingsIcon className='menu_icono' />} className={clase('administrar', sele, '_app')} value='administrar' onClick={() => seleccionar('administrar')} />}
          </Tabs>
        </div>
      )
    }
  }

  return (
    <div>
      <Hidden xsDown> {pintar(false, 'menu_modulo scroll')} </Hidden>
      <Hidden smUp> {pintar(true, 'menu_modulo_app')}</Hidden>
    </div>

  );
}


export function EmpresaDetalleMenu({ sele = 0, seleccionar = () => { }, hbEmpresa }) {
  const pintar = (claseN = '') => {
    return (
      <div className={claseN}>
        <BottomNavigation showLabels>
          <BottomNavigationAction label={"Empresa"} icon={<ImportContactsIcon className='menu_icono' />} className={clase('Empresa', sele)} value={'Empresa'} onClick={() => seleccionar('Empresa')} />
          <BottomNavigationAction label={"Sucursal"} icon={<FormatListNumberedRtlIcon className='menu_icono' />} className={clase('Sucursal', sele)} value={'Sucursal'} onClick={() => seleccionar('Sucursal')} />
        </BottomNavigation>
      </div>
    )
  }

  return pintar('menu_modal');
}



export function PracticaDetalleMenu({admin, sele = 0, seleccionar = () => { }, hbEmpresa }) {
  const pintar = (claseN = '') => {
    return (
      <div className={claseN}>
        <BottomNavigation showLabels>
          <BottomNavigationAction label={"Datos"} icon={<ImportContactsIcon className='menu_icono' />} className={clase('practica', sele)} value={'practica'} onClick={() => seleccionar('practica')} />
          <BottomNavigationAction label={"Requisitos"} icon={<FormatListNumberedRtlIcon className='menu_icono' />} className={clase('requisitos', sele)} value={'requisitos'} onClick={() => seleccionar('requisitos')} />
          {
            hbEmpresa && <BottomNavigationAction label={"Empresas"} icon={<Hv className='menu_icono' />} className={clase('hv', sele)} value={'hv'} onClick={() => seleccionar('hv')} />
          }
          {
          admin &&            
            <BottomNavigationAction label={"Notas"} icon={<EventNoteIcon className='menu_icono' />} className={clase('notas', sele)} value={'notas'} onClick={() => seleccionar('notas')} />
            }
          <BottomNavigationAction label={"Adjuntos"} icon={<MenuBookIcon className='menu_icono' />} className={clase('adjuntos', sele)} value={'adjuntos'} onClick={() => seleccionar('adjuntos')} />
          <BottomNavigationAction label={"Estados"} icon={<LoopIcon className='menu_icono' />} className={clase('estados', sele)} value={'estados'} onClick={() => seleccionar('estados')} />

        </BottomNavigation>
      </div>
    )
  }

  return pintar('menu_modal');
}




export function MenuGestionar({ sele = 0, seleccionar = () => { }, practica }) {
  const pintar = (claseN = '') => {
    return (
      <div className={claseN}>
        <BottomNavigation showLabels>
          <BottomNavigationAction
            label={'Gestionar'}
            icon={<LoopIcon />}
            className={clase('gestion', sele)}
            onClick={() => seleccionar('gestion')}
          />
          <BottomNavigationAction
            label={'Documentos'}
            icon={<MenuBookIcon />}
            className={clase('documentos', sele)}
            onClick={() => seleccionar('documentos')}
          />
          {(practica.estado_actual.valora == '1' || practica.estado_actual.codigo == 'Est_Pra_Ini') && (
            <BottomNavigationAction
              label={'Visitas'}
              icon={<HistoryIcon />}
              className={clase('visitas', sele)}
              onClick={() => seleccionar('visitas')}
            />
          )}
        </BottomNavigation>
      </div>
    );
  };
  return <div>{pintar('menu_modal')}</div>;
}
