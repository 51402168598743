const MODAL_SOL_TUTORIA = 'MODAL_SOL_TUTORIA' 
const MODAL_DET_TUTORIA = 'MODAL_DET_TUTORIA'
const MODAL_FILTRO_TUTORIA = 'MODAL_FILTRO_TUTORIA'
const MODAL_GES_TUTORIA = 'MODAL_GES_TUTORIA'
const MODAL_FIL_DES_TUTORIA = 'MODAL_FIL_DES_TUTORIA'
const MODAL_MOD_PERIODO_TUTORIA = 'MODAL_MOD_PERIODO_TUTORIA'


const mostrarModalSolicitarTutoria = show => {
    return {
      type: MODAL_SOL_TUTORIA,
      show,
    }
  }
  

  const mostrarModalDetalleTutoria = show => {
    return {
      type: MODAL_DET_TUTORIA,
      show,
    }
  }


  const mostrarModalFiltroTutoria = show => {
    return {
      type: MODAL_FILTRO_TUTORIA,
      show,
    }
  }

  const mostrarModalGestionTutoria = show => {
    return {
      type: MODAL_GES_TUTORIA,
      show,
    }
  }


  const mostrarModalFiltroTutoriaDescarga = show => {
    return {
      type: MODAL_FIL_DES_TUTORIA,
      show,
    }
  }

  const mostrarModalModPeriodoTutoria = show => {
    return {
      type: MODAL_MOD_PERIODO_TUTORIA,
      show,
    }
  }


  

  
module.exports = {
    MODAL_SOL_TUTORIA,
    MODAL_DET_TUTORIA,
    MODAL_FILTRO_TUTORIA,
    MODAL_GES_TUTORIA,
    MODAL_FIL_DES_TUTORIA,
    MODAL_MOD_PERIODO_TUTORIA,
    mostrarModalSolicitarTutoria,
    mostrarModalDetalleTutoria,
    mostrarModalFiltroTutoria,
    mostrarModalGestionTutoria,
    mostrarModalFiltroTutoriaDescarga,
    mostrarModalModPeriodoTutoria,
    
  }
  