import React, { Component } from 'react'

// FUNCIONES GLOBLAES - GENERALES
import ListarDatos from '../../general/ListarDatos';
import { consulta, rutaImg, mostrarError } from "../../../global/js/funciones";
import TareasTerminadas from "../../general/TareasTerminadas";
import { BtnDetalle, BtnPermiso, BtnForm, BtnEliminar } from '../../general/BotonesAccion';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import Generica from "./../../personas/generica/Generica";
import AppBarModal from "./../../general/AppBarModal";
import ConfirmarAccion from "../../general/ConfirmarAccion";

// COMPONENTES MODULO
import { BtnListarAsiginaturas } from './ui/BtnListarAsignatura';
import AsignaturasRetos from './AsignaturasRetos';

// IMAGENES
import emma_w from '../../../global/imagenes/emma_w.png';

// HELPERTS
import { ESTADOS } from '../Helpers'

class RetosPermisos extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cargando: true,
      personas: [],
      generica: {
        tipo: 0,
        id: 0,
      },
      modal: false,
      modalAsignatura: false,
      id_persona: null,
      asignaturas: []
    }
  }

  async componentDidMount() {
    let personas = await this.obtenerGestores()
    this.setState({ personas, cargando: false })
  }


  async obtenerGestores() {
    return new Promise(resolve => {
      consulta(`api/v1.0/retoscuc/encargados`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  async obtenerAsignaturas(id_profesor) {
    return new Promise(resolve => {
      consulta(`api/v1.0/retoscuc/permiso_asignatura/${id_profesor}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }


  acciones(data) {
    const onClickProgramas = (data) => this.setState({ id_persona: data.id, modal: true, generica: { tipo: 39, id: 5 } });
    // const onClickAsignatura = async (data) => {
    //   asignaturas = await this.obtenerAsignaturas(data.id);
    //   this.setState({asignaturas});
    //   if(asignaturas) this.setState({ id_persona: data.id, modalAsignatura: true })
    // }
    const onClickEstado = (data) => this.setState({ id_persona: data.id, modal: true, generica: { tipo: 40, id: ESTADOS } });

    let programas = <BtnDetalle callback={() => onClickProgramas(data)} texto='Programas' color='#2196f3' />;
    // let asignaturas = <BtnListarAsiginaturas callback={() => onClickAsignatura(data)} texto='Asignaturas' />
    let estados = <BtnPermiso callback={() => onClickEstado(data)} texto='Estados' />;
    return <div>{programas}  {estados} </div>
  }

  accionesEliminar(data) {
    const onClickEliminar = (data) => this.setState({ id_permiso_asignatura: data, openModalEliminar: true });
    console.log(data)
    let eliminar = <BtnEliminar callback={() => onClickEliminar(data)} texto='Estados' />;
    return <div>{eliminar}</div>
  }

  guardarCodigoAsignatura = (data) => {
    let { id_persona } = this.state;
    let { actualizarMensaje } = this.props
    consulta(`api/v1.0/retoscuc/${id_persona}/${data}`, null, 'post', async (error, estado, resp) => {
      let titulo = "Ha ocurrido un error, contacte el administrador", tipo = 'error'
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;;
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 9000 });
      let asignaturas = await this.obtenerAsignaturas(id_persona);
      this.setState({asignaturas});
    });
  };

  obtenrAsignaturasPermiso = () => {
    let { id_persona } = this.state;
    this.setState({ cargando: true });
    consulta(`api/v1.0/retoscuc/permiso_asignatura/${id_persona}` , null, null,
    (estado) => {
      if (estado === 200) {
        this.setState({ cargando: false });
      }
    });
  }


  eliminarPermisoAsignatura =  (id) => {
    let { id_persona } = this.state
    let { actualizarMensaje } = this.props;
    consulta(`api/v1.0/retoscuc/eliminar/${id}`, {}, "put", 
    async(error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            this.setState({openModalEliminar: false});
            let asignaturas = await this.obtenerAsignaturas(id_persona);
            this.setState({asignaturas});
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  }

  render() {
    let { modal, modalAsignatura, id_persona, generica, cargando, personas, asignaturas, openModalEliminar, id_permiso_asignatura } = this.state;
    let { modalPermisoAsignatura, mostrarModalPermisoAsignatura } = this.props
    console.log(asignaturas);



    if (cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />;
    return (
      <>
        <ListarDatos
          id="tbl_becas_permisos"
          titulo='Lista Gestores'
          descargar="EMMA - GESTORES RETOS"
          datos={personas}
          opciones={true}
          buscar={true}
          acciones={row => this.acciones(row)}
          avatarImg={true}
          avatar={({ identificacion }) => `${rutaImg}${identificacion}.jpg`}
          fila_principal={row => `${row.primer_nombre} ${row.segundo_nombre} ${row.primer_apellido} ${row.segundo_apellido}`.toUpperCase()}
          filas={[
            { 'mostrar': ({ correo }) => `${correo}`, 'id': 'correo', 'enLista': true },
            { 'mostrar': ({ identificacion }) => `${identificacion}`, 'id': 'identificacion', 'enLista': true }
          ]}
        />

        <AsignaturasRetos
          modal={modalPermisoAsignatura}
          mostrarModal={mostrarModalPermisoAsignatura}
          enviar={(value) => this.guardarCodigoAsignatura(value)}
          id_persona={id_persona}
        />

        <Dialog open={modal} fullWidth={true} maxWidth='sm' onClose={() => this.setState({ modal: false })}>
          <AppBarModal titulo='¡ Lista Permisos !' mostrarModal={() => this.setState({ modal: false })} accion={() => this.setState({ modal: false })} titulo_accion='CERRAR' />
          <DialogContent style={{ padding: 0 }} className='scroll'>
            <Generica
              notificaciones={(generica.tipo === 40)}
              tipo={generica.tipo}
              persona={{ id: id_persona }}
              genericaRelacionId={generica.id}
              titulo="¡ Nuevo Permiso !"
              tituloInput="Permisos"
              tituloEliminar='¿ Eliminar Permiso ?'
            />
          </DialogContent>
          <DialogActions>
            <BtnForm texto="Cerrar" callback={() => this.setState({ modal: false })} />
          </DialogActions>
        </Dialog>

        <Dialog open={modalAsignatura} fullWidth={true} maxWidth='sm' onClose={() => this.setState({ modalAsignatura: false })}>
          <AppBarModal titulo='¡ Lista Asignatura Retos !' mostrarModal={() => this.setState({ modalAsignatura: false })} accion={() => this.setState({ modalAsignatura: false })} titulo_accion='CERRAR' />
          <DialogContent style={{ padding: 0 }} className='scroll'>
            <ListarDatos
              datos={asignaturas}
              id='tbl_asignaturas'
              descargar='EMMA - ASIGNATURAS'
              opciones={true}
              agregar={true}
              acciones={row => this.accionesEliminar(row)}
              fila_principal={row => `${row.codigo_asignatura}`.toUpperCase()}
              filas={[
              ]}
              actAgregar={() => { this.props.mostrarModalPermisoAsignatura(true) }}
              
            />
          </DialogContent>
          <DialogActions>
            <BtnForm texto="Cerrar" callback={() => this.setState({ modalAsignatura: false })} />
          </DialogActions>
        </Dialog>


        <ConfirmarAccion
            titulo='¿ Cancelar Solicitud ?'
            dato={id_permiso_asignatura}
            mensaje1='¿Estás seguro(a) que deseas quitarle el permiso a esta persona?'
            modalConAccion={openModalEliminar}
            mostrarModalConfAccion={() => this.setState({ openModalEliminar: false, asignatura: 0 })}
            ejecutarAccion={this.eliminarPermisoAsignatura}
          />
      </>
    )
  }
}


export default RetosPermisos
