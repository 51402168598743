import React, { Component } from "react";
import PropTypes from "prop-types";

// COMPONENTES DE SISEF
import { actualizarMensaje, mostrarModulo } from "../../../redux/actions/actGlobal";
import { Menu } from "../sisef/MenuSisef";
import { consulta } from "../../../global/js/funciones";
import { connect } from "react-redux";
import ProcesosSisefEstudiante from "./ProcesosSisefEstudiante";


// COMPONENTES GENERALES

import { Grid, IconButton, Paper, Tooltip, } from "@material-ui/core";
import TituloAccion from "../../general/TituloAccion";
import { BtnRegresar } from "../../general/BotonesAccion";
import AccionesRapidas from "../../general/AccionesRapidas";
import FilterListIcon from "@material-ui/icons/FilterList";
import emma_s1 from "../../../global/imagenes/emma_s1.png";
import emma_w from "../../../global/imagenes/emma_w.png";
import TareasTerminadas from "../../general/TareasTerminadas";



class SisefAsignaturas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seleccion: "miproceso",
      data: {},
      cargando: false,
      mensaje: "",
      estudiante: '',
      asignaturas: [],
      codigo_asignatura: '',
    };
  }
  async componentDidMount() {
    await this.obtenerAsignaturaSisef();
  }

  async componentDidUpdate({ Modal }) {
    let { Modal: ModalNew } = this.props

    if (ModalNew !== Modal) {
      this.obtenerAsignaturaSisef();
    }
  }



  async obtenerAsignaturaSisef() {
    this.setState({ cargando: true });
    // Datos de la asignatura de prueba
    const datosDePrueba = [
      [1, 'Profesor A', '2023-1', 'PSICOLOGIA APLICADA', '24226', 'A', 'Lunes', '08:00', '10:00', 'Profesor A', 'Tipo 1', '3', 'Salón 101'],
      [2, 'Profesor B', '2023-1', 'Física', 'FIS101', 'B', 'Martes', '10:00', '12:00', 'Profesor B', 'Tipo 2', '4', 'Salón 102'],
      [3, 'Profesor C', '2023-1', 'Química', 'QUI101', 'C', 'Miércoles', '14:00', '16:00', 'Profesor C', 'Tipo 3', '5', 'Salón 103'],
    ];

    const useMockData = true;

    if (!useMockData) {
      const asignaturas = datosDePrueba.map(item => ({
        id: item[0],
        profesor: item[1],
        periodo: item[2],
        nombre_asignatura: item[3],
        codigo_asignatura: item[4],
        grupo: item[5],
        dia: item[6],
        hora_inicio: item[8],
        hora_fin: item[9],
        profesor_asignatura: item[10],
        tipo: item[12],
        creditos: item[13],
        salones: item[15],
      }));
      this.setState({ asignaturas, cargando: false });
    } else {
      console.log('Obteniendo asignaturas de SISEF');
      consulta(`api/v1.0/sisef/listarAsignaturasSisef`, null, null,
        (error, estado, data) => {
          if (estado === 200 && !error) {
            const asignaturas = data.asignaturas_programa_sisef.map(item => ({
              id: item[0],
              profesor: item[1],
              periodo: item[2],
              nombre_asignatura: item[3],
              codigo_asignatura: item[4],
              grupo: item[5],
              dia: item[6],
              hora_inicio: item[8],
              hora_fin: item[9],
              profesor_asignatura: item[10],
              tipo: item[12],
              creditos: item[13],
              salones: item[15],
            }));
            this.setState({ asignaturas, cargando: false });
          } else {
            this.setState({ cargando: false });
          }
        });
    }
  }

  cambiarEstado = (estados) => {
    this.setState(estados);
  };

  vista = () => {
    let { seleccion, codigo_asignatura } = this.state;
    let { periodo } = this.props;
    switch (seleccion) {
      case "miproceso":
        return this.pintarAsignaturas();
      case "actividad_sisef":
        return <ProcesosSisefEstudiante periodo={periodo} codigo_asignatura={codigo_asignatura} seleccionarEstu={() => this.setState({seleccion: 'miproceso'})} />
      default:
        break;
    }
  }

  pintarAsignaturas() {
    const { asignaturas } = this.state;
    const { seleccionar } = this.props;

    const obtenerColor = (num) => {
      const color = "white";
      const colores = {
        0: { backgroundColor: "#f5f5f5", color: "#757575" },
        1: { backgroundColor: "#ffc107", color },
        2: { backgroundColor: "#009688", color },
        3: { backgroundColor: "#0d47a1", color },
        4: { backgroundColor: "#02A6F2", color },
        5: { backgroundColor: "#e65100", color },
        6: { backgroundColor: "#6200ea", color },
        7: { backgroundColor: "#bdbdbd", color },
        8: { backgroundColor: "#F5951B", color },
        9: { backgroundColor: "#b71c1c", color },
      };

      return colores[num] || { backgroundColor: "#f5f5f5", color: "#757575" };
    };

    const handleActividadesSisef = (codigo_asignatura) => {
      this.setState({ seleccion: "actividad_sisef", codigo_asignatura });

    };

    return (
      <div className="pointer">
        <div className="regresar">
          <BtnRegresar callback={() => seleccionar('seleccion', 'miproceso_ini')} />
        </div>
        <div style={{ padding: "2% 2% 0% 2%" }}>
          <Grid container direction="row" spacing={1} justifyContent="center">
            <Grid item xs={12} sm={12} md={12} lg={10}>
              <Grid container direction="row" alignItems="center" spacing={1} style={{ width: "100%" }}>
                <Grid item xs={6}>
                  <TituloAccion principal="" alterno="Seleccione la asignatura a gestionar: " />
                </Grid>
                <Grid item xs={6}>
                  <Tooltip title="Filtrar" aria-label="add">
                    <IconButton edge={false} color="secondary" style={{ float: "right" }}>
                      <FilterListIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              <Paper elevation={0} square className="scroll contenido_fijo">
                {asignaturas.length === 0 ? (
                  <TareasTerminadas
                    mensaje="Aún no tiene asignaturas a cargo para este periodo."
                    marginTop="7%"
                    imagen={emma_s1}
                    widthImg="7%"
                    cargando={false}
                  />
                ) : (
                  <Grid container direction="row" spacing={1} style={{ width: "100%" }}>
                    {asignaturas.map(({ nombre_asignatura, codigo_asignatura }, i) => (
                      <Grid item xs={12} sm={12} md={6} lg={6} key={i}>
                        <Paper
                          elevation={1}
                          style={{ border: "1px solid #e0e0e0" }}
                          onClick={() => handleActividadesSisef(codigo_asignatura)}
                        >
                          <AccionesRapidas
                            imagen=""
                            titulo={nombre_asignatura.toUpperCase()}
                            sub_titulo=""
                            des_sub_titulo=""
                            avatar={obtenerColor(i % 10)}
                            avatar_name={nombre_asignatura.charAt(0).toUpperCase()}
                          />
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }

  render() {
    let { cargando, seleccion } = this.state
    let { estudiante } = this.props;
    return (
      <>
        <div>
          <Menu
            sele={seleccion}
            estudiante={estudiante}
          />

          <div className="contenido_modulo">
            {!cargando ? (
              this.vista()
            ) : (
              <TareasTerminadas
                mensaje="Cargando.."
                marginTop="7%"
                imagen={emma_w}
                widthImg="7%"
                cargando={true}
              />
            )}
          </div>
        </div>
      </>
    )

  }
}

const mapStateToProps = (state) => {
  let { usuario } = state.redGlobal;
  let { ModalAdd } = state.redSisef;
  return { usuario, ModalAdd };
};

const mapDispatchToProps = {
  mostrarModulo,
  actualizarMensaje
};


SisefAsignaturas.propTypes = {
  ModalAdd: PropTypes.bool.isRequired,
  mostrarModulo: PropTypes.func.isRequired,
  actualizarMensaje: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SisefAsignaturas);

