import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AppBarModal from '../../general/AppBarModal';
import { BtnForm } from "../../general/BotonesAccion";
import BuscarValorGenerica from "./../../general/BuscarValorGenerica2";


class GenericaAgregar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      relaciones: [],
      tipoRelaciones: [],
    }
  }

  componentDidUpdate({ modalAdd }) {
    let { modalAdd: modalAddNew } = this.props;
    if (modalAddNew !== modalAdd) this.changeState();
  }

  changeState = () => {
    this.setState({
      relaciones: [],
    })
  }

  onSubmit = () => {
    let { relaciones } = this.state;
    let { tipo } = this.props;
    relaciones = relaciones.map(({ id }) => id)
    this.props.guardarGenerica(relaciones, tipo);
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  seleccionarValor = valor => {
    let { relaciones } = this.state
    relaciones.push(valor)
    this.setState({ relaciones })
  }

  deseleccionarValor = valor => {
    let { relaciones } = this.state
    let encontrado = relaciones.findIndex(({ id }) => id == valor.id)
    if (encontrado > -1) {
      relaciones.splice(encontrado, 1);
      this.setState({ relaciones} )
    }
  }

  render() {
    let { relaciones } = this.state;
    let { modalAdd, mostrarModalAdd, titulo, genericaRelacionId, consultoria, dependenciasInter,centro } = this.props;

    return (
      <Dialog fullWidth={true} maxWidth='sm' open={modalAdd} onClose={mostrarModalAdd.bind(this, false)} aria-labelledby="form-dialog-title">
        <AppBarModal titulo={titulo} mostrarModal={mostrarModalAdd} titulo_accion="" accion="" />
        <DialogContent className='scroll'>
          <BuscarValorGenerica
              generica={genericaRelacionId}
              listaSeleccionados={relaciones}
              callbackAdd={(Valorseleccionado) => this.seleccionarValor(Valorseleccionado)}
              callbackDel={(Valorseleccionado) => this.deseleccionarValor(Valorseleccionado)}
              consultoria={consultoria}
              dependenciasInter={dependenciasInter}
              centro={centro}
            />
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CANCELAR" callback={() => mostrarModalAdd(false)} />
          <BtnForm texto="GUARDAR" callback={() => this.onSubmit()} />
        </DialogActions>
      </Dialog>
    )
  }
}

//Props del componente
GenericaAgregar.propTypes = {
  //variables
  modalAdd: PropTypes.bool.isRequired,
  titulo: PropTypes.string.isRequired,
  genericaRelacionId: PropTypes.number.isRequired,
  //funciones
  mostrarModalAdd: PropTypes.func.isRequired,
  guardarGenerica: PropTypes.func.isRequired,
}


export default GenericaAgregar;

