import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { BtnEliminar, BtnCerrado, BtnGuardar } from "../../general/BotonesAccion";
import ListarDatos from "../../general/ListarDatos"
import AlertasSimple from "../../general/AlertasSimple"
// Utils 
import moment from "moment";

class VotantesListar extends Component {
  render() {
    let { desactivar_acciones, votantes, actualizarDataVotante, mostrarModalDelVotantes, eleccion, mostrarModalAddVotantes } = this.props;

    const actions = (rowData) => {
      const onClickEliminar = (data) => {
        actualizarDataVotante({ id: data.id, "nombre": data.tipo.nombre });
        mostrarModalDelVotantes(true);
      }
      //return desactivar_acciones(eleccion.fecha_inicio, eleccion.estado) || votantes.length === 1 ? <BtnCerrado /> : <BtnEliminar callback={() => onClickEliminar(rowData)} />;
      return <BtnEliminar callback={() => onClickEliminar(rowData)} />;
    }

    const desactivar_guardar = () => {
      let { eleccion, desactivar_acciones } = this.props;
      //return desactivar_acciones(eleccion.fecha_inicio, eleccion.estado) ? '' : <BtnGuardar callback={() => mostrarModalAddVotantes(true)} />;
      // return <BtnGuardar callback={() => mostrarModalAddVotantes(true)} />;
      return <AlertasSimple
      tipo="info"
      titulo="¿ Agregar Votantes ?"
      sub_titulo="Click aquí para agregar votantes."
      callback={()=> mostrarModalAddVotantes(true)}
    />
    }

    return (
      <>
        {desactivar_guardar()}
        <ListarDatos
          datos={votantes}
          id='tbl_votantes'
          descargar='EMMA - VOTANTES'
          avatar={({ tipo }) => tipo.nombre.charAt(0).toUpperCase()}
          // opciones={true}
          // agregar={true}
          // actAgregar={() => {}}
          acciones={(data) => actions(data)}
          fila_principal={({ tipo }) => tipo.nombre}
          filas={[
            { 'id': 'fecha_registro', 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a'), },
          ]}
        />
      </>

    );
  }

}

//Props del componente
VotantesListar.propTypes = {
  //variables
  votantes: PropTypes.array.isRequired,
  eleccion: PropTypes.shape({
    id: PropTypes.number.isRequired,
    nombre: PropTypes.string.isRequired,
    fecha_inicio: PropTypes.string.isRequired,
    fecha_fin: PropTypes.string.isRequired,
  }),
  //funciones
  desactivar_acciones: PropTypes.func.isRequired,
  mostrarModalDelVotantes: PropTypes.func.isRequired,
  mostrarModalAddVotantes: PropTypes.func.isRequired,
  actualizarDataVotante: PropTypes.func.isRequired,
}


export default VotantesListar;


