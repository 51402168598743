import React, { Component } from 'react';
import emma_w from '../../../global/imagenes/emma_w.png'
import { Dialog, DialogContent, Grid, DialogActions } from '@material-ui/core'
import AppBarModal from '../../general/AppBarModal'
import { BtnForm } from '../../general/BotonesAccion'
import BuscarSelect from '../../general/BuscarSelect';
import { obtenerValoresFiltros, transformar } from "../../../global/js/funciones";
import TareasTerminadas from '../../general/TareasTerminadas'
import{
    AREAS_INTERES,ESTADOS_VACANTE_PORTAL
} from '../helper'

class PortalFiltros extends Component {

    constructor(props){
        super(props)
        this.state = {
            cargando: true,
            areas: [],
            area: '',
            estados:[],
            estado: '',
            cargar: true
        }
    }

    async componentDidUpdate({ modalAddFiltros }){
        let { modalAddFiltros: modalAddFiltrosNew } = this.props
        if(modalAddFiltros !== modalAddFiltrosNew && this.state.cargar ){
            this.setState({ cargando: true })
            let areas = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': AREAS_INTERES }, { 'llave': 'estado', 'valor': 1 }])
            let estados = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': ESTADOS_VACANTE_PORTAL }, { 'llave': 'estado', 'valor': 1 }])
            this.setState({
                areas: transformar(areas),
                estados: transformar(estados),
                cargando: false,
                cargar: false
            })
        }
    }

    onSubmit = e => {
        e.preventDefault()
        let { filtrar, actualizarMensaje, mostrarModalAddFiltros } = this.props
        let { area } = this.state
        let filtros = [];
        if(area !== '') filtros.push({ 'llave': 'area', 'valor': area })
        if(filtros.length > 0) {
            filtrar(filtros)
            mostrarModalAddFiltros(false)
        } else actualizarMensaje({ titulo: 'Seleccione Algún filtro', tipo: 'info', mostrar: true, tiempo: 6000 });
    }

    onSubmitEstado = e => {
        e.preventDefault()
        let { filtrar_estados, actualizarMensaje, mostrarModalAddFiltros } = this.props
        let { estado } = this.state
        let filtros = [];
        if(estado !== '') filtros.push({ 'llave': 'estado', 'valor': estado })
        if(filtros.length > 0) {
            filtrar_estados(filtros)
            mostrarModalAddFiltros(false)
        } else actualizarMensaje({ titulo: 'Seleccione Algún filtro', tipo: 'info', mostrar: true, tiempo: 6000 });
    }

    resetState = () => {
        let { limpiar, mostrarModalAddFiltros } = this.props;
        mostrarModalAddFiltros(false);
        this.setState({
            area: '',
            estado: '',
        })
        limpiar();
    };

    render() {
        let { mostrarModalAddFiltros, modalAddFiltros } = this.props
        let { areas, cargando, area, estados,estado } = this.state
        return (
          <Dialog fullWidth={true} maxWidth="sm" open={modalAddFiltros} onClose={() => mostrarModalAddFiltros(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <AppBarModal titulo='¡ Aplicar Filtros !' mostrarModal={mostrarModalAddFiltros} titulo_accion="Limpiar" accion={this.resetState}/>
            <DialogContent className='scroll'>
                {
                    cargando ? <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" /> :
                   <>
                   <Grid container spacing={3} className='margin_cont'>
                        <Grid item xs={12} xl={12} sm={12} md={12} lg={12} style={{'marginBottom': '50px'}}>
                            <BuscarSelect datos={areas} cambiarEstado={(value) => this.setState({ area: value })} id='select-area' valor={area} nombre='Áreas de interes' placeholder='Seleccione el área de interes' />
                        </Grid>
                        <Grid item xs={12} xl={12} sm={12} md={12} lg={12} style={{'marginBottom': '50px'}}>
                            <BuscarSelect datos={estados} cambiarEstado={(value) => this.setState({ estado: value })} id='select-estado' valor={estado} nombre='Estados' placeholder='Seleccione el estado' />
                        </Grid>
                    </Grid>
                   </> 
                }
            </DialogContent>
            <DialogActions>
              <BtnForm texto="CANCELAR" callback={() => mostrarModalAddFiltros(false)} />
              <BtnForm texto="FILTRAR" callback={area !== '' ? this.onSubmit:this.onSubmitEstado} />
            </DialogActions>
          </Dialog >
        )
    }
}

export default PortalFiltros;