import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import {
  ArrowRightAltRounded,
  GetAppRounded,
  HelpRounded,
} from "@material-ui/icons";
import { coloresEmma} from "../../global/js/funciones";

const useStyle = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: coloresEmma.fondocolor,
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    margin: theme.spacing(2, 2),
    padding: theme.spacing(2, 3),
    borderRadius: theme.spacing(2),
    borderLeft: `3px solid ${coloresEmma.primarycolor}`,
  },

  title: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    color: "#2c3e50",
  },

  icon: {
    marginRight: theme.spacing(1),
  },

  buttonAlert: {
    textDecoration: "none",
    color: "black",
    borderBottom: `1px solid ${coloresEmma.primarycolor}`,
  },

  buttonIcon: {
    display: "flex",
    alignItems: "center",
    color: coloresEmma.primarycolor,
    gap: theme.spacing(1),
  },

  info: {
    color: "#4c4d4f",
  },
}));

const alertIcons = {
  duda: <HelpRounded />,
  descarga: <GetAppRounded />,
};

const MensajeContacto = (props) => {
  const classes = useStyle();
  const { title, typeIcon, content, enlaces } = props;
  return (
    <div id="mensajeInformacion" className={classes.container}>
      <div className={classes.title}>
        {alertIcons[typeIcon]}
        <Typography variant="h6">{title}</Typography>
      </div>
      <p className={classes.info}>{content}</p>
      {enlaces
        ? enlaces.map((enlace, index) => (
            <div className={classes.buttonIcon} key={index}>
              <a
                className={classes.buttonAlert}
                href={enlace.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {enlace.title}
              </a>
              <ArrowRightAltRounded />
            </div>
          ))
        : null}
    </div>
  );
};

export default MensajeContacto;
