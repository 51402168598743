import React, { Component } from 'react'
import { Dialog, DialogContent, DialogActions, Link, } from '@material-ui/core';

// FUNCIONES GLOBALES
import AppBarModal from '../../general/AppBarModal';
import { BtnForm, BtnDetalle, BtnCancelar } from '../../general/BotonesAccion';
import ListarDatos from '../../general/ListarDatos';
import { consulta, generarFiltros, mostrarError, obtenerValoresFiltros } from '../../../global/js/funciones';
import ConfirmarAccion from "../../general/ConfirmarAccion";

// COMPONETES MODULO
import { RetosMenu } from './MenuRetos';
import { DetalleSolicitud } from "./DetalleRetos"
import BarraProceso from '../../general/BarraProceso';

// IMAGENES

import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from '../../../global/imagenes/emma_w.png';
import moment from 'moment'


class RetosCuc extends Component {
  constructor(props) {
    super(props)
    this.state = {
      estados: [],
      historial: [],
      datos: [],
      documentos: [],
      cargando: false,
      seleccion: '',
      nota: '',
      lineaEstados:[],
      procesoActual: '',
      idEvidencia: '',
      mostrarModalEvidencia: false,
    };
  }


  async componentDidUpdate({ modalDetRetos }, { seleccion }) {
    let { seleccion: seleccionNew } = this.state;
    let { retoActual, modalDetRetos: modalDetRetosNew, vistaDetalle, reset } = this.props;

    if (seleccionNew !== seleccion) {

      if (seleccionNew === 'detalle') this.obtenerInfoRetos(retoActual);
      else if (seleccionNew === 'documentos') this.obtenerAdjuntos(retoActual)
      else if (seleccionNew === 'estados') this.obtenerEstadosRetos(retoActual);
      else if (seleccionNew === 'historial') await this.obtenerHistorialNotas(retoActual);
      else if (seleccionNew === 'LineaDeEstados') this.obtenerLineaDeEstados(this.state?.datos[0]?.usuario_registro?.id);
    }

    if (modalDetRetos !== modalDetRetosNew) {
      if (!modalDetRetosNew){
        reset()
        this.setState({ seleccion: 'detalle', vistaDetalle: '' })
      } 
      this.setState({ seleccion: modalDetRetosNew ? vistaDetalle : '' })
    }

  }

  async obtenerInfoRetos(id) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'id', 'valor': id }]);
    consulta(`api/v1.0/retos?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, datos: resp });
    })
  }

  async obtenerEstadosRetos(id) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'retoscuc', 'valor': id }]);
    consulta(`api/v1.0/retos/estados?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, estados: resp, historial: resp });
    })
  }

  async obtenerHistorialNotas(id) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'retoscuc', 'valor': id }]);
    consulta(`api/v1.0/retoscuc/historial?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, historial: resp });
    })
  }

  async obtenerLineaDeEstados(id) {
    let { retoActual } = this.props;
    this.setState({ cargando: true });
    let id_retoscuc = await generarFiltros([{ 'llave': 'id_retoscuc', 'valor': retoActual }]);
    let f = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Ret' }]);
    consulta(`api/v1.0/retos/completa/${f[0].nombre}/${id}?${id_retoscuc}`, null, null, (error, estado, resp) => {

      if (estado === 200) this.setState({ cargando: false, lineaEstados: resp.linea_estados, procesoActual: resp.retoscuc });
    });
  }

  async obtenerAdjuntos(id) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'retos', 'valor': id }]);
    consulta(`api/v1.0/retos/adjuntos?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, documentos: resp });
    })
  }

  async validarEstadoEvidencia(id) {
    this.setState({ cargando: true, mostrarModalEvidencia: false});
    let { actualizarMensaje,retoActual } = this.props;
    consulta(`api/v1.0/retoscuc/validar/${id}`, {}, "put", (error, estado, resp) => {
      let titulo = "Ha ocurrido un error, contacte con el administrador.",
        tipo = "error";
      if (!error) {
        if (estado === 200) {
          tipo = "success";
          titulo = resp.titulo;
          this.setState({ cargando: false, idEvidencia: '' })
          this.obtenerAdjuntos(retoActual)
        } else {
          tipo = "info";
          titulo = mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    })
}


pintarEstados = () => {
  let { estados } = this.state;
  const obtenerColor = (codigo) => {
    let color = 'white';
    const colores = {
      'Ret_Gru': { 'backgroundColor': '#0044ff', color },
      'Ret_Env': { 'backgroundColor': '#ffc107', color },
      'Ret_Cal': { 'backgroundColor': '#5f02f5', color },
      'Ret_Env_e2': { 'backgroundColor': '#005E66', color },
      'Ret_Cal_e2': { 'backgroundColor': '#AF601A', color },
      'Ret_Env_e3': { 'backgroundColor': '#2E4053', color },
      'Ret_Cal_e3': { 'backgroundColor': '#685DE3', color },
      'Ret_Env_Fn': { 'backgroundColor': '#B329E6', color },
      'Ret_Cal_Fn': { 'backgroundColor': '#E4D281', color },
      'Ret_Sel': { 'backgroundColor': '#09BA69', color },
      'Ret_Dep': { 'backgroundColor': '#5af7fa', color },
      'Ret_Ire': { 'backgroundColor': '#bffa5a', color },
      'Ret_Gse': { 'backgroundColor': '#46fa64', color },
      'Ret_Act': { 'backgroundColor': '#6c036e', color },
      'Ret_Des': { 'backgroundColor': '#fa3802', color },
      'Ret_Des_Prof': { 'backgroundColor': '#fa3802', color },
      'Ret_Des_Depr': { 'backgroundColor': '#fa3802', color },
      'Ret_Des_Intr': { 'backgroundColor': '#fa3802', color },
      'Ret_Cer': { 'backgroundColor': '#fa3802', color },
      'Tod_Ret': { 'backgroundColor': '#0d47a1', color },
    }
    return (colores[codigo])
  }

  return (
    <ListarDatos
      datos={estados}
      avatar={({ tipo_estado }) => tipo_estado.nombre.charAt(0).toUpperCase()}
      color_avatar={({ tipo_estado: { codigo } }) => obtenerColor(codigo)}
      fila_principal={({ tipo_estado }) => tipo_estado.nombre.toUpperCase()}
      filas={[
        { 'id': 'usurio_registro', 'enLista': true, 'mostrar': ({ usuario_registro }) => `${usuario_registro.primer_nombre} ${usuario_registro.segundo_nombre} ${usuario_registro.primer_apellido} ${usuario_registro.segundo_apellido}`.toUpperCase() },
        { 'id': 'fecha_registro', 'enLista': true, 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a') },
      ]}
    />
  )
};

pintarHistorialNostas = () => {
  let { historial } = this.state;
  console.log(historial)
  if (historial.length > 0) {
    return (
      <ListarDatos
        datos={historial}
        filas={[
          { mostrar: ({ tipo_estado: { nombre } }) => `${nombre} `, },
          { 'id': 'mensaje', 'enLista': true, 'mostrar': ({ mensaje }) => mensaje },
          { 'id': 'retroalimentacion', 'enLista': true, 'mostrar': ({ retroalimentacion_estudiante }) => retroalimentacion_estudiante },
        ]}
      />
    )
  } else {
    return <TareasTerminadas mensaje='No hay información a mostrar' marginTop='7%' imagen={emma_w} widthImg="7%" />;
  }
};

pintarLineaDeEstados = () => {
  let { lineaEstados, procesoActual } = this.state;
  if (procesoActual && lineaEstados.length > 0) {
    let activeStep = 0;
    let steps = [];
    if (procesoActual.estado_actual.codigo === 'Ret_Des' || procesoActual.estado_actual.codigo === 'Ret_Des_Prof' || procesoActual.estado_actual.codigo === 'Ret_Des_Depr'  || procesoActual.estado_actual.codigo === 'Ret_Des_Intr') {
      steps.push({ label: procesoActual.estado_actual.nombre });
    } else {
      console.log(procesoActual);
      lineaEstados.map(({ secundario }, i) => {
        if (secundario.id === procesoActual.estado_actual.id) activeStep = i;
        steps.push({ label: secundario.nombre })
      })
    }

    return <BarraProceso steps={steps} activeStep={activeStep} />;
  }
  return null;
};

pintarReto = () => {
  let { datos } = this.state;
  console.log(datos)
  if (datos[0]) {
    return <DetalleSolicitud retoscuc={datos[0]} />;
  }
}


pintarAdjuntos = () => {
  let { documentos } = this.state;
  let { perfilEstudiante } = this.props;

  const obtenerColor = ({ retoscuc }) => {
    let color = 'white';
    const colores = {
      '1': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
      '2': { 'backgroundColor': '#01519B', color },
      '3': { 'backgroundColor': '#b71c1c', color },
    }
    return (colores[retoscuc])
  }

  const acciones = (row) => {
    const eventoNegar = (row) => {
      this.setState({ mostrarModalEvidencia: true, idEvidencia: row.id })
    }

    let detalle = <Link href={`${row.archivo}`} target="_blank"><BtnDetalle callback={() => { }} /></Link>;

    let negar = (row.retos_solicitud == "1" && !perfilEstudiante) && <BtnCancelar texto='Negar' callback={() => eventoNegar(row)} />;

    return <div>{detalle} {negar}</div>;
  }

  return (
    <ListarDatos
      id="tbl_mis_adjuntos"
      descargar="EVIDENCIAS RETOS - EMMA"
      datos={documentos}
      buscar={false}
      avatar={({ retos_solicitud_text }) => retos_solicitud_text.charAt(0).toUpperCase()}
      color_avatar={row => obtenerColor(row)}
      acciones={row => acciones(row)}
      fila_principal={({ evidencia }) => evidencia.nombre.toUpperCase()}
      filas={[
        { 'id': 'nombre_archivo', 'enLista': false },
        { 'id': 'retos_solicitud_text', 'enLista': true, 'mostrar': ({ retos_solicitud_text }) => retos_solicitud_text.toUpperCase() },
        { 'id': 'fecha_registro', 'enLista': true, 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a') },
        { 'id': 'valido', 'enLista': true, 'mostrar': ({ usuario_valida }) => `${usuario_valida ? `VALIDADO: ${usuario_valida.primer_nombre} ${usuario_valida.primer_apellido} ${usuario_valida.segundo_apellido} - ${usuario_valida.correo}` : ''}` },
        { 'id': 'nota', 'enLista': true, 'mostrar': ({ retoscuc, nota }) => `${retoscuc == 3 ? `${nota}` : ''}` },
      ]}
    />
  )
};


pintar = () => {
  let { cargando, seleccion } = this.state;
  if (cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />;
  return (
    <div>
      {seleccion === 'detalle' && this.pintarReto()}
      {seleccion == 'documentos' && this.pintarAdjuntos()}
      {seleccion === 'estados' && this.pintarEstados()}
      {seleccion === 'historial' && this.pintarHistorialNostas()}
      {seleccion === 'LineaDeEstados' && this.pintarLineaDeEstados()}
    </div>
  )

}

render() {
  let { seleccion, idEvidencia } = this.state;
  let { modalDetRetos, mostrarModalDetalleRetos } = this.props

  return (
    <>
      <Dialog open={modalDetRetos} maxWidth="md" fullWidth={true} onClose={() => mostrarModalDetalleRetos(false)}>
        <AppBarModal titulo='¡ Detalle Retos !' mostrarModal={mostrarModalDetalleRetos} titulo_accion='' />
        <RetosMenu sele={seleccion} seleccionar={seleccion => this.setState({ seleccion })} />
        <DialogContent style={{ padding: 0 }} className='scroll' >
          {this.pintar()}
        </DialogContent>
        <DialogActions>
          <BtnForm texto="Cerrar" callback={() => mostrarModalDetalleRetos(false)} />
        </DialogActions>
      </Dialog>

      {
        idEvidencia &&
        <ConfirmarAccion
          titulo='Negar evidencia'
          mensaje1='¿Está seguro que desea negar la evidencia?'
          dato={{ id: idEvidencia }}
          ejecutarAccion={() => this.validarEstadoEvidencia(idEvidencia)}
          modalConAccion={this.state.mostrarModalEvidencia}
          mostrarModalConfAccion={mostrarModalEvidencia => this.setState({ mostrarModalEvidencia })} />
      }
    </>
  );
}
}

export default RetosCuc