import { makeStyles } from '@material-ui/core/styles';
import { coloresEmma } from '../../global/js/funciones';
const useStylesRubrica = makeStyles((theme) => ({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: coloresEmma.fondocolor,
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: coloresEmma.fondocolor,
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: coloresEmma.fondocolor,
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    },
    menuPrincipal: {
        background: coloresEmma.fondocolor,
        display: 'flex',
        justifyContent: 'center',
        height: '100px',
        [theme.breakpoints.down('sm')]: {
            height: '600px',
        },
    },
    textoMenu: {
        fontSize: '15px',
        color:'#515A5A '
    },
    botonesMenu: {
        height: '35px',
        width: '150px',
        color: 'white',
        background: '#2C2D2D',
        fontSize: '15px',
        '&:hover': {
            backgroundColor: coloresEmma.secondarycolor,
            boxShadow: 'none',
            color: coloresEmma.fondocolor,
        },
        botonesM: {
            paddingTop: "20px",
            display: 'flex',
            justifyContent: 'center'
        },
        fondo: {
            background:'#E9967A',
            height: '400px',
            [theme.breakpoints.down('sm')]: {
                height: '550px',
            },
        }
    },
    tablero: {
        border: '20px',
        borderStyle: 'solid',
        borderRadius: '30px',
        background: 'white',
        width: '200px',
        height:'520px',
        borderColor: '#90aeb2',
        textAlign:'left',
        paddingLeft:'10px',
        [theme.breakpoints.down('sm')]: {
          height: '500px',
          margin:'10px 0' 
        },
        
      },
      btnCorte : {
          backgroundColor: 'white',
          color : "900807",
          textAlign : 'center',
          fontWeight: 'bold',
          fontSize: '13px',
          borderRadius: 0, 
          width : 100,
          height : 20,
          margin : "5px 5px 5px 5px",
          "&:hover": {
            background: coloresEmma.secondarycolor,
            color : "white",
          },
      },
      btnMoreInfo : {
            margin: '5px',
            textAling: 'center',
            borderRadius : "50%",
            backgroundColor : '#C1C1C1',
            height : 30, 
            width: 30,
            color :"white",
            "&:hover": {
                background: coloresEmma.secondarycolor
              },
         },
         headTablaRubrica:{
            background: coloresEmma.secondarycolor,
            color : 'white',
            fontSize: '15px',
        },
        tdRubrica:{
            fontSize: '18px',
            textAlign: 'center',
         },
         table: {
            minWidth: 600,
            width:'100%',
        }
         

}));

export default useStylesRubrica;