import React, { Component } from 'react';
import { mostrarModulo, actualizarMensaje } from '../../redux/actions/actGlobal'
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Grid, Paper,Card,CardHeader,Avatar,CardContent,Typography,Dialog,DialogContent,DialogActions,Stepper,Step,StepLabel } from '@material-ui/core'
import { consulta, formulario, mostrarError, crear_form_data, obtenerPermisosValorAlt,rutaImg,obtenerValores} from '../../global/js/funciones'
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import solicitudGrado from '../../global/imagenes/solicitudGrado.png';
import ingenieros from '../../global/imagenes/ingenieros.png';
import AppBarModal from '../general/AppBarModal';
import { BtnDetalle, BtnGestionar, BtnFolder,BtnRegresar, BtnForm, BtnFormPage, BtnEditar } from "../general/BotonesAccion";
import ListarDatos from "../general/ListarDatos";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import {
    mostrarModalGestionOferta,
    mostrarModalDetalleVacante,
    mostrarModalAddFiltros,
    mostrarModalListarPostulantes,
    mostrarModalEstadoProceso,
    mostrarModalDetallePostulante,
    mostrarModalEditPqrs,
    mostrarModalDetallePqrs,
    mostrarModalListaEstadistica,
    mostrarModalGestionarestudiante,
    mostrarModalGestionarEmpresa,
    mostrarModalActualizarCorreo,
    mostrarModalActualizarDatosEmpresa,
    mostrarModalDetalleEstadosGestion,
} from '../../redux/actions/actPortal'

//components
import TextField from '@material-ui/core/TextField';
import MenuAdm from './PortalGestion/MenuAdm'
import VacanteGestion from './PortalGestion/VacanteGestion'
import GestionOferta from './PortalGestion/GestionOferta'
import GestionarProceso from './PortalGestion/GestionarProceso'
import DetalleOferta from './Portal/VacanteDetalleLista'
import PortalFiltros from './PortalGestion/PortalFiltros'
import Pqrs from './PortalGestion/PqrsGestion'
import ListarPostulantes from './PortalGestion/ListarPostulantes'
import DetallePostulante from './PortalGestion/DetallePostulante'
import EditPqrs from './Pqrs/EditPqrs'
import DetallePqrs from './Pqrs/DetallePqrs'
import BuscarCV from './Empresa/BuscarPostulantes'
import DetalleEstados from './PortalGestion/DetalleEstados';

import TareasTerminadas from "../general/TareasTerminadas";
import TituloAccion from '../general/TituloAccion';
import AccionesRapidas from '../general/AccionesRapidas';
import empresasIcon from '../../global/imagenes/work.png';
import fechaIcon from '../../global/imagenes/calendar.png';
import permisosIcon from '../../global/imagenes/lightbulb.png';
import sucursalesIcon from '../../global/imagenes/architecture-and-city.png';
import vistaIcon from '../../global/imagenes/hourglass.png';
import emma_w from '../../global/imagenes/emma_w.png';
import ModalListaEstadistica from './PortalGestion/ModalListaEstadistica'
import DocumentosOferentes from './PortalGestion/DocumentosOferentes'
import moment from 'moment';
import { DOCUMENTOS_INFORMATIVOS } from './helper';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ImgPortalPiel } from './ImgPortalPiel';
//Imagenes
import mintrabajo from '../../global/imagenes/portal/mintrabajo.png';


const useStyles = makeStyles(theme => ({
    card: {
        maxWidth: "100%", height: '350px'
    },
    image: {
        width: '150px',
        maxWidth: '200px',
        minWidth: '100px',
        height: "150px",
    },
    content_text: {
        padding: "15px 10px 0px 10px",
    },
    content_img: {
        textAlign: 'center',
        height: "150px",
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

function FormEstadistica({ selectDate, inicio, final, getEstadistica }) {
    const classes = useStyles();
    return (
        <Grid container direction={'row'} justifyContent={'center'} style={{ paddingTop: 40 }} >
            <Grid item xs={12} sm={12} md={10} lg={8}>
                <Grid container direction={'row'} justifyContent={'center'}>
                    <Grid item xs = { 5 }>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                id = 'date_inicio'
                                required
                                label = "Fecha Inicial"
                                name = 'date_inicio'
                                format = 'dd/MM/yyyy'
                                value = { inicio }
                                onChange = { (e) => selectDate({
                                    target: {
                                        value: e,
                                        name: "date_inicio"
                                    }
                                }) }
                                fullWidth
                                KeyboardButtonProps={{ 'aria-label': 'cambiar fecha' }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs = { 5 }>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                id = 'date_final'
                                required
                                label = "Fecha Final"
                                name = 'date_final'
                                format = 'dd/MM/yyyy'
                                value = { final }
                                onChange = { (e) => selectDate({
                                    target: {
                                        value: e,
                                        name: "date_final"
                                    }
                                }) }
                                fullWidth
                                KeyboardButtonProps={{ 'aria-label': 'cambiar fecha' }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}


const configStep = ['Consultar estudiante', 'Datos de estudiante'];

function ConsultarEstudiante(component_props){
    let {identificacion,onChange} = component_props
  
    return(
      <>
        <Grid container spacing={3} style={{ marginBottom: '40px' }}>
          <Grid item xs={12}>
            <TextField
              value={identificacion}
              required
              type="number"
              id="identificacion"
              name="identificacion"
              label="Documento Identidad"
              fullWidth
              onChange={onChange}
            />
          </Grid>
        </Grid>
  
      </>
  )
  }
  
  function DatosEstudiante(component_props) {
    let { datosestudiantes,procesos } = component_props
    const actions = row => {
        return (
            <div>
                <BtnEditar
                    callback={() => {
                        procesos(row)
                    }}
                />
            </div>
        )
    }

    return (
      <>
        <ListarDatos
          datos={datosestudiantes}
          titulo='Datos estudiante'
          id='tbl_Datos_listar'
          opciones={true}
          acciones={row => actions(row)}
          buscar={true}
          avatarImg={true}
            css={{
              list: { padding: '0px 0px 20px 0px' },
              appBar: { padding: '0px 0px 0px 0px' },
            }}
          avatar={({ identificacion }) => `${rutaImg}${identificacion}.jpg`}
          fila_principal={({ primer_nombre }) => primer_nombre}
          filas={[
            { 'id': 'identificacion', 'mostrar': ({ identificacion }) => identificacion },
            { 'id': 'primer_nombre', 'mostrar': ({ primer_nombre, primer_apellido }) => `${primer_nombre} ${primer_apellido}` },
            { 'id': 'correo_personal', 'mostrar': ({ correo_personal }) => correo_personal },
            { 'id': 'usuario', 'mostrar': ({ usuario }) => usuario },
          ]}
        />
  
      </>
    )
  
  }

const configStepEmpresa = ['Consultar Empresas', 'Datos de Empresa'];

function DatosEmpresa(component_props) {
    let { datosestudiantes,procesosEmpresa } = component_props
    const actions = row => {
        return (
            <div>
                <BtnEditar
                    callback={() => {
                        procesosEmpresa(row)
                    }}
                />
            </div>
        )
    }

    return (
      <>
        <ListarDatos
          datos={datosestudiantes}
          titulo='Datos estudiante'
          id='tbl_Datos_listar'
          opciones={true}
          acciones={row => actions(row)}
          buscar={true}
          avatarImg={true}
            css={{
              list: { padding: '0px 0px 20px 0px' },
              appBar: { padding: '0px 0px 0px 0px' },
            }}
          avatar={({ identificacion }) => `${rutaImg}${identificacion}.jpg`}
          fila_principal={({ primer_nombre }) => primer_nombre}
          filas={[
            { 'id': 'identificacion', 'mostrar': ({ identificacion }) => identificacion },
            { 'id': 'primer_nombre', 'mostrar': ({ primer_nombre, primer_apellido }) => `${primer_nombre} ${primer_apellido}` },
            { 'id': 'correo_personal', 'mostrar': ({ correo_personal }) => correo_personal },
            { 'id': 'usuario', 'mostrar': ({ usuario }) => usuario },
          ]}
        />
  
      </>
    )
  
  }


class PortalGestion extends Component {

    constructor(props) {
        super(props)
        this.state = {
            seleccion: 'vacantes',
            vacantes: [],
            vacantes_estados_filtro:[],
            bandera:false,
            filtros: [],
            oferta: {},
            detalleVacante: {},
            cargando: true,
            // postulantes: [],
            postulante: { estado_proceso: { id: '0' } },
            // detallePostulante: [],
            listPqrs: [],
            body_pqrs: '',
            idPqrs: null,
            pqrs: {},
            date_inicio: moment().format('YYYY-MM-DD'),
            date_final: moment().format('YYYY-MM-DD'),
            tipoEstadistica: 0,
            modalDocumentosOferentes: false,
            archivos: [],
            data_estadistica: {},
            idVacantePostulantes: 0,
            idDetallePostulante: 0,
            doc_informativos: [],
            activeStep:0,
            identificacion:"",
            datosestudiantes:{ "respuesta":[]},
            correo:"",
            tipo: '',
            tipo_identificacion: '',
            primer_nombre: '',
            segundo_nombre: '',
            primer_apellido: '',
            segundo_apellido: '',
            usuario: '',
            login_directorio: 1,
            indicativo_celular: null,
            celular: null,
            telefono: null,
            correo_personal: '',
            activo: 0,
            dataPersonaEmma:[],
            tipos_persona: [],
            tipos_persona: [
                {
                  id: 1,
                  nombre: 'PERSONA INTERNA'
                },
                {
                  id: 2,
                  nombre: 'PERSONA EXTERNA'
                }
              ],
            tipos_identificacion: [],
            idVacanteEstados:0,
        }
    }

    async componentDidMount() {
        let { mostrarModulo } = this.props
        mostrarModulo({ ruta: '/portal_gestion', nombre: 'Portal Administrador' })
        this.cargarTipos();
        this.setState({ cargando: true });
        this.setState({
            vacantes: await this.getVacantes(),
            doc_informativos: (await obtenerPermisosValorAlt([{ 'llave': 'principal__codigo', 'valor': 'admportal_act' }, { 'llave': 'secundario__generica', 'valor': DOCUMENTOS_INFORMATIVOS }])).map(({secundario}) => secundario),
            cargando: false
        })
    }

    async componentDidUpdate({ }, { seleccion }) {
        let { seleccion: seleccionNew } = this.state;
        if (seleccion !== seleccionNew) {
            this.setState({ cargando: true })
            if (seleccionNew === 'vacantes') this.setState({ vacantes: await this.getVacantes() })
            else if (seleccionNew === 'pqrs') this.setState({ listPqrs: await this.getPqrs() })
            this.setState({ cargando: false })
        }
    }

    cargarTipos = async () => {
        const tipos_identificacion = await obtenerValores(2);
        this.setState({
          tipos_identificacion
        })
      }

    procesos = (dato)=> {
        let {mostrarModalActualizarCorreo} = this.props

        this.setState({
            correo:dato.correo,
            dataPersonaEmma:dato
        })
        mostrarModalActualizarCorreo(true);
        
    }


    procesosEmpresa = (dato)=> {
        let {mostrarModalActualizarDatosEmpresa} = this.props

        this.setState({
            tipo_identificacion:dato.tipo_identificacion.id,
            identificacion:dato.identificacion,
            primer_nombre:dato.primer_nombre,
            segundo_nombre:dato.segundo_nombre,
            primer_apellido:dato.primer_apellido,
            segundo_apellido:dato.segundo_apellido,
            correo:dato.correo,
            usuario:dato.usuario,
            indicativo_celular:dato.indicativo_celular,
            celular:dato.celular,
            telefono:dato.telefono,
            correo_personal:dato.correo_personal,
            tipo:dato.tipo,
            dataPersonaEmma:dato
        })
        mostrarModalActualizarDatosEmpresa(true);
        console.log(dato)
    }

    reiniciarEstado() {
        this.setState({
          activeStep: 0, 
          cargando: false, 
          identificacion: '',
          datosestudiantes:[],
        })
      }

    modificarPersona = (id, datos) => {
        let { actualizarMensaje, mostrarModalActualizarCorreo,mostrarModalGestionarestudiante } = this.props;
        let { tipo, tipo_identificacion, identificacion, primer_nombre, segundo_nombre, primer_apellido, segundo_apellido, correo, usuario, login_directorio, indicativo_celular, celular, telefono, correo_personal, activo} = this.state;
        console.log(datos)
        tipo=datos.tipo;
        tipo_identificacion=datos.tipo_identificacion.id;
        identificacion=datos.identificacion;
        primer_nombre=datos.primer_nombre;
        segundo_nombre=datos.segundo_nombre;
        primer_apellido=datos.primer_apellido;
        segundo_apellido=datos.segundo_apellido;
        usuario=datos.usuario;
        login_directorio=datos.login_directorio;
        indicativo_celular=datos.indicativo_celular ? datos.indicativo_celular : null;
        celular=datos.celular ? datos.celular : null;
        telefono=datos.telefono ? datos.telefono : null;
        correo_personal=datos.correo_personal ? datos.correo_personal : null;
        activo=datos.activo;
        let data = { tipo, tipo_identificacion, identificacion, primer_nombre: primer_nombre.toUpperCase(), segundo_nombre: segundo_nombre.toUpperCase(), primer_apellido: primer_apellido.toUpperCase(), segundo_apellido: segundo_apellido.toUpperCase(), correo, usuario, login_directorio, indicativo_celular, celular, telefono, correo_personal, activo }
        consulta(`api/v1.0/portal/actualizardatopersona/${id}`, data, "patch",
            (error, estado, resp) => {
                let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
                if (!error) {
                    if (estado === 200) {
                        tipo = 'success';
                        titulo = resp.titulo;
                        mostrarModalActualizarCorreo(false);
                        mostrarModalGestionarestudiante(false);
                        this.reiniciarEstado();
                        this.cambiarPassword(datos.id,datos.identificacion);
                    } else {
                        tipo = 'info';
                        titulo = mostrarError(resp)
                    }
                }
                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
            }
        );
    }

    modificarEmpresa = (id, datos) => {
        let { actualizarMensaje, mostrarModalActualizarDatosEmpresa,mostrarModalGestionarEmpresa } = this.props;
        let { tipo, tipo_identificacion, identificacion, primer_nombre, segundo_nombre, primer_apellido, segundo_apellido, correo, usuario, login_directorio, indicativo_celular, celular, telefono, correo_personal, activo} = this.state;
        login_directorio=datos.login_directorio;
        activo=datos.activo;
        tipo = datos.tipo;
        segundo_nombre = datos.segundo_nombre;
        primer_apellido = datos.primer_apellido;
        segundo_apellido=datos.segundo_apellido;
        usuario=datos.usuario;
        indicativo_celular=datos.indicativo_celular ? datos.indicativo_celular : null;
        celular=datos.celular ? datos.celular : null;
        telefono=datos.telefono ? datos.telefono : null;
        correo_personal=datos.correo_personal ? datos.correo_personal : null;
        let data = { tipo, tipo_identificacion, identificacion, primer_nombre: primer_nombre.toUpperCase(), segundo_nombre: segundo_nombre.toUpperCase(), primer_apellido: primer_apellido.toUpperCase(), segundo_apellido: segundo_apellido.toUpperCase(), correo, usuario, login_directorio, indicativo_celular, celular, telefono, correo_personal, activo }
        consulta(`api/v1.0/portal/actualizardatopersona/${id}`, data, "patch",
            (error, estado, resp) => {
                let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
                if (!error) {
                    if (estado === 200) {
                        tipo = 'success';
                        titulo = resp.titulo;
                        mostrarModalActualizarDatosEmpresa(false);
                        mostrarModalGestionarEmpresa(false);
                        this.reiniciarEstado();
                        this.cambiarPassword(datos.id,datos.identificacion);
                    } else {
                        tipo = 'info';
                        titulo = mostrarError(resp)
                    }
                }
                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
            }
        );
    }

    cambiarPassword = (id,pass) => {
        let { actualizarMensaje } = this.props
        consulta(`api/v1.0/portal/${id}/cambiar_pass`, { pass }, "put",
          (error, estado, resp) => {
            let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
            if (!error) {
              if (estado === 200) {
                tipo = 'success';
                titulo = resp.titulo;
              } else {
                tipo = 'info';
                titulo = resp.titulo ? resp.titulo : mostrarError(resp);
              }
            }
            //actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
          }
        );
    }

    obtenerEstados = (id) => {
        let { mostrarModalDetalleEstadosGestion } = this.props
        this.setState({ idVacanteEstados: id })
        mostrarModalDetalleEstadosGestion(true)
    }

    cambiarEstado = (estados) => {
        this.setState(estados)
    }

    cambiarOpcion = (seleccion) => {
        this.cambiarEstado({ seleccion })
    }

    getVacantes = () => {
        return new Promise(resolve => {
            consulta("api/v1.0/portal/oferta_listar", null, null, (error, estado, resp) => {
                resolve(resp)
            })
        })
    }

    obtenerFiltrosGestion = () => {
        let { mostrarModalAddFiltros } = this.props
        mostrarModalAddFiltros(true)
    }

    filtrar = (filtros) => {
        this.setState({ cargando: true });
        let filtro = filtros[0].valor.value
        return new Promise(resolve => {
            consulta("api/v1.0/portal/filtros", { filtro }, "post", (error, estado, resp) => {
                this.setState({
                    vacantes: resp.map(i => i.oferta),
                    vacantes_estados_filtro: resp.map(i => i.oferta),
                    cargando: false
                })
            })
        })
    }

    filtrar_estados = (filtros) => {
        this.setState({ cargando: true });
        let filtro = filtros[0].valor.value
        return new Promise(resolve => {
            consulta("api/v1.0/portal/filtros_estado", { filtro }, "post", (error, estado, resp) => {
                this.setState({
                    vacantes_estados_filtro: resp.map(i => i),
                    cargando: false,
                    bandera: true,
                })
            })
        })
    }

    actualizarData = async () => {
        this.setState({ cargando: true });
        this.setState({
            vacantes: await this.getVacantes()
        })
        this.setState({ cargando: false });
    }

    getPqrs = () => {
        return new Promise(resolve => {
            consulta(`api/v1.0/portal/pqrs/listall`, null, null, (error, estado, resp) => {
                resolve(resp)
            })
        })
    }


    //------------------------------------- postulantes

    listarPostulantes = (row) => {
        let idVacantePostulantes = row.id
        let { mostrarModalListarPostulantes } = this.props
        this.setState({ idVacantePostulantes })
        // this.setState({ cargando2: true })
        // consulta(`api/v1.0/portal/postulantes_listar`, { id }, "post", (error, estado, resp) => {
        //     this.setState({
        //         postulantes: resp.filter(vac => vac.estado_proceso.codigo == "Por_Proc"),// Mejorar esto
        //         cargando2: false
        //     })
        // })
        mostrarModalListarPostulantes(true)
    }

    obtenerEstadoProceso = async (row) => {
        let { mostrarModalEstadoProceso } = this.props
        this.setState({
            postulante: row
        });
        mostrarModalEstadoProceso(true)
    }


    gestionarProceso = async (id, data, callback) => {
        let { mostrarModalEstadoProceso, actualizarMensaje, mostrarModalListarPostulantes } = this.props;
        let form = await crear_form_data(data);
        formulario(`api/v1.0/portal/${id}/gestionar_proceso`, form, "post", (error, estado, resp) => {
            let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
            if (!error) {
                if (estado === 200) {
                    tipo = 'success';
                    titulo = resp.titulo;
                    mostrarModalEstadoProceso(false);
                    mostrarModalListarPostulantes(false);
                } else {
                    tipo = 'info';
                    titulo = resp.titulo ? resp.titulo : mostrarError(resp);
                }
            }
            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 3000 });
            callback();
        })
    }

    listarInformacionPostulante = (row) => {
        let idDetallePostulante = row.postulante.id
        let { mostrarModalDetallePostulante } = this.props
        this.setState({ idDetallePostulante })
        // consulta("api/v1.0/portal/obtener_detalle_postulante", { id }, "post", (error, estado, resp) => {
        //     this.setState({
        //         detallePostulante: resp,
        //         cargando3: false
        //     })
        // })
        mostrarModalDetallePostulante(true)
    }

    vista = () => {
        let {
            seleccion,
            oferta,
            detalleVacante,
            // postulantes,
            // detallePostulante,
            postulante,
            listPqrs,
            pqrs,
            date_inicio,
            date_final,
            tipoEstadistica,
            modalDocumentosOferentes,
            archivos,
            cargando,
            data_estadistica,
            idVacantePostulantes,
            idDetallePostulante,
            idVacanteEstados
        } = this.state
        let {
            mostrarModalDetallePostulante,
            modalGestionOferta,
            mostrarModalGestionOferta,
            modalDetalleVacante,
            mostrarModalDetalleVacante,
            mostrarModalAddFiltros,
            modalAddFiltros,
            actualizarMensaje,
            mostrarModalListarPostulantes,
            modalListarPostulantes,
            modalEstadoProceso,
            modalDetallePostulante,
            modalEditPqrs,
            mostrarModalEditPqrs,
            modalDetallePqrs,
            mostrarModalDetallePqrs,
            mostrarModalListaEstadistica,
            modalListaEstadistica,
            mostrarModalEstadoProceso,
            mostrarModalDetalleEstadosGestion,
            modalDetalleEstadosGestion
        } = this.props


        switch (seleccion) {
            case 'vacantes':
                return (
                    <>
                        {!cargando ?
                            this.pintarVacantesActivar() :
                            <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
                        }
                        <PortalFiltros
                            {...{
                                mostrarModalAddFiltros,
                                modalAddFiltros,
                                actualizarMensaje
                            }}
                            filtrar={this.filtrar}
                            filtrar_estados={this.filtrar_estados}
                            limpiar={this.actualizarData}
                        />
                        <DetalleOferta
                            mostrarModalDetalleVacante={mostrarModalDetalleVacante}
                            modalDetalleVacante={modalDetalleVacante}
                            proceso={detalleVacante}
                        />
                        <ListarPostulantes
                            {...{
                                // postulantes,
                                mostrarModalListarPostulantes,
                                modalListarPostulantes
                            }}
                            obtenerEstadoProceso={this.obtenerEstadoProceso}
                            listarInformacionPostulante={this.listarInformacionPostulante}
                            idVacantePostulantes={idVacantePostulantes}
                        />
                        <DetallePostulante
                            {...{
                                mostrarModalDetallePostulante,
                                modalDetallePostulante,
                            }}
                            idDetallePostulante={idDetallePostulante}
                        />
                        <GestionarProceso
                            {...{
                                postulante,
                                mostrarModalEstadoProceso,
                                modalEstadoProceso
                            }}
                            gestionarProceso={this.gestionarProceso}
                        />
                        {
                            oferta.id ?
                                <GestionOferta
                                    modalGestionOferta={modalGestionOferta}
                                    mostrarModalGestionOferta={mostrarModalGestionOferta}
                                    oferta={oferta}
                                    gestionarOferta={this.gestionarOferta}
                                /> : <div></div>
                        }
                        <DetalleEstados
                            {...{
                                // estados,
                                mostrarModalDetalleEstadosGestion,
                                modalDetalleEstadosGestion,
                                idVacanteEstados
                            }}
                        />
                    </>
                )
            case 'personas':
                return (
                   <>
                    {   
                        this.pintarGestionPersona()
                    }
                   </>
                )
            case 'pqrs':
                return (
                    <>
                        {!cargando ?
                            <Pqrs
                                listPqrs={listPqrs}
                                mostrarModalEditPqrs={mostrarModalEditPqrs}
                                updateIdPqrs={idPqrs => this.setState({ idPqrs })}
                                abrirDetallePqrs={this.abrirDetallePqrs}
                            /> :
                            <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
                        }
                        <EditPqrs
                            modalEditPqrs={modalEditPqrs}
                            mostrarModalEditPqrs={mostrarModalEditPqrs}
                            enviarFormActual={this.editPqrs}
                            onChangeState={this.onChangeStatePqrs}
                        />
                        <DetallePqrs
                            pqrs={pqrs}
                            modalDetallePqrs={modalDetallePqrs}
                            mostrarModalDetallePqrs={mostrarModalDetallePqrs}
                        />
                    </>
                )
            case 'estadistica':
                return (
                    <>
                        <FormEstadistica
                            selectDate={this.selectDate}
                            final={date_final}
                            inicio={date_inicio}
                        />
                        {
                            this.accionesEstadistica()
                        }
                        <ModalListaEstadistica
                            modalListaEstadistica={modalListaEstadistica}
                            mostrarModalListaEstadistica={mostrarModalListaEstadistica}
                            tipo={tipoEstadistica}
                            data_estadistica={data_estadistica}
                        />
                        <DocumentosOferentes
                            modalDocumentosOferentes={modalDocumentosOferentes}
                            mostrarModalDocumentosOferentes={() => this.setState({ modalDocumentosOferentes: false })}
                            agregarArchivos={this.agregarArchivos}
                            actualizarMensaje={actualizarMensaje}
                            archivos={archivos}
                            eliminarArchivo={this.eliminarArchivo}
                            guardarArchivos={this.guardarArchivos}
                        />
                    </>

                )
            case 'CV':
                return (
                    <>
                        <BuscarCV />
                    </>
                )
            default:
                return (
                    <>
                    </>
                )
        }

    }


    agregarArchivos = files => {
        let { actualizarMensaje } = this.props
        let { archivos } = this.state
        files.map((archivo => {
            if (archivos.length === 10000) actualizarMensaje({ titulo: `Se ha superado el numero maximo de archivos`, tipo: 'info', mostrar: true, tiempo: 6000 })
            else archivos.push(archivo)
        }))
        this.setState({ archivos })
    }

    eliminarArchivo = (i) => {
        let { actualizarMensaje } = this.props;
        let { archivos } = this.state;
        archivos.splice(i, 1)
        this.setState({ archivos })
        actualizarMensaje({ titulo: `Archivo Retirado.`, tipo: 'success', mostrar: true, tiempo: 6000 })
    }

    guardarArchivos = () => {
        let { actualizarMensaje } = this.props
        let { archivos } = this.state
        const data = new FormData()
        archivos.map((archivo, index) => {
            data.append(`archivo${index}`, archivo)
        })
        data.append('total', archivos.length)
        formulario(`api/v1.0/portal/documentosorientacion`, data, "post", (error, estado, resp) => {
            let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
            if (!error) {
                if (estado === 200) {
                    tipo = 'success';
                    titulo = resp.titulo;
                    this.setState({
                        archivos: [],
                        modalDocumentosOferentes: false
                    })
                } else {
                    tipo = 'info'
                    titulo = resp.titulo
                }
            }
            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 3000 })
        })
    }

    //---------------------------
    // estadisticas


    selectDate = ({ target: { name, value } }) => {
        this.setState({
            [name]: value
        })
    }



    getEstadistica = (tipo) => {
        let { date_inicio, date_final } = this.state
        let { mostrarModalListaEstadistica } = this.props
        let data_estadistica =
        {
            tipo,
            date_inicio: moment(date_inicio).format('YYYY-MM-DD'),
            date_final: moment(date_final).format('YYYY-MM-DD')
        }
        this.setState({ tipoEstadistica: tipo, data_estadistica })
        mostrarModalListaEstadistica(true)
    }


    accionesEstadistica = () => {
        return (
            <div style={{ padding: '2% 2% 0% 2%' }} >
                <Grid container direction={'row'} spacing={1} justify={'center'} >
                    <Grid item xs={12} sm={12} md={10} lg={8} >
                        <TituloAccion principal='' alterno='¿ Que deseas hacer ?' />
                        <Paper elevation={0} square className="scroll contenido_fijo">
                            <Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
                                <Grid item xs={12} sm={12} md={6} lg={6} >
                                    <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.getEstadistica(1)}>
                                        <AccionesRapidas imagen={empresasIcon}
                                            titulo='Registro Estudiantes/Graduados !'
                                        />
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} >
                                    <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.getEstadistica(2)}>
                                        <AccionesRapidas imagen={fechaIcon}
                                            titulo={`Registro Empresas !`}
                                        />
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} >
                                    <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.getEstadistica(3)}>
                                        <AccionesRapidas imagen={sucursalesIcon}
                                            titulo='Registro Vacantes Laborales!'
                                        />
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} >
                                    <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.getEstadistica(4)}>
                                        <AccionesRapidas imagen={sucursalesIcon}
                                            titulo='Registro Vacantes Practicas!'
                                        />
                                    </Paper>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} >
                                    <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.getEstadistica(5)}>
                                        <AccionesRapidas imagen={permisosIcon}
                                            titulo='Número de Remisiones !'
                                        />
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} >
                                    <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.getEstadistica(6)}>
                                        <AccionesRapidas imagen={vistaIcon}
                                            titulo={`PQRS !`}
                                        />
                                    </Paper>
                                </Grid>

                                {/* campos nuevos */}
                                <Grid item xs={12} sm={12} md={6} lg={6} >
                                    <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.setState({ modalDocumentosOferentes: true })}>
                                        <AccionesRapidas imagen={sucursalesIcon}
                                            titulo='Registro Orientacion impartida a oferentes !'
                                        />
                                    </Paper>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} >
                                    <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.getEstadistica(6)}>
                                        <AccionesRapidas imagen={permisosIcon}
                                            titulo='Ver Orientacion impartida a oferentes !'
                                        />
                                    </Paper>
                                </Grid>
                                {/* fin campos nuevos */}


                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        )
    }

    abrirDetallePqrs = (pqrs) => {
        this.setState({ pqrs });
        this.props.mostrarModalDetallePqrs(true);
    }

    onChangeStatePqrs = ({ target }) => {
        let { name, value } = target
        this.setState({
            [name]: value
        })
    }

    editPqrs = () => {
        let { mostrarModalEditPqrs, actualizarMensaje } = this.props
        let { body_pqrs, idPqrs } = this.state;
        if (body_pqrs == '' || body_pqrs == null) return;

        let data = {
            body_pqrs,
            idPqrs
        }

        consulta(`api/v1.0/portal/pqrs/response`, data, "post", async (error, estado, resp) => {
            let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
            if (!error) {
                if (estado === 200) {
                    tipo = 'success';
                    titulo = resp.titulo;
                    mostrarModalEditPqrs(false);
                    this.setState({ cargando: true })
                    this.setState({
                        listPqrs: await this.getPqrs(),
                        cargando: false
                    })
                } else {
                    tipo = 'info'
                    titulo = resp.titulo
                }
            }
            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 3000 })
        })
    }

    abrir_modal_gestion_oferta = async (data) => {
        let { mostrarModalGestionOferta } = this.props
        this.setState({
            oferta: await data
        })
        mostrarModalGestionOferta(true)
    }


    abrir_modal_detalle_oferta = (data) => {
        let { mostrarModalDetalleVacante } = this.props
        this.setState({
            detalleVacante: data
        })
        mostrarModalDetalleVacante(true)
    }


    gestionarOferta = async (id, data, callback) => {
        let { mostrarModalGestionOferta, actualizarMensaje } = this.props;
        let form = await crear_form_data(data);
        formulario(`api/v1.0/portal/${id}/gestionar`, form, "post", async (error, estado, resp) => {
            let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
            if (!error) {
                if (estado === 200) {
                    tipo = 'success';
                    titulo = resp.titulo;
                    mostrarModalGestionOferta(false);
                    this.setState({ cargando: true })
                    this.setState({
                        vacantes: await this.getVacantes(),
                        cargando: false
                    });
                } else {
                    tipo = 'info';
                    titulo = resp.titulo ? resp.titulo : mostrarError(resp);
                }
            }
            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 3000 });
            callback();
        })
    }


    pintarVacantesActivar = () => {
        let { vacantes,bandera,vacantes_estados_filtro } = this.state
        return (
            <div >
                <VacanteGestion
                    abrir_modal_gestion_oferta={this.abrir_modal_gestion_oferta}
                    abrir_modal_detalle_oferta={this.abrir_modal_detalle_oferta}
                    vacantes={bandera ? vacantes_estados_filtro: vacantes.filter(vacante => vacante.estado_solicitud.codigo != "Por_Fin" && vacante.estado_solicitud.codigo != "Por_Rec")}
                    obtenerFiltrosGestion={this.obtenerFiltrosGestion}
                    actualizarData={this.actualizarData}
                    listarPostulantes={this.listarPostulantes}
                    obtenerEstados={this.obtenerEstados}
                />
            </div>
        )
    }

    pintarGestionPersona() {
        let {mostrarModalGestionarestudiante,mostrarModalGestionarEmpresa} = this.props
        return (
            <>
                <Grid container direction={'row'} justifyContent={'center'} style={{ paddingTop: 40 }}>
                    <Grid item xs={12} sm={6} md={4} lg={4} style={{ padding: '2% 2% 0% 2%' }} onClick={() => { mostrarModalGestionarestudiante(true) }}>
                        <Paper elevation={8} square>
                            <Card style={{ maxWidth: "100%", height: '380px', cursor: 'pointer' }} elevation={1}>
                                <CardHeader
                                    avatar={<Avatar aria-label="recipe" style={{ backgroundColor: red[500] }}>G</Avatar>}
                                    title={`Gestion Estudiante/Graduado `}
                                />
                                <CardContent >
                                    <Grid container direction={'row'} justifyContent={'center'}>
                                        <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center', height: "150px" }}>
                                            <img src={solicitudGrado} style={{ width: '150px', maxWidth: '200px', minWidth: '100px', height: "150px" }} alt='Imagen Tipo' />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} style={{ padding: "15px 10px 0px 10px", }}>
                                            <Typography variant="body2" color="textSecondary" component="p" align='justify'>
                                                Para habilitar un nuevo estudiante Dar click aqui!
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                </CardContent>
                            </Card>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={4} style={{ padding: '2% 2% 0% 2%' }} onClick={() => { mostrarModalGestionarEmpresa(true) }}>
                        <Paper elevation={8} square>
                            <Card style={{ maxWidth: "100%", height: '380px', cursor: 'pointer' }} elevation={1}>
                                <CardHeader
                                    avatar={<Avatar aria-label="recipe" style={{ backgroundColor: red[500] }}>G</Avatar>}
                                    title={`Gestion Empresa `}
                                />
                                <CardContent >
                                    <Grid container direction={'row'} justifyContent={'center'}>
                                        <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center', height: "150px" }}>
                                            <img src={ingenieros} style={{ width: '150px', maxWidth: '200px', minWidth: '100px', height: "150px" }} alt='Imagen Tipo' />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} style={{ padding: "15px 10px 0px 10px", }}>
                                            <Typography variant="body2" color="textSecondary" component="p" align='justify'>
                                                Para habilitar una nueva Empresa Dar click aqui!
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                </CardContent>
                            </Card>
                        </Paper>
                    </Grid>
                </Grid>
            </>
        );
    }

    async obtenerDatosestudiante(dato) {
        let f=dato
        return new Promise(resolve => {
           consulta(`api/v1.0/portal/obtenerdatosestudiante`, { dato }, "post", (error, estado, resp) => {
            resolve(estado === 200 && !error ? resp : null);
          })
        })
      }

      onChange = ({ target }) => {
        let { value, name } = target;
        this.setState({
          [name]: value
        })
      }

      onChangeIdentificacion = async (e) => {// evento que escucha los cambios del formulario y asigna el valor al estado de la variable
        this.setState({cargando:true})
        let identificacion = e.currentTarget.dataset
        this.onChange(e)
        let datosestudiantes= await this.obtenerDatosestudiante(identificacion);
        const result = Object.keys(datosestudiantes).map((key) => datosestudiantes[key]);
        this.setState({
          "datosestudiantes":result,
          
          cargando:false
        }) 
    
        
      }

    handleStep = async (direction) => {//Con esta funcion Valido si se llenaron los campos
        let { activeStep,identificacion} = this.state
        let sw = true
        let {actualizarMensaje} =this.props
        if(direction){
          if(!identificacion && activeStep === 0){
            actualizarMensaje({titulo: 'Debe diligenciar todos los campos para continuar', tipo: 'info', mostrar: true, tiempo:6000});
            sw=false
          }else{
            this.setState({cargando:true});
            let datosestudiantes = await this.obtenerDatosestudiante(identificacion);
            const result = Object.keys(datosestudiantes).map((key) => datosestudiantes[key]);
            this.setState({
            datosestudiantes:result,
            
            cargando:false
            }) 
            if(datosestudiantes[0]===undefined){
              actualizarMensaje({ titulo: 'Identificacion no se encuentra registrada en emma', tipo: 'info', mostrar: true, tiempo: 6000 });
              sw = false
            }else{
              this.setState({ activeStep: direction ? activeStep + 1 : activeStep - 1 })
            }
           
          }
           
          
        }
        if(sw) this.setState({ activeStep: direction ? activeStep + 1 : activeStep - 1 })
      }


    configStepContent = () => {
        let {identificacion, activeStep,datosestudiantes} = this.state
        switch(activeStep){
          case 0:
            return (
              <ConsultarEstudiante
              {...{
                identificacion,
                onChange: this.onChange,
                onChangeIdentificacion:this.onChangeIdentificacion,
              }}
              />
            )
            case 1:
              return(
                <DatosEstudiante
                {...{
                  datosestudiantes,
                  procesos:this.procesos
                }}/>
              )
            
            default:
              return <TareasTerminadas imagen={emma_w} mensaje='Est bandeja esta  vacía' widthImg='10%' marginTop='10%'/>
        }
      }

      configStepContentEmpresa = () => {
        let {identificacion, activeStep,datosestudiantes} = this.state
        switch(activeStep){
          case 0:
            return (
              <ConsultarEstudiante
              {...{
                identificacion,
                onChange: this.onChange,
                onChangeIdentificacion:this.onChangeIdentificacion,
              }}
              />
            )
            case 1:
              return(
                <DatosEmpresa
                {...{
                  datosestudiantes,
                  procesosEmpresa:this.procesosEmpresa
                }}/>
              )
            
            default:
              return <TareasTerminadas imagen={emma_w} mensaje='Est bandeja esta  vacía' widthImg='10%' marginTop='10%'/>
        }
      }

    pintarMenuItem = (lista) => {
        return Array.isArray(lista) ? lista.map(({ id, nombre }) => <MenuItem key={id} value={id}>{nombre}</MenuItem>) : [];
    }

    render() {
        let { seleccion, doc_informativos,activeStep,cargando,correo,dataPersonaEmma,tipo_identificacion,identificacion,tipos_persona,tipo,primer_nombre,segundo_nombre,primer_apellido,segundo_apellido,usuario,indicativo_celular,celular,telefono,correo_personal,tipos_identificacion} = this.state
        let {mostrarModalGestionarestudiante,modalGestionarEstudiante,modalGestionarEmpresa,mostrarModalGestionarEmpresa,mostrarModalActualizarCorreo,modalMostrarCorreo,modalMostrarDatosEmpresa,mostrarModalActualizarDatosEmpresa} = this.props
        return (
            <div>
                <MenuAdm sele={seleccion} seleccionar={(seleccion) => this.cambiarOpcion(seleccion)} doc_informativos={doc_informativos} />
                <div style={{
                    position: 'fixed',
                    top: '80px',
                    left: '120px',
                    zIndex: '1000'
                }} >
                    <img src={mintrabajo} className="logoMT" />
                </div>
                <div className='contenido_modulo' style={{'marginTop' : '70px'}}>
                        {this.vista()}
                </div>
                <ImgPortalPiel/>
                <div> 
                    <Dialog fullWidth={true} maxWidth="sm" open={modalGestionarEstudiante} onClose={() => mostrarModalGestionarestudiante(false)}>
                        <AppBarModal titulo={"¡Gestionar Estudiante/Graduado!"} mostrarModal={() => mostrarModalGestionarestudiante(false)} titulo_accion="Cerrar" accion={() => mostrarModalGestionarestudiante(false)} />
                        <DialogContent className='scroll'>
                            {!cargando ?
                                <Grid container>
                                    <Grid item xs={12} md={12} >
                                        <Stepper activeStep={activeStep}>
                                            {
                                                configStep.map(label => (
                                                    <Step key={label}>
                                                        <StepLabel><p>{label}</p></StepLabel>
                                                    </Step>
                                                ))
                                            }
                                        </Stepper>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        {this.configStepContent()}
                                    </Grid>
                                </Grid>
                                : <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
                            }
                        </DialogContent>
                        <DialogActions>
                            {activeStep > 0 ?
                                <div><BtnFormPage texto='ATRAS' callback={() => this.handleStep(false)} />
                                </div>
                                : <BtnFormPage texto='SIGUIENTE' callback={() => this.handleStep(true)} />
                            }
                        </DialogActions>

                    </Dialog >
                </div>
                
                <div> 
                    <Dialog fullWidth={true} maxWidth="sm" open={modalMostrarCorreo} onClose={() => mostrarModalActualizarCorreo(false)}>
                        <AppBarModal titulo={"¡Actualizar correo estudiante !"} mostrarModal={() => mostrarModalActualizarCorreo(false)} titulo_accion="Cerrar" accion={() => mostrarModalActualizarCorreo(false)} />
                        <DialogContent className='scroll'>
                            {!cargando ?
                                <Grid container>
                                    <Grid item xs={12}>
                                        <TextField
                                        value={correo}
                                        required
                                        type="text"
                                        id="correo"
                                        name="correo"
                                        label="Correo"
                                        fullWidth
                                        onChange={this.onChange}
                                        />
                                    </Grid>
                                </Grid>
                                : <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
                            }
                        </DialogContent>
                        <DialogActions>
                            <BtnFormPage texto='GUARDAR' callback={() =>{this.modificarPersona(dataPersonaEmma.id,dataPersonaEmma)}} type="submit" />
                        </DialogActions>

                    </Dialog >
                </div>

                <div> 
                    <Dialog fullWidth={true} maxWidth="sm" open={modalGestionarEmpresa} onClose={() => mostrarModalGestionarEmpresa(false)}>
                        <AppBarModal titulo={"¡Gestionar Empresa!"} mostrarModal={() => mostrarModalGestionarEmpresa(false)} titulo_accion="Cerrar" accion={() => mostrarModalGestionarEmpresa(false)} />
                        <DialogContent className='scroll'>
                            {!cargando ?
                                <Grid container>
                                    <Grid item xs={12} md={12} >
                                        <Stepper activeStep={activeStep}>
                                            {
                                                configStepEmpresa.map(label => (
                                                    <Step key={label}>
                                                        <StepLabel><p>{label}</p></StepLabel>
                                                    </Step>
                                                ))
                                            }
                                        </Stepper>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        {this.configStepContentEmpresa()}
                                    </Grid>
                                </Grid>
                                : <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
                            }
                        </DialogContent>
                        <DialogActions>
                            {activeStep > 0 ?
                                <div><BtnFormPage texto='ATRAS' callback={() => this.handleStep(false)} />
                                </div>
                                : <BtnFormPage texto='SIGUIENTE' callback={() => this.handleStep(true)} />
                            }
                        </DialogActions>

                    </Dialog >
                </div>

                <div>
                    <Dialog fullWidth={true} maxWidth="sm" open={modalMostrarDatosEmpresa} onClose={() => mostrarModalActualizarDatosEmpresa(false)}>
                        <AppBarModal titulo={"¡Actualizar datos Empresa !"} mostrarModal={() => mostrarModalActualizarDatosEmpresa(false)} titulo_accion="Cerrar" accion={() => mostrarModalActualizarDatosEmpresa(false)} />
                        <DialogContent className='scroll'>
                            {!cargando ?
                                <Grid container spacing={1} style={{ marginTop: 15 }}>
                                    <Grid item xs={6}>
                                        <FormControl className='form-control'>
                                            <InputLabel htmlFor="select-tipo-identificacion">Tipo Identificación</InputLabel>
                                            <Select
                                                value={tipo_identificacion}
                                                onChange={this.onChange}
                                                id='select-tipo-identificacion'
                                                name='tipo_identificacion'
                                            >
                                                {this.pintarMenuItem(tipos_identificacion)}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <TextField
                                            variant="standard"
                                            margin="dense"
                                            id="identificacion"
                                            label="Identificación"
                                            type="number"
                                            fullWidth
                                            name="identificacion"
                                            value={identificacion}
                                            validators={["required", 'minNumber:0']}
                                            errorMessages={["El campo es requerido", "El campo no puede ser menor a 0"]}
                                            onChange={this.onChange}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <TextField
                                            variant="standard"
                                            margin="dense"
                                            id="primer_nombre"
                                            label="Primer Nombre"
                                            type="text"
                                            fullWidth
                                            name="primer_nombre"
                                            value={primer_nombre}
                                            validators={["required"]}
                                            errorMessages={["El campo es requerido"]}
                                            onChange={this.onChange}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <TextField
                                            variant="standard"
                                            margin="dense"
                                            id="correo"
                                            label="Correo"
                                            type="text"
                                            fullWidth
                                            name="correo"
                                            value={correo}
                                            validators={['required', 'isEmail']}
                                            errorMessages={["El campo es requerido", "El correo no es valido"]}
                                            onChange={this.onChange}
                                        />
                                    </Grid>
                                </Grid>
                                : <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
                            }
                        </DialogContent>
                        <DialogActions>
                            <BtnFormPage texto='GUARDAR' callback={() => { this.modificarEmpresa(dataPersonaEmma.id, dataPersonaEmma) }} type="submit" />
                        </DialogActions>

                    </Dialog >
                </div>
                
            </div>
        );
    }
}

const mapStateToProps = state => {
    let {
        modalDetallePostulante,
        modalEstadoProceso,
        modalGestionOferta,
        modalDetalleVacante,
        modalAddFiltros,
        modalListarPostulantes,
        modalEditPqrs,
        modalDetallePqrs,
        modalListaEstadistica,
        modalGestionarEstudiante,
        modalGestionarEmpresa,
        modalMostrarCorreo,
        modalMostrarDatosEmpresa,
        modalDetalleEstadosGestion
    } = state.redPortal
    return {
        modalGestionOferta,
        modalDetalleVacante,
        modalAddFiltros,
        modalListarPostulantes,
        modalEstadoProceso,
        modalDetallePostulante,
        modalEditPqrs,
        modalDetallePqrs,
        modalListaEstadistica,
        modalGestionarEstudiante,
        modalGestionarEmpresa,
        modalMostrarCorreo,
        modalMostrarDatosEmpresa,
        modalDetalleEstadosGestion
    }
}

const mapDispatchToProps = {
    mostrarModulo,
    actualizarMensaje,
    mostrarModalGestionOferta,
    mostrarModalDetalleVacante,
    mostrarModalAddFiltros,
    mostrarModalListarPostulantes,
    mostrarModalEstadoProceso,
    mostrarModalDetallePostulante,
    mostrarModalEditPqrs,
    mostrarModalDetallePqrs,
    mostrarModalListaEstadistica,
    mostrarModalGestionarestudiante,
    mostrarModalGestionarEmpresa,
    mostrarModalActualizarCorreo,
    mostrarModalActualizarDatosEmpresa,
    mostrarModalDetalleEstadosGestion
}

PortalGestion.propTypes = {
    mostrarModulo: PropTypes.func.isRequired,
    modalGestionOferta: PropTypes.bool.isRequired,
    mostrarModalGestionOferta: PropTypes.func.isRequired,
    mostrarModalDetalleVacante: PropTypes.func.isRequired,
    modalDetalleVacante: PropTypes.bool.isRequired,
    modalAddFiltros: PropTypes.bool.isRequired,
    actualizarMensaje: PropTypes.func.isRequired,
    mostrarModalGestionarestudiante:PropTypes.func.isRequired,
    mostrarModalGestionarEmpresa: PropTypes.func.isRequired,
    mostrarModalActualizarCorreo: PropTypes.func.isRequired,
    mostrarModalActualizarDatosEmpresa: PropTypes.func.isRequired,
    mostrarModalDetalleEstadosGestion: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(PortalGestion);