import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardHeader, CardContent, Avatar, Grid, Typography, Tooltip, IconButton, Stepper, Step, StepLabel, CardActions } from '@material-ui/core/';
import { red,purple, orange, grey } from '@material-ui/core/colors';
import CheckIcon from '@material-ui/icons/Check';
import { consulta,obtenerValoresFiltros} from "../../../global/js/funciones";
import { SISEF_ESTADOS } from "./../helper"

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: "100%",
    minHeight: "50px",
    height: "300px",
  },
  image: {
    width: '50px',
    height: "50px",
  },
  content_text: {
    padding: "0px 0px 0px 10px",
  },
  content_img: {
    textAlign: 'center',
    height: "100px",
  },
  hover_delete_icon: {
		'&:hover': {
			color: red[800]
		}
	},
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  root_avatar: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  emma: {
    color: "#fffff",
    backgroundColor: "#c79e32",
    fontWeight: '500',
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: 13
  },
  gris: {
    color: "#fffff",
    backgroundColor: grey[500],
    fontWeight: '500',
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: 13
  },
}));


export default function SisefCardSolicitud({id, procesos, nombre, descripcion, imagen, activeStep, estados_syll, estados_genericas, codigo_asignatura}) {
  
  const classes = useStyles();
  const isInitialMount = React.useRef(true);
  const [estado, setEstado] = React.useState([]);

  React.useEffect( () => {
    const fetchData = async () => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
        await obtenerProcesos(id);
      }
    }
    fetchData();
  })

  const obtenerProcesos=async(id)=>{
    let data = await obtenerEstados(id);
    let estados = []
    estados = estados_genericas.sort((a, b) => {
      if(a.id < b.id) {
        return -1;
      }
      if(a.id > b.id) {
        return 1;
      }
    })
    estados.forEach((est, ind) => {
      let sw=0;
      data.forEach(dat=>{
        if((est.codigo == dat.tipo_estado.codigo) && sw==0){
          sw = 1
        }
      })
      est.tema= (sw==1) ? classes.emma: classes.gris;
      est.num= (sw==1) ? <CheckIcon/> : ind+1;
    })
    setEstado(estados)
  }

  const obtenerEstados = async(id)=> {
    return new Promise(resolve => {
      consulta(`api/v1.0/sisef/listar_estados/${id}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={<Avatar aria-label="recipe" style={{ backgroundColor: orange[800] }}> {nombre.charAt(0).toUpperCase()} </Avatar>}
        title={nombre}
        subheader={<React.Fragment> {codigo_asignatura} <br />  {procesos} </React.Fragment>}
        
      />
      <CardContent>
        <Grid container direction={'row'} justify={'center'}>
          <Grid item xs={12} sm={12} md={12} className={classes.content_img}>
            <img src={imagen} className={classes.image} alt='Imagen Tipo' />
            <Typography variant="body2" color="textSecondary" component="p" align='justify'>
              {descripcion}
            </Typography>
          </Grid> 
        </Grid>
      </CardContent>
      <CardActions>
      <Grid container direction={'row'} justify={'center'} className={classes.root_avatar}>
        {estado.map((item, i) => (
          <Tooltip disableFocusListener disableTouchListener title={item.nombre}>
          <Avatar alt="Remy Sharp" className={item.tema}>{item.num}</Avatar>
          </Tooltip>
        ))}
        </Grid>
      </CardActions>
    </Card>
  );
}