import React, { Component } from 'react';
//COMPONENTS
import { Dialog, DialogContent, DialogActions,CardContent, Typography, CardMedia, Box, Grid } from '@material-ui/core';
import AppBarModal from '../../general/AppBarModal';
import { BtnForm } from '../../general/BotonesAccion';
import {api} from '../../../global/js/funciones';


class DescriptionEstilo extends Component {
  
  constructor(props) {
    super(props)
    this.state = {
      
    }
    
  }
render(){
  let {status, toggle, data, img}= this.props
  return (
    <Dialog open={status} onClose={() => toggle({ modal: 'dialogDescripcion', value: false })} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="md">
      <AppBarModal titulo="Descripcion" mostrarModal={() => toggle({ modal: 'dialogDescripcion', value: false })} titulo_accion="" accion="" />
      <DialogContent style={{ padding: 0 }} className='scroll'>
        <Grid container style={{ marginTop: '5%' }}>
          <Grid item md={4} xs={12} style={{ paddingLeft: '2%' }}>
            <CardMedia component="img" md={{ width: 51 }} image={api+img} alt="Live from space album cover"/>
          </Grid>
          <Grid item md={8} xs={12}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flex: '1 0 auto' }}>
              <Typography variant="subtitle1" component="div" style={{textAlign:'justify'}}>
                {data}
              </Typography>
            </CardContent>
          </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <BtnForm texto="Cerrar" callback={() => toggle({ modal: 'dialogDescripcion', value: false })} />
      </DialogActions>
    </Dialog>
  )
}
}

export default DescriptionEstilo
