import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DialogActions, Dialog } from '@material-ui/core';

import AppBarModal from '../../general/AppBarModal'
import { BtnForm } from "../../general/BotonesAccion";
import moment from "moment";
import ListarDatos from "../../general/ListarDatos";
import AgregarPlan from "./AgregarPlan";
import { consulta } from "../../../global/js/funciones";
import { BtnEliminar } from "../../general/BotonesAccion";


class PintarPlanAcompanamento extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cargando: true,
            titulo_res: 'Cargando..',
            motivo: '',
            archivo: null,
            seleccion: 'gestion',
            adj_archivo: '',
        }
        this.fileInput = React.createRef();
    }



    reiniciarEstado() {
        this.setState({
        cargando: true,
        titulo_res: 'Cargando..',
        motivo: '',
        archivo: null,
        seleccion: 'gestion',
        })
    }

    // actualizarDatos = () => {
    //     let { mostrarPlanAcompanamiento } = this.props;
    //     mostrarPlanAcompanamiento(false);
    //     // mostrarPlanAcompanamiento(true);
    // }

    eliminarPlan = (id, id_estudiante) => {
        let { actualizarMensaje, obtenerPlanAcompanamiento, inscripcion } = this.props;
        let data = {id, id_estudiante};
        let tipo = "";
        let titulo = "";
        return new Promise((resolve) => {
            consulta(`api/v1.0/inscripcion/eliminarPlan`, data, "put", (error, estado, resp) => {
                resolve(resp);
                if (!error && estado === 200) {
                  tipo = "success";
                  titulo = resp.titulo;
                  obtenerPlanAcompanamiento(inscripcion.estudiante.id);
                } else {
                  tipo = "info";
                  titulo = resp.titulo || "Ha ocurrido un error";
                }
                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
            });
        });
    }


    onSubmit = e => {
        e.preventDefault();
    }

    render() {
        let { mostrarPlanAcompanamiento, modalMostrarPlan, mostrarModalPlan, actualizarMensaje, modalAgregarPlan, estudiantes_plan, obtenerPlanAcompanamiento, inscripcion } =
          this.props;
        let { cargando } = this.state;

        const acciones = (data) => {
            const onClickDetalle = data => {
                let { estudiante: { id: id_estudiante }, id } = data;
                this.eliminarPlan(id, id_estudiante);
            }

            let eliminar = <BtnEliminar callback={() => onClickDetalle(data)} color='#01579b' texto='Eliminar' />;
            return <div>{eliminar}</div>;
        }

        return (
            <Dialog fullWidth={true} maxWidth="sm" open={modalMostrarPlan} onClose={mostrarPlanAcompanamiento.bind(this, false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <AppBarModal titulo={'Plan de Acompañamiento'} mostrarModal={mostrarPlanAcompanamiento} titulo_accion="" accion="" />
            {/* <MenuGestionar seleccionar={(seleccion) => this.setState({ seleccion })} sele={seleccion} inscripcion={inscripcion}/> */}
            <ListarDatos
                id="tbl_plan_acompanamiento"
                titulo='Plan de Acompañamiento'
                datos={estudiantes_plan}
                opciones={true}
                buscar={false}
                acciones={(data) => acciones(data)}
                agregar={true}
                actAgregar={() => mostrarModalPlan(true)}
                fila_principal={({ plan }) => plan.nombre.toUpperCase()}
                filas={[
                    {
                    'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a'),
                    'id': 'fecha_registro',
                    'enLista': true
                    },
                    { 'id': 'usuario_registra', 'enLista': false }
                ]}
            />
            <AgregarPlan inscripcion={inscripcion} modalAdd={modalAgregarPlan} mostrarModalAdd={mostrarModalPlan} actualizarMensaje={actualizarMensaje} obtenerPlanAcompanamiento={obtenerPlanAcompanamiento} />
            <DialogActions>
                <BtnForm texto="CANCELAR" callback={() => mostrarPlanAcompanamiento(false)} />
                {!cargando && <BtnForm texto="ACEPTAR" callback={this.onSubmit} />}
            </DialogActions>

            </Dialog>
        );
    }
}

PintarPlanAcompanamento.propTypes = {
  modalMostrarPlan: PropTypes.func.isRequired,
};

export default PintarPlanAcompanamento;
