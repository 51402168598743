// HELPERS PRODUCCIÓN

export const EVIDENCIA = 229
export const ESTADOS = 228
export const EVALUACION = 239
export const TIPO_INFORME_RETOS = 247

// HELPERS DESARROLLO

// export const EVIDENCIA = 48
// export const ESTADOS = 50
// export const EVALUACION = 1051
// export const TIPO_INFORME_RETOS = 2051

// HELPERS SERVIDOR DE PRUEBA

// export const EVIDENCIA =  4133
// export const ESTADOS = 4131
