import React, { Component } from 'react'
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import { ValidatorForm } from "react-material-ui-form-validator";

import AppBarModal from '../../general/AppBarModal'

import { MenuProps } from '../../../global/js/funciones'
import { BtnForm } from "../../general/BotonesAccion";

class VotantesAgregar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      votantes: [],
      tipoVotantes: [],
    }
  }

  componentDidMount() {
    this.cargarTipos();
  }

  componentDidUpdate({ modalAddVotantes }) {
    let { modalAddVotantes: modalAddNew } = this.props;
    if (modalAddNew !== modalAddVotantes) {
      this.changeState();
    }
  }

  changeState = () => {
    this.setState({
      votantes: [],
    })
  }
  onSubmit = e => {
    let { votantes } = this.state;
    this.props.guardarVotante(votantes);
    e.preventDefault();
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }


  setRol = ({ target }) => {
    this.setState({
      votantes: target.value
    })
  }

  cargarTipos = async () => {
    const tipoVotantes = await this.props.obtenerValores(1);
    this.setState({
      tipoVotantes,
    })
  }

  pintarTiposVacantes = () => {
    let { votantes, tipoVotantes } = this.state;
    if (Array.isArray(tipoVotantes)) {
      return tipoVotantes.map(({ nombre, id }) => (
        <MenuItem key={id} value={id}>
          <Checkbox checked={votantes.indexOf(id) > -1} />
          <ListItemText primary={nombre} />
        </MenuItem>
      ))
    }
    return [];
  }

  pintarNombresVacantes = (selected) => {
    let resp = [];
    let { tipoVotantes } = this.state;
    selected.map((element) => {
      tipoVotantes.map((e) => {
        if (element === e.id) return resp.push(e.nombre)
        return null;
      })
      return null;
    });
    return resp.join(", ")
  }

  render() {
    let { votantes } = this.state;
    let { modalAddVotantes, mostrarModalAddVotantes } = this.props;

    return (
      <Dialog fullWidth={true} maxWidth='xs' open={modalAddVotantes} onClose={mostrarModalAddVotantes.bind(this, false)} aria-labelledby="form-dialog-title">
        <AppBarModal titulo="Nuevo Votante" mostrarModal={mostrarModalAddVotantes} titulo_accion="" accion="" />
        <DialogContent className='scroll'>
          <ValidatorForm onSubmit={this.onSubmit}>
            <FormControl variant="outlined" className='form-control' >
              <InputLabel htmlFor="select-multiple-checkbox">Votantes</InputLabel>
              <Select
                multiple
                value={votantes}
                input={<OutlinedInput labelWidth={70} name="select-multiple-checkbox" id="select-multiple-checkbox" />}
                onChange={this.setRol}
                renderValue={selected => {
                  return this.pintarNombresVacantes(selected)
                }}
                MenuProps={MenuProps}
              >
                {this.pintarTiposVacantes()}
              </Select>
            </FormControl>
            <Button color="primary" type="submit" id='enviar_form_add_votantes' className="oculto"> Guardar </Button>
          </ValidatorForm>
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CANCELAR" callback={() => mostrarModalAddVotantes(false)} />
          <BtnForm texto="GUARDAR" callback={() => { document.getElementById('enviar_form_add_votantes').click() }} />
        </DialogActions>
      </Dialog>
    )
  }
}

//Props del componente
VotantesAgregar.propTypes = {
  //variables
  modalAddVotantes: PropTypes.bool.isRequired,
  //funciones
  mostrarModalAddVotantes: PropTypes.func.isRequired,
  guardarVotante: PropTypes.func.isRequired,
}


export default VotantesAgregar;

