import {
  MODAL_ADD_ELECCION,
  MODAL_MOD_ELECCION,
  MODAL_CAN_ELECCION,
  MODAL_FIN_ELECCION,
  MODAL_PROL_ELECCION,
  MODAL_ADM_CANDIDATOS,
  ACT_DATA_ELECCION,
  ACT_DATA_ELECCIONES,
  ACT_RENDER_ELECCION,
  MODAL_LINK_ELECCION,
  MODAL_VOTAR_CANDIDATOS,
  MODAL_FILTRAR_ELLECIONES,
} from "../actions/actElecciones";

const initialState = {
  modalAddEleccion: false,
  modalModEleccion: false,
  modalCanEleccion: false,
  modalFinEleccion: false,
  modalProlEleccion: false,
  modalLinkEleccion: false,
  modalAdmCandidatos: false,
  modalVotarCandidatos: false,
  modalFiltrarElecciones: false,
  eleccion: {
    id: 0,
    nombre: "",
    fecha_inicio: "",
    fecha_fin: "",
  },
  elecciones: [],
  renderElecciones: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MODAL_ADD_ELECCION:
      return Object.assign({}, state, {
        modalAddEleccion: action.show
      })
    case MODAL_MOD_ELECCION:
      return Object.assign({}, state, {
        modalModEleccion: action.show
      })
    case MODAL_CAN_ELECCION:
      return Object.assign({}, state, {
        modalCanEleccion: action.show
      })
    case MODAL_FIN_ELECCION:
      return Object.assign({}, state, {
        modalFinEleccion: action.show
      })
    case MODAL_PROL_ELECCION:
      return Object.assign({}, state, {
        modalProlEleccion: action.show
      })
    case MODAL_ADM_CANDIDATOS:
      return Object.assign({}, state, {
        modalAdmCandidatos: action.show
      })
    case ACT_DATA_ELECCION:
      return Object.assign({}, state, {
        eleccion: action.eleccion
      })
    case ACT_DATA_ELECCIONES:
      return Object.assign({}, state, {
        elecciones: action.elecciones
      })
    case ACT_RENDER_ELECCION:
      return Object.assign({}, state, {
        renderElecciones: action.renderElecciones
      })
    case MODAL_LINK_ELECCION:
      return Object.assign({}, state, {
        modalLinkEleccion: action.show
      })
    case MODAL_VOTAR_CANDIDATOS:
      return Object.assign({}, state, {
        modalVotarCandidatos: action.show
      })
    case MODAL_FILTRAR_ELLECIONES:
      return Object.assign({}, state, {
        modalFiltrarElecciones: action.show
      })
    default:
      return state;
  }
}

export default reducer;
