import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormularioDinamico from './FormularioDinamico';

import AppBarModal from '../../general/AppBarModal';
import { BtnForm } from '../../general/BotonesAccion';
import { useEffect } from 'react';
import { getFormValues } from './helper';

const AgregarParametro = ({
	modalAdd,
	mostrarModalAdd,
	parametro,
	cambiarEstado,
	seleccion,
	programa,
	cargarPermisos
}) => {
	const [ values, setValues ] = useState([]);

	useEffect(
		() => {
			const formInputs = getFormValues(parametro);
			setValues(formInputs);
		},
		[ parametro ]
	);

	return (
		<Dialog
			open={modalAdd}
			onClose={() => mostrarModalAdd(false)}
			aria-labelledby='form-dialog-title'
			fullWidth={true}
			maxWidth='xs'
		>
			<AppBarModal titulo='Nuevo Dato' mostrarModal={mostrarModalAdd} titulo_accion='' accion='' />
			<DialogContent className='scroll'>
				<FormularioDinamico
					fields={values}
					parametro={parametro}
					cambiarEstado={cambiarEstado}
					seleccion={seleccion}
					programa={programa}
					cargarPermisos={cargarPermisos}
				/>
			</DialogContent>
			<DialogActions>
				<BtnForm texto='CANCELAR' callback={() => mostrarModalAdd(false)} />
				<BtnForm
					texto='GUARDAR'
					callback={() => {
						document.getElementById('enviar_form_add_parametro').click();
					}}
				/>
			</DialogActions>
		</Dialog>
	);
};

//Props del componente
AgregarParametro.propTypes = {
	//variables
	modalAdd: PropTypes.bool.isRequired,
	parametro: PropTypes.number,
	seleccion: PropTypes.string,
	//funciones
	mostrarModalAdd: PropTypes.func.isRequired,
	cambiarEstado: PropTypes.func.isRequired,
	cargarPermisos: PropTypes.func
};

export default AgregarParametro;
