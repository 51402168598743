import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
    Avatar,
    ButtonBase,
    Card,
    CardHeader,
    CardMedia,
    CardContent,
    Typography,
 } from '@material-ui/core/';
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 250,
  },
  media: {
    width: 200,
    height: 200,
    objectFit: 'cover',
    objectPosition: 'center center',
    paddingTop: '56.25%', // 16:9
    margin: 30
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function OpcionTipoSolicitud({ 
    accion, 
    handleClick, 
    img="https://picsum.photos/200/300", 
    mensaje,
    titulo,
    modalName
}) {
  const classes = useStyles();

  return (
    <ButtonBase
        onClick={e=>handleClick(e)}
        name={modalName}
    >
        <Card className={classes.root}>
            <CardHeader
                avatar={ 
                    <Avatar aria-label="recipe" className={classes.avatar}> 
                        {titulo[0]}
                    </Avatar> 
                }
                title={ titulo }
                />
            <CardMedia
                className={classes.media}
                image={img}
                title={`Solicitar ${titulo}`}
            />
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    {mensaje}
                </Typography>
            </CardContent>
        </Card>
    </ButtonBase>
  );
}