import React, { Component } from 'react'
import { PropTypes } from "prop-types";
import { Dialog, DialogContent, Grid, TextField, DialogActions, InputLabel, FormControl, Select, MenuItem, FormControlLabel, Checkbox } from "@material-ui/core";
import AppBarModal from "../../general/AppBarModal";
import { BtnFormPage } from "../../general/BotonesAccion";
import InputBuscar from "../../general/InputBuscar";
import { consulta, mostrarError, crear_form_data, formulario, generarFiltros, } from "../../../global/js/funciones";
import BuscarColegio from "../../general/BuscarColegio";
import { BtnForm } from "../../general/BotonesAccion";
import { VisitaCalendarioMenu } from "./PromocionMenu";
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from '../../../global/imagenes/emma_w.png';
import { DetalleVisita } from './Promocion';
// import { VisitaEditar } from './VisitaEditar';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import { es } from 'date-fns/locale'
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { LUGAR_VISITA } from '../helper';
import { obtenerValoresFiltros } from "../../../global/js/funciones";
import BuscarPersona from "../../general/BuscarPersona";

class VisitaEditar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visita: [],
      fecha_visita: null,
      hora_inicio: null,
      hora_fin: null,
      lugar_visita: '',
      numero_estudiante: "",
      responsable: "",
      colegio: "",
      buscarPersona: false,
      buscarColegio: false,
      persona: {
        id: 0,
        nombre: "",
      },
      Colegio: {
        id: 0,
        nombre: '',
      },
      estado_actual: 1,
      lugares_visita: [],
      buscarPersona: false,
      buscarColegio: false,
      titulo_tit: '',
      check_material_audio: false,
      check_stand: false
    }
  }

  async componentDidUpdate({ modalEdiVisita }) {
    let { modalEdiVisita: modalEdiVisitaNew } = this.props;
    if (modalEdiVisita !== modalEdiVisitaNew) {
      if (modalEdiVisitaNew) {
        // this.obtenerVisita([{ llave: "id", valor: id }])
        this.obtenerGenericas()
        await this.obtenerDatos()
      }
      if (!modalEdiVisitaNew) this.reiniciarEstado()
    }

  }

  async obtenerGenericas() {
    let lugares_visita = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': LUGAR_VISITA }]);
    lugares_visita = lugares_visita.reverse()
    this.setState({ lugares_visita });
  }

  async obtenerVisita(filtros_visitas = []) {
    this.setState({ cargando: true });
    let f = await generarFiltros(filtros_visitas);
    console.log(f)
    return new Promise((resolve) => {
      consulta(`api/v1.0/visitas?1${f}`, null, null, (error, estado, resp) => {
        this.setState({ visita: resp })
        resolve(estado === 200 && !error ? resp : null);
      }
      );
    });
  }

  async obtenerDatos() {
    let { id } = this.props
    let datos = await this.obtenerVisita([{ llave: "id", valor: id }]);
    this.setState({
      cargando: false,
      Colegio: { id: datos[0].colegio.id, nombre: datos[0].colegio.nombreColegio },
      fecha_visita: moment(datos[0].fecha_visita).format('ddd MMM DD YYYY HH:mm:ss [GMT]Z (z)'),
      hora_inicio: moment(datos[0].hora_inicio, 'HH:mm:ss').format('ddd MMM DD YYYY HH:mm:ss [GMT]Z (z)'),
      hora_fin: moment(datos[0].hora_fin, 'HH:mm:ss').format('ddd MMM DD YYYY HH:mm:ss [GMT]Z (z)'),
      numero_estudiante: datos[0].numero_estudiante,
      lugar_visita: datos[0].lugar.id,
      persona: { id: datos[0].responsable.id, nombre: `${datos[0].responsable.primer_nombre} ${datos[0].responsable.primer_apellido} ${datos[0].responsable.segundo_apellido}` },
      check_material_audio: datos[0].material_audio == 0 ? false : true,
      check_stand: datos[0].stand == 0 ? false : true
    })

  }

  onChangeFecha = (date) => {
    this.setState({ fecha_visita: date });
  };

  onChangeHoraI = (date) => {
    this.setState({ hora_inicio: date });
  };

  onChangeHoraF = (date) => {
    this.setState({ hora_fin: date });
  };

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value,
    });
  };

  pintar = () => {
    let { Colegio, fecha_visita, hora_inicio, hora_fin, lugar_visita, lugares_visita, numero_estudiante, persona, check_material_audio, check_stand } = this.state
    return (
      <>
        <Grid container spacing={3} style={{ marginBottom: "2px" }}>
          <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
            <InputBuscar
              id="colegio"
              label="Colegios"
              placeholder="Clic aquí para buscar el colegio"
              value={Colegio.nombre}
              callback={() => this.setState({ buscarColegio: true })}
            />
          </Grid>
          <Grid item xs={12}>
            <MuiPickersUtilsProvider libInstance={moment} locale={es} utils={DateFnsUtils}>
              <KeyboardDatePicker
                style={{ marginTop: 3, width: "100%" }}
                required={true}
                // minDate={Date("YYYY-MM-dd")}
                label="Fecha Visita"
                margin="normal"
                id="fecha_visita"
                format="yyyy-MM-dd"
                value={fecha_visita}
                fullWidth
                onChange={this.onChangeFecha}
                KeyboardButtonProps={{ "aria-label": "change date" }}
              // minDateMessage={`La fecha debe ser mayor que la fecha actual.`}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardTimePicker
                required={true}
                style={{ marginTop: 3, width: "100%" }}
                margin="normal"
                label="Hora Inicio"
                value={hora_inicio}
                // format="HH:mm"
                onChange={this.onChangeHoraI}
                KeyboardButtonProps={{ "aria-label": "change date" }}
              // invalidDateMessage="Formato no valido"
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardTimePicker
                required={true}
                style={{ marginTop: 3, width: "100%" }}
                margin="normal"
                label="Hora Fin"
                // format="HH:mm:ss"
                value={hora_fin}
                onChange={this.onChangeHoraF}
                KeyboardButtonProps={{ "aria-label": "change date" }}
                invalidDateMessage="Formato no valido"
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid style={{ marginBottom: "20px" }} item xs={6}>
            <FormControl className="form-control" required>
              <InputLabel id="demo-simple-select-label">Seleccione el lugar de la visita</InputLabel>
              <Select placeholder="Clic aquí para seleccionar el lugar de visita" id="lugar_visita" value={lugar_visita} name='lugar_visita' onChange={this.onChange} >
                {lugares_visita.map(item => <MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              type="number"
              InputProps={{ inputProps: { min: 0, max: 10 } }}
              id="numero_estudiante"
              name="numero_estudiante"
              label="Número de Estudiantes"
              fullWidth
              onChange={this.onChange}
              value={numero_estudiante}
            />
          </Grid>
          <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
            <InputBuscar
              id="persona"
              label="Responsable"
              placeholder="Clic aquí para buscar"
              value={persona.nombre}
              callback={() => this.setState({ buscarPersona: true })}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={check_material_audio}
                  onChange={this.checked}
                  id="check_material_audio"
                  name="check_material_audio"
                  color="primary"
                />
              }
              label="¿ Requiere material audiovisual ?"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={check_stand}
                  onChange={this.checked}
                  id="check_stand"
                  name="check_stand"
                  color="primary"
                />
              }
              label="¿ Requiere stand ?"
            />
          </Grid>
        </Grid>
      </>
    );
  }

  checked = ({ target }) => {
    this.setState({
      [target.name]: target.checked,
    });
  };

  submitVisita = async () => {
    let { id } = this.props
    let {
      fecha_visita,
      hora_inicio,
      hora_fin,
      numero_estudiante,
      persona,
      Colegio,
      // estado_actual,
      lugar_visita,
      check_material_audio,
      check_stand
    } = this.state;
    this.setState({ cagando: true, titulo_carg: "Enviando..." });
    console.log(persona)
    const data = new FormData();
    data.append('colegio', Colegio.id)
    data.append('hora_inicio', moment(hora_inicio).format("H:mm:00"))
    data.append('hora_fin', moment(hora_fin).format("H:mm:00"))
    data.append('lugar', lugar_visita)
    data.append('numero_estudiante', numero_estudiante)
    // data.append('estado_actual', estado_actual)
    data.append('responsable', persona.id)
    data.append('fecha_visita', moment(fecha_visita).format("YYYY-MM-DD"))
    data.append('material_audio', check_material_audio ? 1 : 0)
    data.append('stand', check_stand ? 1 : 0)
    this.props.editarVisita(data, id, () => this.setState({ cargando: false, colegio: "", fecha_visita: null, hora_inicio: null, hora_fin: null, lugar: "", numero_estudiante: "", persona: "", Colegio: '', lugar_visita: '', check_material_audio: false, check_stand: false }));
  }



  reiniciarEstado = () => {
    this.setState({
      cargando: false,
      activeStep: 0,
      colegio: "",
      fecha_visita: null,
      hora_inicio: null,
      hora_fin: null,
      lugar: "",
      numero_estudiante: "",
      persona: "",
      Colegio: '',
      lugar_visita: '',
    });
  };


  render() {
    let { datos, modalEdiVisita, mostrarModalEditarVisita } = this.props
    let { buscarPersona, buscarColegio } = this.state;
    console.log('object');
    return (
      <>
        <Dialog open={modalEdiVisita} maxWidth='md' fullWidth={true} onClose={() => mostrarModalEditarVisita(false)}>
          <AppBarModal titulo='¡ Editar Visita !' mostrarModal={mostrarModalEditarVisita} titulo_accion='' />
          <DialogContent className='scroll' >
            <Grid container>
              {

                <Grid style={{ padding: "2% 2% 0% 2%" }} item xs={12} md={12}>
                  {this.pintar()}
                </Grid>
              }
            </Grid>
          </DialogContent>
          <DialogActions>
            <BtnForm texto="Cerrar" callback={() => mostrarModalEditarVisita(false)} />
            <BtnFormPage
              texto="GUARDAR"
              callback={this.submitVisita}
              type="submit"
            />
          </DialogActions>
        </Dialog>


        <Dialog
          fullWidth={true}
          maxWidth="sm"
          fullScreen={window.innerWidth < 600}
          open={buscarPersona}
          onClose={() => this.setState({ buscarPersona: false })}
        >
          <AppBarModal
            titulo={"¡ Buscar Persona !"}
            mostrarModal={() => this.setState({ buscarPersona: false })}
            titulo_accion="CERRAR"
            accion={() => this.setState({ buscarPersona: false })}
          />
          <DialogContent style={{ padding: "0" }} className="scroll">
            <BuscarPersona
              callback={({
                id,
                primer_nombre,
                primer_apellido,
                segundo_apellido,
              }) =>
                this.setState({
                  persona: {
                    id,
                    nombre: `${primer_nombre} ${primer_apellido} ${segundo_apellido}`,
                  },
                  buscarPersona: false,
                })
              }
            />
          </DialogContent>
          <DialogActions>
            <BtnForm
              texto="CANCELAR"
              callback={() => this.setState({ buscarPersona: false })}
            />
          </DialogActions>
        </Dialog>


        {/* BUSCAR COLEGIO */}
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          fullScreen={window.innerWidth < 600}
          open={buscarColegio}
          onClose={() => this.setState({ buscarColegio: false })}
        >
          <AppBarModal
            titulo={"¡ Buscar Colegio !"}
            mostrarModal={() => this.setState({ buscarColegio: false })}
            titulo_accion="CERRAR"
            accion={() => this.setState({ buscarColegio: false })}
          />
          <DialogContent style={{ padding: "0" }} className="scroll">
            <BuscarColegio
              callback={({
                id,
                nombreColegio,
              }) =>
                this.setState({
                  Colegio: {
                    id,
                    nombre: nombreColegio,
                  },
                  buscarColegio: false,
                })
              }
            />
          </DialogContent>
          <DialogActions>
            <BtnForm
              texto="CANCELAR"
              callback={() => this.setState({ buscarColegio: false })}
            />
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

VisitaEditar.propTypes = {
  modalEdiVisita: PropTypes.bool.isRequired,
  mostrarModalEditarVisita: PropTypes.func.isRequired,
  actualizarMensaje: PropTypes.func.isRequired,

}

export default VisitaEditar;
