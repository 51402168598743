import React from 'react';
import { Avatar, Card, CardActions, CardContent, CardHeader,
  Grid, makeStyles, Typography, Tooltip, IconButton } from '@material-ui/core'; // Material-ui
import education from '../../global/imagenes/education.png';
import moment from 'moment'
import { BtnEditar } from '../general/BotonesAccion';
import BlockIcon from '@material-ui/icons/Block';
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: "100%",
    minHeight: "50px",
    height: "330px",
  },
  image: {
    width: '100px',
    maxWidth: '100px',
    minWidth: '100px',
    height: "100px",
  },
  content_text: {
    padding: "0px 0px 0px 10px",
  },
  content_img: {
    textAlign: 'center',
    height: "150px",
  },
  hover_delete_icon: {
		'&:hover': {
			color: red[800]
		}
	}
}));

export default function SolicitudActiva({ solicitud, descripcion, callback, mostrarModalCancelarSolicitud, mostrarModalContinuarSolicitud }) {
  const classes = useStyles();
  let { fecha_registro, estado_actual: { codigo, valora }, tipo_solicitud, nombre_actividad } = solicitud;
  let fecha_r = moment(fecha_registro).format('YYYY-MM-DD, h:mm a');
  const obtenerColor = (codigo) => {
    let color = 'white';
    const colores = {
      'Est_Int_Env': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
      'Est_Int_Cor': { 'backgroundColor': '#ffc107', color },
      'Est_Int_Rec': { 'backgroundColor': '#b71c1c', color },
      'Est_Int_Hom': { 'backgroundColor': '#01519B', color },
      'Est_Int_Rev': { 'backgroundColor': '#32C79E', color },
      'Est_Int_Pos': { 'backgroundColor': '#6D32C7', color },
      'Est_Int_Doc': { 'backgroundColor': '#009688', color },
      'Est_Int_Ace': { 'backgroundColor': '#C0C732', color },
      'Est_Int_Fin': { 'backgroundColor': '#C74632', color },
      'Est_Int_Neg': { 'backgroundColor': '#C77332', color },
    }
    return (colores[codigo]);
  }
  const obtenerColorConv = (codigo) => {
    let color = 'white';
    const colores = {
      'Est_Int_Bor': { 'backgroundColor': '#EEEEEE', 'color': '#757575' },
      'Est_Int_Env': { 'backgroundColor': '#01579b', color },
      'Sol_aval_jefe': { 'backgroundColor': '#ffc107', color },
      'En_Revi_Con': { 'backgroundColor': '#ffc107', color },
      'Con_rev_sec': { 'backgroundColor': '#ffc107', color },
      'Con_corr': { 'backgroundColor': '#ffc107', color },
      'Con_rev_ins_vin': { 'backgroundColor': '#ffc107', color },
      'Est_Int_Can': { 'backgroundColor': '	#FF0000', color },
      'Con_rev_sec_gen_2': { 'backgroundColor': '#ffc107', color },
      'con_corr_2': { 'backgroundColor': '#ffc107', color },
      'Con_ava_sec_gen': { 'backgroundColor': '	#ffc107', color },
      'Rev_ges_conv_2': { 'backgroundColor': '#ffc107', color },
      'Fir_ins_vincu': { 'backgroundColor': '#ffc107', color },
      'Ges_fir_sg': { 'backgroundColor': '#ffc107', color },
      'Con_sus': { 'backgroundColor': '#ffc107', color },
      'En_corr_soli': { 'backgroundColor': '#ffc107', color },
      'Fir_cuc': { 'backgroundColor': '#ffc107', color },
      'Con_aval_juri_ins_vin': { 'backgroundColor': '#ffc107', color },
        'sin_conv_uni': { 'backgroundColor': '#EEEEEE', color },
        'Con_regis': { 'backgroundColor': '#008000', color },
      'Sol_rechazada': { 'backgroundColor': '#FF0000', color },
    }
    return (colores[codigo])
  }
  const onClickCancelar = (e, data) => {
    mostrarModalCancelarSolicitud(true);
    callback(data);
    e.stopPropagation();
  }
  const onClickEditar = (e, tipo) => {
    mostrarModalContinuarSolicitud(tipo);
    e.stopPropagation();
  }
  let accion = (codigo != 'Est_Int_Can' && codigo != 'Est_Int_Neg' && codigo != 'Est_Int_Fin') &&
                <Tooltip title = 'Cancelar Solicitud'>
                  <IconButton className = { classes.hover_delete_icon } aria-label = "cancel"
                    onClick = { (e) => onClickCancelar(e, solicitud) }>
                    <BlockIcon />
                  </IconButton>
                </Tooltip>
  return (
    <Card className = { classes.card }>
      <CardHeader
        avatar = {tipo_solicitud.codigo === 'Con_Int' ? <Avatar aria-label = "recipe" style = { obtenerColorConv(codigo) }>{ tipo_solicitud.codigo.charAt(0).toUpperCase() }</Avatar> :  <Avatar aria-label = "recipe" style = { obtenerColor(codigo) }>{ valora.toUpperCase() }</Avatar> }
        action = { accion }
        title = { tipo_solicitud.nombre }
        subheader={tipo_solicitud.codigo === 'Reg_Mov_Int' && nombre_actividad ? (<React.Fragment>{nombre_actividad} <br /> {fecha_r}</React.Fragment>) : fecha_r}/>
      <CardContent>
        <Grid container direction = { 'row' } justifyContent = { 'center' }>
          <Grid item xs = { 12 } sm = { 12 } md = { 12 } className = { classes.content_img }>
            <img src = { education } className = { classes.image } alt='Imagen Tipo' />
            <Typography variant="body2" color="textSecondary" component="p" align='justify'>
              { descripcion }
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      {
        codigo == 'Est_Int_Bor' &&
          <CardActions>
            <BtnEditar callback = { (e) => onClickEditar(e, tipo_solicitud) } texto = 'Continuar solicitud' />
          </CardActions>
      }
    </Card>
  );
}
