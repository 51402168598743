// Generales
import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  InputLabel,
  Select,
  OutlinedInput,
  Grid,
  TextField,
  FormControl,
  Button,
  ListItemText,
  Checkbox,
  MenuItem,
  FormHelperText,
  IconButton,
  TableBody,
  TableCell,
  Table,
  TableRow,
  TableHead,
  Typography,
  CardActions,
  Box,
  Divider,
  Collapse,
} from "@material-ui/core";
import AppBarModal from "../../general/AppBarModal";
import {
  BtnForm,
  BtnEliminar,
  BtnEditar,
  BtnPermiso,
  BtnDetalle,
} from "../../general/BotonesAccion";
import ListarDatos from "../../general/ListarDatos";
import ConfirmarAccion from "../../general/ConfirmarAccion";
import { actualizarMensaje } from "../../../redux/actions/actGlobal";
import moment from "moment";
import {
  consulta,
  formulario,
  crear_form_data,
  mostrarError,
  MenuProps,
  obtenerValoresFiltros,
} from "../../../global/js/funciones";
import { ValidatorForm } from "react-material-ui-form-validator";
import AddIcon from "@material-ui/icons/Add";
import { useDispatch } from "react-redux";
// Sisef
import {
  COMP_GENERICA,
  SISEF_CORTES,
  SISEF_NIVELES,
  SISEF_RECURSOS,
  SISEF_REFERENCIAS,
} from "../helper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from "../../../global/imagenes/emma_w.png";

export function InfoBasica({ Modal, cerrarModal, data }) {
  console.log(data);
  return (
    <div>
      <Dialog
        open={Modal}
        onClose={() => cerrarModal(false)}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth='sm'
      >
        <AppBarModal
          titulo="Información Básica"
          mostrarModal={() => cerrarModal(false)}
          titulo_accion="Información Básica"
          accion=""
        />
        <DialogContent style={{ padding: 0 }} className="scroll">
          <Table>
            <TableBody>
              <TableRow key={1}>
                <TableCell component="th" scope="row">Nombre de la asigantura</TableCell>
                <TableCell align="left">{data.asignatura_nombre ? data.asignatura_nombre : "Dato no ingresado"}</TableCell>
              </TableRow>
              <TableRow key={2}>
                <TableCell component="th" scope="row">Código de la asigantura</TableCell>
                <TableCell align="left">{data.codigo_asignatura ? data.codigo_asignatura : "Dato no ingresado"}</TableCell>
              </TableRow>
              <TableRow key={3}>
                <TableCell component="th" scope="row">Programa</TableCell>
                <TableCell align="left">{data.programa_nombre ? data.programa_nombre : "Dato no ingresado"}</TableCell>
              </TableRow>
              <TableRow key={4}>
                <TableCell component="th" scope="row">Departamento</TableCell>
                <TableCell align="left">{data.departamento_nombre ? data.departamento_nombre : "Dato no ingresado"}</TableCell>
              </TableRow>
              <TableRow key={5}>
                <TableCell component="th" scope="row">Nivel de formación</TableCell>
                <TableCell align="left">{data.nivel_formacion ? data.nivel_formacion : "Dato no ingresado"}</TableCell>
              </TableRow>
              <TableRow key={6}>
                <TableCell component="th" scope="row">Horas de trabajo presencial</TableCell>
                <TableCell align="left">{data.horas_presenciales ? data.horas_presenciales : "Dato no ingresado"}</TableCell>
              </TableRow>
              <TableRow key={7}>
                <TableCell component="th" scope="row">Horas de trabajo independiente</TableCell>
                <TableCell align="left">{data.horas_trabajo_independiente ? data.horas_trabajo_independiente : "Dato no ingresado"}</TableCell>
              </TableRow>
              <TableRow key={8}>
                <TableCell component="th" scope="row">Total de horas</TableCell>
                <TableCell align="left">{data.total_horas ? data.total_horas : "Dato no ingresado"}</TableCell>
              </TableRow>
              <TableRow key={9}>
                <TableCell component="th" scope="row">Número de créditos</TableCell>
                <TableCell align="left">{data.creditos ? data.creditos : "Dato no ingresado"}</TableCell>
              </TableRow>
              <TableRow key={10}>
                <TableCell component="th" scope="row">Valores</TableCell>
                <TableCell align="left">{data.valores_plan ? data.valores_plan : "Dato no ingresado"}</TableCell>
              </TableRow>
              <TableRow key={11}>
                <TableCell component="th" scope="row">Perfil profesor</TableCell>
                <TableCell align="left">{data.perfil_profesor ? data.perfil_profesor : "Dato no ingresado"}</TableCell>
              </TableRow>
              <TableRow key={12}>
                <TableCell component="th" scope="row">Perfil de formación</TableCell>
                <TableCell align="left">{data.perfil_formacion ? data.perfil_formacion : "Dato no ingresado"}</TableCell>
              </TableRow>
              <TableRow key={13}>
                <TableCell component="th" scope="row">Área de formación</TableCell>
                <TableCell align="left">{data.area_formacion ? data.area_formacion : "Dato no ingresado"}</TableCell>
              </TableRow>
              <TableRow key={14}>
                <TableCell component="th" scope="row">Prerequisitos</TableCell>
                <TableCell align="left">{data.pre_requisitos ? data.pre_requisitos : "Dato no ingresado"}</TableCell>
              </TableRow>
              <TableRow key={15}>
                <TableCell component="th" scope="row">Resumen</TableCell>
                <TableCell align="left">{data.resumen_plan ? data.resumen_plan : "Dato no ingresado"}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <BtnForm texto="Cerrar" callback={() => cerrarModal(false)} />
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function C_Especifica({ Modal, cerrarModal, id_plan }) {
  const [comp, setComp] = React.useState([]);
  const [nueva_comp_esp, set_nueva_comp_esp] = React.useState("");
  const isInitialMount = React.useRef(true);
  const [Modal_Crear, Set_Modal_Crear] = React.useState(false);
  const [Modal_Editar, Set_Modal_Editar] = React.useState(false);
  const [Modal_Eliminar, Set_Modal_Eliminar] = React.useState(false);
  const [errores, setErrores] = React.useState([]);
  const [dataCompetencia, setDataCompetencia] = React.useState([]);
  const [cargando, setCargando] = React.useState(true);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const fetchData = async () => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
        if (Modal) await obtenerProcesos()
      }
    };
    fetchData();
  }, []);

  const ObtenerCompEsp = async (id) => {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/sisef/listar_comp_esp/${id}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  };

  const getError = (llave, errores) => {
    let existe = errores.find((e) => e.llave === llave);
    return existe ? existe.mensaje : "";
  };

  const onChange = (e) => {
    if (e.target.name == "nueva_comp_esp") {
      set_nueva_comp_esp(e.target.value);
      if (errores.length > 0) {
        setErrores(errores.filter((er) => er.llave != "nueva_comp_esp")); // Elimina error cuando se escribe en el campo
      }
    }
  };

  const onSubmit = async (opc) => {
    setErrores(
      await validateInputs([
        {
          value: nueva_comp_esp,
          name: "nueva_comp_esp",
          err: { empty: false },
        },
      ])
    );
    if (errores.length == 0) {
      enviarDatos(opc);
    }
  };

  const validateInputs = (campos = []) => {
    return new Promise((resolve) => {
      let errores = [];
      campos.map(({ value, name, err, value2 }) => {
        if (err.empty && value.replace(/\s+/g, "") == "")
          errores.push({ llave: name, mensaje: `Este campo es obligatorio` });
        if (err.empty == false && value == 0)
          errores.push({ llave: name, mensaje: `Este campo es obligatorio` });
      });
      resolve(errores);
    });
  };

  const enviarDatos = (opc) => {
    setCargando(true);
    let data = {
      competencia: nueva_comp_esp,
      plan: id_plan,
    };
    if (opc == 1) {
      guardar_comp_esp(data);
    } else {
      modificar_comp_esp(dataCompetencia.id, data);
    }
  };

  const guardar_comp_esp = async (data) => {
    let form = await crear_form_data(data);
    formulario(
      `api/v1.0/sisef/crear_comp_esp`,
      form,
      "post",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            Set_Modal_Crear(false);
            obtenerProcesos();
            reset();
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        dispatch(
          actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
        );
        setCargando(false);
      }
    );
  };

  const modificar_comp_esp = async (id, data) => {
    let form = await crear_form_data(data);
    formulario(
      `api/v1.0/sisef/${id}/modificar_competencia`,
      form,
      "patch",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            obtenerProcesos();
            Set_Modal_Editar(false);
            reset();
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        dispatch(
          actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
        );
      }
    );
  };

  const eliminarCompetencia = () => {
    let id = dataCompetencia.id;
    consulta(
      `api/v1.0/sisef/${id}/eliminar_especifica`,
      {},
      "put",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            obtenerProcesos();
            Set_Modal_Eliminar(false);
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        dispatch(
          actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
        );
      }
    );
  };

  const obtenerProcesos = async () => {
    setCargando(true);
    let data = await ObtenerCompEsp(id_plan);
    let i = 0;
    let aux = [];
    data.forEach((compet) => {
      i++;
      aux.push({
        num: i,
        id: compet.id,
        enunciado: compet.competencia,
        id_plan: compet.plan,
        fecha: compet.fecha_registro,
      });
    });
    setComp(aux);
    setCargando(false);
  };

  const reset = () => {
    set_nueva_comp_esp("");
    setErrores([]);
  };

  const cerrarmodals = () => {
    Set_Modal_Crear(false);
    Set_Modal_Editar(false);
    reset();
  };

  const actions = (rowData) => {
    const Modificar = (row) => {
      setDataCompetencia(row);
      set_nueva_comp_esp(row.enunciado);
      Set_Modal_Editar(true);
    };

    const Eliminar = (row) => {
      setDataCompetencia(row);
      Set_Modal_Eliminar(true);
    };
    return (
      <div>
        {" "}
        <BtnEliminar callback={() => Eliminar(rowData)} />{" "}
        <BtnEditar callback={() => Modificar(rowData)} />
      </div>
    );
  };

  return (
    <div>
      <Dialog
        open={Modal}
        onClose={() => cerrarModal(false)}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <AppBarModal
          titulo="Competencias Especificas"
          mostrarModal={() => cerrarModal(false)}
          titulo_accion="Competencia Especifica"
          accion=""
        />
        <DialogContent style={{ padding: 0 }} className="scroll">
          {
            !cargando ?
              <ListarDatos
                style={{ padding: 0 }}
                id="tbl_listar_comp_esp"
                datos={comp}
                opciones={true}
                agregar={true}
                buscar={true}
                actAgregar={() => Set_Modal_Crear(true)}
                espacioTexto={10} //este item controla el espacio que se le va dar al espacio del texto, en caso de tener acciones
                mensajeVacio={<p style={{ justifyContent: 'center', margin: '0px 12%', fontSize: '15px' }}>Por el momento no ha sido agregada ninguna competencia,<br /> Haz clic en el botón para poder agregar la competencia especifica.</p>}
                css={{
                  list: { padding: "0px 0px 20px 0px" },
                  appBar: { padding: "0px 0px 0px 0px" },
                }}
                acciones={(row) => actions(row)}
                titulo={"Lista de competencias especificas"}
                fila_principal={(row) => `${row.enunciado}`}
                filas={[
                  {
                    mostrar: ({ enunciado }) => `${enunciado}`,
                    id: "nombre",
                    enLista: false,
                  },
                  {
                    mostrar: ({ fecha }) =>
                      `${moment(fecha).format("YYYY-MM-DD, h:mm a")}`,
                    id: "Fecha",
                    enLista: true,
                  },
                ]}
              />
              :
              <TareasTerminadas
                imagen={emma_w}
                mensaje="Cargando..."
                widthImg="7%"
                marginTop="7%"
                cargando={cargando}
              />
          }
        </DialogContent>
        <DialogActions>
          <BtnForm texto="Cerrar" callback={() => cerrarModal(false)} />
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={Modal_Crear}
        onClose={cerrarmodals}
      >
        <AppBarModal
          titulo={"¡ Nueva !"}
          titulo_accion="Competencia Especifica"
          mostrarModal={cerrarmodals}
        />
        <DialogContent className="scroll">
          {
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl className="form-control">
                  <TextField
                    id="nueva_comp_esp"
                    type="text"
                    name="nueva_comp_esp"
                    label="Competencia especifica"
                    multiline
                    rows={4}
                    defaultValue=""
                    onChange={onChange}
                    error={getError("nueva_comp_esp", errores).length > 0}
                    helperText={getError("nueva_comp_esp", errores)}
                  />
                </FormControl>
              </Grid>
            </Grid>
          }
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CANCELAR" callback={cerrarmodals} />
          <BtnForm texto="GUARDAR" callback={() => onSubmit(1)} />
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={Modal_Editar}
        onClose={cerrarmodals}
      >
        <AppBarModal
          titulo={"¡ Modificar !"}
          titulo_accion="Competencia Especifica"
          mostrarModal={cerrarmodals}
        />
        <DialogContent className="scroll">
          {
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl className="form-control">
                  <TextField
                    id="nueva_comp_esp"
                    type="text"
                    name="nueva_comp_esp"
                    label="Competencia especifica"
                    multiline
                    rows={4}
                    defaultValue=""
                    value={nueva_comp_esp}
                    onChange={onChange}
                    error={getError("nueva_comp_esp", errores).length > 0}
                    helperText={getError("nueva_comp_esp", errores)}
                  />
                </FormControl>
              </Grid>
            </Grid>
          }
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CANCELAR" callback={cerrarmodals} />
          <BtnForm texto="MODIFICAR" callback={() => onSubmit(2)} />
        </DialogActions>
      </Dialog>
      <ConfirmarAccion
        titulo="¿ Eliminar Competencia Especifica ?"
        dato={{ id: 0 }}
        mensaje1="¿Estás seguro(a) que deseas eliminar la competencia?"
        modalConAccion={Modal_Eliminar}
        mostrarModalConfAccion={() => Set_Modal_Eliminar(false)}
        ejecutarAccion={eliminarCompetencia}
      />
    </div>
  );
}

export function Gran_Pregunta({ Modal, cerrarModal, id_plan }) {
  const [preguntas, setPreguntas] = React.useState([]);
  const [data_pregunta, setData_pregunta] = React.useState([]);
  const [nueva_pregunta, set_nueva_pregunta] = React.useState("");
  const isInitialMount = React.useRef(true);
  const [Modal_Crear, Set_Modal_Crear] = React.useState(false);
  const [Modal_Editar, Set_Modal_Editar] = React.useState(false);
  const [Modal_Eliminar, Set_Modal_Eliminar] = React.useState(false);
  const [errores, setErrores] = React.useState([]);
  const [cargando, setCargando] = React.useState(true);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const fetchData = async () => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
        if (Modal) await obtenerProcesos()
        reset();
      }
    };
    fetchData();
  }, []);


  const reset = () => {
    setErrores([]);
    set_nueva_pregunta("");
  };

  const obtenerProcesos = async () => {
    setCargando(true);
    let data = await ObtenerPreguntas(id_plan);
    let i = 0;
    let aux = [];
    data.forEach((preg) => {
      i++;
      aux.push({
        id: preg.id,
        num: i,
        pregunta: preg.pregunta,
        id_plan: preg.plan,
        fecha: preg.fecha_registro,
      });
    });
    setPreguntas(aux);
    setCargando(false);
  };

  const ObtenerPreguntas = async (id) => {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/sisef/listar_preguntas/${id}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  };

  const getError = (llave, errores) => {
    let existe = errores.find((e) => e.llave === llave);
    return existe ? existe.mensaje : "";
  };

  const onChange = (e) => {
    if (e.target.name == "nueva_pregunta") {
      set_nueva_pregunta(e.target.value);
      if (errores.length > 0) {
        setErrores(errores.filter((er) => er.llave != "nueva_pregunta")); // Elimina error cuando se escribe en el campo
      }
    }
  };

  const onSubmit = async (opc) => {
    setErrores(
      await validateInputs([
        {
          value: nueva_pregunta,
          name: "nueva_pregunta",
          err: { empty: false },
        },
      ])
    );

    if (errores.length == 0) {
      enviarDatos(opc);
    }
  };

  const validateInputs = (campos = []) => {
    return new Promise((resolve) => {
      let errores = [];
      campos.map(({ value, name, err, value2 }) => {
        if (err.empty && value.replace(/\s+/g, "") == "")
          errores.push({ llave: name, mensaje: `Este campo es obligatorio` });
        if (err.empty == false && value == 0)
          errores.push({ llave: name, mensaje: `Este campo es obligatorio` });
      });
      console.log();
      resolve(errores);
    });
  };

  const enviarDatos = (opc) => {
    setCargando(true);
    let data = {
      pregunta: nueva_pregunta,
      plan: id_plan,
    };
    if (opc == 1) {
      guardar_pregunta(data);
    } else {
      modificarPregunta(data_pregunta.id, data);
    }
  };

  const guardar_pregunta = async (data) => {
    let form = await crear_form_data(data);
    formulario(
      `api/v1.0/sisef/crear_pregunta`,
      form,
      "post",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            Set_Modal_Crear(false);
            obtenerProcesos();
            reset();
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        dispatch(
          actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
        );
        setCargando(false);
      }
    );
  };

  const modificarPregunta = async (id, data) => {
    let form = await crear_form_data(data);
    formulario(
      `api/v1.0/sisef/${id}/modificar_pregunta`,
      form,
      "patch",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            obtenerProcesos();
            Set_Modal_Editar(false);
            reset();
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        dispatch(
          actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
        );
      }
    );
  };

  const eliminarPregunta = () => {
    let id = data_pregunta.id;
    consulta(
      `api/v1.0/sisef/${id}/eliminar_pregunta`,
      {},
      "put",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            obtenerProcesos();
            Set_Modal_Eliminar(false);
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  };

  const cerrarmodals = () => {
    Set_Modal_Crear(false);
    Set_Modal_Editar(false);
    reset();
  };
  const actions = (rowData) => {
    const Modificar = (row) => {
      setData_pregunta(row);
      set_nueva_pregunta(row.pregunta);
      Set_Modal_Editar(true);
    };

    const Eliminar = (row) => {
      setData_pregunta(row);
      Set_Modal_Eliminar(true);
    };

    return (
      <div>
        {" "}
        <BtnEliminar callback={() => Eliminar(rowData)} />{" "}
        <BtnEditar callback={() => Modificar(rowData)} />
      </div>
    );
  };

  return (
    <div>
      <Dialog
        open={Modal}
        onClose={() => cerrarModal(false)}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <AppBarModal
          titulo="Gran Pregunta"
          mostrarModal={() => cerrarModal(false)}
          titulo_accion="Gran Pregunta"
          accion=""
        />
        <DialogContent style={{ padding: 0 }} className="scroll">
          {
            !cargando ?
              <ListarDatos
                id="tbl_listar_gran_pregunta"
                datos={preguntas}
                opciones={true}
                agregar={true}
                buscar={true}
                actAgregar={() => Set_Modal_Crear(true)}
                mensajeVacio={<p style={{ justifyContent: 'center', margin: '0px 12%', fontSize: '15px' }}>Por el momento no ha sido agregada ninguna pregunta,<br /> Haz clic en el botón para poder agregar la pregunta.</p>}
                css={{
                  list: { padding: "0px 0px 20px 0px" },
                  appBar: { padding: "0px 0px 0px 0px" },
                }}
                acciones={(row) => actions(row)}
                espacioTexto={10}
                titulo={"Listado de gran pregunta"}
                fila_principal={(row) => `${row.pregunta}`}
                filas={[
                  {
                    mostrar: ({ pregunta }) => `${pregunta}`,
                    id: "nombre",
                    enLista: false,
                  },
                  {
                    mostrar: ({ fecha }) =>
                      `${moment(fecha).format("YYYY-MM-DD, h:mm a")}`,
                    id: "Fecha",
                    enLista: true,
                  },
                ]}
              />
              :
              <TareasTerminadas
                imagen={emma_w}
                mensaje="Cargando..."
                widthImg="7%"
                marginTop="7%"
                cargando={cargando}
              />
          }
        </DialogContent>
        <DialogActions>
          <BtnForm texto="Cerrar" callback={() => cerrarModal(false)} />
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={Modal_Crear}
        onClose={cerrarmodals}
      >
        <AppBarModal
          titulo={"¡ Nuevo !"}
          titulo_accion="Gran Pregunta"
          mostrarModal={cerrarmodals}
        />
        <DialogContent className="scroll">
          {
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl className="form-control">
                  <TextField
                    id="nueva_pregunta"
                    type="text"
                    name="nueva_pregunta"
                    label="Pregunta"
                    multiline
                    rows={4}
                    defaultValue=""
                    onChange={onChange}
                    error={getError("nueva_pregunta", errores).length > 0}
                    helperText={getError("nueva_pregunta", errores)}
                  />
                </FormControl>
              </Grid>
            </Grid>
          }
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CANCELAR" callback={cerrarmodals} />
          <BtnForm texto="GUARDAR" callback={() => onSubmit(1)} />
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={Modal_Editar}
        onClose={() => cerrarmodals}
      >
        <AppBarModal
          titulo={"¡ Modificar !"}
          titulo_accion="Gran Pregunta"
          mostrarModal={cerrarmodals}
        />
        <DialogContent className="scroll">
          {
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl className="form-control">
                  <TextField
                    id="nueva_pregunta"
                    type="text"
                    name="nueva_pregunta"
                    label="Pregunta"
                    multiline
                    rows={4}
                    defaultValue=""
                    onChange={onChange}
                    value={nueva_pregunta}
                    error={getError("nueva_pregunta", errores).length > 0}
                    helperText={getError("nueva_pregunta", errores)}
                  />
                </FormControl>
              </Grid>
            </Grid>
          }
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CANCELAR" callback={cerrarmodals} />
          <BtnForm texto="MODIFICAR" callback={() => onSubmit(2)} />
        </DialogActions>
      </Dialog>
      <ConfirmarAccion
        titulo="¿ Eliminar pregunta ?"
        dato={{ id: 0 }}
        mensaje1="¿Estás seguro(a) que deseas eliminar la pregunta?"
        modalConAccion={Modal_Eliminar}
        mostrarModalConfAccion={() => Set_Modal_Eliminar(false)}
        ejecutarAccion={eliminarPregunta}
      />
    </div>
  );
}

export function C_Generica({ Modal, cerrarModal, id_plan }) {
  const [Comp_Generica, SetCompGenericas] = React.useState([]);
  const [generica, setGenerica] = React.useState([]);
  const [nueva_competencia, set_nueva_competencia] = React.useState([]);
  const [dataCompetencia, setDataCompetencia] = React.useState([]);
  const isInitialMount = React.useRef(true);
  const [Modal_Crear, Set_Modal_Crear] = React.useState(false);
  const [Modal_Eliminar, Set_Modal_Eliminar] = React.useState(false);
  const [cargando, setCargando] = React.useState(true);
  const [errores, setErrores] = React.useState([]);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const fetchData = async () => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
        if (Modal) {
          await cargarCompetencias();
          await obtenerProcesos();
        }
        reset();
      }
    };
    fetchData();
  }, []);

  const getError = (llave, errores) => {
    let existe = errores.find((e) => e.llave === llave);
    return existe ? existe.mensaje : "";
  };

  const cargarCompetencias = async () => {
    const dato = await obtenerValoresFiltros([{ llave: "generica", valor: COMP_GENERICA }, { llave: "estado", valor: "1" },]);
    setGenerica(dato);
  };

  const PintarC_Genericas = () => {
    if (Array.isArray(generica)) {
      return generica.map(({ nombre, id }) => (
        <MenuItem key={id} value={id}>
          <Checkbox checked={nueva_competencia.indexOf(id) > -1} />
          <ListItemText primary={nombre} />
        </MenuItem>
      ));
    }
    return [];
  };

  const setCheck = (e) => {
    set_nueva_competencia(e.target.value);
    if (errores.length > 0) {
      setErrores(errores.filter((er) => er.llave != "compe")); // Elimina error cuando se escribe en el campo
    }
  };

  const pintarNombreSeleccionadas = (selected) => {
    let resp = [];
    selected.map((element) => {
      generica.map((e) => {
        if (element === e.id) return resp.push(e.nombre);
        return null;
      });
      return null;
    });
    return resp.join(", ");
  };

  const obtenerProcesos = async () => {
    setCargando(true);
    let data = await ObtenerCompGen(id_plan);
    let aux = [];
    let i = 0;
    if (data.length !== 0) {
      data.forEach((comp) => {
        i++;
        aux.push({
          id: comp.id,
          num: i,
          enunciado: comp.competencia.nombre,
          id_plan: comp.plan,
          fecha: comp.fecha_registro,
        });
      });
    }
    SetCompGenericas(aux);
    setCargando(false);
  };

  const ObtenerCompGen = async (id) => {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/sisef/listar_comp_gen/${id}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  };

  const onSubmit = (e) => {
    if (nueva_competencia.length == 0) {
      let error = [];
      error.push({ llave: "compe", mensaje: `Este campo es obligatorio` });
      setErrores(error);
    } else {
      guardar_C_Generica();
    }
  };

  const guardar_C_Generica = () => {
    let plan = id_plan;
    let competencia = nueva_competencia;
    consulta(
      `api/v1.0/sisef/${plan}/crear_comp_gen`,
      { competencia, plan },
      "post",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            obtenerProcesos();
            Set_Modal_Crear(false);
            reset();
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        dispatch(
          actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
        );
      }
    );
  };

  const eliminarCompetencia = () => {
    let id = dataCompetencia.id;
    consulta(
      `api/v1.0/sisef/${id}/eliminar_generica`,
      {},
      "put",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            obtenerProcesos();
            Set_Modal_Eliminar(false);
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        dispatch(
          actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
        );
      }
    );
  };

  const actions = (rowData) => {
    const Eliminar = (row) => {
      setDataCompetencia(row);
      Set_Modal_Eliminar(true);
    };
    return (
      <div>
        {" "}
        <BtnEliminar callback={() => Eliminar(rowData)} />
      </div>
    );
  };

  const reset = () => {
    set_nueva_competencia([]);
    setErrores([]);
  };

  const cerrarmodals = () => {
    Set_Modal_Crear(false);
    reset();
  };

  return (
    <>
      <Dialog
        open={Modal}
        onClose={() => cerrarModal(false)}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <AppBarModal
          titulo="Competencias Genericas"
          mostrarModal={() => cerrarModal(false)}
          titulo_accion="Competencia Generica"
          accion=""
        />
        <DialogContent style={{ padding: 0 }} className="scroll">
          {
            !cargando ?
              <ListarDatos
                id="tbl_listar_c_generica"
                datos={Comp_Generica}
                opciones={true}
                agregar={true}
                buscar={true}
                mensajeVacio={<p style={{ justifyContent: 'center', margin: '0px 12%', fontSize: '15px' }}>Por el momento no ha sido agregada ninguna competencia,<br /> Haz clic en el botón para poder agregar la competencia genérica.</p>}

                actAgregar={() => Set_Modal_Crear(true)}
                css={{
                  list: { padding: "0px 0px 20px 0px" },
                  appBar: { padding: "0px 0px 0px 0px" }

                }}
                acciones={(row) => actions(row)}
                titulo={"Listado de Competencias genericas"}
                fila_principal={(row) => `${row.enunciado}`}
                filas={[
                  {
                    mostrar: ({ enunciado }) => `${enunciado}`,
                    id: "enunciado",
                    enLista: false,
                  },
                  {
                    mostrar: ({ fecha }) =>
                      `${moment(fecha).format("YYYY-MM-DD, h:mm a")}`,
                    id: "Fecha",
                    enLista: true,
                  },
                ]}
              />
              :
              <TareasTerminadas
                imagen={emma_w}
                mensaje="Cargando..."
                widthImg="7%"
                marginTop="7%"
                cargando={cargando}
              />
          }

        </DialogContent>
        <DialogActions>
          <BtnForm texto="Cerrar" callback={() => cerrarModal(false)} />
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={Modal_Crear}
        onClose={cerrarmodals}
        aria-labelledby="form-dialog-title"
      >
        <AppBarModal titulo={"¡ Nueva !"} mostrarModal={cerrarmodals} />
        <DialogContent className="scroll">
          <ValidatorForm onSubmit={onSubmit}>
            <FormControl
              variant="outlined"
              className="form-control"
              error={getError("compe", errores).length > 0}
            >
              <InputLabel htmlFor="select-multiple-checkbox">
                {"C. Generica"}
              </InputLabel>
              <Select
                multiple
                value={nueva_competencia}
                input={
                  <OutlinedInput
                    labelWidth={70}
                    name="select-multiple-checkbox"
                    id="select-multiple-checkbox"
                  />
                }
                onChange={setCheck}
                id="compe"
                name="compe"
                renderValue={(selected) => {
                  return pintarNombreSeleccionadas(selected);
                }}
                MenuProps={MenuProps}
              >
                {PintarC_Genericas()}
              </Select>
              {<FormHelperText> {getError("compe", errores)}</FormHelperText>}
            </FormControl>
            <Button
              color="primary"
              type="submit"
              id="enviar_form_add_votantes"
              className="oculto"
            >
              {" "}
              Guardar{" "}
            </Button>
          </ValidatorForm>
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CANCELAR" callback={cerrarmodals} />
          <BtnForm
            texto="GUARDAR"
            callback={() => {
              document.getElementById("enviar_form_add_votantes").click();
            }}
          />
        </DialogActions>
      </Dialog>
      <ConfirmarAccion
        titulo="¿ Eliminar Competencia Generica ?"
        dato={{ id: 0 }}
        mensaje1="¿Estás seguro(a) que deseas eliminar la competencia?"
        modalConAccion={Modal_Eliminar}
        mostrarModalConfAccion={() => Set_Modal_Eliminar(false)}
        ejecutarAccion={eliminarCompetencia}
      />
    </>
  );
}

export function U_Formacion({ Modal, cerrarModal, id_plan }) {
  const [unidades, setUnidades] = React.useState([]);
  const [cortes_sisef, setCortes_Sisef] = React.useState([]);
  const [data_unidad, set_Data_unidad] = React.useState([]);
  const [Componentes, SetComponentes] = React.useState([]);
  const [IndicadoresAdd, setIndicadoresAdd] = React.useState([]);
  const [ContenidosAdd, setContenidosAdd] = React.useState([]);
  const [SincronicoAdd, setSincronicoAdd] = React.useState([]);
  const [AsincronicoAdd, setAsincronicoAdd] = React.useState([]);
  const [EvaluativaAdd, setEvaluativaAdd] = React.useState([]);
  const [RecursosAdd, setRecursosAdd] = React.useState([]);
  const [ReferenciasAdd, setReferenciasAdd] = React.useState([]);
  const [TituloComponente, SetTituloComponente] = React.useState("");
  const [niveles, setNiveles] = React.useState([]);
  const [TiposRec, setTiposRec] = React.useState([]);
  const [TiposRef, setTiposRef] = React.useState([]);
  const [nombre_unidad, set_nombre_unidad] = React.useState("");
  const [r_aprendizaje, set_r_aprendizaje] = React.useState("");
  const [h_presenciales, set_h_presenciales] = React.useState("");
  const [h_trabajoi, set_h_trabajoi] = React.useState("");
  const [corte, set_Corte] = React.useState("");
  const isInitialMount = React.useRef(true);
  const [Modal_Crear, Set_Modal_Crear] = React.useState(false);
  const [Modal_Editar, Set_Modal_Editar] = React.useState(false);
  const [Modal_Eliminar, Set_Modal_Eliminar] = React.useState(false);
  const [Modal_Detalle, Set_Modal_Detalle] = React.useState(false);
  const [ModalAddEvaluativa, SetModalAddEvaluativa] = React.useState(false);
  const [ModalAddSincronico, SetModalAddSincronico] = React.useState(false);
  const [ModalAddAsincronico, SetModalAddAsincronico] = React.useState(false);
  const [ModalAddConte, SetModalAddConte] = React.useState(false);
  const [ModalAddIndi, SetModalAddIndi] = React.useState(false);
  const [ModalAddRef, SetModalAddRef] = React.useState(false);
  const [ModalAddRecurso, SetModalAddRecurso] = React.useState(false);
  const [ModalComponentes, SetModalComponentes] = React.useState(false);
  const [errores, setErrores] = React.useState([]);
  const [cargando, setCargando] = React.useState(true);
  const [openind, setOpenind] = React.useState(false);
  const [opencont, setOpencont] = React.useState(false);
  const [opensinc, setOpensinc] = React.useState(false);
  const [openasinc, setOpenasinc] = React.useState(false);
  const [openeva, setOpeneva] = React.useState(false);
  const [openrec, setOpenrec] = React.useState(false);
  const [openref, setOpenref] = React.useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const fetchData = async () => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
        if (Modal) {
          setCargando(true);
          await obtenerProcesos();
          await DatosIniciales();
          await cargarNiveles();
          setCargando(false);
        }
      }
    };
    fetchData();
  }, []);

  const DatosIniciales = async () => {
    const dato = await obtenerValoresFiltros([{ llave: "generica", valor: SISEF_CORTES }, { llave: "estado", valor: "1" },]);
    setCortes_Sisef(dato);
  };

  const cargarNiveles = async () => {
    const dato = await obtenerValoresFiltros([
      { llave: "generica", valor: SISEF_NIVELES },
      { llave: "estado", valor: "1" },
    ]);
    setNiveles(dato);
    const datarec = await obtenerValoresFiltros([
      { llave: "generica", valor: SISEF_RECURSOS },
      { llave: "estado", valor: "1" },
    ]);
    setTiposRec(datarec);
    const data = await obtenerValoresFiltros([
      { llave: "generica", valor: SISEF_REFERENCIAS },
      { llave: "estado", valor: "1" },
    ]);
    setTiposRef(data);
  };

  const obtenerProcesos = async () => {
    let data = await ObtenerUnidades(id_plan);
    let i = 0;
    let aux = [];
    data.forEach((und) => {
      i++;
      aux.push({
        num: i,
        id: und.id,
        nombre: und.nombre,
        resultado: und.resul_aprendizaje,
        horas_p: und.hrs_presenciales,
        horas_Ti: und.hrs_trabajo_indp,
        corte_s: und.corte.nombre,
        corte_cod: und.corte.id,
        id_plan: und.plan,
      });
    });
    setUnidades(aux);
  };

  const ObtenerUnidades = async (id) => {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/sisef/listar_und_formacion/${id}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  };
  // Este metodo traera la informacion de la tabla que le indiquen
  const ObtenerInformacion = async (id, tipo, tabla) => {
    return new Promise((resolve) => {
      if (tipo == 0) {
        consulta(
          `api/v1.0/sisef/${tabla}/${id}`,
          null,
          null,
          (error, estado, resp) => {
            resolve(estado === 200 && !error ? resp : null);
          }
        );
      } else {
        consulta(
          `api/v1.0/sisef/${tabla}/${id}/${tipo}`,
          null,
          null,
          (error, estado, resp) => {
            resolve(estado === 200 && !error ? resp : null);
          }
        );
      }
    });
  };
  // Este metodo recetea los array
  const reset = () => {
    setIndicadoresAdd([]);
    setContenidosAdd([]);
    setSincronicoAdd([]);
    setAsincronicoAdd([]);
    setEvaluativaAdd([]);
    setRecursosAdd([]);
    setReferenciasAdd([]);
    set_nombre_unidad("");
    set_r_aprendizaje("");
    set_h_presenciales("");
    set_h_trabajoi("");
    set_Corte("");
    set_Data_unidad([]);
    setErrores([]);
  };

  const getError = (llave, errores) => {
    let existe = errores.find((e) => e.llave === llave);
    return existe ? existe.mensaje : "";
  };

  const onChange = (e) => {
    if (e.target.name == "nombre_unidad") {
      set_nombre_unidad(e.target.value);
      if (errores.length > 0) {
        setErrores(errores.filter((er) => er.llave != "nombre_unidad")); // Elimina error cuando se escribe en el campo
      }
    } else if (e.target.name == "r_aprendizaje") {
      set_r_aprendizaje(e.target.value);
      if (errores.length > 0) {
        setErrores(errores.filter((er) => er.llave != "r_aprendizaje")); // Elimina error cuando se escribe en el campo
      }
    } else if (e.target.name == "h_presenciales") {
      set_h_presenciales(e.target.value);
      if (errores.length > 0) {
        setErrores(errores.filter((er) => er.llave != "h_presenciales")); // Elimina error cuando se escribe en el campo
      }
    } else if (e.target.name == "h_trabajoi") {
      set_h_trabajoi(e.target.value);
      if (errores.length > 0) {
        setErrores(errores.filter((er) => er.llave != "h_trabajoi")); // Elimina error cuando se escribe en el campo
      }
    } else if (e.target.name == "corte") {
      set_Corte(e.target.value);
      if (errores.length > 0) {
        setErrores(errores.filter((er) => er.llave != "corte")); // Elimina error cuando se escribe en el campo
      }
    }
  };

  const onSubmit = async (opc) => {
    setErrores(
      await validateInputs([
        { value: nombre_unidad, name: "nombre_unidad", err: { empty: false } },
        { value: r_aprendizaje, name: "r_aprendizaje", err: { empty: false } },
        {
          value: h_presenciales,
          name: "h_presenciales",
          err: { empty: false },
          value2: "number",
        },
        {
          value: h_trabajoi,
          name: "h_trabajoi",
          err: { empty: false },
          value2: "number",
        },
        { value: corte, name: "corte", err: { empty: false } },
        {
          value: IndicadoresAdd,
          name: "ind",
          err: { empty: false },
          value2: "array",
        },
        {
          value: ContenidosAdd,
          name: "cont",
          err: { empty: false },
          value2: "array",
        },
        {
          value: SincronicoAdd,
          name: "est_pre",
          err: { empty: false },
          value2: "array",
        },
        {
          value: AsincronicoAdd,
          name: "est_ind",
          err: { empty: false },
          value2: "array",
        },
        {
          value: EvaluativaAdd,
          name: "est_eva",
          err: { empty: false },
          value2: "array",
        },
        {
          value: RecursosAdd,
          name: "rec_edu",
          err: { empty: false },
          value2: "array",
        },
        {
          value: ReferenciasAdd,
          name: "ref",
          err: { empty: false },
          value2: "array",
        },
      ])
    );
    if (
      errores.length == 0 &&
      IndicadoresAdd.length != 0 &&
      ContenidosAdd.length != 0 &&
      AsincronicoAdd.length != 0 &&
      SincronicoAdd.length != 0 &&
      EvaluativaAdd.length != 0 &&
      RecursosAdd.length != 0 &&
      ReferenciasAdd.length != 0
    ) {
      enviarDatos(opc);
    }
  };

  const validateInputs = (campos = []) => {
    return new Promise((resolve) => {
      let errores = [];
      campos.map(({ value, name, err, value2 }) => {
        if (value2 == "number" && isNaN(value))
          errores.push({
            llave: name,
            mensaje: `Este campo es valor númerico`,
          });
        if (value2 == "array" && value.length == 0)
          errores.push({
            llave: name,
            mensaje: `El número de registros debe ser mayor a cero`,
          });
        if (err.empty && value.replace(/\s+/g, "") == "" && value2 != "array")
          errores.push({ llave: name, mensaje: `Este campo es obligatorio` });
        if (err.empty == false && value == 0 && value2 != "array")
          errores.push({ llave: name, mensaje: `Este campo es obligatorio` });
      });
      resolve(errores);
    });
  };

  const enviarDatos = (opc) => {
    setCargando(true);
    let data = {
      nombre: nombre_unidad,
      resul_aprendizaje: r_aprendizaje,
      hrs_presenciales: h_presenciales,
      hrs_trabajo_indp: h_trabajoi,
      corte: corte,
      plan: id_plan,
    };
    if (opc == 1) {
      guardar_unidad(data);
    } else {
      modificarUnidad(data_unidad.id, data);
    }
  };

  const guardar_unidad = async (data) => {
    let form = await crear_form_data(data);
    formulario(
      `api/v1.0/sisef/crear_und_formacion`,
      form,
      "post",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            Set_Modal_Crear(false);
            IndicadoresAdd.forEach((comp, ind) => guardar_indicador(comp));
            ContenidosAdd.forEach((comp, ind) => guardar_indicador(comp));
            SincronicoAdd.forEach((comp, ind) => guardar_estrategia(comp));
            AsincronicoAdd.forEach((comp, ind) => guardar_estrategia(comp));
            EvaluativaAdd.forEach((comp, ind) => guardar_estrategia(comp));
            RecursosAdd.forEach((comp, ind) => guardar_recurso(comp));
            ReferenciasAdd.forEach((comp, ind) => guardar_referencia(comp));
            obtenerProcesos();
            reset();
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        dispatch(
          actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
        );
        setCargando(false);
      }
    );
  };

  const guardar_indicador = async (data) => {
    let primera_vez = 0;
    data.id_und = data_unidad.id ? data_unidad.id : primera_vez;
    let form = await crear_form_data(data);
    formulario(
      `api/v1.0/sisef/crear_indicadores`,
      form,
      "post",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
      }
    );
  };

  const guardar_estrategia = async (data) => {
    let primera_vez = 0;
    data.id_und = data_unidad.id ? data_unidad.id : primera_vez;
    let form = await crear_form_data(data);
    formulario(
      `api/v1.0/sisef/crear_estrategias`,
      form,
      "post",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            // titulo = resp.titulo;
          } else {
            tipo = "info";
            // titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        // actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  };

  const guardar_recurso = async (data) => {
    let primera_vez = 0;
    data.id_und = data_unidad.id ? data_unidad.id : primera_vez;
    let form = await crear_form_data(data);
    formulario(
      `api/v1.0/sisef/crear_recursos`,
      form,
      "post",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        // actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  };

  const guardar_referencia = async (data) => {
    let primera_vez = 0;
    data.id_und = data_unidad.id ? data_unidad.id : primera_vez;
    let form = await crear_form_data(data);
    formulario(
      `api/v1.0/sisef/crear_referencias`,
      form,
      "post",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        // actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  };

  const modificarUnidad = async (id, data) => {
    console.log("Hola");
    let form = await crear_form_data(data);
    formulario(
      `api/v1.0/sisef/${id}/modificar_unidad`,
      form,
      "patch",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            obtenerProcesos();
            IndicadoresAdd.forEach((comp, ind) => {
              if (!comp.id) {
                guardar_indicador(comp);
              }
            });
            ContenidosAdd.forEach((comp, ind) => {
              if (!comp.id) {
                guardar_indicador(comp);
              }
            });
            SincronicoAdd.forEach((comp, ind) => {
              if (!comp.id) {
                guardar_estrategia(comp);
              }
            });
            AsincronicoAdd.forEach((comp, ind) => {
              if (!comp.id) {
                guardar_estrategia(comp);
              }
            });
            EvaluativaAdd.forEach((comp, ind) => {
              if (!comp.id) {
                guardar_estrategia(comp);
              }
            });
            RecursosAdd.forEach((comp, ind) => {
              if (!comp.id) {
                guardar_recurso(comp);
              }
            });
            ReferenciasAdd.forEach((comp, ind) => {
              if (!comp.id) {
                guardar_referencia(comp);
              }
            });
            Set_Modal_Editar(false);
            reset();
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        dispatch(
          actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
        );
      }
    );
  };

  const eliminarUnidad = () => {
    let id = data_unidad.id;
    consulta(
      `api/v1.0/sisef/${id}/eliminar_unidad`,
      {},
      "put",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            obtenerProcesos();
            Set_Modal_Eliminar(false);
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        dispatch(
          actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
        );
      }
    );
  };

  const actions = (rowData) => {
    const LlamarContenidos = async (row) => {
      let contenido = await ObtenerInformacion(
        row.id,
        "cont_sisef",
        "listar_indicadores"
      );
      let aux = [];
      contenido.forEach((und) => {
        aux.push({
          id: und.id,
          info: und.info,
          tipo: "cont_sisef",
        });
      });
      setContenidosAdd(aux);
    };

    const LlamarIndicadores = async (row) => {
      let indicador = await ObtenerInformacion(
        row.id,
        "ind_dsp",
        "listar_indicadores"
      );
      let aux = [];
      indicador.forEach((und) => {
        aux.push({
          id: und.id,
          info: und.info,
          tipo: "ind_dsp",
        });
      });
      setIndicadoresAdd(aux);
    };

    const LlamarSincronico = async (row) => {
      let estrategia = await ObtenerInformacion(
        row.id,
        "est_sincr",
        "listar_estrategias"
      );
      let aux = [];
      estrategia.forEach((und) => {
        aux.push({
          id: und.id,
          nombre: und.nombre,
          nivel_nombre: und.nivel.nombre,
          nivel: und.nivel.id,
          tipo: "est_sincr",
        });
      });
      setSincronicoAdd(aux);
    };

    const LlamarAsincronico = async (row) => {
      let estrategia = await ObtenerInformacion(
        row.id,
        "est_asincr",
        "listar_estrategias"
      );
      let aux = [];
      estrategia.forEach((und) => {
        aux.push({
          id: und.id,
          nombre: und.nombre,
          nivel_nombre: und.nivel.nombre,
          nivel: und.nivel.id,
          tipo: "est_asincr",
        });
      });
      setAsincronicoAdd(aux);
    };

    const LlamarEvaluativa = async (row) => {
      let estrategia = await ObtenerInformacion(
        row.id,
        "est_eva",
        "listar_estrategias"
      );
      let aux = [];
      estrategia.forEach((und) => {
        aux.push({
          id: und.id,
          nombre: und.nombre,
          nivel_nombre: und.nivel.nombre,
          nivel: und.nivel.id,
          tipo: "est_eva",
        });
      });
      setEvaluativaAdd(aux);
    };

    const LlamarRecursos = async (row) => {
      let estrategia = await ObtenerInformacion(row.id, 0, "listar_recursos");
      let aux = [];
      estrategia.forEach((und) => {
        aux.push({
          id: und.id,
          recurso: und.recurso,
          tipo_nombre: und.tipo.nombre,
          tipo: und.tipo.id,
          tipo: "",
        });
      });
      setRecursosAdd(aux);
    };

    const LlamarReferencias = async (row) => {
      let referencia = await ObtenerInformacion(
        row.id,
        0,
        "listar_referencias"
      );
      let aux = [];
      referencia.forEach((und) => {
        aux.push({
          id: und.id,
          descripcion: und.descripcion,
          tipo_nombre: und.tipo.nombre,
          tipo: und.tipo.id,
          tipo: "",
        });
      });
      setReferenciasAdd(aux);
    };

    const CompletarDatos = async (row) => {
      row["contenido"] = await ObtenerInformacion(
        row.id,
        "cont_sisef",
        "listar_indicadores"
      );
      row["indicador"] = await ObtenerInformacion(
        row.id,
        "ind_dsp",
        "listar_indicadores"
      );
      row["asincronico"] = await ObtenerInformacion(
        row.id,
        "est_asincr",
        "listar_estrategias"
      );
      row["sincronico"] = await ObtenerInformacion(
        row.id,
        "est_sincr",
        "listar_estrategias"
      );
      row["evaluativa"] = await ObtenerInformacion(
        row.id,
        "est_eva",
        "listar_estrategias"
      );
      row["recursos"] = await ObtenerInformacion(row.id, 0, "listar_recursos");
      row["referencias"] = await ObtenerInformacion(
        row.id,
        0,
        "listar_referencias"
      );
      set_Data_unidad(row);
    };

    const VerDetalle = (row) => {
      CompletarDatos(row);
      Set_Modal_Detalle(true);
    };

    const Eliminar = (row) => {
      set_Data_unidad(row);
      Set_Modal_Eliminar(true);
    };

    const Editar = (row) => {
      LlamarIndicadores(row);
      LlamarContenidos(row);
      LlamarSincronico(row);
      LlamarAsincronico(row);
      LlamarEvaluativa(row);
      LlamarRecursos(row);
      LlamarReferencias(row);
      set_nombre_unidad(row.nombre);
      set_r_aprendizaje(row.resultado);
      set_h_presenciales(row.horas_p);
      set_h_trabajoi(row.horas_Ti);
      set_Corte(row.corte_cod);
      Set_Modal_Editar(true);
      set_Data_unidad(row);
    };
    return (
      <div>
        {" "}
        <BtnDetalle callback={() => VerDetalle(rowData)} />{" "}
        <BtnEliminar callback={() => Eliminar(rowData)} />{" "}
        <BtnEditar callback={() => Editar(rowData)} />
      </div>
    );
  };

  const handleClickOpenAdd = (opc) => {
    if (opc == 1) {
      SetModalAddIndi(true);
    } else if (opc == 2) {
      SetModalAddConte(true);
    } else if (opc == 3) {
      SetModalAddSincronico(true);
    } else if (opc == 4) {
      SetModalAddAsincronico(true);
    } else if (opc == 5) {
      SetModalAddEvaluativa(true);
    } else if (opc == 6) {
      SetModalAddRecurso(true);
    } else if (opc == 7) {
      SetModalAddRef(true);
    }
  };

  const handleClickOpenDelete = (i, opc) => {
    if (opc == 1) {
      let aux = IndicadoresAdd.filter((ind, iind) => iind != i);
      // let aux= IndicadoresAdd.slice(0, i).concat(IndicadoresAdd.slice(i + 1, IndicadoresAdd.length))
      setIndicadoresAdd(aux);
      handleClickOpenDetails(
        aux,
        "Indicadores de desempeño",
        opc,
        "eliminar_indicador"
      );
    } else if (opc == 2) {
      let aux = ContenidosAdd.filter((ind, iind) => iind != i);
      setContenidosAdd(aux);
      handleClickOpenDetails(aux, "Contenidos", opc, "eliminar_indicador");
    } else if (opc == 3) {
      let aux = SincronicoAdd.filter((ind, iind) => iind != i);
      setSincronicoAdd(aux);
      handleClickOpenDetails(
        aux,
        "Estrategia de trabajo presencial",
        "eliminar_estrategia"
      );
    } else if (opc == 4) {
      let aux = AsincronicoAdd.filter((ind, iind) => iind != i);
      setAsincronicoAdd(aux);
      handleClickOpenDetails(
        aux,
        "Estrategias de trabajo independiente",
        opc,
        "eliminar_estrategia"
      );
    } else if (opc == 5) {
      let aux = EvaluativaAdd.filter((ind, iind) => iind != i);
      setEvaluativaAdd(aux);
      handleClickOpenDetails(
        aux,
        "Estrategias de trabajo independiente",
        opc,
        "eliminar_estrategia"
      );
    } else if (opc == 6) {
      let aux = RecursosAdd.filter((ind, iind) => iind != i);
      setRecursosAdd(aux);
      handleClickOpenDetails(
        aux,
        "Recursos educativos",
        opc,
        "eliminar_recurso"
      );
    } else if (opc == 7) {
      let aux = ReferenciasAdd.filter((ind, iind) => iind != i);
      setReferenciasAdd(aux);
      handleClickOpenDetails(aux, "Referencias", opc, "eliminar_referencia");
    }
  };

  const handleClickOpenDetails = (data, titulo, componente, tabla) => {
    let aux = [];
    data.map((item, i) => {
      aux.push({
        posc: i,
        nombre: item.nombre
          ? item.nombre
          : item.info
            ? item.info
            : item.descripcion
              ? item.descripcion
              : item.recurso,
        tipo: item.tipo_nombre
          ? item.tipo_nombre
          : item.nivel_nombre
            ? item.nivel_nombre
            : "",
        opcion: componente,
        id: item.id,
        tabla: tabla,
      });
    });
    SetComponentes(aux);
    SetTituloComponente(titulo);
    SetModalComponentes(true);
  };

  const handleClickCerrar = () => {
    Set_Modal_Crear(false);
    Set_Modal_Editar(false);
    reset();
  };

  return (
    <div>
      <Dialog
        open={Modal}
        onClose={() => cerrarModal(false)}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <AppBarModal
          titulo="Unidades de formación"
          mostrarModal={() => cerrarModal(false)}
          titulo_accion="Unidadades de formación"
          accion=""
        />
        <DialogContent style={{ padding: 0 }} className="scroll">
          {
            !cargando ?
              <ListarDatos
                id="tbl_listar_und_formacion"
                datos={unidades}
                opciones={false}
                agregar={true}
                buscar={true}
                actAgregar={() => Set_Modal_Crear(true)}
                mensajeVacio={<p style={{ justifyContent: 'center', margin: '0px 12%', fontSize: '15px' }}>Por el momento no ha sido agregada ninguna Unidad de formación, Haz clic en el botón para poder agregar la Unidad de formación</p>}
                css={{
                  list: { padding: "0px 0px 20px 0px" },
                  appBar: { padding: "0px 0px 0px 0px" },
                }}
                acciones={(row) => actions(row)}
                espacioTexto={9}
                titulo={"Listado de U. Formación"}
                fila_principal={(row) => `${row.nombre}`}
                filas={[
                  {
                    mostrar: ({ nombre }) => `${nombre}`,
                    id: "nombre",
                    enLista: false,
                  },
                  {
                    mostrar: ({ resultado }) => `${resultado}`,
                    id: "resultado",
                    enLista: true,
                  },
                  {
                    nombre: "Hr. Presenciales: ",
                    mostrar: ({ horas_p }) => `${horas_p}`,
                    id: "horas_p",
                    enLista: true,
                  },
                  {
                    nombre: "Hr. Trabajo Indp: ",
                    mostrar: ({ horas_Ti }) => `${horas_Ti}`,
                    id: "horas_Ti",
                    enLista: true,
                  },
                  {
                    mostrar: ({ corte_s }) => `${corte_s}`,
                    id: "corte_s",
                    enLista: true,
                  },
                ]}
              />
              :
              <TareasTerminadas
                imagen={emma_w}
                mensaje="Cargando..."
                widthImg="7%"
                marginTop="7%"
                cargando={cargando}
              />
          }
        </DialogContent>
        <DialogActions>
          <BtnForm texto="Cerrar" callback={() => cerrarModal(false)} />
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={Modal_Crear}
        onClose={handleClickCerrar}
      >
        <AppBarModal
          titulo={"¡ Nueva !"}
          titulo_accion="U. Formación"
          mostrarModal={handleClickCerrar}
        />
        <DialogContent className="scroll">
          {
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl className="form-control">
                  <TextField
                    id="nombre_unidad"
                    type="text"
                    name="nombre_unidad"
                    label="Nombre"
                    multiline
                    defaultValue=""
                    onChange={onChange}
                    error={getError("nombre_unidad", errores).length > 0}
                    helperText={getError("nombre_unidad", errores)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl className="form-control">
                  <TextField
                    id="r_aprendizaje"
                    type="text"
                    name="r_aprendizaje"
                    label="Resultado de aprendizaje"
                    multiline
                    defaultValue=""
                    onChange={onChange}
                    error={getError("r_aprendizaje", errores).length > 0}
                    helperText={getError("r_aprendizaje", errores)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl className="form-control">
                  <TextField
                    id="h_presenciales"
                    type="number"
                    name="h_presenciales"
                    label="Horas presenciales"
                    multiline
                    defaultValue=""
                    onChange={onChange}
                    error={getError("h_presenciales", errores).length > 0}
                    helperText={getError("h_presenciales", errores)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl className="form-control">
                  <TextField
                    id="h_trabajoi"
                    type="number"
                    name="h_trabajoi"
                    label="Horas de trabajo independiente"
                    multiline
                    defaultValue=""
                    onChange={onChange}
                    error={getError("h_trabajoi", errores).length > 0}
                    helperText={getError("h_trabajoi", errores)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl
                  className="form-control"
                  error={getError("corte", errores).length > 0}
                >
                  <InputLabel id="corte_label">Corte</InputLabel>
                  <Select
                    labelId="corte_label"
                    id="corte"
                    name="corte"
                    inputProps={{ min: "0", step: "1" }}
                    fullWidth
                    onChange={onChange}
                  >
                    {cortes_sisef.map((cor) => (
                      <MenuItem key={cor.id} value={cor.id}>
                        {cor.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                  {
                    <FormHelperText>
                      {" "}
                      {getError("corte", errores)}
                    </FormHelperText>
                  }
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl
                  className="form-control"
                  error={getError("ind", errores).length > 0}
                >
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CardActions disableSpacing>
                      <Typography component="div">
                        <Box sx={{}}>Indicadores de desempeño</Box>
                        <Box
                          sx={{
                            lineHeight: 0.5,
                            fontWeight: "normal",
                            m: 1,
                            color: "#757575",
                          }}
                        >
                          N° Registros: {IndicadoresAdd.length}
                        </Box>
                      </Typography>
                      <Box sx={{ flexGrow: 1 }} />
                      <IconButton
                        aria-label="add to favorites"
                        onClick={() => handleClickOpenAdd(1)}
                      >
                        <AddIcon />
                      </IconButton>
                      <BtnDetalle
                        callback={() =>
                          handleClickOpenDetails(
                            IndicadoresAdd,
                            "Indicadores de desempeño",
                            1,
                            "eliminar_indicador"
                          )
                        }
                      />
                    </CardActions>
                    <Divider />
                  </Grid>
                  {<FormHelperText> {getError("ind", errores)}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl
                  className="form-control"
                  error={getError("cont", errores).length > 0}
                >
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CardActions disableSpacing>
                      <Typography component="div">
                        <Box sx={{}}>Contenidos</Box>
                        <Box
                          sx={{
                            lineHeight: 0.5,
                            fontWeight: "normal",
                            m: 1,
                            color: "#757575",
                          }}
                        >
                          N° Registros: {ContenidosAdd.length}
                        </Box>
                      </Typography>
                      <Box sx={{ flexGrow: 1 }} />
                      <IconButton
                        aria-label="add to favorites"
                        onClick={() => handleClickOpenAdd(2)}
                      >
                        <AddIcon />
                      </IconButton>
                      <BtnDetalle
                        callback={() =>
                          handleClickOpenDetails(
                            ContenidosAdd,
                            "Contenidos",
                            2,
                            "eliminar_indicador"
                          )
                        }
                      />
                    </CardActions>
                    <Divider />
                  </Grid>
                  {
                    <FormHelperText>
                      {" "}
                      {getError("cont", errores)}
                    </FormHelperText>
                  }
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl
                  className="form-control"
                  error={getError("est_pre", errores).length > 0}
                >
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CardActions disableSpacing>
                      <Typography component="div">
                        <Box sx={{}}>Estrategias de trabajo presencial</Box>
                        <Box
                          sx={{
                            lineHeight: 0.5,
                            fontWeight: "normal",
                            m: 1,
                            color: "#757575",
                          }}
                        >
                          N° Registros: {SincronicoAdd.length}
                        </Box>
                      </Typography>
                      <Box sx={{ flexGrow: 1 }} />
                      <IconButton
                        aria-label="add to favorites"
                        onClick={() => handleClickOpenAdd(3)}
                      >
                        <AddIcon />
                      </IconButton>
                      <BtnDetalle
                        callback={() =>
                          handleClickOpenDetails(
                            ContenidosAdd,
                            "Contenidos",
                            2,
                            "eliminar_indicador"
                          )
                        }
                      />
                    </CardActions>
                    <Divider />
                  </Grid>
                  {
                    <FormHelperText>
                      {" "}
                      {getError("est_pre", errores)}
                    </FormHelperText>
                  }
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl
                  className="form-control"
                  error={getError("est_ind", errores).length > 0}
                >
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CardActions disableSpacing>
                      <Typography component="div">
                        <Box sx={{}}>Estrategias de trabajo independiente</Box>
                        <Box
                          sx={{
                            lineHeight: 0.5,
                            fontWeight: "normal",
                            m: 1,
                            color: "#757575",
                          }}
                        >
                          N° Registros: {AsincronicoAdd.length}
                        </Box>
                      </Typography>
                      <Box sx={{ flexGrow: 1 }} />
                      <IconButton
                        aria-label="add to favorites"
                        onClick={() => handleClickOpenAdd(4)}
                      >
                        <AddIcon />
                      </IconButton>
                      <BtnDetalle
                        callback={() =>
                          handleClickOpenDetails(
                            AsincronicoAdd,
                            "Estrategias de trabajo independiente",
                            4,
                            "eliminar_estrategia"
                          )
                        }
                      />
                    </CardActions>
                    <Divider />
                  </Grid>
                  {
                    <FormHelperText>
                      {" "}
                      {getError("est_ind", errores)}
                    </FormHelperText>
                  }
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl
                  className="form-control"
                  error={getError("est_eva", errores).length > 0}
                >
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CardActions disableSpacing>
                      <Typography component="div">
                        <Box sx={{}}>Estrategias evaluativas</Box>
                        <Box
                          sx={{
                            lineHeight: 0.5,
                            fontWeight: "normal",
                            m: 1,
                            color: "#757575",
                          }}
                        >
                          N° Registros: {EvaluativaAdd.length}
                        </Box>
                      </Typography>
                      <Box sx={{ flexGrow: 1 }} />
                      <IconButton
                        aria-label="add to favorites"
                        onClick={() => handleClickOpenAdd(5)}
                      >
                        <AddIcon />
                      </IconButton>
                      <BtnDetalle
                        callback={() =>
                          handleClickOpenDetails(
                            EvaluativaAdd,
                            "Estrategias evaluativas",
                            5,
                            "eliminar_estrategia"
                          )
                        }
                      />
                    </CardActions>
                    <Divider />
                  </Grid>
                  {
                    <FormHelperText>
                      {" "}
                      {getError("est_eva", errores)}
                    </FormHelperText>
                  }
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl
                  className="form-control"
                  error={getError("rec_edu", errores).length > 0}
                >
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CardActions disableSpacing>
                      <Typography component="div">
                        <Box sx={{}}>Recursos educativos</Box>
                        <Box
                          sx={{
                            lineHeight: 0.5,
                            fontWeight: "normal",
                            m: 1,
                            color: "#757575",
                          }}
                        >
                          N° Registros: {RecursosAdd.length}
                        </Box>
                      </Typography>
                      <Box sx={{ flexGrow: 1 }} />
                      <IconButton
                        aria-label="add to favorites"
                        onClick={() => handleClickOpenAdd(6)}
                      >
                        <AddIcon />
                      </IconButton>
                      <BtnDetalle
                        callback={() =>
                          handleClickOpenDetails(
                            RecursosAdd,
                            "Recursos educativos",
                            6,
                            "eliminar_recurso"
                          )
                        }
                      />
                    </CardActions>
                    <Divider />
                  </Grid>
                  {
                    <FormHelperText>
                      {" "}
                      {getError("rec_edu", errores)}
                    </FormHelperText>
                  }
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl
                  className="form-control"
                  error={getError("ref", errores).length > 0}
                >
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CardActions disableSpacing>
                      <Typography component="div">
                        <Box sx={{}}>Referencias</Box>
                        <Box
                          sx={{
                            lineHeight: 0.5,
                            fontWeight: "normal",
                            m: 1,
                            color: "#757575",
                          }}
                        >
                          N° Registros: {ReferenciasAdd.length}
                        </Box>
                      </Typography>
                      <Box sx={{ flexGrow: 1 }} />
                      <IconButton
                        aria-label="add to favorites"
                        onClick={() => handleClickOpenAdd(7)}
                      >
                        <AddIcon />
                      </IconButton>
                      <BtnDetalle
                        callback={() =>
                          handleClickOpenDetails(
                            ReferenciasAdd,
                            "Referencias",
                            7,
                            "eliminar_referencia"
                          )
                        }
                      />
                    </CardActions>
                    <Divider />
                  </Grid>
                  {<FormHelperText> {getError("ref", errores)}</FormHelperText>}
                </FormControl>
              </Grid>
            </Grid>
          }
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CANCELAR" callback={handleClickCerrar} />
          <BtnForm texto="GUARDAR" callback={() => onSubmit(1)} />
        </DialogActions>
      </Dialog>
      <Dialog
        open={Modal_Detalle}
        fullWidth={true}
        maxWidth="sm"
        onClose={() => Set_Modal_Detalle(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBarModal
          titulo={"Detalles"}
          mostrarModal={() => Set_Modal_Detalle(false)}
          titulo_accion=""
          accion=""
        />
        <DialogContent style={{ padding: 0 }} className="scroll">
          <Table>
            <TableBody>
              <TableRow key={1}>
                <TableCell />
                <TableCell component="th" scope="row">
                  Nombre
                </TableCell>
                <TableCell align="left">{data_unidad.nombre}</TableCell>
              </TableRow>
              <TableRow key={2}>
                <TableCell />
                <TableCell component="th" scope="row">
                  Resultado de aprendizaje
                </TableCell>
                <TableCell align="left">{data_unidad.resultado}</TableCell>
              </TableRow>
              <TableRow key={3}>
                <TableCell />
                <TableCell component="th" scope="row">
                  Horas Presenciales
                </TableCell>
                <TableCell align="left">{data_unidad.horas_p}</TableCell>
              </TableRow>
              <TableRow key={4}>
                <TableCell />
                <TableCell component="th" scope="row">
                  Horas de trabajo independiente
                </TableCell>
                <TableCell align="left">{data_unidad.horas_Ti}</TableCell>
              </TableRow>
              <TableRow key={5}>
                <TableCell />
                <TableCell component="th" scope="row">
                  Corte
                </TableCell>
                <TableCell align="left">{data_unidad.corte_s}</TableCell>
              </TableRow>
              <TableRow key={6}>
                <TableCell>
                  <IconButton
                    aria-label=" row"
                    size="small"
                    onClick={() => setOpenind(!openind)}
                  >
                    {openind ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" colspan="2">
                  Indicadores
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={3}
                >
                  <Collapse in={openind} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                      <Table size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow>
                            <TableCell>N°</TableCell>
                            <TableCell>Indicador</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data_unidad.indicador?.map((cont, i) => (
                            <TableRow key={cont.id}>
                              <TableCell component="th" scope="row">
                                {i + 1}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {cont.info}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
              <TableRow key={7}>
                <TableCell>
                  <IconButton
                    aria-label=" row"
                    size="small"
                    onClick={() => setOpencont(!opencont)}
                  >
                    {opencont ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" colspan="2">
                  Contenidos
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={3}
                >
                  <Collapse in={opencont} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                      <Table size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow>
                            <TableCell>N°</TableCell>
                            <TableCell>Contenido</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data_unidad.contenido?.map((cont, i) => (
                            <TableRow key={cont.id}>
                              <TableCell component="th" scope="row">
                                {i + 1}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {cont.info}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
              <TableRow key={8}>
                <TableCell>
                  <IconButton
                    aria-label=" row"
                    size="small"
                    onClick={() => setOpensinc(!opensinc)}
                  >
                    {opensinc ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" colspan="2">
                  Estrategias de trabajo presencial
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={3}
                >
                  <Collapse in={opensinc} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                      <Table size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow>
                            <TableCell>N°</TableCell>
                            <TableCell>Nombre</TableCell>
                            <TableCell>Nivel</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data_unidad.sincronico?.map((cont, i) => (
                            <TableRow key={cont.id}>
                              <TableCell component="th" scope="row">
                                {i + 1}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {cont.nombre}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {cont.nivel.nombre}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
              <TableRow key={9}>
                <TableCell>
                  <IconButton
                    aria-label=" row"
                    size="small"
                    onClick={() => setOpenasinc(!openasinc)}
                  >
                    {openasinc ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" colspan="2">
                  Estrategias de trabajo individual
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={3}
                >
                  <Collapse in={openasinc} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                      <Table size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow>
                            <TableCell>N°</TableCell>
                            <TableCell>Nombre</TableCell>
                            <TableCell>Nivel</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data_unidad.asincronico?.map((cont, i) => (
                            <TableRow key={cont.id}>
                              <TableCell component="th" scope="row">
                                {i + 1}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {cont.nombre}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {cont.nivel.nombre}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
              <TableRow key={10}>
                <TableCell>
                  <IconButton
                    aria-label=" row"
                    size="small"
                    onClick={() => setOpeneva(!openeva)}
                  >
                    {openeva ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" colspan="2">
                  Estrategias evaluativas
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={3}
                >
                  <Collapse in={openeva} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                      <Table size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow>
                            <TableCell>N°</TableCell>
                            <TableCell>Nombre</TableCell>
                            <TableCell>Nivel</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data_unidad.evaluativa?.map((cont, i) => (
                            <TableRow key={cont.id}>
                              <TableCell component="th" scope="row">
                                {i + 1}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {cont.nombre}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {cont.nivel.nombre}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
              <TableRow key={11}>
                <TableCell>
                  <IconButton
                    aria-label=" row"
                    size="small"
                    onClick={() => setOpenrec(!openrec)}
                  >
                    {openrec ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" colspan="2">
                  Recursos Educativos
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={3}
                >
                  <Collapse in={openrec} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                      <Table size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow>
                            <TableCell>N°</TableCell>
                            <TableCell>Tipo</TableCell>
                            <TableCell>Recurso</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data_unidad.recursos?.map((cont, i) => (
                            <TableRow key={cont.id}>
                              <TableCell component="th" scope="row">
                                {i + 1}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {cont.tipo.nombre}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {cont.recurso}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
              <TableRow key={12}>
                <TableCell>
                  <IconButton
                    aria-label=" row"
                    size="small"
                    onClick={() => setOpenref(!openref)}
                  >
                    {openref ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" colspan="2">
                  Referencias
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={3}
                >
                  <Collapse in={openref} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                      <Table size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow>
                            <TableCell>N°</TableCell>
                            <TableCell>Tipo</TableCell>
                            <TableCell>Descripcion</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data_unidad.referencias?.map((cont, i) => (
                            <TableRow key={cont.id}>
                              <TableCell component="th" scope="row">
                                {i + 1}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {cont.tipo.nombre}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {cont.descripcion}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <BtnForm texto="Cerrar" callback={() => Set_Modal_Detalle(false)} />
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={Modal_Editar}
        onClose={handleClickCerrar}
      >
        <AppBarModal
          titulo={"¡ Modificar !"}
          titulo_accion="U. Formación"
          mostrarModal={handleClickCerrar}
        />
        <DialogContent className="scroll">
          {
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl className="form-control">
                  <TextField
                    id="nombre_unidad"
                    type="text"
                    name="nombre_unidad"
                    label="Nombre"
                    multiline
                    value={nombre_unidad}
                    defaultValue=""
                    onChange={onChange}
                    error={getError("nombre_unidad", errores).length > 0}
                    helperText={getError("nombre_unidad", errores)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl className="form-control">
                  <TextField
                    id="r_aprendizaje"
                    type="text"
                    name="r_aprendizaje"
                    label="Resultado de aprendizaje"
                    multiline
                    value={r_aprendizaje}
                    defaultValue=""
                    onChange={onChange}
                    error={getError("r_aprendizaje", errores).length > 0}
                    helperText={getError("r_aprendizaje", errores)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl className="form-control">
                  <TextField
                    id="h_presenciales"
                    type="number"
                    name="h_presenciales"
                    label="Horas presenciales"
                    multiline
                    value={h_presenciales}
                    defaultValue=""
                    onChange={onChange}
                    error={getError("h_presenciales", errores).length > 0}
                    helperText={getError("h_presenciales", errores)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl className="form-control">
                  <TextField
                    id="h_trabajoi"
                    type="number"
                    name="h_trabajoi"
                    label="Horas de trabajo independiente"
                    multiline
                    value={h_trabajoi}
                    defaultValue=""
                    onChange={onChange}
                    error={getError("h_trabajoi", errores).length > 0}
                    helperText={getError("h_trabajoi", errores)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl
                  className="form-control"
                  error={getError("corte", errores).length > 0}
                >
                  <InputLabel id="corte_label">Corte</InputLabel>
                  <Select
                    labelId="corte_label"
                    id="corte"
                    name="corte"
                    value={corte}
                    inputProps={{ min: "0", step: "1" }}
                    fullWidth
                    onChange={onChange}
                  >
                    {cortes_sisef.map((cor) => (
                      <MenuItem key={cor.id} value={cor.id}>
                        {cor.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                  {
                    <FormHelperText>
                      {" "}
                      {getError("corte", errores)}
                    </FormHelperText>
                  }
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl
                  className="form-control"
                  error={getError("ind", errores).length > 0}
                >
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CardActions disableSpacing>
                      <Typography component="div">
                        <Box sx={{}}>Indicadores de desempeño</Box>
                        <Box
                          sx={{
                            lineHeight: 0.5,
                            fontWeight: "normal",
                            m: 1,
                            color: "#757575",
                          }}
                        >
                          N° Registros: {IndicadoresAdd.length}
                        </Box>
                      </Typography>
                      <Box sx={{ flexGrow: 1 }} />
                      <IconButton
                        aria-label="add to favorites"
                        onClick={() => handleClickOpenAdd(1)}
                      >
                        <AddIcon />
                      </IconButton>
                      <BtnDetalle
                        callback={() =>
                          handleClickOpenDetails(
                            IndicadoresAdd,
                            "Indicadores de desempeño",
                            1,
                            "eliminar_indicador"
                          )
                        }
                      />
                    </CardActions>
                    <Divider />
                  </Grid>
                  {<FormHelperText> {getError("ind", errores)}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl
                  className="form-control"
                  error={getError("cont", errores).length > 0}
                >
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CardActions disableSpacing>
                      <Typography component="div">
                        <Box sx={{}}>Contenidos</Box>
                        <Box
                          sx={{
                            lineHeight: 0.5,
                            fontWeight: "normal",
                            m: 1,
                            color: "#757575",
                          }}
                        >
                          N° Registros: {ContenidosAdd.length}
                        </Box>
                      </Typography>
                      <Box sx={{ flexGrow: 1 }} />
                      <IconButton
                        aria-label="add to favorites"
                        onClick={() => handleClickOpenAdd(2)}
                      >
                        <AddIcon />
                      </IconButton>
                      <BtnDetalle
                        callback={() =>
                          handleClickOpenDetails(
                            ContenidosAdd,
                            "Contenidos",
                            2,
                            "eliminar_indicador"
                          )
                        }
                      />
                    </CardActions>
                    <Divider />
                  </Grid>
                  {
                    <FormHelperText>
                      {" "}
                      {getError("cont", errores)}
                    </FormHelperText>
                  }
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl
                  className="form-control"
                  error={getError("est_pre", errores).length > 0}
                >
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CardActions disableSpacing>
                      <Typography component="div">
                        <Box sx={{}}>Estrategias de trabajo presencial</Box>
                        <Box
                          sx={{
                            lineHeight: 0.5,
                            fontWeight: "normal",
                            m: 1,
                            color: "#757575",
                          }}
                        >
                          N° Registros: {SincronicoAdd.length}
                        </Box>
                      </Typography>
                      <Box sx={{ flexGrow: 1 }} />
                      <IconButton
                        aria-label="add to favorites"
                        onClick={() => handleClickOpenAdd(3)}
                      >
                        <AddIcon />
                      </IconButton>
                      <BtnDetalle
                        callback={() =>
                          handleClickOpenDetails(
                            SincronicoAdd,
                            "Estrategias de trabajo presencial",
                            3,
                            "eliminar_estrategia"
                          )
                        }
                      />
                    </CardActions>
                    <Divider />
                  </Grid>
                  {
                    <FormHelperText>
                      {" "}
                      {getError("est_pre", errores)}
                    </FormHelperText>
                  }
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl
                  className="form-control"
                  error={getError("est_ind", errores).length > 0}
                >
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CardActions disableSpacing>
                      <Typography component="div">
                        <Box sx={{}}>Estrategias de trabajo independiente</Box>
                        <Box
                          sx={{
                            lineHeight: 0.5,
                            fontWeight: "normal",
                            m: 1,
                            color: "#757575",
                          }}
                        >
                          N° Registros: {AsincronicoAdd.length}
                        </Box>
                      </Typography>
                      <Box sx={{ flexGrow: 1 }} />
                      <IconButton
                        aria-label="add to favorites"
                        onClick={() => handleClickOpenAdd(4)}
                      >
                        <AddIcon />
                      </IconButton>
                      <BtnDetalle
                        callback={() =>
                          handleClickOpenDetails(
                            AsincronicoAdd,
                            "Estrategias de trabajo independiente",
                            4,
                            "eliminar_estrategia"
                          )
                        }
                      />
                    </CardActions>
                    <Divider />
                  </Grid>
                  {
                    <FormHelperText>
                      {" "}
                      {getError("est_ind", errores)}
                    </FormHelperText>
                  }
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl
                  className="form-control"
                  error={getError("est_eva", errores).length > 0}
                >
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CardActions disableSpacing>
                      <Typography component="div">
                        <Box sx={{}}>Estrategias evaluativas</Box>
                        <Box
                          sx={{
                            lineHeight: 0.5,
                            fontWeight: "normal",
                            m: 1,
                            color: "#757575",
                          }}
                        >
                          N° Registros: {EvaluativaAdd.length}
                        </Box>
                      </Typography>
                      <Box sx={{ flexGrow: 1 }} />
                      <IconButton
                        aria-label="add to favorites"
                        onClick={() => handleClickOpenAdd(5)}
                      >
                        <AddIcon />
                      </IconButton>
                      <BtnDetalle
                        callback={() =>
                          handleClickOpenDetails(
                            EvaluativaAdd,
                            "Estrategias evaluativas",
                            5,
                            "eliminar_estrategia"
                          )
                        }
                      />
                    </CardActions>
                    <Divider />
                  </Grid>
                  {
                    <FormHelperText>
                      {" "}
                      {getError("est_eva", errores)}
                    </FormHelperText>
                  }
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl
                  className="form-control"
                  error={getError("rec_edu", errores).length > 0}
                >
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CardActions disableSpacing>
                      <Typography component="div">
                        <Box sx={{}}>Recursos educativos</Box>
                        <Box
                          sx={{
                            lineHeight: 0.5,
                            fontWeight: "normal",
                            m: 1,
                            color: "#757575",
                          }}
                        >
                          N° Registros: {RecursosAdd.length}
                        </Box>
                      </Typography>
                      <Box sx={{ flexGrow: 1 }} />
                      <IconButton
                        aria-label="add to favorites"
                        onClick={() => handleClickOpenAdd(6)}
                      >
                        <AddIcon />
                      </IconButton>
                      <BtnDetalle
                        callback={() =>
                          handleClickOpenDetails(
                            RecursosAdd,
                            "Recursos educativos",
                            6,
                            "eliminar_recurso"
                          )
                        }
                      />
                    </CardActions>
                    <Divider />
                  </Grid>
                  {
                    <FormHelperText>
                      {" "}
                      {getError("rec_edu", errores)}
                    </FormHelperText>
                  }
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl
                  className="form-control"
                  error={getError("ref", errores).length > 0}
                >
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CardActions disableSpacing>
                      <Typography component="div">
                        <Box sx={{}}>Referencias</Box>
                        <Box
                          sx={{
                            lineHeight: 0.5,
                            fontWeight: "normal",
                            m: 1,
                            color: "#757575",
                          }}
                        >
                          N° Registros: {ReferenciasAdd.length}
                        </Box>
                      </Typography>
                      <Box sx={{ flexGrow: 1 }} />
                      <IconButton
                        aria-label="add to favorites"
                        onClick={() => handleClickOpenAdd(7)}
                      >
                        <AddIcon />
                      </IconButton>
                      <BtnDetalle
                        callback={() =>
                          handleClickOpenDetails(
                            ReferenciasAdd,
                            "Referencias",
                            7,
                            "eliminar_referencia"
                          )
                        }
                      />
                    </CardActions>
                    <Divider />
                  </Grid>
                  {<FormHelperText> {getError("ref", errores)}</FormHelperText>}
                </FormControl>
              </Grid>
            </Grid>
          }
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CANCELAR" callback={handleClickCerrar} />
          <BtnForm texto="MODIFICAR" callback={() => onSubmit(2)} />
        </DialogActions>
      </Dialog>

      <AddIndicadores
        Open={ModalAddIndi}
        Close={SetModalAddIndi}
        IndicadoresAdd={IndicadoresAdd}
        setIndicadoresAdd={setIndicadoresAdd}
        getError={getError}
        validateInputs={validateInputs}
        setErrores={setErrores}
        errores={errores}
      />
      <AddContenidos
        Open={ModalAddConte}
        Close={SetModalAddConte}
        ContenidosAdd={ContenidosAdd}
        setContenidosAdd={setContenidosAdd}
        getError={getError}
        validateInputs={validateInputs}
        setErrores={setErrores}
        errores={errores}
      />
      <AddEstrategiaTrabajoPresencial
        Open={ModalAddSincronico}
        Close={SetModalAddSincronico}
        SincronicoAdd={SincronicoAdd}
        setSincronicoAdd={setSincronicoAdd}
        getError={getError}
        setNiveles={setNiveles}
        niveles={niveles}
        validateInputs={validateInputs}
        setErrores={setErrores}
        errores={errores}
      />
      <AddEstrategiaTrabajoIndependiente
        Open={ModalAddAsincronico}
        Close={SetModalAddAsincronico}
        AsincronicoAdd={AsincronicoAdd}
        setAsincronicoAdd={setAsincronicoAdd}
        getError={getError}
        setNiveles={setNiveles}
        niveles={niveles}
        validateInputs={validateInputs}
        setErrores={setErrores}
        errores={errores}
      />
      <AddEstrategiaEvaluativa
        Open={ModalAddEvaluativa}
        Close={SetModalAddEvaluativa}
        EvaluativaAdd={EvaluativaAdd}
        setEvaluativaAdd={setEvaluativaAdd}
        getError={getError}
        setNiveles={setNiveles}
        niveles={niveles}
        validateInputs={validateInputs}
        setErrores={setErrores}
        errores={errores}
      />
      <AddRecursosEducativos
        Open={ModalAddRecurso}
        Close={SetModalAddRecurso}
        RecursosAdd={RecursosAdd}
        setRecursosAdd={setRecursosAdd}
        getError={getError}
        setTiposRec={setTiposRec}
        TiposRec={TiposRec}
        validateInputs={validateInputs}
        setErrores={setErrores}
        errores={errores}
      />
      <AddReferencias
        Open={ModalAddRef}
        Close={SetModalAddRef}
        ReferenciasAdd={ReferenciasAdd}
        setReferenciasAdd={setReferenciasAdd}
        getError={getError}
        setTiposRef={setTiposRef}
        TiposRef={TiposRef}
        validateInputs={validateInputs}
        setErrores={setErrores}
        errores={errores}
      />
      <VerComponentes
        ModalComponentes={ModalComponentes}
        SetModalComponentes={SetModalComponentes}
        Componentes={Componentes}
        TituloComponente={TituloComponente}
        Delete={handleClickOpenDelete}
      />
      <ConfirmarAccion
        titulo="¿ Eliminar Unidad de formación ?"
        dato={{ id: 0 }}
        mensaje1="¿Estás seguro(a) que deseas eliminar la unidad?"
        modalConAccion={Modal_Eliminar}
        mostrarModalConfAccion={() => Set_Modal_Eliminar(false)}
        ejecutarAccion={eliminarUnidad}
      />
    </div>
  );
}

const AddIndicadores = (props) => {
  const [errores, setErrores] = React.useState([]);
  const [Indicadores_dsp, setIndicadores_dsp] = React.useState("");

  const onChange = (e) => {
    if (e.target.name == "ind_desempeno") {
      setIndicadores_dsp(e.target.value);
      if (errores.length > 0) {
        setErrores(errores.filter((er) => er.llave != "ind_desempeno"));
      }
    }
  };

  const AddArrayInd = async () => {
    if (Indicadores_dsp == "") {
      setErrores(
        await props.validateInputs([
          {
            value: Indicadores_dsp,
            name: "ind_desempeno",
            err: { empty: true },
          },
        ])
      );
    } else {
      let nuevo = [];
      nuevo["tipo"] = "ind_dsp";
      nuevo["info"] = Indicadores_dsp;
      props.IndicadoresAdd.push(nuevo);
      setIndicadores_dsp("");
      props.Close(false);
      if (props.errores.length > 0) {
        props.setErrores(props.errores.filter((er) => er.llave != "ind")); // Elimina error cuando se escribe en el campo
      }
    }
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={props.Open}
        onClose={() => props.Close(false)}
        aria-labelledby="form-dialog-title"
      >
        <AppBarModal
          titulo="Ingrese el indicador"
          mostrarModal={() => props.Close(false)}
          titulo_accion=""
          accion=""
        />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl className="form-control">
                <TextField
                  id="ind_desempeno"
                  type="text"
                  name="ind_desempeno"
                  label="Indicadores de desempeño"
                  multiline
                  rowsMax={6}
                  defaultValue=""
                  onChange={onChange}
                  error={props.getError("ind_desempeno", errores).length > 0}
                  helperText={props.getError("ind_desempeno", errores)}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <BtnForm
            texto="GUARDAR"
            variant="contained"
            color="primary"
            callback={() => AddArrayInd()}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

const AddContenidos = (props) => {
  const [errores, setErrores] = React.useState([]);
  const [contenidos, setContenidos] = React.useState("");

  const onChange = (e) => {
    if (e.target.name == "contenido") {
      setContenidos(e.target.value);
      if (errores.length > 0) {
        setErrores(errores.filter((er) => er.llave != "contenido"));
      }
    }
  };

  const AddArrayInd = async () => {
    if (contenidos == "") {
      setErrores(
        await props.validateInputs([
          { value: contenidos, name: "contenido", err: { empty: true } },
        ])
      );
    } else {
      let nuevo = [];
      nuevo["info"] = contenidos;
      nuevo["tipo"] = "cont_sisef";
      props.ContenidosAdd.push(nuevo);
      setContenidos("");
      props.Close(false);
      if (props.errores.length > 0) {
        props.setErrores(props.errores.filter((er) => er.llave != "cont")); // Elimina error cuando se escribe en el campo
      }
    }
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={props.Open}
        onClose={() => props.Close(false)}
        aria-labelledby="form-dialog-title"
      >
        <AppBarModal
          titulo="Ingrese el contenido"
          mostrarModal={() => props.Close(false)}
          titulo_accion=""
          accion=""
        />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl className="form-control">
                <TextField
                  id="contenido"
                  type="text"
                  name="contenido"
                  label="Contenidos"
                  multiline
                  rowsMax={6}
                  defaultValue=""
                  onChange={onChange}
                  error={props.getError("contenido", errores).length > 0}
                  helperText={props.getError("contenido", errores)}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <BtnForm
            texto="GUARDAR"
            variant="contained"
            color="primary"
            callback={() => AddArrayInd()}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

const AddEstrategiaTrabajoPresencial = (props) => {
  const [errores, setErrores] = React.useState([]);
  const [estrategia, setEstrategia] = React.useState("");
  const [nivel, setNivel] = React.useState("");

  const onChange = (e) => {
    if (e.target.name == "sincronico") {
      setEstrategia(e.target.value);
      if (errores.length > 0) {
        setErrores(errores.filter((er) => er.llave != "sincronico"));
      }
    } else if (e.target.name == "nivel") {
      setNivel(e.target.value);
      if (errores.length > 0) {
        setErrores(errores.filter((er) => er.llave != "nivel"));
      }
    }
  };

  const AddArrayInd = async () => {
    if (estrategia == "" || nivel == "") {
      setErrores(
        await props.validateInputs([
          { value: estrategia, name: "sincronico", err: { empty: true } },
          { value: nivel, name: "nivel", err: { empty: true } },
        ])
      );
    } else {
      let nuevo = [];
      nuevo["nombre"] = estrategia;
      nuevo["nivel"] = nivel;
      nuevo["nivel_nombre"] = props.niveles.filter(
        (item) => item.id == nivel
      )[0].nombre;
      nuevo["tipo"] = "est_sincr";
      props.SincronicoAdd.push(nuevo);
      setEstrategia("");
      setNivel("");
      props.Close(false);
      if (props.errores.length > 0) {
        props.setErrores(props.errores.filter((er) => er.llave != "est_pre")); // Elimina error cuando se escribe en el campo
      }
    }
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={props.Open}
        onClose={() => props.Close(false)}
        aria-labelledby="form-dialog-title"
      >
        <AppBarModal
          titulo="Ingrese la estrategia"
          mostrarModal={() => props.Close(false)}
          titulo_accion=""
          accion=""
        />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl className="form-control">
                <TextField
                  id="sincronico"
                  type="text"
                  name="sincronico"
                  label="Estrategia"
                  multiline
                  rowsMax={6}
                  defaultValue=""
                  onChange={onChange}
                  error={props.getError("sincronico", errores).length > 0}
                  helperText={props.getError("sincronico", errores)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl
                className="form-control"
                error={props.getError("nivel", errores).length > 0}
              >
                <InputLabel id="nivel_label">Nivel</InputLabel>
                <Select
                  labelId="nivel_label"
                  id="nivel"
                  name="nivel"
                  inputProps={{ min: "0", step: "1" }}
                  fullWidth
                  onChange={onChange}
                >
                  {props.niveles.map((niv) => (
                    <MenuItem key={niv.id} value={niv.id}>
                      {niv.nombre}
                    </MenuItem>
                  ))}
                </Select>
                {
                  <FormHelperText>
                    {" "}
                    {props.getError("nivel", errores)}
                  </FormHelperText>
                }
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <BtnForm
            texto="GUARDAR"
            variant="contained"
            color="primary"
            callback={() => AddArrayInd()}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

const AddEstrategiaTrabajoIndependiente = (props) => {
  const [errores, setErrores] = React.useState([]);
  const [estrategia, setEstrategia] = React.useState("");
  const [nivel, setNivel] = React.useState("");

  const onChange = (e) => {
    if (e.target.name == "asincronico") {
      setEstrategia(e.target.value);
      if (errores.length > 0) {
        setErrores(errores.filter((er) => er.llave != "asincronico"));
      }
    } else if (e.target.name == "nivel") {
      setNivel(e.target.value);
      if (errores.length > 0) {
        setErrores(errores.filter((er) => er.llave != "nivel"));
      }
    }
  };

  const AddArrayInd = async () => {
    if (estrategia == "" || nivel == "") {
      setErrores(
        await props.validateInputs([
          { value: estrategia, name: "asincronico", err: { empty: true } },
          { value: nivel, name: "nivel", err: { empty: true } },
        ])
      );
    } else {
      let nuevo = [];
      nuevo["nombre"] = estrategia;
      nuevo["nivel"] = nivel;
      nuevo["nivel_nombre"] = props.niveles.filter(
        (item) => item.id == nivel
      )[0].nombre;
      nuevo["tipo"] = "est_asincr";
      props.AsincronicoAdd.push(nuevo);
      setEstrategia("");
      setNivel("");
      props.Close(false);
      if (props.errores.length > 0) {
        props.setErrores(props.errores.filter((er) => er.llave != "est_ind")); // Elimina error cuando se escribe en el campo
      }
    }
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={props.Open}
        onClose={() => props.Close(false)}
        aria-labelledby="form-dialog-title"
      >
        <AppBarModal
          titulo="Ingrese la estrategia"
          mostrarModal={() => props.Close(false)}
          titulo_accion=""
          accion=""
        />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl className="form-control">
                <TextField
                  id="asincronico"
                  type="text"
                  name="asincronico"
                  label="Estrategia"
                  multiline
                  rowsMax={6}
                  defaultValue=""
                  onChange={onChange}
                  error={props.getError("asincronico", errores).length > 0}
                  helperText={props.getError("asincronico", errores)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl
                className="form-control"
                error={props.getError("nivel", errores).length > 0}
              >
                <InputLabel id="nivel_label">Nivel</InputLabel>
                <Select
                  labelId="nivel_label"
                  id="nivel"
                  name="nivel"
                  inputProps={{ min: "0", step: "1" }}
                  fullWidth
                  onChange={onChange}
                >
                  {props.niveles.map((niv) => (
                    <MenuItem key={niv.id} value={niv.id}>
                      {niv.nombre}
                    </MenuItem>
                  ))}
                </Select>
                {
                  <FormHelperText>
                    {" "}
                    {props.getError("nivel", errores)}
                  </FormHelperText>
                }
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <BtnForm
            texto="GUARDAR"
            variant="contained"
            color="primary"
            callback={() => AddArrayInd()}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

const AddEstrategiaEvaluativa = (props) => {
  const [errores, setErrores] = React.useState([]);
  const [estrategia, setEstrategia] = React.useState("");
  const [nivel, setNivel] = React.useState("");

  const onChange = (e) => {
    if (e.target.name == "evaluativa") {
      setEstrategia(e.target.value);
      if (errores.length > 0) {
        setErrores(errores.filter((er) => er.llave != "evaluativa"));
      }
    } else if (e.target.name == "nivel") {
      setNivel(e.target.value);
      if (errores.length > 0) {
        setErrores(errores.filter((er) => er.llave != "nivel"));
      }
    }
  };

  const AddArrayInd = async () => {
    if (estrategia == "" || nivel == "") {
      setErrores(
        await props.validateInputs([
          { value: estrategia, name: "evaluativa", err: { empty: true } },
          { value: nivel, name: "nivel", err: { empty: true } },
        ])
      );
    } else {
      let nuevo = [];
      nuevo["nombre"] = estrategia;
      nuevo["nivel"] = nivel;
      nuevo["nivel_nombre"] = props.niveles.filter(
        (item) => item.id == nivel
      )[0].nombre;
      nuevo["tipo"] = "est_eva";
      props.EvaluativaAdd.push(nuevo);
      setEstrategia("");
      setNivel("");
      props.Close(false);
      if (props.errores.length > 0) {
        props.setErrores(props.errores.filter((er) => er.llave != "est_eva")); // Elimina error cuando se escribe en el campo
      }
    }
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={props.Open}
        onClose={() => props.Close(false)}
        aria-labelledby="form-dialog-title"
      >
        <AppBarModal
          titulo="Ingrese la estrategia"
          mostrarModal={() => props.Close(false)}
          titulo_accion=""
          accion=""
        />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl className="form-control">
                <TextField
                  id="evaluativa"
                  type="text"
                  name="evaluativa"
                  label="Estrategia"
                  multiline
                  rowsMax={6}
                  defaultValue=""
                  onChange={onChange}
                  error={props.getError("evaluativa", errores).length > 0}
                  helperText={props.getError("evaluativa", errores)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl
                className="form-control"
                error={props.getError("nivel", errores).length > 0}
              >
                <InputLabel id="nivel_label">Nivel</InputLabel>
                <Select
                  labelId="nivel_label"
                  id="nivel"
                  name="nivel"
                  inputProps={{ min: "0", step: "1" }}
                  fullWidth
                  onChange={onChange}
                >
                  {props.niveles.map((niv) => (
                    <MenuItem key={niv.id} value={niv.id}>
                      {niv.nombre}
                    </MenuItem>
                  ))}
                </Select>
                {
                  <FormHelperText>
                    {" "}
                    {props.getError("nivel", errores)}
                  </FormHelperText>
                }
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <BtnForm
            texto="GUARDAR"
            variant="contained"
            color="primary"
            callback={() => AddArrayInd()}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

const AddRecursosEducativos = (props) => {
  const [errores, setErrores] = React.useState([]);
  const [recurso, setRecurso] = React.useState("");
  const [tipo, setTipo] = React.useState("");

  const onChange = (e) => {
    if (e.target.name == "recurso") {
      setRecurso(e.target.value);
      if (errores.length > 0) {
        setErrores(errores.filter((er) => er.llave != "recurso"));
      }
    } else if (e.target.name == "tipo") {
      setTipo(e.target.value);
      if (errores.length > 0) {
        setErrores(errores.filter((er) => er.llave != "tipo"));
      }
    }
  };

  const AddArrayInd = async () => {
    if (recurso == "" || tipo == "") {
      setErrores(
        await props.validateInputs([
          { value: recurso, name: "recurso", err: { empty: true } },
          { value: tipo, name: "tipo", err: { empty: true } },
        ])
      );
    } else {
      let nuevo = [];
      nuevo["recurso"] = recurso;
      nuevo["tipo"] = tipo;
      nuevo["tipo_nombre"] = props.TiposRec.filter(
        (item) => item.id == tipo
      )[0].nombre;
      props.RecursosAdd.push(nuevo);
      setRecurso("");
      setTipo("");
      props.Close(false);
      if (props.errores.length > 0) {
        props.setErrores(props.errores.filter((er) => er.llave != "rec_edu")); // Elimina error cuando se escribe en el campo
      }
    }
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={props.Open}
        onClose={() => props.Close(false)}
        aria-labelledby="form-dialog-title"
      >
        <AppBarModal
          titulo="Ingrese el recurso educativo"
          mostrarModal={() => props.Close(false)}
          titulo_accion=""
          accion=""
        />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl
                className="form-control"
                error={props.getError("tipo", errores).length > 0}
              >
                <InputLabel id="tipo_label">tipo</InputLabel>
                <Select
                  labelId="tipo_label"
                  id="tipo"
                  name="tipo"
                  inputProps={{ min: "0", step: "1" }}
                  fullWidth
                  onChange={onChange}
                >
                  {props.TiposRec.map((niv) => (
                    <MenuItem key={niv.id} value={niv.id}>
                      {niv.nombre}
                    </MenuItem>
                  ))}
                </Select>
                {
                  <FormHelperText>
                    {" "}
                    {props.getError("tipo", errores)}
                  </FormHelperText>
                }
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl className="form-control">
                <TextField
                  id="recurso"
                  type="text"
                  name="recurso"
                  label="recurso"
                  multiline
                  rowsMax={6}
                  defaultValue=""
                  onChange={onChange}
                  error={props.getError("recurso", errores).length > 0}
                  helperText={props.getError("recurso", errores)}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <BtnForm
            texto="GUARDAR"
            variant="contained"
            color="primary"
            callback={() => AddArrayInd()}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

const AddReferencias = (props) => {
  const [errores, setErrores] = React.useState([]);
  const [referencia, setReferencia] = React.useState("");
  const [tipo, setTipo] = React.useState("");

  const onChange = (e) => {
    if (e.target.name == "referencia") {
      setReferencia(e.target.value);
      if (errores.length > 0) {
        setErrores(errores.filter((er) => er.llave != "referencia"));
      }
    } else if (e.target.name == "tipo") {
      setTipo(e.target.value);
      if (errores.length > 0) {
        setErrores(errores.filter((er) => er.llave != "tipo"));
      }
    }
  };

  const AddArrayInd = async () => {
    if (referencia == "" || tipo == "") {
      setErrores(
        await props.validateInputs([
          { value: referencia, name: "referencia", err: { empty: true } },
          { value: tipo, name: "tipo", err: { empty: true } },
        ])
      );
    } else {
      let nuevo = [];
      nuevo["descripcion"] = referencia;
      nuevo["tipo"] = tipo;
      nuevo["tipo_nombre"] = props.TiposRef.filter(
        (item) => item.id == tipo
      )[0].nombre;
      props.ReferenciasAdd.push(nuevo);
      setReferencia("");
      setTipo("");
      props.Close(false);
      if (props.errores.length > 0) {
        props.setErrores(props.errores.filter((er) => er.llave != "ref")); // Elimina error cuando se escribe en el campo
      }
    }
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={props.Open}
        onClose={() => props.Close(false)}
        aria-labelledby="form-dialog-title"
      >
        <AppBarModal
          titulo="Ingrese la referencia"
          mostrarModal={() => props.Close(false)}
          titulo_accion=""
          accion=""
        />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl
                className="form-control"
                error={props.getError("tipo", errores).length > 0}
              >
                <InputLabel id="tipo_label">tipo</InputLabel>
                <Select
                  labelId="tipo_label"
                  id="tipo"
                  name="tipo"
                  inputProps={{ min: "0", step: "1" }}
                  fullWidth
                  onChange={onChange}
                >
                  {props.TiposRef.map((niv) => (
                    <MenuItem key={niv.id} value={niv.id}>
                      {niv.nombre}
                    </MenuItem>
                  ))}
                </Select>
                {
                  <FormHelperText>
                    {" "}
                    {props.getError("tipo", errores)}
                  </FormHelperText>
                }
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl className="form-control">
                <TextField
                  id="referencia"
                  type="text"
                  name="referencia"
                  label="referencia"
                  multiline
                  rowsMax={6}
                  defaultValue=""
                  onChange={onChange}
                  error={props.getError("referencia", errores).length > 0}
                  helperText={props.getError("referencia", errores)}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <BtnForm
            texto="GUARDAR"
            variant="contained"
            color="primary"
            callback={() => AddArrayInd()}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

const VerComponentes = (props) => {
  const [Modal_Eliminar, Set_Modal_Eliminar] = React.useState(false);
  const [Component, SetComponent] = React.useState("");
  const dispatch = useDispatch();

  const eliminarComponentes = () => {
    let id = Component.id;
    let tabla = Component.tabla;
    consulta(
      `api/v1.0/sisef/${id}/${tabla}`,
      {},
      "put",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            Set_Modal_Eliminar(false);
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        dispatch(
          actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
        );
      }
    );
  };

  const actions = (rowData) => {
    const Delete = (row) => {
      props.Delete(row.posc, row.opcion);
      if (row.id) {
        SetComponent(row);
        Set_Modal_Eliminar(true);
      }
    };

    return (
      <div>
        {" "}
        <BtnEliminar callback={() => Delete(rowData)} />{" "}
      </div>
    );
  };
  return (
    <>
      <Dialog
        open={props.ModalComponentes}
        onClose={() => props.SetModalComponentes(false)}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <AppBarModal
          titulo={`${props.TituloComponente}`}
          mostrarModal={() => props.SetModalComponentes(false)}
          titulo_accion={`${props.TituloComponente}`}
          accion=""
        />
        <DialogContent style={{ padding: 0 }} className="scroll">
          <ListarDatos
            id="tbl_listar_componentes"
            datos={props.Componentes}
            opciones={false}
            agregar={false}
            buscar={false}
            acciones={(row) => actions(row)}
            espacioTexto={10}
            css={{
              list: { padding: "0px 0px 20px 0px" },
              appBar: { padding: "0px 0px 0px 0px" },
            }}
            fila_principal={(row) => `${row.nombre}`}
            filas={[
              {
                mostrar: ({ nombre }) => `${nombre}`,
                id: "nombre",
                enLista: false,
              },
              {
                mostrar: ({ tipo }) => `${tipo}`,
                id: "tipo",
                enLista: true,
              },
            ]}
          />
        </DialogContent>
        <DialogActions>
          <BtnForm
            texto="Cerrar"
            callback={() => props.SetModalComponentes(false)}
          />
        </DialogActions>
      </Dialog>
      <ConfirmarAccion
        titulo="¿ Eliminar ?"
        dato={{ id: 0 }}
        mensaje1="¿Estás seguro(a) que deseas eliminar este registro?"
        modalConAccion={Modal_Eliminar}
        mostrarModalConfAccion={() => Set_Modal_Eliminar(false)}
        ejecutarAccion={eliminarComponentes}
      />
    </>
  );
};
