import React, { useEffect } from "react";
import emma_inscripcion_inicio from "../../global/imagenes/emma_inscripcion_inicio.png";
import emma_inscripcion_cierre from "../../global/imagenes/emma_inscripcion_cierre.png";
import { coloresEmma, obtenerValoresFiltros } from "../../global/js/funciones";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import { makeStyles } from "@material-ui/core/styles";
import cuc from "../../global/imagenes/logo_cuc.png";
import Button from "@material-ui/core/Button";
import Copyright from "../general/app/Copyright";
import moment from "moment";
import emma_s from "../../global/imagenes/emma_s1.png";
import TareasTerminadas from "./../general/TareasTerminadas";
import MensajeContacto from "../general/MensajeContacto";
import { Typography, CardContent, Card, CardMedia, CardActionArea } from '@material-ui/core';
import img_homologacion from '../../global/imagenes/homologacion.png'
import img_primer_semestre from '../../global/imagenes/primer_semestre.png'

const useStyles = makeStyles((theme) => ({
  logo: {
    minWidth: "300px",
    width: "25%",
  },
  logo_texto: {
    minWidth: "250px",
    width: "20%",
  },
  div_logo: {
    padding: theme.spacing(8, 0, 0, 0),
    textAlign: "center",
  },
  button: {
    marginTop: theme.spacing(5),
    minWidth: "300px",
    width: "25%",
    borderRadius: "50px",
    color: "white",
  },
  logo_cuc: {
    minWidth: "240px",
    width: "20%",
  },
  div_logo_cuc: {
    textAlign: "center",
  },
  div_message: {
    zIndex: "1",
    position: "fixed",
    textDecoration: "none",
    top: "0%",
    right: ".5%",
    left: "75%",
    fontSize: ".9em",
    lineHeight: "normal",
  },
  div_message2: {
    width: "90%",
    margin: "auto",
    marginBottom: "10px",
    fontSize: ".9em",
  },
}));

function InscripcionInicio({ accion, mensaje }) {
  const classes = useStyles();
  const [cargando, setCargando] = React.useState(true);
  const [valido, setValido] = React.useState(true);

  useEffect(() => {
    async function fetchData() {
      let periodo = await obtenerValoresFiltros([
        { llave: "codigo", valor: "Per_Ins" },
      ]);
      let fecha_hoy = moment();
      let fecha_vence = moment(periodo[0].valora);
      let dias = fecha_vence.diff(fecha_hoy, "days");

      if (dias >= 0) setValido(true);
      else setValido(false);
      setCargando(false);
    }
    fetchData();
  }, []);

  return (
    <div className="Container" >
      {/* <div className="Container" style={{ backgroundColor: 'rgba(255, 255, 255, 0.4)', height:'100vh' }}> */}
      {cargando ? (
        <TareasTerminadas
          mensaje="Cargando.."
          marginTop="7%"
          imagen={emma_s}
          widthImg="7%"
          cargando={true}
        />
      ) : (
        <Grid container className={classes.div_logo}>
          {valido ? (
            <React.Fragment>
              <Grid item xs={12}>
                <img
                  src={emma_inscripcion_inicio}
                  alt="Logo logo Emma"
                  className={classes.logo}
                />
              </Grid>
              <Grid item xs={12}>
                {/* <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  aria-label="add"
                  className={classes.button}
                  onClick={accion}
                >
                  <TouchAppIcon />
                  <Hidden mdUp>continuar</Hidden>
                  <Hidden smDown>Haz click para continuar</Hidden>
                </Button> */}
                <Grid container className='margin_cont' >
                  {/* <Grid item xs={12} sm={3}/> */}
                  <Grid item xs={12} sm={12}>
                    <Card style={{
                      border: '1px solid #BDBBBB',
                      borderRadius: '30px',
                      margin: 'auto',
                      marginTop: '10px',
                      fontSize: '10px',
                      textAlign: 'center',
                      width: 200,
                      maxWidth: 250,
                    }}>
                      <CardActionArea onClick={() => accion('Tipo_Ins')}
                        style={{ color: 'rgb(199, 158, 50, 0.5)' }}>
                        <CardMedia component="img" image={img_primer_semestre} style={{
                          margin: '0 auto',
                          width: '80px',
                          paddingTop: '20px',
                          paddingBottom: '10px',
                        }} />
                        <CardContent>
                          <Typography gutterBottom variant="body1" component="body1" style={{ textTransform: 'uppercase', color: '#000000', fontSize: 13 }}>
                            PRIMER SEMESTRE
                          </Typography>
                        </CardContent>
                        <div className="boxAmarilla"></div>
                      </CardActionArea>
                    </Card>
                  </Grid>
                  {/* <Grid item xs={12} sm={3}>
                    <Card style={{
                      border: '1px solid #BDBBBB',
                      borderRadius: '30px',
                      margin: 'auto',
                      marginTop: '10px',
                      fontSize: '10px',
                      textAlign: 'center',
                      width: 200,
                      maxWidth: 250,
                    }}>
                      <CardActionArea onClick={() => accion('Tipo_Homo')}
                        style={{ color: 'rgb(199, 158, 50, 0.5)' }}>
                        <CardMedia component="img" image={img_homologacion} style={{
                          margin: '0 auto',
                          width: '80px',
                          paddingTop: '20px',
                          paddingBottom: '10px',
                        }} />
                        <CardContent>
                          <Typography gutterBottom variant="body1" component="body1" style={{ textTransform: 'uppercase', color: '#000000', fontSize: 13 }}>
                            HOMOLOGACIÓN
                          </Typography>
                        </CardContent>
                        <div className="boxAmarilla"></div>
                      </CardActionArea>
                    </Card>
                  </Grid> */}
                  <Grid item xs={12} sm={3} style={{marginBottom:'100px'}}/>
                </Grid>
              </Grid>
            </React.Fragment>
          ) : (
            <Grid item xs={12}>
              <img
                src={emma_inscripcion_cierre}
                alt="Logo logo Emma"
                className={classes.logo}
              />
            </Grid>
          )}
        </Grid>
      )}
      {/* <Box mt={2} /> */}
      <div className={classes.div_logo_cuc}>
        <img src={cuc} alt="Logo cuc" className={classes.logo_cuc} />
      </div>
      <Box mt={2} />

      {!cargando && valido && (
        <React.Fragment>
          <Hidden smDown>
            <div className={classes.div_message}>
              <MensajeContacto
                title={"Información"}
                content={mensaje}
                enlaces={[]}
                typeIcon={"duda"}
              />
            </div>
          </Hidden>
          <Hidden mdUp>
            <div className={classes.div_message2}>
              <MensajeContacto
                title={"Información"}
                content={mensaje}
                enlaces={[]}
                typeIcon={"duda"}
              />
            </div>
          </Hidden>
        </React.Fragment>
      )}
      <Copyright />
    {/* </div> */}
    </div>
  );
}

export default InscripcionInicio;
