import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MenuGestionarSolicitud } from '../MenuInternacionalizacion';
import TareasTerminadas from '../../../general/TareasTerminadas';
import { Checkbox, Dialog, DialogActions, DialogContent, FormControl, Grid, List, ListItem, ListItemIcon, ListItemText, ListSubheader, makeStyles, TextField } from '@material-ui/core';
import { BtnForm } from '../../../general/BotonesAccion';
import { formulario, obtenerEstadosProceso } from '../../../../global/js/funciones';
import AppBarModal from '../../../general/AppBarModal';
import CustomDropzone from '../../../general/CustomDropzone';
import { ESTADOS } from '../helper';
import emma_w from '../../../../global/imagenes/emma_w.png';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

function RenderEstados({ estados_siguiente, estado, cambiarSeleccion }) {
  const classes = useStyles();
  return (
    <List className = { classes.root } subheader = {
      <ListSubheader component = "div" >
        Seleccione Estado
      </ListSubheader>
    }>
      {
        estados_siguiente.map(({ secundario }) => {
          let { codigo, nombre } = secundario;
          return (
            <ListItem key = { codigo } role = { undefined } dense button onClick = { () => cambiarSeleccion(codigo) } >
              <ListItemIcon>
                <Checkbox edge = "start" checked = { estado === codigo } tabIndex = { -1 } disableRipple />
              </ListItemIcon>
              <ListItemText id = { codigo } primary = { nombre } />
            </ListItem>
          );
        })
      }
    </List>
  );
}

class SolicitudesGestionar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cargando: true,
      estado: '',
      observacion: '',
      seleccion: 'gestion',
      titulo_res: 'Cargando..',
      archivos: [],
      estados_siguiente: [],
    }
  }

  async componentDidUpdate({ modalGestionarSolicitud }, { estado }) {
    let { modalGestionarSolicitud: modalGestionarSolicitudNew, solicitud } = this.props;
    let { estado: estadoNew } = this.state;
    
    if (estadoNew !== estado) this.setState({ observacion: '', cargando: false });
    if (modalGestionarSolicitud !== modalGestionarSolicitudNew) {
      if (modalGestionarSolicitudNew) {
        this.reiniciarEstados();
        console.log(solicitud)
        let estados_siguiente = await obtenerEstadosProceso(solicitud.tipo_solicitud.id, [{ 'llave': 'principal', 'valor': solicitud.estado_actual.id }, { 'llave': 'secundario__generica', 'valor': ESTADOS }]);
        this.setState({ estado: '', mensaje: '', estados_siguiente, cargando: false }
        );
      }
    }
  }

  onSubmit = async () => {
    let { solicitud, gestionarSolicitud } = this.props;
    let { archivos, estado, observacion, cargando } = this.state;
    this.setState({ cargando: true, titulo_res: 'Enviando..' });
    let data = { observacion, estado, archivos }
    gestionarSolicitud(solicitud.id, data);
    // this.pintarEstados()
    this.setState({ cargando: false, titulo_res: 'Cargando..' });
    if (estado && archivos.length) await this.cargarArchivos(solicitud.id, archivos);
  }

  agregarArchivos = (arc) => {
		let { archivos } = this.state;
		archivos = archivos.concat(arc);
		this.setState({ archivos });
	}

  cargarArchivos(id, archivos) {
		let { actualizarMensaje } = this.props;
		if (archivos.length === 0) return true;
		const data = new FormData();
		data.append(`total`, archivos.length);
    data.append(`tipo`, 0);
		archivos.map((file, index) => data.append(`archivo${index}`, file));
		formulario(`api/v1.0/internacionalizacion/solicitud/${id}/adjuntar`, data, 'post', (error, estado, resp) => {
			if (error) {
				const title = 'Ha ocurrido un error al adjuntar los archivos, contacte con el administrador.';
				const icon = 'error';
				actualizarMensaje({ titulo: title, tipo: icon, mostrar: true, tiempo: 6000 });
			}
		});
	}

  eliminarArchivo = (i) => {
		let { archivos } = this.state;
		let { actualizarMensaje } = this.props;
		archivos.splice(i, 1);
		this.setState({ archivos });
		actualizarMensaje({ titulo: `Archivo Retirado.`, tipo: 'success', mostrar: true, tiempo: 6000 });
	}

  onChange = ({ target }) => {
		let { value, name } = target;
		this.setState({
			[name]: value
		});
	}

  pintar() {
    let { seleccion } = this.state;
    return (
      <div>
        { seleccion === 'gestion' && this.pintarEstados() }
        { seleccion === 'adjuntos' && this.pintarAdjuntos() }
      </div>
    )
  }

  pintarAdjuntos() {
    let { archivos } = this.state;
    return <CustomDropzone archivos = { archivos } addFunction = { this.agregarArchivos } deleteFunction = { this.eliminarArchivo } />
  }

  pintarEstados() {
    let { estado, estados_siguiente, observacion } = this.state;
    return (
      <Grid container>
        <Grid item xs = { 12 } xl = { 12 } sm = { 12 } md = { 12 } lg = { 12 }>
          <RenderEstados
            cambiarSeleccion = { (estado) => this.setState({ estado }) }
            estado = { estado }
            estados_siguiente = { estados_siguiente }
          />
        </Grid>
        {
          estado &&
            <Grid item xs = { 12 } md = { 12 }>
              <FormControl className = "form-control" >
                <TextField
                  value = { observacion }
                  id = "txtObservacion"
                  label = "Observaciones"
                  name = "observacion"
                  placeholder = "Digite observaciones de la solicitud"
                  multiline
                  onChange = { this.onChange }
                />
              </FormControl>
            </Grid>
        }
      </Grid>
    )
  }

  reiniciarEstados() {
    this.setState({
      cargando: true,
      titulo_res: 'Cargando..',
      estado: '',
      estados_siguiente: [],
      seleccion: 'gestion',
      archivos: [],
    })
  }

  render () {
    let { modalGestionarSolicitud, mostrarModalGestionarSolicitud } = this.props;
    let { seleccion, cargando, titulo_res } = this.state;
    return (
      <Dialog fullWidth = { true } maxWidth = "sm" open = { modalGestionarSolicitud } onClose = { mostrarModalGestionarSolicitud.bind(this, false) } aria-labelledby = "alert-dialog-title" aria-describedby="alert-dialog-description">
        <AppBarModal titulo = { '¿ Gestionar Solicitud ?' } mostrarModal = { mostrarModalGestionarSolicitud } titulo_accion = "" accion = "" />
        <MenuGestionarSolicitud seleccionar = { (seleccion) => this.setState({ seleccion }) } sele = { seleccion } />
        <DialogContent style = { { padding: '1' } } className = 'scroll'>
          {
            cargando ?
              <TareasTerminadas mensaje = { titulo_res } marginTop = '7%' imagen = { emma_w } widthImg = "7%" />
              : <div style = { { padding: "5px 0 5px 0" } }>{ this.pintar() }</div>
          }
        </DialogContent>
        <DialogActions>
          <BtnForm texto = "CANCELAR" callback = { () => mostrarModalGestionarSolicitud(false) } />
          { !cargando && <BtnForm texto = "ACEPTAR" callback = { this.onSubmit } /> }
        </DialogActions>
      </Dialog>
    )
  }
}

// Props del componente
SolicitudesGestionar.propTypes = {
  // Variables
  modalGestionarSolicitud: PropTypes.bool.isRequired,
  // Funciones
  mostrarModalGestionarSolicitud: PropTypes.func.isRequired,
}

export default SolicitudesGestionar;
