import React, { Component } from 'react'
import PropTypes from 'prop-types';
// matarial
import { Tooltip, IconButton } from '@material-ui/core';
import { BtnCancelar, BtnCerrado, BtnPosponer, BtnEditar, BtnDetalle, BtnSeleccion } from "../general/BotonesAccion";
import ListarDatos from "../general/ListarDatos"
// iconos
import PeopleIcon from '@material-ui/icons/People';
import HowToRegIcon from '@material-ui/icons/HowToReg';
// utils
import moment from "moment"
import { coloresEmma } from '../../global/js/funciones';
class EleccionesListar extends Component {


  render() {
    let { desactivar_acciones, elecciones, seleccionar, mostrarModalModEleccion, actualizarDataEleccion, mostrarModalCanEleccion, mostrarModalAddEleccion, mostrarModalFinEleccion, mostrarModalProlEleccion, mostrarModalAdmCandidatos, mostrarModalFiltrarEleccion, refrescar } = this.props;

    const actions = (rowData, tipo = "acciones") => {

      const onClickCancelar = (data) => {
        actualizarDataEleccion(data);
        mostrarModalCanEleccion(true)
      }

      const onClickEditar = (data) => {
        actualizarDataEleccion(data);
        mostrarModalModEleccion(true);
      }

      const onClickVer = (data) => {
        seleccionar(0)
        actualizarDataEleccion(data);
        mostrarModalAdmCandidatos(true);
      }

      const onClickFinalizar = (data) => {
        actualizarDataEleccion(data);
        mostrarModalFinEleccion(true)
      }

      const onClickProlongar = (data) => {
        actualizarDataEleccion(data);
        mostrarModalProlEleccion(true)
      }

      const onClickCandidatos = (data) => {
        seleccionar(1)
        actualizarDataEleccion(data);
        mostrarModalAdmCandidatos(true)
      }

      const onClickVotanates = (data) => {
        seleccionar(2)
        actualizarDataEleccion(data);
        mostrarModalAdmCandidatos(true)
      }

      let resp = <BtnDetalle callback={() => onClickVer(rowData)} />
      // if (tipo === "acciones") resp = desactivar_acciones(rowData.fecha_inicio, rowData.estado) ? <BtnCerrado /> : <div> <BtnCancelar callback={() => onClickCancelar(rowData)} /> <BtnEditar callback={() => onClickEditar(rowData)} /></div>;
      resp = desactivar_acciones(rowData.fecha_inicio, rowData.estado) ?
        <div>
          <BtnDetalle callback={() => onClickVer(rowData)} />
          <Tooltip title="Candidatos" aria-label="add" onClick={()=>onClickCandidatos(rowData)}><IconButton aria-label="delete"><PeopleIcon fontSize="small" /> </IconButton></Tooltip>
          <Tooltip title="Votantes" aria-label="add" onClick={()=>onClickVotanates(rowData)}><IconButton aria-label="delete"><HowToRegIcon fontSize="small" /> </IconButton></Tooltip>
          {rowData.estado == 1 && moment().format('YYYY-MM-DD') > rowData.fecha_fin &&
            <>
              <BtnSeleccion texto="Finalizar" callback={() => onClickFinalizar(rowData)} />
              <BtnPosponer texto="Prolongar" callback={() => onClickProlongar(rowData)} />
            </>
          }
        </div> :
        <div>
          <BtnDetalle callback={() => onClickVer(rowData)} />
          <Tooltip title="Candidatos" aria-label="add" onClick={()=>onClickCandidatos(rowData)}><IconButton aria-label="delete"><PeopleIcon fontSize="small" /> </IconButton></Tooltip>
          <Tooltip title="Votantes" aria-label="add" onClick={()=>onClickVotanates(rowData)}><IconButton aria-label="delete"><HowToRegIcon fontSize="small" /> </IconButton></Tooltip>
          <BtnEditar callback={() => onClickEditar(rowData)} />
          <BtnCancelar callback={() => onClickCancelar(rowData)} />
        </div>;
      return resp;
    }

    const obtenerNombre = (estado, fecha_fin) => {
      let fecha_actual = moment().format('YYYY-MM-DD');
      const nombres = {
        '1': 'Activo',
        '2': 'Cancelado',
        '3': 'Finalizado',
      }
      if (estado == 1 && fecha_actual > fecha_fin) return 'Revisión';

      return (nombres[estado])
    }

    const obtenerColor = (estado, fecha_fin) => {
      let color = 'white';
      let fecha_actual = moment().format('YYYY-MM-DD');
      fecha_fin = moment(fecha_fin).format('YYYY-MM-DD');
      const colores = {
        '1': { 'backgroundColor': coloresEmma.colorblanco, 'color': '#757575' },
        // '1': { 'backgroundColor': coloresEmma.iconscolor, color },
        '2': { 'backgroundColor': coloresEmma.solicitudcancelada, color },
        '3': { 'backgroundColor': coloresEmma.solicitudenrevision, color },

      }
      if (parseInt(estado) == 1 && fecha_actual > fecha_fin) return { 'backgroundColor': coloresEmma.SilverGray, color };

      return (colores[estado])
    }

    return (
      <ListarDatos
        datos={elecciones}
        id='tbl_elecciones'
        titulo="Lista de Elecciones"
        descargar='EMMA - ELECCIONES'
        avatar={({ nombre }) => nombre.charAt(0).toUpperCase()}
        color_avatar={({ estado, fecha_fin }) => obtenerColor(estado, fecha_fin)}
        opciones={true}
        agregar={true}
        filtrar={true}
        refrescar={true}
        actAgregar={() => mostrarModalAddEleccion(true)}
        actfiltrar={() => mostrarModalFiltrarEleccion(true)}
        actRefrescar={() => refrescar()}
        acciones={(data) => actions(data)}
        fila_principal={({ nombre }) => nombre.toUpperCase()}
        filas={[
          { 'id': 'nombre', 'enLista': false, 'nombre': 'Nombre: ' },
          { 'id': 'estado', 'mostrar': ({ estado, fecha_fin }) => obtenerNombre(estado, fecha_fin), 'nombre': 'Estado: ' },
          { 'id': 'fecha_inicio', 'mostrar': ({ fecha_inicio }) => moment(fecha_inicio).format('YYYY-MM-DD'), 'nombre': 'Fecha Inicio : ' },
          { 'id': 'fecha_fin', 'mostrar': ({ fecha_fin }) => moment(fecha_fin).format('YYYY-MM-DD'), 'nombre': 'Fecha Fin : ' },
        ]} />
    );
  }

}

//Props del componente
EleccionesListar.propTypes = {
  //variables
  elecciones: PropTypes.array.isRequired,
  //funciones
  actualizarDataEleccion: PropTypes.func.isRequired,
  mostrarModalAddEleccion: PropTypes.func.isRequired,
  mostrarModalModEleccion: PropTypes.func.isRequired,
  mostrarModalCanEleccion: PropTypes.func.isRequired,
  mostrarModalAdmCandidatos: PropTypes.func.isRequired,
  mostrarModalLinkEleccion: PropTypes.func.isRequired,
  desactivar_acciones: PropTypes.func.isRequired,

}


export default EleccionesListar;


