const MODAL_FILTROS_ACTIVIDAD = "MODAL_FILTROS_ACTIVIDAD";
const MODAL_GES_ACTIVIDAD = "MODAL_GES_ACTIVIDAD";
const MODAL_GES_SUBACTIVIDAD = "MODAL_GES_SUBACTIVIDAD";
const MODAL_AGREGAR_ACTIVIDAD = "MODAL_AGREGAR_ACTIVIDAD";
const MODAL_AGREGAR_SUBACTIVIDAD = "MODAL_AGREGAR_SUBACTIVIDAD"

const mostrarFiltrosActividad = (show) => {
  return {
    type: MODAL_FILTROS_ACTIVIDAD,
    show,
  };
};

const mostrarGestionActividad = (show) => {
  return {
    type: MODAL_GES_ACTIVIDAD,
    show,
  };
};

const mostrarGestionSubactividad = (show) => {
  return {
    type: MODAL_GES_SUBACTIVIDAD,
    show,
  };
};

const mostrarAgregarAcitivdad = (show) => {
  return {
    type: MODAL_AGREGAR_ACTIVIDAD,
    show,
  };
};

const mostrarAgregarSubactividad = (show) => { 
  return {
    type: MODAL_AGREGAR_SUBACTIVIDAD,
    show,
  };
};

module.exports = {
  MODAL_FILTROS_ACTIVIDAD,
  MODAL_GES_ACTIVIDAD,
  MODAL_GES_SUBACTIVIDAD,
  MODAL_AGREGAR_ACTIVIDAD,
  MODAL_AGREGAR_SUBACTIVIDAD,
  mostrarFiltrosActividad,
  mostrarGestionActividad,
  mostrarGestionSubactividad,
  mostrarAgregarAcitivdad,
  mostrarAgregarSubactividad,
};
