import React, { Component } from 'react';
import { Grid, Typography, Dialog, DialogActions, DialogContent } from '@material-ui/core'
import AppBarModal from '../../general/AppBarModal'
import { BtnForm } from '../../general/BotonesAccion'
import TareasTerminadas from "../../general/TareasTerminadas";

//component
import DetalleAca from '../DetallePerfil/DetalleAca'
import DetalleExp from '../DetallePerfil/DetalleExp'
import DetalleHab from '../DetallePerfil/DetalleHab'
import DetalleLogros from '../DetallePerfil/DetalleLogros'
import DetalleAdjunto from '../DetallePerfil/DetalleAdj'
import { consulta } from '../../../global/js/funciones'
import emma_w from "../../../global/imagenes/emma_w.png";
import '../../../global/css/stylePortal.css'


class DetallePostulante extends Component {
	constructor(props) {
		super(props)
		this.state = {
			cargando: true,
            detallePostulante: []
		}
	}

    componentDidUpdate({ modalDetallePostulante }) {
        let { modalDetallePostulante: modalDetallePostulanteNew, idDetallePostulante } = this.props;
        if (modalDetallePostulante !== modalDetallePostulanteNew && modalDetallePostulanteNew) this.obtenerDetalle(idDetallePostulante)
    }

    obtenerDetalle(id) {
        this.setState({ cargando: true });
        consulta("api/v1.0/portal/obtener_detalle_postulante", { id }, "post", (error, estado, resp) => {
            if (estado === 200) this.setState({ cargando: false, detallePostulante: resp });
        })
    }


    render() {
        let { cargando, detallePostulante } = this.state
        let { modalDetallePostulante, mostrarModalDetallePostulante } = this.props
        let { academico, experiencia, habilidad, distincion, personal, adjunto } = detallePostulante
        
        return (
            <div>
                <Dialog fullWidth={true} maxWidth="sm" open={modalDetallePostulante} onClose={() => mostrarModalDetallePostulante(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <AppBarModal titulo='Detalle del postulante' mostrarModal={mostrarModalDetallePostulante} titulo_accion="Limpiar" />
                    <DialogContent className='scroll'>
                        { !cargando ?
                            <Grid>
                                <Typography className="ttitulos">
                                    INFORMACIÓN PERSONAL
                                </Typography>
                                {
                                    personal && personal.length > 0?
                                    <Typography className="informacion_principal">
                                        <Typography variant="h6">
                                            { `${personal[0].persona.primer_nombre} ${personal[0].persona.primer_apellido} ${personal[0].persona.segundo_apellido}` }
                                        </Typography>
                                        <Typography className="telefono_and_email">
                                            { personal[0].telefono }
                                        </Typography>
                                        <Typography className="telefono_and_email">
                                            { personal[0].email }
                                        </Typography>
                                        <Typography className="telefono_and_email">
                                            { personal[0].perfil }
                                        </Typography>
                                    </Typography> : <></>
                                }
                                <Typography className="ttitulos">
                                    INFORMACIÓN ACADEMICA
                                </Typography>
                                <Typography>
                                    {
                                        academico ?
                                        Object.keys(academico).map(i => (
                                            <DetalleAca 
                                                key={i}
                                                detalle={academico[i]}
                                            />
                                        )) : <></>  
                                    }
                                </Typography>
                                <Typography className="ttitulos">
                                    INFORMACIÓN LABORAL
                                </Typography>
                                <Typography>
                                    {
                                        experiencia ?
                                        Object.keys(experiencia).map(i => (
                                            <DetalleExp 
                                                key={i}
                                                detalle={experiencia[i]}
                                            />
                                        )) : <></>  
                                    }
                                </Typography>
                                <Typography className="ttitulos">
                                    HABILIDADES
                                </Typography>
                                <Typography>
                                    {
                                        habilidad ? 
                                        <DetalleHab 
                                            data={habilidad}
                                        /> : <></>
                                    }
                                </Typography>
                                <Typography className="ttitulos">
                                    DISTINCIONES Y LOGROS
                                </Typography>
                                <Typography>
                                    {
                                        distincion ?
                                        Object.keys(distincion).map(i => (
                                            <DetalleLogros 
                                                key={i}
                                                logros={distincion[i]}
                                            />
                                        )) : <></>
                                    }
                                </Typography>
                                <br></br>
                                <Typography className="ttitulos">
                                    ADJUNTOS
                                </Typography>
                                <Typography className="ttitulos">
                                    {
                                        adjunto ?
                                        <DetalleAdjunto 
                                            datos={adjunto}
                                        /> : <></>
                                    }
                                </Typography>
                            </Grid>:
                        <TareasTerminadas mensaje={'Cargando..'} marginTop="7%" imagen={emma_w} widthImg="7%" />
                        }
                    </DialogContent>
                    <DialogActions>
                    <BtnForm texto="CANCELAR" callback={() => mostrarModalDetallePostulante(false)} />
                    </DialogActions>
                </Dialog >
            </div>
        );
    }
}

export default DetallePostulante;