import React, { Component,useState } from 'react';
import { PropTypes } from 'prop-types';
//import { mostrarModalSolicitarTutoria } from '../../../redux/actions/actTutorias';
import AppBarModal from '../../general/AppBarModal'
import {BtnFormPage} from '../../general/BotonesAccion'
import TareasTerminadas from '../../general/TareasTerminadas';
import { Select, FormControl, MenuItem, InputLabel, StepLabel, Step, Stepper, Button, TextField, Paper, Dialog, DialogContent, DialogActions, Grid } from '@material-ui/core';
import emma_w from '../../../global/imagenes/emma_w.png'
import ListarDatos from "../../general/ListarDatos";
import { Checkbox } from '@material-ui/core';
import { consulta,obtenerValoresFiltros } from '../../../global/js/funciones';
import { cloneNode } from '@syncfusion/ej2-base';
import BuscarSelect from "../../general/BuscarSelect";


const configStep = ['Mis datos', 'Horarios'];

function MisDatos(component_props){
    let {onChange, onChangeAsignatura, telefono, celular, correo,asignatura, asignaturas, programas, carrera, onChangeProgramas} = component_props

    return(
    <>
      <Grid container spacing={3} style={{ marginBottom: '40px'}}>
        <Grid item xs={6}>
          <TextField
          value={celular}
          required
          type="number"
          id="celular"
          name="celular"
          label="Celular"
          fullWidth
          onChange={onChange}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
          value={telefono}
          required
          type="number"
          id="telefono"
          name="telefono"
          label="Telefono"
          fullWidth
          onChange={onChange}
          />
        </Grid>

        <Grid item xs={12}>
            <TextField
              value={correo}
              required
              type="text"
              id="correo"
              name="correo"
              label="Correo Personal"
              fullWidth
              onChange={onChange}
            />
          </Grid>

        <Grid item xs={12}>
          <FormControl className='form-control'>
            <InputLabel id="demo-simple-select-label">Seleccione Programa</InputLabel>
            <Select id="carrera" value={carrera}  name= 'carrera' onChange={onChangeProgramas}  >
              {                  
                programas.map((value) => {
                   return <MenuItem key={value.relacion.id} value={value.relacion} data-my-value={value.relacion.codigo}>{value.relacion.nombre}</MenuItem>
                })
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className='form-control'>
            <InputLabel id="demo-simple-select-label">Seleccione Asignatura</InputLabel>
            <Select id="asignatura" value={asignatura}  name= 'asignatura' onChange={onChangeAsignatura}  >
              {                  
                asignaturas.map((value) => {
                   return <MenuItem key={value.codigo} value={value} data-my-value={value.codigo}>{value.nom_materia}</MenuItem>
                })
              }
            </Select>
          </FormControl>
        </Grid>

      </Grid>

    </>
  )
}

function Horarios(component_props){
  let {horarios, horario, cambiarEstado,ValidarHorarioDuplicado } = component_props


  let arr=[]
  const [ checked, setChecked ] = useState([]);
    
    const handleChange = (data) => async () => {
      
      const currentIndex = checked.indexOf(data.id);
      let newChecked = [ ...checked ];
      if (currentIndex === -1){
        newChecked = [data.id];

      }else{
        newChecked=[];
      }
      setChecked(newChecked)
      horario=data
      ValidarHorarioDuplicado(horario.dia,horario.hora_inicio,horario.hora_fin)
      cambiarEstado({horario})
      
    }


     const acciones =(data) =>{
      
       const detalle= (
       <Checkbox edge='end' checked={checked.indexOf(data.id) !== -1} onChange={handleChange(data)} />
       );
       return detalle;
     }
  
     return(
       <>
         <ListarDatos
          datos={horarios.respuesta}
          titulo='Lista Horarios tutorias'
          id='tbl_Horarios_listar'
          opciones={true} 
          acciones={(data) => acciones(data)}
          buscar={true}
          avatar={({ nombre }) => nombre.charAt(0).toUpperCase()}
          fila_principal={({nombre}) =>nombre}
          filas={[
            { 'id': 'Profesor','mostrar':({nombre, apellido}) => `${nombre} ${apellido}`},
            { 'id': 'correo','mostrar':({correo}) => correo},
            { 'id': 'telefono','mostrar':({telefono}) => telefono},
            { 'id': 'dia','mostrar':({dia}) => dia},
            { 'id': 'hora','mostrar':({hora_inicio, hora_fin}) => `De ${hora_inicio} a ${hora_fin}`},
            { 'id': 'lugar','mostrar':({lugar}) => lugar},
            { 'id': 'asignatura','mostrar':({valor}) => valor},
]}
        />
      
       </>
  )

}



class TutoriasCrear extends Component {

  constructor(props) {
    super(props)
    this.state = {
    activeStep:0,
    carrera:'',
    cargando: true,
    repetido: false,
    asignaturas:[],
    asignatura:{'nombre':'', 'codigo':''},
    celular:'',
    correro:'',
    telefono:'',
    horario:'',
    horarios:[],
      
    }
  }

  

  async componentDidMount() {
    this.setState({cargando:true})
    let{telefono, celular, correo} = this.props
    this.obtenerTipoTutoria()
    this.setState({telefono, celular, correo, cargando:false})
    
  }

  transformar = data => {
    return data.map(e => ({
      value: e.id,
      label: e.nombre,
      codigo: e.codigo
    }));
  }

componentDidUpdate(prevProps) {
   let {programas, telefono, celular, correo} = this.props
   let {asignatura, horarios} =this.state
   if((programas && programas !== prevProps.programas) && programas.length >= 1) {
    this.setState({ carrera: programas[0].relacion.id })
  }
  if(telefono && telefono !== prevProps.telefono) {
    this.setState({ telefono })
  }
  if(celular && celular !== prevProps.celular) {
    this.setState({ celular })
  }
  if(correo && correo !== prevProps.correo) {
    this.setState({ correo })
  }
 

  }

  ValidarHorarioDuplicado = async(dia,hora_inicio,hora_fin) => {
    let title = `No se admiten horarios duplicados.`;
    let tipo = 'info';
    let { TutoriasActivas, actualizarMensaje } = this.props;
    if(TutoriasActivas){
      TutoriasActivas.map((r)=>{
        //console.log((hora_inicio >= r.hora_inicio && hora_inicio <= r.hora_fin));
        if( (hora_inicio >= r.hora_inicio && hora_inicio <= r.hora_fin || hora_fin >= r.hora_inicio && hora_fin <= r.hora_fin) && r.dia==dia){
          actualizarMensaje({ titulo: title, tipo, mostrar: true, tiempo: 9000 });
          this.setState({ repetido: true });
          return false;
        } else {
          this.setState({ repetido: false });
          //alert('horario guardado');
        }
      });
    } else alert('horario correcto');
    
  } // fin ValidarHorarioDuplicado

async obtenerHorarios(dato) {
  let f=dato
  return new Promise(resolve => {
    consulta(`api/v1.0/tutorias/tutorias/${f}?`, null, null, (error, estado, resp) => {
      resolve(estado === 200 && !error ? resp : null);
    })
  })
}

async obtenerAsignaturas(codigo) {
  let f=codigo
  return new Promise(resolve => {
    consulta(`api/v1.0/tutoriasAS/tutoriasAS/${f}?`, null, null, (error, estado, resp) => {
      resolve(estado === 200 && !error ? resp : null);
    })
  })
}

async obtenerTipoTutoria() {
  let tipo_T = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': 70 }]);
  this.setState({ tipo_T:tipo_T[0]});
  
}

  
  onChange = ({ target }) => {// evento que escucha los cambios del formulario y asigna el valor al estado de la variable
    let { value, name } = target;
    this.setState({
      [name]: value
    })  
  }
  
  onChangeAsignatura = async (e) => {// evento que escucha los cambios del formulario y asigna el valor al estado de la variable
    this.setState({cargando:true})
    let codigo = e.currentTarget.dataset.myValue
    this.onChange(e)
    let horarios= await this.obtenerHorarios(codigo);
    this.setState({
      "horarios":horarios,
      
      cargando:false
    }) 

    
  }

  onChangeProgramas = async (e) => {
    this.setState({ cargando: true })
    this.onChange(e)
    let codigo = e.currentTarget.dataset.myValue
    
    const { actualizarMensaje } = this.props;
    let asignaturas= await this.obtenerAsignaturas(codigo);
    this.setState({ asignaturas: asignaturas.respuesta, cargando: false });
  }

  handleStep = (direction) => {//Con esta funcion Valido si se llenaron los campos
    let { activeStep, carrera, celular, telefono, correo, asignatura} = this.state
    let sw = true
    let {actualizarMensaje} =this.props
    if(!telefono || !celular || !correo || !carrera || !asignatura.codigo && activeStep === 0){
      actualizarMensaje({titulo: 'Debe diligenciar todos los campos para continuar', tipo: 'info', mostrar: true, tiempo:6000});
      sw=false
    }
    if(sw) this.setState({ activeStep: direction ? activeStep + 1 : activeStep - 1 })
  }

  reiniciarEstado() {
    this.setState({
      activeStep: 0, 
      cargando: false, 
      repetido: false,
      titulo_carg : 'Cargando...', 
      carrera: '',
      asignatura: '',
      asignaturas: [],
      programas : [],
    })
  }

  submitTutorias = async () => {  // con esta funcion guardamos los cambios realizados en el formulario 
    
    let { telefono, carrera, celular, asignatura, correo, horario,tipo_T, repetido} = this.state
    let title = `Por favor agregue al menos un horario`;
    let title2 = `por favor seleccione otro horario, ya que  no se pueden escoger tutorías en el mismo lapso de tiempo.`;
    let tipo = 'info';
    let {actualizarMensaje} = this.props
    this.setState({ cargando: true, titulo_carg : 'Enviando...' })
    let data ={
      telefono, 
      celular,
      correo,
      codigo_asignatura:horario.id_asignatura,
      asignatura:horario.valor,
      codigo_programa:carrera.codigo,
      programa:carrera.id,
      hora_inicio:horario.hora_inicio,
      hora_fin:horario.hora_fin,
      lugar:horario.lugar,
      profesor:horario.nombre,
      dia:horario.dia,
      identificacion:horario.identificacion,
      tipo_tutoria:tipo_T.id,
      correo_profesor:horario.correo
    }
    if(horario){
      if(!repetido){
        this.props.guardarTutorias(data, () => this.reiniciarEstado());
      } else actualizarMensaje({ titulo: title2, tipo, mostrar: true, tiempo: 9000 });
    }else{
      actualizarMensaje({ titulo: title, tipo, mostrar: true, tiempo: 9000 });
    }
      
    
    }

    
  cambiarEstado = (estados) => {
    this.setState(estados)
  }

  configStepContent = () => {
    let {celular, telefono, correo, carrera,horarios, activeStep, asignaturas, asignatura, horario,repetido} = this.state
    let { programas }= this.props
    switch(activeStep){
      case 0:
        return (
          <MisDatos
          {...{
            telefono,
            celular,
            correo,
            carrera,
            programas,
            asignaturas,
            asignatura,
            onChange: this.onChange,
            onChangeAsignatura: this.onChangeAsignatura,
            onChangeProgramas: this.onChangeProgramas,
          }}
          />
        )
        case 1:
          return(
            <Horarios
            {...{
              horario,
                horarios,
                repetido,
                cambiarEstado: this.cambiarEstado,
                ValidarHorarioDuplicado: this.ValidarHorarioDuplicado
            }}/>
          )
        
        default:
          return <TareasTerminadas imagen={emma_w} mensaje='Est bandeja esta  vacía' widthImg='10%' marginTop='10%'/>
    }
  }
 

  render() {
   
      let{modalSolTutoria, mostrarModalSolicitarTutoria} = this.props;
      let { activeStep} = this.state
 
    return (
      <> 
       
       <div>
         
         <Dialog fullWidth={true} maxWidth="sm" open={modalSolTutoria} onClose={() => mostrarModalSolicitarTutoria(false)}>
         <AppBarModal titulo={"¡Solicitud !"} mostrarModal={() => mostrarModalSolicitarTutoria(false)} titulo_accion="Cerrar" accion={() => mostrarModalSolicitarTutoria(false)}/>
         <DialogContent className='scroll'>
           <Grid container>
             <Grid item xs={12}  md={12} >
               <Stepper activeStep={activeStep}>
                 {
                   configStep.map(label => (
                     <Step key={label}>
                       <StepLabel><p>{label}</p></StepLabel>
                     </Step>
                   ))
                 }
               </Stepper>
             </Grid>
             <Grid item xs={12} md={12}>
                  {this.configStepContent()}
             </Grid>
           </Grid> 
         </DialogContent>
         <DialogActions>
         {activeStep > 0 ?
                    <div><BtnFormPage texto='ATRAS' callback={() => this.handleStep(false)} />
                    <BtnFormPage texto='GUARDAR' callback={this.submitTutorias} type="submit"/>
                    </div>
                  :<BtnFormPage texto='SIGUIENTE' callback={() => this.handleStep(true)} />
                  }
         </DialogActions>
                  
       </Dialog >
     </div> 
     <div>
     </div>
   </>
      )
    }
    
  
}
TutoriasCrear.propTypes = {
  modalSolTutoria: PropTypes.bool.isRequired,
  programas: PropTypes.array.isRequired,   
  actualizarMensaje:PropTypes.func.isRequired,
  guardarTutorias:PropTypes.func.isRequired,
}



export default TutoriasCrear;