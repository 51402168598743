
import React, { Component } from 'react';
import { DialogActions, DialogContent, Dialog, Button, Grid } from '@material-ui/core';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import AppBarModal from '../general/AppBarModal'
import { BtnForm } from "../general/BotonesAccion";

class PracticasTerminarActa extends Component {

  constructor(props) {
    super(props)
    this.state = {
      nota: '',
      mensaje: '',
    }
  }

  componentDidUpdate({ modal }) {
    let { modal: modalAddNew } = this.props;
    if (modalAddNew !== modal) {
      this.setState({ mensaje: '', nota: '' });
    }
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }


  onSubmit = e => {
    let { mensaje, nota } = this.state;
    nota = `${nota}`.replace(',', '.');
    this.props.enviar(mensaje, nota);
    e.preventDefault();
  }

  render() {
    let { modal, mostrarModal, titulo, calificacion } = this.props;
    let { mensaje, nota } = this.state;
    return (
      <div>
        <Dialog open={modal} fullWidth={true} maxWidth="xs" onClose={mostrarModal.bind(this, false)} aria-labelledby="form-dialog-title">
          <AppBarModal titulo={titulo} mostrarModal={mostrarModal} titulo_accion="" accion="" />
          <DialogContent className='scroll'>
            <ValidatorForm onSubmit={this.onSubmit}>
              <Grid container >

                {
                  calificacion &&
                  <Grid item xs={12} >
                    <TextValidator
                      // variant="outlined"
                      autoFocus
                      margin="dense"
                      label='Nota(un numero entre 0 y 5. Ej : 4,5)'
                      type='text'
                      fullWidth
                      name="nota"
                      value={nota}
                      validators={["required", 'matchRegexp:^[0-9]+([,][0-9]+)?$']}
                      errorMessages={["El campo es requerido", "El campo debe ser un numero entre 0 - 5"]}
                      onChange={this.onChange}
                    />
                  </Grid>
                }
                <Grid item xs={12} >
                  <TextValidator
                    // variant="outlined"
                    autoFocus={!calificacion}
                    margin="dense"
                    label='Observaciones'
                    type='text'
                    fullWidth
                    name="mensaje"
                    value={mensaje}
                    validators={["required"]}
                    errorMessages={["El campo es requerido"]}
                    onChange={this.onChange}
                  />
                </Grid>
              </Grid>
              <Button color="primary" type="submit" id='enviar_form_nota_mensaje' className="oculto"> Enviar </Button>
            </ValidatorForm>
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => mostrarModal(false)} />
            <BtnForm texto="ACEPTAR" callback={() => { document.getElementById('enviar_form_nota_mensaje').click() }} />
          </DialogActions>
        </Dialog>

      </div>
    );
  }
}


export default PracticasTerminarActa;
