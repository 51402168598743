import React from "react";
import { api, consulta } from "../../global/js/funciones";
import Inicio from "./Inicio";
import TareasTerminadas from "./TareasTerminadas";
import emma_w from "../../global/imagenes/emma_w.png";

export const MostrarSubmodulo = ({
   sub_actividades,
   codigo_principal,
   usuario,
}) => {
   const [actividades, setActividades] = React.useState([]);
   const [cargando, setCargando] = React.useState(false);

   React.useEffect(() => {
      const obtenerActividades = async () => {
         setCargando(true);
         let actividades = await obtenerActividadesPerfil(
            usuario,
            codigo_principal
         );
         setActividades(actividades);
         setCargando(false);
      };
      obtenerActividades();
   }, []);

   const obtenerActividadesPerfil = (persona, codigo) => {
      return new Promise((resolve) => {
         consulta(
            `api/v1.0/personas/${persona}/genericas/permiso/${codigo}`,
            null,
            null,
            (error, estado, resp) => {
               if (error) {
                  resolve([]);
               } else {
                  resolve(resp);
               }
            }
         );
      });
   };

   const actividadesDisponibles = (actividades) => {
      let actividades_app = [];
      sub_actividades.forEach((a1) =>
         actividades.forEach(({ secundario: { codigo, nombre, archivo } }) => {
            if (a1.codigo === codigo) {
               a1.titulo = nombre;
               a1.imagen = api + archivo;
               actividades_app.push(a1);
            }
         })
      );
      return actividades_app;
   };

   return (
      <>
         {!cargando ? (
            <>
               <Inicio
                  actividades={actividadesDisponibles(actividades)}
                  submodulo={true}
               />
            </>
         ) : (
            <TareasTerminadas
               mensaje="Cargando.."
               marginTop="7%"
               imagen={emma_w}
               widthImg="7%"
            />
         )}
      </>
   );
};
