import React, { Component } from 'react';
import { PropTypes } from '@material-ui/core';
import { SolicitudesMenu } from '../MenuInternacionalizacion';
import ConvenioCrearDetalle from '../../convenios/ConvenioCrearDetalle';
import { Dialog, DialogContent, DialogActions, Link, Fab, Grid, TableCell, TableRow, TableBody, TableHead, Table, TableContainer, Paper } from '@material-ui/core';
import emma_w from '../../../../global/imagenes/emma_w.png';
import TareasTerminadas from '../../../general/TareasTerminadas';
import AppBarModal from '../../../general/AppBarModal';
import { consulta, generarFiltros } from '../../../../global/js/funciones';
import { BtnCancelar, BtnDetalle, BtnEditar, BtnForm, BtnSeleccion } from '../../../general/BotonesAccion';
import ListarDatos from '../../../general/ListarDatos';
import moment from 'moment/moment';

export class InstitucionesDetalleConvenio extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cargando: true,
            seleccion: '',
            estados: [],
            participantes: [],
            adjuntos: [],
            tipo_paso_infoconveni: [],
            pasocumplimientorequisito: [],
            pasotipoactividad: [],
            datos: [],
            vistaDetalle: 'detalle'
        }
    }

    async componentDidMount() {
        this.setState({ seleccion: 'detalle' })
    }

    async componentDidUpdate({ modalDetConvInst }, { seleccion, datos }) {
        let { modalDetConvInst: modalDetConvInstNew, id } = this.props
        let { seleccion: seleccionNew, datos: datosNew, vistaDetalle } = this.state
        if (modalDetConvInst !== modalDetConvInstNew) {
           this.setState({ seleccion: modalDetConvInstNew ? vistaDetalle : '',datos: modalDetConvInstNew ? datos:''});
         }
        if (seleccion !== seleccionNew) {
            this.setState({ cargando: true });
            let adjuntos = [], participantes = [];
            if (seleccionNew === 'detalle') await this.obtenerInfoSolicitud(id)
            else if (seleccionNew === 'documentos') adjuntos = await this.obtenerAdjuntos(id)
            else if (seleccionNew === 'estados') await this.obtenerEstadosSolicitud(id);
            this.setState({ participantes, adjuntos, cargando: false });
        }

        if (datos !== datosNew) {
            let tipo_paso_infoconveni = [], pasocumplimientorequisito = [], pasotipoactividad = []
            if (datosNew) {
                this.setState({ cargando: true })
                tipo_paso_infoconveni = await this.obtenerBorradorInfConvenio(datosNew[0].id)
                pasocumplimientorequisito = await this.obtenerBorradorCumplimiento(datosNew[0].id)
                pasotipoactividad = await this.obtenerTipoActividad(datosNew[0].id)
                this.setState({ tipo_paso_infoconveni, pasocumplimientorequisito, pasotipoactividad, cargando: false })
            }
        }
    }

    async obtenerInfoSolicitud(id) {
        this.setState({ cargando: true });
        let f = await generarFiltros([{ 'llave': 'solicitud', 'valor': id }]);
        await consulta(`api/v1.0/internacionalizacion/solicitud/convenio?${f}`, null, null, (error, estado, resp) => {
            if (estado === 200) this.setState({ datos: resp });
        });
    }

    async obtenerAdjuntos(id, tipo = 1) {
        let f = await generarFiltros([{ 'llave': 'solicitud', 'valor': id }, { 'llave': 'tipo', 'valor': tipo }]);
        return new Promise(resolve => {
            consulta(`api/v1.0/internacionalizacion/solicitud/adjuntos?${f}`, null, null, (error, estado, resp) => {
                resolve(estado === 200 && !error ? resp : null);
            });
        });
    }

    async obtenerEstadosSolicitud(id) {
        this.setState({ cargando: true });
        let f = await generarFiltros([{ 'llave': 'solicitud', 'valor': id }]);
        consulta(`api/v1.0/internacionalizacion/solicitud/estados?${f}`, null, null, (error, estado, resp) => {
            if (estado === 200) this.setState({ estados: resp });
        })
    }

    async obtenerBorradorInfConvenio(id_convenio) {
        return new Promise((resolve) => {
            consulta(
                `api/v1.0/internacionalizacion/solicitudes/${id_convenio}/obtener_pasoInfConvenio`,
                null,
                null,
                (error, estado, resp) => {
                    resolve(estado === 200 && !error ? resp : null);
                }
            );
        });
    }


    async obtenerBorradorCumplimiento(id_convenio) {
        return new Promise((resolve) => {
            consulta(
                `api/v1.0/internacionalizacion/solicitudes/${id_convenio}/obtener_pasoCumplimiento`,
                null,
                null,
                (error, estado, resp) => {
                    resolve(estado === 200 && !error ? resp : null);
                }
            );
        });
    }

    async obtenerTipoActividad(id_convenio) {
        return new Promise((resolve) => {
            consulta(
                `api/v1.0/internacionalizacion/solicitudes/${id_convenio}/obtener_tipoActividad`,
                null,
                null,
                (error, estado, resp) => {
                    resolve(estado === 200 && !error ? resp : null);
                }
            );
        });
    }

    pintarRequisitos = () => {
        let { mostrarModalModAdjuntos, mostrarModalAprobarAdjunto, mostrarModalNegarAdjunto, admin, solicitud: { permiso } } = this.props;
        let { adjuntos, administrador } = this.state;
        let adjuntos_llenos = adjuntos.filter(adjunto => adjunto.archivo);
        const obtenerColor = ({ tipo }) => {
            let color = 'white';
            const colores = {
                '0': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
                '1': { 'backgroundColor': '#01519B', color },
            }
            return (colores[tipo]);
        }
        const obtenerValidacion = (validacion) => {
            const nombres = {
                '0': 'Enviado',
                '1': 'Negado',
                '2': 'Aprobado',
                '3': 'Reemplazado',
            }
            return (nombres[validacion]);
        }
        const acciones = (data) => {
            let { validacion, tipo } = data;
            const onClickEditar = (adjunto) => {
                this.setState({ adjunto });
                mostrarModalModAdjuntos(true);
            }
            const onClickAprobar = (adjunto) => {
                this.setState({ adjunto });
                mostrarModalAprobarAdjunto(true);
            }
            const onClickNegar = (adjunto) => {
                this.setState({ adjunto });
                mostrarModalNegarAdjunto(true);
            }
            let detalle = <Link href={`${data.archivo}`} target="_blank"><BtnDetalle callback={() => { }} /></Link>;
            let negar = validacion === '0' && admin ? <BtnCancelar texto='Negar' callback={() => onClickNegar(data)} /> : '';
            let editar = (validacion === '1' || (admin && validacion === '0')) && tipo === '1' ? <BtnEditar texto='Editar' callback={() => onClickEditar(data)} /> : '';
            let aprobar = validacion === '0' && admin ? <BtnSeleccion texto='Aprobar' callback={() => onClickAprobar(data)} /> : '';
            return (permiso == 1 || administrador) ? <div>{detalle}{negar}{editar}{aprobar}</div> : <div>{detalle}{!admin && editar}</div>;
        }
        return (
            <ListarDatos
                id="tbl_mis_adjuntos"
                datos={adjuntos_llenos}
                avatar={({ requisito, nombre_archivo }) => requisito ? requisito.nombre.charAt(0).toUpperCase() : nombre_archivo.charAt(0).toUpperCase()}
                color_avatar={data => obtenerColor(data)}
                acciones={data => acciones(data)}
                fila_principal={({ requisito, nombre_archivo }) => requisito ? requisito.nombre.toUpperCase() : nombre_archivo.toUpperCase()}
                filas={[
                    { 'id': 'nombre', 'mostrar': ({ requisito, nombre_archivo }) => nombre_archivo ? nombre_archivo : requisito.nombre },
                    { 'id': 'validacion', 'mostrar': ({ tipo, validacion }) => tipo === '1' && obtenerValidacion(validacion) },
                    { 'id': 'nota', 'mostrar': ({ nota, nota2 }) => nota2 ? nota2 : nota },
                    { 'id': 'fecha_registro', 'enLista': true, 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a') },
                ]}
            />
        )
    }


    pintarEstados = () => {
        let { estados, tipo_solicitud } = this.state;
        const obtenerColor = (codigo) => {
            let color = 'white';
            const colores = {
                'Est_Int_Env': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
                'Est_Int_Cor': { 'backgroundColor': '#ffc107', color },
                'Est_Int_Rec': { 'backgroundColor': '#b71c1c', color },
                'Est_Int_Hom': { 'backgroundColor': '#01519B', color },
                'Est_Int_Rev': { 'backgroundColor': '#32C79E', color },
                'Est_Int_Pos': { 'backgroundColor': '#6D32C7', color },
                'Est_Int_Doc': { 'backgroundColor': '#009688', color },
                'Est_Int_Ace': { 'backgroundColor': '#C0C732', color },
                'Est_Int_Fin': { 'backgroundColor': '#C74632', color },
                'Est_Int_Neg': { 'backgroundColor': '#C77332', color },
            }
            return (colores[codigo])
        }
        const obtenerColorConv = (codigo) => {
            let color = 'white';
            const colores = {
                'Est_Int_Bor': { 'backgroundColor': '#EEEEEE', 'color': '#757575' },
                'Est_Int_Env': { 'backgroundColor': '#01579b', color },
                'Sol_aval_jefe': { 'backgroundColor': '#ffc107', color },
                'En_Revi_Con': { 'backgroundColor': '#ffc107', color },
                'Con_rev_sec': { 'backgroundColor': '#ffc107', color },
                'Con_corr': { 'backgroundColor': '#ffc107', color },
                'Con_rev_ins_vin': { 'backgroundColor': '#ffc107', color },
                'Est_Int_Can': { 'backgroundColor': '	#FF0000', color },
                'Con_rev_sec_gen_2': { 'backgroundColor': '#ffc107', color },
                'con_corr_2': { 'backgroundColor': '#ffc107', color },
                'Con_ava_sec_gen': { 'backgroundColor': '	#ffc107', color },
                'Rev_ges_conv_2': { 'backgroundColor': '#ffc107', color },
                'Fir_ins_vincu': { 'backgroundColor': '#ffc107', color },
                'Ges_fir_sg': { 'backgroundColor': '#ffc107', color },
                'Con_sus': { 'backgroundColor': '#ffc107', color },
                'En_corr_soli': { 'backgroundColor': '#ffc107', color },
                'Fir_cuc': { 'backgroundColor': '#ffc107', color },
                'Con_aval_juri_ins_vin': { 'backgroundColor': '#ffc107', color },
                'sin_conv_uni': { 'backgroundColor': '#EEEEEE', color },
                'Con_regis': { 'backgroundColor': '#008000', color },
                'Sol_rechazada': { 'backgroundColor': '#FF0000', color },
            }
            return (colores[codigo])
        }
        return (
            <ListarDatos
                id="tbl_estados_solicitud"
                datos={estados}
                avatar={({ tipo_solicitud, tipo_estado }) => tipo_solicitud === 'Con_Int' ? tipo_estado.valora.charAt(0).toUpperCase() : tipo_estado.codigo.charAt(0).toUpperCase()}
                color_avatar={({ tipo_estado: { codigo } }) => tipo_solicitud === 'Con_Int' ? obtenerColorConv(codigo) : obtenerColor(codigo)}
                fila_principal={({ tipo_estado }) => tipo_estado.nombre.toUpperCase()}
                filas={[
                    { 'id': 'usuario_registro', 'enLista': true, 'mostrar': ({ usuario_registro }) => `${usuario_registro.primer_nombre} ${usuario_registro.segundo_nombre} ${usuario_registro.primer_apellido} ${usuario_registro.segundo_apellido}`.toUpperCase() },
                    { 'id': 'fecha_registro', 'enLista': true, 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a') },
                    { 'id': 'observaciones', 'enLista': true, 'mostrar': ({ observacion }) => observacion },
                ]}
            />
        )
    }

    pintarSolicitud = () => {
        let { datos, tipo_paso_infoconveni, pasocumplimientorequisito, pasotipoactividad, cargando } = this.state;
        if (datos[0] && !cargando) {
            return (
                <div>
                    {datos[0] && !cargando &&
                        <ConvenioCrearDetalle
                            solicitud={datos[0]}
                            tipo_paso_infoconveni={tipo_paso_infoconveni}
                            pasocumplimientorequisito={pasocumplimientorequisito}
                            pasotipoactividad={pasotipoactividad}
                        />
                    }
                </div>
            )
        }
    }

    pintar = () => {
        let { cargando, seleccion } = this.state;
        if (cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />;
        return (
            <div>
                {seleccion === 'detalle' && this.pintarSolicitud()}
                {seleccion === 'documentos' && this.pintarRequisitos()}
                {seleccion === 'estados' && this.pintarEstados()}
            </div>
        )
    }


    render() {
        let { solicitud: { tipo_solicitud }, modalDetConvInst, mostrarModalDetConvInst } = this.props;
        let { seleccion, cargando } = this.state;
        return (
            <>
                <Dialog open={modalDetConvInst} maxWidth="md" /* "md o sm" */ fullWidth={true} onClose={mostrarModalDetConvInst.bind(this, false)}>
                    <AppBarModal titulo='¡ Detalle Solicitud !' mostrarModal={mostrarModalDetConvInst} titulo_accion='' />
                    <SolicitudesMenu sele={seleccion} seleccionar={seleccion => this.setState({ seleccion })} tipo_solicitud={tipo_solicitud && tipo_solicitud.codigo} />
                    <DialogContent style={{ padding: 0 }} className='scroll' >
                        {
                            !cargando ?
                                this.pintar()
                                : <TareasTerminadas mensaje='Cargando...' marginTop='7%' imagen={emma_w} widthImg="7%" />
                        }
                    </DialogContent>
                    <DialogActions>
                        <BtnForm texto="Cerrar" callback={() => mostrarModalDetConvInst(false)} />
                    </DialogActions>

                </Dialog>
            </>
        )
    }
}
// Props del componente
InstitucionesDetalleConvenio.propTypes = {
    //variables
    // instituciones: PropTypes.array.isRequired,
    // modalDetInstituciones: PropTypes.bool.isRequired,
    // institucionActual: PropTypes.array.isRequired,
    //funciones
    // actualizarDataValor: PropTypes.func.isRequired,
    // mostrarModalDetInstituciones: PropTypes.func.isRequired,
    // usuario: PropTypes.object.isRequired,
};