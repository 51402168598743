import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MenuGestionarConvenio, MenuGestionarSolicitud } from '../MenuInternacionalizacion';
import TareasTerminadas from '../../../general/TareasTerminadas';
import { Checkbox, Dialog, DialogActions, DialogContent, FormControl, Grid, List, ListItem, ListItemIcon, ListItemText, ListSubheader, makeStyles, TextField } from '@material-ui/core';
import { BtnForm } from '../../../general/BotonesAccion';
import { consulta, formulario, obtenerEstadosProceso, obtenerMisDatos } from '../../../../global/js/funciones';
import AppBarModal from '../../../general/AppBarModal';
import CustomDropzone from '../../../general/CustomDropzone';
import { ESTADOS, ID_JF } from '../helper';
import emma_w from '../../../../global/imagenes/emma_w.png';
import EnviarMultiplesArchivos from '../../../general/EnviarMultiplesArchivos';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

function RenderEstados({ estados_siguiente, estado, cambiarSeleccion }) {
  const classes = useStyles();
  return (
    <List className = { classes.root } subheader = {
      <ListSubheader component = "div" >
        Seleccione Estado
      </ListSubheader>
    }>
      { 
        estados_siguiente.map(({ secundario }) => {
          let { codigo, nombre } = secundario;
          return (
            <ListItem key = { codigo } role = { undefined } dense button onClick = { () => cambiarSeleccion(codigo) } >
              <ListItemIcon>
                <Checkbox edge = "start" checked = { estado === codigo } tabIndex = { -1 } disableRipple />
              </ListItemIcon>
              <ListItemText id = { codigo } primary = { nombre } />
            </ListItem>
          );
        })
      }
    </List>
  );
}

class ConvenioGestionar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cargando: true,
      estado: '',
      observacion: '',
      seleccion: 'gestion',
      titulo_res: 'Cargando..',
      archivos: [],
      estados_siguiente: [],
      actasFaltantes: [],
      codigo_conve: '',
    }
  }
  
  async componentDidUpdate({ modalGestionarSolicitud }, { estado }) {
    let { modalGestionarSolicitud: modalGestionarSolicitudNew, solicitud } = this.props;
    let { estado: estadoNew } = this.state;
    
    if (estadoNew !== estado) this.setState({ observacion: '', cargando: false });
    if (modalGestionarSolicitud !== modalGestionarSolicitudNew) {
      if (modalGestionarSolicitudNew) {
        this.reiniciarEstados();
        console.log(solicitud)
        let adjuntos = await this.getAdjFaltantes(solicitud.id)
        console.log(adjuntos)
        this.trasnformarDataAdj(adjuntos)
        let estados_siguiente = await obtenerEstadosProceso(solicitud.tipo_solicitud.id, [{ 'llave': 'principal', 'valor': solicitud.estado_actual.id }, { 'llave': 'secundario__generica', 'valor': ESTADOS }]);
        this.setState({ estado: '', mensaje: '', estados_siguiente, cargando: false }
        );
      }
    }
  }
  trasnformarDataAdj = (data) => {
    let actasFaltantes = [];
    data.map(({ secundario: { nombre, descripcion, id, archivo } }) => {
      actasFaltantes.push({ nombre, descripcion, codigo: id, archivo });
      return true;
    })
    this.setState({ actasFaltantes });
  }

  getAdjFaltantes = (id_solicitud) => {
    return new Promise(resolve => {
      consulta(`api/v1.0/internacionalizacion/solicitudes/estados/requeisitoactual/${id_solicitud}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);

      })
    })
  }
  onSubmit = async (archivo) => {
    let { solicitud, gestionarSolicitud } = this.props;
    let {  estado, observacion, cargando, codigo_conve } = this.state;
    this.setState({ cargando: true, titulo_res: 'Enviando..' });
    let data = { observacion, estado, archivos:archivo, codigo_conve}
    gestionarSolicitud(solicitud.id, data);
    this.setState({ cargando: false, titulo_res: 'Cargando..' });
    if (estado && archivo.length) await this.cargarArchivos(solicitud.id, archivo);
  }

  agregarArchivos = (arc) => {
		let { archivos } = this.state;
		archivos = archivos.concat(arc);
		this.setState({ archivos });
	}

  cargarArchivos(id, archivos) {
		let { actualizarMensaje,solicitud:{tipo_solicitud:{codigo}} } = this.props;
    let {actasFaltantes} = this.state
    // console.log('acta faltantes',actasFaltantes)
		if (archivos.length === 0) return true;
		const data = new FormData();
		data.append(`total`, archivos.length);
    if(codigo=="Con_Int"){
      data.append(`tipo`, 1);
      data.append(`codigo`,actasFaltantes[0].codigo)
    }else {
      data.append(`tipo`, 0);
    }
    console.log(archivos)
		archivos.map(({archivo,codigo}, index) => {data.append(`archivo${index}`, archivo)
    data.append(`codigo${index}`, codigo)}
    );
    // console.log(data)
		formulario(`api/v1.0/internacionalizacion/solicitud/${id}/adjuntar`, data, 'post', (error, estado, resp) => {
			if (error) {
				const title = 'Ha ocurrido un error al adjuntar los archivos, contacte con el administrador.';
				const icon = 'error';
				actualizarMensaje({ titulo: title, tipo: icon, mostrar: true, tiempo: 6000 });
			}
		});
	}

  eliminarArchivo = (i) => {
		let { archivos } = this.state;
		let { actualizarMensaje } = this.props;
		archivos.splice(i, 1);
		this.setState({ archivos });
		actualizarMensaje({ titulo: `Archivo Retirado.`, tipo: 'success', mostrar: true, tiempo: 6000 });
	}

  onChange = ({ target }) => {
		let { value, name } = target;
		this.setState({
			[name]: value
		});
	}

  pintar() {
    let { seleccion } = this.state;
    return (
      <div>
        { seleccion === 'gestion' && this.pintarEstados() }
        { seleccion === 'adjuntos' && this.pintarAdjuntos() }
      </div>
    )
  }

  pintarAdjuntos() {
    let { archivos } = this.state;
    return <CustomDropzone archivos = { archivos } addFunction = { this.agregarArchivos } deleteFunction = { this.eliminarArchivo } />
  }

  pintarEstados() {
    let { estado, estados_siguiente, observacion, codigo_conve } = this.state;
    let {solicitud, persona} = this.props

    console.log(solicitud)
    console.log(estado)
    console.log(estados_siguiente)
    return (
      <>
      <Grid container>
        <Grid item xs = { 12 } xl = { 12 } sm = { 12 } md = { 12 } lg = { 12 }>
          <RenderEstados 
            cambiarSeleccion = { (estado) => this.setState({ estado }) } 
            estado = { estado } 
            estados_siguiente = { estados_siguiente } 
          />
        </Grid>
        {
          
          estado &&
          <>
               <Grid item xs={12} xl={12} sm={12} md={12} lg={12} className={this.state.actasFaltantes.length === 0 ? "oculto" : ""}>
             <EnviarMultiplesArchivos
               enModal={false}
               enviarArchivos={(archivos) => this.onSubmit(archivos)}
               limite={2}
               mostrarMensaje={false}
               lista={this.state.actasFaltantes}
               mostrarRender={true}
               mostrar={true}
               enLista={true}
             />
           </Grid>
            <Grid item xs = { 12 } md = { 12 }>
              <FormControl className = "form-control" >
                <TextField
                  value = { observacion }
                  id = "txtObservacion"
                  label = "Observaciones"
                  name = "observacion"
                  placeholder = "Digite observaciones de la solicitud"
                  multiline
                  onChange = { this.onChange }
                />
              </FormControl>
            </Grid>
        
           </>
        }
        {estado == 'Con_sus' && persona.id == ID_JF &&
        <>
            <Grid item xs={12} md={12} style = { { margin: "20px 0 5px 0" } } >
              <FormControl className="form-control" >
                <TextField
                  value={codigo_conve.toUpperCase()}
                  id="txtcodigo_conve"
                  label="Código convenio"
                  name="codigo_conve"
                  placeholder="Digite el código del convenio. Ejemplo: ABC-123"
                  multiline
                  onChange={this.onChange}
                />
              </FormControl>
            </Grid>
        </>
        }
      </Grid>
      </>
    )
  }

  reiniciarEstados() {
    this.setState({
      cargando: true,
      titulo_res: 'Cargando..',
      estado: '',
      estados_siguiente: [],
      seleccion: 'gestion',
      archivos: [],
    })
  }

  render () {
    let { modalGestionarSolicitud, mostrarModalGestionarSolicitud } = this.props;
    let { seleccion, cargando, titulo_res } = this.state;
    return (
      <Dialog fullWidth = { true } maxWidth = "sm" open = { modalGestionarSolicitud } onClose = { mostrarModalGestionarSolicitud.bind(this, false) } aria-labelledby = "alert-dialog-title" aria-describedby="alert-dialog-description">
        <AppBarModal titulo = { '¿ Gestionar Solicitud ?' } mostrarModal = { mostrarModalGestionarSolicitud } titulo_accion = "" accion = "" />
        <MenuGestionarConvenio seleccionar = { (seleccion) => this.setState({ seleccion }) } sele = { seleccion } />
        <DialogContent style = { { padding: '1' } } className = 'scroll'>
          {
            cargando ? 
              <TareasTerminadas mensaje = { titulo_res } marginTop = '7%' imagen = { emma_w } widthImg = "7%" /> 
              : <div style = { { padding: "5px 0 5px 0" } }>{ this.pintar() }</div>
          }
        </DialogContent>
        <DialogActions>
          <BtnForm texto = "CANCELAR" callback = { () => mostrarModalGestionarSolicitud(false) } />
          { !cargando && <BtnForm texto = "ACEPTAR" callback = { () => { document.getElementById('enviar_form_multi_archivo').click() }} /> }
        </DialogActions>
      </Dialog>
    )
  }
}

// Props del componente
ConvenioGestionar.propTypes = {
  // Variables
  modalGestionarSolicitud: PropTypes.bool.isRequired,
  // Funciones
  mostrarModalGestionarSolicitud: PropTypes.func.isRequired,
}

export default ConvenioGestionar;
