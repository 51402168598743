import React, { Component } from 'react'
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Grid from '@material-ui/core/Grid';
import AppBarModal from '../../general/AppBarModal'
import { BtnForm } from "../../general/BotonesAccion";
import InputFile from "../../general/InputFile";
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import AttachFile from '@material-ui/icons/AttachFile'
import { api, obtenerValores } from '../../../global/js/funciones'
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import { ACTIVIDAD_EMPRESA } from '../helper';

class ValoresModificarPractica extends Component {

  constructor(props) {
    super(props)
    this.state = {
      id: 0,
      codigo: '',
      nombre: '',
      valora: '',
      valorb: '',
      valorc: '',
      valord: '',
      valore: '',
      valorf: '',
      valorg: '',
      archivo: '',
      nombre_archivo: '',
      descripcion: '',
      descripcion: '',
      backactividadempreasa: [],
    }
  }
  async componentDidMount() {
    let backactividadempreasa = await obtenerValores(ACTIVIDAD_EMPRESA);
    this.setState({ backactividadempreasa });
    console.log(backactividadempreasa);
  }

  componentDidUpdate({ modalModValores }) {
    let { modalModValores: modalModNew, valor } = this.props;
    if (modalModNew !== modalModValores) {
      this.changeState(valor);
    }
  }

  changeState = ({ id, codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, archivo }) => {
    this.setState({
      id,
      codigo: codigo ? codigo : '',
      nombre: nombre ? nombre : '',
      descripcion: descripcion ? descripcion : '',
      valora: valora ? valora : '',
      valorb: valorb ? valorb : '',
      valorc: valorc ? valorc : '',
      valord: valord ? valord : '',
      valore: valore ? valore : '',
      valorf: valorf ? valorf : '',
      valorg: valorg ? valorg : '',
      archivo: archivo ? archivo : '',
      nombre_archivo: archivo ? archivo.replace(/^.*[\\\/]/, '') : '',
    })
  }
  changeStateest = (estados) => {
    this.setState(estados);
  };

  onSubmit = e => {
    let { id, codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, archivo } = this.state;
    let archivoSend = archivo ? document.getElementById('archivo_generica_mod').files[0] : '';
    this.props.modificarValor(id, codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, archivoSend);
    e.preventDefault();
  }

  renderForm = () => {
    let { nombre, valora, valorb, valorc, valord, valore, valorf, valorg, archivo, codigo, descripcion, nombre_archivo,backactividadempreasa } = this.state;
    let {
      inputValora,
      inputValorb,
      inputValorc,
      inputValord,
      inputValore,
      inputValorf,
      inputValorg,
      inputArchivo,
      inputCodigo,
      inputDescripcion,
      inputNombre,
    } = this.props.inputs;

    const configInput = (tipo, requerido) => {
      let validaciones = [], mensajes = [];
      if (requerido) {
        validaciones.push("required");
        mensajes.push("El campo es requerido");
      }
      if (tipo === 'email') {
        validaciones.push("isEmail");
        mensajes.push("Correo no valido");
      } else if (tipo === 'number') {
        validaciones.push("isNumber");
        mensajes.push("Debe ser Numérico");
      }
      return {
        validaciones,
        mensajes,
      }
    }

    let validaciones = {
      codigo: configInput(inputCodigo.tipo, inputCodigo.requerido),
      valora: configInput(inputValora.tipo, inputValora.requerido),
      valorb: configInput(inputValorb.tipo, inputValorb.requerido),
      valorc: configInput(inputValorc.tipo, inputValorc.requerido),
      valord: configInput(inputValord.tipo, inputValord.requerido),
      valore: configInput(inputValore.tipo, inputValore.requerido),
      valorf: configInput(inputValorf.tipo, inputValorf.requerido),
      valorg: configInput(inputValorg.tipo, inputValorg.requerido),
      descripcion: configInput(inputValora.tipo, inputValora.requerido),
    }

    return (
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <TextValidator
            variant="outlined"
            margin="dense"
            id="nombre"
            label={inputNombre.label}
            type={inputNombre.tipo}
            autoFocus
            fullWidth
            name="nombre"
            value={nombre}
            validators={["required"]}
            errorMessages={["El campo es requerido"]}
            onChange={inputNombre.mayusculas ? this.onChangeUpperCase : this.onChange}
          />
        </Grid>
        {
          inputDescripcion.mostrar ?
          <Grid item xs={12} md={12} >
            <FormControl variant="outlined" className="form-control">
           
              <InputLabel htmlFor="select-multiple-checkbox">
                Actividad Economica
              </InputLabel>
              <Select
              
                id="actividadeconomica"
                value={descripcion}
                label="Actividad Economica"
                onChange={(e) =>
                  this.changeStateest({ descripcion: e.target.value })
                }
                name="actividadeco"
                selected="Seleccione una Actividad"
              >
                {backactividadempreasa.map((item, index) => (
                  <MenuItem key={index} value={item.nombre}>
                    {item.nombre}
                  </MenuItem>
                ))}
              </Select>
          </FormControl>
          </Grid>:''
        }
        {
          inputCodigo.mostrar ?
            <Grid item xs={12} md={12}>
              <TextValidator
                variant="outlined"
                margin="dense"
                id="codigo"
                label={inputCodigo.label}
                type={inputCodigo.tipo}
                fullWidth
                name="codigo"
                value={codigo}
                validators={validaciones.codigo.validaciones}
                errorMessages={validaciones.codigo.mensajes}
                onChange={inputCodigo.mayusculas ? this.onChangeUpperCase : this.onChange}
              />
            </Grid> : ''
        }
        {
          inputValora.mostrar ?
            <Grid item xs={12} md={12}>
              <TextValidator
                variant="outlined"
                margin="dense"
                id="valora"
                label={inputValora.label}
                type={inputValora.tipo}
                fullWidth
                name="valora"
                value={valora}
                validators={validaciones.valora.validaciones}
                errorMessages={validaciones.valora.mensajes}
                onChange={inputValora.mayusculas ? this.onChangeUpperCase : this.onChange}
              />
            </Grid>
            : ''
        }
        {
          inputValorb.mostrar ?
            <Grid item xs={12} md={12}>
              <TextValidator
                variant="outlined"
                margin="dense"
                id="valorb"
                label={inputValorb.label}
                type={inputValorb.tipo}
                fullWidth
                name="valorb"
                value={valorb}
                validators={validaciones.valorb.validaciones}
                errorMessages={validaciones.valorb.mensajes}
                onChange={inputValorb.mayusculas ? this.onChangeUpperCase : this.onChange}
              />
            </Grid>
            : ''
        }
        {
          inputValorc.mostrar ?
            <Grid item xs={12} md={12}>
              <TextValidator
                variant="outlined"
                margin="dense"
                id="valorc"
                label={inputValorc.label}
                type={inputValorc.tipo}
                fullWidth
                name="valorc"
                value={valorc}
                validators={validaciones.valorc.validaciones}
                errorMessages={validaciones.valorc.mensajes}
                onChange={inputValorc.mayusculas ? this.onChangeUpperCase : this.onChange}
              />
            </Grid>
            : ''
        }
        {
          inputValord.mostrar ?
            <Grid item xs={12} md={12}>
              <TextValidator
                variant="outlined"
                margin="dense"
                id="valord"
                label={inputValord.label}
                type={inputValord.tipo}
                fullWidth
                name="valord"
                value={valord}
                validators={validaciones.valord.validaciones}
                errorMessages={validaciones.valord.mensajes}
                onChange={inputValord.mayusculas ? this.onChangeUpperCase : this.onChange}
              />
            </Grid>
            : ''
        }
        {
          inputValore.mostrar ?
            <Grid item xs={12} md={12}>
              <TextValidator
                variant="outlined"
                margin="dense"
                id="valore"
                label={inputValore.label}
                type={inputValore.tipo}
                fullWidth
                name="valore"
                value={valore}
                validators={validaciones.valore.validaciones}
                errorMessages={validaciones.valore.mensajes}
                onChange={inputValore.mayusculas ? this.onChangeUpperCase : this.onChange}
              />
            </Grid>
            : ''
        }
        {
          inputValorf.mostrar ?
            <Grid item xs={12} md={12}>
              <TextValidator
                variant="outlined"
                margin="dense"
                id="valorf"
                label={inputValorf.label}
                type={inputValorf.tipo}
                fullWidth
                name="valorf"
                value={valorf}
                validators={validaciones.valorf.validaciones}
                errorMessages={validaciones.valorf.mensajes}
                onChange={inputValorf.mayusculas ? this.onChangeUpperCase : this.onChange}
              />
            </Grid>
            : ''
        }
        {
          inputValorg.mostrar ?
            inputValorg.select ?
            <Grid item xs={12} md={12}>
              <FormControl className = 'form-control' required = { inputValorg.requerido } variant = 'outlined' error = { inputValorg.requerido && valorg.length == 0 }>
                <InputLabel id = 'valorg_label'>{ inputValorg.label }</InputLabel>
                <Select
                  labelId = 'valorg_label'
                  id = 'valorg'
                  name = 'valorg'
                  value = { valorg }
                  label = { inputValorg.label }
                  fullWidth
                  onChange = { inputValorg.mayusculas ? this.onChangeUpperCase : this.onChange }
                >
                  { inputValorg.lista.map(({ id, nombre }) => <MenuItem key = { id } value = { nombre }>{ nombre }</MenuItem>) }
                </Select>
                <FormHelperText>{ inputValorg.requerido && valorg.length == 0 ? validaciones.valorg.mensajes : '' }</FormHelperText>
              </FormControl>
            </Grid>
            : <Grid item xs={12} md={12}>
                <TextValidator
                  variant="outlined"
                  margin="dense"
                  id="valorg"
                  label={inputValorg.label}
                  type={inputValorg.tipo}
                  fullWidth
                  name="valorg"
                  value={valorg}
                  validators={validaciones.valorg.validaciones}
                  errorMessages={validaciones.valorg.mensajes}
                  onChange={inputValorg.mayusculas ? this.onChangeUpperCase : this.onChange}
                />
              </Grid>
            : ''
        }
        {
          inputArchivo.mostrar ?
            <Grid item xs={12} >
              <TextField
                className='oculto'
                ref={this.fileInput}
                type="file"
                id="archivo_generica_mod"
                name="archivo"
                label={inputArchivo.label}
                fullWidth
                autoComplete="billing address-line1"
                onChange={this.onChange}
              />
              <Grid item xs={12}>
                <InputFile label={inputArchivo.label} id='archivo_generica_mod' value={archivo ? archivo.replace(/^.*[\\\/]/, '') : nombre_archivo} />
                {nombre_archivo ? <Chip size="small" color="primary" clickable label="Ver Archivo" icon={<AttachFile />} component="a" href={`${api + archivo}`} target="_blank" /> : ''}
              </Grid>
            </Grid>
            : ''
        }
      </Grid>
    )
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  onChangeUpperCase = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value.toUpperCase()
    })
  }

  render() {
    let { modalModValores, mostrarModalModValores, titulo } = this.props;
    return (
      <Dialog open={modalModValores} onClose={mostrarModalModValores.bind(this, false)} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="sm">
        <AppBarModal titulo={titulo} mostrarModal={mostrarModalModValores} titulo_accion="" accion="" />
        <DialogContent className='scroll'>
          <ValidatorForm onSubmit={this.onSubmit}>
            {this.renderForm()}
            <Button color="primary" type="submit" id='enviar_form_mod_valores' className="oculto"> Guardar </Button>
          </ValidatorForm>
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CANCELAR" callback={() => mostrarModalModValores(false)} />
          <BtnForm texto="GUARDAR" callback={() => { document.getElementById('enviar_form_mod_valores').click() }} />
        </DialogActions>
      </Dialog>
    )
  }
}

//Props del componente
ValoresModificarPractica.propTypes = {
  //variables
  modalModValores: PropTypes.bool.isRequired,
  inputs: PropTypes.object.isRequired,
  valor: PropTypes.shape({ id: PropTypes.number.isRequired, }),
  //funciones
  mostrarModalModValores: PropTypes.func.isRequired,
}

ValoresModificarPractica.defaultProps = {
  titulo: '¡ Modificar Valor !',
  inputs: {
    inputCodigo: { 'label': 'Codigo', 'mostrar': false, 'tipo': 'text', requerido: false, mayusculas: false },
    inputNombre: { 'label': 'Nombre', 'mostrar': true, 'tipo': 'text', requerido: true, mayusculas: false },
    inputDescripcion: { 'label': 'Descripción', 'mostrar': true, 'tipo': 'text', requerido: false, mayusculas: false },
    inputValora: { 'label': 'Valor A', 'mostrar': false, 'tipo': 'text', requerido: false, mayusculas: false },
    inputValorb: { 'label': 'Valor B', 'mostrar': false, 'tipo': 'text', requerido: false, mayusculas: false },
    inputValorc: { 'label': 'Valor C', 'mostrar': false, 'tipo': 'text', requerido: false, mayusculas: false },
    inputValord: { 'label': 'Valor D', 'mostrar': false, 'tipo': 'text', requerido: false, mayusculas: false },
    inputValore: { 'label': 'Valor E', 'mostrar': false, 'tipo': 'text', requerido: false, mayusculas: false },
    inputValorf: { 'label': 'Valor F', 'mostrar': false, 'tipo': 'text', requerido: false, mayusculas: false },
    inputValorg: { 'label': 'Valor G', 'mostrar': false, 'tipo': 'text', requerido: false, mayusculas: false, 'select': false, 'lista': [''] },
    inputArchivo: { 'label': 'Adjuntar Archivo', 'mostrar': false, 'tipo': 'file', requerido: false, },
  }
};


export default ValoresModificarPractica;

