import React, { Component } from 'react'
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import moment from 'moment'

import AppBarModal from '../general/AppBarModal'
import { BtnForm } from "../general/BotonesAccion";

class EleccionesProlongar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fecha_fin: null
    }
  }


  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  onSubmit = e => {
    let { id, nombre, fecha_inicio } = this.props.eleccion;
    let { fecha_fin } = this.state
    this.props.prolongarEleccion(id, nombre, fecha_inicio, moment(fecha_fin).format('YYYY-MM-DD'));
    e.preventDefault();
  }

  render() {
    let { modalProlEleccion, mostrarModalProlEleccion } = this.props

    return (
      <Dialog open={modalProlEleccion} fullWidth={true} maxWidth="xs" onClose={() => mostrarModalProlEleccion(false)} aria-labelledby="form-dialog-title">
        <AppBarModal titulo="Prolongar Elección" mostrarModal={() => mostrarModalProlEleccion(false)} titulo_accion="" accion="" />
        <DialogContent className='scroll'>
          <ValidatorForm onSubmit={this.onSubmit}>
            <Grid container spacing={1} alignItems="flex-end">
            </Grid>
            <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
              <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} >
                  <Grid container>
                    <KeyboardDatePicker
                      required={true}
                      style={{ marginTop: 3, width: '100%' }}
                      minDate={Date("YYYY-MM-dd")}
                      margin="normal"
                      label="Fecha fin"
                      format="yyyy-MM-dd"
                      value={this.state.fecha_fin}
                      onChange={(fecha_fin) => this.setState({ fecha_fin })}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      minDateMessage={`La fecha debe ser mayor que la fecha actual.`}
                      invalidDateMessage='Formato Invalido'
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Button color="primary" type="submit" id='enviar_form_pro_eleccion' className="oculto"> Enviar </Button>
          </ValidatorForm>
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CANCELAR" callback={() => mostrarModalProlEleccion(false)} />
          <BtnForm texto="ACEPTAR" callback={() => { document.getElementById('enviar_form_pro_eleccion').click() }} />
        </DialogActions>
      </Dialog>
    )
  }


}

export default EleccionesProlongar;
