import React, { Component } from 'react'
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import AppBarModal from '../general/AppBarModal'
import { BtnForm } from "../general/BotonesAccion";

class GenericasModificar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      id: 0,
      codigo: '',
      nombre: '',
      descripcion: '',
    }
  }

  componentDidUpdate({ modalMod }) {
    let { modalMod: modalModNew, generica } = this.props;
    if (modalModNew !== modalMod) {
      this.changeState(generica);
    }
  }

  changeState = ({ id, codigo, nombre, descripcion }) => {
    this.setState({
      id,
      codigo,
      nombre,
      descripcion,
    })
  }

  onSubmit = e => {
    let { id, codigo, nombre, descripcion } = this.state;
    this.props.modificarGenerica(id, codigo, nombre, descripcion);
    e.preventDefault();
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  render() {
    let { codigo, nombre, descripcion } = this.state;
    let { modalMod, mostrarModalMod } = this.props;
    return (
      <Dialog open={modalMod} onClose={mostrarModalMod.bind(this, false)} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="xs">
        <AppBarModal titulo="Modificar Genérica" mostrarModal={mostrarModalMod} titulo_accion="" accion="" />
        <DialogContent className='scroll'>
          <ValidatorForm onSubmit={this.onSubmit}>
          <TextValidator
              variant="outlined"
              margin="dense"
              id="codigo"
              label="Codigo"
              type="text"
              fullWidth
              name="codigo"
              value={codigo}
              validators={["required"]}
              // errorMessages={["El campo es requerido"]}
              onChange={this.onChange}
            />
            <TextValidator
              variant="outlined"
              margin="dense"
              id="nombre"
              label="Nombre"
              type="text"
              fullWidth
              name="nombre"
              value={nombre}
              validators={["required"]}
              errorMessages={["El campo es requerido"]}
              onChange={this.onChange}
            />
            <TextValidator
              variant="outlined"
              margin="dense"
              id="descripcion"
              label="Descripción"
              type="text"
              fullWidth
              name="descripcion"
              value={descripcion}
              onChange={this.onChange}
            />
            <Button color="primary" type="submit" id='enviar_form_mod_generica' className="oculto"> Guardar </Button>
          </ValidatorForm>
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CANCELAR" callback={() => mostrarModalMod(false)} />
          <BtnForm texto="GUARDAR" callback={() => { document.getElementById('enviar_form_mod_generica').click() }} />
        </DialogActions>
      </Dialog>
    )
  }
}

//Props del componente
GenericasModificar.propTypes = {
  //variables
  modalMod: PropTypes.bool.isRequired,
  generica: PropTypes.shape({
    id: PropTypes.number.isRequired,
    nombre: PropTypes.string.isRequired,
    descripcion: PropTypes.string.isRequired,
  }),
  //funciones
  mostrarModalMod: PropTypes.func.isRequired,
  modificarGenerica: PropTypes.func.isRequired,
}

export default GenericasModificar;

