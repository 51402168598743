import React, { Component } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, TextField, DialogActions, Grid, Dialog, DialogContent, ListSubheader, List, ListItem, ListItemIcon, ListItemText, Checkbox } from '@material-ui/core';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import { BtnForm } from "../../general/BotonesAccion";
import AppBarModal from '../../general/AppBarModal';
import { obtenerEstadosProceso, coloresEmma } from "../../../global/js/funciones";
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from "../../../global/imagenes/emma_w.png";
import { MenuGestionar } from "./MenuBecas";
import EnviarArchivo from "../../general/EnviarArchivo";
import {ESTADOS_BECAS} from '../helper';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

function RenderEstados({ estados_siguiente, estado, cambiarSeleccion }) {
  const classes = useStyles();
  return (
    <List
      className={classes.root}
      subheader={
        <ListSubheader component="div">Seleccione Estado</ListSubheader>
      }
    >
      {estados_siguiente.map(({ secundario }) => {
        let { codigo, nombre, valore } = secundario;
        return (
          <ListItem
            key={codigo}
            role={undefined}
            dense
            button
            onClick={() => cambiarSeleccion({ codigo, valore })}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={estado === codigo}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText id={codigo} primary={nombre} />
          </ListItem>
        );
      })}
    </List>
  );
}

class BecasGestionar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cargando: true,
      titulo_res: "Cargando..",
      estado: { codigo: "", valore: "" },
      motivo: "",
      estados_siguiente: [],
      seleccion: "gestion",
      porcentaje: "",
    };
  }

  async componentDidUpdate({ modalGestionBeca }, { estado }) {
    let { modalGestionBeca: modalGestionBecaNew, beca } = this.props;
    let { estado: estadoNew } = this.state;
    if (estadoNew.codigo !== estado.codigo) this.setState({ motivo: "" });
    if (modalGestionBeca !== modalGestionBecaNew) {
      if (modalGestionBecaNew) {
        this.reiniciarEstado();
        console.log(beca.tipo_beca)
        let estados_siguiente = await obtenerEstadosProceso(beca.tipo_beca.id, [
          { llave: "principal", valor: beca.estado_actual.id },
          { llave: "secundario__generica", valor: ESTADOS_BECAS },
        ]);
        console.log(estados_siguiente);
        this.setState({
          estados_siguiente,
          porcentaje: beca && beca.porcentaje ? beca.porcentaje : "",
          cargando: false,
        });
      }
    }
  }

  reiniciarEstado() {
    this.setState({
      cargando: true,
      titulo_res: "Cargando..",
      estado: { codigo: "", valore: "" },
      motivo: "",
      estados_siguiente: [],
      seleccion: "gestion",
      porcentaje: "",
    });
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value,
    });
  };

  gestionVolante = async (volante) => {
    let { guardarVolante, gestionarBeca, beca } = this.props;
    let { estado, motivo, porcentaje } = this.state;
    await guardarVolante({ volante });
    this.setState({ cargando: true, titulo_res: "Enviando.." });
    let data = { mensaje: motivo, estado: estado.codigo, porcentaje };
    gestionarBeca(beca.id, data, () =>
      this.setState({ cargando: false, titulo_res: "Cargando.." })
    );
  };

  onSubmit = (e) => {
    let { estado, motivo, porcentaje } = this.state;
    let { beca, gestionarBeca, mostrarModalVolante } = this.props;
    if (estado.codigo === "Est_bec_apl") {
      mostrarModalVolante(true);
    } else {
      this.setState({ cargando: true, titulo_res: "Enviando.." });
      let data = { mensaje: motivo, estado: estado.codigo, porcentaje };
      gestionarBeca(beca.id, data, () =>
        this.setState({ cargando: false, titulo_res: "Cargando.." })
      );
    }
    e.preventDefault();
  };

  pintarEstados() {
    let { estado, estados_siguiente, motivo, porcentaje } = this.state;
    let { modalVolante, mostrarModalVolante, beca } = this.props;
    return (
      <Grid container spacing={2}>
        {beca && beca.aprobado == "1" &&
          <Grid item xs={12} >
            <List dense={true}>
              <ListItem>
                <ListItemIcon style={{ color: coloresEmma.primarycolor }}>
                  <NotificationImportantIcon />
                </ListItemIcon>
                <ListItemText
                  primary="El estudiante ya cuenta con una solicitud aprobada para este periodo."
                  style={{ color: '#757575' }}
                />
              </ListItem>
            </List>
          </Grid>
        }
        <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
          <RenderEstados cambiarSeleccion={(estado) => this.setState({ estado })} estado={estado.codigo} estados_siguiente={estados_siguiente} />
        </Grid>
        {estado.codigo && (
          <>
            {estado.valore === "agregar_porcentaje" && (
              <Grid item xs={12} md={12}>
                <FormControl className="form-control">
                  <TextField
                    value={porcentaje}
                    id="txtPorcentaje"
                    label={"Porcentaje Beca"}
                    name="porcentaje"
                    placeholder={"Escriba el Porcentaje de la Beca"}
                    multiline
                    onChange={this.onChange}
                  />
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} md={12}>
              <FormControl className="form-control">
                <TextField
                  value={motivo}
                  id="txtMotivoNegado"
                  label={
                    estado.codigo === "Est_bec_neg"
                      ? "Motivo Negado"
                      : "Observaciones"
                  }
                  name="motivo"
                  placeholder={
                    estado.codigo === "Est_bec_neg"
                      ? "Escriba el motivo de negacion de la solicitud"
                      : "Escriba Observaciones"
                  }
                  multiline
                  onChange={this.onChange}
                />
              </FormControl>
            </Grid>
          </>
        )}
        <EnviarArchivo
          modalAdd={modalVolante}
          mostrarModalAdd={() => mostrarModalVolante(false)}
          enviarArchivo={(file) => this.gestionVolante(file)}
          titulo="Agregar Volante"
        />
      </Grid>
    );
  }

  pintar() {
    let { seleccion } = this.state;
    return <div>{seleccion === "gestion" && this.pintarEstados()}</div>;
  }

  render() {
    let { modalGestionBeca, mostrarGestionBeca } = this.props;
    let { cargando, titulo_res, seleccion } = this.state;
    return (
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={modalGestionBeca}
        onClose={mostrarGestionBeca.bind(this, false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBarModal
          titulo={"¿ Gestionar Beca ?"}
          mostrarModal={mostrarGestionBeca}
          titulo_accion=""
          accion=""
        />
        <MenuGestionar
          seleccionar={(seleccion) => this.setState({ seleccion })}
          sele={seleccion}
        />
        <DialogContent style={{ padding: "1" }} className="scroll">
          {cargando ? (
            <TareasTerminadas
              mensaje={titulo_res}
              marginTop="7%"
              imagen={emma_w}
              widthImg="7%"
            />
          ) : (
            <div style={{ padding: "5px 0 5px 0" }}>{this.pintar()}</div>
          )}
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CERRAR" callback={() => mostrarGestionBeca(false)} />
          {!cargando && <BtnForm texto="ACEPTAR" callback={this.onSubmit} />}
        </DialogActions>
      </Dialog>
    );
  }
}

export default BecasGestionar;
