import React from 'react';
import PropTypes from 'prop-types';
import { BtnDetalle, BtnEditar, BtnEliminar } from '../../../general/BotonesAccion';
import ListarDatos from '../../../general/ListarDatos';

export default function RequisitosListar(props) {
  let { mostrarModalAddValores, mostrarModalDetValores, mostrarModalModValores, mostrarModalDelValores, requisitos, actualizarDataValor } = props;

  const acciones = (data) => {
    const onClickDetalle = (data) => {
      actualizarDataValor(data);
      mostrarModalDetValores(true);
    }
    const onClickModificar = (data) => {
      actualizarDataValor(data);
      mostrarModalModValores(true);
    }
    const onClickEliminar = (data) => {
      actualizarDataValor(data);
      mostrarModalDelValores(true);
    }
    let detalle = <BtnDetalle callback = { () => onClickDetalle(data) } />
    let modificar = <BtnEditar callback = { () => onClickModificar(data) } />
    let eliminar = <BtnEliminar callback = { () => onClickEliminar(data) } />
    return (
      <div>
        { detalle }{ modificar }{ eliminar }
      </div>
    )
  }

  return (
    <ListarDatos
      datos = { requisitos }
      titulo = 'Lista de Requisitos'
      buscar = { true }
      opciones = { true }
      agregar = { true }
      actAgregar = { () => mostrarModalAddValores(true) }
      sub_titulo = 'Requisitos'
      id = 'tbl_requisitos'
      acciones = { (row) => acciones(row) }
      fila_principal = 'nombre'
      filas = { [{ 'id': 'descripcion' }] }
    />
  )
}

//Props del componente
RequisitosListar.propTypes = {
  //variables
  requisitos: PropTypes.array.isRequired,
  //funciones
  actualizarDataValor: PropTypes.func.isRequired,
  mostrarModalModValores: PropTypes.func.isRequired,
  mostrarModalDelValores: PropTypes.func.isRequired,
}
