import React, { Component } from 'react'
import PropTypes from 'prop-types'

// MATERIAL
import { Dialog, DialogContent, DialogActions, List, Paper, Stepper, Step, StepLabel, Grid } from '@material-ui/core';

// FUNCIONES GENERALES;
import AppBarModal from '../../general/AppBarModal';
import AlertasSimple from '../../general/AlertasSimple';
import TareasTerminadas from '../../general/TareasTerminadas';
import { BtnFormPage, BtnCargar } from '../../general/BotonesAccion';
import EnviarMultiplesArchivos from "../../general/EnviarMultiplesArchivos";
import { consulta, obtenerValoresFiltros, obtenerPermisosValorAlt, generarFiltros } from "../../../global/js/funciones";

// IMAGENES
import emma_w from '../../../global/imagenes/emma_w.png';

// HELPERS
import { EVIDENCIA } from '../Helpers';

// STEP
const configStep = ['Actualizar Evidencia'];


function AttachComponent(component_props) {
  let { evidencia, mensaje_retos_evidencia, submit } = component_props

  const trasnformarDataAdj = (data) => {
    let r = [];
    data.map((evi) => r.push({ nombre: evi.nombre, descripcion: evi.descripcion, codigo: evi.id, archivo: evi.archivo }))
    return r;
  }

  const acciones = (data, callback) => {
    const onClickCargar = (data) => callback(data);

    let cargar = <BtnCargar callback={() => onClickCargar(data)} color='#00bfa5' texto='Gestionar' />
    return <div>{cargar}</div>;
  }

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper elevation={0} square>
            <List component="nav" arial-label="main">
              <EnviarMultiplesArchivos
                acciones={acciones}
                enModal={false}
                enviarArchivos={(archivos) => submit(archivos)}
                limite={2}
                mostrarMensaje={false}
                enLista={true}
                lista={trasnformarDataAdj(evidencia)}
              />
            </List>
          </Paper>
        </Grid>


        {/* Mensaje informativo Evidencia */}
        <Grid item xs={12}>
          <AlertasSimple
            tipo='info'
            titulo={'Estimado estudiante, tener en cuenta: '}
            lista={mensaje_retos_evidencia}
            margin='0px'
          />
        </Grid>
      </Grid>

    </React.Fragment>

  )
}


class RetosActualizarEvidencia extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeStep: 0,
      evidencia: [],
      cargar: true,
      titulo_carg: 'Cargando...',
    }
  }

  async componentDidMount() {
    this.obtenerDatos();
  }

  transformar = data => {
    return data.map(e => ({
      value: e.id,
      label: e.nombre,
      codigo: e.codigo
    }));
  }


  obtenerRetoscuc(id) {
    return new Promise(async (resolve) => {
      let f = await generarFiltros([{ 'llave': 'id', 'valor': id }]);
      consulta(`api/v1.0/retos/${f}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && resp.length > 0 ? resp[0] : null);
      })
    })
  }


  async obtenerDatos() {
    this.setState({ cargando: true })
    let informativo_retos_solucion = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Ret_Info_Sol' }]);
    let informativo_retos_evidencia = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Ret_Info_Evi' }]);
    let evidencia = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Evi_Ret_Sol' }]);

    this.setState({ evidencia, cargando: false, informativo_retos_solucion: informativo_retos_solucion.length > 0 ? informativo_retos_solucion[0].descripcion : '', informativo_retos_evidencia: informativo_retos_evidencia.length > 0 ? informativo_retos_evidencia[0].descripcion : '' });
  }

  agregarArchivos = files => {
    let { actualizarMensaje } = this.props
    let { archivos, evidencia } = this.state
    files.map((archivo => {
      if (archivos.length === 10000) actualizarMensaje({ titulo: `Se ha superado el numero maximo de archivos ${evidencia.length}`, tipo: 'info', mostrar: true, tiempo: 6000 })
      else archivos.push(archivo)
    }))
    this.setState({ archivos })
  }

  eliminarArchivo = (i) => {
    let { actualizarMensaje } = this.props;
    let { archivos } = this.state;
    archivos.splice(i, 1)
    this.setState({ archivos })
    actualizarMensaje({ titulo: `Archivo Retirado.`, tipo: 'success', mostrar: true, tiempo: 6000 })
  }

  reiniciarEstado() {
    this.setState({
      activeStep: 0,
      cargando: false,
      titulo_carg: 'Cargando...',
    })
  }



  // OBTENER EVIDENCIA DE RETOS
  obtenerEvidencia = async () => {
    this.setState({ cargando: true })
    // let { estado } = this.state
    let evidencia = await obtenerPermisosValorAlt([{ 'llave': 'secundario__generica', 'valor': EVIDENCIA }, { 'llave': 'secundario__valora', 'valor': 'estudiante' }])
    this.setState({ cargando: false, evidencia })
  }

  // ENVIAR EVIDENCIA ACUTLIZADA
  enviarActulizarEvidenciaRetos = async (archivos) => {
    let { actualizarMensaje, actualizarEvidenciaRetos, retoscuc_id } = this.props
    let total_evidencia = archivos.length
    if (archivos.length > 0) {
      this.setState({ cargando: true, tit_cargando: 'Enviando..' })
      let data = { total_evidencia }
      actualizarEvidenciaRetos(archivos, data, retoscuc_id, (resp) => this.reiniciarEstado(resp))
    } else actualizarMensaje({ titulo: 'Debe adjuntar un archivo por cada requisito obligatorio.', tipo: 'info', mostrar: true, tiempo: 6000 })
  }

  configStepContent = () => {
    let { activeStep, initialTransition, archivos, evidencia, informativo_retos_evidencia, actualizarMensaje } = this.state

    let mensaje_retos_evidencia = [{ item: informativo_retos_evidencia }]
    switch (activeStep) {

      case 0:
        return (
          <AttachComponent
            {...{
              initialTransition,
              evidencia,
              archivos,
              actualizarMensaje,
              mensaje_retos_evidencia,
              eliminarArchivo: this.eliminarArchivo,
              agregarArchivos: this.agregarArchivos,
              submit: this.enviarActulizarEvidenciaRetos
            }}
          />
        )

      default:
        return <TareasTerminadas imagen={emma_w} mensaje='Esta bandeja esta vacía' widthImg='10%' marginTop='10%' />
    }
  }


  render() {
    let { modalactualizarEvidencia, mostrarModalActualizarEvidencia } = this.props
    let { cargando, titulo_carg, activeStep } = this.state

    return (
      <>
        <Dialog fullWidth={true} maxWidth="sm" open={modalactualizarEvidencia} onClose={() => mostrarModalActualizarEvidencia(false)}>
          <AppBarModal titulo={"¡ Actualizar Evidencia Retos !"} mostrarModal={() => mostrarModalActualizarEvidencia(false)} titulo_accion="Cerrar" accion={() => mostrarModalActualizarEvidencia(false)} />
          <DialogContent className='scroll'>
            {
              !cargando ?
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <Stepper activeStep={activeStep}>
                      {
                        configStep.map(label => (
                          <Step key={label}>
                            <StepLabel><p>{label}</p></StepLabel>
                          </Step>
                        ))
                      }
                    </Stepper>
                  </Grid>

                  {this.configStepContent()}

                </Grid> :
                <TareasTerminadas mensaje={titulo_carg} marginTop='7%' imagen={emma_w} widthImg="7%" />
            }

          </DialogContent>
          <DialogActions>
            <div>
              <BtnFormPage texto="ATRAS" callback={() => this.handleStep(false)} type="submit" />
              <BtnFormPage texto="GUARDAR" callback={() => { document.getElementById('enviar_form_multi_archivo').click() }} type="submit" />

            </div>
          </DialogActions>
        </Dialog >
      </>
    )
  }
}

RetosActualizarEvidencia.propsTyps = {
  modalactualizarEvidencia: PropTypes.bool.isRequired,
  actulizarMensaje: PropTypes.bool.isRequired,
}

export default RetosActualizarEvidencia