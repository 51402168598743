import React, { Component } from 'react'
import { connect } from 'react-redux'
import { mostrarModulo, actualizarMensaje } from "../../redux/actions/actGlobal";
import { obtenerValoresFiltros, consulta,generarFiltros,mostrarError,formulario,crear_form_data,modificarValorGenericaAlter } from "../../global/js/funciones";
import { MenuAdministrar } from "./tutoria/TutoriaMenu";
import emma_w from '../../global/imagenes/emma_w.png';
import TutoriaDetalle from './tutoria/TutoriaDetalle'
import TutoriaPermisos from './tutoria/TutoriaPermisos'
import TareasTerminadas from "../general/TareasTerminadas";
import { BtnDetalle, BtnGestionar, BtnFolder,BtnRegresar, BtnForm } from "../general/BotonesAccion";
import {mostrarModalFiltroTutoria,mostrarModalDetalleTutoria,mostrarModalGestionTutoria,mostrarModalModPeriodoTutoria,mostrarModalFiltroTutoriaDescarga} from "../../redux/actions/actTutorias"
import { Paper, Grid, Tooltip, IconButton, InputLabel, MenuItem, FormControl, DialogActions, DialogContent, Select, Dialog } from '@material-ui/core'
import TituloAccion from '../general/TituloAccion';
import FilterListIcon from '@material-ui/icons/FilterList';
import AccionesRapidas from '../general/AccionesRapidas';
import AppBarModal from '../general/AppBarModal'
import emma_s1 from '../../global/imagenes/emma_s1.png';
import TutoriasFiltros from './tutoria/TutoriasFiltros';
import ListarDatos from "../general/ListarDatos";
import moment from 'moment'
import TutoriasGestionar from './tutoria/TutoriasGestionar'
import fechaIcon from '../../global/imagenes/calendar.png';
import permisosIcon from '../../global/imagenes/lightbulb.png';
import EnviarMensajeFecha from "../general/EnviarMensajeFecha";



export class TutoriasAdministrar extends Component {

    constructor(props) {
        super(props)
        this.state = {
          filtros_tutoria: [],
          cargando: false,
          accion: 'inicio',
          accionProceso: 'inicio',
          accionAdm: 'inicio',
          administrador: false,
          tutorias: [],
          procesos:[],
          proceso: null,
          periodo: '',
          tutorias: null,
          modalFiltro: false,
          vistaDetalle : 'detalle',
          id_estudiante : 0,
          estados_procesos: [],
          cargarFiltroP : true,
          periodo_filtro : null,
          periodos:[],
          filtrarPeriodo : false,
          cargandoPeriodo: true,
          procesoActual: {},
    
        }
      }



    componentDidMount() {
        let { mostrarModulo } = this.props;
        mostrarModulo({ ruta: '/gestionTutoria_adm', nombre: 'Gestion Tutorias' })
        this.obtenerDatosIniciales()
        this.esAdministrador();
      }



      async componentDidUpdate({ }, { accion, accionProceso }) {
        let { accion: accionNew, periodo, enNotificacion: enNotificacionNew, accionProceso: accionProcesoNew, periodo_filtro } = this.state;
        if (accion !== accionNew) {
          if (accionNew === 'inicio') this.obtenerEstadoSolicitudes(periodo);
        }
    
        if (accionProceso !== accionProcesoNew) {
          if (accionProcesoNew != 'Tod_Tut') {
            this.cambiarEstado({ cargando: true })
            if (accionProcesoNew == 'inicio') this.obtenerEstadoSolicitudes(periodo_filtro)
            else this.actualizarDataTutorias([{ 'llave': 'estado_actual__codigo', 'valor': accionProcesoNew }, { 'llave': 'periodo', 'valor': periodo_filtro }]);
          }
        }
      }


    async obtenerDatosIniciales() {
        this.setState({ cargando: true });
        let periodo = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Tut' }]);
        let tutorias = await this.obtenerTutorias();
        this.obtenerEstadoSolicitudes(periodo[0].nombre);
        this.setState({tutorias, periodo: periodo.length > 0 ? periodo[0].nombre : '', periodo_filtro: periodo.length > 0 ? periodo[0].nombre : ''});
        
      }



    
      async obtenerTutorias(filtros = []) {
        let f = await generarFiltros(filtros);
        return new Promise(resolve => {
           consulta(`api/v1.0/tutoria/listar/1?${f}`, null, null, (error, estado, resp) => {
              resolve(estado === 200 && !error ? resp : null);
          })
        })
      }



    esAdministrador() {
        let { usuario } = this.props;
        if (usuario && usuario.perfil && (usuario.perfil.codigo === "Per_Adm" )) {
          this.setState({ administrador: true })
        }
      }

      obtenerEstadoSolicitudes = (periodo) => {
        consulta(`api/v1.0/tutorias/estados/${periodo}`, null, null, (error, estado, resp) => {
          if (estado === 200) {
            let { general, extra } = resp
            if (extra.length > 0) {
              extra.map(({ solicitudes, nombre, codigo, orden }) => {
                let reporte = {
                  'estado_actual__codigo': codigo,
                  'estado_actual__nombre': nombre,
                  'num_sol': solicitudes,
                  'orden': orden
                }
                general.push(reporte);
              })
            }
            this.cambiarEstado({ estados_procesos: general.sort((a, b) => a.orden - b.orden ), cargando: false });
          }
        })
      }
    


      async actualizarDataTutorias(filtros = [{ 'llave': 'estado_actual__valorc', 'valor': 'si' }, { 'llave': 'periodo', 'valor': this.state.periodo }]) {
        this.setState({ cargando: true })
        let tutorias = await this.obtenerTutorias(filtros);
        this.setState({ tutorias, cargando: false, filtros_tutoria: filtros })
      }


      async obtenerTutorias(filtros = []) {
        let f = await generarFiltros(filtros);
        return new Promise(resolve => {
          consulta(`api/v1.0/tutoria/listar/1?${f}`, null, null, (error, estado, resp) => {
            resolve(estado === 200 && !error ? resp : null);
    
          })
        })
      }


      



      cambiarEstado = (estados) => {
        this.setState(estados)
      }



      ModalfiltroPeriodo = async () => {
        let { cargarFiltroP } = this.state
        this.cambiarEstado({ filtrarPeriodo: true })
        if (cargarFiltroP) {
          this.cambiarEstado({ cargandoPeriodo: true })
          let periodos = await this.obtenerPeriodos();
          this.cambiarEstado({ periodos, cargandoPeriodo: false, cargarFiltroP: false })
        }
      }

      obtenerPeriodos = () => {
        return new Promise(resolve => {
          consulta(`api/v1.0/tutorias/periodos`, null, null,
            (error, estado, resp) => {
              resolve(resp)
            }
          );
        })
      }

      filtrarEstadoSolicitudes = () => {
        let { periodo_filtro } = this.state
        this.cambiarEstado({ filtrarPeriodo: false, cargando: true })
        this.obtenerEstadoSolicitudes(periodo_filtro)
      }

      configFiltros = (accion_actual) => {
        let { periodo_filtro } = this.state
        switch (accion_actual) {
          case 'Tod_Tut':
            return [{ 'llave': 'periodo', 'valor': periodo_filtro }]
          case 'inicio':
            return [{ 'llave': 'periodo', 'valor': periodo_filtro }]
          default:
            return [{ 'llave': 'estado_actual__codigo', 'valor': accion_actual }, { 'llave': 'periodo', 'valor': periodo_filtro }]
        }
      }

      gestionarTutoria = async (id, data, callback) => {
        let { mostrarModalGestionTutoria, actualizarMensaje} = this.props
        let form = await crear_form_data(data);
        formulario(`api/v1.0/tutorias/${id}/gestionar`, form, "post", (error, estado, resp) => {
          let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
          if (!error) {
            if (estado === 200) {
              tipo = 'success';
              titulo = resp.titulo;
              mostrarModalGestionTutoria(false)
              this.actualizarDataTutorias(this.state.filtros_tutoria);
            } else {
              tipo = 'info';
              titulo = resp.titulo ? resp.titulo : mostrarError(resp);
            }
          }
          actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
          callback()
        })
      }



      modificarPeriodo = async (periodo) => {
        let { actualizarMensaje, mostrarModalModPeriodoTutoria } = this.props;
        if (periodo) {
          let p = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Tut' }]);
          if (p.length > 0) {
            let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
            let { error, estado, resp } = await modificarValorGenericaAlter(p[0].id, { 'nombre': periodo})
            if (!error) {
              if (estado === 200) {
                tipo = 'success';
                titulo = resp.titulo;
                mostrarModalModPeriodoTutoria(false);
                this.cambiarEstado({ periodo, periodo_filtro : periodo});
                this.setState({ cargando :true})
                this.obtenerEstadoSolicitudes(periodo)
              } else {
                tipo = 'info';
                titulo = resp.titulo ? resp.titulo : mostrarError(resp)
              }
            }
            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
          } else actualizarMensaje({ titulo: 'error al cargar el periodo actual', tipo: 'error', mostrar: true, tiempo: 6000 });
        } else actualizarMensaje({ titulo: 'Todos los campos son obligatorios', tipo: 'info', mostrar: true, tiempo: 6000 });
      }
    
      


      pintarProcesos() {
        let { tutorias,proceso } = this.state;
        let {  mostrarModalFiltroTutoria,  mostrarModalFiltroTutoriaDescarga, mostrarModalDetalleTutoria,actualizarMensaje,mostrarModalGestionTutoria,modalGestionTutoria } = this.props;
        console.log(mostrarModalFiltroTutoriaDescarga(true))
        const obtenerColor = (codigo) => {
            let color = 'white';
            const colores = {
              'Est_Tut_Sol': { 'backgroundColor': '#009688', color },
              'Est_Tut_Ace': { 'backgroundColor': '#ffc107', color },
              'Est_Tut_Neg': { 'backgroundColor': '#b71c1c', color },
              'Est_Tut_Can': { 'backgroundColor': '#b71c1c', color },
              'Est_Tut_Fin': { 'backgroundColor': '#b71c1c', color },
            }
            return (colores[codigo])
          }
          const acciones = (data) => { 
            let { estado_actual: { codigo }, permiso } = data;


            const onClickGestionar = proceso => {
              this.setState({ proceso })
              mostrarModalGestionTutoria(true)
            }


            const onClickDetalle = proceso => {
              this.setState({ proceso, procesoActual:data })
          
              mostrarModalDetalleTutoria(true)
            }
      
            let detalle = <BtnDetalle callback={() => onClickDetalle(data)} color='#01579b' texto='Abrir' />;
            let gestionar = (codigo !== 'Est_Tut_Fin' && codigo !== 'Est_Tut_Can' && codigo !== 'Est_Tut_Neg' && permiso === '1') && <BtnGestionar callback={() => onClickGestionar(data)} color='#00bfa5' texto='Gestionar' />;
            return <div>{detalle}{gestionar}</div>; 
          }
          return(
            <>
            <TutoriasGestionar 
            actualizarMensaje={actualizarMensaje} 
            tutoria={proceso} 
            mostrarModalGestionTutoria={mostrarModalGestionTutoria} 
            modalGestionTutoria={modalGestionTutoria} 
            gestionarTutoria={this.gestionarTutoria} 
            
            />
            <ListarDatos
              datos={tutorias}
              titulo='Lista Solicitantes Tutoria'
              id='tbl_tutoria_listar'
              opciones={true} 
              descargar='Solicitudes'
              filtrar={true}
              refrescar={true}
              actfiltrar={() => mostrarModalFiltroTutoria(true)}
              actRefrescar={() => this.actualizarDataTutorias(this.state.filtros)}
              acciones={(data) => acciones(data)}
              buscar={true}
              avatar={({ estado_actual }) => estado_actual.nombre.toUpperCase().charAt(0)}
              color_avatar={({ estado_actual }) => obtenerColor(estado_actual.codigo)}
              fila_principal={({ interesado: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`}
              filas={[
                { 'id': 'interesado', 'mostrar': ({ interesado: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}` },
                { 'id': 'identificacion', 'mostrar': ({ interesado : { identificacion } }) => identificacion },
                { 'id': 'fecha_registro', 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a') },
                { 'id': 'programa', 'mostrar': ({ programa }) => `${programa.nombre}` },
                { 'id': 'estado_actual', 'mostrar': ({ estado_actual }) => `${estado_actual.nombre}` },
              ]}
            />
            </>
          )
          
        }

      
        pintarProcesosAdministrar(){
          return(
            <>
            <TutoriaPermisos/>
            </>
          )
        }

        onChange = ({ target }) => {
          let { value, name } = target;
          this.setState({
            [name]: value
          })
        }



      pintarInicioProcesos = () => {
        let { estados_procesos, filtrarPeriodo, periodo_filtro, periodos, cargandoPeriodo } = this.state;
        let { mostrarModalFiltroTutoria } = this.props
        
        const obtenerColor = (codigo) => {
          let color = 'white';
          const colores = {
            'Est_Tut_Sol': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
            'Est_Tut_Ace': { 'backgroundColor': '#ffc107', color },
            'Est_Tut_Neg': { 'backgroundColor': '#b71c1c', color },
            'Est_Tut_Can': { 'backgroundColor': '#b71c1c', color },
            'Est_Tut_Fin': { 'backgroundColor': '#b71c1c', color },
            'Tod_Tut': { 'backgroundColor': '#0d47a1', color }
        }
          return (colores[codigo])
        }
    
        const handleClick = (codigo) => {
          if (codigo != 'Tod_Tut') this.setState({ accionProceso: codigo })
          else {mostrarModalFiltroTutoria(true); 
          }
        };
    
        return (
          <>
          <div style={{ padding: '2% 2% 0% 2%' }} >
          <Grid container direction={'row'} spacing={1} justify={'center'} >
            <Grid item xs={12} sm={12} md={12} lg={10} >
              <Grid container direction={'row'} alignItems="center" spacing={1} style={{ width: "100%" }}>
                <Grid item xs={6} sm={6} md={6} lg={6} >
                  <TituloAccion principal='' alterno='Seleccione el estado a gestionar: ' />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} >
                  <Tooltip title='Filtrar' aria-label="add" onClick={() => this.ModalfiltroPeriodo()} >
                    <IconButton edge={false} color="secondary" style={{ float: 'right' }}><FilterListIcon /></IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              <Paper elevation={0} square className="scroll contenido_fijo">
                { (estados_procesos.length === 0) ?
                <TareasTerminadas mensaje='Aún no hay solicitudes para este periodo.' marginTop='7%' imagen={emma_s1} widthImg="7%" /> :
                <Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
                  {estados_procesos.map(({ estado_actual__codigo: codigo, estado_actual__nombre: nombre, num_sol}, i) => {
                    return (
                      <Grid item xs={12} sm={12} md={6} lg={6} key={i}>
                        <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => handleClick(codigo)}>
                          <AccionesRapidas imagen='' titulo={`¡ ${nombre} !`} sub_titulo='' des_sub_titulo='' avatar={obtenerColor(codigo)} avatar_name= {nombre.charAt(0).toUpperCase()} list_desc={Number.isInteger(num_sol) ? [`Numero de Solicitudes : ${num_sol}`] :  ['Debe aplicar los filtros.']}/>
                        </Paper>
                      </Grid>
                    )
                  })}
                </Grid>
                }
              </Paper>
            </Grid>
          </Grid>
        </div>
            <Dialog fullWidth={true} maxWidth="xs" open={filtrarPeriodo} onClose={() => this.cambiarEstado({filtrarPeriodo : false})} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
              <AppBarModal titulo='¡ Aplicar Filtros !' mostrarModal={() => this.cambiarEstado({filtrarPeriodo : false})} titulo_accion="" accion="" />
              <DialogContent className='scroll'>
                {!cargandoPeriodo ?
                  <Grid container spacing={3} className='margin_cont' >
                    <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                      <FormControl className='form-control'>
                        <InputLabel htmlFor="periodo_filtro">Seleccione Periodo</InputLabel>
                        <Select
                          inputProps={{
                            name: 'periodo_filtro',
                            id: 'periodo_filtro',
                          }}
                          value={periodo_filtro}
                          onChange={this.onChange}
                          required={true}>
                          {periodos.map(({ periodo }, i) => <MenuItem key={i} value={periodo}>{periodo}</MenuItem>)}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  : <TareasTerminadas imagen={emma_w} mensaje='Cargando...' widthImg='5%' marginTop='5%' />
                }
              </DialogContent>
              <DialogActions>
                <BtnForm texto="CANCELAR" callback={() => this.cambiarEstado({filtrarPeriodo : false})} />
                <BtnForm texto="FILTRAR" callback={() => this.filtrarEstadoSolicitudes()} />
              </DialogActions>
            </Dialog >
          </>
        )
      }


      pintarInicioProcesosAdministrar = () => {
        let { periodo, fecha_periodo } = this.state;
        return (
          <div style={{ padding: '2% 2% 0% 2%' }} >
            <Grid container direction={'row'} spacing={1} justify={'center'} >
              <Grid item xs={12} sm={12} md={10} lg={8} >
                <TituloAccion principal='' alterno='¿ Que deseas hacer ?' />
                <Paper elevation={0} square className="scroll contenido_fijo">
                  <Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
                    <Grid item xs={12} sm={12} md={6} lg={6} >
                      <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.props.mostrarModalModPeriodoTutoria(true)}>
                        <AccionesRapidas imagen={fechaIcon} titulo={`¡ Actualizar Período !`} sub_titulo='' des_sub_titulo={`El periodo actual ${periodo}.`} />
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} >
                      <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.setState({ accionProceso: 'permisos' })}>
                        <AccionesRapidas imagen={permisosIcon} titulo='¡ Controlar Permisos !' sub_titulo='' des_sub_titulo='Verifica, asigna y retira permisos a los gestores.' />
                      </Paper>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </div>
        )
    
      }


      renderProcesos() {
        let { accionProceso } = this.state;
        return (
          <>
            {accionProceso === 'inicio' ? this.pintarInicioProcesos() : this.pintarProcesos()}
            {accionProceso !== 'inicio' && <div className={'regresar'} ><BtnRegresar callback={() => this.setState({ filtros_tutoria: [], accionProceso: 'inicio' })} /></div>}
          </>
        )
      }


       // admin
      renderProcesosAdministrar() {
        let { accionProceso,administrador } = this.state;
        if (!administrador) return <TareasTerminadas imagen={emma_s1} mensaje='No tiene los permisos asignados' widthImg='7%' marginTop='7%' />
        return (
          <>
            {accionProceso === 'inicio' ? this.pintarInicioProcesosAdministrar() : this.pintarProcesosAdministrar()}
            {accionProceso !== 'inicio' && <div className={'regresar'} ><BtnRegresar callback={() => this.setState({ filtros_tutoria: [], accionProceso: 'inicio' })} /></div>}
          </>
        )
      }



      render() {
        let { accion, administrador,cargando,accionProceso,vistaDetalle,id_estudiante,procesoActual } = this.state;
        let {mostrarModalDetalleTutoria,modalDetTutoria,actualizarMensaje,modalFiltroTutoria,mostrarModalFiltroTutoria,mostrarModalModPeriodoTutoria,modalPeriodoTutoria}=this.props
        
        return (
          
        <div>
          <MenuAdministrar
            seleccionar={(accion) => this.cambiarEstado({ accion })}
            sele={accion}
            administrador={administrador} 
            mostrarModalDetalleTutoria = {mostrarModalDetalleTutoria}
            />
            
          <div className='contenido_modulo'>   
            <TutoriasFiltros 
            actualizarMensaje={actualizarMensaje} 
            modalFiltroTutoria={modalFiltroTutoria} 
            mostrarModalFiltroTutoria={mostrarModalFiltroTutoria} 
            filtrar={(filtros) => this.actualizarDataTutorias(this.configFiltros(accionProceso).concat(filtros))}
            limpiar={() => this.actualizarDataTutorias(this.configFiltros(accionProceso))}
            accionProceso={accionProceso}
            mostrarTodos = {(accion) => this.setState({ accionProceso : accion})}
            />
            <TutoriaDetalle
            tutoria = {procesoActual}
            id_estudiante = {id_estudiante}
            mostrarModalDetalleTutoria = {mostrarModalDetalleTutoria}
            modalDetTutoria = {modalDetTutoria}
            vistaDetalle= {vistaDetalle}
            admin = {false}
            />
    
          {
            !cargando ?
              <div>
                {accion === 'inicio' && this.renderProcesos()}
                {accion === 'administrar' && this.renderProcesosAdministrar()}
              </div>
              : <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
          }
          <EnviarMensajeFecha mostrarFecha ={false} modal={modalPeriodoTutoria} mostrarModal={mostrarModalModPeriodoTutoria} enviar={(periodo, fecha) => this.modificarPeriodo(periodo)} label='Periodo' type='text' titulo={`Nuevo Periodo`} label_fecha='Fecha Vencimiento' />
          </div>   
        </div>
        )
      }



}

const mapStateToProps = (state) => {
    
    let { usuario } = state.redGlobal;
    let {modalSolTutoria,modalDetTutoria,modalFiltroTutoria,modalGestionTutoria,modalPeriodoTutoria,modalFiltroDescargaTutoria} = state.redTutoria;
    return {
      usuario,
      modalSolTutoria,
      modalDetTutoria,
      modalFiltroTutoria,
      modalGestionTutoria,
      modalPeriodoTutoria,
      modalFiltroDescargaTutoria,
    }
  }


const mapDispatchToProps = {
    mostrarModulo,
    actualizarMensaje,
    mostrarModalDetalleTutoria,
    mostrarModalFiltroTutoria,
    mostrarModalGestionTutoria,
    mostrarModalModPeriodoTutoria,
    mostrarModalFiltroTutoriaDescarga,
  }

export default connect(mapStateToProps,mapDispatchToProps)(TutoriasAdministrar);