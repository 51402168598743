const MODAL_ADD_FILTROS_CENTRO = 'MODAL_ADD_FILTROS_CENTRO'
const MODAL_MOD_PERIODO_CENTRO = 'MODAL_MOD_PERIODO_CENTRO'
const MODAL_DET_CENTRO = 'MODAL_DET_CENTRO'
const MODAL_GES_CENTRO = 'MODAL_GES_CENTRO'
const MODAL_INFORME_CENTRO = 'MODAL_INFORME_CENTRO'





const mostrarModalAddFiltrosCentro = show => {
  return {
    type: MODAL_ADD_FILTROS_CENTRO,
    show,
  }
}

const mostrarModalModPeriodoCentro = show => {
  return {
    type: MODAL_MOD_PERIODO_CENTRO,
    show,
  }
}

const mostrarModalDetalleCentro = show => {
  return {
    type: MODAL_DET_CENTRO,
    show,
  }
}

const mostrarModalGestionarCentro = show => {
  return {
    type: MODAL_GES_CENTRO,
    show,
  }
}

const mostrarModalInformeCentro = show => {
  return {
    type: MODAL_INFORME_CENTRO,
    show,
  }
}


module.exports = {
  MODAL_ADD_FILTROS_CENTRO,
  mostrarModalAddFiltrosCentro,
  MODAL_MOD_PERIODO_CENTRO,
  mostrarModalModPeriodoCentro,
  MODAL_DET_CENTRO,
  mostrarModalDetalleCentro,
  MODAL_GES_CENTRO,
  mostrarModalGestionarCentro,
  MODAL_INFORME_CENTRO,
  mostrarModalInformeCentro
}