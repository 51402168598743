import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import emma_s from "../../../global/imagenes/emma_s1.png";
import TareasTerminadas from "../TareasTerminadas";

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(10, 0, 0, 0),
    flexGrow: 1,
    textAlign: 'center'
  },
  button: {
    // marginTop: theme.spacing(5),
    minWidth: '300px',
    width: "25%",
    borderRadius: "50px",
    color: 'white',
    textAlign: 'center'
    // background: 'red'
  },
}))

export default function SesionTerminada({history}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TareasTerminadas mensaje='Su sesión ha terminado. Si desea continuar inicie sesión nuevamente, si el problema persiste contacte con el administrador.' marginTop='4%' imagen={emma_s} widthImg="7%" />
      <Button
        variant="contained"
        size="large"
        color="primary"
        aria-label="add"
        className={classes.button}
        onClick={() => history.push('/')} >
        <TouchAppIcon />
        Iniciar Sesión
      </Button>
    </div>
  )
}
