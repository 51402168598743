import { Dialog, DialogActions, DialogContent, Grid, TextField } from '@material-ui/core';
import React, { Component } from 'react';
import AppBarModal from '../../general/AppBarModal';
import { BtnForm, BtnFormPage } from '../../general/BotonesAccion';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { es } from 'date-fns/locale'
import DateFnsUtils from '@date-io/date-fns';
import { crear_form_data, formulario, getError } from '../../../global/js/funciones';
import moment from 'moment';


class SeguimientoCrear extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fecha_gestion: null,
      observacion: '',
      errores: []
    }
  }

  componentDidMount() {
    let { opc, dataSeguimiento } = this.props;
    if (opc == 'editar') {
      let { fecha_gestion, observacion } = dataSeguimiento;
      this.setState({ fecha_gestion: fecha_gestion ? moment(fecha_gestion) : null, observacion });
    }
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({ [name]: value });
  }

  onChangeDate = (nombre, date) => {
    this.setState({ [nombre]: date });
  }

  guardarSeguimiento = async () => {
    let { fecha_gestion, observacion } = this.state;
    let { dataColegio: { id } } = this.props;
    let data = new FormData();
    data.append('fecha_gestion', fecha_gestion ? moment(fecha_gestion).format('YYYY-MM-DD') : null);
    data.append('observacion', observacion);
    data.append('colegio', id);
    await this.enviarSeguimiento(data);
  }

  enviarSeguimiento = (data) => {
    let { actualizarMensaje, cerrarModalAggSeguimiento, obtenerHistorialSeguimiento } = this.props
    formulario(`api/v1.0/seguimiento/crear`, data, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado == 200) {
          tipo = 'success';
          titulo = resp.titulo;
          this.setState({ fecha_gestion: null, observacion: '' });
          cerrarModalAggSeguimiento();
          obtenerHistorialSeguimiento();
        } else {
          tipo = 'info';
          titulo = resp.titulo;
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 3000 })
    });
  }

  editarSeguimiento = async () => {
    let { fecha_gestion, observacion } = this.state;
    let { dataSeguimiento: { id } } = this.props;
    let data = new FormData();
    data.append('fecha_gestion', fecha_gestion ? moment(fecha_gestion).format('YYYY-MM-DD') : null);
    data.append('observacion', observacion);
    await this.enviarEdicion(data, id);
  }

  enviarEdicion = async (data, id) => {
    let { actualizarMensaje, cerrarModalAggSeguimiento, obtenerHistorialSeguimiento } = this.props;
    formulario(`api/v1.0/seguimiento/editar/${id}`, data, "put", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado == 200) {
          tipo = 'success';
          titulo = resp.titulo;
          this.setState({ fecha_gestion: null, observacion: '' });
          cerrarModalAggSeguimiento();
          obtenerHistorialSeguimiento();
        } else {
          tipo = 'info';
          titulo = resp.titulo;
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 3000 })
    });
  }

  pintarFormulario() {
    let { fecha_gestion, observacion, errores } = this.state;
    return (
      <Grid container spacing={3} style={{ marginTop: '0.2%', marginBottom: '2%' }} >
        <Grid item xs={12}>
          <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils} >
            <KeyboardDatePicker
              id='fecha_gestion'
              error={getError('fecha_gestion', errores).length > 0}
              helperText={getError('fecha_gestion', errores)}
              label='Fecha de Gestión'
              name='fecha_gestion'
              format='dd/MM/yyyy'
              value={fecha_gestion}
              onChange={(date) => this.onChangeDate('fecha_gestion', date)}
              KeyboardButtonProps={{ "aria-label": "change date" }}
              required
              fullWidth
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="observacion"
            label="Observaciones"
            name="observacion"
            multiline
            rows={4}
            value={observacion}
            onChange={this.onChange}
            fullWidth
          />
        </Grid>
      </Grid>
    )
  }

  render() {
    let { modalAggSeguimiento, cerrarModalAggSeguimiento, opc } = this.props;
    return (
      <>
        <Dialog open={modalAggSeguimiento} maxWidth='xs' fullWidth={true} onClose={cerrarModalAggSeguimiento}>
          <AppBarModal titulo={opc == 'editar' ? 'Editar Seguimiento' : 'Agregar Seguimiento'} mostrarModal={cerrarModalAggSeguimiento} titulo_accion='' />
          <DialogContent className='scroll' >
            {this.pintarFormulario()}
          </DialogContent>
          <DialogActions>
            <BtnForm texto="Cerrar" callback={cerrarModalAggSeguimiento} />
            {
              opc == 'editar' ?
                <BtnForm texto="Editar" callback={this.editarSeguimiento} />
                :
                <BtnFormPage texto="GUARDAR" callback={this.guardarSeguimiento} type="submit" />
            }
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default SeguimientoCrear;