const MODULO = 'MODULO'
const ACT_AUTH = 'ACT_AUTH'
const ACT_DATA_USUARIO_AUTH = 'ACT_DATA_USUARIO_AUTH'
const ACT_MENSAJE = 'ACT_MENSAJE'
const MODAL_CERRAR = 'MODAL_CERRAR'
const ACTIVIDADES = 'ACTIVIDADES'
const PERFILES = 'PERFILES'
const MENSAJES = 'MENSAJES'
const MOSTRAR_MODAL_MENSAJES = 'MOSTRAR_MODAL_MENSAJES'
const MOSTRAR_MODAL_ADJ_FALTANTES = 'MOSTRAR_MODAL_ADJ_FALTANTES'
const CARGANDO_MENU = 'CARGANDO_MENU'
const MOSTRAR_MODAL_NOTIFICACIONES = 'MOSTRAR_MODAL_NOTIFICACIONES'
const MODAL_REGISTRAR = 'MODAL_REGISTRAR' 
const MOSTRAR_MODAL_VIDEOS = 'MOSTRAR_MODAL_VIDEOS' 


const mostrarModalAdjFaltantes = (show) => {
  return {
    type: MOSTRAR_MODAL_ADJ_FALTANTES,
    show,
  }
}

const mostrarModalMensajes = (show) => {
  return {
    type: MOSTRAR_MODAL_MENSAJES,
    show,
  }
}

const actualizarDataMensajes = mensajes => {
  return {
    type: MENSAJES,
    mensajes,
  }
}

const mostrarModulo = modulo => {
  return {
    type: MODULO,
    modulo,
  }
}

const actualizarMensaje = mensaje => {
  return {
    type: ACT_MENSAJE,
    mensaje,
  }
}
const actualizarAuth = auth => {
  return {
    type: ACT_AUTH,
    auth,
  }
}
const actualizarDataUsuarioAuth = usuario => {
  return {
    type: ACT_DATA_USUARIO_AUTH,
    usuario,
  }
}

const mostrarModalCerrar = show => {
  return {
    type: MODAL_CERRAR,
    show,
  }
}

const actualizarActividades = actividades => {
  return {
    type: ACTIVIDADES,
    actividades,
  }
}

const actualizarPerfiles = perfiles => {
  return {
    type: PERFILES,
    perfiles,
  }
}


const actualizarCargandoMenu = value => {
  return {
    type: CARGANDO_MENU,
    value,
  }
}

const mostrarModalNotificaciones = show => {
  return {
    type: MOSTRAR_MODAL_NOTIFICACIONES,
    show,
  }
}

const mostrarModalRegistrar = show => {
  return {
    type: MODAL_REGISTRAR,
    show,
  }
}

const mostrarModalVideos = show => {
  return {
    type: MOSTRAR_MODAL_VIDEOS,
    show,
  }
}

module.exports = {
  MODULO,
  MENSAJES,
  ACT_AUTH,
  ACT_MENSAJE,
  MOSTRAR_MODAL_MENSAJES,
  MOSTRAR_MODAL_ADJ_FALTANTES,
  mostrarModulo,
  actualizarMensaje,
  actualizarAuth,
  actualizarDataUsuarioAuth,
  ACT_DATA_USUARIO_AUTH,
  mostrarModalCerrar,
  MODAL_CERRAR,
  ACTIVIDADES,
  actualizarActividades,
  PERFILES,
  actualizarPerfiles,
  actualizarDataMensajes,
  mostrarModalMensajes,
  mostrarModalAdjFaltantes,
  CARGANDO_MENU,
  actualizarCargandoMenu,
  MOSTRAR_MODAL_NOTIFICACIONES,
  mostrarModalNotificaciones,
  MODAL_REGISTRAR,
  mostrarModalRegistrar,
  MOSTRAR_MODAL_VIDEOS,
  mostrarModalVideos,
}
