import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ConvocatoriasAgregar from '../convocatorias/ConvocatoriasAgregar';
import ConvocatoriasListar from './ConvocatoriasListar';
import ConvocatoriasModificar from './ConvocatoriasModificar';
import { consulta, crear_form_data, formulario, generarFiltros, mostrarError, obtenerValoresFiltros } from '../../../global/js/funciones';
import { 
  mostrarModalAddConvocatorias, 
  mostrarModalModConvocatorias, 
  mostrarModalDelConvocatorias, 
  mostrarModalDetConvocatorias,
  mostrarModalListaUniversidades,
  mostrarModalListaRequisitos,
  mostrarModalListaArchivos,
  mostrarModalNegarAdjunto,
  actualizarDataConvocatorias,
  mostrarModalAdjuntarDocumentos, 
} from '../../../redux/actions/actInternacionalizacion';
import { connect } from 'react-redux';
import ConfirmarAccion from '../../general/ConfirmarAccion';
import { ConvocatoriaDetalle } from './ConvocatoriaDetalle';
import Detalle from '../../general/Detalle';
import InfoIcon from '@material-ui/icons/Info';
import { REQUISITOS, UNIVERSIDADES } from '../administrar/helper';
import { BtnDetalle } from '../../general/BotonesAccion';

class Convocatorias extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cargando: true,
      archivos_guardados: [],
      requisitos: [],
      universidades: [],
      input_convocatoria: ''
    }
  }

  async componentDidMount() {
    this.obtenerListadoConvocatorias();
  }

  async componentDidUpdate({ modalDetConvocatorias }) {
    let { modalDetConvocatorias: modalDetConvocatoriasNew } = this.props;

    if (modalDetConvocatorias !== modalDetConvocatoriasNew) {
      if (modalDetConvocatoriasNew) {
        this.setState({ cargando: true });
        let universidades = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': UNIVERSIDADES }, { 'llave': 'estado', 'valor': 1}])
        let requisitos = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': REQUISITOS }]);
        this.setState({ universidades, requisitos, cargando: false });
      }
    }
  }

  async obtenerArchivos(id) {
    let f = await generarFiltros([{ 'llave': 'convocatoria', 'valor': id }, { 'llave': 'estado', 'valor': 1 }]);
    consulta(`api/v1.0/internacionalizacion/convocatoria/archivos?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ archivos_guardados: resp });
    });
  }

  async obtenerConvocatorias() {
    return new Promise(resolve => {
      consulta(`api/v1.0/internacionalizacion/convocatorias/buscar`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      }, false);
    });
  }

  obtenerListadoConvocatorias = async () => {
    this.setState({ cargando: true });
    let convocatorias = [];
    convocatorias = await this.obtenerConvocatorias();
    this.props.actualizarDataConvocatorias(convocatorias);
    this.setState({ cargando: false })
  }

  guardarConvocatoria = async (data, universidades, requisitos, archivos, callback) => { // universidades se recibe objecto, requisitos se recibe id
    let { mostrarModalAddConvocatorias, actualizarMensaje } = this.props;
    let form = await crear_form_data(data);
    universidades.map(({ id }) => form.append(`universidades_destino`, id));
    requisitos.map(id => form.append(`requisitos`, id));
    form.append(`total`, archivos.length);
    archivos.map((file, index) => form.append(`archivo${index}`, file));
    formulario(`api/v1.0/internacionalizacion/convocatorias/crear`, form, 'post', (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error'
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          mostrarModalAddConvocatorias(false);
          this.obtenerListadoConvocatorias();
          callback(true);
        } else {
          tipo = 'info'
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          callback(false);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
    });
  }

  modificarConvocatoria = async (id, data, universidades, requisitos, callback) => {
    let { mostrarModalModConvocatorias, actualizarMensaje } = this.props;
    let form = await crear_form_data(data);
    universidades.map(({ id }) => form.append(`universidades_destino`, id));
    requisitos.map((id) => form.append(`requisitos`, id));
    formulario(`api/v1.0/internacionalizacion/convocatorias/${id}/modificar`, form, "patch", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          mostrarModalModConvocatorias(false);
          this.obtenerListadoConvocatorias();
          callback(true);
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          callback(false);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    });
  }

  eliminarConvocatoria = (id) => {
    let { actualizarMensaje, mostrarModalDelConvocatorias } = this.props;
    consulta(`api/v1.0/internacionalizacion/convocatorias/${id}/eliminar`, {}, "put", (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            this.obtenerListadoConvocatorias();
            mostrarModalDelConvocatorias(false);
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({ [name]: value });
  }

  render () {
    let { 
      actualizarDataConvocatoria,
      actualizarMensaje,
      convocatorias,
      modalAddConvocatorias,
      modalDetConvocatorias,
      modalModConvocatorias,
      modalDelConvocatorias,
      modalListaUniversidades,
      modalListaRequisitos,
      modalListaArchivos,
      modalNegarAdjunto,
      modalAdjuntarDocumentos,
      mostrarModalAddConvocatorias, 
      mostrarModalDelConvocatorias,
      mostrarModalDetConvocatorias,
      mostrarModalModConvocatorias,
      mostrarModalListaUniversidades,
      mostrarModalListaRequisitos,
      mostrarModalListaArchivos,
      mostrarModalNegarAdjunto,
      mostrarModalAdjuntarDocumentos,
      convocatoria
    } = this.props;
    let { archivos_guardados } = this.state;
    const configDetailUniversidades = univ_destinos => {
      let { universidades } = this.state;
      let universidades_opciones = [];
      universidades.map(univ => { if (univ_destinos.includes(univ.id)) universidades_opciones.push({"label": univ.nombre, "value": univ.valora}); });
      return universidades_opciones.length > 0 ? universidades_opciones : [{ "label": <InfoIcon />, "value": 'Esta convocatoria no cuenta con universidades todavía.' }];
    }
    const configDetailRequisitos = (req_convocatoria) => {
      let { requisitos } = this.state;
      let lista_requisitos = [];
      let i = 0;
      requisitos.map((req) => {
        if (req_convocatoria.includes(req.id)) {
          i++;
          lista_requisitos.push({ "label": i, "value": req.nombre });
        }
      });
      return lista_requisitos.length > 0 ? lista_requisitos : [{ "label": <InfoIcon />, "value": 'Esta convocatoria no cuenta con requisitos todavía.' }];
    }
      const configDetailArchivos = () => {
        let { archivos_guardados } = this.state;
        let lista_archivos = [];
        if(archivos_guardados) {
          archivos_guardados.map((archivo) => {
            let detalle = <BtnDetalle callback = { () => window.open(`${archivo.adjunto}`, '_blank') } />
            lista_archivos.push({ "label": archivo.nombre, "value": detalle })
          });
        }
        return lista_archivos.length > 0 ? lista_archivos : [{ "label": <InfoIcon />, "value": 'Esta convocatoria no cuenta con archivos todavía.' }];
      }
    return (
      <div>
        <ConvocatoriasListar
          mostrarModalModConvocatorias = { mostrarModalModConvocatorias }
          actualizarDataConvocatoria = { actualizarDataConvocatoria }
          convocatorias = { convocatorias }
          modalDelConvocatorias = { modalDelConvocatorias }
          mostrarModalDelConvocatorias = { mostrarModalDelConvocatorias }
          mostrarModalDetConvocatorias = { mostrarModalDetConvocatorias }
          mostrarModalAddConvocatorias = { mostrarModalAddConvocatorias }
          obtenerArchivos = { (id) => this.obtenerArchivos(id) }
        />
        <ConvocatoriasAgregar
          modalAddConvocatorias = { modalAddConvocatorias }
          mostrarModalAddConvocatorias = { mostrarModalAddConvocatorias }
          guardarConvocatoria = { this.guardarConvocatoria }
          actualizarMensaje = { actualizarMensaje }
        />
        <ConvocatoriasModificar 
          modalModConvocatorias = { modalModConvocatorias }
          mostrarModalModConvocatorias = { mostrarModalModConvocatorias }
          modificarConvocatoria = { this.modificarConvocatoria }
          mostrarModalNegarArchivo = { mostrarModalNegarAdjunto }
          modalNegarArchivo = { modalNegarAdjunto }
          mostrarModalAdjuntarArchivos = { mostrarModalAdjuntarDocumentos }
          modalAdjuntarArchivos = { modalAdjuntarDocumentos }
          valor = { convocatoria }
          actualizarMensaje = { actualizarMensaje }
        />
        <ConvocatoriaDetalle
          titulo = { '¡ Detalle de Convocatoria !' }
          convocatoria = { convocatoria }
          archivos = { archivos_guardados }
          modalDetConvocatorias = { modalDetConvocatorias }
          mostrarModalDetConvocatorias = { mostrarModalDetConvocatorias }
          mostrarModalListaUniversidades = { mostrarModalListaUniversidades }
          mostrarModalListaRequisitos = { mostrarModalListaRequisitos }
          mostrarModalListaArchivos = { mostrarModalListaArchivos }
        />
        <Detalle
          titulo = {'¡ Lista de universidades !'}
          open = { modalListaUniversidades }
          config = { convocatoria.id !== 0 ? configDetailUniversidades(convocatoria.universidades_destino) : [] }
          cerrar = { () => mostrarModalListaUniversidades(false) }
        />
        <Detalle
          titulo = {'¡ Lista de requisitos !'}
          open = { modalListaRequisitos }
          config = { convocatoria.id !== 0 ? configDetailRequisitos(convocatoria.requisitos) : [] }
          cerrar = { () => mostrarModalListaRequisitos(false) }
        />
        <Detalle
          titulo = {'¡ Lista de archivos !'}
          open = { modalListaArchivos }
          config = { convocatoria.id !== 0 ? configDetailArchivos() : [] }
          cerrar = { () => mostrarModalListaArchivos(false) }
        />
        <ConfirmarAccion 
          mensaje1 = { `La convocatoria "${convocatoria.nombre_convocatoria}" será eliminada ` }
          ejecutarAccion = { this.eliminarConvocatoria } 
          mostrarModalConfAccion = { mostrarModalDelConvocatorias } 
          modalConAccion = { modalDelConvocatorias } 
          dato = { convocatoria } 
          titulo = { '¿ Eliminar Convocatoria ?' } 
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let { 
    modalAddConvocatorias, 
    modalModConvocatorias, 
    modalDelConvocatorias, 
    modalDetConvocatorias, 
    modalListaUniversidades,
    modalListaRequisitos,
    modalListaArchivos,
    modalNegarAdjunto,
    modalAdjuntarDocumentos,
    convocatorias 
  } = state.redInternacionalizacion;
  return { 
    modalAddConvocatorias, 
    modalModConvocatorias, 
    modalDelConvocatorias, 
    modalDetConvocatorias,
    modalListaUniversidades,
    modalListaRequisitos,
    modalListaArchivos,
    modalNegarAdjunto,
    modalAdjuntarDocumentos,
    convocatorias 
  }
}

const mapDispatchToProps = {
  actualizarDataConvocatorias,
  mostrarModalAddConvocatorias,
  mostrarModalModConvocatorias,
  mostrarModalDelConvocatorias,
  mostrarModalDetConvocatorias,
  mostrarModalListaUniversidades,
  mostrarModalListaRequisitos,
  mostrarModalListaArchivos,
  mostrarModalNegarAdjunto,
  mostrarModalAdjuntarDocumentos,
}

Convocatorias.propTypes = {
  // variables
  convocatorias: PropTypes.array.isRequired,
  modalAddConvocatorias: PropTypes.bool.isRequired,
  modalModConvocatorias: PropTypes.bool.isRequired,
  modalDelConvocatorias: PropTypes.bool.isRequired,
  modalDetConvocatorias: PropTypes.bool.isRequired,
  modalListaUniversidades: PropTypes.bool.isRequired,
  modalListaRequisitos: PropTypes.bool.isRequired,
  modalListaArchivos: PropTypes.bool.isRequired,
  modalNegarAdjunto: PropTypes.bool.isRequired,
  modalAdjuntarDocumentos: PropTypes.bool.isRequired,
  // funciones
  actualizarDataConvocatorias: PropTypes.func.isRequired,
  mostrarModalAddConvocatorias: PropTypes.func.isRequired,
  mostrarModalModConvocatorias: PropTypes.func.isRequired,
  mostrarModalDelConvocatorias: PropTypes.func.isRequired,
  mostrarModalDetConvocatorias: PropTypes.func.isRequired,
  mostrarModalListaUniversidades: PropTypes.func.isRequired,
  mostrarModalListaRequisitos: PropTypes.func.isRequired,
  mostrarModalListaArchivos: PropTypes.func.isRequired,
  mostrarModalNegarAdjunto: PropTypes.func.isRequired,
  mostrarModalAdjuntarDocumentos: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(Convocatorias);
