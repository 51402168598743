//Importaciones necesaria para poder utilizar react
import React from 'react'

//Importacion para utilizar el AppBarModel modificado de Emma
import AppBarModal from '../../general/AppBarModal'

import SemilleroStepperEstu from './SemilleroStepperEstu'

//Importaciones necesarias para que el modal del formulario de creacion de semilleros se pueda abrir, este es para que se habra el modal.
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { actualizarMensaje } from '../../../redux/actions/actGlobal';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
//Cards
import { Grid, Link, Paper, Typography, Card, CardHeader, CardContent, Avatar } from '@material-ui/core'
import assigment from '../../../global/imagenes/assigment.png';
import * as Funciones from '../../../global/js/funciones';

import TareasTerminadas from '../../general/TareasTerminadas';
import emma_w from '../../../global/imagenes/emma_w.png';

import LinearProgress from '@material-ui/core/LinearProgress';

//Styles
const useStyles = makeStyles({
    color:{
        transition: 'all 0.3s',
        '&:hover': {
            transform: "scale(1.05)",
        },
    },
    center:{
        textAlign: 'center'
    }
});
//Componente funcional Semilleros Solicitudes. Padre de los componentes de form de la creacion de semilleros.

const SemilleroSolicitudesEst = () => {
    
    const classes = useStyles()
    //Estado open para abrir y cerrar el modal.
    const [open, setOpen] = React.useState(false);
    //Estado para cambiar de step.
    const [activeStep, setActiveStep] = React.useState(0);
    //Estados necesarios para guardar la informacion del estudiante
    const [celular, setCelular] = React.useState("");
    const [telefono, setTelefono] = React.useState("");
    const [correo, setCorreo] = React.useState("");
    const [programa, setPrograma] = React.useState([]);
    const [programaSelect, setProgramaSelect] = React.useState("");
    const [cvlac, setCvlac] = React.useState("");
    const [scholar, setScholar] = React.useState("");
    const [researchGate, setResearchGate] = React.useState("");
    const [academiaEdu, setAcademiaEdu] = React.useState("");
    const [semilleroAdjunto, setSemilleroAdjunto] = React.useState(false);
    const [metodologiaInves, setMetodoliaInves] = React.useState(false)
    const [experiencia, setExperiencia] = React.useState("")
    //Semillero a participar
    const [checked, setChecked ] = React.useState([]);
    //Variable que me ayuda con el checkbox
    const [helperCheck, setHelperCheck] = React.useState([]);
    //Lista de Semilleros
    const [listSemilleros, setListSemilleros] = React.useState([]);
    //Errores
    const [errores, setErrores] = React.useState([]);
    //Cargando
    const [cargando, setCargando] = React.useState(false)
    const [cargandoFuera, setCargandoFuera] = React.useState(false)

    const [adj_seminario, setAdj_seminario] = React.useState('');
    const [adj_metodologia, setAdj_metodologia] = React.useState('')
    const [adj_seminario_final, setAdj_seminario_final] = React.useState('');
    const [adj_metodologia_final, setAdj_metodologia_final] = React.useState('')
    //Data final
    let data = {}
    //Variable que obtiene todos los titulos del step
    const steps = getSteps();
    //Dispatch para utilizar los mensajes emergentes
    const dispatch = useDispatch();

    //Funciones para que abra el modal.
    const handleClickOpen = () => {
        setOpen(true);
    };
    //Funcion para el cierre del modal.
    const handleClose = () => {
        setOpen(false);
        setCelular("");
        setTelefono("");
        setCorreo("");
        setPrograma([]);
        setProgramaSelect("")
        setChecked([])
        setActiveStep(0)
        setExperiencia("")
        setAdj_seminario('')
        setAdj_metodologia('')
        setAdj_seminario_final('')
        setAdj_metodologia_final('')
        setCvlac('')
        setSemilleroAdjunto(false)
        setMetodoliaInves(false)
        data = {};
    };
    
    const getError = (llave, errores) => {
        let existe = errores.find(e => e.llave === llave);
        return existe ? existe.mensaje : '';
    }

    const validarCorreo = (valor) => {
        let valido = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
          valor
        );

        return valido;
    };

    const validateInputs = (campos = []) => {
        return new Promise((resolve) => {
          let errores = [];
          let date_now = new Date()

          campos.map(({ value, name, err, value2 }) => {
            if (err.empty && value.replace(/\s+/g, '') == "") errores.push({ llave: name, 'mensaje': `Este campo es obligatorio` });
            if (err.empty == false && value == 0) errores.push({ llave: name, 'mensaje': `Este campo es obligatorio` })
            if (err.email && !validarCorreo(value)) errores.push({ llave: name, 'mensaje': `Correo invalido` });
            /*if (err.numeric && isNaN(value)) errores.push({ llave: name, 'mensaje': `Este campo debe ser un numero` });
            if (err.before_today && value >= date_now) errores.push({ llave: name, 'mensaje': `Fecha futura invalida` });
            if (err.after_today && value <= date_now) errores.push({ llave: name, 'mensaje': `Fecha pasada invalida` });
            if (err.finish_date && value <= value2) errores.push({ llave: name, 'mensaje': `Fecha invalida` });
            if (err.range && (value < 1 || value > value2)) errores.push({ llave: name, 'mensaje': `El valor está fuera del rango permitido` });
            if (err.not_null && value == null) errores.push({ llave:name, 'mensaje': `Este campo es obligatorio` });
            if (err.not_decimal && value % 1 != 0) errores.push({ llave: name, 'mensaje': `El valor no puede ser decimal` });*/
          });
          
          resolve(errores);
        });
    }
    //Funcion para hacer un siguiente en el Step
    const handleNext = async () => {
        if(activeStep == 0){
            const validateString = (variable) => {
                if(typeof variable == 'number'){
                    return variable.toString()
                }else{
                    return variable
                }
            }  

            setErrores(await validateInputs([
                { value: validateString(celular), name: 'celular', err: { empty: true } },
                { value: validateString(telefono), name: 'telefono', err: { empty: true } },
                { value: correo, name: 'correo', err: { email: true } },
                { value: programaSelect, name: 'programa', err: { empty: false } },
                { value: experiencia, name: 'experiencia', err: { empty: false } },
                { value: cvlac, name: 'cvlac', err: { empty: false } },
            ]))
            let valido = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
                correo
            );
            if(validateString(celular).replace(/\s+/g, '') == ""){

            }else if(validateString(telefono).replace(/\s+/g, '') == ""){

            }else if(!valido){

            }else if(programaSelect == ""){

            }else if(semilleroAdjunto == false && metodologiaInves == false){
                let titulo = "Debe cumplir por lo menos con Semillero Ajunto o Metodologia de la Investigación"
                let tipo = "info"
                dispatch(
                    actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                );
            }else if((semilleroAdjunto && !adj_seminario) || (metodologiaInves && !adj_metodologia)){
                dispatch(
                    actualizarMensaje({ titulo: 'Debe adjuntar el soporte', tipo: "info", mostrar: true, tiempo: 6000 })
                );
            }else{
                let adj_sem = document.getElementById('adj_seminario').files[0];
                let adj_met = document.getElementById('adj_metodologia').files[0];
                if (adj_sem) setAdj_seminario_final(adj_sem);
                if (adj_met) setAdj_metodologia_final(adj_met);
                
                data = {
                    celular:celular,
                    telefono:telefono,
                    correo:correo,
                    programa:programaSelect,
                    experiencia:experiencia
                }

                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        }
    };

    const SendDataToApi = async () =>{
        if(checked.length == 0){
            let titulo = "Elija un Semillero Activo."
            let tipo = "info"
            dispatch(
                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
              );
        }else{
            setCargandoFuera(true)
            data = await Funciones.crear_form_data({
                celular:celular,
                telefono:telefono,
                correo:correo,
                programa:programaSelect,
                idUsuario: '',
                idSemillero:checked[0],
                link_cvlac:cvlac,
                link_google_scholar:scholar,
                // link_research_gate:researchGate,
                // link_academia_edu:academiaEdu,
                experiencia:experiencia,
                adj_seminario: adj_seminario_final,
                adj_metodologia: adj_metodologia_final,
            })

            let url = "api/v1.0/investigacion/crearParticipante"
            const callback = (error, estado, resp) => {
                let titulo = "Ha ocurrido un error, contacte con el administrador."
                let tipo = "info"

                if(!error){
                    if(estado === 201){
                        tipo = "success"
                        titulo = resp.titulo
                        handleClose();
                    }else{
                        tipo = "info"
                        titulo = resp.titulo
                    }
                }

                dispatch(
                    actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                  );
                setCargandoFuera(false)
            }
            const msg = await Funciones.formulario(url, data, 'POST', callback)
        }
    }

    //Funcion para hacer un back en el Step
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    //Funcion para hacer un reset en el Step
    const handleReset = () => {
        setActiveStep(0);
    };
    //Retorna el modal de los formularios de los semilleros.
    return(
        <div>
            <Grid container direction={'row'} justify={'center'}>
                <Grid  item xs={10} sm={8} md={6} lg={4} style={{ padding: '2% 2% 0% 2%' }} onClick={() => { handleClickOpen() }}>
                <Paper  elevation={8} square>
                    <Card className={classes.color} style={{ maxWidth: "100%", height: '300px', cursor: 'pointer' }} elevation={1}>
                    <CardHeader
                        avatar={<Avatar aria-label="recipe" style={{ backgroundColor: '#00F087' }}>N</Avatar>}
                        title={`Nueva Solicitud`}
                    />
                    <CardContent >
                        <Grid container direction={'row'} justify={'center'}>
                        <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center', height: "150px" }}>
                            <img src={assigment} style={{ width: '150px', maxWidth: '200px', minWidth: '100px', height: "150px" }} alt='Imagen Tipo' />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} style={{ padding: "15px 10px 0px 10px", }}>
                            <Typography className={classes.center} variant="body2" color="textSecondary" component="p" align='justify'>
                                Para crear una solicitud a un Semillero de Investigación haz click aquí
                            </Typography>
                        </Grid>
                        </Grid>
                    </CardContent>
                    </Card>
                </Paper>
                </Grid>
            </Grid>
            

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth='sm' fullWidth={true}>

                <AppBarModal titulo='Formulario Semillero' mostrarModal={handleClose} titulo_accion="" accion="" />
                {/* Contenido del modal del formulario de */}
                <DialogContent>
                    <div>
                        {cargandoFuera == true ? <><TareasTerminadas imagen={emma_w} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' /> <LinearProgress /></> :
                        <SemilleroStepperEstu 
                            hola={activeStep}
                            steps={steps}
                            handleReset={handleReset}
                            //TextFields
                            celular={celular}
                            telefono={telefono}
                            correo={correo}
                            programa={programa}

                            setCelular={setCelular}
                            setTelefono={setTelefono}
                            setCorreo={setCorreo}
                            setPrograma={setPrograma}
                            
                            cvlac={cvlac}
                            setCvlac={setCvlac}
                            scholar={scholar}
                            setScholar={setScholar}
                            researchGate={researchGate}
                            setResearchGate={setResearchGate}
                            academiaEdu={academiaEdu}
                            setAcademiaEdu={setAcademiaEdu}

                            semilleroAdjunto={semilleroAdjunto}
                            setSemilleroAdjunto={setSemilleroAdjunto}

                            metodologiaInves={metodologiaInves}
                            setMetodoliaInves={setMetodoliaInves}

                            experiencia={experiencia}
                            setExperiencia={setExperiencia}
                            //Lista de Semilleros
                            listSemilleros={listSemilleros}
                            setListSemilleros={setListSemilleros}

                            checked={checked}
                            setChecked={setChecked}

                            helperCheck={helperCheck}
                            setHelperCheck={setHelperCheck}
                            //Errores
                            getError={getError}
                            errores={errores}
                            setErrores={setErrores}

                            validateInputs={validateInputs}
                            //Cargando
                            cargando={cargando}
                            setCargando={setCargando}

                            programaSelect={programaSelect}
                            setProgramaSelect={setProgramaSelect}

                            adjMetodologia={adj_metodologia}
                            setAdjMetodologia={setAdj_metodologia}

                            adjSeminario={adj_seminario}
                            setAdjSeminario={setAdj_seminario}

                            cargandoModal={setCargandoFuera}
                        />}
                    </div>
                    
                </DialogContent>

                <DialogActions>
                    {/* className={classes.backButton}*/}
                    <div>
                        <Button disabled={activeStep === 0 || cargando == true} onClick={handleBack} >
                            Atras
                        </Button>
                        {activeStep === steps.length - 1 ? <Button disabled={cargandoFuera == true} variant="contained" color="primary" style={{color: "#fff"}} onClick={() => {SendDataToApi()}}>Enviar</Button> : <Button disabled={cargando == true} variant="contained" color="primary" style={{color: '#fff'}} onClick={handleNext}>Siguiente</Button>}
                    </div>

                </DialogActions>

            </Dialog>

        </div>
    )
}

//Nombre de las opciones de arriba del Stepper
function getSteps() {
    return ['Mis Datos', 'Semillero de Investigación'];
}

//Exporto el componente funcional. Para que pueda ser llamado
export default SemilleroSolicitudesEst 