import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment'

export function RetosDetalles({ retoscuc }) {

  let {
    estado_actual,
    programa,
    asignatura,
    usuario_registro: { primer_nombre, primer_apellido, segundo_apellido, },
  } = retoscuc;

  return (
    <Table>
      <TableBody>
        <TableRow key={1}>
          <TableCell component="th" scope="row">Solicitante</TableCell>
          <TableCell align="left">{`${primer_nombre} ${primer_apellido} ${segundo_apellido}`}</TableCell>
        </TableRow>
        <TableRow key={2}>
          <TableCell component="th" scope="row">Programa</TableCell>
          <TableCell align="left">{programa.nombre}</TableCell>
        </TableRow>
        <TableRow key={3}>
          <TableCell component="th" scope="row">Estado Actual</TableCell>
          <TableCell align="left">{estado_actual.nombre}</TableCell>
        </TableRow>
        <TableRow key={4}>
          <TableCell component="th" scope="row">Asignatura</TableCell>
          <TableCell align="left">{asignatura}</TableCell>
        </TableRow>
      </TableBody>

    </Table>
  )
}

export function DetalleSolicitud({ retoscuc }) {
  const [expanded, setExpanded] = React.useState(false);

  let {
    usuario_registro: { primer_nombre, primer_apellido, segundo_apellido, identificacion, correo, celular, },
    programa,
    asignatura,
    estado_actual,
    observaciones,
    fecha_registro,
    coordinador,
    profesor_asignado,
    integrantes,
    pregunta_retos,
    url_solucion_reto,
    empresa_reto

  } = retoscuc;
  let fecha = moment(fecha_registro).format('YYYY-MM-DD, h:mm a');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (

    <Table>
      <TableBody>
        <TableRow key={1}>
          <TableCell component="th" scope="row">Lider de Grupo</TableCell>
          <TableCell align="left">{primer_nombre} {primer_apellido} {segundo_apellido}</TableCell>
        </TableRow>

        <TableRow key={2}>
          <TableCell component="th" scope="row">Identificacion</TableCell>
          <TableCell align="left">{identificacion}</TableCell>
        </TableRow>
        <TableRow key={3}>
          <TableCell component="th" scope="row">Correo</TableCell>
          <TableCell align="left">{correo}</TableCell>
        </TableRow>
        <TableRow key={4}>
          <TableCell component="th" scope="row">Celular</TableCell>
          <TableCell align="left">{celular}</TableCell>
        </TableRow>
        {
          integrantes.map((i, index) => {
            if (i.identificacion != identificacion) {
              return (
                <TableRow key={index + 14}>
                  <TableCell component="th" scope="row"> Integrante {index + 1}</TableCell>
                  <TableCell align="left">{i.primer_nombre} {i.primer_apellido} {i.segundo_apellido}<br />
                    {i.correo}<br />
                    {i.identificacion}
                  </TableCell>
                </TableRow>
              )
            }
          })
        }
        {
          pregunta_retos ?
            <TableRow key={5}>
              <TableCell component="th" scope="row">Pregunta Reto</TableCell>
              <TableCell align="left">{pregunta_retos}</TableCell>
            </TableRow> :
            <TableRow key={5}></TableRow>
        }
        <TableRow key={6}>
          <TableCell component="th" scope="row">Programa</TableCell>
          <TableCell align="left">{programa.nombre}</TableCell>
        </TableRow>
        <TableRow key={7}>
          <TableCell component="th" scope="row">Asignatura</TableCell>
          <TableCell align="left">{asignatura}</TableCell>
        </TableRow>
        <TableRow key={8}>
          <TableCell component="th" scope="row">Estado Actual</TableCell>
          <TableCell align="left">{estado_actual.nombre}</TableCell>
        </TableRow>
        {
          coordinador ?
            <TableRow key={9}>
              <TableCell component="th" scope="row">Coordinador Asignado</TableCell>
              <TableCell align="left">{coordinador.primer_nombre} {coordinador.primer_apellido} {coordinador.segundo_apellido}</TableCell>
            </TableRow> :
            <TableRow key={9}></TableRow>
        }
        {
          coordinador ?
            <TableRow key={10}>
              <TableCell component="th" scope="row">Correo Cordinador</TableCell>
              <TableCell align="left">{coordinador.correo}</TableCell>
            </TableRow> :
            <TableRow key={10}></TableRow>
        }
        {
          coordinador ?
            <TableRow key={11}>
              <TableCell component="th" scope="row">Telefono Cordinador</TableCell>
              <TableCell align="left">{coordinador.celular}</TableCell>
            </TableRow> :
            <TableRow key={11}></TableRow>
        }
        {
          observaciones ?
            <TableRow key={12}>
              <TableCell component="th" scope="row">Evaluación</TableCell>
              <TableCell align="left">{observaciones}</TableCell>
            </TableRow> :
            <TableRow key={12}></TableRow>
        }

        {
          profesor_asignado ?
            <TableRow key={13}>
              <TableCell component="th" scope="row">Profesor Asignado</TableCell>
              <TableCell align="left">{profesor_asignado.primer_nombre} {profesor_asignado.primer_apellido} {profesor_asignado.segundo_apellido}</TableCell>
            </TableRow> :
            <TableRow key={13}></TableRow>
        }

        {
          empresa_reto ? 
          <TableRow key={14}>
              <TableCell component="th" scope="row">Empresa</TableCell>
              <TableCell align="left">{empresa_reto}</TableCell>
            </TableRow> :
            <TableRow key={14}></TableRow>
        }

        {
          url_solucion_reto ?
          <TableRow key={15}>
              <TableCell component="th" scope="row">Evidencia final</TableCell>
              <TableCell align="left">{url_solucion_reto}</TableCell>
            </TableRow> :
            <TableRow key={15}></TableRow>
        }


        {/* <TableRow key={14}>
          <TableCell component="th" scope="row">Retroalimentación</TableCell>
          <TableCell align="left">
            <Accordion expanded={expanded === 'granp'} onChange={handleChange('granp')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header"> </AccordionSummary>
              <AccordionDetails>
              </AccordionDetails>
            </Accordion>
          </TableCell>
        </TableRow> */}

        <TableRow key={16}>
          <TableCell component="th" scope="row">Fecha Registro</TableCell>
          <TableCell align="left">{fecha}</TableCell>
        </TableRow>

      </TableBody>
    </Table>
  )
}