import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import React, { Component } from "react";

export default function ConvenioCrearDetalle({
  solicitud,
  tipo_paso_infoconveni,
  pasocumplimientorequisito,
  pasotipoactividad,
}) {
  let {
    usuario_registro,
    programa_facultad,
    institucion,
    rector,
    representacion_legal,
    pais_vinculante,
    ciudad_vinculante,
    direccion_vinculante,
    direccion_Web,
    encargado_interna,
    correo_interna,
    cargo_interna,
    telefono_interna,
    objeto_del_convenio,
    vigencia,
    otro_tipo_convenio,
    otro_subtipo_convenio,
    otro_idioma_minuta,
    otra_fuente_financiacion,
    programa_facultad_3,
    solicitud_excepcion,
    cargo_enlace_Uni,
    email_enlace_Uni,
    nombre_enlace_Uni,
    telefono_enlace_Uni,
    unidad_enlace_Uni,
    nombre_enlace_vin,
    telefono_enlace_vin,
    unidad_enlace_vin,
    cargo_enlace_vin,
    email_enlace_vin,
    codigo_convenio,
    fecha_inicio,
    fecha_finalizacion,
    renovacion,
    estado_convenio,
    act_objeto,
    solicitud_renovacion,
    dpto_academico_enlaceUni
  } = solicitud;
  let {
    Subtipo_convenio,
    TipoConvenio,
    fuenteFinanciacion_convenio,
    Idioma_minuta,
  } = tipo_paso_infoconveni;
  let arraysubtipo = [];
  let subtiponombre = [];
  let tipoconvenionombre = [];
  let fuentefinanciacionconvenionombre = [];
  let helpidioma_minuta = [];
  let { Ranking, Acreditacion_prog, Acreditacion_inst } =
    pasocumplimientorequisito;
  let helpacreditainst = [];
  let helpacreditapro = [];
  let helpranking = [];
  if (Subtipo_convenio) {
    arraysubtipo = Subtipo_convenio.filter((item, index) => {
      return Subtipo_convenio.indexOf(item) === index;
    });
    arraysubtipo.map(({ sub_tipo_convenio }, i) => {
      subtiponombre.push(sub_tipo_convenio.nombre);
    });
  }
  if (TipoConvenio) {
    TipoConvenio.map(({ tipo_convenio_convenio }, i) => {
      tipoconvenionombre.push(tipo_convenio_convenio.nombre);
    });
  }
  if (fuenteFinanciacion_convenio) {
    fuenteFinanciacion_convenio.map(({ fuentefinanciacion_convenio }, i) => {
      fuentefinanciacionconvenionombre.push(fuentefinanciacion_convenio.nombre);
    });
  }
  if (Idioma_minuta) {
    Idioma_minuta.map(({ idioma_minuta_convenio }, i) => {
      helpidioma_minuta.push(idioma_minuta_convenio.nombre);
    });
  }
  if (Acreditacion_prog) {
    Acreditacion_prog.map(({ acreditacion_prog }, i) => {
      helpacreditapro.push(acreditacion_prog.nombre);
    });
  }
  if (Acreditacion_inst) {
    Acreditacion_inst.map(({ acreditacion_ins }, i) => {
      helpacreditainst.push(acreditacion_ins.nombre);
    });
  }
  if (Ranking) {
    Ranking.map(({ posicion, ranking, posicion_dos }, i) => {
      if (posicion_dos !== null) {
        helpranking.push({ posicion: posicion, ranking: ranking.nombre, posicion_dos: posicion_dos });
      } else {
        helpranking.push({ posicion: posicion, ranking: ranking.nombre });
      }
    });
  }
  // console.log(Ranking.length);
  return (
    <Table>
      <TableBody>
        {
          usuario_registro && (
            <TableRow key={1}>
              <TableCell component="th" scope="row">
                Nombre solicitante
              </TableCell>
              <TableCell align="left">
                {usuario_registro.primer_nombre +
                  " " +
                  usuario_registro.primer_apellido +
                  " " +
                  usuario_registro.segundo_apellido}
              </TableCell>
            </TableRow>
          )
        }
        {
          programa_facultad && (
            <TableRow key={2}>
              <TableCell component="th" scope="row">
                Departamento academico o unidad administrativa
              </TableCell>
              <TableCell align="left">{programa_facultad.nombre}</TableCell>
            </TableRow>
          )
        }
        {usuario_registro && (
          <TableRow key={3}>
            <TableCell component="th" scope="row">
              Telefono del solicitante
            </TableCell>
            <TableCell align="left">{usuario_registro.telefono}</TableCell>
          </TableRow>
        )}
        {
          usuario_registro && (
            <TableRow key={4}>
              <TableCell component="th" scope="row">
                Correo del solicitante
              </TableCell>
              <TableCell align="left">{usuario_registro.correo}</TableCell>
            </TableRow>
          )
        }
        {institucion && (
          <TableRow key={5}>
            <TableCell component="th" scope="row">
              Nombre de la institución vinculante
            </TableCell>
            <TableCell align="left">{institucion}</TableCell>
          </TableRow>
        )}
        { codigo_convenio && (
          <TableRow key={5}>
            <TableCell component="th" scope="row">
              Codigo del convenio
            </TableCell>
            <TableCell align="left">{codigo_convenio}</TableCell>
          </TableRow>
        )}
        { estado_convenio && (
          <TableRow key={5}>
            <TableCell component="th" scope="row">
              Estado actual del convenio
            </TableCell>
            <TableCell align="left">{estado_convenio.nombre}</TableCell>
          </TableRow>
        )}
        { fecha_inicio && (
          <TableRow key={5}>
            <TableCell component="th" scope="row">
              Fecha inicio convenio
            </TableCell>
            <TableCell align="left">{fecha_inicio}</TableCell>
          </TableRow>
        )}
        { fecha_finalizacion && (
          <TableRow key={5}>
            <TableCell component="th" scope="row">
              Fecha finalización convenio
            </TableCell>
            <TableCell align="left">{fecha_finalizacion}</TableCell>
          </TableRow>
        )}
        { act_objeto && (
          <TableRow key={5}>
            <TableCell component="th" scope="row">
              Lista de actividades del objeto
            </TableCell>
            <TableCell align="left">{act_objeto}</TableCell>
          </TableRow>
        )}
        { renovacion && (
          <TableRow key={5}>
            <TableCell component="th" scope="row">
              Tipo de renovacion
            </TableCell>
            <TableCell align="left">{renovacion}</TableCell>
          </TableRow>
        )}
        {rector && (
          <TableRow key={6}>
            <TableCell component="th" scope="row">
              Nombre del rector
            </TableCell>
            <TableCell align="left">{rector}</TableCell>
          </TableRow>
        )}
        {representacion_legal && (
          <TableRow key={7}>
            <TableCell component="th" scope="row">
              Nombre del representante legal
            </TableCell>
            <TableCell align="left">{representacion_legal}</TableCell>
          </TableRow>
        )}
        {pais_vinculante && (
          <TableRow key={8}>
            <TableCell component="th" scope="row">
              Pais
            </TableCell>
            <TableCell align="left">{pais_vinculante.nombre}</TableCell>
          </TableRow>
        )}
        {ciudad_vinculante && (
          <TableRow key={9}>
            <TableCell component="th" scope="row">
              Ciudad
            </TableCell>
            <TableCell align="left">{ciudad_vinculante}</TableCell>
          </TableRow>
        )}
        {direccion_vinculante && (
          <TableRow key={10}>
            <TableCell component="th" scope="row">
              Direccion de correspondencia
            </TableCell>
            <TableCell align="left">{direccion_vinculante}</TableCell>
          </TableRow>
        )}
        {direccion_Web && (
          <TableRow key={11}>
            <TableCell component="th" scope="row">
              Direccion web
            </TableCell>
            <TableCell align="left">{direccion_Web}</TableCell>
          </TableRow>
        )}
        {encargado_interna && (
          <TableRow key={12}>
            <TableCell component="th" scope="row">
              Nombre del encargado de internacionalización
            </TableCell>
            <TableCell align="left">{encargado_interna}</TableCell>
          </TableRow>
        )}
        {correo_interna && (
          <TableRow key={13}>
            <TableCell component="th" scope="row">
              Email del encargado de internacionalización
            </TableCell>
            <TableCell align="left">{correo_interna}</TableCell>
          </TableRow>
        )}
        {cargo_interna && (
          <TableRow key={14}>
            <TableCell component="th" scope="row">
              Cargo del encargado de internacionalización
            </TableCell>
            <TableCell align="left">{cargo_interna}</TableCell>
          </TableRow>
        )}
        {telefono_interna && (
          <TableRow key={15}>
            <TableCell component="th" scope="row">
              Telefono del encargado de internacionalización
            </TableCell>
            <TableCell align="left">{telefono_interna}</TableCell>
          </TableRow>
        )}
        {tipoconvenionombre.length > 0 && (
          <TableRow key={17}>
            <TableCell component="th" scope="row">
              Tipo de convenio
            </TableCell>
            <TableCell align="left">
              {tipoconvenionombre.map((index, i) => (
                <TableRow scope="row">{index}</TableRow>
              ))}
            </TableCell>
          </TableRow>
        )}
        {otro_tipo_convenio && (
          <TableRow key={15}>
            <TableCell component="th" scope="row">
              Otro tipo de convenio
            </TableCell>
            <TableCell align="left">{otro_tipo_convenio}</TableCell>
          </TableRow>
        )}
        {subtiponombre.length > 0 && (
          <TableRow key={16}>
            <TableCell component="th" scope="row">
              Funciones misionales que impactan
            </TableCell>
            <TableCell align="left">
              {subtiponombre.map((index, i) => (
                <TableRow scope="row">{index}</TableRow>
              ))}
            </TableCell>
          </TableRow>
        )}
        {otro_subtipo_convenio && (
          <TableRow key={15}>
            <TableCell component="th" scope="row">
              Otra funciones misionales que impactan
            </TableCell>
            <TableCell align="left">{otro_subtipo_convenio}</TableCell>
          </TableRow>
        )}
        {helpidioma_minuta.length > 0 && (
          <TableRow key={21}>
            <TableCell component="th" scope="row">
              Idioma del documento
            </TableCell>
            <TableCell align="left">
              {helpidioma_minuta.map((index, i) => (
                <TableRow scope="row">{index}</TableRow>
              ))}
            </TableCell>
          </TableRow>
        )}
        {otro_idioma_minuta && (
          <TableRow key={15}>
            <TableCell component="th" scope="row">
              Otro idioma del documento
            </TableCell>
            <TableCell align="left">{otro_idioma_minuta}</TableCell>
          </TableRow>
        )}
        {fuentefinanciacionconvenionombre.length > 0 && (
          <TableRow key={18}>
            <TableCell component="th" scope="row">
              Fuentes de financiación
            </TableCell>
            <TableCell align="left">
              {fuentefinanciacionconvenionombre.map((index, i) => (
                <TableRow scope="row">{index}</TableRow>
              ))}
            </TableCell>
          </TableRow>
        )}
        {otra_fuente_financiacion && (
          <TableRow key={15}>
            <TableCell component="th" scope="row">
              Otra fuente de financiación
            </TableCell>
            <TableCell align="left">{otra_fuente_financiacion}</TableCell>
          </TableRow>
        )}
        {objeto_del_convenio && (
          <TableRow key={19}>
            <TableCell component="th" scope="row">
              Objetivo del convenio
            </TableCell>
            <TableCell align="left">{objeto_del_convenio}</TableCell>
          </TableRow>
        )}
        {vigencia && (
          <TableRow key={20}>
            <TableCell component="th" scope="row">
              Vigencia del convenio
            </TableCell>
            <TableCell align="left">{vigencia}</TableCell>
          </TableRow>
        )}
        {helpacreditainst.length > 0 && (
          <TableRow key={22}>
            <TableCell component="th" scope="row">
              Acreditación institucional
            </TableCell>
            <TableCell align="left">
              {helpacreditainst.map((index, i) => (
                <TableRow scope="row">{index}</TableRow>
              ))}
            </TableCell>
          </TableRow>
        )}
        
        {helpranking.length > 0 && (
          <TableRow key={9}>
            <TableCell component="th" scope="row">
              Ranking
            </TableCell>
            <TableCell align="left">
              {helpranking.map((item, i) => (
                item.posicion_dos ? (
                  <TableRow scope="row">
                    {item.ranking + " : " + item.posicion + " - " + item.posicion_dos}
                  </TableRow>
                ) : (<TableRow scope="row">
                  {item.ranking + " : " + item.posicion}
                </TableRow>)

              ))}
            </TableCell>
          </TableRow>
        )}
        {helpacreditapro.length > 0 && (
          <TableRow key={24}>
            <TableCell component="th" scope="row">
              Acreditación programa
            </TableCell>
            <TableCell align="left">
              {helpacreditapro.map((index, i) => (
                <TableRow scope="row">{index}</TableRow>
              ))}
            </TableCell>
          </TableRow>
        )}
        {programa_facultad_3 && (
          <TableRow key={25}>
            <TableCell component="th" scope="row">
              Programa o facultad
            </TableCell>
            <TableCell align="left">{programa_facultad_3}</TableCell>
          </TableRow>
        )}
        {solicitud_excepcion && (
          <TableRow key={25}>
            <TableCell component="th" scope="row">
              Solicitud excepcion
            </TableCell>
            <TableCell align="left">{solicitud_excepcion}</TableCell>
          </TableRow>
        )}
        <TableRow key={26}>
          <TableCell component="th" scope="row">
            Tipo de actividades
          </TableCell>
          {pasotipoactividad.length > 0 ?
            <TableCell align="left">
              {pasotipoactividad.map((item, i) => (
                <TableRow key={i}>
                  {item.tipo_actividad.nombre +
                    " - " +
                    item.descripcion_actividad +
                    " - " +
                    item.presupuesto_Vinculante +
                    " - " +
                    item.presupuesto_Unicosta}
                </TableRow>
              ))}
            </TableCell>
            :
            <TableCell align="left">
              {"No registra"}
            </TableCell>
          }
        </TableRow>
        {nombre_enlace_vin && (
          <TableRow key={27}>
            <TableCell component="th" scope="row">
              Enlace operativo de la Institución Vinculante
            </TableCell>
            <TableCell align="left">
              <TableRow scope="row">{"Nombre: "+nombre_enlace_vin}</TableRow>
              <TableRow scope="row">{"Telefono: "+telefono_enlace_vin}</TableRow>
              <TableRow scope="row">{"Unidad: "+unidad_enlace_vin}</TableRow>
              <TableRow scope="row">{"Cargo: "+cargo_enlace_vin}</TableRow>
              <TableRow scope="row">{"Email: "+email_enlace_vin}</TableRow>
            </TableCell>
          </TableRow>
        )}
        {nombre_enlace_Uni && (
          <TableRow key={28}>
            <TableCell component="th" scope="row">
              Enlace operativo UNICOSTA
            </TableCell>
            <TableCell align="left">
              <TableRow scope="row">{"Nombre: "+nombre_enlace_Uni}</TableRow>
              <TableRow scope="row">{"Telefono: " + telefono_enlace_Uni}</TableRow>
              {dpto_academico_enlaceUni ? 
                <TableRow scope="row">{"Departamento academico o unidad administrativa	: " + dpto_academico_enlaceUni.nombre}</TableRow>
                : ''
              }
              <TableRow scope="row">{"Unidad: "+unidad_enlace_Uni}</TableRow>
              <TableRow scope="row">{"Cargo: "+cargo_enlace_Uni}</TableRow>
              <TableRow scope="row">{"Email: "+email_enlace_Uni}</TableRow>
            </TableCell>
          </TableRow>
        )}
        {solicitud_renovacion && (
        <TableRow key={28}>
            <TableCell component="th" scope="row">
              Renovacion
            </TableCell>
            <TableCell align="left">
              <TableRow scope="row">{solicitud_renovacion}</TableRow>
            </TableCell>
          </TableRow>)}
      </TableBody>
    </Table>
  );
}
