import React, { Component } from 'react';
import moment from 'moment'
import { Dialog, DialogContent, DialogActions } from '@material-ui/core'

//components
import ListarDatos from '../../general/ListarDatos'
import AppBarModal from '../../general/AppBarModal'
import { BtnForm } from '../../general/BotonesAccion'
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from "../../../global/imagenes/emma_w.png";
import { consulta } from '../../../global/js/funciones'


class DetalleEstados extends Component {
    constructor(props) {
		super(props)
		this.state = {
			cargando: true,
            estados: []
		}
	}

    componentDidUpdate({ modalDetalleEstados }) {
        let { modalDetalleEstados: modalDetalleEstadosNew, idVacanteEstados } = this.props;
        if (modalDetalleEstados !== modalDetalleEstadosNew && modalDetalleEstadosNew) this.obtenerEstados(idVacanteEstados)
    }

    obtenerEstados(id) {
        this.setState({ cargando: true });
        consulta("api/v1.0/portal/estados_listar", { id }, "post", (error, estado, resp) => {
            if (estado === 200) this.setState({ cargando: false, estados: resp });
        })
    }

    obtenerColor = (codigo) => {
        let color = '#ffffff';
        const colores = {
          'Por_Sol': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
          'Por_Rev': { 'backgroundColor': '#ffc107', color },
          'Por_Ace': { 'backgroundColor': '#0d47a1', color }
        }
        return (colores[codigo])
    }

    renderDetalleEstados = () => {
        let { estados, cargando } = this.state
        return (
            <>
                {
                    !cargando ?
                        <ListarDatos
                            id="tbl_inf_ofertas"
                            datos={estados}
                            titulo={"Lista de estados"}
                            // agregar={false}
                            descargar={''}
                            // actAgregar={() => console.log('Hola')}
                            opciones={true}
                            buscar={false}
                            sub_titulo={"Lista de mis estados"}
                            avatar={({ tipo_estado }) => tipo_estado.nombre.toUpperCase().charAt(0)}
                            color_avatar={({ tipo_estado }) => this.obtenerColor(tipo_estado.codigo)}
                            fila_principal={({ vacante }) => `Cargo: ${vacante.nombre_cargo}`}
                            filas={[
                                {
                                    'mostrar': ({ mensaje }) => `Mensaje: ${mensaje ? mensaje : "Sin mensaje"}`,
                                    'id': 'mensaje',
                                    'enLista': true
                                },
                                {
                                    'mostrar': ({ tipo_estado }) => `Estado: ${tipo_estado && tipo_estado.nombre}`,
                                    'id': 'mensaje',
                                    'enLista': true
                                },
                                {
                                    'mostrar': ({ fecha_registro }) => `Fecha: ${moment(fecha_registro).format('YYYY-MM-DD, h:mm a')}`,
                                    'id': 'fecha_registro',
                                    'enLista': true
                                }
                            ]}
                        /> :
                        <TareasTerminadas mensaje={'Cargando..'} marginTop="7%" imagen={emma_w} widthImg="7%" />
                }
            </>

        )
    }

    render() {
        let { modalDetalleEstados, mostrarModalDetalleEstados } = this.props
        return (
            <div>
                <Dialog open={modalDetalleEstados} onClose={() => mostrarModalDetalleEstados(false)} fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600}>
                    <AppBarModal titulo={"Detalle Estados"} mostrarModal={() => mostrarModalDetalleEstados(false)} titulo_accion="" />
                    <DialogContent style={{ padding: 0, overflowX: 'hidden' }} className="scroll">
                        <div>
                            {this.renderDetalleEstados()}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <BtnForm texto="CERRAR" callback={() => mostrarModalDetalleEstados(false)} />
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default DetalleEstados;