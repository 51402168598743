import React from 'react'
import PropTypes from 'prop-types';
import ListarDatos from "../../general/ListarDatos";
import { rutaImg } from "../../../global/js/funciones";


class PracticasEstudiantes extends React.Component {

  obtenerColor = (codigo) => {
    let color = 'white';
    const colores = {
      'No': { 'backgroundColor': '#b71c1c', color },
      'Si': { 'backgroundColor': '#009688', color },
    }
    return (colores[codigo])
  }
  render() {
    let { estudiantes } = this.props;
    return (
      <ListarDatos
        datos={estudiantes}
        titulo='Lista Matriculados'
        buscar={true}
        opciones={true}
        sub_titulo={'Estudiantes Matriculados'}
        descargar='EMMA - MATRICULADOS'
        id='tbl_sin_procesos'
        avatarImg={true}
        avatar={({ identificacion }) => `${rutaImg}${identificacion}.jpg`}
        fila_principal='nombre_completo'
        filas={[
          { 'id': 'nombre_completo', nombre: 'Nombre Completo', enLista: false },
          { 'id': 'correo', nombre: 'Correo Institucional: ' },
          { 'id': 'correo_personal', nombre: 'Correo Personal', enLista: false },
          { 'id': 'celular', nombre: 'Celular', enLista: false },
          { 'id': 'programa', nombre: 'Programa: ' },
          { 'id': 'identificacion', nombre: 'Identificación: ' },
          { 'id': 'proceso', nombre: 'proceso EMMA  ' },
        ]}
      />
    )
  }

}
//Props del componente
PracticasEstudiantes.propTypes = {
  //variables
  estudiantes: PropTypes.array.isRequired,
  //funciones
}


export default PracticasEstudiantes;


