import React, { Component } from 'react'
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import AppBarModal from '../../general/AppBarModal'
import { BtnForm } from "../../general/BotonesAccion";

class EditarDiplomado extends Component {

  constructor(props) {
    super(props)
    this.state = {
      nombre: '',
      valor: 0,
      grado: '',
    }
  }

  componentDidUpdate({nombre, valor, grado}){
    let { nombre: np, valor: vp, grado:gp } = this.props;
    if(nombre != np || valor != vp || grado != gp){
        this.setState({
            nombre: np, valor: vp, grado: gp 
        })
    }
  }

  onSubmit = e => {
    let { nombre, valor, grado } = this.state;
    let { editarDiplomado, idDiplomado } = this.props;
    editarDiplomado(idDiplomado, nombre, valor, grado);
    e.preventDefault();
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  render() {
    let { nombre, valor, grado } = this.state;
    let { modalAdd, mostrarModalAdd } = this.props;

    return (
      <Dialog open={modalAdd} onClose={mostrarModalAdd.bind(this, false)} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="xs">
        <AppBarModal titulo="Editar Diplomado" mostrarModal={mostrarModalAdd} titulo_accion="" accion="" />
        <DialogContent className='scroll'>
          <ValidatorForm onSubmit={this.onSubmit}>
            <TextValidator
              variant="outlined"
              autoFocus
              margin="dense"
              id="nombre"
              label="Nombre"
              type="text"
              fullWidth
              name="nombre"
              value={nombre}
              validators={["required"]}
              errorMessages={["El campo es requerido"]}
              onChange={this.onChange}
            />
            <TextValidator
              variant="outlined"
              margin="dense"
              id="valor"
              label="Valor"
              type="number"
              fullWidth
              name="valor"
              value={valor}
              onChange={this.onChange}
            />
            <TextValidator
              variant="outlined"
              autoFocus
              margin="dense"
              label="Opción a grado de:"
              type="text"
              fullWidth
              name="grado"
              value={grado}
              validators={["required"]}
              errorMessages={["El campo es requerido"]}
              onChange={this.onChange}
            />
            <Button color="primary" type="submit" id='enviar_form_editar_diplomado' className="oculto"> Guardar </Button>
          </ValidatorForm>
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CANCELAR" callback={() => mostrarModalAdd(false)} />
          <BtnForm texto="EDITAR" callback={() => { document.getElementById('enviar_form_editar_diplomado').click() }} />
        </DialogActions>
      </Dialog>
    )
  }
}

//Props del componente
EditarDiplomado.propTypes = {
  //variables
  modalAdd: PropTypes.bool.isRequired,
  //funciones
  mostrarModalAdd: PropTypes.func.isRequired,
  editarDiplomado: PropTypes.func.isRequired,
}


export default EditarDiplomado;