import React from "react";

// COMPONENTE GLOBALES
import { Dialog, DialogContent, DialogActions, Grid, InputLabel, Select, MenuItem, FormControl, Checkbox, ListItemText } from '@material-ui/core';
import AppBarModal from '../../general/AppBarModal';
import { BtnForm } from '../../general/BotonesAccion';
import { consulta, generarFiltros, obtenerValoresFiltros, transformar } from '../../../global/js/funciones'
import TareasTerminadas from "../../general/TareasTerminadas";
import ListarTabla from '../../general/ListarTabla';

// IMAGENES
import emma_w from '../../../global/imagenes/emma_w.png';
import emma_s from '../../../global/imagenes/emma_s1.png';

// HELPERS
import { ESTADOS } from '../../retoscuc/Helpers';

class RetosInforme extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			columnas: [],
			data_informe: [],
			cargando: false,
			mostrar: 'filtros',
			maxWidth: 'sm',
			fechaActual: '',
			fecha: '',
			cargar: true,
			periodo: '',
			periodos: [],
			estado_actual: [],
			estado_actualSelect: [],
			programasMulti: [],
			periodosMulti: [],
			programasSelect: [],
			integrantes: [],
		}
	}

	resetState = () => {
		let { mostrar } = this.state
		if (mostrar === 'filtros') this.setState({ data_informe: [], columnas: [], mostrar: 'filtros', maxWidth: 'sm', periodo: '', programa: '', programasMulti: [], periodosMulti: [], cargando: false })
		else if (mostrar === 'informe') this.setState({ mostrar: 'filtros', maxWidth: 'sm', columnas: [], cargando: false })
		else if (mostrar === 'filtroDetalle') this.setState({ mostrar: 'informe', maxWidth: 'lg', practicas: [], cargando: false })
		else this.setState({ mostrar: 'filtroDetalle', maxWidth: 'sm', cargando: false })
	}

	async componentDidUpdate({ modalInformeRetos }, { mostrar }) {
		let { mostrar: cargar } = this.state;
		let { modalInformeRetos: modalInformeRetosNew } = this.props;
		if (modalInformeRetos !== modalInformeRetosNew) {
			if (modalInformeRetosNew && cargar) {
				this.setState({ cargando: true })
				const periodos = await this.obtenerPeriodos();
				const programas = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': 5 }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valorc', 'valor': 'pregrado' }]);
				const estado_actual = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': ESTADOS }, { 'llave': 'estado', 'valor': 1 }]);
				const integrantes = await this.obtenerRetos();

				this.setState({ periodos, cargando: false, cargar: false, 'programas': transformar(programas), 'programasSelect': programas, estado_actualSelect: estado_actual, integrantes: transformar(integrantes) })
			}
			else this.resetState()
		}
	}

	obtenerPeriodos = () => {
		return new Promise(resolve => {
			consulta(`api/v1.0/retoscuc/periodos`, null, null,
				(error, estado, resp) => {
					resolve(resp)
				}
			);
		})
	}

	obtenerRetos = () => {
		return new Promise(resolve => {
			consulta(`api/v1.0/retos`, null, null,
				(error, estado, resp) => {
					resolve(resp)
				}
			);
		})
	}

	obtenerInforme = async (filtros = []) => {
		let { actualizarMensaje } = this.props;
		let f = await generarFiltros(filtros);
		this.setState({ cargando: true });
		consulta(`api/v1.0/retoscuc/informe?${f}`, null, null,
			(error, estado, resp) => {
				if (estado === 200) this.setState({ cargando: false, data_informe: resp, mostrar: 'informe', maxWidth: 'lg' });
				else actualizarMensaje({ titulo: 'Ha ocurrido un error, contacte con el administrador.', tipo: 'info', mostrar: true, tiempo: 6000 });
			}
		);
	}

	obtenerInformeGanadores = async (filtros = []) => {
		let { actualizarMensaje } = this.props;
		let f = await generarFiltros(filtros);
		this.setState({ cargando: true });
		consulta(`api/v1.0/retoscuc/informe_ganadores?${f}`, null, null,
			(error, estado, resp) => {
				if (estado === 200) this.setState({ cargando: false, data_informe: resp, mostrar: 'informe', maxWidth: 'lg' });
				else actualizarMensaje({ titulo: 'Ha ocurrido un error, contacte con el administrador.', tipo: 'info', mostrar: true, tiempo: 6000 });
			}
		);

	}

	onSubmit = (e) => {
		let { periodosMulti, programasMulti, estado_actual, integrantes } = this.state
		let { actualizarMensaje, informe } = this.props
		let filtros = [], titulo = '';

		console.log(integrantes);

		if (informe.codigo === "inf_gene_retoscuc") {
			if (periodosMulti.length > 0) {
				filtros.push({ 'llave': 'informe', 'valor': informe.codigo });
				filtros.push({ 'llave': 'periodo_in', 'valor': periodosMulti });
				filtros.push({ 'llave': 'programa_in', 'valor': programasMulti });
				filtros.push({ 'llave': 'estado_actual_in', 'valor': estado_actual });

			} else titulo = 'Por favor, seleccione el periodo.'
			if (titulo) actualizarMensaje({ titulo, tipo: 'info', mostrar: true, tiempo: 6000 });
			else this.obtenerInforme(filtros)
			e.preventDefault();
		} else if (informe.codigo === "inf_gan_retoscuc") {
			if (periodosMulti.length > 0) {
				filtros.push({ 'llave': 'informe', 'valor': informe.codigo });
				filtros.push({ 'llave': 'periodo_in', 'valor': periodosMulti });
			} else titulo = 'Por favor, seleccione el periodo.'
			if (titulo) actualizarMensaje({ titulo, tipo: 'info', mostrar: true, tiempo: 6000 });
			else this.obtenerInformeGanadores(filtros)
			e.preventDefault();
		}
	}

	pintar = () => {
		let { cargando, mostrar } = this.state;
		if (cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />;
		return (
			<>
				{mostrar === 'filtros' && this.pintarFiltros()}
				{mostrar === 'informe' && this.pintarInforme()}
			</>
		)
	}

	pintarNombresPeriodo = (selected, listado) => {
		let resp = [];

		let a = listado.filter((e) => selected.indexOf(e) !== -1)
		a.map((e) => resp.push(e))

		return resp.join(", ")
	}

	pintarNombres = (selected, listado) => {
		let resp = [];

		let a = listado.filter(({ id }) => selected.indexOf(id) !== -1)
		a.map(({ nombre }) => resp.push(nombre))

		return resp.join(", ")
	}

	pintarFiltros = () => {
		let { informe } = this.props
		let { periodos, periodosMulti, programasMulti, programasSelect, estado_actualSelect, estado_actual } = this.state
		if (informe.codigo === "inf_gene_retoscuc") {
			return (
				<Grid container spacing={3} className='margin_cont'>
					<Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
						<FormControl className='form-control'>
							<InputLabel htmlFor="periodosMulti">Seleccione Periodo</InputLabel>
							<Select
								inputProps={{ name: 'periodosMulti', id: 'periodosMulti' }}
								multiple
								value={periodosMulti}
								onChange={this.onChange}
								renderValue={selected => { return this.pintarNombresPeriodo(selected, periodosMulti) }}
								required={true}>
								{periodos.map(({ periodo }, i) =>
									<MenuItem key={i} value={periodo}>
										<Checkbox checked={periodosMulti.indexOf(periodo) > -1} />
										<ListItemText primary={periodo} />
									</MenuItem>
								)}
							</Select>
						</FormControl>
					</Grid>
					{/* Programas */}
					<Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
						<FormControl className='form-control'>
							<InputLabel htmlFor="programasMulti">Seleccione programa</InputLabel>
							<Select
								inputProps={{ name: 'programasMulti', id: 'programasMulti' }}
								multiple
								value={programasMulti}
								onChange={this.onChange}
								renderValue={selected => { return this.pintarNombres(selected, programasSelect) }}
								required={true}>
								{programasSelect.map(({ id, nombre }, i) =>
									<MenuItem key={i} value={id}>
										<Checkbox checked={programasMulti.indexOf(id) > -1} />
										<ListItemText primary={nombre} />
									</MenuItem>
								)}
							</Select>
						</FormControl>
					</Grid>
					{/* Estado Actual */}
					<Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
						<FormControl className='form-control'>
							<InputLabel htmlFor="estado_actual">Seleccione Estado Actual</InputLabel>
							<Select
								inputProps={{ name: 'estado_actual', id: 'estado_actual' }}
								multiple
								value={estado_actual}
								onChange={this.onChange}
								renderValue={selected => { return this.pintarNombres(selected, estado_actualSelect) }}
								required={true}>
								{estado_actualSelect.map(({ id, nombre }, i) =>
									<MenuItem key={i} value={id}>
										<Checkbox checked={estado_actual.indexOf(id) > -1} />
										<ListItemText primary={nombre} />
									</MenuItem>
								)}
							</Select>
						</FormControl>
					</Grid>
				</Grid>
			)
		}
		if (informe.codigo === "inf_gan_retoscuc") {
			return (
				<Grid container spacing={3} className='margin_cont'>
					<Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
						<FormControl className='form-control'>
							<InputLabel htmlFor="periodosMulti">Seleccione Periodo</InputLabel>
							<Select
								inputProps={{ name: 'periodosMulti', id: 'periodosMulti' }}
								multiple
								value={periodosMulti}
								onChange={this.onChange}
								renderValue={selected => { return this.pintarNombresPeriodo(selected, periodosMulti) }}
								required={true}>
								{periodos.map(({ periodo }, i) =>
									<MenuItem key={i} value={periodo}>
										<Checkbox checked={periodosMulti.indexOf(periodo) > -1} />
										<ListItemText primary={periodo} />
									</MenuItem>
								)}
							</Select>
						</FormControl>
					</Grid>
				</Grid>
			)
		}
	}


	pintarInforme = () => {
		let { data_informe, periodo, columnas } = this.state
		let { informe } = this.props

		let ordenar = false

		let codigo = informe.codigo
		if (codigo === 'inf_gene_retoscuc') {
			if (data_informe.length > 0) {
				if (columnas.length == 0) {
					columnas.push(
						{ title: 'PREGUNTA RETO', field: 'pregunta_retos' },
						{ title: 'CODIGO ASIGNATURA', field: 'codigo_asignatura' },
						{ title: 'ASIGNATURA', field: 'asignatura' },
						{ title: 'PROGRAMA', field: 'programa' },
						{ title: 'NIVEL', field: 'modalidad' },
						{ title: 'PERIODO', field: 'periodo' },
						{ title: 'ESTADO ACTUAL', field: 'estado_actual' },
						{ title: 'PROFESOR', field: 'profesor_asignado' },
						{ title: 'LIDER DE GRUPO', field: 'lider_equipo' },
						{ title: 'CORREO LIDER DE GRUPO', field: 'correo' },
						{ title: 'CELULAR LIDER DE GRUPO', field: 'celular' },
						{ title: 'EMPRESA RETOS', field: 'empresa_reto' },
						{ title: 'URL PRESENTACIÓN FINAL', field: 'url_solucion_reto' },
						{ title: 'INTEGRANTES', field: 'integrantes' },
						{ title: 'NOMBRE RETO', field: 'nombre_reto' },
					)



					data_informe.map((item) => {
						console.log(item)
						let integrantes_grupo = []
						item.integrantes.map((integrante) => {
							let nombre = integrante.primer_nombre + ' ' + integrante.primer_apellido + ' ' + integrante.segundo_apellido
							integrantes_grupo.push(nombre)
						})
						integrantes_grupo = integrantes_grupo.join(', ')
						let pregunta_retos = item.pregunta_retos
						let codigo_asignatura = item.codigo_asignatura
						let asignatura = item.asignatura
						let programa = item.programa.nombre
						let modalidad = item.programa.valorc
						let periodo = item.periodo
						let estado_actual = item.estado_actual.nombre
						let profesor_asignado = item.profesor_asignado.primer_nombre + ' ' + item.profesor_asignado.primer_apellido + ' ' + item.profesor_asignado.segundo_apellido
						let lider_equipo = item.usuario_registro.primer_nombre + ' ' + item.usuario_registro.primer_apellido + ' ' + item.usuario_registro.segundo_apellido
						let correo = item.usuario_registro.correo
						let celular = item.usuario_registro.celular
						let empresa_reto = item.empresa_reto
						let url_solucion_reto = item.url_solucion_reto
						let integrantes = integrantes_grupo
						let nombre_reto = item.nombre_reto

						item.pregunta_retos = pregunta_retos
						item.codigo_asignatura = codigo_asignatura
						item.asignatura = asignatura
						item.programa = programa
						item.modalidad = modalidad
						item.periodo = periodo
						item.estado_actual = estado_actual
						item.profesor_asignado = profesor_asignado
						item.lider_equipo = lider_equipo
						item.correo = correo
						item.celular = celular
						item.empresa_reto = empresa_reto
						item.url_solucion_reto = url_solucion_reto
						item.integrantes = integrantes
						item.nombre_reto = nombre_reto
					})

					const titulo_informe = (periodo) ? `${informe.nombre} - ${periodo}` : informe.nombre

					return (
						<ListarTabla
							titulo={titulo_informe}
							columnas={columnas}
							datos={data_informe}
							orden={ordenar} />
					)
				}

			} else {
				return (
					<TareasTerminadas mensaje='No se encontraron resultados' marginTop='7%' imagen={emma_s} widthImg="7%" />
				)
			}
		}
		if (codigo === 'inf_gan_retoscuc') {
			if (data_informe.length > 0) {
				columnas.push(
					{ title: 'PREGUNTA RETO', field: 'pregunta_retos' },
					{ title: 'CODIGO ASIGNATURA', field: 'codigo_asignatura' },
					{ title: 'ASIGNATURA', field: 'asignatura' },
					{ title: 'PROGRAMA', field: 'programa' },
					{ title: 'NIVEL', field: 'modalidad' },
					{ title: 'PERIODO', field: 'periodo' },
					{ title: 'ESTADO ACTUAL', field: 'estado_actual' },
					{ title: 'PROFESOR', field: 'profesor_asignado' },
					{ title: 'LIDER DE GRUPO', field: 'lider_equipo' },
					{ title: 'CORREO LIDER DE GRUPO', field: 'correo' },
					{ title: 'CELULAR LIDER DE GRUPO', field: 'celular' },
					{ title: 'EMPRESA RETOS', field: 'empresa_reto' },
					{ title: 'URL PRESENTACIÓN FINAL', field: 'url_solucion_reto' },
					{ title: 'INTEGRANTES', field: 'integrantes' },
					{ title: 'NOMBRE RETO', field: 'nombre_reto' },
					{ title: 'NUMERO DE SOLUCIONES', field: 'num_solicitudes'}
				)



				data_informe.map((item) => {
					console.log(item)
					let integrantes_grupo = []
					if (Array.isArray(item.integrantes)) {
						item.integrantes.map((integrante) => {
							let nombre = integrante.primer_nombre + ' ' + integrante.primer_apellido + ' ' + integrante.segundo_apellido
							integrantes_grupo.push(nombre)
						})
					}
					integrantes_grupo = integrantes_grupo.join(', ')
					console.log(integrantes_grupo)
					let pregunta_retos = item.pregunta_retos
					let codigo_asignatura = item.codigo_asignatura
					let asignatura = item.asignatura
					let programa = item.programa.nombre
					let modalidad = item.programa.valorc
					let periodo = item.periodo
					let estado_actual = item.estado_actual.nombre
					let profesor_asignado = item.profesor_asignado.primer_nombre + ' ' + item.profesor_asignado.primer_apellido + ' ' + item.profesor_asignado.segundo_apellido
					let lider_equipo = item.usuario_registro.primer_nombre + ' ' + item.usuario_registro.primer_apellido + ' ' + item.usuario_registro.segundo_apellido
					let correo = item.usuario_registro.correo
					let celular = item.usuario_registro.celular
					let empresa_reto = item.empresa_reto
					let url_solucion_reto = item.url_solucion_reto
					let integrantes = integrantes_grupo
					let nombre_reto = item.nombre_reto
					let num_solicitudes = item.num_solicitudes

					item.pregunta_retos = pregunta_retos
					item.codigo_asignatura = codigo_asignatura
					item.asignatura = asignatura
					item.programa = programa
					item.modalidad = modalidad
					item.periodo = periodo
					item.estado_actual = estado_actual
					item.profesor_asignado = profesor_asignado
					item.lider_equipo = lider_equipo
					item.correo = correo
					item.celular = celular
					item.empresa_reto = empresa_reto
					item.url_solucion_reto = url_solucion_reto
					item.integrantes = integrantes
					item.nombre_reto = nombre_reto
					item.num_solicitudes = num_solicitudes
				})

				const titulo_informe = (periodo) ? `${informe.nombre} - ${periodo}` : informe.nombre

				return (
					<ListarTabla
						titulo={titulo_informe}
						columnas={columnas}
						datos={data_informe}
						orden={ordenar} />
				)


			} else {
				return (
					<TareasTerminadas mensaje='No se encontraron resultados' marginTop='7%' imagen={emma_s} widthImg="7%" />
				)
			}
		}
	}

	onChange = ({ target }) => {
		let { value, name } = target
		this.setState({ [name]: value })
	}


	render() {
		let { cargando, maxWidth, mostrar } = this.state;
		let { modalInformeRetos, mostrarModalInforme, informe } = this.props
		return (
			<Dialog fullWidth={true} maxWidth={maxWidth} PaperProps={(informe.codigo === 'inf_gene_retoscuc' || informe.codigo === 'inf_gan_retoscuc') ? { style: { overflowY: 'visible' } } : {}} open={modalInformeRetos} onClose={() => mostrarModalInforme(false) } aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
				<AppBarModal titulo={informe.nombre} mostrarModal={() => mostrarModalInforme(false)} />
				<DialogContent className='scroll' style={mostrar === 'informe' ? { padding: 0 } : ((informe.codigo === 'inf_gene_retoscuc' || informe.codigo === 'inf_gan_retoscuc') ? { overflowY: 'visible' } : {})}>
					{!cargando ? this.pintar() : <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />}
				</DialogContent>
				<DialogActions>
					<BtnForm texto="Cerrar" callback={() => mostrarModalInforme(false)} />
					{!cargando && mostrar === 'filtros' && <BtnForm texto="GENERAR" callback={this.onSubmit} />}
				</DialogActions>
			</Dialog>
		);
	}
}

export { RetosInforme }