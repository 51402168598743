
// PRODUCION    
export const PROGRAMAS = 5;
export const VALORES_PREGUNTAS = 70;
export const VALORES_RESPUESTAS = 72;
export const ENCABEZADO_INSTRUCCIONES = 71;
export const INSTRUCCIONES = 73;
export const GENERO =  69;
export const CONDICIONALES_TIPO_REA = 91;
export const DEPARTAMENTOS_FACULTADES = 92;
export const LISTARECOMENDACIONES = 116;
export const INFORMESREA = 244;



// LOCAL
// export const PROGRAMAS = 5;
// export const VALORES_PREGUNTAS = 37;
// export const VALORES_RESPUESTAS = 38;
// export const ENCABEZADO_INSTRUCCIONES = 39;
// export const INSTRUCCIONES = 40;
// export const GENERO =  41;
// export const CONDICIONALES_TIPO_REA = 42;
// export const DEPARTAMENTOS_FACULTADES = 43;
// export const LISTARECOMENDACIONES = 44;
// export const INFORMESREA = 1050;
