import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DateFnsUtils from '@date-io/date-fns'
import moment from 'moment'
import { connect } from 'react-redux';
import {
  mostrarModalDetConvocatorias,
  mostrarModalListaUniversidades,
  mostrarModalListaRequisitos,
  mostrarModalListaArchivos,
  actualizarDataConvocatorias,
  actualizarDataConvocatoria
} from '../../../redux/actions/actInternacionalizacion';
//Material Ui
import {
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Stepper,
  Step,
  StepLabel,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Paper,
  List
} from '@material-ui/core'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
//Funciones
import { REQUISITOS, GENEROS, UNIVERSIDADES } from '../administrar/helper';
import ConvocatoriasListar from '../convocatorias/ConvocatoriasListar'
import Detalle from '../../general/Detalle';
import { ConvocatoriaDetalle } from '../convocatorias/ConvocatoriaDetalle';
import EnviarMultiplesArchivos from '../../general/EnviarMultiplesArchivos';
import { BtnCargar, BtnDescargar, BtnDetalle, BtnForm } from '../../general/BotonesAccion'
import AppBarModal from '../../general/AppBarModal'
import TareasTerminadas from '../../general/TareasTerminadas'
import { consulta, generarFiltros, getError, obtenerMisDatos, obtenerValoresFiltros, validateInputs } from '../../../global/js/funciones'
//Imagenes e iconos
import emma_w from '../../../global/imagenes/emma_w.png'
import InfoIcon from '@material-ui/icons/Info';
import ConfirmarAccion from '../../general/ConfirmarAccion';

const configStep = ['Convocatorias', 'Datos personales', 'Información Académica', 'Requisitos'];

function DatosPersonales(component_props) {
  let {
    celular,
    celular_acudiente,
    correo_acudiente,
    correo,
    correo_personal,
    direccion_residencia,
    generos,
    genero,
    otro_genero,
    nacionalidad,
    lugar_residencia,
    ubicacion_residencia,
    nombre_apellido_acudiente,
    direccion_residencia_acudiente,
    fecha_nacimiento,
    onChange,
    errores,
    onChangeDate,
    parentesco,
    lugar_residencia_acudiente,
    ubicacion_residencia_acudiente,
    nombre_completo,
    tipo_de_identificacion,
    numero_de_identificacion
  } = component_props;
  return (
    <>
      <Grid container spacing={3} style={{ marginBottom: '40px' }}>
        <Grid item xs={ 12 }>
          <TextField
            value={ nombre_completo }
            disabled
            id='nombre_completo'
            name='nombre_completo'
            label='Nombre completo'
            fullWidth
          />
        </Grid>
        <Grid item xs={ 6 }>
          <TextField
            value={ tipo_de_identificacion }
            disabled
            id='tipo_de_identificacion'
            name='tipo_de_identificacion'
            label='Tipo de identificación'
            fullWidth
          />
        </Grid>
        <Grid item xs={ 6 }>
          <TextField
            value={ numero_de_identificacion }
            disabled
            id='numero_de_identificacion'
            name='numero_de_identificacion'
            label='Número de identificación'
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              id='fecha_nacimiento'
              required
              error={getError('fecha_nacimiento', errores).length > 0}
              helperText={getError('fecha_nacimiento', errores)}
              label='Fecha nacimiento'
              name='fecha_nacimiento'
              format='dd/MM/yyyy'
              value={fecha_nacimiento}
              onChange={(date) => onChangeDate('fecha_nacimiento', date)}
              fullWidth
              KeyboardButtonProps={{
                'aria-label': 'cambiar fecha'
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={6}>
          <FormControl className='form-control' required error={getError('genero', errores).length > 0}>
            <InputLabel id='genero_label'>Sexo</InputLabel>
            <Select
              labelId='genero_label'
              id='genero'
              name='genero'
              value={genero}
              fullWidth
              onChange={onChange}
            >
              {generos.map(({ id, nombre }) => (
                <MenuItem key={id} value={id}>{nombre}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{getError('genero', errores)}</FormHelperText>
          </FormControl>
        </Grid>
        {/* <Grid item xs={6}>
          <TextField
            value={otro_genero}
            required
            error={getError('otro_genero', errores).length > 0}
            helperText={getError('otro_genero', errores)}
            type='number'
            id='otro_genero'
            name='otro_genero'
            label='Otro genero'
            fullWidth
            onChange={onChange}
          />
        </Grid> */}
        <Grid item xs={ 6 }>
          <TextField
            value={ correo }
            disabled
            id='correo'
            name='correo'
            label='Correo institucional'
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={correo_personal}
            required
            error={getError('correo_personal', errores).length > 0}
            helperText={getError('correo_personal', errores)}
            id='correo_personal'
            name='correo_personal'
            label='Correo personal'
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={celular}
            required
            error={getError('celular', errores).length > 0}
            helperText={getError('celular', errores)}
            type='number'
            id='celular'
            name='celular'
            label='Número de celular'
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={nacionalidad}
            required
            error={getError('nacionalidad', errores).length > 0}
            helperText={getError('nacionalidad', errores)}
            id='nacionalidad'
            name='nacionalidad'
            label='Nacionalidad'
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={direccion_residencia}
            required
            error={getError('direccion_residencia', errores).length > 0}
            helperText={getError('direccion_residencia', errores)}
            id='direccion_residencia'
            name='direccion_residencia'
            label='Dirección de residencia'
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={ubicacion_residencia}
            required
            error={getError('ubicacion_residencia', errores).length > 0}
            helperText={getError('ubicacion_residencia', errores)}
            id='ubicacion_residencia'
            name='ubicacion_residencia'
            label='Ciudad de residencia'
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={lugar_residencia}
            required
            error={getError('lugar_residencia', errores).length > 0}
            helperText={getError('lugar_residencia', errores)}
            id='lugar_residencia'
            name='lugar_residencia'
            label='Departamento de residencia'
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={nombre_apellido_acudiente}
            required
            error={getError('nombre_apellido_acudiente', errores).length > 0}
            helperText={getError('nombre_apellido_acudiente', errores)}
            id='nombre_apellido_acudiente'
            name='nombre_apellido_acudiente'
            label='Nombre completo del acudiente'
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={parentesco}
            required
            error={getError('parentesco', errores).length > 0}
            helperText={getError('parentesco', errores)}
            id='parentesco'
            name='parentesco'
            label='Parentesco'
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={correo_acudiente}
            required
            error={getError('correo_acudiente', errores).length > 0}
            helperText={getError('correo_acudiente', errores)}
            id='correo_acudiente'
            name='correo_acudiente'
            label='Correo electrónico del acudiente'
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={celular_acudiente}
            required
            error={getError('celular_acudiente', errores).length > 0}
            helperText={getError('celular_acudiente', errores)}
            type='number'
            id='celular_acudiente'
            name='celular_acudiente'
            label='Número celular del acudiente'
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={ubicacion_residencia_acudiente}
            required
            error={getError('ubicacion_residencia_acudiente', errores).length > 0}
            helperText={getError('ubicacion_residencia_acudiente', errores)}
            id='ubicacion_residencia_acudiente'
            name='ubicacion_residencia_acudiente'
            label='Ciudad de residencia del acudiente'
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={lugar_residencia_acudiente}
            required
            error={getError('lugar_residencia_acudiente', errores).length > 0}
            helperText={getError('lugar_residencia_acudiente', errores)}
            id='lugar_residencia_acudiente'
            name='lugar_residencia_acudiente'
            label='Departamento de residencia del acudiente'
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={direccion_residencia_acudiente}
            required
            error={getError('direccion_residencia_acudiente', errores).length > 0}
            helperText={getError('direccion_residencia_acudiente', errores)}
            id='direccion_residencia_acudiente'
            name='direccion_residencia_acudiente'
            label='Dirección de residencia del acudiente'
            fullWidth
            onChange={onChange}
          />
        </Grid>
      </Grid>
    </>
  )
}

function InformacionAcademica(component_props) {
  let {
    semestre_actual,
    promedio_acumulado,
    ubicacion_universidad_1,
    ubicacion_universidad_2,
    ubicacion_universidad_3,
    ubicacion_universidad_4,
    errores,
    onChange,
    paises,
    programa_academico,
    programas,
    universidad_1,
    universidad_2,
    universidad_3,
    universidad_4,
    idioma_1,
    idioma_2,
    idioma_3,
    idioma_4,
    universidades_opciones,
    universidades_permitidas,
  } = component_props;
  return (
    <>
      <Grid container spacing={3} style={{ marginBottom: '40px' }}>
        <Grid item xs={12}>
          <FormControl className='form-control' required error={getError('programa_academico', errores).length > 0}>
            <InputLabel id='programa_label'>Programa académico</InputLabel>
            <Select
              labelId='programa_label'
              id='programa_academico'
              name='programa_academico'
              value={programa_academico}
              fullWidth
              onChange={onChange}
            >
              {programas.map((programa) => (
                <MenuItem key={programa.relacion.id} value={programa.relacion.id}>{programa.relacion.nombre}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{getError('programa_academico', errores)}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={semestre_actual}
            disabled
            error={getError('semestre_actual', errores).length > 0}
            helperText={getError('semestre_actual', errores)}
            type='number'
            id="semestre_actual"
            name="semestre_actual"
            label="Semestre actual"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={promedio_acumulado}
            disabled
            error={getError('promedio_acumulado', errores).length > 0}
            helperText={getError('promedio_acumulado', errores)}
            type='number'
            id="promedio_acumulado"
            name="promedio_acumulado"
            label="Promedio académico acumulado"
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl className='form-control' required>
            <InputLabel id='ubicacion_universidad_1_label'>País universidad destino #1</InputLabel>
            <Select
              labelId='ubicacion_universidad_1_label'
              id='ubicacion_universidad_1'
              name='ubicacion_universidad_1'
              value={ubicacion_universidad_1}
              fullWidth
              onChange={onChange}
            >
              {
                paises.map((pais) => (
                  <MenuItem key={pais} value={pais}>{pais}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className='form-control' required error={getError('universidad_1', errores).length > 0}>
            <InputLabel id='universidad_1_label'>Universidad destino #1</InputLabel>
            <Select
              labelId='universidad_1_label'
              id='universidad_1'
              name='universidad_1'
              value={universidad_1}
              fullWidth
              onChange={onChange}
            >
              {
                ubicacion_universidad_1 ?
                  universidades_opciones.map((univ) => (
                    (univ.valora === ubicacion_universidad_1) &&
                    <MenuItem key={univ.id} value={univ.id}>{univ.nombre}</MenuItem>
                  ))
                  : <MenuItem value=''><em>Para mostrar debes seleccionar el programa</em></MenuItem>
              }
            </Select>
            <FormHelperText>{getError('universidad_1', errores)}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <TextField
            value={idioma_1}
            disabled
            id='idioma_1'
            name='idioma_1'
            label='Idioma'
            fullWidth
          />
        </Grid>
        {
          Number(universidades_permitidas) > 1 &&
          <>
            <Grid item xs={4}>
              <FormControl className='form-control' required>
                <InputLabel id='ubicacion_universidad_2_label'>País universidad destino #2</InputLabel>
                <Select
                  labelId='ubicacion_universidad_2_label'
                  id='ubicacion_universidad_2'
                  name='ubicacion_universidad_2'
                  value={ubicacion_universidad_2}
                  fullWidth
                  onChange={onChange}
                >
                  {
                    paises.map((pais) => (
                      <MenuItem key={pais} value={pais}>{pais}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl className='form-control' required error={getError('universidad_2', errores).length > 0}>
                <InputLabel id='universidad_2_label'>Universidad destino #2</InputLabel>
                <Select
                  labelId='universidad_2_label'
                  id='universidad_2'
                  name='universidad_2'
                  value={universidad_2}
                  fullWidth
                  onChange={onChange}
                >
                  {
                    ubicacion_universidad_2 ?
                      universidades_opciones.map((univ) => (
                        (univ.valora === ubicacion_universidad_2) &&
                        <MenuItem key={univ.id} value={univ.id}>{univ.nombre}</MenuItem>
                      ))
                      : <MenuItem value=''><em>Para mostrar debes seleccionar el programa</em></MenuItem>
                  }
                </Select>
                <FormHelperText>{getError('universidad_2', errores)}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                value={idioma_2}
                disabled
                id='idioma_2'
                name='idioma_2'
                label='Idioma'
                fullWidth
              />
            </Grid>
          </>
        }
        {
          Number(universidades_permitidas) > 2 &&
          <>
            <Grid item xs={4}>
              <FormControl className='form-control' required>
                <InputLabel id='ubicacion_universidad_3_label'>País universidad destino #3</InputLabel>
                <Select
                  labelId='ubicacion_universidad_3_label'
                  id='ubicacion_universidad_3'
                  name='ubicacion_universidad_3'
                  value={ubicacion_universidad_3}
                  fullWidth
                  onChange={onChange}
                >
                  {
                    paises.map((pais) => (
                      <MenuItem key={pais} value={pais}>{pais}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl className='form-control' required error={getError('universidad_3', errores).length > 0}>
                <InputLabel id='universidad_3_label'>Universidad destino #3</InputLabel>
                <Select
                  labelId='universidad_3_label'
                  id='universidad_3'
                  name='universidad_3'
                  value={universidad_3}
                  fullWidth
                  onChange={onChange}
                >
                  {
                    ubicacion_universidad_3 ?
                      universidades_opciones.map((univ) => (
                        (univ.valora === ubicacion_universidad_3) &&
                        <MenuItem key={univ.id} value={univ.id}>{univ.nombre}</MenuItem>
                      ))
                      : <MenuItem value=''><em>Para mostrar debes seleccionar el programa</em></MenuItem>
                  }
                </Select>
                <FormHelperText>{getError('universidad_3', errores)}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                value={idioma_3}
                disabled
                id='idioma_3'
                name='idioma_3'
                label='Idioma'
                fullWidth
              />
            </Grid>
          </>
        }
        {
          Number(universidades_permitidas) > 3 &&
          <>
            <Grid item xs={4}>
              <FormControl className='form-control' required>
                <InputLabel id='ubicacion_universidad_4_label'>País universidad destino #3</InputLabel>
                <Select
                  labelId='ubicacion_universidad_4_label'
                  id='ubicacion_universidad_4'
                  name='ubicacion_universidad_4'
                  value={ubicacion_universidad_4}
                  fullWidth
                  onChange={onChange}
                >
                  {
                    paises.map((pais) => (
                      <MenuItem key={pais} value={pais}>{pais}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl className='form-control' required error={getError('universidad_4', errores).length > 0}>
                <InputLabel id='universidad_4_label'>Universidad destino #4</InputLabel>
                <Select
                  labelId='universidad_4_label'
                  id='universidad_4'
                  name='universidad_4'
                  value={universidad_4}
                  fullWidth
                  onChange={onChange}
                >
                  {
                    ubicacion_universidad_4 ?
                      universidades_opciones.map((univ) => (
                        (univ.valora === ubicacion_universidad_4) &&
                        <MenuItem key={univ.id} value={univ.id}>{univ.nombre}</MenuItem>
                      ))
                      : <MenuItem value=''><em>Para mostrar debes seleccionar el programa</em></MenuItem>
                  }
                </Select>
                <FormHelperText>{getError('universidad_4', errores)}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                value={idioma_4}
                disabled
                id='idioma_4'
                name='idioma_4'
                label='Idioma'
                fullWidth
              />
            </Grid>
          </>
        }
      </Grid>
    </>
  )
}

function AttachComponent(component_props) {
  let { requisitos_opciones, submit } = component_props;
  const transformarDataAdj = (data) => {
    let r = [];
    data.map(req => r.push({ nombre: req.nombre, descripcion: req.descripcion, codigo: req.id, archivo: req.archivo }));
    return r;
  }
  const acciones = (data, callback) => {
    const onClickCargar = (data) => callback(data);
    let descargar = data.archivo && <BtnDescargar callback={() => window.open(`${data.archivo}`, '_blank')} color='#01579b' />
    let cargar = <BtnCargar callback={() => onClickCargar(data)} color='#00bfa5' />
    return <div>{descargar} {cargar}</div>;
  }
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper elevation={0} square>
            <List component="nav" arial-label="main">
              <EnviarMultiplesArchivos
                acciones={acciones}
                enModal={false}
                enviarArchivos={(archivos) => submit(archivos)}
                limite={2}
                mostrarMensaje={false}
                enLista={true}
                lista={transformarDataAdj(requisitos_opciones)}
              />
            </List>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

class MovEstudiantilCrear extends Component {
  constructor(props) {
    super(props)
    this.state = {
      celular: '',
      celular_acudiente: '',
      nombre_apellido: '',
      nombre_apellido_acudiente: '',
      num_id: '',
      parentesco: '',
      nacionalidad: '',
      lugar_residencia: '',
      ubicacion_residencia: '',
      direccion_residencia: '',
      direccion_residencia_acudiente: '',
      programa_academico: '',
      promedio_acumulado: '',
      semestre_actual: '',
      correo_acudiente: '',
      correo_personal: '',
      genero: '',
      otro_genero: '',
      tit_cargando: 'Cargando...',
      lugar_residencia_acudiente: '',
      ubicacion_residencia_acudiente: '',
      ubicacion_universidad_1: '',
      ubicacion_universidad_2: '',
      ubicacion_universidad_3: '',
      ubicacion_universidad_4: '',
      universidad_1: '',
      universidad_2: '',
      universidad_3: '',
      universidad_4: '',
      idioma_1: '',
      idioma_2: '',
      idioma_3: '',
      idioma_4: '',
      universidad_seleccionada: '',
      activeStep: 0,
      cargando: true,
      fecha_nacimiento: null,
      modal_inicio_borrador: false,
      modal_quitar_universidad: false,
      archivos: [],
      archivos_guardados: [],
      convocatoria_seleccionada: {},
      solicitud_iniciada: {},
      datos_sesion: [],
      datos_persona: [],
      errores: [],
      generos: [],
      lista_convocatorias: [],
      paises: [],
      programas: [],
      requisitos: [],
      requisitos_opciones: [],
      universidades: [],
      universidades_opciones: [],
      universidades_escogidas: [],
      nombre_completo: '',
      tipo_de_identificacion: '',
      numero_de_identificacion: '',
      correo: ''
    }
  }

  async componentDidUpdate({ modalAddMovEstudiantil, cancelo_solicitud }, { programa_academico }) {
    let { modalAddMovEstudiantil: modalAddMovEstudiantilNew, cancelo_solicitud: cancelo_solicitud_new } = this.props;
    let { programa_academico: programa_academico_new } = this.state;
    if (modalAddMovEstudiantil !== modalAddMovEstudiantilNew) {
      if (modalAddMovEstudiantilNew) {
        this.setState({ cargando: true })
      // this.actualizarEstados({persona});
        this.reiniciarEstados(true);
        this.obtenerDatosIniciales();
      }
    }
    if (programa_academico_new) {
      if (programa_academico !== programa_academico_new) {
        this.setState({ cargando: true });
        let info_academica = await this.validarInfoAcademica(programa_academico_new);
        if (info_academica) this.setState({ promedio_acumulado: info_academica.promedio_acumulado, semestre_actual: info_academica.semestre_actual });
        this.setState({ cargando: false });
      }
    }
    if(cancelo_solicitud !== cancelo_solicitud_new) {
      if(cancelo_solicitud_new) {
        this.setState({ cargando: true });
        this.reiniciarEstados(true);
      }
    }
  }

  async cargarDatosBorrador(datos) {
    let {
      paso_actual,
      solicitud,
      convocatoria,
      acudiente,
      parentesco,
      correo_acudiente,
      celular_acudiente,
      direccion_residencia_acudiente,
      lugar_residencia_acudiente,
      ubicacion_residencia_acudiente,
      programa_academico,
      semestre_actual,
      promedio_acumulado,
      universidad_1,
      universidad_2,
      universidad_3,
      universidad_4,
      // persona
    } = datos;
    let { obtenerSolicitud } = this.props;
    let solicitud_info = await obtenerSolicitud(solicitud.id);
    this.obtenerConvocatoriaSeleccionada(convocatoria);
    this.setState({
      // nombre_completo: persona.primer_nombre + ' ' + persona.segundo_nombre + ' ' + persona.primer_apellido + ' ' + persona.segundo_apellido,
      activeStep: paso_actual,
      solicitud_iniciada: solicitud_info.solicitud,
      convocatoria_seleccionada: convocatoria,
      nombre_apellido_acudiente: acudiente ? acudiente : '',
      parentesco: parentesco ? parentesco : '',
      correo_acudiente: correo_acudiente ? correo_acudiente : '',
      celular_acudiente: celular_acudiente ? celular_acudiente : '',
      direccion_residencia_acudiente: direccion_residencia_acudiente ? direccion_residencia_acudiente : '',
      lugar_residencia_acudiente: lugar_residencia_acudiente ? lugar_residencia_acudiente: '',
      ubicacion_residencia_acudiente: ubicacion_residencia_acudiente ? ubicacion_residencia_acudiente : '',
      programa_academico: programa_academico ? programa_academico.id : '',
      semestre_actual: semestre_actual ? semestre_actual : '',
      promedio_acumulado: promedio_acumulado ? promedio_acumulado : '',
      universidad_1: universidad_1 ? universidad_1.id : '',
      ubicacion_universidad_1: universidad_1 ? universidad_1.valora : '',
      idioma_1: universidad_1 ? universidad_1.valord : '',
      universidad_2: universidad_2 ? universidad_2 : '',
      universidad_3: universidad_3 ? universidad_3 : '',
      universidad_4: universidad_4 ? universidad_4 : '',
      // total: archivos.length
    });
  }

  // Controla el stepper, si es true avanza y false retrocede, también valida campos por pasos.
  handleStep = async direccion => {
    let {
      activeStep,
      celular,
      celular_acudiente,
      convocatoria_seleccionada,
      correo_acudiente,
      correo_personal,
      genero,
      otro_genero,
      nacionalidad,
      nombre_apellido_acudiente,
      parentesco,
      programa_academico,
      lugar_residencia,
      ubicacion_residencia,
      lugar_residencia_acudiente,
      ubicacion_residencia_acudiente,
      universidad_1,
      universidad_2,
      universidad_3,
      universidad_4,
      direccion_residencia,
      direccion_residencia_acudiente,
      fecha_nacimiento,
      semestre_actual,
      promedio_acumulado,
      solicitud_iniciada
    } = this.state;
    let { cantidad_universidades, promedio_requerido, semestre_minimo: { valora: semestre_num } } = convocatoria_seleccionada;
    if (direccion) {
      let errores = [];
      switch (activeStep) {
        case 1:
          errores = await validateInputs([
            { value: fecha_nacimiento, name: 'fecha_nacimiento', err: { not_null: true, before_today: true } },
            { value: genero, name: 'genero', err: { not_null: true, empty: true } },
            { value: correo_personal, name: 'correo_personal', err: { email: true } },
            { value: celular, name: 'celular', err: { phone_number: true } },
            { value: nacionalidad, name: 'nacionalidad', err: { empty: true } },
            { value: lugar_residencia, name: 'lugar_residencia', err: { empty: true } },
            { value: ubicacion_residencia, name: 'ubicacion_residencia', err: { empty: true } },
            { value: direccion_residencia, name: 'direccion_residencia', err: { empty: true } },
            { value: nombre_apellido_acudiente, name: 'nombre_apellido_acudiente', err: { empty: true } },
            { value: parentesco, name: 'parentesco', err: { empty: true } },
            { value: correo_acudiente, name: 'correo_acudiente', err: { email: true } },
            { value: celular_acudiente, name: 'celular_acudiente', err: { phone_number: true } },
            { value: lugar_residencia_acudiente, name: 'lugar_residencia_acudiente', err: { empty: true } },
            { value: ubicacion_residencia_acudiente, name: 'ubicacion_residencia_acudiente', err: { empty: true } },
            { value: direccion_residencia_acudiente, name: 'direccion_residencia_acudiente', err: { empty: true } },
          ]);
          break;
        case 2:
          errores = await validateInputs([
            { value: universidad_1, name: 'universidad_1', err: { empty: true } },
            { value: programa_academico, name: 'programa_academico', err: { empty: true } },
            { value: semestre_actual, name: 'semestre_actual', err: { numeric: true, range2: true }, value2: semestre_num, value3: 11 },
            { value: promedio_acumulado, name: 'promedio_acumulado', err: { numeric: true, range2: true }, value2: promedio_requerido, value3: 5 },
          ]);
          if (Number(cantidad_universidades) > 1 && (universidad_2 === universidad_1)) errores.push({ llave: 'universidad_2', 'mensaje': `Por favor, escoge otra opción.` });
          if (Number(cantidad_universidades) > 2 && ((universidad_3 === universidad_1) || (universidad_3 === universidad_2))) errores.push({ llave: 'universidad_3', 'mensaje': `Por favor, escoge otra opción.` });
          if (Number(cantidad_universidades) > 3 && ((universidad_4 === universidad_1) || (universidad_4 === universidad_2) || (universidad_4 === universidad_3))) {
            errores.push({ llave: 'universidad_4', 'mensaje': `Por favor, escoge otra opción.` });
          }
          break;
        default:
          break;
      }
      this.setState({ errores });
      if (errores.length === 0) {
        activeStep < 3 && this.modificarDatosBorradorMovilidad(solicitud_iniciada.id);
      } else return false;
    } else this.setState({ activeStep: activeStep - 1 });
  }

  async obtenerArchivos(id) {
    let f = await generarFiltros([{ 'llave': 'convocatoria', 'valor': id }]);
    consulta(`api/v1.0/internacionalizacion/convocatoria/archivos?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ archivos_guardados: resp });
    });
  }

  async obtenerBorradorMovilidad() {
    return new Promise(resolve => {
      consulta(`api/v1.0/internacionalizacion/solicitud/movilidad_borrador`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      });
    })
  }

  async obtenerConvocatorias() {
    return new Promise(resolve => {
      consulta(`api/v1.0/internacionalizacion/convocatorias/listar`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  obtenerMisDtosInter = () => {
    return new Promise((resolve) => {
        consulta(`api/v1.0/internacionalizacion/datos_persona`, null, null, (error, estado, resp) => {
            resolve(resp);
        });
    });
};

  async obtenerDatosIniciales() {
    this.setState({ cargando: true });
    let solicitud = await this.obtenerBorradorMovilidad(); // Trae solicitud en borrador según el tipo de movilidad seleccionada.
    let convocatorias = await this.obtenerConvocatorias();
    let datos_sesion = await this.obtenerMisDtosInter();
    let generos = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': GENEROS }]);
    let universidades = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': UNIVERSIDADES }]);
    let requisitos = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': REQUISITOS }]);
    console.log(datos_sesion)
    this.props.actualizarDataConvocatorias(convocatorias);
    this.listaConvocatoriasParaMostrar();
    this.actualizarEstados(datos_sesion, generos, universidades, requisitos);
    if(solicitud && solicitud.length == 1) this.cargarDatosBorrador(solicitud[0]); // Si hay solicitud en borrador, se cargan los datos.
    this.setState({ cargando: false });
  }

  // Cargar parametros iniciales y datos personales que ya están registrados.
  actualizarEstados(datos_sesion, generos, universidades, requisitos) {
    let { persona, programas } = datos_sesion;
    this.setState({
      // Datos personales
      nombre_completo: persona.primer_nombre + ' ' + persona.segundo_nombre + ' ' + persona.primer_apellido + ' ' + persona.segundo_apellido,
      tipo_de_identificacion: persona.tipo_identificacion.nombre,
      numero_de_identificacion: persona.identificacion,
      correo: persona.correo,
      fecha_nacimiento: persona.fecha_nacimiento ? moment(persona.fecha_nacimiento, 'YYYY-MM-DD') : null,
      genero: persona.genero ? persona.genero.id : '',
      otro_genero: persona.otro_genero ? persona.otro_genero : '',
      correo_personal: persona.correo_personal ? persona.correo_personal : '',
      celular: persona.celular ? persona.celular : '',
      nacionalidad: persona.nacionalidad ? persona.nacionalidad : '',
      direccion_residencia: persona.direccion_residencia ? persona.direccion_residencia : '',
      lugar_residencia: persona.lugar_residencia ? persona.lugar_residencia : '',
      ubicacion_residencia: persona.ubicacion_residencia ? persona.ubicacion_residencia : '',
      // Parámetros
      programas,
      generos,
      universidades,
      requisitos,
      cargando: false,
    })
  }

  agregarArchivos = files => {
    let { actualizarMensaje } = this.props;
    let { archivos, requisitos } = this.state;
    files.map((archivo => {
      if (archivos.length === 10000) actualizarMensaje({ titulo: `Se ha superado el numero maximo de archivos ${requisitos.length}`, tipo: 'info', mostrar: true, tiempo: 6000 })
      else archivos.push(archivo)
    }));
    this.setState({ archivos });
  }

  configStepContent = () => {
    let { modalDetConvocatorias, actualizarDataConvocatoria, convocatoria, mostrarModalDetConvocatorias,
      actualizarMensaje, modalListaUniversidades, modalListaRequisitos, modalListaArchivos,
      mostrarModalListaUniversidades, mostrarModalListaRequisitos, mostrarModalListaArchivos } = this.props;
    let { activeStep, archivos, archivos_guardados, celular, celular_acudiente, convocatoria_seleccionada, correo_acudiente,
      correo_personal, direccion_residencia, genero, otro_genero, generos, lista_convocatorias, modal_inicio_borrador,
      modal_quitar_universidad, fecha_nacimiento, programa_academico, ubicacion_residencia, direccion_residencia_acudiente,
      errores, universidad_1, universidad_2, universidad_3, universidad_4, idioma_1, idioma_2, idioma_3, idioma_4,
      semestre_actual, nacionalidad, nombre_apellido_acudiente, paises, parentesco, programas, promedio_acumulado,
      requisitos_opciones, ubicacion_residencia_acudiente, ubicacion_universidad_1, ubicacion_universidad_2,
      ubicacion_universidad_3, ubicacion_universidad_4, universidad_seleccionada, universidades_escogidas,
      universidades_opciones, nombre_completo, lugar_residencia, lugar_residencia_acudiente, tipo_de_identificacion,
      numero_de_identificacion, correo } = this.state;
    switch (activeStep) {
      case 0:
        const configDetailUniversidades = (univ_destinos) => {
          let { universidades } = this.state;
          let universidades_opciones = [];
          universidades.map((univ) => { if (univ_destinos.includes(univ.id)) universidades_opciones.push({ "label": univ.nombre, "value": univ.valora }); });
          return universidades_opciones.length > 0 ? universidades_opciones : [{ "label": <InfoIcon />, "value": 'Esta convocatoria no cuenta con universidades todavía.' }];
        }
        const configDetailRequisitos = (req_convocatoria) => {
          let { requisitos } = this.state;
          let lista_requisitos = [];
          let i = 0;
          requisitos.map((req) => {
            if (req_convocatoria.includes(req.id)) {
              i++;
              lista_requisitos.push({ "label": i, "value": req.nombre });
            }
          });
          return lista_requisitos.length > 0 ? lista_requisitos : [{ "label": <InfoIcon />, "value": 'Esta convocatoria no cuenta con requisitos todavía.' }];
        }
        const configDetailArchivos = () => {
          let { archivos_guardados } = this.state;
          let lista_archivos = [];
          if (archivos_guardados) {
            archivos_guardados.map((archivo) => {
              let detalle = <BtnDetalle callback={() => window.open(`${archivo.adjunto}`, '_blank')} />
              lista_archivos.push({ "label": archivo.nombre, "value": detalle })
            });
          }
          return lista_archivos.length > 0 ? lista_archivos : [{ "label": <InfoIcon />, "value": 'Esta convocatoria no cuenta con archivos todavía.' }];
        }
        return (
          <>
            {
              lista_convocatorias.length === 0 ?
                <TareasTerminadas mensaje='No hay convocatorias para mostrar' marginTop='7%' imagen={emma_w} widthImg='7%' />
                : <div>
                  <ConvocatoriasListar
                    convocatorias={lista_convocatorias}
                    actualizarDataConvocatoria={actualizarDataConvocatoria}
                    mostrarModalDetConvocatorias={mostrarModalDetConvocatorias}
                    formOpciones={true}
                    opciones={false}
                    callback={(convocatoria) => this.obtenerConvocatoriaSeleccionada(convocatoria)}
                    obtenerArchivos={(id) => this.obtenerArchivos(id)}
                    mostrarModalSelConvocatoria={(resp) => this.setState({modal_inicio_borrador: resp})}
                  />
                  <ConvocatoriaDetalle
                    titulo={'¡ Detalle de Convocatoria !'}
                    convocatoria={convocatoria}
                    archivos={archivos_guardados}
                    modalDetConvocatorias={modalDetConvocatorias}
                    mostrarModalDetConvocatorias={mostrarModalDetConvocatorias}
                    mostrarModalListaUniversidades={mostrarModalListaUniversidades}
                    mostrarModalListaRequisitos={mostrarModalListaRequisitos}
                    mostrarModalListaArchivos={mostrarModalListaArchivos}
                  />
                  <Detalle
                    titulo={'¡ Lista de universidades !'}
                    open={modalListaUniversidades}
                    config={convocatoria.id !== 0 ? configDetailUniversidades(convocatoria.universidades_destino) : []}
                    cerrar={() => mostrarModalListaUniversidades(false)}
                  />
                  <Detalle
                    titulo={'¡ Lista de requisitos !'}
                    open={modalListaRequisitos}
                    config={convocatoria.id !== 0 ? configDetailRequisitos(convocatoria.requisitos) : []}
                    cerrar={() => mostrarModalListaRequisitos(false)}
                  />
                  <Detalle
                    titulo={'¡ Lista de archivos !'}
                    open={modalListaArchivos}
                    config={convocatoria.id !== 0 ? configDetailArchivos() : []}
                    cerrar={() => mostrarModalListaArchivos(false)}
                  />
                  <ConfirmarAccion
                    mensaje1={`Usted está a punto de iniciar un proceso de postulación para la convocatoria "${convocatoria.nombre_convocatoria}" `} 
                    ejecutarAccion={() => this.iniciarBorradorMovilidad()}
                    mostrarModalConfAccion={(modal_inicio_borrador) => this.setState({modal_inicio_borrador})}
                    modalConAccion={modal_inicio_borrador}
                    dato={convocatoria}
                    titulo={'¿ Desea continuar ?'}
                  />
                </div>
            }
          </>
        );
      case 1:
        return (
          <>
            <DatosPersonales
              {...{
                nombre_completo,
                tipo_de_identificacion,
                numero_de_identificacion,
                nombre_apellido_acudiente,
                genero,
                otro_genero,
                generos,
                fecha_nacimiento,
                nacionalidad,
                correo_acudiente,
                celular_acudiente,
                lugar_residencia,
                ubicacion_residencia,
                direccion_residencia,
                errores,
                parentesco,
                lugar_residencia_acudiente,
                ubicacion_residencia_acudiente,
                direccion_residencia_acudiente,
                correo,
                correo_personal,
                celular,
                onChange: this.onChange,
                onChangeDate: this.onChangeDate
              }}
            />
          </>
        );
      case 2:
        return (
          <InformacionAcademica
            {...{
              ubicacion_universidad_1,
              programa_academico,
              semestre_actual,
              promedio_acumulado,
              modal_quitar_universidad,
              errores,
              universidad_1,
              universidad_2,
              universidad_3,
              universidad_4,
              idioma_1,
              idioma_2,
              idioma_3,
              idioma_4,
              ubicacion_universidad_2,
              ubicacion_universidad_3,
              ubicacion_universidad_4,
              paises,
              programas,
              universidades_opciones,
              universidades_permitidas: convocatoria_seleccionada.cantidad_universidades,
              universidad_seleccionada,
              universidades_escogidas,
              cambiarEstado: (estados) => this.setState(estados),
              quitar_universidad_seleccionada: (id) => this.quitarUniversidadSeleccionada(id),
              onChange: this.onChange,
            }}
          />
        );
      case 3:
        return (
          <AttachComponent
            {...{
              archivos,
              actualizarMensaje,
              requisitos_opciones,
              eliminarArchivo: this.eliminarArchivo,
              agregarArchivos: this.agregarArchivos,
              submit: this.enviarDatosMovEstudiantil
            }}
          />
        )
      default:
        return <TareasTerminadas imagen={emma_w} mensaje='Esta bandeja está vacía' widthImg='10%' marginTop='10%' />
    }
  }

  // Crea la solucitud para ir guardando los datos
  iniciarBorradorMovilidad = () => {
    let { actualizarMensaje, crearBorradorMovilidad } = this.props;
    let { convocatoria_seleccionada } = this.state;
    if (convocatoria_seleccionada) {
      this.setState({ cargando: true, tit_cargando: 'Iniciando..' });
      let data = { convocatoria: convocatoria_seleccionada.id, tipo_movilidad: convocatoria_seleccionada.tipo_mov_estudiantil.id }
      crearBorradorMovilidad(
        data, 
        (resp, solicitud) => this.setState({
          activeStep: resp ? 1 : 0, 
          modal_inicio_borrador: false, 
          cargando: false, 
          tit_cargando: 'Cargando..',
          solicitud_iniciada: solicitud
        })
      );
    } else actualizarMensaje({ titulo: 'Convocatoria invalida o no seleccionada.', tipo: 'info', mostrar: true, tiempo: 6000 });
  }

  eliminarArchivo = (i) => {
    let { actualizarMensaje } = this.props;
    let { archivos } = this.state;
    archivos.splice(i, 1)
    this.setState({ archivos })
    actualizarMensaje({ titulo: `Archivo Retirado.`, tipo: 'success', mostrar: true, tiempo: 6000 })
  }

  enviarDatosMovEstudiantil = (archivos) => {
    let { actualizarMensaje, modificarBorradorMovilidad } = this.props;
    let { requisitos_opciones, solicitud_iniciada: { id }, activeStep } = this.state;
    let total = requisitos_opciones.length;
    if (archivos.length == total) {
      this.setState({ cargando: true, tit_cargando: 'Enviando..' })
      let data = { paso_actual: activeStep, total: archivos.length }
      modificarBorradorMovilidad(id, data, archivos, activeStep, (resp) => this.reiniciarEstados(resp));
    } else actualizarMensaje({ titulo: 'Debe adjuntar un archivo por cada requisito obligatorio.', tipo: 'info', mostrar: true, tiempo: 6000 });
  }

  // Convocatorias para mostrar dependiendo del tipo de solicitud.
  listaConvocatoriasParaMostrar() {
    let { convocatorias } = this.props;
    let hoy = moment(new Date()).format('YYYY-MM-DD');
    if (convocatorias) {
      let lista = [];
      convocatorias.map((conv) => (conv.fecha_inicio > hoy) && lista.push(conv));
      this.setState({ lista_convocatorias: lista });
    }
  }

  // Actualiza los datos en cada paso
  modificarDatosBorradorMovilidad = (id) => {
    let { modificarBorradorMovilidad } = this.props;
    let {
      activeStep,
      celular,
      celular_acudiente,
      correo_acudiente,
      correo_personal,
      direccion_residencia,
      direccion_residencia_acudiente,
      fecha_nacimiento,
      genero,
      otro_genero,
      nacionalidad,
      nombre_apellido_acudiente,
      parentesco,
      programa_academico,
      promedio_acumulado,
      semestre_actual,
      lugar_residencia,
      ubicacion_residencia,
      lugar_residencia_acudiente,
      ubicacion_residencia_acudiente,
      universidad_1,
      universidad_2,
      universidad_3,
      universidad_4,
    } = this.state;
    if(activeStep) {
      this.setState({ cargando: true, tit_cargando: 'Enviando..' });
      let data = {
        paso_actual: activeStep,
        fecha_nacimiento: fecha_nacimiento ? moment(fecha_nacimiento).format('YYYY-MM-DD') : null,
        genero,
        otro_genero,
        nacionalidad,
        correo_personal,
        celular,
        direccion_residencia,
        lugar_residencia,
        ubicacion_residencia,
        acudiente: nombre_apellido_acudiente,
        parentesco,
        correo_acudiente,
        celular_acudiente,
        direccion_residencia_acudiente,
        lugar_residencia_acudiente,
        ubicacion_residencia_acudiente,
        programa_academico,
        semestre_actual,
        promedio_acumulado,
        universidad_1,
        universidad_2,
        universidad_3,
        universidad_4,
      }
      modificarBorradorMovilidad(
        id,
        data,
        [],
        activeStep,
        (r) => this.setState({
          activeStep: r && activeStep + 1,
          modal_inicio_borrador: false,
          cargando: false,
          tit_cargando: 'Cargando..',
        })
      );
    }
  }

  // Obtener los datos parametros una vez seleccionada una convocatoria en el paso 1
  obtenerConvocatoriaSeleccionada(convocatoria) {
    let { universidades_destino, requisitos } = convocatoria;
    this.setState({ cargando: true });
    this.obtenerUniversidades(universidades_destino);
    this.obtenerRequisitos(requisitos);
    this.setState({ convocatoria_seleccionada: convocatoria, cargando: false });
  }

  // Cargar la ubicación de la institución desde la info guardada de esta.
  obtenerIdiomaUniversidad(nombre, valor) {
    let { universidades } = this.state;
    let idioma = universidades.find(u => u.id === valor);
    { nombre === 'universidad_1' && this.setState({ idioma_1: idioma.valord }); }
    { nombre === 'universidad_2' && this.setState({ idioma_2: idioma.valord }); }
    { nombre === 'universidad_3' && this.setState({ idioma_3: idioma.valord }); }
    { nombre === 'universidad_4' && this.setState({ idioma_4: idioma.valord }); }
    { nombre === 'ubicacion_universidad_1' && this.setState({ universidad_1: '', idioma_1: '' }); }
    { nombre === 'ubicacion_universidad_2' && this.setState({ universidad_2: '', idioma_2: '' }); }
    { nombre === 'ubicacion_universidad_3' && this.setState({ universidad_3: '', idioma_3: '' }); }
    { nombre === 'ubicacion_universidad_4' && this.setState({ universidad_4: '', idioma_4: '' }); }
  }

  // Obtener lista de requisitos de la convocatoria seleccionada.
  obtenerRequisitos(requisitos_conv) {
    let { requisitos } = this.state;
    let requisitos_opciones = [];
    requisitos.map(req => { requisitos_conv.includes(req.id) && requisitos_opciones.push(req) });
    this.setState({ requisitos_opciones });
  }

  // Obtener lista de universidades dependiendo las universidades elegibles de la convocatoria.
  obtenerUniversidades(univ_destinos) {
    let { universidades } = this.state;
    let universidades_opciones = [];
    let paises = [];
    universidades.map((univ) => {
      if (univ_destinos.includes(univ.id)) {
        universidades_opciones.push(univ);
        paises.push(univ.valora);
      }
    });
    this.setState({ universidades_opciones, paises: [...new Set(paises)] })
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    let { errores } = this.state;
    errores.length > 0 && this.setState({ errores: errores.filter(er => er.llave !== name) }); // Elimina error cuando se escribe en el campo
    this.obtenerIdiomaUniversidad(name, value);
    this.setState({ [name]: value });
  }

  onChangeDate = (name, date) => {
    let { errores } = this.state;
    errores.length > 0 && this.setState({ errores: errores.filter(er => er.llave !== name) }); // Elimina error cuando se escribe en el campo
    this.setState({ [name]: date })
  }

  reiniciarEstados(respuesta) {
    let { set_solicitud_cancelada } = this.props;
    if (respuesta) {
      this.setState({
        activeStep: 0,
        universidad_1: '',
        universidad_2: '',
        universidad_3: '',
        universidad_4: '',
        ubicacion_universidad_1: '',
        ubicacion_universidad_2: '',
        ubicacion_universidad_3: '',
        ubicacion_universidad_4: '',
        programa_academico: '',
        semestre_actual: '',
        promedio_acumulado: '',
        tit_cargando: 'Cargando..',
        cargando: false,
        nombre_apellido_acudiente: '',
        parentesco: '',
        correo_acudiente: '',
        celular_acudiente: '',
        direccion_residencia_acudiente: '',
        lugar_residencia_acudiente: '',
        ubicacion_residencia_acudiente: '',
        errores: []
      });
      set_solicitud_cancelada(false);
    } else this.setState({ cargando: false, tit_cargando: 'Cargando..' });
  }

  quitarUniversidadSeleccionada = (id) => {
    let { universidades_escogidas } = this.state;
    if (id) this.setState({
      universidades_escogidas: universidades_escogidas.filter(univ => univ.id !== id),
      universidad_seleccionada: '',
      modal_quitar_universidad: false,
    });
  }

  validarInfoAcademica(programa) {
    return new Promise(resolve => {
      consulta(`api/v1.0/internacionalizacion/solicitante/info_academica`, { programa }, 'post', (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  render() {
    let { modalAddMovEstudiantil, mostrarModalAddMovEstudiantil, mostrarModalCancelarSolicitud, set_datos_solicitud, set_solicitud_cancelada } = this.props;
    let { cargando, activeStep, tit_cargando, solicitud_iniciada } = this.state;
    const onClickDescartar = (data) => { 
      mostrarModalCancelarSolicitud(true);
      set_datos_solicitud(data);
    }
    return (
      <>
        <Dialog fullWidth={true} maxWidth={(activeStep == 1 || activeStep == 2) ? 'md' : 'sm'} open={modalAddMovEstudiantil} onClose={() => mostrarModalAddMovEstudiantil(false)}>
          <AppBarModal titulo={`¡ Postulación a Movilidad Estudiantil Saliente !`} mostrarModal={() => mostrarModalAddMovEstudiantil(false)} titulo_accion='Cerrar' accion={() => mostrarModalAddMovEstudiantil(false)} />
          <DialogContent className='scroll'>
            {
              !cargando ?
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <Stepper activeStep={activeStep}>
                      {
                        configStep.map(label => (
                          <Step key={label}>
                            <StepLabel><p>{label}</p></StepLabel>
                          </Step>
                        ))
                      }
                    </Stepper>
                  </Grid>
                  <Grid item xs={12}>
                    {this.configStepContent()}
                  </Grid>
                </Grid>
                : <TareasTerminadas mensaje={tit_cargando} marginTop='7%' imagen={emma_w} widthImg='7%' />
            }
          </DialogContent>
          <DialogActions>
            {
              activeStep === 1 &&
                <div>
                  <BtnForm texto="DESCARTAR" callback={() => onClickDescartar(solicitud_iniciada)} />
                  <BtnForm texto="SIGUIENTE" callback={() => this.handleStep(true)} />
                </div>
            }
            {
              activeStep === 2 &&
                <div>
                  <BtnForm texto="REGRESAR" callback={() => this.handleStep(false)} />
                  <BtnForm texto="SIGUIENTE" callback={() => this.handleStep(true)} />
                </div>
            }
            {
              activeStep === 3 &&
                <div>
                  <BtnForm texto="REGRESAR" callback={() => this.handleStep(false)} />
                  <BtnForm texto="FINALIZAR" callback={() => { document.getElementById('enviar_form_multi_archivo').click() }} />
                </div>
            }
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  let { modalDetConvocatorias, modalListaUniversidades, modalListaRequisitos, modalListaArchivos, convocatorias, convocatoria } = state.redInternacionalizacion;
  return { modalDetConvocatorias, modalListaUniversidades, modalListaRequisitos, modalListaArchivos, convocatorias, convocatoria }
}

const mapDispatchToProps = {
  actualizarDataConvocatorias,
  actualizarDataConvocatoria,
  mostrarModalDetConvocatorias,
  mostrarModalListaUniversidades,
  mostrarModalListaRequisitos,
  mostrarModalListaArchivos,
}

MovEstudiantilCrear.propTypes = {
  // Variables
  convocatorias: PropTypes.array.isRequired,
  convocatoria: PropTypes.object,
  modalAddMovEstudiantil: PropTypes.bool.isRequired,
  modalDetConvocatorias: PropTypes.bool.isRequired,
  modalListaUniversidades: PropTypes.bool.isRequired,
  modalListaRequisitos: PropTypes.bool.isRequired,
  modalListaArchivos: PropTypes.bool.isRequired,
  //Funciones
  actualizarDataConvocatorias: PropTypes.func.isRequired,
  actualizarDataConvocatoria: PropTypes.func.isRequired,
  mostrarModalDetConvocatorias: PropTypes.func.isRequired,
  mostrarModalListaUniversidades: PropTypes.func.isRequired,
  mostrarModalListaRequisitos: PropTypes.func.isRequired,
  mostrarModalListaArchivos: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(MovEstudiantilCrear);