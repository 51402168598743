import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Paper, Grid } from '@material-ui/core';
import { red } from '@material-ui/core/colors';


//COMPONENTES DE SISEF
import SisefTipoSolicitudes from "./sisef/SisefTipoSolicitudes";
import Sisef_Syllabus from './Sisef_Syllabus';
import { TIPOS_SISEF } from "./helper";
import { SisefMenu } from "./sisef/MenuSisef";
import Sisef_Rubrica from './Sisef_Rubrica';
import Sisef_Desempenos from './Sisef_Desempenos';
import Sisef_Gestion from './gestion/Sisef_Gestion';

//COMPONENTES GENERALES
import { mostrarModulo } from "../../redux/actions/actGlobal";
import { obtenerValoresFiltros } from "../../global/js/funciones";
import TareasTerminadas from '../general/TareasTerminadas';
import emma_w from './../../global/imagenes/emma_w.png';
import Listar_Asignaturas from './gestion/Listar_Asignaturas';
import Sisef_Seguimiento from './Sisef_Seguimiento';


class Sisef extends Component {

  constructor(props) {
    super(props)
    this.state = {
      seleccion: 'miproceso_ini',
      cargando: true,
      nuevo: true,
      mensaje: '',
      tipos: [],
      tipoSolicitud: {},
      administrador: false,
    }
    this.toggleDialog = this.toggleDialog.bind(this)
  }

  async componentDidMount() {
    let { mostrarModulo } = this.props;
    mostrarModulo({ rutas: 'mentora', nombre: 'Mentora' })
    this.obtenerDatosIniciales();
  }

  async componentDidUpdate({ }, { seleccion }) {
    let { seleccion: seleccionNew } = this.state;
    if (seleccion !== seleccionNew) {
      if (seleccionNew === 'administrar') this.setState({ nuevo: true })
    }
  }

  cambiarEstado = (estados) => {
    this.setState(estados)
  }

  cambiarOpcion = (seleccion) => {
    this.cambiarEstado({ seleccion })
  }

  toggleDialog(toggle) {
    let { modal, value } = toggle
    this.setState({ [modal]: value })
  }

  async obtenerDatosIniciales() {
    let { usuario } = this.props;
    console.log(usuario);
    let admin = false
    if (usuario && usuario.perfil && (usuario.perfil.codigo === "Per_Adm" || usuario.perfil.codigo === "coor_area_sisef")) {
      admin = true
      this.setState({ administrador: true});
    }
    this.setState({ cargando: true })
    let mensaje = "Estas en el proceso";
    let tipos = []
    if (admin){
      tipos = await obtenerValoresFiltros([{ llave: 'generica', valor: TIPOS_SISEF }, { llave: 'estado', valor: '1'}, {'llave': 'valorf', 'valor': 'gestores'}])
    }else{
      tipos = await obtenerValoresFiltros([{ llave: 'generica', valor: TIPOS_SISEF }, { llave: 'estado', valor: '1'}, {'llave': 'valore', 'valor': 'docente'}, {'llave': 'valorf', 'valor': 'gestores'}])
    }
    console.log(tipos)
    this.setState({ tipos: tipos.sort((a, b) => a.valora - b.valora), mensaje, cargando: false })
  }

  vista = () => {
    let { seleccion } = this.state
    switch (seleccion) {
      case 'miproceso_ini':
        return this.pintarOpciones();
      case 'administrar':
        return this.pintarAdministrar();
      case 'sol_syll':
        return this.pintarSyllabus();
      case 'sol_doc_rub':
        return this.pintarRubrica();
      case 'sol_desemp':
        return this.pintarDesempeño();
      case 'sol_rep_des':
        return this.pintarReportes();
      case 'sisef_seguimiento':
        return this.pintarSegumiento();
      default:
        break;
    }
  };

  pintarOpciones = () => {
    let { tipos } = this.state;

    const tipoSeleccion = tipoSolicitud => {
      this.setState({ tipoSolicitud })
      switch (tipoSolicitud.codigo) {
        case 'sol_syll':
          this.setState({ seleccion: "sol_syll" });
          break;
        case 'sol_desemp':
          this.setState({ seleccion: "sol_desemp" });
          break;
        case 'sol_doc_rub':
          this.setState({ seleccion: "sol_doc_rub" });
          break;
        case 'sol_rep_des':
          this.setState({ seleccion: "sol_rep_des" });
          break;
        case 'sisef_seguimiento':
          this.setState({ seleccion: "sisef_seguimiento" });
          break;
        default:
          break;
      }
    }
    return (
      <>
        <Grid container direction={'row'} justifyContent={'center'} style={{ paddingTop: 5 }}  >
          {tipos.length > 0 ?
            tipos.map((tipo) => (
              <Grid item xs={12} md={6} lg={4} style={{ padding: '2% 2% 0% 2%' }} key={tipo.codigo}>
                <Paper elevation={8} square onClick={() => tipoSeleccion(tipo)} >
                  <SisefTipoSolicitudes
                    avatarcss={{ backgroundColor: red[500] }}
                    data={tipo}
                    image={tipo.archivo}
                  />
                </Paper>
              </Grid>
            ))
            : <TareasTerminadas mensaje="Cargando ..." marginTop="7%" imagen={emma_w} widthImg="7%" cargando={true} />}
        </Grid>
      </>
    );
  };

  pintarAdministrar() {
    return (
      <div>
        <Sisef_Gestion></Sisef_Gestion>
      </div>
    )
  }

  pintarReportes() {
    return (
      <div>
        <Listar_Asignaturas></Listar_Asignaturas>
      </div>
    )
  }

  pintarSegumiento() {
    return (
      <div>
        <Sisef_Seguimiento></Sisef_Seguimiento>
      </div>
    )
  }

  pintarSyllabus() {
    let { tipoSolicitud } = this.state;
    return (
      <div>
        <Sisef_Syllabus
          tipoSolicitud={tipoSolicitud}
        />
      </div>
    )
  }

  pintarRubrica() {
    let { tipoSolicitud } = this.state;
    return (
      <div>
        <Sisef_Rubrica
          tipoSolicitud={tipoSolicitud}
        />
      </div>
    )
  }

  pintarDesempeño() {
    let { tipoSolicitud } = this.state;
    return (
      <div>
        <Sisef_Desempenos
          tipoSolicitud={tipoSolicitud}
        />
      </div>
    )
  }

  render() {
    let { cargando, seleccion } = this.state
    return (
      seleccion == "miproceso_ini" || seleccion == "administrar" ?
        <div>
          <SisefMenu sele={seleccion} seleccionar={(seleccion) => this.cambiarOpcion(seleccion)} />
          <div className='contenido_modulo'>
            {
              !cargando ? this.vista() :
                <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" cargando={true} />
            }
          </div>
        </div>
        : <div>
          {
            !cargando ? this.vista() :
              <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" cargando={true} />
          }
        </div>

    );
  }
}
const mapStateToProps = state => {
  let { usuario } = state.redGlobal;
  let { ModalAdd } = state.redSisef;
  return { usuario, ModalAdd }
}

const mapDispatchToProps = {
  mostrarModulo
}

Sisef.propTypes = {
  //Funciones
  ModalAdd: PropTypes.bool.isRequired,
  mostrarModulo: PropTypes.func.isRequired,
}


export default connect(mapStateToProps, mapDispatchToProps)(Sisef);