// PERMISOS GENÉRICAS PERSONAS
export const PERMISO_TIPOS = 29;
export const PERMISO_PROGRAMAS = 30;
export const PERMISO_ESTADOS = 31;
export const PERMISO_DEPENDENCIAS = 42;
export const PERMISO_DPTO = 52
// Generales
export const TIPOS_IDENTICACION = 2;
export const PROGRAMAS = 5;
export const GENEROS = 69;
// Perfil
export const PERFILES_ADMINISTRATIVOS = [ 'Per_Adm', 'Per_Int_N1' ];
export const PERFIL_ESTUDIANTE = 'Per_Nor';
export const PERFIL_DOCENTE = 'Per_Doc';
// Codigos
export const CODIGOS_DURACION_ACTIVIDADES = ['AsistenciaAEventos_tipo_actividad', 'CursoCorto_tipo_actividad', 'Clase_Doc_tipo_actividad', 'Clase_Est_tipo_actividad'];
export const CODIGOS_SUBTIPOS_ACTIVIDADES = ['Pasantia_tipo_actividad', 'Practica_tipo_actividad', 'Clase_Doc_tipo_actividad', 'Clase_Est_tipo_actividad', 'AsistenciaAEventos_tipo_actividad', 'Mision_tipo_actividad', 'CursoCorto_tipo_actividad', 'ProfesorVisitante_tipo_actividad'];
export const CODIGOS_MODALIDADES_VIRTUALES = [ 'Mod_Vir_Sin', 'Mod_Vir_Asi' ];
export const CODIGOS_MOVILIDADES_ESTUDIANTES = [ 'Mov_Est_Ent', 'Mov_Est_Sal' ];
export const CODIGO_MOV_ESTUDIANTE_ENTRANTE = 'Mov_Est_Ent';
export const CODIGO_MOV_ESTUDIANTE_SALIENTE = 'Mov_Est_Sal';
// Valores Modalidad
export const VALORES_MODALIDAD_PRESENCIAL = [ '1', '2', '5', '6' ];
export const VALORES_MODALIDAD_VIRSINCRONICA = [ '1', '3', '5', '7' ];
export const VALORES_MODALIDAD_VIRASINCRONICA = [ '1', '4', '6', '7' ];
export const VALORES_MODALIDAD_VIRTUAL = [ '1', '3', '4', '7' ];
// Codigos otros movilidades
export const CODIGO_PLATAFORMA_OTRO = 'Otra_plataforma_utilizada';
export const CODIGO_TIPO_ACTIVIDAD_OTRO = 'Otro_tipo_actividad';
export const CODIGO_SUBTIPO_ACTIVIDAD_OTRO = 'Otro_subtipo_actividad';
export const CODIGO_TIPO_CONCEPTO_OTRO = 'Otro_tipo_concepto';
/////////////
//PRODUCCION//
/////////////
export const TIPOS_CONCEPTOS = 207;
export const RANKINGS = 148;
export const ACREDITACIONES = 147;
export const FUENTES_FINANCIACION = 146;
export const UNIDADES_ADMINISTRATIVAS = 145;
export const DPTOS_ACADEMICOS = 144;
export const CATEGORIAS_CONVENIOS = 143;
export const SUBTIPOS_CONVENIOS = 142;
export const TIPOS_CONVENIOS = 141;
export const TIPOS_SOLICITUDES = 133;
export const MODALIDADES = 134;
export const SEXOS = 135;
export const UNIVERSIDADES = 136;
export const SEMESTRES = 137;
export const REQUISITOS = 138;
export const TIPOS_ACTIVIDADES = 139;
export const PLATAFORMAS = 151;
export const TIPOS_FINANCIACIONES = 152;
export const FACULTADES = 150;
export const TIPOS_MOVILIDADES = 149;
export const NIVELES_EDUCATIVOS = 153;
export const FORMATOS_REQUISITOS = 155;
export const DEPENDENCIAS_FINANCIACIONES = 156;
export const TIPOS_INSTITUCIONES = 157;
export const SUBTIPOS_ACTIVIDADES = 204;
export const PAISES= 195;
export const IDIOMAS = 223;
export const TIPO_ACTIVIDADES_CONVENIOS= 224;
export const CODIGO_TIPO_CONVENIO_OTRO=18443;
export const CODIGO_TIPO_FUENTE_OTRO =18479;
export const CODIGO_TIPO_IDIOMA_OTRO = 21759;
export const CODIGO_SUBTIPO_CONVENIO_OTRO = 18448;
export const CODIGO_ACRE_INTERNACIONAL = 18481;
export const FUENTES_FINANCIACIONES = 154;
export const ESTADOS = 140;
// Valores
export const TIPO_FINANCIACION_INSTITUCIONAL = 18504;
export const TIPO_FINANCIACION_NACIONAL = 18505;
export const TIPO_FINANCIACION_INTERNACIONAL = 18506;
export const TIPO_MOVILIDAD_ESTUDIANTES = [ 18513, 18514 ];
export const TIPO_MOVILIDAD_ESTUDIANTE_ENTRANTE = [ 18513 ];
export const TIPO_MOVILIDAD_ESTUDIANTE_SALIENTE = [ 18514 ];
export const TIPO_MOVILIDAD_PROFESORES = [ 18515, 4573 ];
export const TIPO_MOVILIDAD_PROFESOR_ENTRANTE = [ 18515 ];
export const TIPO_MOVILIDAD_PROFESOR_SALIENTE = [ 18516 ];
export const TIPO_MOVILIDAD_ENTRANTE = [ 18513, 18515 ];
export const TIPO_MOVILIDAD_SALIENTE = [ 18516, 18514 ];
export const TIPO_SOLICITUD_REGISTROS = 18376;
export const TIPOS_SOLICITUD_CONVENIOS = 18377;
export const TIPOS_ACTIVIDADES_HORAS = [ 18412, 18413, 18411 ]; //**Cursos cortos, Asistencia a eventos, Mision
export const FACULTAD_POSGRADOS = 18512;
export const DEPARTAMENTO_ADMINISTRATIVO = 18462;
export const NIVEL_EDUCATIVO_PREGRADO = 18517;
export const NIVEL_EDUCATIVO_ESPECIALIZACION = 18518;
export const NIVEL_EDUCATIVO_MAESTRIA = 18519;
export const NIVEL_EDUCATIVO_DOCTORADO = 18520;
export const TIPOS_RESPONSABLES = 206;
export const ESTADOS_CONVENIOS = 225;
export const FACULTADES_DEPENDENCIAS = 150;
export const ID_JF = 12464;


/////////////
//Emma Test//
/////////////
// export const TIPOS_CONCEPTOS = 4152;
// export const RANKINGS = 4034;
// export const ACREDITACIONES = 4035;
// export const FUENTES_FINANCIACION = 4036;
// export const UNIDADES_ADMINISTRATIVAS = 4040;
// export const DPTOS_ACADEMICOS = 4041;
// export const CATEGORIAS_CONVENIOS = 4037;
// export const SUBTIPOS_CONVENIOS = 4032;
// export const TIPOS_CONVENIOS = 4038;
// export const TIPOS_SOLICITUDES = 4039;
// export const MODALIDADES = 4044
// export const SEXOS = 4042
// export const UNIVERSIDADES = 4045
// export const SEMESTRES = 4046
// export const REQUISITOS = 4033
// export const TIPOS_ACTIVIDADES = 4043;
// export const PLATAFORMAS = 4071;
// export const TIPOS_FINANCIACIONES = 4072;
// export const FACULTADES = 4070;
// export const TIPOS_MOVILIDADES = 4069;
// export const NIVELES_EDUCATIVOS = 4087;
// export const FORMATOS_REQUISITOS = 4089;
// export const DEPENDENCIAS_FINANCIACIONES = 4090; //4246;
// export const TIPOS_INSTITUCIONES = 4091;
// export const SUBTIPOS_ACTIVIDADES = 4156;
// export const PAISES= 4102;
// export const IDIOMAS = 4161;
// export const TIPO_ACTIVIDADES_CONVENIOS= 4162;
// export const CODIGO_TIPO_CONVENIO_OTRO=4310
// export const CODIGO_TIPO_FUENTE_OTRO =4322
// export const CODIGO_TIPO_IDIOMA_OTRO = 5532
// export const CODIGO_SUBTIPO_CONVENIO_OTRO = 4315
// export const CODIGO_ACRE_INTERNACIONAL = 4324
// export const FUENTES_FINANCIACIONES = 4088; //4070; //1134;
// export const ESTADOS = 4047;
// // Valores
// export const TIPO_FINANCIACION_INSTITUCIONAL = 4499 ;
// export const TIPO_FINANCIACION_NACIONAL = 4500 ;
// export const TIPO_FINANCIACION_INTERNACIONAL = 4501 ;
// export const TIPO_MOVILIDAD_ESTUDIANTES = [ 4487, 4488 ];
// export const TIPO_MOVILIDAD_ESTUDIANTE_SALIENTE = 4488 ;
// export const TIPO_MOVILIDAD_PROFESORES = [ 4572 , 4573  ];
// export const TIPO_MOVILIDAD_ENTRANTE = [ 4487 , 4572  ];
// export const TIPO_SOLICITUD_REGISTROS = 4502;
// export const TIPOS_SOLICITUD_CONVENIOS = 4246;
// export const TIPOS_ACTIVIDADES_HORAS = [ 4279 , 4280, 4278  ]; //Cursos cortos, Asistencia a eventos, Mision
// export const FACULTAD_POSGRADOS = 4684;
// export const DEPARTAMENTO_ADMINISTRATIVO = 4683 ;
// export const NIVEL_EDUCATIVO_PREGRADO = 4685;
// export const NIVEL_EDUCATIVO_ESPECIALIZACION = 4686;
// export const NIVEL_EDUCATIVO_MAESTRIA = 4687;
// export const NIVEL_EDUCATIVO_DOCTORADO = 4688;
// export const TIPOS_RESPONSABLES = 4163;
// // Valores Modalidad
// export const MOVILIDAD_VIRTUAL = [ 5475 , 5474 ];

// /////////////
// //LOCAL emma_local_junio//
// /////////////
// export const TIPOS_CONCEPTOS = 137;
// export const RANKINGS = 121;
// export const ACREDITACIONES = 122;
// export const FUENTES_FINANCIACION = 131;
// export const UNIDADES_ADMINISTRATIVAS = 123;
// export const DPTOS_ACADEMICOS = 124;
// export const CATEGORIAS_CONVENIOS = 143;
// export const SUBTIPOS_CONVENIOS = 142;
// export const TIPOS_CONVENIOS = 141;
// export const TIPOS_SOLICITUDES = 117;
// export const MODALIDADES = 119;
// export const SEXOS = 138;
// export const UNIVERSIDADES = 125;
// export const SEMESTRES = 120;
// export const REQUISITOS = 146;
// export const TIPOS_ACTIVIDADES = 128;
// export const PLATAFORMAS = 134;
// export const TIPOS_FINANCIACIONES = 130;
// export const FACULTADES = 126;
// export const TIPOS_MOVILIDADES = 118;
// export const NIVELES_EDUCATIVOS = 127;
// export const FORMATOS_REQUISITOS = 145;
// export const DEPENDENCIAS_FINANCIACIONES = 133;
// export const TIPOS_INSTITUCIONES = 135;
// export const SUBTIPOS_ACTIVIDADES = 129;
// export const PAISES= 148;
// export const IDIOMAS = 139;
// export const TIPO_ACTIVIDADES_CONVENIOS = 144;
// export const CODIGO_TIPO_CONVENIO_OTRO = 0;
// export const CODIGO_TIPO_FUENTE_OTRO = 165;
// export const CODIGO_TIPO_IDIOMA_OTRO = 224;
// export const CODIGO_SUBTIPO_CONVENIO_OTRO = 263;
// export const CODIGO_ACRE_INTERNACIONAL = 0;
// export const FUENTES_FINANCIACIONES = 131;
// export const ESTADOS = 1251;
// // Valores
// export const TIPO_FINANCIACION_INSTITUCIONAL = 158;
// export const TIPO_FINANCIACION_NACIONAL = 159;
// export const TIPO_FINANCIACION_INTERNACIONAL = 160;
// export const TIPO_MOVILIDAD_ESTUDIANTES = [ 32, 33 ];
// export const TIPO_MOVILIDAD_ESTUDIANTE_ENTRANTE = [ 32 ];
// export const TIPO_MOVILIDAD_ESTUDIANTE_SALIENTE = [ 33 ] ;
// export const TIPO_MOVILIDAD_PROFESORES = [ 34, 35 ];
// export const TIPO_MOVILIDAD_PROFESOR_ENTRANTE = [ 34 ];
// export const TIPO_MOVILIDAD_PROFESOR_SALIENTE = [ 35 ];
// export const TIPO_MOVILIDAD_ENTRANTE = [ 32, 34 ];
// export const TIPO_MOVILIDAD_SALIENTE = [ 33, 35 ];
// export const TIPO_SOLICITUD_REGISTROS = 29;
// export const TIPOS_SOLICITUD_CONVENIOS = 30;
// export const TIPOS_ACTIVIDADES_HORAS = [ 117, 113, 123 ]; //**Cursos cortos, Asistencia a eventos, Mision
// export const FACULTAD_POSGRADOS = 108;
// export const DEPARTAMENTO_ADMINISTRATIVO = 99;
// export const NIVEL_EDUCATIVO_PREGRADO = 109;
// export const NIVEL_EDUCATIVO_ESPECIALIZACION = 110;
// export const NIVEL_EDUCATIVO_MAESTRIA = 111;
// export const NIVEL_EDUCATIVO_DOCTORADO = 112;
// export const TIPOS_RESPONSABLES = 136;