import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: "100%",
  },
  image: {
    width: '50%',
    maxWidth: '200px',
    minWidth: '100px',
  },
  content_text: {
    padding: "0px 10px 0px 10px",
  },
  content_img: {
    textAlign: 'center'
  },

  avatar: {
    backgroundColor: red[500],
  },
}));

export default function PracticasTipos({ data, image }) {
  let { nombre, descripcion, opciones } = data;
  const classes = useStyles();

  return (
    <Card className={classes.card} style={{ cursor: 'pointer' }} elevation={1}>
      <CardHeader
        avatar={<Avatar aria-label="recipe" className={classes.avatar}> {nombre.charAt(0)} </Avatar>}
        title={`${nombre}`}
        subheader={opciones}
      />
      <CardContent >
        <Grid container direction={'row'} justifyContent={'center'}>
          <Grid item xs={12} sm={2} md={2} className={classes.content_img}>
            <img src={image} className={classes.image} alt='Imagen Tipo' />
          </Grid>
          <Grid item xs={12} sm={10} md={10} className={classes.content_text}>
            <Typography variant="body2" color="textSecondary" component="p" align='justify'>
              {descripcion}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
