import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { connect } from "react-redux";
import { actualizarMensaje, mostrarModulo } from "../../../redux/actions/actGlobal";
import AppBarModal from '../../general/AppBarModal';
import { Grid, TextField } from '@material-ui/core'
import BuscarSelect from '../../general/BuscarSelect';
import {mostrarModalDetalleGrupo } from "../../../redux/actions/actRea";
import {  Dialog, DialogContent, Button, Radio} from '@material-ui/core';
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from '../../../global/imagenes/emma_w.png';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import useWindowSize from '../../general/hooks/useWindowSize';
import { consulta,obtenerPermisosValor, obtenerValoresFiltros} from "../../../global/js/funciones";
import DetalleGrupo from './detalleGrupo';



const BorderCircularProgress = withStyles(theme => ({
  circle: {
    strokeLinecap: 'round',
    strokeWidth: '2'
  },
}))(CircularProgress);

function ProgressCircle({ percent, color, text, loading }){

  const windowSize = useWindowSize();

  return (
    <Box position="relative" display="inline-flex">
      <BorderCircularProgress
        variant={!loading ?'determinate': 'indeterminate'}
        value={Math.round(percent)}
        style={{
          width: `${windowSize < 600 ? '150px' : '200px'}`,
          height: `${windowSize < 600 ? '150px' : '200px'}`,
          color: `${color}`
        }}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '70%',
          height: '70%',
          borderRadius: '50%',
          justifyContent: 'center',
          background: 'linear-gradient(145deg, #ffffff, #dadfdf)',
          boxShadow: '5px 5px 40px #c2c6c6, -5px -5px 40px #ffffff'
        }}>
          <span
            style={{
              color: '#b5b3bc',
              fontWeight: 'bold',
              fontSize: `${windowSize < 600 ? '15px' : '15px'}`
            }}
          >
            {text}
          </span>
          <span
            style={{
              fontSize: `${windowSize < 600 ? '20px' : '50px'}`,
              color: '#3F3665',
              display: 'flex',
              fontWeight: 'bold'
            }}
          >
            {percent ? `${Math.round(percent)}` : null}
            <span style={{ fontSize: '15px', alignSelf: 'center' }}>%</span>
          </span>
        </div>
      </Box>
    </Box>
  )
}


class ReaFiltroDescarga extends Component {
  constructor(props) {
    super(props) 
    this.state = {
      cargando: false,
      asignaturas:[],
      asignatura:'',
      estadistica:[],
      codigoGrupo:'',
      asimilador:0,
      acomodador:0,
      divergente:0,
      convergente:0,
      asi:0, aco:0, div:0, con:0,
      periodo:'',
      documento:'',
      loading:false,
      selectedOption:'2',
      programa:'',
      facultad:'',
      consultaPrograma:[],
    }
    this.onValueChange = this.onValueChange.bind(this);
  } 
 
  async obtenerEstadistica(codigo) {
    this.setState({loading:true})
    let f= codigo
     return new Promise(resolve => {
        consulta(`api/v1.0/rea/list/estadisticaDocentes/${f}?`, null, null, (error, estado, resp) => {
           resolve(estado === 200 && !error ? resp : null);
       })
     })
   }
   async obtenerEstadisticaPrograma(f) {
    this.setState({loading:true})
     return new Promise(resolve => {
        consulta(`api/v1.0/rea/list/estadisticaGestores/${f}?`, null, null, (error, estado, resp) => {
           resolve(estado === 200 && !error ? resp : null);
       })
     })
   }
 
   async obtenerResultados(periodo) {
    let{documento}= this.state
     return new Promise(resolve => {
        consulta(`api/v1.0/rea/list/consultaSicuc/${periodo}/gestion/${documento}?`, null, null, (error, estado, resp) => {
           resolve(estado === 200 && !error ? resp : null);
       })
     })
   }
 
   resetDatos(){
     this.setState({
       asignaturas:[],
       asignatura:'',
       estadistica:[],
       codigoGrupo:'',
       asimilador:0,
       acomodador:0,
       divergente:0,
       convergente:0,
     })
   }
  

  async buscarAsignaturas(){
    this.resetDatos()
    let{periodo, documento}= this.state
    let {actualizarMensaje } = this.props;
    if(periodo === '' || documento === ''){
      actualizarMensaje({ titulo: 'Digite los campos obligatorio', tipo: 'info', mostrar: true, tiempo: 6000 });
     }else{
    this.setState({cargando:true})
    let respuestaAsignaturas = await this.obtenerResultados(periodo)
    let asignaturas = this.transformar(respuestaAsignaturas.respuesta)
    this.setState({asignaturas, cargando:false})
     }
  }

   transformar = (data, id = 'id', nombre = 'nombre', programa= 'programa') => {
    return data.map((e) => ({
      value: e[id],
      label: e[nombre],
      programa:e[programa]
    }));
  };


  async buscar(){
    let{codigoGrupo} = this.state
    this.setState({asimilador:0, acomodador:0, divergente:0, convergente:0})
    let {actualizarMensaje } = this.props;
      if(codigoGrupo === ''){
        actualizarMensaje({ titulo: 'Debe escribir el ID del grupo', tipo: 'info', mostrar: true, tiempo: 6000 }); 
        this.setState({cargando:false})
      }else{
        let estadistica = await this.obtenerEstadistica(codigoGrupo) 
        this.setState({estadistica, asimilador:0, acomodador:0, divergente:0, convergente:0,
           programa:'', facultad:'' })
        this.calcularPorcentajes()
      }
  }

  onChange = ({ target }) => {// evento que escucha los cambios del formulario y asigna el valor al estado de la variable
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }
  async cambiarEstadoPrograma(estados){
    let {consultaPrograma}= this.state
    this.setState(estados)
    consultaPrograma.push(estados.programa.codigo)
    let estadistica = await this.obtenerEstadisticaPrograma(consultaPrograma)
    this.setState({estadistica, asimilador:0, acomodador:0, divergente:0, convergente:0, asignatura:'',
     consultaPrograma:[], facultad:'' })
    this.calcularPorcentajes()
  }
  
  async cambiarEstadoFacultad(estados){
    let {consultaPrograma}= this.state
    this.setState(estados)
    let programasFacultad = await obtenerPermisosValor(estados.facultad.value)
    programasFacultad.forEach(function(elemento) {
      consultaPrograma.push(elemento.secundario.codigo)
    })
    let estadistica = await this.obtenerEstadisticaPrograma(consultaPrograma)
    this.setState({estadistica, asimilador:0, acomodador:0, divergente:0, convergente:0, asignatura:'',
    consultaPrograma:[], programa:'' })
    this.calcularPorcentajes()
  }

 async cambiarEstado(estados){
    this.setState(estados)
    let estadistica = await this.obtenerEstadistica(estados.asignatura.value) 
    this.setState({estadistica, asimilador:0, acomodador:0, divergente:0, convergente:0 })
    this.calcularPorcentajes()
  }
  calcularPorcentajes(){
    let {asimilador, acomodador, divergente, convergente, asi, aco, div, con, estadistica}= this.state;
   if(estadistica){
    estadistica.forEach(function(elemento ) {
      if(elemento.tipo_aprendizaje.codigo === 'rea_divergente')
      divergente = divergente + 1
      if(elemento.tipo_aprendizaje.codigo === 'rea_acomodador')
      acomodador = acomodador + 1
      if(elemento.tipo_aprendizaje.codigo === 'rea_asimilador')
      asimilador = asimilador + 1
      if(elemento.tipo_aprendizaje.codigo === 'rea_convergente')
      convergente = convergente + 1
    })
    asi=asimilador
    aco=acomodador
    div=divergente
    con=convergente
    asimilador=(asimilador/estadistica.length) * 100
    acomodador=(acomodador/estadistica.length) * 100
    divergente=(divergente/estadistica.length) * 100
    convergente=(convergente/estadistica.length) * 100
    this.setState({asimilador, acomodador, divergente, convergente, loading: false, asi, aco, div, con})
   }
  }

  onValueChange(event) {
    this.setState({
      selectedOption: event.target.value
    });
  }
  pintarConsultas(){
    let { asignaturas, asignatura, asimilador, acomodador, convergente, divergente, loading, 
      codigoGrupo, periodo, documento, cargando, estadistica, selectedOption, programa, facultad} = this.state;

    let {mostrarModalDetalleGrupo, programas, facultades } = this.props;
    return(
      <>
        <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <label style={{marginLeft:'20px'}}>
            <Radio
              type="radio"
              value="1"
              checked={this.state.selectedOption === "1"}
              onChange={this.onValueChange}
             
            />
            Buscar por ID
          </label>
          <label style={{marginLeft:'20px'}}>
            <Radio
              type="radio"
              value="2"
              checked={this.state.selectedOption === "2"}
              onChange={this.onValueChange}
              
            />
            Buscar por Asignatura
          </label>
          <label style={{marginLeft:'20px'}}>
            <Radio
              type="radio"
              value="3"
              checked={this.state.selectedOption === "3"}
              onChange={this.onValueChange}
              
            />
            Reporte por Programa
          </label>
          <label style={{marginLeft:'20px'}}>
            <Radio
              type="radio"
              value="4"
              checked={this.state.selectedOption === "4"}
              onChange={this.onValueChange}
              
            />
            Reporte por Departamento
          </label>
        </Grid>
        {selectedOption === '2' && (<>
          <Grid item xs={6} >
              <TextField
                value={periodo}
                required
                size="small"
                type="text"
                id="periodo"
                name="periodo"
                label="Periodo"
                fullWidth
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={6} >
              <TextField
                value={documento}
                required
                size="small"
                type="number"
                id="documento"
                name="documento"
                label="Identificacion"
                fullWidth
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="outlined" size='large' color='primary' onClick={() => this.buscarAsignaturas() }>Ver Asignaturas</Button>
            </Grid>
            <Grid item xs={12}>
              <BuscarSelect datos={asignaturas} cambiarEstado={(value) => this.cambiarEstado({ asignatura: value })} valor={asignatura} id='select-asigantura' nombre='Asignatura' placeholder='Seleccione Asignatura' />
            </Grid>
            </>
            )}
            {selectedOption === '1' && (<>
            <Grid item xs={6} >
              <TextField
                value={codigoGrupo}
                type="number"
                id="codigoGrupo"
                name="codigoGrupo"
                label="ID Grupo"
                size="small"
                fullWidth
                onChange={this.onChange}
              />
            </Grid>
          
            <Grid item xs={6}>
              <Button variant="outlined" color='primary' size="large" onClick={() => this.buscar()}>Buscar</Button>
            </Grid>
            </>
            )}
             {selectedOption === '3' && (<>
            <Grid item xs={12}>
              <BuscarSelect datos={programas} cambiarEstado={(value) => this.cambiarEstadoPrograma({ programa: value })} valor={programa} id='select-programa' nombre='Programa' placeholder='Seleccione Programa' />
            </Grid>
            </>
            )}
             {selectedOption === '4' && (<>
            <Grid item xs={12}>
              <BuscarSelect datos={facultades} cambiarEstado={(value) => this.cambiarEstadoFacultad({ facultad: value })} valor={facultad} id='select-facultad' nombre='Departamento' placeholder='Seleccione Departamento' />
            </Grid>
            </>
            )}
        </Grid>
        <Grid container direction={'row'} justifyContent={'center'} >
        {!cargando && <>
          <ProgressCircle
          percent={asimilador}
          loading={loading}
          color='#FF5733'
          text='Asimilador' 
          />
          <ProgressCircle
          percent={convergente}
          loading={loading}
          color='#3399FF'
          text='Convergente' 
          />
          <ProgressCircle
          percent={divergente}
          loading={loading}
          color='#73E237'
          text='Divergente' 
          />
          <ProgressCircle
          percent={acomodador}
          loading={loading}
          text='Acomodador' 
          />
        </>
        }
        {cargando && <TareasTerminadas mensaje='Cargando....' marginTop='7%' imagen={emma_w} widthImg="13%" />}
        {estadistica.length > 0 &&
        <Grid container direction={'row'} justifyContent={'center'} style={{ marginTop: 10 }}>
          <Grid item xs={4}>
            <Button variant="contained" color='primary' size="large" style={{color:'white'}} onClick={() => mostrarModalDetalleGrupo(true)}>Ver</Button>
          </Grid>
        </Grid>
        }
        </Grid>
      </>
    )
  }
  render() {
    let { actualizarMensaje,  modalDetalleGrupo, mostrarModalDetalleGrupo, modalFiltroDescarga, mostrarModalFiltroReaDescarga} = this.props;
    let { estadistica, asignatura, codigoGrupo, asi, aco, div, con, programa, facultad } = this.state
    return (
      <>
     <Dialog fullWidth={true} maxWidth="md" open={modalFiltroDescarga} onClose={() => mostrarModalFiltroReaDescarga(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <AppBarModal titulo='¡ Descargar Informe !' mostrarModal={mostrarModalFiltroReaDescarga} titulo_accion="Limpiar" accion={this.resetState} />
        <DialogContent className='scroll'>
        <Grid container >
         <Grid item xs={12} md={12}>
           {this.pintarConsultas()}
         </Grid>
        
         <DetalleGrupo 
          actualizarMensaje={actualizarMensaje} 
          modalDetalleGrupo={modalDetalleGrupo} 
          estadistica={estadistica}
          mostrarModalDetalleGrupo={mostrarModalDetalleGrupo} 
          nombrePrograma={asignatura? asignatura.programa: programa.label}
          nombreAsignatura={asignatura.label}
          nombreFacultad={facultad? facultad.label:''} 
          grupo={codigoGrupo? codigoGrupo:asignatura.value}
          descargar={true}
          asimilador={asi}
          acomodador={aco}
          divergente={div}
          convergente={con}
        /> 
        </Grid>
        </DialogContent>
      </Dialog >
      </>
    )
  }
}
//Props del componente
ReaFiltroDescarga.propTypes = {
  modalFiltroDescarga: PropTypes.bool.isRequired,
  mostrarModalFiltroReaDescarga: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => { 
  let { usuario } = state.redGlobal;
  let { modalDetalleGrupo } = state.redRea
  return {
    usuario,
    modalDetalleGrupo,
  }
};

//CARGO FUNCIONES DEL MODULO
const mapDispatchToProps = {
  actualizarMensaje,
  mostrarModulo,
  mostrarModalDetalleGrupo,

}

export default connect(mapStateToProps, mapDispatchToProps)(ReaFiltroDescarga);
