import React, { Component } from 'react';
import { Dialog, DialogContent, DialogActions } from '@material-ui/core';
import PropTypes from 'prop-types';
import AppBarModal from '../../general/AppBarModal';
import { obtenerValoresFiltros } from '../../../global/js/funciones';
import TareasTerminadas from '../../general/TareasTerminadas';
import emma_w from '../../../global/imagenes/emma_w.png';
import ListarDatos from '../../general/ListarDatos';
import { BtnCancelar, BtnForm, BtnSeleccion } from '../../general/BotonesAccion';
import { RANKINGS } from '../administrar/helper';

class ConveniosBuscarRanking extends Component {
  constructor(props) {
    super(props)
    this.state = {
      posicion: '',
      rankings: [],
      cargando: false
    }
  }

  async componentDidUpdate({ modalBuscarRanking }) {
    let { modalBuscarRanking: modalIniActaNew } = this.props
    if (modalIniActaNew !== modalBuscarRanking) {
      this.setState({ cargando: true })
      let rankings = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': RANKINGS }])
      this.setState({ rankings, cargando: false })
    }
  }

  onChange = ({ target }) => {
    let { value, name } = target
    
    this.setState({
      [name]: value
    })
  }

  acciones = data => {
    let { mostrarModalAddPosicion, seleccionadas } = this.props;

    const onClickSeleccionar = (data) => {
      mostrarModalAddPosicion(true);
      this.props.seleccionar(data);
    }
    const onClickQuitar = (data) => {
      this.props.quitar(data)
    }

    return (
      seleccionadas.map(r => r.id).includes(data.id) ? <BtnCancelar callback = { () => onClickQuitar(data) } />
        : <BtnSeleccion callback = { () => onClickSeleccionar(data) } />
    )
  }

  render () {
    let { cargando, rankings } = this.state;
    let { modalBuscarRanking, mostrarModalBuscarRanking } = this.props;

    return (
      <Dialog open = { modalBuscarRanking } onClose = { mostrarModalBuscarRanking.bind(this, false) } aria-labelledby = 'form-dialog-title' fullWidth = { true } maxWidth = 'sm'>
        <AppBarModal titulo = {'¡ Lista de Rankings !'} mostrarModal = { mostrarModalBuscarRanking } titulo_accion = '' accion = '' />
        <DialogContent className = 'scroll' style = { { padding: 0 } }>
          { 
            cargando ? <TareasTerminadas mensaje = 'Cargando..' marginTop = '7%' imagen = { emma_w } widthImg = '7%' /> :
              <ListarDatos 
                datos = { rankings }
                titulo = 'Lista de Rankings'
                opciones = { true }
                buscar = { true }
                sub_titulo = 'Rankings'
                id = 'tbl_rankings_seleccion'
                acciones = { row => this.acciones(row) }
                fila_principal = 'nombre'
                filas = { [
                  { 'id': 'nombre' }
                ] }
              />
          }
        </DialogContent>
        <DialogActions>
          <BtnForm texto = 'CERRAR' callback = { () => mostrarModalBuscarRanking(false) } />
        </DialogActions>
      </Dialog>
    )
  }
}

ConveniosBuscarRanking.propTypes = {
  modalBuscarRanking: PropTypes.bool.isRequired,
  mostrarModalBuscarRanking: PropTypes.func.isRequired
}

export default ConveniosBuscarRanking;

