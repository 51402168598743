import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {FormControl,TextField,DialogActions,Grid,Dialog,DialogContent,ListSubheader,List,ListItem,ListItemIcon,ListItemText,Checkbox,Select,MenuItem,FormHelperText,IconButton,withStyles,
} from "@material-ui/core";
import { BtnForm } from "../../general/BotonesAccion";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmarAccion from "../../general/ConfirmarAccion";
import AppBarModal from "../../general/AppBarModal";
import { obtenerEstadosProceso, getError } from "../../../global/js/funciones";
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from "../../../global/imagenes/emma_w.png";
import { ESTADOS } from "../Helpers";
import BuscarAsignatura from "./BuscarAsignatura";
// import { MenuGestionar } from "./MenuBecas";
import { coloresEmma } from "../../../global/js/funciones";
const useStyles = (() => ({
  root: {
    width: "100%",
    // backgroundColor: theme.palette.background.paper,
  },
  colorEmmma: {
    color: coloresEmma.secondarycolor,
    transition: "all 0.3s",
    "&:hover": {
      color: "#fff",
      backgroundColor: coloresEmma.secondarycolor,
      transform: "scale(1.3)",
    },
  },
  colorDelete: {
    color: "#d11a2a",
    transition: "all 0.3s",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#d11a2a",
      transform: "scale(1.3)",
    },
  },
}));

function RenderEstados({ estados_siguiente, estado, cambiarSeleccion }) {
  return (
    <List
      className={useStyles.root}
      subheader={
        <ListSubheader component="div">Seleccione Estado</ListSubheader>
      }
    >
      {estados_siguiente.map(({ secundario }) => {
        let { codigo, nombre, valore } = secundario;
        return (
          <ListItem
            key={codigo}
            role={undefined}
            dense
            button
            onClick={() => cambiarSeleccion({ codigo, valore })}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={estado === codigo}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText id={codigo} primary={nombre} />
          </ListItem>
        );
      })}
    </List>
  );
}

class GestionarInvitadosVirtual extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cargando: true,
      titulo_res: "Cargando..",
      estado: { codigo: "", valore: "" },
      motivo: "",
      estados_siguiente: [],
      seleccion: "gestion",
      asignaturas: [],
      asignatura_seleccionada: '',
      modal_buscar_asignatura: false,
      modal_quitar_asignatura: false,
      motivo: ''
    };
  }

  async componentDidUpdate({ mostrarModalGestion }, { estado }) {
    let { mostrarModalGestion: mostrarModalGestionNew, solicitud } = this.props;
    let { estado: estadoNew } = this.state;
    if (estadoNew.codigo !== estado.codigo) this.setState({ motivo: "" });
    if (mostrarModalGestion !== mostrarModalGestionNew) {
      if (mostrarModalGestionNew) {
        this.reiniciarEstado();
        let estados_siguiente = await obtenerEstadosProceso(solicitud.estado_solicitud.id, [
          { llave: "principal", valor: solicitud.estado_solicitud.id },
          { llave: "secundario__generica", valor: ESTADOS },
        ]);
        this.setState({
          estados_siguiente,
          cargando: false,
        });
      }
    }
  }

  reiniciarEstado() {
    this.setState({
      cargando: true,
      titulo_res: "Cargando..",
      estado: { codigo: "", valore: "" },
      motivo: "",
      estados_siguiente: [],
      asignaturas: [],
      seleccion: "gestion",
      
    });
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value,
    });
  };


  onSubmit = async (e) => {
    let { estado, asignaturas, motivo } = this.state;
    let { solicitud, gestionarInvitado, accion } = this.props;
    this.setState({ cargando: true, titulo_res: "Enviando.." });
    let data = { estado: estado.codigo, asignaturas, motivo };
    await gestionarInvitado(solicitud.id, data, () =>
      this.setState({ cargando: false, titulo_res: "Cargando.." })
    );
    // accion(estado.codigo);
    // accion("Est_Ini");
    // ActualizarInfo([ { llave: "estado_solicitud", valor: accion }, { llave: "periodo", valor: periodo_filtro }, ], tipoSolicitud);
    e.preventDefault();
  };

  pintarMostrarAsignaturas() {
    const { classes } = this.props;
    let {  asignaturas, asignatura_seleccionada, modal_buscar_asignatura, modal_quitar_asignatura } = this.state;
    let seleccionada = asignatura_seleccionada ? asignaturas.find(asign => asign.id === asignatura_seleccionada) : {id:0};
    const onClickEliminar = (data) => {
      if (data) this.setState({ modal_quitar_asignatura: true });
    };
    const quitarAsignaturaSeleccionada = (id) => {
      if (id) {
        this.setState({
          asignaturas: asignaturas.filter((inst) => inst.id !== id),
          asignatura_seleccionada: "",
          modal_quitar_asignatura: false,
        });
      }
    };
    // let {  solicitud } = this.props;
    return (
      <Grid container spacing={2}>
        <Grid item>
          <FormControl className='form-control' required>
            <Select  id = 'asignatura_seleccionada' name = 'asignatura_seleccionada' value = { asignatura_seleccionada } fullWidth displayEmpty onChange = { this.onChange }>
              <MenuItem value = "">
                <em>{ `${asignaturas.length} Asignatura(s) seleccionada(s)` }</em>
              </MenuItem>
              { asignaturas.map((inst) => <MenuItem key= { inst.id } value = { inst.id }>{ inst.nombre.toUpperCase() }</MenuItem>) }
            </Select>
          </FormControl>
          <FormHelperText></FormHelperText>
        </Grid>
        <Grid >
          <BuscarAsignatura
          seleccionar = { ({ id, nombre }) => { this.setState({ asignaturas: asignaturas.concat({ id: id, nombre: nombre }) }) } }
          quitar = { ({ id }) => { this.setState({ asignaturas: asignaturas.filter(asign => asign.id !== id) })} }
          seleccionadas = { asignaturas }
          modalBuscarAsignatura = { modal_buscar_asignatura }
          mostrarModalBuscar = { (modal_buscar_asignatura) => this.setState({ modal_buscar_asignatura }) }
          />
          <ConfirmarAccion
            mensaje1 = { asignatura_seleccionada && `Se quitará ${seleccionada.nombre} de la lista, ` }
            ejecutarAccion = { quitarAsignaturaSeleccionada }
            mostrarModalConfAccion = { (modal_quitar_asignatura) => this.setState({ modal_quitar_asignatura }) }
            modalConAccion = { modal_quitar_asignatura }
            dato = { asignatura_seleccionada ? seleccionada : { id: 0 } }
            titulo = { '¿ Quitar Asignatura ?' }
          />
          <IconButton aria-label = "add"  className = { classes.colorEmmma } onClick = { () => this.setState({ modal_buscar_asignatura: true }) }>
            <AddIcon />
          </IconButton>
          <IconButton aria-label = "delete" className = { classes.colorDelete } onClick = { () => onClickEliminar(asignatura_seleccionada) }>
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  }

  pintarEstados() {
    let { estado, estados_siguiente, motivo } = this.state;
    // let {  solicitud } = this.props;
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
          <RenderEstados
            cambiarSeleccion={(estado) => this.setState({ estado })}
            estado={estado.codigo}
            estados_siguiente={estados_siguiente}
          />
          {estado.codigo === "Est_IVir_Asig_Cursar" ? this.pintarMostrarAsignaturas() : ""}

          <Grid item xs={12} md={12}>
              <FormControl className="form-control">
                <TextField
                  value={motivo}
                  id="txtMotivoNegado"
                  label={
                    estado.codigo === "Est_IVir_Neg"
                      ? "Motivo Negado"
                      : "Observaciones"
                  }
                  name="motivo"
                  placeholder={
                    estado.codigo === "Est_IVir_Neg"
                      ? "Escriba el motivo de negacion de la solicitud"
                      : "Escriba Observaciones"
                  }
                  multiline
                  onChange={this.onChange}
                />
              </FormControl>
            </Grid>
        </Grid>
      </Grid>
    );
  }

  pintar() {
    let { seleccion } = this.state;
    return <div>{seleccion === "gestion" && this.pintarEstados()}</div>;
  }

  render() {
    let { mostrarModalGestion, ocultarModalGestion } = this.props;
    let { cargando, titulo_res, seleccion } = this.state;
    return (
      <Dialog fullWidth={true} maxWidth="sm" open={mostrarModalGestion} onClose={ocultarModalGestion.bind(this, false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
        <AppBarModal titulo={"¿ Gestionar solicitud ?"} mostrarModal={ocultarModalGestion} titulo_accion=""  accion="" />
        {/* <MenuGestionar
          seleccionar={(seleccion) => this.setState({ seleccion })}
          sele={seleccion}
        /> */}
        <DialogContent style={{ padding: "1" }} className="scroll">
          {cargando ? (
            <TareasTerminadas
              mensaje={titulo_res}
              marginTop="7%"
              imagen={emma_w}
              widthImg="7%"
            />
          ) : (
            <div style={{ padding: "5px 0 5px 0" }}>{this.pintar()}</div>
          )}
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CERRAR" callback={() => ocultarModalGestion(false)} />
          {!cargando && <BtnForm texto="ACEPTAR" callback={this.onSubmit} />}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(useStyles)(GestionarInvitadosVirtual);
