import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { coloresEmma } from '../../../global/js/funciones';
const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    margin: "auto",
    textAlign: "center",
  },
  buttonProgress: {
    color: coloresEmma.secondarycolor,
  },
  Typography: {
    color: '#9e9e9e',
  },
});


export default function Cargando({ width = "80%", marginTop = "300px", texto = "Cargando..." }) {
  const classes = useStyles();
  return (
    <div className="Container">
    <div className={classes.root} style={{ width, marginTop, }}>
      <CircularProgress disableShrink className={classes.buttonProgress} />
      <Typography variant="h6" component="h2" className={classes.Typography}>
        {texto}
      </Typography>
    </div>
    </div>
  )

}

