import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { BtnForm, BtnSeleccion } from "../BotonesAccion";
import AppBarModal from '../AppBarModal'
import moment from 'moment'
import { actualizarDataMensajes, actualizarMensaje, mostrarModalMensajes } from "../../../redux/actions/actGlobal";
import { consulta, mostrarError } from "../../../global/js/funciones";
import ListarDatos from "../../general/ListarDatos";

class Mensajes extends Component {

  componentDidMount() {
    this.obtenerDataMensajes();
  }

  componentDidUpdate({ modalMensajes }) {
    let { modalMensajes: modalMensajesNew } = this.props;
    if (modalMensajes !== modalMensajesNew && modalMensajesNew) {
      this.obtenerDataMensajes();
    }
  }
  acciones = (data) => {
    const onClickSeleccionar = ({ id }) => {
      this.gestionarMensaje(id);
    }

    return <BtnSeleccion callback={() => onClickSeleccionar(data)} texto='Leído' />;
  }


  obtenerDataMensajes = () => {
    consulta(`api/v1.0/comentarios?leido=0`, null, null,
      (error, estado, resp) => {
        this.props.actualizarDataMensajes(resp);
      }
    )
  }

  gestionarMensaje = (id, data = { leido: 1 }) => {
    let { actualizarMensaje } = this.props;
    consulta(`api/v1.0/comentarios/${id}`, data, "patch", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          this.obtenerDataMensajes();
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp)
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    }
    );
  }

  render() {

    let { mostrarModalMensajes, modalMensajes, mensajes } = this.props;
    return (
      <Dialog fullWidth={true} maxWidth="sm" open={modalMensajes} onClose={() => mostrarModalMensajes(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <AppBarModal titulo='Mensajes Pendientes' mostrarModal={mostrarModalMensajes} titulo_accion="" accion='' />
        <DialogContent className='scroll' style={{ padding: 0 }}>
          <ListarDatos
            datos={mensajes}
            id='tbl_mensajes'
            css={{}}
            acciones={(row) => this.acciones(row)}
            fila_principal='mensaje'
            filas={[
              { 'id': 'usuario_registro', 'mostrar': ({ usuario_registro }) => `${usuario_registro.primer_nombre} ${usuario_registro.primer_apellido} ${usuario_registro.segundo_apellido}` },
              { 'id': 'fecha_registro', 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a') },
            ]}
          />
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CERRAR" callback={() => mostrarModalMensajes(false)} />
        </DialogActions>
      </Dialog>

    )
  }
}

//CARGO ESTADOS DEL MODULO
const mapStateToProps = (state) => {
  let { mensajes, modalMensajes } = state.redGlobal;
  return {
    mensajes,
    modalMensajes
  }
};

//CARGO FUNCIONES DEL MODULO
const mapDispatchToProps = {
  mostrarModalMensajes,
  actualizarMensaje,
  actualizarDataMensajes
}


Mensajes.propTypes = {
  modalMensajes: PropTypes.bool.isRequired,
  mostrarModalMensajes: PropTypes.func.isRequired,
  mensajes: PropTypes.array.isRequired,
}


export default connect(mapStateToProps, mapDispatchToProps)(Mensajes);

