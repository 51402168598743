import React, { Component, useState  } from 'react';
import { BrowserRouter as Brouter } from 'react-router-dom'
import { Provider } from "react-redux";
import store from "../redux/store";
import "../global/css/App.css";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { EstiloApp } from "./general/EstiloApp";
import Principal from "./general/app/Principal";
//MENSAJE
import Mensajes from './general/Mensajes'
import Question from './general/BotonQuestion'
import ReactNotifications from 'react-notifications-component';

class App extends Component {

  render() {
    return (
      <Provider store={store}>
        <ReactNotifications />

        <Brouter>
          <MuiThemeProvider theme={EstiloApp}>
            <div id='fondo_pantalla'>
              <Principal/>
            </div>
            <Question setbBtnPreguntas={(btnPreguntas)=> this.setState({btnPreguntas})}/>
            <Mensajes />
          </MuiThemeProvider>
        </Brouter>
      </Provider>
    );
  }
}


export default (App);

