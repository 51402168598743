// HELPERS
// export const MOTIVOS_NEGACION = 35
// export const ESTADOS = 31
// export const REQUISITOS =  34

// HELPERS PRODUCCION

export const MOTIVOS_NEGACION = 77 
export const ESTADOS = 74
export const REQUISITOS =  76
export const CARRERAS = 5;
