import React from 'react'
import PropTypes from 'prop-types'
import { rutaImg, consulta } from "../../global/js/funciones";
import { BtnSeleccion } from "./BotonesAccion";
import ListarDatos from './ListarDatos';


class BuscarColegio extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      dato_buscar: '',
      cargando: false,
      colegios: [],
    }
  }

  componentDidUpdate(prevProps,prevState) {
    if (this.state.dato_buscar !== prevState.dato_buscar) {
      this.obtenerColegios(this.state.dato_buscar, this.props.buscarColegio)
    }
  }

  obtenerColegios = (dato) => {
    this.setState({ cargando: true })
    if (dato.length >= 2) {
      consulta("api/v1.0/colegio/buscar", { dato}, "post",
        (error, estado, resp) => {
          if (estado === 200) {
            this.setState({ cargando: false, colegios: resp })
          }
        }
      );
    } this.setState({ cargando: false, colegios: [] });
  }

  render() {
    const actions = (rowData) => {
      let { callback} = this.props;
      let mostrarCheck = true;
      const seleccionar = (data) => {
        callback(data);
      }
      let resp = mostrarCheck ? <BtnSeleccion callback={() => seleccionar(rowData)} /> : '';
      return resp;
    }

    let { dato_buscar, colegios, cargando } = this.state;
    let { mensajeVacio } = this.props;
    return (
      <>
        <ListarDatos
          id='tbl_listar_colegios'
          titulo={'Lista Colegios'}
          datos={colegios}
          opciones={true}
          buscar={true}
          cargando={cargando}
          mensajeVacio={mensajeVacio ? mensajeVacio : 'Esta bandeja esta vacía, ingrese dato a buscar'}
          avatarImg={true}
          css={{
            list: { padding: '0px 0px 20px 0px' },
            appBar: { padding: '0px 0px 0px 0px' },
          }}
          actBuscar={(dato_buscar) => this.setState({ dato_buscar })}
          datoBuscarAlt={dato_buscar}
          acciones={(row) => actions(row)}
          fila_principal={({ nombreColegio }) => nombreColegio.toUpperCase()}
          filas={[
            { 'id': 'ciudad', 'enLista': true, 'mostrar': ({ ciudad, departamento }) => ciudad && departamento ? `${departamento.nombre}, ${ciudad.nombre}` : '' },
          ]}
        />
      </>
    )
  }


}

BuscarColegio.propTypes = {
  callback: PropTypes.func.isRequired,
  buscarColegio: PropTypes.string,

}

BuscarColegio.defaultProps = {
  buscarColegio: '',

}

export default BuscarColegio;