import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import { generarFiltros, api, coloresEmma } from "../../../global/js/funciones";
import fileDownload from 'js-file-download';
import moment from 'moment'
import { saveAs } from "file-saver";
import ListarDatos from "../../general/ListarDatos";
import { Checkbox } from '@material-ui/core';
let JSZip = require("jszip");

class AdjuntosListar extends Component {

  seleccionar = value => {
    let { adjSeleccionados, actualizarDataAdjSeleccionados } = this.props;
    let sele = adjSeleccionados;
    const seleccionado = sele.indexOf(value);
    if (seleccionado === -1) sele.push(value);
    else sele.splice(seleccionado, 1);
    actualizarDataAdjSeleccionados(sele);
    this.setState({ adjSeleccionados })
  }



  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  obtenerColor = (codigo, valorReq) => {
    if( codigo === '1' && valorReq == '1' ) codigo = '-1'
    let color = 'white';
    const colores = {
      '0': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
      '1': { 'backgroundColor': coloresEmma.solicitudcancelada, color },
      '2': { 'backgroundColor': coloresEmma.solicitudfinalizada, color },
      '3': { 'backgroundColor': coloresEmma.ReemplazaroReenviar, color },
      '-1': { 'backgroundColor': coloresEmma.ReemplazaroReenviar, color }
    }
    return (colores[codigo])
  }

  obtenerTitulo = (codigo) => {
    const titulos = {
      '0': 'Aprobar, Negar o Remplazar',
      '1': 'Negar Archivos',
      '2': 'Aprobar Archivos',
    }
    return (titulos[codigo])
  }

  obtenerEstadoAdj = (codigo, valorReq) => {
    if( codigo === '1' && valorReq == '1' ) codigo = '-1'
    const titulos = {
      '0': 'Enviado',
      '1': 'Negado',
      '2': 'Aprobado',
      '3': 'Remplazado',
      '4': 'Pendiente',
      '-1': 'Reenviado'
    }
    return (titulos[codigo])
  }

  descargarMasivo = async (archivos) => {
    let { nombreZip, id_sol } = this.props
    // let zip = new JSZip();
    // const urlToPromise = url => Axios.get(url, { responseType: 'blob'}).then(res => res.data)

    // archivos.forEach((req) => {
    //   let extension = (/[.]/.exec(req.archivo)) ? /[^.]+$/.exec(req.archivo)[0] : undefined;
    //   let nombre_archivo = req.requisito.nombre +'.'+extension
    //   zip.file(`${nombreZip}/${nombre_archivo}`, urlToPromise(req.archivo), { binary: true });
    // });

    // zip.generateAsync({ type: 'blob' }).then(function (content) {
    //   saveAs(content, `${nombreZip}.zip`);
    // });
    const filtros = [{ 'llave': 'id_in', 'valor': id_sol }];
    const f = await generarFiltros(filtros);

    let url = `${api}/api/v1.0/practicas/masivo/requisitos?${f}`

    Axios.get(url, {
      responseType: 'blob',
    }).then(res => { 
      fileDownload(res.data, nombreZip+".zip");
      if(res.status == 200) this.setState({ cargando: false })
    });

  }
  
  render() {
    let { adjuntos, acciones, accionesM, adjSeleccionados, tipo, admin } = this.props;
    return (
      <ListarDatos
        datos={adjuntos}
        css={{}}
        buscar={false}
        titulo='Requisitos'
        opciones={admin && tipo == "0"}
        opcionAdicional={true}
        actDescargar={() => this.descargarMasivo(adjuntos.filter(req => req.tipo == '1'))}
        sub_titulo={this.obtenerTitulo(tipo)}
        avatar={({ validacion, requisito : {valorc} }) => this.obtenerEstadoAdj(validacion, valorc).toUpperCase().charAt(0)}
        color_avatar={({ validacion, requisito : {valorc} }) => this.obtenerColor(validacion, valorc)}
        id='tbl_adjuntos'
        acciones={row => accionesM ? <Checkbox edge="end" onChange={() => this.seleccionar(row.id)} checked={adjSeleccionados.indexOf(row.id) !== -1} inputProps={{ 'aria-labelledby': row.id }} /> : acciones(row)}
        accion_general={row => accionesM ? this.seleccionar(row.id) : ''}
        fila_principal={({ requisito, nombre_archivo }) => requisito ? requisito.nombre : nombre_archivo}
        filas={[
          {
            'mostrar': ({ fecha_registro }) => `${moment(fecha_registro).format('YYYY-MM-DD, h:mm a')}`,
            'id': 'fecha_registro',
          },
          {
            'mostrar': ({ validacion, requisito : {valorc} }) => this.obtenerEstadoAdj(validacion, valorc),
            'id': 'estado',
          },
          {
            'nombre': 'Agregado por : ',
            'mostrar': ({ usuario_registro }) => `${usuario_registro.primer_nombre} ${usuario_registro.primer_apellido} ${usuario_registro.segundo_apellido}`,
            'id': 'nombre_completo',
            'enLista': false,
          },
          {
            'nombre': 'Revisado el : ',
            'mostrar': ({ fecha_valida }) => fecha_valida && `${moment(fecha_valida).format('YYYY-MM-DD, h:mm a')}`,
            'id': 'fecha_valida',
            'enLista': false,
          },
          {
            'nombre': 'Revisado por : ',
            'mostrar': ({ usuario_valida }) => usuario_valida && `${usuario_valida.primer_nombre} ${usuario_valida.primer_apellido} ${usuario_valida.segundo_apellido}`,
            'id': 'usuario_valida',
            'enLista': false,
          },
          {
            'nombre': 'Nota : ',
            'id': 'nota',
            'enLista': false,
          },
          {
            'nombre': 'Nota : ',
            'id': 'nota2',
            'enLista': false,
          },
        ]}
      />
    );

  }
}

//Props del componente
AdjuntosListar.propTypes = {
  adjuntos: PropTypes.array.isRequired,
  estado: PropTypes.object.isRequired,
  accionesAct: PropTypes.bool.isRequired,
  //funciones
  acciones: PropTypes.func.isRequired,
  mostrarModalAddAdjunto: PropTypes.func.isRequired,
}

export default AdjuntosListar;
