//Importaciones necesaria para poder utilizar react
import React, { useState }  from 'react'
import * as Funciones from '../../../global/js/funciones';
//Importaciones necesarias para que el modal del formulario de creacion de semilleros se pueda abrir, este es para que se habra el modal.
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button'
//Importe de los text faild para los formularios de los campos.
import TextField from '@material-ui/core/TextField';
//Importe de los grid para los textfield de los formularios entre otros aspecto.
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import AppBarModal from '../../general/AppBarModal'
import TareasTerminadas from '../../general/TareasTerminadas';
import emma_w from '../../../global/imagenes/emma_w.png';
import { BtnForm, BtnDetalle, BtnCancelar } from '../../general/BotonesAccion';
import ListarDatos from '../../general/ListarDatos';
import IconButton from '@material-ui/core/IconButton';
import { useDispatch } from 'react-redux';
import { actualizarMensaje } from '../../../redux/actions/actGlobal';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DeleteIcon from '@material-ui/icons/Delete';
import SchoolSharpIcon from '@material-ui/icons/SchoolSharp';
import { SemilleroMenuProfesorParticipantes } from './MenuParticipantes';
import { Checkbox, DialogContentText } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '150%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  colorWhite : {
      color: '#fff'
  },
  flexGrow: {
      flexGrow: 1,
  },
  flexCenter:{
      textAlign: "center",
      marginTop: "5px"
  },
  formControl: {
    margin: "0",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  colorDelete: {
    color: "#d11a2a",
    transition: "all 0.3s",
    '&:hover': {
      color: "#fff",
      backgroundColor: "#d11a2a",
      transform: "scale(1.3)",
    },
  },colorGraduationIcon: {
    color:'#282828',
    transition: "all 0.3s",
    '&:hover': {
      color: "#fff",
      backgroundColor: "#212121",
      transform: "scale(1.3)",
    },
  },
  colorDelete1: {
    color: "#d11a2a",
    transition: "all 0.3s",
    '&:hover': {
      color: "#fff",
      backgroundColor: "#d11a2a",
      transform: "scale(1.3)",
    },
  },
  color:{
    transition: 'all 0.3s',
    borderRadius: "20px",
    '&:hover': {
        transform: "scale(1.01)",
        borderRadius: "0px",
        
    },
  }
}));

export default function PaticipantesListaSemi(props) {
    const classes = useStyles();
    //Cargando
    const [cargando, setCargando] = React.useState(true)
    const isInitialMount = React.useRef(true);
    const [infoSoli, setInfoSoli] = React.useState([]);
    const dispatch = useDispatch();
    let seminuevoperticipante
    React.useEffect( () => {
      
        if(isInitialMount.current && props.semilleronuevo.length>0){

            isInitialMount.current = false
            const obtenerProcesos = async () => {

                props.semilleronuevo.map((semi)=>{
                    semi.solicitud_semillero_investigacion.map((se)=>{
                        seminuevoperticipante=se.id
                    })
                })
                let dataSolicitudes
                if(props.profesor == true){
                  dataSolicitudes = await obtenerParticipantesSolicitudes();
                }else{
                  dataSolicitudes = null
                }
                setInfoSoli(dataSolicitudes)
                setCargando(false)
                
            }
            obtenerProcesos()

        }

    })

    const obtenerParticipantesSolicitudes = async () => {
            return new Promise(resolve => {
                Funciones.consulta(`api/v1.0/investigacion/listarParticipante2?estado_actual__codigo=Semi_par_acep&semillero=${seminuevoperticipante}`, null, null, (error, estado, resp) => {
                  resolve(estado === 200 && !error ? resp : null);
                })
            })
        
    }

    const PintarInfo4 = () =>{
   
      const [msg, setMsg] = React.useState("");
      const [openModalMessage, setOpenModalMessage] = React.useState(false);
      const [openModalGraduado, setOpenModalGraduado] = React.useState(false);
      const [dataRow, setDataRow] = React.useState("");
      const [dataRow1, setDataRow1] = React.useState("");

      const down = (data) =>{
        setDataRow(data)
        setOpenModalMessage(true)
      }
      const down1 = (data) =>{
        setDataRow1(data)
        setOpenModalGraduado(true)
      }
      
      const closeDownModal = () =>{
        setMsg("")
        setOpenModalMessage(false)
      }
      const closeDownModal1 = () =>{
        setOpenModalGraduado(false)
      }
      

      const acciones = (data) => {
          
        if(data.estado_actual.codigo == "Semi_par_acep"&&props.profesor==true){
          return (
            <div>
                  <IconButton aria-label="done" className={classes.colorDelete1} onClick={() => {down(data)}}>
                      <DeleteIcon />
                  </IconButton>
                  <IconButton  aria-label="graduation" className={classes.colorGraduationIcon}onClick={() => {down1(data)}}>
                    <SchoolSharpIcon />
                </IconButton>
              </div>
          )
        }else{
          return(
            <div></div>
          )
        }
      };

      return (
        <div>
            <ListarDatos
            id="tbl_listar_semilleros_participantes"
            //avatar={({ nombre }) => nombre.charAt(0)}
            datos={infoSoli}
            opciones={true}
            buscar={false}
            acciones={ (row) => acciones(row)}
            titulo={"Lista de participantes del Semilleros de Investigación"}
            fila_principal={({ participante, id }) => `${participante.primer_nombre} ${participante.segundo_nombre} ${participante.primer_apellido} ${participante.segundo_apellido}`}
            filas={[
              {
                'mostrar': ({ semillero }) => `${semillero.nombre}`,
                'id': 'Semillero', 'enLista': true
              },
              {
                'mostrar': ({ participante }) => `${participante.primer_nombre} ${participante.segundo_nombre} ${participante.primer_apellido} ${participante.segundo_apellido}`,
                'id': 'Participantes', 'enLista': false
              },
              {
                'mostrar': ({participante}) => `${participante.correo}`,
                'id': 'Correo', 'enLista': true
              },
              {
                'mostrar': ({participante}) => `${participante.identificacion}`,
                'id': 'Identificacion', 'enLista': true
              },
              {
                'mostrar': ({programa}) => `${programa.nombre}`,
                'id': 'Programa', 'enLista': true
              },
            ]}
          />

          {openModalMessage == true ? 
          <MessageToDown 
            openModal = {openModalMessage}
            setOpenModal = {setOpenModalMessage}
            semilleronuevo={props.semilleronuevo}
            dataRow = {dataRow}
            setMsg = {setMsg}
            msg = {msg}
            closeDownModal={closeDownModal}
          /> : null}
          {openModalGraduado == true ? 
          <MensajeGraduado 
            openModal = {openModalGraduado}
            setOpenModal = {setOpenModalGraduado}
            semilleronuevo={props.semilleronuevo}
            dataRow = {dataRow1}
            closeDownModal={closeDownModal1}
            infoSoli={infoSoli}
          /> : null}  
        </div>
      )
            
    }

    const MensajeGraduado = (props) => {
      
      const isInitialMount = React.useRef(true);
      const dispatch = useDispatch();
      const nombre = props.dataRow.participante.primer_nombre + ' ' +  props.dataRow.participante.primer_apellido + ' ' + props.dataRow.participante.segundo_apellido
      // console.log(nombre);
      const mensaje = `¿Deseas colocar como estudiante graduado al ${nombre} estudiante?`;
      React.useEffect( () => {
         
          if(isInitialMount.current && props.semilleronuevo.length>0){
              isInitialMount.current = false
              props.semilleronuevo.map((semi)=>{
                  semi.solicitud_semillero_investigacion.map((se)=>{
                      seminuevoperticipante=se.id
                  })
              })
          }
      })
      const graduateSenToApi = async () =>{

        let data = {
          id: props.dataRow.id,
        }

        setCargando(true)
        props.closeDownModal()
        let url = "api/v1.0/investigacion/participantegraduado"
        const callback = async (error, estado, resp) => {
            let titulo = "Ha ocurrido un error, contacte con el administrador."
            let tipo = "error"

            if(!error){
                if(estado === 201){
                    tipo = "success"
                    titulo = resp.titulo
                }else{
                  tipo = "info"
                  titulo = resp.titulo
                }
            }

            dispatch(
                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
            );
            let dataSolicitudes = await obtenerParticipantesSolicitudes();
            setInfoSoli(dataSolicitudes)
            setCargando(false)
            
        }
        const msg2 = await Funciones.consulta(url, data, 'POST', callback)
      }
        
      
        return (
          <div>
            <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={props.openModal} onClose={() => props.closeDownModal() } aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
              <AppBarModal titulo={' ¿Asigar Estudiante Graduado? '} mostrarModal={() => props.closeDownModal()} titulo_accion="" accion="" />
                <DialogContent className='scroll'>
                  <DialogContentText id="alert-dialog-description">
                    {mensaje}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <BtnForm texto="CANCELAR" callback={() => props.closeDownModal()} />
                <BtnForm texto="ACEPTAR" callback={graduateSenToApi} />
              </DialogActions>
            </Dialog>
          </div>
        )
      
      }
    const MessageToDown = (props) => {
        
    const isInitialMount = React.useRef(true);
    const dispatch = useDispatch();
    React.useEffect( () => {
       
        if(isInitialMount.current && props.semilleronuevo.length>0){
            isInitialMount.current = false
            props.semilleronuevo.map((semi)=>{
                semi.solicitud_semillero_investigacion.map((se)=>{
                    seminuevoperticipante=se.id
                })
            })
        }
    })
    const downWithMessageSenToApi = async () =>{

        let data = {
          id: props.dataRow.id,
          msg: props.msg
        }

        setCargando(true)
        props.closeDownModal()
        let url = "api/v1.0/investigacion/eliminar"
        const callback = async (error, estado, resp) => {
            let titulo = "Ha ocurrido un error, contacte con el administrador."
            let tipo = "error"

            if(!error){
                if(estado === 201){
                    tipo = "success"
                    titulo = resp.titulo
                }
            }

            dispatch(
                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
            );
            let dataSolicitudes = await obtenerParticipantesSolicitudes();
            setInfoSoli(dataSolicitudes)
            setCargando(false)
            
        }
        const msg2 = await Funciones.consulta(url, data, 'POST', callback)
      }
      const handleOnchange = (e) =>{
          if(e.target.name == "msg"){
              props.setMsg(e.target.value)
          }
      }
    
      return (
        <div>
          <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={props.openModal} onClose={() => props.closeDownModal()}>
            <AppBarModal titulo={'¡ Digite su Mensaje !'} mostrarModal={() => props.closeDownModal()} titulo_accion="" accion="" />
            <DialogContent>
              <Grid container spacing={3}>
                  <Grid item xs={12}>
                      <TextField 
                          fullWidth 
                          multiline
                          rowsMax={6}
                          label="Escriba un Mensaje" 
                          id = 'msg'
                          //error = { props.getError('celular', props.errores).length > 0}
                          //helperText = { props.getError('celular', props.errores) }
                          value={props.msg} 
                          onChange={handleOnchange}
                          name="msg"
                      />
                  </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="primary" style={{color: "#fff"}} onClick={downWithMessageSenToApi}>
                  Enviar
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )
    
    }
    
    const pintar = (pos, dispatch) => {
        if (props.cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />;
        return (
          <div>
            {pos === 'Información del Semillero' ? <PintarInfo4 infoSoli={infoSoli}  /> : ''}
          </div>
        )
    
    }

    const handleClose = () =>{
      props.setPos('Información del Semillero')
      props.setOpenModalInfoParticipante(false)
      props.setDetalle(true)
    }
   
    const handleCloseeee = async () =>{
            
            let titulo = `Elimine los integrantes que no se encuentren activos en el semillero de investigación, en caso de requerirlo. Luego dé clic en guardar`;
            let tipo = 'info'
            dispatch(
                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
              );
        
    }
    return (
      <div>
        <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={props.openModalInfoParticipante} onClose={handleCloseeee}>
          <AppBarModal titulo={'¡ Actualiza Informacion De Participantes !'} mostrarModal={handleCloseeee} titulo_accion="" accion="" />
          <SemilleroMenuProfesorParticipantes  profesor={props.profesor} sele={props.pos} seleccionar={(pos) => {
            props.setPos('Información del Semillero')
            
            
          }} />
          <DialogContent>
            {cargando == true ? <TareasTerminadas imagen={emma_w} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' /> : pintar(props.pos)}
          </DialogContent>
          <DialogActions>
                <BtnForm texto="Guardar" callback={handleClose} />
                
          </DialogActions>
        </Dialog>
      </div>
    )
}

