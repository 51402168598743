import React from 'react'
import { FormControl, Grid, InputLabel, Select, MenuItem, TextField } from '@material-ui/core';

export const AtencionFormCrear = (props) => {
  let {
    onChangeFacultadAtencion,
    onChangeProgramaEstudiante,
    onChangeTipoAtencion,
    onChangeIncidente,
    onChangePlataforma,
    onChangeProgramas,
    onChange,
    facultad_atencion_id,
    facultad_atencion,
    programa_estudiante_id,
    programa,
    estado_estudiante_id,
    estado_estudiante,
    tipo_atencion_id,
    tipo_atencion,
    tipo_incidente_id,
    tipo_incidente,
    plataforma_id,
    plataforma
  } = props

  return (
    <Grid container spacing={3} style={{ marginBottom: '40px' }}>
      <Grid item xs={12}>
        <FormControl className='form-control'>
          <InputLabel required>Facultad de Atención</InputLabel>
          <Select
            id='facultad'
            value={facultad_atencion_id}
            onChange={onChangeFacultadAtencion}
            name='facultad'
          >
            {facultad_atencion.map(({value, label, codigo }) => <MenuItem key={value} value={value} data-my-value={codigo} >{label}</MenuItem>)}
          </Select>
        </FormControl>
      </Grid>
      {
        programa.length > 0 && facultad_atencion_id &&
        <Grid item xs={12}>
          <FormControl className='form-control'>
            <InputLabel required>Programa</InputLabel>
            <Select
              id='programa'
              value={programa_estudiante_id}
              onChange={onChangeProgramaEstudiante}
              name='programa'
            >
              {programa.map(({value, label, codigo }) => <MenuItem key={value} value={value} data-my-value={codigo} >{label}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
      }
      <Grid item xs={12}>
        <FormControl className='form-control'>
          <InputLabel required>Tipo de Atención</InputLabel>
          <Select
            id='tipo_atencion'
            value={tipo_atencion_id}
            onChange={onChangeTipoAtencion}
            name='tipo_atencion'
          >
            {tipo_atencion.map(({value, label, codigo }) => <MenuItem key={value} value={value} data-my-value={codigo} >{label}</MenuItem>)}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl className='form-control'>
          <InputLabel required>Estado del estudiante</InputLabel>
          <Select
            id='estado_estudiante'
            value={estado_estudiante_id}
            onChange={onChangeProgramas}
            name='estado_estudiante'
          >
            {estado_estudiante.map(({value, label, codigo }) => <MenuItem key={value} value={value} data-my-value={codigo} >{label}</MenuItem>)}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl className='form-control'>
          <InputLabel required>Asunto</InputLabel>
          <Select
            id='tipo_incidente'
            value={tipo_incidente_id}
            onChange={onChangeIncidente}
            name='tipo_incidente'
          >
            {tipo_incidente.map(({value, label, codigo }) => <MenuItem key={value} value={value} data-my-value={codigo} >{label}</MenuItem>)}
          </Select>
        </FormControl>
      </Grid>

      {
        plataforma.length > 0 && <Grid item xs={12}>
          <FormControl className='form-control'>
            <InputLabel required>Plataforma</InputLabel>
            <Select
              id='plataforma'
              value={plataforma_id}
              onChange={onChangePlataforma}
              name='plataforma'
            >
              {plataforma.map(({value, label, codigo }) => <MenuItem key={value} value={value} data-my-value={codigo} >{label}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
      }
      
      {/* Observaciones */}
      <Grid item xs={12} >
        <FormControl className="form-control">
          <TextField
            id="observaciones"
            label={"Observaciones"}
            name="observaciones"
            placeholder={"Escriba Observaciones"}
            multiline
            InputLabelProps={{
              shrink: true,
            }}

            onChange={onChange}
          />
          </FormControl>
      </Grid>
    </Grid>
  )
}