import React from 'react'
import Axios from 'axios';
import fileDownload from 'js-file-download';
import { Grid } from '@material-ui/core' 
import TareasTerminadas from '../general/TareasTerminadas';
import file from '../../global/imagenes/file.png';
import download from '../../global/imagenes/download.png';

const DescargarArchivo = ({url, nombre_archivo, calificacion}) => {
    let extension = (/[.]/.exec(url)) ? /[^.]+$/.exec(url)[0] : undefined;
    nombre_archivo = nombre_archivo+'.'+extension

    const descargar = (url, nombre_archivo, e) => {
        e.preventDefault()
        Axios.get(url, {
          responseType: 'blob',
        }).then(res => { 
          fileDownload(res.data, nombre_archivo);
        });
    }

    let textCalificacion = (calificacion!='null') ? 'Este requisito tiene una nota cargada de '+calificacion : '';
    
    return (
        <Grid container alignItems="flex-end">
            <Grid item xs={12} sm={6}>
                <a href="#" className='sin_decorador' onClick={ (e) => { descargar(url, nombre_archivo, e) } } >
                    <TareasTerminadas mensaje={`Descargar Requisito`} marginTop='7%' imagen={download} widthImg="7%" />
                </a>
            </Grid>
            <Grid item xs={12} sm={6}>
                <a href={`${url}`} target="_blank" className='sin_decorador'>
                    <TareasTerminadas mensaje={`Clic aquí para abrirlo`} marginTop='7%' 
                    imagen={file} 
                    widthImg="7%" />
                </a>
            </Grid>
            <Grid item xs={12}>
                <p style={{ fontSize: '15px', paddingLeft: '20px' }}>
					{textCalificacion}
				</p>
            </Grid>
        </Grid>
    );
}

export default DescargarArchivo;

//url : Direccion del archivo, es preferible que termine en la extension del archivo
//nombre_archivo: el el nombre que va a terner el archivo al momento de ser descargado Nota: no lleva extension