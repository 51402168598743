import TareasTerminadas from "./../../general/TareasTerminadas";
import { consulta} from '../../../global/js/funciones';
import emma_w from './../../../global/imagenes/emma_w.png';
import emma_s from './../../../global/imagenes/emma_s1.png';
import React, { Component, forwardRef } from 'react';
import { Dialog, DialogContent, DialogActions, Grid, InputLabel, Select, MenuItem, FormControl,Checkbox,ListItemText, IconButton, Tooltip } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ListarTabla from '../../general/ListarTabla';
import { BtnForm } from '../../general/BotonesAccion';
import AppBarModal from '../../general/AppBarModal';




class PracticaInformeEmpresa extends Component {

  constructor(props) {
    super(props)
    this.state = {
       columnas: [],
       data_informe: [],
       cargando: true,
       mostrar: '',
       maxWidth:'sm',
       cargar: true,
       

      };
    }

   async componentDidMount() {
      let { mostrar,cargando } = this.state;
      let { modalInforme, informe } = this.props;
      if (modalInforme && cargando) {
         await this.obtenerInforme();
      }  
    }

  async componentDidUpdate({ modalInforme }, { mostrar }) {
      let { mostrar: mostrarNew, cargar } = this.state;
      let { modalInforme: modalInformeNew, informe } = this.props;
      if (modalInforme !== modalInformeNew) {
         if (modalInformeNew) {
            await this.obtenerInforme();
         }
      //    else this.resetState()
      }
   }


   //  resetState = () => {
   //    let { mostrar } = this.state

   //    this.setState({ data_informe: [], columnas: [], cargando: false })
     
   // }
    pintar = () => {
      let { cargando, mostrar } = this.state;
      console.log(cargando)
      // this.setState({cargando:true})
      if (cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />;
      return (
         <div>
          
           {this.pintarInforme()}
          
         </div>
      )
   }
//    onSubmit = (e) => {
//     this.obtenerInforme()
//     e.preventDefault();
//  }

  obtenerInforme = async () => {
    let { actualizarMensaje } = this.props
    this.setState({ cargando: true });
    consulta(`api/v1.0/practicas/informe?&informe=Inf_Emp`, null, null,
      (error, estado, resp) => {
        if (estado === 200) this.setState({ cargando: false, data_informe: resp, maxWidth: 'lg' 
       });
        else actualizarMensaje({ titulo: 'Ha ocurrido un error, contacte con el administrador.', tipo: 'info', tiempo: 6000 });
      }
    );

 }
//  obtenerInforme = async (filtros = []) => {
//   let { actualizarMensaje } = this.props
//   let f = await generarFiltros(filtros);
//   this.setState({ cargando: true });
//   consulta(`api/v1.0/practicas/informe?${f}`, null, null,
//     (error, estado, resp) => {
//       if (estado === 200) this.setState({ cargando: false, data_informe: resp, mostrar: 'informe', maxWidth: 'lg' 
//      });
//       else actualizarMensaje({ titulo: 'Ha ocurrido un error, contacte con el administrador.', tipo: 'info', mostrar: true, tiempo: 6000 });
//     }
//   );

// }

    pintarInforme = () => {
    let { data_informe, columnas, } = this.state
    let { informe } = this.props
    let ordenar = false;
    

    if(data_informe.length>0){
      if(columnas.length===0){
        
        let archivo = data_informe.map((consulta) => consulta.archivoEmpresa);

        columnas = [
          {title: "Nombre de la empresa",field: "nombreEmpresa",orden: 1,},
          {title: "Descripción de la empresa", field: "descripcionEmpresa", orden: 2 },
          {title: "NIT", field: "codigoEmpresa", orden: 3 },
          {title: "Archivo Adjunto", field: "archivocontEmpresa", orden: 4 },
          {title: "Nombre de la sucursal", field: "nombreSucursal", orden: 5 },
          {title: "Barrio de la sucursal", field: "barrioSucursal", orden: 6 },
          {title: "Ciudad de la sucursal", field: "ciudadSucursal", orden: 7 },
          {title: "Departamento de la sucursal", field: "departamentoSucursal", orden: 8 },
          {title: "Telefono de la sucursal", field: "telefonoSucursal", orden: 9 },
          {title: "Fax de la sucursal", field: "faxSucursal", orden: 10 },
          {title: "Correo de la sucursal", field: "correoSucursal", orden: 11 },
          {title: "Dirección de la sucursal", field: "direccionSucursal", orden: 12 },
          ];
         
         //    archivo.map((archivo) => {
         //       if(archivo!=''){
         //      columnas.push({title: "Archivo Adjunto", field: "archivocontEmpresa", orden: 4 })
            
         //    }else{

         //     columnas.push({title: "Archivo Adjunto", field: "sinarchivoEmpresa", orden: 4 })


         //    }
         // }
         //    )
          

          




      }
      const titulo_informe=informe.nombre
      
      return (
        <ListarTabla titulo={titulo_informe} columnas={columnas} datos={data_informe}  orden={ordenar} />
     )

    }else{
      return(
         <TareasTerminadas mensaje='No se encontraron resultados' marginTop='7%' imagen={emma_s} widthImg="7%" />
      )
   }



}
 
     render() {
      let { cargando, maxWidth, mostrar } = this.state;
      let { modalInforme, mostrarModalInforme, informe } = this.props

      return (
        <Dialog fullWidth={true} maxWidth={maxWidth} PaperProps={ (informe.codigo === 'Inf_Pro') ? {style: {overflowY: 'visible'}} : {} } open={modalInforme} onClose={() => mostrarModalInforme(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <AppBarModal titulo={informe.nombre} mostrarModal={() => mostrarModalInforme(false)} />
        <DialogContent className='scroll' style={mostrar === 'informe' ? { padding: 0 } : ((informe.codigo === 'Inf_Pro') ? { overflowY: 'visible' }:{})}>
           {!cargando ? this.pintar() : <TareasTerminadas mensaje='Cargando..' marginTop='7%' cargando={cargando} imagen={emma_w} widthImg="7%" />}
        </DialogContent>
        <DialogActions>
           <BtnForm texto="Cerrar" callback={() => mostrarModalInforme(false)} />
           {/* {!cargando && <BtnForm texto="GENERAR" callback={this.onSubmit} />} */}
           
        </DialogActions>
     </Dialog>
      );
    }
  }
  export default PracticaInformeEmpresa