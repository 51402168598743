
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DialogActions, DialogContent, Dialog, Button, Grid } from '@material-ui/core';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import AppBarModal from './AppBarModal'
import { BtnForm } from "./BotonesAccion";

class EnviarMensaje extends Component {

  constructor(props) {
    super(props)
    this.state = {
      mensaje: '',
    }
    this.fileInput = React.createRef();
  }

  componentDidUpdate({ modal }) {
    let { modal: modalAddNew } = this.props;
    if (modalAddNew !== modal) {
      this.setState({ mensaje: '' });
    }
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({ [name]: value })
  }


  onSubmit = e => {
    let { mensaje } = this.state;
    this.props.enviar(mensaje);
    e.preventDefault();
  }

  render() {
    let { modal, mostrarModal, titulo, label, type, placeholder, validaciones, errorMensajes, extraComponent } = this.props;
    let { mensaje } = this.state;
    return (
      <div>
        <Dialog open={modal} fullWidth={true} maxWidth="xs" onClose={mostrarModal.bind(this, false)} aria-labelledby="form-dialog-title">
          <AppBarModal titulo={titulo} mostrarModal={mostrarModal} titulo_accion="" accion="" />
          <DialogContent className='scroll'>
            <ValidatorForm onSubmit={this.onSubmit}>
              <Grid item xs={12} >
                <TextValidator
                  // variant="outlined"
                  autoFocus
                  margin="dense"
                  label={label}
                  type={type}
                  fullWidth
                  name="mensaje"
                  value={mensaje}
                  placeholder={placeholder}
                  validators={validaciones}
                  errorMessages={errorMensajes}
                  onChange={this.onChange}
                />

              </Grid>
              <Button color="primary" type="submit" id='enviar_form_enviar_mensaje' className="oculto"> Enviar </Button>
            </ValidatorForm>
            {extraComponent}
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => mostrarModal(false)} />
            <BtnForm texto="ACEPTAR" callback={() => { document.getElementById('enviar_form_enviar_mensaje').click() }} />
          </DialogActions>
        </Dialog>

      </div>
    );
  }
}

EnviarMensaje.propTypes = {
  //variables
  modal: PropTypes.bool.isRequired,
  titulo: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  //funciones
  enviar: PropTypes.func.isRequired,
  mostrarModal: PropTypes.func.isRequired,
}

EnviarMensaje.defaultProps = {
  placeholder: '',
  validaciones: ["required"],
  errorMensajes: ["El campo es requerido"],
}


export default EnviarMensaje;
