import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import AppBarModal from '../general/AppBarModal'
import { BtnForm } from "../general/BotonesAccion";
import TareasTerminadas from "../general/TareasTerminadas";
import emma_w from '../../global/imagenes/emma_w.png';


class PracticaIniciarActa extends Component {

  constructor(props) {
    super(props)
    this.state = {
      nombre: '',
      cargo: '',
      correo: '',
      observaciones: '',
      Deshabilitar: false,
      cargando: true
    }
  }


  componentDidUpdate({ modalIniActa }) {
    let { modalIniActa: modalIniActaNew } = this.props;
    if (modalIniActaNew !== modalIniActa) {
      this.changeState();
    }
  }


  changeState = () => {
    let { acta: {estudiante, valorf}, practica, coordinador } = this.props;
    let { primer_nombre, primer_apellido, segundo_apellido, correo: correo_est } = practica.usuario_registro;
    let nombre = '';
    let cargo = '';
    let correo = '';
    let Deshabilitar = false;
    if (estudiante === '1') {
      nombre = `${primer_nombre} ${primer_apellido} ${segundo_apellido}`;
      correo = correo_est;
      cargo = 'Estudiante';
      Deshabilitar = true;
    } else if (valorf == 'jefe') {
      nombre = practica.jefe;
      correo = practica.correo_jefe;
      cargo = practica.cargo_jefe;
    } else if (valorf == 'tutor') {
      nombre = `${practica.tutor.primer_nombre} ${practica.tutor.primer_apellido} ${practica.tutor.segundo_apellido}`;
      correo = practica.tutor.correo;
      cargo = 'Tutor';
    } else if (valorf == 'coordinador') {
      nombre = coordinador.nombre;
      correo = coordinador.correo;
      cargo = 'Coordinador de Practicas';
    }
    this.setState({ nombre, cargo, correo, Deshabilitar, observaciones: '', cargando: false })
  }

  onSubmit = e => {
    let { nombre, cargo, correo, observaciones } = this.state;
    this.setState({cargando: true})
    this.props.enviarActa({ nombre_archivo: nombre, cargo_encuesta: cargo, correo_encuesta: correo, observaciones }, () => this.setState({cargando : false}));
    e.preventDefault();
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }


  render() {
    let {
      nombre,
      cargo,
      correo,
      observaciones,
      Deshabilitar,
      cargando
    } = this.state;

    let { modalIniActa, mostrarModalIniActa, acta: { nombre: titulo } } = this.props;
    return (
      <Dialog open={modalIniActa} onClose={mostrarModalIniActa.bind(this, false)} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="sm">
        <AppBarModal titulo={titulo} mostrarModal={mostrarModalIniActa} titulo_accion="" accion="" />
        <DialogContent className='scroll'>
          {cargando ? <TareasTerminadas mensaje={'Enviando...'} marginTop='7%' imagen={emma_w} widthImg="7%" /> :
            <ValidatorForm onSubmit={this.onSubmit}>
              <TextValidator
                disabled={Deshabilitar}
                margin="dense"
                id="nombre"
                label="Nombre Completo"
                type="text"
                fullWidth
                name="nombre"
                value={nombre}
                validators={["required"]}
                errorMessages={["El campo es requerido"]}
                onChange={this.onChange}
              />
              <TextValidator
                disabled={Deshabilitar}
                margin="dense"
                id="cargo"
                label="Cargo"
                type="text"
                fullWidth
                name="cargo"
                validators={["required"]}
                errorMessages={["El campo es requerido"]}
                value={cargo}
                onChange={this.onChange}
              />
              <TextValidator
                disabled={Deshabilitar}
                margin="dense"
                id="correo"
                label="Correo"
                type="text"
                fullWidth
                name="correo"
                value={correo}
                validators={['required']}
                errorMessages={["El campo es requerido", "El correo no es valido"]}
                onChange={this.onChange}
              />
              <TextValidator
                margin="dense"
                id="observaciones"
                label="Observaciones"
                type="text"
                fullWidth
                name="observaciones"
                value={observaciones}
                validators={[]}
                errorMessages={[]}
                onChange={this.onChange}
              />
              <Button color="primary" type="submit" id='form_iniciar_acta' className="oculto"> Guardar </Button>
            </ValidatorForm>
          }
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CANCELAR" callback={() => mostrarModalIniActa(false)} />
          { !cargando && <BtnForm texto="ENVIAR" callback={() => { document.getElementById('form_iniciar_acta').click() }} />}
        </DialogActions>
      </Dialog>
    )
  }
}

//Props del componente
PracticaIniciarActa.propTypes = {
  //variables
  modalIniActa: PropTypes.bool.isRequired,
  acta: PropTypes.object.isRequired,
  //funciones
  mostrarModalIniActa: PropTypes.func.isRequired,
}


export default PracticaIniciarActa;

