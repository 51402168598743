import React, { Component } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Button, Paper, InputBase, Divider, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: "100%",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));


export default function InputBuscarText({ onChange, dato_buscar, obtenerData, placeholder = 'Ingrese Dato', name = 'dato_buscar' }) {
  const classes = useStyles();

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      obtenerData(dato_buscar);
      e.preventDefault();
    }
  }

  return (
    <Paper component="form" className={classes.root}>
      <IconButton className={classes.iconButton} >
        <SearchIcon />
      </IconButton>
      <InputBase
        name={name}
        value={dato_buscar}
        onChange={onChange}
        className={classes.input}
        placeholder={placeholder}
        inputProps={{ 'aria-label': 'search google maps' }}
        onKeyPress={handleKeyPress}
      />
      <Divider className={classes.divider} orientation="vertical" />
      <Button variant="contained" color="primary" style={{ color: 'white' }} onClick={() => obtenerData(dato_buscar)}>Buscar</Button>
    </Paper>
  );
}
