import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment'

export function Validacion({ validacion }) {
  let {
    estado_actual,
    programa,
    asignatura,
    usuario_registro: { primer_nombre, primer_apellido, segundo_apellido, }
  } = validacion;

  // let fecha = moment(fecha_registro).format('YYYY-MM-DD, h:mm a');
  return (
    <Table>
      <TableBody>
        <TableRow key={1}>
          <TableCell component="th" scope="row">Solicitante</TableCell>
          <TableCell align="left">{`${primer_nombre} ${primer_apellido} ${segundo_apellido}`}</TableCell>
        </TableRow>
        <TableRow key={2}>
          <TableCell component="th" scope="row">Programa</TableCell>
          <TableCell align="left">{programa.nombre}</TableCell>
        </TableRow>
        <TableRow key={3}>
          <TableCell component="th" scope="row">Estado Actual</TableCell>
          <TableCell align="left">{estado_actual.nombre}</TableCell>
        </TableRow>
        <TableRow key={4}>
          <TableCell component="th" scope="row">Asignatura</TableCell>
          <TableCell align="left">{asignatura}</TableCell>
        </TableRow>
        {/* <TableRow key={5}>
          <TableCell component="th" scope="row">Fecha de Registro</TableCell>
          <TableCell align="left">{fecha}</TableCell>
        </TableRow> */}
      </TableBody>
    </Table>
  )
}

export function DetalleSolicitud({ validacion }) {
  let {
    usuario_registro: { primer_nombre, primer_apellido, segundo_apellido, identificacion, correo, telefono, celular },
    programa,
    programa_asignado,
    asignatura,
    codigo_asignatura,
    estado_actual,
    observaciones,
    motivo,
    fecha_registro,
    coordinador,
    profesor,
    fecha_examen,
    hora_examen,
    duracion_examen,
    lugar_examen,
    url,
    // profesor
    otro_motivo

  } = validacion;

  let fecha = moment(fecha_registro).format('YYYY-MM-DD, h:mm a');
  return (

    <Table>
      <TableBody>
        <TableRow key={1}>
          <TableCell component="th" scope="row">Solicitante</TableCell>
          <TableCell align="left">{primer_nombre} {primer_apellido} {segundo_apellido}</TableCell>
        </TableRow>
        <TableRow key={2}>
          <TableCell component="th" scope="row">Identificacion</TableCell>
          <TableCell align="left">{identificacion}</TableCell>
        </TableRow>
        <TableRow key={3}>
          <TableCell component="th" scope="row">Correo</TableCell>
          <TableCell align="left">{correo}</TableCell>
        </TableRow>
        <TableRow key={4}>
          <TableCell component="th" scope="row">Telefono</TableCell>
          <TableCell align="left">{celular}</TableCell>
        </TableRow>
        <TableRow key={5}>
          <TableCell component="th" scope="row">Programa</TableCell>
          <TableCell align="left">{programa.nombre}</TableCell>
        </TableRow>
        {
          programa_asignado ?
            <TableRow key={6}>
              <TableCell component="th" scope="row">Programa Estudiante</TableCell>
              <TableCell align="left">{programa_asignado.nombre}</TableCell>
            </TableRow> :
            <TableRow key={6}></TableRow>
        }
        <TableRow key={7}>
          <TableCell component="th" scope="row">Asignatura</TableCell>
          <TableCell align="left">{asignatura}</TableCell>
        </TableRow>
        <TableRow key={8}>
          <TableCell component="th" scope="row">Codigo Asignatura</TableCell>
          <TableCell align="left">{codigo_asignatura}</TableCell>
        </TableRow>
        <TableRow key={9}>
          <TableCell component="th" scope="row">Estado Actual</TableCell>
          <TableCell align="left">{estado_actual.nombre}</TableCell>
        </TableRow>
        {
          motivo ?
            <TableRow key={10}>
              <TableCell component="th" scope="row">Motivo</TableCell>
              <TableCell align="left">{(motivo.codigo == 'Otr_Neg') ? `${motivo.nombre}: ${otro_motivo}` : motivo.nombre}</TableCell>
            </TableRow> :
            <TableRow key={10}></TableRow>
        }
        {
          coordinador ?
            <TableRow key={11}>
              <TableCell component="th" scope="row">Coordinador Asignado</TableCell>
              <TableCell align="left">{coordinador.primer_nombre} {coordinador.primer_apellido} {coordinador.segundo_apellido}</TableCell>
            </TableRow> :
            <TableRow key={11}></TableRow>
        }
        {
          coordinador ?
            <TableRow key={12}>
              <TableCell component="th" scope="row">Correo Cordinador</TableCell>
              <TableCell align="left">{coordinador.correo}</TableCell>
            </TableRow> :
            <TableRow key={12}></TableRow>
        }
        {
          coordinador ?
            <TableRow key={13}>
              <TableCell component="th" scope="row">Telefono Cordinador</TableCell>
              <TableCell align="left">{coordinador.celular}</TableCell>
            </TableRow> :
            <TableRow key={13}></TableRow>
        }
        {
          profesor ?
          <TableRow key={14}>
            <TableCell component="th" scope="row">Profesor Asignado</TableCell>
            <TableCell align="left">{profesor.primer_nombre} {profesor.primer_apellido} {profesor.segundo_apellido}</TableCell>
          </TableRow> :
          <TableRow key={14}></TableRow>
        }
        {
          profesor ?
          <TableRow key={15}>
            <TableCell component="th" scope="row">Correo Profesor</TableCell>
            <TableCell align="left">{profesor.correo}</TableCell>
          </TableRow> : 
          <TableRow key={15}></TableRow>
        }
        {
         profesor ?
          <TableRow key={16}>
            <TableCell component="th" scope="row">Telefono Profesor</TableCell>
            <TableCell align="left">{profesor.celular}</TableCell>
          </TableRow> :
          <TableRow key={16}></TableRow> 
        }
        {
          fecha_examen ?
            <TableRow key={17}>
              <TableCell component="th" scope="row">Fecha del Examen</TableCell>
              <TableCell align="left">{fecha_examen}</TableCell>
            </TableRow> :
            <TableRow key={17}></TableRow>
        }
        {
          hora_examen ?
            <TableRow key={18}>
              <TableCell component="th" scope="row">Hora del Examen</TableCell>
              <TableCell align="left">{hora_examen}</TableCell>
            </TableRow> :
            <TableRow key={18}></TableRow>
        }
        {
          duracion_examen ?
            <TableRow key={19}>
              <TableCell component="th" scope="row">Duración del Examen</TableCell>
              <TableCell align="left">{duracion_examen} Minutos</TableCell>
            </TableRow> :
            <TableRow key={19}></TableRow>
        }
        {
          lugar_examen ?
            <TableRow key={20}>
              <TableCell component="th" scope="row">Lugar del Examen</TableCell>
              <TableCell align="left">{lugar_examen}</TableCell>
            </TableRow> :
            <TableRow key={20}></TableRow>
        }
        {
          url ?
            <TableRow key={21}>
              <TableCell component="th" scope="row">Enlace del Examen</TableCell>
              <TableCell align="left">{url}</TableCell>
            </TableRow> :
            <TableRow key={21}></TableRow>
        }
        {
          observaciones ?
            <TableRow key={22}>
              <TableCell component="th" scope="row">Observaciones Gestor</TableCell>
              <TableCell align="left">{observaciones}</TableCell>
            </TableRow> :
            <TableRow key={22}></TableRow>
        }

        {/* {
          profesor ?
          <TableRow key={13}>
            <TableCell component="th" scope="row">Profesor Asignado</TableCell>
            <TableCell align="left">{profesor.primer_nombre} {profesor.primer_apellido} {profesor.segundo_apellido}</TableCell>
          </TableRow> :
          <TableRow key={13}></TableRow>
        } */}

        <TableRow key={23}>
          <TableCell component="th" scope="row">Fecha Registro</TableCell>
          <TableCell align="left">{fecha}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
