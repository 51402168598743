import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import useWindowSize from '../../general/hooks/useWindowSize';

const Banner = ({ nombre }) => {

  const windowSize = useWindowSize();

  return (
    <div style={{
      alignSelf: 'flex-start',
      margin: '20px auto 5px',
      display: 'flex',
      flexDirection: 'column',
      padding: '30px 20px',
      backgroundColor: '#eaefef',
      borderRadius: '10px',
      width: windowSize < 600 ? 'auto' : '70%'
    }}>
      <Typography variant="h4" gutterBottom>Hola, este es el resumen de  {nombre.toUpperCase()} !</Typography>
      <span>Bienvenido al resumen y estado de OVA</span>
    </div>
  )
}

export default Banner
