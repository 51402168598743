import React from 'react';
import { Hidden, BottomNavigation, BottomNavigationAction, Tab, Tabs } from '@material-ui/core'
import IconCreate from '@material-ui/icons/AddCircle'
import IconPerson from '@material-ui/icons/Person'
import EditIcon from '@material-ui/icons/Edit';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Work from '@material-ui/icons/Work';
import DescriptionIcon from '@material-ui/icons/Description';
import DocInformativos from '../DocInformativos';

const MenuEmpresa = ({sele, seleccionar, doc_informativos}) => {
    const clase = (item, selec) => item === selec ? 'item_selec' : 'item_modulo';

    const pintar = (app, classN = '') => {
        if(!app){
            return (
                <div className={classN}>
                    <BottomNavigation showLabels className={clase('postulante', sele)} onClick={() => seleccionar('postulante')}>
                        <BottomNavigationAction label={'Postulantes'} icon={<IconPerson />} />
                    </BottomNavigation>
                    <BottomNavigation showLabels className={clase('oferta', sele)} onClick={() => seleccionar('oferta')}>
                        <BottomNavigationAction label={'Nueva'} icon={<IconCreate />} />
                    </BottomNavigation>
                    <BottomNavigation showLabels className={clase('mi_empresa', sele)} onClick={() => seleccionar('mi_empresa')}>
                        <BottomNavigationAction label={'Perfil'} icon={<EditIcon />} />
                    </BottomNavigation>
                    <BottomNavigation showLabels className={clase('pqrs', sele)} onClick={() => seleccionar('pqrs')}>
                        <BottomNavigationAction label={'PQRS'} icon={<HelpOutlineIcon />} />
                    </BottomNavigation>
                    <BottomNavigation showLabels className={clase('CV', sele)} onClick={() => seleccionar('CV')}>
                        <BottomNavigationAction label={'CV'} icon={<Work />} />
                    </BottomNavigation>
                    <DocInformativos
                        Documentos = { doc_informativos }
                        rendering = { (click) => <BottomNavigation showLabels onClick={click} className = { clase('documentos', sele) } ><BottomNavigationAction label = { 'Documentos' } icon = { <DescriptionIcon /> } /> </BottomNavigation> }
                    />
                </div>
            );
        }else{
            return (
                <div className={classN}>
                  <Tabs value={sele} variant='fullwidth' scrollButtons="off" >
                    <Tab label='Perfil' icon={<EditIcon className='menu_icono' />} className={clase('mi_empresa', sele, '_app')} value='mi_empresa' onClick={() => seleccionar('mi_empresa')} />
                    
                    <Tab label='Postulante' icon={<IconPerson className='menu_icono' />} className={clase('postulante', sele, '_app')} value='postulante' onClick={() => seleccionar('postulante')} />
                    
                    <Tab label='Nueva' icon={<IconCreate className='menu_icono' />} className={clase('oferta', sele, '_app')} value='oferta' onClick={() => seleccionar('oferta')} />
                  
                    <Tab label='PQRS' icon={<HelpOutlineIcon className='menu_icono' />} className={clase('pqrs', sele, '_app')} value='pqrs' onClick={() => seleccionar('pqrs')} />

                    <Tab label='CV' icon={<Work className='menu_icono' />} className={clase('CV', sele, '_app')} value='CV' onClick={() => seleccionar('CV')} />
                    
                    <DocInformativos
                        Documentos = { doc_informativos }
                        rendering = { (click) => <Tab label='Documentos' icon={<DescriptionIcon className='menu_icono' />} className={clase('documentos', sele, '_app')} value='documentos' onClick = { click } /> }
                    />
                  </Tabs>
                </div>
              )
        }
    }

    return (
        <div>
          <div>
            <Hidden xsDown> {pintar(false, 'menu_modulo')} </Hidden>
            <Hidden smUp> {pintar(true, 'menu_modulo_app')}</Hidden>
          </div>
        </div>
    );
};

export default MenuEmpresa;