import React, { useState, useEffect } from "react";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../redux/actions/actInscripcionesPosgrado";

// Material UI
import {
  Grid,
  Paper,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

// Icons
import fechaIcon from "../../../global/imagenes/calendar.png";
import permisosIcon from "../../../global/imagenes/lightbulb.png";
import gestionPrograma from "../../../global/imagenes/asignatura4.png";

// General components
import TareasTerminadas from "../../general/TareasTerminadas";
import Generica from "../../personas/generica/Generica";

// Global Functions
import { obtenerValoresFiltros, rutaImg } from "../../../global/js/funciones";

// Images
import emma_w from "../../../global/imagenes/emma_w.png";
import { consulta } from "../../../global/js/funciones";
import {
  BtnDetalle,
  BtnForm,
  BtnPermiso,
  BtnRegresar,
} from "../../general/BotonesAccion";
import ListarDatos from "../../general/ListarDatos";
import AppBarModal from "../../general/AppBarModal";
import {
  CARRERAS,
  ESTADOS,
  PERMISOS_ESTADOS,
  PERMISOS_PROGRAMAS,
} from "../helper";
import TituloAccion from "../../general/TituloAccion";
import AccionesRapidas from "../../general/AccionesRapidas";
import InscripcionActualizarPeriodo from "./InscripcionActualizarPeriodo";
import InscripcionGestionarPorgramas from "./InscripcionGestionarPorgramas";

const InscripcionesPosgradoPermisos = () => {
  const [cargando, setCargando] = useState(true);
  const [personaId, setPersonaId] = useState(null);
  const [modal, setModal] = useState(false);
  const [persona, setPersona] = useState([]);
  const [generica, setGenerica] = useState({
    id: 0,
    tipo: 0,
  });
  const [accionProceso, setAccionProceso] = useState("inicio");
  const [periodo, setPeriodo] = useState([]);

  // const { modalPeriodo, modalGestionarProgramas } = useSelector(
  //   (state) => state.redInscripcionesPosgrado
  // );

  const dispatch = useDispatch();
  const { mostrarModalPeriodo, mostrarModalGestionarProgramas } =
    bindActionCreators(actionCreators, dispatch);

  /**************************************************************************
   * * Función para obtener la información de la persona *                  *
   * @returns { Promise<any> } - Retorna un objeto con la información de la *
   *                             persona o gestores del modulo.             *
   * @description - Obtiene la información de la persona                    *
   *                                                                        *
   * ************************************************************************
   */
  const obtenerPersona = async () => {
    return new Promise((resolve) => {
      consulta(
        "api/v1.0/inscripciones_posgrado/encargados",
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  };

  useEffect(() => {
    (async () => {
      setCargando(true);
      const persona = await obtenerPersona();
      const periodoConsulta = await obtenerValoresFiltros([
        { llave: "codigo", valor: "Per_Ins_Posg" },
      ]);
      setPeriodo(periodoConsulta);
      setPersona(persona);
      setCargando(false);
    })();
  }, []);

  /*******************************************************************************
   * * Función para renderizar las acciones a realizar sobre la persona *        *
   * @param {Object} data - Objeto con la información de la persona              *
   * @returns {JSX.Element} - Retorna un componente con las acciones a realizar  *
   *                         sobre la persona                                    *
   * @description - Renderiza las acciones a realizar sobre la persona           *
   *                                                                             *
   * *****************************************************************************
   */
  const acciones = (data) => {
    const onClickEstados = (data) => {
      setPersonaId(data.id);
      setGenerica({
        id: ESTADOS,
        tipo: PERMISOS_ESTADOS,
      });
      setModal(true);
    };

    const onClickProgramas = (data) => {
      setPersonaId(data.id);
      setGenerica({
        id: CARRERAS,
        tipo: PERMISOS_PROGRAMAS,
      });
      setModal(true);
    };

    return (
      <>
        <BtnDetalle callback={() => onClickProgramas(data)} texto="Programas" />
        <BtnPermiso callback={() => onClickEstados(data)} texto="Estados" />
      </>
    );
  };

  const pintarInicioProcesos = () => {
    return (
      <div style={{ padding: "2% 2% 0 2%" }}>
        <Grid container direction={"row"} spacing={1} justifyContent="center">
          <Grid item xs={12} sm={12} md={10} lg={8}>
            <TituloAccion principal="" alterno="¿ Que deseas hacer ?" />
            <Paper elevation={0} square className="scroll contenido_fijo">
              <Grid
                container
                direction={"row"}
                spacing={1}
                style={{ width: "100%" }}
              >
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Paper
                    elevation={1}
                    style={{ border: "1px solid #e0e0e0" }}
                    onClick={() => dispatch(mostrarModalPeriodo(true))}
                  >
                    <AccionesRapidas
                      imagen={fechaIcon}
                      titulo={`¡ Actualizar Período !`}
                      sub_titulo=""
                      des_sub_titulo={
                        periodo[0].valora
                          ? `El período actual ${periodo[0].nombre} esta disponible hasta ${periodo[0].valora}`
                          : `Periodo actual ${periodo[0].nombre}`
                      }
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Paper
                    elevation={1}
                    style={{ border: "1px solid #e0e0e0" }}
                    onClick={() => setAccionProceso("permisos")}
                  >
                    <AccionesRapidas
                      imagen={permisosIcon}
                      titulo="¡ Controlar Permisos !"
                      sub_titulo=""
                      des_sub_titulo="Verifica, asigna y retira permisos a los gestores."
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Paper
                    elevation={1}
                    style={{ border: "1px solid #e0e0e0" }}
                    onClick={() =>
                      dispatch(mostrarModalGestionarProgramas(true))
                    }
                  >
                    <AccionesRapidas
                      imagen={gestionPrograma}
                      titulo="¡ Gestionar posgrados !"
                      sub_titulo=""
                      des_sub_titulo="Agrega, Modifica y Ofertar programas."
                    />
                  </Paper>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <InscripcionActualizarPeriodo
          {...{ periodo, setCargando, setPeriodo }}
        />
        <InscripcionGestionarPorgramas />
      </div>
    );
  };

  const pintarPermisosProceso = () => {
    return (
      <div>
        <ListarDatos
          id="tbl_biblioteca_permiso"
          titulo="Lista gestores"
          descargar="GESTORES INSCRIPCIONES POSGRADOS - EMMA"
          datos={persona}
          opciones={true}
          buscar={true}
          acciones={(item) => acciones(item)}
          avatarImg={true}
          avatar={({ identificacion }) => `${rutaImg}${identificacion}.jpg`}
          fila_principal={(row) =>
            `${row.primer_nombre} ${row.primer_apellido} ${row.segundo_apellido}`.toUpperCase()
          }
          filas={[
            {
              id: "NOMBRE",
              mostrar: ({ primer_nombre, primer_apellido, segundo_apellido }) =>
                `${primer_nombre} ${primer_apellido} ${segundo_apellido}`,
              enLista: false,
            },
            {
              mostrar: ({ correo }) => `${correo}`,
              id: "CORREO",
            },
            {
              mostrar: ({ identificacion }) => `${identificacion}`,
              id: "IDENTIFICACIÓN",
            },
          ]}
        />
        <Dialog
          open={modal}
          fullWidth={true}
          maxWidth={"sm"}
          onClose={() => setModal(false)}
        >
          <AppBarModal
            titulo="¡ Lista permisos !"
            mostrarModal={() => setModal(false)}
            accion={() => setModal(false)}
            titulo_accion={"CERRAR"}
          />
          <DialogContent style={{ padding: 0 }} className="scroll">
            <Generica
              notificaciones={generica.tipo === PERMISOS_ESTADOS}
              tipo={generica.tipo}
              persona={{ id: personaId }}
              genericaRelacionId={generica.id}
              titulo={"¡ Nuevo permiso !"}
              tituloInput={"Permiso"}
              tituloEliminar={"¿ Eliminar permiso ?"}
            />
          </DialogContent>
          <DialogActions>
            <BtnForm texto={"Cerrar"} callback={() => setModal(false)} />
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const accionRenderizarProcesos = () => {
    return (
      <>
        {accionProceso === "inicio" && pintarInicioProcesos()}
        {accionProceso === "permisos" && pintarPermisosProceso()}
        {accionProceso !== "inicio" && (
          <div className="regresar">
            <BtnRegresar callback={() => setAccionProceso("inicio")} />
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {cargando ? (
        <TareasTerminadas
          mensaje="Cargando..."
          marginTop="7%"
          imagen={emma_w}
          widthImg="7%"
          cargando={true}
        />
      ) : (
        <>{accionRenderizarProcesos()}</>
      )}
    </>
  );
};

export default InscripcionesPosgradoPermisos;
