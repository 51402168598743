import { Card, CardContent, CardMedia, Dialog, DialogActions, DialogContent, Grid, Typography } from '@material-ui/core';
import React, { Component } from 'react'
import AppBarModal from '../AppBarModal';
import { BtnForm } from '../BotonesAccion';
// import { mostrarModalVideos } from "../../../redux/actions/actGlobal";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { obtenerValoresFiltros } from '../../../global/js/funciones';
import TareasTerminadas from '../TareasTerminadas';
import emma_s1 from '../../../global/imagenes/emma_s1.png';

export class Videos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentVideo: null,
      videos: [],
      cargando: true,
    };
  }

  async componentDidMount() {
    let videos = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': 268 }]);
    this.setState({ videos, cargando: false })
  }

  // async componentDidUpdate({ modalVideos }) {
  //   let { modalVideos: modalVideosNew } = this.props;
  //   if (modalVideos !== modalVideosNew) {
  //     this.setState({ cargando: true })
  //     let videos = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': 268 }]);
  //     console.log(videos)
  //     this.setState({ videos, cargando: false })
  //   }
  // }

  handleVideoClick = (url) => {
    this.setState({
      currentVideo: url,
    });
  }

  render() {
    let { mostrarModalVideos, modalVideos } = this.props;
    let { currentVideo, videos, cargando } = this.state;
    const modalStyle = {
      backdropFilter: currentVideo ? 'blur(8px)' : 'none',
      transition: 'backdrop-filter 0.3s ease-out',
      backgroundColor: currentVideo ? 'rgba(0, 0, 0, 0.2)' : 'transparent',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 999,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };
    return (
      <>
        <div style={modalStyle}>
          <Dialog fullWidth={true} maxWidth="sm" open={!currentVideo && modalVideos} onClose={() => mostrarModalVideos(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <AppBarModal titulo='Casos de Éxito' mostrarModal={mostrarModalVideos} titulo_accion="" accion='' />
            <DialogContent className='scroll' >
              {videos.length < 0 && <TareasTerminadas imagen={emma_s1} mensaje='Esta bandeja está vacía' widthImg='9%' marginTop='7%' />}
              {
                !cargando && videos && videos.length > 0 ?
                  videos.map((video, index) => (
                    <Grid item xs={12} key={index} style={{ marginTop: "1rem" }} >
                      <Card onClick={() => this.handleVideoClick(video.archivo)} style={{ cursor: 'pointer', display: 'flex' }} elevation={4}>
                        <CardMedia
                          component="img"
                          alt={video.nombre}
                          image={video.valora}
                          title={video.nombre}
                          style={{ width: '40%', height: '100%' }}
                        />
                        <CardContent style={{ flex: 1 }}>
                          <Typography variant="h5" component="h2">
                            {video.nombre}
                          </Typography>
                          <Typography variant="body2" color="textSecondary" component="p">
                            {video.descripcion}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))
                  :
                  <TareasTerminadas imagen={emma_s1} mensaje='Cargando..' widthImg='9%' marginTop='7%' />
              }
            </DialogContent>
            <DialogActions>
              <BtnForm texto="CERRAR" callback={() => mostrarModalVideos(false)} />
            </DialogActions>
          </Dialog>

          <Dialog fullScreen={window.innerWidth < 600} fullWidth={true} maxWidth={'sm'} open={!!currentVideo} onClose={() => this.setState({ currentVideo: null })} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            {/* <AppBarModal titulo='Vídeos' mostrarModal={mostrarModalVideos} titulo_accion="" accion='' />  */}
            <DialogContent style={{ padding: 0 }} >
              {currentVideo && (
                <div style={{ width: '100%', height: '60vh', position: 'relative' }}>
                  <CardMedia
                    component="video"
                    src={currentVideo}
                    title="Video"
                    autoPlay
                    controls
                    controlsList="nodownload nofullscreen"
                    disablePictureInPicture
                    style={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      top: 0,
                      left: 0,
                    }}
                  />

                   {/* <iframe
                    title="Reproductor de video"
                    src={currentVideo}
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    style={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      top: 0,
                      left: 0,
                    }}
                  ></iframe>  */}
                </div>
              )}
            </DialogContent>
          </Dialog>
        </div>

      </>
    )
  }
}

export default Videos