import React from 'react'
import PropTypes from 'prop-types';
import { BtnEliminar, BtnEditar, BtnPermiso, BtnDetalle } from "../../general/BotonesAccion";
import ListarDatos from "../../general/ListarDatos";

export default function PracticasEmpresasListar(props) {

  let { mostrarModalAddValores, empresas, mostrarModalModValores, actualizarDataValor, mostrarModalDelValores, mostrarModalAddPermisoValores, mostrarModalDetValores } = props;
  const acciones = (data) => {
    const onClickDetalle = (data) => {
      actualizarDataValor(data);
      mostrarModalDetValores(true);
    }

    const onClickEliminar = (data) => {
      actualizarDataValor(data);
      mostrarModalDelValores(true);
    }

    const onClickEditar = (data) => {
      actualizarDataValor(data);
      mostrarModalModValores(true);
    }

    // const onClickSeleccionar = (data) => {
    //   actualizarDataValor(data);
    //   mostrarModalAddPermisoValores(true);
    // }

    return <div> <BtnDetalle callback={() => onClickDetalle(data)} /> <BtnEliminar callback={() => onClickEliminar(data)} /> <BtnEditar callback={() => onClickEditar(data)} /> </div>;
  }

  return (
    <ListarDatos
      datos={empresas}
      titulo='Lista Empresas'
      buscar={false}
      opciones={true}
      agregar={true}
      actAgregar={() => mostrarModalAddValores(true)}
      sub_titulo='Empresas'
      id='tbl_empresas'
      acciones={row => acciones(row)}
      fila_principal='nombre'
      filas={[
        { 'id': 'codigo' },
        { 'id': 'descripcion', 'nombre': 'actividad económica : ', 'enLista': false },
      ]}
    />
  )
}

//Props del componente
PracticasEmpresasListar.propTypes = {
  //variables
  empresas: PropTypes.array.isRequired,
  //funciones
  actualizarDataValor: PropTypes.func.isRequired,
  mostrarModalModValores: PropTypes.func.isRequired,
  mostrarModalDelValores: PropTypes.func.isRequired,
  mostrarModalAddPermisoValores: PropTypes.func.isRequired,
}


