import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { actualizarMensaje } from "../../../../../redux/actions/actGlobal";

// Material UI
import {
  AppBar,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Toolbar,
  Typography,
} from "@material-ui/core";

// Funciones generales
import TareasTerminadas from "../../../../general/TareasTerminadas";
import emma_w from "../../../../../global/imagenes/emma_w.png";
import logo_mineducacion from "../../../../../global/imagenes/logo-mineducación.png";
import logo_observatorio_laboral from "../../../../../global/imagenes/logo-observatorio-laboral-para-la-educacion.png";

// Funciones globales
import {
  consulta,
  generarFiltros,
  obtenerMisDatos,
  obtenerValores,
} from "../../../../../global/js/funciones";

// Componentes locales
import { PASOS_ENCUESTA_GRADUADOS } from "../../helper";
import PintarEncuesta from "./PintarEncuesta";
import BarraProceso from "../../../../general/BarraProceso";

const EncuestaGraduados = () => {
  const [pasos, setPasos] = useState([]);
  const [cargando, setCargando] = useState(true);
  const [iniciar, setIniciar] = useState(false);
  const [datosUsuario, setDatosUsuario] = useState({});
  const [programa, setPrograma] = useState("");
  const [programasFiltro, setProgramasFiltro] = useState([]);
  const [mensajeError, setMensajeError] = useState("");
  const [encuestaDisponible, setEncuestaDisponible] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getSurvey();
  }, []);

  const getSurvey = async () => {
    const transformar = (data) => {
      return data.map((item) => ({
        value: item.id,
        label: item.nombre,
      }));
    };
    const pasosEncuesta = await obtenerValores(PASOS_ENCUESTA_GRADUADOS);
    pasosEncuesta.sort((a, b) => (a.valora > b.valora ? 1 : -1));
    const misDatos = await obtenerMisDatos();
    await obtenerEncuestas(misDatos);
    setPasos(transformar(pasosEncuesta));
    setDatosUsuario(misDatos.persona);
    setCargando(false);
  };

  const obtenerEncuestas = async ({ persona, programas }) => {
    setCargando(true);
    let filtro = await generarFiltros([
      { llave: "usuario_registro", valor: persona.id },
    ]);
    consulta(
      `api/v1.0/matriculas/encuesta_graduados/datos?${filtro}`,
      null,
      null,
      (error, estado, resp) => {
        if (estado === 200) {
          setCargando(false);
          // * Filtrar los porgramas y solo donde programas.relacion.id === resp.programa, ademas donde resp.estado === 0. Resp puedes ser un array vacio o con varios elementos.
          const programasFiltrados =
            resp.length > 0
              ? programas
                  .map((programa) => {
                    // Buscar la encuesta correspondiente al programa
                    const encuestaCorrespondiente = resp.find(
                      (encuesta) => encuesta.programa === programa.relacion.id
                    );
                    // Si hay una encuesta correspondiente, incluir el programa con el numero_paso de la encuesta
                    if (
                      encuestaCorrespondiente &&
                      encuestaCorrespondiente.estado_encuesta === "0"
                    ) {
                      return {
                        ...programa,
                        numero_paso: encuestaCorrespondiente.numero_paso,
                      };
                      // * Si no hay una encuesta correspondiente o su estado no es "0", devolver el programa
                    } else if (!encuestaCorrespondiente) {
                      return programa;
                    }
                  })
                  .filter((programa) => programa !== undefined) // * Filtrar los programas que no tienen encuesta
              : programas;
          setProgramasFiltro(programasFiltrados);
          setEncuestaDisponible(programasFiltrados.length > 0);
        }
      }
    );
  };

  const validarPrograma = () => {
    let programaValido = !programa && "Seleccione un programa";

    if (programaValido) {
      setMensajeError(programaValido);
      dispatch(
        actualizarMensaje({
          titulo:
            "Debes seleccionar un programa para poder continuar con la encuesta.",
          tipo: "info",
          mostrar: true,
          tiempo: 4000,
        })
      );
      return true;
    } else {
      setMensajeError("");
      setIniciar(true);
      return false;
    }
  };

  const pintar = () => {
    return encuestaDisponible ? (
      <div
        style={{
          width: "100%",
          textAlign: "center",
          margin: "0 auto",
          padding: "10px 0px 30px 0px",
          height: "100hv",
          backgroundColor: "white",
        }}
      >
        <AppBar position="fixed" elevation={0}>
          <Toolbar>
            <div
              style={{
                padding: "5px 0px 5px 3px",
                color: "white",
                textAlign: "left",
              }}
            >
              <p>
                <span style={{ marginLeft: "10px" }}>
                  {"ENCUESTA GRADUADOS -OLE"}
                </span>
              </p>
            </div>
          </Toolbar>
        </AppBar>
        <Box px={5}>
          <TareasTerminadas
            marginBottom={"3px"}
            mensaje={`Hola ${datosUsuario.primer_nombre} ${datosUsuario.primer_apellido} ${datosUsuario.segundo_apellido}, soy EMMA. Te invito a responder una serie de preguntas solo te tomara unos minutos`}
            marginTop="2%"
            imagen={emma_w}
            widthImg="7%"
          />
          <div style={{ width: "100%", textAlign: "left", padding: "20px 0" }}>
            <BarraProceso steps={pasos} activeStep={programa?.numero_paso} />
          </div>
        </Box>
        <div
          style={{
            width: "30%",
            minWidth: 200,
            padding: "20px 0",
            textAlign: "left",
            margin: "0 auto",
          }}
        >
          <FormControl
            required
            className="form-control"
            error={Boolean(mensajeError)}
          >
            <InputLabel>Seleccione Programa</InputLabel>
            <Select
              id="programa"
              value={programa}
              onChange={(e) => setPrograma(e.target.value)}
              name="programa"
            >
              {programasFiltro.map((programa) => (
                <MenuItem key={programa?.relacion.id} value={programa}>
                  {programa?.relacion.nombre}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{mensajeError}</FormHelperText>
          </FormControl>
        </div>
        <Button
          variant="contained"
          color="primary"
          style={{ width: "30%", minWidth: 200, color: "white" }}
          onClick={() => validarPrograma()}
        >
          Iniciar
        </Button>
        <Grid
          container
          style={{
            margin: "0 auto",
            width: "100%",
            paddingTop: "20px",
          }}
          alignItems="stretch"
          justifyContent="center"
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={4}
            style={{
              border: "1px solid #000",
              padding: "10px",
              textAlign: "center",
            }}
          >
            <img
              src={logo_mineducacion}
              alt="Ministerio de Educación Nacional"
              style={{ width: "50%", padding: "20px 0" }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            style={{
              border: "1px solid #000",
              padding: "10px",
              textAlign: "center",
            }}
          >
            <Typography
              variant="h6"
              style={{
                fontWeight: "normal",
                fontSize: "23px",
                textAlign: "center",
                padding: "20px 0",
              }}
            >
              ENCUESTA DE SEGUIMIENTO A GRADUADOS DE EDUCACIÓN SUPERIOR EN EL
              MOMENTO 0
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            style={{
              border: "1px solid #000",
              padding: "10px",
              textAlign: "center",
            }}
          >
            <img
              src={logo_observatorio_laboral}
              alt="Observatorio Laboral para la Educación"
              style={{ width: "50%", padding: "20px 0" }}
            />
          </Grid>
        </Grid>
      </div>
    ) : (
      <TareasTerminadas
        mensaje={`Hola ${datosUsuario.primer_nombre} ${datosUsuario.primer_apellido} ${datosUsuario.segundo_apellido}, no tienes encuestas disponibles o fueron completadas anteriormente. Gracias por tu tiempo`}
        marginTop="7%"
        imagen={emma_w}
        widthImg="7%"
      />
    );
  };

  return !cargando ? (
    iniciar ? (
      <PintarEncuesta
        pasos={pasos}
        programa={programa?.relacion.id}
        pasoActualPrograma={programa?.numero_paso}
      />
    ) : (
      pintar()
    )
  ) : (
    <TareasTerminadas
      mensaje="Cargando.."
      marginTop="7%"
      imagen={emma_w}
      widthImg="7%"
      cargando={true}
    />
  );
};

export default EncuestaGraduados;
