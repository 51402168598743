import React, { useState, useEffect } from "react";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { mostrarModalAddFiltrosInsPosgrado } from "../../../redux/actions/actInscripcionesPosgrado";

// Material UI
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

// Images
import emma_w from "../../../global/imagenes/emma_w.png";

// Global Functions
import {
  obtenerValoresFiltros,
  transformar,
} from "../../../global/js/funciones";

// General Components
import BuscarPersona from "../../general/BuscarPersona";
import AppBarModal from "../../general/AppBarModal";
import InputBuscar from "../../general/InputBuscar";
import TareasTerminadas from "../../general/TareasTerminadas";
import { BtnForm } from "../../general/BotonesAccion";

// Helpers
import { CARRERAS } from "../helper";
import { actualizarMensaje } from "../../../redux/actions/actGlobal";

const InscripcionPosgradoFiltro = ({
  accionProceso,
  limpiar,
  mostrarTodo,
  filtrar,
}) => {
  const { modalAddFiltro } = useSelector(
    (state) => state.redInscripcionesPosgrado
  );

  const dispatch = useDispatch();

  const [cargando, setCargando] = useState(true);
  const [cargar, setCargar] = useState(true);
  const [programas, setProgramas] = useState([]);
  const [programa, setPrograma] = useState(null);
  const [buscarEstudiantes, setBuscarEstudiantes] = useState(false);
  const [estudiante, setEstudiante] = useState({
    id: 0,
    nombre: "",
  });

  useEffect(() => {
    async function cargarDatos() {
      if (cargar) {
        setCargando(true);
        let programas = await obtenerValoresFiltros([
          { llave: "generica", valor: CARRERAS },
          { llave: "valorc", valor: "posgrado" },
          { llave: "estado", valor: 1 },
        ]);
        setProgramas(transformar(programas));
        setCargando(false);
        setCargar(false);
      }
      // if (accionProceso !== "inicio") {
      //   setPrograma(null);
      //   setEstudiante({
      //     id: 0,
      //     nombre: "",
      //   });
      // }
    }
    cargarDatos();
  }, [modalAddFiltro, cargar]);

  const reiniciarEstados = () => {
    setPrograma(null);
    setEstudiante({
      id: 0,
      nombre: "",
    });
    limpiar();
    dispatch(mostrarModalAddFiltrosInsPosgrado(false));
  };

  const onSubmit = (e) => {
    let filtros = [];

    if (programa !== null) {
      filtros.push({ llave: "carrera", valor: programa });
    }
    if (estudiante.id) {
      filtros.push({ llave: "estudiante", valor: estudiante.id });
    }

    if (filtros.length > 0) {
      if (accionProceso === "inicio") {
        mostrarTodo("Tod_Ins");
      }
      dispatch(mostrarModalAddFiltrosInsPosgrado(false));
      filtrar(filtros);
    } else {
      dispatch(
        actualizarMensaje({
          titulo: "Seleccione un filtro",
          tipo: "info",
          mostrar: true,
          tiempo: 6000,
        })
      );
    }
    e.preventDefault();
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        open={modalAddFiltro}
        onClose={() => dispatch(mostrarModalAddFiltrosInsPosgrado(false))}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBarModal
          titulo={"Aplicar filtro"}
          mostrarModal={() =>
            dispatch(mostrarModalAddFiltrosInsPosgrado(false))
          }
          titulo_accion={"Limpiar"}
          accion={reiniciarEstados}
        />
        <DialogContent className="scroll">
          {cargando ? (
            <TareasTerminadas
              mensaje={"Cargando..."}
              marginTop={"7%"}
              imagen={emma_w}
              widthImg={"7%"}
              cargando={true}
            />
          ) : (
            <Grid container className={"margin_cont"}>
              <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                <InputBuscar
                  id={"estudiante"}
                  label={"Estudiante"}
                  placeholder={"Click para buscar"}
                  value={estudiante.nombre}
                  callback={() => setBuscarEstudiantes(true)}
                />
              </Grid>
              <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                <FormControl className="form-control">
                  <InputLabel>Seleccione programa</InputLabel>
                  <Select
                    id="carrera-seleccion"
                    name="carrera"
                    value={programa}
                    onChange={(e) => setPrograma(e.target.value)}
                  >
                    {programas.map((item, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <BtnForm
            texto="CANCELAR"
            callback={() => dispatch(mostrarModalAddFiltrosInsPosgrado(false))}
          />
          {!cargando && <BtnForm texto="FILTRAR" callback={onSubmit} />}
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        fullScreen={window.innerWidth < 600}
        open={buscarEstudiantes}
        onClose={() => setBuscarEstudiantes(false)}
      >
        <AppBarModal
          titulo={"Buscar estudiante"}
          mostrarModal={() => setBuscarEstudiantes(false)}
          titulo_accion={"CERRAR"}
          accion={() => setBuscarEstudiantes(false)}
        />
        <DialogActions style={{ padding: "0" }} className="scroll">
          <BuscarPersona
            callback={({
              id,
              primer_nombre,
              primer_apellido,
              segundo_apellido,
            }) => {
              setEstudiante({
                id,
                nombre: `${primer_nombre} ${primer_apellido} ${segundo_apellido}`,
              });
              setBuscarEstudiantes(false);
            }}
          />
        </DialogActions>
        <DialogActions>
          <BtnForm
            texto="CANCELAR"
            callback={() => setBuscarEstudiantes(false)}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InscripcionPosgradoFiltro;
