// React
import React from 'react';
//componentes
// Material Components
import { Table, TableBody, TableCell, TableRow, Chip, Link } from '@material-ui/core';
import Trofeo from '@material-ui/icons/EmojiEvents';
import SchoolIcon from '@material-ui/icons/School';
import GavelIcon from '@material-ui/icons/Gavel';
// Libraries
import moment from 'moment';
//funciones
import { obtenerTituloEstadoAdjunto } from '../funciones'
import { valor_peso } from '../../../global/js/funciones'
import ListarDatos from '../../general/ListarDatos';

export function GradoDetalleSimple({ solicitud }) {
	let {
		fecha_registro,
		tipo: { nombre: tipo },
		estado_actual: { nombre: estado },
		usuario_registro: { primer_nombre, segundo_nombre, primer_apellido, segundo_apellido, identificacion },
		programa: { nombre: programa },
	} = solicitud;
	let nombre_completo = `${primer_nombre} ${segundo_nombre} ${primer_apellido} ${segundo_apellido}`
	return (
		<Table>
			<TableBody>
				<TableRow>
					<TableCell>Solicitante</TableCell>
					<TableCell>{nombre_completo}</TableCell>
				</TableRow>
				{/* <TableRow>
					<TableCell>Identificación</TableCell>
					<TableCell>{identificacion}</TableCell>
				</TableRow> */}
				<TableRow>
					<TableCell>Tipo de Solicitud</TableCell>
					<TableCell>{tipo}</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>Estado Actual</TableCell>
					<TableCell>{estado}</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>Programa</TableCell>
					<TableCell>{programa}</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>Fecha Registro</TableCell>
					<TableCell>{moment(fecha_registro).format('YYYY-MM-DD, h:mm a')}</TableCell>
				</TableRow>
			</TableBody>
		</Table>
	)
}

export default function GradoDetalle({ solicitud }) {
	let {
		fecha_registro,
		tipo: { nombre: tipo, codigo },
		estado_actual: { nombre: estado },
		usuario_registro: { primer_nombre, segundo_nombre, primer_apellido, segundo_apellido, correo, celular, identificacion },
		programa: { nombre: programa },
		nota,
		condecoracion,
		nombre_articulo,
		estado_articulo,
		revista,
		grupo_investigacion,
		url_articulo,
		doi_articulo,
		issn_revista,
		volumen_revista,
		numero_revista,
		emisor_certificado,
		descripcion_certificado,
		motivo_rechazo,
		diplomado,
		aprobado
	} = solicitud;
	let nombre_completo = `${primer_nombre} ${segundo_nombre} ${primer_apellido} ${segundo_apellido}`
	return (
		<Table>
			<TableBody>
				<TableRow>
					<TableCell>Solicitante</TableCell>
					<TableCell>{nombre_completo}</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>Identificación</TableCell>
					<TableCell>{identificacion}</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>Correo Solicitante</TableCell>
					<TableCell>{correo}</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>Celular Solicitante</TableCell>
					<TableCell>{celular}</TableCell>
				</TableRow>
				{
					nota &&
					<TableRow>
						<TableCell>Nota Final</TableCell>
						<TableCell><Chip label={nota} icon={<Trofeo />} /></TableCell>
					</TableRow>
				}
				{
					codigo === 'gr_trabajo_grado' ?
						<TableRow>
							<TableCell>Distinción</TableCell>
							<TableCell>
								{aprobado &&
									aprobado === '1' ?
									<Chip label="Aprobado" icon={<GavelIcon />} /> : aprobado === '0' ?
										<Chip label="No Aprobado" icon={<GavelIcon />} /> :
										'No registra'
								}
								{condecoracion == 3 && <Chip label="Meritorio" icon={<GavelIcon />} />}
								{condecoracion == 4 && <Chip label="Laureado" icon={<GavelIcon />} />}
							</TableCell>
						</TableRow> :
						<TableRow>
							<TableCell>Condecoración</TableCell>
							{condecoracion == 0 && <TableCell>No registra</TableCell>}
							{condecoracion == 1 && <TableCell><Chip label="No Aprobado" icon={<GavelIcon />} /></TableCell>}
							{condecoracion == 2 && <TableCell><Chip label="Aprobado" icon={<GavelIcon />} /></TableCell>}
							{condecoracion == 3 && <TableCell><Chip label="Aprobado Meritorio" icon={<GavelIcon />} /></TableCell>}
							{condecoracion == 4 && <TableCell><Chip label="Aprobado Laureado" icon={<GavelIcon />} /></TableCell>}
							{condecoracion == 5 && <TableCell><Chip label="Aprobado en espera" icon={<GavelIcon />} /></TableCell>}
						</TableRow>
				}

				<TableRow>
					<TableCell>Tipo de Solicitud</TableCell>
					<TableCell>{tipo}</TableCell>
				</TableRow>
				{ diplomado && diplomado.nombre &&
					<TableRow>
						<TableCell>Diplomado</TableCell>
						<TableCell>{diplomado.nombre}</TableCell>
					</TableRow>
				}
				<TableRow>
					<TableCell>Estado Actual</TableCell>
					<TableCell>{estado}</TableCell>
				</TableRow>
				{motivo_rechazo &&
					<TableRow>
						<TableCell>Motivo de rechazo</TableCell>
						<TableCell>{motivo_rechazo}</TableCell>
					</TableRow>
				}
				<TableRow>
					<TableCell>Programa</TableCell>
					<TableCell>{programa}</TableCell>
				</TableRow>
				{codigo == "gr_articulo" && <>
					{nombre_articulo && <TableRow>
						<TableCell>Articulo</TableCell>
						<TableCell>{nombre_articulo}</TableCell>
					</TableRow>}
					{estado_articulo && <TableRow>
						<TableCell>Articulo Estado</TableCell>
						{estado_articulo == 1 && <TableCell>Postulado en Revista</TableCell>}
						{estado_articulo == 2 && <TableCell>En espera de Publicación</TableCell>}
						{estado_articulo == 3 && <TableCell>Publicado en Revista</TableCell>}
					</TableRow>}
					<TableRow>
						<TableCell>Articulo DOI</TableCell>
						<TableCell>{doi_articulo ? doi_articulo : 'No registra'}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>Articulo URL</TableCell>
						<TableCell>
							{url_articulo ? <Link href={url_articulo} target="_blank">Click aquí</Link> : 'No registra'}
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>Revista</TableCell>
						<TableCell>{revista ? revista : 'No registra'}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>Revista ISSN</TableCell>
						<TableCell>{issn_revista ? issn_revista : 'No registra'}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>Revista Número</TableCell>
						<TableCell>{numero_revista ? numero_revista : 'No registra'}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>Revista Volumen</TableCell>
						<TableCell>{volumen_revista ? volumen_revista : 'No registra'}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>Grupo de Investigación vinculado:</TableCell>
						<TableCell>{grupo_investigacion ? grupo_investigacion.nombre : 'No registra'}</TableCell>
					</TableRow>
				</>}
				{["gr_concurso", "gr_certificado"].includes(codigo) && <>
					<TableRow>
						<TableCell>Entidad Certificadora</TableCell>
						<TableCell>{emisor_certificado}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>{codigo == "gr_certificado" ? "Descripción certificado" : "Descripción del proyecto ganador del concurso"}</TableCell>
						<TableCell>{descripcion_certificado}</TableCell>
					</TableRow></>}
				<TableRow>
					<TableCell>Fecha Registro</TableCell>
					<TableCell>{moment(fecha_registro).format('YYYY-MM-DD, h:mm a')}</TableCell>
				</TableRow>
			</TableBody>
		</Table>
	);
}
export function Cursoslibres({solicitud}){
	return(
		<>{solicitud &&
		<ListarDatos
          datos={solicitud.curso_libre}
		  titulo='Lista de cursos libres seleccionados'
          id='tbl_cursos_libre'
		  avatar={({ nombre }) => nombre.charAt(0)}
          fila_principal={({nombre}) => nombre}
		  filas={[
          { 'id': 'Creditos', 'mostrar': ({ valora }) => valora,'nombre': "Creditos " },
          { 'id': 'precio', 'mostrar': ({ valorb }) => valor_peso(valorb),'nombre': "Valor por credito " },
           
          ]}
		/>}
		</>
	)
}
export function AdjuntoDetalle({ adjunto }) {
	let {
		tipo,
		validacion,
		requisito,
		fecha_registro,
		fecha_valida,
		nota,
		nota2,
		usuario_registro: { primer_nombre, primer_apellido, segundo_apellido },
		usuario_valida,
	} = adjunto;
	let fecha = moment(fecha_registro).format('YYYY-MM-DD, h:mm a');
	let fechav = moment(fecha_valida).format('YYYY-MM-DD, h:mm a');

	return (
		<Table>
			<TableBody>
				<TableRow key={1}>
					<TableCell component="th" scope="row">Tipo</TableCell>
					<TableCell align="left">{tipo === '1' ? 'Adjunto' : 'Acta'}</TableCell>
				</TableRow>
				{requisito &&
					<TableRow key={2}>
						<TableCell component="th" scope="row">Requisito</TableCell>
						<TableCell align="left">{requisito.nombre}</TableCell>
					</TableRow>
				}
			</TableBody>
			<TableBody>
				<TableRow key={9}>
					<TableCell component="th" scope="row">Estado</TableCell>
					<TableCell align="left">{obtenerTituloEstadoAdjunto(validacion)}</TableCell>
				</TableRow>
				<TableRow key={10}>
					<TableCell component="th" scope="row">Registrado por</TableCell>
					<TableCell align="left">{`${primer_nombre} ${primer_apellido} ${segundo_apellido}`}</TableCell>
				</TableRow>
				<TableRow key={11}>
					<TableCell component="th" scope="row">Registrado el</TableCell>
					<TableCell align="left">{fecha}</TableCell>
				</TableRow>
				{
					usuario_valida ?
						<TableRow key={12}>
							<TableCell component="th" scope="row">Verificado por</TableCell>
							<TableCell align="left">{`${usuario_valida.primer_nombre} ${usuario_valida.primer_apellido} ${usuario_valida.segundo_apellido}`}</TableCell>
						</TableRow> : <TableRow key={12}></TableRow>
				}
				{
					fecha_valida ?
						<TableRow key={13}>
							<TableCell component="th" scope="row">Verificado el</TableCell>
							<TableCell align="left">{fechav}</TableCell>
						</TableRow> : <TableRow key={13}></TableRow>
				}
				{
					nota ?
						<TableRow key={15}>
							<TableCell component="th" scope="row">Observación</TableCell>
							<TableCell align="left">{nota}</TableCell>
						</TableRow> : <TableRow key={15}></TableRow>
				}
				{
					nota2 ?
						<TableRow key={16}>
							<TableCell component="th" scope="row">Observación</TableCell>
							<TableCell align="left">{nota2}</TableCell>
						</TableRow> : <TableRow key={16}></TableRow>
				}
			</TableBody>
		</Table>
	)
}