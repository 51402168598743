import React, { Component } from 'react'
import { Dialog, DialogContent, DialogActions } from '@material-ui/core';
import AppBarModal from '../../general/AppBarModal';
import { BtnForm } from '../../general/BotonesAccion';
import { consulta, generarFiltros, transformar } from '../../../global/js/funciones'
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from '../../../global/imagenes/emma_w.png';
import PropTypes from 'prop-types';
import { ValidatorForm} from "react-material-ui-form-validator";
import { obtenerValoresFiltros, obtenerPermisosValor, obtenerValores } from "../../../global/js/funciones";
import { Id_Tipo_colegio, Id_Barrio_colegio, Id_Departamento_colegio } from '../helper';
import Input from '@material-ui/core/Input';
import { Button, Grid, InputLabel, FormControl, Select, MenuItem, TextField } from "@material-ui/core";


class PromocionEditar extends Component {
	constructor(props) {
		super(props)
		this.state = {
			id: 0,
			cargando: false,
			onChange: '',
			nombreColegio: '',
			ciudad: '',
			direccion: '',
			nombreRector: '',
			fax: '',
			nombreContacto: '',
			telefonoContacto: '',
			Tipo: '',
			barrio: '',
			correo: '',
			telefonos: '',
			paginaWeb: '',
			cargoContacto: '',
			datos: [],
			promocion: [],
			tipos: [],
			tipo_Col: '',
			showtipoColegio: false,
			barrios: [],
			barrio_Col: '',
			showBarrioColegio: false,
			departamentos: [],
			departamento: '',
			showDepColegio: false,
			showCiuColegio: false,
			ciudades: { 'label': '', 'value': '' },
			colegio: [],
		}
	}
	componentDidMount() {
		this.setState({ cargando: true })
    let { promocion} = this.props
				// this.cargarTipos()
    this.setState({ promocion, cargando: false})
	}

	async componentDidUpdate({ modalEdiPromocion }, { departamento }) {
		let { promocion, modalEdiPromocion: modalEdiPromoNew,id_promocion } = this.props;
		let { departamento: departamentoNew} = this.state;
		if (modalEdiPromoNew !== modalEdiPromocion) {
			
			if (modalEdiPromoNew) {
				this.changeState(id_promocion)
				this.obtenerTipoColegio()
				this.obtenerInformacion(promocion.id)
			}
			if(!modalEdiPromoNew){
				this.resetState()
			}
		}
		if (modalEdiPromoNew) {
			if (departamentoNew !== departamento) {
				let ciudades = await obtenerPermisosValor(departamentoNew);
				ciudades = ciudades.map(({ secundario }) => ({
					value: secundario.id,
					label: secundario.nombre,
				}));
				this.changestate('ciudades', ciudades);
			}
		}

	}

	async obtenerInformacion(id) {
		this.setState({ cargando: true });
		let f = await generarFiltros([{ 'llave': 'id', 'valor': id }]);
		let departamentos = await obtenerValoresFiltros([{ "llave": 'generica', 'valor': Id_Departamento_colegio }])
		consulta(`api/v1.0/promocion?${f}`, null, null, (error, estado, resp) => {
			if (estado === 200) this.setState({ cargando: false, colegio: resp })
			let { colegio } = this.state;
			this.setState({
				id: colegio[0] ? colegio[0].id : null,
				nombreColegio: colegio[0] ? colegio[0].nombreColegio : null,
				direccion: colegio[0] ? colegio[0].direccion : null,
				nombreRector: colegio[0] ? colegio[0].nombreRector : null,
				fax: colegio[0] ? colegio[0].fax : null,
				nombreContacto: colegio[0] ? colegio[0].nombreContacto : null,
				telefonoContacto: colegio[0] ? colegio[0].telefonoContacto : null,
				correo: colegio[0] ? colegio[0].correo : null,
				telefonos: colegio[0] ? colegio[0].telefonos : null,
				paginaWeb: colegio[0] ? colegio[0].paginaWeb : null,
				cargoContacto: colegio[0] ? colegio[0].cargoContacto : null,
				Tipo: colegio[0].Tipo ? colegio[0].Tipo.id : null,
				barrio: colegio[0].barrio ? colegio[0].barrio.id : null,
				departamento: colegio[0].departamento ? colegio[0].departamento.id : null,
				ciudad: colegio[0].ciudad ? colegio[0].ciudad.id : null,
				'departamentos': transformar(departamentos)
			})
		})
	}

	async obtenerTipoColegio() {
		let tipo_C = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': Id_Tipo_colegio }]);
		let barrio_C = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': Id_Barrio_colegio }]);
		this.setState({ tipos: tipo_C, barrios: barrio_C })
	}

	cargarTipos = async () => {
		const transformar = data => {
			return data.map(e => ({
				value: e.id,
				label: e.nombre,
				codigo: e.codigo,
			}))
		}
		const departamentos = await obtenerValores(Id_Departamento_colegio);
		this.setState({
			departamentos: transformar(departamentos)
		})
	}

	changestate = (name, value) => {
		this.setState({
			[name]: value
		})
	}

	changeState = ({ id }) => {
		this.setState({id})
	}

	onChange = ({ target }) => {
		let { value, name } = target;
		this.setState({
			[name]: value
		});
	};

	pintar() {
		let { cargando} = this.state;
		if (cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />;
		return (
			<div>
				{this.pintarPromocion()}
			</div>
		)
	}

	onSubmit = e => {
		let { id, nombreColegio, ciudad, direccion, nombreRector, fax, nombreContacto, telefonoContacto, Tipo, barrio, correo, telefonos, paginaWeb, cargoContacto, departamento } = this.state;
		this.props.editarPromocion(id, nombreColegio, ciudad, direccion, nombreRector, fax, nombreContacto, telefonoContacto, Tipo, barrio, correo, telefonos, paginaWeb, cargoContacto, departamento);
		e.preventDefault();
	}

	setSelecT = (name, show) => {
		this.setState({
			[name]: show
		})
	}

	pintarMenuItem = (lista) => {
		return Array.isArray(lista) ? lista.map(({ id, nombre }) => <MenuItem key={id} value={id}>{nombre}</MenuItem>) : [];
	}

	pintarMenuItemm = (lista) => {
		return Array.isArray(lista) ? lista.map(({ value, label }) => <MenuItem key={value} value={value}>{label}</MenuItem>) : [];
	}

	resetState = () =>{
		this.setState({
			id: 0,
			nombreColegio: '',
			direccion:'' ,
			nombreRector: '',
			fax: '',
			nombreContacto: '',
			telefonoContacto: '',
			correo:'' ,
			telefonos: '',
			paginaWeb: '',
			cargoContacto:'' ,
			Tipo: '',
			barrio:'' ,
			departamento: '',
			ciudad:'' ,
		});
	}


	render() {
		let { modalEdiPromocion, mostrarModalEditarPromocion } = this.props;
		let { nombreColegio, ciudad, direccion, nombreRector, fax, nombreContacto, telefonoContacto, Tipo, barrio, correo, telefonos, paginaWeb, cargoContacto, tipos, showtipoColegio, barrios, showBarrioColegio, departamentos, departamento, showDepColegio, showCiuColegio, ciudades } = this.state;
		return (
			<>
				<Dialog open={modalEdiPromocion} onClose={mostrarModalEditarPromocion.bind(this, false)} aria-labelledby="form-dialog-title" maxWidth='md' fullWidth={true} >
					<AppBarModal titulo='¡ Editar Promoción !' mostrarModal={mostrarModalEditarPromocion} titulo_accion='' accion='' />
					<DialogContent className='scroll' >
						<ValidatorForm onSubmit={this.onSubmit}>
							<Grid container spacing={1} style={{ marginBottom: "20px", marginTop: '10px' }}>
								<Grid item xs={6} style={{ marginTop: '8px' }}>
									<TextField
										id='nombreColegio'
										name="nombreColegio"
										label="Nombre del colegio"
										value={nombreColegio}
										type="text"
										fullWidth
										onChange={this.onChange}
										required
									/>
								</Grid>
								<Grid item xs={6}>
									<FormControl className='form-control' style={{ marginTop: 8 }}>
										<InputLabel id='departamento' ref={235} htmlFor="departamento">Seleccione el departamento</InputLabel>
										<Select
											displayEmpty={false}
											input={<Input labelWidth={235} name="departamento" id="departamento" />}
											open={showDepColegio}
											onClose={this.setSelecT.bind(this, "showDepColegio", false)}
											onOpen={this.setSelecT.bind(this, "showDepColegio", true)}
											value={departamento}
											onChange={this.onChange}
										>
											{this.pintarMenuItemm(departamentos)}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={6} >
									<FormControl  className='form-control' style={{ marginTop: 8 }}>
										<InputLabel id='ciudad' ref={235} htmlFor="ciudad">Seleccione la ciudad</InputLabel>
										<Select
											input={<Input labelWidth={235} name="ciudad" id="ciudad" />}
											open={showCiuColegio}
											onClose={this.setSelecT.bind(this, "showCiuColegio", false)}
											onOpen={this.setSelecT.bind(this, "showCiuColegio", true)}
											value={ciudad}
											onChange={this.onChange}
										>
											{this.pintarMenuItemm(ciudades)}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={6}>
									<FormControl className='form-control' style={{ marginTop: 8 }}>
										<InputLabel id='Tipo' ref={235} htmlFor="Tipo">Seleccione el tipo de Colegio</InputLabel>
										<Select
											input={<Input labelWidth={235} name="Tipo" id="Tipo" />}
											open={showtipoColegio}
											onClose={this.setSelecT.bind(this, "showtipoColegio", false)}
											onOpen={this.setSelecT.bind(this, "showtipoColegio", true)}
											value={Tipo}
											onChange={this.onChange}
										>
											{this.pintarMenuItem(tipos)}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={6}>
									<FormControl className='form-control' style={{ marginTop: 8 }}>
										<InputLabel id='barrio' ref={295} htmlFor="barrio">Seleccione el barrio</InputLabel>
										<Select
											input={<Input labelWidth={295} name="barrio" id="barrio" />}
											open={showBarrioColegio}
											onClose={this.setSelecT.bind(this, "showBarrioColegio", false)}
											onOpen={this.setSelecT.bind(this, "showBarrioColegio", true)}
											value={barrio}
											onChange={this.onChange}
										>
											{this.pintarMenuItem(barrios)}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={6} style={{ marginTop: '8px' }}>
									<TextField
										value={direccion}
										// required
										type="text"
										id="direccion"
										name="direccion"
										label="Dirección"
										fullWidth
										onChange={this.onChange}
									/>
								</Grid>
								<Grid item xs={6} style={{ marginTop: 8 }}>
									<TextField
										id="correo"
										name="correo"
										label="E-mail"
										value={correo}
										type="text"
										fullWidth
										onChange={this.onChange}
									/>
								</Grid>
								<Grid item xs={6} style={{ marginTop: 8 }}>
									<TextField
										value={nombreRector}
										id="nombreRector"
										name="nombreRector"
										label="Nombre Rector"
										type="text"
										fullWidth
										onChange={this.onChange}
									/>
								</Grid>
								<Grid item xs={6} style={{ marginTop: 8 }}>
									<TextField
										id="telefonos"
										name="telefonos"
										label="Teléfonos"
										value={telefonos}
										type="text"
										fullWidth
										onChange={this.onChange}
										// required
									/>
								</Grid>
								<Grid item xs={6} style={{ marginTop: 8 }}>
									<TextField
										id="fax"
										name="fax"
										label="Fax"
										value={fax}
										type="text"
										fullWidth
										onChange={this.onChange}
									/>
								</Grid>
								<Grid item xs={6} style={{ marginTop: 8 }}>
									<TextField
										id="paginaWeb"
										name="paginaWeb"
										label="Página Web"
										value={paginaWeb}
										type="text"
										fullWidth
										onChange={this.onChange}
									/>
								</Grid>
								<Grid item xs={6} style={{ marginTop: 8 }}>
									<TextField
										id="nombreContacto"
										name="nombreContacto"
										label="Nombre del contacto"
										value={nombreContacto}
										type="text"
										fullWidth
										onChange={this.onChange}
									/>
								</Grid>
								<Grid item xs={6} style={{ marginTop: 8 }}>
									<TextField
										id="cargoContacto"
										name="cargoContacto"
										label="Cargo del contacto"
										value={cargoContacto}
										type="text"
										fullWidth
										onChange={this.onChange}
									/>
								</Grid>
								<Grid item xs={6} style={{ marginTop: 8 }}>
									<TextField
										id="telefonoContacto"
										name="telefonoContacto"
										label="Teléfonos del contacto"
										value={telefonoContacto}
										type="text"
										fullWidth
										onChange={this.onChange}
									/>
								</Grid>
							</Grid>
							<Button color="primary" type="submit" id='enviar_form_mod_promocion' className="oculto"> Guardar </Button>
						</ValidatorForm>

					</DialogContent>
					<DialogActions>
						<BtnForm texto="CANCELAR" callback={() => mostrarModalEditarPromocion(false)} />
						<BtnForm texto="GUARDAR" callback={() => { document.getElementById('enviar_form_mod_promocion').click() }} />
					</DialogActions>
				</Dialog>
			</>
		);
	}
}

PromocionEditar.propTypes = {
	modalEdiPromocion: PropTypes.bool.isRequired,
	mostrarModalEditarPromocion: PropTypes.func.isRequired,
	editarPromocion: PropTypes.func.isRequired,
}

export default PromocionEditar;