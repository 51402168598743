import React from 'react'
import PropTypes from 'prop-types';
import { BtnEliminar, BtnEditar, BtnDetalle, BtnGestionar,BtnValidar } from "../general/BotonesAccion";
import ListarDatos from '../general/ListarDatos';
import { api } from "../../global/js/funciones";


class PersonasListar extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      dato_buscar: ''
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let { obtenerPersonas } = this.props
    if (this.state.dato_buscar !== prevState.dato_buscar) {
      obtenerPersonas(this.state.dato_buscar)
    }
  }


  render() {
    let { cargando, personas, mostrarModalDetallePersona, mostrarModalModPersona, actualizarDataPersona, mostrarModalDelPersona, mostrarModalAddPersona, mostrarModalCambiarPassword, id_persona,administrador } = this.props;
    const actions = (rowData, tipo = "acciones") => {
      rowData.identificacion = parseInt(rowData.identificacion);
      rowData.login_directorio = parseInt(rowData.login_directorio);

      const onClickEliminar = (data) => {
        actualizarDataPersona(data);
        mostrarModalDelPersona(true);

      }

      // const onClickValidar = (data) => {
      //   actualizarDataPersona(data);
      //   mostrarModalDelPersona(true);

      // }

      const onClickEditar = (data) => {
        actualizarDataPersona(data);
        mostrarModalModPersona(true);
      }

      const onClickDetalle = (data) => {
        actualizarDataPersona(data);
        mostrarModalDetallePersona(true);
      }

      const onClickPassword = data => {
        actualizarDataPersona(data)
        id_persona(data.id)
        mostrarModalCambiarPassword(true)
      }
      let resp = <BtnDetalle callback={() => onClickDetalle(rowData)} />
      // let validar = <BtnValidar callback={() => onClickValidar(rowData)} />
      if (tipo === "acciones") resp = <div> <BtnDetalle callback={() => onClickDetalle(rowData)} /> <BtnEditar callback={() => onClickEditar(rowData)} /> <BtnGestionar callback={() => onClickPassword(rowData)} /></div>;
      return resp;
    }

    let { dato_buscar } = this.state;

    return (
      <div>
        {/* <TituloAccion buscar={true} dato_buscar={dato_buscar} callback={(dato_buscar) => this.setState({ dato_buscar })} /> */}
        <ListarDatos
          id="tbl_listar_valores"
          titulo={"Lista Personas"}
          datos={personas}
          opciones={true}
          agregar={true}
          buscar={true}
          cargando={cargando}
          actAgregar={() => mostrarModalAddPersona(true)}
          avatarImg={true}
          css={{
            list: { padding: '0px 0px 20px 0px' },
            appBar: { padding: '0px 0px 0px 0px' },
          }}
          actBuscar={(dato_buscar) => this.setState({ dato_buscar })}
          datoBuscarAlt={dato_buscar}
          avatar={() => ''}
          acciones={(row) => actions(row)}
          fila_principal={({ primer_nombre, segundo_nombre, primer_apellido, segundo_apellido }) => `${primer_nombre} ${segundo_nombre} ${primer_apellido} ${segundo_apellido}`.toUpperCase()}
          filas={[
            { 'id': 'id', 'enLista': true },
            { 'id': 'usuario', 'enLista': true },
            { 'id': 'identificacion', 'enLista': true },
            { 'id': 'correo', 'enLista': false },
          ]}

        />
      </div>
    );
  }

}
//Props del componente
PersonasListar.propTypes = {
  //variables
  personas: PropTypes.array.isRequired,
  acciones: PropTypes.bool,
  //funciones
  actualizarDataPersona: PropTypes.func.isRequired,
  mostrarModalAddPersona: PropTypes.func.isRequired,
  mostrarModalModPersona: PropTypes.func.isRequired,
  mostrarModalCambiarPassword: PropTypes.func.isRequired,
  mostrarModalDelPersona: PropTypes.func.isRequired,
  mostrarModalDetallePersona: PropTypes.func.isRequired,
  obtenerPersonas: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
}


export default PersonasListar;


