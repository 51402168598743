import React from 'react'
import { Typography, Grid, } from '@material-ui/core'
import '../../../global/css/stylePortal.css'

const DetalleExp = (props) => {
    let { cargo, empresa, fecha_final_exp, fecha_inicio_exp, responsabilidades, en_curso_lab } = props.detalle
    let x = ''
    if(en_curso_lab == "1") x = 'Presente' 
    else x = fecha_final_exp.substring(0, 4)
    return (
        <>
           {
            (empresa != null) ?
            <Grid item xs={12} md={12} style={{'marginBottom': '30px'}}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography variant="h4" className="__titulo">
                        {cargo}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h6" className="__fecha">
                        {`(${fecha_inicio_exp.substring(0, 4)} - ${x})`}
                    </Typography>
                </Grid>
            </Grid>
            <Typography variant="h6" className="__lugar">
                {empresa}
            </Typography>
            <Typography component="p" className='__textJustify'>
                {responsabilidades}
            </Typography>
        </Grid>
            : <div className="__sindatos"> No cuento con experiencia laboral </div>
           }
        </>
    )
}

export default DetalleExp