import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Select, FormControl, MenuItem, InputLabel, StepLabel, Step, Stepper, TextField, Paper,
  Dialog, DialogContent, DialogActions, Grid, List, FormControlLabel, Checkbox
} from '@material-ui/core';
import AppBarModal from '../../general/AppBarModal'
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from '../../../global/imagenes/emma_w.png';
import { BtnCargar, BtnForm } from "../../general/BotonesAccion";
import AlertasSimple from '../../general/AlertasSimple';
import { obtenerPermisosValor, obtenerValoresFiltros } from '../../../global/js/funciones';
import EnviarMultiplesArchivos from "../../general/EnviarMultiplesArchivos";
import BuscarSelect from "../../general/BuscarSelect";
import { TIPOS_IDENTIFICACION, DEPARTAMENTOS, COMUNIDAD, GENERO, AREA_DERECHO, REQUISITOS_CONSULTORIA, TIPOS_CONSULTORIA } from '../helper';

const configStep = ['Datos del Solicitante', 'Hechos', 'Requisitos'];

function MisDatos(component_props) {
  let { documento, primer_nombre, segundo_nombre, primer_apellido, segundo_apellido, direccion, barrio, telefono, email, onChange,
    tipos_identificacion, tipo_identificacion, genero, generos, ciudad, ciudades, departamento,
    departamentos, comunidad, comunidades, discapacidad, discapacidades, programa, anho, egresado,
    checked, programas, setPrograma } = component_props
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={egresado}
                onChange={checked}
                id="egresado"
                name="egresado"
                color="primary"
              />
            }
            label="¿Eres Egresado CUC?"
          />
        </Grid>

        <Grid item xs={6}>
          <FormControl className='form-control' required>
            <InputLabel>Tipo Identificacion</InputLabel>
            <Select id='tipo_identificacion' value={tipo_identificacion} onChange={onChange} name='tipo_identificacion'>
              {
                tipos_identificacion.map((datos) => (
                  <MenuItem key={datos.id} value={datos.id}>{datos.nombre}</MenuItem>
                ))
              }

            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={documento}
            required
            type="number"
            id="documento"
            name="documento"
            label="Numero documento"
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={primer_nombre}
            required
            type="text"
            id="primer_nombre"
            name="primer_nombre"
            label="Primer Nombre"
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={segundo_nombre}
            type="text"
            id="segundo_nombre"
            name="segundo_nombre"
            label="Segundo Nombre"
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={primer_apellido}
            required
            type="text"
            id="primer_apellido"
            name="primer_apellido"
            label="Primer Apellido"
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={segundo_apellido}
            type="text"
            id="segundo_apellido"
            name="segundo_apellido"
            label="Segundo Apellido"
            fullWidth
            onChange={onChange}
          />
        </Grid>
        {egresado === true && (<>
          <Grid item xs={6}>
            <BuscarSelect datos={programas} cambiarEstado={(value) => setPrograma(value)} valor={programa} id='select-programa' nombre='Programa' placeholder='Seleccione Programa de Egreso' />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={anho}
              type="number"
              id="anho"
              name="anho"
              label="Año de Egreso"
              fullWidth
              onChange={onChange}
            />
          </Grid>
        </>
        )}
        <Grid item xs={6}>
          <TextField
            value={direccion}
            required
            type="text"
            id="direccion"
            name="direccion"
            label="Direccion"
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={barrio}
            required
            type="text"
            id="barrio"
            name="barrio"
            label="Barrio"
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl className='form-control' required>
            <InputLabel>Departamento</InputLabel>
            <Select id='departamento' value={departamento} onChange={onChange} name='departamento'>
              {
                departamentos.map((datos) => (
                  <MenuItem key={datos.id} value={datos.id}>{datos.nombre}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl className='form-control' required>
            <InputLabel>Ciudad</InputLabel>
            <Select id='ciudad' value={ciudad} onChange={onChange} name='ciudad'>
              {
                ciudades.map(({ secundario }) => (
                  <MenuItem key={secundario.id} value={secundario.id}>{secundario.nombre}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={telefono}
            required
            type="number"
            id="telefono"
            name="telefono"
            label="Telefono"
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={email}
            type="text"
            id="email"
            name="email"
            label="Email"
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl className='form-control' required>
            <InputLabel>Genero</InputLabel>
            <Select id='genero' value={genero} onChange={onChange} name='genero'>
              {
                generos.map((datos) => (
                  <MenuItem key={datos.id} value={datos.id}>{datos.nombre}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl className='form-control' required>
            <InputLabel>Comunidad Etnica</InputLabel>
            <Select id='comunidad' value={comunidad} onChange={onChange} name='comunidad'>
              {
                comunidades.map((datos) => (
                  <MenuItem key={datos.id} value={datos.id}>{datos.nombre}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className='form-control' required>
            <InputLabel>Discapacidad</InputLabel>
            <Select id='discapacidad' value={discapacidad} onChange={onChange} name='discapacidad'>
              {
                discapacidades.map((datos) => (
                  <MenuItem key={datos.value} value={datos.value}>{datos.label}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  )
}
function Hechos(component_props) {
  let { hechos, onChange, area, area_derecho, Pretensiones, tipo_consultoria, tipos_consultorias } = component_props
  return (
    <>
      <Grid container spacing={2} style={{ marginBotton: '40px' }}>
        <Grid item xs={12}>
          <FormControl className='form-control' required>
            <InputLabel>Tipo de Asesoría</InputLabel>
            <Select id='tipo_consultoria' value={tipo_consultoria} onChange={onChange} name='tipo_consultoria'>
              {
                tipos_consultorias.map((datos) => (
                  <MenuItem key={datos.id} value={datos.id}>{datos.nombre}</MenuItem>
                ))
              }

            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className='form-control' required>
            <InputLabel>Area del Derecho</InputLabel>
            <Select id='area' value={area} onChange={onChange} name='area'>
              {
                area_derecho.map((datos) => (
                  <MenuItem key={datos.id} value={datos.id}>{datos.nombre}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={Pretensiones}
            required
            type="text"
            id="Pretensiones"
            name="Pretensiones"
            label="Pretensiones"
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={hechos}
            required
            type="text"
            id="hechos"
            name="hechos"
            label="Escriba los hechos"
            multiline
            fullWidth
            rows={8}
            onChange={onChange}
          />
        </Grid>

      </Grid>
    </>
  );
}

function Requisitos(component_props) {

  let { submit, requisitos } = component_props

  let obligatorio = []
  requisitos.map((info) => {
    if (info.valora === '1') {
      obligatorio.push({ item: info.nombre })
    }
  })

  const trasnformarDataAdj = (data) => {
    let r = [];
    data.map((info) => r.push({ nombre: info.nombre, /*descripcion: info.descripcion,*/ codigo: info.id, archivo: info.archivo, formatos: info.valorg, obligatorio: info.valora }))
    return r;
  }

  const acciones = (data, callback) => {
    const onClickCargar = (data) => callback(data);

    let cargar = <BtnCargar callback={() => onClickCargar(data)} color='#00bfa5' texto='Gestionar' />

    return <div>{cargar}</div>;
  }

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper elevation={0} square>
            <List component="nav" arial-label="main">
              <EnviarMultiplesArchivos
                acciones={acciones}
                enModal={false}
                enviarArchivos={(archivos) => submit(archivos)}
                limite={3}
                mostrarMensaje={false}
                enLista={true}
                lista={trasnformarDataAdj(requisitos)}
                formatos={true}
              />
            </List>
          </Paper>
        </Grid>
        <AlertasSimple
          tipo='info'
          titulo={'Estimado, tener en cuenta que estos documentos son obligatorios: '}
          lista={obligatorio}
          margin='0px'
        />
      </Grid>
    </React.Fragment>
  );
}
class ConsultoriaAgregar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      activeStep: 0,
      cargando: false,
      titulo_res: 'Cargando',
      archivos: [],
      tipo_identificacion: '',
      hechos: '',
      Pretensiones: '',
      primer_nombre: '',
      primer_apellido: '',
      segundo_nombre: '',
      segundo_apellido: '',
      telefono: '',
      email: '',
      barrio: '',
      direccion: '',
      documento: '',
      genero: '',
      departamento: '',
      ciudad: '',
      comunidad: '',
      discapacidad: '',
      area: '',
      ciudades: [],
      egresado: false,
      anho: '',
      programa: '',
      Pretensiones: '',
      tipo_consultoria: '',
      discapacidades: [
        {
          value: '1',
          label: 'SI',
        },
        {
          value: '0',
          label: 'NO',
        }
      ],
      tipos_identificacion: [],
      generos: [],
      departamentos: [],
      comunidades:[],
      area_derecho:[],
      requisitos: [],
      programas: [],
      tipos_consultorias: [],
      ciudades:[],
      cargar: true
    }
  }

  async componentDidUpdate({ modalAddConsultas }, { departamento }) {
    let { modalAddConsultas: modalAddConsultasNew } = this.props;

    let { departamento: departamentoNew, cargar } = this.state;
    if (departamentoNew !== departamento) {
      let ciudades = await obtenerPermisosValor(departamentoNew);
      this.setState({ ciudades })
    }

    if (modalAddConsultas !== modalAddConsultasNew) {
      if (modalAddConsultasNew && cargar) {
        this.setState({ cargando: true })
        let tipos_identificacion = await obtenerValoresFiltros([{"llave": 'generica', 'valor': TIPOS_IDENTIFICACION}])
        let generos = await obtenerValoresFiltros([{"llave": 'generica', 'valor': GENERO}])
        let departamentos = await obtenerValoresFiltros([{"llave": 'generica', 'valor': DEPARTAMENTOS}])
        let comunidades = await obtenerValoresFiltros([{"llave": 'generica', 'valor': COMUNIDAD}])
        let area_derecho = await obtenerValoresFiltros([{"llave": 'generica', 'valor': AREA_DERECHO}])
        let requisitos = await obtenerValoresFiltros([{"llave": 'generica', 'valor': REQUISITOS_CONSULTORIA}])
        let programas = await obtenerValoresFiltros([{"llave": 'generica', 'valor': 5}])
        let tipos_consultorias = await obtenerValoresFiltros([{"llave": 'generica', 'valor': TIPOS_CONSULTORIA}])

        const transformar = data => {
          return data.map(e => ({
            value: e.id,
            label: e.nombre,
            codigo: e.codigo,
          }));
        }
        this.setState({ tipos_identificacion, generos, departamentos, comunidades, area_derecho, requisitos, programas: transformar(programas), tipos_consultorias, cargando: false, cargar: false })
      }
    }
  }


  handleStep = (direction) => {
    let { actualizarMensaje } = this.props
    let { activeStep, area, ciudad, departamento, discapacidad, genero, comunidad, tipo_identificacion, hechos, documento, primer_nombre, primer_apellido, direccion, barrio, telefono, Pretensiones } = this.state
    let sw = true
    if ((!departamento || !ciudad || !comunidad || !discapacidad || !genero || !tipo_identificacion || !documento || !primer_nombre || !primer_apellido || !direccion || !barrio || !telefono) && activeStep === 0) {
      actualizarMensaje({ titulo: 'Debe diligenciar todos los campos obligatorios para continuar', tipo: 'info', mostrar: true, tiempo: 6000 });
      sw = false

    }
    if ((!area || !hechos || !Pretensiones) && activeStep === 1) {
      actualizarMensaje({ titulo: 'Debe diligenciar el campo para continuar', tipo: 'info', mostrar: true, tiempo: 6000 });
      sw = false
    }
    if (sw) this.setState({ activeStep: direction ? activeStep + 1 : activeStep - 1 })
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }


  changeState = (name, value) => {
    this.setState({
      [name]: value
    })
  }

  checked = ({ target }) => {
    this.setState({
      [target.name]: target.checked
    })
  }

  submitDatos = () => {
    document.getElementById('enviar_form_multi_archivo').click()
  }

  submit = (archivos) => {
    let { requisitos } = this.state;
    let { actualizarMensaje } = this.props;
    let contador = 0
    let contador2 = 0
    requisitos.map((info) => {
      if (info.valora === '1') {
        contador = contador + 1;
        archivos.map((j) => {
          if (j.codigo === info.id) {
            contador2 = contador2 + 1
          }
        })
      }

    })
    if (contador2 >= contador) {
      this.enviarDatos(archivos)
    } else actualizarMensaje({ titulo: 'Debe adjuntar los documentos que son obligatorio', tipo: 'info', mostrar: true, tiempo: 6000 });

  }

  enviarDatos = async (archivos) => {
    this.setState({ cargando: true, titulo_res: 'Guardando..'})
    let { tipo_identificacion, ciudad, departamento, primer_nombre, genero, discapacidad,
      comunidad, area, primer_apellido, segundo_nombre, segundo_apellido, telefono, email, barrio,
      direccion, documento, hechos, Pretensiones, programa, anho, tipo_consultoria } = this.state
    const data = new FormData();
    archivos.map(({ archivo, codigo }, index) => {
      data.append(`archivo${index}`, archivo);
      data.append(`codigo${index}`, codigo);
      return true;
    });
    data.append('total', archivos.length);
    data.append('hechos', hechos);
    data.append('Pretensiones', Pretensiones);
    data.append('discapacidad', discapacidad);
    data.append('tipo_identificacion', tipo_identificacion);
    data.append('identificacion', documento);
    data.append('telefono', telefono);
    data.append('correo', email == "" ? 'consultario' + documento + '@cuc.edu.co' : email);
    data.append('area', area);
    data.append('primer_nombre', primer_nombre.toUpperCase());
    data.append('segundo_nombre', segundo_nombre.toUpperCase());
    data.append('primer_apellido', primer_apellido.toUpperCase());
    data.append('segundo_apellido', segundo_apellido.toUpperCase());
    data.append('departamento', departamento);
    data.append('ciudad', ciudad);
    data.append('comunidad', comunidad);
    data.append('genero', genero);
    data.append('lugar_residencia', barrio);
    data.append('direccion', direccion);
    data.append('id_asociado', 0);
    data.append('tipo_consultoria', tipo_consultoria);
    if (programa !== '') {
      data.append('programa', programa.value);
    }
    if (anho !== '') {
      data.append('anho', anho);
    }
    this.props.guardarConsulta(data, () => this.setState({ cargando: false, titulo_res: 'Cargando' }));
    this.reiniciarEstados()
  }

  reiniciarEstados() {
    this.setState({
      activeStep: 0,
      archivos: [],
      tipo_identificacion: '',
      hechos: '',
      Pretensiones: '',
      primer_nombre: '',
      primer_apellido: '',
      segundo_nombre: '',
      segundo_apellido: '',
      telefono: '',
      email: '',
      barrio: '',
      direccion: '',
      documento: '',
      genero: '',
      departamento: '',
      ciudad: '',
      comunidad: '',
      discapacidad: '',
      area: '',
      anho: '',
      programa: '',
      ciudades: [],
      tipo_consultoria: ''
    })
  }


  configStepContent = () => {
    let { activeStep, egresado, tipo_identificacion, ciudad, programa, anho, departamento, primer_nombre, genero, ciudades, discapacidad, comunidad, area, primer_apellido, segundo_nombre, segundo_apellido, telefono, email, barrio, direccion, documento, hechos, Pretensiones, tipo_consultoria, tipos_identificacion, generos, departamentos, comunidades, area_derecho, requisitos, programas, tipos_consultorias, discapacidades } = this.state
    let { actualizarMensaje } = this.props
    switch (activeStep) {
      case 0:
        return (
          <MisDatos{...{
            tipos_identificacion,
            tipo_identificacion,
            ciudades,
            ciudad,
            egresado,
            programas,
            programa,
            anho,
            comunidad,
            comunidades,
            discapacidad,
            discapacidades,
            departamentos,
            departamento,
            onChange: this.onChange,
            checked: this.checked,
            setPrograma: (value) => {
              this.changeState('programa', value)
            },
            primer_nombre,
            primer_apellido,
            segundo_nombre,
            segundo_apellido,
            telefono,
            email,
            barrio,
            direccion,
            documento,
            generos,
            genero,
            area
          }}
          />
        )
      case 1:
        return (
          <Hechos{...{
            onChange: this.onChange,
            actualizarMensaje,
            hechos,
            area,
            area_derecho,
            Pretensiones,
            tipo_consultoria,
            tipos_consultorias
          }}
          />
        )
      case 2:
        return (
          <Requisitos{...{
            requisitos,
            submit: this.submit,
          }}
          />
        )
      default:
        return <TareasTerminadas imagen={emma_w} mensaje='Esta bandeja esta vacia' widthImg='10%' marginTop='10%' />

    }
  }
  render() {
    let { modalAddConsultas, mostrarModalAddConsultas } = this.props
    let { activeStep, cargando, titulo_res } = this.state
    return (
          <Dialog fullWidth={true} maxWidth="md" open={modalAddConsultas} onClose={() => mostrarModalAddConsultas(false)}>
            <AppBarModal titulo={"Consultas Juridicas"} mostrarModal={() => mostrarModalAddConsultas(false)} titulo_accion="Cerrar" accion={() => mostrarModalAddConsultas(false)} />
            <DialogContent className='scroll'>
              { !cargando ?
              <Grid container>
                <Grid item xs={12} md={12} >
                  <Stepper activeStep={activeStep}>
                    {
                      configStep.map(label => (
                        <Step key={label}>
                          <StepLabel><p>{label}</p></StepLabel>
                        </Step>
                      ))
                    }
                  </Stepper>
                </Grid>
                <Grid item xs={12} md={12}>
                  {this.configStepContent()}
                </Grid>
              </Grid>
              : <TareasTerminadas mensaje={titulo_res} marginTop="7%" imagen={emma_w} widthImg="7%" cargando={true}/>
              }
            </DialogContent>
            <DialogActions>
              {activeStep > 0 ?
                activeStep > 1 ?
                  <div>
                    <BtnForm texto="ATRAS" callback={() => this.handleStep(false)} />
                    {!cargando && <BtnForm texto="GUARDAR" callback={() => this.submitDatos()} />}
                  </div>
                  : <div>
                    <BtnForm texto="ATRAS" callback={() => this.handleStep(false)} />
                    {!cargando && <BtnForm texto="SIGUIENTE" callback={() => this.handleStep(true)} />}
                  </div>
                : <>{!cargando && <BtnForm texto="SIGUIENTE" callback={() => this.handleStep(true)} />}</>
              }
            </DialogActions>
          </Dialog >
    )

  }
}
ConsultoriaAgregar.propTypes = {
  //variables
  actualizarMensaje: PropTypes.func.isRequired,

}

export default ConsultoriaAgregar;