const MODAL_ADD_REA = 'MODAL_ADD_REA' 
const MODAL_DET_REA = 'MODAL_DET_REA'
const MODAL_FIL_REA = 'MODAL_FIL_REA'
const MODAL_FIL_DES_REA = 'MODAL_FIL_DES_REA'
const MODAL_DET_GRUPO = 'MODAL_DET_GRUPO'
const MODAL_REP_REA = 'MODAL_REP_REA'

const mostrarModalAddRea = show => {
    return {
      type: MODAL_ADD_REA,
      show,
    }
  }
const mostrarModalDetalleRea = show => {
    return {
      type: MODAL_DET_REA,
      show,
    }
  }
const mostrarModalFiltroRea = show => {
    return {
      type: MODAL_FIL_REA,
      show,
    }
  }
const mostrarModalFiltroReaDescarga = show => {
    return {
      type: MODAL_FIL_DES_REA,
      show,
    }
  }

  const mostrarModalDetalleGrupo = show => {
    return {
      type: MODAL_DET_GRUPO,
      show,
    }
  }

  const mostrarModalReportRea = show => {
    return {
      type: MODAL_REP_REA,
      show,
    }
  }
  
module.exports = {
    MODAL_ADD_REA,
    MODAL_DET_REA,
    MODAL_FIL_REA,
    MODAL_FIL_DES_REA,
    MODAL_DET_GRUPO,
    MODAL_REP_REA,
    mostrarModalAddRea,
    mostrarModalDetalleRea,
    mostrarModalFiltroRea,
    mostrarModalFiltroReaDescarga,
    mostrarModalDetalleGrupo,
    mostrarModalReportRea
  }
  