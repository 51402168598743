import {
	obtenerValoresFiltros,
	consulta,
	mostrarError,
	Toast,
	personaSesionGenericas
} from '../../../global/js/funciones';

export const DEPARTAMENTOS = 7;
export const DIPLOMADOS = 24; //29;
export const POSGRADOS = 29; // 28; 
export const CURSOS_LIBRES = 31; // 30;
export const MATERIAS = 32; // 31;
export const ESTADOS_ID = 33; // 32;   
export const PROCESOS = 34 // 33;  
export const PROGRAMAS = 5;
export const CURSOS_LIBRES_PRE = 46; // 44;
export const NOTAS = 47; // 45;
export const FECHASGRADO = 209 //2149 // 2214;
export const MENCIONES = 200 //2150 // 2215;
export const TIPO_IDENTIFICACION = 2;
export const ASPIRANTE = 210 //2131 // 2216;
export const OPCION_GRADO2 = 211 //2132 // 2217;
export const ESTRATOS = 9;
export const SEXO = 69; // 67;
export const EGRESADO = 212 //2133 //2218;
export const TRABAJA = 213 //2134 // 2219; 
export const ACTIVIDAD_LABORAL = 214 //2135 //2220; 
export const RANGO_SALARIAL = 215 //2136 // 2221; 
export const ACEPTAR_SOLICITUD = 216 //2137 // 2222;
export const TIPOS_EXONERACION = 217 //2138 // 2223; 
export const TIPOS_ESTUDIO = 218 //2139 // 2224;
export const REQUISITOS = 219 //2140 // 2225; 
export const CERTIFICADOS = 286 //2141 // 2226;
export const TIPO_CERTIFICADOS =  287 //2142 // 2227;  
export const PERIODO_CERTIFICADO = 288 //2143 // 2228; 
export const MODALIDAD_VIRTUAL = ['pregrado_virtual'];
export const DEPARTAMENTOS_ACADEMICOS=  144 //2144
export const MOTIVOS_SOLICITUD_NOTAS = 284 //2145;
// Encuesta graduados
export const PASOS_ENCUESTA_GRADUADOS = 293; 
export const PREGUNTAS_ENCUESTA_GRADUADOS = 294;
export const RESPUESTAS_ENCUESTA_GRADUADOS = 295;
export const OPCION_OTRO_ENCUESTA_GRADUADOS = 30131;
export const OPCION_NO_APLICA = 29992;

export const arrayOpcion2Validos =[
	21409, // Articulo Cientifico
	21410, // Cursos LIbres
	21413, // Otro
	21414 // Trabajo de Grado
]
export const idOpcionNoTrabaja = 21421;
export const idOpcionNoAplicaEgresado = 21418;


export const getFormValues = (parametro) => {
	switch (parametro) {
		case DIPLOMADOS:
			return [
				{
					name: 'nombre',
					type: 'text',
					label: 'Nombre Diplomado'
				},
				{
					name: 'valora',
					type: 'number',
					label: 'Valor Diplomado'
				}
			];
		case PROGRAMAS:
			return [ { name: 'nombre', type: 'text', label: 'Nombre Programa Pregrado' } ];
		case POSGRADOS:
			return [ { name: 'nombre', type: 'text', label: 'Nuevo programa Posgrado' } ];

		case MATERIAS:
			return [
				{
					name: 'nombre',
					type: 'text',
					label: 'Nombre Materia'
				},
				{
					name: 'valora',
					type: 'number',
					label: 'Créditos'
				},
				{
					name: 'valord',
					type: 'text',
					label: 'Horario',
				},
				{
					name: 'valore',
					type: 'text',
					label: 'Hora'
				},
				{
					name: 'valorf',
					type: 'text',
					label: 'Nombre Profesor'
				},
				{
					name: 'valorg',
					type: 'text',
					label: 'Pensum'
				},
			];

		case CURSOS_LIBRES:
			return [
				{
					name: 'nombre',
					type: 'text',
					label: 'Nombre Materia'
				},
				{
					name: 'valora',
					type: 'number',
					label: 'Créditos'
				}
			];

		default:
			break;
	}
};

export const getDataTabla = async (seleccion) => {
	let parametro;
	let titulo;
	let filtro = [];
	let data;
	switch (seleccion) {
		case 'materias':
			parametro = MATERIAS;
			titulo = 'Lista Programas de Pregrado';
			const res = await personaSesionGenericas(11);
			data = res.map((p) => p.relacion);
			break;

		case 'cursos':
			parametro = CURSOS_LIBRES;
			titulo = 'Lista Programas de Posgrado';
			filtro = [ { llave: 'generica', valor: POSGRADOS }, { llave: 'estado', valor: 1 } ];
			data = await obtenerValoresFiltros(filtro);
			break;

		case 'diplomados':
			parametro = DIPLOMADOS;
			titulo = 'Lista Diplomados';
			filtro = [ { llave: 'generica', valor: parametro } ];
			data = await obtenerValoresFiltros(filtro);
			break;

		default:
			break;
	}

	return { data, parametro, titulo };
};

export const guardarPermiso = (valor_secundario, programa, callback) => {
	consulta(
		'api/v1.0/permisos/crear',
		{ secundario: valor_secundario, principal: programa },
		'post',
		(error, estado, resp) => {
			let title = 'Ha ocurrido un error, contacte con el administrador.',
				icon = 'error';
			if (!error) {
				if (estado === 200) {
					icon = 'success';
					title = 'Permiso asignado';
					callback(programa);
				} else {
					icon = 'info';
					title = mostrarError(resp);
				}
			}
			Toast.fire({ title, icon });
		}
	);
};

export const eliminarPermiso = (permiso, programa, callback) => {
	consulta(`api/v1.0/permisos/${permiso}/eliminar`, {}, 'put', (error, estado, resp) => {
		let title = 'Ha ocurrido un error, contacte con el administrador.',
			icon = 'error';
		if (!error) {
			if (estado === 200) {
				icon = 'success';
				title = 'Permiso Eliminado';
				callback(programa);
			} else {
				icon = 'info';
				title = mostrarError(resp);
			}
		}
		Toast.fire({ title, icon });
	});
};

export const asignarPrograma = (persona, programa) => {
	return new Promise((resolve) => {
		consulta(
			`api/v1.0/personas/${persona}/genericas/crear`,
			{
				tipo: 11,
				relaciones: [ programa ]
			},
			'POST',
			(error, estado, resp) => resolve(resp)
		);
	});
};

export const desasignarPrograma = (persona, programa) => {
	new Promise((resolve) => {
		consulta(
			`api/v1.0/personas/${persona}/genericas/${programa}/permiso/11/eliminar`,
			null,
			'PUT',
			(error, estado, resp) => {
				resolve(resp);
			}
		);
	});
};

export const RESPUESTAS_MULTI_RADIO_BUTTONS = [
	{
	  id: "0",
	  nombre: "1",
	},
	{
	  id: "1",
	  nombre: "2",
	},
	{
	  id: "2",
	  nombre: "3",
	},
	{
	  id: "3",
	  nombre: "4",
	},
	{
	  id: "4",
	  nombre: "No aplica",
	},
  ];