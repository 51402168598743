const MODAL_SOLICITUDES = 'MODAL_SOLICITUDES';
const MODAL_DETALLE = 'MODAL_DETALLE';
const MODAL_GESTIONAR = 'MODAL_GESTIONAR';
const MODAL_FILTROS = 'MODAL_FILTROS';
const MODAL_PROGRAMAS = 'MODAL_PROGRAMAS';
const MODAL_AUX = 'MODAL_AUX';
const MATRICULAS = 'MATRICULAS';
const MIS_DATOS = 'MIS_DATOS';
const CURSOS = 'CURSOS';
const MODAL_OBSERVACIONES = 'MODAL_OBSERVACIONES';
const MODAL_DIPLOMADO_AGREGAR = 'MODAL_DIPLOMADO_AGREGAR';
const MODAL_DIPLOMADO_EDITAR = 'MODAL_DIPLOMADO_EDITAR';
const MODAL_DIPLOMADO_VER = 'MODAL_DIPLOMADO_VER';
const MODAL_DIPLOMADO_ELIMINAR = 'MODAL_DIPLOMADO_ELIMINAR';
const MODAL_ADJ_VOLANTE = 'MODAL_ADJ_VOLANTE';
const MODAL_CONFIRMACION = 'MODAL_CONFIRMACION';
const MODAL_EDIT_MATRICULA = 'MODAL_EDIT_MATRICULA';
const MODAL_INFORME = 'MODAL_INFORME';
const MODAL_MEN_VALIDACION = 'MODAL_MEN_VALIDACION';
const MODAL_DEL = 'MODAL_DEL'
const MODAL_OPEN_STATE = 'MODAL_OPEN_STATE'
const MODAL_MOD_PERIODO_MATRICULAS = 'MODAL_MOD_PERIODO_MATRICULAS'

const showModalDiplomadoAgregar = (show) => {
	return {
		type: MODAL_DIPLOMADO_AGREGAR,
		show
	};
};

const showModalDiplomadoEditar = (show) => {
	return {
		type: MODAL_DIPLOMADO_EDITAR,
		show
	};
};

const showModalDiplomadoVer = (show) => {
	return {
		type: MODAL_DIPLOMADO_VER,
		show
	};
};
const showModalDiplomadoEliminar = (show) => {
	return {
		type: MODAL_DIPLOMADO_ELIMINAR,
		show
	};
};

const showModalSolicitudes = (show) => {
	return {
		type: MODAL_SOLICITUDES,
		show
	};
};
const showModalProgramas = (show) => {
	return {
		type: MODAL_PROGRAMAS,
		show
	};
};
const showModalDetalle = (show) => {
	return {
		type: MODAL_DETALLE,
		show
	};
};
const showModalAux = (show) => {
	return {
		type: MODAL_AUX,
		show
	};
};
const showModalGestionar = (show) => {
	return {
		type: MODAL_GESTIONAR,
		show
	};
};
const showModalFiltros = (show) => {
	return {
		type: MODAL_FILTROS,
		show
	};
};
const setMatriculas = (matriculas) => {
	return {
		type: MATRICULAS,
		matriculas
	};
};
const setMisDatos = (misDatos) => {
	return {
		type: MIS_DATOS,
		misDatos
	};
};
const setMisCursos = (misCursos) => {
	return {
		type: CURSOS,
		misCursos
	};
};
const showModalObservaciones = show => {
	return {
	  type: MODAL_OBSERVACIONES,
	  show,
	}
};

const mostrarAdjuntarVolante = show => {
	return {
	  type: MODAL_ADJ_VOLANTE,
	  show
	}
}

const mostrarConfirmacion = show => {
	return {
	  type: MODAL_CONFIRMACION,
	  show
	}
}

const mostrarEditarMatricula = show => {
	return {
	  type: MODAL_EDIT_MATRICULA,
	  show
	}
}

const mostrarModalInforme = show => {
  return {
    type: MODAL_INFORME,
    show,
  }
}

const mostrarModalMensajeValidacionId = show => {
  return {
    type: MODAL_MEN_VALIDACION,
    show,
  }
}

const mostrarModalDel = show => {
  return {
    type: MODAL_DEL,
    show,
  }
}

const showModalState = (show) => {
	return {
		type: MODAL_OPEN_STATE,
		show
	};
};

const mostrarModalModPeriodo = show => {
  return {
    type: MODAL_MOD_PERIODO_MATRICULAS,
    show,
  }
}

module.exports = {
	MODAL_SOLICITUDES,
	showModalSolicitudes,
	MODAL_DETALLE,
	showModalDetalle,
	MODAL_GESTIONAR,
	showModalGestionar,
	MATRICULAS,
	setMatriculas,
	MIS_DATOS,
	setMisDatos,
	CURSOS,
	setMisCursos,
	MODAL_FILTROS,
	showModalFiltros,
	showModalAux,
	MODAL_AUX,
	MODAL_PROGRAMAS,
	showModalProgramas,
	showModalObservaciones,
	MODAL_OBSERVACIONES,
	MODAL_DIPLOMADO_AGREGAR,
	showModalDiplomadoAgregar,
	MODAL_DIPLOMADO_EDITAR,
	showModalDiplomadoEditar,
	MODAL_DIPLOMADO_VER,
	showModalDiplomadoVer,
	MODAL_DIPLOMADO_ELIMINAR,
	showModalDiplomadoEliminar,
	MODAL_ADJ_VOLANTE,
	mostrarAdjuntarVolante,
	MODAL_CONFIRMACION,
	mostrarConfirmacion,
	MODAL_EDIT_MATRICULA,
	mostrarEditarMatricula,
  	mostrarModalInforme,
    MODAL_INFORME,
  	mostrarModalMensajeValidacionId,
  	MODAL_MEN_VALIDACION,
	MODAL_DEL,
	mostrarModalDel,
	MODAL_OPEN_STATE,
	showModalState,
	mostrarModalModPeriodo,
	MODAL_MOD_PERIODO_MATRICULAS
};
