import React, { Component } from 'react';
//COMPONENTS
import { Dialog, DialogContent, DialogActions, Grid,CardContent, Typography, CardMedia, Box } from '@material-ui/core';
import AppBarModal from '../../general/AppBarModal';
import { BtnForm } from '../../general/BotonesAccion';
import {api} from '../../../global/js/funciones';


function Vista(component_props) {
  let {data}= component_props
  return(
      <ul>
      {
        data.map(( relacion, i ) => (
         
            <Typography variant="subtitle1" component="div" key={i} style={{ textAlign: "justify" }}>
               <li>{relacion.descripcion}</li>
            </Typography>
        ))
      }
      </ul>
    )
  }
class RecomendacionesEstilo extends Component {
  
  constructor(props) {
    super(props)
    this.state = {
      
    }
    
  }
render(){
  let {status, toggle, data, img}= this.props
  return (
    <Dialog open={status} onClose={() => toggle({ modal: 'dialogRecomendaciones', value: false })} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="md">
      <AppBarModal titulo="Recomendaciones" mostrarModal={() => toggle({ modal: 'dialogRecomendaciones', value: false })} titulo_accion="" accion="" />
      <DialogContent style={{ padding: 0 }} className='scroll'>
        <Grid container style={{ marginTop: '5%' }}>
          <Grid item md={8} xs={12}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flex: '1 0 auto' }}>
              <Vista
                {...{
                  data,
                }}
              />
              </CardContent>
            </Box>
          </Grid>
          <Grid item md={4} xs={12} >
            <CardMedia component="img" md={{ width: 51 }} image={api+img} alt="Live from space album cover"/>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <BtnForm texto="Cerrar" callback={() => toggle({ modal: 'dialogRecomendaciones', value: false })} />
      </DialogActions>
    </Dialog>
  )
}
 
}

export default RecomendacionesEstilo
