import React, {Component} from "react";
import { Grid, Dialog, DialogContent, TableCell, TableRow, TableBody, Table, DialogActions, BottomNavigation, BottomNavigationAction, Button} from "@material-ui/core";
import AppBarModal from "../../general/AppBarModal";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import LoopIcon from "@material-ui/icons/Loop";
import FormatListIcon from "@material-ui/icons/FormatListNumberedRtl";
import TareasTerminadas from "../../general/TareasTerminadas";
import { BtnForm } from "../../general/BotonesAccion";
import ListarDatos from "../../general/ListarDatos";
import { consulta,generarFiltros } from "../../../global/js/funciones";


//imagenes
import emma_w from '../../../global/imagenes/emma_w.png';
import moment from "moment";

const API_URI = `api/v1.0/invitadosVirtual/`;


function Menu({ sele = "detalle", seleccionar = () => { } }) {
    const clase = (item, selec, tipo = '') => item === selec ? `item_selec${tipo}` : `item_modulo`;

    const pintar = (claseN = '') => {
        return (
            <div className={claseN}>
                <BottomNavigation showLabels style={{ justifyContent: "center", overflowX: "auto" }}>
                    <BottomNavigationAction label={'Detalle'} icon={<ImportContactsIcon className='menu_icono' />} className={clase("detalle", sele)} onClick={() => seleccionar("detalle")} />
                    <BottomNavigationAction label={'Estados'} icon={<LoopIcon className='menu_icono' />} className={clase("estados", sele)} onClick={() => seleccionar("estados")} />
                    <BottomNavigationAction label={'Asignatura'} icon={<FormatListIcon className='menu_icono' />} className={clase("asignatura", sele)} onClick={() => seleccionar("asignatura")} />
                </BottomNavigation>
            </div>
        )
    }

    return (
        <div>
            {pintar('menu_modal')}
        </div>
    );
}

class SolicitudDetalle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clase: "",
            cargando: false,
            sele: "",
            seleccionar: "detalle",
            estados: [],
            asignaturas: [],
        }
    }

    pintar = (data) => {
		let { seleccionar } = this.state;
		switch (seleccionar) {
			case "detalle":
				return this.pintarDetalle(data);
			case "estados":
				return this.pintarEstados();
			case "asignatura":
                return this.pintarAsignaturas();
			default:
			break;
		}
	};

    async componentDidUpdate({ modalRespuestas }, { seleccionar }) {
        let { seleccionar: seleccionarNew } = this.state
        let {data} = this.props;
        if (seleccionarNew !== seleccionar) {
            if (seleccionarNew === 'detalle') await this.pintarDetalle(data);
            else if (seleccionarNew === 'estados') await this.obtenerEstados(data.id);
            else if (seleccionarNew === 'asignatura') await this.obtenerAsignatura(data.id);
        }
    }
    

    async obtenerEstados(id) {
        this.setState({ cargando: true });
        let f = await generarFiltros([{ llave: "solicitud", valor: id }]);
        consulta(`${API_URI}estados?${f}`, null, null, (error, estado, resp) => {
          if (estado === 200) this.setState({ cargando: false, estados: resp });
        });
    }

    async obtenerAsignatura(id) {
        this.setState({ cargando: true });
        let f = await generarFiltros([{ llave: "solicitud", valor: id }]);
        consulta(`${API_URI}getAsignaturas?${f}`, null, null, (error, estado, resp) => {
          if (estado === 200) this.setState({ cargando: false, asignaturas: resp });
        });
    }

    pintarEstados = () => {
        let { estados } = this.state;

        const obtenerColor = (codigo) => {
        let color = 'white';
    
        const colores = {
            'Est_IVir_Ini': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
            'Est_IVir_Trami': { 'backgroundColor': '#ffc107', color },
            'Est_IVir_Asig_Cursar': { 'backgroundColor': '#01519B', color },
            'Est_IVir_Neg': { 'backgroundColor': '#b71c1c', color },
            'Est_IVir_Fin': { 'backgroundColor': '#009688', color },
            'Tod_Inv_Vir': { 'backgroundColor': '#0d47a1', color },
        };
    
        return colores[codigo];
        };

        return (
          <ListarDatos
            datos={estados}
            titulo="Estados"
            id="tbl_estados"
            buscar={true}
            avatar={({ estado_solicitud }) => `${estado_solicitud["valora"]}`}
            color_avatar={({ estado_solicitud: { codigo } }) => obtenerColor(codigo)}
            fila_principal={({ estado_solicitud }) =>
              `${estado_solicitud["nombre"]}`
            }
            filas={[
              {
                mostrar: ({ persona_registra }) =>
                  `${persona_registra["primer_nombre"]} ${persona_registra["segundo_nombre"]} ${persona_registra["primer_apellido"]} ${persona_registra.segundo_apellido}`.toUpperCase(),
                id: "nombre_completo",
                enLista: true,
              },
              {
                mostrar: ({ fecha_registra }) => moment(fecha_registra).format('YYYY-MM-DD, h:mm a'),
                id: "fecha_registra",
                enLista: true,
              },
              {
                mostrar: ({ observaciones }) => observaciones,
                id: "observaciones",
                enLista: true,
              },
            ]}
          />
        );
    }

    pintarAsignaturas = () => {
        let { asignaturas } = this.state;
        return (
          <ListarDatos
            datos={asignaturas}
            titulo="Asignaturas"
            id="tbl_asignaturas"
            buscar={true}
            avatarImg={false}
            avatar={({ asignatura }) => `${asignatura["nombre"]}`.charAt(0)}
            fila_principal={({ asignatura }) => `${asignatura["nombre"]}`}
            filas={[
              {
                mostrar: ({ persona_registra }) =>
                  `${persona_registra["primer_nombre"]} ${persona_registra["segundo_nombre"]} ${persona_registra["primer_apellido"]} ${persona_registra.segundo_apellido}`.toUpperCase(),
                id: "nombre_completo",
                enLista: true,
              },
              {
                mostrar: ({ fecha_registra }) =>
                  moment(fecha_registra).format("YYYY-MM-DD, h:mm a"),
                id: "fecha_registra",
                enLista: true,
              },
            ]}
          />
        );
    }

    pintarDetalle = (data) => {
        return (
            <Grid container>
                <Grid item xs={12} md={12}>
                    <Table>
                        <TableBody>
                            <TableRow key = { 1 }>
                                <TableCell component = "th" scope = "row">Nombre del Solicitante</TableCell>
                                <TableCell align = "left">{ (data.length !== 0) ? data["persona"]["primer_nombre"] + " " + data["persona"]["primer_apellido"] + " " + data["persona"]["segundo_apellido"] : "" }</TableCell>
                            </TableRow>

                            <TableRow key = { 2 }>
                                <TableCell component = "th" scope = "row">Número de identificación</TableCell>
                                <TableCell align = "left">{ (data.length !== 0) ? data["persona"]["identificacion"] : "" }</TableCell>
                            </TableRow>

                            <TableRow key = { 3 }>
                                <TableCell component = "th" scope = "row">Correo Electronico</TableCell>
                                <TableCell align = "left">{ (data.length !== 0) ? data["persona"]["correo_personal"] : "" }</TableCell>
                            </TableRow>

                            <TableRow key = { 4 }>
                                <TableCell component = "th" scope = "row">Celular/Telefono</TableCell>
                                <TableCell align = "left"> {(data.length !== 0) ? data["persona"]["celular"] : ""} </TableCell>
                            </TableRow>

                            <TableRow key = { 5 }>
                                <TableCell component = "th" scope = "row">Programa academico</TableCell>
                                <TableCell align = "left">{ (data.length !== 0) ? data["programa"]["nombre"] : "" }</TableCell>
                            </TableRow>

                            <TableRow key = { 6 }>
                                <TableCell component = "th" scope = "row">Tipo Estudiante</TableCell>
                                <TableCell align = "left">{ (data.length !== 0) ? data["tipo_solicitud"]["nombre"] : "" }</TableCell>
                            </TableRow>

                            <TableRow key = { 7 }>
                                <TableCell component = "th" scope = "row">Periodo</TableCell>
                                <TableCell align = "left">{ (data.length !== 0) ? data.periodo : "" }</TableCell>
                            </TableRow>

                            <TableRow key = { 8 }>
                                <TableCell component = "th" scope = "row">Fecha Solicitud</TableCell>
                                <TableCell align = "left">{ (data.length !== 0) ? moment(data["fecha_registra"]).format("YYYY-MM-DD, h:mm a") : "" }</TableCell>
                            </TableRow>

                            <TableRow key = { 9 }>
                                <TableCell component = "th" scope = "row">Estado Solicitud</TableCell>
                                <TableCell align = "left">{ (data.length !== 0) ? data["estado_solicitud"]["nombre"] : "" }</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        )
    }

    handleClose = () => {
		let { closeModal } = this.props;
        this.setState({seleccionar: "detalle"});
		closeModal(false);
	};


    render(){
        let { data, openModal } = this.props;
        let { cargando, seleccionar } = this.state;
        return(
            <div>
				<Dialog onClose={this.handleClose} open={openModal} fullWidth={true} fullScreen={window.innerWidth < 600} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
					<AppBarModal titulo={'Detalle Solicitud'} mostrarModal={this.handleClose} titulo_accion="Cerrar" />
					<DialogContent className="scroll" style={{ padding: 0 }}>
						<Menu sele={seleccionar} seleccionar={(seleccionar) => this.setState({seleccionar})} />
						{!cargando ? this.pintar(data) : <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />}
					</DialogContent>
					<DialogActions>
                        <Button autoFocus onClick={this.handleClose} color="primary">
							Cerrar
						</Button>
                     </DialogActions>
				</Dialog>
			</div>
        )
    }

}

export default SolicitudDetalle;