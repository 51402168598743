import React, { Component } from 'react'
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import AppBarModal from '../general/AppBarModal'
import { BtnForm } from "../general/BotonesAccion";
import { MenuProps } from '../../global/js/funciones'

class EleccionesAgregar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      nombre: '',
      fecha_inicio: null,
      fecha_fin: null,
      votantes: [],
      tipoVotantes: [],
    }
  }

  componentDidMount() {
    this.cargarTipos();
  }

  componentDidUpdate({ modalAddEleccion }) {
    let { modalAddEleccion: modalAddNew } = this.props;
    if (modalAddNew !== modalAddEleccion) {
      this.changeState();
    }
  }

  changeState = () => {
    this.setState({
      nombre: '',
      fecha_inicio: null,
      fecha_fin: null,
      votantes: [],
    })
  }
  onSubmit = e => {
    let { nombre, fecha_inicio, fecha_fin, votantes } = this.state;
    fecha_inicio = fecha_inicio ? moment(fecha_inicio).format('YYYY-MM-DD') : null;
    fecha_fin = fecha_fin ? moment(fecha_fin).format('YYYY-MM-DD') : null;
    this.props.guardarEleccion(nombre, fecha_inicio, fecha_fin, votantes);
    e.preventDefault();
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  setFechaInicio = (fecha_inicio) => {
    this.setState({
      fecha_inicio
    })
  }
  setFechaFin = (fecha_fin) => {
    this.setState({
      fecha_fin
    })
  }

  setRol = ({ target }) => {
    this.setState({
      votantes: target.value
    })
  }

  cargarTipos = async () => {
    const tipoVotantes = await this.props.obtenerValores(1);
    this.setState({
      tipoVotantes,
    })
  }

  pintarTiposVacantes = () => {
    let { votantes, tipoVotantes } = this.state;
    if (Array.isArray(tipoVotantes)) {
      return tipoVotantes.map(({ nombre, id }) => (
        <MenuItem key={id} value={id}>
          <Checkbox checked={votantes.indexOf(id) > -1} />
          <ListItemText primary={nombre} />
        </MenuItem>
      ))
    }
    return [];
  }

  pintarNombresVacantes = (selected) => {
    let resp = [];
    let { tipoVotantes } = this.state;
    selected.map((element) => {
      tipoVotantes.map((e) => {
        if (element === e.id) return resp.push(e.nombre)
        return null;
      })
      return null;
    });
    return resp.join(", ")
  }

  render() {
    let { nombre, fecha_inicio, fecha_fin, votantes } = this.state;
    let { modalAddEleccion, mostrarModalAddEleccion } = this.props;

    return (
      <Dialog open={modalAddEleccion} onClose={mostrarModalAddEleccion.bind(this, false)} fullWidth={true} maxWidth="sm" aria-labelledby="form-dialog-title">
        <AppBarModal titulo="Nueva Elección" mostrarModal={mostrarModalAddEleccion} titulo_accion="" accion="" />
        <DialogContent className='scroll'>
          <ValidatorForm onSubmit={this.onSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextValidator
                  autoFocus
                  margin="dense"
                  id="nombre"
                  label="Nombre"
                  type="text"
                  fullWidth
                  name="nombre"
                  value={nombre}
                  validators={["required"]}
                  errorMessages={["El campo es requerido"]}
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    minDate={Date("YYYY-MM-dd")}
                    id="date-picker-dialog"
                    label="Fecha Inicio"
                    format="yyyy-MM-dd"
                    value={fecha_inicio}
                    onChange={this.setFechaInicio}
                    style={{ width: '100%' }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    minDateMessage={`La fecha debe ser mayor que la fecha actual.`}
                    invalidDateMessage='Formato Invalido'
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} >
                  <KeyboardDatePicker
                    minDate={Date("YYYY-MM-dd")}
                    id="date-picker-dialog"
                    label="Fecha Fin"
                    format="yyyy-MM-dd"
                    value={fecha_fin}
                    onChange={this.setFechaFin}
                    style={{ width: '100%' }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    minDateMessage={`La fecha debe ser mayor que la fecha actual.`}
                    invalidDateMessage='Formato Invalido'
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <FormControl className='form-control' >
                  <InputLabel htmlFor="select-multiple-checkbox">Votantes</InputLabel>
                  <Select
                    multiple
                    value={votantes}
                    input={<Input labelWidth={70} name="select-multiple-checkbox" id="select-multiple-checkbox" />}
                    onChange={this.setRol}
                    renderValue={selected => {
                      return this.pintarNombresVacantes(selected)
                    }}
                    MenuProps={MenuProps}
                  >
                    {this.pintarTiposVacantes()}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Button color="primary" type="submit" id='enviar_form_add_eleccion' className="oculto"> Guardar </Button>
          </ValidatorForm>
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CANCELAR" callback={() => mostrarModalAddEleccion(false)} />
          <BtnForm texto="GUARDAR" callback={() => { document.getElementById('enviar_form_add_eleccion').click() }} />
        </DialogActions>
      </Dialog>
    )
  }
}

//Props del componente
EleccionesAgregar.propTypes = {
  //variables
  modalAddEleccion: PropTypes.bool.isRequired,
  //funciones
  mostrarModalAddEleccion: PropTypes.func.isRequired,
  guardarEleccion: PropTypes.func.isRequired,
}


export default EleccionesAgregar;

