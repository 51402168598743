//HELPER PRODUCCIÓN
export const MODALIDADES = 121;
export const TEMAS_PRACTICAS_CLINICAS = 122;
export const CONTRATOS = 60;
export const VALOR_CONTRATO = {value: 17696, label: 'No Aplica'} ;
export const ARLS = 123;
export const EMPRESAS = 14;
export const TIPOS_PRACTICAS = 11;
export const PASOS_ENCUETAS = 41;
export const PREGUNTAS_ENCUESTA = 42;

export const PERIODO_PRACTICAS = 63; // 63 1065

export const TIPO_INFORME_PRACTICAS = 62 //62 1066
export const AREA_DE_INTERES=202;
export const TIPOEMPRESAS=203;
export const ACTIVIDAD_EMPRESA=205;
export const GENEROS=69;
export const GRUPO_PRACTICAS=245;
export const CARERRAS_CUC=5;
export const EXCLUIR_REQ_CONVGOB = [10763]

// export const TIPO_INFORME_PRACTICAS = 62 //62 1066
// export const AREA_DE_INTERES=126;
// export const TIPOEMPRESAS=127;
// export const ACTIVIDAD_EMPRESA=128;


