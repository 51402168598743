//Importaciones necesaria para poder utilizar react
import React, { useState }  from 'react'
import ReactDOM from 'react-dom'; 

//Importaciones necesarias para que el modal del formulario de creacion de semilleros se pueda abrir, este es para que se habra el modal.
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button'
//Importe de los text faild para los formularios de los campos.
import TextField from '@material-ui/core/TextField';
//Importe de los grid para los textfield de los formularios entre otros aspecto.
import Grid from '@material-ui/core/Grid';

import { makeStyles } from '@material-ui/core/styles';
import { render } from 'react-dom';

import AppBarModal from '../../general/AppBarModal'
import BuscarPersona from '../../general/BuscarPersona';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up('xs')]: {
            minWidth: "400px"
          },
      },
      flexGrow: {
          flexGrow: 1,
      },
      formControl: {
        margin: theme.spacing(1),
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      noneScroll:{
        overflowY: 'hidden',
        [theme.breakpoints.up('sm')]: {
          minWidth: "600px"
        },
    }
}));

export default function SemillerosAddRol(props) {
    const classes = useStyles();

    //Funcion para asginar Roles
    const handleChangeRol = (event) => {
      const selectedRol = event.target.value; 
        props.setRolSelect(selectedRol);
        if(props.errores.length > 0){
          props.setErrores(props.errores.filter(er => er.llave != "rolSelect"));
          // Elimina error cuando se escribe en el campo
        }
    };

    return (
        <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={props.modalRol} onClose={() => props.setModalRol(false)}>
          <AppBarModal titulo={'¡ Asignar Rol !'} mostrarModal={() => props.setModalRol(false)} titulo_accion="" accion="" />
          <DialogContent>
          <InputLabel htmlFor="age-native-simple">Rol</InputLabel>
            <FormControl className={classes.formControl} fullWidth error = { props.getError('rolSelect', props.errores).length > 0}>
                <Select
                value={props.rolSelect}
                displayEmpty
                className={classes.selectEmpty}
                inputProps={{ 'aria-label': 'Without label' }}
                onChange={handleChangeRol}
                >
                {props.gestor == true? 
                <MenuItem value="">
                <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `Asignar Profesor Lider` }</em>
                </MenuItem>
                : 
                <MenuItem value="">
                    <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.rol.length} Roles Disponibles` }</em>
                </MenuItem>
                }
            {/* {props.rol.map((item, index) => (
                item.codigo !== "Semi_rol3" && (
                <MenuItem key={index} value={item}>
                  {item.nombre}
                </MenuItem>
              )
              ))} */}
            {props.gestor == true? 
              (
                props.rol
                  .filter(item => item.codigo === 'Semi_rol3')
                  .map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item.nombre}
                    </MenuItem>
                  ))
              )
              :
              (props.rol.map((item, index) => (
              (<MenuItem key={index} value={item}>
                 {item.nombre}
               </MenuItem>)
             )))
            }
                </Select>

            </FormControl>
            <FormHelperText>{ props.getError('rolSelect', props.errores) }</FormHelperText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" style={{color: "#fff"}} onClick={() => props.onClickChange(false)}>
                Asignar
            </Button>
          </DialogActions>
        </Dialog>
    )
}