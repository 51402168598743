import React from 'react';
import { BottomNavigation, BottomNavigationAction, Stepper, Step, StepLabel } from '@material-ui/core';
import { ImportContacts, MenuBook, Ballot, Loop, Poll, Stars } from '@material-ui/icons';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import { makeStyles } from '@material-ui/core/styles';
import EmojiEmotionsRoundedIcon from '@material-ui/icons/EmojiEmotionsRounded';
import ApartmentRoundedIcon from '@material-ui/icons/ApartmentRounded';
import SchoolRoundedIcon from '@material-ui/icons/SchoolRounded';
import MonetizationOnRoundedIcon from '@material-ui/icons/MonetizationOnRounded';
import TrendingUpRoundedIcon from '@material-ui/icons/TrendingUpRounded';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%'
	},
	button: {
		marginRight: theme.spacing(1)
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1)
	}
}));

const clase = (item, selec, tipo = '') => (item === selec ? `item_selec${tipo}` : `item_modulo`);

export function DetalleMenu({ sele = 0, seleccionar, matricula: { tipo: { codigo } } }) {
	const pintar = (claseN = '') => {
		return (
			<div className={claseN}>
				<BottomNavigation showLabels>
					{
						codigo === "Mat_Pro_Certi" && (
							<BottomNavigationAction
								label={'Certificados'}
								icon={<ImportContacts />}
								className={clase('certificados', sele)}
								onClick={() => seleccionar({ opcion: 'certificados' })}
							/>
						)
					}

					{codigo !== "Mat_Pro_Act_Datos" && codigo !== "Mat_Pro_Certi" &&
						<BottomNavigationAction
							label={'Detalle'}
							icon={<ImportContacts />}
							className={clase('detalle', sele)}
							onClick={() => seleccionar({ opcion: 'detalle' })}
						/>
					}

					{
						codigo === "Mat_Pro_Sol_Gra" && (
							<BottomNavigationAction
								label={'Información'}
								icon={<LiveHelpIcon />}
								className={clase('info', sele)}
								onClick={() => seleccionar({ opcion: 'info' })}
							/>
						)
					}

					{
						codigo === "Mat_Pro_Act_Datos" && (
							<BottomNavigationAction
								label={'Actualizacion'}
								icon={<ImportContacts />}
								className={clase('actualizacion', sele)}
								onClick={() => seleccionar({ opcion: 'actualizacion' })}
							/>
						)
					}
					{
						(codigo === "Mat_Pro_Int" || codigo === "Mat_Pro_Pre" || codigo === "Mat_Pro_Pos" || codigo === "Mat_Pro_Cre_ext" ) && (
							<BottomNavigationAction
								label={'Materias'}
								icon={<Ballot />}
								className={clase('materias', sele)}
								onClick={() => seleccionar({ opcion: 'materias' })}
							/>
						)
					}
					{codigo !== "Mat_Pro_Act_Datos" && codigo !== "Mat_Pro_Cor_Nota" && codigo !== "Mat_Pro_Certi" && (
						<BottomNavigationAction
							label={'Documentos Generados'}
							icon={<MenuBook />}
							className={clase('documentos', sele)}
							onClick={() => seleccionar({ opcion: 'documentos' })}
						/>
					)}
					<BottomNavigationAction
						label={'Adjuntos'}
						icon={<MenuBook />}
						className={clase('adjuntos', sele)}
						onClick={() => seleccionar({ opcion: 'adjuntos' })}
					/>
					<BottomNavigationAction
						label={'Estados'}
						icon={<Loop />}
						className={clase('estados', sele)}
						onClick={() => seleccionar({ opcion: 'estados' })}
					/>
					{

						<BottomNavigationAction
							label={'Encuesta'}
							icon={<Poll />}
							className={clase('encuesta', sele)}
							onClick={() => seleccionar({ opcion: 'encuesta' })}
						/>
					}
					{
						codigo === "Mat_Pro_Sol_Gra" && (
							<BottomNavigationAction
								label={'Comprobados'}
								icon={<Poll />}
								className={clase('linea', sele)}
								onClick={() => seleccionar({ opcion: 'linea' })}
							/>
						)
					}

					{
						codigo === "Mat_Pro_Sol_Gra" && (
							<BottomNavigationAction
								label={'Menciones'}
								icon={<Stars />}
								className={clase('menciones', sele)}
								onClick={() => seleccionar({ opcion: 'menciones' })}
							/>
						)
					}

				</BottomNavigation>
			</div>
		);
	};

	return <div>{pintar('menu_modal')}</div>;
}

export function MenuGestionar({ sele = 0, seleccionar = () => { }, matricula }) {
	const pintar = (claseN = '') => {
		return (
			<div className={claseN}>
				<BottomNavigation showLabels>
					<BottomNavigationAction
						label={'Gestionar'}
						icon={<Loop />}
						className={clase('gestion', sele)}
						onClick={() => seleccionar('gestion')}
					/>
					{(
						((matricula.estado_solicitud.codigo === 'Mat_En_Est' || matricula.estado_solicitud.codigo === 'Mat_Pro' || matricula.estado_solicitud.codigo === 'Mat_Rein_Tran_Doc_Ok' || matricula.estado_solicitud.codigo === 'Mat_Pro_Sol_Cam_Fec')) ||
						((matricula.estado_solicitud.codigo === 'Mat_Env' || matricula.estado_solicitud.codigo === 'Mat_Pag' || matricula.estado_solicitud.codigo === 'Mat_Aval_Fac_Rein_Tran' || matricula.estado_solicitud.codigo === 'Mat_Rein_Tran_En_Valid_Fac' || matricula.estado_solicitud.codigo === 'Mat_Rein_Tran_Est_Hom_Apro' || matricula.estado_solicitud.codigo === 'Mat_Rein_Tran_Est_Hom_Recha') && matricula.tipo.codigo === 'Mat_Pro_Sol_Rein_Tran') ||
						((matricula.estado_solicitud.codigo === 'Mat_cer_val_soli' || matricula.estado_solicitud.codigo === 'Mat_Com_Env') && matricula.tipo.codigo === 'Mat_Pro_Certi')) && (
							<BottomNavigationAction
								label={'Documentos'}
								icon={<MenuBook />}
								className={clase('adjuntos', sele)}
								onClick={() => seleccionar('adjuntos')}
							/>
						)}
				</BottomNavigation>
			</div>
		);
	};

	return <div>{pintar('menu_modal')}</div>;
}

export function PasosCrearSolicitud({ steps, activeStep }) {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Stepper activeStep={activeStep}>
				{steps.map((label, index) => {
					const stepProps = {};
					const labelProps = {};
					return (
						<Step key={label} {...stepProps}>
							<StepLabel {...labelProps}>{label}</StepLabel>
						</Step>
					);
				})}
			</Stepper>
		</div>
	);
}

export function DetalleMenuEncuesta({ sele = 0, seleccionar }) {
	const pintar = (claseN = '') => {
		return (
			<div className={claseN}>
				<BottomNavigation showLabels>
					<BottomNavigationAction
						label={'Satisfacción'}
						icon={<EmojiEmotionsRoundedIcon />}
						className={clase('Enc_Gra_Sat', sele)}
						onClick={() => seleccionar('Enc_Gra_Sat' )}
					/>
					<BottomNavigationAction
						label={'Infraestructura'}
						icon={<ApartmentRoundedIcon />}
						className={clase('Enc_Gra_Inf', sele)}
						onClick={() => seleccionar('Enc_Gra_Inf')}
					/>
					<BottomNavigationAction
						label={'Competencias'}
						icon={<SchoolRoundedIcon />}
						className={clase('Enc_Gra_Com', sele)}
						onClick={() => seleccionar('Enc_Gra_Com')}
					/>
					<BottomNavigationAction
						label={'Financiación'}
						icon={<MonetizationOnRoundedIcon />}
						className={clase('Enc_Gra_Fin', sele)}
						onClick={() => seleccionar('Enc_Gra_Fin')}
					/>
					<BottomNavigationAction
						label={'Proyección'}
						icon={<TrendingUpRoundedIcon />}
						className={clase('Enc_Gra_Pro', sele)}
						onClick={() => seleccionar('Enc_Gra_Pro')}
					/>
				</BottomNavigation>
			</div>
		)
	}
	return <div>{pintar('menu_modal')}</div>;
}