import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button, Popover } from '@material-ui/core';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import { BtnForm } from "./BotonesAccion";


export default function Alerta({ tipo, mensaje, titulo, elemento, width }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      {elemento(handleClick)}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className='alert_cont'
        style={{ width }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Grid container >
          <Grid item xs={12} md={12} className={`alert_${tipo}_titulo`}>
            <Button
              variant="text"
              size="small"
              color="inherit"
            >
              <NotificationImportantIcon style={{ height: 15 }} />
              {titulo}
            </Button>
          </Grid>
          <Grid item xs={12} md={12} className={`alert_${tipo}`}>
            <Typography variant="body2" component="p">{mensaje}</Typography>
          </Grid>
          <Grid item xs={12} md={12} className='alert_btn'>
            <BtnForm texto="ENTIENDO" callback={() => handleClose()} />
          </Grid>
        </Grid>
      </Popover>
    </>
  );
}
