import React, { Component } from 'react';
import { Grid, Dialog, DialogActions, DialogContent, Fab, Box,IconButton,Tooltip } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { api, coloresEmma} from '../../global/js/funciones';
import { Document, Image, Page, PDFDownloadLink, Text, View, StyleSheet } from '@react-pdf/renderer';
import { consulta } from '../../global/js/funciones';
import emma_w from "../../global/imagenes/emma_w.png";
import AppBarModal from '../general/AppBarModal'
import { BtnForm } from '../general/BotonesAccion'
import TareasTerminadas from "../general/TareasTerminadas";



const styles = StyleSheet.create({
  body: {
    margin: 28,
    flexGrow: 1,
  },
  row: {
    flexGrow: 1,
    flexDirection: 'row'
  },
  titulo_nombre: {
    color: coloresEmma.secondarycolor,
    fontSize: '20px',
    textAlign: 'center'
  },
  titulos: {
    backgroundColor: coloresEmma.secondarycolor,
    color: '#FFF',
    padding: '2px',
    textAlign: 'center',
    margin: '10px 0px',
    fontSize: '11px'
  },
  Tprincipal: {
    marginTop: '5px'
  },
  Tprincipal2: {
    color: 'gray',
    fontSize: '11px',
    textAlign: 'right',
    paddingTop: '12px'
  },
  Tsecundario: {
    fontSize: '12px',
    color: coloresEmma.secondarycolor,
    marginTop: '5px'
  },
  Ttexto: {
    fontSize: '11px',
    marginTop: '5px',
    textAlign: 'justify'
  },
  TextosInfo: {
    fontSize: '11px',
    marginTop: '3px',
    textAlign: 'center'
  },
  THabilidades: {
    marginTop: '5px',
    fontSize: '13px',
    paddingLeft: '5px'
  },
  bloque: {
    marginTop: '15px'
  },
  nivelesSi: {
    backgroundColor: coloresEmma.secondarycolor,
    color: '#FDFEFE',
    textAlign: 'center',
    width: '18px',
    fontSize: '12px',
    borderRadius: '2px',
    padding: '2px'
  },
  nivelesNo: {
    color: '#FDFEFE',
    textAlign: 'center',
    width: '18px',
    fontSize: '12px',
    border: '2px solid #F5EBB6',
    borderRadius: '2px'
  },
  foto: {
    display: 'grid',
    justify: 'center',
    self: 'center',
    width: '100px',
    height: '100px',
    border: '50px',
    padding: '2px',
    borderRight: `50px solid ${coloresEmma.secondarycolor}`,
    borderLeft: `50px solid ${coloresEmma.secondarycolor}`,
    borderTop: `50px solid ${coloresEmma.secondarycolor}`,
    borderBottom: `50px solid ${coloresEmma.secondarycolor}`,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '20px'
  }
});


function generarPDF(personal, habilidad, experiencia, academico, distincion) {
  let personales = habilidad.filter(i => i.tipo === "personal")
  let laboral = habilidad.filter(i => i.tipo === "laboral")

  const niveles = (nivel) => {
    switch (nivel) {
      case "5":
        return (
          <View style={styles.row}>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>1</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>2</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>3</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>4</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>5</Text></View>
          </View>
        )
      case "4":
        return (
          <View style={styles.row}>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>1</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>2</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>3</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>4</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesNo}> </Text></View>
          </View>
        )
      case "3":
        return (
          <View style={styles.row}>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>1</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>2</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>3</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesNo}> </Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesNo}> </Text></View>
          </View>
        )
      case "2":
        return (
          <View style={styles.row}>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>1</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>2</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesNo}> </Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesNo}> </Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesNo}> </Text></View>
          </View>
        )
      case "1":
        return (
          <View style={styles.row}>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>1</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesNo}> </Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesNo}> </Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesNo}> </Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesNo}> </Text></View>
          </View>
        )
    }
  }

  return (
    <Document>
      <Page size="A4">
        <View style={styles.body}>
          <View style={styles.row}>
            <View style={{ width: '40%', paddingRight: '15px' }}>
              <Image style={styles.foto} src={api + personal[0].archivo} alt="images" />

              <Text style={[styles.TextosInfo, { fontWeight: 'bold' }]}>{`${personal[0].persona ? personal[0].persona.primer_nombre : ""} ${personal[0].persona ? personal[0].persona.primer_apellido : ""} ${personal[0].persona ? personal[0].persona.segundo_apellido : ""}`}</Text>
              <Text style={styles.TextosInfo}>{personal[0].persona ? personal[0].persona.telefono : ""}</Text>
              <Text style={styles.TextosInfo}>{personal[0] ? personal[0].email : ""}</Text>
              <Text style={[styles.TextosInfo, { color: coloresEmma.secondarycolor }]}>{`Linkedin de ${personal[0].persona ? personal[0].persona.primer_nombre : ""} ${personal[0].persona ? personal[0].persona.primer_apellido : ""}`}</Text>

              <Text style={styles.titulos}>SOBRE MI</Text>
              <Text style={styles.Ttexto}>{personal[0] ? personal[0].perfil : ""}</Text>

              <Text style={styles.titulos}>HABILIDADES</Text>
              <Text style={styles.Tprincipal}>LABORALES</Text>
              {laboral.map(e => (
                <View style={[styles.row, { marginTop: '7px' }]}>
                  <View style={{ width: '50%' }}><Text style={styles.THabilidades}>{e.descripcion}</Text></View>
                  <View style={{ width: '50%' }}>{niveles(e.nivel)}</View>
                </View>
              ))}
              <Text style={styles.Tprincipal}>PERSONALES</Text>
              {personales.map(e => (
                <View style={[styles.row, { marginTop: '7px' }]}>
                  <View style={{ width: '50%' }}><Text style={styles.THabilidades}>{e.descripcion}</Text></View>
                  <View style={{ width: '50%' }}>{niveles(e.nivel)}
                  </View>
                </View>
              ))}
            </View>
            <View style={{ width: '60%', paddingLeft: '15px' }}>
              <Text style={styles.titulo_nombre}>{`${personal[0].persona ? personal[0].persona.primer_nombre : ""} ${personal[0].persona ? personal[0].persona.primer_apellido : ""} ${personal[0].persona ? personal[0].persona.segundo_apellido : ""}`}</Text>

              <Text style={styles.titulos}>EXPERIENCIA</Text>
              {
                experiencia.map(e => (
                  e.empresa ?
                    <View style={styles.bloque}>
                      <View style={styles.row}>
                        <View style={{ width: '50%' }}>
                          <Text style={styles.Tprincipal}>{e.cargo}</Text>
                        </View>
                        <View style={{ width: '50%' }}>
                          <Text style={styles.Tprincipal2}>{`(${e.fecha_inicio_exp.substring(0, 4)} - ${e.en_curso_lab == '1' ? 'Presente' : e.fecha_final_exp.substring(0, 4)})`}</Text>
                        </View>
                      </View>
                      <Text style={styles.Tsecundario}>{e.empresa}</Text>
                      <Text style={styles.Ttexto}>{e.responsabilidades}</Text>
                    </View>
                    :
                    <View style={styles.bloque}>
                      <Text style={styles.Tprincipal}>No cuento con experiencia laboral</Text>
                    </View>
                ))
              }

              <Text style={styles.titulos}>EDUCACIÓN</Text>
              {
                academico.map(e => (
                  <View style={styles.bloque}>
                    <Text style={styles.Tprincipal}>{e.profesion ? `${e.profesion.toUpperCase()} - ${e.titulo}` : ''}</Text>
                    <Text style={styles.Tsecundario}>{e.universidad}</Text>
                    <Text style={styles.Ttexto}>{`(${e.fecha_inicio_est.substring(0, 4)} - ${e.en_curso_aca == '1' ? 'Presente' : e.fecha_final_est.substring(0, 4)})`}</Text>
                  </View>
                ))
              }

              <Text style={styles.titulos}>DISTINCIONES Y LOGROS</Text>
              {
                distincion.map(e => (
                  <View style={styles.bloque}>
                    <Text style={styles.Tprincipal}>{e.descripcion.toUpperCase()}</Text>
                  </View>
                ))
              }
            </View>
          </View>
        </View>
      </Page>
    </Document>

  )

}

class DescargarHoJaDeVida extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cargando: true,
      detallePostulante: []
    }
  }

  componentDidMount() {
    let { idDetallePostulante } = this.props;
    if (idDetallePostulante !== '') {
      this.obtenerDetalle(idDetallePostulante)
    }
  }


  obtenerDetalle(id) {
    this.setState({ cargando: true });
    consulta("api/v1.0/portal/obtener_detalle_postulante", { id }, "post", (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, detallePostulante: resp });
    })
  }

  render() {
    let { cargando } = this.state
    let { detallePostulante } = this.state
    console.log('Renderizado')
    console.log(detallePostulante);
    let { personal, habilidad, experiencia, academico, distincion } = detallePostulante;
    console.log(personal)
    let descargar = <Box style={{ display: 'flex', justifyContent: 'space-evenly', 'marginLeft': 'auto' }}>
      <PDFDownloadLink style={{ textDecoration: 'none' }} document={habilidad && generarPDF(personal, habilidad, experiencia, academico, distincion)} fileName={`Hoja_de_vida.pdf`}>
        {
          <Tooltip title='Descargar hoja de vida' aria-label="add">
            <IconButton
              style={{ color: '#2196f3' }}
              aria-label="delete">
              <GetAppIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        }
      </PDFDownloadLink>
    </Box>;
    return (
      <div>
        <React.Fragment>
          {descargar}
        </React.Fragment>
      </div>
    );
  }

}

export default DescargarHoJaDeVida
