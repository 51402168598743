import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DialogActions, Grid, Dialog, DialogContent, ListSubheader } from '@material-ui/core';
import { BtnForm } from "../../general/BotonesAccion";
import AppBarModal from '../../general/AppBarModal';
import { List, ListItem, ListItemIcon, ListItemText, Checkbox } from '@material-ui/core';
import { obtenerEstadosProceso } from "../../../global/js/funciones";
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from '../../../global/imagenes/emma_w.png';
import { ESTADOS_PROCESO_PORTAL } from '../helper'

const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
}));

function RenderEstados({ estados_siguiente, estado, cambiarSeleccion }) {
    const classes = useStyles();
    return (
      <List className={classes.root} subheader={
        <ListSubheader component="div" >
          Seleccione Estado
        </ListSubheader>
      }>
        {estados_siguiente.map(({ secundario }) => {
          let { codigo, nombre } = secundario;
          return (
            <ListItem key={codigo} role={undefined} dense button onClick={() => cambiarSeleccion(codigo)} >
              <ListItemIcon>
                <Checkbox edge="start" checked={estado === codigo} tabIndex={-1} disableRipple />
              </ListItemIcon>
              <ListItemText id={codigo} primary={nombre} />
            </ListItem>
          );
        })}
      </List>
    );
}

class GestionarProceso extends Component {

    constructor(props) {
        super(props)
        this.state = {
          cargando: true,
          titulo_res: 'Cargando..',
          estado: '',
          estados_siguiente: [],
          seleccion: 'gestion'
        }
    }

    // async componentWillMount(){
    //     let { estado_proceso } = this.props.postulante
    //     let { id } = estado_proceso
    //     let estados_siguiente = await obtenerEstadosProceso(id, [{ 'llave': 'principal', 'valor': estado_proceso.id }, { 'llave': 'secundario__generica', 'valor': ESTADOS_PROCESO_PORTAL }]);
    //     this.setState({ estado: '', estados_siguiente, cargando: false })
    // }

    async componentDidUpdate({ modalEstadoProceso }) {
      let { modalEstadoProceso: modalEstadoProcesoNew, postulante: { estado_proceso } } = this.props;
      let { id } = estado_proceso
      if (modalEstadoProceso !== modalEstadoProcesoNew && modalEstadoProcesoNew) {
        this.reiniciarEstado();
        let estados_siguiente = await obtenerEstadosProceso(id, [{ 'llave': 'principal', 'valor': estado_proceso.id }, { 'llave': 'secundario__generica', 'valor': ESTADOS_PROCESO_PORTAL }]);
        this.setState({ estado: '', estados_siguiente, cargando: false })
      }
    }

    reiniciarEstado() {
      this.setState({
        cargando: true,
        titulo_res: "Cargando..",
        estados_siguiente: [],
        seleccion: "gestion",
        estado: '',
      });
    }

    onSubmit = e => {
        e.preventDefault();
        let { postulante, gestionarProceso } = this.props;
        let { estado } = this.state;
        this.setState({ cargando: true, titulo_res: 'Enviando..' })
        let data = { estado }
        this.props.gestionarProceso(postulante.id, data, () => this.setState({ cargando: false, titulo_res: 'Cargando..' }));
    }

    pintarEstados() {
        let { estado, estados_siguiente } = this.state;
        return (
          <Grid container >
            <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
              <RenderEstados cambiarSeleccion={(estado) => this.setState({ estado })} estado={estado} estados_siguiente={estados_siguiente} />
            </Grid>
          </Grid >
        )
    }

    pintar() {
        let { seleccion } = this.state;
        return (<div>
          {seleccion === 'gestion' && this.pintarEstados()}
        </div>)
    }

    render() {
        let { modalEstadoProceso, mostrarModalEstadoProceso } = this.props;
        let { cargando, titulo_res } = this.state;
        return (
          <Dialog fullWidth={true} maxWidth="sm" open={modalEstadoProceso} onClose={() => mostrarModalEstadoProceso(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <AppBarModal titulo={'¿ Gestionar Oferta ?'} mostrarModal={mostrarModalEstadoProceso} titulo_accion="" accion="" />
            <DialogContent style={{ padding: '1' }} className='scroll'>
              {cargando ? <TareasTerminadas mensaje={titulo_res} marginTop='7%' imagen={emma_w} widthImg="7%" /> : <div style={{ padding: "5px 0 5px 0" }}>{this.pintar()}</div>}
            </DialogContent>
            <DialogActions>
              <BtnForm texto="CANCELAR" callback={() => mostrarModalEstadoProceso(false)} />
              {!cargando && <BtnForm texto="ACEPTAR" callback={this.onSubmit} />}
              </DialogActions>
          </Dialog>
        )
    }
}

export default GestionarProceso;