/* eslint-disable array-callback-return */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Funciones Globales
import { Dialog, DialogActions, DialogContent, Grid, Tooltip, IconButton, Paper, FormControl, InputLabel , Select, MenuItem} from "@material-ui/core";
import { consulta, rutaImg, generarFiltros, obtenerValoresFiltros, mostrarError } from "../../global/js/funciones";
import TituloAccion from "../general/TituloAccion";
import AccionesRapidas from "./../general/AccionesRapidas";
import FilterListIcon from "@material-ui/icons/FilterList";
import { red, blue, yellow, brown, green } from "@material-ui/core/colors";

// Funciones -Componentes del Módulo
import { mostrarModulo, actualizarMensaje } from "../../redux/actions/actGlobal";
import { BtnPermiso, BtnForm } from "../general/BotonesAccion";
import { MenuAdmInvitados } from "./invitadosVirtual/MenuAdmInvitados";
import SolicitudDetalle from "./invitadosVirtual/SolicitudDetalle";
import GestionarInvitadosVirtual from "./invitadosVirtual/GestionarInvitadosVirtual";
import TareasTerminadas from "../general/TareasTerminadas";
import ListarDatos from "../general/ListarDatos";
import { ESTADOS } from "./Helpers";
import AppBarModal from "../general/AppBarModal";
import GestFiltros from './invitadosVirtual/GestFiltros';
import Generica from "./../personas/generica/Generica";
// import CreditosGestionar from "../creditos/creditos/CreditosGestionar";
import { BtnDetalle, BtnGestionar, BtnRegresar } from "../general/BotonesAccion";
// IMAGENES
import emma_w from '../../global/imagenes/emma_w.png';
import emma_s1 from "../../global/imagenes/emma_s1.png";

import { mostrarModalAddFiltros } from "../../redux/actions/actInvitadosVirtuales";

const API_URI = `api/v1.0/invitadosVirtual/`;

class InvitadosVirtualAdministrar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accion: "btn_proceso",
      administrador: false,
      cargando: false,
      personas: [],
      generica: {
        tipo: 0,
        id: 0,
      },
      modal: false,
      id_persona: null,
      estados_procesos: [],
      accionProceso: "inicio",
      filtros: [],
      periodo_filtro: null,
      tipos_estados: [],
      periodos: [],
      solicitudes: [],
      solicitud: [],
      seleccionProc: null,
      solicitudes_resultado: [],
      openModal: false,
      mostrarModalGestion: false,
      cargarFiltroP: true,
      filtrarPeriodo: false,
      pintarProceso: "",
      mostrar_data_filtrada: false,
    };
  }


  pintarEstados() {
    let { tipos_estados, cargando, filtrarPeriodo, periodos, periodo_filtro, cargandoPeriodo } = this.state;
    let { mostrarModalAddFiltros, modalAddFiltros } = this.props;
    let {  tipoSolicitud  } = this.state;

    const obtenerColorTipo = (codigo) => {
      let color = 'white';

      const colores = {
        'Est_IVir_Sol': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
        'Est_IVir_Trami': { 'backgroundColor': '#ffc107', color },
        'Est_IVir_Asig_Cursar': { 'backgroundColor': '#01519B', color },
        'Est_IVir_Neg': { 'backgroundColor': '#b71c1c', color },
        'Est_IVir_Fin': { 'backgroundColor': '#009688', color },
        'Tod_Inv_Vir': { 'backgroundColor': '#0d47a1', color },
      };

      return colores[codigo];
    };

    return (
      <>
        <div style = {{ padding: '2% 2% 0% 2%' }}>
          <Grid container direction = { 'row' } spacing = { 1 } justifyContent = { 'center' }>
            <Grid item xs = { 12 } sm = { 12 } md = { 12 } lg = { 10 }>
              <Grid container direction = { 'row' } alignItems = "center" spacing = { 1 } style = {{ width: "100%" }}>
                <Grid item xs = { 6 } sm = { 6 } md = { 6 } lg = { 6 }>
                  <TituloAccion principal = '' alterno = 'Seleccione el estado a gestionar: ' />
                </Grid>
                <Grid item xs = { 6 } sm = { 6 } md = { 6 } lg = { 6 }>
                  <Tooltip title = 'Filtrar' aria-label = "add" onClick = { () => this.ModalfiltroPeriodo() }>
                    <IconButton edge = { false } color = "secondary" style = {{ float: 'right' }}><FilterListIcon /></IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              <Paper elevation = { 0 } square className = 'scroll contenido_fijo'>
                {
                  tipos_estados.length === 0 ?
                    <TareasTerminadas mensaje = 'Aún no hay solicitudes disponibles.' marginTop = '7%' imagen = { emma_s1 } widthImg = "7%" />
                    : <Grid container direction = { 'row' } spacing = { 1 } style = {{ width: "100%" }}>
                        {
                          tipos_estados.map(({ estado_solicitud__codigo: codigo, estado_solicitud__nombre: nombre, estado_solicitud__valora: inicial , cant}, index) => {
                            return (
                              <Grid  key= { index }  item xs = { 12 } sm = { 12 } md = { 6 } lg = { 6 }>
                                <Paper elevation = { 1 } style = {{ border: '1px solid #e0e0e0' }} onClick = { () => this.setState({ accion: codigo, tipoSolicitud: codigo }) }>
                                  <AccionesRapidas imagen = '' titulo = {`¡ ${ nombre } !`} sub_titulo = '' des_sub_titulo = '' avatar = { obtenerColorTipo(codigo) }  avatar_name = { inicial.charAt(0).toUpperCase() } list_desc={Number.isInteger(cant) ? [`Numero de Solicitudes : ${cant}`]  :  ['Debe aplicar los filtros.']}/>
                                </Paper>
                              </Grid>
                            );
                          })
                        }
                      </Grid>
                }
              </Paper>
            </Grid>
          </Grid>
        </div>

        <Dialog fullWidth = { true } maxWidth = "xs" open = { filtrarPeriodo } onClose = { () => this.setState({ filtrarPeriodo: false })} aria-labelledby = "alert-dialog-title" aria-describedby = "alert-dialog-description">
          <AppBarModal titulo = '¡ Aplicar Filtros !' mostrarModal = { () => this.setState({ filtrarPeriodo : false }) } titulo_accion = "" accion = "" />
          <DialogContent className = 'scroll'>
            {
              !cargandoPeriodo ?
                <Grid container spacing = { 3 } className = 'margin_cont'>
                  <Grid item xs = { 12 } xl = { 12 } sm = { 12 } md = { 12 } lg = { 12 }>
                    <FormControl className = 'form-control'>
                      <InputLabel htmlFor = "periodo_filtro">Seleccione Periodo</InputLabel>
                      <Select inputProps = { { name: 'periodo_filtro', id: 'periodo_filtro' } } value = { periodo_filtro } onChange = { this.onChange } required = { true }>
                        { periodos.map(({ periodo }, i) => <MenuItem key = { i } value = { periodo }>{ periodo }</MenuItem>) }
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                : <TareasTerminadas imagen = { emma_w } mensaje = 'Cargando...' widthImg = '5%' marginTop = '5%' />
            }
          </DialogContent>
          <DialogActions>
            <BtnForm texto = "CANCELAR" callback = { () => this.setState({ filtrarPeriodo: false }) } />
            <BtnForm texto = "FILTRAR" callback = { () => this.filtrarEstadoSolicitudes() } />
          </DialogActions>
        </Dialog>
        <GestFiltros
          tipoSolicitud = { tipoSolicitud }
          actualizarMensaje = { actualizarMensaje }
          modalFiltro = { modalAddFiltros }
          mostrarModalFiltros = { mostrarModalAddFiltros }
          filtrar = { (filtros) => this.actualizarDataSolicitudes(filtros) }
          mostrar_data_filtrada = {(mostrar_data_filtrada) => this.setState({mostrar_data_filtrada})}
          limpiar = { () => this.actualizarDataSolicitudes([ { llave: "estado_solicitud__codigo", valor: "Tod_Inv_Vir"}, { llave: "periodo", valor: this.state.periodo_filtro }, ]) }
        />
      </>
    )
  }

  obtenerPeriodos = () => {
    return new Promise((resolve) => {
      consulta(`${API_URI}periodos`, null, null, (error, estado, resp) => {
        resolve(resp);
      });
    });
  }

  filtrarEstadoSolicitudes = async () => {
    let { periodo_filtro } = this.state
    this.cambiarEstado({ filtrarPeriodo: false, cargando: true })
    await this.obtenerEstados(periodo_filtro);
    this.setState({accion: "btn_proceso"});
    // return this.pintarEstados();
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  ModalfiltroPeriodo = async () => {
    let { cargarFiltroP, periodo } = this.state
    this.cambiarEstado({ filtrarPeriodo: true })
    if (cargarFiltroP) {
      this.cambiarEstado({ cargandoPeriodo: true })
      let periodos = await this.obtenerPeriodos();
      if(!(periodos.find(e => e.periodo === periodo))) periodos.push({'periodo': periodo})
      this.cambiarEstado({ periodos, cargandoPeriodo: false, cargarFiltroP: false })
    }
  }

  pintarProcesos() {
    //revivasr
    let { mostrarModalAddFiltros, modalAddFiltros } = this.props;
    let { administrador, solicitudes_resultado, solicitud, openModal, mostrarModalGestion, cargando, tipoSolicitud  } = this.state;
    let e_solicitud = (this.state.accion === 'Tod_Inv_Vir') ? '' :  this.state.accion
    const acciones = (data) => {
      let { estado_solicitud: { codigo } } = data;
      const onClickDetalle = (data) => {
        this.setState({ solicitud: data, openModal: true });
      };
      const onClickGestionar = solicitud => {
        this.setState({ solicitud });
        this.setState({ mostrarModalGestion: true });
      }
      let detalle = <BtnDetalle callback={() => onClickDetalle(data) } color='#01579b' texto='Abrir' />;
      let gestionar = (codigo !== 'Est_IVir_Fin' && codigo !== 'Est_IVir_Neg') && <BtnGestionar callback = { () => onClickGestionar(data) } color='#00bfa5' texto='Gestionar' />;
      return administrador  ? <div>{ detalle }{ gestionar }</div> : <div>{ detalle }{/* { adjuntos } */}</div>;
    }

    const obtenerColor = (codigo) => {
      let color = 'white';
  
      const colores = {
        'Est_IVir_Ini': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
        'Est_IVir_Trami': { 'backgroundColor': '#ffc107', color },
        'Est_IVir_Asig_Cursar': { 'backgroundColor': '#01519B', color },
        'Est_IVir_Neg': { 'backgroundColor': '#b71c1c', color },
        'Est_IVir_Fin': { 'backgroundColor': '#009688', color },
        'Tod_Inv_Vir': { 'backgroundColor': '#0d47a1', color },
      };
  
      return colores[codigo];
    };
    return !cargando ? (
      <div>
        <div className = { 'regresar' } ><BtnRegresar callback={ () => this.setState({ accion: 'btn_proceso', mostrar_data_filtrada: false }) } /></div>
        <SolicitudDetalle
          data={solicitud}
          openModal={openModal}
          closeModal={(openModal) => this.setState({ openModal })}
        />
        <GestionarInvitadosVirtual
          solicitud={solicitud}
          ocultarModalGestion={(mostrarModalGestion) =>
            this.setState({ mostrarModalGestion })
          }
          mostrarModalGestion={mostrarModalGestion}
          gestionarInvitado={this.gestionarInvitado}
          accion={(accion) => this.setState({accion})}
        />
        <ListarDatos
          datos={solicitudes_resultado}
          titulo="Lista de solicitudes"
          id="tbl_solicitudes"
          opciones={true}
          filtrar={true}
          refrescar={true}
          actfiltrar = { () => mostrarModalAddFiltros(true) }
          actRefrescar = { () => this.obtenerDatosIniciales() }
          acciones={(row) => acciones(row)}
          color_avatar={({ estado_solicitud: { codigo } }) => obtenerColor(codigo)}
          buscar={true}
          descargar='INVITADOS VIRTUAL - EMMA'
          avatar={({ estado_solicitud }) => `${estado_solicitud["valora"]}`}
          fila_principal={(row) =>
            `${row["persona"]["primer_nombre"]} ${row["persona"]["segundo_nombre"]} ${row["persona"]["primer_apellido"]} ${row["persona"].segundo_apellido}`.toUpperCase()
          }
          filas={[
            {
              mostrar: ({ persona }) => `${persona["primer_nombre"]} ${persona["segundo_nombre"]} ${persona["primer_apellido"]} ${persona.segundo_apellido}`.toUpperCase(),
              id: "estudiante",
              enLista: false,
            },
            {
              mostrar: ({ persona }) => `${persona["identificacion"]}`,
              id: "identificacion",
              enLista: true,
            },
            {
              mostrar: ({ persona }) =>
                `${
                  persona["correo_personal"]
                    ? persona["correo_personal"]
                    : persona["correo"]
                }`,
              id: "correo",
              enLista: true,
            },
            {
              mostrar: ({ programa }) => `${programa["nombre"]}`,
              id: "programa",
              enLista: true,
            },
            {
              mostrar: ({ periodo }) => `${periodo}`,
              id: "periodo",
              enLista: false,
            },
            {
              mostrar: ({ estado_solicitud }) =>
                `${estado_solicitud["nombre"]}`,
              id: "estado",
              enLista: true,
            },
            {
              mostrar: ({ tipo_solicitud }) =>
                `${tipo_solicitud["nombre"]}`,
              id: "tipo",
              enLista: false,
            },
          ]}
        />
        <GestFiltros
          tipoSolicitud = { tipoSolicitud }
          actualizarMensaje = { actualizarMensaje }
          modalFiltro = { modalAddFiltros }
          mostrarModalFiltros = { mostrarModalAddFiltros }
          filtrar = { (filtros) => this.actualizarDataSolicitudes(filtros) }
          limpiar = { () => this.actualizarDataSolicitudes([ { llave: "estado_solicitud__codigo", valor: `${e_solicitud}`}, { llave: "periodo", valor: this.state.periodo_filtro }, ]) }
        />
      </div>
    ) : (
      <TareasTerminadas
        mensaje="No hay nada para mostrar!"
        marginTop="7%"
        imagen={emma_w}
        widthImg="7%"
      />
    );
  }

  esAdministrador() {
    let { usuario } = this.props;
    if (usuario && usuario.perfil && (usuario.perfil.codigo === "Per_Adm" || usuario.perfil.codigo === "Gest_Inv_Vir")) {
      this.setState({ administrador: true, esGestor: true });
    }
  }

  cambiarEstado = (estados) => {
    this.setState(estados);
  };

  vista = () => {
    let { modalAddFiltros } = this.props;
    let { accion, mostrar_data_filtrada} = this.state;
    switch (accion) {
      case "btn_proceso":
        if(mostrar_data_filtrada && !modalAddFiltros) return this.pintarProcesos()
        return this.pintarEstados();

      case "btn_admin":
        let { personas, generica, modal, id_persona } = this.state;
        return (
          <div>
            <ListarDatos
              id="tbl_autodiagnostico_permisos"
              titulo="Lista Gestores"
              datos={personas}
              opciones={true}
              buscar={true}
              acciones={(row) => this.acciones(row)}
              avatarImg={true}
              avatar={({ identificacion }) => `${rutaImg}${identificacion}.jpg`}
              fila_principal={(row) =>
                `${row.primer_nombre} ${row.segundo_nombre} ${row.primer_apellido} ${row.segundo_apellido}`.toUpperCase()
              }
              filas={[
                {
                  mostrar: ({ correo }) => `${correo}`,
                  id: "correo",
                  enLista: true,
                },
                {
                  mostrar: ({ identificacion }) => `${identificacion}`,
                  id: "identificacion",
                  enLista: true,
                },
              ]}
            />
            <Dialog
              open={modal}
              fullWidth={true}
              maxWidth="sm"
              onClose={() => this.setState({ modal: false })}
            >
              <AppBarModal
                titulo="¡ Lista Permisos !"
                mostrarModal={() => this.setState({ modal: false })}
                accion={() => this.setState({ modal: false })}
                titulo_accion="CERRAR"
              />
              <DialogContent style={{ padding: 0 }} className="scroll">
                <Generica
                  notificaciones={generica.tipo === 37}
                  tipo={generica.tipo}
                  persona={{ id: id_persona }}
                  genericaRelacionId={generica.id}
                  titulo="¡ Nuevo Permiso !"
                  tituloInput="Permisos"
                  tituloEliminar="¿ Eliminar Permiso ?"
                />
              </DialogContent>
              <DialogActions>
                <BtnForm
                  texto="Cerrar"
                  callback={() => this.setState({ modal: false })}
                />
              </DialogActions>
            </Dialog>
          </div>
        );
      case "Tod_Inv_Vir":
        if (!modalAddFiltros && !mostrar_data_filtrada) this.setState({accion: "btn_proceso"});
        if(mostrar_data_filtrada && !modalAddFiltros) return this.pintarProcesos()
        else return (
          this.pintarEstados()
        )
      default:
        return this.pintarProcesos();
    }
    
  };


  cambiarOpcion = (accion) => {
    this.setState({ accion });
  };

  async componentDidMount() {
    let { mostrarModulo } = this.props;
    this.setState({cargando: true})
    mostrarModulo({ ruta: "/InvitadosVirtualGestion", nombre: "Invitados Virtual Gestion" });
    let periodo = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'per_inv_vir'}]);
    await this.obtenerEstados(periodo[0].valorb);
    this.setState({ 'periodo': periodo[0].valorb, 'periodo_filtro': periodo[0].valorb, cargando:false });
    this.esAdministrador();
    // this.obtenerDatosIniciales();
  }


  gestionarInvitado = async (id, data, callback) => {
    const { actualizarMensaje } = this.props;
    let { solicitud } = this.state;
    let tipo = "";
    let titulo = "";
    // this.setState({ cargando: true });
    consulta(`${API_URI}${id}/gestionar`, data, "post", (error, estado, resp) => {
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          this.setState({
            // cargando: false,
            mostrarModalGestion: false,
            accion: solicitud["estado_solicitud"]["codigo"],
            tipoSolicitud: solicitud["estado_solicitud"]["codigo"],
          });
          this.obtenerDatosIniciales() 
          // this.setState({accion})
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      callback()
    }
    );
  };

  solicitudes_detallada = async (filtros = []) => {
    let f = await generarFiltros(filtros);
    return new Promise((resolve) => {
      consulta( `${API_URI}obtener_solicitudes?${f}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      });
    });
  };

  async obtenerSolicitudes(filtros = []) {
    let f = await generarFiltros(filtros)
    return new Promise(resolve => {
      consulta(`${API_URI}solicitudes/listar/1?${f}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      });
    });
  }

  async obtenerEstados(periodo) {
    return new Promise(resolve => {
      consulta(`${API_URI}EstadoSolicitudes/${periodo}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
        let { general, extra } = resp;
        general.push(extra[0]);
        this.cambiarEstado({ cargando: false, tipos_estados: general });
      });
    });
  }

  async actualizarDataSolicitudes(filtros = [ ]) {
    this.setState({ cargando: true });
    let solicitudes = await this.solicitudes_detallada(filtros);
    this.setState({ solicitudes, cargando: false, solicitudes_resultado: solicitudes });
    if (this.state.accion === "Tod_Inv_Vir") {
      this.setState({cargando: false});
    }
  }

  // async componentDidUpdate(prevProps, prevState) {
  //   let { mostrarModalAddFiltros } = this.props;
  //   const { periodo_filtro, tipoSolicitud, accion } = this.state;
  //   if (accion !== prevState.accion) {
  //     await this.obtenerEstados(periodo_filtro);
  //     let e_solicitud = (accion === 'Tod_Inv_Vir') ? '' :  accion
  //     let solicitudes_detallada = [];
  //     if(accion !== "btn_proceso" && accion !== "btn_admin" && accion !== "Tod_Inv_Vir"){
  //       this.setState({ solicitudes_resultado: [], cargando: true });
  //       solicitudes_detallada = await this.solicitudes_detallada( [ { llave: "estado_solicitud__codigo", valor: e_solicitud }, { llave: "periodo", valor: periodo_filtro }, ]);
  //       // this.actualizarDataSolicitudes([ { llave: "estado_solicitud", valor: accion }, { llave: "periodo", valor: periodo_filtro }, ], tipoSolicitud)
  //       this.setState({ solicitudes_resultado: solicitudes_detallada, cargando: false });
  //     }else if(accion === 'Tod_Inv_Vir'){
  //       mostrarModalAddFiltros(true);
  //     }else if(accion === "btn_proceso"){
  //       this.setState({mostrar_data_filtrada: false})
  //     }
  //   }
  // }

  async componentDidUpdate(prevProps, prevState) {
    let { mostrarModalAddFiltros } = this.props;
    const { periodo_filtro, tipoSolicitud, accion } = this.state;
    if (accion !== prevState.accion) {
      this.setState({cargando: true})
      if (accion != 'Tod_Inv_Vir') {
        let e_solicitud = (accion === 'Tod_Inv_Vir') ? '' :  accion
        let solicitudes_detallada = [];
        if(accion !== "btn_proceso" && accion !== "btn_admin" && accion !== "Tod_Inv_Vir"){
          this.setState({ solicitudes_resultado: [], cargando: true });
          solicitudes_detallada = await this.solicitudes_detallada( [ { llave: "estado_solicitud__codigo", valor: e_solicitud }, { llave: "periodo", valor: periodo_filtro }, ]);
          // this.actualizarDataSolicitudes([ { llave: "estado_solicitud", valor: accion }, { llave: "periodo", valor: periodo_filtro }, ], tipoSolicitud)
          this.setState({ solicitudes_resultado: solicitudes_detallada, cargando: false });
        }else if(accion === "btn_proceso"){
          await this.obtenerEstados(periodo_filtro);
          this.setState({mostrar_data_filtrada: false})
        }else if(accion === "btn_admin"){
          let personas = await this.obtenerGestores();
          this.setState({personas , cargando: false})
        }
      }else{
        mostrarModalAddFiltros(true);
      }
    }
  }

  async obtenerDatosIniciales () {
    let { tipoSolicitud, accion, periodo_filtro } = this.state;
    let e_solicitud = (accion === 'Tod_Inv_Vir') ? '' :  accion
    // this.setState({ cargando: true });
    this.actualizarDataSolicitudes([ { llave: "estado_solicitud__codigo", valor: e_solicitud }, { llave: "periodo", valor: periodo_filtro }, ]);
    // this.setState({ cargando: false });
  }

  async obtenerGestores() {
    return new Promise((resolve) => {
      consulta(`${API_URI}gestores`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      });
    });
  }

  acciones(data) {
    const onClickEstado = (data) =>
      this.setState({
        id_persona: data.id,
        modal: true,
        generica: { tipo: 37, id: ESTADOS },
      });
    let estados = (
      <BtnPermiso callback={() => onClickEstado(data)} texto="Estados" />
    );
    return (
      <div>
        {/*{tipos}*/} {estados}
      </div>
    );
  }

  render() {
    let { accion, administrador, cargando } = this.state;
    return (
      <div>
        <MenuAdmInvitados
          seleccionar={(accion) => this.cambiarOpcion(accion)}
          sele={accion}
          administrador={administrador}
        />
        <div className="contenido_modulo">
          {!cargando ? (
            this.vista()
          ) : (
            <TareasTerminadas
              mensaje="Cargando.."
              marginTop="7%"
              imagen={emma_w}
              widthImg="7%"
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let {modalAddFiltros} = state.redInvitadosVirtual;
  let { usuario } = state.redGlobal;
  return { modalAddFiltros, usuario };
};

const mapDispatchToProps = {
  actualizarMensaje,
  mostrarModulo,
  mostrarModalAddFiltros,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvitadosVirtualAdministrar);
