import React, { Component } from 'react'
import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import AppBarModal from '../../general/AppBarModal'
import { BtnForm } from '../../general/BotonesAccion';
import emma_w from '../../../global/imagenes/emma_w.png';

//Components
import Perfil from './Perfil'
import TareasTerminadas from '../../general/TareasTerminadas';

export default class PortalPerfilData extends Component {
    render() {
        let { 
            modalAddPerfilPortal, 
            mostrarModalAddPerfilPortal, 
            llave, 
            datos_modal, 
            onChangeState,
            enviarFormActual,
            datosPrincipales, 
            datosAcademicos, 
            datosHabilidad, 
            datosExperiencia, 
            datosNoExperiencia,
            datosDistincion, 
            agregarArchivos, 
            eliminarArchivo, 
            archivos, 
            actualizarMensaje,
            imgPerfil,
            cargandoPerfil
        } = this.props
        return (
            <div> 
                <Dialog open={modalAddPerfilPortal} onClose={() => mostrarModalAddPerfilPortal(false)} fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600}>
                    <AppBarModal titulo={'Agregar ' + datos_modal.nombre_modal} mostrarModal={() => mostrarModalAddPerfilPortal(false)} titulo_accion="" />
                    <DialogContent style={{ padding: 0, overflowX: 'hidden' }} className="scroll">
                        <div>
                            <Perfil
                                llave={llave}
                                onChangeState={onChangeState}
                                datosPrincipales={datosPrincipales}
                                datosAcademicos={datosAcademicos}
                                datosExperiencia={datosExperiencia}
                                datosNoExperiencia={datosNoExperiencia}
                                datosHabilidad={datosHabilidad}
                                datosDistincion={datosDistincion}
                                agregarArchivos={agregarArchivos}
                                eliminarArchivo={eliminarArchivo}
                                archivos={archivos}
                                actualizarMensaje={actualizarMensaje}
                            />
                        </div>
                        
                            {/* cargandoPerfil ? <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
                                :   <div>
                                        <Perfil
                                            llave={llave}
                                            onChangeState={onChangeState}
                                            datosPrincipales={datosPrincipales}
                                            datosAcademicos={datosAcademicos}
                                            datosExperiencia={datosExperiencia}
                                            datosHabilidad={datosHabilidad}
                                            datosDistincion={datosDistincion}
                                            agregarArchivos={agregarArchivos}
                                            eliminarArchivo={eliminarArchivo}
                                            archivos={archivos}
                                            actualizarMensaje={actualizarMensaje}
                                        />
                                    </div> */}
                        
                    </DialogContent>
                    <DialogActions>
                        <BtnForm texto="CERRAR" callback={() => mostrarModalAddPerfilPortal(false)} />
                        <BtnForm texto="GUARDAR" callback={() => enviarFormActual()}/>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}