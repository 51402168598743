import React, { Component } from 'react';
import PropTypes from 'prop-types';
//Material
import { Dialog, TextField, DialogActions, DialogContent, Link, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
// Material Icons
//Componebtes
import { BtnEditar, BtnForm, BtnCargar, BtnDetalle } from '../general/BotonesAccion';
import AppBarModal from '../general/AppBarModal'
import EnviarArchivo from '../general/EnviarArchivo'
import ConfirmarAccion from "../general/ConfirmarAccion"
import ListarDatos from '../general/ListarDatos'
//funciones
import { crear_form_data, formulario, consulta, mostrarError, api } from "../../global/js/funciones";
import { getEventosSolictud } from './funciones';
//utils
import moment from "moment"

class Eventos extends Component {

  constructor(props) {
    super(props)
    this.state = {
      fecha: null,
      hora_inicio: null,
      hora_fin: null,
      tipo: "",
      lugar: null,
      evento: null,
      accion: "nuevo",
      openSeleTipo: false,
      modalActa: false,
      modalConfir: false,
    }
  }

  //Handlers y utils

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    });
  }

  handleCloseModalNew = () => {
    this.setState({ fecha: null, hora_inicio: null, hora_fin: null, tipo: null, lugar: null, accion: "nuevo" })
    this.props.showModalNewEvento(false)
  }

  getColorAvatar = (fecha) => {
    let codigo = 2
    if (moment(fecha).diff(moment(), 'days') < 0) codigo = 0 //pasado
    // if (moment(fecha).diff(moment(), 'days') == 0) codigo = 1 //presente
    // if (moment(fecha).diff(moment(), 'days') > 0) codigo = 1 //futuro
    let colores = [
      { 'backgroundColor': '#009688', 'color': 'white' },
      { 'backgroundColor': '#03a9f4', 'color': 'white' },
      { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
    ]
    return (colores[codigo])
  }

  // Acciones Generales

  guardarEvento = () => {
    let { accion, fecha, hora_inicio, hora_fin, tipo, lugar, evento } = this.state
    let { solicitud, setEventos, actualizarMensaje } = this.props
    fecha = fecha ? moment(fecha).format('YYYY-MM-DD') : '';
    hora_inicio = hora_inicio ? moment(hora_inicio).format('HH:mm') : '';
    hora_fin = hora_fin ? moment(hora_fin).format('HH:mm') : '';
    let data = { fecha, hora_inicio, hora_fin, tipo, lugar }
    let ruta = accion == "nuevo" ? `api/v1.0/grados/${solicitud.id}/eventos/nuevo` : `api/v1.0/grados/eventos/${evento.id}`
    let method = accion == "nuevo" ? "post" : "patch"
    consulta(ruta, data, method, (err, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.',
        tipo = 'error';
      if (!err) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          this.handleCloseModalNew()
          this.setState({ modalConfir: false })
        } else if (estado === 302) {
          tipo = 'info';
          titulo = resp.titulo;
        }
        else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      getEventosSolictud(solicitud.id, setEventos);
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    });
  };

  guardarActaTutoria = async (archivo) => {
    let { solicitud, actualizarMensaje, setEventos } = this.props;
    let data = await crear_form_data({ archivo, tipo: 2, evento: this.state.evento.id });
    formulario(`api/v1.0/grados/${solicitud.id}/estado/${solicitud.estado_actual.id}/adjuntos/nuevo`, data, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          this.setState({ modalActa: false })
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      getEventosSolictud(solicitud.id, setEventos);
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    })
  }

  //Acciones basicas
  configurarAcciones = (data) => {
    let { admin, showModalNewEvento } = this.props

    const eventoEditar = () => {
      this.setState({
        evento: data,
        accion: "modificar",
        fecha: moment(data.fecha).format('YYYY-MM-DD, h:mm a'),
        hora_inicio: moment(data.hora_inicio, "h:mm A"),
        hora_fin: moment(data.hora_fin, "h:mm A"),
        tipo: data.lugar ? "presencial" : "virtual",
        lugar: data.lugar
      })
      showModalNewEvento(true)
    }

    const eventoCargarActa = () => {
      this.setState({ modalActa: true, evento: data })
    }

    let acta = (moment(data.fecha).diff(moment(), 'days')) <= 0 && !data.adjunto ? <BtnCargar titulo='Cargar acta' callback={() => eventoCargarActa()} /> : ""
    let verActa = data.adjunto ? <Link href={`${api}${data.adjunto}`} rel="noopener noreferrer" target="_blank"><BtnDetalle texto="Descargar Acta" callback={() => { }} /></Link> : ""
    let editar = (moment(data.fecha).diff(moment(), 'days')) >= 0 ? <BtnEditar texto='Editar' callback={() => eventoEditar()} /> : ""
    let botones = admin ? <div>{editar} {verActa} {acta}</div> : ""
    return botones
  }

  //Modales


  modalNuevoEvento = () => {
    let { modalNewEvento } = this.props
    let { openSeleTipo, accion, tipo, fecha, hora_inicio, hora_fin, lugar } = this.state
    return (
      <Dialog open={modalNewEvento} onClose={() => this.handleCloseModalNew()} fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} style={{ width: 480, margin: "auto" }}>
        <AppBarModal titulo={accion == "nuevo" ? "Nueva Tutoria" : "Modificar Evento"} mostrarModal={this.handleCloseModalNew} titulo_accion="" />
        <DialogContent className='scroll'>
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} >
                <Grid container>
                  <KeyboardDatePicker
                    required={true}
                    style={{ marginTop: 3, width: '100%' }}
                    minDate={Date("YYYY-MM-dd")}
                    margin="normal"
                    label="Fecha evento"
                    format="yyyy-MM-dd"
                    value={fecha}
                    onChange={(fecha) => this.setState({ fecha })}
                    KeyboardButtonProps={{ 'aria-label': 'change date', }}
                    minDateMessage={`La fecha debe ser mayor que la fecha actual.`}
                    invalidDateMessage='Formato no valido'
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} >
                <Grid container>
                  <KeyboardTimePicker
                    required={true}
                    style={{ marginTop: 3, width: '100%' }}
                    margin="normal"
                    label="Hora Inicio"
                    value={hora_inicio}
                    onChange={(hora_inicio) => this.setState({ hora_inicio })}
                    KeyboardButtonProps={{ 'aria-label': 'change date', }}
                    invalidDateMessage='Formato no valido'
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} >
                <Grid container >
                  <KeyboardTimePicker
                    required={true}
                    style={{ marginTop: 1, width: '100%' }}
                    margin="normal"
                    label="Hora Fin"
                    value={hora_fin}
                    onChange={(hora_fin) => this.setState({ hora_fin })}
                    KeyboardButtonProps={{ 'aria-label': 'change date', }}
                    invalidDateMessage='Formato no valido'
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            {accion == "nuevo" &&
              <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                <FormControl className="form-control">
                  <InputLabel shrink required={true}>Tipo Tutoría</InputLabel>
                  <Select open={openSeleTipo} onClose={() => this.setState({ openSeleTipo: false })} onOpen={() => this.setState({ openSeleTipo: true })} value={tipo} onChange={(e) => this.setState({ tipo: e.target.value })} labelid="demo-simple-select-placeholder-label-label" id="demo-simple-select-placeholder-label" >
                    <MenuItem key={1} value={"presencial"}>Tutoría presencial</MenuItem>
                    <MenuItem key={2} value={"virtual"}>Tutoría Virtual</MenuItem>
                  </Select>
                </FormControl>
              </Grid>}
            {tipo == "presencial" &&
              <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                <TextField required multiline value={lugar} onChange={this.onChange} name="lugar" id="lugar" label="Lugar" fullWidth placeholder="Ejemplo: Bloque 10, aula 121" />
              </Grid>
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CANCELAR" callback={() => this.handleCloseModalNew()} />
          <BtnForm texto={accion == "nuevo" ? "CREAR" : "MODIFICAR"} callback={() => accion == "nuevo" ? this.guardarEvento() : this.setState({ modalConfir: true })} />
        </DialogActions>
      </Dialog >
    )
  };


  render() {
    let { admin, eventos, showModalNewEvento } = this.props
    let { modalActa, modalConfir } = this.state
    return (
      <div>
        <ListarDatos
          id="tbl_eventos"
          datos={eventos}
          avatar={() => "T"}
          color_avatar={({ fecha }) => this.getColorAvatar(fecha)}
          agregar={admin}
          acciones={row => this.configurarAcciones(row)}
          actAgregar={() => showModalNewEvento(true)}
          fila_principal={() => "TUTORIAS"}
          filas={[
            {
              'nombre': 'Organizador: ',
              'mostrar': ({ usuario_registro }) => usuario_registro ? `${usuario_registro.primer_nombre} ${usuario_registro.primer_apellido}` : "",
            },
            {
              'nombre': 'Programada para el: ',
              'mostrar': ({ fecha }) => fecha,
            },
            {
              'nombre': 'Hora: ',
              'mostrar': ({ hora_inicio, hora_fin }) => `${moment(hora_inicio, "HH:mm").format("h:mm A")} - ${moment(hora_fin, "HH:mm").format("h:mm A")}`,
            },
            {
              'nombre': 'Modalidad: ',
              'mostrar': ({ lugar }) => lugar ? 'Presencial' : 'Virtual',
            },
            {
              'nombre': 'Lugar: ',
              'mostrar': ({ lugar }) => lugar,
            },
          ]}
        />
        {/* Modales */}
        {this.modalNuevoEvento()}
        <ConfirmarAccion
          titulo={'¿ Modificar Evento ?'}
          dato={{ id: 0 }}
          mensaje1="Esta Seguro que desea modificar los datos de este evento."
          modalConAccion={modalConfir}
          mostrarModalConfAccion={() => this.setState({ modalConfir: false })}
          ejecutarAccion={() => this.guardarEvento()} />
        <EnviarArchivo modalAdd={modalActa} enviarArchivo={(archivo) => this.guardarActaTutoria(archivo)} mostrarModalAdd={() => this.setState({ modalActa: false })} titulo='¿Agregar Acta de tutoria?' />
      </div>
    )
  }
}

Eventos.defaultProps = {
  modalNewEvento: false,
};

export default Eventos;

