import React, {Component} from 'react'
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import AppBarModal from "./../../general/AppBarModal";
import TareasTerminadas from "./../../general/TareasTerminadas";
import BuscarSelect from '../../general/BuscarSelect';
import { BtnForm } from "../../general/BotonesAccion";
import InputBuscar from '../../general/InputBuscar';
import BuscarPersona from '../../general/BuscarPersona';
import { obtenerValoresFiltros, transformar, consulta } from "../../../global/js/funciones";

import emma_w from './../../../global/imagenes/emma_w.png'


class InscripcionesFiltrar extends Component {
    constructor(props) {
        super(props)
        this.state = {
          cargando: true,
          cargar: true,
          programas: [],
          estratos: [],
          programa: null,
          estrato: null,
          generacion: '',
          buscarEstudiante: false,
          buscarProfesor: false,
          estudiante: {
            id: 0,
            nombre: ''
          },
          profesor: {
            id: 0,
            nombre: ''
          },
          calificacion_contacto:{'label':'', 'value':''},
        }
    }

    obtenerPeriodos = () => {
        return new Promise(resolve => {
            consulta(`api/v1.0/inscripciones/periodos`, null, null,
                (error, estado, resp) => {
                    resolve(resp)
                }
            );
        })
    }

    async componentDidUpdate({ modalAddFiltrosInscripcion, accionProceso }) {
        let { modalAddFiltrosInscripcion: modalAddFiltrosInscripcionNew, accionProceso: accionProcesoNew } = this.props;
        if (modalAddFiltrosInscripcion !== modalAddFiltrosInscripcionNew && this.state.cargar) {
          this.setState({ cargando: true })
          let programas = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': 5 }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valorc', 'valor': 'pregrado' }]); 
          let estratos = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': 9 }, { 'llave': 'estado', 'valor': 1 }]);
          this.setState({
            'programas': transformar(programas),
            'estratos' : transformar(estratos),
            cargando: false,
            cargar: false,
          })
        }

        if (accionProceso != accionProcesoNew && accionProceso != 'inicio') {
            this.setState({
              programa: null,
              tipo: '',
              estrato: null,
              estudiante: {
                id: 0,
                nombre: ''
              },
              profesor: {
                id: 0,
                nombre: ''
              },
              calificacion_contacto:null,
            })
          }
    }

    onChange = ({ target }) => {
        let { value, name } = target;
        this.setState({
          [name]: value
        })
    }

    resetState = () => {
        let { limpiar, mostrarModalAddFiltrosInscripcion } = this.props;
        this.setState({
            programa: null,
            estrato: null,
            estado: null,
            periodo: null,
            generacion: '',
            estudiante: {
                id: 0,
                nombre: ''
            }
        })
        limpiar();
        mostrarModalAddFiltrosInscripcion(false)
    }

    onSubmit = e => {
        let { filtrar, accionProceso, mostrarTodos, actualizarMensaje, mostrarModalAddFiltrosInscripcion, setIs_Ins_Env_Pre } = this.props;
        let { programa, estrato, estado, generacion, periodo, estudiante, profesor, calificacion_contacto } = this.state;

        let filtros = [];
        if (programa) filtros.push({ 'llave': 'carrera', 'valor': programa.value });
        if (estrato) filtros.push({ 'llave': 'estrato', 'valor': estrato.value });
        if (estado) filtros.push({ 'llave': 'estado_actual', 'valor': estado.value });
        if (periodo) filtros.push({ 'llave': 'periodo', 'valor': periodo.value });
        if (generacion) filtros.push({ 'llave': 'generacion', 'valor': generacion });
        if (estudiante.id) filtros.push({ 'llave': 'estudiante', 'valor': estudiante.id });
        if (profesor.id) filtros.push({ 'llave': 'gestor', 'valor': profesor.id });
        if (calificacion_contacto) filtros.push({ 'llave': 'gestion_inscripcion__calificacion_contacto', 'valor': calificacion_contacto.value });
        if (filtros.length > 0){
            if (accionProceso == 'inicio') mostrarTodos('Tod_Ins')
            mostrarModalAddFiltrosInscripcion(false)
            filtrar(filtros);
        }
        else actualizarMensaje({ titulo: 'Seleccione Algún filtro', tipo: 'info', mostrar: true, tiempo: 6000 });
        e.preventDefault();
    }

    render(){
        let { modalAddFiltrosInscripcion, mostrarModalAddFiltrosInscripcion, accionProceso, mostrar, inscripciones, tipoDeEstadosCalificacionContacto, is_Ins_Env_Pre } = this.props;
        let {cargando, periodos, programas, estratos, estados, periodo, programa, estrato, estado, generacion, buscarEstudiante, estudiante, calificacion_contacto, profesor, buscarProfesor} = this.state;
        let habilitar_limpiar = accionProceso !== 'Tod_Ins' && accionProceso !== 'inicio'
        return(
            <>
                <Dialog fullWidth={true} maxWidth="sm" open={modalAddFiltrosInscripcion} onClose={() => mostrarModalAddFiltrosInscripcion(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <AppBarModal titulo='¡ Aplicar Filtros !' mostrarModal={mostrarModalAddFiltrosInscripcion} titulo_accion={habilitar_limpiar ? "Limpiar" : ''} accion={habilitar_limpiar ? this.resetState : ''} />
                    <DialogContent className='scroll'>
                        {
                            cargando ? <TareasTerminadas mensaje="Cargando..." marginTop='7%' imagen={emma_w} widthImg="7%" cargando={true}/> :
                                <Grid container spacing={3} className='margin_cont'>
                                    <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                                        <InputBuscar
                                            id='estudiante'
                                            label='Estudiante'
                                            placeholder='Clic aquí para buscar'
                                            value={estudiante.nombre}
                                            callback={() => this.setState({ buscarEstudiante: true })} />
                                    </Grid>
                                        {is_Ins_Env_Pre ? 
                                        <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                                            <InputBuscar
                                                id='profesor'
                                                label='Gestor'
                                                placeholder='Clic aquí para buscar'
                                                value={profesor.nombre}
                                                callback={() => this.setState({ buscarProfesor: true })} />
                                        </Grid> : null}
                                    {mostrar && (
                                        <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                                        <BuscarSelect datos={programas} cambiarEstado={(value) => this.setState({ programa: value })} valor={programa} id='select-programa' nombre='Programa' placeholder='Seleccione el Programa' />
                                    </Grid>
                                    )}
                                    <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                                        <BuscarSelect datos={estratos} cambiarEstado={(value) => this.setState({ estrato: value })} valor={estrato} id='select-estrato' nombre='Estrato' placeholder='Seleccione el Estrato' />
                                    </Grid>
                                    {mostrar && (
                                    <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                                        <FormControl className='form-control'>
                                            <InputLabel htmlFor="generacion">Seleccion la Generacion E</InputLabel>
                                            <Select
                                                inputProps={{
                                                    name: 'generacion',
                                                    id: 'generacion',
                                                }}
                                                value={generacion}
                                                onChange={this.onChange}
                                                required={true}>
                                                <MenuItem value={'si'}>SI</MenuItem>
                                                <MenuItem value={'no'}>NO</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    )}
                                    {tipoDeEstadosCalificacionContacto.length > 0 && is_Ins_Env_Pre ? 
                                        <Grid item xs={12}>
                                        <BuscarSelect 
                                        datos={tipoDeEstadosCalificacionContacto} 
                                        cambiarEstado={(value) => this.setState({ calificacion_contacto: value })} 
                                        valor={calificacion_contacto} 
                                        id='select-calificacion' 
                                        nombre='Calificacion Contacto' 
                                        placeholder='Seleccione Calificacion' />
                                        </Grid>
                                    : ''}
                                </Grid>
                        }
                    </DialogContent>
                    <DialogActions>
                        <BtnForm texto="CANCELAR" callback={() => mostrarModalAddFiltrosInscripcion(false)} />
                        <BtnForm texto="FILTRAR" callback={this.onSubmit} />
                    </DialogActions>
                </Dialog>


                <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={buscarEstudiante} onClose={() => this.setState({ buscarEstudiante: false })}>
                    <AppBarModal titulo={'¡ Buscar Estudiante !'} mostrarModal={() => this.setState({ buscarEstudiante: false })} titulo_accion="CERRAR" accion={() => this.setState({ buscarEstudiante: false })} />
                    <DialogContent style={{ padding: '0' }} className='scroll'>
                        <BuscarPersona callback={({ id, primer_nombre, primer_apellido, segundo_apellido }) => this.setState({
                            estudiante: {
                                id,
                                nombre: `${primer_nombre} ${primer_apellido} ${segundo_apellido}`,
                            }, buscarEstudiante: false
                        })} />
                    </DialogContent>
                    <DialogActions>
                        <BtnForm texto="CANCELAR" callback={() => this.setState({ buscarEstudiante: false })} />
                    </DialogActions>
                </Dialog>

                <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={buscarProfesor} onClose={() => this.setState({ buscarProfesor: false })}>
                    <AppBarModal titulo={'¡ Buscar Gestor !'} mostrarModal={() => this.setState({ buscarProfesor: false })} titulo_accion="CERRAR" accion={() => this.setState({ buscarProfesor: false })} />
                    <DialogContent style={{ padding: '0' }} className='scroll'>
                        <BuscarPersona callback={({ id, primer_nombre, primer_apellido, segundo_apellido }) => this.setState({
                            profesor: {
                                id,
                                nombre: `${primer_nombre} ${primer_apellido} ${segundo_apellido}`,
                            }, buscarProfesor: false
                        })} />
                    </DialogContent>
                    <DialogActions>
                        <BtnForm texto="CANCELAR" callback={() => this.setState({ buscarProfesor: false })} />
                    </DialogActions>
                </Dialog>
            </>
        )   
    }
}

InscripcionesFiltrar.propTypes = {
    modalAddFiltrosInscripcion: PropTypes.bool.isRequired,
    mostrarModalAddFiltrosInscripcion: PropTypes.func.isRequired,
    filtrar: PropTypes.func.isRequired,
    limpiar: PropTypes.func.isRequired,
}

export default InscripcionesFiltrar;