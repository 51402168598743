// // HELPERS PRODUCCION

export const TIPOS_SISEF = 269
export const PLAN_ESTUDIO = 270
export const COMP_GENERICA = 271
export const SISEF_CORTES = 272
export const SISEF_NIVELES = 273
export const SISEF_RECURSOS = 274
export const SISEF_REFERENCIAS = 275
export const SISEF_ESTADOS = 277
export const ESTRATEGIA = 276
export const ITEMS_PROCESOS = 278
export const PROGRAMAS = 5
export const ESTADOS_GENERALES = 279
export const ACCIONES =280
export const SISEF_MASIVO =281

// CODIGOS DE NIVELES DE CRITERIOS

export const COD_NIVEL_AVANZADO = "avanzado"
export const COD_NIVEL_EXCELENTE = "excelente"
export const COD_NIVEL_SATISFACTORIO = "satisfactorio"
export const COD_NIVEL_MINIMO = "minimo"
export const COD_NIVEL_INSUFICIENTE = "insuficiente"


// HELPERS LOCAL

// export const TIPOS_SISEF = 1119; //4134;  Tipos Solicitud - mentora
// export const PLAN_ESTUDIO = 1120; //4135
// export const COMP_GENERICA = 1121; //4136
// export const SISEF_CORTES = 1122; //4137
// export const SISEF_NIVELES = 1123; //4138
// export const SISEF_RECURSOS = 1124; //4139
// export const SISEF_REFERENCIAS = 1125; //4140
// export const SISEF_ESTADOS = 1127; //4142  Estados Syllabus - mentora
// export const ESTRATEGIA = 1126; //4141  Tipos genericas mentora
// export const ITEMS_PROCESOS = 1129 ; //4151 TIPOS DE CRITERIOS SISEF
// export const PROGRAMAS = 5; //5 TIPOS DE CRITERIOS SISEF
// export const ESTADOS_GENERALES = 1130; //4166  ESTADOS SISEF
// export const ACCIONES =1131; //5143  ESTADOS SISEF
// export const SISEF_MASIVO =1132; //5143  ESTADOS SISEF

// // // CODIGOS DE NIVELES DE CRITERIOS

// export const COD_NIVEL_AVANZADO = "avanzado"
// export const COD_NIVEL_EXCELENTE = "excelente"
// export const COD_NIVEL_SATISFACTORIO = "satisfactorio"
// export const COD_NIVEL_MINIMO = "minimo"
// export const COD_NIVEL_INSUFICIENTE = "insuficiente"