const MODAL_ADD_FILTROS = "MODAL_ADD_FILTROS";

const mostrarModalAddFiltros = (show) => {
  return {
    type: MODAL_ADD_FILTROS,
    show,
  };
};


module.exports = {
  MODAL_ADD_FILTROS,
  mostrarModalAddFiltros,
};
