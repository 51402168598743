import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

//FUNCIONES GENERALES
import { consulta, generarFiltros, mostrarError, obtenerValores } from "../../../global/js/funciones";

//COMPONENTES DEL MODULO
import PermisoListar from './PermisoListar'
import PermisoAgregar from './PermisoAgregar';
import ConfirmarAccion from '../../general/ConfirmarAccion';
import emma_w from '../../../global/imagenes/emma_w.png';
import TareasTerminadas from '../../general/TareasTerminadas';
//ACCIONES DEL MODULO
import { mostrarModalAdd, mostrarModalMod, mostrarModalDel, actualizarDataGenericas, actualizarRender, actualizarDataGenerica } from "../../../redux/actions/actGenericas";
import { actualizarMensaje } from "../../../redux/actions/actGlobal";


class Permisos extends Component {

  constructor(props) {
    super(props)
    this.state = {
      id:0,
      cargando: true,
    }
  }

  async componentDidMount() {
    let { salas} = this.props
    if(salas) {
      await this.obtenerPermisosSalas(salas)
    }
  }

  obtenerPermisosSalas = async ( id )=> {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'principal__id', 'valor': id }]);;
    let { actualizarDataGenericas, actualizarRender } = this.props;
    consulta(`api/v1.0/bloques/listaSalasSoftware?${f}`, null, null,
      (error, estado, resp) => {
        if (estado === 200) {
          actualizarDataGenericas(resp);
          actualizarRender(true);
          this.setState({ cargando: false });

        }
      }
    );
  }

  guardarPermiso = (relaciones) => {
    let { actualizarMensaje,salas,changeSalasToSoftware} = this.props;
    consulta(`api/v1.0/bloques/permisosSoftwareCrear`,{ secundario: relaciones, principal: salas}, "post",
      (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            console.log(resp);
            this.obtenerPermisosSalas(salas)
            changeSalasToSoftware(salas)
          } else  {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  }


  eliminarPermiso = (actualizarDataGenerica) => {
    let { actualizarMensaje, mostrarModalDel, salas,changeSalasToSoftware} = this.props;
    consulta(`api/v1.0/permisos/${actualizarDataGenerica}/eliminar`, {}, "put",
      (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            if(salas) {
            this.obtenerPermisosSalas(salas)
            changeSalasToSoftware(salas)
            }
            mostrarModalDel(false);
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  }

  

  render() {
    let {
      genericas,
      actualizarDataGenerica,
      mostrarModalDel,
      modalDel,
      mostrarModalAdd,
      generica,
      modalAdd,
      genericaRelacionId,
      tituloEliminar,
      tipo,
      titulo,
      tituloInput,
    } = this.props;
    return (
      <div>
        {!this.state.cargando ?
          <div>
            <ConfirmarAccion mensaje1={`El dato "${generica.nombre}" será eliminado, `} ejecutarAccion={this.eliminarPermiso} mostrarModalConfAccion={mostrarModalDel} modalConAccion={modalDel} dato={generica} titulo={tituloEliminar} />
            <PermisoListar  mostrarModalAdd={mostrarModalAdd} mostrarModalDel={mostrarModalDel} actualizarDataGenerica={actualizarDataGenerica} genericas={genericas} />
            <PermisoAgregar titulo={titulo} tituloInput={tituloInput} obtenerValores={obtenerValores} modalAdd={modalAdd} mostrarModalAdd={mostrarModalAdd} guardarGenerica={this.guardarPermiso} genericaRelacionId={genericaRelacionId} tipo={tipo} />
          </div> : <TareasTerminadas imagen={emma_w} mensaje='Cargando..' widthImg='7%' marginTop='100px' />}
      </div>
    )
  }
}

//CARGO ESTADOS DEL MODULO
const mapStateToProps = (state) => {
  let { generica, render, genericas, modalMod, modalAdd, modalDel } = state.redGenericas;
  return {
    render,
    generica,
    genericas,
    modalMod,
    modalAdd,
    modalDel,
  }
};

//CARGO FUNCIONES DEL MODULO
const mapDispatchToProps = {
  actualizarMensaje,
  actualizarRender,
  actualizarDataGenericas,
  actualizarDataGenerica,
  mostrarModalAdd,
  mostrarModalMod,
  mostrarModalDel,
}

//Props del componente
Permisos.propTypes = {
  //variables
  render: PropTypes.bool.isRequired,
  genericas: PropTypes.array.isRequired,
  modalMod: PropTypes.bool.isRequired,
  modalAdd: PropTypes.bool.isRequired,
  modalDel: PropTypes.bool.isRequired,
  generica: PropTypes.object.isRequired,
  tipo: PropTypes.number.isRequired,
  tituloInput: PropTypes.string.isRequired,
  titulo: PropTypes.string.isRequired,
  tituloEliminar: PropTypes.string.isRequired,
  persona: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),

  //funciones
  actualizarMensaje: PropTypes.func.isRequired,
  actualizarRender: PropTypes.func.isRequired,
  actualizarDataGenericas: PropTypes.func.isRequired,
  actualizarDataGenerica: PropTypes.func.isRequired,
  mostrarModalAdd: PropTypes.func.isRequired,
  mostrarModalMod: PropTypes.func.isRequired,
  mostrarModalDel: PropTypes.func.isRequired,
}



export default connect(mapStateToProps, mapDispatchToProps)(Permisos);