
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { mostrarModulo, actualizarMensaje } from "../../../redux/actions/actGlobal";
import { BtnForm } from "../../general/BotonesAccion";
import {
  obtenerValores,
  obtenerValoresFiltros,
  obtenerPermisosValor,
  formulario,
  mostrarError,
  crear_form_data,
} from "../../../global/js/funciones";
import {
  mostrarModalMensajeValidacionId, mostrarModalAutorizacionPadre,
  mostrarModalAutorizacionCumplimiento, mostrarModalAutorizacionCalidad
} from "../../../redux/actions/actInscripciones";
import InscripcionForm from "./PreInscripcionForm";
import InscripcionInicio from "./PreInscripcionInicio";
import emma_s from "../../../global/imagenes/emma_s1.png";
import TareasTerminadas from "../../general/TareasTerminadas";
import AppBarModal from "./../../general/AppBarModal";
import { DialogActions, DialogContent, Dialog, Card, CardContent, Typography } from '@material-ui/core'
import {
  LINEAEDU,
  JORNADAPREINSCRIPCION,
  GENEROS,
  COSTEAR_ESTUDIOS,
  AUTORIZACION_PREINSCRIPCION,
  EPS,
  EPS_OTRO,
  GRUPOS_INSCRIPCIONES,
  DISCAPACIDADES_INSCRIPCIONES,
  VALIDACION_DOCUMENTOS_INSCRIPCIONES,
} from "../helper";


function AutorizacionPadre(component_props) {
  let { mostrarModalAutorizacionPadre, modalAutorizacionPadre, autorizaciones, aceptoAutorizacion } = component_props
  return (
    <Dialog open={modalAutorizacionPadre} maxWidth='sm' fullWidth={true} onClose={mostrarModalAutorizacionPadre.bind(this, false)}>
      <AppBarModal titulo='¡ AUTORIZACION PADRES !' mostrarModal={mostrarModalAutorizacionPadre} titulo_accion='' />
      <DialogContent style={{ padding: 0 }} className='scroll' >
        <Card>
          <CardContent>
            <Typography color="textSecondary" gutterBottom style={{ textAlign: 'justify' }}>
              {autorizaciones[0].descripcion}{autorizaciones[0].valora}{autorizaciones[0].valorb}
            </Typography>
            <Typography color="textSecondary" gutterBottom style={{ textAlign: 'justify' }}>
              {autorizaciones[0].valorc}
            </Typography>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <BtnForm texto="Aceptar" callback={() => aceptoAutorizacion()} />
      </DialogActions>
    </Dialog>
  )
}


function AutorizacionCalidad(component_props) {
  let { mostrarModalAutorizacionCalidad, modalAutorizacionCalidad, autorizaciones, aceptoAutorizacionCalidad } = component_props
  return (
    <Dialog open={modalAutorizacionCalidad} maxWidth='sm' fullWidth={true} onClose={mostrarModalAutorizacionCalidad.bind(this, false)}>
      <AppBarModal titulo='¡ AUTORIZACION CALIDAD TITULAR !' mostrarModal={mostrarModalAutorizacionCalidad} titulo_accion='' />
      <DialogContent style={{ padding: 0 }} className='scroll' >
        <Card>
          <CardContent>
            <Typography color="textSecondary" gutterBottom style={{ textAlign: 'justify' }}>
              {autorizaciones[1].descripcion}{autorizaciones[1].valora}{autorizaciones[1].valorb}
              {autorizaciones[1].valorc}
            </Typography>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <BtnForm texto="Aceptar" callback={() => aceptoAutorizacionCalidad()} />
      </DialogActions>
    </Dialog>
  )
}


function CumplimientoLey(component_props) {
  let { mostrarModalAutorizacionCumplimiento, modalAutorizacionCumplimiento, autorizaciones, aceptoAutorizacionCumplimiento, link_politica } = component_props
  return (
    <Dialog open={modalAutorizacionCumplimiento} maxWidth='sm' fullWidth={true} onClose={mostrarModalAutorizacionCumplimiento.bind(this, false)}>
      <AppBarModal titulo='¡ CUMPLIMIENTO LEY 1581 DE 2012 !' mostrarModal={mostrarModalAutorizacionCumplimiento} titulo_accion='' />
      <DialogContent style={{ padding: 0 }} className='scroll' >
        <Card>
          <CardContent>
            <Typography color="textSecondary" gutterBottom style={{ textAlign: 'justify' }}>
              {autorizaciones[2].descripcion}
              <p><a href={link_politica} target="_blank"  className='sin_decorador' style={{ color: 'black' }}>Ver Políticas</a></p>
            </Typography>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <BtnForm texto="Aceptar" callback={() => aceptoAutorizacionCumplimiento()} />
      </DialogActions>
    </Dialog>
  )
}

class PreInscripcionAgregar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      iniciar: false,
      identificacion: '',
      primer_nombre: '',
      segundo_nombre: '',
      primer_apellido: '',
      segundo_apellido: '',
      telefono1: '',
      telefono2: '',
      celular1: '',
      celular2: '',
      correo: '',
      contactoEmergencia: '',
      celularEmergencia: '',
      departamentos: [],
      departamento: '',
      ciudades: [],
      ciudad: '',
      carrera: '',
      carrera2: '',
      edad: '',
      estratos: [],
      estrato: '',
      barrio: '',
      direccion: '',
      guardado: false,
      enviando: false,
      mensaje_contacto: '',
      mostrar_btn: true,
      nombreEps : '',
      nombreEpsOtro : '',
      opcionesEps : [],
      poseeEps : '',
      opcionesPoseeEps: [
        {
          value: 'si',
          label: 'SI',
        },
        {
          value: 'no',
          label: 'NO',
        }
      ],
      lineas_edu_co: [],
      linea_edu: '',
      jornada_preinscripcion: [],
      jornada: '',
      mensaje_final: '',
      cargando: true,
      nombre_colegio: '',
      estudiante_11: '',
      generos: [],
      genero: '',
      grupos : [],
      grupo: '',
      discapacidades : [],
      discapacidad: '',
      adj_certificado : '',
      adj_certificado_final : '',
      validacion_doc : [],
      terminarProceso: false,
      opciones_estudiante: [
        {
          value: 'si',
          label: 'SI',
        },
        {
          value: 'no',
          label: 'NO',
        }
      ],
      niveles_educacion: [
        {
          value: 'tecnico',
          label: 'TECNICO',
        },
        {
          value: 'tecnologo',
          label: 'TECNOLOGO',
        },
        {
          value: 'profesional',
          label: 'PROFESIONAL',
        }
      ],
      nivel_educacion: '',
      costear_estudios: [],
      costear_estudio: '',
      nombre_madre: '',
      nombre_padre: '',
      celular_madre: '',
      celular_padre: '',
      autorizaciones: [],
      otro_costear: '',
      otro_jornada: '',
      link_politica: ''
    }
  }

  componentDidMount() {
    this.cargarTipos();
  }

  async componentDidUpdate({ }, { departamento, poseeEps, nombreEps }) {
    let { departamento: departamentoNew } = this.state;
    if (departamentoNew.value !== departamento.value) {
      let ciudades = await obtenerPermisosValor(departamentoNew.value);
      ciudades = ciudades.map(({ secundario }) => ({
        value: secundario.id,
        label: secundario.nombre,
      }));

      this.changeState('ciudades', ciudades);
      this.changeState('ciudad', '');
    }

    if (poseeEps !== this.state.poseeEps) {
      if (this.state.poseeEps.value === 'no') {
        let nombreEps = '';
        let nombreEpsOtro = '';
        this.changeState('nombreEps', nombreEps);
        this.changeState('nombreEpsOtro', nombreEpsOtro);
      }
    }

    if (nombreEps !== this.state.nombreEps) {
      if (this.state.nombreEps.value !== EPS_OTRO) {
        let nombreEpsOtro = '';
        this.changeState('nombreEpsOtro', nombreEpsOtro);
      }
    }

  }

  changeState = (name, value) => {
    this.setState({
      [name]: value
    })
  }

  guardar = () => {
    this.guardarInscripcion()
  }


  guardarInscripcion = async () => {
    let { actualizarMensaje } = this.props;
    // actualizarMensaje({ titulo: 'Enviado datos, por favor espere...', tipo: 'info', mostrar: true, tiempo: 1000 });
    this.setState({ cargando: true })
    let {
      identificacion,
      primer_nombre,
      primer_apellido,
      segundo_nombre,
      segundo_apellido,
      correo,
      carrera,
      carrera2,
      edad,
      opcion_conociste,
      celular1,
      celular2,
      telefono1,
      telefono2,
      departamento,
      ciudad,
      estrato,
      barrio,
      direccion,
      linea_edu,
      jornada,
      genero,
      grupo,
      discapacidad,
      adj_certificado_final,
      nombre_colegio,
      estudiante_11,
      costear_estudio,
      nivel_educacion,
      nombre_madre,
      nombre_padre,
      celular_madre,
      celular_padre,
      contactoEmergencia,
      celularEmergencia,
      poseeEps,
      nombreEps,
      nombreEpsOtro,
      otro_costear,
      otro_jornada,
    } = this.state;
    let usuario = correo.split("@")[0]
    let data = await crear_form_data({
      identificacion,
      primer_nombre,
      primer_apellido,
      segundo_nombre,
      segundo_apellido,
      correo,
      carrera_pre_universitario: carrera,
      carrera_pre_universitario_2: carrera2,
      edad,
      celular1,
      celular2,
      telefono1,
      telefono2,
      departamento: 0,
      ciudad: 0,
      estrato: estrato.value,
      barrio,
      direccion,
      usuario,
      linea_edu: linea_edu.value,
      jornada: jornada.value,
      genero: genero.value,
      grupo: grupo.value,
      discapacidad: discapacidad ? discapacidad.value : '',
      adj_certificado: adj_certificado_final,
      costear_estudio: costear_estudio.value,
      nivel_educacion: nivel_educacion.value,
      estudiante_11: estudiante_11.value,
      nombre_colegio,
      nombre_madre,
      nombre_padre,
      celular_madre,
      celular_padre,
      contacto_emergencia: contactoEmergencia,
      celular_emergencia: celularEmergencia,
      tiene_eps: poseeEps.value,
      nombre_eps: poseeEps.value === 'si' ? nombreEps.value : '',
      nombre_otro_eps: nombreEpsOtro,
      otro_costear,
      otro_jornada,
    });
    this.changeState('enviando', true);
    formulario("api/v1.0/inscripciones/preuniversitario/crear", data, "post",
      (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            this.changeState('guardado', true);
            this.changeState('enviando', false);
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        this.setState({ cargando: false })
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }, false
    );

  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value.replace(/[À-ÿ]/g, '')
    })
  }

  checked = ({ target }) => {
    this.setState({
      [target.name]: target.checked
    })
  }

  cargarTipos = async () => {
    const transformar = data => {
      return data.map(e => ({
        value: e.id,
        label: e.nombre,
        codigo: e.codigo,
      }));
    }

    const mensaje = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Men_PreUni' }]);
    const departamentos = await obtenerValores(7);
    const estratos = await obtenerValores(9);
    const lineas_edu_co = await obtenerValores(LINEAEDU);
    const jornada_preinscripcion = await obtenerValores(JORNADAPREINSCRIPCION);
    const generos = await obtenerValores(GENEROS);
    const grupos = await obtenerValores(GRUPOS_INSCRIPCIONES);
    const discapacidades = await obtenerValores(DISCAPACIDADES_INSCRIPCIONES);
    const validacionDocs = await obtenerValores(VALIDACION_DOCUMENTOS_INSCRIPCIONES);
    const costear_estudios = await obtenerValores(COSTEAR_ESTUDIOS);
    const autorizaciones = await obtenerValores(AUTORIZACION_PREINSCRIPCION);
    const politica = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Pol_Dat_Cuc' }]);
    const eps = await obtenerValores(EPS);
    this.setState({
      generos: transformar(generos),
      grupos: transformar(grupos),
      discapacidades: transformar(discapacidades),
      validacion_doc : validacionDocs.sort((a, b) => a.valorb - b.valorb).map(e => ({id: e.id, nombre: e.nombre, valora: e.valora, estado: e.estado})),
      costear_estudios: transformar(costear_estudios),
      lineas_edu_co: transformar(lineas_edu_co),
      jornada_preinscripcion: transformar(jornada_preinscripcion),
      departamentos: transformar(departamentos),
      estratos: transformar(estratos),
      mensaje_contacto: mensaje[0].nombre,
      autorizaciones,
      link_politica:  politica && politica[0] ? politica[0].archivo : '',
      cargando: false,
      opcionesEps: transformar(eps),
    })
  }

  aceptoAutorizacion = () => {
    let { mostrarModalAutorizacionPadre } = this.props;
    mostrarModalAutorizacionPadre(false)
  }

  aceptoAutorizacionCumplimiento = () => {
    let { mostrarModalAutorizacionCumplimiento } = this.props;
    mostrarModalAutorizacionCumplimiento(false)
  }

  aceptoAutorizacionCalidad = () => {
    let { mostrarModalAutorizacionCalidad } = this.props;
    mostrarModalAutorizacionCalidad(false)
  }

  validarDatos = (step) => {
    let {
      identificacion,
      primer_nombre,
      primer_apellido,
      correo,
      telefono1,
      carrera,
      carrera2,
      edad,
      celular1,
      departamento,
      ciudad,
      estrato,
      barrio,
      jornada,
      linea_edu,
      nombre_colegio,
      direccion,
      genero,
      grupo,
      discapacidad,
      adj_certificado,
      validacion_doc,
      estudiante_11,
      costear_estudio,
      nivel_educacion,
      nombre_madre,
      nombre_padre,
      celular_madre,
      celular_padre,
      contactoEmergencia,
      celularEmergencia,
      poseeEps,
      nombreEps,
      nombreEpsOtro,
    } = this.state;
    let mensaje = '';

    if (step === 1) {
      if (carrera === '') mensaje = 'Ingrese Carrera.';
      if (carrera2 === '') mensaje = 'Ingrese Carrera2.';
      if (edad === '') mensaje = 'Ingrese su edad.';
      else if (costear_estudio === '') mensaje = 'Seleccione como costeara sus estudios.';
      else if (nivel_educacion === '') mensaje = 'Seleccione Nivel de Educacion.';

    } else if (step === 0) {
      if (identificacion === '') mensaje = 'Ingrese #identificación.';
      else if (primer_nombre === '') mensaje = 'Ingrese primer nombre.';
      else if (primer_apellido === '') mensaje = 'Ingrese primer apellido.';
      else if (telefono1 === '') mensaje = 'Ingrese telefono.';
      else if (celular1 === '') mensaje = 'Ingrese celular.';
      else if (linea_edu === '') mensaje = 'Seleccione una Linea EDU.co.';
      else if (jornada === '') mensaje = 'Seleccione una Jornada.';
      else if (genero === '') mensaje = 'Seleccione Genero.';
      else if (nombre_colegio === '') mensaje = 'Ingrese Colegio.';
      else if (estudiante_11 === '') mensaje = 'Seleccione si cursa o no actualmente el grado 11.';
      else if (correo === '') mensaje = 'Ingrese correo personal.';
      else if (!grupo) mensaje = 'Seleccione a que grupo pertenece.';
      else if (grupo.codigo === 'GRU_DIS' && !discapacidad) mensaje = 'Seleccione el tipo de discapacidad.';
      // else if ((grupo !== '' && grupo.codigo !== 'GRU_NIN') && (!adj_certificado && validacion_doc[0].valora === '1')) mensaje = 'Debe adjuntar el certificado de comunidad especial.';
      // Verificamos si el grupo no es GRU_NIN y si el género y el grupo son LGTIQ+
      else if ((grupo !== '' && grupo.codigo !== 'GRU_NIN') && genero === 'LGTIQ+' && grupo.codigo === 'LGTIQ+') {mensaje = 'Debe adjuntar el certificado de comunidad especial.';}


      else {
        let adj_ce = document.getElementById('adj_certificado').files[0];
        if (adj_ce) this.changeState('adj_certificado_final', adj_ce);
    }

    } else if (step === 2) {
      // if (!departamento) mensaje = 'Seleccione departamento.';
      // else if (!ciudad) mensaje = 'Seleccione ciudad o municipio.';
      if (!barrio) mensaje = 'Ingrese barrio.';
      else if (!direccion) mensaje = 'Ingrese Dirección.';
      else if (!estrato) mensaje = 'Seleccione estrato.';
      else if (!poseeEps) mensaje = 'Seleccione si posee EPS.';
      else if (poseeEps.value === 'si' && !nombreEps) mensaje = 'Seleccione nombre de EPS.';
      else if (nombreEps.value === EPS_OTRO && !nombreEpsOtro) mensaje = 'Ingrese nombre de EPS.';
      else if (nombre_madre === '') mensaje = 'Ingrese Nombre de la Madre.';
      else if (celular_madre === '') mensaje = 'Ingrese Celular de la Madre.';
      else if (nombre_padre === '') mensaje = 'Ingrese Nombre del Padre.';
      else if (celular_padre === '') mensaje = 'Ingrese Celular del Padre.';
      else if (!contactoEmergencia) mensaje = 'Ingrese Nombre de Contacto de Emergencia.';
      else if (!celularEmergencia) mensaje = 'Ingrese Celular de Contacto de Emergencia.';
  }
    return mensaje;
  }
  
  render() {
    let {
      carrera,
      carrera2,
      edad,
      identificacion,
      primer_nombre,
      segundo_nombre,
      primer_apellido,
      segundo_apellido,
      correo,
      telefono1,
      telefono2,
      celular1,
      celular2,
      contactoEmergencia,
      celularEmergencia,
      opcionesEps,
      nombreEps,
      nombreEpsOtro,
      poseeEps,
      opcionesPoseeEps,
      departamentos,
      departamento,
      ciudades,
      ciudad,
      estratos,
      estrato,
      barrio,
      lineas_edu_co,
      linea_edu,
      jornada_preinscripcion,
      jornada,
      nombre_colegio,
      direccion,
      guardado,
      iniciar,
      mensaje_contacto,
      mostrar_btn,
      validacion_doc,
      opciones_estudiante,
      estudiante_11,
      genero,
      generos,
      grupos,
      grupo,
      discapacidades,
      discapacidad,
      adj_certificado,
      adj_certificado_final,
      nivel_educacion,
      niveles_educacion,
      costear_estudio,
      costear_estudios,
      nombre_madre,
      nombre_padre,
      celular_madre,
      celular_padre,
      autorizaciones,
      otro_costear,
      otro_jornada,
      cargando,
      link_politica
    } = this.state;

    let { actualizarMensaje, mostrarModalAutorizacionPadre, modalAutorizacionPadre,
      modalMensajeValidacionId, mostrarModalMensajeValidacionId,
      mostrarModalAutorizacionCumplimiento, modalAutorizacionCumplimiento,
      mostrarModalAutorizacionCalidad, modalAutorizacionCalidad } = this.props;

    const mensajeAlerta = `En caso de que presentes algún inconveniente en el momento de realizar tu proceso PreUniversitario, ${mensaje_contacto}.`

    if (cargando) {
      return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_s} widthImg="7%" cargando={true}/>;
    }
    if (iniciar) {
      return (
        <div>
          <AutorizacionPadre
            mostrarModalAutorizacionPadre={mostrarModalAutorizacionPadre}
            modalAutorizacionPadre={modalAutorizacionPadre}
            autorizaciones={autorizaciones}
            aceptoAutorizacion={this.aceptoAutorizacion}
          />
          <AutorizacionCalidad
            mostrarModalAutorizacionCalidad={mostrarModalAutorizacionCalidad}
            modalAutorizacionCalidad={modalAutorizacionCalidad}
            autorizaciones={autorizaciones}
            aceptoAutorizacionCalidad={this.aceptoAutorizacionCalidad}
          />
          <CumplimientoLey
            mostrarModalAutorizacionCumplimiento={mostrarModalAutorizacionCumplimiento}
            modalAutorizacionCumplimiento={modalAutorizacionCumplimiento}
            autorizaciones={autorizaciones}
            aceptoAutorizacionCumplimiento={this.aceptoAutorizacionCumplimiento}
            link_politica={link_politica}
          />
          <InscripcionForm
            props_c={{
              obtenerValores: () => obtenerValores,
              primer_nombre,
              segundo_nombre,
              primer_apellido,
              segundo_apellido,
              correo,
              carrera,
              carrera2,
              contactoEmergencia,
              celularEmergencia,
              nombreEps,
              nombreEpsOtro,
              opcionesEps,
              poseeEps,
              opcionesPoseeEps,
              edad,
              otro_costear,
              otro_jornada,
              identificacion,
              telefono1,
              telefono2,
              celular1,
              celular2,
              departamentos,
              departamento,
              ciudades,
              estratos,
              estrato,
              ciudad,
              barrio,
              lineas_edu_co,
              linea_edu,
              jornada_preinscripcion,
              jornada,
              genero,
              generos,
              nombre_colegio,
              opciones_estudiante,
              estudiante_11,
              nivel_educacion,
              niveles_educacion,
              costear_estudio,
              costear_estudios,
              nombre_madre,
              nombre_padre,
              celular_madre,
              celular_padre,
              direccion,
              grupos,
              grupo,
              discapacidades,
              discapacidad,
              otro_costear,
              otro_jornada,
              onChange: this.onChange,
              validarDatos: this.validarDatos,
              guardar: this.guardar,
              mensajeAlerta,
              adj_certificado,
              adj_certificado_final,
              actualizarMensaje,
              autorizacion_padre: mostrarModalAutorizacionPadre,
              cumplimiento_ley: mostrarModalAutorizacionCumplimiento,
              calidad: mostrarModalAutorizacionCalidad,
              guardado,
              mensaje: mensaje_contacto,
              mostrar_btn,
              validacion_doc,
              modalMensajeValidacionId,
              mostrarModalMensajeValidacionId,
              setNombreEmergencia: (value) => {
                this.changeState('contactoEmergencia', value)
              },
              setCelularEmergencia: (value) => {
                this.changeState('celularEmergencia', value)
              },
              setPoseeEps: (value) => {
                this.changeState('poseeEps', value)
              },
              setNombreEps: (value) => {
                this.changeState('nombreEps', value)
              },
              setNombreEpsOtro: (value) => {
                this.changeState('nombreEpsOtro', value)
              },
              setTipo_linea: (value) => {
                this.changeState('linea_edu', value)
              },
              setTipo_jornada: (value) => {
                this.changeState('jornada', value)
              },
              setDepartamento: (value) => {
                this.changeState('departamento', value)
              },
              setCiudad: (value) => {
                this.changeState('ciudad', value)
              },
              setEstrato: (value) => {
                this.changeState('estrato', value)
              },
              setGenero: (value) => {
                this.changeState('genero', value)
              },
              setGrupo: (value) => {
                this.changeState('grupo', value)
              },
              setDiscapacidad: (value) => {
                this.changeState('discapacidad', value)
              },
              setAdj_certificado: (value) => {
                this.changeState('adj_certificado', value)
              },
              setNombre_Colegio: (value) => {
                this.changeState('nombre_colegio', value)
              },
              setMostrarBtn: (value) => {
                this.changeState('mostrar_btn', value)
              },
               setOpcion_estudiante: (value) => {
                this.changeState('estudiante_11', value)
              },
              setOpcion_nivelEducacion: (value) => {
                this.changeState('nivel_educacion', value)
              },
              setOpcion_costearEstudio: (value) => {
                this.changeState('costear_estudio', value)
              },
              setprimer_nombre: (value) => {
                this.changeState('primer_nombre', value)
              },
              setsegundo_nombre: (value) => {
                this.changeState('segundo_nombre', value)
              },
              setprimer_apellido: (value) => {
                this.changeState('primer_apellido', value)
              },
              setsegundo_apellido: (value) => {
                this.changeState('segundo_apellido', value)
              },
              setcorreo: (value) => {
                this.changeState('correo', value)
              },
              setcarrera: (value) => {
                this.changeState('carrera', value)
              },
              setcarrera2: (value) => {
                this.changeState('carrera2', value)
              },
              setedad: (value) => {
                this.changeState('edad', value)
              },
              setotro_costear: (value) => {
                this.changeState('otro_costear', value)
              },
              setotro_jornada: (value) => {
                this.changeState('otro_jornada', value)
              },
              setidentificacion: (value) => {
                this.changeState('identificacion', value)
              },
              settelefono1: (value) => {
                this.changeState('telefono1', value)
              },
              settelefono2: (value) => {
                this.changeState('telefono2', value)
              },
              setcelular1: (value) => {
                this.changeState('celular1', value)
              },
              setcelular2: (value) => {
                this.changeState('celular2', value)
              },
              setdepartamentos: (value) => {
                this.changeState('departamentos', value)
              },
              setdepartamento: (value) => {
                this.changeState('departamento', value)
              },
              setciudades: (value) => {
                this.changeState('ciudades', value)
              },
              setestratos: (value) => {
                this.changeState('estratos', value)
              },
              setestrato: (value) => {
                this.changeState('estrato', value)
              },
              setciudad: (value) => {
                this.changeState('ciudad', value)
              },
              setbarrio: (value) => {
                this.changeState('barrio', value)
              },
              setlineas_edu_co: (value) => {
                this.changeState('lineas_edu_co', value)
              },
              setlinea_edu: (value) => {
                this.changeState('linea_edu', value)
              },
              setjornada_preinscripcion: (value) => {
                this.changeState('jornada_preinscripcion', value)
              },
              setjornada: (value) => {
                this.changeState('jornada', value)
              },
              setgenero: (value) => {
                this.changeState('genero', value)
              },
              setgeneros: (value) => {
                this.changeState('generos', value)
              },
              setnombre_colegio: (value) => {
                this.changeState('nombre_colegio', value)
              },
              setopciones_estudiante: (value) => {
                this.changeState('opciones_estudiante', value)
              },
              setniveles_educacion: (value) => {
                this.changeState('niveles_educacion', value)
              },
              setcostear_estudios: (value) => {
                this.changeState('costear_estudios', value)
              },
              setnombre_madre: (value) => {
                this.changeState('nombre_madre', value)
              },
              setnombre_padre: (value) => {
                this.changeState('nombre_padre', value)
              },
              setcelular_madre: (value) => {
                this.changeState('celular_madre', value)
              },
              setcelular_padre: (value) => {
                this.changeState('celular_padre', value)
              },
              setdireccion: (value) => {
                this.changeState('direccion', value)
              },
              setotro_costear: (value) => {
                this.changeState('otro_costear', value)
              },
              setotro_jornada: (value) => {
                this.changeState('otro_jornada', value)
              },
              setactualizarMensaje: (value) => {
                this.changeState('actualizarMensaje', value)
              },
              setautorizacion_padre: (value) => {
                this.changeState('autorizacion_padre', value)
              },
              setcumplimiento_ley: (value) => {
                this.changeState('cumplimiento_ley', value)
              },
              setcalidad: (value) => {
                this.changeState('calidad', value)
              },
              setmensaje: (value) => {
                this.changeState('mensaje', value)
              },
              setmostrar_btn: (value) => {
                this.changeState('mostrar_btn', value)
              },
              setvalidacion_doc: (value) => {
                this.changeState('validacion_doc', value)
              },
              setmodalMensajeValidacionId: (value) => {
                this.changeState('modalMensajeValidacionId', value)
              },
              setmostrarModalMensajeValidacionId: (value) => {
                this.changeState('mostrarModalMensajeValidacionId', value)
              },


            }}
          />
        </div>
      )
    } else {
      return <InscripcionInicio mensaje={mensajeAlerta} accion={() => this.changeState('iniciar', true)} />
    }
  }
}

//CARGO ESTADOS DEL MODULO
const mapStateToProps = (state) => {
  let { modalMensajeValidacionId, modalAutorizacionPadre, modalAutorizacionCumplimiento,
    modalAutorizacionCalidad } = state.redInscripciones;
  return {
    modalMensajeValidacionId,
    modalAutorizacionCumplimiento,
    modalAutorizacionPadre,
    modalAutorizacionCalidad
  }
};

//CARGO FUNCIONES DEL MODULO
const mapDispatchToProps = {
  actualizarMensaje,
  mostrarModulo,
  mostrarModalMensajeValidacionId,
  mostrarModalAutorizacionPadre,
  mostrarModalAutorizacionCumplimiento,
  mostrarModalAutorizacionCalidad,
}

PreInscripcionAgregar.propTypes = {
  //variables
  modalMensajeValidacionId: PropTypes.bool.isRequired,
  actualizarMensaje: PropTypes.func.isRequired,
  mostrarModulo: PropTypes.func.isRequired,
  mostrarModalMensajeValidacionId: PropTypes.func.isRequired,
  mostrarModalAutorizacionPadre: PropTypes.func.isRequired,
  mostrarModalAutorizacionCumplimiento: PropTypes.func.isRequired,
  mostrarModalAutorizacionCalidad: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(PreInscripcionAgregar);
