// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Material-UI Components
import { Grid, TextField, Dialog, DialogContent, DialogActions, Button, } from '@material-ui/core/';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
// Components
import AppBarModal from '../general/AppBarModal';
import { BtnForm } from './../general/BotonesAccion';
import ConfirmarAccion from './../general/ConfirmarAccion';
import ThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
// Funciones
import { validarDOI, validarISSN } from "./funciones"

class GradosModificar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cagando: true,
      modalConfir: false,
      // Campos articulos
      url_articulo: "",
      doi_articulo: "",
      issn_revista: "",
      volumen_revista: "",
      numero_revista: "",
    }
  }

  componentDidMount() {
    ValidatorForm.addValidationRule('isDOI', (value) => {
      return validarDOI(value)
    });
    ValidatorForm.addValidationRule('isISSN', (value) => {
      return validarISSN(value)
    });
  }

  componentDidUpdate({ solicitud }, { }) {
    let { solicitud: solicitudNew } = this.props
    if (solicitud != solicitudNew) {
      this.setState({
        url_articulo: this.props.solicitud.url_articulo,
        doi_articulo: this.props.solicitud.doi_articulo,
        issn_revista: this.props.solicitud.issn_revista,
        volumen_revista: this.props.solicitud.volumen_revista,
        numero_revista: this.props.solicitud.numero_revista,
      })
    }

  }

  //Handles

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    });
  }

  submit = () => {
    let { solicitud } = this.props
    let estado_articulo = 3
    let { url_articulo, doi_articulo, issn_revista, volumen_revista, numero_revista } = this.state
    if (url_articulo && doi_articulo && issn_revista && numero_revista && volumen_revista) {
      this.props.modificarSolicitud(solicitud.id, url_articulo, doi_articulo, issn_revista, volumen_revista, numero_revista, estado_articulo)
      this.setState({ modalConfir: false })
    }
  }

  pintar = () => {
    let { solicitud } = this.props
    let { url_articulo, doi_articulo, issn_revista, volumen_revista, numero_revista } = this.state
    if (Object.entries(solicitud).length > 0) {
      if (solicitud.tipo.codigo == "gr_articulo") {
        return (
          <ValidatorForm onSubmit={(e) => {
            e.preventDefault()
            this.setState({ modalConfir: true })
          }}>
            <Grid container spacing={3} className='scroll'>
              <Grid item xs={12} md={12} lg={12}>
                <TextValidator
                  autoFocus
                  margin="dense"
                  label='URL Articulo'
                  type='text'
                  fullWidth
                  name="url_articulo"
                  value={url_articulo}
                  validators={["required"]}
                  errorMessages={["El campo es requerido"]}
                  onChange={this.onChange}
                />
                {/* <TextField required id='url_articulo' name='url_articulo' label='URL Articulo' value={url_articulo} onChange={this.onChange} fullWidth autoComplete="billing address-line1" /> */}
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextValidator
                  margin="dense"
                  label='DOI Artículo'
                  type='text'
                  fullWidth
                  name="doi_articulo"
                  value={doi_articulo}
                  placeholder="Ejemplo: 10.1109/CST.2019.1.22.23"
                  validators={["required", "isDOI"]}
                  errorMessages={["El campo es requerido", "doi no valido"]}
                  onChange={this.onChange}
                />
                {/* <TextField required id='doi_articulo' name='doi_articulo' label='DOI Articulo' value={doi_articulo} onChange={this.onChange} fullWidth autoComplete="billing address-line1" /> */}
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextValidator
                  margin="dense"
                  label='ISSN Revista'
                  type='text'
                  fullWidth
                  name="issn_revista"
                  value={issn_revista}
                  placeholder="Ejemplo : 8530-9434"
                  validators={["required", 'isISSN']}
                  errorMessages={["El campo es requerido", "ISSN no valido"]}
                  onChange={this.onChange}
                />
                {/* <TextField required id='issn_revista' name='issn_revista' label='ISSN Revista' value={issn_revista} onChange={this.onChange} fullWidth autoComplete="billing address-line1" /> */}
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextValidator
                  margin="dense"
                  label='Volumen Revista'
                  type='number'
                  fullWidth
                  name="volumen_revista"
                  value={volumen_revista}
                  validators={["required"]}
                  errorMessages={["El campo es requerido"]}
                  onChange={this.onChange}
                />
                {/* <TextField required id='volumen_revista' name='volumen_revista' label='Volumen Revista' value={volumen_revista} onChange={this.onChange} fullWidth type="number" autoComplete="billing address-line1" /> */}
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextValidator
                  margin="dense"
                  label='Número Revista'
                  type='number'
                  fullWidth
                  name="numero_revista"
                  value={numero_revista}
                  validators={["required"]}
                  errorMessages={["El campo es requerido"]}
                  onChange={this.onChange}
                />
                {/* <TextField required id='numero_revista' name='numero_revista' label='Número Revista' value={numero_revista} onChange={this.onChange} fullWidth type="number" autoComplete="billing address-line1" /> */}
              </Grid>
            </Grid>
            <Button color="primary" type="submit" id='enviar_form_edit_grado' className="oculto"> Enviar </Button>
          </ValidatorForm>
        )
      }
    }
  };


  render() {
    let { modalModSolicitud, showModalModSolicitud } = this.props
    let { modalConfir } = this.state
    return (
      <div>
        <Dialog onClose={() => showModalModSolicitud(false)} open={modalModSolicitud} fullWidth={true} fullScreen={window.innerWidth < 600} maxWidth='sm' aria-labelledby="max-width-dialog-title">
          <AppBarModal titulo={'Datos Pendientes'} mostrarModal={showModalModSolicitud} titulo_accion="Cerrar" />
          <DialogContent className='scroll'>
            {this.pintar()}
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CERRAR" callback={() => showModalModSolicitud(false)} />
            <BtnForm texto="GUARDAR" callback={() => { document.getElementById('enviar_form_edit_grado').click() }} />
          </DialogActions>
        </Dialog>
        <ConfirmarAccion mensaje1={`¿ Está seguro que la información es correcta ?`} ejecutarAccion={() => this.submit()} mostrarModalConfAccion={() => this.setState({ modalConfir: false })} dato={{ id: 0 }} modalConAccion={modalConfir} titulo={'¿ Confirmar ?'} />
      </div>
    );
  }
}


export default GradosModificar;
