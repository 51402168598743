import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Select, InputLabel, MenuItem, List, ListItem, ListItemIcon, ListItemText, ListSubheader,
  Checkbox, DialogActions, Grid, Dialog, DialogContent
} from '@material-ui/core';
import { FormControl, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { obtenerPermisosValor, obtenerValoresFiltros,obtenerValores } from "../../../global/js/funciones";
import AppBarModal from '../../general/AppBarModal'
import { BtnForm } from "../../general/BotonesAccion";
import CustomDropzone from "../../general/CustomDropzone";
import { MenuGestionar, MenuGestionarEstudiante } from "./ConsultoriaMenu";
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from './../../../global/imagenes/emma_w.png';
import BuscarPersona from '../../general/BuscarPersona';
import { AREA_DERECHO, TIPO_CENTRO_CONCILIACION } from '../helper';
import { api, consulta, formulario, mostrarError } from "../../../global/js/funciones";
import AlertasSimple from '../../general/AlertasSimple';
import { coloresEmma } from '../../../global/js/funciones';

function RenderEstados({ estados_siguiente, estado, cambiarSeleccion }) {
  return (
    <List subheader={
      <ListSubheader component="div" >
        Seleccione Estado
      </ListSubheader>
    }>
      {estados_siguiente.map(({ secundario }) => {
        let { codigo, nombre } = secundario;
        return (
          <ListItem key={codigo} role={undefined} dense button onClick={() => cambiarSeleccion(codigo)} >
            <ListItemIcon>
              <Checkbox edge="start" checked={estado === codigo} tabIndex={-1} disableRipple />
            </ListItemIcon>
            <ListItemText id={codigo} primary={nombre} />
          </ListItem>
        );
      })}
    </List>
  );
}

class ConsultoriaGestionar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cargando: true,
      titulo_res: 'Cargando..',
      estado: '',
      motivo: '',
      observacion: '',
      estados_siguiente: [],
      archivos: [],
      seleccion: 'gestion',
      formatos_archivos: '',
      buscarGestor: false,
      area: '',
      gestor: {
        id: 0,
        nombre_completo: 'Clic aquí para buscar',
      },
      area_derecho: []
    }
    this.fileInput = React.createRef();
  }

  async componentDidUpdate({ modalGestionarConsultoria }) {
    let { seleccion } = this.state;
    let { modalGestionarConsultoria: modalGestionarConsultoriaNew, proceso, usuario } = this.props;
    if (modalGestionarConsultoria !== modalGestionarConsultoriaNew) {
      let area_derecho = await obtenerValoresFiltros([{ "llave": 'generica', 'valor': AREA_DERECHO }])
      let formatos_archivos = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'For_Arc_Con' }]);
      let estados_siguiente = proceso.estado_actual ? await obtenerPermisosValor(proceso.estado_actual.id) : []
      this.setState({ estado: '', motivo: '', estados_siguiente, cargando: false, formatos_archivos: formatos_archivos ? formatos_archivos[0].valora : '', seleccion: usuario.perfil.codigo === "Per_Consul_Est" ? 'adjuntos' : 'gestion', area_derecho });
    }
  }


  reiniciarEstado() {
    this.setState({
      cargando: true,
      area: '',
      titulo_res: 'Cargando..',
      estado: '',
      motivo: '',
      archivos: [],
      seleccion: 'gestion',
    })
  }

  cambiarEstado = (estados) => {
    this.setState(estados)
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  agregarArchivos = (arc) => {
    let { archivos } = this.state;
    archivos = archivos.concat(arc);
    this.setState({ archivos })
  }

  eliminarArchivo = (i) => {
    let { actualizarMensaje } = this.props;
    let { archivos } = this.state;
    archivos.splice(i, 1)
    this.setState({ archivos })
    actualizarMensaje({ titulo: `Archivo Retirado.`, tipo: 'success', mostrar: true, tiempo: 6000 })
  }

  async obtenerSolicitud() {
    let { proceso } = this.props
    let f = proceso.id
    this.setState({ cargando: true });
    return new Promise(resolve => {
      consulta(`api/v1.0/consultoria/detalle/${f}?`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  /* async obtenerAdjuntos() {
    let {proceso} = this.props
    let f = proceso.id
    this.setState({ cargando: true });
    return new Promise(resolve => {
        consulta(`api/v1.0/consultoria/adjuntos/${f}?`, null, null, (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
     })
   })
  } */

  guardarConsultaCentro = async (data) => {
    let { actualizarMensaje } = this.props;
    actualizarMensaje({ titulo: 'Enviado datos, por favor espere...', tipo: 'info', mostrar: true, tiempo: 4000 });
    formulario(`api/v1.0/consultoria/crear/centro`, data, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });

    })
  }

  enviarDatos = async (e) => {
    let { actualizarMensaje } = this.props;
    let { estado, motivo, gestor, observacion, area, archivos } = this.state;
    let error = false
    if (estado === 'Est_Consul_Asig') {
      if (gestor.id === 0) {
        error = true
        actualizarMensaje({ titulo: `Debe asiganar un Gestor.`, tipo: 'info', mostrar: true, tiempo: 6000 })
      }
      if (area === '') {
        error = true
        actualizarMensaje({ titulo: `Debe asiganar un Area del Derecho.`, tipo: 'info', mostrar: true, tiempo: 6000 })
      }
    } else if (estado === 'Est_Consul_Rech') {
      if (motivo === '') {
        error = true
        actualizarMensaje({ titulo: `Debe especificar un motivo de rechazo.`, tipo: 'info', mostrar: true, tiempo: 6000 })
      }
    } else if (estado === 'Est_Consul_Hech') {
      if (observacion === '') {
        error = true
        actualizarMensaje({ titulo: `Debe especificar una observacion para el estado hechos.`, tipo: 'info', mostrar: true, tiempo: 6000 })
      }
    } /* else if (estado === 'Est_Consul_Fin') {
      if (archivos.length == 0) {
        error = true
        actualizarMensaje({ titulo: `Estimado gestor recuerda que es de carácter obligatorio, cargar el entregable de la solución jurídica.`, tipo: 'info', mostrar: true, tiempo: 6000 })
      }
    }  */else if (estado === 'Est_Consul_CentroC') {
      if (observacion === '') {
        error = true
        actualizarMensaje({ titulo: `Debe especificar una observacion para el estado centro de conciliación.`, tipo: 'info', mostrar: true, tiempo: 6000 })
      } else {
        let tiposCentro = await obtenerValores(TIPO_CENTRO_CONCILIACION)
        let datos = await this.obtenerSolicitud();
        /* let adjunto = await this.obtenerAdjuntos(); */
        const data = new FormData();
        archivos.map((file, index) => data.append(`archivo${index}`, file));
        data.append('total', archivos.length);
        data.append('hechos', datos[0].hechos);
        data.append('id_consultas_juridicas', datos[0].id);
        data.append('Pretensiones', datos[0].Pretensiones);
        data.append('discapacidad', datos[0].solicitante.discapacidad);
        data.append('tipo_identificacion', datos[0].solicitante.tipo_identificacion.id);
        data.append('identificacion', datos[0].solicitante.identificacion);
        data.append('telefono', datos[0].solicitante.telefono);
        data.append('correo', datos[0].solicitante.correo == "" ? 'consultario' + datos[0].solicitante.identificacion + '@cuc.edu.co' : datos[0].solicitante.correo);
        data.append('area', datos[0].area.id);
        data.append('primer_nombre', datos[0].solicitante.primer_nombre.toUpperCase());
        data.append('segundo_nombre', datos[0].solicitante.segundo_nombre.toUpperCase());
        data.append('primer_apellido', datos[0].solicitante.primer_apellido.toUpperCase());
        data.append('segundo_apellido', datos[0].solicitante.segundo_apellido.toUpperCase());
        data.append('departamento', datos[0].solicitante.departamento.id);
        data.append('ciudad', datos[0].solicitante.ciudad.id);
        data.append('comunidad', datos[0].solicitante.comunidad.id);
        data.append('genero', datos[0].solicitante.genero.id);
        data.append('lugar_residencia', datos[0].solicitante.lugar_residencia);
        data.append('direccion', datos[0].solicitante.direccion);
        data.append('id_asociado', datos[0].id_asociado);
        data.append('tipo_consultoria', tiposCentro[0].id);
        if (datos[0].programa !== null) {
          data.append('programa', datos[0].programa);
        }
        if (datos[0].anho !== null) {
          data.append('anho', datos[0].anho);
        }
        this.guardarConsultaCentro(data);
      }
    }
    if (!error) this.onSubmit()
  }

  onSubmit = () => {
    let { proceso } = this.props;
    let { estado, motivo, archivos, gestor, observacion, area } = this.state;
    this.setState({ cargando: true, titulo_res: 'Enviando..' })
    let data = { area, motivo, observacion, estado, gestor: gestor.id, archivos, total: archivos.length }
    this.props.gestionarConsultoria(proceso.id, data, () => this.setState({ cargando: false, titulo_res: 'Cargando..' }));
    this.reiniciarEstado()
  }

  pintarAdjuntos() {
    let { formatos_archivos } = this.state
    return <CustomDropzone archivos={this.state.archivos} addFunction={this.agregarArchivos} deleteFunction={this.eliminarArchivo} actualizarMensaje={this.props.actualizarMensaje} acceptedFiles={formatos_archivos} />
  }

  pintarEstados() {
    let { estado, estados_siguiente, gestor, area } = this.state;
    let { area_derecho } = this.state;
    return (
      <Grid container>
        <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
          <RenderEstados cambiarSeleccion={(estado) => this.setState({ estado })} estado={estado} estados_siguiente={estados_siguiente} />
        </Grid>
        {estado === 'Est_Consul_Rech' && (
          <Grid item xs={12} md={12}>
            <FormControl className="form-control">
              <TextField
                id="txtMotivoRechazo"
                label="Motivo Cancelación"
                name="motivo"
                placeholder="Escriba el motivo de cancelación de la solicitud"
                multiline
                onChange={this.onChange}
              />
            </FormControl>
          </Grid>
        )}
        {estado === 'Est_Consul_Asig' && (<>
          <Grid container spacing={1} alignItems="flex-end" onClick={() => this.setState({ buscarGestor: true })} className='pointer' style={{ marginBottom: '10px' }}>
            <Grid item xs={11} xl={11} sm={11} md={11} lg={11}>
              <TextField label="Gestor" disabled={true} type="text" value={gestor.nombre_completo} style={{ width: '100%' }} />
            </Grid>
            <Grid item xs={1} xl={1} sm={1} md={1} lg={1}>
              <SearchIcon style={{ color: coloresEmma.primarycolor }} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormControl className='form-control' required>
              <InputLabel>Area del Derecho</InputLabel>
              <Select id='area' value={area} onChange={this.onChange} name='area'>
                {
                  area_derecho.map((datos) => (
                    <MenuItem key={datos.id} value={datos.id}>{datos.nombre}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
        </>
        )}
        { estado === 'Est_Consul_Fin' && (<>
          <DialogContent>
            <AlertasSimple
                tipo='info'
                titulo={'Estimado Gestor, Recuerda que para pasar al siguiente estado, tienes la opción de dirigirte al apartado de adjuntos, y agregar el entregable de la solución jurídica, en caso de que sea necesario.'}
                /* lista={'hola'} */
                margin='0px'
            />
          </DialogContent>
        </>)
        }
        <Grid item xs={12}>
          <TextField
            type="text"
            id="observacion"
            name="observacion"
            label="Escriba las Observaciones"
            multiline
            fullWidth
            rows={4}
            onChange={this.onChange}
          />
        </Grid>
      </Grid>
    )
  }

  pintar() {
    let { usuario } = this.props;
    let { seleccion } = this.state;
    return (
      <>
        {usuario.perfil.codigo === "Per_Consul_Est" ?
          <div>
            {seleccion === 'adjuntos' && this.pintarAdjuntos()}
          </div> :
          <div>
            {seleccion === 'gestion' && this.pintarEstados()}
            {seleccion === 'adjuntos' && this.pintarAdjuntos()}
          </div>
        }
      </>
    )
  }
  render() {
    let { modalGestionarConsultoria, mostrarModalGestionarConsultoria, usuario } = this.props;
    let { cargando, titulo_res, seleccion, buscarGestor } = this.state;

    return (
      <>
        <Dialog fullWidth={true} maxWidth="sm" open={modalGestionarConsultoria} onClose={mostrarModalGestionarConsultoria.bind(this, false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <AppBarModal titulo={'¿ Gestionar Consultoria ?'} mostrarModal={mostrarModalGestionarConsultoria} titulo_accion="" accion="" />
          {usuario.perfil.codigo === "Per_Consul_Est" ? <MenuGestionarEstudiante seleccionar={(seleccion) => this.setState({ seleccion })} sele={seleccion} /> : <MenuGestionar seleccionar={(seleccion) => this.setState({ seleccion })} sele={seleccion} />}
          <DialogContent style={{ padding: '1' }} className='scroll'>
            {cargando ? <TareasTerminadas mensaje={titulo_res} marginTop='7%' imagen={emma_w} widthImg="7%" cargando={true} /> : <div style={{ padding: "5px 0 5px 0" }}> {this.pintar()} </div>}
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => mostrarModalGestionarConsultoria(false)} />
            {!cargando && <BtnForm texto="ACEPTAR" callback={this.enviarDatos} />}
          </DialogActions>
        </Dialog>


        <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={buscarGestor} onClose={() => this.setState({ buscarGestor: false })}>
          <AppBarModal titulo={'¡ Buscar Gestor !'} mostrarModal={() => this.setState({ buscarGestor: false })} titulo_accion="CERRAR" accion={() => this.setState({ buscarGestor: false })} />
          <DialogContent style={{ padding: '0' }} className='scroll'>
            < BuscarPersona callback={({ id, primer_nombre, primer_apellido, segundo_apellido }) => this.setState({
              gestor: {
                id,
                nombre_completo: `${primer_nombre} ${primer_apellido} ${segundo_apellido}`,
              }, buscarGestor: false
            })} />
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => this.setState({ buscarGestor: false })} />
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

ConsultoriaGestionar.propTypes = {
  //variables
  // inscripcion: PropTypes.object.isRequired,
  modalGestionarConsultoria: PropTypes.bool.isRequired,
  //funciones
  mostrarModalGestionarConsultoria: PropTypes.func.isRequired,

}

export default ConsultoriaGestionar;
