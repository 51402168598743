const MODAL_DETALLE_INSCRIPCIONES = 'MODAL_DETALLE_INSCRIPCIONES'
const MODAL_DETALLE_INSCRIPCIONESPRE = 'MODAL_DETALLE_INSCRIPCIONESPRE'
const MODAL_ADD_FILTRO_INSCRIPCION = 'MODAL_ADD_FILTRO_INSCRIPCION'
const MODAL_GES_INSCRIPCION = 'MODAL_GES_INSCRIPCION'
const MODAL_MEN_VALIDACION = 'MODAL_MEN_VALIDACION'
const MODAL_EDITAR_INSCRIPCIONES = 'MODAL_EDITAR_INSCRIPCIONES'
const MODAL_PERIODO_INSCRIPCIONES = 'MODAL_PERIODO_INSCRIPCIONES'
const MODAL_PERIODO_ACTUALIZAR_INSCRIPCIONES = 'MODAL_PERIODO_ACTUALIZAR_INSCRIPCIONES'
const MODAL_AUTORIZACION_PADRE = 'MODAL_AUTORIZACION_PADRE'
const MODAL_AUTORIZACION_CUMPLIMIENTO = 'MODAL_AUTORIZACION_CUMPLIMIENTO'
const MODAL_AUTORIZACION_CALIDAD = 'MODAL_AUTORIZACION_CALIDAD'
const MODAL_GESTION_PREUNIVERSITARIO = 'MODAL_GESTION_PREUNIVERSITARIO'
const MODAL_OBSERVACION_PREUNIVERSITARIO = 'MODAL_OBSERVACION_PREUNIVERSITARIO'
const MODAL_ASIGNAR_PREUNIVERSITARIO = 'MODAL_ASIGNAR_PREUNIVERSITARIO'
const MODAL_PLAN_ACOMPANAMIENTO = "MODAL_PLAN_ACOMPANAMIENTO";
const MODAL_VER_PLAN = "MODAL_VER_PLAN";

const mostrarModalDetalleInscripcion = show => {
  return {
    type: MODAL_DETALLE_INSCRIPCIONES,
    show,
  }
}

const mostrarModalDetalleInscripcionPre = show => {
  return {
    type: MODAL_DETALLE_INSCRIPCIONESPRE,
    show,
  }
}

const mostrarModalAddFiltrosInscripcion = show => {
  return {
    type: MODAL_ADD_FILTRO_INSCRIPCION,
    show,
  }
}

const mostrarModalGestionarInscripcion = show => {
  return {
    type: MODAL_GES_INSCRIPCION,
    show,
  }
}

const mostrarModalMensajeValidacionId = show => {
  return {
    type: MODAL_MEN_VALIDACION,
    show,
  }
}

const mostrarModalEditarInscripcion = show => {
  return {
    type: MODAL_EDITAR_INSCRIPCIONES,
    show,
  }
}

const mostrarModalPeriodoInscripcion = show => {
  return {
    type: MODAL_PERIODO_INSCRIPCIONES,
    show,
  }
}

const mostrarModalPeriodoActualizarInscripcion = show => {
  return {
    type: MODAL_PERIODO_ACTUALIZAR_INSCRIPCIONES,
    show,
  }
}

const mostrarModalAutorizacionPadre = show => {
  return {
    type:MODAL_AUTORIZACION_PADRE,
    show,
  }
}
const mostrarModalAutorizacionCumplimiento = show => {
  return {
    type:MODAL_AUTORIZACION_CUMPLIMIENTO,
    show,
  }
}

const mostrarModalAutorizacionCalidad = show => {
  return {
    type:MODAL_AUTORIZACION_CALIDAD,
    show,
  }
}
const mostrarModalGestionPreUniversitario = show => {
  return {
    type: MODAL_GESTION_PREUNIVERSITARIO,
    show,
  }
}

const mostrarModalObservacionPreUniversitario = show => {
  return {
    type: MODAL_OBSERVACION_PREUNIVERSITARIO,
    show,
  }
}
const mostrarModalAsignarPreUniversitario = show => {
  return {
    type: MODAL_ASIGNAR_PREUNIVERSITARIO,
    show,
  }
}
const mostrarModalPlan = (show) => {
  return {
    type: MODAL_VER_PLAN,
    show,
  };
};

const mostrarPlanAcompanamiento = (show) => {
  return {
    type: MODAL_PLAN_ACOMPANAMIENTO,
    show,
  };
};

module.exports = {
  mostrarModalDetalleInscripcion,
  MODAL_DETALLE_INSCRIPCIONES,
  mostrarModalDetalleInscripcionPre,
  MODAL_DETALLE_INSCRIPCIONESPRE,
  mostrarModalAddFiltrosInscripcion,
  MODAL_ADD_FILTRO_INSCRIPCION,
  mostrarModalGestionarInscripcion,
  MODAL_GES_INSCRIPCION,
  mostrarModalMensajeValidacionId,
  MODAL_MEN_VALIDACION,
  mostrarModalEditarInscripcion,
  MODAL_EDITAR_INSCRIPCIONES,
  mostrarModalPeriodoInscripcion,
  MODAL_PERIODO_INSCRIPCIONES,
  mostrarModalPeriodoActualizarInscripcion,
  MODAL_PERIODO_ACTUALIZAR_INSCRIPCIONES,
  mostrarModalAutorizacionPadre,
  MODAL_AUTORIZACION_PADRE,
  mostrarModalAutorizacionCumplimiento,
  MODAL_AUTORIZACION_CUMPLIMIENTO,
  mostrarModalAutorizacionCalidad,
  MODAL_AUTORIZACION_CALIDAD,
  mostrarModalGestionPreUniversitario,
  MODAL_GESTION_PREUNIVERSITARIO,
  mostrarModalObservacionPreUniversitario,
  MODAL_OBSERVACION_PREUNIVERSITARIO,
  mostrarModalAsignarPreUniversitario,
  MODAL_ASIGNAR_PREUNIVERSITARIO,
  mostrarPlanAcompanamiento,
  MODAL_PLAN_ACOMPANAMIENTO,
  mostrarModalPlan,
  MODAL_VER_PLAN,
};
