import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableRow, Paper, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import AppBarModal from '../general/AppBarModal';
import { BtnForm } from '../general/BotonesAccion';
import TareasTerminadas from './TareasTerminadas';
import emma_w from '../../global/imagenes/emma_w.png';

export default function Detalle({ titulo, open, config, cerrar, cargando = false }) {

  return (
    <Dialog open={open} onClose={cerrar} fullWidth={true} maxWidth="sm" aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <AppBarModal titulo={titulo} mostrarModal={cerrar} titulo_accion="" accion={() => { }} />
      <DialogContent style={{ padding: 0 }} className='scroll'>
        {
          cargando ? <TareasTerminadas mensaje = 'Cargando..' marginTop = '7%' imagen = { emma_w } widthImg = "7%" />
            : <Paper square elevation={0}>
                <Table >
                  <TableBody>
                    {config.map(campo => {
                      return (
                        <TableRow key={campo.label}>
                          <TableCell component="th" scope="row">{campo.label}</TableCell>
                          <TableCell align="left" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{campo.value}</TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </Paper>
        }
      </DialogContent>
      <DialogActions>
        <BtnForm texto="CERRAR" callback={cerrar} />
      </DialogActions>
    </Dialog>
  )
}

Detalle.propTypes = {
  config: PropTypes.array.isRequired
}
