import React, { Component } from 'react';
import { consulta, crear_form_data, formulario, generarFiltros, mostrarError, obtenerValoresFiltros } from '../../../global/js/funciones';
import RegistrosCrear from './RegistrosCrear';
import RegistroCrear from '../registro/RegistroCrear';

class Registros extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cargando: true
    }
  }

  async actualizarListaSolicitudes() {
    let { set_lista_solicitudes, obtenerSolicitudes } = this.props;
    let periodo = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Int' }]);
    let listaSolicitudes = await obtenerSolicitudes([{ 'llave': 'periodo', 'valor': periodo.length > 0 ? periodo[0].nombre : '' }]);
    set_lista_solicitudes(listaSolicitudes);
  }

  agregarParticipanteRegistro = async (id_registro, data, callback) => {
    let { actualizarMensaje } = this.props;
    let form = await crear_form_data(data);
    formulario(`api/v1.0/internacionalizacion/registro/${id_registro}/agregar_participante`, form, 'post', (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error', participante = {}
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          participante = resp.participante;
          callback(participante);
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    })
  }

  agregarFinanciacionRegistro = async (id_registro, data, callback) => {
    let { actualizarMensaje } = this.props;
    let form = await crear_form_data(data);
    formulario(`api/v1.0/internacionalizacion/registro/${id_registro}/agregar_financiacion`, form, 'post', (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error', financiacion = {}
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          // financiacion = resp.financiacion;
          callback();
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    })
  }

  guardarParticipanteExterno = async (id_registro, data, callback) => {
    let { actualizarMensaje } = this.props;
    let form = await crear_form_data(data);
    formulario(`api/v1.0/internacionalizacion/registro/${id_registro}/crear_persona_ext`, form, 'post', (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error', participante = {}
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          callback();
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    })
  }

  modificarBorradorRegistro = async (id, data = {}, requisitos = [], instituciones = [], fac_dptos = [], facultad = [], unidades_adm = [], arraytipos = [], paso_actual, callback) => {
    let { actualizarMensaje, mostrarModalAddRegistro, actualizarDataProcesoActual } = this.props;
    let form = await crear_form_data(data);
    if (paso_actual == 0) {
      requisitos.forEach((id, index) => form.append(`evidencia${index}`, id));
      instituciones.forEach(({ id }, index) => form.append(`institucion${index}`, id));
      fac_dptos.forEach((id, index) => form.append(`fac_dep${index}`, id));
      facultad.forEach((id, index) => form.append(`fac${index}`, id));
      unidades_adm.forEach((id, index) => form.append(`unidad_adm${index}`, id));
      arraytipos.forEach((id, index) => form.append(`array_tipo${index}`, id));
    }
    formulario(`api/v1.0/internacionalizacion/solicitud/${id}/modificar_registro`, form, "put", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          callback(true);
          if (paso_actual == 4) {
            mostrarModalAddRegistro(false); // Cerrar modal en último paso
            this.actualizarListaSolicitudes(); // Recargar info de solicitudes mostradas en proceso
            actualizarDataProcesoActual(); // Recargar data proceso actual
          }
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          callback(false);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    });
  }

  modificarEvidencia = async (id, data = {}) => {
    let { actualizarMensaje } = this.props;
    let form = await crear_form_data(data);
    formulario(`api/v1.0/internacionalizacion/registro/modificar_evidencia/${id}`, form, "put", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    });
  }

  modificarParticipanteRegistro = async (id, data, callback) => {
    let { actualizarMensaje } = this.props;
    let form = await crear_form_data(data);
    formulario(`api/v1.0/internacionalizacion/registro/participante/${id}/modificar`, form, "patch", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error', participante = {};
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          participante = resp.participante;
          callback(participante); // Retorna el participante modificado, si este es modificado correctamente.
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    });
  }

  async obtenerBorradorRegistro() {
    return new Promise(resolve => {
      consulta(`api/v1.0/internacionalizacion/registros/obtener_borrador`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      });
    })
  }

  async obtenerListaEvidencias(modalidad) {
    return new Promise(resolve => {
      consulta(`api/v1.0/internacionalizacion/registros/lista_evidencias/${modalidad}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      });
    });
  }

  async obtenerRegistroConSolicitud(id) {
    let f = await generarFiltros([{ 'llave': 'solicitud', 'valor': id }]);
    return new Promise(resolve => {
      consulta(`api/v1.0/internacionalizacion/solicitud/registro?${f}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      });
    })
  }

  quitarParticipanteRegistro = (id, callback) => {
    let { actualizarMensaje } = this.props;
    consulta(`api/v1.0/internacionalizacion/registro/participante/${id}/quitar`, {}, "put", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          callback(); // Envía "(true," si se ejecuto correctamente y ", false)" para cerrar el modal.
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    });
  }

  quitarFinanciacionRegistro = (id, callback) => {
    let { actualizarMensaje } = this.props;
    consulta(`api/v1.0/internacionalizacion/registro/financiacion/${id}/quitar`, {}, "put", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          callback(); // Envía "(true," si se ejecuto correctamente y ", false)" para cerrar el modal.
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    });
  }

  guardarRegistro = async (data, callback) => { 
    let { actualizarMensaje, mostrarModalAddRegistro, actualizarDataProcesoActual, tipo_solicitud_seleccionada } = this.props;
    data.append('tipo_solicitud', tipo_solicitud_seleccionada);
    console.log(data)
    formulario(`api/v1.0/internacionalizacion/registro/crear`, data, 'post', (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
            if (estado === 200) {
                tipo = 'success';
                titulo = resp.titulo;
                callback(true);
                mostrarModalAddRegistro(false);
                // this.actualizarListaSolicitudes();
                // actualizarDataProcesoAct}ual();
            } else {
                tipo = 'info';
                titulo = resp.titulo ? resp.titulo : mostrarError(resp);
                callback(false);
            }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    });
}


  render() {
    let { actualizarMensaje, crearBorradorSolicitud, modalAddRegistro, mostrarModalAddRegistro, obtenerAdjuntos,
      mostrarModalCancelarSolicitud, set_datos_solicitud, cancelo_solicitud, set_solicitud_cancelada, obtenerParticipantesActividad, obtenerFinanciacionesActividad, tipo_solicitud_seleccionada, instructivo } = this.props;
    return (
      // <RegistrosCrear
      //   modalAddRegistro = { modalAddRegistro }
      //   mostrarModalAddRegistro = { mostrarModalAddRegistro }
      //   mostrarModalCancelarSolicitud = { mostrarModalCancelarSolicitud }
      //   actualizarMensaje = { actualizarMensaje }
      //   obtenerAdjuntos = { obtenerAdjuntos }
      //   obtenerParticipantesActividad = { obtenerParticipantesActividad }
      //   obtenerFinanciacionesActividad = { obtenerFinanciacionesActividad }
      //   crearBorradorRegistro = { crearBorradorSolicitud }
      //   obtenerBorradorRegistro = { this.obtenerBorradorRegistro }
      //   obtenerListaEvidencias = { this.obtenerListaEvidencias }
      //   modificarBorradorRegistro = { this.modificarBorradorRegistro }
      //   modificarEvidencia = { this.modificarEvidencia }
      //   modificarParticipanteRegistro = { this.modificarParticipanteRegistro }
      //   agregarParticipanteRegistro = { this.agregarParticipanteRegistro }
      //   agregarFinanciacionRegistro = { this.agregarFinanciacionRegistro }
      //   quitarParticipanteRegistro = { this.quitarParticipanteRegistro }
      //   quitarFinanciacionRegistro={ this.quitarFinanciacionRegistro }
      //   obtenerRegistroConSolicitud = { this.obtenerRegistroConSolicitud }
      //   guardarParticipanteExterno = { this.guardarParticipanteExterno }
      //   cancelo_solicitud = { cancelo_solicitud }
      //   set_datos_solicitud = { set_datos_solicitud }
      //   set_solicitud_cancelada = { set_solicitud_cancelada }
      //   modalAddFinanciaciones = { this.props.modalAddFinanciaciones }
      //   mostrarModalAddFinanciaciones = { this.props.mostrarModalAddFinanciaciones }
      // />
      <RegistroCrear
        modalAddRegistro = { modalAddRegistro }
        mostrarModalAddRegistro = { mostrarModalAddRegistro }
        actualizarMensaje={actualizarMensaje}
        guardarRegistro={this.guardarRegistro}
        tipo_solicitud={tipo_solicitud_seleccionada}
        instructivo={instructivo}
      />
    )
  }
}

export default Registros;