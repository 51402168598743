import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {BtnGestionar, BtnForm, BtnRegresar, BtnPermiso } from "../../general/BotonesAccion";
import ListarDatos from '../../general/ListarDatos';
import { rutaImg } from "../../../global/js/funciones";
import {actualizarDataPersona, actualizarDataPersonas, actualizarRenderPersona } from "../../../redux/actions/actPersonas";
import {mostrarModalInformeConsultoria} from "../../../redux/actions/actConsultoria"
import {actualizarMensaje } from "../../../redux/actions/actGlobal";
import { connect } from "react-redux";
import { consulta,obtenerValoresFiltros} from "../../../global/js/funciones";
import AppBarModal from '../../general/AppBarModal'
import { Paper, Grid, DialogActions, DialogContent, Dialog} from '@material-ui/core'
import Generica from "./../../personas/generica/Generica";
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from '../../../global/imagenes/emma_w.png';
import AccionesRapidas from '../../general/AccionesRapidas';
import TituloAccion from '../../general/TituloAccion';
import study from '../../../global/imagenes/study.png';
import permisosIcon from '../../../global/imagenes/lightbulb.png';
import reportIcon from '../../../global/imagenes/report.png';
import { ESTADOS_CONSULTORIA, TIPOS_CONSULTORIA, AREA_DERECHO, Tipo_informes_consultoria } from '../helper';
import ListarTarjetas from '../../general/ListarTarjetas';
import ConsultoriaInforme from "./ConsultoriaInforme";
import fechaIcon from '../../../global/imagenes/calendar.png';
import EnviarMensajeFecha from "../../general/EnviarMensajeFecha";

function ConfiguracionUsuario(component_props) {
  let {perfilPermiso, cambiarEstado, data} = component_props
  return(
    <Dialog open={perfilPermiso} fullWidth={true} maxWidth='sm' onClose={() => cambiarEstado({perfilPermiso : false})}>
      <AppBarModal titulo='¡ Configuracion de Usuario !' mostrarModal={() => cambiarEstado({perfilPermiso : false})} titulo_accion='' />
        <DialogContent className='scroll'>
          <Generica tipo={1} persona={data} genericaRelacionId={3} consultoria={true} titulo="Nuevo Perfil" tituloInput="Perfiles" tituloEliminar='¿ Eliminar Perfil ?' />
        </DialogContent>
      <DialogActions>
        <BtnForm texto="Cerrar" callback={() =>cambiarEstado({perfilPermiso : false})} />
      </DialogActions>
  </Dialog>
  )
}

class ConsultoriaPerfilesAdministrar extends Component {
 
  constructor(props) {
    super(props)
    this.state = {
      cargando: false,
      dato_buscar: '',
      perfilPermiso:false,
      accionProceso: 'inicio',
      generica: {
        tipo: 0,
        id: 0,
      },
      modal: false,
      id_persona: null,
      personasPerfil:[],
      data:'',
      informes : [],
      informe: {'nombre' : '', 'codigo' : ''},
      fecha_periodo: '',
      descargarInforme: false,
      periodoActual:'',
      area_derecho:[],
      informed:[],
    }
  }

  
  async componentDidUpdate(prevProps, prevState) {
    let { accionProceso } = this.state;
    let { actualizarDataPersonas, actualizarRenderPersona } = this.props;

    if (this.state.dato_buscar !== prevState.dato_buscar) {
      this.obtenerPersonas(this.state.dato_buscar);
    }
  
    if (accionProceso !== prevState.accionProceso){
      if(accionProceso === 'permisos'){
        this.setState({ cargando: true })
        let personasPerfil = await this.obtenerPersonasPerfil();
        this.setState({ personasPerfil, cargando: false })
      }
      else if (accionProceso === 'informes') this.obtenerInformes();
      else if (accionProceso === 'actividad'){
        actualizarDataPersonas([]);
        actualizarRenderPersona(true);
      }
    }
    
  }

  async listarSolicitudesDescarga(periodo) {
    return new Promise(resolve => {
       consulta(`api/v1.0/consultoria/listar/All/${periodo}`, null, null, (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  async descargarCantidadSolicitudes(){
    let { periodo } = this.props;
    let { area_derecho } = this.state
    let informed=[]
    let contador=0
    let informeSolicitudes = await this.listarSolicitudesDescarga(periodo)
    area_derecho.forEach(function(elemento,j ) {
      informeSolicitudes.forEach(function(dato ) {
        if(elemento.id === dato.area) {
           contador=contador+1
        }
      })
      informed[j]={'nombre':elemento.nombre, 'cantidad':contador}
      contador=0
    })
    this.setState({descargarInforme:true, informed})
  }

  
  async obtenerPersonasPerfil() {
    return new Promise(resolve => {
      consulta(`api/v1.0/consultoria/gestores`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }
  
  obtenerPersonas = (dato) => {
    let { actualizarDataPersonas, actualizarRenderPersona } = this.props;
    // this.setState({ cargando: true })
    if (dato.length > 4) {
      consulta("api/v1.0/personas/buscar", { dato }, "post",
        (error, estado, resp) => {
          if (estado === 200) {
            actualizarDataPersonas(resp);
            actualizarRenderPersona(true);
            // this.setState({ cargando: false })
          }
        }
      );
    } else {
      actualizarDataPersonas([]);
      actualizarRenderPersona(true);
      this.setState({ cargando: false });
    }
  }

  obtenerInformes = async () => {
    let informes = []
    this.setState({cargando : true})
    informes = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': Tipo_informes_consultoria }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valora', 'valor': 'admin'}]);
    let area_derecho = await obtenerValoresFiltros([{"llave": 'generica', 'valor': AREA_DERECHO}])
    this.setState({ informes, area_derecho, cargando: false })
  }

 
  acciones(data) {
    const onClickTipo = (data) => this.setState({ id_persona: data.id, modal: true, generica: { tipo: 21, id: TIPOS_CONSULTORIA } });
    const onClickEstado = (data) => this.setState({ id_persona: data.id, modal: true, generica: { tipo: 22, id: ESTADOS_CONSULTORIA } });
    let tipos = <BtnGestionar callback={() => onClickTipo(data)} texto='Permiso' color='#343434' />;
    let estados = <BtnPermiso callback={() => onClickEstado(data)} texto='Estados' />;
    return <div>{tipos}{estados}</div>
  }


  cambiarEstado = (estados) => {
    this.setState(estados)
  }


  listarpersona(){
    let { personas} = this.props;
    let { dato_buscar, perfilPermiso, data, cargando } = this.state;

    const actions = (rowData, tipo = "acciones") => {
      rowData.identificacion = parseInt(rowData.identificacion);
      rowData.login_directorio = parseInt(rowData.login_directorio);

      const onClickGestionar = (data) => {
        actualizarDataPersona(data);
        this.setState({perfilPermiso:true, data})
      }
      let gestionar= <BtnGestionar callback={() => onClickGestionar(rowData)} color='#00bfa5' texto='Gestionar' />
      return <div>{gestionar}</div>; 
    }

    return (
      <>
        <ListarDatos
          mensajeVacio="Esta bandeja esta vacía. Digita el dato en el buscador para mostrar a la persona"
          id="tbl_listar_valores"
          titulo={"Lista Personas"}
          descargar="EMMA - PERSONAS"
          datos={personas}
          opciones={true}
          buscar={true}
          cargando={cargando}
          avatarImg={true}
          css={{
            list: { padding: '0px 0px 20px 0px' },
            appBar: { padding: '0px 0px 0px 0px' },
          }}
          actBuscar={(dato_buscar) => this.setState({ dato_buscar })}
          datoBuscarAlt={dato_buscar}
          avatar={({ identificacion }) => `${rutaImg}${identificacion}.jpg`}
          acciones={(row) => actions(row)}
          fila_principal={({ primer_nombre, segundo_nombre, primer_apellido, segundo_apellido }) => `${primer_nombre} ${segundo_nombre} ${primer_apellido} ${segundo_apellido}`.toUpperCase()}
          filas={[
            { 'id': 'usuario', 'enLista': true },
            { 'id': 'identificacion', 'enLista': true },
            { 'id': 'correo', 'enLista': false },
          ]}
        />
          <ConfiguracionUsuario  perfilPermiso={perfilPermiso} cambiarEstado={this.cambiarEstado} data={data}/>
      </>
    )
  }

  
  pintarInicioProcesos() {
    let { periodo} = this.props;
    return(
      <div style={{ padding: '2% 2% 0% 2%' }} >
        <Grid container direction={'row'} spacing={1} justify={'center'} >
          <Grid item xs={12} sm={12} md={10} lg={8} >
            <TituloAccion principal='' alterno='¿ Que deseas hacer ?' />
            <Paper elevation={0} square className="scroll contenido_fijo">
              <Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
                <Grid item xs={12} sm={12} md={6} lg={6} >
                  <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.setState({ accionProceso: 'actividad' })}>
                    <AccionesRapidas imagen={study} titulo={`¡ Controlar Vista Modulo !`} sub_titulo='' des_sub_titulo='Verifica, asigna y retira la visual del modulo.' />
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} >
                  <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.setState({ accionProceso: 'permisos' })}>
                    <AccionesRapidas imagen={permisosIcon} titulo='¡ Controlar Permisos !' sub_titulo='' des_sub_titulo='Verifica, asigna y retira permisos a los gestores.' />
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} >
                  <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.setState({ accionProceso: 'informes' })}>
                    <AccionesRapidas imagen={reportIcon} titulo={`¡ Informes !`} sub_titulo='' des_sub_titulo={`Genera reporte de los procesos de consultoria.`} />
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} >
                      <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.props.mostrarModalModPeriodoConsultoria(true)}>
                        <AccionesRapidas imagen={fechaIcon} titulo={`¡ Actualizar Período !`} sub_titulo='' des_sub_titulo={`El periodo actual ${periodo}.`} />
                      </Paper>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div> 
    )
  }

  renderPermisosConsultoria = () => {
    let { modal, id_persona, generica,  personasPerfil } = this.state;
    return(
      <>
      <ListarDatos
          datos={personasPerfil}
          titulo='Lista Gestores'
          buscar={true}
          agregar={false}
          opciones={true}
          descargar='EMMA - GESTORES INSCRIPCIONES'
          id='tbl_gestores_ins'
          acciones={row => this.acciones(row)}
          avatarImg={true}
          avatar={({ identificacion }) => `${rutaImg}${identificacion}.jpg`}
          fila_principal={({ primer_nombre, primer_apellido, segundo_apellido }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`}
          filas={[
            { 'id': 'nombre', 'mostrar': ({ primer_nombre, primer_apellido, segundo_apellido }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`, 'enLista': false },
            { 'id': 'correo' },
            { 'id': 'identificacion' },
          ]}
        />
  
      <Dialog open={modal} fullWidth={true} maxWidth='sm' onClose={() => this.setState({ modal: false })}>
        <AppBarModal titulo='¡ Lista Permisos !' mostrarModal={() => this.setState({ modal: false })} accion={() => this.setState({ modal: false })} titulo_accion='CERRAR' />
        <DialogContent style={{ padding: 0 }} className='scroll'>
          <Generica tipo={generica.tipo} persona={{ id: id_persona }} genericaRelacionId={generica.id} titulo="¡ Nuevo Permiso !" tituloInput="Permisos" tituloEliminar='¿ Eliminar Permiso ?' />
        </DialogContent>
        <DialogActions>
          <BtnForm texto="Cerrar" callback={() => this.setState({ modal: false })} />
        </DialogActions>
      </Dialog>
      </>
    )
    }

    pintarInformes() {
      let { informes, informe, descargarInforme,informed }  = this.state
      let { modalInformeConsultoria, mostrarModalInformeConsultoria, actualizarMensaje}  = this.props
  
      const acciones = (data) => {      
        this.setState({ informe: {'nombre': data.nombre, 'codigo': data.codigo} }) 
        mostrarModalInformeConsultoria(true);
        this.descargarCantidadSolicitudes();
      }
  
      return (
        <>
          <ListarTarjetas
            datos={informes}
            titulo={'Informes Consultoria'}
            acciones={(data) => acciones(data)}
            avatar={({ nombre }) => nombre.toUpperCase().charAt(0)}
            color_avatar={({valorc}) => ({'backgroundColor': ((valorc) ? valorc : '#EEEEEE'), 'color': ((valorc) ? 'white' : '#757575') })}
            orden={'valorb'}
          />
          <ConsultoriaInforme
            informe={informe}
            modalInformeConsultoria={modalInformeConsultoria}
            mostrarModalInformeConsultoria={mostrarModalInformeConsultoria}
            actualizarMensaje={actualizarMensaje}
            descargarInforme = {descargarInforme}
            informed = {informed}
        />
        </>
      )
    }

  renderProcesos() {
    let { accionProceso } = this.state;
    let {mostrarModalModPeriodoConsultoria,modalModPeriodoConsultoria} = this.props;
    return (
      <>
        {accionProceso === 'inicio' && this.pintarInicioProcesos() }
        {accionProceso !== 'inicio' && <div className={'regresar'} ><BtnRegresar callback={() => this.setState({ filtros_practica: [], accionProceso: 'inicio' })} /></div>}
        {accionProceso === 'permisos' && this.renderPermisosConsultoria()}   
        {accionProceso === 'actividad' && this.listarpersona()}
        {accionProceso === 'informes' && this.pintarInformes() }
        <EnviarMensajeFecha mostrarFecha ={false} modal={modalModPeriodoConsultoria} mostrarModal={mostrarModalModPeriodoConsultoria} enviar={(periodo, fecha) => this.props.modificarPeriodo(periodo)} label='Periodo' type='text' titulo={`Nuevo Periodo`} label_fecha='Fecha Vencimiento' /> 
      </>
    )
  }

 
  render() {
    
    let {cargando } = this.state;
    if (cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" cargando={true}/>;
    return (
      <>
      {this.renderProcesos()}
      </>
    )
    }
  
}

const mapStateToProps = (state) => {
  let { persona, personas, renderPersona} = state.redPersonas;
  let {modalInformeConsultoria} = state.redConsultoria;
  return {
    persona,
    personas,
    renderPersona,
    modalInformeConsultoria
  }
};

//CARGO FUNCIONES DEL MODULO
const mapDispatchToProps = {
  actualizarMensaje,
  actualizarDataPersona,
  actualizarDataPersonas,
  actualizarRenderPersona,
  mostrarModalInformeConsultoria,
}

//Props del componente
ConsultoriaPerfilesAdministrar.propTypes = {
  //variables
  renderPersona: PropTypes.bool.isRequired,
  personas: PropTypes.array.isRequired,
  persona: PropTypes.shape({
    id: PropTypes.number.isRequired,
    // tipo: PropTypes.number.isRequired,
    tipo_identificacion: PropTypes.object.isRequired,
    identificacion: PropTypes.number.isRequired,
    primer_nombre: PropTypes.string.isRequired,
    segundo_nombre: PropTypes.string,
    primer_apellido: PropTypes.string.isRequired,
    segundo_apellido: PropTypes.string,
    correo: PropTypes.string.isRequired,
    usuario: PropTypes.string.isRequired,
  }),
  //funciones
  actualizarRenderPersona: PropTypes.func.isRequired,
  actualizarDataPersonas: PropTypes.func.isRequired,
  actualizarDataPersona: PropTypes.func.isRequired,
  mostrarModalInformeConsultoria: PropTypes.func.isRequired,
}


export default connect(mapStateToProps, mapDispatchToProps)(ConsultoriaPerfilesAdministrar);
