import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { TextField, Paper, Grid, Tooltip, IconButton, InputLabel, MenuItem, FormControl, DialogActions, DialogContent, Select, Dialog, CardHeader,Avatar,Stepper,StepLabel,Step,Card,CardContent,Typography } from '@material-ui/core'
import FilterListIcon from '@material-ui/icons/FilterList';
import { consulta, formulario, mostrarError, generarFiltros, obtenerValoresFiltros, crear_form_data,rutaImg } from "../../../global/js/funciones";
import { mostrarModalComment, mostrarModalDetalleBeca, mostrarGestionBeca, mostrarModalVolante, mostrarModalFiltroBeca,mostrarModalHabilitarestudiante } from "../../../redux/actions/actBecas";
import { BtnDetalle, BtnGestionar, BtnFolder,BtnRegresar, BtnForm, BtnFormPage } from "../../general/BotonesAccion";
import { mostrarModulo, actualizarMensaje } from "../../../redux/actions/actGlobal";
import ListarDatos from "../../general/ListarDatos";
import { MenuBecas }  from './MenuBecas';
import BecasGestionar from './BecasGestionar'
import BecaDetalle from '../BecaDetalle';
import BecasPermisos from './BecasPermisos'
import BecaFiltros from './BecaFiltros';
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from '../../../global/imagenes/emma_w.png';
import emma_s1 from '../../../global/imagenes/emma_s1.png';
import {brown} from '@material-ui/core/colors';
import moment from 'moment'
import TituloAccion from '../../general/TituloAccion';
import AccionesRapidas from '../../general/AccionesRapidas';
import AppBarModal from '../../general/AppBarModal';
import { red } from '@material-ui/core/colors';
import tap from '../../../global/imagenes/tap.png';
import { coloresEmma } from '../../../global/js/funciones';


const configStep = ['Consultar estudiante', 'Datos de estudiante'];

function ConsultarEstudiante(component_props){
  let {identificacion,onChange} = component_props

  return(
    <>
      <Grid container spacing={3} style={{ marginBottom: '40px' }}>
        <Grid item xs={12}>
          <TextField
            value={identificacion}
            required
            type="number"
            id="identificacion"
            name="identificacion"
            label="Documento Identidad"
            fullWidth
            onChange={onChange}
          />
        </Grid>
      </Grid>

    </>
)
}

function DatosEstudiante(component_props) {
  let { datosestudiantes } = component_props
  return (
    <>
      <ListarDatos
        datos={datosestudiantes.respuesta}
        titulo='Datos estudiante'
        id='tbl_Datos_listar'
        opciones={true}
        acciones={false}
        buscar={true}
        avatarImg={true}
          css={{
            list: { padding: '0px 0px 20px 0px' },
            appBar: { padding: '0px 0px 0px 0px' },
          }}
        avatar={({ Documento }) => `${rutaImg}${Documento}.jpg`}
        fila_principal={({ nom_tercero }) => nom_tercero}
        filas={[
          { 'id': 'Documento', 'mostrar': ({ Documento }) => Documento },
          { 'id': 'nom_tercero', 'mostrar': ({ nom_tercero, pri_apellido }) => `${nom_tercero} ${pri_apellido}` },
          { 'id': 'Correo_Personal', 'mostrar': ({ Correo_Personal }) => Correo_Personal },
          { 'id': 'cod_unidad', 'mostrar': ({ cod_unidad }) => cod_unidad },
        ]}
      />

    </>
  )

}

export class BecasAdministrar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      filtros_beca: [],
      cargando: false,
      accion: 'Procesos',
      accionProceso: 'inicio',
      administrador: false,
      becas: [],
      periodo: '',
      beca: null,
      volante: '',
      modalFiltro: false,
      vistaDetalle : 'detalle',
      id_estudiante : 0,
      estados_procesos: [],
      cargarFiltroP : true,
      periodo_filtro : null,
      periodos:[],
      filtrarPeriodo : false,
      cargandoPeriodo: true,
      correo:"",
      identificacion:"",
      estudiantesEx:[],
      activeStep:0,
      datosestudiantes:{ "respuesta":[]}

    }
  }

  componentDidMount() {
    let { mostrarModulo } = this.props;
    mostrarModulo({ ruta: '/becas_adm', nombre: 'Becas' });
    this.obtenerDatosIniciales();
    this.esAdministrador();
    this.obtenerDatoperiodo();
  }

  async componentDidUpdate({ }, { accion, accionProceso }) {
    let { accion: accionNew, periodo, enNotificacion: enNotificacionNew, accionProceso: accionProcesoNew, periodo_filtro } = this.state;
    if (accion !== accionNew) {
      if (accionNew === 'Procesos') this.obtenerEstadoSolicitudes(periodo);
    }

    if (accionProceso !== accionProcesoNew) {
      if (accionProcesoNew != 'Tod_Bec') {
        this.cambiarEstado({ cargando: true })
        if (accionProcesoNew == 'inicio') this.obtenerEstadoSolicitudes(periodo_filtro)
        else this.actualizarDataBecas([{ 'llave': 'estado_actual__codigo', 'valor': accionProcesoNew }, { 'llave': 'periodo', 'valor': periodo_filtro }]);
      }
    }
  }

  esAdministrador() {
    let { usuario } = this.props;
    if (usuario && usuario.perfil && (usuario.perfil.codigo === "Per_Adm" || usuario.perfil.codigo === "Per_Bec_n1")) {
      this.setState({ administrador: true })
    }
  }

  async actualizarDataBecas(filtros = [{ 'llave': 'estado_actual__valorc', 'valor': 'si' }, { 'llave': 'periodo', 'valor': this.state.periodo }]) {
    this.setState({ cargando: true })
    console.log(filtros)
    let becas = await this.obtenerBecas(filtros);
    this.setState({ becas, cargando: false, filtros_beca: filtros })
  }

  async obtenerDatosIniciales() {
    this.setState({ cargando: true });
    let periodo = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Bec' }]);
    this.obtenerEstadoSolicitudes(periodo[0].nombre);
    this.setState({ periodo: periodo.length > 0 ? periodo[0].nombre : '', periodo_filtro: periodo.length > 0 ? periodo[0].nombre : ''});
    
  }


  async obtenerBecas(filtros = []) {
    let f = await generarFiltros(filtros);
    return new Promise(resolve => {
      consulta(`api/v1.0/becas/listar/1?${f}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);

      })
    })
  }

  async obtenerDatosestudiante(dato) {
    let f=dato
    return new Promise(resolve => {
      consulta(`api/v1.0/becas/obtenerdatosestudiante/${f}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  gestionarBeca = async (id, data, callback) => {
    let { volante } = this.state
    let { mostrarGestionBeca, actualizarMensaje, mostrarModalVolante } = this.props
    let form = await crear_form_data(data);
    form.append('volante', volante)
    formulario(`api/v1.0/becas/${id}/gestionar`, form, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          mostrarGestionBeca(false)
          mostrarModalVolante(false);
          this.actualizarDataBecas(this.state.filtros_beca);
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      callback()
    })
  }

  cambiarEstado = (estados) => {
    this.setState(estados)
  }

  pintarProcesos() {
    let { becas, beca } = this.state;
    let { mostrarModalDetalleBeca, mostrarGestionBeca, modalGestionBeca, actualizarMensaje, mostrarModalFiltroBeca, modalVolante, mostrarModalVolante } = this.props;

    const obtenerColor = (codigo) => {
      let color = 'white';
      const colores = {
        'Est_bec_env': { 'backgroundColor': coloresEmma.colorblanco, 'color': '#757575' },
        'Est_bec_rev': { 'backgroundColor': coloresEmma.solicitudactiva, color },
        'Est_bec_apr': { 'backgroundColor': coloresEmma.solicitudfinalizada, color },
        'Est_bec_apl': { 'backgroundColor': coloresEmma.solicitudfinalizada, color },
        'Est_bec_neg': { 'backgroundColor': coloresEmma.solicitudcancelada, color },
        'Est_bec_can': { 'backgroundColor': coloresEmma.solicitudcancelada, color }
      }
      return (colores[codigo])
    }

    const acciones = (data) => {
      let { estado_actual: { codigo }, permiso } = data;
      console.log(data)
      
      const onClickDetalle = beca => {
        this.setState({ beca , vistaDetalle : 'detalle', id_estudiante : beca.solicitante.id }) 
        mostrarModalDetalleBeca(true)
      }

      const onClickGestionar = beca => {
        this.setState({ beca })
        mostrarGestionBeca(true)
      }

      const onClickAdjuntos = beca => {
        this.setState({ beca, vistaDetalle : 'documentos', id_estudiante : beca.solicitante.id  })
        mostrarModalDetalleBeca(true)
      }

      let detalle = <BtnDetalle callback={() => onClickDetalle(data)} color='#01579b' texto='Abrir' />;
      let gestionar = (codigo !== 'Est_bec_apl' && codigo !== 'Est_bec_neg' && codigo !== 'Est_bec_apr' && codigo !== 'Est_bec_can' && codigo !== 'bec_epo' && permiso === '1') && <BtnGestionar callback={() => onClickGestionar(data)} color='#00bfa5' texto='Gestionar' />;
      let adjuntos = (codigo == 'Est_bec_env' && permiso === '1') && <BtnFolder callback={() => onClickAdjuntos(data)} color='#ffd600' texto='Documentos' />;
      return <div>{detalle}{adjuntos}{gestionar}</div>;
    }
    return (
      <div>
        <BecasGestionar actualizarMensaje={actualizarMensaje} beca={beca} mostrarGestionBeca={mostrarGestionBeca} modalGestionBeca={modalGestionBeca} gestionarBeca={this.gestionarBeca} mostrarModalVolante={mostrarModalVolante} modalVolante={modalVolante} guardarVolante={this.cambiarEstado}/>
        <ListarDatos
          datos={becas}
          titulo='Lista de Becas'
          id='tbl_becas_listar'
          opciones={true}
          descargar='BECAS - EMMA'
          filtrar={true}
          refrescar={true}
          actfiltrar={() => mostrarModalFiltroBeca(true)}
          actRefrescar={() => this.actualizarDataBecas(this.state.filtros_beca)}
          acciones={(data) => acciones(data)}
          buscar={true}
          avatar={({ estado_actual }) => estado_actual.nombre.toUpperCase().charAt(0)}
          color_avatar={({ estado_actual }) => obtenerColor(estado_actual.codigo)}
          fila_principal={({ solicitante: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`}
          filas={[
            { 'id': 'solicitante', 'mostrar': ({ solicitante: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`, 'enLista': false },
            { 'id': 'identificacion', 'mostrar': ({ solicitante : { identificacion } }) => identificacion },
            { 'id': 'tipo_beca', 'mostrar': ({ tipo_beca }) => `${tipo_beca.nombre}`.toUpperCase(), 'enLista': true },
            { 'id': 'subtipo', 'mostrar': ({ subtipo }) => subtipo ? subtipo.nombre : '', 'enLista': false },
            { 'id': 'fecha_registro', 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a') },
            { 'id': 'estado_actual', 'mostrar': ({ estado_actual }) => `${estado_actual.nombre}`.toUpperCase(), 'enLista': true },
            { 'id': 'programa', 'mostrar': ({ programa }) => `${programa.nombre}`, 'enLista': true },
            { 'id': 'porcentaje', 'mostrar': ({ porcentaje }) => porcentaje ? porcentaje: '', 'enLista': false }
          ]}
        />
      </div>
    )
  }


  pintarInicioProcesos = () => {
    let { estados_procesos, filtrarPeriodo, periodo_filtro, periodos, cargandoPeriodo } = this.state;
    let { mostrarModalFiltroBeca } = this.props
    
    const obtenerColor = (codigo) => {
      let color = 'white';
      const colores = {
        'Est_bec_env': { 'backgroundColor': coloresEmma.colorblanco, 'color': '#757575' },
        'Est_bec_rev': { 'backgroundColor': coloresEmma.solicitudactiva, color },
        'Est_bec_apr': { 'backgroundColor': coloresEmma.solicitudfinalizada, color },
        'Est_bec_apl': { 'backgroundColor': coloresEmma.solicitudfinalizada, color },
        'Est_bec_neg': { 'backgroundColor': coloresEmma.solicitudcancelada, color },
        'Est_bec_can': { 'backgroundColor': coloresEmma.solicitudcancelada, color},
        'Est_bec_Apr_th': { 'backgroundColor': '#bdbdbd', color },
        'Est_bec_Apr_Rev': { 'backgroundColor': '#bdbdbd', color },
        'Tod_Bec': { 'backgroundColor': coloresEmma.filtrotodos, color }
    }
      return (colores[codigo])
    }

    const handleClick = (codigo) => {
      if (codigo != 'Tod_Bec') this.setState({ accionProceso: codigo })
      else {mostrarModalFiltroBeca(true); 
      }
    };

    return (
      <>
        <div style={{ padding: '2% 2% 0% 2%' }} >
          <Grid container direction={'row'} spacing={1} justify={'center'} >
            <Grid item xs={12} sm={12} md={12} lg={10} >
              <Grid container direction={'row'} alignItems="center" spacing={1} style={{ width: "100%" }}>
                <Grid item xs={6} sm={6} md={6} lg={6} >
                  <TituloAccion principal='' alterno='Seleccione el estado a gestionar: ' />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} >
                  <Tooltip title='Filtrar' aria-label="add" onClick={() => this.ModalfiltroPeriodo()} >
                    <IconButton edge={false} color="secondary" style={{ float: 'right' }}><FilterListIcon /></IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              <Paper elevation={0} square className="scroll contenido_fijo">
                { (estados_procesos.length === 0) ?
                <TareasTerminadas mensaje='Aún no hay solicitudes para este periodo.' marginTop='7%' imagen={emma_s1} widthImg="7%" /> :
                <Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
                  {estados_procesos.map(({ estado_actual__codigo: codigo, estado_actual__nombre: nombre, num_sol}, i) => {
                    return (
                      <Grid item xs={12} sm={12} md={6} lg={6} key={i}>
                        <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => handleClick(codigo)}>
                          <AccionesRapidas imagen='' titulo={`¡ ${nombre} !`} sub_titulo='' des_sub_titulo='' avatar={obtenerColor(codigo)} avatar_name= {nombre.charAt(0).toUpperCase()} list_desc={Number.isInteger(num_sol) ? [`Numero de Solicitudes : ${num_sol}`] :  ['Debe aplicar los filtros.']}/>
                        </Paper>
                      </Grid>
                    )
                  })}
                </Grid>
                }
              </Paper>
            </Grid>
          </Grid>
        </div>

        <Dialog fullWidth={true} maxWidth="xs" open={filtrarPeriodo} onClose={() => this.cambiarEstado({filtrarPeriodo : false})} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <AppBarModal titulo='¡ Aplicar Filtros !' mostrarModal={() => this.cambiarEstado({filtrarPeriodo : false})} titulo_accion="" accion="" />
          <DialogContent className='scroll'>
            {!cargandoPeriodo ?
              <Grid container spacing={3} className='margin_cont' >
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                  <FormControl className='form-control'>
                    <InputLabel htmlFor="periodo_filtro">Seleccione Periodo</InputLabel>
                    <Select
                      inputProps={{
                        name: 'periodo_filtro',
                        id: 'periodo_filtro',
                      }}
                      value={periodo_filtro}
                      onChange={this.onChange}
                      required={true}>
                      {periodos.map(({ periodo }, i) => <MenuItem key={i} value={periodo}>{periodo}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              : <TareasTerminadas imagen={emma_w} mensaje='Cargando...' widthImg='5%' marginTop='5%' />
            }
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => this.cambiarEstado({filtrarPeriodo : false})} />
            <BtnForm texto="FILTRAR" callback={() => this.filtrarEstadoSolicitudes()} />
          </DialogActions>
        </Dialog >
      </>
    )
  }

  ModalfiltroPeriodo = async () => {
    let { cargarFiltroP, periodo } = this.state
    this.cambiarEstado({ filtrarPeriodo: true })
    if (cargarFiltroP) {
      this.cambiarEstado({ cargandoPeriodo: true })
      let periodos = await this.obtenerPeriodos();
      if(!(periodos.find(e => e.periodo == periodo))) periodos.push({'periodo': periodo})
      this.cambiarEstado({ periodos, cargandoPeriodo: false, cargarFiltroP: false })
    }
  }

  obtenerPeriodos = () => {
    return new Promise(resolve => {
      consulta(`api/v1.0/becas/periodos`, null, null,
        (error, estado, resp) => {
          resolve(resp)
        }
      );
    })
  }

  obtenerDatoperiodo =() => {
    let {periodos} = this.state
    let data_periodo
    for (data_periodo of periodos){
      console.log(data_periodo)
    }
    this.setState({data_periodo})
  }

  filtrarEstadoSolicitudes = () => {
    let { periodo_filtro } = this.state
    this.cambiarEstado({ filtrarPeriodo: false, cargando: true })
    this.obtenerEstadoSolicitudes(periodo_filtro)
  }

  obtenerEstadoSolicitudes = (periodo) => {
    consulta(`api/v1.0/becas/estados/${periodo}`, null, null, (error, estado, resp) => {
      if (estado === 200) {
        let { general, extra } = resp
        if (extra.length > 0) {
          extra.map(({ solicitudes, nombre, codigo, orden }) => {
            let reporte = {
              'estado_actual__codigo': codigo,
              'estado_actual__nombre': nombre,
              'num_sol': solicitudes,
              'orden': orden
            }
            general.push(reporte);
          })
        }
        this.cambiarEstado({ estados_procesos: general.sort((a, b) => a.orden - b.orden ), cargando: false });
      }
    })
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }


  onChangeIdentificacion = async (e) => {// evento que escucha los cambios del formulario y asigna el valor al estado de la variable
    this.setState({cargando:true})
    let identificacion = e.currentTarget.dataset
    console.log(identificacion)
    this.onChange(e)
    let datosestudiantes= await this.obtenerDatosestudiante(identificacion);
    this.setState({
      "datosestudiantes":datosestudiantes,
      
      cargando:false
    }) 

    
  }


  renderProcesos() {
    let { accionProceso } = this.state;
    return (
      <>
        {accionProceso === 'inicio' ? this.pintarInicioProcesos() : this.pintarProcesos()}
        {accionProceso !== 'inicio' && <div className={'regresar'} ><BtnRegresar callback={() => this.setState({ filtros_beca: [], accionProceso: 'inicio' })} /></div>}
      </>
    )
  }


  pintarHabilitar(){
    let {mostrarModalHabilitarestudiante} = this.props
    return (
      <>
        <Grid container direction={'row'} justifyContent={'center'} style={{ paddingTop: 40 }}>
          <Grid item xs={12} sm={6} md={4} lg={4} style={{ padding: '2% 2% 0% 2%' }} onClick={() => {mostrarModalHabilitarestudiante(true)}}>
            <Paper elevation={8} square>
              <Card style={{ maxWidth: "100%", height: '380px', cursor: 'pointer' }} elevation={1}>
                <CardHeader
                  avatar={<Avatar aria-label="recipe" style={{ backgroundColor: red[500] }}>H</Avatar>}
                  title={`Habilitar nuevo estudiante `}
                />
                <CardContent >
                  <Grid container direction={'row'} justifyContent={'center'}>
                    <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center', height: "150px" }}>
                      <img src={tap} style={{ width: '150px', maxWidth: '200px', minWidth: '100px', height: "150px" }} alt='Imagen Tipo' />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} style={{ padding: "15px 10px 0px 10px", }}>
                      <Typography variant="body2" color="textSecondary" component="p" align='justify'>
                        Para habilitar una nueva estudiante Dar click aqui!
                      </Typography>
                    </Grid>
                  </Grid>

                </CardContent>
              </Card>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }

  reiniciarEstado() {
    this.setState({
      activeStep: 0, 
      cargando: false, 
      titulo_carg : 'Cargando...', 
      identificacion: '',
      datosestudiantes:[],
    })
  }

  onSubmit = (e) => {
    let {identificacion} = this.state;
    let { mostrarModalHabilitarestudiante } = this.props;
    console.log(identificacion)
    let {actualizarMensaje} = this.props
    this.setState({ cargando: true, titulo_carg: 'Enviando...' })
    if(identificacion == "") {
      actualizarMensaje({ titulo: 'Los campos deben ser Diligenciados', tipo: 'info', mostrar: true, tiempo: 4000 });
    }
    else {
      this.setState({ identificacion})
      let data = {
        identificacion:identificacion
      }
      this.guardarHabilitar(data)
      this.reiniciarEstado()
      mostrarModalHabilitarestudiante(false)
    }
  }

  guardarHabilitar = async (data) => { 
    let { actualizarMensaje } = this.props;
    return new Promise(resolve => {
      consulta(`api/v1.0/becas/consultaPersona`, data, "post", (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) { 
            tipo = 'success';
            titulo = resp.titulo;
            this.setState({ seleccion: 'proceso' });

          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
        resolve(estado === 200 && !error ? resp : null);
        
      })
    })
  }



  configFiltros = (accion_actual) => {
    let { periodo_filtro } = this.state
    switch (accion_actual) {
      case 'Tod_Bec':
        return [{ 'llave': 'periodo', 'valor': periodo_filtro }]
      case 'inicio':
        return [{ 'llave': 'periodo', 'valor': periodo_filtro }]
      default:
        return [{ 'llave': 'estado_actual__codigo', 'valor': accion_actual }, { 'llave': 'periodo', 'valor': periodo_filtro }]
    }
  }

  handleStep = async (direction) => {//Con esta funcion Valido si se llenaron los campos
    let { activeStep,identificacion,datosestudiantes, cargando} = this.state
    let sw = true
    let {actualizarMensaje} =this.props
    if(direction){
      if(!identificacion && activeStep === 0){
        actualizarMensaje({titulo: 'Debe diligenciar todos los campos para continuar', tipo: 'info', mostrar: true, tiempo:6000});
        sw=false
      }else{
        this.setState({cargando:true});
        let datosestudiantes = await this.obtenerDatosestudiante(identificacion);
        this.setState({
        datosestudiantes:datosestudiantes,
        
        cargando:false
        }) 
        console.log(datosestudiantes)
        if(datosestudiantes.respuesta.length <= 0){
          actualizarMensaje({ titulo: 'Identificacion no se encuentra registrada en sicuc', tipo: 'info', mostrar: true, tiempo: 6000 });
          sw = false
        }else{
          this.setState({ activeStep: direction ? activeStep + 1 : activeStep - 1 })
        }
       
      }
       
      
    }
    if(sw) this.setState({ activeStep: direction ? activeStep + 1 : activeStep - 1 })
  }

  configStepContent = () => {
    let {identificacion, activeStep,datosestudiantes} = this.state
    switch(activeStep){
      case 0:
        return (
          <ConsultarEstudiante
          {...{
            identificacion,
            onChange: this.onChange,
            onChangeIdentificacion:this.onChangeIdentificacion,
          }}
          />
        )
        case 1:
          return(
            <DatosEstudiante
            {...{
              datosestudiantes,
            }}/>
          )
        
        default:
          return <TareasTerminadas imagen={emma_w} mensaje='Est bandeja esta  vacía' widthImg='10%' marginTop='10%'/>
    }
  }

  render() {
    let { accion, cargando, beca, administrador, vistaDetalle, id_estudiante, accionProceso, activeStep} = this.state;
    let { modalDetBeca, mostrarModalDetalleBeca, actualizarMensaje, modalAddComment, mostrarModalComment, modalFiltro, mostrarModalFiltroBeca, modalHabiliEstu, mostrarModalHabilitarestudiante } = this.props;
    return (
      <>
        <div>
          <MenuBecas
            seleccionar={(accion) => this.cambiarEstado({ accion })}
            sele={accion}
            administrador={administrador} />
          <div className='contenido_modulo'>
            <BecaFiltros
              actualizarMensaje={actualizarMensaje}
              modalFiltro={modalFiltro}
              mostrarModalFiltroBeca={mostrarModalFiltroBeca}
              filtrar={(filtros) => this.actualizarDataBecas(this.configFiltros(accionProceso).concat(filtros))}
              limpiar={() => this.actualizarDataBecas(this.configFiltros(accionProceso))}
              accionProceso={accionProceso}
              mostrarTodos={(accion) => this.setState({ accionProceso: accion })}
            />

            <BecaDetalle
              beca={beca ? beca.id : 0}
              beca_codigo={beca ? beca.estado_actual.codigo : ''}
              id_estudiante={id_estudiante}
              mostrarModalDetalleBeca={mostrarModalDetalleBeca}
              modalDetBeca={modalDetBeca}
              vistaDetalle={vistaDetalle}
              mostrarModalComment={mostrarModalComment}
              modalAddComment={modalAddComment}
              actualizarMensaje={actualizarMensaje}
              admin={true}
            />
            {
              !cargando ?
                <div>
                  {accion === 'Procesos' && this.renderProcesos()}
                  {accion === 'habilitar' && this.pintarHabilitar()}
                  {accion === 'Permisos' && <BecasPermisos />}
                </div>
                : <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
            }
          </div>
        </div>

        <div>

          <Dialog fullWidth={true} maxWidth="sm" open={modalHabiliEstu} onClose={() => mostrarModalHabilitarestudiante(false)}>
            <AppBarModal titulo={"¡Habilitar estudiante !"} mostrarModal={() => mostrarModalHabilitarestudiante(false)} titulo_accion="Cerrar" accion={() => mostrarModalHabilitarestudiante(false)} />
            <DialogContent className='scroll'>
              {!cargando ?
                <Grid container>
                  <Grid item xs={12} md={12} >
                    <Stepper activeStep={activeStep}>
                      {
                        configStep.map(label => (
                          <Step key={label}>
                            <StepLabel><p>{label}</p></StepLabel>
                          </Step>
                        ))
                      }
                    </Stepper>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {this.configStepContent()}
                  </Grid>
                </Grid>
                : <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
              }

            </DialogContent>
            <DialogActions>
              {activeStep > 0 ?
                <div><BtnFormPage texto='ATRAS' callback={() => this.handleStep(false)} />
                  <BtnFormPage texto='GUARDAR' callback={this.onSubmit} type="submit" />
                </div>
                : <BtnFormPage texto='SIGUIENTE' callback={() => this.handleStep(true)} />
              }
            </DialogActions>

          </Dialog >
        </div>
        <div>
        </div>

      </>
    )
  }
}

const mapStateToProps = (state) => {
  let { modalAddComment, modalDetBeca, modalGestionBeca, modalVolante, modalFiltro, modalHabiliEstu } = state.redBecas
  let { usuario } = state.redGlobal;
  return {
    modalAddComment,
    modalDetBeca,
    modalGestionBeca,
    modalVolante,
    modalFiltro,
    modalHabiliEstu,
    usuario
  }
}

const mapDispatchToProps = {
  mostrarModulo,
  mostrarModalComment,
  mostrarModalDetalleBeca,
  mostrarGestionBeca,
  actualizarMensaje,
  mostrarModalVolante,
  mostrarModalFiltroBeca,
  mostrarModalHabilitarestudiante
}

BecasAdministrar.propTypes = {
  //funciones
  mostrarModulo: PropTypes.func.isRequired,
  mostrarModalComment: PropTypes.func.isRequired,
  mostrarModalDetalleBeca: PropTypes.func.isRequired,
  mostrarGestionBeca: PropTypes.func.isRequired,
  mostrarModalVolante: PropTypes.func.isRequired,
  actualizarMensaje: PropTypes.func.isRequired,
  mostrarModalHabilitarestudiante: PropTypes.func.isRequired,
  //variables
  modalAddComment: PropTypes.bool.isRequired,
  modalDetBeca: PropTypes.bool.isRequired,
  modalGestionBeca: PropTypes.bool.isRequired,
  modalVolante: PropTypes.bool.isRequired,
  modalFiltro: PropTypes.bool.isRequired,
  modalHabiliEstu: PropTypes.bool.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(BecasAdministrar);
