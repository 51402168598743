import React from 'react';
import PropTypes from 'prop-types';
import ListarDatos from '../../general/ListarDatos';
import { BtnDetalle, BtnEditar, BtnEliminar, BtnSeleccion } from '../../general/BotonesAccion';

export default function ConvocatoriasListar(props) {
  let { 
    mostrarModalAddConvocatorias, 
    convocatorias, 
    actualizarDataConvocatoria, 
    mostrarModalModConvocatorias, 
    mostrarModalDelConvocatorias, 
    mostrarModalDetConvocatorias, 
    formOpciones, 
    opciones, 
    callback, 
    obtenerArchivos,
    mostrarModalSelConvocatoria
  } = props;

  const acciones = (data) => {
    const onClickDetalle = (data) => {
      actualizarDataConvocatoria(data);
      obtenerArchivos(data.id);
      mostrarModalDetConvocatorias(true);
    }
    const onClickEliminar = (data) => {
      actualizarDataConvocatoria(data);
      mostrarModalDelConvocatorias(true);
    }
    const onClickEditar = (data) => {
      actualizarDataConvocatoria(data);
      mostrarModalModConvocatorias(true);
    }
    const onClickSeleccionar = (data) => {
      actualizarDataConvocatoria(data);
      mostrarModalSelConvocatoria(true)
      callback(data);
    }
    let detalle = <BtnDetalle callback = { () => onClickDetalle(data) } /> 
    let eliminar = <BtnEliminar callback = { () => onClickEliminar(data) } />
    let editar = <BtnEditar callback = { () => onClickEditar(data) } />
    let seleccionar = <BtnSeleccion callback = { () => onClickSeleccionar(data) } />
    return (
      <div>
        { detalle }
        { !formOpciones && eliminar }
        { !formOpciones && editar }
        { formOpciones && seleccionar } {/*Botones de seleccion en form */}
      </div>
    );
  }

  return(
    <div>
      <ListarDatos
        datos = { convocatorias }
        titulo = 'Lista de Convocatorias'
        buscar = { true }
        opciones = { opciones }
        agregar = { formOpciones ? false : true }
        actAgregar = { () => mostrarModalAddConvocatorias(true) }
        sub_titulo = 'Convocatorias'
        id = 'tbl_convocatorias'
        acciones = { row => acciones(row) }
        fila_principal = { ({ nombre_convocatoria }) => nombre_convocatoria.toUpperCase() }
        filas = { [
          { 'id': 'fecha_actividad', 'enLista': true, 'mostrar': ({ fecha_inicio, fecha_final }) => `${ fecha_inicio } | ${ fecha_final }` },
          { 'id': 'tipo_movilidad', 'enLista': formOpciones ? false : true , 'mostrar': ({ tipo_mov_estudiantil }) => tipo_mov_estudiantil.nombre },
          { 'id': 'modalidad', 'enLista': true, 'mostrar': ({ modalidad }) => modalidad.nombre },
        ] }
      />
    </div>
  );
}

//Props del componente
ConvocatoriasListar.propTypes = {
  //variables
  convocatorias: PropTypes.array.isRequired,
  formOpciones: PropTypes.bool, // True para mostrar la opción de seleccionar, y quitar la de modificar y eliminar.
  opciones: PropTypes.bool,
  //funciones
  actualizarDataConvocatoria: PropTypes.func.isRequired,
  callback: PropTypes.func,
}

ConvocatoriasListar.defaultProps = {
  opciones: true,
  formOpciones: false,
}
