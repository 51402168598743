import { Grid, Link, List, ListItem, ListItemText, Typography, makeStyles } from '@material-ui/core'
import React from 'react'
import { useState } from 'react'
import EnviarArchivo from '../../general/EnviarArchivo'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles(theme => ({
  avatar: {
    color: 'white',
    fontSize: '10px',
    backgroundColor: '#C79E32',
    width: '40px',
    height: '40px'
  },
  image: {
    width: '150px',
    maxWidth: '150px',
    minWidth: '150px',
    height: "150px",
  },
  content_img: {
    textAlign: 'center',
    height: "150px",
  },
}));

export const CargaMasiva = (props) => {

  const classes = useStyles();

  return (
    <>
      <Grid container>
        <Grid style={{ padding: "2% 2% 0% 2%" }} item xs={12} md={12}>
          <EnviarArchivo
            modalAdd={props.modalCargarMasivo}
            mostrarModalAdd={() => props.cerrarModal()}
            enviarArchivo={(file) => props.enviarArchivo(file)}
            titulo={props.titulo}
            formatos='.xlsx'
            mensaje={
              <Indicaciones classes={classes} archivo={props.archivo} />
            }
          />
        </Grid>
      </Grid>
    </>
  )
}

const Indicaciones = ({ classes, archivo }) => {
  return (
    <>
      <Grid >
        <Typography variant="body2" component="p">Tenga en cuenta las siguientes indicaciones: </Typography>
        <List dense={true}>
          <ListItem>
            <ListItemText
              primary={
                <Link
                  href={archivo}
                  target="_blank"
                  style={{ textDecoration: 'none', fontSize: '14px', color: '#C79E32' }}
                >
                  Descargue esta plantilla y anexe los datos.
                </Link>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <span style={{ fontSize: '14px' }}>
                  No realice cambios en el formato de la plantilla.
                </span>
              }
            />
          </ListItem>
        </List>
      </Grid>
    </>
  )
}