import React, { Component } from 'react';
import {consulta, crear_form_data,formulario,mostrarError } from "../../../global/js/funciones";
import {Paper, Grid, Typography, Button } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import dinero_rojo from '../../../global/imagenes/asignatura4.png';
import { connect } from "react-redux";
import { actualizarMensaje, mostrarModulo } from "../../../redux/actions/actGlobal";
import { mostrarAgregarSisef } from "../../../redux/actions/actSisef";
import SisefOpcionesTipo from "./SisefOpcionesTipo";
import SisefAgregar from "./SisefAgregar";


class SisefNewSolicitud extends Component {

  constructor(props) {
    super(props)
    this.state = {
      seleccion: 'inicio',
      titulo: 'inicio',
      categoria: '',
      tipo: '',
      cargando: true,
      mensaje_icetex: '',
      mensaje_otras: '',
      mensajes_nuevo: '',
      mensajes_renovacion: '',
      requisitos: [],
      credito_para:[],
      datos_sesion: [],
      telefono_contacto:'',
      direccion_per:'',
      correo_personal:'',
      lugar_expedicion:'',
      estado_civil:'',
      lugar_residencia:'',
      programas: [],
      Ocupacion:[],
      asignaturas:[],
    }
  }
  
  async componentDidMount() {
    this.DatosIniciales()
    this.setState({cargando:false});
  }

  async componentDidUpdate({ }, { seleccion }) {
    let { seleccion: seleccionNew } = this.state;
    if (seleccionNew !== seleccion) {
    }
  }

  async DatosIniciales(){
    let asignaturas = await this.obtenerAsignaturas();
    this.setState({asignaturas:asignaturas});
  }

  async obtenerAsignaturas() {
    let {periodo_actual}=this.props
    return new Promise(resolve => {
      consulta(`api/v1.0/sisef/docente_asignaturas/${0}/${periodo_actual}/${0}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  guardarSisef = async (data, callback) => {
    let { mostrarAgregarSisef, actualizarMensaje, Seleccion} = this.props;
    let form = await crear_form_data(data);
    formulario(`api/v1.0/sisef/crear`, form, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          mostrarAgregarSisef(false);
          Seleccion("miproceso")
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      callback()
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    })
  }

  renderInicio() {
    let { setMenu,tipoSolicitud, mostrarAgregarSisef, Seleccion } = this.props;
    return (
      <div className=''>
        {/* <Button onClick={() => Seleccion('inicio')} style={{ padding: "5px 5px 0px 3px", marginTop: '1%' }} color="secondary" > <ArrowBackIcon /></Button> */}
        <div>
          <Grid container direction={'row'} justifyContent={'center'} style={{ paddingTop: 30 }} >
            <Grid item xs={12} sm={12} md={10} lg={8}>
              <Grid container direction={'row'} justify={'center'}>
                { tipoSolicitud.codigo === 'sol_syll' &&
                  <Grid item xs={12} sm={10} md={6} lg={5} style={{ padding: '1% 2% 0% 2%' }} onClick={() => { mostrarAgregarSisef(true) }}>
                    <Paper elevation={8} square>
                      <SisefOpcionesTipo avatarcss={{ background: '#01579b' }} cardcss={{ maxWidth: "100%", height: '420px', cursor: 'pointer' }} image={dinero_rojo} data={{ opciones: `Nuevo plan`, nombre: 'PLAN DE ASIGNATURA'}} />
                    </Paper>
                  </Grid>
                }
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }

  render() {
    let { seleccion, tipo, asignaturas} = this.state;
    let {mostrarAgregarSisef, modalAgregarSisef, periodo_actual} = this.props;
    return (
        <div>
            {(seleccion === 'inicio' && !tipo) && this.renderInicio()}
          <SisefAgregar
            guardarSisef={this.guardarSisef} 
            mostrarAgregarSisef={mostrarAgregarSisef} 
            modalAgregarSisef={modalAgregarSisef} 
            actualizarMensaje={actualizarMensaje}
            asignaturas={asignaturas}
            periodo_actual={periodo_actual}
          />
        </div>
    )
  }
}

//CARGO ESTADOS DEL MODULO
const mapStateToProps = (state) => {
  let { modalAgregarSisef} = state.redSisef;
  return {
    modalAgregarSisef,
  }
};

//CARGO FUNCIONES DEL MODULO
const mapDispatchToProps = {
  actualizarMensaje,
  mostrarModulo,
  mostrarAgregarSisef
}

export default connect(mapStateToProps, mapDispatchToProps)(SisefNewSolicitud);
