import React, { Component } from 'react';
import { Hidden, BottomNavigation, BottomNavigationAction, Tab, Tabs } from '@material-ui/core'
import IconPerson from '@material-ui/icons/Person'
import EditIcon from '@material-ui/icons/Edit';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ChartIcon from '@material-ui/icons/InsertChart';
import Work from '@material-ui/icons/Work';
import DescriptionIcon from '@material-ui/icons/Description';
import DocInformativos from '../DocInformativos';

const MenuAdm = ({ sele, seleccionar, doc_informativos }) => {
    const clase = (item, selec) => item === selec ? 'item_selec' : 'item_modulo';

    const pintar = (app, classN = '') => {
        if (!app) {
            return (
                <div className={classN}>
                    <BottomNavigation showLabels className={clase('vacantes', sele)} onClick={() => seleccionar('vacantes')}>
                        <BottomNavigationAction label={'Vacantes'} icon={<EditIcon />} />
                    </BottomNavigation>
                    <BottomNavigation showLabels className={clase('personas', sele)} onClick={() => seleccionar('personas')}>
                        <BottomNavigationAction label={'Personas'} icon={<IconPerson />} />
                    </BottomNavigation>
                    <BottomNavigation showLabels className={clase('pqrs', sele)} onClick={() => seleccionar('pqrs')}>
                        <BottomNavigationAction label={'PQRS'} icon={<HelpOutlineIcon />} />
                    </BottomNavigation>
                    <BottomNavigation showLabels className={clase('estadistica', sele)} onClick={() => seleccionar('estadistica')}>
                        <BottomNavigationAction label={'Estadisticas'} icon={<ChartIcon />} />
                    </BottomNavigation>
                    <BottomNavigation showLabels className={clase('CV', sele)} onClick={() => seleccionar('CV')}>
                        <BottomNavigationAction label={'CV'} icon={<Work />} />
                    </BottomNavigation>
                    <DocInformativos
                        Documentos={doc_informativos}
                        rendering={(click) => <BottomNavigation showLabels onClick={click} className={clase('documentos', sele)} ><BottomNavigationAction label={'Documentos'} icon={<DescriptionIcon />} /> </BottomNavigation>}
                    />
                </div>
            );
        } else {
            return (
                <div className={classN}>
                    <Tabs value={sele} variant='fullwidth' scrollButtons="off" >
                        <Tab label='Vacantes' icon={<EditIcon className='menu_icono' />} className={clase('vacantes', sele, '_app')} value='vacantes' onClick={() => seleccionar('vacantes')} />

                        <Tab label='Personas' icon={<IconPerson className='menu_icono' />} className={clase('personas', sele, '_app')} value='personas' onClick={() => seleccionar('personas')} />

                        <Tab label='PQRS' icon={<HelpOutlineIcon className='menu_icono' />} className={clase('pqrs', sele, '_app')} value='pqrs' onClick={() => seleccionar('pqrs')} />

                        <Tab label='Estadisticas' icon={<ChartIcon className='menu_icono' />} className={clase('estadistica', sele, '_app')} value='estadistica' onClick={() => seleccionar('estadistica')} />

                        <Tab label='CV' icon={<Work className='menu_icono' />} className={clase('CV', sele, '_app')} value='CV' onClick={() => seleccionar('CV')} />

                        <DocInformativos
                            Documentos={doc_informativos}
                            rendering = { (click) => <Tab label='Documentos' icon={<DescriptionIcon className='menu_icono' />} className={clase('documentos', sele, '_app')} value='documentos' onClick = { click } /> }
                        />
                    </Tabs>
                </div>
            )
        }
    }

    return (
        <div>
            <div>
                <Hidden xsDown> {pintar(false, 'menu_modulo')} </Hidden>
                <Hidden smUp> {pintar(true, 'menu_modulo_app')}</Hidden>
            </div>
        </div>
    );
};

export default MenuAdm;