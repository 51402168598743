import React from 'react';
import { Typography } from '@material-ui/core';
import useWindowSize from '../../../general/hooks/useWindowSize';


const Banner = ({nombre, programa}) => {
    const windowSize = useWindowSize();

    return (
        <div style={{
            margin: '15px 0px',
            display: 'flex',
            flexDirection: 'column',
            padding: '30px 20px',
            backgroundColor: '#eaefef',
            borderRadius: '10px',
            width: windowSize < 600 ? 'auto' : '100%'
        }}>
            <Typography variant="h4" gutterBottom>Hola, {nombre} !</Typography>
            <span>Bienvenido al módulo de retos, aquí podrás hacer la gestión de todas las solicitudes del proceso retos.</span>
        </div>
    )
}

export default Banner