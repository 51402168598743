import React, { useState, Fragment }  from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import AppBarModal from '../../general/AppBarModal'
import TareasTerminadas from '../../general/TareasTerminadas';
import emma_s from '../../../global/imagenes/emma_s1.png';
import emma_w from '../../../global/imagenes/emma_w.png'; 
import * as Funciones from '../../../global/js/funciones';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { Grid, Link, Paper, Button, Typography, Card, CardHeader, CardContent, Avatar, Grow, Slide, createMuiTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import * as Helper from '../Hijos/Helper';
import { obtenerValores, obtenerValoresFiltros, validarCorreo, obtenerPermisosValor, formulario, mostrarError, crear_form_data } from "../../../global/js/funciones";
import moment from 'moment'
import TextField from '@material-ui/core/TextField';
import { DateTimePicker, MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import BuscarSelect from '../../general/BuscarSelect';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SemilleroAddParti from '../Hijos/SemilleroAddParti';
import { BtnEliminar } from "../../general/BotonesAccion";
import LinearProgress from '@material-ui/core/LinearProgress';
import ListarDatos from '../../general/ListarDatos';
import { useDispatch } from 'react-redux';
import { actualizarMensaje } from '../../../redux/actions/actGlobal';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { coloresEmma } from '../../../global/js/funciones';
const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    colorWhite : {
        color: '#fff'
    },
    flexGrow: {
        flexGrow: 1,
    },
    flexCenter:{
        textAlign: "center",
        marginTop: "5px"
    },
    formControl: {
      margin: "0",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    colorEmmma: {
      color: coloresEmma.secondarycolor,
      transition: 'all 0.3s',
      '&:hover': {
        color: "#fff",
        backgroundColor:coloresEmma.secondarycolor,
        transform: "scale(1.3)",
      },
    },
    colorUsers: {
      color: "#20da87",
      transition: 'all 0.3s',
      '&:hover': {
        color: "#fff",
        backgroundColor: "#20da87",
        transform: "scale(1.3)",
      },
    },
    colorDelete: {
      color: "#d11a2a",
      transition: "all 0.3s",
      '&:hover': {
        color: "#fff",
        backgroundColor: "#d11a2a",
        transform: "scale(1.3)",
      },
    },
    colorEdit: {
      color: "#0074C5",
      transition: "all 0.3s",
      '&:hover': {
        color: "#fff",
        backgroundColor: "#0074C5",
        transform: "scale(1.3)",
      },
    },
    colorCheck: {
      color: "#2ECC71",
      transition: "all 0.3s",
      '&:hover': {
        color: "#fff",
        backgroundColor: "#2ECC71",
        transform: "scale(1.2)",
      },
    },
    escalar: {
      transition: "all 0.3s",
      border: '1px solid #e0e0e0',
      borderRadius: '5px',
      [theme.breakpoints.up('sm')]: {
          '&:hover': {
              transform: "scale(1.05)",
              border: `1px solid ${coloresEmma.secondarycolor}`,
              borderRadius: '0',
          },
          
      },
    },
    color:{
      transition: 'all 0.3s',
      borderRadius: "20px",
      '&:hover': {
          transform: "scale(1.01)",
          borderRadius: "0px",
          
      },
    },
    center:{
      textAlign: 'center'
  },
  modalMinWith: {
    [theme.breakpoints.up('sm')]: {
        minWidth:'500px'
    },
},
  }));

const SemillerosGestionEditTecTecno = (props) =>{
    const classes = useStyles()
    //Aquí declaro las variables para que exsta persistencia y hago las funciones de validate data y handleSendToApi
    const [cargandoModal, setCargandoModal] = React.useState(true)
    const isInitialMount = React.useRef(true);
    const [activeStep, setActiveStep] = React.useState(0);
    const [steps, setSteps] = React.useState(['Información del General', 'Autores'])
    const [tipo, setTipo] = React.useState('form')
    const [data, setData] = React.useState([]) //Inputs de fecha y los demas de text
    const [dataInputsExtra, setDataInputsExtra] = React.useState([]) //Inputs de producto tiene
    //ActiveStep para produccion bibliografica y produccion tecnica
    const [activeStepBiblio, setActiveStepBiblio] = React.useState(0);
    //Variables para fechas y selects
    const [fechaCreacionDiseñoIndus, setFechaCreacionDiseñoIndus] = React.useState(new Date()); //Fecha de creacion de diseño industrial
    const [fechaCreacionPrototipo, setFechaCreacionPrototipo] = React.useState(new Date()); //Fecha de creacion de prototipo
    const [fechaCreacionSignoDistintivo, setFechaCreacionSignoDistintivo] = React.useState(new Date()); //Fecha de creacion de signo distintivo
    const [fechaDesarrolloSoftware, setFechaDesarrolloSoftware] = React.useState(new Date())//Fecah de desarrollo de software
    const [fechaObtencionRegistroSoftware, setFechaObtencionRegistroSoftware] = React.useState(new Date())//Fecah de obtencion de software
    const [fechaRegistroBaseTecno, setFechaRegistroBaseTecno] = React.useState(new Date())//Fecha de registro base tecnologica de software
    const [fechaInnovacionProceServi, setFechaInnovacionProceServi] = React.useState(new Date())//Fecha de Fecha de la Innovación Procedimientos y Servicios
    const [fechaInnovacionGestionEmp, setFechaInnovacionGestionEmp] = React.useState(new Date())//Fecha de la Innovación Gestión Emp.
    const [fechaInicioEventoArtistico, setFechaInicioEventoArtistico] = React.useState(new Date())//Fecha de inicio o fin de evento artistico
    const [fechaFinalEventoArtistico, setFechaFinalEventoArtistico] = React.useState(new Date())//Fecha de inicio o fin de evento artistico
    const [fechaPresentacionObraProducto, setFechaPresentacionObraProducto] = React.useState(new Date())//Fecha de creacion o presentacion de obra producto
    const [fechaCreacionObraProducto, setFechaCreacionObraProducto] = React.useState(new Date())//Fecha de creacion o presentacion de obra producto
    const [fechaInicioTalleresCreacion, setFechaInicioTalleresCreacion] = React.useState(new Date())//Fecha de inicio y fin de talleres de creacion
    const [fechaFinalTalleresCreacion, setFechaFinalTalleresCreacion] = React.useState(new Date())//Fecha de inicio y fin de talleres de creacion
    const [fechaRegistroEmpresaCultural, setFechaRegistroEmpresaCultural] = React.useState(new Date())

    const [tiposPrototipoTiene, setTiposPrototipoTiene] = React.useState([]) //Select de tipos tiene
    const [productoTieneSelected, setProductoTieneSelected] = React.useState('') //Producto tiene seleccionado del select


    const [tiposDePrototipos, setTiposDePrototipos] = React.useState([]) //Select de tipos de prototipos
    const [tipoPrototipoSelected, setTipoPrototipoSelected] = React.useState('') //Tipo de prototipo seleccionado del select

    const [tiposSignoDistintivos, setTiposSignoDistintivos] = React.useState([])//Select de tipos de signo distintivo 
    const [tipoSignoDistintivoSelected, setTipoSignoDistintivoSelected] = React.useState("")//Tipo de signo distintivo seleccionado del select

    const [tiposDeSoftware, setTiposDeSoftware] = React.useState([])//Select de tipos de software 
    const [tipoSoftwareSelected, setTipoSoftwareSelected] = React.useState("")//Tipo de software seleccionado del select

    const [tiposEmpresas, setTiposEmpresas] = React.useState([])//Select de tipos de empresas
    const [tipoEmpresaSelected, setTipoEmpresaSelected] = React.useState("")//Tipo de empresa seleccionado del select
    
    const [tiposInnovaciones, setTiposInnovaciones] = React.useState([])//Select de tipos de Innovaciones
    const [tipoInnovacionSelected, setTipoInnovacionSelected] = React.useState("")//Tipo de Innovacion seleccionado del Select
    
    const [tiposRoles, setTiposRoles] = React.useState([])//Select de tipos de Roles
    const [tipoRolSelected, setTipoRolSelected]= React.useState("")//Tipo de rol seleccionado del select de roles
    
    const [tiposAmbitos, setTiposAmbitos] = React.useState([])//Select de ambitos
    const [tipoAmbitoSelected, setTipoAmbitoSelected]= React.useState("")//Tipo de ambito seleccionado del select de ambitos
    
    const [tiposEspacioEvento, setTiposEspacioEvento] = React.useState([])//Select de espacio evento
    const [tipoEspacioEventoSelected, setTipoEspacioEventoSelected] = React.useState("")//Espacio seleccionado del select espacio evento
    
    const [tiposDisciplinaAmbito, setTiposDisciplinaAmbito] = React.useState([])//Select de tipos disciplina ambito
    const [tipoDisciplinaAmbitoSelected, setTipoDisciplinaAmbitoSelected] = React.useState("")//Disciplina seleccionada del select de disciplina ambito
    
    const [tiposEventos, setTiposEventos] = React.useState([])//Select de tipos de eventos
    const [tipoEventoSelected, setTipoEventoSelected]= React.useState("")//Evento seleccionado de tipos de eventos
    //Inputs de "OTROS"
    const [otroTipoPrototipoSelected, setOtroTipoPrototipoSelected] = React.useState('') //Cuando seleccionan otro tipo de prototipo
    const [otroTipoSoftwareSelected, setOtroTipoSoftwareSelected] = React.useState("")//Cuando seleccionan otro tipo de software
    //Prototipo tiene
    const [fechaRegistroDiseñoPrototipoTiene, setFechaRegistroDiseñoPrototipoTiene] = React.useState(new Date());//Fecha de producto tiene
    //Tipo de producto Tecnologico
    const [tipoProductoTecTecno, setTipoProductoTecTecno] = React.useState('') //Tipo de producto al cual estoy editando
    //Errores
    const [errores, setErrores] = React.useState([]);
    //Array para los Autores y Participantes
    const [usuarios, setUsuarios] = React.useState([])
    const [arrai, setArrai] = React.useState([])

    //Array de los eliminados
    const [usuariosEliminados, setUsuariosEliminados] = React.useState([])
    //Estados para rol y participacion de algunos formularios para los autores o participantes
    const [rolActive, setRolActive] = React.useState(false)
    const [modalidadParticipacionActive, setModalidadParticipacionActive] = React.useState(false)

    const [paisesTecTecno, setPaisesTecTecno] = React.useState([])
    const [paisSelectedTecTecno, setPaisSelectedTecTecno] = React.useState("")
    const [otroTipoEspacioEvento, setOtroTipoEspacioEvento] = React.useState("")
    const dispatch = useDispatch();
    React.useEffect( () => {
      if(isInitialMount.current){
        isInitialMount.current = false

        const obtenerProcesos = async () => {
          let datos = await getInfoRow();
          if(datos[0].key.producto.codigo == "Inves_tipo_tec_pro_eveArt" || datos[0].key.producto.codigo == "Inves_tipo_tec_pro_talCre"){
            let users = await getInfoRowUsers();
            users = users.map((item)=>({
                id: item.participante.id,
                nombre: `${item.participante.primer_nombre} ${item.participante.primer_apellido}`,
                correo: `${item.participante.correo}`,
                identificacion: `${item.participante.identificacion}`,
                rolDeParticipante: item.rol,
                nuevo: false,
            }))



            setUsuarios(users)
            setArrai(users)
          }else{
            let users = await getInfoRowUsers();
            users = users.map(({participante})=>({
                id: participante.id,
                nombre: `${participante.primer_nombre} ${participante.primer_apellido}`,
                correo: `${participante.correo}`,
                identificacion: `${participante.identificacion}`,
                nuevo: false,
            }))

            setUsuarios(users)
            setArrai(users)
          }
          
          let datosNormales = []
          let datosProductoTiene = []
          //Obtener los valores de los Selects dependiendo al producto
          if(datos[0].key.producto.codigo == "Inves_tipo_tec_pro_diseñoIndustrial"){
            obtenerElproductoTiene()
            obtenerPaises()
          }else if(datos[0].key.producto.codigo == "Inves_tipo_tec_pro_prototipo"){
            obtenerTiposPrototipos()
            obtenerElproductoTiene()
            obtenerPaises()
          }else if(datos[0].key.producto.codigo == "Inves_tipo_tec_pro_signo_distintivo"){
            obtenerTiposSignoDistintivo()
            obtenerPaises()
          }else if(datos[0].key.producto.codigo == "Inves_tipo_tec_pro_software"){
            obtenerTiposSoftware()
            obtenerPaises()
          }else if(datos[0].key.producto.codigo == "Inves_tipo_tec_pro_empBaseTec"){
            obtenerTiposEmpresas()
          }else if(datos[0].key.producto.codigo == "Inves_tipo_tec_pro_innProProSer"){
            obtenerElproductoTiene()
            obtenerPaises()
          }else if(datos[0].key.producto.codigo == "Inves_tipo_tec_pro_empCreaCul"){
            obtenerTiposDisciplinaAmbito()
            obtenerPaises()
          }else if(datos[0].key.producto.codigo == "Inves_tipo_tec_pro_innGenGesEmp"){
            obtenerElproductoTiene()
            obtenerTiposInnovaciones()
            obtenerPaises()
          }else if(datos[0].key.producto.codigo == "Inves_tipo_tec_pro_eveArt"){
            obtenerTiposRoles()
            obtenerTiposAmbitos()
            obtenerPaises()
            setRolActive(true)
          }else if(datos[0].key.producto.codigo == "Inves_tipo_tec_pro_obrPro"){
            obtenerTiposAmbitos()
            obtenerTiposEspacioEvento()
            obtenerTiposDisciplinaAmbito()
            obtenerPaises()
          }else if(datos[0].key.producto.codigo == "Inves_tipo_tec_pro_talCre"){
            obtenerTiposEventos()
            obtenerTiposRoles()
            obtenerTiposAmbitos()
            obtenerPaises()
            setRolActive(true)
          }
          datos.forEach( async (item) => {
            //Caso en que no sea ninguno de los inputs de prototipo tiene
            if(item.nombre != "fechaRegistroDiseñoPrototipoTiene" && item.nombre != "noRegistroDiseñoPrototipoTiene" && item.nombre != "tituloRegistroDiseñoPrototipoTiene" ){
              if(item.valor == null && item.valorSelect == null){
                //Caso en que ese input sea un fecha
                if(item.nombre.includes('fecha')){
                  if(item.nombre == "fechaCreacion"){
                    let anio = parseInt(moment(item.valorFecha).format('YYYY'))
                    let mes = parseInt(moment(item.valorFecha).format('MM'))-1
                    let dia = parseInt(moment(item.valorFecha).format('DD'))+1
                    let fechaCreacion = new Date(anio, mes, dia)
                    setFechaCreacionDiseñoIndus(fechaCreacion)

                    datosNormales.push({
                        id: item.id,
                        label: item.label,
                        codigo: item.nombre,
                        value: item.valorFecha
                    })
                  }else if(item.nombre == "fechaCreacionPrototipo"){
                    let anio = parseInt(moment(item.valorFecha).format('YYYY'))
                    let mes = parseInt(moment(item.valorFecha).format('MM'))-1
                    let dia = parseInt(moment(item.valorFecha).format('DD'))+1
                    let fechaCreacionPrototipo = new Date(anio, mes, dia)
                    setFechaCreacionPrototipo(fechaCreacionPrototipo)

                    datosNormales.push({
                        id: item.id,
                        label: item.label,
                        codigo: item.nombre,
                        value: item.valorFecha
                    })
                  }else if(item.nombre == "fechaCreacionSignoDistintivo"){
                    let anio = parseInt(moment(item.valorFecha).format('YYYY'))
                    let mes = parseInt(moment(item.valorFecha).format('MM'))-1
                    let dia = parseInt(moment(item.valorFecha).format('DD'))+1
                    let fechaCreacionSignoDistintivo = new Date(anio, mes, dia)
                    setFechaCreacionSignoDistintivo(fechaCreacionSignoDistintivo)

                    datosNormales.push({
                        id: item.id,
                        label: item.label,
                        codigo: item.nombre,
                        value: item.valorFecha
                    })
                  }else if(item.nombre == "fechaDesarrolloSoftware"){
                    let anio = parseInt(moment(item.valorFecha).format('YYYY'))
                    let mes = parseInt(moment(item.valorFecha).format('MM'))-1
                    let dia = parseInt(moment(item.valorFecha).format('DD'))+1
                    let fechaDesarrolloSoftware = new Date(anio, mes, dia)
                    //fechaDesarrolloSoftware.setFullYear(moment(item.valorFecha).format('YYYY'), moment(item.valorFecha).format('MM'), moment(item.valorFecha).format('DD'))
                    setFechaDesarrolloSoftware(fechaDesarrolloSoftware)

                    datosNormales.push({
                        id: item.id,
                        label: item.label,
                        codigo: item.nombre,
                        value: item.valorFecha
                    })
                  }else if(item.nombre == "fechaRegistroBaseTecno"){
                    let anio = parseInt(moment(item.valorFecha).format('YYYY'))
                    let mes = parseInt(moment(item.valorFecha).format('MM'))-1
                    let dia = parseInt(moment(item.valorFecha).format('DD'))+1
                    let fechaRegistroBaseTecno = new Date(anio, mes, dia)
                    //fechaRegistroBaseTecno.setFullYear(moment(item.valorFecha).format('YYYY'), moment(item.valorFecha).format('MM'), moment(item.valorFecha).format('DD'))
                    setFechaRegistroBaseTecno(fechaRegistroBaseTecno)

                    datosNormales.push({
                        id: item.id,
                        label: item.label,
                        codigo: item.nombre,
                        value: item.valorFecha
                    })
                  }else if(item.nombre == "fechaInnovacionProceServi"){
                    let anio = parseInt(moment(item.valorFecha).format('YYYY'))
                    let mes = parseInt(moment(item.valorFecha).format('MM'))-1
                    let dia = parseInt(moment(item.valorFecha).format('DD'))+1
                    let fechaInnovacionProceServi = new Date(anio, mes, dia)
                    //fechaInnovacionProceServi.setFullYear(moment(item.valorFecha).format('YYYY'), moment(item.valorFecha).format('MM'), moment(item.valorFecha).format('DD'))
                    setFechaInnovacionProceServi(fechaInnovacionProceServi)
                    datosNormales.push({
                        id: item.id,
                        label: item.label,
                        codigo: item.nombre,
                        value: item.valorFecha
                    })
                  }else if(item.nombre == "fechaInnovacionGestionEmp"){
                    let anio = parseInt(moment(item.valorFecha).format('YYYY'))
                    let mes = parseInt(moment(item.valorFecha).format('MM'))-1
                    let dia = parseInt(moment(item.valorFecha).format('DD'))+1
                    let fechaInnovacionGestionEmp = new Date(anio, mes, dia)
                    //fechaInnovacionGestionEmp.setFullYear(moment(item.valorFecha).format('YYYY'), moment(item.valorFecha).format('MM'), moment(item.valorFecha).format('DD'))
                    setFechaInnovacionGestionEmp(fechaInnovacionGestionEmp)

                    datosNormales.push({
                        id: item.id,
                        label: item.label,
                        codigo: item.nombre,
                        value: item.valorFecha
                    })
                  }else if(item.nombre == "fechaInicioEventoArtistico"){
                    let anio = parseInt(moment(item.valorFecha).format('YYYY'))
                    let mes = parseInt(moment(item.valorFecha).format('MM'))-1
                    let dia = parseInt(moment(item.valorFecha).format('DD'))+1
                    let fechaInicioEventoArtistico = new Date(anio, mes, dia)
                    //fechaInicioEventoArtistico.setFullYear(moment(item.valorFecha).format('YYYY'), moment(item.valorFecha).format('MM'), moment(item.valorFecha).format('DD'))
                    setFechaInicioEventoArtistico(fechaInicioEventoArtistico)

                    datosNormales.push({
                        id: item.id,
                        label: item.label,
                        codigo: item.nombre,
                        value: item.valorFecha
                    })
                  }else if(item.nombre == "fechaCreacionObraProducto"){
                    let anio = parseInt(moment(item.valorFecha).format('YYYY'))
                    let mes = parseInt(moment(item.valorFecha).format('MM'))-1
                    let dia = parseInt(moment(item.valorFecha).format('DD'))+1
                    let fechaCreacionObraProducto = new Date(anio, mes, dia)
                    //fechaCreacionObraProducto.setFullYear(moment(item.valorFecha).format('YYYY'), moment(item.valorFecha).format('MM'), moment(item.valorFecha).format('DD'))
                    setFechaCreacionObraProducto(fechaCreacionObraProducto)

                    datosNormales.push({
                        id: item.id,
                        label: item.label,
                        codigo: item.nombre,
                        value: item.valorFecha
                    })
                  }else if(item.nombre == "fechaInicioTalleresCreacion"){
                    let anio = parseInt(moment(item.valorFecha).format('YYYY'))
                    let mes = parseInt(moment(item.valorFecha).format('MM'))-1
                    let dia = parseInt(moment(item.valorFecha).format('DD'))+1
                    let fechaInicioTalleresCreacion = new Date(anio, mes, dia)
                    //fechaInicioTalleresCreacion.setFullYear(moment(item.valorFecha).format('YYYY'), moment(item.valorFecha).format('MM'), moment(item.valorFecha).format('DD'))
                    setFechaInicioTalleresCreacion(fechaInicioTalleresCreacion)

                    datosNormales.push({
                        id: item.id,
                        label: item.label,
                        codigo: item.nombre,
                        value: item.valorFecha
                    })
                  }else if(item.nombre == "fechaRegistroEmpresaCultural"){
                    let anio = parseInt(moment(item.valorFecha).format('YYYY'))
                    let mes = parseInt(moment(item.valorFecha).format('MM'))-1
                    let dia = parseInt(moment(item.valorFecha).format('DD'))+1
                    let fechaRegistroEmpresaCultural = new Date(anio, mes, dia)
                    //fechaInicioTalleresCreacion.setFullYear(moment(item.valorFecha).format('YYYY'), moment(item.valorFecha).format('MM'), moment(item.valorFecha).format('DD'))
                    setFechaRegistroEmpresaCultural(fechaRegistroEmpresaCultural)

                    datosNormales.push({
                        id: item.id,
                        label: item.label,
                        codigo: item.nombre,
                        value: item.valorFecha
                    })
                  }
                }
                

            }else{//Caso en que sea un SELECT O FECHA donde valorFecha no tiene nada
                if(item.nombre == "fechaObtencionRegistroSoftware"){
                    let fechaObtencionRegistroSoftware = new Date()
                    fechaObtencionRegistroSoftware.setFullYear(moment(item.valor).format('YYYY'), moment(item.valor).format('MM')-1, moment(item.valor).format('DD'))
                    setFechaObtencionRegistroSoftware(fechaObtencionRegistroSoftware)

                    datosNormales.push({
                        id: item.id,
                        label: item.label,
                        codigo: item.nombre,
                        value: item.valorFecha
                    })
                }else if(item.nombre == "fechaFinalEventoArtistico"){
                    let fechaFinalEventoArtistico = new Date()
                    fechaFinalEventoArtistico.setFullYear(moment(item.valor).format('YYYY'), moment(item.valor).format('MM')-1, moment(item.valor).format('DD'))
                    setFechaFinalEventoArtistico(fechaFinalEventoArtistico)

                    datosNormales.push({
                        id: item.id,
                        label: item.label,
                        codigo: item.nombre,
                        value: item.valorFecha
                    })
                }else if(item.nombre == "fechaPresentacionObraProducto"){
                    let fechaPresentacionObraProducto = new Date()
                    fechaPresentacionObraProducto.setFullYear(moment(item.valor).format('YYYY'), moment(item.valor).format('MM')-1, moment(item.valor).format('DD'))
                    setFechaPresentacionObraProducto(fechaPresentacionObraProducto)

                    datosNormales.push({
                        id: item.id,
                        label: item.label,
                        codigo: item.nombre,
                        value: item.valorFecha
                    })
                }else if(item.nombre == "fechaFinalTalleresCreacion"){
                    let fechaFinalTalleresCreacion = new Date()
                    fechaFinalTalleresCreacion.setFullYear(moment(item.valor).format('YYYY'), moment(item.valor).format('MM')-1, moment(item.valor).format('DD'))
                    setFechaFinalTalleresCreacion(fechaFinalTalleresCreacion)

                    datosNormales.push({
                        id: item.id,
                        label: item.label,
                        codigo: item.nombre,
                        value: item.valorFecha
                    })
                }else{
                    if(item.valorSelect != null){
                        if(item.valorSelect.codigo == "ningunoDiseñoPrototipoTiene" || item.valorSelect.codigo == "patenteDiseñoPrototipoTiene" || item.valorSelect.codigo == "registroDiseñoPrototipoTiene" || item.valorSelect.codigo == "secreEmpDiseñoPrototipoTiene"){
                            setProductoTieneSelected(item.valorSelect.codigo)
                        }else if(item.valorSelect.codigo == "Inves_tipo_prototipo_industrial" || item.valorSelect.codigo == "Inves_tipo_prototipo_otro" || item.valorSelect.codigo == "Inves_tipo_prototipo_servicio"){
                            setTipoPrototipoSelected(item.valorSelect.codigo)
                        }else if(item.valorSelect.codigo == "Inves_tipo_signo_denoOrigen" || item.valorSelect.codigo == "Inves_tipo_signo_enseComerciales" || item.valorSelect.codigo == "Inves_tipo_signo_lemaComerciales"
                        || item.valorSelect.codigo == "Inves_tipo_signo_marca" || item.valorSelect.codigo == "Inves_tipo_signo_marColectivas" || item.valorSelect.codigo == "Inves_tipo_signo_marCertifi"
                        || item.valorSelect.codigo == "Inves_tipo_signo_nomComerciales"){
                            setTipoSignoDistintivoSelected(item.valorSelect.codigo)
                        }else if(item.valorSelect.codigo == "Inves_tipo_software_computacional" || item.valorSelect.codigo == "Inves_tipo_software_multimedia" || item.valorSelect.codigo == "Inves_tipo_software_otro"){
                            setTipoSoftwareSelected(item.valorSelect.codigo)
                        }else if(item.valorSelect.codigo == "Inves_tipo_empresa_spinoff" || item.valorSelect.codigo == "Inves_tipo_empresa_startup"){
                            setTipoEmpresaSelected(item.valorSelect.codigo)
                        }else if(item.valorSelect.codigo == "Inves_tipo_innoEmp_empresarial" || item.valorSelect.codigo == "Inves_tipo_innoEmp_organizacional"){
                            setTipoInnovacionSelected(item.valorSelect.codigo)
                        }else if(item.valorSelect.codigo == "Inves_tipo_ambito_internacional" || item.valorSelect.codigo == "Inves_tipo_ambito_local" || item.valorSelect.codigo == "Inves_tipo_ambito_nacional"){
                            setTipoAmbitoSelected(item.valorSelect.codigo)
                        }else if(item.valorSelect.codigo == "Inves_tipo_espaEven_bienal" || item.valorSelect.codigo == "Inves_tipo_espaEven_concurso" || item.valorSelect.codigo == "Inves_tipo_espaEven_festival" || item.valorSelect.codigo == "Inves_tipo_espaEven_salon" || item.valorSelect.codigo == "Inves_tipo_espaEven_otro"){
                            setTipoEspacioEventoSelected(item.valorSelect.codigo)
                        }else if(item.valorSelect.codigo == "Inves_tipo_disciAmbi_arquiUrba" 
                        || item.valorSelect.codigo == "Inves_tipo_disciAmbi_arteAudio" || item.valorSelect.codigo == "Inves_tipo_disciAmbi_artPlasVisu" 
                        || item.valorSelect.codigo == "Inves_tipo_disciAmbi_danArtDan" || item.valorSelect.codigo == "Inves_tipo_disciAmbi_diseño" || item.valorSelect.codigo == "Inves_tipo_disciAmbi_dramaArtEsce"
                        || item.valorSelect.codigo == "Inves_tipo_disciAmbi_musiMusi" || item.valorSelect.codigo == "Inves_tipo_disciAmbi_otraArt" || item.valorSelect.codigo == "Inves_tipo_disciAmbi_teatro"){
                            setTipoDisciplinaAmbitoSelected(item.valorSelect.codigo)

                        }else if(item.valorSelect.codigo == "Inves_tipo_evento_internacional" || item.valorSelect.codigo == "Inves_tipo_evento_nacional" || item.valorSelect.codigo == "Inves_tipo_evento_taller"){
                            setTipoEventoSelected(item.valorSelect.codigo)
                        }else if(item.valorSelect.valora.toLowerCase() == "pais"){
                            datosNormales.push({
                                id: item.id,
                                label: item.label,
                                codigo: item.nombre,
                                value: item.valorSelect.nombre
                            })
                            setPaisSelectedTecTecno(item.valorSelect.id)
                        }
                    }else{
                        //Caso en que alguno de los formularios tenga el campo de "OTRO" si lo tiene entonces entra al else sino entra el if para guardar como input normal
                        if(item.nombre != "otroTipoPrototipoSelected" && item.nombre != "otroTipoSoftwareSelected" && item.nombre != "otroTipoEspacioEvento"){
                            datosNormales.push({
                            id: item.id,
                            label: item.label,
                            codigo: item.nombre,
                            value: item.valor
                            })
                        }else{
                            if(item.nombre == "otroTipoPrototipoSelected"){
                                setOtroTipoPrototipoSelected(item.valor)                  
                            }else if(item.nombre == "otroTipoSoftwareSelected"){
                                setOtroTipoSoftwareSelected(item.valor)
                            }else if(item.nombre == "otroTipoEspacioEvento"){
                                setOtroTipoEspacioEvento(item.valor)
                            }
                        }
                    }
                    
                }
                
              }
          }else{//Caso en que sea algun campo de prototipo tiene
            if(item.nombre.includes('fecha')){
              if(item.nombre == "fechaRegistroDiseñoPrototipoTiene"){
                let fechaRegistroDiseñoPrototipoTiene = new Date()
                fechaRegistroDiseñoPrototipoTiene.setFullYear(moment(item.valor).format('YYYY'), moment(item.valor).format('MM')-1, moment(item.valor).format('DD'))
                setFechaRegistroDiseñoPrototipoTiene(fechaRegistroDiseñoPrototipoTiene)

              }
            }
            datosProductoTiene.push({
              id: item.id,
              label: item.label,
              codigo: item.nombre,
              value: item.valor
            })
          }
        });

          setData(datosNormales)
          setDataInputsExtra(datosProductoTiene)
          setTipoProductoTecTecno(datos[0].key)
          setCargandoModal(false)
        }
        obtenerProcesos()
      }
    })

    const getInfoRow = async () => {
      return new Promise(resolve => {
          Funciones.consulta(`api/v1.0/investigacion/listaGestionTecTecnoUno?key=${props.data.key.id}`, null, null, (error, estado, resp) => {
            resolve(estado === 200 && !error ? resp : null);
          })  
      })
    }

    const obtenerElproductoTiene = async () =>{
      let permisos = await Funciones.obtenerValores(Helper.DISEÑO_INDUSTRIAL_PROTOTIPO_TIENE)
      setTiposPrototipoTiene(permisos)
    }

    const obtenerTiposPrototipos = async () =>{
      let permisos = await Funciones.obtenerValores(Helper.TIPOS_DE_PROTOTIPOS_INVESTIGACION)
      setTiposDePrototipos(permisos)
    }

    const obtenerTiposSignoDistintivo = async () =>{
      let permisos = await Funciones.obtenerValores(Helper.TIPO_SIGNO_DISTINTIVO_INVESTIGACION)

      setTiposSignoDistintivos(permisos)
    }

    const obtenerTiposSoftware = async () =>{
      let permisos = await Funciones.obtenerValores(Helper.TIPOS_DE_SOFTWARE_INVESTIGACION)

      setTiposDeSoftware(permisos)
    }

    const obtenerTiposEmpresas = async () =>{
      let permisos = await Funciones.obtenerValores(Helper.TIPO_DE_EMPRESA_INVESTIGACION)

      setTiposEmpresas(permisos)
    }

    const obtenerTiposInnovaciones = async () =>{
      let permisos = await Funciones.obtenerValores(Helper.TIPO_INNOVACION_EMP_INVESTIGACION)

      setTiposInnovaciones(permisos)
    }

    const obtenerTiposRoles = async () =>{
      let permisos = await Funciones.obtenerValores(Helper.TIPO_ROL_INVESTIGACION)

      setTiposRoles(permisos)
    }

    const obtenerTiposAmbitos = async () =>{
      let permisos = await Funciones.obtenerValores(Helper.TIPO_AMBITO_INVESTIGACION)

      setTiposAmbitos(permisos)
    }

    const obtenerTiposEspacioEvento = async () =>{
      let permisos = await Funciones.obtenerValores(Helper.TIPO_ESPACIO_EVENTO_INVESTIGACION)

      setTiposEspacioEvento(permisos)
    }

    const obtenerTiposDisciplinaAmbito = async () =>{
      let permisos = await Funciones.obtenerValores(Helper.TIPO_DISCIPLINA_AMBITO_INVESTIGACION)

      setTiposDisciplinaAmbito(permisos)
    }

    const obtenerTiposEventos = async () =>{
      let permisos = await Funciones.obtenerValores(Helper.TIPO_EVENTO_INVESTIGACION)

      setTiposEventos(permisos)
    }

    const obtenerPaises= async () => {
        try{
            let data = await Funciones.obtenerValores(Helper.PAISES)
            setPaisesTecTecno(data)
        }catch(err){
          console.log(err)
        } 
    }
    //Sistema de errores

    const getError = (llave, errores) => {
      let existe = errores.find(e => e.llave === llave);
      return existe ? existe.mensaje : '';
    }

    const getInfoRowUsers = async () => {
      return new Promise(resolve => {
          Funciones.consulta(`api/v1.0/investigacion/listaGestionSemilleroAutores?produccion_tec_tecno=${props.data.key.id}`, null, null, (error, estado, resp) => {
            resolve(estado === 200 && !error ? resp : null);
          })
      })
  }

    const validateInputsTecnico = (campos = []) => {
      return new Promise((resolve) => {
      let errores = [];

      function isObjEmpty(obj) {

          if(typeof obj === 'object'){
              for (var prop in obj) {
                  if (obj.hasOwnProperty(prop)) return false;
                }
              
                return true;
          }else if(typeof obj === 'string'){
              return true
          }else{
              return false;
          }
          
      }

      campos.map(({ value, name, err }) => {
          
          if (!err.obj && err.empty && value.replace(/\s+/g, '') == "") {
              errores.push({ llave: name, 'mensaje': `Este campo es obligatorio` })   
          }
          if (err.empty && isObjEmpty(value) && err.obj){
              errores.push({ llave: name, 'mensaje': `Este campo es obligatorio` })
          }
          /*if (err.numeric && isNaN(value)) errores.push({ llave: name, 'mensaje': `Este campo debe ser un numero` });
          if (err.email && !validarCorreo(value)) errores.push({ llave: name, 'mensaje': `Correo invalido` });
          if (err.before_today && value >= date_now) errores.push({ llave: name, 'mensaje': `Fecha futura invalida` });
          if (err.after_today && value <= date_now) errores.push({ llave: name, 'mensaje': `Fecha pasada invalida` });
          if (err.finish_date && value <= value2) errores.push({ llave: name, 'mensaje': `Fecha invalida` });
          if (err.range && (value < 1 || value > value2)) errores.push({ llave: name, 'mensaje': `El valor está fuera del rango permitido` });
          if (err.not_null && value == null) errores.push({ llave:name, 'mensaje': `Este campo es obligatorio` });
          if (err.not_decimal && value % 1 != 0) errores.push({ llave: name, 'mensaje': `El valor no puede ser decimal` });*/
      });
      
      resolve(errores);
      });
    }

    const vista = () => {
        switch(tipo){
            case 'form':
                return(
                    <ModalStepperFormTecTecno
                        //Stepper
                        hola={activeStepBiblio}
                        handleReset={handleReset}
                        steps={steps}
                
                        cargandoModal={cargandoModal}
                        setCargandoModal={setCargandoModal}

                        //Informacion de los inputs
                        datos={data}
                        setDatos={setData}
                        dataInputsExtra={dataInputsExtra}
                        setDataInputsExtra={setDataInputsExtra}
                        //Tipo de producto
                        tipoProductoTecTecno={tipoProductoTecTecno}
                        setTipoProductoTecTecno={setTipoProductoTecTecno}

                        //Tipo de prototipo
                        tiposDePrototipos={tiposDePrototipos}
                        setTiposDePrototipos={setTiposDePrototipos}
                        tipoPrototipoSelected={tipoPrototipoSelected}
                        setTipoPrototipoSelected={setTipoPrototipoSelected}
                        
                        //Tipo de signo distintivo
                        tiposSignoDistintivos={tiposSignoDistintivos}
                        setTiposSignoDistintivos={setTiposSignoDistintivos}
                        tipoSignoDistintivoSelected={tipoSignoDistintivoSelected}
                        setTipoSignoDistintivoSelected={setTipoSignoDistintivoSelected}

                        //Tipo de software
                        tiposDeSoftware={tiposDeSoftware}
                        setTiposDeSoftware={setTiposDeSoftware}
                        tipoSoftwareSelected={tipoSoftwareSelected}
                        setTipoSoftwareSelected={setTipoSoftwareSelected}

                        //Tipo de empresa
                        tiposEmpresas={tiposEmpresas}
                        setTiposEmpresas={setTiposEmpresas}
                        tipoEmpresaSelected={tipoEmpresaSelected}
                        setTipoEmpresaSelected={setTipoEmpresaSelected}

                        //Tipo de Innovacion
                        tiposInnovaciones={tiposInnovaciones}
                        setTiposInnovaciones={setTiposInnovaciones}
                        tipoInnovacionSelected={tipoInnovacionSelected}
                        setTipoInnovacionSelected={setTipoInnovacionSelected}

                        //Tipo de Ambitos
                        tiposAmbitos={tiposAmbitos}
                        setTiposAmbitos={setTiposAmbitos}
                        tipoAmbitoSelected={tipoAmbitoSelected}
                        setTipoAmbitoSelected={setTipoAmbitoSelected}

                        //Tipo de Roles
                        tiposRoles={tiposRoles}
                        setTiposRoles={setTiposRoles}
                        tipoRolSelected={tipoRolSelected}
                        setTipoRolSelected={setTipoRolSelected}

                        //Tipo de Espacio Evento
                        tiposEspacioEvento={tiposEspacioEvento}
                        setTiposEspacioEvento={setTiposEspacioEvento}
                        tipoEspacioEventoSelected={tipoEspacioEventoSelected}
                        setTipoEspacioEventoSelected={setTipoEspacioEventoSelected}

                        //Tipo disciplina ambito
                        tiposDisciplinaAmbito={tiposDisciplinaAmbito}
                        setTiposDisciplinaAmbito={setTiposDisciplinaAmbito}
                        tipoDisciplinaAmbitoSelected={tipoDisciplinaAmbitoSelected}
                        setTipoDisciplinaAmbitoSelected={setTipoDisciplinaAmbitoSelected}

                        //Tipo de eventos
                        tiposEventos={tiposEventos}
                        setTiposEventos={setTiposEventos}
                        tipoEventoSelected={tipoEventoSelected}
                        setTipoEventoSelected={setTipoEventoSelected}

                        //Pais
                        paisesTecTecno={paisesTecTecno}
                        setPaisesTecTecno={setPaisesTecTecno}
                        paisSelectedTecTecno={paisSelectedTecTecno}
                        setPaisSelectedTecTecno={setPaisSelectedTecTecno}

                        //fechas llenos
                        fechaCreacionDiseñoIndus = {fechaCreacionDiseñoIndus}
                        setFechaCreacionDiseñoIndus = {setFechaCreacionDiseñoIndus} 

                        fechaRegistroDiseñoPrototipoTiene = {fechaRegistroDiseñoPrototipoTiene}
                        setFechaRegistroDiseñoPrototipoTiene= {setFechaRegistroDiseñoPrototipoTiene} 

                        fechaCreacionPrototipo={fechaCreacionPrototipo}
                        setFechaCreacionPrototipo={setFechaCreacionPrototipo}

                        fechaCreacionSignoDistintivo={fechaCreacionSignoDistintivo}
                        setFechaCreacionSignoDistintivo={setFechaCreacionSignoDistintivo}
                        
                        fechaDesarrolloSoftware={fechaDesarrolloSoftware}
                        setFechaDesarrolloSoftware={setFechaDesarrolloSoftware}

                        fechaObtencionRegistroSoftware={fechaObtencionRegistroSoftware}
                        setFechaObtencionRegistroSoftware={setFechaObtencionRegistroSoftware}

                        fechaRegistroBaseTecno={fechaRegistroBaseTecno}
                        setFechaRegistroBaseTecno={setFechaRegistroBaseTecno}

                        fechaInnovacionProceServi={fechaInnovacionProceServi}
                        setFechaInnovacionProceServi={setFechaInnovacionProceServi}

                        fechaInnovacionGestionEmp={fechaInnovacionGestionEmp}
                        setFechaInnovacionGestionEmp={setFechaInnovacionGestionEmp}

                        fechaInicioEventoArtistico={fechaInicioEventoArtistico}
                        setFechaInicioEventoArtistico={setFechaInicioEventoArtistico}

                        fechaFinalEventoArtistico={fechaFinalEventoArtistico}
                        setFechaFinalEventoArtistico={setFechaFinalEventoArtistico}

                        fechaPresentacionObraProducto={fechaPresentacionObraProducto}
                        setFechaPresentacionObraProducto={setFechaPresentacionObraProducto}

                        fechaCreacionObraProducto={fechaCreacionObraProducto}
                        setFechaCreacionObraProducto={setFechaCreacionObraProducto}

                        fechaInicioTalleresCreacion={fechaInicioTalleresCreacion}
                        setFechaInicioTalleresCreacion={setFechaInicioTalleresCreacion}

                        fechaFinalTalleresCreacion={fechaFinalTalleresCreacion}
                        setFechaFinalTalleresCreacion={setFechaFinalTalleresCreacion}

                        fechaRegistroEmpresaCultural={fechaRegistroEmpresaCultural}
                        setFechaRegistroEmpresaCultural={setFechaRegistroEmpresaCultural}

                        otroTipoEspacioEvento={otroTipoEspacioEvento}
                        setOtroTipoEspacioEvento={setOtroTipoEspacioEvento}
                        //Tipo de prototipo tiene
                        tiposPrototipoTiene = {tiposPrototipoTiene}
                        setTiposPrototipoTiene = {setTiposPrototipoTiene} 
                        productoTieneSelected={productoTieneSelected}
                        setProductoTieneSelected={setProductoTieneSelected}
                    
                        //Campos de "OTROS" de los SELECTS
                        otroTipoPrototipoSelected={otroTipoPrototipoSelected}
                        setOtroTipoPrototipoSelected={setOtroTipoPrototipoSelected}
                        otroTipoSoftwareSelected={otroTipoSoftwareSelected}
                        setOtroTipoSoftwareSelected={setOtroTipoSoftwareSelected}

                        //Errores
                        getError={getError}
                        errores={errores}
                        setErrores={setErrores}

                        usuarios={usuarios}
                        setUsuarios={setUsuarios}

                        arrai={arrai}
                        setArrai={setArrai}

                        usuariosEliminados={usuariosEliminados}
                        setUsuariosEliminados={setUsuariosEliminados}

                        rolActive={rolActive}
                        setRolActive={setRolActive}

                        modalidadParticipacionActive={modalidadParticipacionActive}
                        setModalidadParticipacionActive={setModalidadParticipacionActive}
                    />
                )
        }
    }

    const handleNext = async () =>{
        if(props.data.key.producto.codigo == "Inves_tipo_tec_pro_empCreaCul"){
            
            if(activeStepBiblio == 0){
                let array = []
                let errorSelects = []
                let sw = true
                for (const item of data) {
                    let error = []

                    if(item.codigo.includes('fecha')){
                        if(item.codigo == "fechaRegistroEmpresaCultural"){
                            let fecha_actual = moment(new Date()).format('YYYY-MM-DD');
                            let fecha = moment(fechaRegistroEmpresaCultural).format('YYYY-MM-DD');
                            if(fecha_actual < fecha){
                                let tipo = "info"
                                let titulo = "Elija una Fecha de Registro valida"
                                sw = false
                                dispatch(
                                    actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                );
                            }
                        }
                    }else{
                        error = await validateInputsTecnico([
                            { value: item.value, name: item.codigo, err: { empty: true, obj: false } },
                        ])
                    }
                    if(error.length != 0){
                        array.push(error[0])
                    }
                    
                }

                errorSelects = await validateInputsTecnico([
                    { value: tipoDisciplinaAmbitoSelected, name: 'tipoDisciplinaAmbitoSelected', err: { empty: true, obj: false } },
                ])

                if(errorSelects.length != 0){
                    for(let i = 0; i < errorSelects.length; i++){
                        array.push(errorSelects[i])
                    }
                }

                setErrores(array)
                if(array.length == 0 && sw == true){
                    setActiveStepBiblio((prevActiveStep) => prevActiveStep + 1);
                }else{
                    console.log(array)
                }
            }
        }else if(props.data.key.producto.codigo == "Inves_tipo_tec_pro_diseñoIndustrial"){
          if(activeStepBiblio == 0){
            let array = []
            let errorSelects = []
            let sw = true
            for (const item of data) {
                let error = []

                if(item.codigo.includes('fecha')){
                    if(item.codigo == "fechaCreacion"){
                        let fecha_actual = moment(new Date()).format('YYYY-MM-DD');
                        let fecha = moment(fechaCreacionDiseñoIndus).format('YYYY-MM-DD');
                        if(fecha_actual < fecha){
                            let tipo = "info"
                            let titulo = "Elija una fecha de Creacion valida"
                            sw = false
                            dispatch(
                                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                            );
                        }
                    }
                }else{
                    error = await validateInputsTecnico([
                        { value: item.value, name: item.codigo, err: { empty: true, obj: false } },
                        { value: paisSelectedTecTecno, name: 'paisSelectedTecTecno', err: { empty: true, obj: true } },
                    ])
                }
                if(error.length != 0){
                    array.push(error[0])
                }
                
            }

            errorSelects = await validateInputsTecnico([
                { value: productoTieneSelected, name: 'productoTiene', err: { empty: true, obj: false } },
            ])

            if(errorSelects.length != 0){
                array.push(errorSelects[0])
            }else {
              if(productoTieneSelected != "ningunoDiseñoPrototipoTiene"){
                for (const item of dataInputsExtra) {
                  let error = []

                  if(item.codigo.includes('fecha')){
                      if(item.codigo == "fechaRegistroDiseñoPrototipoTiene"){
                          let fecha_actual = moment(new Date()).format('YYYY-MM-DD');
                          let fecha = moment(fechaRegistroDiseñoPrototipoTiene).format('YYYY-MM-DD');
                          if(fecha_actual < fecha){
                              let tipo = "info"
                              let titulo = "Elija una Fecha de Obtención del Registro valida"
                              sw = false
                              dispatch(
                                  actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                              );
                          }
                      }
                  }else{
                      error = await validateInputsTecnico([
                          { value: item.value, name: item.codigo, err: { empty: true, obj: false } },
                      ])
                  }
                  if(error.length != 0){
                      array.push(error[0])
                  }
                }
              }
            }

            setErrores(array)
            if(array.length == 0 && sw == true){
                setActiveStepBiblio((prevActiveStep) => prevActiveStep + 1);
            }
            
        }
      }else if(props.data.key.producto.codigo == "Inves_tipo_tec_pro_prototipo"){
        if(activeStepBiblio == 0){
          let array = []
          let errorSelects = []
          let sw = true
          for (const item of data) {
              let error = []

              if(item.codigo.includes('fecha')){
                  if(item.codigo == "fechaCreacionPrototipo"){
                      let fecha_actual = moment(new Date()).format('YYYY-MM-DD');
                      let fecha = moment(fechaCreacionPrototipo).format('YYYY-MM-DD');
                      if(fecha_actual < fecha){
                          let tipo = "info"
                          let titulo = "Elija una Fecha de Creación Prototipo valida"
                          sw = false
                          dispatch(
                              actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                          );
                      }
                  }
              }else{
                  error = await validateInputsTecnico([
                      { value: item.value, name: item.codigo, err: { empty: true, obj: false } },
                  ])
              }
              if(error.length != 0){
                  array.push(error[0])
              }
              
          }
          
          errorSelects = await validateInputsTecnico([
              { value: productoTieneSelected, name: 'productoTiene', err: { empty: true, obj: false } },
              { value: tipoPrototipoSelected, name: 'tipoPrototipoSelected', err: { empty: true, obj: false } },
              { value: paisSelectedTecTecno, name: 'paisSelectedTecTecno', err: { empty: true, obj: true } },
            ])
          
          if(errorSelects.length != 0){
              for(let i = 0; i < errorSelects.length; i++){
                  array.push(errorSelects[i])
              }
          }else {
              if(productoTieneSelected != "ningunoDiseñoPrototipoTiene"){
                  for (const item of dataInputsExtra) {
                      let error = []

                      if(item.codigo.includes('fecha')){
                          if(item.codigo == "fechaRegistroDiseñoPrototipoTiene"){
                              let fecha_actual = moment(new Date()).format('YYYY-MM-DD');
                              let fecha = moment(fechaRegistroDiseñoPrototipoTiene).format('YYYY-MM-DD');
                              if(fecha_actual < fecha){
                                  let tipo = "info"
                                  let titulo = "Elija una Fecha de Obtención del Registro valida"
                                  sw = false
                                  dispatch(
                                      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                  );
                              }
                          }
                      }else{
                          error = await validateInputsTecnico([
                              { value: item.value, name: item.codigo, err: { empty: true, obj: false } },
                          ])
                      }
                      if(error.length != 0){
                          array.push(error[0])
                      }
                  }
              }

              if(tipoPrototipoSelected == "Inves_tipo_prototipo_otro"){
                  let error = []
                  errorSelects = await validateInputsTecnico([
                      { value: otroTipoPrototipoSelected, name: 'nombreOtroPrototipo', err: { empty: true, obj: false } },
                  ])
                  if(errorSelects.length != 0){
                      array.push(errorSelects[0])
                  }
              }
          }

          setErrores(array)
          if(array.length == 0 && sw == true){
              setActiveStepBiblio((prevActiveStep) => prevActiveStep + 1);
          }
        }
      }else if(props.data.key.producto.codigo == "Inves_tipo_tec_pro_signo_distintivo"){
        if(activeStepBiblio == 0){
          let array = []
          let errorSelects = []
          let sw = true
          for (const item of data) {
              let error = []

              if(item.codigo.includes('fecha')){
                  if(item.codigo == "fechaCreacionSignoDistintivo"){
                      let fecha_actual = moment(new Date()).format('YYYY-MM-DD');
                      let fecha = moment(fechaCreacionSignoDistintivo).format('YYYY-MM-DD');
                      if(fecha_actual < fecha){
                          let tipo = "info"
                          let titulo = "Elija una Fecha de Creación Signo Distintivo valida"
                          sw = false
                          dispatch(
                              actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                          );
                      }
                  }
              }else{
                  error = await validateInputsTecnico([
                      { value: item.value, name: item.codigo, err: { empty: true, obj: false } },
                  ])
              }
              if(error.length != 0){
                  array.push(error[0])
              }
              
          }

          errorSelects = await validateInputsTecnico([
              { value: tipoSignoDistintivoSelected, name: 'tipoSignoDistintivoSelected', err: { empty: true, obj: false } },
              { value: paisSelectedTecTecno, name: 'paisSelectedTecTecno', err: { empty: true, obj: true } },
            ])

          if(errorSelects.length != 0){
              for(let i = 0; i < errorSelects.length; i++){
                  array.push(errorSelects[i])
              }
          }

          setErrores(array)
          if(array.length == 0 && sw == true){
              setActiveStepBiblio((prevActiveStep) => prevActiveStep + 1);
          }
        }
      }else if(props.data.key.producto.codigo == "Inves_tipo_tec_pro_software"){
        if(activeStepBiblio == 0){
          let array = []
          let errorSelects = []
          let sw = true
          for (const item of data) {
              let error = []

              if(item.codigo.includes('fecha')){
                  if(item.codigo == "fechaDesarrolloSoftware"){
                      let fecha_actual = moment(new Date()).format('YYYY-MM-DD');
                      let fecha = moment(fechaDesarrolloSoftware).format('YYYY-MM-DD');
                      if(fecha_actual < fecha){
                          let tipo = "info"
                          let titulo = "Elija una Fecha de Desarrollo valida"
                          sw = false
                          dispatch(
                              actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                          );
                      }
                  }else if(item.codigo == "fechaObtencionRegistroSoftware"){
                      let fecha_actual = moment(new Date()).format('YYYY-MM-DD');
                      let fecha = moment(fechaObtencionRegistroSoftware).format('YYYY-MM-DD');
                      if(fecha_actual < fecha){
                          let tipo = "info"
                          let titulo = "Elija una Fecha de Obtención del Registro valida"
                          sw = false
                          dispatch(
                              actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                          );
                      }
                  }
              }else{
                  error = await validateInputsTecnico([
                      { value: item.value, name: item.codigo, err: { empty: true, obj: false } },
                  ])
              }
              if(error.length != 0){
                  array.push(error[0])
              }
              
          }

          errorSelects = await validateInputsTecnico([
              { value: tipoSoftwareSelected, name: 'tipoSoftwareSelected', err: { empty: true, obj: false } },
              { value: paisSelectedTecTecno, name: 'paisSelectedTecTecno', err: { empty: true, obj: true } },
            ])

          if(errorSelects.length != 0){
              for(let i = 0; i < errorSelects.length; i++){
                  array.push(errorSelects[i])
              }
          }else{
              if(tipoSoftwareSelected == "Inves_tipo_software_otro"){
                  errorSelects = await validateInputsTecnico([
                      { value: otroTipoSoftwareSelected, name: 'nombreOtroSoftware', err: { empty: true, obj: false } },
                  ])
                  if(errorSelects.length != 0){
                      array.push(errorSelects[0])
                  }
              }
          }

          setErrores(array)
          if(array.length == 0 && sw == true){
              setActiveStepBiblio((prevActiveStep) => prevActiveStep + 1);
          }
        }
      }else if(props.data.key.producto.codigo == "Inves_tipo_tec_pro_empBaseTec"){
        if(activeStepBiblio == 0){
            let array = []
            let errorSelects = []
            let sw = true
            for (const item of data) {
                let error = []

                if(item.codigo.includes('fecha')){
                    if(item.codigo == "fechaRegistroBaseTecno"){
                        let fecha_actual = moment(new Date()).format('YYYY-MM-DD');
                        let fecha = moment(fechaRegistroBaseTecno).format('YYYY-MM-DD');
                        if(fecha_actual < fecha){
                            let tipo = "info"
                            let titulo = "Elija una Fecha de Registro Base Tecnológica valida"
                            sw = false
                            dispatch(
                                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                            );
                        }
                    }
                }else{
                    error = await validateInputsTecnico([
                        { value: item.value, name: item.codigo, err: { empty: true, obj: false } },
                    ])
                }
                if(error.length != 0){
                    array.push(error[0])
                }
                
            }

            errorSelects = await validateInputsTecnico([
                { value: tipoEmpresaSelected, name: 'tipoEmpresaSelected', err: { empty: true, obj: false } },
            ])

            if(errorSelects.length != 0){
                for(let i = 0; i < errorSelects.length; i++){
                    array.push(errorSelects[i])
                }
            }

            setErrores(array)
            if(array.length == 0 && sw == true){
                setActiveStepBiblio((prevActiveStep) => prevActiveStep + 1);
            }
        }
      }else if(props.data.key.producto.codigo == "Inves_tipo_tec_pro_innProProSer"){
        if(activeStepBiblio == 0){
          let array = []
          let errorSelects = []
          let sw = true
          for (const item of data) {
              let error = []

              if(item.codigo.includes('fecha')){
                  if(item.codigo == "fechaInnovacionProceServi"){
                      let fecha_actual = moment(new Date()).format('YYYY-MM-DD');
                      let fecha = moment(fechaInnovacionProceServi).format('YYYY-MM-DD');
                      if(fecha_actual < fecha){
                          let tipo = "info"
                          let titulo = "Elija una Fecha de la Innovación Procedimientos y Servicios valida"
                          sw = false
                          dispatch(
                              actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                          );
                      }
                  }
              }else{
                  error = await validateInputsTecnico([
                      { value: item.value, name: item.codigo, err: { empty: true, obj: false } },
                  ])
              }
              if(error.length != 0){
                  array.push(error[0])
              }
              
          }
                                  
          errorSelects = await validateInputsTecnico([
              { value: productoTieneSelected, name: 'productoTiene', err: { empty: true, obj: false } },
              { value: paisSelectedTecTecno, name: 'paisSelectedTecTecno', err: { empty: true, obj: true } },
            ])
          
          if(errorSelects.length != 0){
              for(let i = 0; i < errorSelects.length; i++){
                  array.push(errorSelects[i])
              }
          }else {
              if(productoTieneSelected != "ningunoDiseñoPrototipoTiene"){
                  for (const item of dataInputsExtra) {
                      let error = []

                      if(item.codigo.includes('fecha')){
                          if(item.codigo == "fechaRegistroDiseñoPrototipoTiene"){
                              let fecha_actual = moment(new Date()).format('YYYY-MM-DD');
                              let fecha = moment(fechaRegistroDiseñoPrototipoTiene).format('YYYY-MM-DD');
                              if(fecha_actual < fecha){
                                  let tipo = "info"
                                  let titulo = "Elija una Fecha de Obtención del Registro valida"
                                  sw = false
                                  dispatch(
                                      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                  );
                              }
                          }
                      }else{
                          error = await validateInputsTecnico([
                              { value: item.value, name: item.codigo, err: { empty: true, obj: false } },
                          ])
                      }
                      if(error.length != 0){
                          array.push(error[0])
                      }
                  }
              }
          }

          setErrores(array)
          if(array.length == 0 && sw == true){
              setActiveStepBiblio((prevActiveStep) => prevActiveStep + 1);
          }
        }
      }else if(props.data.key.producto.codigo == "Inves_tipo_tec_pro_innGenGesEmp"){
        if(activeStepBiblio == 0){
          let array = []
          let errorSelects = []
          let sw = true
          for (const item of data) {
              let error = []

              if(item.codigo.includes('fecha')){
                  if(item.codigo == "fechaInnovacionGestionEmp"){
                      let fecha_actual = moment(new Date()).format('YYYY-MM-DD');
                      let fecha = moment(fechaInnovacionGestionEmp).format('YYYY-MM-DD');
                      if(fecha_actual < fecha){
                          let tipo = "info"
                          let titulo = "Elija una Fecha de la Innovación Gestión Emp. valida"
                          sw = false
                          dispatch(
                              actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                          );
                      }
                  }
              }else{
                  error = await validateInputsTecnico([
                      { value: item.value, name: item.codigo, err: { empty: true, obj: false } },
                  ])
              }
              if(error.length != 0){
                  array.push(error[0])
              }
              
          }

          errorSelects = await validateInputsTecnico([
              { value: productoTieneSelected, name: 'productoTiene', err: { empty: true, obj: false } },
              { value: tipoInnovacionSelected, name: 'tipoInnovacionSelected', err: { empty: true, obj: false } },
              { value: paisSelectedTecTecno, name: 'paisSelectedTecTecno', err: { empty: true, obj: true } },
            ])
          
          if(errorSelects.length != 0){
              for(let i = 0; i < errorSelects.length; i++){
                  array.push(errorSelects[i])
              }
          }else {
              if(productoTieneSelected != "ningunoDiseñoPrototipoTiene"){
                  for (const item of dataInputsExtra) {
                      let error = []

                      if(item.codigo.includes('fecha')){
                          if(item.codigo == "fechaRegistroDiseñoPrototipoTiene"){
                              let fecha_actual = moment(new Date()).format('YYYY-MM-DD');
                              let fecha = moment(fechaRegistroDiseñoPrototipoTiene).format('YYYY-MM-DD');
                              if(fecha_actual < fecha){
                                  let tipo = "info"
                                  let titulo = "Elija una Fecha de Obtención del Registro valida"
                                  sw = false
                                  dispatch(
                                      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                  );
                              }
                          }
                      }else{
                          error = await validateInputsTecnico([
                              { value: item.value, name: item.codigo, err: { empty: true, obj: false } },
                          ])
                      }
                      if(error.length != 0){
                          array.push(error[0])
                      }
                  }
              }
          }

          setErrores(array)
          if(array.length == 0 && sw == true){
              setActiveStepBiblio((prevActiveStep) => prevActiveStep + 1);
          }
        }
      }else if(props.data.key.producto.codigo == "Inves_tipo_tec_pro_eveArt"){
        if(activeStepBiblio == 0){
            let array = []
            let errorSelects = []
            let sw = true
            for (const item of data) {
                let error = []

                if(item.codigo.includes('fecha')){
                    if(item.codigo == "fechaInicioEventoArtistico"){
                        let fecha_inicio = moment(fechaInicioEventoArtistico).format('YYYY-MM-DD');
                        let fecha_final = moment(fechaFinalEventoArtistico).format('YYYY-MM-DD');
                        if(fecha_inicio > fecha_final){
                            let tipo = "info"
                            let titulo = "La fecha de inicio no puede ser mayor que la fecha final"
                            sw = false
                            dispatch(
                                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                            );
                        }
                    }
                }else{
                    error = await validateInputsTecnico([
                        { value: item.value, name: item.codigo, err: { empty: true, obj: false } },
                    ])
                }
                if(error.length != 0){
                    array.push(error[0])
                }
                
            }

            errorSelects = await validateInputsTecnico([
                { value: tipoAmbitoSelected, name: 'tipoAmbitoSelected', err: { empty: true, obj: false } },
                { value: paisSelectedTecTecno, name: 'paisSelectedTecTecno', err: { empty: true, obj: true } },
            ])

            if(errorSelects.length != 0){
                for(let i = 0; i < errorSelects.length; i++){
                    array.push(errorSelects[i])
                }
            }

            setErrores(array)
            if(array.length == 0 && sw == true){
                setActiveStepBiblio((prevActiveStep) => prevActiveStep + 1);
            }
        }
      }else if(props.data.key.producto.codigo == "Inves_tipo_tec_pro_obrPro"){
        if(activeStepBiblio == 0){
            let array = []
            let errorSelects=[]
            let sw = true
            for (const item of data) {
                let error = []

                if(item.codigo.includes('fecha')){
                    if(item.codigo == "fechaPresentacionObraProducto"){
                        let fecha_actual = moment(new Date()).format('YYYY-MM-DD');
                        let fecha = moment(fechaPresentacionObraProducto).format('YYYY-MM-DD');
                        if(fecha_actual < fecha){
                            let tipo = "info"
                            let titulo = "Elija una Fecha de Presentación Obra o Producto valida"
                            sw = false
                            dispatch(
                                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                            );
                        }
                    }else if(item.codigo == "fechaCreacionObraProducto"){
                        let fecha_actual = moment(new Date()).format('YYYY-MM-DD');
                        let fecha = moment(fechaCreacionObraProducto).format('YYYY-MM-DD');
                        if(fecha_actual < fecha){
                            let tipo = "info"
                            let titulo = "Elija una Fecha de Creación Obra o Producto valida"
                            sw = false
                            dispatch(
                                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                            );
                        }
                    }
                }else{
                    error = await validateInputsTecnico([
                        { value: item.value, name: item.codigo, err: { empty: true, obj: false } },
                    ])
                }
                if(error.length != 0){
                    array.push(error[0])
                }
                
            }

            errorSelects = await validateInputsTecnico([
                { value: tipoAmbitoSelected, name: 'tipoAmbitoSelected', err: { empty: true, obj: false } },
                { value: tipoDisciplinaAmbitoSelected, name: 'tipoDisciplinaAmbitoSelected', err: { empty: true, obj: false } },
                { value: tipoEspacioEventoSelected, name: 'tipoEspacioEventoSelected', err: { empty: true, obj: false } },
                { value: paisSelectedTecTecno, name: 'paisSelectedTecTecno', err: { empty: true, obj: true } },
            ])

            if(errorSelects.length != 0){
                for(let i = 0; i < errorSelects.length; i++){
                    array.push(errorSelects[i])
                }
            }else{
                if(tipoEspacioEventoSelected == "Inves_tipo_espaEven_otro"){
                    let error = []
                    error = await validateInputsTecnico([
                        { value: otroTipoEspacioEvento, name: 'otroTipoEspacioEvento', err: { empty: true, obj: false } },
                    ])
                    if(error.length != 0){
                        array.push(error[0])
                    }
                }
            }

            setErrores(array)
            if(array.length == 0 && sw == true){
                setActiveStepBiblio((prevActiveStep) => prevActiveStep + 1);
            }
        }
      }else if(props.data.key.producto.codigo == "Inves_tipo_tec_pro_talCre"){
        if(activeStepBiblio == 0){
            let array = []
            let errorSelects = []
            let sw = true
            for (const item of data) {
                let error = []

                if(item.codigo.includes('fecha')){
                    if(item.codigo == "fechaInicioTalleresCreacion"){
                        let fecha_inicio = moment(fechaInicioTalleresCreacion).format('YYYY-MM-DD');
                        let fecha_final = moment(fechaFinalTalleresCreacion).format('YYYY-MM-DD');
                        if(fecha_inicio > fecha_final){
                            let tipo = "info"
                            let titulo = "La fecha de inicio no puede ser mayor que la fecha final"
                            sw = false
                            dispatch(
                                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                            );
                        }
                    }
                }else{
                    error = await validateInputsTecnico([
                        { value: item.value, name: item.codigo, err: { empty: true, obj: false } },
                    ])
                }
                if(error.length != 0){
                    array.push(error[0])
                }
                
            }

            errorSelects = await validateInputsTecnico([
                { value: tipoAmbitoSelected, name: 'tipoAmbitoSelected', err: { empty: true, obj: false } },
                { value: tipoEventoSelected, name: 'tipoEventoSelected', err: { empty: true, obj: false } },
                { value: paisSelectedTecTecno, name: 'paisSelectedTecTecno', err: { empty: true, obj: true } },
            ])

            if(errorSelects.length != 0){
                for(let i = 0; i < errorSelects.length; i++){
                    array.push(errorSelects[i])
                }
            }
            
            setErrores(array)
            if(array.length == 0 && sw == true){
                setActiveStepBiblio((prevActiveStep) => prevActiveStep + 1);
            }
        }
      }
    }

    const handleSendToApi = async () =>{
    if(props.data.key.producto.codigo == "Inves_tipo_tec_pro_empCreaCul"){
        if(activeStepBiblio == 1){
            if(usuarios.length == 0){
                let tipo = "info"
                let titulo = "Inserte por lo menos 1 Participante"
                dispatch(
                    actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                    );
            }else{
                setCargandoModal(true)
                let api = {}
                let fechaRegistroEmpresaCulturalFormat = moment(fechaRegistroEmpresaCultural).format('YYYY-MM-DD');
                api = {
                    inputs: data,
                    fechaRegistroEmpresaCultural: fechaRegistroEmpresaCulturalFormat,
                    autores: usuarios,
                    autoresEliminados: usuariosEliminados,
                    tipoDisciplinaAmbitoSelected: tipoDisciplinaAmbitoSelected,
                    tipoDeProducto: props.data.key.producto.codigo,
                    keyId: props.data.key.id,
                }

                setCargandoModal(false)
                console.log(api)
                let url = "api/v1.0/investigacion/editGestionSemillerosTecTecno"
                const callback = (error, estado, resp) => {
                    let titulo = "Ha ocurrido un error, contacte con el administrador."
                    let tipo = "info"

                    if(!error){
                        if(estado === 201){
                            tipo = "success"
                            titulo = resp.titulo
                        }
                    }
                    
                    if(estado == 201){
                        dispatch(
                            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                        );
                        handleOnClose()
                    }else{
                        dispatch(
                            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                        );
                        setCargandoModal(false)
                    }
                }
                console.log(api)
                const msg = await Funciones.consulta(url, api, 'POST', callback)
            }
        }
    }else if(props.data.key.producto.codigo == "Inves_tipo_tec_pro_diseñoIndustrial"){
        if(activeStepBiblio == 1){

          if(usuarios.length == 0){
              let tipo = "info"
              let titulo = "Inserte por lo menos 1 Participante"
              dispatch(
                  actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                  );
          }else{
              setCargandoModal(true)
              let api = {}
              
              
              if(productoTieneSelected != "ningunoDiseñoPrototipoTiene"){
                  let fechaCreacionDiseñoIndusFormat = moment(fechaCreacionDiseñoIndus).format('YYYY-MM-DD');
                  let fechaRegistroDiseñoPrototipoTieneFormat = moment(fechaRegistroDiseñoPrototipoTiene).format('YYYY-MM-DD');
                  api = {
                      inputs: data,
                      productoTieneSelected: productoTieneSelected,
                      inputsExtra: dataInputsExtra,
                      fechaRegistroDiseñoPrototipoTiene: fechaRegistroDiseñoPrototipoTieneFormat,
                      fechaCreacionDiseñoIndus: fechaCreacionDiseñoIndusFormat,
                      autores: usuarios,
                      autoresEliminados: usuariosEliminados,
                      paisSelectedTecTecno:paisSelectedTecTecno,
                      tipoDeProducto: props.data.key.producto.codigo,
                      keyId: props.data.key.id,
                  }
              }else{
                  let fechaCreacionDiseñoIndusFormat = moment(fechaCreacionDiseñoIndus).format('YYYY-MM-DD');
                  api = {
                      inputs: data,
                      productoTieneSelected: productoTieneSelected,
                      fechaCreacionDiseñoIndus: fechaCreacionDiseñoIndusFormat,
                      inputsExtra: [],
                      fechaRegistroDiseñoPrototipoTiene: fechaCreacionDiseñoIndusFormat,
                      autores: usuarios,
                      autoresEliminados: usuariosEliminados,
                      paisSelectedTecTecno:paisSelectedTecTecno,
                      tipoDeProducto: props.data.key.producto.codigo,
                      keyId: props.data.key.id,
                  }
              } 
              setCargandoModal(false)
              console.log(api)
              let url = "api/v1.0/investigacion/editGestionSemillerosTecTecno"
              const callback = (error, estado, resp) => {
                  let titulo = "Ha ocurrido un error, contacte con el administrador."
                  let tipo = "info"

                  if(!error){
                      if(estado === 201){
                          tipo = "success"
                          titulo = resp.titulo
                      }
                  }
                  
                  if(estado == 201){
                      dispatch(
                          actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                      );
                      handleOnClose()
                  }else{
                      dispatch(
                          actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                      );
                      setCargandoModal(false)
                  }
              }
              console.log(api)
              const msg = await Funciones.consulta(url, api, 'POST', callback)
          }
        }
      }else if(props.data.key.producto.codigo == "Inves_tipo_tec_pro_software"){
        if(activeStepBiblio == 1){
          if(usuarios.length == 0){
            let tipo = "info"
            let titulo = "Inserte por lo menos 1 Participante"
            dispatch(
                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                );
          }else{
            setCargandoModal(true)
            let api = {}
            if(tipoSoftwareSelected == "Inves_tipo_software_otro"){
                let fechaDesarrolloSoftwareFormat = moment(fechaDesarrolloSoftware).format('YYYY-MM-DD');
                let fechaObtencionRegistroSoftwareFormat = moment(fechaObtencionRegistroSoftware).format('YYYY-MM-DD');
                
                api = {
                    fechaDesarrolloSoftware: fechaDesarrolloSoftwareFormat,
                    fechaObtencionRegistroSoftware: fechaObtencionRegistroSoftwareFormat,
                    tipoSoftwareSelected: tipoSoftwareSelected,
                    otroTipoSoftwareSelected: {codigo: "otroTipoSoftwareSelected", value: otroTipoSoftwareSelected},
                    inputs: data,
                    autores: usuarios,
                    autoresEliminados: usuariosEliminados,
                    paisSelectedTecTecno:paisSelectedTecTecno,
                    tipoDeProducto: props.data.key.producto.codigo,
                    keyId: props.data.key.id,
                }
            }else{
                let fechaDesarrolloSoftwareFormat = moment(fechaDesarrolloSoftware).format('YYYY-MM-DD');
                let fechaObtencionRegistroSoftwareFormat = moment(fechaObtencionRegistroSoftware).format('YYYY-MM-DD');
                
                api = {
                    fechaDesarrolloSoftware: fechaDesarrolloSoftwareFormat,
                    fechaObtencionRegistroSoftware: fechaObtencionRegistroSoftwareFormat,
                    tipoSoftwareSelected: tipoSoftwareSelected,
                    inputs: data,
                    autores: usuarios,
                    paisSelectedTecTecno:paisSelectedTecTecno,
                    autoresEliminados: usuariosEliminados,
                    tipoDeProducto: props.data.key.producto.codigo,
                    keyId: props.data.key.id,
                }
            }

            let url = "api/v1.0/investigacion/editGestionSemillerosTecTecno"
              const callback = (error, estado, resp) => {
                  let titulo = "Ha ocurrido un error, contacte con el administrador."
                  let tipo = "info"

                  if(!error){
                      if(estado === 201){
                          tipo = "success"
                          titulo = resp.titulo
                      }
                  }
                  
                  if(estado == 201){
                      dispatch(
                          actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                      );
                      handleOnClose()
                  }else{
                      dispatch(
                          actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                      );
                      setCargandoModal(false)
                  }
              }
              console.log(api)
              const msg = await Funciones.consulta(url, api, 'POST', callback)
          }
        }
      }else if(props.data.key.producto.codigo == "Inves_tipo_tec_pro_prototipo"){
        if(activeStepBiblio == 1){
          if(usuarios.length == 0){
            let tipo = "info"
            let titulo = "Inserte por lo menos 1 Participante"
            dispatch(
                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                );
          }else{
            setCargandoModal(true)
            let api = {}
            if(productoTieneSelected != "ningunoDiseñoPrototipoTiene"){
                if(tipoPrototipoSelected != "Inves_tipo_prototipo_otro"){
                    let fechaRegistroDiseñoPrototipoTieneFormat = moment(fechaRegistroDiseñoPrototipoTiene).format('YYYY-MM-DD');
                    let fechaCreacionPrototipoFormat = moment(fechaCreacionPrototipo).format('YYYY-MM-DD');
                    api = {
                        inputs: data,
                        productoTieneSelected: productoTieneSelected,
                        inputsExtra: dataInputsExtra,
                        tipoPrototipoSelected: tipoPrototipoSelected,
                        fechaRegistroDiseñoPrototipoTiene: fechaRegistroDiseñoPrototipoTieneFormat,
                        fechaCreacionPrototipo: fechaCreacionPrototipoFormat,
                        autores: usuarios,
                        autoresEliminados: usuariosEliminados,
                        paisSelectedTecTecno:paisSelectedTecTecno,
                        tipoDeProducto: props.data.key.producto.codigo,
                        keyId: props.data.key.id,
                    }
                }else{
                    let fechaRegistroDiseñoPrototipoTieneFormat = moment(fechaRegistroDiseñoPrototipoTiene).format('YYYY-MM-DD');
                    let fechaCreacionPrototipoFormat = moment(fechaCreacionPrototipo).format('YYYY-MM-DD');
                    
                    api = {
                        inputs: data,
                        productoTieneSelected: productoTieneSelected,
                        inputsExtra: dataInputsExtra,
                        tipoPrototipoSelected: tipoPrototipoSelected,
                        otroTipoPrototipoSelected: {codigo: "otroTipoPrototipoSelected", value: otroTipoPrototipoSelected},
                        fechaRegistroDiseñoPrototipoTiene: fechaRegistroDiseñoPrototipoTieneFormat,
                        fechaCreacionPrototipo: fechaCreacionPrototipoFormat,
                        autores: usuarios,
                        autoresEliminados: usuariosEliminados,
                        paisSelectedTecTecno:paisSelectedTecTecno,
                        tipoDeProducto: props.data.key.producto.codigo,
                        keyId: props.data.key.id,
                    }
                }
            }else{
                if(tipoPrototipoSelected != "Inves_tipo_prototipo_otro"){
                    let fechaCreacionPrototipoFormat = moment(fechaCreacionPrototipo).format('YYYY-MM-DD');
                    api = {
                        inputs: data,
                        productoTieneSelected: productoTieneSelected,
                        tipoPrototipoSelected: tipoPrototipoSelected,
                        fechaCreacionPrototipo: fechaCreacionPrototipoFormat,
                        autores: usuarios,
                        autoresEliminados: usuariosEliminados,
                        paisSelectedTecTecno:paisSelectedTecTecno,
                        tipoDeProducto: props.data.key.producto.codigo,
                        keyId: props.data.key.id,
                        inputsExtra: [],
                        fechaRegistroDiseñoPrototipoTiene: fechaCreacionPrototipoFormat,
                    }
                }else{
                    let fechaCreacionPrototipoFormat = moment(fechaCreacionPrototipo).format('YYYY-MM-DD');
                    api = {
                        inputs: data,
                        productoTieneSelected: productoTieneSelected,
                        tipoPrototipoSelected: tipoPrototipoSelected,
                        otroTipoPrototipoSelected: {codigo: "otroTipoPrototipoSelected", value: otroTipoPrototipoSelected},
                        fechaCreacionPrototipo: fechaCreacionPrototipoFormat,
                        autores: usuarios,
                        autoresEliminados: usuariosEliminados,
                        tipoDeProducto: props.data.key.producto.codigo,
                        keyId: props.data.key.id,
                        paisSelectedTecTecno:paisSelectedTecTecno,
                        inputsExtra: [],
                        fechaRegistroDiseñoPrototipoTiene: fechaCreacionPrototipoFormat,
                    }
                }
            } 
            let url = "api/v1.0/investigacion/editGestionSemillerosTecTecno"
            const callback = (error, estado, resp) => {
                let titulo = "Ha ocurrido un error, contacte con el administrador."
                let tipo = "info"

                if(!error){
                    if(estado === 201){
                        tipo = "success"
                        titulo = resp.titulo
                    }
                }
                
                if(estado == 201){
                    dispatch(
                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                    );
                    handleOnClose()
                }else{
                    dispatch(
                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                    );
                    setCargandoModal(false)
                }
            }
            console.log(api)
            const msg = await Funciones.consulta(url, api, 'POST', callback)
          }
        }
      }else if(props.data.key.producto.codigo == "Inves_tipo_tec_pro_signo_distintivo"){
        if(activeStepBiblio == 1){
          if(usuarios.length == 0){
            let tipo = "info"
            let titulo = "Inserte por lo menos 1 Participante"
            dispatch(
                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                );
          }else{
            setCargandoModal(true)
            let fechaCreacionSignoDistintivoFormat = moment(fechaCreacionSignoDistintivo).format('YYYY-MM-DD');
            let api = {
                fechaCreacionSignoDistintivo: fechaCreacionSignoDistintivoFormat,
                tipoSignoDistintivoSelected: tipoSignoDistintivoSelected,
                inputs: data,
                autores: usuarios,
                autoresEliminados: usuariosEliminados,
                paisSelectedTecTecno:paisSelectedTecTecno,
                tipoDeProducto: props.data.key.producto.codigo,
                keyId: props.data.key.id,
            }

            let url = "api/v1.0/investigacion/editGestionSemillerosTecTecno"
            const callback = (error, estado, resp) => {
                let titulo = "Ha ocurrido un error, contacte con el administrador."
                let tipo = "info"

                if(!error){
                    if(estado === 201){
                        tipo = "success"
                        titulo = resp.titulo
                    }
                }
                
                if(estado == 201){
                    dispatch(
                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                    );
                    handleOnClose()
                }else{
                    dispatch(
                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                    );
                    setCargandoModal(false)
                }
            }
            console.log(api)
            const msg = await Funciones.consulta(url, api, 'POST', callback)
          }
        }
      }else if(props.data.key.producto.codigo == "Inves_tipo_tec_pro_empBaseTec"){
        if(activeStepBiblio == 1){
          if(usuarios.length == 0){
            let tipo = "info"
            let titulo = "Inserte por lo menos 1 Participante"
            dispatch(
                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                );
          }else{
            setCargandoModal(true)
            let fechaRegistroBaseTecnoFormat = moment(fechaRegistroBaseTecno).format('YYYY-MM-DD');
            let api
            api = {
                tipoEmpresaSelected: tipoEmpresaSelected,
                fechaRegistroBaseTecno:fechaRegistroBaseTecnoFormat,
                inputs: data,
                autores: usuarios,
                autoresEliminados: usuariosEliminados,
                tipoDeProducto: props.data.key.producto.codigo,
                keyId: props.data.key.id,
            }
            let url = "api/v1.0/investigacion/editGestionSemillerosTecTecno"
            const callback = (error, estado, resp) => {
                let titulo = "Ha ocurrido un error, contacte con el administrador."
                let tipo = "info"
  
                if(!error){
                    if(estado === 201){
                        tipo = "success"
                        titulo = resp.titulo
                    }
                }
                
                if(estado == 201){
                    dispatch(
                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                    );
                    handleOnClose()
                }else{
                    dispatch(
                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                    );
                    setCargandoModal(false)
                }
                
            }
            console.log(api)
            const msg = await Funciones.consulta(url, api, 'POST', callback)
          }
        }
      }else if(props.data.key.producto.codigo == "Inves_tipo_tec_pro_innProProSer"){
        if(activeStepBiblio == 1){
          if(usuarios.length == 0){
            let tipo = "info"
            let titulo = "Inserte por lo menos 1 Participante"
            dispatch(
                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                );
          }else{
            setCargandoModal(true)
            let api
            if(productoTieneSelected != "ningunoDiseñoPrototipoTiene"){
                let fechaRegistroDiseñoPrototipoTieneFormat = moment(fechaRegistroDiseñoPrototipoTiene).format('YYYY-MM-DD');
                let fechaInnovacionProceServiFormat = moment(fechaInnovacionProceServi).format('YYYY-MM-DD');
                
                api = {
                    fechaInnovacionProceServi: fechaInnovacionProceServiFormat,
                    productoTieneSelected: productoTieneSelected,
                    inputs: data,
                    inputsExtra:dataInputsExtra,
                    fechaRegistroDiseñoPrototipoTiene:fechaRegistroDiseñoPrototipoTieneFormat,
                    autores: usuarios,
                    autoresEliminados: usuariosEliminados,
                    paisSelectedTecTecno:paisSelectedTecTecno,
                    tipoDeProducto: props.data.key.producto.codigo,
                    keyId: props.data.key.id,
                }
            }else{
                let fechaInnovacionProceServiFormat = moment(fechaInnovacionProceServi).format('YYYY-MM-DD');
                
                api = {
                    fechaInnovacionProceServi: fechaInnovacionProceServiFormat,
                    fechaRegistroDiseñoPrototipoTiene:fechaInnovacionProceServiFormat,
                    productoTieneSelected: productoTieneSelected,
                    paisSelectedTecTecno:paisSelectedTecTecno,
                    inputs: data,
                    inputsExtra:[],
                    autores: usuarios,
                    autoresEliminados: usuariosEliminados,
                    tipoDeProducto: props.data.key.producto.codigo,
                    keyId: props.data.key.id,
                }
            }

            let url = "api/v1.0/investigacion/editGestionSemillerosTecTecno"
            const callback = (error, estado, resp) => {
                let titulo = "Ha ocurrido un error, contacte con el administrador."
                let tipo = "info"
  
                if(!error){
                    if(estado === 201){
                        tipo = "success"
                        titulo = resp.titulo
                    }
                }
                
                if(estado == 201){
                    dispatch(
                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                    );
                    handleOnClose()
                }else{
                    dispatch(
                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                    );
                    setCargandoModal(false)
                }
                
            }
            console.log(api)
            const msg = await Funciones.consulta(url, api, 'POST', callback)
          }
        }
      }else if(props.data.key.producto.codigo == "Inves_tipo_tec_pro_innGenGesEmp"){
        if(activeStepBiblio == 1){
          if(usuarios.length == 0){
            let tipo = "info"
            let titulo = "Inserte por lo menos 1 Participante"
            dispatch(
                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                );
          }else{
            setCargandoModal(true)
            let api
            if(productoTieneSelected.codigo != "ningunoDiseñoPrototipoTiene"){
                let fechaInnovacionGestionEmpFormat = moment(fechaInnovacionGestionEmp).format('YYYY-MM-DD');
                let fechaRegistroDiseñoPrototipoTieneFormat = moment(fechaRegistroDiseñoPrototipoTiene).format('YYYY-MM-DD');
                
                api = {
                    tipoInnovacionSelected:tipoInnovacionSelected,
                    fechaInnovacionGestionEmp: fechaInnovacionGestionEmpFormat,
                    productoTieneSelected: productoTieneSelected,
                    inputs: data,
                    inputsExtra:dataInputsExtra,
                    fechaRegistroDiseñoPrototipoTiene:fechaRegistroDiseñoPrototipoTieneFormat,
                    autores: usuarios,
                    autoresEliminados: usuariosEliminados,
                    paisSelectedTecTecno:paisSelectedTecTecno,
                    tipoDeProducto: props.data.key.producto.codigo,
                    keyId: props.data.key.id,
                }
            }else{
                let fechaInnovacionGestionEmpFormat = moment(fechaInnovacionGestionEmp).format('YYYY-MM-DD');
                
                api = {
                    fechaInnovacionGestionEmp: fechaInnovacionGestionEmpFormat,
                    tipoInnovacionSelected:tipoInnovacionSelected,
                    productoTieneSelected: productoTieneSelected,
                    fechaRegistroDiseñoPrototipoTiene:fechaInnovacionGestionEmpFormat,
                    inputs: data,
                    inputsExtra:[],
                    autores: usuarios,
                    autoresEliminados: usuariosEliminados,
                    tipoDeProducto: props.data.key.producto.codigo,
                    keyId: props.data.key.id,
                }
            }

            let url = "api/v1.0/investigacion/editGestionSemillerosTecTecno"
            const callback = (error, estado, resp) => {
                let titulo = "Ha ocurrido un error, contacte con el administrador."
                let tipo = "info"
  
                if(!error){
                    if(estado === 201){
                        tipo = "success"
                        titulo = resp.titulo
                    }
                }
                
                if(estado == 201){
                    dispatch(
                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                    );
                    handleOnClose()
                }else{
                    dispatch(
                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                    );
                    setCargandoModal(false)
                }
                
            }
            console.log(api)
            const msg = await Funciones.consulta(url, api, 'POST', callback)
          }
        }
      }else if(props.data.key.producto.codigo == "Inves_tipo_tec_pro_eveArt"){
        if(activeStepBiblio == 1){
            if(usuarios.length == 0){
                let tipo = "info"
                let titulo = "Inserte por lo menos 1 Participante"
                dispatch(
                    actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                    );
            }else{
                setCargandoModal(true)
                let api
                let fechaInicioEventoArtisticoFormat = moment(fechaInicioEventoArtistico).format('YYYY-MM-DD');
                let fechaFinalEventoArtisticoFormat = moment(fechaFinalEventoArtistico).format('YYYY-MM-DD');
                api = {
                    tipoAmbitoSelected:tipoAmbitoSelected,
                    fechaInicioEventoArtistico: fechaInicioEventoArtisticoFormat,
                    fechaFinalEventoArtistico: fechaFinalEventoArtisticoFormat,
                    inputs: data,
                    autores: usuarios,
                    autoresEliminados: usuariosEliminados,
                    paisSelectedTecTecno:paisSelectedTecTecno,
                    tipoDeProducto: props.data.key.producto.codigo,
                    keyId: props.data.key.id,
                }
                let url = "api/v1.0/investigacion/editGestionSemillerosTecTecno"
                const callback = (error, estado, resp) => {
                    let titulo = "Ha ocurrido un error, contacte con el administrador."
                    let tipo = "info"
    
                    if(!error){
                        if(estado === 201){
                            tipo = "success"
                            titulo = resp.titulo
                        }
                    }
                    
                    if(estado == 201){
                        dispatch(
                            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                        );
                        handleOnClose()
                    }else{
                        dispatch(
                            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                        );
                        setCargandoModal(false)
                    }
                    
                }
                console.log(api)
                const msg = await Funciones.consulta(url, api, 'POST', callback)
            }
        }
      }else if(props.data.key.producto.codigo == "Inves_tipo_tec_pro_obrPro"){
        if(activeStepBiblio == 1){
            if(usuarios.length == 0){
                let tipo = "info"
                let titulo = "Inserte por lo menos 1 Participante"
                dispatch(
                    actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                    );
            }else{
                setCargandoModal(true)
                let api
                let fechaCreacionObraProductoFormat = moment(fechaCreacionObraProducto).format('YYYY-MM-DD');
                let fechaPresentacionObraProductoFormat = moment(fechaPresentacionObraProducto).format('YYYY-MM-DD');
                if(tipoEspacioEventoSelected == "Inves_tipo_espaEven_otro"){
                    api = {
                        tipoAmbitoSelected:tipoAmbitoSelected,
                        tipoEspacioEventoSelected:tipoEspacioEventoSelected,
                        tipoDisciplinaAmbitoSelected:tipoDisciplinaAmbitoSelected,
                        fechaCreacionObraProducto: fechaCreacionObraProductoFormat,
                        fechaPresentacionObraProducto: fechaPresentacionObraProductoFormat,
                        otroTipoEspacioEvento: {codigo: "otroTipoEspacioEvento", value: otroTipoEspacioEvento},
                        inputs: data,
                        autores: usuarios,
                        autoresEliminados: usuariosEliminados,
                        paisSelectedTecTecno:paisSelectedTecTecno,
                        tipoDeProducto: props.data.key.producto.codigo,
                        keyId: props.data.key.id,
                    }
                }else{
                    api = {
                        tipoAmbitoSelected:tipoAmbitoSelected,
                        tipoEspacioEventoSelected:tipoEspacioEventoSelected,
                        tipoDisciplinaAmbitoSelected:tipoDisciplinaAmbitoSelected,
                        fechaCreacionObraProducto: fechaCreacionObraProductoFormat,
                        fechaPresentacionObraProducto: fechaPresentacionObraProductoFormat,
                        inputs: data,
                        autores: usuarios,
                        autoresEliminados: usuariosEliminados,
                        paisSelectedTecTecno:paisSelectedTecTecno,
                        tipoDeProducto: props.data.key.producto.codigo,
                        keyId: props.data.key.id,
                    }
                }
                
                let url = "api/v1.0/investigacion/editGestionSemillerosTecTecno"
                const callback = (error, estado, resp) => {
                    let titulo = "Ha ocurrido un error, contacte con el administrador."
                    let tipo = "info"
    
                    if(!error){
                        if(estado === 201){
                            tipo = "success"
                            titulo = resp.titulo
                        }
                    }
                    
                    if(estado == 201){
                        dispatch(
                            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                        );
                        handleOnClose()
                    }else{
                        dispatch(
                            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                        );
                        setCargandoModal(false)
                    }
                    
                }
                console.log(api)
                const msg = await Funciones.consulta(url, api, 'POST', callback)
            }
        }
      }else if(props.data.key.producto.codigo == "Inves_tipo_tec_pro_talCre"){
        if(activeStepBiblio == 1){
            if(usuarios.length == 0){
                let tipo = "info"
                let titulo = "Inserte por lo menos 1 Participante"
                dispatch(
                    actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                    );
            }else{
                setCargandoModal(false)
                let api
                let fechaInicioTalleresCreacionFormat = moment(fechaInicioTalleresCreacion).format('YYYY-MM-DD');
                let fechaFinalTalleresCreacionFormat = moment(fechaFinalTalleresCreacion).format('YYYY-MM-DD');
                
                api = {
                    tipoAmbitoSelected:tipoAmbitoSelected,
                    tipoEventoSelected:tipoEventoSelected,
                    fechaInicioTalleresCreacion: fechaInicioTalleresCreacionFormat,
                    fechaFinalTalleresCreacion: fechaFinalTalleresCreacionFormat,
                    inputs: data,
                    autores: usuarios,
                    paisSelectedTecTecno:paisSelectedTecTecno,
                    autoresEliminados: usuariosEliminados,
                    tipoDeProducto: props.data.key.producto.codigo,
                    keyId: props.data.key.id,
                }

                let url = "api/v1.0/investigacion/editGestionSemillerosTecTecno"
                const callback = (error, estado, resp) => {
                    let titulo = "Ha ocurrido un error, contacte con el administrador."
                    let tipo = "info"
    
                    if(!error){
                        if(estado === 201){
                            tipo = "success"
                            titulo = resp.titulo
                        }
                    }
                    
                    if(estado == 201){
                        dispatch(
                            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                        );
                        handleOnClose()
                    }else{
                        dispatch(
                            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                        );
                        setCargandoModal(false)
                    }
                    
                }
                console.log(api)
                const msg = await Funciones.consulta(url, api, 'POST', callback)
            }
        }
      }
    }
    //Funcion para hacer un reset en el Step
    const handleReset = () => {
        setActiveStepBiblio(0);
    };

    //Funcion para hacer un back en el Step
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    //Funcion para hacer un back en el Step de Bibliografia
    const handleBackTipoBiblio = () => {
        setActiveStepBiblio((prevActiveStep) => prevActiveStep - 1);
    };

    const handleOnClose = () =>{
        setActiveStep(0);
        setActiveStepBiblio(0);
        props.setOpenModal(false)
    }

    return (
        <div >

            <Dialog TransitionComponent={Slide} open={props.openModal} onClose={()=>{handleOnClose()}} aria-labelledby="form-dialog-title">

                <AppBarModal 
                titulo='Editar Información' 
                mostrarModal={()=>{handleOnClose()}} 
                titulo_accion="" a
                ccion="" />
                {/* Contenido del modal del formulario de */}
                <DialogContent className={classes.modalMinWith}>
                  {cargandoModal == true ? <TareasTerminadas imagen={emma_s} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' /> 
                    : vista()}
                </DialogContent>

                <DialogActions>
                    {/* className={classes.backButton}*/}
                    <div>
                        <Button disabled={activeStepBiblio === 0} onClick={handleBackTipoBiblio} >
                            Atras
                        </Button>
                        {activeStepBiblio === steps.length - 1 ? <Button disabled={cargandoModal == true} variant="contained" color="primary" style={{color: "#fff"}} onClick={()=>{handleSendToApi()}}>Enviar</Button> : <Button disabled={cargandoModal == true} variant="contained" color="primary" style={{color: '#fff'}} onClick={() => handleNext()}>Siguiente</Button>}
                    </div>

                </DialogActions>

            </Dialog>
        </div>
    )

}

const ModalStepperFormTecTecno = (props) =>{
    const classes = useStyles()
    return (
        <div className={classes.root}>
          <Stepper activeStep={props.hola} alternativeLabel>
            {props.steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
          {/* Aqui es cuando el Stepper llega al final.*/}
            {props.hola === props.steps.length ? (
              <div>
                <Typography className={classes.instructions}>{props.hola}</Typography>
                <Button onClick={props.handleReset}>Reset</Button>
              </div>
            ) : (
              <div>
                {getStepContent(props.hola, props)}
                </div>
            )}
          </div>
        </div>
    )
}

const getStepContent = (stepIndex, props) => {
    switch (stepIndex) {
        case 0:
            return(
              <ModalProduccionTecForm 
                //Informacion de los inputs
                datos={props.data} //llegan 3 

                cargandoModal={props.cargandoModal}
                setCargandoModal={props.setCargandoModal}

                //Informacion de los inputs
                datos={props.datos}
                setDatos={props.setDatos}
                dataInputsExtra={props.dataInputsExtra}
                setDataInputsExtra={props.setDataInputsExtra}
                //Tipo de producto
                tipoProductoTecTecno={props.tipoProductoTecTecno}
                setTipoProductoTecTecno={props.setTipoProductoTecTecno}

                //Tipo de prototipo
                tiposDePrototipos={props.tiposDePrototipos}
                setTiposDePrototipos={props.setTiposDePrototipos}
                tipoPrototipoSelected={props.tipoPrototipoSelected}
                setTipoPrototipoSelected={props.setTipoPrototipoSelected}

                //Tipo de signo distintivo
                tiposSignoDistintivos={props.tiposSignoDistintivos}
                setTiposSignoDistintivos={props.setTiposSignoDistintivos}
                tipoSignoDistintivoSelected={props.tipoSignoDistintivoSelected}
                setTipoSignoDistintivoSelected={props.setTipoSignoDistintivoSelected}
                
                //Tipo de software
                tiposDeSoftware={props.tiposDeSoftware}
                setTiposDeSoftware={props.setTiposDeSoftware}
                tipoSoftwareSelected={props.tipoSoftwareSelected}
                setTipoSoftwareSelected={props.setTipoSoftwareSelected}

                //Tipo de empresa
                tiposEmpresas={props.tiposEmpresas}
                setTiposEmpresas={props.setTiposEmpresas}
                tipoEmpresaSelected={props.tipoEmpresaSelected}
                setTipoEmpresaSelected={props.setTipoEmpresaSelected}

                //Tipo de Innovacion
                tiposInnovaciones={props.tiposInnovaciones}
                setTiposInnovaciones={props.setTiposInnovaciones}
                tipoInnovacionSelected={props.tipoInnovacionSelected}
                setTipoInnovacionSelected={props.setTipoInnovacionSelected}

                //Tipo de Ambitos
                tiposAmbitos={props.tiposAmbitos}
                setTiposAmbitos={props.setTiposAmbitos}
                tipoAmbitoSelected={props.tipoAmbitoSelected}
                setTipoAmbitoSelected={props.setTipoAmbitoSelected}

                //Tipo de Roles
                tiposRoles={props.tiposRoles}
                setTiposRoles={props.setTiposRoles}
                tipoRolSelected={props.tipoRolSelected}
                setTipoRolSelected={props.setTipoRolSelected}

                //Tipo de Espacio Evento
                tiposEspacioEvento={props.tiposEspacioEvento}
                setTiposEspacioEvento={props.setTiposEspacioEvento}
                tipoEspacioEventoSelected={props.tipoEspacioEventoSelected}
                setTipoEspacioEventoSelected={props.setTipoEspacioEventoSelected}

                //Tipo disciplina ambito
                tiposDisciplinaAmbito={props.tiposDisciplinaAmbito}
                setTiposDisciplinaAmbito={props.setTiposDisciplinaAmbito}
                tipoDisciplinaAmbitoSelected={props.tipoDisciplinaAmbitoSelected}
                setTipoDisciplinaAmbitoSelected={props.setTipoDisciplinaAmbitoSelected}

                //Tipo de eventos
                tiposEventos={props.tiposEventos}
                setTiposEventos={props.setTiposEventos}
                tipoEventoSelected={props.tipoEventoSelected}
                setTipoEventoSelected={props.setTipoEventoSelected}
                
                //Pais
                paisesTecTecno={props.paisesTecTecno}
                setPaisesTecTecno={props.setPaisesTecTecno}
                paisSelectedTecTecno={props.paisSelectedTecTecno}
                setPaisSelectedTecTecno={props.setPaisSelectedTecTecno}

                //fechas llenos
                fechaCreacionDiseñoIndus = {props.fechaCreacionDiseñoIndus}
                setFechaCreacionDiseñoIndus = {props.setFechaCreacionDiseñoIndus} 

                fechaRegistroDiseñoPrototipoTiene = {props.fechaRegistroDiseñoPrototipoTiene}
                setFechaRegistroDiseñoPrototipoTiene= {props.setFechaRegistroDiseñoPrototipoTiene} 

                fechaCreacionPrototipo={props.fechaCreacionPrototipo}
                setFechaCreacionPrototipo={props.setFechaCreacionPrototipo}

                fechaCreacionSignoDistintivo={props.fechaCreacionSignoDistintivo}
                setFechaCreacionSignoDistintivo={props.setFechaCreacionSignoDistintivo}
                
                fechaDesarrolloSoftware={props.fechaDesarrolloSoftware}
                setFechaDesarrolloSoftware={props.setFechaDesarrolloSoftware}

                fechaObtencionRegistroSoftware={props.fechaObtencionRegistroSoftware}
                setFechaObtencionRegistroSoftware={props.setFechaObtencionRegistroSoftware}

                fechaRegistroBaseTecno={props.fechaRegistroBaseTecno}
                setFechaRegistroBaseTecno={props.setFechaRegistroBaseTecno}

                fechaInnovacionProceServi={props.fechaInnovacionProceServi}
                setFechaInnovacionProceServi={props.setFechaInnovacionProceServi}

                fechaInnovacionGestionEmp={props.fechaInnovacionGestionEmp}
                setFechaInnovacionGestionEmp={props.setFechaInnovacionGestionEmp}

                fechaInicioEventoArtistico={props.fechaInicioEventoArtistico}
                setFechaInicioEventoArtistico={props.setFechaInicioEventoArtistico}

                fechaFinalEventoArtistico={props.fechaFinalEventoArtistico}
                setFechaFinalEventoArtistico={props.setFechaFinalEventoArtistico}

                fechaPresentacionObraProducto={props.fechaPresentacionObraProducto}
                setFechaPresentacionObraProducto={props.setFechaPresentacionObraProducto}

                fechaCreacionObraProducto={props.fechaCreacionObraProducto}
                setFechaCreacionObraProducto={props.setFechaCreacionObraProducto}

                fechaInicioTalleresCreacion={props.fechaInicioTalleresCreacion}
                setFechaInicioTalleresCreacion={props.setFechaInicioTalleresCreacion}

                fechaFinalTalleresCreacion={props.fechaFinalTalleresCreacion}
                setFechaFinalTalleresCreacion={props.setFechaFinalTalleresCreacion}

                fechaRegistroEmpresaCultural={props.fechaRegistroEmpresaCultural}
                setFechaRegistroEmpresaCultural={props.setFechaRegistroEmpresaCultural}

                otroTipoEspacioEvento={props.otroTipoEspacioEvento}
                setOtroTipoEspacioEvento={props.setOtroTipoEspacioEvento}
                //Tipo de prototipo tiene
                tiposPrototipoTiene = {props.tiposPrototipoTiene}
                setTiposPrototipoTiene = {props.setTiposPrototipoTiene} 
                productoTieneSelected={props.productoTieneSelected}
                setProductoTieneSelected={props.setProductoTieneSelected}

                //Campos de "OTROS" de los SELECTS
                otroTipoPrototipoSelected={props.otroTipoPrototipoSelected}
                setOtroTipoPrototipoSelected={props.setOtroTipoPrototipoSelected}
                otroTipoSoftwareSelected={props.otroTipoSoftwareSelected}
                setOtroTipoSoftwareSelected={props.setOtroTipoSoftwareSelected}

                //Errores
                getError={props.getError}
                errores={props.errores}
                setErrores={props.setErrores}
              />
            )

        case 1:
            return(
              <ModalFormGestionAutores 
              tipoSolicitudActual={props.tipoSolicitudActual}
              setTipoSolicitudActual={props.setTipoSolicitudActual}
          
              //Variables Autores y Particiapntes
              numeroIdParEvent={props.usuarios}
              setNumeroIdParEvent={props.setUsuarios}
              arrai={props.arrai}
              setArrai={props.setArrai}

              usuariosEliminados = {props.usuariosEliminados}
              setUsuariosEliminados = {props.setUsuariosEliminados}

              rolActive={props.rolActive}
              setRolActive={props.setRolActive}

                //Tipo de Roles
                tiposRoles={props.tiposRoles}
                setTiposRoles={props.setTiposRoles}

              modalidadParticipacionActive={props.modalidadParticipacionActive}
                setModalidadParticipacionActive={props.setModalidadParticipacionActive}
              />
            )
    }
}
const ModalProduccionTecForm = (props) => {
  const classes = useStyles()
  const handleOnchange = async (e) =>{
    if(e.target.name == "productoTiene"){
      props.setCargandoModal(true)
      props.setProductoTieneSelected(e.target.value)
      if(e.target.value != ""){
        let producto = e.target.value
        if(props.errores.length > 0){
            props.setErrores(props.errores.filter(er => er.llave != "productoTiene"));
            // Elimina error cuando se escribe en el campo
        }
        await obtenerPermisosInputExtra(producto)
      }else{
          if(props.errores.length > 0){
              props.setErrores(props.errores.filter(er => er.llave != "productoTiene"));
              // Elimina error cuando se escribe en el campo
          }
          props.setCargandoModal(false)
      }
    }else if(e.target.name == "tipoPrototipoSelected"){
      props.setTipoPrototipoSelected(e.target.value)
      if(props.errores.length > 0){
          props.setErrores(props.errores.filter(er => er.llave != "tipoPrototipoSelected"));
          // Elimina error cuando se escribe en el campo
      }
    }else if(e.target.name == "nombreOtroPrototipo"){
      props.setOtroTipoPrototipoSelected(e.target.value)
      if(props.errores.length > 0){
          props.setErrores(props.errores.filter(er => er.llave != "nombreOtroPrototipo"));
          // Elimina error cuando se escribe en el campo
      }
    }else if(e.target.name == "tipoSignoDistintivoSelected"){
      props.setTipoSignoDistintivoSelected(e.target.value)
      if(props.errores.length > 0){
          props.setErrores(props.errores.filter(er => er.llave != "tipoSignoDistintivoSelected"));
          // Elimina error cuando se escribe en el campo
      }
    }else if(e.target.name == "tipoSoftwareSelected"){
      props.setTipoSoftwareSelected(e.target.value)
      if(props.errores.length > 0){
          props.setErrores(props.errores.filter(er => er.llave != "tipoSoftwareSelected"));
          // Elimina error cuando se escribe en el campo
      }
    }else if(e.target.name == "otroTipoEspacioEvento"){
        props.setOtroTipoEspacioEvento(e.target.value)
        if(props.errores.length > 0){
            props.setErrores(props.errores.filter(er => er.llave != "otroTipoEspacioEvento"));
            // Elimina error cuando se escribe en el campo
        }
    }else if(e.target.name == "nombreOtroSoftware"){
      props.setOtroTipoSoftwareSelected(e.target.value)
      if(props.errores.length > 0){
          props.setErrores(props.errores.filter(er => er.llave != "nombreOtroSoftware"));
          // Elimina error cuando se escribe en el campo
      }
    }else if(e.target.name == "tipoEmpresaSelected"){
      props.setTipoEmpresaSelected(e.target.value)
      if(props.errores.length > 0){
          props.setErrores(props.errores.filter(er => er.llave != "tipoEmpresaSelected"));
         // Elimina error cuando se escribe en el campo
      }
    }else if(e.target.name == "tipoInnovacionSelected"){
      props.setTipoInnovacionSelected(e.target.value)
      if(props.errores.length > 0){
          props.setErrores(props.errores.filter(er => er.llave != "tipoInnovacionSelected"));
          //Elimina error cuando se escribe en el campo
      }
    }else if(e.target.name == "tipoAmbitoSelected"){
      props.setTipoAmbitoSelected(e.target.value)
      if(props.errores.length > 0){
          props.setErrores(props.errores.filter(er => er.llave != "tipoAmbitoSelected"));
          // Elimina error cuando se escribe en el campo
      }
    }else if(e.target.name == "tipoEspacioEventoSelected"){
      props.setTipoEspacioEventoSelected(e.target.value)
      if(props.errores.length > 0){
          props.setErrores(props.errores.filter(er => er.llave != "tipoEspacioEventoSelected"));
          // Elimina error cuando se escribe en el campo
      }
    }else if(e.target.name == "tipoDisciplinaAmbitoSelected"){
      props.setTipoDisciplinaAmbitoSelected(e.target.value)
      if(props.errores.length > 0){
          props.setErrores(props.errores.filter(er => er.llave != "tipoDisciplinaAmbitoSelected"));
          // Elimina error cuando se escribe en el campo
      }
    }else if(e.target.name == "tipoEventoSelected"){
      props.setTipoEventoSelected(e.target.value)
      if(props.errores.length > 0){
          props.setErrores(props.errores.filter(er => er.llave != "tipoEventoSelected"));
          // Elimina error cuando se escribe en el campo
      }
    }else if(e.target.name == "paisSelectedTecTecno"){
        props.setPaisSelectedTecTecno(e.target.value)
        if(props.errores.length > 0){
            props.setErrores(props.errores.filter(er => er.llave != "paisSelectedTecTecno"));
            // Elimina error cuando se escribe en el campo
        }
    }
    else{   
      let array = props.datos.map((item)=>{
          if(item.codigo == e.target.name){
              item.value = e.target.value
              if(props.errores.length > 0){
                  props.setErrores(props.errores.filter(er => er.llave != item.codigo));
                  // Elimina error cuando se escribe en el campo
              }
          }
          
          return item
      })

      let arraySelects = props.dataInputsExtra.map((item)=>{
          if(item.codigo == e.target.name){
              item.value = e.target.value
              if(props.errores.length > 0){
                  props.setErrores(props.errores.filter(er => er.llave != item.codigo));
                  // Elimina error cuando se escribe en el campo
              }
          }
          
          return item
      })
      props.setDatos(array)
      props.setDataInputsExtra(arraySelects)
    }
  }

  const obtenerPermisosInputExtra = async (producto) =>{
    let permisos = await Funciones.obtenerPermisosValorAlt([{ llave: 'principal__codigo', valor: producto }]);
    permisos = permisos.map(({ secundario }) => {
        return {
            id: secundario.id,
            label: secundario.nombre,
            codigo: secundario.codigo,
            error: props.errores,
            value: '' 
        } 
    });
    
    props.setDataInputsExtra(permisos)
    props.setCargandoModal(false)
  }

  return (
    props.cargandoModal == true ? <div> <TareasTerminadas imagen={emma_w} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' /> <LinearProgress /></div> :
    <div>
      <Grid container spacing={3}>

        {props.tipoProductoTecTecno.producto.codigo  == "Inves_tipo_tec_pro_talCre"? 
            <Grid item xs={12}>
                <InputLabel htmlFor="age-native-simple">Tipo Evento: </InputLabel>
                <FormControl className={classes.formControl} fullWidth error = { props.getError('tipoEventoSelected', props.errores).length > 0}>
                    <Select
                        value={props.tipoEventoSelected}
                        displayEmpty
                        fullWidth
                        inputProps={{ 'aria-label': 'Without label' }}
                        onChange={handleOnchange}
                        name="tipoEventoSelected"
                    >
                        <MenuItem value="">
                            <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.tiposEventos.length} Opciones a Elegir` }</em>
                        </MenuItem>
                        {props.tiposEventos.map((item, index) => (
                        <MenuItem key= { index } value = { item.codigo }>{`${item.nombre}`}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormHelperText>{ props.getError('tipoEventoSelected', props.errores) }</FormHelperText>
            </Grid>
        
        : ''}

        {props.tipoProductoTecTecno.producto.codigo == "Inves_tipo_tec_pro_obrPro" ? 
          <Grid item xs={12}>
              <InputLabel htmlFor="age-native-simple">Tipo de espacio o evento: </InputLabel>
              <FormControl className={classes.formControl} fullWidth error = { props.getError('tipoEspacioEventoSelected', props.errores).length > 0}>
                  <Select
                      value={props.tipoEspacioEventoSelected}
                      displayEmpty
                      fullWidth
                      inputProps={{ 'aria-label': 'Without label' }}
                      onChange={handleOnchange}
                      name="tipoEspacioEventoSelected"
                  >
                      <MenuItem value="">
                          <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.tiposEspacioEvento.length} Opciones a Elegir` }</em>
                      </MenuItem>
                      {props.tiposEspacioEvento.map((item, index) => (
                      <MenuItem key= { index } value = { item.codigo }>{`${item.nombre}`}</MenuItem>
                      ))}
                  </Select>
              </FormControl>
              <FormHelperText>{ props.getError('tipoEspacioEventoSelected', props.errores) }</FormHelperText>
          </Grid>
      
        : ''}

        {props.tipoEspacioEventoSelected == "Inves_tipo_espaEven_otro" ? 
            <Grid item xs={12}>
                <TextField 
                    fullWidth 
                    label='Nombre del Otro Espacio o Evento'
                    error = { props.getError('otroTipoEspacioEvento', props.errores).length > 0}
                    helperText = { props.getError('otroTipoEspacioEvento', props.errores) }
                    value={props.otroTipoEspacioEvento} 
                    onChange={handleOnchange}
                    name='otroTipoEspacioEvento'
                />
            </Grid>
        :''}

        {props.tipoProductoTecTecno.producto.codigo == "Inves_tipo_tec_pro_empCreaCul" || props.tipoProductoTecTecno.producto.codigo == "Inves_tipo_tec_pro_obrPro" ? 
          <Grid item xs={12}>
              <InputLabel htmlFor="age-native-simple">Disciplina o Ambito de Origen: </InputLabel>
              <FormControl className={classes.formControl} fullWidth error = { props.getError('tipoDisciplinaAmbitoSelected', props.errores).length > 0}>
                  <Select
                      value={props.tipoDisciplinaAmbitoSelected}
                      displayEmpty
                      fullWidth
                      inputProps={{ 'aria-label': 'Without label' }}
                      onChange={handleOnchange}
                      name="tipoDisciplinaAmbitoSelected"
                  >
                      <MenuItem value="">
                          <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.tiposDisciplinaAmbito.length} Opciones a Elegir` }</em>
                      </MenuItem>
                      {props.tiposDisciplinaAmbito.map((item, index) => (
                      <MenuItem key= { index } value = { item.codigo }>{`${item.nombre}`}</MenuItem>
                      ))}
                  </Select>
              </FormControl>
              <FormHelperText>{ props.getError('tipoDisciplinaAmbitoSelected', props.errores) }</FormHelperText>
          </Grid>
      
        : ''}
        
        {props.tipoProductoTecTecno.producto.codigo == "Inves_tipo_tec_pro_obrPro" || props.tipoProductoTecTecno.producto.codigo == "Inves_tipo_tec_pro_eveArt" || props.tipoProductoTecTecno.producto.codigo == "Inves_tipo_tec_pro_talCre"? 
            <Grid item xs={12}>
                <InputLabel htmlFor="age-native-simple">Ambito: </InputLabel>
                <FormControl className={classes.formControl} fullWidth error = { props.getError('tipoAmbitoSelected', props.errores).length > 0}>
                    <Select
                        value={props.tipoAmbitoSelected}
                        displayEmpty
                        fullWidth
                        inputProps={{ 'aria-label': 'Without label' }}
                        onChange={handleOnchange}
                        name="tipoAmbitoSelected"
                    >
                        <MenuItem value="">
                            <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.tiposAmbitos.length} Opciones a Elegir` }</em>
                        </MenuItem>
                        {props.tiposAmbitos.map((item, index) => (
                        <MenuItem key= { index } value = { item.codigo }>{`${item.nombre}`}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormHelperText>{ props.getError('tipoAmbitoSelected', props.errores) }</FormHelperText>
            </Grid>
        
        : ''}

        {props.tipoProductoTecTecno.producto.codigo == "Inves_tipo_tec_pro_innGenGesEmp" ? 
            <Grid item xs={12}>
                <InputLabel htmlFor="age-native-simple">Tipo de Innovacion: </InputLabel>
                <FormControl className={classes.formControl} fullWidth error = { props.getError('tipoInnovacionSelected', props.errores).length > 0}>
                    <Select
                        value={props.tipoInnovacionSelected}
                        displayEmpty
                        fullWidth
                        inputProps={{ 'aria-label': 'Without label' }}
                        onChange={handleOnchange}
                        name="tipoInnovacionSelected"
                    >
                        <MenuItem value="">
                            <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.tiposInnovaciones.length} Opciones a Elegir` }</em>
                        </MenuItem>
                        {props.tiposInnovaciones.map((item, index) => (
                        <MenuItem key= { index } value = { item.codigo }>{`${item.nombre}`}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormHelperText>{ props.getError('tipoInnovacionSelected', props.errores) }</FormHelperText>
            </Grid>
        
        : ''}

        {props.tipoProductoTecTecno.producto.codigo == "Inves_tipo_tec_pro_empBaseTec" ? 
            <Grid item xs={12}>
                <InputLabel htmlFor="age-native-simple">Tipo de Empresa: </InputLabel>
                <FormControl className={classes.formControl} fullWidth error = { props.getError('tipoEmpresaSelected', props.errores).length > 0}>
                    <Select
                        value={props.tipoEmpresaSelected}
                        displayEmpty
                        fullWidth
                        inputProps={{ 'aria-label': 'Without label' }}
                        onChange={handleOnchange}
                        name="tipoEmpresaSelected"
                    >
                        <MenuItem value="">
                            <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.tiposEmpresas.length} Opciones a Elegir` }</em>
                        </MenuItem>
                        {props.tiposEmpresas.map((item, index) => (
                        <MenuItem key= { index } value = { item.codigo }>{`${item.nombre}`}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormHelperText>{ props.getError('tipoEmpresaSelected', props.errores) }</FormHelperText>
            </Grid>
        
        : ''}

        {props.tipoProductoTecTecno.producto.codigo == "Inves_tipo_tec_pro_software" ? 
            <Grid item xs={12}>
                <InputLabel htmlFor="age-native-simple">Tipo de Software: </InputLabel>
                <FormControl className={classes.formControl} fullWidth error = { props.getError('tipoSoftwareSelected', props.errores).length > 0}>
                    <Select
                        value={props.tipoSoftwareSelected}
                        displayEmpty
                        fullWidth
                        inputProps={{ 'aria-label': 'Without label' }}
                        onChange={handleOnchange}
                        name="tipoSoftwareSelected"
                    >
                        <MenuItem value="">
                            <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.tiposDeSoftware.length} Opciones a Elegir` }</em>
                        </MenuItem>
                        {props.tiposDeSoftware.map((item, index) => (
                        <MenuItem key= { index } value = { item.codigo }>{`${item.nombre}`}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormHelperText>{ props.getError('tipoSoftwareSelected', props.errores) }</FormHelperText>
            </Grid>
        
        : ''}

        {props.tipoSoftwareSelected == "Inves_tipo_software_otro" ? 
            <Grid item xs={12}>
                <TextField 
                    fullWidth 
                    label='Nombre del Otro Software'
                    error = { props.getError('nombreOtroSoftware', props.errores).length > 0}
                    helperText = { props.getError('nombreOtroSoftware', props.errores) }
                    value={props.otroTipoSoftwareSelected} 
                    onChange={handleOnchange}
                    name='nombreOtroSoftware'
                />
            </Grid>
        :''}
        {props.tipoProductoTecTecno.producto.codigo == "Inves_tipo_tec_pro_signo_distintivo" ? 
            <Grid item xs={12}>
                <InputLabel htmlFor="age-native-simple">Tipo de Signo Distintivo: </InputLabel>
                <FormControl className={classes.formControl} fullWidth error = { props.getError('tipoSignoDistintivoSelected', props.errores).length > 0}>
                    <Select
                        value={props.tipoSignoDistintivoSelected}
                        displayEmpty
                        fullWidth
                        inputProps={{ 'aria-label': 'Without label' }}
                        onChange={handleOnchange}
                        name="tipoSignoDistintivoSelected"
                    >
                        <MenuItem value="">
                            <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.tiposSignoDistintivos.length} Opciones a Elegir` }</em>
                        </MenuItem>
                        {props.tiposSignoDistintivos.map((item, index) => (
                        <MenuItem key= { index } value = { item.codigo }>{`${item.nombre}`}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormHelperText>{ props.getError('tipoSignoDistintivoSelected', props.errores) }</FormHelperText>
            </Grid>
        
        : ''}
        {/* Tipo de prototipo */}
        {props.tipoProductoTecTecno.producto.codigo == "Inves_tipo_tec_pro_prototipo"? 
            <Grid item xs={12}>
              <InputLabel htmlFor="age-native-simple">Tipo de Prototipo: </InputLabel>
              <FormControl className={classes.formControl} fullWidth error = { props.getError('tipoPrototipoSelected', props.errores).length > 0}>
                <Select
                  value={props.tipoPrototipoSelected}
                  displayEmpty
                  fullWidth
                  inputProps={{ 'aria-label': 'Without label' }}
                  onChange={handleOnchange}
                  name="tipoPrototipoSelected"
                >
                  <MenuItem value="">
                      <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.tiposDePrototipos.length} Opciones a Elegir` }</em>
                  </MenuItem>
                  {props.tiposDePrototipos.map((item, index) => (
                  <MenuItem key= { index } value = { item.codigo }>{`${item.nombre}`}</MenuItem>
                  ))}
                  </Select>
              </FormControl>
              <FormHelperText>{ props.getError('tipoPrototipoSelected', props.errores) }</FormHelperText>
            </Grid>
        :''}
        {props.tipoPrototipoSelected == "Inves_tipo_prototipo_otro" ? 
            <Grid item xs={12}>
              <TextField 
                fullWidth 
                label='Nombre del Otro Prototipo'
                error = { props.getError('nombreOtroPrototipo', props.errores).length > 0}
                helperText = { props.getError('nombreOtroPrototipo', props.errores) }
                value={props.otroTipoPrototipoSelected} 
                onChange={handleOnchange}
                name='nombreOtroPrototipo'
              />
            </Grid>
        :''}
        {props.tipoProductoTecTecno.producto.codigo == "Inves_tipo_tec_pro_diseñoIndustrial" || props.tipoProductoTecTecno.producto.codigo == "Inves_tipo_tec_pro_prototipo"
        || props.tipoProductoTecTecno.producto.codigo == "Inves_tipo_tec_pro_innProProSer" || props.tipoProductoTecTecno.producto.codigo == "Inves_tipo_tec_pro_innGenGesEmp"? 
          <Grid item xs={12}>
            <InputLabel htmlFor="age-native-simple">El Producto tiene: </InputLabel>
              <FormControl className={classes.formControl} fullWidth error = { props.getError('productoTiene', props.errores).length > 0}>
                  <Select
                      value={props.productoTieneSelected}
                      displayEmpty
                      fullWidth
                      inputProps={{ 'aria-label': 'Without label' }}
                      onChange={handleOnchange}
                      name="productoTiene"
                  >
                      <MenuItem value="">
                          <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.tiposPrototipoTiene.length} Opciones a Elegir` }</em>
                      </MenuItem>
                      {props.tiposPrototipoTiene.map((item, index) => (
                      <MenuItem key= { index } value = { item.codigo }>{`${item.nombre}`}</MenuItem>
                      ))}
                  </Select>
              </FormControl>
            <FormHelperText>{ props.getError('productoTiene', props.errores) }</FormHelperText>
          </Grid>
        : ''}
        {props.productoTieneSelected == "patenteDiseñoPrototipoTiene" || props.productoTieneSelected == "registroDiseñoPrototipoTiene" || props.productoTieneSelected == "secreEmpDiseñoPrototipoTiene"? 
          props.dataInputsExtra.map((item, index) => {
              if(item.codigo.includes('fecha')){
                if(item.codigo == "fechaRegistroDiseñoPrototipoTiene"){
                    return(
                        <Grid item xs={12} key={index}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <Grid item xs={12}> 
                                <Fragment>
                                <KeyboardDatePicker
                                    required={true}
                                    style={{ marginTop: 3, width: '100%' }}
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    label={item.label}
                                    value={props.fechaRegistroDiseñoPrototipoTiene}
                                    onChange={newDate => props.setFechaRegistroDiseñoPrototipoTiene(newDate)} 
                                    name={item.codigo}
                                    fullWidth
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    invalidDateMessage='Formato Invalido'
                                />
                                </Fragment> 
                            </Grid>
                            </MuiPickersUtilsProvider>
                        </Grid>
                    )
                }
            }else{
                return(
                    <Grid item xs={12} key={index}>
                      <TextField 
                      fullWidth 
                      label={item.label}
                      error = { props.getError(item.codigo, props.errores).length > 0}
                      helperText = { props.getError(item.codigo, props.errores) }
                      value={item.value} 
                      onChange={handleOnchange}
                      name={item.codigo}
                      />
                    </Grid>
                )
            }
          })
        :''}

        {props.datos.map((item, index)=>{
          if(item.codigo.includes('fecha')){
            if(item.codigo == "fechaCreacion"){
              return(
                <Grid item xs={12} key={index}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} > 
                  <Grid item xs={12}>
                    <Fragment>
                    <KeyboardDatePicker
                      required={true}
                      style={{ marginTop: 3, width: '100%' }}
                      format="yyyy-MM-dd"
                      margin="normal"
                      label={item.label}
                      value={props.fechaCreacionDiseñoIndus}
                      onChange={newDate => props.setFechaCreacionDiseñoIndus(newDate)} 
                      name={item.codigo}
                      fullWidth
                      KeyboardButtonProps={{
                          'aria-label': 'change date',
                      }}
                      invalidDateMessage='Formato Invalido'
                    />
                    </Fragment> 
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
              )
            }else if(item.codigo == "fechaCreacionPrototipo"){
              return(
                  <Grid item xs={12} key={index}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} > 
                      <Grid item xs={12}>
                          <Fragment>
                          <KeyboardDatePicker
                              required={true}
                              style={{ marginTop: 3, width: '100%' }}
                              format="yyyy-MM-dd"
                              margin="normal"
                              label={item.label}
                              value={props.fechaCreacionPrototipo}
                              onChange={newDate => props.setFechaCreacionPrototipo(newDate)} 
                              name={item.codigo}
                              fullWidth
                              KeyboardButtonProps={{
                                  'aria-label': 'change date',
                              }}
                              invalidDateMessage='Formato Invalido'
                          />
                          </Fragment> 
                        </Grid>
                      </MuiPickersUtilsProvider>
                  </Grid>
              )
            }else if(item.codigo == "fechaCreacionSignoDistintivo"){
              return(
                  <Grid item xs={12} key={index}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} > 
                      <Grid item xs={12}>
                          <Fragment>
                          <KeyboardDatePicker
                              required={true}
                              style={{ marginTop: 3, width: '100%' }}
                              format="yyyy-MM-dd"
                              margin="normal"
                              label={item.label}
                              value={props.fechaCreacionSignoDistintivo}
                              onChange={newDate => props.setFechaCreacionSignoDistintivo(newDate)} 
                              name={item.codigo}
                              fullWidth
                              KeyboardButtonProps={{
                                  'aria-label': 'change date',
                              }}
                              invalidDateMessage='Formato Invalido'
                          />
                          </Fragment> 
                          </Grid>
                      </MuiPickersUtilsProvider>
                  </Grid>
              )
          }else if(item.codigo == "fechaDesarrolloSoftware"){
            return(
                <Grid item xs={12} key={index}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} > 
                    <Grid item xs={12}>
                        <Fragment>
                        <KeyboardDatePicker
                            required={true}
                            style={{ marginTop: 3, width: '100%' }}
                            format="yyyy-MM-dd"
                            margin="normal"
                            label={item.label}
                            value={props.fechaDesarrolloSoftware}
                            onChange={newDate => props.setFechaDesarrolloSoftware(newDate)} 
                            name={item.codigo}
                            fullWidth
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            invalidDateMessage='Formato Invalido'
                        />
                        </Fragment> 
                        </Grid>
                    </MuiPickersUtilsProvider>
                </Grid>
            )
          }else if(item.codigo == "fechaObtencionRegistroSoftware"){
            return(
                <Grid item xs={12} key={index}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} > 
                    <Grid item xs={12}>
                        <Fragment>
                        <KeyboardDatePicker
                            required={true}
                            style={{ marginTop: 3, width: '100%' }}
                            format="yyyy-MM-dd"
                            margin="normal"
                            label={item.label}
                            value={props.fechaObtencionRegistroSoftware}
                            onChange={newDate => props.setFechaObtencionRegistroSoftware(newDate)} 
                            name={item.codigo}
                            fullWidth
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            invalidDateMessage='Formato Invalido'
                        />
                        </Fragment> 
                        </Grid>
                    </MuiPickersUtilsProvider>
                </Grid>
            )
          }else if(item.codigo == "fechaRegistroBaseTecno"){
            return(
                <Grid item xs={12} key={index}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} >
                    <Grid item xs={12}> 
                        <Fragment>
                        <KeyboardDatePicker
                            required={true}
                            style={{ marginTop: 3, width: '100%' }}
                            format="yyyy-MM-dd"
                            margin="normal"
                            label={item.label}
                            value={props.fechaRegistroBaseTecno}
                            onChange={newDate => props.setFechaRegistroBaseTecno(newDate)} 
                            name={item.codigo}
                            fullWidth
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            invalidDateMessage='Formato Invalido'
                        />
                        </Fragment> 
                        </Grid>
                    </MuiPickersUtilsProvider>
                </Grid>
            )
          }else if(item.codigo == "fechaInnovacionProceServi"){
            return(
                <Grid item xs={12} key={index}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} > 
                    <Grid item xs={12}>
                        <Fragment>
                        <KeyboardDatePicker
                            required={true}
                            style={{ marginTop: 3, width: '100%' }}
                            format="yyyy-MM-dd"
                            margin="normal"
                            label={item.label}
                            value={props.fechaInnovacionProceServi}
                            onChange={newDate => props.setFechaInnovacionProceServi(newDate)} 
                            name={item.codigo}
                            fullWidth
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            invalidDateMessage='Formato Invalido'
                        />
                        </Fragment> 
                        </Grid>
                    </MuiPickersUtilsProvider>
                </Grid>
            )
          }else if(item.codigo == "fechaInnovacionGestionEmp"){
            return(
                <Grid item xs={12} key={index}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} > 
                    <Grid item xs={12}>
                        <Fragment>
                        <KeyboardDatePicker
                            required={true}
                            style={{ marginTop: 3, width: '100%' }}
                            format="yyyy-MM-dd"
                            margin="normal"
                            label={item.label}
                            value={props.fechaInnovacionGestionEmp}
                            onChange={newDate => props.setFechaInnovacionGestionEmp(newDate)} 
                            name={item.codigo}
                            fullWidth
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            invalidDateMessage='Formato Invalido'
                        />
                        </Fragment> 
                    </Grid>
                    </MuiPickersUtilsProvider>
                </Grid>
            )
          }else if(item.codigo == "fechaInicioEventoArtistico"){
            return(
                <Grid item xs={12} key={index}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} > 
                    <Grid item xs={12}>
                        <Fragment>
                        <KeyboardDatePicker
                            required={true}
                            style={{ marginTop: 3, width: '100%' }}
                            format="yyyy-MM-dd"
                            margin="normal"
                            label={item.label}
                            value={props.fechaInicioEventoArtistico}
                            onChange={newDate => props.setFechaInicioEventoArtistico(newDate)} 
                            name={item.codigo}
                            fullWidth
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            invalidDateMessage='Formato Invalido'
                        />
                        </Fragment>
                        </Grid> 
                    </MuiPickersUtilsProvider>
                </Grid>
            )
          }else if(item.codigo == "fechaFinalEventoArtistico"){
            return(
                <Grid item xs={12} key={index}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} > 
                    <Grid item xs={12}>
                        <Fragment>
                        <KeyboardDatePicker
                            required={true}
                            style={{ marginTop: 3, width: '100%' }}
                            format="yyyy-MM-dd"
                            margin="normal"
                            label={item.label}
                            value={props.fechaFinalEventoArtistico}
                            onChange={newDate => props.setFechaFinalEventoArtistico(newDate)} 
                            name={item.codigo}
                            fullWidth
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            invalidDateMessage='Formato Invalido'
                        />
                        </Fragment> 
                    </Grid>
                    </MuiPickersUtilsProvider>
                </Grid>
            )
          }else if(item.codigo == "fechaPresentacionObraProducto"){
            return(
                <Grid item xs={12} key={index}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} > 
                    <Grid item xs={12}>
                        <Fragment>
                        <KeyboardDatePicker
                            required={true}
                            style={{ marginTop: 3, width: '100%' }}
                            format="yyyy-MM-dd"
                            margin="normal"
                            label={item.label}
                            value={props.fechaPresentacionObraProducto}
                            onChange={newDate => props.setFechaPresentacionObraProducto(newDate)} 
                            name={item.codigo}
                            fullWidth
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            invalidDateMessage='Formato Invalido'
                        />
                        </Fragment> 
                    </Grid>
                    </MuiPickersUtilsProvider>
                </Grid>
            )
          }else if(item.codigo == "fechaCreacionObraProducto"){
            return(
                <Grid item xs={12} key={index}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} > 
                        <Fragment>
                        <KeyboardDatePicker
                            required={true}
                            style={{ marginTop: 3, width: '100%' }}
                            format="yyyy-MM-dd"
                            margin="normal"
                            label={item.label}
                            value={props.fechaCreacionObraProducto}
                            onChange={newDate => props.setFechaCreacionObraProducto(newDate)} 
                            name={item.codigo}
                            fullWidth
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                        </Fragment> 
                    </MuiPickersUtilsProvider>
                </Grid>
            )
          }else if(item.codigo == "fechaInicioTalleresCreacion"){
            return(
                <Grid item xs={12} key={index}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} > 
                    <Grid item xs={12}>
                        <Fragment>
                        <KeyboardDatePicker
                            required={true}
                            style={{ marginTop: 3, width: '100%' }}
                            format="yyyy-MM-dd"
                            margin="normal"
                            label={item.label}
                            value={props.fechaInicioTalleresCreacion}
                            onChange={newDate => props.setFechaInicioTalleresCreacion(newDate)} 
                            name={item.codigo}
                            fullWidth
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            invalidDateMessage='Formato Invalido'
                        />
                        </Fragment> 
                    </Grid>
                    </MuiPickersUtilsProvider>
                </Grid>
            )
          }else if(item.codigo == "fechaFinalTalleresCreacion"){
            return(
                <Grid item xs={12} key={index}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} > 
                    <Grid item xs={12}>
                        <Fragment>
                        <KeyboardDatePicker
                            required={true}
                            style={{ marginTop: 3, width: '100%' }}
                            format="yyyy-MM-dd"
                            margin="normal"
                            label={item.label}
                            value={props.fechaFinalTalleresCreacion}
                            onChange={newDate => props.setFechaFinalTalleresCreacion(newDate)} 
                            name={item.codigo}
                            fullWidth
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            invalidDateMessage='Formato Invalido'
                        />
                        </Fragment> 
                    </Grid>
                    </MuiPickersUtilsProvider>
                </Grid>
            )
          }else if(item.codigo == "fechaRegistroEmpresaCultural"){
            return(
                <Grid item xs={12} key={index}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} > 
                    <Grid item xs={12}>
                        <Fragment>
                        <KeyboardDatePicker
                            required={true}
                            style={{ marginTop: 3, width: '100%' }}
                            format="MM/dd/yyyy"
                            margin="normal"
                            label={item.label}
                            value={props.fechaRegistroEmpresaCultural}
                            onChange={newDate => props.setFechaRegistroEmpresaCultural(newDate)} 
                            name={item.codigo}
                            fullWidth
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            invalidDateMessage='Formato Invalido'
                        />
                        </Fragment>
                    </Grid> 
                    </MuiPickersUtilsProvider>
                </Grid>
            )
          }
        }else if(item.codigo.includes('pais')){
            return(<Grid item xs={12}>
                <InputLabel htmlFor="age-native-simple">País</InputLabel>
                    <FormControl className={classes.formControl} fullWidth error = { props.getError('paisSelectedTecTecno', props.errores).length > 0}>
                        <Select
                            value={props.paisSelectedTecTecno}
                            displayEmpty
                            fullWidth
                            inputProps={{ 'aria-label': 'Without label' }}
                            onChange={handleOnchange}
                            name="paisSelectedTecTecno"
                        >
                            <MenuItem value="">
                                <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.paisesTecTecno.length} Paises para Seleccionar` }</em>
                            </MenuItem>
                            {props.paisesTecTecno.map((item, index) => (
                            <MenuItem key= { index } value = { item.id }>{`${item.nombre}`}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                <FormHelperText>{ props.getError('paisSelectedTecTecno', props.errores) }</FormHelperText>
            </Grid> )
        }else{
            return(
              <Grid item xs={12} key={index}>
                <TextField 
                fullWidth 
                label={item.label}
                error = { props.getError(item.codigo, props.errores).length > 0}
                helperText = { props.getError(item.codigo, props.errores) }
                value={item.value} 
                onChange={handleOnchange}
                name={item.codigo}
                />
              </Grid> 
            )
          }
        })}
      </Grid>
    </div>
  )
}

const ModalFormGestionAutores = (props) => {

  const [openModalAddParti, setopenModalAddParti] = React.useState(false);
  const actions = (requisito) => {

    const eventoDetalle = (requisito) => {
      if(requisito.nuevo == false){
          props.usuariosEliminados.push(requisito)
      }
      let i = props.numeroIdParEvent.indexOf(requisito)
      let filtredData = ''
      let filtredDataArrai = ''
      if ( i !== -1 ) {
        filtredData = props.numeroIdParEvent.filter(item => item.id != requisito.id);
        filtredDataArrai = props.arrai.filter(item => item.id != requisito.id);
      }
      props.setNumeroIdParEvent(filtredData)
      props.setArrai(filtredDataArrai)
  }

    let detalle = <BtnEliminar callback={() => eventoDetalle(requisito)} />;
    
    return detalle

  }

  return(
      props.cargando == true ? <div> <TareasTerminadas imagen={emma_w} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' /> <LinearProgress /></div> :
      <div>
      <ListarDatos
          id="tbl_listar_encargados"
          datos={props.numeroIdParEvent}
          opciones={true}
          agregar={true}
          buscar={true}
          actAgregar={() => setopenModalAddParti(true)}
          css={{
          list: { padding: '0px 0px 20px 0px' },
          appBar: { padding: '0px 0px 0px 0px' },
          }}
          acciones={(row) => actions(row)}
          titulo={"Lista de Autores"}
          fila_principal={({ nombre, id }) => `${nombre}`}
          filas={[
          {
              'mostrar': ({ nombre }) => `${nombre}`,
              'id': 'nombre', 'enLista': false
          },
          {
              'mostrar': ({ correo }) => `${correo}`,
              'id': 'nombre', 'enLista': true
          },
          {
              'mostrar': ({ identificacion }) => `${identificacion}`,
              'id': 'nombre', 'enLista': true
          },
          {
            'mostrar': ({ rolDeParticipante }) => {
                if(rolDeParticipante != null){
                    return `${rolDeParticipante.nombre}`
                }else{
                    return ''
                }
            },
            'id': 'nombre', 'enLista': true
            },
          { 'id': 'descripcion', 'enLista': false },
          ]}
      />
    
      <SemilleroAddParti 
          openModalAddParti={openModalAddParti}
          setopenModalAddParti={setopenModalAddParti}

          participantes={props.numeroIdParEvent}
          setParticipantes={props.setNumeroIdParEvent}

          arrai={props.arrai}
          setArrai={props.setArrai}

          modalidadParticipacion={props.modalidadParticipacionActive}
          rol={props.rolActive}

          tiposModalidadParticipacion={props.tiposRoles}
      />
  </div>
  )

}
export default SemillerosGestionEditTecTecno 