import React, { useState } from "react";
import { useHistory } from "react-router-dom";

// Material UI
import {
  Avatar,
  Box,
  ButtonBase,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from "@material-ui/core";
import AppBarModal from "../../general/AppBarModal";
import { makeStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";

// Imagenes
import solicitud_grado from '../../../global/imagenes/graduated.png';
import encuesta_grado from '../../../global/imagenes/encuesta.png';

// Componentes
import ModalCrearSolicitud from "./ModalCrearSolicitudGrado";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 250,
    textAlign: "left",
  },
  media: {
    width: 200,
    height: 200,
    objectFit: 'cover',
    objectPosition: 'center center',
    paddingTop: '56.25%',
    margin: 30

  },
  avatar: {
    backgroundColor: red[500],
  },
}));

const opciones = [
  {
    codigo: "Sol_Grad",
    titulo: "Solicitud de Grado",
    // mensaje: "Realiza la solicitud de grado para obtener tu título.",
    imagen: solicitud_grado,
  },
  {
    codigo: "Enc_Grad",
    titulo: "Realizar Encuesta de Graduados",
    // mensaje: "Realiza la encuesta de graduados para mejorar la calidad educativa. ",
    imagen: encuesta_grado,
  },
];

const ModalSolicitudGrado = ({
  open,
  setOpen,
  tipo,
  misDatos,
  setMisDatos,
  actualizarMensaje,
  mostrarAdjuntarVolante,
  modalAdjuntarVolante,
}) => {
  const [modalSolicitud, setModalSolicitud] = useState(false);

  const classes = useStyles();

  return (
    <div>
      <Dialog
        onClose={() => setOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="md"
        className="scroll"
      >
        <AppBarModal
          titulo="Solicitud de Grado"
          mostrarModal={() => setOpen(false)}
          titulo_accion="Cerrar"
          accion={() => setOpen(false)}
        />
        <DialogContent>
          <Box m={4}>
            <Grid
              container
              justifyContent={"space-evenly"}
              alignItems={"center"}
              spacing={3}
            >
              {opciones.map((opcion, index) => (
                <Grid item xs={12} md={6} lg={4} key={index}>
                  <ButtonBase
                    key={index}
                    onClick={() => {
                      if (opcion.codigo === "Sol_Grad") {
                        setModalSolicitud(true);
                      } else if (opcion.codigo === "Enc_Grad") {
                        window.open("/encuesta_graduados", "_blank");
                      }
                    }}
                  >
                    <Card
                      className={classes.root}
                      elevation={3}
                    >
                      <CardHeader
                        avatar={
                          <Avatar
                            aria-label="recipe"
                            className={classes.avatar}
                          >
                            {opcion.titulo[0]}
                          </Avatar>
                        }
                        title={opcion.titulo}
                      />
                      <CardMedia
                        className={classes.media}
                        image={opcion.imagen}
                        title={`Solicitar ${opcion.titulo}`}
                      />
                      <CardContent>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          {opcion.mensaje}
                        </Typography>
                      </CardContent>
                    </Card>
                  </ButtonBase>
                </Grid>
              ))}
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
      <ModalCrearSolicitud
        open={modalSolicitud}
        setOpen={() => setModalSolicitud(false)}
        tipo={tipo}
        misDatos={misDatos}
        setMisDatos={setMisDatos}
        actualizarMensaje={actualizarMensaje}
        mostrarAdjuntarVolante={mostrarAdjuntarVolante}
        modalAdjuntarVolante={modalAdjuntarVolante}
      />
    </div>
  );
};

export default ModalSolicitudGrado;
