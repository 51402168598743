import React from "react";
import { Grid, Dialog, DialogActions, DialogContent, StepLabel, Stepper, Step } from "@material-ui/core";
import { BtnForm } from "../../general/BotonesAccion";
import AppBarModal from "../../general/AppBarModal";
import { PintarDatosIniciales } from "./PintarFormulario";
import { Hidden } from "@material-ui/core";


// const steps = ["Datos iniciales"];



export function OpenNewModal({openModalNuevo, activeStep, closeModalNuevo, celular, telefono, correo_personal, cargando, handleSubmit, onChange, programas, abrirProgramas, programa}) {
    
    const pintar  = (app, claseN = "") => {
        return (
          <Dialog open={openModalNuevo} onClose={closeModalNuevo} fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} >
            <AppBarModal titulo={"Nueva Inscripción"} mostrarModal={closeModalNuevo} titulo_accion="" accion="" />
            <DialogContent className="scroll">
              <Grid container>
                <Grid item xs={12} md={12} style={{'paddingTop': '10px'}}>
                  <PintarDatosIniciales correo_personal={correo_personal} celular={celular} telefono={telefono} cargando={cargando} onChange={onChange} programas={programas} abrirProgramas={abrirProgramas} programa={programa}/>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              {/* <div> */}
                <BtnForm texto="GUARDAR" callback={handleSubmit} type="submit" />
              {/* </div> */}
              {/* <BtnForm texto="ATRAS" callback={() => this.handleStep(false)} /> */}
            </DialogActions>
          </Dialog>
        );
    }
    return (
      <div>
        <div>
          <Hidden xsDown> {pintar(false, "menu_modulo")} </Hidden>
          <Hidden smUp> {pintar(true, "menu_modulo_app")}</Hidden>
        </div>
      </div>
    );
}