import React, { Component } from 'react';
import { Dialog, DialogContent, DialogActions, List, ListItem, ListItemText, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import AppBarModal from '../../general/AppBarModal';
import TareasTerminadas from '../../general/TareasTerminadas';
import { BtnForm, } from '../../general/BotonesAccion';
import ListarDatos from '../../general/ListarDatos';
import { coloresEmma, consulta, generarFiltros } from '../../../global/js/funciones'
import emma_w from './../../../global/imagenes/emma_w.png';
import { MenuDetalle } from "./MenuAutodiagnostico";
import moment from 'moment'


class AutodiagnosticoDetalle extends Component {
  constructor(props) {
    super(props)
    this.state = {
      respuestas: [],
      estados: [],
      cargando: false,
      seleccion: '',
    };
  }

  async componentDidUpdate({ modalRespuestas }, { seleccion }) {
    let { seleccion: seleccionNew } = this.state
    let { autodiagnostico, modalRespuestas: modalRespuestasNew } = this.props
    if (modalRespuestas !== modalRespuestasNew) this.setState({ seleccion: modalRespuestasNew ? 'detalle' : '' })

    if (seleccionNew !== seleccion) {
      if (seleccionNew === 'detalle') this.obtenerRespuestas(autodiagnostico.id)
      else if (seleccionNew === 'estados') this.obtenerEstados(autodiagnostico.id);
    }
  }

  async obtenerRespuestas(id) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'autodiagnostico', 'valor': id }]);
    consulta(`api/v1.0/autodiagnostico/respuestas?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, respuestas: resp });
    })
  }

  async obtenerEstados(id) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'autodiagnostico', 'valor': id }]);
    consulta(`api/v1.0/autodiagnostico/estados?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, estados: resp });
    })
  }

  pintarDetalle = () => {
    let { respuestas } = this.state
    let { autodiagnostico } = this.props

    return (
      <>
        {autodiagnostico &&
          <Table>
            <TableBody>
              <TableRow key={1}>
                <TableCell component="th" scope="row">Nombre</TableCell>
                <TableCell align="left">{`${autodiagnostico.usuario_registro.primer_nombre} ${autodiagnostico.usuario_registro.primer_apellido} ${autodiagnostico.usuario_registro.segundo_apellido}`}</TableCell>
              </TableRow>
              <TableRow key={2}>
                <TableCell component="th" scope="row">Identificacion</TableCell>
                <TableCell align="left">{autodiagnostico.usuario_registro.identificacion}</TableCell>
              </TableRow>
              <TableRow key={3}>
                <TableCell component="th" scope="row">Telefono</TableCell>
                <TableCell align="left">{autodiagnostico.usuario_registro.celular}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        }
        <div>
          <List style={{ backgroundColor: 'white', borderBottom: '1px solid #eeeeee' }}>
            {respuestas.map(({ pregunta, respuesta }, i) => (
              <ListItem alignItems="flex-start" key={i}>
                <ListItemText
                  primary={`${i + 1}) ${pregunta.nombre} `}
                  secondary={
                    <React.Fragment>
                      Respuesta : {respuesta.nombre}
                    </React.Fragment>
                  }
                />
              </ListItem>
            ))}
          </List>
        </div>
      </>
    )
  }

  pintarEstados = () => {
    let { admin } = this.props;
    let { estados } = this.state;

    const obtenerColor = (codigo) => {
      let color = 'white';
      const colores = {
        'Aut_Env': { 'backgroundColor': coloresEmma.colorblanco, 'color': '#757575' },
        'Aut_Pen': { 'backgroundColor': coloresEmma.solicitudactiva, color },
        'Aut_Rev': { 'backgroundColor': coloresEmma.solicitudenrevision, color },
        'Aut_Com': { 'backgroundColor': coloresEmma.solicitudfinalizada, color },
      }
      return (colores[codigo])
    }

    return (
      <ListarDatos
        id="tbl_estados_beca"
        datos={estados}
        avatar={({ tipo_estado }) => tipo_estado.nombre.charAt(0).toUpperCase()}
        color_avatar={({ tipo_estado: { codigo } }) => obtenerColor(codigo)}
        fila_principal={({ tipo_estado }) => tipo_estado.nombre.toUpperCase()}
        filas={[
          { 'id': 'usurio_registro', 'enLista': true, 'mostrar': ({ usuario_registro }) => `${usuario_registro.primer_nombre} ${usuario_registro.primer_apellido} ${usuario_registro.segundo_apellido}`.toUpperCase() },
          { 'id': 'fecha_registro', 'enLista': true, 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a') },
          { 'id': 'mensaje', 'enLista': true, 'mostrar': ({ observacion, tipo_estado }) => (admin || tipo_estado.codigo == 'Aut_Env') && observacion },
        ]}
      />
    )
  };

  pintar = () => {
    let { cargando, seleccion } = this.state;
    if (cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />;
    return (
      <div>
        {seleccion === 'detalle' && this.pintarDetalle()}
        {seleccion === 'estados' && this.pintarEstados()}
      </div>
    )

  }

  render() {
    let { seleccion } = this.state
    let { modalRespuestas, mostrarModalRespuestas } = this.props
    return (
      <Dialog open={modalRespuestas} maxWidth="sm" fullWidth={true} onClose={() => mostrarModalRespuestas(false)}>
        <AppBarModal titulo='¡ Detalle Autodiagnóstico !' mostrarModal={mostrarModalRespuestas} titulo_accion='' />
        <MenuDetalle
          sele={seleccion}
          seleccionar={seleccion => this.setState({ seleccion })}
        />
        <DialogContent style={{ padding: 0 }} className='scroll' >
          {this.pintar()}
        </DialogContent>
        <DialogActions>
          <BtnForm texto="Cerrar" callback={() => mostrarModalRespuestas(false)} />
        </DialogActions>
      </Dialog>
    )
  }
}

AutodiagnosticoDetalle.defaultProps = {
  admin: false
}


export default AutodiagnosticoDetalle