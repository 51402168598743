//Importaciones necesaria para poder utilizar react
import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { mostrarModulo } from '../../redux/actions/actGlobal';
import MenuSemilleros from './Hijos/MenuSemilleros'
import TareasTerminadas from '../general/TareasTerminadas';
import emma_w from '../../global/imagenes/emma_w.png';
import emma_s from '../../global/imagenes/emma_s1.png';
import LogoCuc from '../../global/imagenes/logo_cuc3.png';
import firma_curso from '../../global/imagenes/firma_curso.png';
import * as Funciones from '../../global/js/funciones';
import TituloAccion from "../general/TituloAccion";
import { Grid, Paper, Button, Typography, Card, CardHeader, CardContent, Avatar } from '@material-ui/core'
import SemilleroCardSolicitud from './Hijos/SemilleroCardSolicitud';
import SemillerosSolicitudes from './SemillerosSolicitudes';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import revisar from '../../global/imagenes/revisar.png';
import AccionesRapidas from '../general/AccionesRapidas'
import SemillerosListSemi from './Hijos/SemillerosListSemi'
import SemilleroSolicitudesEst from './Hijos/SemilleroSolicitudesEst'
import SemilleroCardParticipante from './Hijos/SemilleroCardParticipante';
import { SemilleroTableParticipante } from './Hijos/SemilleroTables'
import { Document, Image, Page, PDFDownloadLink, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import sourcebold from './../../global/fonts/PTSerif-Bold.ttf'
import file from '../../global/imagenes/file.png'
import moment from 'moment';
import Semilleros from './Semilleros'
import { Link } from 'react-router-dom';
import { coloresEmma } from '../../global/js/funciones';
const useStyles = makeStyles((theme) => ({
  contenidoModulo: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: "90px !important"
    },
  }
}));
Font.register({ family: 'PTSerifBold', src: sourcebold });
const styles = StyleSheet.create({
  body: {
    border: '1.1px solid #DFDDDD',
    width: '100%',
    height: '100%',
    paddingBottom: '60px',
    paddingLeft: '45px',
    paddingRight: '45px'
  },
  imagen: {
    width: '100%',
    display: 'flex',
    margin: '20px 0 20px 0',
    justifyContent: 'center',
    alignItems: 'center'
  },
  img: {
    objectFit: 'cover',
    width: '200px'
  },
  titular: {
    fontSize: '12px',
    textAlign: 'center',
    margin: '10px 0 10px 0',
    fontFamily: 'PTSerifBold',

  },
  negrita: {
    fontFamily: 'PTSerifBold',
    lineHeight: 1.3,
    textAlign: 'justify',
  },
  parrafo: {
    fontSize: '12px',
    margin: '20px 0 0 0',
    textAlign: 'justify',
    lineHeight: 1.3,
  },
  parrafo1: {
    fontSize: '12px',
    margin: '70px 0 0 0',
    textAlign: 'justify',
  },
  firma: {
    paddingLeft: '10px',
    width: '100px',
    height: '135px',
    display: 'flex',
    margin: '35px 0 -80px -5px'
  },
  piefirma: {
    fontFamily: 'PTSerifBold',
    fontSize: '13px',
  },
  piefirma1: {
    fontSize: '13px',
    textAlign: 'justify',
    lineHeight: 1.3,
  },
  piepagina: {
    fontSize: '11px',
    padding: '50px',
  },
  informacion: {
    textAlign: 'justify',
    fontSize: '11px',
    color: 'lightcoral',
    margin: '150px 0 0 0',
  },
  linea: {
    margin: '-20px 0 0 0',
  }
});


const SemilleroEstudiante = () => {
  const usuario = useSelector(state => state.redGlobal.usuario);
  const [seleccion, setSeleccion] = React.useState('miproceso');
  const [mensaje, setMensaje] = React.useState(null);
  const [detalle, setDetalle] = React.useState(true)
  const [procesos, setProcesos] = React.useState([])
  const [participanteActual, setParticipanteActual] = React.useState({});
  const [openModalInfo, setOpenModalInfo] = React.useState(false);
  const [instructivo, setInstructivo] = React.useState([]);
  const [cordinador, setCordinador] = React.useState('');
  const [firma_coordinador, setFirma_coordinador] = React.useState('');
  const classes = useStyles()
  const dispatchI = useDispatch();

  React.useEffect(() => {
    if (usuario.perfil.codigo == 'Per_Nor' || usuario.perfil.codigo == 'Per_Adm' || usuario.perfil.codigo == 'Per_Est_Pos') {
      dispatchI(mostrarModulo({ ruta: '/semilleros_estudiante', nombre: 'Semilleros' }))
    } else {
      setMensaje('permiso')
    }
    obtenerInstructivo()
    obtenerNombreJefes()
  }, []);

  const obtenerInstructivo = async () => {
    let instructivo_estudiante = await Funciones.obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Est_Inst' }]);
    setInstructivo(instructivo_estudiante)
  }
  const obtenerNombreJefes = async () => {
    let nombrejefe = await Funciones.obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'firma_coordinador' }]);
    setCordinador(nombrejefe[0].valora)
    setFirma_coordinador(nombrejefe[0].archivo)
  }

  const vista = () => {

    switch (seleccion) {
      case 'miproceso':
        return (
          <div>
            {detalle == true ? <PintarSolicitudes
              procesos={procesos}
              setProcesos={setProcesos}

              detalle={detalle}
              setDetalle={setDetalle}

              participanteActual={participanteActual}
              setParticipanteActual={setParticipanteActual}

              seleccion={seleccion}
              setSeleccion={setSeleccion}
            /> : <PintarSolicitudSeleccionada
              detalle={detalle}
              setDetalle={setDetalle}

              participanteActual={participanteActual}
              setParticipanteActual={setParticipanteActual}
              firma_coordinador={firma_coordinador}
              cordinador={cordinador}
              openModalInfo={openModalInfo}
              setOpenModalInfo={setOpenModalInfo}
            />}
          </div>
        )
      case 'semilleroLider':
        return (
          <Semilleros vistaEstudiante={true} />
        )
      case 'nuevo':
        return (
          <SemilleroSolicitudesEst />
        )
      default:
        break;
    }
  }

  return (
    <div>
      {mensaje ? (
        <div>
          <Link to="/semilleros">
            <ArrowBackIcon style={{ fontSize: '35px', color: coloresEmma.secondarycolor, marginTop: '5px' }} />
          </Link>
          <TareasTerminadas mensaje='No tienes permiso para este modulo' marginTop='7%' imagen={emma_s} widthImg="7%" />
        </div>
      ) : (
        <>
          <MenuSemilleros instructivo_estudiante={instructivo[0] ? instructivo[0].archivo : ''} sele={seleccion} seleccionar={(seleccion) => setSeleccion(seleccion)} vistaEstudiante={true} />
          <div className={classes.contenidoModulo}>
            {vista()}
          </div>
        </>)}
    </div>
  )
}


const PintarSolicitudes = (props) => {

  const isInitialMount = React.useRef(true);
  const [cargando, setCargando] = React.useState(true);


  React.useEffect(() => {
    //Obtengo los prceosos activos de la persona activa.
    if (isInitialMount.current) {
      isInitialMount.current = false
      const obtenerProcesos = async () => {
        let data = await obtenerParticipantesSolicitudes();
        props.setProcesos(data)
        setCargando(false)
      }
      obtenerProcesos()
    }
  })
  //Obtener semilleros solicitudes
  const obtenerParticipantesSolicitudes = async () => {
    return new Promise(resolve => {
      Funciones.consulta(`api/v1.0/investigacion/listarParticipante2?user_reg=1`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  if (props.procesos.length == 0) {
    return (
      <div className="pointer" onClick={() => props.setSeleccion("nuevo")}>
        <TareasTerminadas
          mensaje="No tienes solicitudes activas, clic aquí para crear una solicitud"
          marginTop="7%"
          imagen={emma_s}
          widthImg="7%"
        />
      </div>
    );
  }

  const handleClick = (participante) => {
    props.setDetalle(false)
    props.setParticipanteActual(participante)
  };

  return (
    <div>
      {cargando == true ? <TareasTerminadas imagen={emma_s} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' />
        : <div style={{ padding: '2% 2% 0% 2%' }}>
          <>
            <Grid container direction={'row'} spacing={1}>
              <TituloAccion principal="" alterno="¡ Lista de Solicitudes !" />
              {props.procesos.map((participante, i) => {
                return (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
                    <Paper elevation={1} onClick={() => handleClick(participante)} className="pointer">
                      <SemilleroCardParticipante
                        id={participante.id}
                        tipo={participante}
                        fecha={participante.fecha_registro}
                        descripcion={`Esta es su solicitud para el Semillero de Investigación ${participante.semillero.nombre} 
                            se encuentra en estado ${participante.estado_actual.nombre}. Clic aquí para obtener mas información.`}
                        estado={participante.estado_actual}
                      //handleClickDelete={handleClickDelete}
                      />
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          </>
        </div>}
    </div>
  )

}

const PintarSolicitudSeleccionada = (props) => {

  const [pos, setPos] = React.useState('Información del Semillero')

  const handleClick = () => {
    props.setDetalle(true)
    props.setParticipanteActual([])
  };

  const openModalInfo = () => {
    props.setOpenModalInfo(true)
  }

  const obtenerMes = (mes) => {
    const meses = {
      '1': 'Enero',
      '2': 'Febrero',
      '3': 'Marzo',
      '4': 'Abril',
      '5': 'Mayo',
      '6': 'Junio',
      '7': 'Julio',
      '8': 'Agosto',
      '9': 'Septiembre',
      '10': 'Octubre',
      '11': 'Noviembre',
      '12': 'Diciembre'
    }
    return (meses[mes])
  }
  const generarPDF = () => {
    let fecha_actual = moment()
    let hoy = `${fecha_actual.format('D')} de ${obtenerMes(fecha_actual.format('M'))} de ${fecha_actual.format('YYYY')}`
    let data = props.participanteActual
    let nombre = data.usuario_registro.primer_nombre + ' ' + data.usuario_registro.segundo_nombre + ' ' + data.usuario_registro.primer_apellido + ' ' + data.usuario_registro.segundo_apellido
    let cedula = data.usuario_registro.identificacion
    let programa = data.programa.nombre
    let semillero = data.semillero.nombre
    let categoria = ''
    let firma = props.cordinador
    let imgfirma= props.firma_coordinador
    if (data.usuario_registro.categoria_investigacion == null) {
      categoria = 'N/A'
    } else {
      categoria = data.usuario_registro.categoria_investigacion.nombre
    }

    console.log(data);
    return (
      <Document>
        <Page size="A4">
          <View style={styles.body}>
            <View style={styles.imagen}>
              <Image style={styles.img} src={LogoCuc} alt="images" />
            </View>
            <Text style={styles.titular}>A QUIEN INTERESE</Text>
            <Text style={styles.parrafo}>
              El suscrito Coordinador Semilleros De Investigación e Innovación de la Corporación Universidad de la Costa, certifica que <Text style={styles.negrita}>{nombre}</Text> identificado(a) con la Cédula de Ciudadanía No.<Text style={styles.negrita}>{cedula}</Text>, estudiante del programa de <Text style={styles.negrita}>{programa}</Text> pertenece al Semillero de Investigación. <Text style={styles.negrita}>{semillero} </Text>
            </Text>
            <Text style={styles.parrafo}>Se expide el presente certificado en Barranquilla, Colombia el {hoy}</Text>
            <Text style={styles.parrafo1}>Atentamente, </Text>

            <View style={styles.firma}>
              <Image src={firma_curso} />
            </View>
            <Text style={styles.linea}>_____________________</Text>
            <Text style={styles.piefirma}>{firma} </Text>
            <Text style={styles.piefirma1}> Coordinador(a) Semilleros de Investigación e Innovación </Text>
            <Text style={styles.piefirma1}> Corporación Universidad de la Costa </Text>
            <Text style={styles.informacion}>
              Calle 58 N° 55-66 – Teléfonos: (5) 336 2200 – 3441974 – Fax: 3442670 – A.A. 50366 – www.cuc.edu.co
            </Text>
          </View>
        </Page>
      </Document>
    )
  }

  return (
    <div className='' >
      <Button onClick={() => handleClick()} style={{ padding: "0px 3px 0px 3px", marginTop: '1%' }} color="secondary" > <ArrowBackIcon /> <Typography variant="body2" style={{ color: '#9e9e9e' }} component="p" align='justify'> Atras </Typography> </Button>
      <div style={{ padding: '0% 2% 0% 2%' }} >
        <Grid container direction={'row'} spacing={1} >
          <Grid item xs={12} sm={12} md={6} >
            <TituloAccion principal='' alterno='¡ Información Básica !' />
            <Paper elevation={0} square className="scroll">
              <SemilleroTableParticipante semi={props.participanteActual} />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TituloAccion principal='' alterno='¿ Que deseas hacer ?' />
            <Grid container direction={'row'} spacing={2} style={{ width: "100%" }}>
              <Grid item xs={12} sm={12} md={12} >

                <Paper elevation={3} style={{ border: '1px solid #e0e0e0' }} onClick={() => openModalInfo()}>
                  <AccionesRapidas imagen={revisar} titulo='¡ Más Información !' sub_titulo='Bien' des_sub_titulo=', Aquí puedes ver toda la información actual.' />
                </Paper>
              </Grid>
              {props.participanteActual.estado_actual.nombre == 'Aceptado' ?
                <Grid item xs={12} sm={12} md={12} >
                  <PDFDownloadLink style={{ textDecoration: 'none' }} document={generarPDF(props.participante)} fileName={`CerificadoSemillero.pdf`}>
                    {
                      () => (

                        <Paper elevation={3} style={{ border: '1px solid #e0e0e0' }} >
                          <AccionesRapidas imagen={file} titulo='¡ Descargar Certificado !' sub_titulo='Bien' des_sub_titulo=', Aquí puedes Descargar tu certificado de participacion en pdf.' />
                        </Paper>

                      )
                    }
                  </PDFDownloadLink>
                </Grid>
                : ''}

            </Grid>
          </Grid>
        </Grid>
      </div>
      {props.openModalInfo == true ? <SemillerosListSemi
        openModalInfo={props.openModalInfo}
        setOpenModalInfo={props.setOpenModalInfo}

        semiActual={props.participanteActual.semillero}

        pos={pos}
        setPos={setPos}

        profesor={false}
        gestor={false}
      /> : ''}

    </div>
  )
}

export default SemilleroEstudiante 