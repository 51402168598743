import React, { Component } from 'react'
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import AppBarModal from '../../general/AppBarModal'
import { BtnForm } from "../../general/BotonesAccion";

class EditarDiplomado extends Component {

  onSubmit = e => {
    let { cambiarEstadoDiplomado } = this.props;
    cambiarEstadoDiplomado();
    e.preventDefault();
  }

  render() {
    let { modalAdd, mostrarModalAdd, nombre } = this.props;

    return (
      <Dialog open={modalAdd} onClose={mostrarModalAdd.bind(this, false)} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="xs">
        <AppBarModal titulo="Cambiar Estado Diplomado" mostrarModal={mostrarModalAdd} titulo_accion="" accion="" />
        <DialogContent className='scroll'>
            <DialogContentText id="alert-dialog-description">
                {`El estado de "${nombre}" será cambiado, si desea continuar presione la opción de "Aceptar".`}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CANCELAR" callback={() => mostrarModalAdd(false)} />
          <BtnForm texto="ACEPTAR" callback={ this.onSubmit } />
        </DialogActions>
      </Dialog>
    )
  }
}

//Props del componente
EditarDiplomado.propTypes = {
  //variables
  modalAdd: PropTypes.bool.isRequired,
  //funciones
  mostrarModalAdd: PropTypes.func.isRequired,
  eliminarDiplomado: PropTypes.func.isRequired,
}


export default EditarDiplomado;