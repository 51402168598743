import React, { Component, forwardRef } from 'react'
import { Dialog, DialogContent, DialogActions, Grid, InputLabel, Select, MenuItem, FormControl, Checkbox, ListItemText, IconButton, Tooltip } from '@material-ui/core';
import AppBarModal from '../../general/AppBarModal';
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from '../../../global/imagenes/emma_w.png';
import emma_s from './../../../global/imagenes/emma_s1.png';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { es } from 'date-fns/locale'
import { BtnForm } from '../../general/BotonesAccion';
import { consulta, obtenerValoresFiltros, transformar, generarFiltros } from '../../../global/js/funciones'
import ListarTabla from '../../general/ListarTabla';
import { LUGAR_VISITA } from '../helper';
import moment from "moment";

class PromocionInforme extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cargando: false,
      mostrar: 'filtros',
      fecha_visita1: null,
      fecha_visita2: null,
      data_informe: [],
      columnas: [],
      lugares: [],
      lugar: '',
      maxWidth: 'sm'
    }
  }

  async componentDidUpdate({ modalInforme }, { mostrar }) {
    let { mostrar: mostrarNew, cargar } = this.state;
    let { modalInforme: modalInformeNew, informe } = this.props;
    if (modalInforme !== modalInformeNew) {
      if (modalInformeNew) {
        this.setState({ cargando: true })
        let lugares_visita = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': LUGAR_VISITA }]);
        this.setState({ cargando: false, lugares: lugares_visita })
      }
      if(!modalInformeNew) this.resetState()
    }

  }


  pintar = () => {
    let { cargando, mostrar } = this.state;
    if (cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />;
    return (
      <div>
        {mostrar === 'filtros' && this.pintarFiltros()}
        {mostrar === 'informe' && this.pintarInforme()}
        {/* {mostrar === 'filtroDetalle' && this.pintarFiltroDetalle() }
            {mostrar === 'detalle' && this.pintarDetalle()}  */}
      </div>
    )
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value,
    });
  };

  pintarFiltros = () => {
    let { informe } = this.props
    let { fecha_visita1, fecha_visita2, lugares, lugar } = this.state;
    return (
      <Grid container spacing={3} className='margin_cont'>
        <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
          <FormControl className="form-control" >
            <InputLabel id="demo-simple-select-label">Seleccione el lugar</InputLabel>
            <Select id="lugar" value={lugar} name='lugar' onChange={this.onChange} >
              {lugares.map(tipo => <MenuItem key={tipo.id} value={tipo.id}>{tipo.nombre}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
        <Grid container spacing={2} style={{ padding: "0% 2% 0% 2%" }}>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
              <KeyboardDatePicker
                style={{ width: "100%" }}
                required={fecha_visita2 != null ? true : false}
                // minDate={Date("YYYY-MM-dd")}
                label="Fecha Visita"
                margin="normal"
                id="fecha_visita1"
                format="yyyy-MM-dd"
                value={fecha_visita1}
                fullWidth
                onChange={(fecha_visita1) => this.setState({ fecha_visita1 })}
                KeyboardButtonProps={{ "aria-label": "change date" }}
                minDateMessage={`La fecha debe ser mayor que la fecha actual.`}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
              <KeyboardDatePicker

                style={{ width: "100%" }}
                required={false}
                // minDate={Date("YYYY-MM-dd")}
                label="Fecha Visita"
                margin="normal"
                id="fecha_visita2"
                format="yyyy-MM-dd"
                value={fecha_visita2}
                fullWidth
                onChange={(fecha_visita2) => this.setState({ fecha_visita2 })}
                KeyboardButtonProps={{ "aria-label": "change date" }}
                minDateMessage={`La fecha debe ser mayor que la fecha actual.`}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  pintarInforme = () => {
    let { informe } = this.props
    let { data_informe, columnas } = this.state
    if (data_informe.length > 0) {
      if (columnas.length == 0) {
        let codigo = informe.codigo

        columnas = [
          { title: 'Colegio', field: 'nombre_colegio' },
          { title: 'Fecha de Visita', field: 'fecha_visita' },
          { title: 'Hora de Inicio', field: 'hora_inicio' },
          { title: 'Hora de Finalización', field: 'hora_fin' },
          { title: 'Lugar', field: 'lugar_visita' },
          { title: 'Número de Estudiantes', field: 'numero_estudiante' },
          { title: 'Responsable', field: 'responsable_visita' },
          { title: '¿Requiere material audiovisual?', field: 'material_audio_si_no' },
          { title: '¿Requiere stand?', field: 'stand_si_no' },

        ]
      }

      const titulo_informe = informe.nombre
      return (
        <ListarTabla titulo={titulo_informe} columnas={columnas} datos={data_informe} /* acciones={acciones} orden={ordenar} total={Total} */ />
      )

    } else {
      return (
        <TareasTerminadas mensaje='No se encontraron resultados' marginTop='7%' imagen={emma_s} widthImg="7%" />
      )
    }


  }

  onSubmit = (e) => {
    let { informe, actualizarMensaje } = this.props
    let { fecha_visita1, fecha_visita2, lugar } = this.state
    let filtros = [], titulo = ''

    if (fecha_visita2 !== null && fecha_visita1 == null) titulo = 'Por favor, seleccione la fecha de inicio.'

    filtros.push({ 'llave': 'informe', 'valor': informe.codigo });
    if (fecha_visita1 !== null) filtros.push({ 'llave': 'fecha_visita1', 'valor': moment(fecha_visita1).format("YYYY-MM-DD") })
    if (fecha_visita2 !== null) filtros.push({ 'llave': 'fecha_visita2', 'valor': moment(fecha_visita2).format("YYYY-MM-DD") })
    if (lugar !== '') filtros.push({ 'llave': 'lugar', 'valor': lugar })

    console.log(filtros)
    if (titulo) actualizarMensaje({ titulo, tipo: 'info', mostrar: true, tiempo: 6000 });
    else this.obtenerInforme(filtros)

    e.preventDefault();
  }

  obtenerInforme = async (filtros = []) => {
    let { actualizarMensaje } = this.props
    let f = await generarFiltros(filtros);
    this.setState({ cargando: true });
    consulta(`api/v1.0/visitas/informe?${f}`, null, null,
      (error, estado, resp) => {
        if (estado === 200) this.setState({
          cargando: false, data_informe: resp, mostrar: 'informe', maxWidth: 'lg'
        });
        else actualizarMensaje({ titulo: 'Ha ocurrido un error, contacte con el administrador.', tipo: 'info', mostrar: true, tiempo: 6000 });
      }
    );

  }

  resetState = () => {
    let { mostrar } = this.state

    if (mostrar === 'filtros') this.setState({ cargando: false, fecha_visita1: null, fecha_visita2: null, lugar: ''})
    else if (mostrar === 'informe') this.setState({ mostrar: 'filtros', cargando: false, fecha_visita1: null, fecha_visita2: null, lugar: '', maxWidth: 'sm' })
  }

  render() {
    let { cargando, maxWidth, mostrar } = this.state;
    let { modalInforme, mostrarModalInforme, informe } = this.props
    return (
      
      <Dialog fullWidth={true} maxWidth={maxWidth} open={modalInforme} onClose={() => mostrarModalInforme(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <AppBarModal titulo={informe.nombre} mostrarModal={() => mostrarModalInforme(false)} titulo_accion={ (mostrar === 'filtros') ? "Limpiar" : 'Volver'} accion={this.resetState} />
        <DialogContent className='scroll'>
          <Grid style={{ padding: "0% 6% 0% 0%" }} item xs={12} md={12}>
            {!cargando ? this.pintar() : <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />}
          </Grid>
        </DialogContent>
        <DialogActions>
          <BtnForm texto="Cerrar" callback={() => mostrarModalInforme(false)} />
          {!cargando && mostrar === 'filtros' && <BtnForm texto="GENERAR" callback={this.onSubmit} />}
        </DialogActions>
      </Dialog>
    );
  }
}

export default PromocionInforme;