import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Paper from '@material-ui/core/Paper';
import moment from 'moment'

export default function EleccionDetalle({ eleccion }) {
  let { nombre, estado, fecha_inicio, fecha_fin } = eleccion;

  const obtenerNombre = (estado) => {
    const nombres = {
      '1': 'Registrado',
      '2': 'Cancelado',
      '3': 'Finalizado',
    }
    return (nombres[estado])
  }

  return (
    <Paper square>
      <Table >
        <TableBody>
          <TableRow key={1}>
            <TableCell component="th" scope="row">Nombre</TableCell>
            <TableCell align="left">{nombre.toUpperCase()}</TableCell>
          </TableRow>
          <TableRow key={1}>
            <TableCell component="th" scope="row">Estado</TableCell>
            <TableCell align="left">{obtenerNombre(estado)}</TableCell>
          </TableRow>
          <TableRow key={1}>
            <TableCell component="th" scope="row">Fecha Inicio</TableCell>
            <TableCell align="left">{fecha_inicio}</TableCell>
          </TableRow>
          <TableRow key={2}>
            <TableCell component="th" scope="row">Fecha Fin</TableCell>
            <TableCell align="left">{fecha_fin}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
}

EleccionDetalle.propTypes = {
  //variables
  eleccion: PropTypes.shape({
    id: PropTypes.number.isRequired,
    nombre: PropTypes.string.isRequired,
    fecha_inicio: PropTypes.string.isRequired,
    fecha_fin: PropTypes.string.isRequired,
  }),
}
