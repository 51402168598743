
import React, { Component } from 'react';
import PropTypes, { number } from 'prop-types';
import { connect } from "react-redux";
import { mostrarModulo, actualizarMensaje } from "../../redux/actions/actGlobal";
import { mostrarModalDel } from "../../redux/actions/actGenericas";
import { obtenerValores, obtenerValoresFiltros, validarCorreo, obtenerPermisosValor, formulario, mostrarError, crear_form_data } from "../../global/js/funciones";
import { mostrarModalMensajeValidacionId } from "./../../redux/actions/actInscripciones";
import AlertasSimple from './../general/AlertasSimple';
import InscripcionForm from "./InscripcionForm";
import InscripcionInicio from "./InscripcionInicio";
import ConfirmarAccion from '../general/ConfirmarAccion';
import emma_s from "../../global/imagenes/emma_s1.png";
import TareasTerminadas from "./../general/TareasTerminadas";
import {GRUPOS_INSCRIPCIONES,DISCAPACIDADES_INSCRIPCIONES,
        VALIDACION_DOCUMENTOS_INSCRIPCIONES,
        TIPO_PRIMER_SEMESTRE,
        TIPO_HOMOLOGACION,
        EPS,
        EPS_OTRO} from './helper';


class InscripcionAgregar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      iniciar: false,
      tipo_identificacion: null,
      carrera: '',
      identificacion: '',
      primer_nombre: '',
      segundo_nombre: '',
      primer_apellido: '',
      segundo_apellido: '',
      telefono1: '',
      telefono2: '',
      celular1: '',
      celular2: '',
      correo: '',
      carreras: [],
      tipos_identificacion: [],
      opciones_conociste: [],
      opcion_conociste: '',
      departamentos: [],
      departamento: '',
      ciudades: [],
      ciudad: '',
      estratos: [],
      estrato: '',
      barrio: '',
      direccion: '',
      guardado: false,
      enviando: false,
      adj_identificacion: '',
      adj_diploma: '',
      adj_saber: '',
      adj_identificacion_final: '',
      adj_diploma_final: '',
      adj_saber_final: '',
      generacion: '',
      grupos : [],
      grupo: '',
      discapacidades : [],
      discapacidad: '',
      adj_certificado : '',
      adj_certificado_final : '',
      adj_idpadres : '',
      adj_idpadres_final : '',
      menor_edad : false,
      mensaje_contacto: '',
      mostrar_btn : true,
      validacion_doc : [],
      mensaje_final : '',
      cargando : true,
      codigo_pruebas: 'AC',
      puntaje_pruebas: '',
      puntaje_sisben: '',
      tiene_sisben: '',
      opciones_sisben: [
        {
          value: 'si',
          label: 'SI',
        },
        {
          value: 'no',
          label: 'NO',
        }
      ],
      link_politica: '',
      link_consentimiento_informado: '',
      tipo_inscripcion_preg:'',
      tipos_inscripcion_preg: [
        {
          value: TIPO_PRIMER_SEMESTRE,
          label: 'INSCRIPCIÓN PRIMER SEMESTRE',
        },
        {
          value: TIPO_HOMOLOGACION,
          label: 'HOMOLOGACIÓN',
        }
      ],
      habilitar_form: false,
      adj_docu_notas: '',
      adj_docu_notas_final: '',
      nombreEps : '',
      nombreEpsOtro : '',
      opcionesEps : [],
    }
  }

  componentDidMount() {
    this.cargarTipos();
  }

  async componentDidUpdate({}, { departamento, tipo_inscripcion_preg, nombreEps }) {
    let { departamento: departamentoNew, tipo_inscripcion_preg: tipo_inscripcion_pregNew } = this.state;
    if (departamentoNew.value && departamentoNew.value !== departamento.value) {
      let ciudades = await obtenerPermisosValor(departamentoNew.value);
      ciudades = ciudades.map(({ secundario }) => ({
        value: secundario.id,
        label: secundario.nombre,
      }));

      this.changeState('ciudades', ciudades);
      this.changeState('ciudad', '');
    }

    if (tipo_inscripcion_pregNew.value && tipo_inscripcion_pregNew.value !== tipo_inscripcion_preg.value) {
      this.changeState('habilitar_form', true)
      console.log('tipo_inscripcion_pregNew', tipo_inscripcion_pregNew);

    }
    if (nombreEps !== this.state.nombreEps) {
      if (this.state.nombreEps.value !== EPS_OTRO) {
        let nombreEpsOtro = '';
        this.changeState('nombreEpsOtro', nombreEpsOtro);
      }
    }

  }

  changeState = (name, value) => {
    this.setState({
      [name]: value
    })
  }

  guardarInscripcion = async () => {
    let { mostrarModalDel, actualizarMensaje } = this.props;
    actualizarMensaje({ titulo: 'Enviado datos, por favor espere...', tipo: 'info', mostrar: true, tiempo: 10000 });
    let {
      tipo_identificacion,
      identificacion,
      primer_nombre,
      primer_apellido,
      segundo_nombre,
      segundo_apellido,
      correo,
      carrera,
      opcion_conociste,
      celular1,
      celular2,
      telefono1,
      telefono2,
      departamento,
      ciudad,
      estrato,
      barrio,
      direccion,
      adj_identificacion_final,
      adj_diploma_final,
      adj_saber_final,
      grupo,
      discapacidad,
      adj_certificado_final,
      adj_idpadres_final,
      codigo_pruebas,
      puntaje_pruebas,
      puntaje_sisben,
      tiene_sisben,
      nombreEps,
      nombreEpsOtro,
      adj_docu_notas_final,
      tipo_inscripcion_preg
    } = this.state;
    let usuario = correo.split("@")[0]
    let data = await crear_form_data({
      tipo_identificacion: tipo_identificacion.value,
      identificacion,
      primer_nombre,
      primer_apellido,
      segundo_nombre,
      segundo_apellido,
      correo,
      carrera: carrera.value,
      conocio: opcion_conociste.value,
      celular1,
      celular2,
      telefono1,
      telefono2,
      departamento: departamento.value,
      ciudad: ciudad.value,
      estrato: estrato.value,
      barrio,
      direccion,
      usuario,
      adj_identificacion: adj_identificacion_final,
      adj_diploma: adj_diploma_final,
      adj_saber: adj_saber_final,
      adj_certificado: adj_certificado_final,
      adj_idpadres: adj_idpadres_final,
      grupo: grupo.value,
      discapacidad: discapacidad ? discapacidad.value : '',
      codigo_pruebas,
      puntaje_pruebas,
      puntaje_sisben,
      sisben: tiene_sisben.value,
      adj_docu_notas: adj_docu_notas_final,
      tipo: tipo_inscripcion_preg.value,
      nombre_eps: nombreEps.value,
      nombre_otro_eps: nombreEpsOtro,
    });
    this.changeState('enviando', true);
    formulario("api/v1.0/inscripciones/crear", data, "post",
      async (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            this.changeState('guardado', true);
            this.changeState('enviando', false);
            mostrarModalDel(false);
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }, false
    );

  }

  onChange = ({ target }) => {
    let { value, name } = target;
    if (name != 'codigo_pruebas' || (name == 'codigo_pruebas' && value.length >= 2)) {
      this.setState({
        [name]: value.replace(/[À-ÿ]/g, '')
      })
    }
  }

  checked = ({target}) => {
    this.setState({
      [target.name]: target.checked
    })
  }

  cargarTipos = async () => {
    const transformar = data => {
      return data.map(e => ({
        value: e.id,
        label: e.nombre,
        codigo: e.codigo,
      }));
    }
    const mensaje = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Men_Ins' }]);
    const validacionDocs = await obtenerValores(VALIDACION_DOCUMENTOS_INSCRIPCIONES);
    const carreras = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': 5 }, { 'llave': 'valora', 'valor': 'ofertado' }]);
    const tipos_identificacion = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': 2 }, { 'llave': 'valora', 'valor': 'persona' }]);
    const opciones_conociste = await obtenerValores(6);
    const departamentos = await obtenerValores(7);
    const estratos = await obtenerValores(9);
    const grupos = await obtenerValores(GRUPOS_INSCRIPCIONES);
    const discapacidades = await obtenerValores(DISCAPACIDADES_INSCRIPCIONES);
    const mensajefin = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Men_Ins_Fin' }]);
    const politica = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Pol_Dat_Cuc' }]);
    const consentimiento_informado = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Con_Inf_Ins' }]);
    const epsResp = await obtenerValores(EPS);
    this.setState({
      carreras: transformar(carreras),
      tipos_identificacion: transformar(tipos_identificacion),
      opciones_conociste: transformar(opciones_conociste),
      departamentos: transformar(departamentos),
      estratos: transformar(estratos),
      grupos: transformar(grupos),
      discapacidades: transformar(discapacidades),
      mensaje_contacto : mensaje[0].nombre,
      validacion_doc : validacionDocs.sort((a, b) => a.valorb - b.valorb).map(e => ({id: e.id, nombre: e.nombre, valora: e.valora, estado: e.estado})),
      mensaje_final : mensajefin[0].nombre,
      link_politica:  politica && politica[0] ? politica[0].archivo : '',
      link_consentimiento_informado:  consentimiento_informado && consentimiento_informado[0] ? consentimiento_informado[0].archivo : '',
      opcionesEps : transformar(epsResp),
      cargando : false
    })
  }

  validarDatos = (step) => {
    let {
      carrera,
      tipo_identificacion,
      identificacion,
      primer_nombre,
      primer_apellido,
      correo,
      telefono1,
      celular1,
      opcion_conociste,
      departamento,
      ciudad,
      estrato,
      barrio,
      direccion,
      adj_diploma,
      adj_saber,
      adj_identificacion,
      grupo,
      discapacidad,
      adj_idpadres,
      menor_edad,
      adj_certificado,
      validacion_doc,
      codigo_pruebas,
      puntaje_pruebas,
      puntaje_sisben,
      tiene_sisben,
      tipo_inscripcion_preg,
      adj_docu_notas,
      nombreEps,
      nombreEpsOtro,
    } = this.state;
    let mensaje = '';

    if (step === 2) {
      if (!carrera) mensaje = 'Seleccione carrera.';
      else if (!opcion_conociste) mensaje = 'Seleccione como nos conociste.';
      else if ((menor_edad && carrera.codigo == '14011') && !adj_idpadres) mensaje = 'Debe adjuntar la identificación de los padres.';
      else if (!carrera) mensaje = '¿Que quieres estudiar?.';
      else {
        let adj_pa = document.getElementById('adj_idpadres').files[0];
        if (adj_pa) this.changeState('adj_idpadres_final', adj_pa);
      }
      
    } else if (step === 0) {
      if (!tipo_inscripcion_preg) mensaje = 'Seleccione el motivo de la solicitud.';
      else if (!tipo_identificacion) mensaje = 'Seleccione tipo identificación.';
      else if (!identificacion) mensaje = 'Ingrese identificación.';
      else if (!primer_nombre) mensaje = 'Ingrese primer nombre.';
      else if (!primer_apellido) mensaje = 'Ingrese primer apellido.';
      else if (!telefono1) mensaje = 'Ingrese telefono.';
      else if (!celular1) mensaje = 'Ingrese celular.';
      else if (!correo) mensaje = 'Ingrese correo personal.';
      else if (!validarCorreo(correo)) mensaje = 'Correo personal invalido.';
      else if (codigo_pruebas.length < 10) mensaje = 'Ingrese codigo de pruebas valido.';
      // else if (isNaN(codigo_pruebas.substring(2))) mensaje = 'Ingrese codigo de pruebas valido.2';
      else if (!puntaje_pruebas) mensaje = 'Ingrese puntaje global pruebas.';
      else if (!tiene_sisben) mensaje = 'Seleccione si tiene Sisben.';
      else if ((tiene_sisben && tiene_sisben.value == 'si') && !puntaje_sisben) mensaje = 'Ingrese puntaje sisben.';
      else if (!grupo) mensaje = 'Seleccione a que grupo pertenece.';
      else if (grupo.codigo === 'GRU_DIS' && !discapacidad) mensaje = 'Seleccione el tipo de discapacidad.';
      else if (!adj_identificacion && validacion_doc[0].valora === '1') mensaje = 'Debe adjuntar la identificación.';  
      else if (!adj_diploma && validacion_doc[2].valora === '1') mensaje = 'Debe adjuntar el diploma.';
      else if (!adj_saber && validacion_doc[1].valora === '1') mensaje = 'Debe adjunta tu documento de resultado pruebas saber 11.';
      else if ((grupo !== '' && grupo.codigo !== 'GRU_NIN') && (!adj_certificado && validacion_doc[3].valora === '1')) mensaje = 'Debe adjuntar el certificado de comunidad especial.';
      else if (tipo_inscripcion_preg == TIPO_HOMOLOGACION && !adj_docu_notas && validacion_doc[4].valora === '1') mensaje = 'Debe adjunta tu documento de notas.';
      else {
        let adj_id = document.getElementById('adj_identificacion').files[0];
        let adj_di = document.getElementById('adj_diploma').files[0];
        let adj_sa = document.getElementById('adj_saber').files[0];
        let adj_ce = document.getElementById('adj_certificado').files[0];
        let adj_no = document.getElementById('adj_docu_notas').files[0];
        if (adj_id) this.changeState('adj_identificacion_final', adj_id);
        if (adj_di) this.changeState('adj_diploma_final', adj_di);
        if (adj_sa) this.changeState('adj_saber_final', adj_sa);
        if (adj_ce) this.changeState('adj_certificado_final', adj_ce);
        if (adj_no) this.changeState('adj_docu_notas_final', adj_no);
      }
    } else if (step === 1) {
      if (!departamento) mensaje = 'Seleccione departamento.';
      else if (!ciudad) mensaje = 'Seleccione ciudad o municipio.';
      else if (!barrio) mensaje = 'Ingrese barrio.';
      else if (!direccion) mensaje = 'Ingrese Dirección.';
      else if (!estrato) mensaje = 'Seleccione estrato.';
      else if (!nombreEps) mensaje = 'Seleccione EPS.';
      else if (nombreEps.value === EPS_OTRO && !nombreEpsOtro) mensaje = 'Ingrese nombre de la EPS.';
      // else if (!carrera) mensaje = '¿Que quieres estudiar?.';
    }
    return mensaje;
  }


  render() {
    let {
      carrera,
      tipo_identificacion,
      carreras,
      tipos_identificacion,
      identificacion,
      primer_nombre,
      segundo_nombre,
      primer_apellido,
      segundo_apellido,
      correo,
      telefono1,
      telefono2,
      celular1,
      celular2,
      opciones_conociste,
      opcion_conociste,
      departamentos,
      departamento,
      ciudades,
      ciudad,
      estratos,
      estrato,
      barrio,
      direccion,
      guardado,
      adj_identificacion,
      adj_identificacion_final,
      iniciar,
      adj_diploma,
      adj_diploma_final,
      adj_saber,
      adj_saber_final,
      grupos,
      grupo,
      discapacidades,
      discapacidad,
      adj_certificado,
      adj_certificado_final,
      adj_idpadres,
      adj_idpadres_final,
      menor_edad,
      mensaje_contacto,
      mostrar_btn,
      validacion_doc,
      mensaje_final,
      cargando,
      codigo_pruebas,
      puntaje_pruebas,
      puntaje_sisben,
      opciones_sisben,
      tiene_sisben,
      link_politica,
      link_consentimiento_informado,
      tipo_inscripcion_preg,
      tipos_inscripcion_preg,
      habilitar_form,
      adj_docu_notas,
      adj_docu_notas_final,
      opcionesEps,
      nombreEps,
      nombreEpsOtro,
    } = this.state;

    let { actualizarMensaje, modalDel, mostrarModalDel, modalMensajeValidacionId, mostrarModalMensajeValidacionId } = this.props;
    const mensajeAlerta = `En caso de que presentes algún inconveniente en el momento de realizar tu proceso de inscripción ${mensaje_contacto}`
    if(cargando){
      return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_s} widthImg="7%" cargando={true}/>;
    }
    if (iniciar) {
      return (
        <div>
          <ConfirmarAccion
          mensaje2=''
          mensaje1={carrera.codigo == '14011' ?
          <div style={{textAlign: 'justify'}}><span>
          Para tu ingreso al PROGRAMA DE PSICOLOGIA de la Universidad de la Costa, es importante contar con tu aprobación para la realización de una prueba psicotécnica, por tanto debes aceptar los términos y condiciones expresados en el <a href={link_consentimiento_informado} target='_black' className='sin_decorador' style={{ color: 'black' }}>consentimiento
          informado</a> y aceptar las <a href={link_politica} target='_black' className='sin_decorador' style={{ color: 'black' }}>políticas de protección de datos</a> de la Universidad de la Costa.<br/><br/>
          Al continuar, se da a entender la aprobación de los términos y condiciones.</span></div>:
          <div style={{textAlign: 'justify'}}><span style={{textAlign: 'justify'}}>
          Al continuar usted acepta las políticas de protección de datos de la Universidad de la Costa.<br/><a href={link_politica} target='_black' className='sin_decorador' style={{ color: 'black' }}> Ver políticas</a></span></div>
          } 
          ejecutarAccion={this.guardarInscripcion} mostrarModalConfAccion={mostrarModalDel} modalConAccion={modalDel} dato={{ id: 0 }} titulo={'¿ Enviar Datos ?'} />
          <InscripcionForm
            props_c={{
              obtenerValores: () => obtenerValores,
              primer_nombre,
              segundo_nombre,
              primer_apellido,
              segundo_apellido,
              correo,
              carrera,
              tipo_identificacion,
              carreras,
              tipos_identificacion,
              identificacion,
              telefono1,
              telefono2,
              celular1,
              celular2,
              opciones_conociste,
              opcion_conociste,
              departamentos,
              departamento,
              ciudades,
              estratos,
              estrato,
              ciudad,
              barrio,
              direccion,
              grupos,
              grupo,
              discapacidades,
              discapacidad,
              opcionesEps,
              nombreEps,
              nombreEpsOtro,
              onChange: this.onChange,
              checked : this.checked,
              validarDatos: this.validarDatos,
              actualizarMensaje,
              terminarProceso: mostrarModalDel,
              guardado,
              adj_identificacion,
              adj_identificacion_final,
              adj_diploma,
              adj_diploma_final,
              adj_saber,
              adj_saber_final,
              adj_certificado,
              adj_certificado_final,
              adj_idpadres,
              adj_idpadres_final,
              menor_edad,
              mensaje : mensaje_contacto,
              mensaje_final,
              mostrar_btn,
              validacion_doc,
              codigo_pruebas,
              puntaje_pruebas,
              puntaje_sisben,
              tiene_sisben,
              opciones_sisben,
              modalMensajeValidacionId,
              mostrarModalMensajeValidacionId,
              mensajeAlerta,
              link_consentimiento_informado,
              tipo_inscripcion_preg,
              tipos_inscripcion_preg,
              habilitar_form,
              adj_docu_notas,
              adj_docu_notas_final,
              setNombreEps: (value) => {
                this.changeState('nombreEps', value)
              },
              setNombreEpsOtro: (value) => {
                this.changeState('nombreEpsOtro', value)
              },
              setTipo_identificacion: (value) => {
                this.changeState('tipo_identificacion', value)
              },
              setPrimer_nombre: (value) => {
                this.changeState('primer_nombre', value)
              },
              setSegundo_nombre: (value) => {
                this.changeState('segundo_nombre', value)
              },
              setPrimer_apellido: (value) => {
                this.changeState('primer_apellido', value)
              },
              setSegundo_apellido: (value) => {
                this.changeState('segundo_apellido', value)
              },
              setTelefono1: (value) => {
                this.changeState('telefono1', value)
              },
              setCelular1: (value) => {
                this.changeState('celular1', value)
              },
              setCorreo: (value) => {
                this.changeState('correo', value)
              },
              setCodigo_pruebas: (value) => {
                this.changeState('codigo_pruebas', value)
              },
              setPuntaje_pruebas: (value) => {
                this.changeState('puntaje_pruebas', value)
              },
              setPuntaje_sisben: (value) => {
                this.changeState('puntaje_sisben', value)
              },
              setAdj_identificacion: (value) => {
                this.changeState('adj_identificacion', value)
              },
              setAdj_diploma: (value) => {
                this.changeState('adj_diploma', value)
              },
              setAdj_saber: (value) => {
                this.changeState('adj_saber', value)
              },
              setAdj_certificado: (value) => {
                this.changeState('adj_certificado', value)
              },
              setOpcion_conociste: (value) => {
                this.changeState('opcion_conociste', value)
              },
              setDepartamento: (value) => {
                this.changeState('departamento', value)
              },
              setCiudad: (value) => {
                this.changeState('ciudad', value)
              },
              setBarrio: (value) => {
                this.changeState('barrio', value)
              },
              setDireccion: (value) => {
                this.changeState('direccion', value)
              },
              setEstrato: (value) => {
                this.changeState('estrato', value)
              },
              setGrupo: (value) => {
                this.changeState('grupo', value)
              },
              setDiscapacidad: (value) => {
                this.changeState('discapacidad', value)
              },
              setMostrarBtn: (value) => {
                this.changeState('mostrar_btn', value)
              },
              setOpcion_sisben: (value) => {
                this.changeState('tiene_sisben', value)
              },
              setCarrera: (value) => {
                this.changeState('carrera', value)
              },
              setTipoInscripcionPreg: (value) => {
                this.changeState('tipo_inscripcion_preg', value)
              },
              setAdj_docu_notas: (value) => {
                this.changeState('adj_docu_notas', value)
              },
            }}
          />
        </div>
      )
    } else {
      return <InscripcionInicio mensaje={mensajeAlerta} accion={(tipo) => {
        this.changeState('iniciar', true)
        if (tipo === 'Tipo_Homo') {
          this.changeState('tipo_inscripcion_preg', {
            value: TIPO_HOMOLOGACION,
            label: 'HOMOLOGACIÓN',
          })
        } else if (tipo === 'Tipo_Ins') {
          this.changeState('tipo_inscripcion_preg', {
            value: TIPO_PRIMER_SEMESTRE,
            label: 'INSCRIPCIÓN PRIMER SEMESTRE',
          })
        }
      }
      } />
    }
  }
}

//CARGO ESTADOS DEL MODULO
const mapStateToProps = (state) => {
  let { modalDel } = state.redGenericas;
  let { modalMensajeValidacionId } = state.redInscripciones;
  return {
    modalDel,
    modalMensajeValidacionId,
  }
};

//CARGO FUNCIONES DEL MODULO
const mapDispatchToProps = {
  actualizarMensaje,
  mostrarModulo,
  mostrarModalDel,
  mostrarModalMensajeValidacionId
}

InscripcionAgregar.propTypes = {
  //variables
  modalDel: PropTypes.bool.isRequired,
  modalMensajeValidacionId: PropTypes.bool.isRequired,
  actualizarMensaje: PropTypes.func.isRequired,
  mostrarModulo: PropTypes.func.isRequired,
  mostrarModalDel: PropTypes.func.isRequired,
  mostrarModalMensajeValidacionId: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(InscripcionAgregar);
