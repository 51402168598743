import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import Generica from '../../personas/generica/Generica';
import AppBarModal from '../../general/AppBarModal';
import { BtnDetalle, BtnEditar, BtnEliminar } from '../../general/BotonesAccion';
import ListarDatos from '../../general/ListarDatos';
import TareasTerminadas from '../../general/TareasTerminadas';
import emma_w from '../../../global/imagenes/emma_w.png';
import { consulta, api, obtenerValoresFiltros } from '../../../global/js/funciones';
import moment from 'moment';
import { connect } from 'react-redux';
import { actualizarMensaje } from '../../../redux/actions/actGlobal';
import fechaIcon from '../../../global/imagenes/calendar.png';
import diplomadoIcon from '../../../global/imagenes/diploma.png';
import {
	mostrarModalAddValores,
	mostrarModalModValores,
	mostrarModalDelValores,
	actualizarDataValor,
	actualizarDataValores,
	actualizarRenderValores,
	mostrarModalAddPermisoValores,
	mostrarModalDetValores,
	actualizarDataPermisos
} from '../../../redux/actions/actValores';

import {
	guardarValorGenerica,
	mostrarError,
	modificarValorGenerica,
} from '../../../global/js/funciones';

//COMPONENTES DEL MODULO
import ValoresAgregar from '../../genericas/valores/ValoresAgregar';
import ValoresModificar from '../../genericas/valores/ValoresModificar';
import ConfirmarAccion from '../../general/ConfirmarAccion';
import Detalle from '../../general/Detalle';

class MatriculaFechaGrado extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cargando: true,
			FechaGrado: [],
			modal: false
		};
	}

    async cargarValores(){
        let { idGenerica, tipo } = this.props;
        // let valores = await this.obtenerValores(idGenerica);
				let valores = await obtenerValoresFiltros([
          { 'llave': 'generica', 'valor': idGenerica }, 
          { 'llave': 'estado', 'valor': '1' }, 
		  		tipo === 'grado' && { 'llave': 'valorf', 'valor': 'interno' }
        ])
        this.setState({ valores, cargando: false });
    }

	async componentDidMount() {
		this.cargarValores();
	}


    obtenerValores(idGenerica) {
		return new Promise((resolve) => {
			consulta(`api/v1.0/generica/${idGenerica}/valores`, null, null, (error, estado, resp) => {
				resolve(estado === 200 && !error ? resp : null);
			});
		});
	}

    guardarValor = async (
		codigo,
		nombre,
		descripcion,
		valora,
		valorb,
		valorc,
		valord,
		valore,
		valorf,
		valorg,
		archivo
	) => {
		let { actualizarMensaje, mostrarModalAddValores, idGenerica } = this.props;
		let { error, estado, resp } = await guardarValorGenerica(
			idGenerica,
			codigo,
			nombre,
			descripcion,
			valora,
			valorb,
			valorc,
			valord,
			valore,
			valorf,
			valorg,
			archivo
		);
		let titulo = 'Ha ocurrido un error, contacte con el administrador.',
			tipo = 'error';
		if (!error) {
			if (estado === 200) {
				tipo = 'success';
				titulo = resp.titulo;
                this.cargarValores();
				mostrarModalAddValores(false);
			} else {
				tipo = 'info';
				titulo = resp.titulo ? resp.titulo : mostrarError(resp);
			}
		}
		actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
	};

	modificarValor = async (
		id,
		codigo,
		nombre,
		descripcion,
		valora,
		valorb,
		valorc,
		valord,
		valore,
		valorf,
		valorg,
		archivo
	) => {
		let { actualizarMensaje, mostrarModalModValores } = this.props;
		let { error, estado, resp } = await modificarValorGenerica(
			id,
			codigo,
			nombre,
			descripcion,
			valora,
			valorb,
			valorc,
			valord,
			valore,
			valorf,
			valorg,
			archivo
		);
		let titulo = 'Ha ocurrido un error, contacte con el administrador.',
			tipo = 'error';
		if (!error) {
			if (estado === 200) {
				tipo = 'success';
				titulo = resp.titulo;
				this.cargarValores();
				mostrarModalModValores(false);
			} else {
				tipo = 'info';
				titulo = resp.titulo ? resp.titulo : mostrarError(resp);
			}
		}
		actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
	};

	eliminarValor = (id) => {
		let { actualizarMensaje, mostrarModalDelValores,  } = this.props;
		consulta(`api/v1.0/valores/${id}/eliminar`, {}, 'put', (error, estado, resp) => {
			let titulo = 'Ha ocurrido un error, contacte con el administrador.',
				tipo = 'error';
			if (!error) {
				if (estado === 200) {
					tipo = 'success';
					titulo = resp.titulo;
					this.cargarValores();
					mostrarModalDelValores(false);
				} else {
					tipo = 'info';
					titulo = mostrarError(resp);
				}
			}
			actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
		});
	};


	cambiarEstado = (estado) => {
		this.setState(estado);
	};

	render() {

        let {
            idGenerica,
			permisos,
			valor,
			mostrarModalModValores,
			actualizarDataValor,
			mostrarModalDelValores,
			mostrarModalAddValores,
			modalAddValores,
			modalModValores,
			modalDelValores,
			modalAdmValores,
			modalDetValores,
			mostrarModalAdmValores,
			modalAddPermisosValores,
			mostrarModalAddPermisoValores,
			mostrarModalDetValores
		} = this.props;

        const config = () => {
			return {
				inputCodigo: { label: 'Codigo', mostrar: true, tipo: 'text', requerido: false },
				inputNombre: { label: 'Nombre', mostrar: true, tipo: 'text', requerido: true },
				inputDescripcion: { label: 'Descripción', mostrar: true, tipo: 'text', requerido: false },
				inputValora: { label: 'Valor A', mostrar: false, tipo: 'text', requerido: false },
				inputValorb: { label: 'Valor B', mostrar: false, tipo: 'text', requerido: false },
				inputValorc: { label: 'Valor C', mostrar: false, tipo: 'text', requerido: false },
				inputValord: { label: 'Valor D', mostrar: false, tipo: 'text', requerido: false },
				inputValore: { label: 'Valor E', mostrar: true, tipo: 'text', requerido: true },
				inputValorf: { label: 'Valor F', mostrar: true, tipo: 'text', requerido: true },
				inputValorg: { label: 'Valor G', mostrar: false, tipo: 'text', requerido: false },
				inputValorh: { label: 'Valor H', mostrar: false, tipo: 'text', requerido: false },
				inputValori: { label: 'Valor I', mostrar: false, tipo: 'text', requerido: false },
				inputArchivo: { label: 'Adjuntar Archivo', mostrar: false, tipo: 'file', requerido: false }
			};
		};

        const configDetail = (data) => {
			let {
				codigo,
				descripcion,
				valora,
				valorb,
				valorc,
				valord,
				valore,
				valorf,
				valorg,
				archivo,
				id,
				nombre
			} = data;
			return [
				{ label: 'Nombre', value: nombre },
				{ label: 'Codigo', value: codigo },
				// { label: 'ID', value: id },
				{ label: 'Descripción', value: descripcion },
				// { label: 'Valor A', value: valora },
				// { label: 'Valor B', value: valorb },
				// { label: 'Valor C', value: valorc },
				// { label: 'Valor D', value: valord },
				// { label: 'Valor E', value: valore },
				// { label: 'Valor F', value: valorf },
				// { label: 'Valor G', value: valorg },
				// {
				// 	label: 'Archivo',
				// 	value: archivo
				// 		? () => (
				// 				<a href={`${api}${archivo}`} target="_balck" className="sin_decorador">
				// 					Ver
				// 				</a>
				// 			)
				// 		: null
				// }
			];
		};

        const actions = (rowData) => {
			let { tipo } = this.props;
			const onClickEliminar = (data) => {
				actualizarDataValor(data);
				mostrarModalDelValores(true);
			}
      
            const onClickEditar = (data) => {
              actualizarDataValor(data);
              mostrarModalModValores(true);
            }
      
            const onClickDetalle = (data) => {
              actualizarDataValor(data);
              mostrarModalDetValores(true);
            }
      
            return <div> <BtnDetalle callback={() => onClickDetalle(rowData)} />  <BtnEditar callback={() => onClickEditar(rowData)} /> {tipo !== 'grado' && tipo !== 'diplomados' && <BtnEliminar callback={() => onClickEliminar(rowData)} /> }</div>;
          }

		const { cargando, valores } = this.state;
		if (cargando) return <TareasTerminadas mensaje="Cargando.." marginTop="7%" imagen={emma_w} widthImg="7%" />;
		return (
			<>
                <ListarDatos
                    datos={valores}
                    titulo="Lista Fechas de Grado"
                    buscar={true}
                    agregar={true}
                    opciones={true}
                    descargar="EMMA - FECHAS DE GRADO"
                    id="tbl_fechasgrado"
                    acciones={(row) => actions(row)}
                    actAgregar={() => mostrarModalAddValores(true)}
                    avatarImg={true}
                    avatar={({ generica }) => generica == 30 ? diplomadoIcon : fechaIcon}
                    fila_principal={({ nombre, id }) => `${id} - ${nombre}`}
                    filas={[
                        {
                            'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a'),
                            'id': 'fecha_registro',
                            'enLista': true
                        },
                        { 'id': 'id', 'enLista':false },
                        { 'id': 'nombre', 'enLista':false },
                        { 'id': 'descripcion' },
                        { 'id': 'codigo' },
                        // { 'id': 'archivo', 'enLista': false },
                        // { 'id': 'id', 'enLista': false },
                        // { 'id': 'estado', 'enLista': false },
                        // { 'id': 'fecha_elimino', 'enLista': false },
                        // { 'id': 'generica', 'enLista': false },
                        // { 'id': 'valora', 'enLista': false },
                        // { 'id': 'valorb', 'enLista': false },
                        // { 'id': 'valorc', 'enLista': false },
                        // { 'id': 'valord', 'enLista': false },
                        { 'id': 'valore', 'enLista': true},
                        { 'id': 'valorf', 'enLista': true },
                        // { 'id': 'valorg', 'enLista': false },
                        ]}
                />
                <ValoresAgregar
					mostrarABC={true}
					modalAddValores={modalAddValores}
					mostrarModalAddValores={mostrarModalAddValores}
					guardarValor={this.guardarValor}
					inputs={config()}
				/>
				<ValoresModificar
					mostrarABC={true}
					modalModValores={modalModValores}
					mostrarModalModValores={mostrarModalModValores}
					valor={valor}
					modificarValor={this.modificarValor}
					inputs={config()}
				/>
				<Detalle
					titulo={'¡ Detalle !'}
					open={modalDetValores}
					config={valor ? configDetail(valor) : []}
					cerrar={() => mostrarModalDetValores(false)}
				/>
				<ConfirmarAccion
					mensaje1={`El valor "${valor.nombre}" será eliminado, `}
					ejecutarAccion={this.eliminarValor}
					mostrarModalConfAccion={mostrarModalDelValores}
					modalConAccion={modalDelValores}
					dato={valor}
					titulo={'¿ Eliminar Valor ?'}
				/>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	let {
		modalAddValores,
		modalModValores,
		modalDelValores,
		modalDetValores,
		valor,
		valores,
		modalAddPermisosValores,
		permisos
	} = state.redValores;
	let { generica } = state.redGenericas;
	return {
		modalAddValores,
		modalModValores,
		modalDelValores,
		modalDetValores,
		valor,
		valores,
		permisos,
		generica,
		modalAddPermisosValores
	};
};

const mapDispatchToProps = {
	actualizarRenderValores,
	actualizarDataValores,
	actualizarDataValor,
	mostrarModalDelValores,
	mostrarModalModValores,
	mostrarModalAddValores,
	mostrarModalDetValores,
	actualizarMensaje,
	mostrarModalAddPermisoValores,
	actualizarDataPermisos
};

MatriculaFechaGrado.propTypes = {
	//variables
	valores: PropTypes.array.isRequired,
	modalModValores: PropTypes.bool.isRequired,
	modalAddValores: PropTypes.bool.isRequired,
	modalDelValores: PropTypes.bool.isRequired,
	modalAdmValores: PropTypes.bool.isRequired,
	modalDetValores: PropTypes.bool.isRequired,
	valor: PropTypes.shape({
		id: PropTypes.number.isRequired,
		codigo: PropTypes.string.isRequired,
		nombre: PropTypes.string.isRequired,
		descripcion: PropTypes.string.isRequired
	}),
	//funciones
	actualizarMensaje: PropTypes.func.isRequired,
	actualizarRenderValores: PropTypes.func.isRequired,
	actualizarDataValores: PropTypes.func.isRequired,
	actualizarDataValor: PropTypes.func.isRequired,
	mostrarModalAddValores: PropTypes.func.isRequired,
	mostrarModalModValores: PropTypes.func.isRequired,
	mostrarModalDelValores: PropTypes.func.isRequired,
	mostrarModalAdmValores: PropTypes.func.isRequired,
	mostrarModalDetValores: PropTypes.func.isRequired,
	mostrarModalAddPermisoValores: PropTypes.func.isRequired,
	actualizarDataPermisos: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(MatriculaFechaGrado);


