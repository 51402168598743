//Importaciones necesaria para poder utilizar react
import React from 'react'
import {Hidden} from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import SettingsIcon from '@material-ui/icons/Settings';

const clase = (item, selec, tipo = '') => item === selec ? `item_selec${tipo}` : `item_modulo`;

function MenuSalas(props) {
    
    const pintar = (app, claseN = '') => {
        if (!app) {
              return (
                <div className={claseN}>
                {props.usuario.perfil.codigo=='Per_Salas'? 
                <div>
                <BottomNavigation showLabels className={clase('miproceso', props.sele)} onClick={() => props.seleccionar('miproceso')}>
                    <BottomNavigationAction label={'Procesos'} icon={<LocationOnIcon />} />
                </BottomNavigation>
                </div>

                :''}
                {props.usuario.perfil.codigo=='Per_Adm_Salas'  || props.usuario.perfil.codigo=='Per_Adm'?
                <div>
                <BottomNavigation showLabels className={clase('miproceso', props.sele)} onClick={() => props.seleccionar('miproceso')}>
                    <BottomNavigationAction label={'Procesos'} icon={<LocationOnIcon />} />
                </BottomNavigation>
                <BottomNavigation showLabels className={clase('administrar', props.sele)} onClick={() => props.seleccionar('administrar')}>
                    <BottomNavigationAction label={'Administrar'} icon={<SettingsIcon />} />
                </BottomNavigation> 
                </div>
                :''} 
                </div>
              )
            } else {
              return (
                <div className={claseN}>
                {props.usuario.perfil.codigo=='Per_Salas' ? 
                  <BottomNavigation showLabels >
                    <BottomNavigationAction label={'Procesos'} icon={<LocationOnIcon />} className={clase('miproceso', props.sele, '_app')} onClick={() => props.seleccionar('miproceso')} />
                  </BottomNavigation>
                :''} 
                {props.usuario.perfil.codigo=='Per_Adm_Salas'  || props.usuario.perfil.codigo=='Per_Adm' ? 
                  <BottomNavigation showLabels >
                    <BottomNavigationAction label={'Procesos'} icon={<LocationOnIcon />} className={clase('miproceso', props.sele, '_app')} onClick={() => props.seleccionar('miproceso')} />
                    <BottomNavigationAction label={'Administrar'} icon={<SettingsIcon />} className={clase('administrar', props.sele, '_app')} onClick={() => props.seleccionar('administrar')} />
                  </BottomNavigation>
                :''} 
                </div>
              )
            }
            
          }
        
          return (
            <div>
              <div>
                <Hidden xsDown> {pintar(false, 'menu_modulo')} </Hidden>
                <Hidden smUp> {pintar(true, 'menu_modulo_app')}</Hidden>
              </div>
            </div>
          );
  }


  export default MenuSalas 