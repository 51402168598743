import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { api } from "../../../global/js/funciones";

import { BtnEliminar, BtnCerrado, BtnGuardar } from "../../general/BotonesAccion";
import ListarDatos from "../../general/ListarDatos"
import AlertasSimple from "../../general/AlertasSimple"

class CandidatosListar extends Component {

  render() {
    let { candidatos, mostrarModalDelCandidatos, mostrarModalAddCandidatos, actualizarDataCandidato, desactivar_acciones, eleccion } = this.props;

    const actions = (rowData) => {
      const onClickEliminar = ({ candidato, id }) => {
        actualizarDataCandidato({ id, "nombre": `${candidato.primer_nombre} ${candidato.primer_apellido}` });
        mostrarModalDelCandidatos(true);
      }
      return desactivar_acciones(eleccion.fecha_inicio, eleccion.estado) || rowData.votos > 0 ? <BtnCerrado /> : <BtnEliminar callback={() => onClickEliminar(rowData)} />;
    }

    const desactivar_guardar = () => {
      let { eleccion, desactivar_acciones, mostrarModalAddCandidatos } = this.props;
      return desactivar_acciones(eleccion.fecha_inicio, eleccion.estado) ? '' :
        <AlertasSimple
          tipo="info"
          titulo="¿ Agregar Candidatos ?"
          sub_titulo="Click aquí para agregar candidatos."
          callback={() => mostrarModalAddCandidatos(true)}
        />;
    }

    return (
      <>
        {desactivar_guardar()}
        <ListarDatos
          datos={candidatos}
          id='tbl_candidatos'
          // titulo="Lista de votaciones"
          descargar='EMMA - CANDIDATOS'
          // avatar={({ candidato }) => `${candidato.primer_nombre}`.charAt(0).toUpperCase()}
          avatarImg={true}
          avatar={({ foto }) => `${api}${foto}`}
          opciones={true}
          // agregar={true}
          // actAgregar={() => mostrarModalAddCandidatos(true)}
          acciones={(data) => actions(data)}
          fila_principal={({ candidato }) => `${candidato.primer_nombre} ${candidato.primer_apellido} ${candidato.segundo_apellido}`}
          filas={[
            { 'id': 'votos', 'mostrar': ({ votos }) => `${votos}`, 'nombre': 'Votos: ' },
          ]}
        />
      </>
    );
  }
}


CandidatosListar.propTypes = {
  //variables
  candidatos: PropTypes.array.isRequired,
  eleccion: PropTypes.shape({
    id: PropTypes.number.isRequired,
    nombre: PropTypes.string.isRequired,
    fecha_inicio: PropTypes.string.isRequired,
    fecha_fin: PropTypes.string.isRequired,
  }),
  //funciones
  mostrarModalAddCandidatos: PropTypes.func.isRequired,
  mostrarModalDelCandidatos: PropTypes.func.isRequired,
  desactivar_acciones: PropTypes.func.isRequired,
}


export default CandidatosListar;


