import React, { Component, useState } from "react";
import PropTypes from "prop-types";
//COMPONENTES DE SISEF
import Sisef from "./Sisef";
import SisefFiltroDesempeno from "./sisef/SisefFiltroDesempeno";
import SisefCalificaciones from "./sisef/SisefCalificaciones";
import SisefCalificarVarios from "./sisef/SisefCalificarVarios";
import SisefOpcionesTipo from "./sisef/SisefOpcionesTipo";
import { Menu } from "./sisef/MenuSisef";
import CrearActividades from "./sisef/CrearActividades";
//COMPONENTES GENERALES
import { obtenerValoresFiltros, rutaImg, mostrarError, generarFiltros } from "../../global/js/funciones";
import { consulta } from "../../global/js/funciones";
import TareasTerminadas from "../general/TareasTerminadas";
import emma_w from "./../../global/imagenes/emma_w.png";
import emma_s1 from "./../../global/imagenes/emma_s1.png";
import ListarDatos from "../general/ListarDatos";
import FilterListIcon from "@material-ui/icons/FilterList";
import { actualizarMensaje, mostrarModulo } from "../../redux/actions/actGlobal";
import { BtnDetalle, BtnEditar, BtnForm, BtnPermiso, BtnRegresar } from "../general/BotonesAccion";
import { Checkbox, Dialog, DialogActions, DialogContent, Grid, IconButton, MenuItem, Paper, Select, Table, TableBody, TableCell, TableRow, Tooltip, jssPreset } from "@material-ui/core";
import AppBarModal from "../general/AppBarModal";
import TituloAccion from "../general/TituloAccion";
import AccionesRapidas from "../general/AccionesRapidas";
import { connect } from "react-redux";
import { StyleSheet } from "@react-pdf/renderer";
import tarea from "./../../global/imagenes/validacion_iconos_revisado.png";
import { COD_NIVEL_AVANZADO, COD_NIVEL_EXCELENTE, COD_NIVEL_INSUFICIENTE, COD_NIVEL_MINIMO, COD_NIVEL_SATISFACTORIO } from './helper';


const styles = StyleSheet.create({
  negrita: { fontWeight: 500 },
  ancho: { fontWeight: 500, width: "20%" },
  ancho1: { width: "20%" },
  ancho2: { width: "50%" },
  cursiva: { fontStyle: "italic" },
  subrayado: { textDecorationLine: "underline" },
});

const classes = StyleSheet.create({
  gridContainer: {
    width: "100%",
    padding: "0.7% 0 0 0",
  },
  tableContainer: {
    borderRadius: '10px',
    overflow: 'hidden',
  },
  table: {
    width: '100%',
  },
  tableRow: {
    backgroundColor: '#f9f5eb',
    borderBottom: '5px solid #C79e32',
    borderRadius: '10px',
  },
  columnaNivel: {
    padding: '0.8%',
    backgroundColor: '#f9f5eb',
    lineHeight: '1.5',
    width: '15%',
    fontSize: "12px",
  },
  containerRadio: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  smallRadio: {
    transform: "scale(0.7)",
    // border: "1px solid #000",
    padding: "0 0 0 0",
    marginTop: "-5px",
    // marginBottom: "-5px",
  }
});

class Sisef_Desempenos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seleccion: "miproceso",
      data: {},
      periodo_actual: null,
      cargando: false,
      nuevo: true,
      estado: true,
      mensaje: "",
      ModalFiltroActividad: false,
      ModalFiltroAdjuntar: false,
      modal_adjuntar_archivo: false,
      MCrearActividad: false,
      calificar: false,
      calificarVariosEstudiantes: false,
      ver_calificacion: false,
      calificar_varios: false,
      asignatura: 0,
      data_asignatura: {},
      asignaturas: [],
      corte: null,
      personas: [],
      estudiantes_selec: [],
      estudiantes_selecNombre: [],
      criterios: [],
      criteriosTabla: [],
      objetivos: [],
      mis_objetivos: [],
      seleccionados: [],
      actividad: {},
      accionesDocExc: false,
      cargandoData: false,
      modal_editar: false,
      selectedCells: {},
      selecciones: [],
      selectedProceso: '',
      id_calificacion: [],
      estudiante: ''
    };
    this.toggleDialog = this.toggleDialog.bind(this);
  }

  async componentDidMount() {
    this.obtenerDatosIniciales();
  }

  async componentDidUpdate({ Modal }, { seleccion }) {
    let { Modal: ModalNew } = this.props
    let { seleccion: seleccionNew, estudiante, id_calificacion, } = this.state;
    if (seleccion !== seleccionNew) {
      if (seleccionNew === "miproceso") this.obtenerDatosIniciales();
    }

    if (ModalNew !== Modal) {
      this.obtenerInformacionCalificacion(estudiante.identificacion, id_calificacion);
    }
  }

  async obtenerInformacionCalificacion(estudiante, id_calificacion) {
    this.setState({ cargando: true });
    consulta(
      `api/v1.0/sisef/obtener_objetivos_est/${estudiante}`,
      { id_calificacion },
      "POST",
      (error, estado, data) => {
        if (estado === 200 && !error) {
          this.setState({ proceso: data, cargando: false }, () => {
            const { calificacion } = this.state;
            this.setState({
              objetivo: calificacion.length > 0 ? calificacion[0].objetivo.criterio_tipo : null,
              detalle: calificacion.length > 0 ? calificacion[0].detalle : null
            });
          });
        } else {
          this.setState({ cargando: false });
        }
      });
  }


  cambiarEstado = (estados) => {
    this.setState(estados);
  };

  cambiarOpcion = (seleccion) => {
    this.cambiarEstado({ seleccion });
  };

  toggleDialog(toggle) {
    let { modal, value } = toggle;
    this.setState({ [modal]: value });
  }

  toggleModal = () => {
    this.setState(prevState => ({ modal_adjuntar_archivo: !prevState.modal_adjuntar_archivo }));
  };

  async ObtenerAsignaturas(periodo) {
    return new Promise((resolve) => {
      consulta(`api/v1.0/sisef/docente_asignaturas/${0}/${periodo}/${0}`, null, null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  async obtenerDatosIniciales() {
    this.setState({ cargando: true });
    let mensaje = "Usted aún no cuenta con un proceso";
    let data_periodo = await obtenerValoresFiltros([{ llave: "codigo", valor: "Per_Sisef" }, { llave: 'estado', valor: '1' }]);
    data_periodo = data_periodo[0].nombre;
    let asign = await this.ObtenerAsignaturas(data_periodo);
    this.setState({ mensaje, cargando: false, periodo_actual: data_periodo, asignaturas: asign, });
  }

  async ObtenerRelacionRubrica(asignatura) {
    let { periodo_actual } = this.state;
    return new Promise((resolve) => {
      consulta(`api/v1.0/sisef/obtener_relacion/${asignatura}/${periodo_actual}`, null, null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  async ObtenerRubricas(corte, relacion) {
    return new Promise((resolve) => {
      consulta(`api/v1.0/sisef/listar_rubricas/${corte}/${relacion}`, null, null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  async ObtenerCalificacion(id_actividad, id_estudiante) {
    return new Promise((resolve) => {
      consulta(`api/v1.0/sisef/obtener_calificacion/${id_actividad}/${id_estudiante}`, null, null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  async buscar_estudiantes(periodo, grupo, actividad, asignatura) {
    return new Promise((resolve) => {
      this.setState({ cargando: true, titulo_res: 'Cargando..' });
      consulta(`api/v1.0/sisef/listar_estudiantes/${periodo}/${grupo}/${actividad}/${asignatura}`, null, null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
          this.setState({ cargando: false, estudiantes: resp });
        }
      );
    });
  }

  async ObjetivosRubrica(rubrica) {
    return new Promise((resolve) => {
      consulta(`api/v1.0/sisef/obtener_objetivos`, { rubrica }, "POST",
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  async ObjetivosEst(estudiante, calificacion) {
    return new Promise((resolve) => {
      consulta(
        // `api/v1.0/sisef/obtener_objetivos_est/${estudiante}/${calificacion}`,
        `api/v1.0/sisef/obtener_objetivos_est/${estudiante}`,
        { calificacion },
        "POST",
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  async ObjetivosEstEditar(estudiante, calificacion) {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/sisef/obtener_objetivos_est_editar/${estudiante}`,
        { calificacion },
        "POST",
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  async ObtenerObjetivosEst(est, cal) {
    let objetivos = await this.ObjetivosEst(est, cal);
    let aux = [];
    objetivos.map(({ objetivo }) => { aux.push(objetivo.id) });
    this.setState({ mis_objetivos: objetivos, seleccionados: aux });
  }

  async ProcesoRubrica(asig, corte) {
    let relacion = await this.ObtenerRelacionRubrica(asig);
    if (relacion.length != 0) {
      let id = relacion[0].id;
      let rubrica = await this.ObtenerRubricas(corte, id);
      const criteriosTabla = rubrica.map(({ criterio }) => criterio);
      this.setState({ criterios: rubrica, criteriosTabla });
    }
  }

  handleToggle = (value, nombre) => () => {
    let { estudiantes_selec, calificar_varios, estudiantes_selecNombre } = this.state;
    const currentIndex = estudiantes_selec.indexOf(value);
    const newEstudiante = [...estudiantes_selec];
    const newEstudianteNombre = [...estudiantes_selecNombre];

    if (currentIndex === -1) {
      newEstudiante.push(value);
      newEstudianteNombre.push(nombre);
      calificar_varios = true;
    } else {
      newEstudiante.splice(currentIndex, 1);
      newEstudianteNombre.splice(currentIndex, 1);
      if (newEstudiante.length == 0) {
        calificar_varios = false;
      }
    }
    this.setState({ estudiantes_selec: newEstudiante, calificar_varios, estudiantes_selecNombre: newEstudianteNombre });
  };

  Ver_Calificaciones = async (actividad) => {
    let { periodo_actual, data_asignatura } = this.state;
    let estudiantes = await this.buscar_estudiantes(periodo_actual, actividad.grupo, actividad.id, data_asignatura.codigo);
    estudiantes = estudiantes.data;
    this.setState({ personas: estudiantes });
  };

  filtrar_estudiantes = async (grupo, tipo_calificacion, estado_est) => {
    let { actividad, periodo_actual, data_asignatura } = this.state;
    let estudiantes = await this.buscar_estudiantes(periodo_actual, actividad.grupo, actividad.id, data_asignatura.codigo);
    if (grupo == 0 && tipo_calificacion == 0) {
      estudiantes = estudiantes.data.filter((und) => und.estado == estado_est);
    } else if (estado_est != 0 && tipo_calificacion != 0) {
      estudiantes = estudiantes.data.filter((und) => und.nom_proceso == tipo_calificacion);
    } else if (estado_est == 0 && tipo_calificacion == 0) {
      estudiantes = estudiantes.data.filter((und) => und.id_grupo == grupo);
    } else if (estado_est != 0 && grupo != 0) {
      estudiantes = estudiantes.data.filter((und) => und.id_grupo == grupo && und.estado == estado_est);
    } else {
      estudiantes = estudiantes.data.filter(
        (und) =>
          und.id_grupo == grupo &&
          und.nom_proceso == tipo_calificacion &&
          und.estado == estado_est
      );
    }
    this.setState({ personas: estudiantes, ModalFiltroEstudiantes: false });
  };

  listar_estudiantes = async (id, corte, actividad) => {
    let { data_asignatura } = this.state;
    this.ProcesoRubrica(data_asignatura.codigo, actividad.indicador.unidad.corte.codigo);
    this.Ver_Calificaciones(actividad);

    this.setState({
      actividad: actividad,
      corte: corte,
      ModalFiltroActividad: false,
      ModalFiltroAdjuntar: false,
      calificar_varios: false,
      estudiantes_selec: [],
      seleccion: "Valorar",
    });
  };

  /*  NUEVO */
  onSubmit = (e) => {
    e.preventDefault();
    let { selecciones, } = this.state;
    let { actualizarMensaje, criterios } = this.props;
    let error = false;
    if (selecciones.length == 0) {
      actualizarMensaje({ titulo: `Debe seleccionar al menos un criterio`, tipo: 'info', mostrar: true, tiempo: 6000 })
      error = true;
    } else if (selecciones.length !== criterios.length) {
      actualizarMensaje({ titulo: `Faltan criterios por evaluar`, tipo: 'info', mostrar: true, tiempo: 6000 })
      error = true;
    }
    if (!error) this.enviar();
  };

  // NUEVO
  enviar = async () => {
    let { selecciones } = this.state;
    let { estudiante, actividad } = this.props;
    this.setState({ cargando: true });
    let data = { actividad: actividad.id, estudiante: estudiante.identificacion, criterio: selecciones, };
    await this.Guardar(data)
  }

  // NUEVO
  Guardar = async (data) => {
    let { cerrarModal, actualizarMensaje, actividad, listar_estudiantes, corte, asignatura, } = this.props;
    consulta(
      `api/v1.0/sisef/asignar_calificacion`,
      data,
      "post",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            listar_estudiantes(asignatura, corte, actividad);
            cerrarModal(false);
            this.setState({ cargando: false });
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  };

  acciones(rowData) {
    let { estudiantes_selec } = this.state;
    const Calificar = (row) => {
      this.setState({ calificar: true, data: row });
    };
    const Mostrar = async (row) => {
      this.setState({ ver_calificacion: true, cargandoData: true });
      console.log(row)
      // const arrayObjetivos = row.calificacion.map(objeto => ({ id: objeto.criterio.id, proceso_id: objeto.proceso.id }));
      const ids = row.calificacion.map(objeto => objeto.id);
      // await this.ObtenerObjetivos(arrayObjetivos);
      await this.ObtenerObjetivosEst(row.identificacion, ids);
      this.setState({ cargandoData: false, data: row });

    };
    const Modificar = async (row) => {
      this.setState({ data: row, editar_cal: true, modal_editar: true, cargandoData: true });
      const ids = row.calificacion.map(objeto => objeto.id);
      await this.ObtenerObjetivosEst(row.identificacion, ids);
      this.setState({ cargandoData: false, id_calificacion: ids });
    };

    return rowData.estado == "sisef_sin_valorar" ? (
      <div>
        <Checkbox
          edge="end"
          onChange={this.handleToggle(rowData.identificacion, rowData.nombre)}
          checked={estudiantes_selec.indexOf(rowData.identificacion) !== -1}
        />
        {estudiantes_selec.length == 0 && (
          <BtnPermiso texto="Valorar" callback={() => Calificar(rowData)} />
        )}
      </div>
    ) : (
      <div>
        <BtnDetalle texto="Detalle" callback={() => Mostrar(rowData)} />
        <BtnEditar texto="Modificar" callback={() => Modificar(rowData)} />
      </div>
    );
  }


  vista = () => {
    let { seleccion } = this.state;
    switch (seleccion) {
      case "miproceso":
        return this.pintarAsignaturas();
      case "Valorar":
        return this.pintarLista();
      case "nuevo":
        return this.pintarNuevo();
      default:
        break;
    }
  };

  pintarNuevo() {
    return (
      <div className="">
        <div style={{ padding: "1% 0% 0% 1%" }}>
          <BtnRegresar
            callback={() => this.setState({ seleccion: "inicio" })}
          />
        </div>
        <div>
          <Grid
            container
            direction={"row"}
            justifyContent={"center"}
            style={{ paddingTop: 30 }}
          >
            <Grid item xs={12} sm={12} md={10} lg={8}>
              <Grid container direction={"row"} justify={"center"}>
                <Grid
                  item
                  xs={12}
                  sm={10}
                  md={6}
                  lg={5}
                  style={{ padding: "1% 2% 0% 2%" }}
                  onClick={() => {
                    this.setState({ MCrearActividad: true });
                  }}
                >
                  <Paper elevation={8} square>
                    <SisefOpcionesTipo
                      avatarcss={{ background: "#01579b" }}
                      image={tarea}
                      cardcss={{
                        maxWidth: "100%",
                        height: "400px",
                        cursor: "pointer",
                      }}
                      data={{ opciones: `Nuevo`, nombre: "CREAR ACTIVIDAD" }}
                    />
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }

  resetData = () => {
    this.setState({
      estudiantes_selec: [],
      estudiantes_selecNombre: [],
      calificar_varios: false,
      seleccionados: [],

    });
  };

  calificarEstudiantes = () => {
    let { estudiantes_selec } = this.state;
    if (estudiantes_selec.length == 1) {
      this.props.actualizarMensaje({
        titulo: "Debe seleccionar al menos dos estudiantes.",
        tipo: "info",
        mostrar: true,
        tiempo: 6000,
      });
      return;
    }
    this.setState({ calificarVariosEstudiantes: true });
  };

  onClick = (subindex, index, valor, nivel, dataHead) => {
    console.log(subindex, index, valor, nivel, dataHead)
    let { selecciones } = this.state;
    const selectedCells = { ...this.state.selectedCells };
    Object.keys(selectedCells).forEach(key => {
      const [col, row] = key.split("-").map(Number);
      if (col === subindex) {
        selectedCells[key] = false;
      }
    });
    selectedCells[`${subindex}-${index}`] = true;

    const nivelCodigoMap = {
      'excelente': COD_NIVEL_EXCELENTE,
      'avanzado': COD_NIVEL_AVANZADO,
      'satisfactorio': COD_NIVEL_SATISFACTORIO,
      'minimo': COD_NIVEL_MINIMO,
      'insuficiente': COD_NIVEL_INSUFICIENTE,
    };

    if (nivel in nivelCodigoMap) dataHead.codigo_nivel = nivelCodigoMap[nivel]

    let data_criterio = { id_rubrica: dataHead.id, detalle: valor, codigo_nivel: dataHead.codigo_nivel, }

    let c = selecciones.filter((crit) => crit.id_rubrica == dataHead.id);
    if (c.length == 0) selecciones.push(data_criterio);
    else {
      selecciones = selecciones.filter((crit) => crit.id_rubrica != dataHead.id);
      selecciones.push(data_criterio);
    }
    this.setState({ selectedCells, selecciones });
  };

  pintarLista() {
    let { personas, calificar, estudiantes_selec, criterios, data, actividad, data_asignatura, corte, calificacion_data, ver_calificacion, editar_cal, calificar_varios, seleccionados, mis_objetivos, criteriosTabla, cargandoData, calificarVariosEstudiantes, estudiantes_selecNombre, } = this.state;
    let { actualizarMensaje } = this.props;
    return (
      <>
        <div className={"regresar"}>
          <BtnRegresar
            callback={() => this.setState({ seleccion: "miproceso" })}
          />
        </div>
        <ListarDatos
          id="tbl_lista_practica"
          datos={personas}
          opciones={true}
          titulo={actividad.actividad}
          acciones={(row) => this.acciones(row)}
          buscar={true}
          opcionAdicional={true}
          filtrar={true}
          refrescar={true}
          actRefrescar={() => this.Ver_Calificaciones(actividad)}
          opcionCalificacion={calificar_varios}
          actCalificar={() => this.calificarEstudiantes()}
          actDescargar={() => this.setState({ ModalFiltroActividad: true })}
          // actCargar={() => this.setState({ modal_adjuntar_archivo: true })}
          actfiltrar={() => this.setState({ ModalFiltroEstudiantes: true })}
          avatarImg={true}
          avatar={({ identificacion }) => `${rutaImg}${identificacion}.jpg`}
          fila_principal={({ nombre }) => `${nombre}`.toUpperCase()}
          filas={[
            {
              mostrar: ({ identificacion }) => `${identificacion}`,
              id: "identificacion",
              enLista: true,
            },
            {
              mostrar: ({ nom_proceso }) => `${nom_proceso}`,
              id: "nom_proceso",
              enLista: true,
            },
          ]}
        />
        {calificar ? (
          <SisefCalificaciones
            Modal={calificar}
            estudiantes_selec={estudiantes_selec}
            cerrarModal={(resp) => this.setState({ calificar: resp })}
            criterios={criterios}
            criteriosTabla={criteriosTabla}
            corte={corte}
            asignatura={data_asignatura.codigo}
            estudiante={data}
            actividad={actividad}
            listar_estudiantes={this.listar_estudiantes}
            actualizarMensaje={actualizarMensaje}
          />
        ) : (
          ""
        )}
        {
          calificarVariosEstudiantes && (
            <SisefCalificarVarios
              Modal={calificarVariosEstudiantes}
              cerrarModal={(resp) => this.setState({ calificarVariosEstudiantes: resp })}
              criterios={criterios}
              estudiantes_selec={estudiantes_selec}
              nombre_estudiantes={estudiantes_selecNombre}
              corte={corte}
              asignatura={data_asignatura.codigo}
              estudiante={data}
              actividad={actividad}
              listar_estudiantes={this.listar_estudiantes}
              actualizarMensaje={actualizarMensaje}
              resetData={this.resetData}

            />
          )
        }
        {editar_cal && data ? (
          <EditarCalificacion
            Modal={editar_cal}
            cerrarModal={(resp) => this.setState({ editar_cal: resp })}
            SetSeleccionados={(resp) => this.setState({ seleccionados: resp })}
            calificacion_data={calificacion_data}
            estudiante={data}
            actividad={actividad}
            seleccionados={seleccionados}
            // objetivos={objetivos}
            mis_objetivos={mis_objetivos}
            cargandoData={cargandoData}
            id_calificacion={this.state.id_calificacion}
            actualizarMensaje={actualizarMensaje}
          />

        ) : (
          ""
        )}
        {ver_calificacion ? (
          <MostrarCalificacion
            Modal={ver_calificacion}
            cerrarModal={(resp) => this.setState({ ver_calificacion: resp })}
            SetSeleccionados={(resp) => this.setState({ seleccionados: resp })}
            calificacion_data={calificacion_data}
            estudiante={data}
            actividad={actividad}
            seleccionados={seleccionados}
            // objetivos={objetivos}
            mis_objetivos={mis_objetivos}
            cargandoData={cargandoData}
          />
        ) : (
          ""
        )}

      </>
    );
  }

  pintarInicio() {
    return <Sisef />;
  }

  pintarAsignaturas() {
    let { asignaturas } = this.state;

    const obtenerColor = (num) => {
      let color = "white";
      const colores = {
        0: { backgroundColor: "#f5f5f5", color: "#757575" },
        1: { backgroundColor: "#ffc107", color },
        2: { backgroundColor: "#009688", color },
        3: { backgroundColor: "#0d47a1", color },
        4: { backgroundColor: "#02A6F2", color },
        5: { backgroundColor: "#e65100", color },
        6: { backgroundColor: "#6200ea", color },
        7: { backgroundColor: "#bdbdbd", color },
        8: { backgroundColor: "#F5951B", color },
        9: { backgroundColor: "#b71c1c", color },
      };

      if (!colores[num])
        return { backgroundColor: "#f5f5f5", color: "#757575" };
      return colores[num];
    };
    return (
      <div className="pointer">
        <div className={"regresar"}>
          <BtnRegresar
            callback={() => this.setState({ seleccion: "inicio" })}
          />
        </div>
        <div style={{ padding: "2% 2% 0% 2%" }}>
          <Grid
            container
            direction={"row"}
            spacing={1}
            justifyContent={"center"}
          >
            <Grid item xs={12} sm={12} md={12} lg={10}>
              <Grid
                container
                direction={"row"}
                alignItems="center"
                spacing={1}
                style={{ width: "100%" }}
              >
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <TituloAccion
                    principal=""
                    alterno="Seleccione la asignatura a gestionar: "
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Tooltip
                    title="Filtrar"
                    aria-label="add"
                  // onClick={() => this.ModalfiltroPeriodo()}
                  >
                    <IconButton
                      edge={false}
                      color="secondary"
                      style={{ float: "right" }}
                    >
                      <FilterListIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              <Paper elevation={0} square className="scroll contenido_fijo">
                {asignaturas.length === 0 ? (
                  <TareasTerminadas
                    mensaje="Aún no tiene asignaturas a cargo para este periodo."
                    marginTop="7%"
                    imagen={emma_s1}
                    widthImg="7%"
                    cargando={true}
                  />
                ) : (
                  <Grid
                    container
                    direction={"row"}
                    spacing={1}
                    style={{ width: "100%" }}
                  >
                    {asignaturas.map(
                      (
                        {
                          nombre_asignatura: nombre,
                          codigo_asignatura: codigo,
                        },
                        i
                      ) => {
                        return (
                          <Grid item xs={12} sm={12} md={6} lg={6} key={i}>
                            <Paper
                              elevation={1}
                              style={{ border: "1px solid #e0e0e0" }}
                              onClick={() => this.handleClick(codigo, nombre)}
                            >
                              <AccionesRapidas
                                imagen=""
                                titulo={`${nombre.toUpperCase()}`}
                                sub_titulo=""
                                des_sub_titulo=""
                                avatar={obtenerColor(i % 10)}
                                avatar_name={nombre.charAt(0).toUpperCase()}
                              // list_desc={[`Numero de actividades : ${i}`]}
                              />
                            </Paper>
                          </Grid>
                        );
                      }
                    )}
                  </Grid>
                )}
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }

  handleClick = async (codigo, nombre) => {
    let data = {};
    data["codigo"] = codigo;
    data["nombre"] = nombre;
    this.setState({
      ModalFiltroActividad: true,
      data_asignatura: data,
    });
  };

  render() {
    let {
      seleccion,
      cargando,
      ModalFiltroActividad,
      ModalFiltroEstudiantes,
      modal_adjuntar_archivo,
      MCrearActividad,
      data_asignatura,
      actividad,
      asignaturas,
      periodo_actual,
    } = this.state;
    let { actualizarMensaje } = this.props;
    return seleccion != "inicio" ? (
      <>
        <div>
          <Menu
            sele={seleccion}
            seleccionar={(seleccion) => this.cambiarOpcion(seleccion)}
          />

          <div className="contenido_modulo">
            {!cargando ? (
              this.vista()
            ) : (
              <TareasTerminadas
                mensaje="Cargando.."
                marginTop="7%"
                imagen={emma_w}
                widthImg="7%"
                cargando={true}
              />
            )}
          </div>
        </div>
        {ModalFiltroActividad || ModalFiltroEstudiantes ? (
          <SisefFiltroDesempeno
            Modal={ModalFiltroActividad}
            FiltroEstudiantes={ModalFiltroEstudiantes}
            data_asignatura={data_asignatura}
            cerrarModal={(resp) =>
              this.setState({ ModalFiltroActividad: resp })
            }
            cerrarFiltroEstudiante={(resp) =>
              this.setState({ ModalFiltroEstudiantes: resp })
            }
            personas={(resp) => this.setState({ personas: resp })}
            listar_estudiantes={this.listar_estudiantes}
            filtrar_estudiantes={this.filtrar_estudiantes}
            actividad={actividad}
          />
        ) : (
          ""
        )}
        <CrearActividades
          MCrearActividad={MCrearActividad}
          SetMCrearActividad={(resp) =>
            this.setState({ MCrearActividad: resp })
          }
          seleccion={(resp) => this.setState({ seleccion: resp })}
          asignaturas={asignaturas}
          periodo_actual={periodo_actual}
          actualizarMensaje={actualizarMensaje}
        />

      </>
    ) : (
      this.pintarInicio()
    );
  }
}


class MostrarCalificacion extends Component {
  render() {
    let { estudiante, actividad, Modal, cerrarModal, mis_objetivos, cargandoData } = this.props;
    console.log(estudiante)
    return (
      <>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={Modal}
          onClose={() => cerrarModal(false)}
          aria-labelledby="form-dialog-title"
        >
          <AppBarModal
            titulo="Detalles"
            mostrarModal={() => cerrarModal(false)}
            titulo_accion="Detalle calificación"
            accion=""
          />
          <DialogContent className="scroll">
            {
              cargandoData ? (
                <TareasTerminadas
                  mensaje="Cargando.."
                  marginTop="7%"
                  imagen={emma_w}
                  widthImg="7%"
                  cargando={true}
                />
              ) :
                (
                  <Table width={"100%"}>
                    <TableBody>
                      <TableRow key={1}>
                        <TableCell align="center" style={styles.negrita} component="th" scope="row" colSpan={1}>Estudiante</TableCell>
                        <TableCell align="left" colSpan={4} >{estudiante.nombre.toUpperCase()}</TableCell>
                      </TableRow>
                      <TableRow key={2}>
                        <TableCell align="center" style={styles.negrita} component="th" scope="row" colSpan={1}>Actividad</TableCell>
                        <TableCell align="left" colSpan={4}>{actividad.actividad}</TableCell>
                      </TableRow>
                      <TableRow key={3}>
                        <TableCell style={styles.ancho} component="th" scope="row" align="center">Criterio</TableCell>
                        <TableCell style={styles.ancho} component="th" scope="row" align="center">Nivel del estudiante</TableCell>
                        <TableCell style={styles.ancho} component="th" scope="row" align="center">Detalle</TableCell>
                        <TableCell style={styles.ancho} component="th" scope="row" align="center">Oportunidades por mejorar</TableCell>
                        <TableCell style={styles.ancho} component="th" scope="row" align="center">Retroalimentación</TableCell>
                      </TableRow>
                      {
                        estudiante.calificacion.map(({ criterio, proceso, detalle }, index) => (
                          <>
                            <TableRow key={index}>
                              <TableCell style={styles.ancho1} align="center">{criterio.criterio}</TableCell>
                              <TableCell style={styles.ancho1} align="center">{proceso.nombre}</TableCell>
                              <TableCell style={styles.ancho1} align="center">{detalle}</TableCell>
                              <TableCell style={styles.ancho1} align="center">{mis_objetivos[index].objetivo.objetivo}</TableCell>
                              <TableCell style={styles.ancho1} align="center">{mis_objetivos[index].objetivo.retroalimentacion}</TableCell>
                            </TableRow>
                          </>
                        ))
                      }
                    </TableBody>
                  </Table>
                )
            }
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CERRAR" callback={() => cerrarModal(false)} />
          </DialogActions>
        </Dialog>
      </>
    );
  }
}


class EditarCalificacion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calificaciones: [],
      actividad: [],
      proceso: [],
      niveles: [],
      detalleCriterio: null,

    };
  }

  handleProcesoChange = async (event, index) => {
    const { estudiante, actividad } = this.props;
    const { value } = event.target;
    const { calificaciones } = this.state;
    const { criterio } = estudiante.calificacion[index];
    const newCalificaciones = [...calificaciones];
    const criterioId = criterio.id;
    const actividadId = actividad.id;
    newCalificaciones[index].codigo = value;
    newCalificaciones[index].criterio = criterioId;
    newCalificaciones[index].actividad = actividadId 
    newCalificaciones[index].editado = true 

    
    let resp = await this.listarDetalleCalificacion(criterioId);

    const nivelCodigoMap = {
      'excelente': COD_NIVEL_EXCELENTE,
      'avanzado': COD_NIVEL_AVANZADO,
      'satisfactorio': COD_NIVEL_SATISFACTORIO,
      'minimo': COD_NIVEL_MINIMO,
      'insuficiente': COD_NIVEL_INSUFICIENTE,
    };

    if (value in nivelCodigoMap) {
      const valorAvanzado = resp[0][nivelCodigoMap[value]];
      newCalificaciones[index].detalle = valorAvanzado;
      console.log(valorAvanzado)
    }

    this.setState({ calificaciones: newCalificaciones });
  };


  async listarDetalleCalificacion(criterioId) {
    return new Promise((resolve) => {
      consulta(`api/v1.0/sisef/editar_obtener_datalle/${criterioId}`, null, null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      )
    });
  }


  handleSubmit = async () => {
    const { estudiante } = this.props;
    const { calificaciones } = this.state;
    let calificacionesEditadas = calificaciones.filter(calificacion => calificacion.editado);
    await this.mofidificarCalificación(estudiante.identificacion, calificacionesEditadas);
  };

  // async mofidificarCalificación(estudiante, calificaciones) {
  //   let { actualizarMensaje } = this.props
  //   this.setState({ cargando: true });
  //   let data = calificaciones.map(({ actividad, criterio, codigo, detalle }) => ({ actividad, criterio, codigo, detalle }));
  //   consulta(
  //     `api/v1.0/sisef/editar_calificacion/${estudiante}`,
  //     { calificaciones: data },
  //     "PUT",
  //     (error, estado, respuesta) => {
  //       if (estado === 200 && !error) {
  //         this.setState({ cargando: false });
  //         this.props.cerrarModal(false);
  //         actualizarMensaje({
  //           titulo: "Calificaciones actualizadas correctamente",
  //           tipo: "success",
  //           mostrar: true,
  //           tiempo: 6000,
  //         });
  //       } else {
  //         this.setState({ cargando: false });
  //         actualizarMensaje({
  //           titulo: "Ha ocurrido un error, contacte con el administrador.",
  //           tipo: "error",
  //           mostrar: true,
  //           tiempo: 6000,
  //         });
  //       }
  //     }
  //   );
  // }

  mofidificarCalificación = async (estudiante, calificaciones) => {
    let {actualizarMensaje , cerrarModal} = this.props;
    this.setState({ cargando: true });
    let data = calificaciones.map(({ actividad, criterio, codigo, detalle }) => ({ actividad, criterio, codigo, detalle }));
    consulta(
      `api/v1.0/sisef/editar_calificacion/${estudiante}`,
      { calificaciones: data },
      "PUT",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            cerrarModal(false);
            this.setState({ cargando: false });
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  };

  async componentDidMount() {
    const { estudiante } = this.props;
    if (estudiante.calificacion.length > 0) {
      const niveles = await obtenerValoresFiltros([{ llave: "generica", valor: 1129 }, { llave: 'estado', valor: '1' }]);
      const calificaciones = estudiante.calificacion.map(calificacion => ({
        codigo: calificacion.proceso.codigo,
        detalle: calificacion.detalle, // Agrega aquí los datos adicionales que necesites
        editado: false,
        // Agrega más propiedades según sea necesario
      }));
      this.setState({ niveles, calificaciones });
    }
  }

  render() {
    const { niveles, calificaciones, detalleCriterio } = this.state;
    const { estudiante, actividad, Modal, cerrarModal, cargandoData } = this.props;

    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={Modal}
        onClose={() => cerrarModal(false)}
        aria-labelledby="form-dialog-title"
      >
        <AppBarModal
          titulo="Editar calificación"
          mostrarModal={() => cerrarModal(false)}
          titulo_accion="Editar calificación"
          accion=""
        />
        <DialogContent className="scroll">
          {cargandoData ? (
            <TareasTerminadas
              mensaje="Cargando.."
              marginTop="7%"
              imagen={emma_w}
              widthImg="7%"
              cargando={true}
            />
          ) : (
            <Table width={"100%"}>
              <TableBody>
                <TableRow key={1}>
                  <TableCell align="center" style={styles.negrita} component="th" scope="row" colSpan={1}>Estudiante</TableCell>
                  <TableCell align="left" colSpan={4} >{estudiante.nombre.toUpperCase()}</TableCell>
                </TableRow>
                <TableRow key={2}>
                  <TableCell align="center" style={styles.negrita} component="th" scope="row" colSpan={1}>Actividad</TableCell>
                  <TableCell align="left" colSpan={4}>{actividad.actividad}</TableCell>
                </TableRow>
                <TableRow key={3}>
                  <TableCell style={styles.ancho} component="th" scope="row" align="center">Criterio</TableCell>
                  <TableCell style={styles.ancho} component="th" scope="row" align="center">Nivel del estudiante Actual</TableCell>
                  <TableCell style={styles.ancho} component="th" scope="row" align="center">Detalle</TableCell>
                </TableRow>
                {estudiante.calificacion && estudiante.calificacion.map(({ criterio, proceso, detalle }, index) => (
                  <TableRow key={index}>
                    <TableCell style={styles.ancho1} align="center">{criterio.criterio}</TableCell>
                    <TableCell style={styles.ancho1} align="center">
                      <Select
                        value={calificaciones[index] ? calificaciones[index].codigo : ''}
                        onChange={(event) => this.handleProcesoChange(event, index)}
                      >
                        {niveles.map((nivel) => (
                          <MenuItem key={nivel.id} value={nivel.codigo}>{nivel.nombre}</MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    {/* { */}
                      {/* detalleCriterio !== null && ( */}
                        <TableCell style={styles.ancho1} align="center">{calificaciones[index].detalle}</TableCell>
                      {/* )  */}
                    {/* } */}
                    {/* <TableCell style={styles.ancho1} align="center">{detalle}</TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CERRAR" callback={() => cerrarModal(false)} />
          <BtnForm texto="ACTUALIZAR CALIFICACIÓN" callback={this.handleSubmit} tipo="submit" />
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  let { usuario } = state.redGlobal;
  let { ModalAdd } = state.redSisef;
  return { usuario, ModalAdd };
};
const mapDispatchToProps = {
  mostrarModulo,
  actualizarMensaje,
};

Sisef_Desempenos.propTypes = {
  //Funciones
  ModalAdd: PropTypes.bool.isRequired,
  mostrarModulo: PropTypes.func.isRequired,
  actualizarMensaje: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sisef_Desempenos);
export { MostrarCalificacion };