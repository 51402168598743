const MODAL_VALIDACION_ID = "MODAL_VALIDAICON_ID";
const MODAL_ADD_FILTRO = "MODAL_ADD_FILTRO";
const MODAL_DETALLE = "MODAL_DETALLE";
const MODAL_GESTIONAR = "MODAL_GESTIONAR";
const MODAL_PERIODO = "MODAL_PERIODO";
const MODAL_GESTIONAR_PROGRAMAS = "MODAL_GESTIONAR_PROGRAMAS";
const MODAL_DET_PROGRAMAS = "MODAL_DET_PROGRAMAS";
const MODAL_ADD_PROGRAMAS = "MODAL_ADD_PROGRAMAS";
const MODAL_MOD_PROGRAMAS = "MODAL_MOD_PROGRAMAS";

const mostrarModalMensajeValidacionId = (show) => {
  return {
    type: MODAL_VALIDACION_ID,
    show,
  };
};

const mostrarModalAddFiltrosInsPosgrado = (show) => {
  return {
    type: MODAL_ADD_FILTRO,
    show,
  };
};

const mostrarModalDetalleInsPosgrado = (show) => {
  return {
    type: MODAL_DETALLE,
    show,
  };
};

const mostrarModalGestionarInsPosgrado = (show) => {
  return {
    type: MODAL_GESTIONAR,
    show,
  };
};

const mostrarModalPeriodo = (show) => {
  return {
    type: MODAL_PERIODO,
    show,
  };
}

const mostrarModalGestionarProgramas = (show) => {
  return {
    type: MODAL_GESTIONAR_PROGRAMAS,
    show,
  };
}

const mostrarModalDetProgramas = (show) => {
  return {
    type: MODAL_DET_PROGRAMAS,
    show,
  };
}

const mostrarModalAddProgramas = (show) => {
  return {
    type: MODAL_ADD_PROGRAMAS,
    show,
  };
}

const mostrarModalModProgramas = (show) => {
  return {
    type: MODAL_MOD_PROGRAMAS,
    show,
  };
}

module.exports = {
  MODAL_VALIDACION_ID,
  MODAL_ADD_FILTRO,
  MODAL_DETALLE,
  MODAL_GESTIONAR,
  MODAL_PERIODO,
  MODAL_GESTIONAR_PROGRAMAS,
  MODAL_DET_PROGRAMAS,
  MODAL_ADD_PROGRAMAS,
  MODAL_MOD_PROGRAMAS,
  mostrarModalModProgramas,
  mostrarModalAddProgramas,
  mostrarModalAddFiltrosInsPosgrado,
  mostrarModalMensajeValidacionId,
  mostrarModalDetalleInsPosgrado,
  mostrarModalGestionarInsPosgrado,
  mostrarModalPeriodo,
  mostrarModalGestionarProgramas,
  mostrarModalDetProgramas,
};
