import React from "react";
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// FUNCIONES GLOBALES
import { Grid, Paper } from '@material-ui/core'
import { obtenerValoresFiltros } from "../../../global/js/funciones";
import ListarTarjetas from '../../general/ListarTarjetas';
import TareasTerminadas from "../../general/TareasTerminadas";
import TituloAccion from '../../general/TituloAccion';

// ACCIIONES DEL MODULO
import { mostrarModalInforme } from "../../../redux/actions/actRetoscuc";
import { actualizarMensaje } from "../../../redux/actions/actGlobal"

// COMPONETES DEL MODULO
import { RetosInforme } from "./RetosInforme";

// IMAGENES
import emma_w from '../../../global/imagenes/emma_w.png';

// HELPERS
import { TIPO_INFORME_RETOS } from '../Helpers'



class AdminInforme extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      cargando: true,
      perosonas: [],
      generica: {
        tipo: 0,
        id: 0
      },
      informes: [],
      informe: { 'nombre': '', 'codigo': '' },
      modal: false,
    }
  }

  async componentDidMount() {
    await this.obtenerInformes();
    this.esAdministrador()
  }

  esAdministrador() {
    let { usuario } = this.props
    if (usuario && usuario.perfil && (usuario.perfil.codigo === "Per_Admin" || usuario.perfil.codigo === 'Per_Ret_n1')) {
      this.setState({ administrador: true })
    }
  }


  obtenerInformes = async () => {
    let { usuario } = this.props
    let informes = []
    this.setState({ cargando: true })
    if (usuario.perfil.codigo == 'Per_Adm' || usuario.perfil.codigo == 'Per_Ret_n1') {
      let informes1 = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPO_INFORME_RETOS }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valora', 'valor': 'admin' }]);
      informes = informes1
    }
    this.setState({ informes, cargando: false })
  }

  pintarInformes() {
    let { informes, informe, cargando } = this.state
    let { modalInformeRetos, mostrarModalInforme, actualizarMensaje } = this.props

    const acciones = (data) => {
      this.setState({ informe: { 'nombre': data.nombre, 'codigo': data.codigo } })
      mostrarModalInforme(true)
    }

    if (cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />

    return (
      <>
        <ListarTarjetas
          datos={informes}
          titulo={'Informes Retos CUC'}
          acciones={(data) => acciones(data)}
          avatar={({ nombre }) => nombre.toUpperCase().charAt(0)}
          color_avatar={({ valorc }) => ({ 'backgroundColor': ((valorc) ? valorc : '#EEEEEE'), 'color': ((valorc) ? 'white' : '#757575') })}
          orden={'valorb'}
        />
        <RetosInforme
          informe={informe}
          modalInformeRetos={modalInformeRetos}
          mostrarModalInforme={mostrarModalInforme}
          actualizarMensaje={actualizarMensaje}
        />
      </>
    )
  }

  render() {
    return (
      this.pintarInformes()
    )
  }
}

const mapStateToProps = (state) => {
  let { modalInformeRetos } = state.redRetoscuc
  let { usuario } = state.redGlobal;

  return {
    usuario,
    modalInformeRetos
  }
}

const mapDispatchToProps = {
  mostrarModalInforme,
  actualizarMensaje,
}

AdminInforme.propTypes = {
  //variables
  actualizarMensaje: PropTypes.func.isRequired,

}

export default connect(mapStateToProps, mapDispatchToProps)(AdminInforme)