import React, { Component } from 'react';
import { connect } from "react-redux";
import moment from 'moment'
import { Grid, Paper, Typography, Card, CardHeader, CardContent, Avatar, Table, TableBody, TableRow, TableCell } from '@material-ui/core'
import { red } from '@material-ui/core/colors';
// FUNCIONES GENERALES
import { consulta, mostrarError, generarFiltros, obtenerValoresFiltros } from "../../global/js/funciones";
import AccionesRapidas from '../general/AccionesRapidas'
import { BtnDetalle } from '../general/BotonesAccion';
import ListarDatos from '../general/ListarDatos';
import TituloAccion from "../general/TituloAccion";
import TareasTerminadas from '../general/TareasTerminadas';
import { mostrarModulo, actualizarMensaje } from "../../redux/actions/actGlobal";
// FUNCIONES DEL MODULO
import { mostrarModalRespuestas, mostrarAgregarAutodiag } from "../../redux/actions/actAutodiagnostico";
import { MenuEstudiante } from "./autodiagnostico/MenuAutodiagnostico"
import AutodiagnosticoDetalle from "./autodiagnostico/AutodiagnosticoDetalle";
import AutodiagnosticoAgregar from "./autodiagnostico/AutodiagnosticoAgregar";
// IMAGENES
import emma_s from '../../global/imagenes/emma_s1.png'
import emma_w from './../../global/imagenes/emma_w.png';
import revisar from '../../global/imagenes/revisar.png';
import assigment from '../../global/imagenes/preguntas.png';


class AutodiagnosticoEstudiantes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      seleccion: 'proceso',
      cargando: true,
      // linea_estados: [],
      historial: [],
      test_hoy: null,
      autodiagnostico: null,
      sw_test: '',
      mensajes: [],
    }
  }

  async componentDidMount() {
    let { mostrarModulo } = this.props;
    mostrarModulo({ ruta: '/autodiagnostico', nombre: 'Autodiagnóstico' })
    this.obtenerDatosIniciales();
  }

  async componentDidUpdate({ }, { seleccion }) {
    let { seleccion: seleccionNew } = this.state;
    if (seleccion !== seleccionNew) {
      if (seleccionNew === 'proceso') this.obtenerDatosIniciales();
      else if (seleccionNew === 'nuevo') this.validarAutodiagnostico()
      else if (seleccionNew === 'historial') this.obtenerHistorial();
    }
  }

  async obtenerDatosIniciales() {
    this.setState({ cargando: true, enListado: true, })
    let test_hoy = await this.obtenerAutodiagnostico([{ 'llave': 'fecha_actual', 'valor': moment().format('YYYY-MM-DD') }]);
    let test_detalle = null
    if (test_hoy && test_hoy[0]) test_detalle = test_hoy[0]
    this.setState({ test_hoy: test_detalle, autodiagnostico: test_detalle, cargando: false })
  }

  async obtenerAutodiagnostico(filtros = []) {
    let f = await generarFiltros(filtros);
    return new Promise(resolve => {
      consulta(`api/v1.0/autodiagnostico/listar/0?${f}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  async obtenerHistorial() {
    this.setState({ cargando: true })
    let historial = await this.obtenerAutodiagnostico();
    this.setState({ historial, cargando: false })
  }

  async validarAutodiagnostico() {
    this.setState({ cargando: true })
    let mensajes = []
    let validacion_test = await this.validarTest();
    let dataMensajes = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Auto_Mens' }]);
    if (dataMensajes.length != 0) {
      mensajes = [dataMensajes[0]['valora'], dataMensajes[0]['valorb'], dataMensajes[0]['valorc']];
    }
    this.setState({ sw_test: validacion_test.encuesta, mensajes, cargando: false })
  }

  async validarTest() {
    return new Promise(resolve => {
      consulta(`api/v1.0/autodiagnostico/validar`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  guardarAutodiagnostico = async (data, callback) => {
    let { mostrarAgregarAutodiag, actualizarMensaje } = this.props;
    consulta(`api/v1.0/autodiagnostico/crear`, data, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          mostrarAgregarAutodiag(false);
          this.setState({ seleccion: 'proceso' });
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          callback()
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    })
  }

  pintarProcesoActual() {
    let { mostrarModalRespuestas } = this.props;
    let { test_hoy } = this.state;

    const ejecutarAccion = () => {
      mostrarModalRespuestas(true);
    }

    if (test_hoy) {
      let { usuario_registro: { primer_nombre, primer_apellido, segundo_apellido, identificacion }, estado_actual, fecha_registro } = test_hoy
      return (
        <div className='' >
          <div style={{ padding: '2% 2% 0% 2%' }} >
            <Grid container direction={'row'} spacing={1} >
              <Grid item xs={12} sm={12} md={6} >
                <TituloAccion principal='' alterno='¡ Información Básica !' />
                <Paper elevation={0} square className="scroll">
                  <Table>
                    <TableBody>
                      <TableRow key={1}>
                        <TableCell component="th" scope="row">Estudiante</TableCell>
                        <TableCell align="left">{`${primer_nombre} ${primer_apellido} ${segundo_apellido}`}</TableCell>
                      </TableRow>
                      <TableRow key={2}>
                        <TableCell component="th" scope="row">Identificacion</TableCell>
                        <TableCell align="left">{identificacion}</TableCell>
                      </TableRow>
                      <TableRow key={3}>
                        <TableCell component="th" scope="row">Fecha</TableCell>
                        <TableCell align="left">{moment(fecha_registro).format('YYYY-MM-DD, h:mm a')}</TableCell>
                      </TableRow>
                      <TableRow key={4}>
                        <TableCell component="th" scope="row">Estado Actual</TableCell>
                        <TableCell align="left">{estado_actual.nombre}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TituloAccion principal='' alterno='¿ Que deseas hacer ?' />
                <Paper elevation={0} square className="scroll">
                  <Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
                    <Grid item xs={12} sm={12} md={12} >
                      <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => ejecutarAccion()}>
                        <AccionesRapidas imagen={revisar} titulo='Más Información !' sub_titulo='Bien' des_sub_titulo=', Aquí puedes ver toda la información de tu test.' />
                      </Paper>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              {/* {
                credito.estado_actual.codigo !== 'Cre_Rec' &&
                <Grid item xs={12} sm={12} md={12} >
                  <TituloAccion principal='' alterno='¿ Como va mi proceso ?' />
                  {this.pintarTiempo()}
                </Grid>
              } */}
            </Grid>
          </div >
        </div>
      )
    } else return (<div className='pointer' onClick={() => this.setState({ seleccion: 'nuevo' })}><TareasTerminadas mensaje='Aún no has diligenciado el test del día, clic aquí para realizarlo' marginTop='7%' imagen={emma_s} widthImg="7%" /></div>)

  }

  pintarNuevo() {
    let { sw_test, mensajes } = this.state
    let { mostrarAgregarAutodiag } = this.props

    if (sw_test == 'habilitada') {
      return (
        <Grid container direction={'row'} justifyContent={'center'} style={{ paddingTop: 40 }}>
          <Grid item xs={12} sm={10} md={6} lg={5} style={{ padding: '1% 2% 0% 2%' }} onClick={() => { mostrarAgregarAutodiag(true) }}>
            <Paper elevation={8} square>
              <Card style={{ maxWidth: "100%", height: '480px', cursor: 'pointer' }} elevation={1}>
                <CardHeader
                  avatar={<Avatar aria-label="recipe" style={{ backgroundColor: red[500] }}>T</Avatar>}
                  title={`Test Autodiagnóstico`}
                />
                <CardContent >
                  <Grid container direction={'row'} justifyContent={'center'}>
                    <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center', height: "200px" }}>
                      <img src={assigment} style={{ width: '200px', maxWidth: '200px', minWidth: '100px', height: "200px" }} alt='Imagen Tipo' />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} style={{ padding: "15px 10px 0px 10px", }}>
                      <Typography variant="body2" color="textSecondary" component="p" align='justify'>
                        {mensajes[0]}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        </Grid>
      )
    } else if (sw_test == 'realizada') {
      let mens = 'Gracias por diligenciar el test Autodiagnóstico. '
      if (mensajes.length != 0) {
        mensajes[1].split(";").forEach(e => {
          mens = `${mens}${e}\n`
        });
      }
      return (<div><TareasTerminadas mensaje={mens} marginTop='7%' imagen={emma_s} widthImg="7%" /></div>)
    } else return (<div><TareasTerminadas mensaje={ mensajes.length != 0 ? mensajes[2] : '' } marginTop='7%' imagen={emma_s} widthImg="7%" /></div>)
  }

  pintarHistorial() {
    let { historial } = this.state;
    let { mostrarModalRespuestas } = this.props;

    const obtenerColor = (codigo) => {
      let color = 'white';
      const colores = {
        'Aut_Env': { 'backgroundColor': '#EEEEEE', 'color': '#757575' },
        'Aut_Pen': { 'backgroundColor': '#ffc107', color },
        'Aut_Rev': { 'backgroundColor': '#01579b', color },
        'Aut_Com': { 'backgroundColor': '#009688', color },
      }
      return (colores[codigo])
    }

    const acciones = (data) => {
      const onClickDetalle = (autodiagnostico) => {
        this.setState({ autodiagnostico })
        mostrarModalRespuestas(true)
      }
      let detalle = <BtnDetalle callback={() => onClickDetalle(data)} color='#01579b' texto='Abrir' />;
      return detalle;
    }

    return (
      <ListarDatos
        datos={historial}
        titulo='Historial de Tests Autodiagnóstico '
        id='tbl_historial_test'
        opciones={true}
        acciones={(data) => acciones(data)}
        buscar={true}
        avatar={({ estado_actual }) => estado_actual.nombre.toUpperCase().charAt(0)}
        color_avatar={({ estado_actual }) => obtenerColor(estado_actual.codigo)}
        fila_principal={({ usuario_registro: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`}
        filas={[
          { 'id': 'fecha_registro', 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a') },
          { 'id': 'estado', 'mostrar': ({ estado_actual }) => estado_actual.nombre },]}
      />
    )
  }
  render() {
    let { modalRespuestas, mostrarModalRespuestas, mostrarAgregarAutodiag, modalAgregarAutodiag, actualizarMensaje } = this.props
    let { seleccion, cargando, autodiagnostico } = this.state

    return (
      <div>
        <MenuEstudiante
          seleccionar={(seleccion) => this.setState({ seleccion })}
          sele={seleccion}
        />
        <div className='contenido_modulo'>
          <AutodiagnosticoDetalle
            modalRespuestas={modalRespuestas}
            mostrarModalRespuestas={mostrarModalRespuestas}
            autodiagnostico={autodiagnostico}
          />
          <AutodiagnosticoAgregar
            mostrarAgregarAutodiag={mostrarAgregarAutodiag}
            modalAgregarAutodiag={modalAgregarAutodiag}
            actualizarMensaje={actualizarMensaje}
            guardarAutodiagnostico={this.guardarAutodiagnostico}
          />
          {
            !cargando
              ?
              <div>
                {seleccion === 'proceso' && this.pintarProcesoActual()}
                {seleccion === 'nuevo' && this.pintarNuevo()}
                {seleccion === 'historial' && this.pintarHistorial()}
              </div>
              : <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  let { modalRespuestas, modalAgregarAutodiag } = state.redAutodiagnostico
  return {
    modalRespuestas,
    modalAgregarAutodiag
  }
}

const mapDispatchToProps = {
  mostrarModulo,
  mostrarModalRespuestas,
  mostrarAgregarAutodiag,
  actualizarMensaje
}


export default connect(mapStateToProps, mapDispatchToProps)(AutodiagnosticoEstudiantes);
