export const TIPOS_GRADOS = 22; //3031
export const ESTADOS_GRADOS = 23; //3031
export const GR_INVESTIGACION_GRADOS = 25; //3031
export const REQUISITOS_GRADOS = 27; //3031
export const PERSONAS_EXTERNAS = 26; //3030
export const DIPLOMADOS = 30; //
export const ID_DIPLOMADO = 30  // PROD 2422
export const LISTA_CURSOS_POSGRADO=31

// export const Id_Estado_tutoria = 81; 
// export const Id_Carreras_CUC = 5;
// export const Id_secundario_generica = 30;