import React, { Component } from 'react';
import moment from 'moment'
import { Grid, Paper } from '@material-ui/core'

//components
import ListarDatos from '../../general/ListarDatos';
import { BtnDetalle } from '../../general/BotonesAccion';

class Historial extends Component {

    actions = row => {
        let { abrirDetalleVacante } = this.props
        return (
            <div>
                <BtnDetalle 
                    callback={() => {
                        abrirDetalleVacante(row.vacante)
                    }}
                />
            </div>
        )
    }

    obtenerColor = codigo => {
        let color = '#ffffff'
        const colores = {
            'Por_Proc': { 'backgroundColor': '#f5f5f5', 'color': '#000000' },
            'Por_Cont': { 'backgroundColor': '#44c408', 'color': color },
            'Por_Rech': { 'backgroundColor': '#c21b1b', 'color': color }
        }
        return colores[codigo]
    }

    render() {
        let { historial } = this.props

        return (
            <ListarDatos
                id="tbl_inf_historial"
                datos={historial}
                titulo={"Historial de procesos"}
                agregar={false}
                descargar={''}
                actAgregar={() => console.log('Hola')}
                opciones={true}
                buscar={true}
                sub_titulo={"Mi historial"}
                avatar={({ estado_proceso }) => null}
                color_avatar={({ estado_proceso }) => this.obtenerColor(estado_proceso.codigo)}
                acciones={row => this.actions(row)}
                fila_principal={({ vacante }) => `Cargo: ${vacante.nombre_cargo.toUpperCase()}`}
                filas={[
                    {
                        'mostrar': ({ estado_proceso }) => `Estado: ${estado_proceso.nombre}`,
                        'id': 'n_vacantes',
                        'enLista': true
                    },
                    {
                        'mostrar': ({ vacante }) => `Salario: ${vacante.salario}`,
                        'id': 'salario',
                        'enLista': true
                    },
                    {
                        'mostrar': ({ vacante }) => moment(vacante.fecha_registro).format('YYYY-MM-DD'),
                        'id': 'fecha_inicio',
                        'enLista': true
                    }
                ]}
            />
        );
    }
}

export default Historial;