import React, { Component, useState } from 'react'
import { Dialog, DialogContent, DialogActions, Link, FormControl, TextField, Grid } from '@material-ui/core';
import AppBarModal from '../general/AppBarModal';
import { BtnForm, BtnDetalle, BtnCancelar } from '../general/BotonesAccion';
import ListarDatos from '../general/ListarDatos';
import { consulta, generarFiltros, formulario, mostrarError, coloresEmma } from '../../global/js/funciones'
import { BecasMenu } from "./administrar/MenuBecas"
import { DetalleSolicitud } from "./Beca"
import TareasTerminadas from "./../general/TareasTerminadas";
import emma_w from './../../global/imagenes/emma_w.png';
import moment from 'moment'


function AdjuntoNegar({ negarDocumento, open, show, setNota, nota }) {
  return (
    <Dialog open={open} maxWidth="sm" fullWidth={true} onClose={() => show(false)} >
      <AppBarModal titulo='Negar Documento' mostrarModal={() => show(false)} titulo_accion='' />
      <DialogContent className='scroll' >
        <Grid item xs={12} md={12}>
          <FormControl fullWidth style={{ marginTop: '10px' }}>
            <TextField id="comment_input" label="Observación" variant="outlined" multiline rows="3" value={nota} onChange={(evt) => setNota(evt.target.value)} />
          </FormControl>
        </Grid>
      </DialogContent>
      <DialogActions>
        <BtnForm texto="Guardar" callback={() => negarDocumento()} />
        <BtnForm texto="Cerrar" callback={() => show(false)} />
      </DialogActions>
    </Dialog>
  );
}

class BecaDetalle extends Component {
  constructor(props) {
    super(props)
    this.state = {
      estados: [],
      adjuntos: [],
      previos : [],
      datos: [],
      cargando: false,
      seleccion: '',
      adjunto : [],
      nota : '',
      porcentaje:''
    };
  }
    
    
  async componentDidUpdate({ modalDetBeca }, { seleccion }) {
    let { seleccion: seleccionNew } = this.state;
    let { beca, modalDetBeca: modalDetBecaNew, vistaDetalle, id_estudiante } = this.props;
    if (seleccionNew !== seleccion) {
      if (seleccionNew === 'detalle') this.obtenerInfoBeca(beca);
      else if (seleccionNew === 'documentos') this.obtenerAdjuntos(beca);
      else if (seleccionNew === 'previos') this.obtenerPrevios(id_estudiante);
      else if (seleccionNew === 'estados') this.obtenerEstadosBeca(beca);
    }
    if (modalDetBeca !== modalDetBecaNew) this.setState({ seleccion: modalDetBecaNew ? vistaDetalle : '' })
  }

  async obtenerInfoBeca(id) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'id', 'valor': id }]);
    consulta(`api/v1.0/beca?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, datos: resp });
    })
  }

  async obtenerAdjuntos(id) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'beca', 'valor': id }]);
    consulta(`api/v1.0/beca/adjuntos?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, adjuntos: resp });
    })
  }

  async obtenerEstadosBeca(id) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'beca', 'valor': id }]);
    consulta(`api/v1.0/beca/estados?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, estados: resp });
    })
  }

  async obtenerPrevios(id_est) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'estudiante', 'valor': id_est }]);
    consulta(`api/v1.0/beca/previos?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, previos: resp });
    })
  }

  negarAdjunto = () => {
    let { actualizarMensaje, mostrarModalComment, beca } = this.props
    let { adjunto, nota } = this.state
    const data = new FormData();
    data.append('nota', nota)
    data.append('validacion', 3)
    mostrarModalComment(false);
    this.setState({ cargando: true, titulo_carg: 'Guardando..' })
    formulario(`api/v1.0/becas/adjuntos/${adjunto.id}/validar`, data, "put", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          this.obtenerAdjuntos(beca)
        } else {
          mostrarModalComment(true);
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      this.setState({ nota: '', cargando: false, titulo_carg: 'Cargando..' })
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    })
  }

  pintarEstados = () => {
    let { estados } = this.state;

    const obtenerColor = (codigo) => {
      let color = 'white';
      const colores = {
        'Est_bec_env': { 'backgroundColor': coloresEmma.colorblanco, 'color': '#757575' },
        'Est_bec_rev': { 'backgroundColor': coloresEmma.solicitudactiva, color },
        'Est_bec_apr': { 'backgroundColor': coloresEmma.solicitudfinalizada, color },
        'Est_bec_apl': { 'backgroundColor': coloresEmma.solicitudfinalizada, color },
        'Est_bec_neg': { 'backgroundColor': coloresEmma.solicitudcancelada, color },
        'Est_bec_can': { 'backgroundColor': coloresEmma.solicitudcancelada, color }
      }
      return (colores[codigo])
    }

    return (
      <ListarDatos
        id="tbl_estados_beca"
        datos={estados}
        avatar={({ tipo_estado }) => tipo_estado.nombre.charAt(0).toUpperCase()}
        color_avatar={({ tipo_estado: { codigo } }) => obtenerColor(codigo)}
        fila_principal={({ tipo_estado }) => tipo_estado.nombre.toUpperCase()}
        filas={[
          { 'id': 'usurio_registro', 'enLista': true, 'mostrar': ({ usuario_registro }) => `${usuario_registro.primer_nombre} ${usuario_registro.segundo_nombre} ${usuario_registro.primer_apellido} ${usuario_registro.segundo_apellido}`.toUpperCase() },
          { 'id': 'fecha_registro', 'enLista': true, 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a') },
          { 'id': 'mensaje', 'enLista': true, 'mostrar': ({ mensaje }) => mensaje },
        ]}
      />
    )
  };
    
  pintarAdjuntos = () => {
    let { adjuntos } = this.state;
    let { mostrarModalComment, admin, beca_codigo } = this.props;
    const obtenerColor = ({validacion}) => {
      let color = 'white';
      const colores = {
        '1': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
        '2': { 'backgroundColor': '#01519B', color },
        '3': { 'backgroundColor': '#b71c1c', color },
      }
      return (colores[validacion])
    }

    const acciones = (data) => {
      const onClickNegar = () => {
        this.setState({ adjunto: data, nota: '' })
        mostrarModalComment(true)
      }
      let detalle = <Link href={`${data.archivo}`} target="_blank"><BtnDetalle callback={() => { }} /></Link>
      let negar = (data.validacion === '1' && beca_codigo == 'Est_bec_env' && admin) && <BtnCancelar callback={() => onClickNegar()} texto='Negar' />;
      return <div>{detalle} {negar}</div>;
    }

    return (
      <ListarDatos
        id="tbl_mis_adjuntos"
        datos={adjuntos}
        avatar={({ requisito }) => requisito.nombre.charAt(0).toUpperCase()}
        color_avatar={data => obtenerColor(data)}
        acciones={data => acciones(data)}
        fila_principal={({ requisito }) => requisito.nombre.toUpperCase()}
        filas={[
          { 'id': 'nombre_archivo', 'enLista': false },
          { 'id': 'validacion_text', 'enLista': true, 'mostrar': ({ validacion_text }) => validacion_text.toUpperCase() },
          { 'id': 'fecha_registro', 'enLista': true, 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a') },
          { 'id': 'valido', 'enLista': true, 'mostrar': ({ usuario_valida }) => `${  usuario_valida ? `VALIDADO: ${usuario_valida.primer_nombre} ${usuario_valida.primer_apellido} ${usuario_valida.segundo_apellido} - ${usuario_valida.correo}` : ''}` },
          { 'id': 'nota', 'enLista': true, 'mostrar': ({ validacion, nota }) => `${validacion == 3 ? `${nota}` : ''}` },
        ]}
      />
    )
  };

  pintarPrevios = () => {
    let { previos } = this.state;

    return (
      <ListarDatos
        id="tbl_mis_adjuntos_previos"
        datos={previos}
        avatar={({adjunto : { requisito }}) => requisito.nombre.charAt(0).toUpperCase()}
        color_avatar={() => ({ 'backgroundColor': '#009688', color : 'white' })}
        acciones={({adjunto}) => <Link href={`${adjunto.archivo}`} target="_blank"><BtnDetalle callback={() => { }} color="#01579b" /></Link>}
        fila_principal={({adjunto : { requisito }}) => requisito.nombre.toUpperCase()}
        filas={[
          { 'id': 'fecha_registro', 'enLista': true, 'mostrar': ({adjunto : { fecha_registro }}) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a') },
        ]}
      />
    )
  };

  pintarBeca = () => {
    let { datos } = this.state;
    let { admin } = this.props;
    if (datos[0]) {
      return <DetalleSolicitud datos={datos[0]} gestor={admin}/>;
    }
  }
    
  pintar = () => {
    let { cargando, seleccion } = this.state;
    if (cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />;
    return (
      <div>
        {seleccion === 'detalle' && this.pintarBeca()}
        {seleccion === 'documentos' && this.pintarAdjuntos()}
        {seleccion === 'previos' && this.pintarPrevios()}
        {seleccion === 'estados' && this.pintarEstados()}
      </div>
    )

  }
    
  render() {
    let { seleccion, datos, nota } = this.state;
    let { modalDetBeca, mostrarModalDetalleBeca, mostrarModalComment, modalAddComment, admin } = this.props

    return (
    <>
      <Dialog open={modalDetBeca} maxWidth="sm" fullWidth={true} onClose={mostrarModalDetalleBeca.bind(this, false)}>
        <AppBarModal titulo='¡ Detalle Beca !' mostrarModal={mostrarModalDetalleBeca} titulo_accion='' />
        <BecasMenu sele={seleccion} seleccionar={seleccion => this.setState({ seleccion })} />
        <DialogContent style={{ padding: 0 }} className='scroll' >
          {this.pintar()}
        </DialogContent>
        <DialogActions>
          {datos[0] && datos[0].volante ? <Link href={`${datos[0].volante}`} target="_blank"><BtnForm texto="DESCARGAR VOLANTE" callback={() => { }} color="#01579b" /></Link> : ''}
          <BtnForm texto="Cerrar" callback={() => mostrarModalDetalleBeca(false)} />
        </DialogActions>
      </Dialog>
      { admin && 
        <AdjuntoNegar show={mostrarModalComment} open={modalAddComment} negarDocumento={this.negarAdjunto} setNota = {nota => this.setState({nota})} nota={nota} />
      }
    </>
    );
  }
}

export default BecaDetalle