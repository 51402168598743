import {
  MODAL_DETALLE_INSCRIPCIONES,
  MODAL_DETALLE_INSCRIPCIONESPRE,
  MODAL_ADD_FILTRO_INSCRIPCION,
  MODAL_GES_INSCRIPCION,
  MODAL_EDITAR_INSCRIPCIONES,
  MODAL_PERIODO_INSCRIPCIONES,
  MODAL_PERIODO_ACTUALIZAR_INSCRIPCIONES,
  MODAL_MEN_VALIDACION,
  MODAL_AUTORIZACION_PADRE,
  MODAL_AUTORIZACION_CUMPLIMIENTO,
  MODAL_AUTORIZACION_CALIDAD,
  MODAL_GESTION_PREUNIVERSITARIO,
  MODAL_OBSERVACION_PREUNIVERSITARIO,
  MODAL_ASIGNAR_PREUNIVERSITARIO,
  MODAL_PLAN_ACOMPANAMIENTO,
  MODAL_VER_PLAN,
} from "../actions/actInscripciones";

const initialState = {
  modalDetalleInscripcion: false,
  modalDetalleInscripcionPre: false,
  modalAddFiltrosInscripcion: false,
  modalGestionarInscripcion: false,
  modalMensajeValidacionId: false,
  modalEditarInscripcion: false,
  modalPeriodoInscripcion: false,
  modalPeriodoActualizarInscripcion: false,
  modalAutorizacionPadre: false,
  modalAutorizacionCumplimiento: false,
  modalAutorizacionCalidad: false,
  modalGestionPreUniversitario: false,
  modalObservacionPreUniversitario: false,
  modalAsignarPreUniversitario: false,
  modalMostrarPlan: false,
  modalAgregarPlan: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MODAL_DETALLE_INSCRIPCIONES:
      return Object.assign({}, state, {
        modalDetalleInscripcion: action.show
      })
    case MODAL_DETALLE_INSCRIPCIONESPRE:
      return Object.assign({}, state, {
        modalDetalleInscripcionPre: action.show
      })
    case MODAL_ADD_FILTRO_INSCRIPCION:
      return Object.assign({}, state, {
        modalAddFiltrosInscripcion: action.show
      })
    case MODAL_GES_INSCRIPCION:
      return Object.assign({}, state, {
        modalGestionarInscripcion: action.show
    })
    case MODAL_MEN_VALIDACION:
      return Object.assign({}, state, {
        modalMensajeValidacionId: action.show
    })
    case MODAL_EDITAR_INSCRIPCIONES:
      return Object.assign({}, state, {
        modalEditarInscripcion: action.show
    })
    case MODAL_PERIODO_INSCRIPCIONES:
      return Object.assign({}, state, {
        modalPeriodoInscripcion: action.show
    })
    case MODAL_PERIODO_ACTUALIZAR_INSCRIPCIONES:
      return Object.assign({}, state, {
        modalPeriodoActualizarInscripcion: action.show
    })
    case  MODAL_AUTORIZACION_PADRE:
      return Object.assign({}, state, {
        modalAutorizacionPadre: action.show
    })
    case  MODAL_AUTORIZACION_CUMPLIMIENTO:
      return Object.assign({}, state, {
        modalAutorizacionCumplimiento: action.show
    })
    case  MODAL_AUTORIZACION_CALIDAD:
      return Object.assign({}, state, {
        modalAutorizacionCalidad: action.show
    })
    case  MODAL_GESTION_PREUNIVERSITARIO:
      return Object.assign({}, state, {
        modalGestionPreUniversitario: action.show
    })
    case  MODAL_OBSERVACION_PREUNIVERSITARIO:
      return Object.assign({}, state, {
        modalObservacionPreUniversitario: action.show
    })
    case  MODAL_ASIGNAR_PREUNIVERSITARIO:
      return Object.assign({}, state, {
        modalAsignarPreUniversitario: action.show
    })
    case MODAL_PLAN_ACOMPANAMIENTO:
      return Object.assign({}, state, {
        modalMostrarPlan: action.show,
      });
    case MODAL_VER_PLAN:
      return Object.assign({}, state, {
        modalAgregarPlan: action.show,
      });
    default:
      return state;
  }
}

export default reducer
