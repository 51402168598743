import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, Paper, Grid } from '@material-ui/core';
import ReactExport from 'react-data-export';
//ACCIONES DEL MODULO
import { consulta, generarFiltros, api } from "../../global/js/funciones";
import TareasTerminadas from "../general/TareasTerminadas";
import { BtnForm } from "../general/BotonesAccion";
import AppBarModal from '../general/AppBarModal';
import TituloAccion from '../general/TituloAccion';
import AccionesRapidas from '../general/AccionesRapidas';
import fechaIcon from '../../global/imagenes/calendar.png';
import sucursalesIcon from '../../global/imagenes/architecture-and-city.png';
import requisitosIcon from '../../global/imagenes/matricula_.png';
import emma_w from '../../global/imagenes/emma_w.png';
import emma_s1 from '../../global/imagenes/emma_s1.png';
import download from '../../global/imagenes/download.png';
import fileDownload from 'js-file-download';
import Axios from 'axios';


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class PracticasAccionesMasivas extends Component {
  constructor(props) {
    super(props)
    this.state = {
      accion: 'principal',
      listado: [],
      cargando: true
    }
  }

  componentDidUpdate() {
    let { modalAccionesMasivas } = this.props;
    let { accion } = this.state

    if(!modalAccionesMasivas && accion!='principal'){
      this.setState({ accion: 'principal',  cargando: true })
    }
  }

  render() {
    let { modalAccionesMasivas, mostrarModalAccionesMasivasPractica } = this.props;
    let { accion } = this.state;

    return (
      <Fragment>
        <Dialog maxWidth="sm" fullWidth open={modalAccionesMasivas} onClose={mostrarModalAccionesMasivasPractica.bind(this, false)} aria-labelledby="form-dialog-title">
          <AppBarModal titulo={'¡ Acciones Masivas !'} mostrarModal={mostrarModalAccionesMasivasPractica} titulo_accion={''} accion={''} />
          <DialogContent style={{ padding: 0 }} className='scroll'>
            <div style={{ padding: '2% 2% 0% 2%' }} >
              <Grid container direction={'row'} spacing={1} justify={'center'} >
                <Grid item xs={12}>
                  { this.pintarAdministrar() }
                </Grid>
              </Grid>
            </div>
          </DialogContent>
          <DialogActions>
              <BtnForm texto="CANCELAR" callback={() => mostrarModalAccionesMasivasPractica(false)} />
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  }

  obtenerPracticas = async () => {
    let { seleccionMasiva } = this.props

    consulta(`api/v1.0/practicas/masivo/informe`, { seleccion: seleccionMasiva }, "put",
      (error, estado, resp) => {
        if (estado === 200) {
          this.setState({ listado: resp, cargando: false })
        }
      }
    );
  }

  pintarAdministrar() {
    let { modalAccionesMasivas } = this.props;
    let { accion } = this.state;

    return (
      <Fragment>
        {accion === 'principal' && modalAccionesMasivas && this.pintarPrincipal() }
        {accion === 'reporte' && this.Reporte()}
        {accion === 'requisitos' && this.descargarRequisitos()}
      </Fragment>
    )
  }

  pintarPrincipal = () => {
    let { practicas } = this.props
    let practica = practicas.length ? practicas[0] : false;

    return (
      <Fragment>
        <TituloAccion principal='' alterno='¿ Que deseas hacer ?' />
        <Paper elevation={0} square className="scroll contenido_fijo">
          <Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
          { practica.estado_actual.codigo=='Est_Pra_Env' &&
            <Grid item xs={12} sm={12} md={6} lg={6} >
              <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.pintarInduccion() }>
                <AccionesRapidas imagen={fechaIcon} titulo={`¡ Inducción General !`} sub_titulo='' des_sub_titulo='Agende las inducciones, según el horario establecido.' />
              </Paper>
            </Grid>
          }
          { practica.estado_actual.codigo=='Est_Pra_Pol' &&
            <Grid item xs={12} sm={12} md={6} lg={6} >
              <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.pintarInduccion() }>
                <AccionesRapidas imagen={fechaIcon} titulo={`¡ Inducción ARL !`} sub_titulo='' des_sub_titulo='Agende las inducciones, según el horario y modalidad establecida.' />
              </Paper>
            </Grid>
          }
          { practica.estado_actual.codigo=='Est_Pra_As' &&
            <Grid item xs={12} sm={12} md={6} lg={6} >
              <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.pintarInduccion() }>
                <AccionesRapidas imagen={fechaIcon} titulo={`¡ Inducción Especifica !`} sub_titulo='' des_sub_titulo='Agende la o las inducciones, según el horario y modalidad establecida en el sitio de praticas.' />
              </Paper>
            </Grid>
          }
          { practica.estado_actual.codigo=='Est_Pra_Arl' &&
            <Grid item xs={12} sm={12} md={6} lg={6} >
              <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.pintarARL() }>
                <AccionesRapidas imagen={sucursalesIcon} titulo='¡ Asignar Poliza S.C. !' sub_titulo='' des_sub_titulo='Asigne a los solicitantes a la Poliza de Seguridad Civil.' />
              </Paper>
            </Grid>
          }
            <Grid item xs={12} sm={12} md={6} lg={6} >
              <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => { this.setState({ accion: 'reporte'}); this.obtenerPracticas() } }>
                <AccionesRapidas imagen={sucursalesIcon} titulo='¡ Generar Informe !' sub_titulo='' des_sub_titulo='Descargue un repote con los datos de los solicitantes.' />
              </Paper>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} >
              <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => { this.setState({ accion: 'requisitos'}); this.obtenerRequsitos() } }>
                <AccionesRapidas imagen={requisitosIcon} titulo='¡ Descargar Requisitos !' sub_titulo='' des_sub_titulo='Descargue los requisitos de los solicitantes seleccionado.' />
              </Paper>
            </Grid>

          </Grid>
        </Paper>
      </Fragment>
    )
  }

  pintarInduccion = () => {
    this.props.mostrarModalGestionarPractica(true)
    this.props.mostrarModalAccionesMasivasPractica(false)
  }

  pintarARL = () => {
    this.props.mostrarModalGestionarPractica(true)
    this.props.mostrarModalAccionesMasivasPractica(false)
  }

  Reporte = () => {
    let { listado, cargando } = this.state;

    let datos=[], titulos = [
      { title: "", width:{ wch: 1.5} },
      { title: "Tipo de Id.", width: {wch: 13} },
      { title: "No. Documento de identidad", width: {wch: 12} },
      { title: "Lugar de expedición", width: {wch: 17} },
      { title: "Fecha de expedición", width: {wch: 14} },
      { title: "Primer Apellido", width: {wch: 18} },
      { title: "Segundo Apellido", width: {wch: 17} },
      { title: "Primer Nombre", width: {wch: 14} },
      { title: "Segundo Nombre", width: {wch: 15} },
      { title: "Programa", width: {wch: 33} },
      { title: "Fecha de nacimiento", width: {wch: 14} },
      { title: "Sexo", width: {wch: 10} },
      { title: "Correo Institucional", width: {wch: 16} },
      { title: "Ciudad o Municipio de residencia", width: {wch: 20} },
      { title: "Cargo", width: {wch: 17} },
      { title: "Nivel de Riesgo", width: {wch: 10} },
      { title: "EPS", width: {wch: 20},  },
      { title: "Régimen", width: {wch: 15},  },
      { title: "Fecha de Inicio ARL", width: {wch: 14},  },
      { title: "Fecha de Finalización", width: {wch: 11} },
      { title: "ASIGNATURA ELECTIVA", width: {wch: 30} },
      { title: "ASIGNACIÓN ESCENARIO", width: {wch: 14} },
    ]
    titulos.map(item => item.style={font: {sz: "11", bold: true, name: 'Arial Narrow'}, alignment: { vertical: "center", horizontal: "center", wrapText: true}})

    listado.map((item)=>{
        datos.push([
          { value: ""},
          { value: item.usuario_registro.tipo_identificacion }, 
          { value: item.usuario_registro.identificacion },
          { value: (item.usuario_registro.lugar_expedicion) ? item.usuario_registro.lugar_expedicion : ''},
          { value: (item.usuario_registro.fecha_expedicion) ? item.usuario_registro.fecha_expedicion : ''},
          { value: item.usuario_registro.primer_apellido }, 
          { value: item.usuario_registro.segundo_apellido }, 
          { value: item.usuario_registro.primer_nombre }, 
          { value: item.usuario_registro.segundo_nombre }, 
          { value: item.programa },
          { value: (item.usuario_registro.fecha_nacimiento) ? item.usuario_registro.fecha_nacimiento : '' }, 
          { value: (item.usuario_registro.sexo) ? (item.usuario_registro.sexo==1 ? 'MASCULINO' : 'FEMENINO') : '' },
          { value: (item.usuario_registro.correo) ? item.usuario_registro.correo : '' }, 
          { value: "" }, 
          { value: "" }, 
          { value: "3" }, 
          { value: item.eps }, 
          { value: (item.afiliacion==1) ? 'CONTRIBUTIVO' : 'SUBSIDIADO' }, 
          { value: "" },
          { value: "" }, 
          { value: item.opcion_materia },
          { value: "" }, 
        ])
      })

    //datos.map(item => item.style={font:{sz: "11", name: "Arial"}})

    const multiDataSet = [{
            columns: titulos,
            data: datos
        }];

    return (
      <div>
        { cargando ? <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" /> : 
          <ExcelFile filename="Informe Prácticas" element={<TareasTerminadas mensaje={`Descargar Informe`} marginTop='7%' imagen={download} widthImg="7%" />}>
              <ExcelSheet dataSet={multiDataSet} name="Informe Prácticas"/>
          </ExcelFile>
        }
      </div>
    );
  }

  descargarRequisitos = () => {
    let { cargando } = this.state;
    
    return (
      <div>
        { cargando ? <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" /> :
          <TareasTerminadas mensaje='Se han descargado los requisitos de los estudiantes seleccionados' marginTop='7%' imagen={emma_s1} widthImg="7%" /> 
        }
      </div>
    );
  }

  obtenerRequsitos = async () => {
    const filtros = [{ 'llave': 'id_in', 'valor': this.props.seleccionMasiva }];
    const f = await generarFiltros(filtros);

    let url = `${api}/api/v1.0/practicas/masivo/requisitos?${f}`

    Axios.get(url, {
      responseType: 'blob',
    }).then(res => { 
      fileDownload(res.data, 'Requisitos.zip');
      if(res.status==200) this.setState({ cargando: false })
    });

  }
  
}

//Props del componente
PracticasAccionesMasivas.propTypes = {
  //variables
  modalAccionesMasivas: PropTypes.bool.isRequired,
  mostrarModalAccionesMasivasPractica: PropTypes.func.isRequired
}

export default PracticasAccionesMasivas;