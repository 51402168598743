import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment'

export function Atencion({ atencion, respuesta }) {
  let {
    estado_actual,
    facultad,
    programa,
    tipo_incidente,
    tipo_atencion,
    estado_estudiante,
    observaciones,
    usuario_registro: { primer_nombre, primer_apellido, segundo_apellido, },
    fecha_registro,
  } = atencion
  console.log(programa)
  console.log(facultad)
  console.log(estado_estudiante)
  let fecha = moment(fecha_registro).format('YYYY-MM-DD, h:mm a');
  return (
    <Table>
      <TableBody>
        <TableRow key={1}>
          <TableCell component="th" scope="row">Tipo de Atención</TableCell>
          <TableCell align="left">{tipo_atencion.nombre}</TableCell>
        </TableRow>
        <TableRow key={2}>
          <TableCell component="th" scope="row">Solicitante</TableCell>
          <TableCell align="left">{`${primer_nombre} ${primer_apellido} ${segundo_apellido}`}</TableCell>
        </TableRow>
        { facultad != null &&
          <TableRow key={1}>
            <TableCell component="th" scope="row">Facultad</TableCell>
            <TableCell align="left">{facultad.nombre}</TableCell>
          </TableRow>
        }
        { programa != null &&
          <TableRow key={1}>
            <TableCell component="th" scope="row">Programa</TableCell>
            <TableCell align="left">{programa.nombre}</TableCell>
          </TableRow>
        }
        { estado_estudiante != null &&
          <TableRow key={3}>
            <TableCell component="th" scope="row">Estado del estudiante</TableCell>
            <TableCell align="left">{estado_estudiante.nombre}</TableCell>
          </TableRow>
        }
        <TableRow key={4}>
          <TableCell component="th" scope="row">Asunto</TableCell>
          <TableCell align="left">{tipo_incidente.nombre}</TableCell>
        </TableRow>
        { observaciones != null || observaciones != '' &&
          <TableRow key={5}>
            <TableCell component="th" scope="row">Detalle</TableCell>
            <TableCell align="left">{observaciones}</TableCell>
          </TableRow>
        }
        <TableRow key={6}>
          <TableCell component="th" scope="row">Estado Actual</TableCell>
          <TableCell align="left">{estado_actual.nombre}</TableCell>
        </TableRow>
        {estado_actual.codigo === 'STATE_FINALIZADO_ATENCION' && <TableRow key={7}>
          <TableCell component="th" scope="row">Respuesta</TableCell>
          <TableCell align="left">{respuesta}</TableCell>
        </TableRow>}
        <TableRow key={8}>
          <TableCell component="th" scope="row">Fecha de Registro</TableCell>
          <TableCell align="left">{fecha}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export function DetalleSolicitud({ atencion }) {
  let {
    usuario_registro: { primer_nombre, primer_apellido, segundo_apellido, identificacion, correo },
    facultad,
    programa,
    estado_actual,
    observaciones,
    fecha_registro,
    tipo_incidente,
    tipo_atencion,
    estado_estudiante,
    plataforma,
  } = atencion

  let fecha = moment(fecha_registro).format('YYYY-MM-DD, h:mm a');
  return (

    <Table>
      <TableBody>
        { facultad != null &&
          <TableRow key={1}>
            <TableCell component="th" scope="row">Facultad</TableCell>
            <TableCell align="left">{facultad.nombre}</TableCell>
          </TableRow>
        }
        { programa != null &&
          <TableRow key={1}>
            <TableCell component="th" scope="row">Programa</TableCell>
            <TableCell align="left">{programa.nombre}</TableCell>
          </TableRow>
        }
        { estado_estudiante != null &&
          <TableRow key={3}>
            <TableCell component="th" scope="row">Estado del estudiante</TableCell>
            <TableCell align="left">{estado_estudiante.nombre}</TableCell>
          </TableRow>
        }
        <TableRow key={1}>
          <TableCell component="th" scope="row">Tipo de Atención</TableCell>
          <TableCell align="left">{tipo_atencion.nombre}</TableCell>
        </TableRow>
        <TableRow key={7}>
          <TableCell component="th" scope="row">Asunto</TableCell>
          <TableCell align="left">{tipo_incidente.nombre}</TableCell>
        </TableRow>
        { plataforma != null && 
          <TableRow key={12}>
            <TableCell component="th" scope="row">Plataforma</TableCell>
            <TableCell align="left">{plataforma.nombre}</TableCell>
          </TableRow>
        }
        <TableRow key={2}>
          <TableCell component="th" scope="row">Solicitante</TableCell>
          <TableCell align="left">{primer_nombre} {primer_apellido} {segundo_apellido}</TableCell>
        </TableRow>
        <TableRow key={3}>
          <TableCell component="th" scope="row">Identificacion</TableCell>
          <TableCell align="left">{identificacion}</TableCell>
        </TableRow>
        <TableRow key={4}>
          <TableCell component="th" scope="row">Correo</TableCell>
          <TableCell align="left">{correo}</TableCell>
        </TableRow>
        <TableRow key={8}>
          <TableCell component="th" scope="row">Estado Actual</TableCell>
          <TableCell align="left">{estado_actual.nombre}</TableCell>
        </TableRow>
        { observaciones != null || observaciones != '' &&
          <TableRow key={5}>
            <TableCell component="th" scope="row">Detalle</TableCell>
            <TableCell align="left">{observaciones}</TableCell>
          </TableRow>
        }
        {/* <TableRow key={10}>
          <TableCell component="th" scope="row">Coordinador</TableCell>
          <TableCell align="left">{`${primer_nombre_cor} ${primer_apellido_cor} ${segundo_apellido_cor}`}</TableCell>
        </TableRow> */}
        <TableRow key={11}>
          <TableCell component="th" scope="row">Fecha Registro</TableCell>
          <TableCell align="left">{fecha}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
