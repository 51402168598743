import React, { useEffect, useState } from 'react';
import {
    Dialog, DialogContent, Grid, Typography, Button, Box, IconButton, DialogActions,
    TableContainer, Table, TableHead, TableCell, TableRow, useTheme, useMediaQuery
} from '@material-ui/core';
import useStylesRubrica from './cssRubrica.js'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import profesor from '../../global/imagenes/profesor informacion general.svg';
import AppBarModal from '../general/AppBarModal';
import { BtnForm } from '../general/BotonesAccion';

const InformacionRubrica = ({ rubrica, dataPlan, toggle }) => {
    const classes = useStylesRubrica();
    const [activeStepCorte, setActiveCorte] = React.useState('Primer Corte');
    const [resultadoA, setResultadoA] = React.useState([]);
    const [moreInfo, setMoreInfo] = React.useState('');
    const [moreInfoCheck, setMoreInfoCheck] = React.useState('');
    const [modalMoreInfo, setModalMoreInfo] = React.useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [cargando, setCargando] = React.useState(false);

    useEffect(() => {
        obtenerResultadosA()
    }, []);

    const obtenerResultadosA = async () => {
        setCargando(true);
        let aprendizajes = [];
        await rubrica.map(({ corte, resultado_de_aprendizaje }) => {
            let e = aprendizajes.find((data) => data.corte == corte);
            if (!e) aprendizajes.push({ corte, resultado_de_aprendizaje })
        })
        setResultadoA(aprendizajes);
        setCargando(false);
    }

    const MoreInfo = (data, titulo) => {
        return <IconButton color='primary' onClick={() => { setMoreInfo(data); setModalMoreInfo(true); setMoreInfoCheck(titulo) }} className={classes.btnMoreInfo}><MoreHorizIcon /></IconButton >;
    }

    const pintarResutadoA = () => {
        let res = resultadoA.find((data) => data.corte == activeStepCorte);
        if (res) {
            return res.resultado_de_aprendizaje
        } else {
            return "Por el momento no se ha cargado informacion en esta asignatura"
        }

    }

    const pintarRubrica = ()=>{
        if (rubrica.length > 0) {
           return rubrica.map(({ corte, criterio, avanzado, excelente, insuficiente, satisfactorio, minimo }) => {
                if (corte == activeStepCorte) {
                    return (
                        <tr>
                            <td style={{ textAlign: 'center' }}>{criterio}</td>
                            <td style={{ textAlign: 'center' }}>{MoreInfo(insuficiente, 'Insuficiente')}</td>
                            <td style={{ textAlign: 'center' }}>{MoreInfo(minimo, 'Mínimo')}</td>
                            <td style={{ textAlign: 'center' }}>{MoreInfo(satisfactorio, 'Satisfactorio')}</td>
                            <td style={{ textAlign: 'center' }}>{MoreInfo(avanzado, 'Avanzdo')}</td>
                            <td style={{ textAlign: 'center' }}>{MoreInfo(excelente, 'Excelente')}</td>
                        </tr>
                    )
                }
            })
        }else{
         return <h3>Por el momento no se ha cargado informacion en esta asignatura</h3>
        }
    }

    const pintarTabla = () => {
        return (
            <table style={{ width: '95%', borderCollapse: 'collapse', margin: 20, }} className='tabla_rubrica'>
                <thead className={classes.headTablaRubrica}>

                    <tr>
                        <th>
                            <Button style={{ background: activeStepCorte == 'Primer Corte' && '#9b771e', color: activeStepCorte == 'Primer Corte' && '#fff' }} className={classes.btnCorte} onClick={() => setActiveCorte("Primer Corte")}>Corte 1</Button>
                            <Button style={{ background: activeStepCorte == 'Segundo Corte' && '#9b771e', color: activeStepCorte == 'Segundo Corte' && '#fff' }} className={classes.btnCorte} onClick={() => setActiveCorte("Segundo Corte")}>Corte 2</Button>
                            <Button style={{ background: activeStepCorte == 'Tercer Corte' && '#9b771e', color: activeStepCorte == 'Tercer Corte' && '#fff' }} className={classes.btnCorte} onClick={() => setActiveCorte("Tercer Corte")}>Corte 3</Button>
                        </th>
                    </tr>
                    <tr>
                        <td rowspan={2} className={classes.tdRubrica}>Indicadores de desempeño</td>
                        <td colspan={5} className={classes.tdRubrica}>Nivel de desempeño</td>
                    </tr>
                    <tr>
                        <td className={classes.tdRubrica}>Insuficiente</td>
                        <td className={classes.tdRubrica}>Mínimo</td>
                        <td className={classes.tdRubrica}>Satisfactorio</td>
                        <td className={classes.tdRubrica}>Avanzado</td>
                        <td className={classes.tdRubrica}>Excelente</td>
                    </tr>
                </thead>
                <tbody >
                     {pintarRubrica()}                    
                </tbody>
            </table>
        )
    }

    return (
        <>
            <div style={{ textAling: 'center', height: '700px', backgroundColor: '#F9EBEA', paddingTop: '20px' }} className={classes.fondo}>
                <Grid container>
                    {
                        isMobile ?
                            (
                                <>
                                    <Grid item xs={12} style={{ textAling: 'center' }}>
                                        <Typography style={{ color: '#000' }}>
                                            <Box textAlign="center" fontSize={20} fontWeight="fontWeightMedium" >
                                                RUBRÍCA
                                            </Box>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} className={classes.tablero} style={{ width: '100%', overflow: 'auto' }}>
                                        <TableContainer style={{ color: '#2C2D2D' }} >
                                            <Table className={classes.table} aria-label="spanning table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="left" colSpan={2} >
                                                            <Typography>
                                                                <Box fontSize={15} >
                                                                    <b>Asignatura <b className="lineaTablero"> </b> </b>
                                                                    <span>{dataPlan ? dataPlan.nombre : null}</span>
                                                                </Box>
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="left" colSpan={10} >
                                                            <Typography>
                                                                <Box fontSize={15}>
                                                                    <b>Código <b className="lineaTablero"> </b> </b>
                                                                    <span>{dataPlan ? dataPlan.codigo : null}</span>
                                                                </Box>
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="left" colSpan={2}>
                                                            <Typography>
                                                                <Box fontSize={15} >
                                                                    <b>Resultado de Aprendizaje (RA) <b className="lineaTablero"> </b> </b>
                                                                    <span>{pintarResutadoA()}</span>
                                                                </Box>
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="left" colSpan={10} >
                                                            <Typography>
                                                                <Box fontSize={15}>
                                                                    <b>Docente <b className="lineaTablero"> </b> </b>
                                                                    <span>{dataPlan ? dataPlan.docente : null}</span>
                                                                </Box>
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                            </Table>
                                            {pintarTabla()}
                                        </TableContainer>

                                    </Grid>

                                </>
                            ) : (
                                <>
                                    <Grid item xs={2} style={{ textAlign: 'right' }}>
                                        <img src={profesor} width="85%" style={{ paddingTop: '15px' }} />
                                    </Grid>
                                    <Grid item xs={9} className={classes.tablero} style={{ width: '100%', overflow: 'auto' }}>
                                        <TableContainer style={{ color: '#2C2D2D' }} >
                                            <Table className={classes.table} aria-label="spanning table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="left" colSpan={2} >
                                                            <Typography>
                                                                <Box fontSize={15} >
                                                                    <b>Asignatura <b className="lineaTablero"> </b> </b>
                                                                    <span>{dataPlan ? dataPlan.nombre : null}</span>
                                                                </Box>
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="left" colSpan={10} >
                                                            <Typography>
                                                                <Box fontSize={15}>
                                                                    <b>Código <b className="lineaTablero"> </b> </b>
                                                                    <span>{dataPlan ? dataPlan.codigo : null}</span>
                                                                </Box>
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="left" colSpan={2}>
                                                            <Typography>
                                                                <Box fontSize={15} >
                                                                    <b>Resultado de Aprendizaje (RA) <b className="lineaTablero"> </b> </b>
                                                                    <span>{pintarResutadoA()}</span>
                                                                </Box>
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="left" colSpan={10} >
                                                            <Typography>
                                                                <Box fontSize={15}>
                                                                    <b>Docente <b className="lineaTablero"> </b> </b>
                                                                    <span>{dataPlan ? dataPlan.docente : null}</span>
                                                                </Box>
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                            </Table>
                                        </TableContainer>
                                        {pintarTabla()}
                                    </Grid>
                                   
                                </>
                            )
                    }

                </Grid>
            </div >
            <Dialog open={modalMoreInfo} onClose={() => setModalMoreInfo(false)} fullWidth={true} maxWidth="sm">
                <AppBarModal titulo={moreInfoCheck} mostrarModal={() => setModalMoreInfo(false)} titulo_accion="" accion="" />
                <DialogContent style={{ padding: 0 }} className='scroll'>
                    <p style={{ padding: 30, fontSize: 15 }}>{moreInfo}</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setModalMoreInfo(false)} > Cerrar</Button>
                </DialogActions>

            </Dialog>
        </>
    )
}

export default InformacionRubrica