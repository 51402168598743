import React, { Component } from "react";
import { connect } from "react-redux";

// COMPONENTES GENERALES
import ListarDatos from "../../general/ListarDatos";
import { Button, Typography, Grid, Paper, Card, CardContent, CardHeader, Avatar, Table, TableCell, Dialog, DialogContent, } from "@material-ui/core";
import { actualizarMensaje } from "../../../redux/actions/actGlobal";
import AppBarModal from "../../general/AppBarModal";
import { consulta } from "../../../global/js/funciones";
import TituloAccion from "../../general/TituloAccion";
import { BtnDetalle } from "../../general/BotonesAccion";
import emma_w from "../../../global/imagenes/emma_w.png";
import TareasTerminadas from "../../general/TareasTerminadas";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AccionesRapidas from "../../general/AccionesRapidas";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import { blue } from "@material-ui/core/colors";

// SISEF COMPONENTS
import { MostrarCalificacion } from "../Sisef_Desempenos";

class ProcesosSisefEstudiante extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seleccion: "inicio",
      Modal: false,
      mis_objetivos: [],
      calificacion_data: [],
      data: {},
      actividad: {},
      cargandoData: false,
      ver_calificacion: false,
    }
  };

  vista = () => {
    return this.actividadSisef()
  };

  async obtenerActvidadesCortes(corte) {
    let { periodo, codigo_asignatura } = this.props;
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/sisef/listar_actividades_all_student/${codigo_asignatura}/${periodo}/${corte}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }


  async ObjetivosEst(estudiante, calificacion) {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/sisef/obtener_objetivos_est/${estudiante}`,
        { calificacion },
        "POST",
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  async obtenerActividadId(id_calificacion) {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/sisef/calificaciones_student/${id_calificacion}`, null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }


  //   async ObtenerObjetivosEst(est, cal) {
  //     let objetivos = await this.ObjetivosEst(est, cal);
  //     let { data } = this.state;

  //     let detalle = [...objetivos];
  //     let copia_data = { ...data };
  //     let detalle_calificacion = [];

  //     if (Array.isArray(detalle)) {
  //       detalle.forEach(({ calificacion }) => {
  //         if (calificacion && 'criterio' in calificacion && 'proceso' in calificacion && 'detalle' in calificacion) {
  //           let dataCalificacion = {
  //             "criterio": calificacion.criterio,
  //             "proceso": calificacion.proceso,
  //             "detalle": calificacion.detalle,
  //           };
  //           detalle_calificacion.push(dataCalificacion);
  //         } else {
  //           console.warn('Elemento de detalle sin propiedades requeridas:', calificacion);
  //         }
  //       });
  //     } else {
  //       console.error('detalle no es un array');
  //     }


  //     copia_data = {
  //         "calificacion": detalle_calificacion,
  //     };

  //     let aux = [];
  //     objetivos.map(({ objetivo }) => { aux.push(objetivo.id) });

  //     this.setState({ mis_objetivos: objetivos, seleccionados: aux, data: copia_data });

  //     return copia_data;
  // }


  async ObtenerObjetivosEst(est, cal) {
    let objetivos = await this.ObjetivosEst(est, cal);
    let aux = [];
    objetivos.map(({ objetivo }) => { aux.push(objetivo.id) });
    this.setState({ mis_objetivos: objetivos, seleccionados: aux });
  }
  acciones(row) {

    const Mostrar = async () => {
      let { usuario } = this.props;
      let { calificacion_data } = this.state
      console.log("usuario", usuario);
      this.setState({ cargandoData: true })
      let actvidadEstudiante = await this.obtenerActividadId(row.id)
      const ids = actvidadEstudiante.map(objeto => objeto.id);
      await this.ObtenerObjetivosEst(usuario.identificacion, ids);

      console.log("actvidadEstudiante", actvidadEstudiante);

      let nombre = usuario.primer_nombre + " " + usuario.segundo_nombre + " " + usuario.primer_apellido + " " + usuario.segundo_apellido
      let info = {
        "nombre": nombre,
        "calificacion": actvidadEstudiante
      }
      this.setState({ cargandoData: false, data: info, ver_calificacion: true })
      // let estudiante = { ...row }
      // console.log("row", row);
      // estudiante.nombre = nombre

      // const ids = calificacion_data.map(objeto => objeto.id);


      //  let datos =  await this.ObtenerObjetivosEst(usuario.identificacion, ids);
      //   let data_all = {...estudiante, ...datos}

      // console.log(data_all)
      // this.setState({ cargandoData: false, data: data_all, ver_calificacion: true });

    };

    return (
      <>
        <BtnDetalle texto="Detalle" callback={() => Mostrar()} />
      </>
    );
  }


  actividadSisef() {
    let { seleccionarEstu } = this.props;
    const handleClick = async () => {
      seleccionarEstu()
    };

    const obtenerColor = () => {
      let color = "white";
      const colores = { backgroundColor: "#FD9727", color };
      return colores;
    };

    const handleClickCortes = async (corte) => {
      let dataActvidadCorte = await this.obtenerActvidadesCortes(corte)

      this.setState({ actividades_data: dataActvidadCorte, Modal: true, cargando: false });
    }


    return (
      <div>
        <Button onClick={() => handleClick()} style={{ padding: "0px 3px 0px 3px", marginTop: '1%' }} color="secondary" > <ArrowBackIcon /> <Typography variant="body2" style={{ color: '#9e9e9e' }} component="p" align='justify'> Regresar </Typography> </Button>
        <div style={{ padding: '0% 2% 0% 2%' }} >
          <Grid container direction={'row'} spacing={1} >
            <Grid item xs={12} sm={12} md={6} >
              {/* <TituloAccion principal='' alterno='Informacion sobre tus actividades' /> */}
              <Paper elevation={0} square className="scroll">
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  <CardHeader
                    avatar={
                      <Avatar
                        aria-label="recipe"
                        style={{ backgroundColor: blue[500] }}
                        variant="square"
                      >
                        {" "}
                        {"AT"}{" "}
                      </Avatar>
                    }
                    title={"Informacion sobre tus actividades"}
                  />
                </Typography>
                <Table style={{ width: "1px solid #f5f5f5 " }}>
                  <TableBody>
                    <TableRow key={1}>
                      <TableCell component="th" scope="row">
                        {" "}
                        Asignatura
                      </TableCell>
                      <TableCell align="left"></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TituloAccion principal='' alterno='Actividad por cortes' />
              <Grid container direction={'row'} spacing={2} style={{ width: "100%" }}>
                <Grid item xs={12} sm={12} md={12} onClick={() => handleClickCortes("primer_corte_sisef")}>
                  <Paper
                    elevation={1}
                    style={{ border: "1px solid #e0e0e0" }}
                  >
                    <AccionesRapidas
                      imagen=""
                      titulo="¡ Primer Corte !"
                      sub_titulo="Bien"
                      des_sub_titulo=", Aquí puedes ver toda la información actual."
                      avatar={obtenerColor()}
                      avatar_name={"1"}
                    />
                  </Paper>
                </Grid>


                <Grid item xs={12} sm={12} md={12} onClick={() => handleClickCortes("segundo_corte_sisef", "Segundo Corte")}>
                  <Paper
                    elevation={1}
                    style={{ border: "1px solid #e0e0e0" }}
                  >
                    <AccionesRapidas
                      imagen=""
                      titulo="¡ Segundo Corte !"
                      sub_titulo="Bien"
                      des_sub_titulo=", Aquí puedes ver toda la información actual."
                      avatar={obtenerColor()}
                      avatar_name={"2"}
                    />
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={12} md={12} onClick={() => handleClickCortes("tercer_corte_sisef", "Tercer Corte")}>
                  <Paper
                    elevation={1}
                    style={{ border: "1px solid #e0e0e0" }}
                  >
                    <AccionesRapidas
                      imagen=""
                      titulo="¡ Tercer Corte !"
                      sub_titulo="Bien"
                      des_sub_titulo=", Aquí puedes ver toda la información actual."
                      avatar={obtenerColor()}
                      avatar_name={"3"}
                    />
                  </Paper>
                </Grid>


              </Grid>
            </Grid>
          </Grid>
        </div >
      </div>
    );
  }

  render() {
    let { cargando, Modal, ver_calificacion, data, cargandoData, actividad, mis_objetivos } = this.state;
    return (
      <>
        <div className="contenido_modulo">
          {
            !cargando ? (
              this.vista()
            ) : (
              <TareasTerminadas
                mensaje="Cargando.."
                marginTop="7%"
                imagen={emma_w}
                widthImg="7%"
                cargando={true}
              />
            )
          }
        </div>
        {
          Modal == true &&
          <Dialog
            fullWidth={true}
            maxWidth="md"
            open={this.state.Modal}
            onClose={() => this.setState({ Modal: false })}
            aria-labelledby="form-dialog-title"
          >
            <AppBarModal
              titulo="Detalles"
              mostrarModal={() => this.setState({ Modal: false })}
              titulo_accion="Detalle calificación"
              accion=""
            />
            <DialogContent className="scroll">
              {this.state.cargando ?
                <TareasTerminadas
                  mensaje='Cargando..'
                  marginTop='7%'
                  imagen={emma_w}
                  widthImg="7%"
                /> :
                <ListarDatos
                  id="tbl_actividades"
                  datos={this.state.actividades_data}
                  opciones={true}
                  titulo={"Actividades"}
                  acciones={(row) => this.acciones(row)}
                  filtrar={false}
                  refrescar={false}
                  actRefrescar={() => this.RefrescarActividades()}
                  actfiltrar={() => this.setState({ modal_filtro: true })}
                  buscar={false}
                  avatar={({ actividad }) => actividad.toUpperCase().charAt(0)}
                  color_avatar={() => ({ backgroundColor: "#6200ea", color: "white" })}
                  fila_principal={({ actividad }) => `${actividad}`}
                  filas={[
                    { mostrar: ({ fecha }) => `${fecha}`, id: "fecha", enLista: true },

                    {
                      mostrar: ({ asignatura_nombre }) => `${asignatura_nombre}`,
                      id: "asignatura_nombre",
                      enLista: true,
                    },
                  ]}
                />}
            </DialogContent>
          </Dialog>
        }
        {
          ver_calificacion ?
            <MostrarCalificacion
              Modal={ver_calificacion}
              cerrarModal={(resp) => this.setState({ ver_calificacion: resp })}
              SetSeleccionados={(resp) => this.setState({ seleccionados: resp })}
              estudiante={data}
              actividad={actividad}
              // seleccionados={seleccionados}
              mis_objetivos={mis_objetivos}
              cargandoData={cargandoData}
            /> : ""
        }
      </>
    );

  }
}


const mapStateToProps = (state) => {
  let { usuario } = state.redGlobal;
  return { usuario };
}

const mapDispatchToProps = {
  actualizarMensaje
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcesosSisefEstudiante);