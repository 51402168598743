import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogContent, DialogActions, FormControl, List, Paper, Stepper, Step, StepLabel, Grid, InputLabel, Select, MenuItem, TextField, Typography, Checkbox, FormControlLabel } from '@material-ui/core';
import AppBarModal from '../../general/AppBarModal';
import { BtnForm } from '../../general/BotonesAccion';
import { consulta, obtenerValores, api, obtenerValoresFiltros, obtenerPermisosValorAlt, obtenerMisDatos, generarFiltros } from "../../../global/js/funciones";
import TareasTerminadas from '../../general/TareasTerminadas';
import AlertasSimple from '../../general/AlertasSimple';
import BuscarSelect from "../../general/BuscarSelect";
import emma_w from '../../../global/imagenes/emma_w.png'; 

const configStep = ['Datos Iniciales'];

function Form(component_props) {
  let { carreras, carrera, telefono, celular, programas_pregrado, asignaturas, asignatura, habilitarelectiva , electivas, electiva, handleChangeCarrera, handleChangeAsignatura, setearEstado, onChange, check_validaciones, mensaje, tipo, tipos, onChangeProgramas, onChangeAsigntura } = component_props

  
  return (
    
    <>
      <Grid container spacing={3} style={{ marginBottom: '40px' }}>

        {/* Formulario de celular*/}
        <Grid item xs={6}>
          <TextField
            value={celular}
            required
            type="number"
            id="celular"
            name="celular"
            label="Celular"
            fullWidth
            onChange={onChange}
          />
        </Grid>

        {/* Formulario de celular */}
        <Grid item xs={6}>
          <TextField
            value={telefono}
            required
            type="number"
            id="telefono"
            name="telefono"
            label="Telefono"
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl className='form-control'>
            <InputLabel>Programa</InputLabel>
            <Select
              id='programa'
              value={carrera}
              onChange={onChangeProgramas}
              name='programa'
            >

            { 
              carreras.map(({value, label, codigo }) => (
                
                <MenuItem key={value} value={value} data-my-value={codigo} >
                  {label}
                </MenuItem>
              ))
            }
            </Select>
          </FormControl>
        </Grid>
        
        {/* Asignatura */}
        <Grid item xs={12} >
          <BuscarSelect datos={asignaturas} cambiarEstado={(value) =>onChangeAsigntura(value)} valor={asignatura} id='asignatura' nombre='Asignatura' placeholder='Seleccione Asginatura' />
        </Grid>

        {/* Electivas */}

        {
          habilitarelectiva == true && 
          
          <Grid item xs={12} >
            <BuscarSelect datos={electivas} cambiarEstado={(value) =>setearEstado({electiva: value})}  valor={electiva}  id='electiva' nombre='Electiva' placeholder='Seleccione Electiva' />
          </Grid>
        }
        
        

        {/* Observaciones */}
        <Grid item xs={12} >
          <FormControl className="form-control">
            <TextField
              id="observaciones"
              label={"Observaciones"}
              name="motivo"
              placeholder={"Escriba Observaciones"}
              multiline
              InputLabelProps={{
                shrink: true,
              }}
            onChange={({target : {value}}) => setearEstado({mensaje: value})}
            />
            </FormControl>
        </Grid>
      </Grid>
    </>
  )
}

class ValidacionesCrear extends Component {

    constructor(props) {
      super(props)
      this.state = {
        carrera: '',
        telefono: '',
        celular : '',
        asignatura: '',
        activeStep: 0,
        check_validaciones: false,
        carreras : [],
        asignaturas : [],
        cargar: true,
        acu_validaciones : [],
        titulo_carg: 'Cargando...',
        electiva: '',
        electivas: [],
        habilitarelectiva : false

      }
    }
  
    async componentDidMount() {
      this.obtenerDatos();
    }


   transformar = data => {
      return data.map(e => ({
        value: e.id,
        label: e.nombre,
        codigo: e.codigo
      }));
    }

    async obtenerDatos (){
      this.setState({ cargando: true })
      let { persona : { celular, telefono}, programas} = await obtenerMisDatos();
      let carreras = await this.transformar(programas.map(e => e.relacion));
      let acuerdo_validaciones = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Val_Acu_Est' }]);
      this.setState({celular, telefono, carreras,  cargando: false, acuerdo_validaciones : acuerdo_validaciones.length > 0 ? acuerdo_validaciones[0].descripcion : '' });
      
    }


    async obtenerAsignaturas(programa) {
      return new Promise(resolve => {
        consulta(`api/v1.0/validaciones/materias/${programa}`, null, null, (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        })
      })
    }

    async obtenerElectivas(value, prog) {
      return new Promise(resolve => {
        consulta(`api/v1.0/validaciones/materiaselectivas/${prog}/${value}`, null, null, (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        })
      })
    }


    obtenerObservaciones = async () => {
      this.setState({ cargando: true });
      let {acuerdo_validaciones} = this.state
      let obs = await obtenerPermisosValorAlt([{ 'llave': 'codigo', 'valor': 'Val_Acu_Est'}])
      let acu_validaciones = [{item : acuerdo_validaciones}]
      obs.map(({ secundario }) => acu_validaciones.push({item : secundario.nombre}));
      this.setState({ acu_validaciones });
    }

    onChangeProgramas = async (e) => {
      this.setState({ cargando: true })
      let id = e.currentTarget.dataset.value
      let codigo = e.currentTarget.dataset.myValue
      const { actualizarMensaje } = this.props;
      let { error, estado, titulo, asignaturas } = await this.obtenerAsignaturas(codigo);

      if (!error && estado === 200) {
        this.setState({
          codigo: codigo,
          carrera: id,
          asignaturas,
          asignatura: null,
        });
      } else {
        actualizarMensaje({ titulo, tipo: 'info', mostrar: true, tiempo: 6000 });
      }
      this.setState({ cargando: false });
    }

    // MATERIAS ELECTIVAS
    onChangeAsigntura = async (asignatura) => {
      let { label, value } = asignatura
      let electiva = label.includes('ELECTIVA')
      this.setState({ habilitarelectiva: electiva, electiva: '' })
      
      if (electiva == true) {
        const { actualizarMensaje } = this.props;
        let { codigo } = this.state
        this.setState({ cargando: true })
        let { error, estado, titulo, electivas } = await  this.obtenerElectivas(value, codigo)
        if (!error && estado === 200) {
          if (electivas.length != 0) this.setState({electivas})
          else this.setState({electivas: [asignatura]})
        }
        else {
          actualizarMensaje({ titulo, tipo: 'info', mostrar: true, tiempo: 6000 });
        }
        this.setState({cargando: false });

     } 
      this.setState({ asignatura: asignatura });

    }

  
    onChange = ({ target }) => {
      let { value, name } = target;
      this.setState({
        [name]: value
      })
    }
  
    checked = ({ target }) => {
      this.setState({
        [target.name]: target.checked,
        carrera: ''
      })
    }
  
    obtenerRequisitos(tipo) {
      this.setState({ cargando: true });
      consulta(`api/v1.0/validaciones/${tipo}/requisitos`, null, null, (error, estado, resp) => {
        if (estado === 200) this.setState({ requisitos: resp });
      })
    }
  
    reiniciarEstado() {
      this.setState({
        activeStep: 0, 
        cargando: false, 
        titulo_carg : 'Cargando...', 
        check_validaciones : false, 
        carrera: '',
        asignatura: '',
        observaciones: '',
        electiva: '',
        electivas: [],
        habilitarelectiva : false
      })
    }

    validarCampos = () => {
      let { asignatura, electiva, habilitarelectiva } = this.state;
      let { actualizarMensaje } = this.props;

      let titulo = '';
      if ( habilitarelectiva && !electiva ) titulo = 'Seleccione Electiva'
      else if ( !asignatura ) titulo = 'Seleccione Asignatura'
      
      if (titulo) {
        actualizarMensaje({ titulo, tipo: 'info', mostrar: true, tiempo: 6000 })
        return false;
      }
      return true;
    }

    submitValidaciones = async () => {
    let { telefono, carrera, celular, asignatura, electiva} = this.state
      let select = this.validarCampos()
      if (select == true) {
        let { guardarValidaciones } = this.props
        this.setState({ cargando: true, titulo_carg : 'Enviando...' })
        let datos = {celular: celular ? celular : '', telefono: telefono ? telefono : '' , programa: carrera, codigo_asignatura: electiva ? electiva.label : asignatura.value, asignatura: electiva ? electiva.label : asignatura.label }
        guardarValidaciones(datos, () => this.reiniciarEstado())
      }
    }

   
    configStepContent = () => {
      let { activeStep, carrera, asignatura, telefono, celular, programas_pregrado, check_validaciones, carreras, asignaturas, habilitarelectiva, electiva, electivas} = this.state  
      switch (activeStep) {
        case 0:
          return (
            <Form
              {...{
                carrera,
                telefono,
                celular,
                carreras,
                programas_pregrado,
                asignatura,
                asignaturas,
                check_validaciones,
                habilitarelectiva,
                electiva,
                electivas,
                onChangeProgramas: this.onChangeProgramas,
                onChangeAsigntura: this.onChangeAsigntura,
                onChange: this.onChange,
                setearEstado: (estado)=>this.setState(estado)
              }}
            />
          )
        case 1:
          return
        default:
          return <TareasTerminadas imagen={emma_w} mensaje='Esta bandeja esta vacía' widthImg='10%' marginTop='10%' />
      }
    }
  
    render() {
      let { modalAddValidaciones, mostrarModalAddValidaciones} = this.props
      let { activeStep, cargando, titulo_carg, acuerdo_validaciones} = this.state
      let mensaje_validaciones = [{item: acuerdo_validaciones}]
  
      return (
        <>
          <Dialog fullWidth={true} maxWidth="sm" open={modalAddValidaciones} onClose={() => mostrarModalAddValidaciones(false)}>
            <AppBarModal titulo={"¡ Nueva Solicitud !"} mostrarModal={() => mostrarModalAddValidaciones(false)} titulo_accion="Cerrar" accion={() => mostrarModalAddValidaciones(false)}/>
            <DialogContent className='scroll'>
              {
                !cargando ?
                  <Grid container>
                  <Grid item xs={12} md={12}>
                  <Stepper activeStep={activeStep}>
                      {
                        configStep.map(label => (
                          <Step key={label}>
                            <StepLabel><p>{label}</p></StepLabel>
                          </Step>
                        ))
                      }
                    </Stepper>
                  </Grid>
                  <Grid item xs={12}>
                    {this.configStepContent()}
                    <AlertasSimple
                    tipo='info'
                    titulo={'Estimado Estudiante, tener en cuenta: '}
                    lista={mensaje_validaciones}
                    margin = '0px'
                    />
                  </Grid>
                  </Grid> :
                  <TareasTerminadas mensaje={titulo_carg} marginTop='7%' imagen={emma_w} widthImg="7%" />
              }
      
            </DialogContent>
            <DialogActions>
              <div>
              { 
                !cargando &&
                <BtnForm texto="GUARDAR" callback={this.submitValidaciones} type="submit" />
              }
              </div>
            </DialogActions>
  
          </Dialog >
        </>
      )
    }
  }
  
  ValidacionesCrear.propTypes = {
    modalAddValidaciones: PropTypes.bool.isRequired,
    actualizarMensaje: PropTypes.func.isRequired,
  }
  
export default ValidacionesCrear