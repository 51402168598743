import React, { Component } from 'react';
import { obtenerValoresFiltros, MenuProps, obtenerMisDatos, consulta, obtenerPermisosValorAlt } from "../../../global/js/funciones";
import { Typography, Button, FormHelperText, Checkbox, ListItemText, Select, FormControl, MenuItem, InputLabel, TextField, Dialog, DialogContent, DialogActions, Grid, FormControlLabel } from '@material-ui/core';
import { BtnForm } from "../../general/BotonesAccion";
import AppBarModal from "../../general/AppBarModal";
import TareasTerminadas from "../../general/TareasTerminadas";
import CustomDropzone from "../../general/CustomDropzone";
import AlertasSimple from "../../general/AlertasSimple";
import BuscarSelect from "../../general/BuscarSelect";
import emma_w from '../../../global/imagenes/emma_w.png';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import { ENTIDADES_FINANCIERAS, PROCESOS_CREDITO, PAGOS_LEGALIZACION, PECUNIARIOS_LEGALIZACION, OBSERVACIONES_FINANCIEROS, SOLICITUDES_DESCONGELAMIENTO, ORIGEN_DINERO_DESCONGELAMIENTO, MODALIDADES_CONGELAMIENTO, REQUISITOS_FINANCIEROS, TIPOS_DEVOLUCION, ORIGEN_DINEROS_DEVOLUCION, CODIGO_ICETEX_DEVOLUCION, CODIGO_ENTIDAD_FINANCIERA_DEVOLUCION, CODIGO_ABONO_DEVOLUCION } from "./../helper";
import { actualizarMensaje } from '../../../redux/actions/actGlobal';
import { coloresEmma } from '../../../global/js/funciones';

class CreditoFormAgregar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      promedios: false,
      promedio_acumulado: 0,
      promedio_nivel: 0,
      cargando: true,
      entiEspecial: false,
      preguntas: true,
      modalAdjuntos: false,
      mensaje: { 'valor': '' },
      mensaje_info: false,
      titulo_res: 'Cargando..',
      programas: [],
      entidades: [],
      archivos: [],
      tipos_solicitud: [],
      telefono: { 'valor': '' },
      celular: { 'valor': '' },
      programa: { 'valor': '' },
      solicitud_reliazada: { 'valor': '' },
      requiere_asesoria: { 'valor': '' },
      entidad: { 'valor': '' },
      tipos_seleccionados: { 'valor': [] },
      creditos_matricula: { 'valor': '' },
      primer_semestre: false,
      pago: { 'valor': '' },
      pagos: [],
      subtipo_pago: { 'valor': '' },
      subtipos_pagos: { 'matriculas': [], 'pecuniarios': [] },
      observaciones_leg: [],
      observaciones_solicitud: [],
      docs: [],
      formatos_archivos: '',
      tipos_solicitud_desc: [],
      solicitud_desc: { 'valor': '' },
      origenes_dineros_desc: [],
      origen_dinero_desc: { 'valor': '' },
      asignatura_desc: { 'valor': '' },
      modalidades_cong: [],
      modalidad_cong: { 'valor': '' },
      modalMensaje: false,
      mensajes_extra: [],
      tipo_solicitud_dev: [],
      solicitud_dev: { 'valor': '' },
      codigo_icetex_dev: { 'valor': '' },
      origen_dinero_devolucion: [],
      origen_dinero_dev: { 'valor': '' },
      nombre_titular_dev: { 'valor': '' },
      numero_documento_dev: { 'valor': '' },
      entidades_devoluciones: [],
      entidades_dev: { valor: '' },
      motivo: 0,
      motivos: [],
    }
  }

  async componentDidUpdate({ modalAddCredito }, { solicitud_reliazada, requiere_asesoria, programa, solicitud_desc, modalidad_cong, solicitud_dev }) {
    let { modalAddCredito: modalAddCreditoNew, categoria, tipo, tipoSolicitud } = this.props;
    let { solicitud_reliazada: solicitud_reliazadaNew, requiere_asesoria: requiere_asesoriaNew, programa: programaNew, solicitud_desc: solicitud_descNew, modalidad_cong: modalidad_congNew } = this.state;
    
    if (solicitud_reliazadaNew.valor != solicitud_reliazada.valor) {
      if (solicitud_reliazadaNew.valor == '1') this.setState({ preguntas: false, mensaje_info: false, });
      else this.setState({ preguntas: true, mensaje_info: false, });
    }
    if (requiere_asesoriaNew.valor != requiere_asesoria.valor) {
      if (requiere_asesoriaNew.valor == '2') this.setState({ preguntas: true, mensaje_info: true })
      else this.setState({ preguntas: false, mensaje_info: false, })
    }
    if (programaNew.valor !== programa.valor && modalAddCreditoNew) {
      this.setState({ cargando: true })
      let promedios = tipoSolicitud.codigo === 'sol_cred' ? await this.validarPromedios(programaNew.valor) : false;
      if (promedios) this.setState({ promedio_acumulado: promedios.promedio_acumulado, promedio_nivel: promedios.promedio_nivel, promedios: true })
      let entiEspecial = true;
      let entidades = []
      if (entidades.length === 0) {
        entiEspecial = false;
        entidades = tipoSolicitud.codigo === 'sol_cred' ? await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': ENTIDADES_FINANCIERAS }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valora', 'valor': tipo }]) : [];
      }
      this.setState({ entidades, entidad: { 'valor': entidades.length === 1 ? entidades[0].id : '', 'error': '' }, entiEspecial, cargando: false });
    }

    if (tipoSolicitud.codigo === 'sol_cong') {
      if ((solicitud_descNew.valor !== solicitud_desc.valor) && solicitud_descNew.valor.valora === 'inp_asig') {
        this.setState({ cargando: true })
        let mensajes = await obtenerPermisosValorAlt([{ 'llave': 'principal', 'valor': solicitud_descNew.valor.value }, { 'llave': 'secundario__generica', 'valor': OBSERVACIONES_FINANCIEROS }]);
        this.setState({ modalMensaje: true, mensajes_extra: mensajes.map(({ secundario }) => secundario.nombre), cargando: false })

      } else if (solicitud_descNew.valor && solicitud_descNew.valor.valora === 'inp_mod' && (modalidad_congNew.valor !== modalidad_cong.valor)) {
        this.setState({ cargando: true })
        let mensajes = await obtenerPermisosValorAlt([{ 'llave': 'principal', 'valor': modalidad_congNew.valor }, { 'llave': 'secundario__generica', 'valor': OBSERVACIONES_FINANCIEROS }]);
        this.setState({ modalMensaje: true, mensajes_extra: mensajes.map(({ secundario }) => secundario.nombre), cargando: false })

      }
    }

    if (modalAddCreditoNew !== modalAddCredito) {

      if (modalAddCreditoNew) {
        let formatos_archivos = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'For_Arc_Cre' }]);
        let datos_persona = await obtenerMisDatos();
        let tipos_solicitud_gen = tipoSolicitud.codigo === 'sol_cred' ? await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': PROCESOS_CREDITO }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valora', 'valor': tipo }]) : [];
        let tipos_solicitud_mix = tipoSolicitud.codigo === 'sol_cred' ? await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': PROCESOS_CREDITO }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valora', 'valor': 'mixta' }]) : [];
        let tipos_solicitud = tipos_solicitud_mix.concat(tipos_solicitud_gen);
        let pagos = tipoSolicitud.codigo === 'sol_lega' ? await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': PAGOS_LEGALIZACION }, { 'llave': 'estado', 'valor': 1 }]) : [];
        let tip_matr = tipoSolicitud.codigo === 'sol_lega' ? await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': PROCESOS_CREDITO }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valorb', 'valor': 'ambos' }]) : [];
        let tip_pecu = tipoSolicitud.codigo === 'sol_lega' ? await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': PECUNIARIOS_LEGALIZACION }, { 'llave': 'estado', 'valor': 1 }]) : [];
        let observaciones_leg = tipoSolicitud.codigo === 'sol_lega' ? await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Obs_Leg' }, { 'llave': 'estado', 'valor': 1 }]) : [];
        // let modelo_doc = (tipoSolicitud.codigo === 'sol_cred' && tipo === 'icetex' && categoria === 'renovacion' ) ? await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': REQUISITOS_FINANCIEROS }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valora', 'valor': 'renovacion' }]) : [];
        let doc_vp = await obtenerPermisosValorAlt([{ 'llave': 'principal', 'valor': tipoSolicitud.id }, { 'llave': 'secundario__generica', 'valor': REQUISITOS_FINANCIEROS }])
        // let modelo_doc = (tipoSolicitud.codigo === 'sol_cred' && tipo === 'icetex' && categoria === 'renovacion' ) ? await obtenerPermisosValorAlt([{ 'llave': 'principal', 'valor': tipoSolicitud.id }, { 'llave': 'secundario__generica', 'valor': REQUISITOS_FINANCIEROS }, { 'llave': 'secundario__valora', 'valor': 'renovacion' }]) : [];
        let tipos_solicitud_desc = (tipoSolicitud.codigo === 'sol_desc' || tipoSolicitud.codigo === 'sol_cong') ? await obtenerPermisosValorAlt([{ 'llave': 'principal', 'valor': tipoSolicitud.id }, { 'llave': 'secundario__generica', 'valor': SOLICITUDES_DESCONGELAMIENTO }]) : [];
        let modalidades_cong = (tipoSolicitud.codigo === 'sol_cong') ? await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': MODALIDADES_CONGELAMIENTO }, { 'llave': 'estado', 'valor': 1 }]) : [];
        let origenes_dineros_desc = (tipoSolicitud.codigo === 'sol_desc' || tipoSolicitud.codigo === 'sol_cong') ? await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': ORIGEN_DINERO_DESCONGELAMIENTO }, { 'llave': 'estado', 'valor': 1 }]) : [];
        let observaciones_solicitud = (tipoSolicitud.codigo !== 'sol_cred' || (tipoSolicitud.codigo === 'sol_cred' && tipo === 'icetex' && categoria === 'renovacion')) ? await obtenerPermisosValorAlt([{ 'llave': 'principal', 'valor': tipoSolicitud.id }, { 'llave': 'secundario__generica', 'valor': OBSERVACIONES_FINANCIEROS }]) : []
        let tipo_solicitud_dev = (tipoSolicitud.codigo === 'sol_dev') ? await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_DEVOLUCION }, { 'llave': 'estado', 'valor': 1 }]) : [];
        let origen_dinero_devolucion = (tipoSolicitud.codigo === 'sol_dev') ? await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': ORIGEN_DINEROS_DEVOLUCION }, { 'llave': 'estado', 'valor': 1 }]) : [];
        let entidades_devoluciones = (tipoSolicitud.codigo === 'sol_dev') ? await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': ENTIDADES_FINANCIERAS }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valorb', 'valor': 'devoluciones' }]) : [];


        this.setState({
          tipos_solicitud,
          programas: datos_persona.programas,
          telefono: { 'valor': datos_persona.persona.telefono ? datos_persona.persona.telefono : '', 'error': '' },
          celular: { 'valor': datos_persona.persona.celular ? datos_persona.persona.celular : '', 'error': '' },
          programa: { 'valor': datos_persona.programas.length === 1 ? datos_persona.programas[0].relacion.id : '', 'error': '' },
          cargando: false,
          preguntas: categoria === 'renovacion' && tipo === 'icetex',
          pagos: this.transformar(pagos),
          subtipos_pagos: { 'matriculas': tip_matr, 'pecuniarios': tip_pecu },
          observaciones_leg: this.configObs(observaciones_leg),
          observaciones_solicitud: this.configAlert(observaciones_solicitud),
          docs: this.configDocs(doc_vp),
          formatos_archivos: formatos_archivos ? formatos_archivos[0].valora : '',
          tipos_solicitud_desc: this.transformar2(tipos_solicitud_desc.map(({ secundario }) => secundario)),
          tipo_solicitud_dev: this.transformar_devolucion(tipo_solicitud_dev),
          origen_dinero_devolucion: this.transformar_origen_dinero(origen_dinero_devolucion),
          entidades_devoluciones: this.transformar_entidades(entidades_devoluciones),
          origenes_dineros_desc,
          modalidades_cong
        })
      } else {
        this.reiniciarEstado();
      }
    }
  }

  conError(valor) {
    return valor ? true : false;
  }

  validarPromedios(programa) {
    return new Promise(resolve => {
      consulta(`api/v1.0/creditos/estudiante/promedios`, { programa }, 'post', (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  onSubmit = (e) => {
    let { categoria, tipo, tipoSolicitud } = this.props;
    let { telefono, celular, programa, entidad, tipos_seleccionados, solicitud_reliazada, requiere_asesoria, creditos_matricula, pago, subtipo_pago, solicitud_desc, origen_dinero_desc, asignatura_desc, modalidad_cong, codigo_icetex_dev, origen_dinero_dev, nombre_titular_dev, numero_documento_dev,  entidades_dev, solicitud_dev} = this.state;
    let obligatorio = 'Este campo es obligatorio';
    let numero = 'Este campo debe ser un numero';
    let creditos = 'Ingrese una numero entre 6 y 21';
    let error = false;
    if (!telefono.valor) { telefono.error = obligatorio; error = true; }
    else if (!Number(telefono.valor)) { telefono.error = numero; error = true; }
    if (!celular.valor) { celular.error = obligatorio; error = true; }
    else if (!Number(celular.valor)) { celular.error = numero; error = true; }
    if (!programa.valor) { programa.error = obligatorio; error = true; }

    if (!entidad.valor && tipoSolicitud.codigo === 'sol_cred') { entidad.error = obligatorio; error = true; }
    if (tipos_seleccionados.valor.length === 0 && tipoSolicitud.codigo === 'sol_cred') { tipos_seleccionados.error = obligatorio; error = true; }
    if (categoria === 'renovacion' && tipo === 'icetex' && tipoSolicitud.codigo === 'sol_cred') {
      if (!creditos_matricula.valor) { creditos_matricula.error = obligatorio; error = true; }
      else if (!Number(creditos_matricula.valor)) { creditos_matricula.error = numero; error = true; }
      else if (creditos_matricula.valor < 6 || creditos_matricula.valor > 21) { creditos_matricula.error = creditos; error = true; }
      if (!solicitud_reliazada.valor) { solicitud_reliazada.error = obligatorio; error = true; }
      if (solicitud_reliazada.valor === 2 && !requiere_asesoria.valor) { requiere_asesoria.error = obligatorio; error = true; }
    }

    if ( tipoSolicitud.codigo === 'sol_dev') {
      if (solicitud_dev.valor === '') { solicitud_dev.error = obligatorio; error = true; }
      if (solicitud_dev.valor.value === CODIGO_ICETEX_DEVOLUCION) {
        if (codigo_icetex_dev.valor === '') { codigo_icetex_dev.error = obligatorio; error = true; }
      }
      if (origen_dinero_dev.valor === '') { origen_dinero_dev.error = obligatorio; error = true; }
      if (origen_dinero_dev.valor.value === CODIGO_ENTIDAD_FINANCIERA_DEVOLUCION) {
        if (entidades_dev.valor == '') { entidades_dev.error = obligatorio; error = true; }
        if (nombre_titular_dev.valor === '') { nombre_titular_dev.error = obligatorio; error = true; }
        if (numero_documento_dev.valor === '') { numero_documento_dev.error = obligatorio; error = true; }
      }
    }
    if (!pago.valor && tipoSolicitud.codigo === 'sol_lega') { pago.error = obligatorio; error = true; }
    if ((pago.valor && (pago.valor.codigo !== 'pag_car' && pago.valor.codigo !== 'pag_mul')) && !subtipo_pago.valor && tipoSolicitud.codigo === 'sol_lega') { subtipo_pago.error = obligatorio; error = true; }
    if (!solicitud_desc.valor && (tipoSolicitud.codigo === 'sol_desc' || tipoSolicitud.codigo === 'sol_cong')) { solicitud_desc.error = obligatorio; error = true; }
    if (!origen_dinero_desc.valor && (tipoSolicitud.codigo === 'sol_desc' || tipoSolicitud.codigo === 'sol_cong')) { origen_dinero_desc.error = obligatorio; error = true; }
    if (!asignatura_desc.valor && solicitud_desc.valor.valora === 'inp_asig' && (tipoSolicitud.codigo === 'sol_desc' || tipoSolicitud.codigo === 'sol_cong')) { asignatura_desc.error = obligatorio; error = true; }
    if (!modalidad_cong.valor && solicitud_desc.valor.valora === 'inp_mod' && tipoSolicitud.codigo === 'sol_cong') { modalidad_cong.error = obligatorio; error = true; }

    if (!error) this.enviarDatos();
    else this.setState({ telefono, celular, programa, entidad, tipos_seleccionados, solicitud_reliazada, requiere_asesoria, creditos_matricula, pago, subtipo_pago, solicitud_desc, origen_dinero_desc, asignatura_desc })
  }

  enviarDatos() {
    let { promedio_acumulado, promedio_nivel, telefono, celular, programa, entidad, tipos_seleccionados, solicitud_reliazada, requiere_asesoria, creditos_matricula, mensaje, archivos, primer_semestre, pago, subtipo_pago, solicitud_desc, origen_dinero_desc, asignatura_desc, modalidad_cong, solicitud_dev, entidades_dev, codigo_icetex_dev, origen_dinero_dev, nombre_titular_dev, numero_documento_dev } = this.state;
    let { categoria, tipo, tipoSolicitud, actualizarMensaje } = this.props;
    this.setState({ cargando: true, titulo_res: 'Enviando..' });

    if (tipoSolicitud.codigo === 'sol_dev' &&  solicitud_dev.valor.value !== CODIGO_ABONO_DEVOLUCION &&  archivos.length < 2) {
      actualizarMensaje({ titulo: `Debes cargar los archivos necesarios.`, tipo: 'info', mostrar: true, tiempo: 6000 })
      this.setState({ cargando: false, titulo_res: 'Enviando..' });
      return false;
    } else if (tipoSolicitud.codigo === 'sol_dev' &&  solicitud_dev.valor.value === CODIGO_ABONO_DEVOLUCION &&  archivos.length < 3) { 
      actualizarMensaje({ titulo: `Debes cargar los archivos necesarios. abono`, tipo: 'info', mostrar: true, tiempo: 6000 })
      this.setState({ cargando: false, titulo_res: 'Enviando..' });
      return false;
    }
    
    if (!this.state.mostrar){
      
      let data = {
        promedio_acumulado: tipoSolicitud.codigo === 'sol_cred' ? promedio_acumulado : '',
        promedio_nivel: tipoSolicitud.codigo === 'sol_cred' ? promedio_nivel : '',
        archivos,
        telefono: telefono.valor,
        celular: celular.valor,
        programa: programa.valor,
        entidad: entidad.valor,
        solicitudes: tipos_seleccionados.valor,
        solicitud_reliazada: categoria === 'renovacion' && tipo === 'icetex' ? solicitud_reliazada.valor : -1,
        requiere_asesoria: categoria === 'renovacion' && tipo === 'icetex' && solicitud_reliazada.valor === 2 ? requiere_asesoria.valor : -1,
        creditos_matricula: categoria === 'renovacion' && tipo === 'icetex' ? creditos_matricula.valor : 0,
        mensaje: mensaje.valor ? mensaje.valor : '',
        categoria: tipoSolicitud.codigo === 'sol_cred' ? categoria === 'nuevo' ? 1 : 2 : -1,
        primer_semestre: tipoSolicitud.codigo === 'sol_cred' ? primer_semestre ? 'si' : 'no' : -1,
        tipo_pago: tipoSolicitud.codigo === 'sol_lega' ? pago.valor.value : pago.valor,
        subtipo_pago: subtipo_pago.valor,
        solicitud_descongela: (tipoSolicitud.codigo === 'sol_desc' || tipoSolicitud.codigo === 'sol_cong') ? solicitud_desc.valor.value : solicitud_desc.valor,
        origen_descongela: origen_dinero_desc.valor,
        asignatura_descongela: asignatura_desc.valor,
        modalidad_congela: modalidad_cong.valor,
        solicitud_dev: (tipoSolicitud.codigo === 'sol_dev') ? solicitud_dev.valor.value: solicitud_dev.valor,
        codigo_icetex_dev: codigo_icetex_dev.valor,
        origen_dinero_dev: (tipoSolicitud.codigo === 'sol_dev') ? origen_dinero_dev.valor.value : origen_dinero_dev.valor,
        entidades_dev: !entidades_dev.valor.value ? '' : entidades_dev.valor.value,
        nombre_titular_dev: nombre_titular_dev.valor,
        numero_documento_dev: numero_documento_dev.valor
      }
      this.props.guardarCredito(data, () => this.setState({ cargando: false, titulo_res: 'Cargando..' }));
      
    }
  }

  reiniciarEstado() {
    this.setState(
      {
        cargando: true,
        entiEspecial: false,
        preguntas: true,
        mensaje: { 'valor': '' },
        mensaje_info: false,
        titulo_res: 'Cargando..',
        programas: [],
        entidades: [],
        tipos_solicitud: [],
        telefono: { 'valor': '' },
        celular: { 'valor': '' },
        programa: { 'valor': '' },
        solicitud_reliazada: { 'valor': '' },
        requiere_asesoria: { 'valor': '' },
        entidad: { 'valor': '' },
        tipos_seleccionados: { 'valor': [] },
        creditos_matricula: { 'valor': '' },
        primer_semestre: false,
        pago: { 'valor': '' },
        pagos: [],
        subtipo_pago: { 'valor': '' },
        subtipos_pagos: { 'matriculas': [], 'pecuniarios': [] },
        observaciones_leg: [],
        observaciones_solicitud: [],
        docs: [],
        formatos_archivos: '',
        tipos_solicitud_desc: [],
        solicitud_desc: { 'valor': '' },
        origenes_dineros_desc: [],
        origen_dinero_desc: { 'valor': '' },
        asignatura_desc: { 'valor': '' },
        modalidad_cong: { 'valor': '' },
        modalMensaje: false,
        mensajes_extra: [],
        tipo_solicitud_dev: [],
        codigo_icetex_dev: { 'valor': '' },
        origen_dinero_devolucion: [],
        origen_dinero_dev: { 'valor': '' },
        nombre_titular_dev: { 'valor': '' },
        numero_documento_dev: { 'valor': '' },
      }
    )
  }


  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: { valor: value }
    })
  }

  checked = ({ target }) => {
    this.setState({
      [target.name]: target.checked
    })
  }

  transformar = data => {
    return data.map(e => ({
      value: e.id,
      label: e.nombre,
      codigo: e.codigo,
    }));
  }

  transformar2 = data => {
    return data.map(e => ({
      value: e.id,
      label: e.nombre,
      valora: e.valora
    }));
  }

  transformar_devolucion = data => {
    return data.map(e => ({
      value: e.id,
      label: e.nombre,
      valora: e.valora,
    }));
  }
  transformar_origen_dinero = data => {
    return data.map(e => ({
      value: e.id,
      label: e.nombre,
      valora: e.valora,
    }));
  }

  transformar_entidades = data => {
    return data.map(e => ({
      value: e.id,
      label: e.nombre,
      valorb: e.valorb,
    }))
  }

  configObs = obs => {
    let observaciones = []
    if (obs && obs[0]) {
      let valores = [obs[0].valora, obs[0].valorb, obs[0].valorc, obs[0].valord, obs[0].valore]
      valores.map((valor) => valor && observaciones.push({ item: valor }))
    }
    return observaciones
  }

  configAlert = list => {
    let observaciones = []
    if (list && list[0]) {
      list.map(({ secundario }) => observaciones.push({ descripcion: secundario.nombre, link: secundario.valorb }))
    }
    return observaciones
  }

  configDocs = requisitos_vps => {
    let { tipoSolicitud, tipo, categoria } = this.props
    let documentos_alert = []

    if (tipoSolicitud && (tipoSolicitud.codigo === 'sol_cong' || tipoSolicitud.codigo === 'sol_dev' || (tipoSolicitud.codigo === 'sol_cred' && tipo === 'icetex' && categoria === 'renovacion'))) {
      if (requisitos_vps.length !== 0) requisitos_vps.map(({ secundario }) => documentos_alert.push({ descripcion: secundario.nombre, 'link': secundario.archivo }))
      if (tipoSolicitud && tipoSolicitud.codigo !== 'sol_cong' && tipoSolicitud.codigo !== 'sol_dev') documentos_alert.push({ descripcion: 'Volante Matricula', link: '' })
    } else {
      documentos_alert.push({ descripcion: 'Volante Matricula', link: '' })
    }
    return documentos_alert
  }

  pintarTiposSeleccionado = (selected) => {
    let resp = [];
    let { tipos_solicitud } = this.state;
    selected.map((element) => {
      tipos_solicitud.map((e) => {
        if (element === e.id) return resp.push(e.nombre)
        return null;
      })
      return null;
    });
    return resp.join(", ")
  }

  observaciones(lista, mensaje, mensajelink) {
    return (
      <Grid container spacing={3} >
        {(lista && lista[0]) &&
          <Grid item xs={12} >
            <Grid item xs={12} md={12} className={`alert_info_titulo`}>
              <Button variant="text" size="small" color="inherit" >
                <NotificationImportantIcon style={{ height: 15 }} /> {mensaje}: </Button>
            </Grid>
            <Grid item xs={12} md={12} className={`alert_info`}>
              <ul style={{ fontSize: '13px' }}>
                {lista.map((dato, i) => (
                  <li key={i}>{dato.descripcion}
                    {dato.link && <a href={dato.link} target='_black' className='sin_decorador' style={{ color: coloresEmma.primarycolor }}> {mensajelink}</a>}
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid>
        }
      </Grid>
    )
  }

  preguntas() {
    let { requiere_asesoria, solicitud_reliazada, mensaje_info, primer_semestre } = this.state;
    let { categoria, tipo } = this.props;
    return (
      <Grid container spacing={3} style={{ marginTop: '15px' }}>
        {categoria === 'nuevo' &&
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={primer_semestre}
                  onChange={this.checked}
                  id="primer_semestre"
                  name="primer_semestre"
                  color="primary"
                />
              }
              label="¿Vas a cursar primer semestre en Unicosta?"
            />
          </Grid>
        }
        {
          (categoria === 'renovacion' && tipo === 'icetex') &&
          <Grid item xs={12}>
            <FormControl className='form-control' error={this.conError(solicitud_reliazada.error)}>
              <InputLabel id="select_solicitud_reliazada">¿ Realizo la actualización de datos ante icetex ?</InputLabel>
              <Select labelid="select_solicitud_reliazada" value={solicitud_reliazada.valor} onChange={this.onChange} name='solicitud_reliazada'>
                <MenuItem value={1}>SI</MenuItem>
                <MenuItem value={2}>NO</MenuItem>
              </Select>
              {solicitud_reliazada.error && <FormHelperText>{solicitud_reliazada.error}</FormHelperText>}
            </FormControl>
          </Grid>
        }
        {solicitud_reliazada.valor === 2 &&
          <Grid item xs={12}>
            <FormControl className='form-control' error={this.conError(requiere_asesoria.error)}>
              <InputLabel id="select_requiere_asesoria">¿ Requiere asesoría para la renovación de crédito ?</InputLabel>
              <Select labelid="select_requiere_asesoria" value={requiere_asesoria.valor} onChange={this.onChange} name='requiere_asesoria'>
                <MenuItem value={1}>SI</MenuItem>
                <MenuItem value={2}>NO</MenuItem>
              </Select>
            </FormControl>
            {requiere_asesoria.error && <FormHelperText>{requiere_asesoria.error}</FormHelperText>}
          </Grid>
        }
        {mensaje_info &&
          <Grid item xs={12} >
            <Grid item xs={12} md={12} className={`alert_info_titulo`}>
              <Button variant="text" size="small" color="inherit" >
                <NotificationImportantIcon style={{ height: 15 }} /> Nota</Button>
            </Grid>
            <Grid item xs={12} md={12} className={`alert_info`}>
              <Typography variant="body2" component="p">Para continuar debes realizar la renovación de crédito ante icetex, si tienes algún inconveniente puedes solicitar tu asesoría por este medio.</Typography>
            </Grid>
          </Grid>
        }
      </Grid>
    )
  }

  form() {
    let { categoria, tipo, tipoSolicitud } = this.props;
    let { telefono, creditos_matricula, celular, programa, programas, mensaje, entidad, entidades, tipos_solicitud, tipos_seleccionados, entiEspecial, pago, pagos, subtipo_pago, subtipos_pagos, tipos_solicitud_desc, tipo_solicitud_dev, solicitud_dev, codigo_icetex_dev, origen_dinero_dev, origen_dinero_devolucion, solicitud_desc, origenes_dineros_desc, origen_dinero_desc, entidades_devoluciones, entidades_dev, nombre_titular_dev, numero_documento_dev, asignatura_desc, modalidades_cong, modalidad_cong } = this.state;
    let subtipos = pago.valor ? pago.valor.codigo === 'pag_mat' ? subtipos_pagos.matriculas : subtipos_pagos.pecuniarios : [];
    return (
      <Grid container spacing={3} style={{ marginTop: '15px' }}>
        <Grid item xs={12}>
          <FormControl className='form-control' error={this.conError(programa.error)}>
            <InputLabel id="demo-simple-select-label">Seleccione Programa</InputLabel>
            <Select id="programa" value={programa.valor} onChange={this.onChange} name='programa'>
              {
                programas.map(({ relacion }) => {
                  return <MenuItem key={relacion.id} value={relacion.id}>{relacion.nombre}</MenuItem>
                })
              }
            </Select>
            {programa.error && <FormHelperText>{programa.error}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            value={telefono.valor}
            type="number"
            id="telefono"
            name="telefono"
            label="¿ Cual es tu numero fijo ?"
            inputProps={{ min: "0", step: "1" }}
            fullWidth
            autoComplete="lname"
            onChange={this.onChange}
            error={this.conError(telefono.error)}
            helperText={telefono.error}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            value={celular.valor}
            type="number"
            id="celular"
            name="celular"
            label="¿ Cual es tu numero celular ?"
            inputProps={{ min: "0", step: "1" }}
            fullWidth
            autoComplete="lname"
            onChange={this.onChange}
            error={this.conError(celular.error)}
            helperText={celular.error}
          />
        </Grid>
        {/* submodulo de creditos */}
        {tipoSolicitud.codigo === 'sol_cred' &&
          <Grid item xs={12}>
            <FormControl className='form-control' error={this.conError(entidad.error)}>
              <InputLabel id="select_entidad_financiera">Seleccione Entidad Financiera</InputLabel>
              <Select labelid="select_entidad_financiera" value={entidad.valor} onChange={this.onChange} name='entidad'>
                {
                  entidades.length === 0 &&
                  <MenuItem value="">
                    <em>Para mostrar debes seleccionar tu programa</em>
                  </MenuItem>
                }

                {
                  entidades.map(({ id = null, nombre = null, secundario }) => {
                    return !entiEspecial ? <MenuItem key={id} value={id}>{nombre}</MenuItem> : <MenuItem key={secundario.id} value={secundario.id}>{secundario.nombre}</MenuItem>
                  })
                }
              </Select>
              {entidad.error && <FormHelperText>{entidad.error}</FormHelperText>}
            </FormControl>
          </Grid>
        }
        {/* submodulo de creditos en renovacion*/}
        {
          categoria === 'renovacion' && tipo === 'icetex' && tipoSolicitud.codigo === 'sol_cred' &&
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
              value={creditos_matricula.valor}
              type="number"
              inputProps={{ min: "6", max: "21", step: "1" }}
              id="creditos_matricula"
              name="creditos_matricula"
              label="¿ Cuantos créditos  vas a matricular ?"
              fullWidth
              autoComplete="lname"
              onChange={this.onChange}
              error={this.conError(creditos_matricula.error)}
              helperText={creditos_matricula.error}
            />
          </Grid>
        }
        {/* submodulo de creditos */}
        {tipoSolicitud.codigo === 'sol_cred' &&
          <Grid item xs={12}>
            <FormControl className='form-control' error={this.conError(tipos_seleccionados.error)}>
              <InputLabel id="select_tipos_solcitud">Seleccione Tipo de Concepto</InputLabel>
              <Select
                multiple
                labelid='select_tipos_solcitud'
                name='tipos_seleccionados'
                value={tipos_seleccionados.valor}
                onChange={this.onChange}
                renderValue={selected => {
                  return this.pintarTiposSeleccionado(selected)
                }}
                MenuProps={MenuProps}
              >
                {
                  tipos_solicitud.map(({ nombre, id }) => (
                    <MenuItem key={id} value={id}>
                      <Checkbox checked={tipos_seleccionados.valor.indexOf(id) > -1} />
                      <ListItemText primary={nombre} />
                    </MenuItem>
                  ))
                }
              </Select>
              {tipos_seleccionados.error && <FormHelperText>{tipos_seleccionados.error}</FormHelperText>}
            </FormControl>
          </Grid>
        }
        {/* submodulo de legalizacion */}
        {tipoSolicitud.codigo === 'sol_lega' &&
          <Grid item xs={12}>
            <BuscarSelect datos={pagos} cambiarEstado={(value) => this.setState({ pago: { 'valor': value }, subtipo_pago: { 'valor': '' } })} valor={pago.valor} id='pago' nombre='Seleccione Tipo de Pago' placeholder='Seleccione Tipo de Pago' />
            {pago.error && <FormHelperText error={pago.error}>{pago.error}</FormHelperText>}
          </Grid>
        }
        {/* submodulo de legalizaion */}
        {tipoSolicitud.codigo === 'sol_lega' && pago.valor && (pago.valor.codigo === 'pag_mat' || pago.valor.codigo === 'pag_pec') &&
          <Grid item xs={12}>
            <FormControl className='form-control' error={this.conError(subtipo_pago.error)}>
              <InputLabel id="select_subtipo_pago">Seleccione Tipo de {pago.valor.label}</InputLabel>
              <Select labelid="select_subtipo_pago" value={subtipo_pago.valor} onChange={this.onChange} name='subtipo_pago'>
                {
                  subtipos.map(({ nombre, id }) => {
                    return <MenuItem key={id} value={id}>{nombre}</MenuItem>
                  })
                }
              </Select>
              {subtipo_pago.error && <FormHelperText>{subtipo_pago.error}</FormHelperText>}
            </FormControl>
          </Grid>
        }
        {/* submodulo de descongelamiento y congelamiento */}
        {
          // tipoSolicitud.codigo === 'sol_desc' &&
          //   <Grid item xs={12}>
          //       <BuscarSelect datos={tipos_procedimientos_desc} cambiarEstado={(value) => this.setState({ procedimiento_desc: { 'valor': value }, solicitud_desc: { 'valor': '' } })} valor={procedimiento_desc.valor} id='procedimiento_desc' nombre='Seleccione Tipo de Procedimiento' placeholder='Seleccione Tipo de Procedimiento' />
          //     {/* <FormControl className='form-control' error={this.conError(procedimiento_desc.error)}>
          //       <InputLabel id="select_tipo_procedimiento">Seleccione Tipo de Procedimiento</InputLabel>
          //       <Select labelid="select_tipo_procedimiento" value={procedimiento_desc.valor} onChange={this.onChange} name='procedimiento_desc'>
          //         {
          //           tipos_procedimientos_desc.map(({ id = null, nombre = null }) => {
          //             return <MenuItem key={id} value={id}>{nombre}</MenuItem>
          //           })
          //         }
          //       </Select>
          //     </FormControl> */}
          //     {procedimiento_desc.error && <FormHelperText>{procedimiento_desc.error}</FormHelperText>}
          //   </Grid>
        }
        {/* submodulo de descongelamiento y congelamiento */}
        {(tipoSolicitud.codigo === 'sol_desc' || tipoSolicitud.codigo === 'sol_cong') &&
          <Grid item xs={12}>
            <BuscarSelect datos={tipos_solicitud_desc} cambiarEstado={(value) => this.setState({ solicitud_desc: { 'valor': value }, asignatura_desc: { 'valor': '' }, modalidad_cong: { 'valor': '' } })} valor={solicitud_desc.valor} id='solicitud_desc' nombre='Seleccione Tipo de Solicitud' placeholder='Seleccione Tipo de Solicitud' />
            {solicitud_desc.error && <FormHelperText error={solicitud_desc.error}>{solicitud_desc.error}</FormHelperText>}
          </Grid>
        }
        {/* submodulo de congelamiento modalidades_cong, */}
        {(tipoSolicitud.codigo === 'sol_cong') && solicitud_desc.valor.valora == 'inp_mod' &&
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormControl className='form-control' error={this.conError(modalidad_cong.error)}>
              <InputLabel id="select_modalidad_cong">Seleccione Modalidad</InputLabel>
              <Select labelid="select_modalidad_cong" value={modalidad_cong.valor} onChange={this.onChange} name='modalidad_cong'>
                {
                  modalidades_cong.map(({ id = null, nombre = null }) => {
                    return <MenuItem key={id} value={id}>{nombre}</MenuItem>
                  })
                }
              </Select>
              {modalidad_cong.error && <FormHelperText>{modalidad_cong.error}</FormHelperText>}
            </FormControl>
          </Grid>
        }
        {/* submodulo de descongelamiento y congelamiento */}
        {(tipoSolicitud.codigo === 'sol_desc' || tipoSolicitud.codigo === 'sol_cong') &&
          <Grid item xs={12}>
            <FormControl className='form-control' error={this.conError(origen_dinero_desc.error)}>
              <InputLabel id="select_origen_dineros">Seleccione Origen de Dineros Congelados</InputLabel>
              <Select labelid="select_origen_dineros" value={origen_dinero_desc.valor} onChange={this.onChange} name='origen_dinero_desc'>
                {
                  origenes_dineros_desc.map(({ id = null, nombre = null }) => {
                    return <MenuItem key={id} value={id}>{nombre}</MenuItem>
                  })
                }
              </Select>
              {origen_dinero_desc.error && <FormHelperText>{origen_dinero_desc.error}</FormHelperText>}
            </FormControl>
          </Grid>
        }
        {/* submodulo de descongelamiento y congelamiento */}
        {(tipoSolicitud.codigo === 'sol_desc' || tipoSolicitud.codigo === 'sol_cong') && solicitud_desc.valor.valora === 'inp_asig' &&
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
              value={asignatura_desc.valor}
              type="text"
              id="asignatura_desc"
              name="asignatura_desc"
              label="¿ Nombre de la asignatura ?"
              fullWidth
              autoComplete="lname"
              error={this.conError(asignatura_desc.error)}
              helperText={asignatura_desc.error}
              onChange={this.onChange}
            />
          </Grid>
        }
        {/* Devolucion de dinero */}
        {(tipoSolicitud.codigo === 'sol_dev') &&
          <Grid item xs={12}>
            <BuscarSelect datos={tipo_solicitud_dev} cambiarEstado={(value) => this.setState({ solicitud_dev: { 'valor': value }, entidades_dev: { 'valor': '' } })} valor={solicitud_dev.valor} id='solicitud_dev' nombre='Seleccione tipo de devolución' placeholder='Seleccione devolución' />
            {solicitud_dev.error && <FormHelperText error={solicitud_dev.error}>{solicitud_dev.error}</FormHelperText>}
          </Grid>  
        }
        {/* Devolución Valor Girado por ICETEX */}
        {(tipoSolicitud.codigo === 'sol_dev') && solicitud_dev.valor.valora === 'dev_icet' &&
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
              value={codigo_icetex_dev.valor}
              type="number"
              id="codigo_icetex_dev"
              name="codigo_icetex_dev"
              label="Número de Resolución de Giro ICETEX (Sólo dígitos)"
              fullWidth
              autoComplete="lname"
              onChange={this.onChange}
              error={this.conError(codigo_icetex_dev.error)}
              helperText={codigo_icetex_dev.error}
            />
          </Grid>
        }
        {/*Origen de Dineros */}
        {(tipoSolicitud.codigo === 'sol_dev')  &&
          <Grid item xs={12}>
            <BuscarSelect datos={origen_dinero_devolucion} cambiarEstado={(value) => this.setState({ origen_dinero_dev: { 'valor': value } })} valor={origen_dinero_dev.valor} id='origen_dinero_dev' nombre='Origenes de Dineros' placeholder='Origenes de Dinero' />
            {origen_dinero_dev.error && <FormHelperText error={origen_dinero_dev.error}>{origen_dinero_dev.error}</FormHelperText>}
          </Grid>
        }
        {console.log(origen_dinero_dev)}
        {/* Entidades de Devolución */}
        {(tipoSolicitud.codigo === 'sol_dev') && origen_dinero_dev.valor.valora === 'dev_enti' &&
          <>
            <Grid item xs={12}>
              <BuscarSelect datos={entidades_devoluciones} cambiarEstado={(value) => this.setState({ entidades_dev: { 'valor': value }, entidades: { 'valor': '' } })} valor={entidades_dev.valor} id='entidades_dev' nombre='Entidades Financieras' placeholder='Entidades' />
              {entidades_dev.error && <FormHelperText error={entidades_dev.error}>{entidades_dev.error}</FormHelperText>}
            </Grid> 

            {/* nombre del titular */}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                value={nombre_titular_dev.valor}
                type="text"
                id="nombre_titular_dev"
                name="nombre_titular_dev"
                label="Nombre completo del titular de la cuenta"
                fullWidth
                autoComplete="lname"
                onChange={this.onChange}
                error={this.conError(nombre_titular_dev.error)}
                helperText={nombre_titular_dev.error}
              />
            </Grid>

            {/* documento titular */}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                value={numero_documento_dev.valor}
                type="number"
                id="numero_documento_dev"
                name="numero_documento_dev"
                label="Número Documento"
                fullWidth
                autoComplete="lname"
                onChange={this.onChange}
                error={this.conError(numero_documento_dev.error)}
                helperText={numero_documento_dev.error}
              />
            </Grid>
          </>
        }
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TextField
            value={mensaje.valor}
            type="text"
            id="mensaje"
            name="mensaje"
            label="¿ Deseas agregar un mensaje ?"
            fullWidth
            autoComplete="lname"
            onChange={this.onChange}
          />
        </Grid>
      </Grid >
    )
  }

  eliminarArchivo = (i) => {
    let { actualizarMensaje } = this.props;
    let { archivos } = this.state;
    archivos.splice(i, 1)
    this.setState({ archivos })
    actualizarMensaje({ titulo: `Archivo Retirado.`, tipo: 'success', mostrar: true, tiempo: 6000 })
  }

  agregarArchivos = (arc) => {
    let { archivos } = this.state;
    archivos = archivos.concat(arc);
    this.setState({ archivos })
  }


  render() {
    let { titulo, modalAddCredito, mostrarModalAddCredito, actualizarMensaje, tipoSolicitud, tipo, categoria } = this.props;
    let { cargando, preguntas, titulo_res, modalAdjuntos, archivos, promedio_nivel, promedio_acumulado, promedios, observaciones_leg, docs, observaciones_solicitud, formatos_archivos, modalMensaje, mensajes_extra } = this.state;
    return (
      <div>
        <Dialog fullWidth={true} maxWidth="sm" open={modalAddCredito} onClose={() => mostrarModalAddCredito(false)}>
          <AppBarModal titulo={titulo} mostrarModal={() => mostrarModalAddCredito(false)} titulo_accion='' />
          <DialogContent className='scroll'>
            <form style={{ marginTop: '10px' }}>
              {
                cargando ? <TareasTerminadas mensaje={titulo_res} marginTop='7%' imagen={emma_w} widthImg="7%" />
                  :
                  <div>
                    {this.observaciones(observaciones_solicitud, 'Tener en Cuenta', 'Clic aqui para ir al formato')}
                    {tipoSolicitud.codigo === 'sol_cred' && this.preguntas()}
                    {this.form()}
                    {/* Ocultando cargue de documentos en solicitud de credito icetex nuevo */}
                    {!(tipoSolicitud.codigo === 'sol_cred' && categoria === 'nuevo' && tipo === 'icetex') &&
                      <AlertasSimple tipo='info' titulo={`Documentos (${archivos.length})`} sub_titulo={`Clic aquí si desea cargar algun soporte o firma. ${formatos_archivos ? `(Formatos validos: ${formatos_archivos})` : ''}`} callback={() => this.setState({ modalAdjuntos: true })} />
                    }
                    {tipoSolicitud.codigo === 'sol_cred' && promedios && <AlertasSimple tipo='warnig' titulo='Promedio' sub_titulo={`Nivel : ${promedio_nivel} - Acumulado : ${promedio_acumulado}`} conCallback={false} />}
                    {tipoSolicitud.codigo === 'sol_lega' && <AlertasSimple tipo='warnig' titulo='' lista={observaciones_leg} conCallback={false} />}
                  </div>
              }
            </form>
          </DialogContent>
          <DialogActions>
            <BtnForm texto='CANCELAR' callback={() => mostrarModalAddCredito(false)} />
            {(!preguntas && !cargando) && < BtnForm texto='GUARDAR' callback={this.onSubmit} />}
          </DialogActions>
        </Dialog >

        <Dialog fullWidth={true} maxWidth="sm" open={modalAdjuntos} onClose={() => this.setState({ modalAdjuntos: false })}>
          <AppBarModal titulo='Cargar Documentos' mostrarModal={() => this.setState({ modalAdjuntos: false })} titulo_accion='' />
          <DialogContent className='scroll'>
            {this.observaciones(docs, 'Documentos a Cargar', '(Ver Modelo)')}
            <CustomDropzone archivos={archivos} addFunction={this.agregarArchivos} deleteFunction={this.eliminarArchivo} actualizarMensaje={actualizarMensaje} acceptedFiles={formatos_archivos} />
          </DialogContent>
          <DialogActions>
            <BtnForm texto='TERMINAR' callback={() => this.setState({ modalAdjuntos: false })} />
          </DialogActions>
        </Dialog >

        {tipoSolicitud.codigo === 'sol_cong' &&
          <Dialog open={modalMensaje} maxWidth="xs" fullWidth={true} onClose={() => this.setState({ modalMensaje: false })} >
            <AppBarModal titulo='¡ Mensaje !' mostrarModal={() => this.setState({ modalMensaje: false })} titulo_accion='' />
            <DialogContent className='scroll' >
              {mensajes_extra.map((men) => <Typography variant="body1" color="textSecondary" component="p" align="justify">{men}</Typography>)}
            </DialogContent>
            <DialogActions>
              <BtnForm texto="Cerrar" callback={() => this.setState({ modalMensaje: false })} />
            </DialogActions>
          </Dialog>
        }
      </div>
    )
  }
}





export default CreditoFormAgregar;
