import React, { Component } from 'react'
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { ValidatorForm } from "react-material-ui-form-validator";

import { traerServidor } from "../../global/js/funciones";

import AppBarModal from '../general/AppBarModal'

class EleccionLink extends Component {

  onSubmit = e => {
    let link = document.getElementById('link_votacion').innerHTML;
    this.props.copiarPortaPapelesLink(link, 'alert-dialog-description');
    e.preventDefault();
  }

  render() {
    let { modalLinkEleccion, mostrarModalLinkEleccion, eleccion } = this.props;

    return (
      <Dialog open={modalLinkEleccion} onClose={mostrarModalLinkEleccion.bind(this, false)} aria-labelledby="form-dialog-title">
        <ValidatorForm onSubmit={this.onSubmit}>
          <AppBarModal titulo={`Elecciones ${eleccion.nombre}`} mostrarModal={mostrarModalLinkEleccion} titulo_accion="" accion="" />
          <DialogContent className='scroll'>
            <DialogContentText id="alert-dialog-description">
              El link para el proceso de votación es: <span id='link_votacion' className='bold'>{`${traerServidor()}/elecciones/${eleccion.id}/votar`}</span> presione la opción de copiar para guardar el link en el portapapeles.
           </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={mostrarModalLinkEleccion.bind(this, false)}> Cancelar </Button>
            <Button color="primary" type="submit"> Copiar </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    )
  }
}

//Props del componente
EleccionLink.propTypes = {
  //variables
  modalLinkEleccion: PropTypes.bool.isRequired,
  eleccion: PropTypes.shape({
    id: PropTypes.number.isRequired,
    nombre: PropTypes.string.isRequired,
    fecha_inicio: PropTypes.string.isRequired,
    fecha_fin: PropTypes.string.isRequired,
  }),
  //funciones
  mostrarModalLinkEleccion: PropTypes.func.isRequired,
  copiarPortaPapelesLink: PropTypes.func.isRequired,
}


export default EleccionLink;

