import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardHeader, CardContent, CardActions, Avatar, Grid, Typography, } from '@material-ui/core/';
import asignatura1 from '../../../global/imagenes/asignatura1.png';
import asignatura2 from '../../../global/imagenes/asignatura2.png';
import asignatura3 from '../../../global/imagenes/asignatura3.png';
import asignatura4 from '../../../global/imagenes/asignatura4.png';
import asignatura5 from '../../../global/imagenes/asignatura5.png';
import asignatura6 from '../../../global/imagenes/asignatura6.png';
import asignatura7 from '../../../global/imagenes/asignatura7.png';
import asignatura8 from '../../../global/imagenes/asignatura8.png';
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: "100%",
    minHeight: "50px",
    height: "300px",
  },
  image: {
    width: '150px',
    maxWidth: '150px',
    minWidth: '150px',
    height: "150px",
  },
  content_img: {
    textAlign: 'center',
    height: "150px",
  },

}));

export default function AsistenciaAsignaturas({ titulo, horario, grupo, categoria }) {
  let codigo = Math.floor(Math.random() * (7 - 0 + 1)) + 0;
  const classes = useStyles();

  const obtenerColor = (codigo) => {
    let color = 'white';
    const colores = [
      { 'backgroundColor': '#311b92', color },
      { 'backgroundColor': '#ffc107', color },
      { 'backgroundColor': '#0d47a1', color },
      { 'backgroundColor': '#e65100', color },
      { 'backgroundColor': '#b71c1c', color },
      { 'backgroundColor': '#009688', color },
      { 'backgroundColor': '#bf360c', color },
      { 'backgroundColor': '#3e2723', color },
    ]
    return (colores[codigo])
  }

  const obtenerImagen = (codigo) => {
    const imagenes = [asignatura1, asignatura2, asignatura3, asignatura4, asignatura5, asignatura6, asignatura7, asignatura8]
    return (imagenes[codigo])
  }


  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={<Avatar aria-label="recipe" style={obtenerColor(codigo)}> {grupo} </Avatar>}
        title={titulo}
        subheader={horario}
      />
      <CardContent>
        <Grid container direction={'row'} justifyContent={'center'}>
          <Grid item xs={12} sm={12} md={12} className={classes.content_img}>
            <img src={obtenerImagen(codigo)} className={classes.image} alt='Imagen Asignatura' />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
