import React from 'react';
import PropTypes from 'prop-types';

const ActionBadge = ({ color, iconColor, reverse, action, Icon, text, orientation }) => {

  let HandleMouseOver = () => document.getElementById(text).style.transform = "scale(1.2)"
  let HandleMouseOut = () => document.getElementById(text).style.transform = "scale(1)";

  return (
    <div id={text} style={{
      display: `${orientation === 'vertical' ? 'grid' : 'flex'}`,
      textAlign: 'center',
      alignItems: 'center',
      flexDirection: `${reverse ? 'row-reverse' : 'row'}`,
      flexWrap: 'wrap',
      justifyContent: 'center',
      cursor: 'pointer',
      borderRadius: '15%',
      transitionDuration: '.2s'
    }}
      onClick={async () => await action()}
      onMouseOver={() => HandleMouseOver()}
      onMouseOut={() => HandleMouseOut()}>
      <div style={{
        padding: '10px',
        margin: '10px',
        backgroundColor: `${color}`,
        display: 'grid',
        placeItems: 'center',
        borderRadius: '50%',
      }}>
        <Icon style={{ color: `${iconColor}` }} />
      </div>
      <span style={{ fontWeight: 'bold' }}>{text}</span>
    </div>
  )
}

ActionBadge.propTypes = {
  color: PropTypes.string,
  iconColor: PropTypes.string,
  reverse: PropTypes.bool,
  action: PropTypes.func,
  Icon: PropTypes.elementType,
  orientation: PropTypes.string
}

ActionBadge.defaultProps = {
  reverse: false,
  iconColor: '#ffffff',
  orientation: 'horizontal'
}

export default ActionBadge
