import React from 'react';
import useWindowSize from '../../general/hooks/useWindowSize';

const Container = ({ children, loading }) => {

  const windowSize = useWindowSize();

  return (
    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      cursor: loading ? 'wait' : 'default'
    }} >
      {children}
    </div>
  )
}

export default Container
