import React from "react";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import ExtensionIcon from '@material-ui/icons/Extension';
import TimelineIcon from '@material-ui/icons/Timeline';
import CachedIcon from '@material-ui/icons/Cached';

// ICONOS MENU
import { Hidden } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import CrearBeca from "@material-ui/icons/Edit";
import SettingsIcon from "@material-ui/icons/Settings";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import GroupIcon from "@material-ui/icons/Group";
const clase = (item, selec, tipo = "") =>
  item === selec ? `item_selec${tipo}` : `item_modulo`;

export function Menu({ sele, seleccionar, estudiante }) {
  const pintar = (app, claseN = "") => {
    return (
      <div className={claseN}>
        <BottomNavigation
          showLabels
          className={clase("miproceso", sele)}
          onClick={() => seleccionar("miproceso")}
        >
          <BottomNavigationAction
            label={"Procesos"}
            icon={<LocationOnIcon />}
          />
        </BottomNavigation>

        {
          !estudiante &&
          <BottomNavigation
            showLabels
            className={clase("nuevo", sele)}
            onClick={() => seleccionar("nuevo")}
          >
            <BottomNavigationAction label={"Nuevo"} icon={<CrearBeca />} />
          </BottomNavigation>
        }
      </div>
    );
  };

  return (
    <div>
      <div>
        <Hidden xsDown> {pintar(false, "menu_modulo")} </Hidden>
        <Hidden smUp> {pintar(true, "menu_modulo_app")}</Hidden>
      </div>
    </div>
  );
}

export function SisefMenu({ sele, seleccionar, estudiante }) {
  const pintar = (app, claseN = "") => {
    return (
      <div className={claseN}>
        <BottomNavigation
          showLabels
          className={clase("miproceso_ini", sele)}
          onClick={() => seleccionar("miproceso_ini")}
        >
          <BottomNavigationAction
            label={"Procesos"}
            icon={<LocationOnIcon />}
          />
        </BottomNavigation>

        {
          !estudiante &&
          <BottomNavigation
            showLabels
            className={clase("administrar", sele)}
            onClick={() => seleccionar("administrar")}
          >
            <BottomNavigationAction
              label={"Administrar"}
              icon={<SettingsIcon />}
            />
          </BottomNavigation>
        }


      </div>
    );
  };

  return (
    <div>
      <div>
        <Hidden xsDown> {pintar(false, "menu_modulo")} </Hidden>
        <Hidden smUp> {pintar(true, "menu_modulo_app")}</Hidden>
      </div>
    </div>
  );
}

export function SeguimientoMenu({ sele = 0, seleccionar = () => { } }) {
  const pintar = (claseN = "") => {
    return (
      <div className={claseN}>
        <BottomNavigation showLabels>
          <BottomNavigationAction
            label={"Detalle"}
            icon={<ImportContactsIcon />}
            className={clase("detalle", sele)}
            onClick={() => seleccionar("detalle")}
          />
          <BottomNavigationAction
            label={"Profesores"}
            icon={<GroupIcon />}
            className={clase("docentes", sele)}
            onClick={() => seleccionar("docentes")}
          />
          <BottomNavigationAction
            label={"Historial"}
            icon={<TimelineIcon />}
            className={clase("historial", sele)}
            onClick={() => seleccionar("historial")}
          />
          <BottomNavigationAction
            label={"Estados"}
            icon={<CachedIcon />}
            className={clase("estados", sele)}
            onClick={() => seleccionar("estados")}
          />
          <BottomNavigationAction
            label={"Versiones"}
            icon={<ExtensionIcon />}
            className={clase("versiones", sele)}
            onClick={() => seleccionar("versiones")}
          />
        </BottomNavigation>
      </div>
    );
  };

  return <div>{pintar("menu_modal")}</div>;
}