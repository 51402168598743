import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Box from '@material-ui/core/Box';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import InscripcionAgregarMisDatos from './PreInscripcionAgregarMisDatos';
import InscripcionAgregarMiCarerra from './PreInscripcionAgregarMiCarerra';
import InscripcionAgregarMiContacto from './PreInscripcionAgregarMiContacto';
import Copyright from '../../general/app/Copyright';
import AlertasSimple from '../../general/AlertasSimple';
import emma_logo from "../../../global/imagenes/logo_agrupado_sinfondo.png";
import emma_01 from "../../../global/imagenes/emma_01.png";
import emma_02 from "../../../global/imagenes/emma_02.png";
import emma_03 from "../../../global/imagenes/emma_03.png";
import cuc from "../../../global/imagenes/logo_cuc.png";
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import MensajeContacto from '../../general/MensajeContacto';
import {coloresEmma } from '../../../global/js/funciones';
const useStyles = makeStyles(theme => ({
  layout: {
    width: 'auto',
    padding: '5px',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
  
    },
  },
  paper: {
    borderRadius: "50px",
    background: 'linear-gradient(0deg, rgba(245,245,245,0.5410539215686274) 100%, rgba(230,230,230,0.6587009803921569) 100%)',
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: theme.spacing(3),
    },
  },
  stepper: {
    backgroundColor: "transparent",
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',

  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    backgroundColor: "white",
    color: coloresEmma.secondarycolor,
  },
  logo_emma: {
    minWidth: '200px',
    width: "10%",
  },
  div_logo_cuc: {
    width: "100%",
    textAlign: "center"
  },
  titulo: {
    borderRadius: "50px",
    backgroundColor: coloresEmma.secondarycolor,
    color: "white",
    padding: "2%"
  },
  div_imagen: {
    position: "fixed",
    width: '90%',
    height: '400px',
    textAlign: 'left',
    zIndex: '-1'
  },
  div_imagen_fin: {
    width: '90%',
    textAlign: "center",

  },
  emma_fin: {
    width: "90%",
    maxWidth: '400px',
  },
  emma_info: {
    margin: theme.spacing(25, 0, 0, 5),
    width: "40%",
    maxWidth: "400px",
  },
  emma_info2: {
    margin: theme.spacing(25, 0, 0, 5),
    width: "40%",
    maxWidth: "400px",
  },
  btn_salir: {
    marginTop: theme.spacing(4),
    minWidth: '300px',
    width: "25%",
    borderRadius: "50px",
    color: 'white',
  },
  div_message: {
    zIndex: '1',
    position: "fixed",
    textDecoration: 'none',
    top: "0%",
    left: "75%",
    fontSize: "1em",
    lineHeight: "normal",
  },
  div_message2: {
    marginBottom: '10px',
    fontSize: "1em",
    lineHeight: "normal",
  },
  logo: {
    minWidth: '300px',
    width: "28%",
  },
  div_logo: {
    padding: theme.spacing(8, 0, 0, 0),
    textAlign: "center"
  },
  logo_cuc: {
    minWidth: '240px',
    width: "20%",
    marginTop: "20px"
  },
}));

const steps = ['Mis datos', 'Mi carrera', 'Mi contacto'];

function getStepContent(step, props_c, setBotonSiguiente) {
  switch (step) {
    case 0:
      return <InscripcionAgregarMisDatos props_c={props_c} setBotonSiguiente={setBotonSiguiente} />;
    case 1:
      return <InscripcionAgregarMiCarerra props_c={props_c} />;
    case 2:
      return <InscripcionAgregarMiContacto props_c={props_c} />;
    default:
      throw new Error('Unknown step');
  }
}

export default function InscripcionForm({ props_c }) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [botonSiguiente, setBotonSiguiente] = React.useState(false);
  let { guardado, mostrar_btn, mensajeAlerta } = props_c;
  const siguiente = () => {
    let { validarDatos, actualizarMensaje } = props_c;
    let titulo = validarDatos(activeStep);
    if (titulo) actualizarMensaje({ titulo, tipo: 'info', mostrar: true, tiempo: 6000 });
    else {
      if (activeStep < 2) setActiveStep(activeStep + 1);
    }
  };

  const anterior = () => {
    setActiveStep(activeStep - 1);
  };

  const terminar = () => {
    let { guardar, actualizarMensaje, validarDatos } = props_c;
    let titulo = validarDatos(activeStep);
    if (titulo) actualizarMensaje({ titulo, tipo: 'info', mostrar: true, tiempo: 6000 });
    else { guardar()}
  }

  const renderImagen = (item, guardado) => {
    if (!guardado) {
      if (item === 0) return <img src={emma_01} alt="Emma Mensaje" className={classes.emma_info} />
      else if (item === 1) return <img src={emma_02} alt="Emma Mensaje" className={classes.emma_info2} />
      else if (item === 2) return <img src={emma_03} alt="Emma Mensaje" className={classes.emma_info2} />
    }
  }

  const renderContenido = (guardado) => {
    if (!guardado) {
      return (
        <Paper className={classes.paper}>
          <Typography className={classes.titulo} component="h1" variant="h5" align="center">
            <Hidden mdUp> PRE INSCRIPCIONES</Hidden>
            <Hidden smDown>INSCRIPCIÓN  EDU.co PREUNIVERSITARIO  UNIVERSIDAD DE LA COSTA</Hidden>
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {guardado ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  Datos Guardados..!!
            </Typography>
                <br />
                <Divider />
                <br />
                <a href="/preinscripcion" className='sin_decorador'> <Button variant="contained" color="primary"> Salir </Button></a>
              </React.Fragment>
            ) : (
                <React.Fragment>
                  {getStepContent(activeStep, props_c, setBotonSiguiente)}
                  <div className={classes.buttons}>
                  {activeStep === 0 && (
                      <Button
                      onClick={siguiente}
                      disabled={botonSiguiente}
                      className={classes.button}>
                        SIGUIENTE
                      </Button>
                    )}
                    {activeStep === 1 && (<>
                    <Button onClick={anterior} className={classes.button}>
                      Regresar
                    </Button>
                    <Button onClick={siguiente} className={classes.button}>
                      SIGUIENTE
                    </Button>
                    </>
                     )}
                    {activeStep === 2 && (<>
                    <Button onClick={anterior} className={classes.button}>
                      Regresar
                    </Button>
                    <Button onClick={terminar} className={classes.button}>
                      TERMINAR
                    </Button>
                    </>
                     )}
                  </div>
                </React.Fragment>
              )}
          </React.Fragment>
        </Paper>
      )
    } else {
      return (
        <Grid container className={classes.div_logo}>
          <Grid item xs={12} >
            <img src={emma_logo} alt="Logo logo Emma" className={classes.logo} />
          </Grid>
          <Grid item xs={12} >
            <AlertasSimple
            tipo="info"
            titulo={<p style={{ textAlign: 'justify', margin: '0', fontSize: '.9rem' }}>Genial, ya estás inscrito y te damos las gracias por tu interés en vivir la experiencia EDU.co, un entrenamiento pre universitario que te acerca a la vida en la educación superior.  
            Tu inscripción se realizó de manera exitosa, ahora debes estar atento al correo electrónico registrado donde te notificaremos el estado de tu admisión al programa y los pasos a seguir. Recuerda que la Universidad de la Costa y el programa EDU.co se reservan el derecho de admisión, y la inscripción no significa aceptación ni admisión al programa. </p>}
          />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              size="large"
              color="primary"
              aria-label="add"
              className={classes.btn_salir}
              href="/"
              >
              Salir
            </Button>
          </Grid>
          <Grid item xs={12} >
            <img src={cuc} alt="Logo cuc" className={classes.logo_cuc} />
          </Grid>
        </Grid>
      );
    }
  }

  return (
    <React.Fragment>
      <Hidden smDown>
        <div className={classes.div_imagen}>
          {renderImagen(activeStep, guardado)}
        </div>
      </Hidden>
      <CssBaseline />
      <main className={classes.layout}>
        {!guardado &&
        <div className={classes.div_logo_cuc}>
          <img src={emma_logo} alt="Logo emma_logo" className={classes.logo_emma} />
        </div>
        } 
        {renderContenido(guardado)}
        {!guardado &&
          <React.Fragment>
            <Hidden mdDown>
              <div className={classes.div_message}>
                <MensajeContacto
                  title={"Información"}
                  content={mensajeAlerta}
                  enlaces={[]}
                  typeIcon={"duda"}
                />
              </div>
            </Hidden>
            <Hidden lgUp>
              <div className={classes.div_message2}>
                <MensajeContacto
                  title={"Información"}
                  content={mensajeAlerta}
                  enlaces={[]}
                  typeIcon={"duda"}
                />
              </div>
            </Hidden>
          </React.Fragment>
        }
        <Box mt={2}>
        </Box>
      </main>
      <Copyright />

    </React.Fragment>
  );
}
