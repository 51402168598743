import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Avatar, List, AppBar, Toolbar, Fab, InputBase, ListSubheader, Badge, Hidden, Tooltip,  Paper, Grid } from '@material-ui/core'
import emma_w from '../../global/imagenes/emma_w.png';
import emma_s1 from '../../global/imagenes/emma_s1.png';
import TareasTerminadas from './TareasTerminadas';
import AccionesRapidas from './AccionesRapidas';
import { coloresEmma } from '../../global/js/funciones';
const useStyles = makeStyles(theme => ({
  appBar: {
    width: '100%',
    backgroundColor: 'white'
  },
  grow: {
    flexGrow: 1
  },
  title: {
    margin: '0 auto',
    color: '#757575',
    display: 'none',
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    }
  }
}));

function Titulo({title}) {
  const classes = useStyles();
  return (
    <AppBar position='static' className={classes.appBar} elevation={0} id='appBar_listar_datos'>
      <Toolbar>
        <Typography variant="subtitle2" className={classes.title}>
          {title}
        </Typography>
        <div className={classes.grow} />
      </Toolbar>
    </AppBar>
  )
}
 
class ListarTarjetas extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    let { datos } = this.props;
    this.setState({ registros: datos })
  }

  render() {
    let { cargando, datos, titulo, avatar, color_avatar, acciones, orden} = this.props;

    if(orden && datos.length>0){
      datos = datos.sort(function (a, b) {
        if (a[orden] > b[orden]) return 1
        if (a[orden] < b[orden]) return -1
        return 0;
      })
    }
    
    return (
        <List style={{ padding: '0px 0px 0px 0px' }} className='scroll' subheader={
          <ListSubheader component="div" style={{ backgroundColor: 'white', borderBottom: `1px solid ${coloresEmma.fondocolor}` }}>
            <Titulo title={titulo} />
          </ListSubheader>
        }>
          {datos.length === 0 || cargando ? <TareasTerminadas imagen={cargando ? emma_w : emma_s1} mensaje={!cargando ? 'No se encontraron resultados' : 'Cargando..'} widthImg='7%' marginTop='7%' cargando={cargando} /> :
            <Grid container direction={'row'} spacing={1} justify={'center'} >
              <Grid item xs={12} sm={12} md={10} lg={10} >
                <Paper elevation={0} square className="scroll contenido_fijo">
                  <Grid container direction={'row'} spacing={1} style={{ width: "100%" }}> 
                    {datos.map((row, i) => {
                      let tam = (datos.length==(i+1) && datos.length%2!=0) ? 12 : 6
                      return (
                        <Grid item xs={12} sm={12} md={tam} lg={tam} key={i}>
                          <Paper elevation={1} style={{ border: `1px solid ${coloresEmma.fondocolor}` }} onClick={ () => acciones(row) }>
                              <AccionesRapidas avatar={color_avatar(row)} avatar_name={avatar(row)} titulo={row.nombre.charAt(0).toUpperCase() + row.nombre.slice(1).toLowerCase() } sub_titulo='' des_sub_titulo={row.descripcion} altura={{ minHeight: '80px' }} />
                          </Paper>
                        </Grid>
                      )
                    })}
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          }
        </List>
    )
  }
}

ListarTarjetas.propTypes = {
  datos: PropTypes.array.isRequired,
  titulo: PropTypes.string
}

ListarTarjetas.defaultProps = {
  datos: [],
  titulo: '',
  acciones: () => '',
  avatar: () => '',
  color_avatar: () => ({ 'backgroundColor': coloresEmma.fondocolor, 'color': '#757575' }),
  orden: false,
};

export default ListarTarjetas