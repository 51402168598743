import React, { Component } from 'react'
import { Dialog, DialogContent, DialogActions } from '@material-ui/core';
import AppBarModal from '../../general/AppBarModal';
import { BtnForm} from '../../general/BotonesAccion';
import { consulta, generarFiltros } from '../../../global/js/funciones'
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from '../../../global/imagenes/emma_w.png';
import PropTypes from 'prop-types'
import { DetallePromocion } from './Promocion';
import { PromocionMenu } from "./PromocionMenu";


class PromocionDetalle extends Component {
	constructor(props) {
		super(props)
		this.state = {
			cargando: false,
			seleccion: '',
			datos: [],
		}
	}
	async componentDidUpdate({ modalDetPromocion }, { seleccion }) {
		let { seleccion: seleccionNew } = this.state;
		let { id_promocion, modalDetPromocion: modalDetPromoNew, vistaDetalle } = this.props;
		if (seleccionNew !== seleccion) {
			if (seleccionNew === 'detalle') this.ObtenerPromocion(id_promocion);
		}
		if (modalDetPromocion !== modalDetPromoNew) this.setState({ seleccion: modalDetPromoNew ? vistaDetalle : '' })
	}

	async ObtenerPromocion(id) {
		this.setState({ cargando: true });
		let f = await generarFiltros([{ 'llave': 'id', 'valor': id }]);
		consulta(`api/v1.0/promocion?${f}`, null, null, (error, estado, resp) => {
			if (estado === 200) this.setState({ cargando: false, datos: resp });
		})
	}

	pintarPromocion = () => {
		let { datos } = this.state;
		if (datos[0]) {
			return <DetallePromocion datos={datos[0]} />;
		}
	}

	pintar = () => {
		let { cargando, seleccion } = this.state;
		if (cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />;
		return (
			<div>
				{seleccion === 'detalle' && this.pintarPromocion()}
			</div>
		)

	}
	render() {
		let { seleccion } = this.state;
		let { modalDetPromocion, mostrarModalDetallePromocion } = this.props;

		return (
			<>
				<Dialog open={modalDetPromocion} maxWidth='sm' fullWidth={true} onClose={() => mostrarModalDetallePromocion(false)}>
					<AppBarModal titulo='¡ Detalle Colegio !' mostrarModal={mostrarModalDetallePromocion} titulo_accion='' />
					<PromocionMenu sele={seleccion} seleccionar={seleccion => this.setState({ seleccion })} />
					<DialogContent style={{ padding: 0 }} className='scroll' >
						{this.pintar()}
					</DialogContent>
					<DialogActions>
						<BtnForm texto="Cerrar" callback={() => mostrarModalDetallePromocion(false)} />
					</DialogActions>
				</Dialog>
			</>
		);
	}
}

PromocionDetalle.propTypes = {
	modalDetPromocion: PropTypes.bool.isRequired,
}

export default PromocionDetalle;