import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import AlertasSimple from '../../general/AlertasSimple';
import BuscarSelect from "../../general/BuscarSelect";
import InputFile from "../../general/InputFile";
import AppBarModal from "../../general/AppBarModal";
import { BtnForm } from "../../general/BotonesAccion";
import { consulta, generarFiltros } from "../../../global/js/funciones";
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from '../../../global/imagenes/emma_w.png';

function MensajeValidacionId({ modal, mostrarmodal, mensaje, cargando }) {
  return (
    <Dialog open={modal} maxWidth="sm" fullWidth={true} onClose={() => mostrarmodal(false)} >
      <AppBarModal titulo='¡ Mensaje !' mostrarModal={mostrarmodal} titulo_accion='' />
      <DialogContent className='scroll' >
        {cargando ?
          <TareasTerminadas mensaje="Espere un momento. Estamos validando su numero de identificacion" marginTop='7%' imagen={emma_w} widthImg="7%" /> :
          <Typography variant="body1" color="textSecondary" component="p" align="justify">{mensaje}</Typography>
        }
      </DialogContent>
      <DialogActions>
        <BtnForm texto="Cerrar" callback={() => mostrarmodal(false)} />
      </DialogActions>
    </Dialog>
  );
}



export default class InscripcionAgregarMisDatos extends Component {

  constructor(props) {
    super(props)
    this.state = {
      cargando: true,
      mensaje: ''
    }
    this.fileInput = React.createRef();
  }

  async llenar_campos(datos = null) {
    if (datos) {
      this.props.props_c.setDepartamento(datos.departamento && { value: datos.departamento.id, label: datos.departamento.nombre } || { value: '' })
      setTimeout(() => this.props.props_c.setCiudad(datos.ciudad && { value: datos.ciudad.id, label: datos.ciudad.nombre } || { value: '' }), 1000);
      this.props.props_c.setEstrato(datos.estrato && { value: datos.estrato.id, label: datos.estrato.nombre } || { value: '' })
      this.props.props_c.setGenero(datos.estudiante && datos.estudiante.genero && { value: datos.estudiante.genero.id, label: datos.estudiante.genero.nombre } || '')
      this.props.props_c.setGrupo(datos.grupo && { value: datos.grupo.id, label: datos.grupo.nombre, codigo: datos.grupo.codigo } || '')
      this.props.props_c.setDiscapacidad(datos.discapacidad && { value: datos.discapacidad.id, descripcion: datos.discapacidad.descripcion, label: datos.discapacidad.nombre, codigo: datos.discapacidad.codigo } || '')
      this.props.props_c.setAdj_certificado(datos.adj_certificado || '')
      this.props.props_c.setNombre_Colegio(datos.nombre_colegio || '')
      this.props.props_c.setOpcion_estudiante(datos.estudiante_11 && { value: datos.estudiante_11, label: datos.estudiante_11.toUpperCase() } || { value: '' })
      this.props.props_c.setOpcion_nivelEducacion(datos.nivel_educacion && { value: datos.nivel_educacion, label: datos.nivel_educacion.toUpperCase()} || { value: '' })
      this.props.props_c.setOpcion_costearEstudio(datos.costear_estudio && { value: datos.costear_estudio.id, label: datos.costear_estudio.nombre } || { value: '' })
      this.props.props_c.setprimer_nombre(datos.estudiante && datos.estudiante.primer_nombre || '')
      this.props.props_c.setsegundo_nombre(datos.estudiante && datos.estudiante.segundo_nombre || '')
      this.props.props_c.setprimer_apellido(datos.estudiante && datos.estudiante.primer_apellido || '')
      this.props.props_c.setsegundo_apellido(datos.estudiante && datos.estudiante.segundo_apellido || '')
      this.props.props_c.setcorreo(datos.estudiante && datos.estudiante.correo || '')
      this.props.props_c.setcarrera(datos.carrera_pre_universitario || '')
      this.props.props_c.setcarrera2(datos.carrera_pre_universitario_2 || '')
      this.props.props_c.setedad(datos.edad || '')
      this.props.props_c.settelefono1(datos.telefono1 || '')
      this.props.props_c.setcelular1(datos.celular1 || '')
      this.props.props_c.setbarrio(datos.barrio || '')
      this.props.props_c.setlinea_edu(datos.linea_edu && { value: datos.linea_edu.id, label: datos.linea_edu.nombre } || { value: '' })
      this.props.props_c.setjornada(datos.jornada && { value: datos.jornada.id, label: datos.jornada.nombre } || { value: '' })
      this.props.props_c.setotro_jornada(datos.otro_jornada || '')
      this.props.props_c.setnombre_madre(datos.nombre_madre || '')
      this.props.props_c.setnombre_padre(datos.nombre_padre || '')
      this.props.props_c.setcelular_madre(datos.celular_madre || '')
      this.props.props_c.setcelular_padre(datos.celular_padre || '')
      this.props.props_c.setdireccion(datos.direccion || '')
      this.props.props_c.setPoseeEps(datos.tiene_eps && { value: datos.tiene_eps, label: datos.tiene_eps.toUpperCase() } || { value: '' })
      this.props.props_c.setNombreEps(datos.nombre_eps && { value: datos.nombre_eps.id, label: datos.nombre_eps.nombre } || { value: '' })
      this.props.props_c.setNombreEmergencia(datos.contacto_emergencia || '')
      this.props.props_c.setCelularEmergencia(datos.celular_emergencia || '')
      this.props.props_c.setNombreEpsOtro(datos.nombre_otro_eps || '')
      this.props.props_c.setotro_costear(datos.otro_costear || '')
    } else {
      this.props.props_c.setDepartamento({ value: '' })
      this.props.props_c.setCiudad({ value: '' });
      this.props.props_c.setEstrato({ value: '' })
      this.props.props_c.setGenero({ value: '' })
      this.props.props_c.setGrupo("")
      this.props.props_c.setDiscapacidad("")
      this.props.props_c.setAdj_certificado("")
      // this.props.props_c.setNombre_Colegio('')
      // this.props.props_c.setOpcion_estudiante({ value: '' })
      this.props.props_c.setOpcion_nivelEducacion({ value: '' })
      this.props.props_c.setOpcion_costearEstudio({ value: '' })
      this.props.props_c.setprimer_nombre('')
      this.props.props_c.setsegundo_nombre('')
      this.props.props_c.setprimer_apellido('')
      this.props.props_c.setsegundo_apellido('')
      this.props.props_c.setcorreo('')
      this.props.props_c.setcarrera('')
      this.props.props_c.setcarrera2('')
      this.props.props_c.setedad('')
      this.props.props_c.settelefono1('')
      this.props.props_c.setcelular1('')
      this.props.props_c.setbarrio('')
      // this.props.props_c.setlinea_edu({ value: '' })
      // this.props.props_c.setjornada({ value: '' })
      this.props.props_c.setnombre_madre('')
      this.props.props_c.setnombre_padre('')
      this.props.props_c.setcelular_madre('')
      this.props.props_c.setcelular_padre('')
      this.props.props_c.setdireccion('')
      this.props.props_c.setPoseeEps('')
      this.props.props_c.setNombreEps('')
      this.props.props_c.setNombreEmergencia('')
      this.props.props_c.setCelularEmergencia('')
      this.props.props_c.setNombreEpsOtro('') 
    }
  }
  validar_id = async ({ target }) => {
    let { actualizarMensaje, setMostrarBtn, mostrarModalMensajeValidacionId, mensaje } = this.props.props_c
    let identificacion = target.value
    if (identificacion) {
      this.setState({ mensaje: '', cargando: true });
      mostrarModalMensajeValidacionId(true)
      let f = await generarFiltros([{ 'llave': 'estudiante__identificacion', 'valor': identificacion }]);
      consulta(`api/v1.0/inscripcion/preuniversitario/validar_id?${f}`, null, null, (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            if (resp.length > 0) {
              if([16879, 119].includes(resp[0].tipo)) {
                setMostrarBtn(true)
                this.llenar_campos()
                this.props.setBotonSiguiente(true)
                this.setState({ mensaje: '¡Ya estás inscrito en la plataforma, comunicate vía Whatsapp con el múmero 3168569919!', cargando: false });
              } else {
                setMostrarBtn(false)
                this.llenar_campos(resp[0])
                this.props.setBotonSiguiente(false)
                this.setState({ mensaje: `¡Ya estás registrado en nuestra plataforma! Para continuar: Revisa tus datos y actualizalos. ${mensaje}.`, cargando: false });
              }
            } else {
              setMostrarBtn(true)
              this.llenar_campos()
              this.props.setBotonSiguiente(false)
              this.setState({ mensaje: `Tu identificación es válida para la inscripcion. Puedes continuar.`, cargando: false });
            }
          }
        } else {
          this.props.setBotonSiguiente(false)
          actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
        }
      }, false)
    }
  }

  render() {
    let {
      setTipo_linea,
      setTipo_jornada,
      setOpcion_estudiante,
      setGenero,
      generos,
      genero,
      grupos,
      grupo,
      setGrupo,
      discapacidades,
      discapacidad,
      setDiscapacidad,
      adj_certificado,
      modalMensajeValidacionId,
      mostrarModalMensajeValidacionId,
      validacion_doc,
      identificacion,
      edad,
      onChange,
      primer_nombre,
      segundo_nombre,
      primer_apellido,
      segundo_apellido,
      correo,
      telefono1,
      celular1,
      lineas_edu_co,
      linea_edu,
      jornada_preinscripcion,
      jornada,
      nombre_colegio,
      opciones_estudiante,
      estudiante_11,
      otro_jornada,
      cumplimiento_ley,
    } = this.props.props_c;
    let { mensaje, cargando } = this.state
    return (
      <React.Fragment>
        <MensajeValidacionId modal={modalMensajeValidacionId} mostrarmodal={mostrarModalMensajeValidacionId} mensaje={mensaje} cargando={cargando} />
        <Typography variant="h6" gutterBottom>
          Datos Personales
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} className='oculto'>
            <TextField
              ref={this.fileInput}
              required
              type="file"
              id="adj_certificado"
              name="adj_certificado"
              label="Adjuntar Certificado Medico de discapacidad o comunidad especial"
              fullWidth
              autoComplete="billing address-line1"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <BuscarSelect datos={opciones_estudiante} cambiarEstado={(value) => setOpcion_estudiante(value)} valor={estudiante_11} id='select-tipo-estudiante_11' nombre='¿ Estás cursando 11° actualmente ?' placeholder='Seleccione Opción' />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={nombre_colegio.toUpperCase()}
              required
              id="nombre_colegio"
              name="nombre_colegio"
              label="¿Cuál es el nombre del colegio donde estudias?"
              fullWidth
              autoComplete="fname"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <BuscarSelect datos={lineas_edu_co} cambiarEstado={(value) => setTipo_linea(value)} valor={linea_edu} id='select-tipo-EDU.co' nombre='¿En cuál linea EDU.co te quieres inscribir?' placeholder='Seleccione linea' />
          </Grid>
          <Grid item xs={12}>
            <BuscarSelect datos={jornada_preinscripcion} cambiarEstado={(value) => setTipo_jornada(value)} valor={jornada} id='select-tipo-jornada' nombre='¿Qué disponiilidad tienes para realizar este entrenamiento?' placeholder='Seleccione disponibilidad' />
          </Grid>
          {jornada.label === 'OTRO' && (
            <Grid item xs={12}>
              <TextField
                value={otro_jornada.toUpperCase()}
                required
                id="otro_jornada"
                name="otro_jornada"
                label="Escriba la Disponibilidad"
                fullWidth
                autoComplete="fname"
                onChange={onChange}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              value={identificacion}
              required
              type="number"
              id="identificacion"
              name="identificacion"
              label="¿Cuál es tu número de identificación?"
              fullWidth
              autoComplete="fname"
              onChange={onChange}
              onBlur={this.validar_id}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={edad}
              required
              type="number"
              id="edad"
              name="edad"
              label="¿Qué edad tienes?"
              fullWidth
              autoComplete="fname"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              value={primer_nombre.toUpperCase()}
              required
              id="primer_nombre"
              name="primer_nombre"
              label="¿Cuál es tu primer nombre?"
              fullWidth
              autoComplete="fname"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              value={segundo_nombre.toUpperCase()}
              id="segundo_nombre"
              name="segundo_nombre"
              label="¿Cuál es tu segundo nombre?"
              fullWidth
              autoComplete="fname"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              value={primer_apellido.toUpperCase()}
              required
              id="primer_apellido"
              name="primer_apellido"
              label="¿Cuál es tu primer apellido?"
              fullWidth
              autoComplete="lname"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              value={segundo_apellido.toUpperCase()}
              id="segundo_apellido"
              name="segundo_apellido"
              label="¿Cuál es tu segundo apellido?"
              fullWidth
              autoComplete="lname"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <BuscarSelect datos={generos} cambiarEstado={(value) => setGenero(value)} valor={genero} id='select-genero' nombre='Selecciona Tu Genero' placeholder='Genero' />
          </Grid>
          <Grid item xs={12}>
            <BuscarSelect datos={grupos} cambiarEstado={(value) => setGrupo(value)} valor={grupo} id='select-tipo-grupo' nombre='¿Perteneces a alguno de estos grupos?' placeholder='Seleccione Comunidad Especial' />
          </Grid>
          {grupo.codigo === 'GRU_DIS' &&
          <Grid item xs={12}>
            <BuscarSelect datos={discapacidades} cambiarEstado={(value) => setDiscapacidad(value)} valor={discapacidad} id='select-tipo-discapacidad' nombre='¿Que tipo de discapacidad tienes?' placeholder='Seleccione Discapacidad' />
          </Grid>
          }
          {(grupo !== '' && grupo.codigo !== 'GRU_NIN' && grupo.codigo !== 'GRU_LGB') &&
            <Grid item xs={12}>
              <InputFile label='Adjuntar Certificado medico de Discapacidad o Comunidad Especial' id='adj_certificado' value={adj_certificado} required={(validacion_doc[0].valora === '1') ? true : false} />
            </Grid>
          }
          <Grid item xs={12}>
            <TextField
              value={telefono1}
              required
              type="number"
              id="telefono1"
              name="telefono1"
              label="¿Como es tu número fijo de contacto?"
              fullWidth
              autoComplete="lname"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={celular1}
              required
              id="celular1"
              type="number"
              name="celular1"
              label="¿Cuál es tu número de celular?"
              fullWidth
              autoComplete="lname"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={correo.toUpperCase()}
              required
              type="email"
              id="correo"
              name="correo"
              label="Correo Personal"
              fullWidth
              autoComplete="billing address-line1"
              onChange={onChange}
            />
            <Grid item xs={12} md={12} className={`alert_info_titulo_sin_fondo`}>
              <Button variant="text" size="small" color="inherit" >
                <NotificationImportantIcon style={{ height: 15 }} /> Nota</Button>
            </Grid>
            <Grid item xs={12} md={12} className={`alert_info_sin_borde`}>
              <Typography variant="body2" component="p">Por favor verificar tu correo, ya que este es muy importante durante tu proceso.</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <AlertasSimple
              tipo="danger"
              titulo={<><p style={{ textAlign: 'justify', margin: '0', fontSize: '.9rem' }}>
                Estimado usuario al presionar el boton Siguiente esta aceptando los terminos de <b>CUMPLIMIENTO DE LEY </b> de la Corporacion Universitaria de la Costa CUC.</p>
                <p>Para ver los terminos y condiciones click sobre este mensaje</p></>}
              callback={() => cumplimiento_ley(true)}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}
