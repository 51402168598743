import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { BtnEliminar, BtnEditar, BtnDetalle } from "../../general/BotonesAccion";
import ListarDatos from "../../general/ListarDatos";

class PracticasSucursalesListar extends Component {

  render() {
    let {
      mostrarModalDelSucursal,
      mostrarModalModSucursal,
      actualizarDataSucursal,
      sucursales,
      mostrarModalDetValores
    } = this.props;

    const acciones = (data) => {

      const onClickDetalle = (data) => {
        actualizarDataSucursal(data);
        mostrarModalDetValores(true);
      }


      const onClickEliminar = (data) => {
        actualizarDataSucursal(data);
        mostrarModalDelSucursal(true);
      }

      const onClickEditar = (data) => {
        actualizarDataSucursal(data);
        mostrarModalModSucursal(true);
      }

      return <div>  <BtnDetalle callback={() => onClickDetalle(data)} />  <BtnEliminar callback={() => onClickEliminar(data)} /> <BtnEditar callback={() => onClickEditar(data)} /></div>;
    }
    return (
      <ListarDatos
        datos={sucursales}
        titulo='Lista Sucursales'
        buscar={false}
        opciones={true}
        sub_titulo='Sucursales'
        id='tbl_sucursales'
        acciones={row => acciones(row)}
        fila_principal='nombre'
        filas={[
          { 'id': 'valorb', },
          { 'id': 'valorg', },
        ]}
      />
    )
  }

}

//Props del componente
PracticasSucursalesListar.propTypes = {
  //variables
  sucursales: PropTypes.array.isRequired,
  //funciones
  actualizarDataSucursal: PropTypes.func.isRequired,
  mostrarModalModSucursal: PropTypes.func.isRequired,
  mostrarModalDelSucursal: PropTypes.func.isRequired,
}


export default PracticasSucursalesListar;


