import React, { Component } from 'react'
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { BtnForm } from "./BotonesAccion";
import AppBarModal from './AppBarModal'

class ModalAyuda extends Component {

  constructor(props) {
    super(props)
    this.state = {
    }
  }


  render() {
    let { modalAyuda, mostrarModalAyuda, titulo, mensaje1, mensaje2 } = this.props;
    return (
      <Dialog open={modalAyuda} onClose={mostrarModalAyuda.bind(this, false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <AppBarModal titulo={titulo} mostrarModal={mostrarModalAyuda} titulo_accion="" accion="" />
        <DialogContent className='scroll'>
          <DialogContentText id="alert-dialog-description">
            {mensaje1} {mensaje2}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CERRAR" callback={mostrarModalAyuda.bind(this, false)} />
        </DialogActions>
      </Dialog>
    )
  }
}

//Props del componente
ModalAyuda.propTypes = {
  //variables
  modalAyuda: PropTypes.bool.isRequired,
  titulo: PropTypes.string,
  //funciones
  mostrarModalAyuda: PropTypes.func.isRequired,
  ejecutarAccion: PropTypes.func.isRequired,
}

ModalAyuda.defaultProps = {
  titulo: 'Ayuda',
  mensaje1: '',
  mensaje2: ''
};

export default ModalAyuda;
