import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
//COMPONENTS
import { Dialog, DialogContent, DialogActions } from '@material-ui/core';
import AppBarModal from '../general/AppBarModal';
import ListarDatos from '../general/ListarDatos';
import { BtnForm } from '../general/BotonesAccion';
//MATERIAL
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
//MATERIAL ICONS
import AssignmentTurnedInRoundedIcon from '@material-ui/icons/AssignmentTurnedInRounded';
import HourglassEmptyRoundedIcon from '@material-ui/icons/HourglassEmptyRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//UTILS
import useWindowSize from '../general/hooks/useWindowSize';

const Semaforo = ({ status, toggle, data }) => {

  const [materias, setMaterias] = useState([]);
  const windowSize = useWindowSize();

  useEffect(() => {
    const newData = data.map(materia => {
      const newItem = {
        codigo: materia[2],
        nombre: materia[3],
        nota: materia[4],
        semestre: materia[6],
        estado: materia[7]
      }
      return newItem;
    });
    let result = newData.reduce(function (r, a) {
      r[a.semestre] = r[a.semestre] || [];
      r[a.semestre].push(a);
      return r;
    }, Object.create(null));
    setMaterias(result);

    console.log(result["1"]);

  }, [data])

  return (
    <Dialog open={status} onClose={() => toggle({ modal: 'dialogSemaforo', value: false })} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="sm">
      <AppBarModal titulo="Semaforo" mostrarModal={() => toggle({ modal: 'dialogSemaforo', value: false })} titulo_accion="" accion="" />
      <DialogContent style={{ padding: 0 }} className='scroll'>
        <div>
          {Object.keys(materias).map((i) => {
            return (
              <ExpansionPanel square >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Semestre {i}</Typography>
                </ExpansionPanelSummary>
                <ListarDatos
                  id="tbl_listar_semaforo"
                  descargar="EMMA - SEMAFORO"
                  datos={materias[i]}
                  opciones={false}
                  agregar={false}
                  buscar={false}
                  acciones={{}}
                  avatar={({ estado }) => estado === "Aprobada" ? <AssignmentTurnedInRoundedIcon /> : <HourglassEmptyRoundedIcon />}
                  color_avatar={({ estado }) => estado === 'Aprobada' ? ({ 'backgroundColor': '#64dd17', 'color': '#ffffff' }) : ({ 'backgroundColor': '#ffd600', 'color': '#ffffff' })}
                  titulo={"Semaforo"}
                  fila_principal={({ nombre }) => `${nombre}`}
                  filas={[
                    {
                      'id': 'nombre',
                      'enLista': false
                    },
                    {
                      'mostrar': ({ codigo }) => `Codigo: ${codigo}`,
                      'id': 'codigo',
                      'enLista': true
                    },
                    {
                      'mostrar': ({ nota }) => `Nota: ${nota}`,
                      'id': 'nota',
                      'enLista': true
                    },
                  ]}
                />
              </ExpansionPanel>
            )
          })}
        </div>
      </DialogContent>
      <DialogActions>
        <BtnForm texto="Cerrar" callback={() => toggle({ modal: 'dialogSemaforo', value: false })} />
      </DialogActions>
    </Dialog>
  )
}

export default Semaforo
