import React from "react";
import { Grid, TextField, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import BuscarSelect from "../../general/BuscarSelect";
import TareasTerminadas from "../../general/TareasTerminadas";
import { Hidden } from "@material-ui/core";

// IMAGENES
import emma_w from "../../../global/imagenes/emma_w.png";
const onChange = ({ target }) => {
  let { value, name } = target;
  this.setState({
    [name]: value,
  });
};

export function PintarDatosIniciales({
  correo_personal,
  celular,
  telefono,
  cargando,
  onChange,
  programas,
  abrirProgramas,
  programa,
}) {
  const pintar = () => {
    if (!cargando) {
      return (
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              type="number"
              id="telefono"
              name="telefono"
              label="Telefono"
              value={telefono}
              onChange={onChange}
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              type="number"
              id="celular"
              name="celular"
              label="Celular"
              value={celular}
              onChange={onChange}
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              type="email"
              id="correo"
              name="correo_personal"
              label="Correo Personal"
              value={correo_personal}
              onChange={onChange}
              required
              fullWidth
            />
          </Grid>

          {/* <Grid item xs={12}>
            <FormControl className="form-control">
                <InputLabel shrink required={true}> Programa </InputLabel>
                <Select open={abrirProgramas} onClose={() => abrirProgramas("false")} onOpen={() => abrirProgramas("true")} value={programas && programas.codigo} labelid="demo-simple-select-placeholder-label-label" id="demo-simple-select-placeholder-label" >
                  {programas.map(({  codigo, nombre  }) => <MenuItem key={codigo} value={codigo} name={nombre}>{nombre}</MenuItem>)}
                </Select>
            </FormControl>
            </Grid> */}

          <Grid item xs={12}>
            <FormControl className="form-control">
              <InputLabel>Programa</InputLabel>
              <Select
                id="subtipo"
                value={programa}
                onChange={onChange}
                name="subtipo"
              >
                {programas.map(({ codigo, nombre }) => (
                  <MenuItem key={codigo} value={codigo}>
                    {nombre}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      );
    } else
      return (
        <TareasTerminadas
          mensaje="Cargando..."
          marginTop="7%"
          imagen={emma_w}
          widthImg="7%"
        />
      );
  };
  return (
    <div>
      <div>
        <Hidden xsDown> {pintar(false, "menu_modulo")} </Hidden>
        <Hidden smUp> {pintar(true, "menu_modulo_app")}</Hidden>
      </div>
    </div>
  );
}
