import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import BuscarSelect from "../general/BuscarSelect";
import InputFile from "../general/InputFile";

export default function InscripcionAgregarMiCarerra({ props_c }) {

  let fileInput = React.createRef();

  let {
    carreras,
    opciones_conociste,
    opcion_conociste,
    setOpcion_conociste,
    carrera,
    setCarrera,
    adj_idpadres,
    menor_edad,
    onChange,
    link_consentimiento_informado
  } = props_c;

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Datos Finales
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} className='oculto'>
          <TextField
            ref={fileInput}
            required
            type="file"
            id="adj_idpadres"
            name="adj_idpadres"
            label='Adjuntar Identificacion de los padres.'
            fullWidth
            autoComplete="billing address-line1"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <BuscarSelect datos={carreras} cambiarEstado={(value) => setCarrera(value)} valor={carrera} id='select-carrera' nombre='¿ Que quieres estudiar ?' placeholder='Seleccione Carrera' />
        </Grid>
        <Grid item xs={12}>
          <BuscarSelect datos={opciones_conociste} cambiarEstado={(value) => setOpcion_conociste(value)} valor={opcion_conociste} id='select-tipo-conociste' nombre='¿ Como nos conociste ?' placeholder='Seleccione Opción' />
        </Grid>
        { (menor_edad && carrera.codigo == '14011') &&
            <Grid item xs={12}>
              <InputFile label='Adjuntar Identificacion de los padres.' id='adj_idpadres' value={adj_idpadres} required={false} />
              <Typography variant="body2" style={{ color: '#9e9e9e', padding: '2px 4px' }} component="p" align='justify'>En aprobacion al <a href={link_consentimiento_informado} target='_black' className='sin_decorador' style={{ color: 'black' }}>consentimiento informado</a></Typography>
            </Grid>
        }
      </Grid>
    </React.Fragment>
  );
}
