import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mostrarModulo } from '../../redux/actions/actGlobal';
import ListarDatos from '../general/ListarDatos';
import { BtnDetalle, BtnRegresar } from '../general/BotonesAccion';
import Detalle from '../general/Detalle';
import { consulta, generarFiltros } from '../../global/js/funciones';
import TareasTerminadas from '../general/TareasTerminadas';
import emma_w from '../../global/imagenes/emma_w.png';
import { PREGUNTAS } from './helper';
import { Grid, Paper } from '@material-ui/core';
import TituloAccion from '../general/TituloAccion';
import AccionesRapidas from '../general/AccionesRapidas';
import fechaIcon from '../../global/imagenes/calendar.png';

class Entrevistas extends Component {
  constructor(props) {
    super(props)
    this.state = {
      entrevistas: [],
      modal: false,
      entrevista: {},
      cargando: false,
      cargando_det: false,
      entrevista_id: 0,
      periodos: [],
      periodo: '',
      seleccion: 'inicio'
    }
  }

  async componentDidMount() {
    let { mostrarModulo } = this.props;
    mostrarModulo({ ruta: '/historico_entrevistas', nombre: 'Historico Entrevistas' });
    this.setState({ cargando: true });
    let periodos = await this.obtenerPeriodos();
    this.setState({ periodos: periodos.sort((a, b) => b.periodo - a.periodo), cargando: false });
  }

  async componentDidUpdate({}, { entrevista_id, seleccion }) {
    let { entrevista_id: entrevista_id_new, seleccion: seleccion_new } = this.state;

    if (seleccion !== seleccion_new) {
      if (seleccion_new !== 'inicio') this.obtenerListaEntrevistas();
    }
    if (entrevista_id !== entrevista_id_new) {
      if (entrevista_id_new !== 0) this.obtenerDetalleEntrevista(entrevista_id_new);
    }
  }

  async obtenerListaEntrevistas() {
    let { periodo } = this.state;

    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'periodo', 'valor': periodo }]);
    consulta(`api/v1.0/entrevistas/lista?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, entrevistas: resp });
    });
  }

  async obtenerDetalleEntrevista(id) {
    this.setState({ cargando_det: true });
    consulta(`api/v1.0/entrevista/${id}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando_det: false, entrevista: resp.entrevista });
    });
  }

  obtenerPeriodos = () => {
    return new Promise(resolve => {
      consulta(`api/v1.0/entrevistas/periodos`, null, null,
        (error, estado, resp) => {
          resolve(resp)
        }
      );
    });
  }

  vista = () => {
    let { seleccion } = this.state;

    return(
      <>
        { seleccion === 'inicio' && this.pintarInicio() }
        { seleccion !== 'inicio' && this.pintarListaEntrevistas() }
      </>
    )
  }

  pintarInicio = () => {
    let { periodos } = this.state;
    return (
      <div style = {{ padding: '2% 2% 0% 2%' }}>
        <Grid container direction = { 'row' } spacing = { 1 } justifyContent = { 'center' }>
          <Grid item xs = { 12 } sm = { 12 } md = { 10 } lg = { 8 }>
            <TituloAccion principal='' alterno = 'Periodos con solicitudes' />
            <Paper elevation = { 0 } square className = 'scroll contenido_fijo'>
              <Grid container direction = { 'row' } spacing = { 1 } style = {{ width: "100%" }}>
                {
                  periodos.map(({ periodo }) => {
                    return (
                      <Grid item xs = { 12 } sm = { 12 } md = { 6 } lg = { 6 } >
                        <Paper elevation = { 1 } style = {{ border: '1px solid #e0e0e0' }} onClick = { () => this.setState({ periodo, seleccion: periodo }) }>
                          <AccionesRapidas imagen = { fechaIcon } titulo = { periodo } sub_titulo = '' des_sub_titulo = { `Entrevistas registradas en el periodo ${periodo}.`} />
                        </Paper>
                      </Grid>
                    );
                  })
                }
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    )
  }

  pintarListaEntrevistas = () => {
    let { entrevistas } = this.state;

    const acciones = (row) => {
      const onClickDetalle = ({ id }) => this.setState({ modal: true, entrevista_id: id });
      let detalle = <BtnDetalle callback={() => onClickDetalle(row)} color='#01579b' texto='Abrir' />;
      return detalle;
    };

    return (
      <>
        <div className = { 'regresar' } ><BtnRegresar callback = { () => this.setState({ seleccion: 'inicio' }) } /></div>
        <ListarDatos
          id="tbl_historial"
          titulo='Lista de Entrevistas'
          datos={entrevistas}
          buscar={true}
          opciones={true}
          sub_titulo="Lista de Entrevistas"
          avatar = {({ estado }) => estado.charAt(0).toUpperCase()}
          // color_avatar={({ estado_actual: { codigo } }) => obtenerColor(codigo)}
          acciones={(row) => acciones(row)}
          fila_principal = { ({ nombre_completo }) => nombre_completo.toUpperCase() }
          filas={[
            { 'id': 'identificacion', 'enLista': true, 'nombre': 'Número de Identificación: ' },
            { 'id': 'programa', 'enLista': true, 'nombre': 'Programa: ' },
            { 'id': 'codigo', 'enLista': true, 'nombre': 'Código: ' },
            { 'id': 'fecha_registro', 'enLista': true, 'nombre': 'Fecha de creación de entrevista: ' },
            { 'id': 'usuario_registro', 'enLista': true, 'nombre': 'Entrevista realizada por: ' },
          ]}
        />
      </>
    )
  }

  render() {
    let { modal, cargando, cargando_det } = this.state;

    const configDetail = () => {
      let { entrevista } = this.state;
      let arr = []

      if (entrevista) {
        Object.keys(entrevista).map((k, index) => {
          if (k !== 'id') {
            // if (k === 'posee_eps' || k === 'observacion_psicologica' || k === 'posee_computador' || k === 'posee_celular_inteligente' || k === 'tiene_acceso_internet' || k === 'tiene_plan_datos' || k === 'usa_lentes_recetados' || k === 'trabaja_mientras_estudia') {
            //   arr.push({ 'label': PREGUNTAS[index], 'value': entrevista[k] === '1' ? 'Sí' : 'No' });
            // } else arr.push({ 'label': PREGUNTAS[index], 'value': entrevista[k] ? entrevista[k] : 'No registra' });

            switch (k) {
              case 'posee_eps':
              case 'observacion_psicologica':
              case 'posee_computador':
              case 'posee_celular_inteligente':
              case 'tiene_acceso_internet':
              case 'tiene_plan_datos':
              case 'usa_lentes_recetados':
              case 'trabaja_mientras_estudia':
                  arr.push({ 'label': PREGUNTAS[index], 'value': entrevista[k] === '1' ? 'Sí' : 'No' });
                break;
              case 'talento_capacidad':
              case 'num_hijos':
              case 'diagnostico_enfermedad':
              case 'tipo_inscripcion':
              case 'tipo_vinculacion':
              case 'intereses_cbasicas':
              case 'raz_cuantitativo':
              case 'cual_diagnostico':
                  if(entrevista['periodo'] == '20191' || entrevista['periodo'] == '20201' || entrevista['periodo'] == '20202') arr.push({ 'label': PREGUNTAS[index], 'value': entrevista[k] ? entrevista[k] : 'No registra' });
                break;
              case 'plc':
              case 'pma':
              case 'pglobal':
                if(entrevista['periodo'] == '20221') arr.push({ 'label': PREGUNTAS[index], 'value': entrevista[k] ? entrevista[k] : 'No registra' });
              break;
              default:
                 arr.push({ 'label': PREGUNTAS[index], 'value': entrevista[k] ? entrevista[k] : 'No registra' });
                break;
            }

          }
        });
      }

      return arr;
    };

    return (
      <div>
        { cargando ? <TareasTerminadas mensaje = 'Cargando..' marginTop = '7%' imagen = { emma_w } widthImg = '7%' /> : this.vista() }
        
        <Detalle
          titulo={'¡ Detalle entrevista !'}
          open={modal}
          config = { configDetail()}
          cerrar={() => this.setState({ modal: false })}
          cargando = { cargando_det }
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = { mostrarModulo }

Entrevistas.propTypes = {
  mostrarModulo: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Entrevistas);
