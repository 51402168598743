import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { TextField, Paper, Grid, Tooltip, IconButton, InputLabel, MenuItem, FormControl, DialogActions, DialogContent, Select, Dialog, CardHeader, Avatar, Stepper, StepLabel, Step, Card, CardContent, Typography, List } from '@material-ui/core'
import { consulta, formulario, mostrarError, generarFiltros, obtenerValoresFiltros, crear_form_data, rutaImg, obtenerPermisosValorAlt, api } from "../../../global/js/funciones";
import { mostrarModalComment, mostrarModalFormuDirecto } from "../../../redux/actions/actCreditos";
import { BtnDetalle, BtnGestionar, BtnFolder, BtnRegresar, BtnForm, BtnFormPage, BtnDescargar, BtnCargar,BtnVer } from "../../general/BotonesAccion";
import { mostrarModulo, actualizarMensaje } from "../../../redux/actions/actGlobal";
import ListarDatos from "../../general/ListarDatos";
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from '../../../global/imagenes/emma_w.png';
import AppBarModal from '../../general/AppBarModal';
import EnviarMultiplesArchivos from "../../general/EnviarMultiplesArchivos";
import { CODIGO_MODELO_LETRA_CAMBIO, LONGITUD_REQUISITOS } from "./../helper";



const configStep = ['Datos del credito', 'Datos del estudiante', 'Referencia del estudiante', 'Datos del codeudor', 'Referencia del codeudor', 'Requisitos'];


function PrimerFormulario(component_props) {
  let { Valor_solicitado, CreditoPara, Valor_de_la_matricula, Programa_cre_d, onChange, credito_para, programas } = component_props

  return (
    <>
      <Grid container spacing={3} style={{ marginBottom: '40px' }}>
        <Grid item xs={6}>
          <TextField
            value={Valor_solicitado}
            required
            type="number"
            id="Valor_solicitado"
            name="Valor_solicitado"
            label="Valor solicitado"
            fullWidth
            onChange={onChange}
          />
        </Grid>


        <Grid item xs={6}>
          <FormControl className='form-control'>
            <InputLabel id="demo-simple-select-label">Crédito para</InputLabel>
            <Select id="CreditoPara" value={CreditoPara} name='CreditoPara' onChange={onChange}  >
              {
                credito_para.map((value) => {
                  return <MenuItem key={value.id} value={value} data-my-value={value.codigo}>{value.nombre}</MenuItem>
                })
              }
            </Select>
          </FormControl>
        </Grid>


        <Grid item xs={6}>
          <TextField
            value={Valor_de_la_matricula}
            required
            type="number"
            id="Valor_de_la_matricula"
            name="Valor_de_la_matricula"
            label="Valor de la matricula"
            fullWidth
            onChange={onChange}
          />
        </Grid>


        <Grid item xs={6}>
          <FormControl className='form-control'>
            <InputLabel id="demo-simple-select-label">Programa</InputLabel>
            <Select id="Programa_cre_d" value={Programa_cre_d} name='Programa_cre_d' onChange={onChange}  >
              {
                programas.map((value) => {
                  return <MenuItem key={value.relacion.id} value={value.relacion} data-my-value={value.relacion.codigo}>{value.relacion.nombre}</MenuItem>
                })
              }
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  )
}

function SegundoFormulario(component_props) {
  let {estado_civil, onChange,telefono_contacto,direccion_per,correo_personal,lugar_expedicion,lugar_residencia } = component_props
  return (
    <>
      <Grid container spacing={3} style={{ marginBottom: '40px' }}>
        <Grid item xs={6}>
          <TextField
            value={telefono_contacto}
            required
            type="number"
            id="telefono_contacto"
            name="telefono_contacto"
            label="Teléfono de contacto"
            fullWidth
            onChange={onChange}
          />
        </Grid>



        <Grid item xs={6}>
          <TextField
            value={direccion_per}
            required
            type="text"
            id="direccion_per"
            name="direccion_per"
            label="Dirección"
            fullWidth
            onChange={onChange}
          />
        </Grid>



        <Grid item xs={6}>
          <TextField
            value={correo_personal}
            required
            type="text"
            id="correo_personal"
            name="correo_personal"
            label="Correo personal"
            fullWidth
            onChange={onChange}
          />
        </Grid>



        <Grid item xs={6}>
          <TextField
            value={lugar_expedicion}
            required
            type="text"
            id="lugar_expedicion"
            name="lugar_expedicion"
            label="Lugar de expedición del documento"
            fullWidth
            onChange={onChange}
          />
        </Grid>



        <Grid item xs={6}>
          <TextField
            value={estado_civil}
            required
            type="text"
            id="estado_civil"
            name="estado_civil"
            label="Estado civil"
            fullWidth
            onChange={onChange}
          />
        </Grid>



        <Grid item xs={6}>
          <TextField
            value={lugar_residencia}
            required
            type="text"
            id="lugar_residencia"
            name="lugar_residencia"
            label="Lugar de residencia"
            fullWidth
            onChange={onChange}
          />
        </Grid>
      </Grid>

    </>
  )

}

function TercerFormulario(component_props) {
  let { documento, fecha_expedicion, nombre_completo, fecha_nacimiento, lugar_nacimiento, telefono_estu, direccion_estu, correo_estu, onChange } = component_props
  return (
    <>
      <Grid container spacing={3} style={{ marginBottom: '40px' }}>
        {/* <Grid item xs={6}>
          <TextField
            value={documento}
            required
            type="number"
            id="documento"
            name="documento"
            label="Documento de identidad"
            fullWidth
            onChange={onChange}
          />
        </Grid> */}



        {/* <Grid item xs={6}>
          <TextField
            value={fecha_expedicion}
            required
            type="text"
            id="fecha_expedicion"
            name="fecha_expedicion"
            label="Fecha de expedición"
            fullWidth
            onChange={onChange}
          />
        </Grid> */}



        <Grid item xs={6}>
          <TextField
            value={nombre_completo}
            required
            type="text"
            id="nombre_completo"
            name="nombre_completo"
            label="Nombre completo"
            fullWidth
            onChange={onChange}
          />
        </Grid>



        {/* <Grid item xs={6}>
          <TextField
            value={fecha_nacimiento}
            required
            type="text"
            id="fecha_nacimiento"
            name="fecha_nacimiento"
            label="Fecha de nacimiento"
            fullWidth
            onChange={onChange}
          />
        </Grid> */}



        {/* <Grid item xs={6}>
          <TextField
            value={lugar_nacimiento}
            required
            type="text"
            id="lugar_nacimiento"
            name="lugar_nacimiento"
            label="Lugar de nacimiento"
            fullWidth
            onChange={onChange}
          />
        </Grid> */}



        <Grid item xs={6}>
          <TextField
            value={telefono_estu}
            required
            type="number"
            id="telefono_estu"
            name="telefono_estu"
            label="Teléfono de contacto"
            fullWidth
            onChange={onChange}
          />
        </Grid>



        <Grid item xs={6}>
          <TextField
            value={direccion_estu}
            required
            type="text"
            id="direccion_estu"
            name="direccion_estu"
            label="Dirección"
            fullWidth
            onChange={onChange}
          />
        </Grid>



        <Grid item xs={6}>
          <TextField
            value={correo_estu}
            required
            type="text"
            id="correo_estu"
            name="correo_estu"
            label="Correo electrónico"
            fullWidth
            onChange={onChange}
          />
        </Grid>
      </Grid>

    </>
  )

}

function CuartoFormulario(component_props) {
  let { documento_co, fecha_expedicion_co, nombre_completo_co, fecha_nacimiento_co, lugar_nacimiento_co,
    telefono_co, direccion_co, correo_co, lugar_expe_documento_co, estado_civil_co, direccion_resi_co, lugar_resi_co, ocupacion_co,
    ciudad_empresa_co, direccion_empresa_co, telefono_empresa_co, empresa_co, ingreso_co, Ocupacion, onChange } = component_props
  return (
    <>
      <Grid container spacing={3} style={{ marginBottom: '40px' }}>
        <Grid item xs={6}>
          <TextField
            value={documento_co}
            required
            type="number"
            id="documento_co"
            name="documento_co"
            label="Documento de identidad"
            fullWidth
            onChange={onChange}
          />
        </Grid>



        <Grid item xs={6}>
          <TextField
            value={fecha_expedicion_co}
            required
            type="text"
            id="fecha_expedicion_co"
            name="fecha_expedicion_co"
            label="Fecha de expedición"
            fullWidth
            onChange={onChange}
          />
        </Grid>



        <Grid item xs={6}>
          <TextField
            value={nombre_completo_co}
            required
            type="text"
            id="nombre_completo_co"
            name="nombre_completo_co"
            label="Nombre completo"
            fullWidth
            onChange={onChange}
          />
        </Grid>



        <Grid item xs={6}>
          <TextField
            value={fecha_nacimiento_co}
            required
            type="text"
            id="fecha_nacimiento_co"
            name="fecha_nacimiento_co"
            label="Fecha de nacimiento"
            fullWidth
            onChange={onChange}
          />
        </Grid>



        <Grid item xs={6}>
          <TextField
            value={lugar_nacimiento_co}
            required
            type="text"
            id="lugar_nacimiento_co"
            name="lugar_nacimiento_co"
            label="Lugar de nacimiento"
            fullWidth
            onChange={onChange}
          />
        </Grid>



        <Grid item xs={6}>
          <TextField
            value={telefono_co}
            required
            type="number"
            id="telefono_co"
            name="telefono_co"
            label="Teléfono de contacto"
            fullWidth
            onChange={onChange}
          />
        </Grid>



        <Grid item xs={6}>
          <TextField
            value={direccion_co}
            required
            type="text"
            id="direccion_co"
            name="direccion_co"
            label="Dirección"
            fullWidth
            onChange={onChange}
          />
        </Grid>



        <Grid item xs={6}>
          <TextField
            value={correo_co}
            required
            type="text"
            id="correo_co"
            name="correo_co"
            label="Correo electronico"
            fullWidth
            onChange={onChange}
          />
        </Grid>



        <Grid item xs={6}>
          <TextField
            value={lugar_expe_documento_co}
            required
            type="text"
            id="lugar_expe_documento_co"
            name="lugar_expe_documento_co"
            label="Lugar de expedición del documento"
            fullWidth
            onChange={onChange}
          />
        </Grid>



        <Grid item xs={6}>
          <TextField
            value={estado_civil_co}
            required
            type="text"
            id="estado_civil_co"
            name="estado_civil_co"
            label="Estado civil"
            fullWidth
            onChange={onChange}
          />
        </Grid>



        <Grid item xs={6}>
          <TextField
            value={direccion_resi_co}
            required
            type="text"
            id="direccion_resi_co"
            name="direccion_resi_co"
            label="dirección de residencia"
            fullWidth
            onChange={onChange}
          />
        </Grid>



        <Grid item xs={6}>
          <TextField
            value={lugar_resi_co}
            required
            type="text"
            id="lugar_resi_co"
            name="lugar_resi_co"
            label="Lugar de residencia"
            fullWidth
            onChange={onChange}
          />
        </Grid>



        <Grid item xs={6}>
          <FormControl className='form-control'>
            <InputLabel id="demo-simple-select-label">Ocupación</InputLabel>
            <Select id="ocupacion_co" value={ocupacion_co} name='ocupacion_co' onChange={onChange}  >
              {
                Ocupacion.map((value) => {
                  return <MenuItem key={value.id} value={value} data-my-value={value.id}>{value.nombre}</MenuItem>
                })
              }
            </Select>
          </FormControl>
        </Grid>



        <Grid item xs={6}>
          <TextField
            value={ingreso_co}
            required
            type="number"
            id="ingreso_co"
            name="ingreso_co"
            label="Ingresos mensuales"
            fullWidth
            onChange={onChange}
          />
        </Grid>



        <Grid item xs={6}>
          <TextField
            value={empresa_co}
            required
            type="text"
            id="empresa_co"
            name="empresa_co"
            label="Empresa donde labora"
            fullWidth
            onChange={onChange}
          />
        </Grid>



        <Grid item xs={6}>
          <TextField
            value={telefono_empresa_co}
            required
            type="number"
            id="telefono_empresa_co"
            name="telefono_empresa_co"
            label="Teléfono de contacto de la empresa"
            fullWidth
            onChange={onChange}
          />
        </Grid>



        <Grid item xs={6}>
          <TextField
            value={direccion_empresa_co}
            required
            type="text"
            id="direccion_empresa_co"
            name="direccion_empresa_co"
            label="dirección de la empresa"
            fullWidth
            onChange={onChange}
          />
        </Grid>



        <Grid item xs={6}>
          <TextField
            value={ciudad_empresa_co}
            required
            type="text"
            id="ciudad_empresa_co"
            name="ciudad_empresa_co"
            label="Ciudad de la empresa"
            fullWidth
            onChange={onChange}
          />
        </Grid>
      </Grid>

    </>
  )

}

function QuintoFormulario(component_props) {
  let { documento_re, fecha_expedicion_re, nombre_completo_re, fecha_nacimiento_re, lugar_nacimiento_re,
    telefono_re, direccion_re, correo_re, onChange } = component_props
  return (
    <>
      <Grid container spacing={3} style={{ marginBottom: '40px' }}>
        {/* <Grid item xs={6}>
          <TextField
            value={documento_re}
            required
            type="number"
            id="documento_re"
            name="documento_re"
            label="Documento de identidad"
            fullWidth
            onChange={onChange}
          />
        </Grid> */}



        {/* <Grid item xs={6}>
          <TextField
            value={fecha_expedicion_re}
            required
            type="text"
            id="fecha_expedicion_re"
            name="fecha_expedicion_re"
            label="Fecha de expedición"
            fullWidth
            onChange={onChange}
          />
        </Grid> */}



        <Grid item xs={6}>
          <TextField
            value={nombre_completo_re}
            required
            type="text"
            id="nombre_completo_re"
            name="nombre_completo_re"
            label="Nombre completo"
            fullWidth
            onChange={onChange}
          />
        </Grid>



        {/* <Grid item xs={6}>
          <TextField
            value={fecha_nacimiento_re}
            required
            type="text"
            id="fecha_nacimiento_re"
            name="fecha_nacimiento_re"
            label="Fecha de nacimiento"
            fullWidth
            onChange={onChange}
          />
        </Grid> */}


{/* 
        <Grid item xs={6}>
          <TextField
            value={lugar_nacimiento_re}
            required
            type="text"
            id="lugar_nacimiento_re"
            name="lugar_nacimiento_re"
            label="Lugar de nacimiento"
            fullWidth
            onChange={onChange}
          />
        </Grid> */}



        <Grid item xs={6}>
          <TextField
            value={telefono_re}
            required
            type="number"
            id="telefono_re"
            name="telefono_re"
            label="Teléfono de contacto"
            fullWidth
            onChange={onChange}
          />
        </Grid>



        <Grid item xs={6}>
          <TextField
            value={direccion_re}
            required
            type="text"
            id="direccion_re"
            name="direccion_re"
            label="Dirección"
            fullWidth
            onChange={onChange}
          />
        </Grid>



        <Grid item xs={6}>
          <TextField
            value={correo_re}
            required
            type="text"
            id="correo_re"
            name="correo_re"
            label="Correo electrónico"
            fullWidth
            onChange={onChange}
          />
        </Grid>
      </Grid>

    </>
  )

}

function SextoFormulario(component_props) {

  let { requisitos, submit } = component_props;

  const transformarDataAdj = (data) => {

    let r = [];

    data.map(req => r.push({ nombre: req.nombre, codigo: req.id, archivo: req.archivo }));

    return r;

  }

  const acciones = (data, callback) => {
    let descargar
    let cargar
    const onClickCargar = (data) => callback(data);
    const onClickDescargar = ({ archivo }) => {
      if (archivo) window.open(`${archivo}`, '_blank')
    }
    if(data.codigo === CODIGO_MODELO_LETRA_CAMBIO){
        descargar = (data.archivo) && <BtnVer callback={() => onClickDescargar(data)} color='#01579b' texto='Ver' />
    }else{
        descargar = (data.archivo) && <BtnDescargar callback={() => onClickDescargar(data)} color='#01579b' texto='Abrir' />
        cargar = <BtnCargar callback={() => onClickCargar(data)} color='#00bfa5' texto='Gestionar' />
    }
    
    return <div>{descargar} {cargar}</div>;
  }

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper elevation={0} square>
            <List component="nav" arial-label="main">
              <EnviarMultiplesArchivos
                acciones={acciones}
                enModal={false}
                enviarArchivos={(archivo) => submit(archivo)}
                limite={2}
                mostrarMensaje={false}
                enLista={true}
                lista={transformarDataAdj(requisitos)}
                formatos={true}
              />
            </List>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );

}

export class CreditoFormuDirecto extends Component {

  constructor(props) {
    super(props)
    this.state = {
      cargando: false,
      activeStep: 0,
      archivo: [],
      Valor_solicitado: '',
      CreditoPara: [],
      Valor_de_la_matricula: '',
      Programa_cre_d: [],
      documento: '',
      fecha_expedicion: '',
      nombre_completo: '',
      fecha_nacimiento: '',
      lugar_nacimiento: '',
      telefono_estu: '',
      direccion_estu: '',
      correo_estu: '',
      documento_co: '',
      fecha_expedicion_co: '',
      nombre_completo_co: '',
      fecha_nacimiento_co: '',
      lugar_nacimiento_co: '',
      telefono_co: '',
      direccion_co: '',
      correo_co: '',
      lugar_expe_documento_co: '',
      estado_civil_co: '',
      direccion_resi_co: '',
      lugar_resi_co: '',
      ocupacion_co: '',
      ciudad_empresa_co: '',
      direccion_empresa_co: '',
      telefono_empresa_co: '',
      empresa_co: '',
      ingreso_co: '',
      documento_re: '',
      fecha_expedicion_re: '',
      nombre_completo_re: '',
      fecha_nacimiento_re: '',
      lugar_nacimiento_re: '',
      telefono_re: '',
      direccion_re: '',
      correo_re: '',
      titulo_res: 'Cargando..'
    }
  }

  async componentDidMount() {
    this.setState({cargando:true})
    let{telefono_contacto, direccion_per, correo_personal, lugar_expedicion,estado_civil, lugar_residencia} = this.props
    this.setState({telefono_contacto, direccion_per, correo_personal, lugar_expedicion,estado_civil, lugar_residencia, cargando:false})
    
  }

  cambiarEstado = (estados) => {
    this.setState(estados)
  }



  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  reiniciarEstado() {
    this.setState({
      activeStep: 0,
      cargando: false,
      titulo_carg: 'Cargando...',
      Valor_solicitado: '',
      CreditoPara: [],
      Valor_de_la_matricula: '',
      Programa_cre_d: [],
      documento: '',
      fecha_expedicion: '',
      nombre_completo: '',
      fecha_nacimiento: '',
      lugar_nacimiento: '',
      telefono_estu: '',
      direccion_estu: '',
      correo_estu: '',
      documento_co: '',
      fecha_expedicion_co: '',
      nombre_completo_co: '',
      fecha_nacimiento_co: '',
      lugar_nacimiento_co: '',
      telefono_co: '',
      direccion_co: '',
      correo_co: '',
      lugar_expe_documento_co: '',
      estado_civil_co: '',
      direccion_resi_co: '',
      lugar_resi_co: '',
      ocupacion_co: '',
      ciudad_empresa_co: '',
      direccion_empresa_co: '',
      telefono_empresa_co: '',
      empresa_co: '',
      ingreso_co: '',
      documento_re: '',
      fecha_expedicion_re: '',
      nombre_completo_re: '',
      fecha_nacimiento_re: '',
      lugar_nacimiento_re: '',
      telefono_re: '',
      direccion_re: '',
      correo_re: '',
      archivo: []
    })
  }

  agregarArchivos = files => {
    let { actualizarMensaje } = this.props
    let { archivo, requisitos } = this.state
    files.map((archivo => {
      if (archivo.length === 10000) actualizarMensaje({ titulo: `Se ha superado el numero maximo de archivos ${requisitos.length}`, tipo: 'info', mostrar: true, tiempo: 6000 })
      else archivo.push(archivo)
    }))
    this.setState({ archivo })
  }

  eliminarArchivo = (i) => {
    let { actualizarMensaje } = this.props;
    let { archivo } = this.state;
    archivo.splice(i, 1)
    this.setState({ archivo })
    actualizarMensaje({ titulo: `Archivo Retirado.`, tipo: 'success', mostrar: true, tiempo: 6000 })
  }

  submit = (archivo) => {
    this.setState({ archivo })
    let { Valor_solicitado, CreditoPara, Valor_de_la_matricula, Programa_cre_d,
      documento, fecha_expedicion, nombre_completo, fecha_nacimiento, lugar_nacimiento, telefono_estu, direccion_estu, correo_estu,
      documento_co, fecha_expedicion_co, nombre_completo_co, fecha_nacimiento_co, lugar_nacimiento_co,
      telefono_co, direccion_co, correo_co, lugar_expe_documento_co, estado_civil_co, direccion_resi_co, lugar_resi_co, ocupacion_co,
      ciudad_empresa_co, direccion_empresa_co, telefono_empresa_co, empresa_co, ingreso_co,
      documento_re, fecha_expedicion_re, nombre_completo_re, fecha_nacimiento_re, lugar_nacimiento_re, telefono_re, direccion_re, correo_re } = this.state;
    let { actualizarMensaje, mostrarModalFormuDirecto, requisitos,telefono_contacto, direccion_per, correo_personal, lugar_expedicion, estado_civil, lugar_residencia} = this.props;
    this.setState({ cargando: true, titulo_res: 'Enviando..' })
    let total = LONGITUD_REQUISITOS;
    if (archivo.length != total) {
      actualizarMensaje({ titulo: 'Debe adjuntar un archivo por cada requisito', tipo: 'info', mostrar: true, tiempo: 6000 });
      this.setState({ cargando: false})
    } else {
      this.setState({ cargando: true, titulo_carg: 'Enviando...' })
      this.setState({
        Valor_solicitado, CreditoPara, Valor_de_la_matricula, Programa_cre_d,
        telefono_contacto, direccion_per, correo_personal, lugar_expedicion, estado_civil, lugar_residencia,
        documento, fecha_expedicion, nombre_completo, fecha_nacimiento, lugar_nacimiento, telefono_estu, direccion_estu, correo_estu,
        documento_co, fecha_expedicion_co, nombre_completo_co, fecha_nacimiento_co, lugar_nacimiento_co,
        telefono_co, direccion_co, correo_co, lugar_expe_documento_co, estado_civil_co, direccion_resi_co, lugar_resi_co, ocupacion_co,
        ciudad_empresa_co, direccion_empresa_co, telefono_empresa_co, empresa_co, ingreso_co,
        documento_re, fecha_expedicion_re, nombre_completo_re, fecha_nacimiento_re, lugar_nacimiento_re, telefono_re, direccion_re, correo_re
      })

      let data = {
        // formulario 1
        Valor_solicitado: Valor_solicitado,
        CreditoPara: CreditoPara.nombre,
        Valor_de_la_matricula: Valor_de_la_matricula,
        Programa_cre_d: Programa_cre_d.id,
        // formulario 2
        telefono_contacto,
        direccion_per,
        correo_personal,
        lugar_expedicion,
        estado_civil,
        lugar_residencia,
        // formulario 3
        documento: documento,
        fecha_expedicion: fecha_expedicion,
        nombre_completo: nombre_completo,
        fecha_nacimiento: fecha_nacimiento,
        lugar_nacimiento: lugar_nacimiento,
        telefono_estu: telefono_estu,
        direccion_estu: direccion_estu,
        correo_estu: correo_estu,
        // formulario 4
        documento_co: documento_co,
        fecha_expedicion_co: fecha_expedicion_co,
        nombre_completo_co: nombre_completo_co,
        fecha_nacimiento_co: fecha_nacimiento_co,
        lugar_nacimiento_co: lugar_nacimiento_co,
        telefono_co: telefono_co,
        direccion_co: direccion_co,
        correo_co: correo_co,
        lugar_expe_documento_co: lugar_expe_documento_co,
        estado_civil_co: estado_civil_co,
        direccion_resi_co: direccion_resi_co,
        lugar_resi_co: lugar_resi_co,
        ocupacion_co: ocupacion_co.nombre,
        ciudad_empresa_co: ciudad_empresa_co,
        direccion_empresa_co: direccion_empresa_co,
        telefono_empresa_co: telefono_empresa_co,
        empresa_co: empresa_co,
        ingreso_co: ingreso_co,
        // formulario 5
        documento_re: documento_re,
        fecha_expedicion_re: fecha_expedicion_re,
        nombre_completo_re: nombre_completo_re,
        fecha_nacimiento_re: fecha_nacimiento_re,
        lugar_nacimiento_re: lugar_nacimiento_re,
        telefono_re: telefono_re,
        direccion_re: direccion_re,
        correo_re: correo_re,
        //formulario 6
        archivo: archivo
      }
      this.props.guardarCreditodirecto(data, () => this.reiniciarEstado())
    }
  }

  
  handleStep = async (direction) => {//Con esta funcion Valido si se llenaron los campos
    let { activeStep, Valor_solicitado, CreditoPara, Valor_de_la_matricula, Programa_cre_d,
      documento, fecha_expedicion, nombre_completo, fecha_nacimiento, lugar_nacimiento,
      telefono_estu, direccion_estu, correo_estu,
      documento_co, fecha_expedicion_co, nombre_completo_co, fecha_nacimiento_co, lugar_nacimiento_co,
      telefono_co, direccion_co, correo_co, lugar_expe_documento_co, estado_civil_co, direccion_resi_co, lugar_resi_co,
      ocupacion_co, ciudad_empresa_co, direccion_empresa_co, telefono_empresa_co, empresa_co, ingreso_co,
      documento_re, fecha_expedicion_re, nombre_completo_re, fecha_nacimiento_re, lugar_nacimiento_re,
      telefono_re, direccion_re, correo_re} = this.state
    let { actualizarMensaje,telefono_contacto, direccion_per, correo_personal, lugar_expedicion, estado_civil, lugar_residencia } = this.props
    let sw = false
    if (direction) {
      if (activeStep === 0 && Valor_solicitado && CreditoPara && Valor_de_la_matricula && Programa_cre_d) {
        sw = true
      } else if (activeStep === 1 && telefono_contacto && direccion_per && correo_personal && lugar_expedicion && estado_civil && lugar_residencia) {
        sw = true
      // } else if (activeStep === 2 && documento && fecha_expedicion && nombre_completo && fecha_nacimiento && lugar_nacimiento && telefono_estu && direccion_estu && correo_estu) {
      } else if (activeStep === 2 && nombre_completo && telefono_estu && direccion_estu && correo_estu) {
        sw = true
      } else if (activeStep === 3 && documento_co && fecha_expedicion_co && nombre_completo_co && fecha_nacimiento_co && lugar_nacimiento_co && telefono_co && direccion_co && correo_co && lugar_expe_documento_co && estado_civil_co && direccion_resi_co && lugar_resi_co && ocupacion_co && ciudad_empresa_co && direccion_empresa_co && telefono_empresa_co && empresa_co && ingreso_co) {
        sw = true
      // } else if (activeStep === 4 && documento_re && fecha_expedicion_re && nombre_completo_re && fecha_nacimiento_re && lugar_nacimiento_re && telefono_re && direccion_re && correo_re) {
      } else if (activeStep === 4 && nombre_completo_re && telefono_re && direccion_re && correo_re) {
        sw = true
      } else {
        actualizarMensaje({ titulo: 'Debe diligenciar todos los campos para continuar', tipo: 'info', mostrar: true, tiempo: 6000 });
      }
      if (sw) {
        this.setState({ activeStep: direction ? activeStep + 1 : activeStep - 1 })
      }
    } else {
      this.setState({ activeStep: direction ? activeStep + 1 : activeStep - 1 })
    }

  }

  configStepContent = () => {
    let { Valor_solicitado, activeStep, Programa_cre_d, CreditoPara, ocupacion_co, Valor_de_la_matricula,
      documento, fecha_expedicion, nombre_completo, fecha_nacimiento, lugar_nacimiento,
      telefono_estu, direccion_estu, correo_estu, documento_co, fecha_expedicion_co, nombre_completo_co,
      fecha_nacimiento_co, lugar_nacimiento_co, telefono_co, direccion_co, correo_co, lugar_expe_documento_co,
      estado_civil_co, direccion_resi_co, lugar_resi_co, ciudad_empresa_co, direccion_empresa_co, telefono_empresa_co,
      empresa_co, ingreso_co, documento_re, fecha_expedicion_re, nombre_completo_re, fecha_nacimiento_re, lugar_nacimiento_re,
      telefono_re, direccion_re, correo_re, archivo} = this.state
    let { credito_para, programas, Ocupacion,requisitos,actualizarMensaje,telefono_contacto,direccion_per,correo_personal,lugar_expedicion,estado_civil,lugar_residencia,onChange  } = this.props
    switch (activeStep) {
      case 0:
        return (
          <PrimerFormulario
            {...{
              Valor_solicitado,
              Valor_de_la_matricula,
              Programa_cre_d,
              CreditoPara,
              onChange: this.onChange,
              credito_para,
              programas
            }}
          />
        )
      case 1:
        return (
          <SegundoFormulario
            {...{
              telefono_contacto,
              direccion_per,
              correo_personal,
              lugar_expedicion,
              estado_civil,
              lugar_residencia,
              onChange
            }} />
        )
      case 2:
        return (
          <TercerFormulario
            {...{
              documento,
              fecha_expedicion,
              nombre_completo,
              fecha_nacimiento,
              lugar_nacimiento,
              telefono_estu,
              direccion_estu,
              correo_estu,
              onChange: this.onChange,
            }} />
        )

      case 3:
        return (
          <CuartoFormulario
            {...{
              documento_co,
              fecha_expedicion_co,
              nombre_completo_co,
              fecha_nacimiento_co,
              lugar_nacimiento_co,
              telefono_co,
              direccion_co,
              correo_co,
              lugar_expe_documento_co,
              estado_civil_co,
              direccion_resi_co,
              lugar_resi_co,
              ocupacion_co,
              ciudad_empresa_co,
              direccion_empresa_co,
              telefono_empresa_co, empresa_co,
              ingreso_co,
              Ocupacion,
              onChange: this.onChange
            }} />
        )

      case 4:
        return (
          <QuintoFormulario
            {...{
              documento_re,
              fecha_expedicion_re,
              nombre_completo_re,
              fecha_nacimiento_re,
              lugar_nacimiento_re,
              telefono_re,
              direccion_re,
              correo_re,
              onChange: this.onChange
            }} />
        )

      case 5:
        return (
          <SextoFormulario
            {...{
              requisitos,
              archivo,
              actualizarMensaje,
              eliminarArchivo: this.eliminarArchivo,
              agregarArchivos: this.agregarArchivos,
              submit: this.submit
            }} />
        )

      default:
        return <TareasTerminadas imagen={emma_w} mensaje='Est bandeja esta  vacía' widthImg='10%' marginTop='10%' />
    }
  }

  render() {
    let { cargando, activeStep, titulo_carg } = this.state;
    let { ModalFormuDirecto, mostrarModalFormuDirecto } = this.props;

    return (
      <>
        <div>

          <Dialog fullWidth={true} maxWidth="md" open={ModalFormuDirecto} onClose={() => mostrarModalFormuDirecto(false)}>
            <AppBarModal titulo={"¡Credito directo !"} mostrarModal={() => mostrarModalFormuDirecto(false)} titulo_accion="Cerrar" accion={() => mostrarModalFormuDirecto(false)} />
            <DialogContent className='scroll'>
              {!cargando ?
                <Grid container>
                  <Grid item xs={12} md={12} >
                    <Stepper activeStep={activeStep}>
                      {
                        configStep.map(label => (
                          <Step key={label}>
                            <StepLabel><p>{label}</p></StepLabel>
                          </Step>
                        ))
                      }
                    </Stepper>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {this.configStepContent()}
                  </Grid>
                </Grid>
                : <TareasTerminadas mensaje={titulo_carg} marginTop='7%' imagen={emma_w} widthImg="7%" cargando={true}/>
              }

            </DialogContent>
            <DialogActions>
              {activeStep === 0 &&
                <div>
                  <BtnFormPage texto='CERRAR' callback={() => mostrarModalFormuDirecto(false)} />
                  <BtnFormPage texto='SIGUIENTE' callback={() => this.handleStep(true)} />
                </div>
              }
              {
                (activeStep === 1 || activeStep === 2 || activeStep === 3 || activeStep === 4) &&
                <div>
                  <BtnFormPage texto='ATRAS' callback={() => this.handleStep(false)} />
                  <BtnFormPage texto='SIGUIENTE' callback={() => this.handleStep(true)} />
                </div>
              }
              {
                (activeStep === 5) &&
                <div>
                  <BtnFormPage texto='ATRAS' callback={() => this.handleStep(false)} />
                  <BtnFormPage texto='GUARDAR' callback={() => { document.getElementById('enviar_form_multi_archivo').click() }} />
                </div>
              }
            </DialogActions>

          </Dialog >
        </div>
        <div>
        </div>

      </>
    )
  }
}

const mapStateToProps = (state) => {
  let { ModalFormuDirecto } = state.redCreditos
  let { usuario } = state.redGlobal;
  return {
    ModalFormuDirecto,
    usuario
  }
}

const mapDispatchToProps = {
  mostrarModulo,
  mostrarModalComment,
  actualizarMensaje,
  mostrarModalFormuDirecto
}

CreditoFormuDirecto.propTypes = {
  //funciones
  mostrarModulo: PropTypes.func.isRequired,
  mostrarModalComment: PropTypes.func.isRequired,
  actualizarMensaje: PropTypes.func.isRequired,
  mostrarModalFormuDirecto: PropTypes.func.isRequired,
  //variables
  modalAddComment: PropTypes.bool.isRequired,
  ModalFormuDirecto: PropTypes.bool.isRequired,
  programas: PropTypes.array.isRequired,
  credito_para: PropTypes.array.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreditoFormuDirecto);