import React from 'react'
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import Generica from "../personas/generica/Generica";
import AppBarModal from "../general/AppBarModal";
import { BtnDetalle, BtnForm } from "../general/BotonesAccion";
import ListarDatos from "../general/ListarDatos";
import TareasTerminadas from "../general/TareasTerminadas";
import emma_w from '../../global/imagenes/emma_w.png';
import { consulta, rutaImg } from "../../global/js/funciones";


class ReportePermisos extends React.Component {
   constructor(props) {
      super(props)
      this.state = {
         cargando: true,
         personas: [],
         generica: {
            tipo: 0,
            id: 0,
         },
         modal: false,
         id_persona: null,
      }
   }

   async componentDidMount() {
      let personas = await this.obtenerGestores();
      this.setState({ personas, cargando: false })
   }

   acciones(data) {
      const onClickTipo = (data) => this.setState({ id_persona: data.id, modal: true, generica: { tipo: 17, id: 56 } }); //ID
      let tipos = <BtnDetalle callback={() => onClickTipo(data)} texto='Tipos' />;
      return <div>{tipos}</div>
   }

   async obtenerGestores() {
      return new Promise(resolve => {
         consulta(`api/v1.0/reportes/gestores`, null, null, (error, estado, resp) => {
            resolve(estado === 200 && !error ? resp : null);
         })
      })
   }

   render() {
      let { modal, id_persona, generica, cargando, personas } = this.state;
      if (cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" cargando={true}/>;
      return (
         <div>
            <ListarDatos
               datos={personas}
               titulo='Lista Gestores'
               buscar={true}
               agregar={false}
               opciones={true}
               descargar='EMMA - GESTORES'
               id='tbl_coordinadores'
               acciones={row => this.acciones(row)}
               avatarImg={true}
               avatar={({ identificacion }) => `${rutaImg}${identificacion}.jpg`}
               fila_principal={({ primer_nombre, primer_apellido, segundo_apellido }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`}
               filas={[
                  { 'id': 'correo' },
                  { 'id': 'identificacion' },
               ]}
            />

            <Dialog open={modal} fullWidth={true} maxWidth='sm' onClose={() => this.setState({ modal: false })}>
               <AppBarModal titulo='¡ Lista Permisos !' mostrarModal={() => this.setState({ modal: false })} accion={() => this.setState({ modal: false })} titulo_accion='CERRAR' />
               <DialogContent style={{ padding: 0 }} className='scroll'>
                  <Generica tipo={generica.tipo} persona={{ id: id_persona }} genericaRelacionId={generica.id} titulo="¡ Nuevo Permiso !" tituloInput="Permisos" tituloEliminar='¿ Eliminar Permiso ?' />
               </DialogContent>
               <DialogActions>
                  <BtnForm texto="Cerrar" callback={() => this.setState({ modal: false })} />
               </DialogActions>
            </Dialog>
         </div>
      );
   }
}

export default ReportePermisos;


