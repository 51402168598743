import { Button, Checkbox, FormControl, FormControlLabel, FormLabel, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
//Material
import DateFnsUtils from '@date-io/date-fns';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
//icons
import PersonAddIcon from '@material-ui/icons/PersonAdd';
//Componentes
import AlertasSimple from '../../general/AlertasSimple';
import AppBarModal from '../../general/AppBarModal';
import { BtnCancelar, BtnForm } from "../../general/BotonesAccion";
import BuscarPersona from '../../general/BuscarPersona';
import ConfirmarAccion from '../../general/ConfirmarAccion';
import EnviarMultiplesArchivos from '../../general/EnviarMultiplesArchivos';
import ListarDatos from '../../general/ListarDatos';
import TareasTerminadas from '../../general/TareasTerminadas';
import PersonasBuscarGrado from '../PersonasBuscarGrado';
import PersonaExternaBuscar from './PersonaExternaBuscar';
//imagenges
import emma_w from '../../../global/imagenes/emma_w.png';
//funciones
import { consulta, generarFiltros, obtenerEstadosProceso, rutaImg } from '../../../global/js/funciones';
//Utils
import { es } from 'date-fns/locale';
import moment from 'moment';
import { ESTADOS_GRADOS } from "./../helper";
import { coloresEmma } from '../../../global/js/funciones';


const useStyles = makeStyles(theme => ({
  root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
  },
}));


function RenderEstados({ estados_siguiente, estado, cambiarSeleccion }) {
  const classes = useStyles();
  return (
      <List className={classes.root}>
          {estados_siguiente.map(({ secundario }) => {
              let { id, codigo, nombre, descripcion } = secundario;
              return (
                  <Tooltip title={descripcion} placement="left" key={id}>
                      <ListItem key={codigo} role={undefined} dense button onClick={() => cambiarSeleccion(codigo)}>
                          <ListItemIcon>
                              <Checkbox edge="start" checked={estado === codigo} tabIndex={-1} disableRipple />
                          </ListItemIcon>
                          <ListItemText id={codigo} primary={nombre} />
                      </ListItem>
                  </Tooltip>
              );
          })}
      </List>
  );
}

class GradosGestionar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      estado: '',
      mensaje: '',
      nota: '',
      condecoracion: 0,
      coordinador: { id: "", nombre_completo: 'Clic aquí para buscar un coordinador', },
      tutor: { id: "", nombre_completo: 'Clic aquí para buscar un tutor', },
      cotutor: { id: "", nombre_completo: 'Clic aquí para buscar un cotutor', },
      tipo_tutor: 'tutor',
      tipo_evalaudor: 'interno',
      evaluadores: [],
      fecha_sustentacion: null,
      hora_inicio: null,
      hora_fin: null,
      tipo_sustentacion: "virtual",
      lugar_sustentacion: "",
      modalEvaluador: false,
      mondaConfirm: false,
      actasFaltantes: [],
      archivos: [],
      estados_siguiente: [],
      cargando: false,
      noAprob: false,
      solicitudFechaSustentacion: null,
    }
  }

  async componentDidUpdate({ modalGestionarSolicitud }, { nota,estado, noAprob }) {
    let { modalGestionarSolicitud: modalGestionarSolicitudNew } = this.props
    let { nota: notaNew,estado:estadoNew } = this.state
    let { solicitud } = this.props;

    if (modalGestionarSolicitud !== modalGestionarSolicitudNew) {
      if (modalGestionarSolicitudNew) {
        this.setState({ cargando: true })
        let adjuntos = await this.getAdjFaltantes(solicitud.id)
        let estados_siguientes = await obtenerEstadosProceso(solicitud.tipo.id, [{ 'llave': 'principal', 'valor': solicitud.estado_actual.id }, { 'llave': 'secundario__generica', 'valor': ESTADOS_GRADOS }]);
        let historialEstados= await this.getEstadosSolicitud(solicitud.id)
        const noAprob = historialEstados.find(objeto => objeto.tipo_estado.codigo === "no_aprob");
        if (noAprob) {
          this.setState({ noAprob: true })
          estados_siguientes.forEach((objeto, indice) => {
            if (objeto.secundario.codigo === noAprob.tipo_estado.codigo) {
              estados_siguientes.splice(indice, 1);
            }
          });
        }
        this.trasnformarDataAdj(adjuntos)        
        this.changeState({
          estado: "",
          mensaje: "",
          coordinador: { id: "", nombre_completo: 'Clic aquí para buscar un coordinador', },
          tutor: { id: "", nombre_completo: 'Clic aquí para buscar un tutor', },
          cotutor: { id: "", nombre_completo: 'Clic aquí para buscar un cotutor', },
          tipo_tutor: 'tutor',
          tipo_evalaudor: 'interno',
          evaluadores: [],
          fecha_sustentacion: null,
          hora_inicio: null,
          hora_fin: null,
          tipo_sustentacion: "virtual",
          lugar_sustentacion: "",
          nota: '',
          condecoracion: 0,
          estados_siguiente: estados_siguientes,
          cargando: false,
        });
      }
    }
    if (estado !== estadoNew) {
      this.setState({ cargando: true })
      let adjuntos = await this.getAdjFaltantes(solicitud.id)
      this.trasnformarDataAdj(adjuntos)        
      this.setState({ cargando: false })
      
      if(estadoNew === "apl_tr" ){
        this.setState({cargando: true, actasFaltantes: []})


        let info= await this.ObtenerEstadoDesarrollo(solicitud.id)
        if (info){
          let evaluadores_esquema = [];
          info.evaluadores.map((evaluador) => {
            evaluadores_esquema.push({
              "id": evaluador.persona_interna
                ? evaluador.persona_interna.id
                : evaluador.persona_externa.id,
              "nombre_completo": evaluador.persona_interna
                ? `${evaluador.persona_interna.primer_nombre} ${evaluador.persona_interna.primer_apellido}`
                : `${evaluador.persona_externa.primer_nombre} ${evaluador.persona_externa.primer_apellido}`,
              "tipo": evaluador.tipo,
            });
          });
          

          const horaString = info.estado.hora_inicio;
          const horaArray = horaString.split(":");
          const horaDate_inicio = new Date();
          horaDate_inicio.setHours(parseInt(horaArray[0]), parseInt(horaArray[1]), parseInt(horaArray[2]));
          const horaString1 = info.estado.hora_fin;
          const horaArray1 = horaString1.split(":");
          const horaDate1_fin = new Date();
          horaDate1_fin.setHours(parseInt(horaArray1[0]), parseInt(horaArray1[1]), parseInt(horaArray1[2]));
          console.log(info.estado.fecha_sustentacion)
          this.setState({fecha_sustentacion: moment(info.estado.fecha_sustentacion).format('ddd MMM DD YYYY HH:mm:ss [GMT]Z (z)') ,hora_fin:horaDate1_fin,hora_inicio:horaDate_inicio,tipo_sustentacion:info.estado.lugar_sustentacion?"presencial":"virtual",evaluadores:evaluadores_esquema})
          if (noAprob) {
              this.setState({ solicitudFechaSustentacion: info.estado.fecha_sustentacion })
          }
        }
        this.setState({cargando: false})
      }
      // else if (estadoNew === 'gr_est_fin' || estadoNew === 'apr_obv') this.setState({ actasFaltantes: [] })
      else if (estadoNew === 'tr_est_sus_tr' && noAprob) {
        this.setState({cargando: true})
        let info = await this.getEstadosSolicitudFiltro(solicitud.id)
        if (info) {
          this.setState({ solicitudFechaSustentacion: info[0].fecha_sustentacion })
        }
        this.setState({cargando: false})
      }

      if (estadoNew === 'gr_est_rec') this.setState({actasFaltantes: []})

    }
    if (nota !== notaNew) this.changeState({ condecoracion: 0 })
  }
  async ObtenerEstadoDesarrollo(id_grado) { 
    return new Promise(resolve => {
      consulta(`api/v1.0/grados/${id_grado}/estados/filtro`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  async getEstadosSolicitud(grado_id) { 
    return new Promise(resolve => {
      consulta(`api/v1.0/grados/${grado_id}/estados`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  async getEstadosSolicitudFiltro(id) { 
    let f = await generarFiltros([{'llave': 'grado__id', 'valor': id}, { 'llave': 'grado__estado_anterior', 'valor': 'tr_est_sus_tr' }, {'llave': 'grado__estado_actual__codigo', 'valor': 'tr_est_des_tr'}, {'llave' : 'tipo_estado__codigo', 'valor': 'tr_est_sus_tr'}]);
    return new Promise(resolve => {
      consulta(`api/v1.0/grados/estados/filtro?${f}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }
  
  // Handlers y utils
  trasnformarDataAdj = (data) => {
    let actasFaltantes = [];
    data.map(({ secundario: { nombre, descripcion, id, archivo } }) => {
      actasFaltantes.push({ nombre, descripcion, codigo: id, archivo });
      return true;
    })
    this.setState({ actasFaltantes });
  }

  changeState = (estados) => {
    this.setState(estados)
  }

  onClickbuscarTutorCotutor = (tipo_tutor) => {
    this.setState({ tipo_tutor })
    this.props.showModalNewGestor(true)
  }

  onClickbuscarEvaluador = (tipo_evalaudor) => {
    this.setState({ tipo_evalaudor })
    this.setState({ modalEvaluador: true })
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  setFechaSustentacion = (fecha_sustentacion) => {
    this.setState({ fecha_sustentacion })
  }

  pintarNombresPersonas = (personas, tipo = "persona") => {
    if (personas.length == 0) return `Clic aquí para buscar ${tipo}`
    let resp = [];
    resp = personas.map(e => e.nombre_completo)
    return resp.join(", ")
  }

  // Submit

  onSubmit = (archivos2) => {
    let { solicitud, gestionarGrado, actualizarMensaje } = this.props;
    let { estado, mensaje, coordinador, tutor, cotutor, fecha_sustentacion, hora_inicio, hora_fin, tipo_sustentacion, lugar_sustentacion, evaluadores, nota, condecoracion, archivos, actasFaltantes, noAprob, solicitudFechaSustentacion } = this.state;
    if (archivos2.length >= actasFaltantes.length) {
      let errores = false
      this.setState({ archivos: archivos2 })
      if (noAprob && solicitudFechaSustentacion) {
        const fecha1 = moment(fecha_sustentacion).startOf('day').toDate();
        const fecha2 = moment(solicitudFechaSustentacion).startOf('day').toDate();
        const diferenciaMs = Math.abs(fecha1.getTime() - fecha2.getTime());
        const diferenciaDias = Math.ceil(diferenciaMs / 86400000);
        if (diferenciaDias >= 7) {
          errores = true
        }
      } 
      fecha_sustentacion = fecha_sustentacion ? moment(fecha_sustentacion).format('YYYY-MM-DD') : '';
      hora_inicio = hora_inicio ? moment(hora_inicio).format('HH:mm:ss') : '';
      hora_fin = hora_fin ? moment(hora_fin).format('HH:mm:ss') : '';
      if (errores) {
        actualizarMensaje({ titulo: 'La nueva fecha de sustentación no debe superar los 7 días.', tipo: 'info', mostrar: true, tiempo: 2000 });
        errores= false
      } else {
        gestionarGrado(solicitud.id, estado, mensaje, coordinador.id, tutor.id, cotutor.id, fecha_sustentacion, hora_inicio, hora_fin, tipo_sustentacion, lugar_sustentacion, evaluadores, nota, condecoracion, archivos2);
      }
    } else actualizarMensaje({ titulo: 'Debe adjuntar un archivo por cada requisito', tipo: 'info', mostrar: true, tiempo: 2000 });
  }

  getAdjFaltantes = (id_solicitud) => {
    return new Promise(resolve => {
      consulta(`api/v1.0/grados/${id_solicitud}/adjuntos/faltantes/3`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);

      })
    })
  }

  gestionarGestores = (estado, data) => {
    let { showModalNewGestor } = this.props;
    let { tipo_tutor } = this.state
    let { id, primer_nombre, primer_apellido, segundo_apellido } = data
    let nombre_completo = `${primer_nombre} ${primer_apellido} ${segundo_apellido}`
    if (estado == 'tr_est_coor') {
      this.changeState({ coordinador: { id, nombre_completo } })
    }
    else if (estado === 'tr_est_des_tr') {
      if (tipo_tutor == "tutor") this.changeState({ tutor: { id, nombre_completo } })
      if (tipo_tutor == "cotutor") this.changeState({ cotutor: { id, nombre_completo } })
    }
    showModalNewGestor(false)
  }

  gestionarEvaluadores = (estado, data) => {
    let { tipo_evalaudor, evaluadores } = this.state
    let { id, primer_nombre, primer_apellido, segundo_apellido, identificacion } = data
    let nombre_completo = `${primer_nombre} ${primer_apellido} ${segundo_apellido}`
    let array = evaluadores.filter(e => e.id != id)

    if (evaluadores.length + 1 > 3) return this.props.actualizarMensaje({ titulo: "Solo se permiten 3 evaluadores como máximo", tipo: 'info', mostrar: true, tiempo: 3000 });
    if (array.length < evaluadores.length) return this.props.actualizarMensaje({ titulo: 'Esta persona ya fue seleccionada', tipo: 'info', mostrar: true, tiempo: 3000 });

    tipo_evalaudor == "interno" && array.push({ id, nombre_completo, tipo: 1, identificacion })
    tipo_evalaudor == "externo" && array.push({ id, nombre_completo: data.nombre, tipo: 2 })

    this.changeState({ evaluadores: array })
    this.props.actualizarMensaje({ titulo: 'Persona seleccionada', tipo: 'success', mostrar: true, tiempo: 2000 });
  }

  renderInputs = (estado) => {
    let { solicitud, solicitud: tipo, showModalNewGestor } = this.props
    let { coordinador, tutor, cotutor, evaluadores, fecha_sustentacion, hora_inicio, hora_fin, tipo_sustentacion, lugar_sustentacion, nota, condecoracion, noAprob } = this.state;
    let propuestos = [{ id: 1, nombre: solicitud.tutor_propuesto, rol: "Tutor Propuesto" }, { id: 2, nombre: solicitud.cotutor_propuesto, rol: "Cotutor Propuesto" }]

    if (estado == 'tr_est_coor') {
      return (
        <Grid container spacing={1} alignItems="flex-end" onClick={() => showModalNewGestor(true)} className='pointer' style={{ marginBottom: '10px' }}>
          <Grid item xs={11} xl={11} sm={11} md={11} lg={11}>
            <TextField label="Coordinador" disabled={true} type="text" value={coordinador.nombre_completo} style={{ width: '100%' }} />
          </Grid>
          <Grid item xs={1} xl={1} sm={1} md={1} lg={1}>
            <SearchIcon style={{ color: coloresEmma.serchover }} />
          </Grid>
        </Grid>
      )
    } else if (estado == 'tr_est_des_tr' && solicitud.tiene_tutores == "0") {
      return (
        <div>
          {(solicitud.tutor_propuesto || solicitud.cotutor_propuesto) &&
            <Grid container spacing={1} alignItems="flex-end" style={{ marginBottom: '15px' }}>
              <Grid item xs={11} xl={11} sm={11} md={11} lg={11}>
                <TableContainer >
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Sugerencias del estudiante</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {solicitud.tutor_propuesto &&
                        <TableRow>
                          <TableCell align="left">{solicitud.tutor_propuesto}</TableCell>
                          <TableCell align="left">Tutor Propuesto</TableCell>
                        </TableRow>}
                      {solicitud.cotutor_propuesto &&
                        <TableRow >
                          <TableCell align="left">{solicitud.cotutor_propuesto}</TableCell>
                          <TableCell align="left">Cotutor Propuesto</TableCell>
                        </TableRow>}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* <ListarDatos
                  datos={propuestos}
                  // avatar={true}
                  avatar={({ nombre }) => nombre.charAt(0)}
                  opciones={true}
                  buscar={false}
                  titulo="Tutores propuestos por el estudiante"
                  fila_principal={({ rol }) => rol.toUpperCase()}
                  filas={[
                    { 'mostrar': ({ nombre }) => nombre.toUpperCase()},
                  ]}
                /> */}
              </Grid>
            </Grid>}
            {(solicitud.tutor_propuesto2 || solicitud.cotutor_propuesto2) &&
            <Grid container spacing={1} alignItems="flex-end" style={{ marginBottom: '15px' }}>
              <Grid item xs={11} xl={11} sm={11} md={11} lg={11}>
                <TableContainer >
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Sugerencias del Coordinador</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {solicitud.tutor_propuesto2 &&
                        <TableRow>
                          <TableCell align="left">{solicitud.tutor_propuesto2}</TableCell>
                          <TableCell align="left">Tutor Propuesto</TableCell>
                        </TableRow>}
                      {solicitud.cotutor_propuesto2 &&
                        <TableRow >
                          <TableCell align="left">{solicitud.cotutor_propuesto2}</TableCell>
                          <TableCell align="left">Cotutor Propuesto</TableCell>
                        </TableRow>}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* <ListarDatos
                  datos={propuestos}
                  // avatar={true}
                  avatar={({ nombre }) => nombre.charAt(0)}
                  opciones={true}
                  buscar={false}
                  titulo="Tutores propuestos por el estudiante"
                  fila_principal={({ rol }) => rol.toUpperCase()}
                  filas={[
                    { 'mostrar': ({ nombre }) => nombre.toUpperCase()},
                  ]}
                /> */}
              </Grid>
            </Grid>}
          <Grid container spacing={1} alignItems="flex-end" onClick={() => this.onClickbuscarTutorCotutor("tutor")} className='pointer' style={{ marginBottom: '10px' }}>
            <Grid item xs={11} xl={11} sm={11} md={11} lg={11}>
              <TextField label="Tutor" disabled={true} type="text" value={tutor.nombre_completo} style={{ width: '100%' }} />
            </Grid>
            <Grid item xs={1} xl={1} sm={1} md={1} lg={1}>
              <SearchIcon style={{ color: coloresEmma.serchover }} />
            </Grid>
          </Grid>
          <Grid container spacing={1} alignItems="flex-end" onClick={() => this.onClickbuscarTutorCotutor("cotutor")} className='pointer' style={{ marginBottom: '5px' }}>
            <Grid item xs={11} xl={11} sm={11} md={11} lg={11}>
              <TextField label="Cotutor" disabled={true} type="text" value={cotutor.nombre_completo} style={{ width: '100%' }} />
            </Grid>
            <Grid item xs={1} xl={1} sm={1} md={1} lg={1}>
              <SearchIcon style={{ color:  coloresEmma.serchover }} />
            </Grid>
          </Grid>
        </div>
      )
      //ESTOY TRABAJANDO AQUI
    } else if (estado == "tr_est_sus_tr" || estado == "tr_est_sus_tr" || estado == "gr_est_sus"|| estado == "apl_tr" ) {
      return (
        <div>
          <Grid container spacing={1} alignItems="flex-end" className='pointer' style={{ marginBottom: '10px' }}>
            <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
              {/* <TextField label="Evaluadores" disabled={true} type="text" value={this.pintarNombresPersonas(evaluadores, "evaluadores")} style={{ width: '100%' }} /> */}
              <Button variant="contained" color="primary" size="small" onClick={() => this.onClickbuscarEvaluador("interno")} style={{ marginBottom: "8px" }}>
                <PersonAddIcon style={{ color: '#FFFFFF', marginRight: "3px" }} />
                <span style={{ color: '#FFFFFF' }}>Agregar Jurado Evaluador</span>
              </Button>
              {evaluadores.length > 0 &&
                <ListarDatos
                  datos={evaluadores}
                  avatarImg={true}
                  avatar={({ identificacion }) => `${rutaImg}${identificacion}.jpg`}
                  // opciones={true}
                  buscar={false}
                  // titulo="Evaluadores"
                  acciones={(row) => {
                    return <BtnCancelar texto='Quitar' callback={() => this.setState({ evaluadores: evaluadores.filter(e => e.id != row.id) })} />
                  }}
                  fila_principal={({ nombre_completo }) => nombre_completo.toUpperCase()}
                  filas={[
                    { 'mostrar': ({ tipo }) => tipo == 1 ? "Jurado Evaluador Interno" : "Jurado Evaluador Externo" },
                  ]}
                />}
            </Grid>
            {/* <Grid item xs={1} xl={1} sm={1} md={1} lg={1}>
              <SearchIcon style={{ color: '#757575' }} />
            </Grid> */}
          </Grid>
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
              <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils} >
                <Grid container>
                  <KeyboardDatePicker
                    required={true}
                    style={{ marginTop: 3, width: '100%' }}
                    minDate={ noAprob ? moment(this.state.solicitudFechaSustentacion).startOf('day').toDate()  : Date("YYYY-MM-dd")}
                    margin="normal"
                    label="Fecha sustentación"
                    format="yyyy-MM-dd"
                    value={fecha_sustentacion}
                    onChange={this.setFechaSustentacion}
                    KeyboardButtonProps={{ 'aria-label': 'change date', }}
                    minDateMessage={`La fecha debe ser mayor que la fecha actual.`}
                    invalidDateMessage='Formato no valido'
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} >
                <Grid container>
                  <KeyboardTimePicker
                    required={true}
                    style={{ marginTop: 3, width: '100%' }}
                    margin="normal"
                    label="Hora Inicio"
                    value={hora_inicio}
                    onChange={(hora) => this.setState({ hora_inicio: hora })}
                    KeyboardButtonProps={{ 'aria-label': 'change date', }}
                    invalidDateMessage='Formato no valido'
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} >
                <Grid container >
                  <KeyboardTimePicker
                    required={true}
                    style={{ marginTop: 1, width: '100%' }}
                    margin="normal"
                    label="Hora Fin"
                    value={hora_fin}
                    onChange={(hora) => this.setState({ hora_fin: hora })}
                    KeyboardButtonProps={{ 'aria-label': 'change date', }}
                    invalidDateMessage='Formato no valido'
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            {noAprob && estado === 'tr_est_sus_tr' && 
              <AlertasSimple tipo='warnig' titulo='Atención' sub_titulo={"Recuerda que la nueva fecha de sustentación no debe superar los 7 días calendario a la fecha de la última defensa."} />
            }
            <Grid item xs={12} xl={12} md={12}>
              <RadioGroup name={tipo_sustentacion} value={tipo_sustentacion} row >
                <FormControlLabel onClick={() => this.setState({ tipo_sustentacion: "virtual", lugar_sustentacion: "" })} value="virtual" control={<Radio />} label="Defensa Virtual" size="small" />
                <FormControlLabel onClick={() => this.setState({ tipo_sustentacion: "presencial" })} value="presencial" control={<Radio />} label="Defensa Presencial" size="small" />
              </RadioGroup>
              {tipo_sustentacion === "presencial" &&
                <TextField required multiline value={lugar_sustentacion} onChange={this.onChange} name="lugar_sustentacion" id="lugar_sustentacion" label="Lugar" fullWidth placeholder="Ejemplo: Bloque 10, aula 121" />
              }
            </Grid>
          </Grid>
        </div>)
    } else if (estado === 'gr_est_fin' && solicitud.tipo ?solicitud.tipo.codigo == "gr_trabajo_grado":"") {
      return (
        <Grid container spacing={3} alignItems="flex-end">
          <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
            <TextField type='number' value={nota} onChange={this.onChange} name="nota" id="nota" label="Nota Final" fullWidth />
          </Grid>
          {solicitud.tipo.codigo == "gr_trabajo_grado" && nota >= 4.5 && nota <= 5 &&
            <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
              <FormControl style={{width: '100%'}}>
                <InputLabel id="demo-contr  olled-open-select-label">Distinción</InputLabel>
                <Select value={condecoracion} onChange={(event) => this.setState({ condecoracion: event.target.value })} labelId="demo-controlled-open-select-label" id="demo-controlled-open-select">
                  <MenuItem value={0}>No aplica</MenuItem>
                  {nota >= 4.5 && nota <= 4.79 ?
                      <MenuItem value={3}>Meritorio</MenuItem>
                    :
                    nota >= 4.8 && nota <= 5 &&
                    <MenuItem value={4}>Laureado</MenuItem>
                  }
                </Select>
              </FormControl>
            </Grid>}
        </Grid>
      )
    } 
    else if (estado === 'gr_est_fin' && (solicitud.tipo ? solicitud.tipo.codigo == "gr_trabajo_grado" || solicitud.tipo.codigo == "gr_articulo":"")) {
      return (
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel id="demo-controlled-open-select-label">Distinción</InputLabel>
              <Select value={condecoracion} onChange={(event) => this.setState({ condecoracion: event.target.value })} labelId="demo-controlled-open-select-label" id="demo-controlled-open-select">
                <MenuItem value={1}>No Aprobado</MenuItem>
                <MenuItem value={2}>Aprobado</MenuItem>
              </Select>
              {/* {solicitud.tipo.codigo != "gr_articulo" || (solicitud.tipo.codigo == "gr_articulo" && solicitud.estado_articulo == 3) || (solicitud.condecoracion == 5) &&
                <Select value={condecoracion} onChange={(event) => this.setState({ condecoracion: event.target.value })} labelId="demo-controlled-open-select-label" id="demo-controlled-open-select">
                  <MenuItem value={1}>No Aprobado</MenuItem>
                  <MenuItem value={2}>Aprobado</MenuItem>
                </Select>} */}
              {/* {solicitud.tipo.codigo == "gr_articulo" && solicitud.estado_articulo != 3 && solicitud.condecoracion != 5 &&
                <Select value={condecoracion} onChange={(event) => this.setState({ condecoracion: event.target.value })} labelId="demo-controlled-open-select-label" id="demo-controlled-open-select">
                  <MenuItem value={1}>No Aprobado</MenuItem>
                  <MenuItem value={5}>Aprobado en espera</MenuItem>
                </Select>} */}
            </FormControl>
          </Grid>
        </Grid>
      )
    }
  }

  renderAlertas = (condecoracion, estado) => {
    let { solicitud, showModalDetalleGrado } = this.props
    if (solicitud.estado_actual) {
      if ((estado == "tr_est_des_tr" && solicitud.estado_actual.codigo == "tr_est_sus_tr") || (estado == "tr_est_des_tr" && solicitud.estado_actual.codigo == "tr_est_sus_tr")) {
        return <AlertasSimple tipo='warnig' titulo='Cuidado' sub_titulo='Usted está a punto de volver a un estado por el cual ya pasó la solicitud' />
      }
      if (estado == "tr_est_inv_ok" || estado == "tr_est_tr_ok") {
        return <AlertasSimple tipo='info' titulo='Adjuntos Requisitos' sub_titulo='Por favor verifique que se hayan cargado los documentos requistos' />
      }
      if (estado == "gr_est_rec") {
        return <AlertasSimple tipo='warnig' titulo='Cuidado' sub_titulo='Si rechaza esta solicitud el estudiante perderá todo su proceso y tendrá que iniciar de nuevo con la misma o una nueva opción de grado.' />
      }
      if (condecoracion == 1) {
        return <AlertasSimple tipo='warnig' titulo='Cuidado' sub_titulo='Usted está a punto de reprobar esta solicitud.' />
      }
      if (condecoracion == 2 && solicitud.tipo.codigo == "gr_articulo" && !(solicitud.url_articulo && solicitud.doi_articulo && solicitud.issn_revista && solicitud.numero_revista && solicitud.volumen_revista)) {
        return <AlertasSimple tipo='info' titulo='Información Requerida' sub_titulo={<span>Por favor verifique que él estudiante haya completado la siguiente información: <br />1.URL y DOI del Artículo <br />2.ISSN, número y volumen de la Revista <br />Clic aquí para ver</span>} callback={() => showModalDetalleGrado(true)} />
      }
    }
  }
  // Modales

  modalBuscarGestor = () => {
    let { modalNewGestor, showModalNewGestor } = this.props
    let { estado } = this.state
    return (
      <Dialog onClose={() => showModalNewGestor(false)} open={modalNewGestor} fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600}>
        <AppBarModal titulo={'Buscar Persona'} mostrarModal={() => showModalNewGestor(false)} titulo_accion="" />
        <DialogContent style={{ padding: '0' }} className='scroll'>
          <PersonasBuscarGrado tipo="personal" callback={(data) => { this.gestionarGestores(estado, data) }} />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    )
  }

  modalBuscarEvaluador = () => {
    let { tipo_evalaudor, modalEvaluador } = this.state
    return (
      <Dialog onClose={() => this.setState({ modalEvaluador: false })} open={modalEvaluador} fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600}>
        <AppBarModal titulo={'Buscar Jurado Evaluador'} mostrarModal={() => this.setState({ modalEvaluador: false })} titulo_accion="" />
        <DialogContent style={{ padding: '0' }} className='scroll'>
          {tipo_evalaudor == "interno" ?
            <BuscarPersona callback={(data) => { this.gestionarEvaluadores(this.state.estado, data) }} mensajeVacio='Ingrese nombre, correo o usuario de la persona interna a buscar' /> :
            <PersonaExternaBuscar callback={(data) => { this.gestionarEvaluadores(this.state.estado, data) }} />
          }
          <FormControl style={{ padding: '0px 16px 0px 16px' }}>
            <FormLabel component="legend">Seleccione un Tipo de Jurado Evaluador</FormLabel>
            <RadioGroup name={tipo_evalaudor} value={tipo_evalaudor} row >
              <FormControlLabel onClick={() => this.setState({ tipo_evalaudor: "interno" })} value="interno" control={<Radio />} label="Jurado Evaluador Interno" size="small" />
              <FormControlLabel onClick={() => this.setState({ tipo_evalaudor: "externo" })} value="externo" control={<Radio />} label="Jurado Evaluador Externo" size="small" />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    )
  }

  render() {
    let { solicitud, modalGestionarSolicitud, showModalGestionarSolicitud, cargando_envio } = this.props;
    let { cargando, mondaConfirm, estado, condecoracion, estados_siguiente } = this.state
    return (
      <div>
        <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={modalGestionarSolicitud} onClose={showModalGestionarSolicitud.bind(this, false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <AppBarModal titulo={'¿ Gestionar Grado ?'} mostrarModal={showModalGestionarSolicitud} titulo_accion="" accion="" />
          <DialogContent style={{ padding: '1' }} className='scroll'>
            {!cargando ?
              <Grid container spacing={1} className={cargando_envio ? "oculto" : ""}>
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                  {/* <GradosEstadosGestion cambiarSeleccion={(estado) => this.changeState({ estado })} estado={this.state.estado} solicitud={solicitud} /> */}
                  <RenderEstados estados_siguiente={estados_siguiente} estado={estado} cambiarSeleccion={(estado) => this.changeState({ estado })} />
                </Grid>
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                  {this.renderInputs(estado)}
                </Grid>
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12} className={this.state.actasFaltantes.length === 0 ? "oculto" : ""}>
                  <EnviarMultiplesArchivos
                    enModal={false}
                    enviarArchivos={(archivos) => this.onSubmit(archivos)}
                    limite={2}
                    mostrarMensaje={false}
                    lista={this.state.actasFaltantes}
                    mostrarRender={true}
                    mostrar={true}
                    enLista={true}
                  />
                </Grid>
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                  <TextField multiline value={this.state.mensaje} onChange={this.onChange} name="mensaje" id="mensaje" label="¿Desea enviar una retroalimentación al estudiante?" fullWidth />
                </Grid>
                {this.renderAlertas(condecoracion, estado)}
                {this.state.actasFaltantes.length > 0  && <AlertasSimple tipo='info' titulo='¿Adjuntar actas o documentos?' sub_titulo={"Las actas o documentos tienen como objetivo dar soporte al proceso"} />}
              </Grid> :
              <TareasTerminadas imagen={emma_w} mensaje='Cargando...' widthImg='7%' marginTop='7%' />
            }
            <div className={!cargando_envio ? "oculto" : ""} >
              <TareasTerminadas imagen={emma_w} mensaje='Enviando...' widthImg='7%' marginTop='7%' cargando={true} />
            </div>
          </DialogContent>
          <DialogActions>
            <div className={cargando_envio ? "oculto" : ""}>
              <BtnForm texto="CANCELAR" callback={() => showModalGestionarSolicitud(false)} />
              <BtnForm texto="ACEPTAR" callback={() => {
                if ((estado == "tr_est_des_tr" && solicitud.estado_actual.codigo == "tr_est_sus_tr") || (estado == "tr_est_des_tr" && solicitud.estado_actual.codigo == "tr_est_sus_tr") || estado == "gr_est_rec") {
                  this.setState({ mondaConfirm: true })
                }
                else document.getElementById('enviar_form_multi_archivo').click()
              }} />
            </div>
            <ConfirmarAccion
              dato={{ id: 0 }}
              titulo={' ¿ Seguro que desea ejecutar esta acción ?'}
              mensaje1= {estado != "gr_est_rec" ? 
              "Esta solicitud ya ha pasado por este estado anteriormente, tenga en cuenta que no podrá revertir esta acción, ": 
              "Tenga en cuenta que no podrá revertir esta acción"}
              ejecutarAccion={(id) => {
                document.getElementById('enviar_form_multi_archivo').click()
                this.setState({ mondaConfirm: false })
              }}
              modalConAccion={mondaConfirm}
              mostrarModalConfAccion={() => this.setState({ mondaConfirm: false })}
            />
          </DialogActions>
        </Dialog>

        {/* Modales */}
        {this.modalBuscarGestor()}
        {this.modalBuscarEvaluador()}
      </div>
    )
  }
}

//Props del componente
GradosGestionar.propTypes = {
  //variables
  modalGestionarSolicitud: PropTypes.bool.isRequired,
  solicitud: PropTypes.object.isRequired,
  //funciones
  showModalGestionarSolicitud: PropTypes.func.isRequired,
  gestionarGrado: PropTypes.func.isRequired,
}


export default GradosGestionar;


