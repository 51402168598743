import React, { Component } from 'react'
import { AgregarParticipante } from './Forms';
import { consulta, crear_form_data, formulario, mostrarError, obtenerValoresFiltros, validateInputs } from '../../../../global/js/funciones';
import { TIPOS_IDENTICACION } from '../helper';

export default class EditarParticipanteExt extends Component {

  constructor(props) {
    super(props);
    this.state = {
      datosParticipante: {
        primer_nombre: '',
        segundo_nombre: '',
        primer_apellido: '',
        segundo_apellido: '',
        tipo_identificacion: '',
        identificacion: '',
        nacionalidad: '',
        celular: '',
        correo_personal: '',
        correo: '',
        indicativo_celular: ''
      },
      errores: [],
      tipos_identificacion: [],
      cargandoForm: false,
      modalAddParticipantExt: false,
    }
  }

  async componentDidMount() {
    let { participante } = this.props;
    let tipos_identificacion = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_IDENTICACION }, { 'llave': 'estado', 'valor': 1 }]);
    console.log(participante)
    let tipo_identificacion_estu = tipos_identificacion.find(tipo => tipo.id === participante.tipo_identificacion.id)?.id;
    console.log(tipo_identificacion_estu)
    this.setState({
      tipos_identificacion: tipos_identificacion,
      datosParticipante: {
        primer_nombre: participante.primer_nombre || '',
        segundo_nombre: participante.segundo_nombre || '',
        primer_apellido: participante.primer_apellido || '',
        segundo_apellido: participante.segundo_apellido || '',
        tipo_identificacion: tipo_identificacion_estu || '',
        identificacion: participante.identificacion || '',
        nacionalidad: participante.nacionalidad || '',
        celular: participante.celular || '',
        correo_personal: participante.correo_personal || '',
        correo: participante.correo || '',
        indicativo_celular: participante.indicativo_celular || ''
      },
      modalAddParticipantExt: true
    });
  }

  async componentDidUpdate({ }, { modalAddParticipantExt }) {
    let { modalAddParticipantExt: modalAddParticipantExtNew } = this.state;
    let { resetState } = this.props
    if (modalAddParticipantExt !== modalAddParticipantExtNew) {
      if (!modalAddParticipantExtNew) {
        resetState()
      }
    }
  }

  actualizarEstado = (nombreEstado, valor) => {
    this.setState(prevState => ({
      [nombreEstado]: typeof valor === 'object' && !Array.isArray(valor)
        ? { ...prevState[nombreEstado], ...valor }
        : valor
    }));
  };

  onChangeParticipante = (event) => {
    const { name, value } = event.target;
    this.setState(prevState => ({
      datosParticipante: {
        ...prevState.datosParticipante,
        [name]: value
      }
    }));
  }

  guardarParticipanteNuevoInt = async () => {
    let { actualizarMensaje } = this.props;
    let { datosParticipante: { primer_nombre, segundo_nombre, primer_apellido, segundo_apellido, tipo_identificacion, identificacion, correo, indicativo_celular, celular, nacionalidad }, participantes, datosParticipante } = this.state;

    // Validar campos obligatorios
    let errores = await validateInputs([
      { value: primer_nombre, name: 'primer_nombre', err: { empty: true } },
      { value: primer_apellido, name: 'primer_apellido', err: { empty: true } },
      { value: tipo_identificacion, name: 'tipo_identificacion', err: { empty: true } },
      { value: identificacion, name: 'identificacion', err: { empty: true } },
      { value: nacionalidad, name: 'nacionalidad', err: { empty: true } },
    ]);

    // Si hay errores en los campos obligatorios, mostrar mensaje y salir de la función
    if (errores.length > 0) {
      this.setState({ errores });
      actualizarMensaje({ titulo: 'Debes llenar los campos obligatorios.', tipo: 'info', mostrar: true, tiempo: 6000 });
      return;
    }

    console.log(datosParticipante)
    this.editarInfoParticipante(datosParticipante)

    // Validar si la identificación ya está registrada
    // let participanteExistente = participantes.find(participante => participante.identificacion === identificacion);
    // if (participanteExistente) {
    //   actualizarMensaje({ titulo: 'Ya existe un participante registrado con esta identificación', tipo: 'info', mostrar: true, tiempo: 6000 });
    //   return; // Salir de la función si la identificación ya está registrada
    // }

    // Si no hay errores y la identificación no está registrada, guardar el participante
    // this.setState({ cargando: true });
    // participantes.push(datosParticipante);
    // this.setState({ participantes, modalAddParticipante: false, errores, datosParticipante: { primer_nombre: '', segundo_nombre: '', primer_apellido: '', segundo_apellido: '', tipo_identificacion: '', identificacion: '', nacionalidad: '', celular: '', correo_personal: '', correo: '', indicativo_celular: '' }, cargando: false, modalAddParticipantExt: false });
  }

  editarInfoParticipante = async (info) => {
    let { actualizarMensaje, obtenerParticipantesRegistro, id_solicitud, consultar, setCargando, consultaParticipantes } = this.props;
    let { participante: { id_participante } } = this.props;
    let data = {
      id: id_participante,
      primer_nombre: info.primer_nombre,
      segundo_nombre: info.segundo_nombre,
      primer_apellido: info.primer_apellido,
      segundo_apellido: info.segundo_apellido,
      tipo_identificacion: info.tipo_identificacion,
      identificacion: info.identificacion,
      indicativo_celular: info.indicativo_celular,
      celular: info.celular,
      correo_personal: info.correo_personal,
      correo: info.correo,
      nacionalidad: info.nacionalidad
    }
    consulta(`api/v1.0/internacionalizacion/registro/editarParticipante/${id_participante}`, data, 'patch', async (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          this.setState({ modalAddParticipantExt: false })
          setCargando(true)
          let participantes = await obtenerParticipantesRegistro('', id_solicitud)
          await consultaParticipantes(participantes)
          setCargando(false)
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    });
  }

  render() {
    return (
      <AgregarParticipante state={this.state} actions={this} editar={true} />
    )
  }
}
