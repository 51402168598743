import React, { Component } from 'react';
import { Grid, Checkbox, FormControlLabel, TextField, Typography, Button } from '@material-ui/core';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import BuscarSelect from "../general/BuscarSelect";


export default class SolicitudesAgregarMisDatos extends Component {

  constructor(props) {
    super(props)
    this.state = {
      cargando: true,
      mensaje: ''
    }
    this.fileInput = React.createRef();
  }


  render() {
    let {
      tipo_identificacion,
      tipos_identificacion,
      setTipo_identificacion,
      identificacion,
      primer_nombre,
      segundo_nombre,
      primer_apellido,
      segundo_apellido,
      barrio,
      direccion,
      departamentos,
      departamento,
      setDepartamento,
      ciudades,
      ciudad,
      setCiudad,
      setGenero,
      setComunidad,
      setDiscapacidad,
      telefono,
      correo,
      comunidad,
      comunidades,
      discapacidad,
      discapacidades,
      genero,
      generos,
      egresado,
      checked,
      programas,
      setPrograma,
      programa,
      anho,
      onChange,
    } = this.props.props_c;
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Datos Personales
        </Typography>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={egresado}
                onChange={checked}
                id= "egresado"
                name="egresado"
                color="primary"
              />
                }
                label="¿Eres Egresado CUC?"
              />
          </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <BuscarSelect datos={tipos_identificacion} cambiarEstado={(value) => setTipo_identificacion(value)} valor={tipo_identificacion} id='select-tipo-identificacion' nombre='¿Cuál es tu tipo de identificación?' placeholder='Seleccione Tipo' />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              value={identificacion}
              required
              type="number"
              id="identificacion"
              name="identificacion"
              label="Número de identificación"
              fullWidth
              autoComplete="fname"
              onChange={onChange}
            />
            <Grid item xs={12} className={`alert_info_sin_borde`}>
              <Typography variant="body2" component="p">Sin puntos ni comas.</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              value={primer_nombre}
              required
              type="text"
              id="primer_nombre"
              name="primer_nombre"
              label="Primer Nombre"
              fullWidth
              onChange={onChange}
              />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              value={segundo_nombre}
              type="text"
              id="segundo_nombre"
              name="segundo_nombre"
              label="Segundo Nombre"
              fullWidth
              onChange={onChange}
              />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              value={primer_apellido}
              required
              type="text"
              id="primer_apellido"
              name="primer_apellido"
              label="Primer Apellido"
              fullWidth
              onChange={onChange}
              />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              value={segundo_apellido}
              type="text"
              id="segundo_apellido"
              name="segundo_apellido"
              label="Segundo Apellido"
              fullWidth
              onChange={onChange}
              />
          </Grid>
          {egresado === true && (<>
          <Grid item xs={12} md={6}>
             <BuscarSelect datos={programas} cambiarEstado={(value) => setPrograma(value)} valor={programa} id='select-programa' nombre='Programa' placeholder='Seleccione Programa de Egreso' />
           </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              value={anho}
              type="number"
              id="anho"
              name="anho"
              label="Año de Egreso"
              fullWidth
              onChange={onChange}
              />
          </Grid>
          </>
          )}
          <Grid item xs={12} md={6}>
            <TextField
              value={direccion}
              required
              type="text"
              id="direccion"
              name="direccion"
              label="Direccion"
              fullWidth
              onChange={onChange}
              />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              value={barrio}
              required
              type="text"
              id="barrio"
              name="barrio"
              label="Barrio"
              fullWidth
              onChange={onChange}
              />
          </Grid>
          <Grid item xs={12} md={6}>
            <BuscarSelect datos={departamentos} cambiarEstado={(value) => setDepartamento(value)} valor={departamento} id='select-departamento' nombre='Departamento' placeholder='Seleccione Departamento' />
          </Grid>
          <Grid item xs={12} md={6}>
            <BuscarSelect datos={ciudades} cambiarEstado={(value) => setCiudad(value)} valor={ciudad} id='select-ciudad' nombre='Ciudad' placeholder='Seleccione Ciudad' />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              value={telefono}
              required
              type="number"
              id="telefono"
              name="telefono"
              label="Telefono"
              fullWidth
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              value={correo}
              required
              type="text"
              id="correo"
              name="correo"
              label="Email"
              fullWidth
                onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <BuscarSelect datos={comunidades} cambiarEstado={(value) => setComunidad(value)} valor={comunidad} id='select-comunidad' nombre='Comunidad' placeholder='Seleccione Comunidad' />
          </Grid>
          <Grid item xs={12} md={6}>
            <BuscarSelect datos={generos} cambiarEstado={(value) => setGenero(value)} valor={genero} id='select-genero' nombre='Genero' placeholder='Seleccione Genero' />
          </Grid>
          <Grid item xs={12}>
          <BuscarSelect datos={discapacidades} cambiarEstado={(value) => setDiscapacidad(value)} valor={discapacidad} id='select-discapacidad' nombre='¿ Tiene alguna discapacidad ?' placeholder='Seleccione Discapacidad' />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}
