import React, { Component } from "react";
import { Dialog, DialogActions, DialogContent, Grid, Paper, Radio, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { consulta, crear_form_data, formulario, mostrarError, } from "../../../global/js/funciones";
import { actualizarMensaje, mostrarModulo, } from "../../../redux/actions/actGlobal";
import AppBarModal from "../../general/AppBarModal";
import { BtnForm } from "../../general/BotonesAccion";
import { obtenerValoresFiltros } from "../../../global/js/funciones";
import { ITEMS_PROCESOS, COD_NIVEL_AVANZADO, COD_NIVEL_EXCELENTE, COD_NIVEL_INSUFICIENTE, COD_NIVEL_MINIMO, COD_NIVEL_SATISFACTORIO } from "../helper";
import TituloAccion from "../../general/TituloAccion";
import emma_w from "./../../../global/imagenes/emma_w.png";
import emma_s1 from "./../../../global/imagenes/emma_s1.png";
import TareasTerminadas from "../../general/TareasTerminadas";
import Cargando from "../../general/app/Cargando";
import { StyleSheet } from "@react-pdf/renderer";
import BeenhereIcon from '@material-ui/icons/Beenhere';
import { TablaValoracion } from "./components/TablaValoracion";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const classes = StyleSheet.create({
  gridContainer: {
    width: "100%",
    padding: "0.7% 0 0 0",
  },
  tableContainer: {
    borderRadius: '10px',
    overflow: 'hidden',
  },
  table: {
    width: '100%',
  },
  tableRow: {
    backgroundColor: '#f9f5eb',
    borderBottom: '5px solid #C79e32',
    borderRadius: '10px',
  },
  columnaNivel: {
    padding: '0.8%',
    backgroundColor: '#f9f5eb',
    lineHeight: '1.5',
    width: '15%',
    fontSize: "12px",
  },
  containerRadio: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  smallRadio: {
    transform: "scale(0.7)",
    // border: "1px solid #000",
    padding: "0 0 0 0",
    marginTop: "-5px",
    // marginBottom: "-5px",
  }
});

class SisefCalificaciones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proceso: "",
      informacion: "",
      errores: {},
      retro: {},
      criterio: {},
      proc_criterios: [],
      genericas_procesos: [],
      objetivos_selec: [],
      objetivos: [],
      cargando: false,
      selectedRows: {},
      selectedCols: {},
      selectedCells: {},
      selecciones: [],
    };

  }

  async ObjetivosRubrica(rubrica) {
    return new Promise((resolve) => {
      consulta(`api/v1.0/sisef/obtener_objetivos/${rubrica}`, null, null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  async ObtenerObjetivos(id) {
    let objetivos = await this.ObjetivosRubrica(id);
    this.setState({ objetivos: objetivos });
  }

  getError(valor) {
    return valor ? true : false;
  }

  /*  NUEVO */
  onSubmit = (e) => {
    e.preventDefault();
    let { selecciones, } = this.state;
    let { actualizarMensaje, criterios } = this.props;
    let error = false;
    if (selecciones.length == 0) {
      actualizarMensaje({ titulo: `Debe seleccionar al menos un criterio`, tipo: 'info', mostrar: true, tiempo: 6000 })
      error = true;
    } else if (selecciones.length !== criterios.length) {
      actualizarMensaje({ titulo: `Faltan criterios por evaluar`, tipo: 'info', mostrar: true, tiempo: 6000 })
      error = true;
    }
    if (!error) this.enviar();
  };

  // NUEVO
  enviar = async () => {
    let { selecciones } = this.state;
    let { estudiante, actividad } = this.props;
    this.setState({ cargando: true });
    let data = { actividad: actividad.id, estudiante: estudiante.identificacion, criterio: selecciones, };
    await this.Guardar(data)
  }

  // NUEVO
  Guardar = async (data) => {
    let { cerrarModal, actualizarMensaje, actividad, listar_estudiantes, corte, asignatura, } = this.props;
    consulta(
      `api/v1.0/sisef/asignar_calificacion`,
      data,
      "post",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            listar_estudiantes(asignatura, corte, actividad);
            cerrarModal(false);
            this.setState({ cargando: false });
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  };

  Guardar_varios = async (data) => {
    let {
      cerrarModal,
      actualizarMensaje,
      actividad,
      listar_estudiantes,
      corte,
      asignatura,
      estudiantes_selec,
    } = this.props;
    let proceso = data.proceso;
    let criterio = data.criterio;
    let detalle = data.detalle;
    consulta(
      `api/v1.0/sisef/${data.actividad}/calificar_varios`,
      {
        proceso,
        criterio,
        detalle,
        estudiantes_selec,
      },
      "post",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            // listar_estudiantes(asignatura, corte, actividad);
            cerrarModal(false);
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  };

  obtenerRango = (nivel) => {
    const rangos = {
      excelente: "(4,6 - 5,0)",
      avanzado: "(4,0 - 4,5)",
      satisfactorio: "(3,0 - 3,9)",
      minimo: "(2,0 - 2,9)",
      insuficiente: "(0,0 - 1,9)",
    };
    return rangos[nivel] || "";
  }

  onClick = (subindex, index, valor, nivel, dataHead) => {
    let { selecciones } = this.state;
    const selectedCells = { ...this.state.selectedCells };
    Object.keys(selectedCells).forEach(key => {
      const [col, row] = key.split("-").map(Number);
      if (col === subindex) {
        selectedCells[key] = false;
      }
    });
    selectedCells[`${subindex}-${index}`] = true;

    const nivelCodigoMap = {
      'excelente': COD_NIVEL_EXCELENTE,
      'avanzado': COD_NIVEL_AVANZADO,
      'satisfactorio': COD_NIVEL_SATISFACTORIO,
      'minimo': COD_NIVEL_MINIMO,
      'insuficiente': COD_NIVEL_INSUFICIENTE,
    };

    console.log('nivel', nivel)

    if (nivel in nivelCodigoMap) dataHead.codigo_nivel = nivelCodigoMap[nivel]

    let data_criterio = { id_rubrica: dataHead.id, detalle: valor, codigo_nivel: dataHead.codigo_nivel, }

    let c = selecciones.filter((crit) => crit.id_rubrica == dataHead.id);
    if (c.length == 0) selecciones.push(data_criterio);
    else {
      selecciones = selecciones.filter((crit) => crit.id_rubrica != dataHead.id);
      selecciones.push(data_criterio);
    }
    this.setState({ selectedCells, selecciones });
  };

  render() {
    let { cargando, selectedCells, } = this.state;
    let { Modal, cerrarModal, criterios, estudiante } = this.props;
    let titulo = estudiante.nombre;
    return (
      <>
        <TablaValoracion
          onClose={() => cerrarModal(false)}
          open={Modal}
          titulo={titulo}
          cargando={cargando}
          criterios={criterios}
          selectedCells={selectedCells}
          classes={classes}
          onClick={this.onClick}
          onSubmit={this.onSubmit}
        />
      </>
    );
  }
}

export default SisefCalificaciones;
