import React, { Component } from 'react';
import { consulta, rutaImg } from '../../../global/js/funciones';
import { BtnDepartamento, BtnDetalle, BtnFolder, BtnForm, BtnPermiso } from '../../general/BotonesAccion';
import ListarDatos from '../../general/ListarDatos';
import TareasTerminadas from '../../general/TareasTerminadas';
import { DPTOS_ACADEMICOS, ESTADOS, PERMISO_DPTO, PERMISO_ESTADOS, PERMISO_PROGRAMAS, PERMISO_TIPOS, PROGRAMAS, TIPOS_SOLICITUDES } from './helper';
import emma_w from '../../../global/imagenes/emma_w.png';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import AppBarModal from '../../general/AppBarModal';
import Generica from '../../personas/generica/Generica';

class InternacionalizacionPermisos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      personas: [],
      cargando: true,
      modal: false,
      generica: {
        tipo: 0,
        id: 0,
      },
    }
  }

  async componentDidMount() {
    let personas = await this.obtenerGestores();
    this.setState({ personas, cargando: false })
  }

  async obtenerGestores() {
    return new Promise(resolve => {
      consulta(`api/v1.0/internacionalizacion/encargados`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  acciones(data) {
    const onClickTipo = (data) => this.setState({ id_persona: data.id, modal: true, generica: { tipo: PERMISO_TIPOS, id: TIPOS_SOLICITUDES } });
    const onClickProgramas = (data) => this.setState({ id_persona: data.id, modal: true, generica: { tipo: PERMISO_PROGRAMAS, id: PROGRAMAS } });
    const onClickDepartamentos = (data) => this.setState({ id_persona: data.id, modal: true, generica: { tipo: PERMISO_DPTO, id: DPTOS_ACADEMICOS } });
    const onClickEstado = (data) => this.setState({ id_persona: data.id, modal: true, generica: { tipo: PERMISO_ESTADOS, id: ESTADOS } });
    let tipos = <BtnDetalle callback = { () => onClickTipo(data) } texto='Tipos' color='#2196f3' />;
    let programas = <BtnFolder callback = { () => onClickProgramas(data) } texto='Programas' color='#CFC915' />;
    let departamentos = <BtnDepartamento callback = { () => onClickDepartamentos(data) } texto='Departamentos' color='#d11a2a' />;
    let estados = <BtnPermiso callback = { () => onClickEstado(data) } texto='Estados' />;
    return <div>{ tipos }{ programas }{departamentos}{ estados }</div>
  }

  render () {
    let { personas, cargando, modal, generica, id_persona } = this.state;
    if (cargando) return <TareasTerminadas mensaje = 'Cargando..' marginTop = '7%' imagen = { emma_w } widthImg = "7%" />;
    return (
      <div>
        <ListarDatos
          id = 'tbl_internacional_permisos'
          titulo = 'Lista Gestores'
          descargar = 'EMMA - GESTORES INTERNACIONALIZACIÓN'
          datos = { personas }
          opciones = { true }
          buscar = { true }
          acciones = { row => this.acciones(row) }
          avatarImg = { true }
          avatar = { ({ identificacion }) => `${rutaImg}${identificacion}.jpg` }
          fila_principal = { row => `${row.primer_nombre} ${row.segundo_nombre} ${row.primer_apellido} ${row.segundo_apellido}`.toUpperCase() }
          filas = { [
            { 'mostrar': ({ correo }) => `${correo}`, 'id': 'correo', 'enLista': true },
            { 'mostrar': ({ identificacion }) => `${identificacion}`, 'id': 'identificacion', 'enLista': true }
          ] }
        />
        <Dialog open = { modal } fullWidth = { true } maxWidth = 'sm' onClose = { () => this.setState({ modal: false }) }>
          <AppBarModal titulo = '¡ Lista Permisos !' mostrarModal = { () => this.setState({ modal: false }) } accion = { () => this.setState({ modal: false }) } titulo_accion = 'CERRAR' />
          <DialogContent style = { { padding: 0 } } className = 'scroll'>
            <Generica
              notificaciones = { (generica.tipo == PERMISO_TIPOS || generica.tipo == PERMISO_ESTADOS || generica.tipo == PERMISO_DPTO) ? true : false }
              tipo = { generica.tipo }
              persona = { { id: id_persona } }
              genericaRelacionId = { generica.id }
              titulo = "¡ Nuevo Permiso !"
              tituloInput = "Permisos"
              tituloEliminar = '¿ Eliminar Permiso ?'
            />
          </DialogContent>
          <DialogActions>
            <BtnForm texto = "Cerrar" callback = { () => this.setState({ modal: false }) } />
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default InternacionalizacionPermisos;
