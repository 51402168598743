import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { crear_form_data, formulario, mostrarError, obtenerValoresFiltros } from '../../../global/js/funciones';
import MovEstudiantilCrear from './MovEstudiantilCrear';
import { actualizarDataSolicitud } from '../../../redux/actions/actInternacionalizacion';
import { connect } from 'react-redux';

class MovEstudiantiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tipo_mov_estudiantil: '',
      cargardo: true
    }
  }

  async actualizarListaSolicitudes() {
    let { set_lista_solicitudes, obtenerSolicitudes } = this.props;
    let periodo = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Int' }]);
    let listaSolicitudes = await obtenerSolicitudes([{ 'llave': 'periodo', 'valor': periodo.length > 0 ? periodo[0].nombre : '' }]);
    set_lista_solicitudes(listaSolicitudes);
  }

  modificarBorradorSolicitud = async (id, data = {}, requisitos = [], paso_actual, callback) => {
    let { actualizarMensaje, mostrarModalAddMovEstudiantil, recargarSolicitudes } = this.props;
    let form = await crear_form_data(data);
    if (paso_actual==3) {
      requisitos.map(({ archivo, codigo }, index) => {
        form.append(`archivo${index}`, archivo)
        form.append(`codigo${index}`, codigo)
        return true;
      });
    }
    formulario(`api/v1.0/internacionalizacion/solicitud/${id}/modificar_borrador`, form, "put", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          callback(true);
          if (paso_actual==3) {
            mostrarModalAddMovEstudiantil(false); // Cierra el modal en el último paso
            this.actualizarListaSolicitudes(); // Recargar info de solicitudes mostradas en proceso
          }
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          callback(false);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    });
  }

  tipoMovEstudiantilSeleccion = (tipo_mov_estudiantil) => {
    let { mostrarModalAddMovEstudiantil } = this.props;
    let { codigo } = tipo_mov_estudiantil;
    { codigo === 'Sem_Int_Mov_Est' && mostrarModalAddMovEstudiantil(true) }
    { codigo === 'Dob_Tit_Mov_Est' && mostrarModalAddMovEstudiantil(true) }
    this.setState({ tipo_mov_estudiantil: tipo_mov_estudiantil });
  }

  render () {
    let { 
      modalAddMovEstudiantil, 
      mostrarModalAddMovEstudiantil, 
      actualizarMensaje,
      actualizarDataSolicitud,
      solicitud, 
      mostrarModalCancelarSolicitud,
      obtenerSolicitud,
      crearBorradorSolicitud,
      cancelo_solicitud,
      set_datos_solicitud,
      set_solicitud_cancelada
    } = this.props;
    let { tipo_mov_estudiantil } = this.state;
    return (
      <div className = 'contenido_modulo'>
        <MovEstudiantilCrear
          modalAddMovEstudiantil = { modalAddMovEstudiantil }
          mostrarModalAddMovEstudiantil = { mostrarModalAddMovEstudiantil }
          mostrarModalCancelarSolicitud = { mostrarModalCancelarSolicitud } 
          tipoMovEstudiantil = { tipo_mov_estudiantil } // OBSERVACION - NO SE USA EN EL COMPONENTE
          actualizarMensaje = { actualizarMensaje }
          crearBorradorMovilidad = { crearBorradorSolicitud }
          modificarBorradorMovilidad = { this.modificarBorradorSolicitud }
          actualizarDataSolicitud = { actualizarDataSolicitud }
          valorSolicitud = { solicitud }
          obtenerSolicitud = { obtenerSolicitud }
          cancelo_solicitud = { cancelo_solicitud }
          set_datos_solicitud = { set_datos_solicitud }
          set_solicitud_cancelada = { set_solicitud_cancelada }
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let { solicitud } = state.redInternacionalizacion;
  return { solicitud }
}

const mapDispatchToProps = { actualizarDataSolicitud }

MovEstudiantiles.propTypes = {
  // Funciones
  actualizarDataSolicitud: PropTypes.func,
  mostrarModalAddMovEstudiantil: PropTypes.func.isRequired,
  // Variables
  solicitud: PropTypes.object,
  modalAddMovEstudiantil: PropTypes.bool.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(MovEstudiantiles);
