import React, { Component } from 'react';
import { Grid, Typography, Dialog, DialogActions, DialogContent } from '@material-ui/core'
import AppBarModal from '../../general/AppBarModal'
import { BtnForm } from '../../general/BotonesAccion'

import '../../../global/css/stylePortal.css'
import CustomDropzone from "../../general/CustomDropzone"

class DocumentosOferentes extends Component {
    render() {
        let { 
            modalDocumentosOferentes, 
            mostrarModalDocumentosOferentes, 
            agregarArchivos,
            actualizarMensaje,
            archivos,
            eliminarArchivo,
            guardarArchivos
        } = this.props

        return (
            <div>
                <Dialog fullWidth={true} maxWidth="sm" open={modalDocumentosOferentes} onClose={() => mostrarModalDocumentosOferentes(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <AppBarModal titulo='Cargar orientacion impartida a oferentes' mostrarModal={mostrarModalDocumentosOferentes} titulo_accion="Limpiar" />
                    <DialogContent className='scroll'>
                        <Grid container>
                            <CustomDropzone
                                addFunction={agregarArchivos}
                                actualizarMensaje={actualizarMensaje}
                                archivos={archivos}
                                deleteFunction={eliminarArchivo}
                            />
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <BtnForm texto="CANCELAR" callback={() => mostrarModalDocumentosOferentes(false)} />
                        <BtnForm texto="GUARDAR" callback={guardarArchivos} />
                    </DialogActions>
                </Dialog >
            </div>
        );
    }
}

export default DocumentosOferentes;