import React, { Component } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  InputLabel,
  FormControl,
  Tooltip,
  Select,
  MenuItem,
  Paper,
  IconButton,
} from "@material-ui/core";

import PropTypes from 'prop-types'
import ListarDatos from "../general/ListarDatos";
import moment from "moment";

// Imagenes
import emma_w from "../../global/imagenes/emma_w.png";
import { MenuAdministrar } from "./MenuMatriculas";
import TareasTerminadas from "../general/TareasTerminadas";
import { HistorialAdmin } from "./matriculas/Historial";
import TituloAccion from "../general/TituloAccion";
import FilterListIcon from "@material-ui/icons/FilterList";
import emma_s1 from "../../global/imagenes/emma_s1.png";
import AccionesRapidas from "../general/AccionesRapidas";
import { BtnRegresar, BtnForm, BtnEditar } from "../general/BotonesAccion";
import AppBarModal from "../general/AppBarModal";
import GetAppIcon from "@material-ui/icons/GetApp";

import reportIcon from '../../global/imagenes/report.png';
import PeriodoIcon from '../../global/imagenes/periodo.png';
import ListarTarjetas from '../general/ListarTarjetas';

import ModificarFecha from "./matriculas/ModificarFecha";

import {
  consulta,
  generarFiltros,
  obtenerMisDatos,
  obtenerValoresFiltros,
  mostrarError,
  obtenerPermisosValor,
  modificarValorGenericaAlter,
  crear_form_data,
  formulario
} from "../../global/js/funciones";
// Redux
import { connect } from "react-redux";
import {
  showModalSolicitudes,
  showModalDetalle,
  showModalGestionar,
  showModalFiltros,
  showModalProgramas,
  setMisDatos,
  setMatriculas,
  showModalObservaciones,
  mostrarEditarMatricula,
  mostrarModalInforme,
  mostrarModalModPeriodo
} from "../../redux/actions/actMatriculas";
import MatriculaPermisos from "./matriculas/MatriculaPermiso";
import ModalDetalleSolicitud from "./matriculas/ModalDetalleSolicitud";
import {
  mostrarModulo,
  actualizarMensaje,
} from "../../redux/actions/actGlobal";
import MatriculasFiltrar from "./matriculas/MatriculasFiltrar";
import MatriculasGestionar from "./matriculas/MatriculasGestionar";
import ModalFechaGrado from "./matriculas/ModalFechaGrado";
import ModalMenciones from "./matriculas/ModalMenciones";
import ModalPasarAGraduado from "./matriculas/ModalPasarAGraduado";
import MatriculaInforme from "./matriculas/MatriculaInforme";

// exportar a excel la encuesta
import ReactExport from "react-export-excel";
import permisosIcon from '../../global/imagenes/lightbulb.png';
import fechaIcon from '../../global/imagenes/calendar.png';
import diplomadoIcon from '../../global/imagenes/diploma.png';
import { FECHASGRADO, DIPLOMADOS } from './matriculas/helper';
import MatriculaValoresGenericas from "./matriculas/MatriculaValoresGenericas";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class MatriculasAdm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			seleccion: '',
			render: false,
			cargando: false,
			accion: '',
			accionProceso: 'inicio',
			tipoSolicitud: null,
			parametro: null,
			programas: [],
			asignaturas: [],
			procesoActual: {},
			estados: [],
			admin: false,
			filtros: [],
			matriculas: [],
			opcion: 'detalle',
			numgestion: 0,
			survey: false,
			estados_procesos: [],
			cargarFiltroP: true,
			filtrarPeriodo: false,
			periodo_filtro: null,
			periodos: [],
			cargandoPeriodo: true,
			encuestas: [],
			modalFechaSolicitud: false,
			modalMenciones: false,
			modalPasarAGraduado: false,
			modalDesacrgarPorSolicitud: false,
			descargaSolicitud: null,
			descargaSolicitudFormulario: true,
			descargaSolicitudCargar: false,
			descargaSolicitudLista: false,
      descargaTipoSolicitud: null,
      tipos_procesos: [],
			informeExcel: [],
      accionAdm: 'inicio',
      filasInforme: [],
      informes: [],
      submodulos: [],
      tipoSolSelec: '',
      informe: {'nombre' : '', 'codigo' : ''},
      periodo_informe: ''
		};
	}

	cambiarEstado = (estados) => {
		this.setState(estados);
	};

	async componentDidMount() {
		const { mostrarModulo, setMatriculas } = this.props;
    const  {seleccion} = this.state;
		mostrarModulo({ ruta: '/gestion_procesos_academicos', nombre: 'Gestión Procesos Académicos' });
    let esAdmin = this.esAdministrador();
		const { setMisDatos } = this.props;
    let data = await this.permisosActividad('si', esAdmin);
    let tipo_solicitud = '';
    if (data){
      if(!seleccion){
        this.setState({seleccion: data[0].codigo});
        tipo_solicitud = data[0].codigo;
      }else{
        tipo_solicitud = seleccion;
      }
      
      const periodo = await obtenerValoresFiltros([{ llave: 'codigo', valor: tipo_solicitud }]);
      const filtros =
        periodo && periodo.length > 0
          ? [{ llave: 'estado_solicitud__valorb', valor: 'si' }, { llave: 'periodo', valor: periodo[0].valorb }, { llave: 'tipo__id', valor: seleccion }]
          : [];
      const misDatos = await obtenerMisDatos();
      setMisDatos(misDatos);
      this.obtenerEstadoSolicitudes(periodo[0].valorb);
      this.obtenerTipoSolicitudes(periodo[0].valorb);
      this.setState({ periodo: periodo.length > 0 ? periodo[0].valorb : '', filtros, periodo_filtro: periodo.length > 0 ? periodo[0].valorb : '' });
    }
	}

	async componentDidUpdate(prevProps, prevState) {
    const { seleccion, filtros, accionProceso, periodo_filtro, accionAdm, submodulos, periodo } = this.state;
		if (seleccion !== prevState.seleccion) {
      // switch (seleccion) {
        // 	case 'historial':
        if(submodulos.filter((elemento) => elemento.codigo === seleccion) && seleccion !== 'administrar'){
          this.cambiarEstado({ cargandoPeriodo: true });
          let periodos = await this.obtenerPeriodos();
          const periodofiltro = await obtenerValoresFiltros([{ llave: 'codigo', valor: seleccion }]);
          let periodo_a = periodofiltro[0].valorb
          if (!(periodos.find(e => e.periodo == periodo_a))) periodos.push({ 'periodo': periodo_a })
          this.cambiarEstado({ periodos, cargandoPeriodo: false, cargarFiltroP: false,
          });
        
					this.setState({ render: false, periodo_filtro: periodo_a });
					this.obtenerEstadoSolicitudes(periodo_a);
        }

        if(seleccion !== 'administrar') this.setState({accionProceso: 'inicio'});
					// break;
			// 	default:
			// }
		}

		if (prevState.accionProceso !== accionProceso) {
			if (accionProceso != 'Tod_Mat') {
				this.cambiarEstado({ render: false })
				if (accionProceso == 'inicio') this.obtenerEstadoSolicitudes(periodo_filtro)
				else {
					this.setState({ cargando: true });
					let matriculas = await this.obtenerMatriculas([{ 'llave': 'estado_solicitud__codigo', 'valor': accionProceso }, { 'llave': 'periodo', 'valor': periodo_filtro }, { 'llave': 'tipo__id', 'valor': submodulos.filter((elemento) => elemento.codigo === seleccion)[0].id }])
					this.props.setMatriculas(matriculas);
					this.setState({ cargando: false, render: true });
				};
			}
		}
    if (prevState.accionAdm !== accionAdm) {
      if (accionAdm === 'informes') {
        this.cambiarEstado({ render: false })
        if (accionAdm === 'informes') this.obtenerInformes(periodo_filtro);
        if (accionAdm === 'inicio') this.setState({ cargando: false, render: true });
      }
    }
	}

	esAdministrador() {
		const { usuario } = this.props;
		if (
			usuario &&
			usuario.perfil &&
			(usuario.perfil.codigo === 'Per_Adm' || usuario.perfil.codigo === 'Per_Adm_Mat')
		) {
			this.setState({ admin: true });
      return true;
		}
	}

	obtenerMatriculas = async (filtros = []) => {
		let f = await generarFiltros(filtros);
		return new Promise((resolve) => {
			consulta(`api/v1.0/matriculas?${f}`, null, null, (error, estado, resp) => {
				resolve(estado === 200 && !error ? resp : null);
			});
		});
	};

    permisosActividad = (cambiar = 'si', esAdmin) => {
    return new Promise((resolve) => {
      consulta( "api/v1.0/matriculas/config", null,  "get",  (error,estado,resp)=>{
        if(error) return;
        this.setState({submodulos: resp});
        if (cambiar == 'si' && resp.length > 0 ) this.setState({seleccion: resp[0].codigo});
        if(resp.length > 0){
          this.cambiarEstado({Procesos: resp[0].codigo})
          resolve(resp)
        } else if (resp.length == 0 && esAdmin) {
            this.setState({render: true, seleccion: 'administrar'})
        }
        // else return false
      });
    });
	}

	gestionarMatricula = (id, data) => {
		let { actualizarMensaje, showModalGestionar, showModalDetalle } = this.props;
		return new Promise((resolve) => {
			consulta(`api/v1.0/matriculas/${id}/gestionar`, data, 'put', async (error, estado, resp) => {
				let title = 'Ha ocurrido un error, contacte con el administrador.';
				let icon = 'error';
				if (!error) {
					if (estado === 200) {
						icon = 'success';
						title = resp.titulo;
						await this.listarMatriculas(this.state.filtros);
						showModalGestionar(false);
						showModalDetalle(false);
					} else {
						icon = 'info';
						title = resp.titulo ? resp.titulo : mostrarError(resp);
					}
				}
				actualizarMensaje({ titulo: title, tipo: icon, mostrar: true, tiempo: 6000 });
				resolve(resp);
			});
		});
	};

	listarMatriculas = async (filtros) => {
		this.props.setMatriculas([]);
		this.setState({ filtros, cargando: true, render: false });
		const matriculas = await this.obtenerMatriculas(filtros);
		this.props.setMatriculas(matriculas);
		this.setState({ cargando: false, render: true });
	};

	handlerSurvey = (survey) => {
		const { showModalFiltros } = this.props;
		this.setState({ survey });
		showModalFiltros(survey);
	}

	obtenerEncuestas = async (filtros = []) => {
		let f = await generarFiltros(filtros);
		return new Promise((resolve) => {
			consulta(`api/v1.0/matriculas/config/encuesta?${f}`, null, null, (error, estado, resp) => {
				resolve(estado === 200 && !error ? resp : null);
			});
		});
	};

	downloadExcelEncuestas = async (filtros) => {
		let encuestas = await this.obtenerEncuestas(filtros);
		// cambiar los ids por las respuestas
		for (let index = 0; index < encuestas.length; index++) {
			if (encuestas[index].tipo == "tipo2") {
				let aux = await obtenerPermisosValor(encuestas[index].pregunta.id);
				let respuestasId = encuestas[index].respuesta.split(",");
				let respuestaString = "";
				for (let index2 = 0; index2 < respuestasId.length; index2++) {
					respuestaString += aux.find(element => element.secundario.id == respuestasId[index2]).secundario.nombre + ", ";
				}
				encuestas[index].respuesta = respuestaString;
			}
			if (encuestas[index].tipo == "tipo1") {
				let aux = await obtenerPermisosValor(encuestas[index].pregunta.id);
				encuestas[index].respuesta = aux.find(element => element.secundario.id == encuestas[index].respuesta).secundario.nombre;
			}
		}
		// sacar los ids de las matriculas
		let result = encuestas.reduce((acc, item) => {
			if (!acc.includes(item.matricula)) {
				acc.push(item.matricula);
			}
			return acc;
		}, [])
		// agrupar por ids de las matriculas
		result = result.map((item) => encuestas.filter((pregunta) => pregunta.matricula == item))
		this.setState({
			encuestas: result
		})
		document.getElementById('excel-encuesta').click()
	}

	// downloadExcelInforme = async () => {
	// 	const { descargaSolicitud } = this.state;
  //   console.log(aqui)
  //   console.log(descargaSolicitud)
	// 	// consulta(`api/v1.0/matriculas/informe/${descargaSolicitud}`, null, null, (error, estado, resp) => {
	// 	// 	if (estado === 200 && !error) {
	// 	// 		this.setState({
	// 	// 			informeExcel: resp,
	// 	// 			descargaSolicitudFormulario: false,
	// 	// 			descargaSolicitudCargar: false,
	// 	// 			descargaSolicitudLista: true,
	// 	// 		})
	// 	// 	}
	// 	// });
	// 	// this.setState({
	// 	// 	descargaSolicitudFormulario: false,
	// 	// 	descargaSolicitudCargar: true,
	// 	// 	descargaSolicitudLista: false,
	// 	// })
	// }

	onCloseDescaragrExcelInforme = () => {
		this.setState({
			modalDesacrgarPorSolicitud: false,
			descargaSolicitudFormulario: true,
			descargaSolicitudCargar: false,
			descargaSolicitudLista: false,
		})
	}

	cambiarEstadoAGraduado = () => {
		const { actualizarMensaje } = this.props;
		const { procesoActual } = this.state;
		let data = {
			matricula: procesoActual.id
		}
		consulta("api/v1.0/matricula/solicitud/estudiantegraduado", data, "POST", (error, estado, resp) => {
			let title = 'Ha ocurrido un error, contacte con el administrador.';
			let icon = 'error';
			if (!error) {
				if (estado === 200) {
					icon = 'success';
					title = resp.titulo;
					this.listarMatriculas(this.state.filtros);
				} else {
					icon = 'info';
					title = resp.titulo;
				}
			}
			actualizarMensaje({ titulo: title, tipo: icon, mostrar: true, tiempo: 6000 });
			this.setState({ modalPasarAGraduado: false })
		});
	}


  pintarInicioAdm = () => {
    let { periodo_filtro } = this.state;
    return (
      <div style={{ padding: '2% 2% 0% 2%' }} >
        <Grid container direction={'row'} spacing={1} justifyContent={'center'} >
          <Grid item xs={12} sm={12} md={10} lg={8} >
            <TituloAccion principal='' alterno='¿ Que deseas hacer ?' />
            <Paper elevation={0} square className="scroll contenido_fijo">
              <Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
                <Grid item xs={12} sm={12} md={6} lg={6} >
                  <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.setState({ accionAdm: 'fechaGrado' })}>
                    <AccionesRapidas imagen={fechaIcon} titulo='¡ Administrar Fechas de Grado !' sub_titulo='' des_sub_titulo='Crea, modifica y elimina fechas de grado.' />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} >
                  <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.setState({ accionAdm: 'diplomados' })}>
                    <AccionesRapidas imagen={diplomadoIcon} titulo='¡ Administrar Diplomados !' sub_titulo='' des_sub_titulo='Crea, modifica Diplomados.' />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} >
                  <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.setState({ accionAdm: 'permisos' })}>
                    <AccionesRapidas imagen={permisosIcon} titulo='¡ Controlar Permisos !' sub_titulo='' des_sub_titulo='Verifica, asigna y retira permisos a los gestores.' />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} >
                  <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.setState({ accionAdm: 'informes' })}>
                    <AccionesRapidas imagen={reportIcon} titulo={`¡ Informes !`} sub_titulo='' des_sub_titulo={`Genera reporte de los procesos de practicas.`} />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} >
                  <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.setState({ accionAdm: 'fechaPeriodo' })}>
                    <AccionesRapidas imagen={PeriodoIcon} titulo={`¡ Modificar Periodos !`} sub_titulo='' des_sub_titulo={`Aqui podrás gestionar los periodos.`} />
                  </Paper>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    )

  }

  renderPermisos = () =>{
    const {
			showModalProgramas
		} = this.props;
    return (
      <MatriculaPermisos showModalProgramas={showModalProgramas} />
    );
  }

  renderTipoSolicitudes = () =>{
    let {submodulos, tipoSolSelec} = this.state;
    let { mostrarModalModPeriodo, modalModPeriodo } = this.props;
    const acciones = (data) => {
      const onClickEdit= (data) => {
        this.setState({tipoSolSelec: data.codigo })
        this.props.mostrarModalModPeriodo(true)
      }
      let detalle = <BtnEditar callback={() => onClickEdit(data)} color='#01579b' texto='Editar' />;
      return detalle;
    }

    return (
      <>
        <ListarDatos
          datos={submodulos}
          opciones={true}
          id="tbl_tipoSolicitudes"
          // avatar={''}
          titulo="Tipos de Solicitudes"
          acciones={(row) => acciones(row)}
          descargar="Tipo de Solicitudes - EMMA"
          fila_principal={({ nombre }) => nombre}
          filas={[
            {
              id: 'Periodo',
              mostrar: ({ valorb, valord, codigo }) => (codigo == "Mat_Pro_Sol_Rein_Tran") ? `${valorb} - ${valord} `: valorb,
              enLista: true
						},
          ]}
        />
        <ModificarFecha modal={modalModPeriodo} mostrarModal={mostrarModalModPeriodo} enviar={(periodo, periodoV) => this.modificarPeriodo(periodo, periodoV)} label='Periodo' type='number' titulo={`Nuevo Periodo`} tipo={tipoSolSelec}/>
      </>
    );
  }

  renderValoresGenericas = (idgenerica, tipo) =>{
    return <MatriculaValoresGenericas idGenerica = {idgenerica} tipo = {tipo}/>
  }

  pintarInformes() {
    let { informes, informe, render }  = this.state
    let { modalInforme, mostrarModalInforme, actualizarMensaje }  = this.props

    const acciones = (data) => {      
      this.setState({ informe: {'nombre': data.nombre, 'codigo': data.codigo} }) 
      mostrarModalInforme(true)
    }
    return (
      <>
        {/* {render ? ( */}
          <ListarTarjetas
            datos={informes}
            titulo={'Informes Matriculas'}
            acciones={(data) => acciones(data)}
            avatar={({ nombre }) => nombre.toUpperCase().charAt(0)}
            color_avatar={({valorb}) => ({'backgroundColor': ((valorb) ? valorb : '#EEEEEE'), 'color': ((valorb) ? 'white' : '#757575') })}
            orden={'orden'}
          />
        {/* ) : (
          <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg='7%' />
        )} */}
        {
          <MatriculaInforme
            informe={informe}
            modalInforme={modalInforme}
            mostrarModalInforme={mostrarModalInforme}
            actualizarMensaje={actualizarMensaje}
          />
        }
      </>
    )
  }

  modificarPeriodo = async (periodo, periodoV) => {
    let { actualizarMensaje, mostrarModalModPeriodo } = this.props;
    let {tipoSolSelec} = this.state;
    if (periodo) {
      let p = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': tipoSolSelec }]);
      if (p.length > 0) {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        let { error, estado, resp } = await modificarValorGenericaAlter(p[0].id, { 'valorb': periodo, 'valord': periodoV.toUpperCase() })
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            mostrarModalModPeriodo(false);
            this.cambiarEstado({ periodo, periodo_filtro : periodo});
            this.setState({ cargando :true})
            // this.obtenerEstadoSolicitudes(periodo)
            let data = await this.permisosActividad('no');
            if (data) {
              this.renderTipoSolicitudes();
            }
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp)
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      } else actualizarMensaje({ titulo: 'error al cargar el periodo actual', tipo: 'error', mostrar: true, tiempo: 6000 });
    } else actualizarMensaje({ titulo: 'Todos los campos son obligatorios', tipo: 'info', mostrar: true, tiempo: 6000 });
  }

  // admin
  pintarAdministrar() {
    let { accionAdm, admin, periodo_filtro } = this.state;

    if (!admin) return <TareasTerminadas imagen={emma_s1} mensaje='No tiene los permisos asignados' widthImg='7%' marginTop='7%' />
    return (
      <>
        {
          <div>
          {accionAdm === 'inicio' && this.pintarInicioAdm()}
          {accionAdm !== 'inicio' && <div className={'regresar'} ><BtnRegresar callback={() => this.setState({ accionAdm: 'inicio' })} /></div>}
          {accionAdm === 'fechaGrado' && this.renderValoresGenericas(FECHASGRADO, 'grado')}
          {accionAdm === 'permisos' && this.renderPermisos()}
          {accionAdm === 'administrar' && this.pintarInicioAdm()}
          {accionAdm === 'diplomados' && this.renderValoresGenericas(DIPLOMADOS, 'diplomados')}
          {accionAdm === 'informes' && this.pintarInformes()}
          {accionAdm === 'fechaPeriodo' && this.renderTipoSolicitudes() }
          </div>
        }
      </>
    )

  }

	pintar = () => {
		let { seleccion,
			render,
			admin,
			opcion,
			procesoActual,
			numgestion,
			survey,
			encuestas,
			accionProceso,
			modalFechaSolicitud,
			modalMenciones,
			modalPasarAGraduado,
      submodulos,
      filtros
		} = this.state;
		const {
			modalDetalle,
			modalFiltros,
			showModalFiltros,
			showModalDetalle,
			modalGestionar,
			showModalGestionar,
			showModalProgramas,
			actualizarMensaje,
			showModalObservaciones,
			modalObservaciones
		} = this.props;
		return (
			<div>
				<MenuAdministrar
					seleccionar={(seleccion) => this.cambiarEstado({ seleccion })}
					sele={seleccion}
					admin={admin}
				/>
				<div className='contenido_modulo'>
					{render ? (
						<div>
							{submodulos.filter((elemento) => elemento.codigo == seleccion) && this.renderProcesos()}
							{seleccion === 'administrar' && this.pintarAdministrar()}
						</div>
					) : (
						<TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg='7%' />
					)}
					{/* boton descargar excel */}
					{
						<ExcelFile filename={`encuestas`} element={<button className='oculto' id={'excel-encuesta'} />}>
							<ExcelSheet data={encuestas} name='datos'>
								{
									encuestas[0] && (
										encuestas[0].map((fila, i) => {
											return <ExcelColumn
												key={i}
												label={`${fila.pregunta.nombre}`}
												value={(e) => {
													return e[i].respuesta
												}} />
										})
									)
								}
							</ExcelSheet>
						</ExcelFile>
					}
					<ModalDetalleSolicitud
						open={modalDetalle}
						setOpen={showModalDetalle}
						matricula={procesoActual}
						opcion={opcion}
						cambiarEstado={this.cambiarEstado}
						admin={true}
						actualizarMensaje={actualizarMensaje}
						gestion={numgestion}
						openGestionar={showModalGestionar}
						openObs={modalObservaciones}
						setOpenObs={showModalObservaciones}
					/>
					<MatriculasFiltrar
						downloadExcelEncuestas={this.downloadExcelEncuestas}
						survey={survey}
						handlerSurvey={this.handlerSurvey}
						setOpen={showModalFiltros}
            seleccion={submodulos.filter((elemento) => elemento.codigo === seleccion)}
						open={modalFiltros}
						accionProceso={accionProceso}
						mostrarTodos={(accion) => this.setState({ accionProceso: accion })}
						listarHistorial={(filtros) => this.listarMatriculas(this.configFiltros(accionProceso).concat(filtros))}
					/>
					<MatriculasGestionar
						open={modalGestionar}
						setOpen={showModalGestionar}
						matricula={procesoActual}
						gestionarMatricula={this.gestionarMatricula}
						cambiarEstado={this.cambiarEstado}
						actualizarMensaje={actualizarMensaje}
					/>
					<ModalFechaGrado
						modalFechaSolicitud={modalFechaSolicitud}
						handleCloseModal={() => this.setState({ modalFechaSolicitud: false })}
						matricula={procesoActual}
						actualizarMensaje={actualizarMensaje}
					/>
					<ModalFechaGrado
						modalFechaSolicitud={modalFechaSolicitud}
						handleCloseModal={() => this.setState({ modalFechaSolicitud: false })}
						matricula={procesoActual}
						actualizarMensaje={actualizarMensaje}
					/>
					<ModalMenciones
						modalFechaSolicitud={modalMenciones}
						handleCloseModal={() => this.setState({ modalMenciones: false })}
						matricula={procesoActual}
						actualizarMensaje={actualizarMensaje}
					/>
					<ModalPasarAGraduado
						open={modalPasarAGraduado}
						onClose={() => this.setState({ modalPasarAGraduado: false })}
						titulo={"! ESTUDIANTE GRADUADO ¡"}
						matricula={procesoActual}
						onClick={this.cambiarEstadoAGraduado}
					/>
				</div>
			</div>
		);
	};

	handleClickOpcion = (opcion, parametro) => {
		this.setState({ tipoSolicitud: opcion, parametro });
	};

	pintarHistorial() {
		const { showModalDetalle, showModalGestionar, showModalFiltros, misDatos } = this.props;
		const { filtros, cargando } = this.state;
		const { matriculas, actualizarMensaje, mostrarEditarMatricula, modalEditarMatricula } = this.props;
		return (
			<HistorialAdmin
				showModalPasarAGraduado={() => this.setState({ modalPasarAGraduado: true })}
				showModalCambiarFecha={() => this.setState({ modalFechaSolicitud: true })}
				showModalMenciones={() => this.setState({ modalMenciones: true })}
				showModalDetalle={showModalDetalle}
				showModalGestionar={showModalGestionar}
				showModalFiltros={showModalFiltros}
				historial={matriculas}
				listarHistorial={this.listarMatriculas}
				misDatos={misDatos}
				cambiarEstado={this.cambiarEstado}
				filtros={filtros}
        filtrosReintegro={this.configFiltros(this.state.accionProceso).concat(this.state.filtros)}
				cargando={cargando}
				resetOpcion={(opc = "detalle") => this.setState({ opcion: opc })}
				habilitarGestion={(num) => this.setState({ numgestion: num })}
				handlerSurvey={this.handlerSurvey}
				actualizarMensaje={actualizarMensaje}
        mostrarEditarMatricula={mostrarEditarMatricula}
				modalEditarMatricula={modalEditarMatricula}
			/>
		);
	}


	pintarInicioProcesos = () => {
		let { estados_procesos, filtrarPeriodo, periodo_filtro, periodos, cargandoPeriodo } = this.state;
		let { descargaSolicitud, modalDesacrgarPorSolicitud, descargaSolicitudFormulario, descargaSolicitudCargar, descargaSolicitudLista, informeExcel } = this.state;
		let { showModalFiltros } = this.props

		const obtenerColor = (codigo) => {
			let color = 'white';
			const colores = {
				'Mat_Env': { backgroundColor: '#f5f5f5', color: '#757575' },
				'Mat_En_Est': { backgroundColor: '#AFF9DB', color: "#000" },
				'Mat_Pro': { backgroundColor: '#ffc107', color },
				'Mat_Rec': { backgroundColor: '#b71c1c', color },
        'Mat_Com_Env': { backgroundColor: '#DBB62C', color },
        'Mat_Pag': { backgroundColor: '#3498db', color },
        'Mat_Aval_Fac_Rein_Tran': { backgroundColor: '#5cb85c', color },
				'Mat_Ter': { backgroundColor: '#009688', color },
				'Mat_Pro_Ter_Exo': { backgroundColor: '#009688', color },
				'Mat_Pro_Sol_Cam_Fec': { backgroundColor: '#009688', color },
				'Mat_Gra': { backgroundColor: '#009688', color },
				'Tod_Mat': { backgroundColor: '#0d47a1', color },
				'Mat_Sol_Rever': { backgroundColor: '#0d47a1', color },
        'Mat_Rein_Tran_Apr_Fac': { backgroundColor: '#5cb85c', color },
				'Mat_Rein_Tran_Rec_Fac': { backgroundColor: '#b71c1c', color },
				'Mat_Rein_Tran_En_Valid_Fac': { backgroundColor: '#ffc107', color },
				'Mat_Act_Dat_Apro_Reg': { backgroundColor: '#2c1d58', color },
				'Mat_Act_Dat_Re_Reg': { backgroundColor: '#bd3a2f', color },
				'Mat_Act_Dat_Rev_Reg': { backgroundColor: '#044a2b', color },
				'Mat_Act_Dat_Apro_Cre': { backgroundColor: '#2950a6', color },
				'Mat_Act_Dat_Re_Cred': { backgroundColor: '#340e25', color },
				'Mat_Act_Dat_Apr_Tec': { backgroundColor: '#a8b4d5', color },
				'Mat_Act_Dat_Re_Tec': { backgroundColor: '#e382a3', color },
				'Mat_Rein_Tran_Doc_Ok': { backgroundColor: '#2950a6', color },
				'Mat_Rein_Tran_Est': { backgroundColor: '#2950a6', color },
        'Mat_Act_Pen': { backgroundColor: "#ffc107", color },
        'Mat_Act_Rev_Are': { backgroundColor: '#3498db', color },
        'Mat_cer_pago_vol': { backgroundColor: '#ACC7A1', color },
        'Mat_cer_val_pago': { backgroundColor: '#C7B713', color },
        'Mat_cer_val_soli': { backgroundColor: '#2AF40A', color },
        'Mat_Rein_Tran_Est_Hom_Apro': { backgroundColor: '#2AF40A', color },
        'Mat_Rein_Tran_Est_Hom_Recha': { backgroundColor: '#0d47a1', color },
        'Mat_Rein_Tran_Apr': { backgroundColor: '#ec9c06', color },
			};
			return colores[codigo];
		};

		const handleClick = (codigo) => {
			if (codigo != 'Tod_Mat') this.setState({ accionProceso: codigo })
			else {
				showModalFiltros(true);
			}
		};

		return (
			<>
				<div style={{ padding: '2% 2% 0% 2%' }} >
					<Grid container direction={'row'} spacing={1} justifyContent={'center'} >
						<Grid item xs={12} sm={12} md={12} lg={10} >
							<Grid container direction={'row'} alignItems="center" spacing={1} style={{ width: "100%" }}>
								<Grid item xs={6} sm={6} md={6} lg={6} >
									<TituloAccion principal='' alterno='Seleccione el estado a gestionar: ' />
								</Grid>
								<Grid item xs={6} sm={6} md={6} lg={6} >
									<Tooltip title='Descargar' aria-label="add" onClick={() => this.cambiarEstado({ modalDesacrgarPorSolicitud: true })} >
										<IconButton edge={false} color="secondary" style={{ float: 'right' }}><GetAppIcon /></IconButton>
									</Tooltip>
									<Tooltip title='Filtrar' aria-label="add" onClick={() => this.ModalfiltroPeriodo()} >
										<IconButton edge={false} color="secondary" style={{ float: 'right' }}><FilterListIcon /></IconButton>
									</Tooltip>
								</Grid>
							</Grid>
							<Paper elevation={0} square className="scroll contenido_fijo">
								{(estados_procesos.length === 0) ?
									<TareasTerminadas mensaje='Aún no hay solicitudes para este periodo.' marginTop='7%' imagen={emma_s1} widthImg="7%" /> :
									<Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
										{estados_procesos.map(({ estado_solicitud__codigo: codigo, estado_solicitud__nombre: nombre, num_sol }, i) => {
											return (
												<Grid item xs={12} sm={12} md={6} lg={6} key={i}>
													<Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => handleClick(codigo)}>
														<AccionesRapidas imagen='' titulo={`¡ ${nombre} !`} sub_titulo='' des_sub_titulo='' avatar={obtenerColor(codigo)} avatar_name={nombre.charAt(0).toUpperCase()} list_desc={Number.isInteger(num_sol) ? [`Numero de Solicitudes : ${num_sol}`] : ['Debe aplicar los filtros.']} />
													</Paper>
												</Grid>
											)
										})}
									</Grid>
								}
							</Paper>
						</Grid>
					</Grid>
				</div>

				<Dialog fullWidth={true} maxWidth="xs" open={filtrarPeriodo} onClose={() => this.cambiarEstado({ filtrarPeriodo: false })} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
					<AppBarModal titulo='¡ Aplicar Filtros !' mostrarModal={() => this.cambiarEstado({ filtrarPeriodo: false })} titulo_accion="" accion="" />
					<DialogContent className='scroll'>
						{!cargandoPeriodo ?
							<Grid container spacing={3} className='' >
								<Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
									<FormControl className='form-control'>
										<InputLabel htmlFor="periodo_filtro">Seleccione Periodo</InputLabel>
										<Select
											inputProps={{
												name: 'periodo_filtro',
												id: 'periodo_filtro',
											}}
											value={periodo_filtro}
											onChange={this.onChange}
											required={true}>
											{periodos.map(({ periodo }, i) => <MenuItem key={i} value={periodo}>{periodo}</MenuItem>)}
										</Select>
									</FormControl>
								</Grid>
							</Grid>
							: <TareasTerminadas imagen={emma_w} mensaje='Cargando...' widthImg='5%' marginTop='5%' />
						}
					</DialogContent>
					<DialogActions>
						<BtnForm texto="CANCELAR" callback={() => this.cambiarEstado({ filtrarPeriodo: false })} />
						<BtnForm texto="FILTRAR" callback={() => this.filtrarEstadoSolicitudes()} />
					</DialogActions>
				</Dialog>

				{/* <Dialog fullWidth={true} maxWidth="sm" open={modalDesacrgarPorSolicitud} onClose={this.onCloseDescaragrExcelInforme} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
					<AppBarModal titulo='¡ Descargar Informe por Solicitud !' mostrarModal={this.onCloseDescaragrExcelInforme} titulo_accion="" accion="" />
					<DialogContent className='scroll'>
						{
							descargaSolicitudFormulario && (
								<Grid container spacing={3} className='margin_cont' >
									<Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
										<FormControl className='form-control'>
											<InputLabel htmlFor="descargaSolicitud">Seleccione Solicitud</InputLabel>
											<Select
												inputProps={{
													name: 'descargaSolicitud',
													id: 'descargaSolicitud',
												}}
												value={descargaSolicitud}
												onChange={this.onChange}
												required={true}>
												{
													estados_procesos.map(({ estado_solicitud__codigo, estado_solicitud__nombre }, i) => <MenuItem key={i} value={estado_solicitud__codigo}>{estado_solicitud__nombre}</MenuItem>)
												}
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							)
						}
						{
							descargaSolicitudCargar && (
								<TareasTerminadas imagen={emma_w} mensaje='Cargando...' widthImg='5%' marginTop='5%' cargando={true}/>
							)
						}
						{
							descargaSolicitudLista && (
								<ListarDatos
									datos={informeExcel}
									opciones={true}
									id="tbl_informe_Excel"
									avatar={({ estado_solicitud: { nombre } }) => nombre.charAt(0)}
									titulo="Lista Informe"
									descargar="Informe - EMMA"
									fila_principal={({ usuario_registro: { primer_nombre, primer_apellido, segundo_apellido } }) =>
										`${primer_nombre} ${primer_apellido} ${segundo_apellido}`}
									filas={[
										{
											id: 'Fecha de registro',
											mostrar: ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a'),
											enLista: false
										},
										{
											id: 'Programa',
											mostrar: ({ programa: { nombre } }) => nombre,
											enLista: false
										},
										{
											id: 'Nombre completo',
											mostrar: ({ usuario_registro: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`,
											enLista: false
										},
										{
											id: 'Identificacion',
											mostrar: ({ usuario_registro: { identificacion } }) => `${identificacion}`,
											enLista: false
										},
										{
											id: 'Fecha Expedición',
											mostrar: ({ usuario_registro: { fecha_expedicion } }) => `${fecha_expedicion}`,
											enLista: false
										},
										{
											id: 'Correo',
											mostrar: ({ usuario_registro: { correo } }) => `${correo}`,
											enLista: false
										},
										{
											id: 'Correo personal',
											mostrar: ({ usuario_registro: { correo_personal } }) => `${correo_personal}`,
											enLista: false
										},
										{
											id: 'Celular',
											mostrar: ({ usuario_registro: { celular } }) => `${celular}`,
											enLista: false
										},
										{
											id: 'Telefono',
											mostrar: ({ usuario_registro: { telefono } }) => `${telefono}`,
											enLista: false
										},
										{
											id: 'Tipo de solicitud',
											mostrar: ({ tipo: { nombre } }) => `${nombre}`,
											enLista: false
										},
										{
											id: 'Estado solicitud',
											mostrar: ({ estado_solicitud: { nombre } }) => `${nombre}`,
											enLista: false
										},
										{
											id: 'Motivo negado',
											mostrar: ({ motivo_negado }) => motivo_negado,
											enLista: false
										},
										{
											id: 'Fecha de grado',
											mostrar: ({ informacion_solicitud_grado: { fechaGrado: { nombre } } }) => `${nombre} `,
											enLista: false
										},
                    {
                      id: 'Exoneración',
                      mostrar: ({exoneracion}) => {if(exoneracion) {const {nombre} = exoneracion; return `${nombre}`;} else{return '';}},
                      enLista: false
                    },
										{
											id: 'Aspirante',
											mostrar: ({ informacion_solicitud_grado: { aspirante: { nombre } } }) => `${nombre} `,
											enLista: false
										},
                    {
											id: 'Autorizo',
											mostrar: ({ autorizo }) => `${autorizo} `,
											enLista: false
										},
										{
											id: 'Periodo termino',
											mostrar: ({ informacion_solicitud_grado: { periodo } }) => `${periodo} `,
											enLista: false
										},
										{
											id: 'Opcion grado 1',
											mostrar: ({ informacion_solicitud_grado: { opcionGrado1: { nombre } } }) => `${nombre} `,
											enLista: false
										},
										{
											id: 'Opcion grado 2',
											mostrar: ({ informacion_solicitud_grado: { opcionGrado2: { nombre } } }) => `${nombre} `,
											enLista: false
										},
										{
											id: 'Trabajo de grado',
											mostrar: ({ informacion_solicitud_grado: { trabajo_grado } }) => `${trabajo_grado} `,
											enLista: false
										},
										{
											id: 'Direccion de residencia',
											mostrar: ({ informacion_solicitud_grado: { direccion } }) => `${direccion} `,
											enLista: false
										},
										{
											id: 'Ciudad de residencia',
											mostrar: ({ informacion_solicitud_grado: { ciudad_residencia } }) => `${ciudad_residencia} `,
											enLista: false
										},
										{
											id: 'Pais',
											mostrar: ({ informacion_solicitud_grado: { pais } }) => `${pais} `,
											enLista: false
										},
										{
											id: 'Pais',
											mostrar: ({ informacion_solicitud_grado: { pais } }) => `${pais} `,
											enLista: false
										},
										{
											id: 'Estrato',
											mostrar: ({ informacion_solicitud_grado: { estrato:{ nombre } } }) => `${nombre} `,
											enLista: false
										},
										{
											id: 'sexo',
											mostrar: ({ informacion_solicitud_grado: { sexo:{ nombre } } }) => `${nombre} `,
											enLista: false
										},
										{
											id: 'Egresado otro programa',
											mostrar: ({ informacion_solicitud_grado: { egresado:{ nombre } } }) => `${nombre} `,
											enLista: false
										},
										{
											id: 'Nombre del programa',
											mostrar: ({ informacion_solicitud_grado: { nombre_programa } }) => `${nombre_programa} `,
											enLista: false
										},
										{
											id: 'Ano en que se graduo',
											mostrar: ({ informacion_solicitud_grado: { ano_graduo } }) => `${ano_graduo} `,
											enLista: false
										},
										{
											id: 'Institucion educativa',
											mostrar: ({ informacion_solicitud_grado: { institucion_educativa } }) => `${institucion_educativa} `,
											enLista: false
										},
										{
											id: 'Trabaja actualmente',
											mostrar: ({ informacion_solicitud_grado: { trabaja:{ nombre } } }) => `${nombre} `,
											enLista: false
										},
										{
											id: 'Actividad laboral',
											mostrar: ({ informacion_solicitud_grado: { actividadLaboral:{ nombre } } }) => `${nombre} `,
											enLista: false
										},
										{
											id: 'Empresa donde labora',
											mostrar: ({ informacion_solicitud_grado: { empresa_labora } }) => `${empresa_labora} `,
											enLista: false
										},
										{
											id: 'Direccion',
											mostrar: ({ informacion_solicitud_grado: { direccion } }) => `${direccion} `,
											enLista: false
										},
										{
											id: 'Cargo desempena',
											mostrar: ({ informacion_solicitud_grado: { cargo_desempena } }) => `${cargo_desempena} `,
											enLista: false
										},
										{
											id: 'Telefono',
											mostrar: ({ informacion_solicitud_grado: { telefono_empresarial } }) => `${telefono_empresarial} `,
											enLista: false
										},
										{
											id: 'Correo empresarial',
											mostrar: ({ informacion_solicitud_grado: { correo_empresarial } }) => `${correo_empresarial} `,
											enLista: false
										},
										{
											id: 'Trabaja actualmente',
											mostrar: ({ informacion_solicitud_grado: { trabaja:{ nombre } } }) => `${nombre} `,
											enLista: false
										},
										{
											id: 'Rango salarial',
											mostrar: ({ informacion_solicitud_grado: { rango:{ nombre } } }) => `${nombre} `,
											enLista: false
										},
										{
											id: 'Acepto',
											mostrar: ({ informacion_solicitud_grado: { acepto:{ nombre } } }) => `${nombre} `,
											enLista: false
										},
									]}
								/>
							)
						}
					</DialogContent>
					<DialogActions>
						<BtnForm texto="CANCELAR" callback={this.onCloseDescaragrExcelInforme} />
						{ !descargaSolicitudCargar && <BtnForm texto="GENERAR" callback={this.downloadExcelInforme} />}
					</DialogActions>
				</Dialog> */}

			</>
		)
	}

	ModalfiltroPeriodo = async () => {
		let { cargarFiltroP } = this.state
		this.cambiarEstado({ filtrarPeriodo: true })
		if (cargarFiltroP) {
			this.cambiarEstado({ cargandoPeriodo: true })
			let periodos = await this.obtenerPeriodos();
			this.cambiarEstado({ periodos, cargandoPeriodo: false, cargarFiltroP: false })
		}
	}

	filtrarEstadoSolicitudes = () => {
		let { periodo_filtro } = this.state
		this.cambiarEstado({ filtrarPeriodo: false, render: false })
		this.obtenerEstadoSolicitudes(periodo_filtro)
	}

	obtenerPeriodos = () => {
    let {seleccion} = this.state;
		return new Promise((resolve) => {
			consulta(`api/v1.0/matriculas/periodos/${seleccion}`, null, null, (error, estado, resp) => {
				resolve(resp);
			});
		});
	};


	obtenerEstadoSolicitudes = (periodo) => {
    let {seleccion} = this.state;
    if(periodo){
      consulta(`api/v1.0/matriculas/estado_solicitud/${periodo}/${seleccion}`, null, null, (error, estado, resp) => {
        if (estado === 200) {
          let { general, extra } = resp
          if (extra.length > 0) {
            extra.map(({ solicitudes, nombre, codigo, orden }) => {
              let reporte = {
                'estado_solicitud__codigo': codigo,
                'estado_solicitud__nombre': nombre,
                'num_sol': solicitudes,
                'orden': orden
              }
              general.push(reporte);
            })
          }
          this.cambiarEstado({ estados_procesos: general.sort((a, b) => a.orden - b.orden), render: true });
        }
      })
    }
	}

	onChange = ({ target }) => {
		let { value, name } = target;
		this.setState({
			[name]: value
		})
	}

	renderProcesos() {
		let { accionProceso } = this.state;
		return (
			<>
				{accionProceso === 'inicio' ? this.pintarInicioProcesos() : this.pintarHistorial()}
				{accionProceso !== 'inicio' && <div className={'regresar'} ><BtnRegresar callback={() => this.setState({ filtros: [], accionProceso: 'inicio' })} /></div>}
			</>
		)
	}

	configFiltros = (accion_actual) => {
		let { periodo_filtro, seleccion } = this.state
		switch (accion_actual) {
			case 'Tod_Mat':
				return [{ 'llave': 'tipo__codigo', 'valor': seleccion }, { 'llave': 'periodo', 'valor': periodo_filtro }]
			case 'inicio':
				return [{ 'llave': 'tipo__codigo', 'valor': seleccion }, { 'llave': 'periodo', 'valor': periodo_filtro }]
			default:
				return [{ 'llave': 'tipo__codigo', 'valor': seleccion }, { 'llave': 'estado_solicitud__codigo', 'valor': accion_actual }, { 'llave': 'periodo', 'valor': periodo_filtro }]
		}
	}


	render() {
		return this.pintar();
	}


  cambiarEstado = (estados) => {
    this.setState(estados);
  };

  // async componentDidMount() {
  //   const { mostrarModulo, setMatriculas } = this.props;
  //   mostrarModulo({ ruta: "/matriculasAdm", nombre: "Gestión Matrículas" });
  //   const { setMisDatos } = this.props;
  //   const periodo = await obtenerValoresFiltros([
  //     { llave: "codigo", valor: "Per_Mat" },
  //   ]);
  //   const filtros =
  //     periodo && periodo.length > 0
  //       ? [
  //           { llave: "estado_solicitud__valorb", valor: "si" },
  //           { llave: "periodo", valor: periodo[0].nombre },
  //         ]
  //       : [];
  //   const misDatos = await obtenerMisDatos();
  //   setMisDatos(misDatos);
  //   this.esAdministrador();
  //   this.obtenerEstadoSolicitudes(periodo[0].nombre);
  //   this.obtenerTipoSolicitudes(periodo[0].nombre);
  //   this.setState({
  //     periodo: periodo.length > 0 ? periodo[0].nombre : "",
  //     filtros,
  //     periodo_filtro: periodo.length > 0 ? periodo[0].nombre : "",
  //   });
  // }

  // async componentDidUpdate(prevProps, prevState) {
  //   const { seleccion, filtros, accionProceso, periodo_filtro } = this.state;
  //   if (seleccion !== prevState.seleccion) {
  //     switch (seleccion) {
  //       case "historial":
  //         this.setState({ render: false });
  //         this.obtenerEstadoSolicitudes(periodo_filtro);
  //         this.obtenerTipoSolicitudes(periodo_filtro);
  //         break;
  //       default:
  //     }
  //   }

  //   if (prevState.accionProceso !== accionProceso) {
  //     if (accionProceso != "Tod_Mat") {
  //       this.cambiarEstado({ render: false });
  //       if (accionProceso == "inicio"){
  //         this.obtenerEstadoSolicitudes(periodo_filtro);
  //         this.obtenerTipoSolicitudes(periodo_filtro);
  //       }else {
  //         this.setState({ cargando: true });
  //         let matriculas = await this.obtenerMatriculas([
  //           { llave: "estado_solicitud__codigo", valor: accionProceso },
  //           { llave: "periodo", valor: periodo_filtro },
  //         ]);
  //         this.props.setMatriculas(matriculas);
  //         this.setState({ cargando: false, render: true });
  //       }
  //     }
  //   }
  // }

  // esAdministrador() {
  //   const { usuario } = this.props;
  //   if (
  //     usuario &&
  //     usuario.perfil &&
  //     (usuario.perfil.codigo === "Per_Adm" ||
  //       usuario.perfil.codigo === "Per_Adm_Mat")
  //   ) {
  //     this.setState({ admin: true });
  //   }
  // }

  obtenerMatriculas = async (filtros = []) => {
    let f = await generarFiltros(filtros);
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/matriculas?${f}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  };

  gestionarMatricula = (id, data) => {
    let { actualizarMensaje, showModalGestionar, showModalDetalle } = this.props;
    return new Promise((resolve) => {
      consulta( `api/v1.0/matriculas/${id}/gestionar`, data, "put", async (error, estado, resp) => {
          let title = "Ha ocurrido un error, contacte con el administrador.";
          let icon = "error";
          if (!error) {
            if (estado === 200) { 
              icon = "success"; 
              title = resp.titulo; 
              actualizarMensaje({   titulo: title,   tipo: icon,   mostrar: true,   tiempo: 6000, });
			        this.configFiltros();

              //   await this.listarMatriculas(this.state.filtros);
              await this.listarMatriculas(this.configFiltros(this.state.accionProceso).concat(this.state.filtros));
              showModalGestionar(false);
              showModalDetalle(false);
            } else {
              icon = "info";
              title = resp.titulo ? resp.titulo : mostrarError(resp);
			        actualizarMensaje({ titulo: title, tipo: icon, mostrar: true, tiempo: 6000, });
            }
          }
          resolve(resp);
        }
      );
    });
  };

  listarMatriculas = async (filtros) => {
    this.props.setMatriculas([]);
    this.setState({ filtros, cargando: true, render: false });
    const matriculas = await this.obtenerMatriculas(filtros);
    if(matriculas.length > 0){
      this.props.setMatriculas(matriculas);
    }
    this.setState({ cargando: false, render: true });
  };

  handlerSurvey = (survey) => {
    const { showModalFiltros } = this.props;
    this.setState({ survey });
    showModalFiltros(survey);
  };

  obtenerEncuestas = async (filtros = []) => {
    let f = await generarFiltros(filtros);
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/matriculas/config/encuesta?${f}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  };

  downloadExcelEncuestas = async (filtros) => {
    let encuestas = await this.obtenerEncuestas(filtros);
    // cambiar los ids por las respuestas
    for (let index = 0; index < encuestas.length; index++) {
      if (encuestas[index].tipo == "tipo2") {
        let aux = await obtenerPermisosValor(encuestas[index].pregunta.id);
        let respuestasId = encuestas[index].respuesta.split(",");
        let respuestaString = "";
        for (let index2 = 0; index2 < respuestasId.length; index2++) {
          respuestaString +=
            aux.find((element) => element.secundario.id == respuestasId[index2])
              .secundario.nombre + ", ";
        }
        encuestas[index].respuesta = respuestaString;
      }
      if (encuestas[index].tipo == "tipo1") {
        let aux = await obtenerPermisosValor(encuestas[index].pregunta.id);
        encuestas[index].respuesta = aux.find(
          (element) => element.secundario.id == encuestas[index].respuesta
        ).secundario.nombre;
      }
    }
    // sacar los ids de las matriculas
    let result = encuestas.reduce((acc, item) => {
      if (!acc.includes(item.matricula)) {
        acc.push(item.matricula);
      }
      return acc;
    }, []);
    // agrupar por ids de las matriculas
    result = result.map((item) =>
      encuestas.filter((pregunta) => pregunta.matricula == item)
    );
    this.setState({
      encuestas: result,
    });
    document.getElementById("excel-encuesta").click();
  };

  downloadExcelInforme = async () => {
    const { descargaSolicitud, descargaTipoSolicitud, filasInforme, periodo_informe} = this.state;
    const { actualizarMensaje } = this.props;
    let filas = [];
    if(!descargaSolicitud || !descargaTipoSolicitud || !periodo_informe){
      actualizarMensaje({ 
        titulo: "Todos los campos son obligatorios.", 
        tipo: "info", 
        mostrar: true, 
        tiempo: 6000 
      });
    }else{
      if(descargaTipoSolicitud != 'Mat_Pro_Sol_Gra'){
        filas = [
          {
            id: "Fecha de registro",
            mostrar: ({ fecha_registro }) =>
              moment(fecha_registro).format("YYYY-MM-DD, h:mm a"),
            enLista: false,
          },
          {
            id: "Programa",
            mostrar: ({ programa: { nombre } }) => nombre,
            enLista: false,
          },
          {
            id: "Nombre completo",
            mostrar: ({
              usuario_registro: {
                primer_nombre,
                primer_apellido,
                segundo_apellido,
              },
            }) =>
              `${primer_nombre} ${primer_apellido} ${segundo_apellido}`,
            enLista: false,
          },
          {
            id: "Identificación",
            mostrar: ({ usuario_registro: { identificacion } }) =>
              `${identificacion}`,
            enLista: false,
          },
          {
            id: "Correo",
            mostrar: ({ usuario_registro: { correo } }) => `${correo}`,
            enLista: false,
          },
          {
            id: "Correo personal",
            mostrar: ({ usuario_registro: { correo_personal } }) =>
              `${correo_personal}`,
            enLista: false,
          },
          {
            id: "Celular",
            mostrar: ({ usuario_registro: { celular } }) =>
              `${celular}`,
            enLista: false,
          },
          {
            id: "Telefono",
            mostrar: ({ usuario_registro: { telefono } }) =>
              `${telefono}`,
            enLista: false,
          },
          {
            id: "Tipo de solicitud",
            mostrar: ({ tipo: { nombre } }) => `${nombre}`,
            enLista: false,
          },
          {
            id: "Estado solicitud",
            mostrar: ({ estado_solicitud: { nombre } }) => `${nombre}`,
            enLista: false,
          },
          {
            id: "Motivo negado",
            mostrar: ({ motivo_negado }) => motivo_negado ? motivo_negado : 'N/A',
            enLista: false,
          },
          {
            id: "Autorizo",
            mostrar: ({ autorizo }) => autorizo == 1 ? 'SI' : 'NO',
            enLista: false,
          },
          {
            id: "Fecha Nacimiento",
            mostrar: ({ usuario_registro: { fecha_nacimiento } }) => fecha_nacimiento ? fecha_nacimiento : 'N/A',
            enLista: false,
          },
          {
            id: "Lugar Nacimiento",
            mostrar: ({ usuario_registro: { lugar_nacimiento } }) => lugar_nacimiento ? lugar_nacimiento : 'N/A',
            enLista: false,
          },
          {
            id: "Dirección Residencia",
            mostrar: ({ usuario_registro: { direccion } }) => direccion ? direccion : 'N/A',
            enLista: false,
          },
          {
            id: "Lugar Residencia",
            mostrar: ({ usuario_registro: { lugar_residencia } }) => lugar_residencia ? lugar_residencia : 'N/A',
            enLista: false,
          },
          {
            id: "Tipo Estudio",
            mostrar: ({ tipo_estudio }) => tipo_estudio ? tipo_estudio.nombre : 'N/A',
            enLista: false,
          },
          {
            id: "Programa Procedencia",
            mostrar: ({ programa_procedencia }) => programa_procedencia ? programa_procedencia : 'N/A',
            enLista: false,
          },
          {
            id: "Institución Procedencia",
            mostrar: ({ institucion_procedencia }) => institucion_procedencia ? institucion_procedencia : 'N/A',
            enLista: false,
          },
          {
            id: "Número Créditos",
            mostrar: ({ numero_creditos }) => numero_creditos ? numero_creditos : 'N/A',
            enLista: false,
          }
        ];
      }else if (descargaTipoSolicitud === 'Mat_Pro_Sol_Gra') {
        filas = [
          {
            id: "Fecha de registro",
            mostrar: ({ fecha_registro }) =>
              moment(fecha_registro).format("YYYY-MM-DD, h:mm a"),
            enLista: false,
          },
          {
            id: "Primer Nombre",
            mostrar: ({ usuario_registro: { primer_nombre } }) => `${primer_nombre} `,
						enLista: false
          },
          {
            id: "Segundo Nombre",
            mostrar: ({ usuario_registro: { segundo_nombre } }) => `${segundo_nombre}`,
						enLista: false
          },
          {
            id: "Primer Apellido",
            mostrar: ({ usuario_registro: { primer_apellido } }) => `${primer_apellido}`,
						enLista: false
          },
          {
            id: "Segundo Apellido",
            mostrar: ({ usuario_registro: { segundo_apellido } }) => ` ${segundo_apellido}`,
						enLista: false
          },
          {
            id: "Identificacion",
            mostrar: ({ usuario_registro: { identificacion } }) => `${identificacion}`,
						enLista: false
          },
          {
            id: 'Fecha Expedición',
            mostrar: ({ usuario_registro: { fecha_expedicion } }) => fecha_expedicion ? fecha_expedicion : 'N/A',
            enLista: false
          },
          {
            id: "Lugar de Expedición",
            mostrar: ({ usuario_registro: { lugar_expedicion } }) => lugar_expedicion ? lugar_expedicion : 'N/A',
						enLista: false
          },
          {
            id: "Programa",
            mostrar: ({ programa: { nombre } }) => nombre,
            enLista: false
          },
          {
            id: "Aspirante",
            mostrar: ({ informacion_solicitud_grado }) => informacion_solicitud_grado ? informacion_solicitud_grado.aspirante.nombre : '',
						enLista: false
          },
          {
            id: "Opción de Grado 1",
            mostrar: ({ informacion_solicitud_grado }) => informacion_solicitud_grado ? informacion_solicitud_grado.opcionGrado1.nombre : '',
						enLista: false
          },
          {
            id: "Opción de Grado 2",
            mostrar: ({ informacion_solicitud_grado }) => informacion_solicitud_grado ? informacion_solicitud_grado.opcionGrado2.nombre : '',
						enLista: false
          },
          {
            id: "Nombre de opción de grado tipo 2 ",
            mostrar: ({ informacion_solicitud_grado }) => informacion_solicitud_grado ? informacion_solicitud_grado.trabajo_grado : '',
						enLista: false
          },
          {
            id: "Exoneración",
            mostrar: ({ exoneracion }) => { if (exoneracion) { const { nombre } = exoneracion; return `${nombre}`; } else { return ''; } },
						enLista: false
          },
          {
            id: "Menciones",
            mostrar: ({ informacion_solicitud_grado_mencion }) => informacion_solicitud_grado_mencion,
						enLista: false,
          },
          {
            id: "Correo",
            mostrar: ({ usuario_registro: { correo } }) => `${correo}`,
						enLista: false,
          },
          {
            id: "Correo Personal",
            mostrar: ({ usuario_registro: { correo_personal } }) => `${correo_personal}`,
						enLista: false,
          },
          {
            id: "Celular",
            mostrar: ({ usuario_registro: { celular } }) => `${celular}`,
						enLista: false,
          },
          {
            id: "Telefono",
            mostrar: ({ usuario_registro: { telefono } }) => `${telefono}`,
						enLista: false,
          },
          {
            id: "Tipo de Solicitud",
            mostrar: ({ tipo: { nombre } }) => `${nombre} `,
						enLista: false,
          },
          {
            id: "Estdo Solicitud",
            mostrar: ({ estado_solicitud:  { nombre } }) => `${nombre} `,
						enLista: false,
          },
          {
            id: "Motivo negado",
            mostrar: ({ estado_solicitud: { codigo }, motivo_negado }) => { if (codigo === 'Mat_Rec') { return `${motivo_negado}`; } else { return ''; } },
						enLista: false,
          },
          {
            id: "Autorizo",
            mostrar: ({ autorizo }) => autorizo == 1 ? 'SI' : 'NO',
						enLista: false,
          },
          {
            id: "Fecha Nacimiento",
            mostrar: ({ informacion_solicitud_grado }) => informacion_solicitud_grado ? informacion_solicitud_grado.fecha_nacimiento : '',
						enLista: false,
          },
          {
            id: "Fecha de grado",
            mostrar: ({ informacion_solicitud_grado }) => informacion_solicitud_grado ? informacion_solicitud_grado.fechaGrado.nombre : '',
						enLista: false,
          },
          {
            id: "Periodo termino",
            mostrar: ({ informacion_solicitud_grado }) => informacion_solicitud_grado ? informacion_solicitud_grado.periodo : '',
						enLista: false,
          },
          {
            id: "Dirección de residencia",
            mostrar: ({ informacion_solicitud_grado }) => informacion_solicitud_grado ? informacion_solicitud_grado.direccion_residencia : '',
						enLista: false,
          },
          {
            id: "Ciudad de residencia",
            mostrar: ({ informacion_solicitud_grado }) => informacion_solicitud_grado ? informacion_solicitud_grado.ciudad_residencia : '',
						enLista: false,
          },
          {
            id: "Pais",
            mostrar: ({ informacion_solicitud_grado }) => informacion_solicitud_grado ? informacion_solicitud_grado.pais : '',
						enLista: false,
          },
          {
            id: "Estrato",
            mostrar: ({ informacion_solicitud_grado }) => informacion_solicitud_grado ? informacion_solicitud_grado.estrato.nombre : '',
						enLista: false,
          },
          {
            id: "sexo",
            mostrar: ({ informacion_solicitud_grado }) => informacion_solicitud_grado ? informacion_solicitud_grado.sexo.nombre : '',
						enLista: false,
          },
          {
            id: "Egresado otro programa ",
            mostrar: ({ informacion_solicitud_grado }) => informacion_solicitud_grado ? informacion_solicitud_grado.egresado.nombre : '',
						enLista: false,
          },
          {
            id: "Nombre del programa",
            mostrar: ({ informacion_solicitud_grado }) => informacion_solicitud_grado ? informacion_solicitud_grado.nombre_programa : '',
						enLista: false,
          },
          {
            id: "Ano en que se graduó ",
            mostrar: ({ informacion_solicitud_grado }) => informacion_solicitud_grado ? informacion_solicitud_grado.ano_graduo : '',
						enLista: false,
          },
          {
            id: "Institución educativa",
            mostrar: ({ informacion_solicitud_grado }) => informacion_solicitud_grado ? informacion_solicitud_grado.institucion_educativa : '',
						enLista: false,
          },
          {
            id: "Trabaja actualmente",
            mostrar: ({ informacion_solicitud_grado }) => informacion_solicitud_grado ? informacion_solicitud_grado.trabaja.nombre : '',
						enLista: false,
          },
          {
            id: "Actividad laboral",
            mostrar: ({ informacion_solicitud_grado }) => informacion_solicitud_grado ? informacion_solicitud_grado.actividadLaboral.nombre : '',
						enLista: false,
          },
          {
            id: "Empresa donde labora",
            mostrar: ({ informacion_solicitud_grado }) => informacion_solicitud_grado ? informacion_solicitud_grado.empresa_labora : '',
						enLista: false,
          },
          {
            id: "Dirección",
            mostrar: ({ informacion_solicitud_grado }) => informacion_solicitud_grado ? informacion_solicitud_grado.direccion : '',
						enLista: false,
          },
          {
            id: "Cargo desempeña",
            mostrar: ({ informacion_solicitud_grado }) => informacion_solicitud_grado ? informacion_solicitud_grado.cargo_desempena : '',
						enLista: false,
          },
          {
            id: "Teléfono",
            mostrar: ({ informacion_solicitud_grado }) => informacion_solicitud_grado ? informacion_solicitud_grado.telefono_empresarial : '',
						enLista: false,
          },
          {
            id: "Correo empresarial",
            mostrar: ({ informacion_solicitud_grado }) => informacion_solicitud_grado ? informacion_solicitud_grado.correo_empresarial : '',
						enLista: false,
          },
          {
            id: "Rango salarial",
            mostrar: ({ informacion_solicitud_grado }) => informacion_solicitud_grado ? informacion_solicitud_grado.rango.nombre : '',
						enLista: false,
          },
          {
            id: "Acepto",
            mostrar: ({ informacion_solicitud_grado }) => informacion_solicitud_grado ? informacion_solicitud_grado.acepto.nombre : '',
						enLista: false,
          },
          {
            id: "ENVIADO",
            mostrar: ({ estados_solicitudes }) => {let nombres = ''; estados_solicitudes.forEach(elemento => { if (elemento.estado_matricula.codigo === 'Mat_Env' && elemento.usuario_registro && elemento.usuario_registro.correo) { nombres = elemento.usuario_registro.primer_nombre + ' ' + elemento.usuario_registro.primer_apellido + ' ' + elemento.usuario_registro.segundo_apellido; } }); return nombres;  },
						enLista: false,
          },
          {
            id: "EN ESTUDIO",
            mostrar: ({ estados_solicitudes }) => {let nombres = ''; estados_solicitudes.forEach(elemento => { if (elemento.estado_matricula.codigo === 'Mat_En_Est' && elemento.usuario_registro && elemento.usuario_registro.correo) { nombres = elemento.usuario_registro.primer_nombre + ' ' + elemento.usuario_registro.primer_apellido + ' ' + elemento.usuario_registro.segundo_apellido; } }); return nombres;  },
						enLista: false,
          },
          {
            id: "APROBADO FACULTAD",
            mostrar: ({ estados_solicitudes }) => {let nombres = ''; estados_solicitudes.forEach(elemento => { if (elemento.estado_matricula.codigo === 'Mat_Sol_Gra_Apr_Fac' && elemento.usuario_registro && elemento.usuario_registro.correo) { nombres = elemento.usuario_registro.primer_nombre + ' ' + elemento.usuario_registro.primer_apellido + ' ' + elemento.usuario_registro.segundo_apellido; } }); return nombres;  },
						enLista: false,
          },
          {
            id: "APROBADO TECNOLOGIA",
            mostrar: ({ estados_solicitudes }) => {let nombres = ''; estados_solicitudes.forEach(elemento => { if (elemento.estado_matricula.codigo === 'Mat_Sol_Gra_Apr_Tec' && elemento.usuario_registro && elemento.usuario_registro.correo) { nombres = elemento.usuario_registro.primer_nombre + ' ' + elemento.usuario_registro.primer_apellido + ' ' + elemento.usuario_registro.segundo_apellido; } }); return nombres;  },
						enLista: false,
          },
          {
            id: "APROBADO BIBLIOTECA",
            mostrar: ({ estados_solicitudes }) => {let nombres = ''; estados_solicitudes.forEach(elemento => { if (elemento.estado_matricula.codigo === 'Mat_Sol_Gra_Apr_Bib' && elemento.usuario_registro && elemento.usuario_registro.correo) { nombres = elemento.usuario_registro.primer_nombre + ' ' + elemento.usuario_registro.primer_apellido + ' ' + elemento.usuario_registro.segundo_apellido; } }); return nombres;  },
						enLista: false,
          },
          {
            id: "APROBADO PAZ Y SALVO",
            mostrar: ({ estados_solicitudes }) => {let nombres = ''; estados_solicitudes.forEach(elemento => { if (elemento.estado_matricula.codigo === 'Mat_Sol_Gra_Apr_Paz_Sal' && elemento.usuario_registro && elemento.usuario_registro.correo) { nombres = elemento.usuario_registro.primer_nombre + ' ' + elemento.usuario_registro.primer_apellido + ' ' + elemento.usuario_registro.segundo_apellido; } }); return nombres;  },
						enLista: false,
          },
          {
            id: "MULTA",
            mostrar: ({ estados_solicitudes }) => {let nombres = ''; estados_solicitudes.forEach(elemento => { if (elemento.estado_matricula.codigo === 'Mat_Sol_Gra_Mul' && elemento.usuario_registro && elemento.usuario_registro.correo) { nombres = elemento.usuario_registro.primer_nombre + ' ' + elemento.usuario_registro.primer_apellido + ' ' + elemento.usuario_registro.segundo_apellido; } }); return nombres;  },
						enLista: false,
          },
          {
            id: "NO APARECE EN EL SISTEMA",
            mostrar: ({ estados_solicitudes }) => {let nombres = ''; estados_solicitudes.forEach(elemento => { if (elemento.estado_matricula.codigo === 'Mat_Sol_Gra_No_Sis' && elemento.usuario_registro && elemento.usuario_registro.correo) { nombres = elemento.usuario_registro.primer_nombre + ' ' + elemento.usuario_registro.primer_apellido + ' ' + elemento.usuario_registro.segundo_apellido; } }); return nombres;  },
						enLista: false,
          },
          {
            id: "PENDIENTE ACADEMICAMENTE",
            mostrar: ({ estados_solicitudes }) => {let nombres = ''; estados_solicitudes.forEach(elemento => { if (elemento.estado_matricula.codigo === 'Mat_Sol_Gra_Pen_Aca' && elemento.usuario_registro && elemento.usuario_registro.correo) { nombres = elemento.usuario_registro.primer_nombre + ' ' + elemento.usuario_registro.primer_apellido + ' ' + elemento.usuario_registro.segundo_apellido; } }); return nombres;  },
						enLista: false,
          },
          {
            id: "PENDIENTE CREDITO",
            mostrar: ({ estados_solicitudes }) => {let nombres = ''; estados_solicitudes.forEach(elemento => { if (elemento.estado_matricula.codigo === 'Mat_Sol_Gra_Pen_Cre' && elemento.usuario_registro && elemento.usuario_registro.correo) { nombres = elemento.usuario_registro.primer_nombre + ' ' + elemento.usuario_registro.primer_apellido + ' ' + elemento.usuario_registro.segundo_apellido; } }); return nombres;  },
						enLista: false,
          },
          {
            id: "PENDIENTE PRESTAMO ACTIVO",
            mostrar: ({ estados_solicitudes }) => {let nombres = ''; estados_solicitudes.forEach(elemento => { if (elemento.estado_matricula.codigo === 'Mat_Sol_Gra_Pre_Act' && elemento.usuario_registro && elemento.usuario_registro.correo) { nombres = elemento.usuario_registro.primer_nombre + ' ' + elemento.usuario_registro.primer_apellido + ' ' + elemento.usuario_registro.segundo_apellido; } }); return nombres;  },
						enLista: false,
          },
          {
            id: "PENDIENTE TRABAJO DE GRADO",
            mostrar: ({ estados_solicitudes }) => {let nombres = ''; estados_solicitudes.forEach(elemento => { if (elemento.estado_matricula.codigo === 'Mat_Sol_Gra_Pen_Trab_Gra' && elemento.usuario_registro && elemento.usuario_registro.correo) { nombres = elemento.usuario_registro.primer_nombre + ' ' + elemento.usuario_registro.primer_apellido + ' ' + elemento.usuario_registro.segundo_apellido; } }); return nombres;  },
						enLista: false,
          },
          {
            id: "TRAER PAZ Y SALVO - CUCFIE",
            mostrar: ({ estados_solicitudes }) => {let nombres = ''; estados_solicitudes.forEach(elemento => { if (elemento.estado_matricula.codigo === 'Mat_Sol_Gra_Paz_Sal_Cuc' && elemento.usuario_registro && elemento.usuario_registro.correo) { nombres = elemento.usuario_registro.primer_nombre + ' ' + elemento.usuario_registro.primer_apellido + ' ' + elemento.usuario_registro.segundo_apellido; } }); return nombres;  },
						enLista: false,
          },
          {
            id: "PENDIENTE GIRO ICETEX",
            mostrar: ({ estados_solicitudes }) => {let nombres = ''; estados_solicitudes.forEach(elemento => { if (elemento.estado_matricula.codigo === 'Mat_Sol_Gra_Paz_Sal_Ser' && elemento.usuario_registro && elemento.usuario_registro.correo) { nombres = elemento.usuario_registro.primer_nombre + ' ' + elemento.usuario_registro.primer_apellido + ' ' + elemento.usuario_registro.segundo_apellido; } }); return nombres;  },
						enLista: false,
          },
          {
            id: "RECHAZADO FACULTAD",
            mostrar: ({ estados_solicitudes }) => {let nombres = ''; estados_solicitudes.forEach(elemento => { if (elemento.estado_matricula.codigo === 'Mat_Rein_Tran_Rec_Fac' && elemento.usuario_registro && elemento.usuario_registro.correo) { nombres = elemento.usuario_registro.primer_nombre + ' ' + elemento.usuario_registro.primer_apellido + ' ' + elemento.usuario_registro.segundo_apellido; } }); return nombres;  },
						enLista: false,
          },
          {
            id: "PROCESANDO",
            mostrar: ({ estados_solicitudes }) => {let nombres = ''; estados_solicitudes.forEach(elemento => { if (elemento.estado_matricula.codigo === 'Mat_Pro' && elemento.usuario_registro && elemento.usuario_registro.correo) { nombres = elemento.usuario_registro.primer_nombre + ' ' + elemento.usuario_registro.primer_apellido + ' ' + elemento.usuario_registro.segundo_apellido; } }); return nombres;  },
						enLista: false,
          },
          {
            id: "TERMINADO",
            mostrar: ({ estados_solicitudes }) => {let nombres = ''; estados_solicitudes.forEach(elemento => { if (elemento.estado_matricula.codigo === 'Mat_Ter' && elemento.usuario_registro && elemento.usuario_registro.correo) { nombres = elemento.usuario_registro.primer_nombre + ' ' + elemento.usuario_registro.primer_apellido + ' ' + elemento.usuario_registro.segundo_apellido; } }); return nombres;  },
						enLista: false,
          },
          {
            id: "GRADUADO",
            mostrar: ({ estados_solicitudes }) => {let nombres = ''; estados_solicitudes.forEach(elemento => { if (elemento.estado_matricula.codigo === 'Mat_Gra' && elemento.usuario_registro && elemento.usuario_registro.correo) { nombres = elemento.usuario_registro.primer_nombre + ' ' + elemento.usuario_registro.primer_apellido + ' ' + elemento.usuario_registro.segundo_apellido; } }); return nombres;  },
						enLista: false,
          },
          {
            id: "RECHAZADA",
            mostrar: ({ estados_solicitudes }) => {let nombres = ''; estados_solicitudes.forEach(elemento => { if (elemento.estado_matricula.codigo === 'Mat_Rec' && elemento.usuario_registro && elemento.usuario_registro.correo) { nombres = elemento.usuario_registro.primer_nombre + ' ' + elemento.usuario_registro.primer_apellido + ' ' + elemento.usuario_registro.segundo_apellido; } }); return nombres;  },
						enLista: false,
          },

      
      ];
      }
      this.setState({ filasInforme:filas });
      let data = await crear_form_data({ descargaSolicitud, descargaTipoSolicitud, periodo_informe })
      formulario(
        `api/v1.0/matriculas/informe2`,
        data,
        "post",
        (error, estado, resp) => {
          if (estado === 200 && !error) {
            this.setState({
              informeExcel: resp,
              descargaSolicitudFormulario: false,
              descargaSolicitudCargar: false,
              descargaSolicitudLista: true,
            });
          }
        }
      );
      this.setState({
        descargaSolicitudFormulario: false,
        descargaSolicitudCargar: true,
        descargaSolicitudLista: false,
      });
    }
  };

  onCloseDescaragrExcelInforme = () => {
    this.setState({
      modalDesacrgarPorSolicitud: false,
      descargaSolicitudFormulario: true,
      descargaSolicitudCargar: false,
      descargaSolicitudLista: false,
    });
  };

  cambiarEstadoAGraduado = async () => {
    const { actualizarMensaje } = this.props;
    const { procesoActual } = this.state;
    let data = {
      matricula: procesoActual.id,
    };
    consulta( "api/v1.0/matricula/solicitud/estudiantegraduado", data, "POST", (error, estado, resp) => {
      let title = "Ha ocurrido un error, contacte con el administrador.";
      let icon = "error";
      if (!error) {
        if (estado === 200) {
          icon = "success";
          title = resp.titulo;
          this.listarMatriculas(this.configFiltros(this.state.accionProceso).concat(this.state.filtros));
          // this.listarMatriculas(this.state.filtros);
        } else {
          icon = "info";
          title = resp.titulo;
        }
      }
      actualizarMensaje({ titulo: title, tipo: icon, mostrar: true, tiempo: 6000, });
      this.setState({ modalPasarAGraduado: false });
    });
  };

  pintar = () => {
    let {
      seleccion,
      render,
      admin,
      opcion,
      procesoActual,
      numgestion,
      survey,
      encuestas,
      accionProceso,
      modalFechaSolicitud,
      modalMenciones,
      modalPasarAGraduado,
      submodulos,
    } = this.state;
    const {
      modalDetalle,
      modalFiltros,
      showModalFiltros,
      showModalDetalle,
      modalGestionar,
      showModalGestionar,
      showModalProgramas,
      actualizarMensaje,
      showModalObservaciones,
      modalObservaciones,
      usuario
    } = this.props;
    return (
      <div>
        <MenuAdministrar
          seleccionar={(seleccion) => this.cambiarEstado({ seleccion })}
          sele={seleccion}
          admin={admin}
          submodulos={submodulos}
        />
        <div className="contenido_modulo_matriculas">
          {render ? (
            <div>
              {seleccion === 'administrar' ? this.pintarAdministrar() : this.renderProcesos() }
              {/* {submodulos.filter((elemento) => elemento.codigo == seleccion) && this.renderProcesos()} */}
              
            </div>
          ) : (
            <TareasTerminadas
              mensaje="Cargando.."
              marginTop="7%"
              imagen={emma_w}
              widthImg="7%"
              cargando={true}
            />
          )}
          {/* boton descargar excel */}
          {
            <ExcelFile
              filename={`encuestas`}
              element={<button className="oculto" id={"excel-encuesta"} />}
            >
              <ExcelSheet data={encuestas} name="datos">
                {encuestas[0] &&
                  encuestas[0].map((fila, i) => {
                    return (
                      <ExcelColumn
                        key={i}
                        label={`${fila.pregunta.nombre}`}
                        value={(e) => {
                          return e[i].respuesta;
                        }}
                      />
                    );
                  })}
              </ExcelSheet>
            </ExcelFile>
          }
          <ModalDetalleSolicitud
            open={modalDetalle}
            setOpen={showModalDetalle}
            matricula={procesoActual}
            opcion={opcion}
            cambiarEstado={this.cambiarEstado}
            admin={true}
            actualizarMensaje={actualizarMensaje}
            gestion={numgestion}
            openGestionar={showModalGestionar}
            openObs={modalObservaciones}
            setOpenObs={showModalObservaciones}
            usuario={usuario}
          />
          <MatriculasFiltrar
            downloadExcelEncuestas={this.downloadExcelEncuestas}
            survey={survey}
            handlerSurvey={this.handlerSurvey}
            setOpen={showModalFiltros}
            open={modalFiltros}
            seleccion={submodulos.filter((elemento) => elemento.codigo === seleccion)}
            accionProceso={accionProceso}
            mostrarTodos={(accion) => this.setState({ accionProceso: accion })}
            listarHistorial={(filtros) =>
              this.listarMatriculas(
                this.configFiltros(accionProceso).concat(filtros)
              )
            }
          />
          <MatriculasGestionar
            open={modalGestionar}
            setOpen={showModalGestionar}
            matricula={procesoActual}
            gestionarMatricula={this.gestionarMatricula}
            cambiarEstado={this.cambiarEstado}
            actualizarMensaje={actualizarMensaje}
          />
          <ModalFechaGrado
            modalFechaSolicitud={modalFechaSolicitud}
            handleCloseModal={() =>
              this.setState({ modalFechaSolicitud: false })
            }
            matricula={procesoActual}
            actualizarMensaje={actualizarMensaje}
          />
          <ModalFechaGrado
            modalFechaSolicitud={modalFechaSolicitud}
            handleCloseModal={() =>
              this.setState({ modalFechaSolicitud: false })
            }
            matricula={procesoActual}
            actualizarMensaje={actualizarMensaje}
          />
          <ModalMenciones
            modalFechaSolicitud={modalMenciones}
            handleCloseModal={() => this.setState({ modalMenciones: false })}
            matricula={procesoActual}
            actualizarMensaje={actualizarMensaje}
          />
          <ModalPasarAGraduado
            open={modalPasarAGraduado}
            onClose={() => this.setState({ modalPasarAGraduado: false })}
            titulo={"! ESTUDIANTE GRADUADO ¡"}
            matricula={procesoActual}
            onClick={this.cambiarEstadoAGraduado}
          />
        </div>
      </div>
    );
  };

  handleClickOpcion = (opcion, parametro) => {
    this.setState({ tipoSolicitud: opcion, parametro });
  };

  // pintarHistorial() {
  //   const { showModalDetalle, showModalGestionar, showModalFiltros, misDatos } =
  //     this.props;
  //   const { filtros, cargando } = this.state;
  //   const { matriculas, actualizarMensaje, mostrarEditarMatricula, modalEditarMatricula } = this.props;
  //   return (
  //     <HistorialAdmin
  //       showModalPasarAGraduado={() =>
  //         this.setState({ modalPasarAGraduado: true })
  //       }
  //       showModalCambiarFecha={() =>
  //         this.setState({ modalFechaSolicitud: true })
  //       }
  //       showModalMenciones={() => this.setState({ modalMenciones: true })}
  //       showModalDetalle={showModalDetalle}
  //       showModalGestionar={showModalGestionar}
  //       showModalFiltros={showModalFiltros}
  //       historial={matriculas}
  //       listarHistorial={this.listarMatriculas}
  //       misDatos={misDatos}
  //       cambiarEstado={this.cambiarEstado}
  //       filtros={filtros}
  //       cargando={cargando}
  //       resetOpcion={(opc = "detalle") => this.setState({ opcion: opc })}
  //       habilitarGestion={(num) => this.setState({ numgestion: num })}
  //       handlerSurvey={this.handlerSurvey}
  //       actualizarMensaje={actualizarMensaje}
  //       mostrarEditarMatricula={mostrarEditarMatricula}
	// 			modalEditarMatricula={modalEditarMatricula} 
  //     />
  //   );
  // }

  pintarInicioProcesos = () => {
    let {
      estados_procesos,
      filtrarPeriodo,
      periodo_filtro,
      periodos,
      cargandoPeriodo,
      tipos_procesos,
    } = this.state;
    let {
      descargaSolicitud,
      modalDesacrgarPorSolicitud,
      descargaSolicitudFormulario,
      descargaSolicitudCargar,
      descargaSolicitudLista,
      informeExcel,
      descargaTipoSolicitud,
      filasInforme,
      periodo_informe
    } = this.state;
    let { showModalFiltros } = this.props;

    const obtenerColor = (codigo) => {
      let color = "white";
      const colores = {
        'Mat_Env': { backgroundColor: "#f5f5f5", color: "#757575" },
				'Mat_En_Est': { backgroundColor: '#AFF9DB', color: "#000" },
        'Mat_Pro': { backgroundColor: "#ffc107", color },
        'Mat_Rec': { backgroundColor: "#b71c1c", color },
        'Mat_Com_Env': { backgroundColor: '#DBB62C', color },
        'Mat_Pag': { backgroundColor: '#3498db', color },
        'Mat_Aval_Fac_Rein_Tran': { backgroundColor: '#5cb85c', color },
        'Mat_Ter': { backgroundColor: "#009688", color },
        'Mat_Pro_Ter_Exo': { backgroundColor: "#009688", color },
        'Mat_Pro_Sol_Cam_Fec': { backgroundColor: "#009688", color },
        'Mat_Gra': { backgroundColor: "#009688", color },
        'Tod_Mat': { backgroundColor: "#0d47a1", color },
				'Mat_Sol_Rever': { backgroundColor: '#0d47a1', color },
				'Mat_Rein_Tran_Apr_Fac': { backgroundColor: '#5cb85c', color },
				'Mat_Rein_Tran_Rec_Fac': { backgroundColor: '#b71c1c', color },
				'Mat_Rein_Tran_En_Valid_Fac': { backgroundColor: '#ffc107', color },
		  'Mat_Act_Dat_Apro_Reg': { backgroundColor: '#2c1d58', color },
		  'Mat_Act_Dat_Re_Reg': { backgroundColor: '#bd3a2f', color },
		  'Mat_Act_Dat_Rev_Reg': { backgroundColor: '#044a2b', color },
		  'Mat_Act_Dat_Apro_Cre': { backgroundColor: '#2950a6', color },
		  'Mat_Act_Dat_Re_Cred': { backgroundColor: '#340e25', color },
		  'Mat_Act_Dat_Apr_Tec': { backgroundColor: '#a8b4d5', color },
		  'Mat_Act_Dat_Re_Tec': { backgroundColor: '#e382a3', color },
		  'Mat_Rein_Tran_Doc_Ok': { backgroundColor: '#2950a6', color },
			'Mat_Rein_Tran_Est': { backgroundColor: '#2950a6', color },
      'Mat_Act_Pen': { backgroundColor: "#ffc107", color },
      'Mat_Act_Rev_Are': { backgroundColor: '#3498db', color },
      'Mat_cer_val_soli':{ backgroundColor: '#2AF40A', color },
      'Mat_cer_pago_vol': { backgroundColor: "#ACC7A1", color },
      'Mat_cer_val_pago': { backgroundColor: '#C7B713', color },
      'Mat_Rein_Tran_Est_Hom_Apro': { backgroundColor: '#2AF40A', color },
      'Mat_Rein_Tran_Est_Hom_Recha': { backgroundColor: '#0d47a1', color },
      'Mat_Rein_Tran_Apr': { backgroundColor: '#ec9c06', color }
      };
      return colores[codigo];
    };

    const handleClick = (codigo) => {
      if (codigo != "Tod_Mat") this.setState({ accionProceso: codigo });
      else {
        showModalFiltros(true);
      }
    };

    return (
      <>
        <div style={{ padding: "2% 2% 0% 2%" }}>
          <Grid container direction={"row"} spacing={1} justify={"center"}>
            <Grid item xs={12} sm={12} md={12} lg={10}>
              <Grid
                container
                direction={"row"}
                alignItems="center"
                spacing={1}
                style={{ width: "100%" }}
              >
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <TituloAccion
                    principal=""
                    alterno="Seleccione el estado a gestionar: "
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Tooltip
                    title="Descargar"
                    aria-label="add"
                    onClick={() =>
                      this.cambiarEstado({ modalDesacrgarPorSolicitud: true })
                    }
                  >
                    <IconButton
                      edge={false}
                      color="secondary"
                      style={{ float: "right" }}
                    >
                      <GetAppIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title="Filtrar"
                    aria-label="add"
                    onClick={() => this.ModalfiltroPeriodo()}
                  >
                    <IconButton
                      edge={false}
                      color="secondary"
                      style={{ float: "right" }}
                    >
                      <FilterListIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              <Paper elevation={0} square className="scroll contenido_fijo">
                {estados_procesos.length === 0 ? (
                  <TareasTerminadas
                    mensaje="No hay solicitudes disponibles a mostrar, por favor seleccione un tipo de solicitud en la parte izquierda"
                    marginTop="7%"
                    imagen={emma_s1}
                    widthImg="7%"
                  />
                ) : (
                  <Grid
                    container
                    direction={"row"}
                    spacing={1}
                    style={{ width: "100%" }}
                  >
                    {estados_procesos.map(
                      (
                        {
                          estado_solicitud__codigo: codigo,
                          estado_solicitud__nombre: nombre,
                          num_sol,
                        },
                        i
                      ) => {
                        return (
                          <Grid item xs={12} sm={12} md={6} lg={6} key={i}>
                            <Paper
                              elevation={1}
                              style={{ border: "1px solid #e0e0e0" }}
                              onClick={() => handleClick(codigo)}
                            >
                              <AccionesRapidas
                                imagen=""
                                titulo={`¡ ${nombre} !`}
                                sub_titulo=""
                                des_sub_titulo=""
                                avatar={obtenerColor(codigo)}
                                avatar_name={nombre.charAt(0).toUpperCase()}
                                list_desc={
                                  Number.isInteger(num_sol)
                                    ? [`Numero de Solicitudes : ${num_sol}`]
                                    : ["Debe aplicar los filtros."]
                                }
                              />
                            </Paper>
                          </Grid>
                        );
                      }
                    )}
                  </Grid>
                )}
              </Paper>
            </Grid>
          </Grid>
        </div>

        <Dialog
          fullWidth={true}
          maxWidth="xs"
          open={filtrarPeriodo}
          onClose={() => this.cambiarEstado({ filtrarPeriodo: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <AppBarModal
            titulo="¡ Aplicar Filtros !"
            mostrarModal={() => this.cambiarEstado({ filtrarPeriodo: false })}
            titulo_accion=""
            accion=""
          />
          <DialogContent className="scroll">
            {!cargandoPeriodo ? (
              <Grid container className="">
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                  <FormControl className="form-control">
                    <InputLabel htmlFor="periodo_filtro">
                      Seleccione Periodo
                    </InputLabel>
                    <Select
                      inputProps={{
                        name: "periodo_filtro",
                        id: "periodo_filtro",
                      }}
                      value={periodo_filtro}
                      onChange={this.onChange}
                      required={true}
                    >
                      {periodos.map(({ periodo }, i) => (
                        <MenuItem key={i} value={periodo}>
                          {periodo}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            ) : (
              <TareasTerminadas
                imagen={emma_w}
                mensaje="Cargando..."
                widthImg="5%"
                marginTop="5%"
              />
            )}
          </DialogContent>
          <DialogActions>
            <BtnForm
              texto="CANCELAR"
              callback={() => this.cambiarEstado({ filtrarPeriodo: false })}
            />
            <BtnForm
              texto="FILTRAR"
              callback={() => this.filtrarEstadoSolicitudes()}
            />
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={modalDesacrgarPorSolicitud}
          onClose={this.onCloseDescaragrExcelInforme}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <AppBarModal
            titulo="¡ Descargar Informe por Solicitud !"
            mostrarModal={this.onCloseDescaragrExcelInforme}
            titulo_accion=""
            accion=""
          />
          <DialogContent className="scroll">
            {descargaSolicitudFormulario && (
              <Grid container spacing={3} className="">


                <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>

                <FormControl className='form-control'>
										<InputLabel htmlFor="periodo_informe">Seleccione Periodo</InputLabel>
										<Select
											inputProps={{
												name: 'periodo_informe',
												id: 'periodo_informe',
											}}
											value={periodo_informe}
											onChange={this.onChange}
											required={true}>
											{periodos.map(({ periodo }, i) => <MenuItem key={i} value={periodo}>{periodo}</MenuItem>)}
										</Select>
									</FormControl>
                  <FormControl className="form-control">
                    <InputLabel htmlFor="descargaSolicitud">
                      Seleccione Solicitud
                    </InputLabel>
                    <Select
                      inputProps={{
                        name: "descargaSolicitud",
                        id: "descargaSolicitud",
                      }}
                      value={descargaSolicitud}
                      onChange={this.onChange}
                      required={true}
                    >
                      {estados_procesos.map(
                        (
                          {
                            estado_solicitud__codigo,
                            estado_solicitud__nombre,
                          },
                          i
                        ) => (
                          <MenuItem key={i} value={estado_solicitud__codigo}>
                            {estado_solicitud__nombre.toUpperCase()}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>  
                  <FormControl className="form-control">
                    <InputLabel htmlFor="descargaTipoSolicitud">Seleccione Tipo Solicitud</InputLabel>
                      <Select
                        inputProps={{
                          name: 'descargaTipoSolicitud',
                          id: 'descargaTipoSolicitud',
                        }}
                        value={descargaTipoSolicitud}
                        onChange={this.onChange}
                        required={true}>
                        {
                          tipos_procesos.map(({ tipo__codigo, tipo__nombre }, i) => <MenuItem key={i} value={tipo__codigo}>{tipo__nombre.toUpperCase()}</MenuItem>)
                        }
                      </Select>
                  </FormControl>
                </Grid>
              </Grid>
            )}
            {descargaSolicitudCargar && (
              <TareasTerminadas
                imagen={emma_w}
                mensaje="Estamos validando la informacion, esto puede tardar unos segundos ..."
                widthImg="5%"
                marginTop="5%"
                cargando={true}
              />
            )}
            {descargaSolicitudLista && (
              <ListarDatos
                datos={informeExcel}
                opciones={true}
                id="tbl_informe_Excel"
                avatar={({ estado_solicitud: { nombre } }) => nombre.charAt(0)}
                titulo="Lista Informe"
                descargar="Informe - EMMA"
                fila_principal={
                  ({fecha_registro, usuario_registro: { primer_nombre, primer_apellido }, tipo: { nombre }}) => `${primer_nombre} ${primer_apellido} - ` + moment(fecha_registro).format("YYYY-MM-DD, h:mm a") + ` - ${nombre.toUpperCase()}`
                }
                filas={filasInforme}
              />
            )}
          </DialogContent>
          <DialogActions>
            <BtnForm
              texto="CANCELAR"
              callback={this.onCloseDescaragrExcelInforme}
            />
            {!descargaSolicitudCargar && !descargaSolicitudLista && (<BtnForm texto="GENERAR" callback={this.downloadExcelInforme} />)}
          </DialogActions>
        </Dialog>
      </>
    );
  };

  ModalfiltroPeriodo = async () => {
    let { cargarFiltroP, periodo } = this.state;
    this.cambiarEstado({ filtrarPeriodo: true });
    if (cargarFiltroP) {
      this.cambiarEstado({ cargandoPeriodo: true });
      let periodos = await this.obtenerPeriodos();
      if(!(periodos.find(e => e.periodo == periodo))) periodos.push({'periodo': periodo})
      this.cambiarEstado({
        periodos,
        cargandoPeriodo: false,
        cargarFiltroP: false,
      });
    }
  };

  filtrarEstadoSolicitudes = () => {
    let { periodo_filtro } = this.state;
    this.cambiarEstado({ filtrarPeriodo: false, render: false });
    this.obtenerEstadoSolicitudes(periodo_filtro);
    this.obtenerTipoSolicitudes(periodo_filtro);
  };

  obtenerPeriodos = () => {
    let {seleccion} = this.state;
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/matriculas/periodos/${seleccion}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(resp);
        }
      );
    });
  };

  obtenerEstadoSolicitudes = (periodo) => {
    let {seleccion} = this.state;
    if(periodo){
      consulta(
        `api/v1.0/matriculas/estado_solicitud/${periodo}/${seleccion}`,
        null,
        null,
        (error, estado, resp) => {
          if (estado === 200) {
            let { general, extra } = resp;
            if (extra.length > 0) {
              extra.map(({ solicitudes, nombre, codigo, orden }) => {
                let reporte = {
                  estado_solicitud__codigo: codigo,
                  estado_solicitud__nombre: nombre,
                  num_sol: solicitudes,
                  orden: orden,
                };
                general.push(reporte);
              });
            }
            this.cambiarEstado({
              estados_procesos: general.sort((a, b) => a.orden - b.orden),
              render: true,
            });
          }
        }
      );
    }
  };

  obtenerInformes = async () => {
    let informes = []
    this.setState({cargando : true})
    let datos = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': 34 }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valorc', 'valor': 'informe'}]);
    if (datos.length > 0) {
      datos.map(({ nombre, codigo, valorc }) => {
        let reporte = {
          'nombre': nombre,
          'codigo': codigo,
          'valorc': valorc
        }
        informes.push(reporte);
      })
		}
    this.setState({informes, cargando : false, render: true})
  }

  obtenerTipoSolicitudes = (periodo) => {
		consulta(`api/v1.0/matriculas/tipo_solicitud/${periodo}`, null, null, (error, estado, resp) => {
			if (estado === 200) {
				let { general, extra } = resp
				if (extra.length > 0) {
					extra.map(({ solicitudes, nombre, codigo, orden }) => {
						let reporte = {
							'tipo__codigo': codigo,
							'tipo__nombre': nombre,
							'num_sol': solicitudes,
							'orden': orden
						}
						general.push(reporte);
					})
				}
				this.cambiarEstado({ tipos_procesos: general.sort((a, b) => a.orden - b.orden), render: true });
			}
		})
	}

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value,
    });
  };

  // renderProcesos() {
  //   let { accionProceso } = this.state;
  //   return (
  //     <>
  //       {accionProceso === "inicio"
  //         ? this.pintarInicioProcesos()
  //         : this.pintarHistorial()}
  //       {accionProceso !== "inicio" && (
  //         <div className={"regresar"}>
  //           <BtnRegresar
  //             callback={() =>
  //               this.setState({ filtros: [], accionProceso: "inicio" })
  //             }
  //           />
  //         </div>
  //       )}
  //     </>
  //   );
  // }

  configFiltros = (accion_actual) => {
    let { periodo_filtro, seleccion } = this.state;
    switch (accion_actual) {
      case "Tod_Mat":
        return [{ llave: "periodo", valor: periodo_filtro }, { 'llave': 'tipo__codigo', 'valor': seleccion }];
      case "inicio":
        return [{ llave: "periodo", valor: periodo_filtro }, { 'llave': 'tipo__codigo', 'valor': seleccion }];
      default:
        return [
          { llave: "estado_solicitud__codigo", valor: accion_actual },
          { llave: "periodo", valor: periodo_filtro },
          { 'llave': 'tipo__codigo', 'valor': seleccion }
        ];
    }
  };

  // render() {
  //   return this.pintar();
  // }
}

const mapStateToProps = (state) => {
  let {
    misDatos,
    modalSolicitudes,
    modalDetalle,
    modalGestionar,
    modalFiltros,
    matriculas,
    modalProgramas,
    modalObservaciones,
    modalEditarMatricula,
    modalInforme,
    modalModPeriodo
  } = state.redMatriculas;
  let { usuario } = state.redGlobal;
  return {
    modalSolicitudes,
    modalDetalle,
    modalGestionar,
    modalFiltros,
    misDatos,
    usuario,
    matriculas,
    modalProgramas,
    modalObservaciones,
    modalEditarMatricula,
    modalInforme,
    modalModPeriodo
  };
};

const mapDispatchToProps = {
  showModalSolicitudes,
  showModalDetalle,
  showModalGestionar,
  showModalFiltros,
  showModalProgramas,
  setMisDatos,
  mostrarModulo,
  setMatriculas,
  actualizarMensaje,
  showModalObservaciones,
  mostrarEditarMatricula,
  mostrarModalInforme,
  mostrarModalModPeriodo
};
MatriculasAdm.propTypes = {
  //variables
  mostrarModalModPeriodo: PropTypes.func.isRequired,
}
export default connect(mapStateToProps, mapDispatchToProps)(MatriculasAdm);
