import React from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Button } from '@material-ui/core';
import { useForm } from '../../../hooks/useForm';

import { formulario, Toast, obtenerValores, modificarValorGenerica, crear_form_data } from '../../../global/js/funciones';
import { guardarPermiso } from './helper';

const FormularioDinamico = ({ datosMateria, fields, parametro, cambiarEstado, seleccion, programa, cargarPermisos, mostrarModalMod }) => {
	
	const inputFields = (datosMateria)
	? fields.map((item) => ({ [item.name]: datosMateria[item.name] }))
	: fields.map(({ name }) => ({ [name]: '' }));

	const [ formValues, handleInputChange, reset ] = useForm(Object.assign(...inputFields));

	const handleSubmit = async () => {
		let dataAux =  { ...formValues, generica: parametro, codigo: '' };

		if(datosMateria){
			const { resp } = await modificarValorGenerica(
				datosMateria.id, 
				datosMateria.codigo == null ? '': datosMateria.codigo, 
				formValues["nombre"], 
				datosMateria.descripcion, 
				formValues["valora"], 
				datosMateria.valorb, 
				datosMateria.valorc, 
				formValues["valord"], 
				formValues["valore"], 
				formValues["valorf"], 
				formValues["valorg"], 
				datosMateria.archivo == null ? '' : datosMateria.archivo
			);
			const { titulo: title } = resp;
			Toast.fire({ title, icon: 'success' });
			cargarPermisos(programa)
			mostrarModalMod(false);
			return
		}
		let form_data = await crear_form_data(dataAux)
		formulario('api/v1.0/valores/crear', form_data, 'post',
			async (error, estado, resp) => {
				const { titulo: title, valor_id } = resp;
				Toast.fire({ title, icon: 'success' });
				if (seleccion === 'diplomados') {
					const data = await obtenerValores(parametro);
					cambiarEstado({ data });
				} else guardarPermiso(valor_id, programa, cargarPermisos);
				reset();
			}
		);
	};

	return (
		<ValidatorForm onSubmit={handleSubmit}>
			{fields.map(({ name, label, type }, i) => {
				let validators, errorMessages;
				if (type === 'number') {
					validators = [ 'minNumber:0', 'required' ];
					errorMessages = [ 'Este campo debe ser numérico' ];
				} else {
					validators = [ 'required' ];
					errorMessages = [ 'Este campo es obligatorio' ];
				}

				return (
					<TextValidator
						key={`input-${i}`}
						variant='outlined'
						margin='dense'
						id={name}
						label={label}
						type={type}
						fullWidth
						name={name}
						value={ formValues[name]}
						validators={validators}
						errorMessages={errorMessages}
						onChange={handleInputChange}
						autoComplete='off'
					/>
				);
			})}
			<Button color='primary' type='submit' id='enviar_form_add_parametro' className='oculto'>
				{' '}
				Guardar{' '}
			</Button>
		</ValidatorForm>
	);
};

export default FormularioDinamico;
