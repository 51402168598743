import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  InputLabel,
  FormControl,
  Grid,
  MenuItem,
  Select,
} from "@material-ui/core";
import AppBarModal from "../../general/AppBarModal";
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from "../../../global/imagenes/emma_w.png";
import { BtnForm } from "../../general/BotonesAccion";

const FiltrarCursos = ({
  open,
  setOpen,
  cargarPermisos,
  permisos,
  cambiarEstado,
  programa,
}) => {
  // state cargando
  const [cargando, setCargando] = useState(false);
  const [estado, setEstado] = useState("");

  const handleSubmit = async () => {
    setOpen(false);
    // cargar los permisos

    if (estado === "") {
      return;
    }
    await cargarPermisos(programa);

    // cancelar si no elige un opcion

    // filtrar el array
    const permisosFiltrados = permisos.filter((permiso) =>
      estado === 1 ? permiso.permiso !== 0 : permiso.permiso === estado
    );
    cambiarEstado({ permisos: permisosFiltrados });
  };

  // state select
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBarModal
          titulo="¡ Aplicar Filtros !"
          mostrarModal={() => setOpen(false)}
          //   titulo_accion={habilitar_limpiar ? "Limpiar" : ""}
          //   accion={habilitar_limpiar ? this.limpiarFiltros : ""}
        />
        <DialogContent className="scroll">
          {cargando ? (
            <TareasTerminadas
              mensaje="Cargando.."
              marginTop="7%"
              imagen={emma_w}
              widthImg="20%"
            />
          ) : (
            <>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Seleccione una opción
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={estado}
                      onChange={(e) => setEstado(e.target.value)}
                    >
                      <MenuItem value="">
                        <em>Seleccione una opción</em>
                      </MenuItem>
                      <MenuItem value={1}>Asignado</MenuItem>
                      <MenuItem value={0}>No Asignado</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CANCELAR" callback={() => setOpen(false)} />
          {!cargando && <BtnForm texto="FILTRAR" callback={handleSubmit} />}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FiltrarCursos;
