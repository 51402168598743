import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { BtnForm } from "../../general/BotonesAccion";
import AppBarModal from '../../general/AppBarModal'
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { obtenerValoresFiltros, transformar } from "../../../global/js/funciones";
import BuscarSelect from '../../general/BuscarSelect';
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from '../../../global/imagenes/emma_w.png';
import { ARLS, TEMAS_PRACTICAS_CLINICAS, EMPRESAS, TIPOS_PRACTICAS } from '../helper';



class PracticasMasivoFiltrar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      arls: [],
      arl: '',
      empresas: [],
      empresa: '',
      tipos: [],
      tipo: '',
      temas: [],
      tema: '',
      cargando: true,
      cargar: true,
    }
  }


  async componentDidUpdate({ modalMasivoFiltro }) {
    let { modalMasivoFiltro: modalAddFiltrosNew } = this.props;
    if (modalMasivoFiltro !== modalAddFiltrosNew && this.state.cargar) {
      this.setState({ cargando: true })
      let tipos = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_PRACTICAS }, { 'llave': 'estado', 'valor': 1 }]);
      const empresas = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': EMPRESAS }, { 'llave': 'estado', 'valor': 1 }]);
      const arls = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': ARLS }, { 'llave': 'estado', 'valor': 1 }]);
      const temas = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TEMAS_PRACTICAS_CLINICAS }, { 'llave': 'estado', 'valor': 1 }])

      tipos = tipos.filter((item) => { if(item.valord==1) return item  } )

      this.setState({ arls, empresas: transformar(empresas), tipos, temas, cargando: false, cargar: false })
    }
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  changeState = (estados) => {
    this.setState(estados)
  }

  resetState = () => {
    this.setState({
      tipo: '',
      empresa: '',
      arl: '',
      tema: ''
    })
  }

  onSubmit = e => {
    let { filtrar, actualizarMensaje, accionProceso, mostrarTodos, mostrarModalMasivoFiltro, estadoSeleccione } = this.props;
    let { tipo, arl, empresa, tema } = this.state;

    let filtros = [];
    if (tipo !== '') filtros.push({ 'llave': 'tipo', 'valor': tipo });
    if (arl != '') filtros.push({ 'llave': 'arl', 'valor': arl})
    if (empresa != '') filtros.push({ 'llave': 'empresa', 'valor': empresa.value })
    if (tema != '') filtros.push({ 'llave': 'opcion_materia', 'valor': tema })
    if (filtros.length > 0){
      if (accionProceso == 'inicio') mostrarTodos('Tod_Pra')
      mostrarModalMasivoFiltro(false)
      filtrar(filtros);
      estadoSeleccione(true)
      this.resetState()
    } else actualizarMensaje({ titulo: 'Seleccione Algún filtro', tipo: 'info', mostrar: true, tiempo: 6000 });
    e.preventDefault();
  }

  render() {
    let { modalMasivoFiltro, mostrarModalMasivoFiltro, accionProceso } = this.props;
    let { arls, arl, empresas, empresa, tipo, tipos, tema, temas, cargando } = this.state;
    return (
      <>
        <Dialog fullWidth={true} maxWidth="sm" open={modalMasivoFiltro} onClose={() => mostrarModalMasivoFiltro(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <AppBarModal titulo='¡ Aplicar Filtros Masivo !' mostrarModal={mostrarModalMasivoFiltro} titulo_accion='Limpiar' accion={ this.resetState } />
          <DialogContent className='scroll'>
            {cargando ? <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" /> :
              <Grid container spacing={3} className='margin_cont'>
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                  <FormControl className='form-control'>
                    <InputLabel htmlFor="tipo">Seleccione Modalidad</InputLabel>
                    <Select
                      inputProps={{
                        name: 'tipo',
                        id: 'tipo',
                      }}
                      value={tipo}
                      onChange={this.onChange}>
                      {
                        tipos.map(({ id, nombre }) => <MenuItem key={id} value={id}>{nombre}</MenuItem>
                        )
                      }
                    </Select>
                  </FormControl>
                </Grid>

                {
                  tipo && 
                  <>
                  <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                    <BuscarSelect datos={empresas} cambiarEstado={(value) => this.setState({ empresa: value }) } valor={empresa} id='select-empresa' nombre='Escenario' placeholder='Seleccione el escenario' />
                  </Grid>
                  
                  <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                    <FormControl className='form-control'>
                      <InputLabel htmlFor="arl">Seleccione ARL</InputLabel>
                      <Select
                        inputProps={{
                          name: 'arl',
                          id: 'arl',
                        }}
                        value={arl}
                        onChange={this.onChange}>
                        {
                          arls.map(({ id, nombre }) => <MenuItem key={id} value={id}>{nombre}</MenuItem>)
                        }
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                    <FormControl className='form-control'>
                      <InputLabel htmlFor="tema">Seleccione Tema</InputLabel>
                      <Select
                        inputProps={{
                          name: 'tema',
                          id: 'tema',
                        }}
                        value={tema}
                        onChange={this.onChange}>
                        {
                          temas.map(({ id, nombre }) => <MenuItem key={id} value={id}>{nombre}</MenuItem>)
                        }
                      </Select>
                    </FormControl>
                  </Grid>
                  </>
                }

              </Grid>
            }
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => mostrarModalMasivoFiltro(false)} />
            {!cargando && <BtnForm texto="FILTRAR" callback={this.onSubmit} />}
          </DialogActions>
        </Dialog >

      </>
    )
  }
}

//Props del componente
PracticasMasivoFiltrar.propTypes = {
  //variables
  modalMasivoFiltro: PropTypes.bool.isRequired,
  //funciones
  mostrarModalMasivoFiltro: PropTypes.func.isRequired,
  filtrar: PropTypes.func.isRequired,
}
export default PracticasMasivoFiltrar;
