import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  ListItem: {
    width: '100%',
    backgroundColor: "#f5f5f5",
  },
  inline: {
    display: 'inline',
  },
  block: {
    display: 'block',
  },
  avatar: {
    borderRadius: 0,
  },
}));

export default function AccionesRapidas({ imagen, titulo, sub_titulo, des_sub_titulo, avatar='', avatar_name= '', list_desc= [], altura={} }) {
  const classes = useStyles();

  return (
    <List className={classes.root}>
      <ListItem alignItems="flex-start" button className={classes.ListItem}>
        <ListItemAvatar>
          {imagen && !avatar ?
            <Avatar alt="" src={imagen} className={classes.avatar} />
            :
            <Avatar alt="" style={avatar}>{avatar_name}</Avatar>
          }
        </ListItemAvatar>
        <ListItemText
          style={altura}
          primary={titulo}
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                {sub_titulo}
              </Typography>
              {des_sub_titulo}
              {
                list_desc.map((descripcion, i) => (
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.block}
                    color="textSecondary"
                    key={i}
                  >
                    {descripcion}
                  </Typography>
                ))
              }
            </React.Fragment>
          }
        />
      </ListItem>
    </List>
  );
}
