export const BLOQUES = 117;
export const SALAS = 118;
export const SOFTWARES = 119;
export const SOLICITUDES_BLOQUES = 120;
export const PERMISO_SALAS=41

// Local
// export const BLOQUES = 3120;
// export const SALAS = 3121;
// export const SOFTWARES = 3122;
// export const SOLICITUDES_BLOQUES = 3119;
// export const PERMISO_SALAS=41