import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardHeader, CardContent, Avatar, Grid, Typography, } from '@material-ui/core/';
import moment from 'moment'
import job1 from '../../../global/imagenes/portal/job.png'
import job2 from '../../../global/imagenes/portal/job-s.png'
import job3 from '../../../global/imagenes/portal/experience.png'

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: "100%",
    minHeight: "50px",
    height: "300px",
  },
  image: {
    width: '100px',
    maxWidth: '100px',
    minWidth: '100px',
    height: "100px",
  },
  content_text: {
    padding: "0px 0px 0px 10px",
  },
  content_img: {
    textAlign: 'center',
    height: "150px",
  },

}));


export default function ProcesoActivo({ titulo, fecha, descripcion, estado }) {
  let fecha_r = moment(fecha).format('YYYY-MM-DD, h:mm a');
  const classes = useStyles();
  
  const random = () => {
    let rnd = Math.floor((Math.random()*3))
    let img = [job1, job2, job3]
    return img[rnd]
  }

  const obtenerColor = (codigo) => {
    let color = 'white';
    const colores = {
      'Por_Env': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
      'Por_Rev': { 'backgroundColor': '#ffc107', color },
      'Por_Ace': { 'backgroundColor': '#0d47a1', color },
    }
    return (colores[codigo])
  }


  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={<Avatar aria-label="recipe" style={obtenerColor(estado.codigo)}></Avatar>}
        title={titulo}
        subheader={fecha_r}
      />
      <CardContent>
        <Grid container direction={'row'} justifyContent={'center'}>
          <Grid item xs={12} sm={12} md={12} className={classes.content_img}>
            <img src={random()} className={classes.image} alt='Imagen Tipo' />
            <Typography variant="body2" color="textSecondary" component="p" align='justify'>
              {descripcion}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}