import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogContent, DialogActions } from '@material-ui/core';
import AppBarModal from '../../general/AppBarModal';
import { VisitaMenuDetalle } from "./PromocionMenu";
import { BtnForm } from '../../general/BotonesAccion';
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from '../../../global/imagenes/emma_w.png';
import { DetalleVisita } from './Promocion';
import { consulta, generarFiltros} from '../../../global/js/funciones'
import ListarDatos from '../../general/ListarDatos';
import moment from 'moment'
class VisitaDetalle extends Component {
	constructor(props) {
		super(props)
		this.state = {
			cargando: false,
			seleccion: '',
			datos: [],
			estados: [],
			id_visita: '',
		}
	}

	async componentDidUpdate({ modalDetVisita }, { seleccion }) {
		let { seleccion: seleccionNew } = this.state;
		let { visita, modalDetVisita: modalDetVisitaNew, vistaDetalle} = this.props;
		
		if (seleccionNew !== seleccion) {
			if (seleccionNew === 'detalle') {
				this.ObtenerVisita(visita.id);
			} else if (seleccionNew === 'estado') {
				this.obtenerEstadosVisita(visita.id);
			}
		}
		if (modalDetVisita !== modalDetVisitaNew) this.setState({ seleccion: modalDetVisitaNew ? vistaDetalle : '' })
	}

	async ObtenerVisita(id) {
		this.setState({ cargando: true });
		let f = await generarFiltros([{ 'llave': 'id', 'valor': id }]);
		consulta(`api/v1.0/visitas?${f}`, null, null, (error, estado, resp) => {
			if (estado === 200) this.setState({ cargando: false, datos: resp });
		})
	}

	async obtenerEstadosVisita(id) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'visita', 'valor': id }]);
    consulta(`api/v1.0/visitas/estados?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, estados: resp });
    })
  }

  pintarEstadosHistorial() {
    let { estados } = this.state;

    const obtenerColor = (codigo) => {
      let color = 'white';
      const colores = {
				'Est_Vis_Env': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
        'Est_Vis_Apr': { 'backgroundColor': '#01519B', color },
        'Est_Vis_Rec': { 'backgroundColor': '#b71c1c', color },
        'Est_Vis_Fin': { 'backgroundColor': '#009688', color },
      }
      return (colores[codigo])
    }

    return (
      <ListarDatos
        id="tbl_estados_visita"
        datos={estados}
        avatar={({ tipo_estado }) => tipo_estado.nombre.charAt(0).toUpperCase()}
        color_avatar={({ tipo_estado: { codigo } }) => obtenerColor(codigo)}
        fila_principal={({ tipo_estado }) => tipo_estado.nombre.toUpperCase()}
        filas={[
          { 'id': 'usurio_registro', 'enLista': true, 'mostrar': ({ usuario_registro }) => `${usuario_registro.primer_nombre} ${usuario_registro.segundo_nombre} ${usuario_registro.primer_apellido} ${usuario_registro.segundo_apellido}`.toUpperCase() },
          { 'id': 'fecha_registro', 'enLista': true, 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a') },
          { 'id': 'mensaje', 'enLista': true, 'mostrar': ({ mensaje }) => mensaje },
        ]}
      />
    )
  }

	pintarVisita = () => {
		let { datos} = this.state;
		if (datos[0]) {
			return <DetalleVisita datos={datos[0]} />;
		}
	}

	pintar = () => {
		let { cargando, seleccion} = this.state;
		if (cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />;
		return (
			<div>
				{seleccion === 'detalle' && this.pintarVisita()}
				{seleccion === 'estado' && this.pintarEstadosHistorial()}
			</div>
		)

	}

	render() {
		let { seleccion } = this.state;
		let { modalDetVisita, mostrarModalDetalleVisita } = this.props;

		return (
			<>
				<Dialog open={modalDetVisita} maxWidth='sm' fullWidth={true} onClose={() => mostrarModalDetalleVisita(false)}>
					<AppBarModal titulo='¡ Detalle Visita !' mostrarModal={mostrarModalDetalleVisita} titulo_accion='' />
					<VisitaMenuDetalle sele={seleccion} seleccionar={seleccion => this.setState({ seleccion })} />
					<DialogContent style={{ padding: 0 }} className='scroll' >
						{this.pintar()}
					</DialogContent>
					<DialogActions>
						<BtnForm texto="Cerrar" callback={() => mostrarModalDetalleVisita(false)} />
					</DialogActions>
				</Dialog>
			</>
		);
	}
}

VisitaDetalle.propTypes = {
	modalDetVisita: PropTypes.bool.isRequired,
}

export default VisitaDetalle;