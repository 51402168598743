import React from 'react';
import emma_s1 from '../../global/imagenes/emma_s1.png';
import { useDropzone } from 'react-dropzone';
import TareasTerminadas from './TareasTerminadas';
import moment from 'moment';
import { Grid, Paper, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Divider } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete'

export default function CustomDrozone({ mensaje = 'Arrastre y suelte los archivos aquí o haga clic', addFunction, deleteFunction, acceptedFiles = 'image/*, application/.odt,.doc,.docx,.odp,.ppt,.ods,.xls,.xlsx,.pdf,.csv,.gz,.gzip,.rar,.zip,.txt', actualizarMensaje, archivos }) {
  // CONFIGURACION DEL DROPZONE CON PARAMETROS DE FUNCION DE AGREGAR, ALERTA EN ARCHIVOS RECHAZADOS 
  // Y CONFIGURACION DE EXTENSIONES ACEPTADAS
  let { getRootProps, getInputProps } = useDropzone(
    {
      onDrop: files => { addFunction(files) }, //EJECUTA LA FUNCION DE AGREGADO ENVIADA CON LOS ARCHIVOS ACEPTADOS (ARRAY)
      onDropRejected: files => {
        files.map(file => {
          actualizarMensaje({ titulo: `Lo sentimos el archivo ${file.name} fue rechazado debido a su extensión, ya que no es permitida`, tipo: 'info', mostrar: true, tiempo: 6000 });
          return true;
        })
      },
      accept: acceptedFiles 
    })

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <TareasTerminadas mensaje={mensaje} imagen={emma_s1} marginTop='5%' widthImg="8%" />
            </div>
          </section>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0} square>
            <List>
              <ListItem >
                <ListItemText primary={`${archivos.length} archivos Cargados`} />
              </ListItem>
              {
                archivos.map(({ name, lastModified }, i) => {
                  let fecha_mod = moment(lastModified).format('YYYY-MM-DD, h:mm:ss a');
                  return (
                    <div key={i}>
                      <Divider />
                      <ListItem >
                        <ListItemText primary={name} secondary={`Modificado el :${fecha_mod}`} />
                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="delete" onClick={() => deleteFunction(i)} style={{ color: '#d50000' }}>
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </div>
                  );
                })
              }
            </List>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
