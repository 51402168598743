export const TIPOS_IDENTIFICACION = 2;
export const DEPARTAMENTOS = 7;
export const GENERO =  69; // En local 67
export const COMUNIDAD = 85; // En local 83
export const CIUDADES = 8; 
export const AREA_DERECHO = 86; // En local 84
export const ESTADOS_CONSULTORIA = 88; // En local 86
export const REQUISITOS_CONSULTORIA = 89; // En local 87
export const TIPOS_CONSULTORIA = 90; // En local 88
export const Tipo_informes_consultoria = 199 // En local 194
export const TIPO_CENTRO_CONCILIACION = 264 // En local 1212
export const DOCUMENTOS_INFORMATIVOS = 289 // En local 3226 
