import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormularioDinamico from './FormularioDinamico';

import AppBarModal from '../../general/AppBarModal';
import { BtnForm } from '../../general/BotonesAccion';
import { useEffect } from 'react';
import { getFormValues } from './helper';

const EditarParametro = ({
	modalMod,
	mostrarModalMod,
	parametro,
	cambiarEstado,
	seleccion,
	programa,
	cargarPermisos,
	datosMateria,
}) => {
	const [ values, setValues ] = useState([]);

	useEffect(
		() => {
			const formInputs = getFormValues(parametro);
			setValues(formInputs);
		},
		[ parametro ]
	);
	return (
		<Dialog
			open={modalMod}
			onClose={() => mostrarModalMod(false)}
			aria-labelledby='form-dialog-title'
			fullWidth={true}
			maxWidth='xs'
		>
			<AppBarModal titulo='Editar' mostrarModal={mostrarModalMod} titulo_accion='' accion='' />
			<DialogContent className='scroll'>
				<FormularioDinamico
					mostrarModalMod={mostrarModalMod}
					datosMateria={datosMateria}
					fields={values}
					parametro={parametro}
					cambiarEstado={cambiarEstado}
					seleccion={seleccion}
					programa={programa}
					cargarPermisos={cargarPermisos}
				/>
			</DialogContent>
			<DialogActions>
				<BtnForm texto='CANCELAR' callback={() => mostrarModalMod(false)} />
				<BtnForm
					texto='EDITAR'
					callback={() => {
						document.getElementById('enviar_form_add_parametro').click();
					}}
				/>
			</DialogActions>
		</Dialog>
	);
};

//Props del componente
EditarParametro.propTypes = {
	//variables
	modalMod: PropTypes.bool.isRequired,
	parametro: PropTypes.number,
	seleccion: PropTypes.string,
	//funciones
	mostrarModalMod: PropTypes.func.isRequired,
	cambiarEstado: PropTypes.func.isRequired,
	cargarPermisos: PropTypes.func
};

export default EditarParametro;
