import React, { Component } from 'react'
import { Dialog, DialogActions, DialogContent, Link } from '@material-ui/core'
import AppBarModal from '../../general/AppBarModal';
import ListarDatos from '../../general/ListarDatos';
import { BtnForm, BtnDetalle, BtnEditar } from '../../general/BotonesAccion';
import { generarFiltros, consulta, mostrarError, formulario } from '../../../global/js/funciones';
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from '../../../global/imagenes/emma_w.png';
import moment from 'moment';


export default class ValidacionesAdjuntos extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cargando : true,
      documentos: [],
      adjunto : []
    }
  }

  async componentDidUpdate({ modalAdjuntosValidacion }, {}) {
    let { modalAdjuntosValidacion: modalAdjuntosValidacionNew, validacion }  = this.props;
    if (modalAdjuntosValidacion !== modalAdjuntosValidacionNew) this.obtenerAdjuntos(validacion)
  }

  async obtenerAdjuntos(id) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'validacion', 'valor': id }]);
    consulta(`api/v1.0/validaciones/adjuntos?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, documentos: resp });
    })
  }

  render() {
    let { modalAdjuntosValidacion, mostrarModalAdjuntos } = this.props
    let { documentos, cargando } = this.state


    const acciones = (row) => {
     
      let detalle = <Link href={`${row.archivo}`} target="_blank"><BtnDetalle callback={() => { }} /></Link>
      // let reemplazar = (infoValidacion.estado_actual.codigo === 'Val_Env' && row.validacion != '2') && <BtnEditar callback={() => onClickReemplazar()} color='#00bfa5' texto='Reemplazar' />;
      let reemplazar = ''
      return <div>{detalle} {reemplazar}</div>;
    }


    const obtenerColor = ({validacion}) => {
      let color = 'white';
      const colores = {
        '1': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
        '2': { 'backgroundColor': '#01519B', color },
        '3': { 'backgroundColor': '#b71c1c', color },
      }
      return (colores[validacion])
    }

    return (
      <div>

        <Dialog open={modalAdjuntosValidacion} onClose={() => mostrarModalAdjuntos(false)} fullWidth={true} maxWidth="sm">
          <AppBarModal titulo="¡ Mis Documentos !" mostrarModal={() => mostrarModalAdjuntos(false)} titulo_accion="" />
          <DialogContent style={{ padding: 0 }} className="scroll">
            {
              !cargando ?
                <ListarDatos
                  id="tbl_mis_adjuntos"
                  descargar="DOCUMENTOS VALIDAICONES - EMMA"
                  datos={documentos}
                  buscar={false}
                  avatar={({ validacion_solicitud_text }) => validacion_solicitud_text.charAt(0).toUpperCase()}
                  color_avatar={row => obtenerColor(row)}
                  acciones={row => acciones(row)}
                  fila_principal={({ requisito }) => requisito.nombre.toUpperCase()}
                  filas={[
                    { 'id': 'nombre_archivo', 'enLista': false },
                    { 'id': 'validacion_solicitud_text', 'enLista': true, 'mostrar': ({ validacion_solicitud_text }) => validacion_solicitud_text.toUpperCase() },
                    { 'id': 'fecha_registro', 'enLista': true, 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a') },
                    { 'id': 'valido', 'enLista': true, 'mostrar': ({ usuario_valida }) => `${  usuario_valida ? `VALIDADO: ${usuario_valida.primer_nombre} ${usuario_valida.primer_apellido} ${usuario_valida.segundo_apellido} - ${usuario_valida.correo}` : ''}` },
                    { 'id': 'nota', 'enLista': true, 'mostrar': ({ validacion, nota }) => `${validacion == 3 ? `${nota}` : ''}` },
                  ]}
                /> :
                <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
            }
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CERRAR" callback={() => mostrarModalAdjuntos(false)} />
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}