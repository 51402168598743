const MODAL_ADD_VOTANTE = 'MODAL_ADD_VOTANTE'
const MODAL_DEL_VOTANTE = 'MODAL_DEL_VOTANTE'
const ACT_DATA_VOTANTES = 'ACT_DATA_VOTANTES'
const ACT_DATA_VOTANTE = 'ACT_DATA_VOTANTE'
const ACT_RENDER_VOTANTE = 'ACT_RENDER_VOTANTE'



const mostrarModalAddVotantes = show => {
  return {
    type: MODAL_ADD_VOTANTE,
    show,
  }
}
const mostrarModalDelVotantes = show => {
  return {
    type: MODAL_DEL_VOTANTE,
    show,
  }
}


const actualizarDataVotantes = votantes => {
  return {
    type: ACT_DATA_VOTANTES,
    votantes,
  }
}
const actualizarDataVotante = votante => {
  return {
    type: ACT_DATA_VOTANTE,
    votante,
  }
}
const actualizarRenderVotantes = render => {
  return {
    type: ACT_RENDER_VOTANTE,
    render,
  }
}


module.exports = {
  mostrarModalAddVotantes,
  mostrarModalDelVotantes,
  actualizarDataVotantes,
  actualizarRenderVotantes,
  actualizarDataVotante,
  MODAL_ADD_VOTANTE,
  MODAL_DEL_VOTANTE,
  ACT_DATA_VOTANTES,
  ACT_RENDER_VOTANTE,
  ACT_DATA_VOTANTE
}
