import React, { Component } from 'react';
import { List, ListItemIcon, ListItemText, ListItem, Avatar, InputLabel, IconButton, MenuItem, FormControl, Select, Popover, Typography, Card, CardContent } from '@material-ui/core';
import usuarioIcon from "../../../global/imagenes/user.png";
import { obtenerMisDatos, consulta, api } from "../../../global/js/funciones";
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from '../../../global/imagenes/emma_w.png';
import signout from '../../../global/imagenes/signout.png';

export default class Perfil extends Component {

  constructor(props) {
    super(props)
    this.state = {
      evento: null,
      mensaje: null,
      perfiles: [],
      perfil: '',
      persona: null,
      cargando: true,
      openSelect: false,
    }
  }

  componentDidUpdate({ }, { evento }) {
    let { evento: eventoNew } = this.state;
    let anterior = Boolean(evento);
    let actual = Boolean(eventoNew);
    if (anterior !== actual && actual) this.cargarData();
  }

  async cargarData() {
    this.setState({ cargando: true });
    let { persona, perfiles } = await obtenerMisDatos();
    this.setState({ cargando: false, persona, perfiles, perfil: persona.perfil, mensaje: '' });
  }


  cambiarPerfil = (e) => {
    let perfil = e.target.value;
    this.setState({ perfil, cargando: true });
    let { persona: { id } } = this.state;
    consulta(`api/v1.0/personas/${id}`, { perfil, carnet: false }, "patch",
      (error, estado, resp) => {
        if (error || estado !== 200) this.setState({ perfil, cargando: false, mensaje: 'Error al cambiar el perfil' });
        else window.location.href = '/';
      }
    );
  }

  render() {
    const estilos = (() => ({
      iconoCarta: {
        width: 60,
        height: 60,
        margin: '0 auto'
      },
      carta: {
        width: 300,
        padding: "30px 0px 0px 0px",
        border: '3px solid #eeeeee'
      },
      listaItem: { textAlign: 'center' },
      cartaContenido: { paddingTop: 0 },
      icono: { width: '25px', maxHeight: "25px" },
    }));

    let { cerrarSesion, usuario } = this.props;
    let { evento, perfiles, persona, cargando, openSelect, perfil, mensaje } = this.state;

    const open = Boolean(evento);
    const id = open ? 'usuario-popover' : undefined;
    let { iconoCarta, carta, listaItem, cartaContenido, icono } = estilos();
    const itemFoto = localStorage.getItem('fotoMs')
    const imageUrl = itemFoto ? `data:image/jpeg;base64,${itemFoto}` : null;
    // const imageUrl = null
    return (
      <>
        <IconButton color="inherit" onClick={(e) => this.setState({ evento: e.currentTarget })}>
          <Avatar alt="Foto Usuario" src={imageUrl ? imageUrl : usuarioIcon} />
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={evento}
          onClose={() => this.setState({ evento: null })}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >

          {
            cargando ? <div style={carta}><TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />  </div>
              :
              <Card style={carta}>
                <div >
                  <Avatar alt="Foto Usuario" src={imageUrl ? imageUrl : usuarioIcon} style={iconoCarta} />
                  <List>
                    <ListItem style={listaItem}>
                      <ListItemText primary={`${persona.primer_nombre} ${persona.primer_apellido}`} secondary={`${persona.correo}`} />
                    </ListItem>
                  </List>
                </div>
                <CardContent style={cartaContenido}>
                  <FormControl className='form-control'>
                    <InputLabel htmlFor="perfiles-select">¿ Cambiar Perfil ?</InputLabel>
                    <Select
                      open={openSelect}
                      name='perfil'
                      onClose={() => this.setState({ openSelect: false })}
                      onOpen={() => this.setState({ openSelect: true })}
                      value={perfil}
                      onChange={(e) => this.cambiarPerfil(e)}
                    >
                      {perfiles.map(({ relacion: { id, nombre } }) => <MenuItem key={id} value={id}>{nombre}</MenuItem >)}
                    </Select>
                    {mensaje && <Typography variant="subtitle1" color="textSecondary" component="p">{mensaje}.</Typography>}
                  </FormControl>

                </CardContent>
                <p style={{ margin: "20px 0px 20px 0", border: '1px solid #eeeeee' }}></p>
                <CardContent style={cartaContenido}>
                  <ListItem button onClick={() => cerrarSesion(true)} style={{ margin: "0", padding: 5 }}>
                    <ListItemIcon >
                      <img src={signout} style={icono} alt="Cerrar Sesión" />
                    </ListItemIcon>
                    <ListItemText primary={<Typography style={{ fontWeight: 600, color: '#62727b', textTransform: 'capitalize' }}>
                      Cerrar Sesión
                    </Typography>} />
                  </ListItem>
                </CardContent>
              </Card>
          }

        </Popover>
      </>
    )
  }
}
