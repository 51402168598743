import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	Dialog,
	DialogActions,
	DialogContent,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select
} from '@material-ui/core';
//FUNCIONES GENERALES
import { obtenerValorsPermisos, consulta, mostrarError } from '../../../global/js/funciones';
//COMPONENTES DEL MODULO
import ValoresListarPermisos from '../../genericas/valores/ValoresListarPermisos';
import ValoresAgregar from '../../genericas/valores/ValoresAgregar';
//ACCIONES DEL MODULO
import { BtnForm } from '../../general/BotonesAccion';
import AppBarModal from '../../general/AppBarModal';
import { actualizarMensaje } from '../../../redux/actions/actGlobal';
import emma_w from '../../../global/imagenes/emma_w.png';
import TareasTerminadas from '../../general/TareasTerminadas';

class ValoresAgregarPermisoPractica extends Component {
	constructor(props) {
		super(props);
		this.state = {
			permisos: [],
			cargando: false,
			valorSelect: '',
            sucursalesNoSeleccionadas: [],
			
		};
	}
	componentDidUpdate({ modalAddPermisosValores, modalAddValorPermiso }, { valorSelect }) {
		let { modalAddPermisosValores: modalAddNew, modalAddValorPermiso: modalAddValorPermisoNew, valor, filtro } = this.props;
		if (modalAddNew !== modalAddPermisosValores) {
			if (modalAddNew) this.setState({ valorSelect: filtro });
			else this.setState({ valorSelect: '' });
		}
		if ((valorSelect !== this.state.valorSelect && this.state.valorSelect) || modalAddValorPermiso != modalAddValorPermisoNew && modalAddValorPermisoNew == false) this.cargarPermisos(valor.id);

	}
	  obtenerValorsSinPermisos =  (id, filtros = []) => {
		let f = '';
		filtros.map(({ llave, valor }) => (f = `${f}&${llave}=${valor}`));
		return new Promise((resolve) => {
			consulta(
				`api/v1.0/practicas/sucursales/${id}?${f}`,
				null,
				null,
				(error, estado, resp) => {
					resolve(resp);
				},
				false
			);
		});
	};

	cargarPermisos = async (valor) => {
		this.setState({ cargando: true });
        let { sucursalesNoSeleccionadas,permisos } = this.state;
        let {sucursalesSelec}=this.props;
		 const obtenerValorsSinPermisos =  (id, filtros = []) => {
			let f = '';
			filtros.map(({ llave, valor }) => (f = `${f}&${llave}=${valor}`));
			return new Promise((resolve) => {
				consulta(
					`api/v1.0/practicas/sucursales/${id}?${f}`,
					null,
					null,
					(error, estado, resp) => {
						resolve(resp);
					},
					false
				);
			});
		};

		permisos = await obtenerValorsSinPermisos(valor, [{ llave: 'generica', valor: this.state.valorSelect }]);
		
     /*   console.log(sucursalesSelec.map(sucursal=>{
            let sucursal2= {'IdSucursal':sucursal.secundario.id}
            return sucursal2;
        }))*/
    /*  console.log( sucursalesSelec.map(sucurselec => {permisos.map(todasuc => {
            let sucursal2= {'IdSucursal':sucurselec.secundario.id}
            return sucursal2;
        }
   
        )}))*/

      /*  permisos.map(todasuc => {
            sucursalesSelec.map((sucurselec) => {
              console.log('sucursal seleccionada',sucurselec)
              
                if (sucurselec.id>0 ) {
                   permisos.splice(permisos.indexOf(sucurselec.secundario.id), 1);
                }
                  
                }

            )})
          */
        
         
        this.setState({ permisos, cargando: false, sucursalesNoSeleccionadas });
          
	};


	guardarPermiso = (valor_secundario) => {
		let { valor } = this.props;
		consulta(
			'api/v1.0/permisos/crear',
			{ secundario: valor_secundario.id, principal: valor.id },
			'post',
			(error, estado, resp) => {
				let titulo = 'Ha ocurrido un error, contacte con el administrador.',
					tipo = 'error';
				if (!error) {
					if (estado === 200) {
						tipo = 'success';
						titulo = resp.titulo;
						this.cargarPermisos(valor.id);
					} else {
						tipo = 'info';
						titulo = mostrarError(resp);
					}
				}
				actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
			}
		);
	};

	

	eliminarPermiso = (data) => {
		let { valor } = this.props;
		consulta(`api/v1.0/permisos/${data.permiso}/eliminar`, {}, 'put', (error, estado, resp) => {
			let titulo = 'Ha ocurrido un error, contacte con el administrador.',
				tipo = 'error';
			if (!error) {
				if (estado === 200) {
					tipo = 'success';
					titulo = resp.titulo;
					this.cargarPermisos(valor.id);
				} else {
					tipo = 'info';
					titulo = mostrarError(resp);
				}
			}
			actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
		});
	};

	render() {
		let { modalAddPermisosValores, mostrarModalAddPermisoValores, titulo, administra, genericas, modalAddValorPermiso, mostrarModalAddValorPermiso, inputs_agregar, titulo_agregar, agregarValor, guardar_agregar } = this.props;

		let { permisos, cargando, valorSelect, sucursalesNoSeleccionadas} = this.state;

		return (
			<div>
				
				<ValoresAgregar
					mostrarABC={true}
					modalAddValores={modalAddValorPermiso}
					mostrarModalAddValores={mostrarModalAddValorPermiso}
					guardarValor={guardar_agregar}
					titulo={titulo_agregar}
					inputs={inputs_agregar}
				/>
			</div>
		);
	}
}

//Props del componente
ValoresAgregarPermisoPractica.propTypes = {
	administra: PropTypes.bool.isRequired,
	//variables
	modalAddPermisosValores: PropTypes.bool.isRequired,
	mostrarModalAddPermisoValores: PropTypes.func.isRequired
};

ValoresAgregarPermisoPractica.defaultProps = {
	genericas: [],
	titulo: 'Asignar Relacion',
	administra: true,
	filtro: '',
	agregarValor: false,
	titulo_agregar: '',
	modalAddValorPermiso: false,
	mostrarModalAddValorPermiso: () => {},
	guardar_agregar : () => {},
	inputs_agregar: {
		inputCodigo: { 'label': 'Codigo', 'mostrar': false, 'tipo': 'text', requerido: false, mayusculas: false },
		inputNombre: { 'label': 'Nombre', 'mostrar': true, 'tipo': 'text', requerido: true, mayusculas: false },
		inputDescripcion: { 'label': 'Descripción', 'mostrar': false, 'tipo': 'text', requerido: false, mayusculas: false },
		inputValora: { 'label': 'Valor A', 'mostrar': false, 'tipo': 'text', requerido: false, mayusculas: false },
		inputValorb: { 'label': 'Valor B', 'mostrar': false, 'tipo': 'text', requerido: false, mayusculas: false },
		inputValorc: { 'label': 'Valor C', 'mostrar': false, 'tipo': 'text', requerido: false, mayusculas: false },
		inputValord: { 'label': 'Valor D', 'mostrar': false, 'tipo': 'text', requerido: false, mayusculas: false },
		inputValore: { 'label': 'Valor E', 'mostrar': false, 'tipo': 'text', requerido: false, mayusculas: false },
		inputValorf: { 'label': 'Valor F', 'mostrar': false, 'tipo': 'text', requerido: false, mayusculas: false },
		inputValorg: { 'label': 'Valor G', 'mostrar': false, 'tipo': 'text', requerido: false, mayusculas: false },
		inputArchivo: { 'label': 'Adjuntar Archivo', 'mostrar': false, 'tipo': 'file', requerido: false, },
	  }
};

export default ValoresAgregarPermisoPractica;
