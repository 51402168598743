import React from 'react';
import { BottomNavigation, BottomNavigationAction, Hidden } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CreateIcon from '@material-ui/icons/Create';
import Procesos from '@material-ui/icons/ImportContacts';

export function InvitadosMenu({ sele = 0, seleccionar = () => {} }) {
  const clase = (item, selec, tipo = '') => item === selec ? `item_selec${tipo}` : `item_modulo`;

  const pintar = (app, claseN = '') => {
    if (!app) {
      return (
        <div className={claseN}>
          <BottomNavigation showLabels className={clase('procesos', sele)} onClick={() => seleccionar('procesos')}>
            <BottomNavigationAction label={'Procesos'} icon={<LocationOnIcon />} />
          </BottomNavigation>
          <BottomNavigation showLabels className={clase('nuevo', sele)} onClick={() => seleccionar('nuevo')}>
            <BottomNavigationAction label={'Nuevo'} icon={<CreateIcon />} />
          </BottomNavigation>
          <BottomNavigation showLabels className={clase('historial', sele)} onClick={() => seleccionar('historial')}>
            <BottomNavigationAction label={'Historial'} icon={<Procesos />} />
          </BottomNavigation>
        </div>
      )
    } else {
      return (
        <div className={claseN}>
          <BottomNavigation showLabels >
            <BottomNavigationAction label={'Procesos'} icon={<LocationOnIcon />} className={clase('procesos', sele, '_app')} onClick={() => seleccionar('procesos')} />
            <BottomNavigationAction label={'Nuevo'} icon={<CreateIcon />} className={clase('nuevo', sele, '_app')} onClick={() => seleccionar('nuevo')} />
            <BottomNavigationAction label={'Historial'} icon={<Procesos />} className={clase('historial', sele, '_app')} onClick={() => seleccionar('historial')} />
          </BottomNavigation>
        </div>
      )
    }
  }

  return (
    <div>
      <Hidden xsDown> { pintar(false, 'menu_modulo scroll') } </Hidden>
      <Hidden smUp> { pintar(true, 'menu_modulo_app') } </Hidden>
    </div>
  )
}

