import React, { Component } from 'react';
import ReactExport from "react-export-excel";
import Button from '@material-ui/core/Button';
import { coloresEmma } from '../../../global/js/funciones';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ExportarCandidatosExcel extends Component {

  render() {
    let { data, nombre } = this.props;
    return (
      <ExcelFile filename={nombre} element={<Button style={{ color: coloresEmma.secondarycolor}}>Descargar</Button>}>
        <ExcelSheet data={data} name="candidatos">
          <ExcelColumn label="Nombre" value={({ candidato }) => `${candidato.primer_nombre} ${candidato.primer_apellido}`} />
          <ExcelColumn label="Votos" value="votos" />
        </ExcelSheet>
      </ExcelFile>
    );
  }
}
export default ExportarCandidatosExcel;
