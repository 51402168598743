const MODAL_CREAR_PROMOCION = "MODAL_CREAR_PROMOCION";
const MODAL_DET_PROMOCION = "MODAL_DET_PROMOCION";
const MODAL_EDI_PROMOCION = "MODAL_EDI_PROMOCION";
const MODAL_ELI_PROMOCION = "MODAL_ELI_PROMOCION";
const ACT_DATA_PROMOCION = "ACT_DATA_PROMOCION";
const MODAL_CREAR_VISITA = 'MODAL_CREAR_VISITA';
const MODAL_EDI_VISITA = "MODAL_EDI_VISITA";
const MODAL_DET_VISITA = "MODAL_DET_VISITA";
const MODAL_GEST_VISITA = 'MODAL_GEST_VISITA';
const ACT_RENDER_VISITA = 'ACT_RENDER_VISITA';
const ACT_DATA_VISITAS = 'ACT_DATA_VISITAS';
const MODAL_ADD_FILTROS_COLEGIO = 'MODAL_ADD_FILTROS_COLEGIO';
const MODAL_ADD_FILTROS_VISITA = 'MODAL_ADD_FILTROS_VISITA';
const MODAL_INFORME = 'MODAL_INFORME';

const mostrarModalCrearPromocion = show => {
  return {
    type: MODAL_CREAR_PROMOCION,
    show,
  }
}

const mostrarModalDetallePromocion = show => {
  return {
    type: MODAL_DET_PROMOCION,
    show,
  }
}

const mostrarModalEditarPromocion = show => { 
  return {
    type: MODAL_EDI_PROMOCION,
    show,
  }
}

const mostrarModalEliminarPromocion = show => { 
  return {
    type: MODAL_ELI_PROMOCION,
    show,
  }
}

const actualizarDataPromocion = promociones => {
  return {
    type: ACT_DATA_PROMOCION,
    promociones,
  }
}

const mostrarModalCrearVisita = show => {
  return {
    type: MODAL_CREAR_VISITA,
    show,
  }
}

const mostrarModalEditarVisita = show => { 
  return {
    type: MODAL_EDI_VISITA,
    show,
  }
}

const mostrarModalDetalleVisita = show => {
  return {
    type: MODAL_DET_VISITA,
    show,
  }
}

const mostrarModalGestionarVisita = show => {
  return {
    type: MODAL_GEST_VISITA,
    show,
  }
}

const actualizarRenderVisitas = render => {
  return {
    type: ACT_RENDER_VISITA,
    render,
  }
}

const actualizarDataVisitas = visitas =>{
  return{
    type: ACT_DATA_VISITAS,
    visitas,
  }
}

const mostrarModalFiltrosColegio = show => {
  return {
    type: MODAL_ADD_FILTROS_COLEGIO,
    show,
  }
}

const mostrarModalFiltrosVisita = show => {
  return {
    type: MODAL_ADD_FILTROS_VISITA,
    show,
  }
}

const mostrarModalInforme = show => {
  return {
    type: MODAL_INFORME,
    show,
  }
}

module.exports = {
  MODAL_CREAR_PROMOCION,
  MODAL_DET_PROMOCION,
  MODAL_EDI_PROMOCION,
  MODAL_ELI_PROMOCION,
  ACT_DATA_PROMOCION,
  MODAL_CREAR_VISITA,
  MODAL_EDI_VISITA,
  MODAL_DET_VISITA,
  MODAL_GEST_VISITA,
  ACT_RENDER_VISITA,
  ACT_DATA_VISITAS,
  MODAL_ADD_FILTROS_COLEGIO,
  MODAL_ADD_FILTROS_VISITA,
  MODAL_INFORME,
  mostrarModalCrearPromocion,
  mostrarModalDetallePromocion,
  mostrarModalEditarPromocion,
  mostrarModalEliminarPromocion,
  actualizarDataPromocion,
  mostrarModalCrearVisita,
  mostrarModalEditarVisita,
  mostrarModalDetalleVisita,
  mostrarModalGestionarVisita,
  actualizarRenderVisitas,
  actualizarDataVisitas,
  mostrarModalFiltrosColegio,
  mostrarModalFiltrosVisita,
  mostrarModalInforme,
};
