import React, { Component } from 'react';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import { Paper, Grid } from '@material-ui/core'
import { BtnForm } from '../../general/BotonesAccion';


//Imagenes
import datos_personales from '../../../global/imagenes/portal/portfolio.png'
import estudios from '../../../global/imagenes/portal/school.png'
import experiencia from '../../../global/imagenes/portal/experience.png'
import habilidades from '../../../global/imagenes/portal/shine.png'
import honor from '../../../global/imagenes/portal/honor.png'
import anexos from '../../../global/imagenes/files.png'
import interes from '../../../global/imagenes/portal/interest.png'

//componentn
import AppBarModal from '../../general/AppBarModal'
import AccionesRapidas from '../../general/AccionesRapidas'
import BarraProgreso from '../BarraProgreso'

class OpcionesPerfil extends Component {
    render() {
        let { modalAccionesRapidas, mostrarModalAccionesRapidas, abrirListarOpcionesPerfil, abrirFormInformacionPrincipal, listarIntereses,validarPerfilEstudiante } = this.props
        return (
            <div> 
                <Dialog open={modalAccionesRapidas} onClose={() => mostrarModalAccionesRapidas(false)} fullWidth={true} maxWidth="md" fullScreen={window.innerWidth < 600}>
                    <AppBarModal titulo="Información Estudiante/Graduado" mostrarModal={() => mostrarModalAccionesRapidas(false)} titulo_accion="" />
                    <DialogContent style={{ padding: 40 }} className="scroll">
                    <BarraProgreso
                        validarPerfilEstudiante={validarPerfilEstudiante}
                    />
                        <Grid item xs={12} sm={12} md={12}>
                            <Paper elevation={0} square style={{ maxHeight: 400, width: "100%" }}>
                                <Grid container direction={'row'} spacing={2} style={{ width: "100%" }}>
                                    <Grid item xs={12} sm={6} md={4} >
                                        <Paper 
                                            onClick={
                                                () => abrirFormInformacionPrincipal('Personal','Datos Personales')
                                            }
                                            elevation={3} 
                                            style={{ border: '1px solid #e0e0e0' }} >
                                            <AccionesRapidas imagen={datos_personales}  titulo='Datos Personales' sub_titulo='Bien' des_sub_titulo=', Aquí puedes agregar información personal.' />
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} >
                                        <Paper onClick={() => abrirListarOpcionesPerfil('Academica', 'Información Académica')} elevation={3} style={{ border: '1px solid #e0e0e0' }} >
                                            <AccionesRapidas imagen={estudios} titulo='Información Académica' sub_titulo='Bien' des_sub_titulo=', Aquí puedes agregar información académica...' />
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} >
                                        <Paper onClick={() => abrirListarOpcionesPerfil('Experiencia', 'Información Laboral')} elevation={3} style={{ border: '1px solid #e0e0e0' }} >
                                            <AccionesRapidas imagen={experiencia}   titulo='Experiencia' sub_titulo='Bien' des_sub_titulo=', Aquí puedes agregar información laboral...' />
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} >
                                        <Paper onClick={() => listarIntereses('Intereses', 'Áreas de Interes', false)} elevation={3} style={{ border: '1px solid #e0e0e0' }} >
                                            <AccionesRapidas imagen={interes}   titulo='Áreas de interes' sub_titulo='Bien' des_sub_titulo=', Aquí puedes agregar tus areas de interes...' />
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} >
                                        <Paper onClick={() => abrirListarOpcionesPerfil('Habilidad', 'Habilidades')} elevation={3} style={{ border: '1px solid #e0e0e0' }} >
                                            <AccionesRapidas imagen={habilidades} titulo='Habilidades' sub_titulo='Bien' des_sub_titulo=', Aquí puedes agregar tus habilidades...' />
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} >
                                        <Paper onClick={() => abrirListarOpcionesPerfil('Distincion', 'Distinciones y Logros')} elevation={3} style={{ border: '1px solid #e0e0e0' }} >
                                            <AccionesRapidas imagen={honor} titulo='Distinciones y Logros' sub_titulo='Bien' des_sub_titulo=', Aquí puedes agregar tus logros alcanzados...' />
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} >
                                        <Paper elevation={3} onClick={() => abrirListarOpcionesPerfil('Anexos', 'Anexos')} style={{ border: '1px solid #e0e0e0' }} >
                                            <AccionesRapidas imagen={anexos} titulo='Anexos' sub_titulo='Bien' des_sub_titulo=', Aquí puedes agregar tus certificados...' />
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <button type="submit" className='oculto' id='btn_crear_empresa_pro'></button>
                        <BtnForm texto="CERRAR" callback={() => mostrarModalAccionesRapidas(false)} />
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default OpcionesPerfil;