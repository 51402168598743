import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Button,
} from "@material-ui/core";
import AppBarModal from "../../general/AppBarModal";
import { BtnForm } from "../../general/BotonesAccion";
import { coloresEmma } from "../../../global/js/funciones";
import { FormControl, Input, FormHelperText } from "@material-ui/core/";
import { useFormik } from "formik";
import * as Yup from "yup";
import { setLocale } from "yup";

const ModalAgregarGrupo = ({
  modalProgramas,
  handleCloseModal,
  handleAgregar,
  checked,
  cursoGrupo,
}) => {
  const formik = useFormik({
    initialValues: {
      grupo: "",
      creditos: "",
    },
    validationSchema: Yup.object({
      grupo: Yup.string().required("El grupo es requerido"),
      creditos: Yup.number()
        .required("El credito es requerido")
        .max(
          cursoGrupo.valora,
          "Excedes el numero maximo de creditos de la materia (${max})"
        ),
    }),
    onSubmit: (values, { resetForm }) => {
      const { grupo, creditos } = values;
      handleAgregar(grupo, creditos);
      resetForm();
    },
  });

  return (
    <Dialog
      open={modalProgramas}
      fullWidth={true}
      maxWidth="sm"
      onClose={handleCloseModal}
    >
      <AppBarModal
        titulo="¡ Grupo y Creditos !"
        mostrarModal={handleCloseModal}
        accion={handleCloseModal}
        titulo_accion="CERRAR"
      />
      <DialogContent style={{ overflow: "hidden" }} className="scroll">
        {/* <form onSubmit={formik.handleSubmit}> */}
        <Grid spacing={6} container style={{ marginTop: 10 }}>
          <Grid item xs={12}>
            <FormControl className="form-control"
            error={formik.errors.grupo}
            >
              <Input
                placeholder="Grupo"
                name="grupo"
                id="grupo"
                value={formik.values.grupo}
                //   onChange={(e) => setGrupo(e.target.value)}
                onChange={formik.handleChange}
              />
              {formik.errors.grupo && formik.touched.grupo ? (
                <FormHelperText>{formik.errors.grupo}</FormHelperText>
              ) : null}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              className="form-control"
              error={formik.errors.creditos}
            >
              <Input
                placeholder="Creditos"
                name="creditos"
                id="creditos"
                value={formik.values.creditos}
                //   onChange={(e) => setCreditos(e.target.value)}
                onChange={formik.handleChange}
              />
              {formik.errors.creditos && formik.touched.creditos ? (
                <FormHelperText>{formik.errors.creditos}</FormHelperText>
              ) : null}
            </FormControl>
          </Grid>
        </Grid>
        {/* </form> */}
      </DialogContent>
      <DialogActions>
        <BtnForm texto="Cerrar" callback={handleCloseModal} />
        {/* <BtnForm
          texto="Guardar"
          callback={() => formik.handleSubmit()}
        /> */}
        <Button
          variant="text"
          color="primary"
          style={{ color: coloresEmma.secondarycolor }}
          onClick={() => formik.handleSubmit()}
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalAgregarGrupo;
