const ADJUNTOS = 'ADJUNTOS'
const TUTORES = 'TUTORES'
const ESTADO_SOLICITUD = 'ESTADO_SOLICITUD'
const ESTADOS_SOLICITUD = 'ESTADOS_SOLICITUD'
const EVENTOS = 'EVENTOS'
const MODAL_ADJUNTOS = 'MODAL_ADJUNTOS'
const MODAL_NEW_ADJUNTOS = 'MODAL_NEW_ADJUNTOS'
const MODAL_MODIFICAR_SOLICITUD = 'MODAL_MODIFICAR_SOLICITUD'
const MODAL_DETALLE_GRADO = 'MODAL_DETALLE_GRADO'
const MODAL_NEW_TUTOR = 'MODAL_NEW_TUTOR'
const MODAL_NUEVO_GRADO = 'MODAL_NUEVO_GRADO'
const MODAL_GESTIONAR_SOLICITUD = 'MODAL_GESTIONAR_SOLICITUD'
const MODAL_PROGRAMA_GESTOR = "MODAL_PROGRAMA_GESTOR"
const MODAL_PERMISO_ESTADO_GESTOR = "MODAL_PERMISO_ESTADO_GESTOR"
const MODAL_EVALUACION_COMITE = "MODAL_EVALUACION_COMITE"
const MODAL_FILTROS = "MODAL_FILTROS"
const MODAL_EVENTOS = 'MODAL_EVENTOS'
const MODAL_NEW_EVENTO= 'MODAL_NEW_EVENTO'
const SOLICITUDES_GRADOS = 'SOLICITUDES_GRADOS'
const ULTIMA_SOLICITUD = 'ULTIMA_SOLICITUD'
const DATA_ADJUNTO_SOLICITUD = "DATA_ADJUNTO_SOLICITUD"

const showModalTrabajoGrado = show => {
    return {
        type: MODAL_NUEVO_GRADO,
        show,
    }
}

const showModalDetalleGrado = show => {
    return {
        type: MODAL_DETALLE_GRADO,
        show,
    }
}

const showModalAdjuntos = show => {
    return {
        type: MODAL_ADJUNTOS,
        show,
    }
}

const showModalNewAdjuntos = show => {
    return {
        type: MODAL_NEW_ADJUNTOS,
        show,
    }
}

const showModalNewGestor = show => {
    return {
        type: MODAL_NEW_TUTOR,
        show,
    }
}

const showModalGestionarSolicitud = show => {
    return {
        type: MODAL_GESTIONAR_SOLICITUD,
        show,
    }
}

const showModalModSolicitud = show => {
    return {
        type: MODAL_MODIFICAR_SOLICITUD,
        show,
    }
}


const showModalProgramaGestor = show => {
    return {
        type: MODAL_PROGRAMA_GESTOR,
        show,
    }
}

const showModalPermisoEstadoGestor = show => {
    return {
        type: MODAL_PERMISO_ESTADO_GESTOR,
        show,
    }
}

const showModalEvalComite = show => {
    return {
        type: MODAL_EVALUACION_COMITE,
        show,
    }
}

const showModalFiltros = show => {
    return {
        type: MODAL_FILTROS,
        show,
    }
}

const showModalEventos = show => {
    return {
        type: MODAL_EVENTOS,
        show,
    }
}

const showModalNewEvento = show => {
    return {
        type: MODAL_NEW_EVENTO,
        show,
    }
}


const solicitudesGrados = grados => {
    return {
        type: SOLICITUDES_GRADOS,
        grados,
    }
}

const setEstadosSolicitud = estados => {
    return {
        type: ESTADOS_SOLICITUD,
        estados,
    }
}

const setEstado = estado => {
    return {
        type: ESTADO_SOLICITUD,
        estado,
    }
}

const setAdjuntosEstado = adjuntos => {
    return {
        type: ADJUNTOS,
        adjuntos,
    }
}

const setDataSolicitud = solicitud => {
    return {
        type: ULTIMA_SOLICITUD,
        solicitud,
    }
}

const setDataAdjunto = adjunto => {
    return {
        type: DATA_ADJUNTO_SOLICITUD,
        adjunto,
    }
}

const setTutores = tutores => {
    return {
        type: TUTORES,
        tutores,
    }
}

const setEventos = eventos => {
    return {
        type: EVENTOS,
        eventos,
    }
}



module.exports = {
    showModalTrabajoGrado,
    MODAL_NUEVO_GRADO,
    SOLICITUDES_GRADOS,
    solicitudesGrados,
    showModalDetalleGrado,
    showModalGestionarSolicitud,
    showModalProgramaGestor,
    showModalPermisoEstadoGestor,
    showModalEvalComite,
    showModalFiltros,
    showModalEventos,
    MODAL_DETALLE_GRADO,
    ESTADOS_SOLICITUD,
    ESTADO_SOLICITUD,
    setEstadosSolicitud,
    setEstado,
    MODAL_ADJUNTOS,
    showModalAdjuntos,
    setAdjuntosEstado,
    ADJUNTOS,
    setDataSolicitud,
    setDataAdjunto,
    setEventos,
    ULTIMA_SOLICITUD,
    DATA_ADJUNTO_SOLICITUD,
    MODAL_NEW_TUTOR,
    MODAL_GESTIONAR_SOLICITUD,
    showModalNewGestor,
    MODAL_MODIFICAR_SOLICITUD,
    showModalModSolicitud,
    setTutores,
    TUTORES,
    EVENTOS,
    showModalNewAdjuntos,
    showModalNewEvento,
    MODAL_NEW_ADJUNTOS,
    MODAL_PROGRAMA_GESTOR,
    MODAL_PERMISO_ESTADO_GESTOR,
    MODAL_EVALUACION_COMITE, 
    MODAL_FILTROS,
    MODAL_EVENTOS,
    MODAL_NEW_EVENTO,
}