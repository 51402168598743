//Importaciones necesaria para poder utilizar react
import React, { useState }  from 'react'
import ReactDOM from 'react-dom'; 

//Importaciones necesarias para que el modal del formulario de creacion de semilleros se pueda abrir, este es para que se habra el modal.
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button'
//Importe de los text faild para los formularios de los campos.
import TextField from '@material-ui/core/TextField';
//Importe de los grid para los textfield de los formularios entre otros aspecto.
import Grid from '@material-ui/core/Grid';

import { makeStyles } from '@material-ui/core/styles';
import { render } from 'react-dom';

import AppBarModal from '../../general/AppBarModal'

//Modal del agregar Objetivos General del Semillero

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up('xs')]: {
            minWidth: "400px"
          },
      },
      backButton: {
        marginRight: theme.spacing(1),
      },
      instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
      colorWhite : {
          color: '#fff'
      },
      flexGrow: {
          flexGrow: 1,
          width: '300px'
      },
      flexCenter:{
          textAlign: "center"
      },
      noneScroll:{
          overflowY: 'hidden',
          [theme.breakpoints.up('sm')]: {
            minWidth: "600px"
          },
      }
}));

export default function SemillerosAddObj(props) {
    const classes = useStyles();
    return (
        <div>
            <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">

                <AppBarModal titulo='Digite un objetivo' mostrarModal={props.handleClose} titulo_accion="" accion="" />
                <DialogContent className={classes.noneScroll}> 
                        <Grid item container>
                            <Grid item xs={12}>
                                <TextField
                                autoFocus
                                fullWidth 
                                multiline
                                rowsMax={6}
                                error = { props.getError('objEspSemi', props.errores).length > 0}
                                helperText = { props.getError('objEspSemi', props.errores) }
                                id="standard-required" 
                                label="Objetivo" 
                                name="objEspSemi" 
                                value={props.objEspSemi} 
                                onChange={props.handleOnchange}/>
                            </Grid>
                        </Grid>
                </DialogContent>

                <DialogActions>
                        <Button variant="contained" color="primary" style={{color: "#fff"}} onClick={props.addObjEsp}>
                            Agregar
                        </Button>
                </DialogActions>

            </Dialog>

        </div>
    );
}