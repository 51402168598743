import React, { Component } from "react";
import PropTypes from "prop-types";
import { consulta, generarFiltros } from "../../../global/js/funciones";
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import {
   BtnCancelar,
   BtnForm,
   BtnSeleccion,
} from "../../general/BotonesAccion";
import ListarDatos from "../../general/ListarDatos";
import AppBarModal from "../../general/AppBarModal";
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from "../../../global/imagenes/emma_w.png";

class ConveniosBuscarUniversidad extends Component {
   constructor(props) {
      super(props);
      this.state = {
         dato_buscar: "",
         pais_filtro: "",
         paises: [],
         convenios: [],
         tipos_instituciones: [],
         cargando: false,
         cargando_pais: false,
         modal_filtro_pais: false,
      };
   }

   async componentDidUpdate({ modalBuscarUniversidad }) {
      let { modalBuscarUniversidad: modalBuscarUniversidadNew } = this.props;
      if (modalBuscarUniversidadNew !== modalBuscarUniversidad) {
         this.setState({ cargando: true });
         await this.obtenerconvenios();
         this.setState({ cargando: false });
      }
   }

   obtenerconvenios = async () => {
      let f = await generarFiltros([
         { llave: "solicitud__estado_actual__codigo", valor: "Con_regis" },
      ]);
      consulta(
         `api/v1.0/internacionalizacion/solicitud/convenio?${f}`,
         null,
         null,
         (error, estado, resp) => {
            console.log(resp);
            if (estado === 200) this.setState({ convenios: resp });
         }
      );
   };

   acciones = (data) => {
      let { mostrarModalBuscarUniversidad, seleccionadas } = this.props;

      const onClickSeleccionar = (data) => {
         this.props.seleccionar(data);
         mostrarModalBuscarUniversidad(false);
      };

      const onClickQuitar = (data) => {
         this.props.quitar(data);
      };

      let seleccion = <BtnSeleccion callback={() => onClickSeleccionar(data)} />;
      let cancelar = <BtnCancelar callback={() => onClickQuitar(data)} />;
      return <>{seleccionadas == data.solicitud.id ? cancelar : seleccion}</>;
   };

   render() {
      let { modalBuscarUniversidad, mostrarModalBuscarUniversidad } = this.props;
      let { cargando, convenios } = this.state;
      return (
         <>
            <Dialog
               open={modalBuscarUniversidad}
               onClose={mostrarModalBuscarUniversidad.bind(this, false)}
               aria-labelledby="form-dialog-title"
               fullWidth={true}
               maxWidth="sm"
            >
               <AppBarModal
                  titulo={"¡ Buscar Convenio !"}
                  mostrarModal={mostrarModalBuscarUniversidad}
                  titulo_accion=""
                  accion=""
               />
               <DialogContent className="scroll" style={{ padding: 0 }}>
                  {cargando ? (
                     <TareasTerminadas
                        mensaje="Cargando.."
                        marginTop="7%"
                        imagen={emma_w}
                        widthImg="7%"
                     />
                  ) : convenios.length > 0 ? (
                     <ListarDatos
                        datos={convenios}
                        titulo="Lista de Convenios"
                        opciones={true}
                        buscar={true}
                        filtrar={false}
                        // actfiltrar = { () => this.setState({ modal_filtro_pais: true }) }
                        sub_titulo="Convenios"
                        id="tbl_convenios_seleccion"
                        acciones={(row) => this.acciones(row)}
                        fila_principal={({ institucion }) => institucion.toUpperCase()}
                        filas={[
                           {
                              nombre: "Código del convenio: ",
                              id: "codigo_inter",
                              mostrar: ({ codigo_convenio }) =>
                                 codigo_convenio ? codigo_convenio : "No registra",
                           },
                           {
                              nombre: "País: ",
                              id: "pais",
                              mostrar: ({ pais_vinculante }) =>
                                 pais_vinculante ? pais_vinculante.nombre : "No registra",
                           },
                           // {'nombre': 'Ciudad: ', 'id': 'ciudad', 'mostrar': ({ ciudad_vinculante }) => ciudad_vinculante ? ciudad_vinculante :"No registra"},
                        ]}
                     />
                  ) : (
                     <TareasTerminadas
                        imagen={emma_w}
                        mensaje="Esta bandeja está vacía"
                        widthImg="10%"
                        marginTop="10%"
                     />
                  )}
               </DialogContent>
               <DialogActions>
                  <BtnForm
                     texto="CERRAR"
                     callback={() => mostrarModalBuscarUniversidad(false)}
                  />
               </DialogActions>
            </Dialog>
         </>
      );
   }
}

ConveniosBuscarUniversidad.propTypes = {
   actualizarMensaje: PropTypes.func.isRequired,
   modalBuscarUniversidad: PropTypes.bool.isRequired,
   modalAddInstituciones: PropTypes.bool.isRequired,
   mostrarModalBuscarUniversidad: PropTypes.func.isRequired,
   mostrarModalAddInstituciones: PropTypes.func.isRequired,
};

export default ConveniosBuscarUniversidad;
