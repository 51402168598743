import React from "react";
import {BottomNavigation, BottomNavigationAction, Hidden, Badge,} from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import Procesos from "@material-ui/icons/ImportContacts";
import SchoolIcon from "@material-ui/icons/School";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import LoopIcon from "@material-ui/icons/Loop";
import VisitasNotificaciones from "./VisitaNotificaciones";
import Notificaciones from "@material-ui/icons/NotificationImportant";
import Calendar from "@material-ui/icons/Today";
import SettingsIcon from "@material-ui/icons/Settings";

const clase = (item, selec, tipo = "") =>  item === selec ? `item_selec${tipo}` : `item_modulo`;

export function MenuPromocion({notificaciones = [], sele = 0, seleccionar = () => { }, cargandoNotif, administrador,}) {
  const pintar = (app, claseN = "") => {
    if (!app) {
      return (
        <div className={claseN}>

			{/* ICONO NOTIFICACION */}
          <VisitasNotificaciones
            cargando={cargandoNotif}
            notificaciones={notificaciones}
            render={(click) => (
              <BottomNavigation
                showLabels
                className={clase("notificacion", sele)}
                onClick={(e) => {
                  click(e);
                  seleccionar("notificacion");
                }}
              >
                {" "}
                <BottomNavigationAction
                  label={"Notificación"}
                  icon={
                    <Badge
                      badgeContent={notificaciones.length}
                      color="secondary"
                    >
                      <Notificaciones />
                    </Badge>
                  }
                />
              </BottomNavigation>
            )}
          />

		  {/* ICONO NUEVO */}
          <BottomNavigation showLabels className={clase("agregar", sele)} onClick={() => seleccionar("agregar")}>
            <BottomNavigationAction label={"Nuevo"} icon={<CreateIcon />} />
          </BottomNavigation>

		  {/* ICONO HISTORIAL */}
          <BottomNavigation showLabels className={clase("historial", sele)} onClick={() => seleccionar("historial")}>
            <BottomNavigationAction label={"Historial"} icon={<SchoolIcon />} />
          </BottomNavigation>

		  {/* ICONO ADMINISTRAR */}
          {/* {administrador && ( */}
            <BottomNavigation showLabels className={clase("administrar", sele)} onClick={() => seleccionar("administrar")}>
              <BottomNavigationAction label={"Administrar"} icon={<SettingsIcon className="menu_icono" />} />
            </BottomNavigation>
          {/* )} */}

        </div>
      );
    } else {
      return (
        <div className={claseN}>

          {/* ICONO NOTIFICACION */}
          <VisitasNotificaciones
            notificaciones={notificaciones}
            render={(click) => (
              <BottomNavigation
                showLabels
                onClick={click}
                className={clase("notificaciones", sele)}
              >
                <BottomNavigationAction
                  label={"Notificación"}
                  icon={
                    <Badge
                      // badgeContent={notificaciones.length}
                      color="secondary"
                    >
                      <Notificaciones />
                    </Badge>
                  }
                />{" "}
              </BottomNavigation>
            )}
          />

          {/* ICONO NUEVO */}
          <BottomNavigation showLabels className={clase("agregar", sele)} onClick={() => seleccionar("agregar")}>
            <BottomNavigationAction label={"Nuevo"} icon={<CreateIcon />} />
          </BottomNavigation>

		  {/* ICONO HISTORIAL */}
          <BottomNavigation showLabels className={clase("historial", sele)} onClick={() => seleccionar("historial")}>
            <BottomNavigationAction label={"Historial"} icon={<SchoolIcon />} />
          </BottomNavigation>

		  {/* ICONO ADMINISTRAR */}
          {/* {administrador && ( */}
            <BottomNavigation showLabels className={clase("administrar", sele)} onClick={() => seleccionar("administrar")}>
              <BottomNavigationAction label={"Administrar"} icon={<SettingsIcon className="menu_icono" />} />
            </BottomNavigation>
          {/* )} */}
        </div>
      );
    }
  };

  return (
    <div>
      <div>
        <Hidden xsDown> {pintar(false, "menu_modulo")} </Hidden>
        <Hidden smUp> {pintar(true, "menu_modulo_app")}</Hidden>
      </div>
    </div>
  );
}

export function PromocionMenu({ sele = 0, seleccionar = () => { } }) {
  const pintar = (claseN = "") => {
    return (
      <div className={claseN}>
        <BottomNavigation showLabels>
          <BottomNavigationAction
            label={"Detalle"}
            icon={<ImportContactsIcon />}
            className={clase("detalle", sele)}
            onClick={() => seleccionar("detalle")}
          />
        </BottomNavigation>
      </div>
    );
  };
  return pintar("menu_modal");
}

export function VisitaMenuDetalle({ sele = 0, seleccionar = () => { } }) {
  const pintar = (claseN = "") => {
    return (
      <div className={claseN}>
        <BottomNavigation showLabels>
          <BottomNavigationAction
            label={"Detalle"}
            icon={<ImportContactsIcon />}
            className={clase("detalle", sele)}
            onClick={() => seleccionar("detalle")}
          />
          <BottomNavigationAction
            label={"Estado"}
            icon={<LoopIcon />}
            className={clase("estado", sele)}
            onClick={() => seleccionar("estado")}
          />
        </BottomNavigation>
      </div>
    );
  };
  return pintar("menu_modal");
}

export function VisitaMenu({ sele = 0, seleccionar = () => { } }) {
  const pintar = (claseN = "") => {
    return (
      <div className={claseN}>
        <BottomNavigation showLabels>
          <BottomNavigationAction
            label={"Gestionar"}
            icon={<LoopIcon />}
            className={clase("gestionar", sele)}
            onClick={() => seleccionar("gestionar")}
          />
        </BottomNavigation>
      </div>
    );
  };
  return pintar("menu_modal");
}

export function VisitaCalendarioMenu({sele = 0, seleccionar = () =>{}}){
  const pintar = (claseN= "") =>{
    return(
      <div className={claseN}>
        <BottomNavigation showLabels>
          <BottomNavigationAction
            label={"Detalle"}
            icon={<ImportContactsIcon />}
            className={clase("detalle", sele)}
            onClick={() => seleccionar("detalle")}
          />
          <BottomNavigationAction
            label={"Editar"}
            icon={<ImportContactsIcon />}
            className={clase("editar", sele)}
            onClick={() => seleccionar("editar")}
          />
        </BottomNavigation>
      </div>
    )
  }
  return pintar("menu_modal");

}
