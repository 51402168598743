import React, { useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import useWindowSize from '../../general/hooks/useWindowSize';
import { coloresEmma } from '../../../global/js/funciones';

const BorderCircularProgress = withStyles(theme => ({
  circle: {
    strokeLinecap: 'round',
    strokeWidth: '3'
  },
}))(CircularProgress);

const ProgressCircle = ({ percent, text, loading }) => {
  const windowSize = useWindowSize();

  return (
    <Box position="relative" display="inline-flex">
      {/* Fondo circular */}
      <div
        style={{
          width: windowSize < 650 ? '120px' : '200px',
          height: windowSize < 650 ? '120px' : '200px',
          borderRadius: '50%',
          border: `30px solid ${coloresEmma.primarycolor}`,
          background: `linear-gradient(300deg, ${coloresEmma.primarycolor}, ${coloresEmma.primarycolor})`,
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      />
      <BorderCircularProgress
        variant={!isNaN(percent) && !loading ? 'static' : 'indeterminate'}
        value={Math.round(percent)}
        style={{
          width: windowSize < 650 ? '120px' : '200px',
          height: windowSize < 650 ? '120px' : '200px',
          color: coloresEmma.secondarycolor,
        }}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '90%',
            height: '90%',
            borderRadius: '50%',
            justifyContent: 'center',
            border: `20px solid ${coloresEmma.primarycolor}`,
            background: `linear-gradient(400deg, ${coloresEmma.SilverGray}, #dadfdf)`,
          }}
        >
          <span
            style={{
              color: coloresEmma.colornegro,
              fontWeight: 'bold',
              fontSize: windowSize < 600 ? '8px' : '10px',
            }}
          >
            {text}
          </span>
          <span
            style={{
              fontSize: windowSize < 600 ? '10px' : '20px',
              color: coloresEmma.colornegro,
              display: 'flex',
              fontWeight: 'bold',
            }}
          >
            {!isNaN(percent) ? `${Math.round(percent)}` : null}
            <span style={{ fontSize: windowSize < 600 ? '10px' : '20px', alignSelf: 'center' }}>%</span>
          </span>
        </div>
      </Box>
    </Box>
  );
};

export default ProgressCircle;
