import React, { Component, forwardRef } from 'react'
import { Dialog, DialogContent, DialogActions, Grid, InputLabel, Select, MenuItem, FormControl,Checkbox,ListItemText, IconButton, Tooltip } from '@material-ui/core';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import AppBarModal from '../../general/AppBarModal';
import { BtnForm } from '../../general/BotonesAccion';
import { consulta, obtenerValoresFiltros, transformar, generarFiltros } from '../../../global/js/funciones'
import TareasTerminadas from "./../../general/TareasTerminadas";
import emma_w from './../../../global/imagenes/emma_w.png';
import emma_s from './../../../global/imagenes/emma_s1.png';
import BuscarSelect from '../../general/BuscarSelect';
import Box from '@material-ui/core/Box';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ListarTabla from '../../general/ListarTabla';

class SemillerosInforme extends Component {
   constructor(props) {
      super(props)
      this.state = {
         columnas: [],
         data_informe: [],
         cargando: false,
         periodo: '',
         programa: '',
         periodos: [],
         programas: [],
         mostrar : 'filtros',
         maxWidth : 'sm',
         fechaActual:'',
         fecha:'',
         cargar: true,
         programasSelect: [],
         programasMulti: [],
         periodosMulti: [],
         camposMostrar: [],
         practicas: [],
         programaDetalle: '',
         estadoSolicitud: '',
         meses: [],
         mes: ''
      };
   }

   async componentDidUpdate({ modalInforme }, { mostrar }) {
      let { mostrar: mostrarNew, cargar } = this.state;
      let { modalInforme: modalInformeNew, informe } = this.props;
      if (modalInforme !== modalInformeNew) {
         if (modalInformeNew && cargar) {
            this.setState({ cargando: true })
            const periodos = await this.obtenerPeriodos();
            this.setState({ periodos, cargando: false, cargar: false})
         }
         else this.resetState()
      }
   }

   obtenerPeriodos = () => {
      return new Promise(resolve => {
         consulta(`api/v1.0/investigacion/periodos`, null, null,
            (error, estado, resp) => {
               resolve(resp)
            }
         );
      })
   }
    
   obtenerSemillerosId = async (filtros = []) => {
    let { actualizarMensaje } = this.props
    let f = await generarFiltros(filtros);
    this.setState({ cargando: true });
    consulta(`api/v1.0/investigacion/listaSemilleros/1?${f}`, null, null,
      (error, estado, resp) => {
        if (estado === 200) this.setState({ cargando: false, data_informe: resp, mostrar: 'informe', maxWidth: 'lg' });
        else actualizarMensaje({ titulo: 'Ha ocurrido un error, contacte con el administrador.', tipo: 'info', mostrar: true, tiempo: 6000 });
      }
    );
   }
   
   obtenerSemilleros = async (filtros = []) => {
    let { actualizarMensaje } = this.props
    let f = await generarFiltros(filtros);
    this.setState({ cargando: true });
    consulta(`api/v1.0/investigacion/semillerosList?${f}`, null, null,
      (error, estado, resp) => {
        if (estado === 200) this.setState({ cargando: false, data_informe: resp, mostrar: 'informe', maxWidth: 'lg' });
        else actualizarMensaje({ titulo: 'Ha ocurrido un error, contacte con el administrador.', tipo: 'info', mostrar: true, tiempo: 6000 });
      }
    );
   }

   obtenerInforme = async (filtros = []) => {
      let { actualizarMensaje } = this.props
      let f = await generarFiltros(filtros);
      this.setState({ cargando: true });
      consulta(`api/v1.0/investigacion/informe?${f}`, null, null,
        (error, estado, resp) => {
          if (estado === 200) this.setState({ cargando: false, data_informe: resp, mostrar: 'informe', maxWidth: 'lg' });
          else actualizarMensaje({ titulo: 'Ha ocurrido un error, contacte con el administrador.', tipo: 'info', mostrar: true, tiempo: 6000 });
        }
      );
   }

   onChange = ({ target }) => {
      let { value, name } = target
      this.setState({ [name]: value })
   }

   resetState = () => {
      let { mostrar } = this.state

      if(mostrar==='filtros') this.setState({ data_informe: [], columnas: [], mostrar: 'filtros', maxWidth: 'sm', periodo: '', programa: '', modalidad: '', trabaja: '', busca_empresa: '', programasMulti: [], periodosMulti: [], modalidadesMulti: [], camposMostrar: [], contratosMulti: [], practicas: [], estadoSolicitud: '', mes: '', cargando: false })
      else if(mostrar === 'informe') this.setState({ mostrar:'filtros', maxWidth: 'sm', columnas: [], cargando: false })
      else if(mostrar === 'filtroDetalle') this.setState({ mostrar:'informe', maxWidth: 'lg', practicas: [], cargando: false })
      else this.setState({ mostrar:'filtroDetalle', maxWidth: 'sm', cargando: false })
   }

   onSubmit = (e) => {
    let { periodo, programa} = this.state
    let { actualizarMensaje, informe } = this.props
    let filtros = [], titulo='';
   
    if(informe.codigo=="inf_gru"){
       if(periodo !== ''){
          filtros.push({ 'llave': 'informe', 'valor': informe.codigo }); 
          filtros.push({ 'llave': 'periodo', 'valor': periodo });

         }else titulo = 'Por favor, seleccione el periodo.'
         if(titulo) actualizarMensaje({ titulo, tipo: 'info', mostrar: true, tiempo: 6000 });
         else  this.obtenerInforme(filtros) 
         e.preventDefault();
    }else{
      if(periodo !== ''){
         filtros.push({ 'llave': 'informe', 'valor': informe.codigo }); 
         filtros.push({ 'llave': 'periodo', 'valor': periodo });

        }else titulo = 'Por favor, seleccione el periodo.'
        if(titulo) actualizarMensaje({ titulo, tipo: 'info', mostrar: true, tiempo: 6000 });
        else  this.obtenerInforme(filtros)
        e.preventDefault();
    }

   }

   pintar = () => {
      let { cargando, mostrar } = this.state;
      if (cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />;
      return (
         <div>
            {mostrar === 'filtros' && this.pintarFiltros()}
            {mostrar === 'informe' && this.pintarInforme()}
            {/* {mostrar === 'filtroDetalle' && this.pintarFiltroDetalle() }
            {mostrar === 'detalle' && this.pintarDetalle()} */}
         </div>
      )
   }

   pintarFiltros = () => {
      let { informe } = this.props
      let { periodo, periodos } = this.state
      if(informe.codigo=="inf_gru" || informe.codigo=="inf_gene" ||informe.codigo== "inf_prod" ||informe.codigo=='inf_tutor' ||informe.codigo=='inf_part'||informe.codigo=='inf_product'||informe.codigo=='inf_part_estados'){
         return(
            <Grid container spacing={3} className='margin_cont'>
               <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                  <FormControl className='form-control'>
                     <InputLabel htmlFor="periodo">Seleccione Periodo</InputLabel>
                     <Select
                        inputProps={{
                           name: 'periodo',
                           id: 'periodo',
                        }}
                        value={periodo}
                        onChange={this.onChange}
                        required={true}>
                        {periodos.map(({ periodo }, i) => <MenuItem key={i} value={periodo}>{periodo}</MenuItem>)}
                     </Select>
                  </FormControl>
               </Grid>
            </Grid> 
         )
      }
   }

   pintarInforme = () => {
      let { data_informe, periodo, columnas} = this.state
      let { informe } = this.props
      let ordenar = false;
      //  console.log(data_informe);
            let codigo = informe.codigo
            let campos={}
            if(codigo === 'inf_gru'){
               if(data_informe.length>0){
                  if(columnas.length == 0){

                     columnas.push(
                        { title: 'NOMBRE DEL SEMILLERO', field: 'nombre' },
                        { title: 'SIGLA', field: 'sigla' },
                        { title: 'N° DE PARTICIPANTES', field: 'participantes' },
                        { title: 'PROGRAMAS', field: 'programa' },
                        { title: 'DEPARTAMENTOS', field: 'departamento' },
                        { title: 'GRUPOS', field: 'grupos' },
                        { title: 'LÍNEAS DE INVESTIGACIÓN', field: 'lineas' },
                        { title: 'SUBLINEAS DE INVESTIGACIÓN', field: 'sublineas' },
                        { title: 'PROFESOR ASESOR', field: 'profesor' },
                        { title: 'ESTUDIANTE LÍDER', field: 'estudiante' },
                        { title: 'CORREO ELECTRÓNICO', field: 'correo' },
                        { title: 'TELEFONO', field: 'celular' })
      
                     
                     
                    data_informe.map((e) =>{
                     let grupos=''
                     let departamento=''
                     let programa=''
                     let lineas=''
                     let sublineas=''
                     let profesor=''
                     let estudiante=''
                     let correo=e.solicitud.usuario_registro.correo
                     let celular=e.solicitud.usuario_registro.celular
                     let cont=0;


                     e.semillero_programa_departamento_investigacion.map((p)=>{
                        if(p.estado==1){
                           departamento = departamento + ' * ' + p.nombre.split('/')[1];
                           programa = programa + ' * ' + p.nombre.split('/')[0];
                        }
                        
                     })
                        
                     e.semillero_Linea_sublinea_investigacion.map((l)=>{
                        if(l.estado==1){
                           lineas =   lineas + '*' +l.nombre.split('/')[0] ;
                           sublineas =  sublineas + '*' + l.nombre.split('/')[1] ;
                        }
                     })
                        
                     e.semillero_grupos_investigacion.map((g)=>{
                        if(g.estado==1){
                           grupos= grupos + '*'+ g.nombreGrupo
                        }
                     })
                     
                     e.semillero_Encargado_investigacion.map((l)=>{
                        if(l.estado==1){
                           if(l.rol.nombre=="Profesor Asesor"){
                              profesor= profesor + ' * '+l.nombre 
                           }else if(l.rol.nombre=="Estudiante Lider"){
                              estudiante=estudiante+ ' * ' + l.nombre
                           }
                        }

                     })

                     e.semillero_Participante_investigacion.map((x) => {
                        if(x.estado==1){
                           if(x.estado_actual.nombre=="Aceptado"){
                              cont=cont + 1
                           }
                           return cont
                        }
                     })
                  
      
                     
                     e.departamento=departamento
                     e.programa=programa
                     e.grupos=grupos
                     e.lineas=lineas
                     e.sublineas=sublineas  
                     e.participantes = cont
                     e.profesor=profesor
                     e.estudiante=estudiante
                     e.correo=correo
                     e.celular=celular

                    })
      
                  const titulo_informe = (periodo) ? `${informe.nombre} - ${periodo}` : informe.nombre
         
                  return (
                  <ListarTabla 
                  titulo={titulo_informe} 
                  columnas={columnas} 
                  datos={data_informe} 
                  orden={ordenar} />
                  )
                  }
               }else{
                  return(
                     <TareasTerminadas mensaje='No se encontraron resultados' marginTop='7%' imagen={emma_s} widthImg="7%" />
                  )
               }

            }else if(codigo === 'inf_gene'){
                  if(data_informe.length>0){
                     if(columnas.length == 0){
                        columnas.push(
                           { title: 'NOMBRE DEL SEMILLERO', field: 'nombresemillero'},
                           { title: 'ESTUDIANTES', field: 'nombre' },
                           { title: 'N° DE IDENTIFICACION', field: 'identificacion' },
                           { title: 'CORREO ELECTRÓNICO', field: 'correo' },
                           { title: 'GRUPOS', field: 'grupos' },
                           { title: 'PROGRAMA ACADÉMICO', field: 'programa' },
                           { title: 'DEPARTAMENTOS', field: 'departamento' },
                           { title: 'ESTADO PARTICIPANTE', field: 'estado' },
                           // { title: 'TIPO DE VINCULACIÓN', field: 'categorias' },
                           { title: 'ESTUDIANTE GRADUADO', field: 'graduado' }
                           )

                        data_informe.map((e) =>{
                              let nombresemillero=e.semillero.nombre
                              let nombre=e.participante.primer_nombre + ' ' + e.participante.segundo_nombre+ ' ' + e.participante.primer_apellido+ ' ' + e.participante.segundo_apellido
                              let identificacion=e.participante.identificacion
                              let correo=e.participante.correo
                              let grupos=''
                              let programa=''
                              let departamento=''
                              let estado=e.estado_actual.nombre
                              let categorias= ''
                              let graduado=''

                              e.semillero.semillero_grupos_investigacion.map((g)=>{
                                 if(g.estado==1){
                                    grupos=grupos + '*' + g.nombreGrupo
                                 }
                              })

                              // if(e.participante.categoria_investigacion !== null){
                              //    categorias=e.participante.categoria_investigacion.nombre
                              // }else{
                              //    categorias='N/A'
                              // }

                              e.semillero.semillero_programa_departamento_investigacion.map((p)=>{
                                 if(p.estado==1){
                                    departamento = departamento + '*' + p.nombre.split('/')[1];
                                    programa = programa + '*' + p.nombre.split('/')[0];
                                 }
                              })

                              if(e.Rol_Graduado==true){
                                 graduado='SI'
                              }else{
                                 graduado='NO'
                              }

                              e.nombresemillero=nombresemillero
                              e.identificacion=identificacion
                              e.nombre=nombre
                              e.programa=programa
                              e.correo=correo
                              e.grupos=grupos
                              e.departamento=departamento
                              e.estado=estado
                              e.categorias=categorias
                              e.graduado=graduado
                        })
                  const titulo_informe = (periodo) ? `${informe.nombre} - ${periodo}` : informe.nombre
                  
                     return (
                     <ListarTabla 
                     titulo={titulo_informe} 
                     columnas={columnas} 
                     datos={data_informe} 
                     orden={ordenar} />
                     )

                     }
                  }else{
                     return(
                        <TareasTerminadas mensaje='No se encontraron resultados' marginTop='7%' imagen={emma_s} widthImg="7%" />
                     )
                  }
           }else if (codigo === 'inf_prod'){

            if(data_informe.length>0){
               if(columnas.length == 0){
                  columnas.push(
                     { title: 'PRODUCTO', field: 'nombre_producto'},
                     { title: 'TIPO DE PRODUCTO', field: 'tipo_producto' },
                     { title: 'SUB TIPO DE PRODUCTO', field: 'nombre_subproducto' },
                     { title: 'SEMILLERO', field: 'semillero' },
                     { title: 'NOMBRE AUTOR Y MODALIDAD', field: 'autores' },
                     { title: 'FECHA INICIO', field: 'fecha_inicio' },
                     { title: 'FECHA FIN', field: 'fecha_final' },)
                     
                     
                     data_informe.map((g)=>{
                        let nombre_producto=''
                        let tipo_producto=''
                        let semillero=g.semillero.nombre
                        let nombre_subproducto=''
                        let autores=''
                        let modalidad=''
                        let fecha_inicio=''
                        let fecha_final=''
   
                        if(g.tipo!=null){
                           nombre_subproducto=g.tipo.nombre
                           fecha_inicio=g.fecha_de_inicio
                           fecha_final=g.fecha_de_fin
                           
                        }else{
                           nombre_subproducto=g.tipoProducto.nombre
                        }
   
                        if(g.tipo==null){
                           tipo_producto=g.producto.nombre
                        }else if(g.tipo_de_producto==null){
                           tipo_producto=g.tipo.nombre
                        }else{
                           tipo_producto=g.tipo_de_producto.nombre
                        }
                        if(g.key==null){
                           nombre_producto=g.nombre
                        }else{
                           g.key.map((k)=>{
                              if(k.nombre==='nombreEventoArtistico'|| k.nombre=='nombreObraProducto'||k.nombre=='nombreEventoTalleresCreacion'||
                              k.nombre=='nombreEmpresaBaseTecno'||k.nombre=='nombreEmpresaCreativaCultural'||k.nombre=='nombreInnovacionProceServi'||k.nombre=='nombreInnovacionGestionEmp'||
                              k.nombre=='nombreDiseñoIndustrial'||k.nombre=='nombrePrototipo'||k.nombre=='nombreTitularSignoDistintivo'||
                              k.nombre=='nombreDelSoftware'){
                                 nombre_producto=k.valor
                              }
                              if( k.nombre=='fechaInicioEventoArtistico'||k.nombre=='fechaInicioTalleresCreacion'||k.nombre=='fechaCreacionObraProducto'){
                                 fecha_inicio=k.valorFecha
                              }
                              if( k.nombre=='fechaFinalEventoArtistico'|| k.nombre=='fechaFinalTalleresCreacion'||k.nombre=='fechaPresentacionObraProducto'){
                                 fecha_final=k.valor
                              }
                           }) 
                        }
                        
                       
                       
                        g.Autor_investigacion.map((a)=>{
                           if(g.tipo!=null && a.modalidad_de_participacion != null){
                              autores=autores + ' * ' + a.participante.primer_nombre+ ' ' + a.participante.primer_apellido + ' ' + a.participante.segundo_apellido + ', MODALIDAD:'+ ' ' + a.modalidad_de_participacion.nombre
                              
                           }else if(g.tipo!=null && a.modalidad_de_participacion == null){
                              
                              autores=autores + ' * ' + a.participante.primer_nombre+ ' ' + a.participante.primer_apellido + ' ' + a.participante.segundo_apellido + ', MODALIDAD:'+ ' ' + 'N/A'
                           }else if(g.tipo==null && a.rol__nombre !=null){
                              autores=autores + ' * ' + a.participante__primer_nombre+ ' ' + a.participante__primer_apellido+ ' ' + a.participante__segundo_apellido + ', MODALIDAD:'+ ' '+ a.rol__nombre
                           }else if(g.tipo==null && a.rol__nombre ==null){
                              autores=autores + ' * ' + a.participante__primer_nombre+ ' ' + a.participante__primer_apellido+ ' ' + a.participante__segundo_apellido + ', MODALIDAD:'+ ' '+ 'N/A'
                           }

                        })

                        g.Autor_investigacion.map((r)=>{
                           if(r.modalidad_de_participacion!=null && g.tipo!=null){
                              modalidad=r.modalidad_de_participacion.nombre
                           }else if(r.rol__nombre!=null){
                              modalidad=r.rol__nombre
                           }else if(r.modalidad_de_participacion==null && r.rol__nombre==null){
                              modalidad='N/A'
                           }else if(g.tipo==null ){
                              modalidad='N/A'
                           }
                           
                        })
                        
                        g.nombre_producto=nombre_producto
                        g.tipo_producto=tipo_producto
                        g.semillero=semillero
                        g.nombre_subproducto=nombre_subproducto
                        g.autores=autores
                        g.modalidad=modalidad
                        g.fecha_inicio=fecha_inicio
                        g.fecha_final=fecha_final
                        
   
                        })
            const titulo_informe = (periodo) ? `${informe.nombre} - ${periodo}` : informe.nombre
            
               return (
               <ListarTabla 
               titulo={titulo_informe} 
               columnas={columnas} 
               datos={data_informe} 
               orden={ordenar} />
               )

               }
            }else{
               return(
                  <TareasTerminadas mensaje='No se encontraron resultados' marginTop='7%' imagen={emma_s} widthImg="7%" />
               )
            }

           }else if(codigo==='inf_tutor'){
            if(data_informe.length>0 ){
               if(columnas.length == 0){
                  columnas.push(
                     { title: 'NOMBRE DEL SEMILLERO', field: 'nombre' },
                     { title: 'SIGLA', field: 'sigla' },
                     { title: 'OBJETIVO GENERAL', field: 'objetivo_general' },
                     { title: 'OBJETIVOS ESPECIFICOS', field: 'objetivos' },
                     { title: 'MISIÓN', field: 'mision' },
                     { title: 'VISIÓN', field: 'vision' },
                     { title: 'DEPARTAMENTOS', field: 'departamento' },
                     { title: 'PROGRAMAS', field: 'programa' },
                     { title: 'LÍNEAS DE INVESTIGACIÓN', field: 'lineas' },
                     { title: 'SUBLINEAS DE INVESTIGACIÓN', field: 'sublineas' },
                     { title: 'GRUPOS', field: 'grupo' },
                     { title: 'LIDERES', field: 'lideres' },
                     { title: 'PARTICIPANTES', field: 'participante' })


                     data_informe.map((f) =>{
                        let objetivos= ''
                        let departamento=''
                        let programa=''
                        let lineas=''
                        let sublineas=''
                        let grupo=''
                        let lideres=''
                        let participante=''
                           f.semillero_objetivo_investigacion.map((o)=>{
                              if(o.estado==1){
                                 objetivos= objetivos + ' * ' + o.nombre 
                              }
                              })

                           f.semillero_programa_departamento_investigacion.map((p)=>{
                              if(p.estado==1){
                                 departamento = departamento + ' * ' + p.nombre.split('/')[1];
                                 programa = programa + ' * ' + p.nombre.split('/')[0];
                              }
                              })
                           f.semillero_Linea_sublinea_investigacion.map((l)=>{
                              if(l.estado==1){
                                 lineas = lineas + ' * ' + l.nombre.split('/')[0]  ;
                                 sublineas = sublineas + ' * ' + l.nombre.split('/')[1] ;
                              }
                           })
                           
                           f.semillero_grupos_investigacion.map((g)=>{
                              if(g.estado==1){
                                 grupo= grupo + ' * '+ g.nombreGrupo
                              }
   
                           })

                           f.semillero_Encargado_investigacion.map((l)=>{
                              if(l.estado==1){
                                 lideres= lideres + ' * '+l.nombre + ' ' + l.rol.nombre
                              }

                        })

                        f.semillero_Participante_investigacion.map((i)=>{
                           
                           participante= participante + ' * '+ i.participante.primer_nombre + ' ' + i.participante.primer_apellido + ' ' + 
                           i.participante.segundo_apellido
                          
 

                     })


                        f.objetivos=objetivos
                        f.departamento=departamento
                        f.programa=programa
                        f.lineas=lineas
                        f.sublineas=sublineas
                        f.grupo=grupo
                        f.lideres=lideres
                        f.participante=participante
                            
                        
                     })
                     // r.nombre=nombresemillero
                             
            const titulo_informe = (periodo) ? `${informe.nombre} - ${periodo}` : informe.nombre
            
               return (
               <ListarTabla 
               titulo={titulo_informe} 
               columnas={columnas} 
               datos={data_informe} 
               orden={ordenar} />
               )

               }
            }else{
               return(
                  <TareasTerminadas mensaje='No se encontraron resultados' marginTop='7%' imagen={emma_s} widthImg="7%" />
               )
            }
           }else if (codigo==='inf_part'){
            if(data_informe.length>0 ){
               if(columnas.length == 0){
                  columnas.push(
                     { title: 'NOMBRE DEL SEMILLERO', field: 'nombre'},
                     { title: 'GRUPOS', field: 'grupo' },
                     { title: 'ESTUDIANTES', field: 'estudiante' },
                     { title: 'N° DE IDENTIFICACION', field: 'identificacion' },
                     { title: 'PROGRAMA ACADÉMICO', field: 'programa' },
                     { title: 'TIPO DE VINCULACIÓN', field: 'categoria' },
                     { title: 'CORREO ELECTRÓNICO', field: 'correo' })


                     data_informe.map((e) =>{
                     
                     e.identificacion=e.participante.identificacion
                     e.nombre=e.semillero.nombre
                     e.estudiante=e.participante.primer_nombre + ' ' + e.participante.primer_apellido+ ' ' + e.participante.segundo_apellido
                     e.programa=e.programa.nombre
                     e.correo=e.usuario_registro.correo
                     let grupo=''

                     e.semillero.semillero_grupos_investigacion.map((g)=>{
                        if(g.estado==1){
                           grupo = grupo + ' * ' + g.nombreGrupo 
                        }
   
                     })

                     if(e.participante.categoria_investigacion!= null){

                        e.categoria=e.participante.categoria_investigacion.nombre
                     }else{
                        e.categoria='N/A'
                     }
                     e.grupo=grupo
                     })    
                     
            const titulo_informe = (periodo) ? `${informe.nombre} - ${periodo}` : informe.nombre
            
               return (
               <ListarTabla 
               titulo={titulo_informe} 
               columnas={columnas} 
               datos={data_informe} 
               orden={ordenar} />
               )

               }
            }else{
               return(
                  <TareasTerminadas mensaje='No se encontraron resultados' marginTop='7%' imagen={emma_s} widthImg="7%" />
               )
            }
           }else if (codigo==='inf_part_estados'){
            if(data_informe.length>0 ){
               if(columnas.length == 0){
                  columnas.push(
                     { title: 'NOMBRE DEL SEMILLERO', field: 'nombre'},
                     { title: 'GRUPOS', field: 'grupo' },
                     { title: 'ESTUDIANTES', field: 'estudiante' },
                     { title: 'N° DE IDENTIFICACION', field: 'identificacion' },
                     { title: 'PROGRAMA ACADÉMICO', field: 'programa' },
                     { title: 'TIPO DE VINCULACIÓN', field: 'categoria' },
                     { title: 'CORREO ELECTRÓNICO', field: 'correo' },
                     { title: 'ESTADO PARTICIPANTE', field: 'estado' })


                     data_informe.map((e) =>{
                     
                     e.identificacion=e.participante.identificacion
                     e.nombre=e.semillero.nombre
                     e.estudiante=e.participante.primer_nombre + ' ' + e.participante.primer_apellido+ ' ' + e.participante.segundo_apellido
                     e.programa=e.programa.nombre
                     e.estado=e.estado_actual.nombre
                     e.correo=e.usuario_registro.correo
                     let grupo=''

                     e.semillero.semillero_grupos_investigacion.map((g)=>{
                        if(g.estado==1){
                           grupo = grupo + ' * ' + g.nombreGrupo 
                        }
   
                     })

                     if(e.participante.categoria_investigacion!= null){

                        e.categoria=e.participante.categoria_investigacion.nombre
                     }else{
                        e.categoria='N/A'
                     }
                     e.grupo=grupo
                     })    
                     
            const titulo_informe = (periodo) ? `${informe.nombre} - ${periodo}` : informe.nombre
            
               return (
               <ListarTabla 
               titulo={titulo_informe} 
               columnas={columnas} 
               datos={data_informe} 
               orden={ordenar} />
               )

               }
            }else{
               return(
                  <TareasTerminadas mensaje='No se encontraron resultados' marginTop='7%' imagen={emma_s} widthImg="7%" />
               )
            }
           }else if (codigo === 'inf_product'){

            if(data_informe.length>0){
               if(columnas.length == 0){
                  columnas.push(
                     { title: 'PRODUCTO', field: 'nombre_producto'},
                     { title: 'TIPO DE PRODUCTO', field: 'tipo_producto' },
                     { title: 'SUB TIPO DE PRODUCTO', field: 'nombre_subproducto' },
                     { title: 'SEMILLERO', field: 'semillero' },
                     { title: 'NOMBRE AUTOR Y MODALIDAD', field: 'autores' },
                     { title: 'FECHA INICIO', field: 'fecha_inicio' },
                     { title: 'FECHA FINAL', field: 'fecha_final' },)
                  

                     data_informe.map((g)=>{
                     let nombre_producto=''
                     let tipo_producto=''
                     let semillero=g.semillero.nombre
                     let nombre_subproducto=''
                     let autores=''
                     let fecha_inicio=''
                     let fecha_final=''

                     if(g.tipo!=null){
                        nombre_subproducto=g.tipo.nombre
                        fecha_inicio=g.fecha_de_inicio
                        fecha_final=g.fecha_de_fin
                        
                     }else{
                        nombre_subproducto=g.tipoProducto.nombre
                      
                     }

                     if(g.tipo==null){
                        tipo_producto=g.producto.nombre

                     }else if(g.tipo_de_producto==null){
                        tipo_producto=g.tipo.nombre
                     }else{
                        tipo_producto=g.tipo_de_producto.nombre
                     }
                     if(g.key==null){
                        nombre_producto=g.nombre
                     }else{
                        g.key.map((k)=>{
                           if(k.nombre==='nombreEventoArtistico'|| k.nombre=='nombreObraProducto'||k.nombre=='nombreEventoTalleresCreacion'||
                           k.nombre=='nombreEmpresaBaseTecno'||k.nombre=='nombreEmpresaCreativaCultural'||k.nombre=='nombreInnovacionProceServi'||k.nombre=='nombreInnovacionGestionEmp'||
                           k.nombre=='nombreDiseñoIndustrial'||k.nombre=='nombrePrototipo'||k.nombre=='nombreTitularSignoDistintivo'||
                           k.nombre=='nombreDelSoftware'){
                              nombre_producto=k.valor
                              
                           }
                           if( k.nombre=='fechaInicioEventoArtistico'||k.nombre=='fechaInicioTalleresCreacion'||k.nombre=='fechaCreacionObraProducto'){
                              fecha_inicio=k.valorFecha
                           }
                           if( k.nombre=='fechaFinalEventoArtistico'|| k.nombre=='fechaFinalTalleresCreacion'||k.nombre=='fechaPresentacionObraProducto'){
                              fecha_final=k.valor
                           }
                        }) 
                     }
                     
                    
                   
                     g.Autor_investigacion.map((a)=>{
                        if(g.tipo!=null && a.modalidad_de_participacion != null){
                           autores=autores + ' * ' + a.participante.primer_nombre+ ' ' + a.participante.primer_apellido + ' ' + a.participante.segundo_apellido + ', MODALIDAD:'+ ' ' + a.modalidad_de_participacion.nombre
                           
                        }else if(g.tipo!=null && a.modalidad_de_participacion == null){
                           
                           autores=autores + ' * ' + a.participante.primer_nombre+ ' ' + a.participante.primer_apellido + ' ' + a.participante.segundo_apellido + ', MODALIDAD:'+ ' ' + 'N/A'
                        }else if(g.tipo==null && a.rol__nombre !=null){
                           autores=autores + ' * ' + a.participante__primer_nombre+ ' ' + a.participante__primer_apellido+ ' ' + a.participante__segundo_apellido + ', MODALIDAD:'+ ' '+ a.rol__nombre
                        }else if(g.tipo==null && a.rol__nombre ==null){
                           autores=autores + ' * ' + a.participante__primer_nombre+ ' ' + a.participante__primer_apellido+ ' ' + a.participante__segundo_apellido + ', MODALIDAD:'+ ' '+ 'N/A'
                        }
                        
                     })
                 
                     g.nombre_producto=nombre_producto
                     g.tipo_producto=tipo_producto
                     g.semillero=semillero
                     g.nombre_subproducto=nombre_subproducto
                     g.autores=autores
                     g.fecha_inicio=fecha_inicio
                     g.fecha_final=fecha_final
                     

                     })
                            
            const titulo_informe = (periodo) ? `${informe.nombre} - ${periodo}` : informe.nombre
            
               return (
               <ListarTabla 
               titulo={titulo_informe} 
               columnas={columnas} 
               datos={data_informe} 
               orden={ordenar} />
               )

          }
         }else{
               return(
                  <TareasTerminadas mensaje='No se encontraron resultados' marginTop='7%' imagen={emma_s} widthImg="7%" />
               )
            }
      }
         
   }

   render() {
      let { cargando, maxWidth, mostrar } = this.state;
      let { modalInforme, mostrarModalInforme, informe } = this.props
      return (
         <Dialog fullWidth={true} maxWidth={maxWidth} PaperProps={ (informe.codigo === 'inf_gru') ? {style: {overflowY: 'visible'}} : {} } open={modalInforme} onClose={() => mostrarModalInforme(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <AppBarModal titulo={informe.nombre} mostrarModal={() => mostrarModalInforme(false)}  />
            <DialogContent className='scroll' style={mostrar === 'informe' ? { padding: 0 } : ((informe.codigo === 'inf_gru') ? { overflowY: 'visible' }:{})}>
               {!cargando ? this.pintar() : <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />}
            </DialogContent>
            <DialogActions>
               <BtnForm texto="Cerrar" callback={() => mostrarModalInforme(false)} />
               {!cargando && mostrar === 'filtros' && <BtnForm texto="GENERAR" callback={this.onSubmit} />}
            </DialogActions>
         </Dialog>
      );
   }
}

export default SemillerosInforme