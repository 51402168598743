import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogContent, DialogActions, Grid } from '@material-ui/core'
import AppBarModal from '../../general/AppBarModal'
import { BtnForm } from '../../general/BotonesAccion'
import { obtenerPermisosValor, obtenerValoresFiltros } from "../../../global/js/funciones"
import { ESTADO_ESTUDIANTE, PROGRAMAS_ATENCION, TIPO_ATENCION_ID, FACULTADES_ATENCION } from "../Helpers"
import { AtencionFormCrear } from './AtencionFormCrear'

class AtencionCrear extends Component {
  constructor(props) {
    super(props)
    this.state = {
      carrera: '',
      telefono: '',
      celular: '',
      asignatura: '',
      activeStep: 0,
      check_validaciones: false,
      carreras: [],
      asignaturas: [],
      cargar: true,
      acu_validaciones: [],
      titulo_carg: 'Cargando...',
      electiva: '',
      electivas: [],
      habilitarelectiva: false,
      estado_estudiante_id: '',
      estado_estudiante: [],
      tipo_incidente_id: '',
      tipo_incidente: [{ codigo: '', value: '', label: 'Para mostar debes seleccionar tu estado' }],
      tipo_atencion: [],
      tipo_atencion_id: '',
      plataforma: [],
      plataforma_id: '',
      observaciones: '',
      facultad_atencion: [],
      facultad_atencion_id: '',
      programa: [],
      programa_estudiante_id: '',
      estado_estudiante: [],
      estado_estudiante_id: '',
      coordinador: []
    }
  }

  async componentDidMount() {
    this.obtenerDatos()
  }


  transformar = data => {
    return data.map(e => ({
      value: e.id,
      label: e.nombre,
      codigo: e.codigo
    }))
  }

  async obtenerDatos() {
    this.setState({ cargando: true })
    const programa_estudiante_datos = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': PROGRAMAS_ATENCION }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valorc', 'valor': 'pregrado' }])
    let estado_estudiante_datos = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': ESTADO_ESTUDIANTE }, { 'llave': 'estado', 'valor': 1 }])
    let tipo_atencion_datos = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPO_ATENCION_ID }, { 'llave': 'estado', 'valor': 1 }])
    let facultad_atencion_datos = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': FACULTADES_ATENCION }])
    let estado_estudiante = await this.transformar(estado_estudiante_datos)
    let tipo_atencion = await this.transformar(tipo_atencion_datos)
    let facultad_atencion = await this.transformar(facultad_atencion_datos)
    let programa = await this.transformar(programa_estudiante_datos)
    this.setState({ estado_estudiante, tipo_atencion, facultad_atencion, programa, cargando: false })
  }

  // Facultad
  onChangeFacultadAtencion = async (e) => {
    this.setState({ cargando: true })
    let id = e.currentTarget.dataset.value
    this.setState({
      facultad_atencion_id: id,
    })
    this.setState({ cargando: false })
  }

  onChangeFacultadAtencion = async (e) => {
    this.setState({ cargando: true })
    let id = e.currentTarget.dataset.value
    let programa_estudiante_datos = await obtenerPermisosValor(id)
    let programa = await programa_estudiante_datos.map(data => ({
      value: data.secundario.id,
      label: data.secundario.nombre,
      codigo: data.secundario.codigo
    }))

    this.setState({
      facultad_atencion_id: id,
      programa
    })
    this.setState({ cargando: false })
  }

  // Programa
  onChangeProgramaEstudiante = async (e) => {
    this.setState({ cargando: true })
    let id = e.currentTarget.dataset.value
    this.setState({
      programa_estudiante_id: id
    })
    this.setState({ cargando: false })
  }

  // Tipo de atencion
  onChangeTipoAtencion = async (e) => {
    this.setState({ cargando: true })
    let id = e.currentTarget.dataset.value
    this.setState({
      tipo_atencion_id: id,
    })
    this.setState({ cargando: false })
  }

  // Estado
  onChangeEstadoEstudiante = async (e) => {
    this.setState({ cargando: true })
    let id = e.currentTarget.dataset.value
    this.setState({
      estado_estudiante_id: id
    })
    this.setState({ cargando: false })
  }

  // Incidente
  onChangeProgramas = async (e) => {
    this.setState({ cargando: true })
    let id = e.currentTarget.dataset.value
    let tipo_incidente_datos = await obtenerPermisosValor(id)
    let tipo_incidente = await tipo_incidente_datos.map(data => ({
      value: data.secundario.id,
      label: data.secundario.nombre,
      codigo: data.secundario.codigo
    }))

    this.setState({
      estado_estudiante_id: id,
      tipo_incidente
    })
    this.setState({ cargando: false })
  }

  // Plataforma
  onChangeIncidente = async (e) => {
    this.setState({ cargando: true })
    let id = e.currentTarget.dataset.value
    let plataforma_datos = await obtenerPermisosValor(id)
    let plataforma = plataforma_datos ? await plataforma_datos.map(data => ({
      value: data.secundario.id,
      label: data.secundario.nombre,
      codigo: data.secundario.codigo
    })) : []
    this.setState({
      tipo_incidente_id: id,
      plataforma
    })
    this.setState({ cargando: false })
  }

  // Plataforma
  onChangePlataforma = async (e) => {
    this.setState({ cargando: true })
    let id = e.currentTarget.dataset.value
    this.setState({ plataforma_id: id })
    this.setState({ cargando: false })
  }

  onChange = ({ target }) => {
    let { value, name } = target
    this.setState({
      [name]: value
    })
  }

  checked = ({ target }) => {
    this.setState({
      [target.name]: target.checked,
      carrera: ''
    })
  }

  reiniciarEstado() {
    this.setState({
      activeStep: 0,
      cargando: false,
      titulo_carg: 'Cargando...',
      check_validaciones: false,
      carrera: '',
      asignatura: '',
      observaciones: '',
      electiva: '',
      electivas: [],
      habilitarelectiva: false
    })
  }

  validarCampos = () => {
    let { asignatura, electiva, habilitarelectiva } = this.state
    let { actualizarMensaje } = this.props

    let titulo = ''
    if (habilitarelectiva && !electiva) titulo = 'Seleccione Electiva'
    else if (!asignatura) titulo = 'Seleccione Asignatura'

    if (titulo) {
      actualizarMensaje({ titulo, tipo: 'info', mostrar: true, tiempo: 6000 })
      return false
    }
    return true
  }

  submitAtencion = async () => {
    let {
      observaciones,
      tipo_incidente_id,
      estado_estudiante_id,
      tipo_atencion_id,
      plataforma_id,
      programa_estudiante_id,
      facultad_atencion_id,
    } = this.state
    let periodo = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Aten' }])
    let { guardarAtencion } = this.props
    this.setState({ cargando: true, titulo_carg: 'Enviando...' })
    console.log(programa_estudiante_id)
    let programa = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': 1 }, { 'llave': 'id', 'valor': programa_estudiante_id }])
    let datos = {
      tipo_atencion: tipo_atencion_id ?? '',
      tipo_incidente: tipo_incidente_id ?? '',
      observaciones: observaciones ?? '',
      periodo: periodo[0].nombre,
      facultad: facultad_atencion_id,
      programa: programa_estudiante_id,
      estado_estudiante: estado_estudiante_id,
      plataforma: plataforma_id
    }

    guardarAtencion(datos, () => this.reiniciarEstado())
  }


  configStepContent = () => {
    let {
      estado_estudiante_id,
      estado_estudiante,
      tipo_incidente_id,
      tipo_incidente,
      tipo_atencion,
      tipo_atencion_id,
      plataforma,
      plataforma_id,
      programa,
      programa_estudiante_id,
      facultad_atencion,
      facultad_atencion_id,
    } = this.state

    return (
      <AtencionFormCrear
        {...{
          estado_estudiante_id,
          estado_estudiante,
          tipo_incidente_id,
          tipo_incidente,
          tipo_atencion,
          tipo_atencion_id,
          plataforma,
          plataforma_id,
          onChangeProgramas: this.onChangeProgramas,
          onChangeIncidente: this.onChangeIncidente,
          onChangeTipoAtencion: this.onChangeTipoAtencion,
          onChangeEstadoEstudiante: this.onChangeEstadoEstudiante,
          onChangeFacultadAtencion: this.onChangeFacultadAtencion,
          onChangeProgramaEstudiante: this.onChangeProgramaEstudiante,
          onChangePlataforma: this.onChangePlataforma,
          onChange: this.onChange,
          programa,
          programa_estudiante_id,
          facultad_atencion,
          facultad_atencion_id,
          setearEstado: (estado) => this.setState(estado)
        }}
      />
    )
  }

  render() {
    let { modalAddAtencion, mostrarModalAddAtencion } = this.props

    return (
      <>
        <Dialog fullWidth={true} maxWidth="sm" open={modalAddAtencion} onClose={() => mostrarModalAddAtencion(false)}>
          <AppBarModal
            titulo={"¡ Nueva Solicitud !"}
            mostrarModal={() => mostrarModalAddAtencion(false)}
            titulo_accion="Cerrar"
            accion={() => mostrarModalAddAtencion(false)}
          />
          <DialogContent className='scroll'>
            <Grid container>
              <Grid item xs={12}>
                {this.configStepContent()}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <div>
              <BtnForm texto="GUARDAR" callback={this.submitAtencion} type="submit" />
            </div>
          </DialogActions>
        </Dialog >
      </>
    )
  }
}

AtencionCrear.propTypes = {
  modalAddAtencion: PropTypes.bool.isRequired,
  actualizarMensaje: PropTypes.func.isRequired,
}

export default AtencionCrear