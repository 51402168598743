import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import { Grid, Paper, Button, Typography } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

// FUNCIONES GLOBALES
import {
  consulta,
  mostrarError,
  formulario,
  obtenerValores,
  obtenerValoresFiltros,
  api,
  crear_form_data
} from "../../global/js/funciones";
import TituloAccion from "../general/TituloAccion"
import { BtnDetalle, BtnVer } from '../general/BotonesAccion'
import ListarDatos from '../general/ListarDatos'
import AccionesRapidas from '../general/AccionesRapidas'
import BarraProceso from "../general/BarraProceso"
import moment from 'moment'
import { coloresEmma } from '../../global/js/funciones'

//FUNCIONES DEL MODULO
import {
  mostrarModalAddAtencion,
  mostrarModalDetalleAtencion,
  modalAdjuntosValidacion,
  mostrarModalAddAdjValidacion,
  mostrarModalAdjuntos,
  mostrarAdjuntarVolante
} from "../../redux/actions/actAtencion";
import { mostrarModulo, actualizarMensaje } from "../../redux/actions/actGlobal"
import { MenuEstudiantes } from "./atencion/MenuAtencion"
import { Atencion } from "./atencion/AtencionDetalle"
import AtencionCrear from "./atencion/AtencionCrear"
import SolicitudesActivas from "./atencion/AtencionActivas"
import AtencionDetalle from "./atencion/Atencion"
import TareasTerminadas from '../general/TareasTerminadas'
import TiposAtencion from "./atencion/TiposAtencion"

// SERVICES
import { atencionApi, obtenerEstadosValidaciones } from './services/AtencionAPI'

// IMAGENES
import notification from '../../global/imagenes/notification.png'
import revisar from '../../global/imagenes/revisar.png';
import emma_s from '../../global/imagenes/emma_s1.png';
import emma_w from '../../global/imagenes/emma_w.png';
import solicitudImg from '../../global/imagenes/solicitud.png'

// HELPERS
import { TIPOS_DE_CASOS } from "./Helpers"

class AtencionEstudiantes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      seleccion: 'miproceso',
      tiposDeCasos: [],
      asignaturas: [],
      procesos: [],
      lineaEstados: [],
      cargando: true,
      id_estudiante: 0,
      historial: [],
      procesoActual: null,
      notificaciones: [],
      anchorEl: null,
      respuesta: null
    }
  }

  async componentDidMount() {
    let { mostrarModulo } = this.props;
    mostrarModulo({ rutas: 'AtencionEstudiantes', nombre: 'Reporta tu caso y consulta.' })
    this.obtenerDatosIniciales()
    this.obtenerTiposDeCasos()
  }

  async componentDidUpdate(event, { seleccion, validacionActual }) {
    let { seleccion: seleccionNew, validacionActual: validacionActualNew } = this.state
    if (validacionActual !== validacionActualNew && seleccionNew === 'miproceso' && validacionActualNew !== 0) this.obtenerDataProcesoActual(validacionActualNew)
    if (seleccion !== seleccionNew) {
      if (seleccionNew === 'miproceso') this.obtenerDatosIniciales()
      else if (seleccionNew === 'historial') await this.obtenerHistorial()
    }
  }


  vista = () => {
    let { seleccion, enListado } = this.state

    const tipoSeleccion = {
      'miproceso': enListado ? this.pintarSolicitudes() : this.pintarSolicitudSeleccionada(),
      'nuevo': this.pintarNuevo(),
      'historial': this.pintarHistorial()
    }
    
    return tipoSeleccion[seleccion]
  }



  pintarSolicitudes() {
    let { procesos } = this.state;
    if (procesos.length === 0) {
      return (
        <div className="pointer" onClick={() => this.setState({ seleccion: "nuevo" })}>
          <TareasTerminadas
            mensaje="No tienes solicitudes activas, clic aquí para hacer una nueva solicitud"
            marginTop="7%"
            imagen={emma_s}
            widthImg="7%"
          />
        </div>
      )
    }

    const handleClick = (solicitud) => {
      this.setState({ enListado: false, validacionActual: solicitud.id });
    };

    const listSolicitudes = procesos.map((solicitud, i) => {
      return (
        <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
          <Paper elevation={1} onClick={() => handleClick(solicitud)} className="pointer">
            <SolicitudesActivas
              nombre={`Reporte en ${solicitud.tipo_incidente.nombre}`}
              fecha={solicitud.fecha_registro}
              descripcion={`Tu reporte se encuentra en estado ${solicitud.estado_actual.nombre}. Clic aquí para obtener mas información.`}
              estado={solicitud.estado_actual}
              imagen={`${solicitud.tipo_incidente.archivo ?? solicitudImg}`}
            />
          </Paper>
        </Grid>
      )
    })

    return (
      <div style={{ padding: '2% 2% 0% 2%' }}>
        <Grid container direction={'row'} spacing={1}>
          <TituloAccion principal="" alterno="¡ Estas son tus solicitudes de reporte !" />
          {listSolicitudes}
        </Grid>
      </div>
    );
  }

  pintarSolicitudSeleccionada() {
    let { mostrarModalDetalleAtencion } = this.props
    let { procesoActual, respuesta } = this.state;

    const ejecutarAccionDetalle = vistaDetalle => {
      this.setState({ vistaDetalle })
      mostrarModalDetalleAtencion(true);
    }

    if (procesoActual) {
      return (
        <div className='' >
          <Button onClick={() => this.setState({ enListado: true, validacionActual: 0 })} style={{ padding: "0px 3px 0px 3px", marginTop: '1%' }} color="secondary" > <ArrowBackIcon /> <Typography variant="body2" style={{ color: '#9e9e9e' }} component="p" align='justify'> Atras </Typography> </Button>
          <div style={{ padding: '0% 3% 0% 3%' }} >
            <Grid container direction={'row'} spacing={2} >
              <Grid item xs={12} sm={12} md={6} >
                <TituloAccion principal='' alterno='¡ Información Básica !' />
                <Paper elevation={0} square className="scroll">
                  <Atencion atencion={procesoActual} respuesta={respuesta} />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TituloAccion principal='' alterno='¿ Que deseas hacer ?' />
                <Grid container direction={'row'} spacing={2} style={{ width: "100%" }}>
                  <Grid item xs={12} sm={12} md={12} >
                    <Paper elevation={3} style={{ border: '1px solid #e0e0e0' }} onClick={() => ejecutarAccionDetalle('detalle')}>
                      <AccionesRapidas imagen={revisar} titulo='¡ Más Información !' sub_titulo='Bien' des_sub_titulo=', Aquí puedes ver toda la información actual.' />
                    </Paper>
                    {/* <Paper elevation={2} style={{ border: '1px solid #e0e0e0' }} onClick={() => ejecutarAccionDetalle('detalle')}>
                      <AccionesRapidas imagen={revisar} titulo='¡ Califique su atención !'/>
                    </Paper> */}
                  </Grid>
                </Grid>
              </Grid>
              {
                procesoActual.estado_actual.codigo !== 'Aten_Neg' &&
                <Grid item xs={12} sm={12} md={12} >
                  <TituloAccion principal='' alterno='¿ Como va mi proceso ?' />
                  {this.lineaDeEstados()}
                </Grid>
              }
            </Grid>
          </div >
        </div>
      )
    }
  }

  async obtenerDatosIniciales() {
    this.setState({ cargando: true, enListado: true, })
    let periodo = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Val' }]);
    let procesos = await atencionApi.solicitudes()
    await this.obtenerNotificacines()
    this.setState({ periodo: periodo.length > 0 ? periodo[0].nombre : '', procesos, cargando: false })
  }

  obtenerNotificacines = () => {
    consulta(`api/v1.0/validaciones/notificaciones`, null, null,
      (error, estado, resp) => {
        if (estado === 200) {
          this.notificacionRequistosPendientes(resp);
        }
      }
    )
  }

  async notificacionRequistosPendientes(validacionesAprobadas) {
    let notificaciones = []
    // let {mostrarAdjuntarVolante} = this.props
    const accionesSolicitudes = (data) => {
      const onClickAbrir = ({ id }) => {
        this.setState({ enListado: false, validacionActual: id, anchorEl: null });
      }
      return <BtnVer callback={() => onClickAbrir(data)} texto='Ver' />;
    }

    validacionesAprobadas.forEach((data) => {
      let notificacion = {
        icono: notification,
        titulo: `¡ Documento Pendiente !`,
        mensaje: `Ya puedes subir el volante cancelado `,
        tipo: 'mensaje',
        acciones: () => accionesSolicitudes(data),
      }
      notificaciones.push(notificacion)
    })

    this.setState({ notificaciones });
  }



  async obtenerDataProcesoActual(id = 0) {
    this.setState({ cargando: true })
    let data = await atencionApi.solicitudes(id)
    if (data) {
      if (data.atencion.estado_actual.codigo === 'STATE_FINALIZADO_ATENCION') {
        const { id } = data.atencion
        obtenerEstadosValidaciones(id).then(res => {
          const resData = res.filter(data => data.tipo_estado.codigo === 'STATE_FINALIZADO_ATENCION')[0]
          this.setState({ respuesta: resData.mensaje })
        })
      }
      this.setState({ lineaEstados: data.linea, procesoActual: data.atencion, id_estudiante: data.atencion.usuario_registro.id, })
    }
    this.setState({ cargando: false })
  }

  async obtenerHistorial() {
    this.setState({ cargando: true })
    let historial = await atencionApi.solicitudes()
    this.setState({ historial, cargando: false })
  }

  cambiarOpcion = (seleccion) => {
    this.setState({ seleccion })
  }

  guardarAtencion = async (datos, callback) => {
    let { actualizarMensaje, mostrarModalAddAtencion } = this.props
    let data = await crear_form_data(datos);
    formulario(`api/v1.0/atencion/crear`, data, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          mostrarModalAddAtencion(false);
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 9000 });
      callback();
    })
  }

  obtenerTiposDeCasos = async () => {
    this.setState({ cargando: true });
    const tiposDeCasos = await obtenerValores(TIPOS_DE_CASOS)
    tiposDeCasos.sort((a, b) => a.id - b.id)
    this.setState({
      tiposDeCasos: tiposDeCasos,
      cargando: false,
    });
  }

  pintarNuevo = () => {
    let { mostrarModalAddAtencion } = this.props
    let { tiposDeCasos } = this.state
    return (
      <Grid container direction={'row'} justifyContent={'center'} style={{ paddingTop: 40 }}>
        {tiposDeCasos.map((data, i) => <TiposAtencion modal={mostrarModalAddAtencion} datos={data} api={api} key={i} />)}
      </Grid>
    )
  }

  pintarHistorial = () => {
    let { historial } = this.state;
    let { mostrarModalDetalleAtencion } = this.props;

    const obtenerColor = (codigo) => {
      let color = 'white';
      const colores = {
        'STATE_CREATE_ATENCION': { 'backgroundColor': coloresEmma.filtrotodos, color  },
        'STATE_ASIGNADO_ATENCION': { 'backgroundColor': coloresEmma.solicitudactiva, color },
        'STATE_GESTION_ATENCION': { 'backgroundColor': coloresEmma.solicitudenrevision, color },
        'STATE_FINALIZADO_ATENCION': { 'backgroundColor': coloresEmma.solicitudfinalizada, color },
      }
      return (colores[codigo])
    }

    const acciones = (data) => {
      const onClickDetalle = ({ id, usuario_registro }) => {
        this.setState({ validacionActual: id, id_estudiante: usuario_registro.id })
        mostrarModalDetalleAtencion(true)
      }
      let detalle = <BtnDetalle callback={() => onClickDetalle(data)} color='#01579b' texto='Abrir' />;
      return detalle;
    }

    return (
      <div>
        <ListarDatos
          id='lista_datos'
          titulo='Lista de Atenciones'
          datos={historial}
          buscar={true}
          opciones={true}
          sub_titulo="Lista de adjuntos"
          avatar={({ estado_actual }) => estado_actual.nombre.charAt(0).toUpperCase()}
          color_avatar={({ estado_actual: { codigo } }) => obtenerColor(codigo)}
          acciones={(row) => acciones(row)}
          filas={[
            { 'id': 'tipo_estado', 'enLista': true, 'mostrar': ({ estado_actual }) => estado_actual.nombre.toUpperCase() },
            { 'id': 'fecha_registro', 'enLista': true, 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a'), nombre: 'Fecha Registro: ' }
          ]}
        />
      </div>
    )
  }

  lineaDeEstados = () => {
    let { cargando, lineaEstados, procesoActual } = this.state;
    if (!cargando && procesoActual && lineaEstados.length > 0) {
      let activeStep = 0;
      let steps = [];
      lineaEstados.forEach(({ secundario }, i) => {
        if (secundario.id === procesoActual.estado_actual.id) activeStep = i;
        steps.push({ label: secundario.nombre })
      })
      activeStep = procesoActual.estado_actual.codigo === 'Val_Not' ? lineaEstados.length + 1 : activeStep;
      return <BarraProceso steps={steps} activeStep={activeStep} />;
    }
    return null;
  }

  render() {
    let {
      modalAddAtencion,
      mostrarModalAddAtencion,
      modalDetValidaciones,
      mostrarModalDetalleAtencion,
      actualizarMensaje
    } = this.props
    
    let { seleccion, cargando, asignaturas, mensaje, id_estudiante, validacionActual, notificaciones, anchorEl } = this.state
    return (
      <div>
        <MenuEstudiantes notificaciones={notificaciones} anchorEl={anchorEl} setanchorEl={(valor) => this.setState({'anchorEl': valor})} sele={seleccion} seleccionar={(seleccion) => this.cambiarOpcion(seleccion)} />
        <div className="contenido_modulo">
          <AtencionCrear
            guardarAtencion={this.guardarAtencion}
            actualizarMensaje={actualizarMensaje}
            mostrarModalAddAtencion={mostrarModalAddAtencion}
            modalAddAtencion={modalAddAtencion}
            asignaturas={asignaturas}
            mensaje={mensaje}
          />
          <AtencionDetalle
            id_estudiante={id_estudiante}
            mostrarModalDetalleAtencion={mostrarModalDetalleAtencion}
            modalDetValidaciones={modalDetValidaciones}
            vistaDetalle='detalle'
            admin={false}
            validacionActual={validacionActual}
          />
          {
            !cargando ? this.vista() : <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
          }
        </div>
      </div>
    );
  }

}

const mapStateToProps = (state) => {
  let { modalAddAtencion, modalDetValidaciones, modalAdjuntosValidacion, modalAddAdjValidacion, modalAdjuntarVolante } = state.redAtencion
  return {
    modalAddAtencion,
    modalDetValidaciones,
    modalAdjuntosValidacion,
    modalAddAdjValidacion,
    modalAdjuntarVolante
  }
}

const mapDispatchToProps = {
  mostrarModalAddAtencion,
  mostrarModulo,
  actualizarMensaje,
  mostrarModalDetalleAtencion,
  modalAdjuntosValidacion,
  mostrarModalAddAdjValidacion,
  mostrarModalAdjuntos,
  mostrarAdjuntarVolante
}

AtencionEstudiantes.propTypes = {
  // Funciones
  actualizarMensaje: PropTypes.func.isRequired,
  mostrarModulo: PropTypes.func.isRequired,
  mostrarModalAddAtencion: PropTypes.func.isRequired,
  mostrarModalDetalleAtencion: PropTypes.func.isRequired,
  mostrarModalAdjuntos: PropTypes.func.isRequired,
  mostrarModalAddAdjValidacion: PropTypes.func.isRequired,
  mostrarAdjuntarVolante: PropTypes.func.isRequired,

  // Variables
  modalAdjuntosValidacion: PropTypes.bool.isRequired,
  modalAddAtencion: PropTypes.bool.isRequired,
  modalDetValidaciones: PropTypes.bool.isRequired,
  modalAddAdjValidacion: PropTypes.bool.isRequired,
  modalAdjuntarVolante: PropTypes.bool.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(AtencionEstudiantes)