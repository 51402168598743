import React from 'react'
import ListarDatos from '../general/ListarDatos';
import { Popover } from '@material-ui/core';


export default function PracticasNotificaciones({ render, horizontal = 'right', cargando = false, notificaciones }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      {render(handleClick)}
      <Popover
        id={id}
        open={open}
        className='notificaciones_estudiantes'
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ListarDatos
          datos={notificaciones}
          id='tbl_solicitudes_limite'
          titulo={`${notificaciones.length} Notificacion(es)`}
          cargando={cargando}
          opciones={true}
          acciones={(row) => row.acciones()}
          avatarImg={true}
          avatar={({ icono }) => icono}
          fila_principal={({ titulo }) => titulo}
          filas={[
            { 'id': 'mensaje' },
          ]}
        />
      </Popover>
    </>
  );
}

