import React, { useState, useEffect, Component } from 'react';
// Material-UI
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Dialog,
    Grid,
    FormControl,
    Input,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    FormHelperText,
    Checkbox,
    Typography
} from '@material-ui/core/';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import 'date-fns';
// Components
import AppBarModal from '../../general/AppBarModal';
import {
    formulario,
    obtenerValores,
    mostrarError,
    crear_form_data,
    consulta,
    obtenerValoresFiltros
} from '../../../global/js/funciones';
import { PasosCrearSolicitud } from './MatriculasMenu';
import {
    DIPLOMADOS,
    FECHASGRADO,
    TIPO_IDENTIFICACION,
    TIPOS_ESTUDIO,
    ASPIRANTE,
    OPCION_GRADO2,
    ESTRATOS,
    SEXO,
    EGRESADO,
    TRABAJA,
    ACTIVIDAD_LABORAL,
    RANGO_SALARIAL,
    ACEPTAR_SOLICITUD,
    MODALIDAD_VIRTUAL,
} from './helper';
import AlertasSimple from '../../general/AlertasSimple';
import TareasTerminadas from '../../general/TareasTerminadas';
import emma_w from '../../../global/imagenes/emma_w.png';

import CustomDropzone from '../../general/CustomDropzone';
import { render } from 'react-dom';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { es } from 'date-fns/locale'
import moment from "moment";


const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1)
    }
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        width: '100%',
        marginTop: 15,
        '& > *': {
            margin: theme.spacing(0.5)
        }
    },
    alert: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2)
        }
    },
    list: {
        marginTop: 20,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300
    },
    backButton: {
        marginRight: theme.spacing(1)
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    inline: {
        display: 'inline'
    }
}));

export default class ModalCrearSolicitud extends Component {

    constructor(props) {
        super(props);
        this.formEndRef = React.createRef();
        this.state = {
            steps: ['Datos personales', 'Programa', 'Documentos'],
            activeStep: 0,
            tiposIdentificacion: [],
            tiposEstudio: [],
            archivos: [],
            cargando: false,
            programasAcad: [],
            tipo_modalidad:[],
            modalidad_virtual:[],
            Mensaje_Progra_Virtual:'',
            mensaje:'',
            Link_Prog_Academico:'',
            link:'',
            isChecked1: false,
            isChecked2: false,
            cargando_text: 'Cargando'
        };
    }

    async componentDidUpdate({ open }, {  }) {
        const { misDatos, open: openNew } = this.props
        if (open !== openNew) {
          if (openNew) {
            this.setState({cargando: true, cargando_text: 'Cargando ..'})
            obtenerValores(TIPO_IDENTIFICACION).then(tiposIdentificacion => this.setState({ tiposIdentificacion }));
            obtenerValores(TIPOS_ESTUDIO).then(tiposEstudio => this.setState({ tiposEstudio }));
            obtenerValores(5).then(tipo_modalidad => this.setState({ tipo_modalidad }));
            obtenerValoresFiltros([{ llave: "codigo", valor: "Mens_Prog_Virt" }]).then(data=> this.setState({ Mensaje_Progra_Virtual:data[0].descripcion,Link_Prog_Academico: data[0].valora}));
            
            if (misDatos && misDatos.persona) {
                this.setState({
                    primer_nombre: `${misDatos.persona.primer_nombre}`,
                    segundo_nombre: `${misDatos.persona.segundo_nombre}`,
                    primer_apellido: `${misDatos.persona.primer_apellido}`,
                    segundo_apellido: `${misDatos.persona.segundo_apellido}`,
                    numero_documento: misDatos.persona.identificacion,
                    celular: misDatos.persona.celular,
                    telefono: misDatos.persona.telefono,
                    correo: misDatos.persona.correo_personal,
                    tipoIdentificacion: misDatos.persona.tipo_identificacion,
                    ciudad_expedicion: misDatos.persona.ciudad_expedicion,
                    cargando: false
                })
            }
          }
        }
      }

    handleNext = () => {
        const { steps, activeStep } = this.state
        const { crear_solicitud } = this

        const {
            validar_datos_persoanles,
            validar_programa,
            validar_adjunto
        } = this

        if (activeStep == 0 && validar_datos_persoanles()) return;
        if (activeStep == 1 && validar_programa()) return;
        if (activeStep == 2 && validar_adjunto()) return;

        if (activeStep == steps.length - 1) {
            crear_solicitud();
        } else {
            this.setState({ activeStep: activeStep + 1 })
        }
    };

    setFechaNacimiento = (fecha_nacimiento) => {
        this.setState({
            fecha_nacimiento
        })
    }

    handleBack = () => {
        const { activeStep } = this.state
        this.setState({ activeStep: activeStep - 1 });
    };

    validar_estado_no_null = (nombre_estados) => {
        for (let index = 0; index < nombre_estados.length; index++) {
            const element = nombre_estados[index];
            if (
                this.state[element] == '' ||
                this.state[element] == undefined ||
                this.state[element] == null
            ) return true;
        }
        return false
    }

    onChangeStateForInput = async (event)  => {
        const {  target: { value, name } } = event

        this.setState({
            [name]: value
        })
        // this.formEndRef.current.scrollIntoView({ behavior: 'smooth' });
        if (name == 'tipo_estudio'){
            let {tiposEstudio} = this.state;
            const { misDatos } = this.props
            let resp = tiposEstudio.filter((d) => d.id == value )[0]
            this.setState({programasAcad: resp.codigo == "Mat_Tran_Int" || resp.codigo == "Mat_Tran_Ext" || resp.codigo == "Mat_Dob_Tit" ? await ListarProgramaPregrado() : misDatos.programas }) 
            
        }
        if(name=='programa'){
            let {tipo_modalidad,Mensaje_Progra_Virtual,Link_Prog_Academico} = this.state;
            let modalidad = tipo_modalidad.filter((d) => d.id == value )[0] 
            if(modalidad){
                this.setState({isChecked1:false,isChecked2:false})    
            }
            this.setState({modalidad_virtual:modalidad,mensaje:Mensaje_Progra_Virtual,link:Link_Prog_Academico})
        }

        async function ListarProgramaPregrado() {
            return new Promise((resolve) => {
                consulta(`api/v1.0/matriculas/ListarProgramaPregrado`, null, null, (error, estado, resp) => {
                resolve(estado === 200 && !error ? resp : null);
                });
            });
        }
        
        
    }
    handleCheckboxChange = async (event) => {
        const { name, checked } = event.target;
        this.setState({ [name]: checked });
        // this.setState({ isChecked: checked });
        if(name=='checked1') {
            this.setState({isChecked1:checked,isChecked2:false})
            // this.formEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }else if (name=='checked2') {
            this.setState({isChecked2:checked,isChecked1:false})
        }else{
            this.setState({isChecked1:false, isChecked2:false})
        }
    }

    DatosPersonales = () => {

        const {
            primer_nombre,
            segundo_nombre,
            primer_apellido,
            segundo_apellido,

            tiposIdentificacion,
            tipoIdentificacion,
            numero_documento,

            fecha_nacimiento = null,
            ciudad_nacimiento,
            ciudad_residencia,
            direccion_residencia,
            celular,
            telefono,
            correo,

            autorizo
        } = this.state

        const { onChangeStateForInput } = this

        return (
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <FormControl className='form-control'>
                        <InputLabel>Primer Nombre</InputLabel>
                        <Input
                            name='primer_nombre'
                            defaultValue={primer_nombre}
                            onChange={onChangeStateForInput}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl className='form-control'>
                        <InputLabel>Segundo Nombre</InputLabel>
                        <Input
                            name='segundo_nombre'
                            defaultValue={segundo_nombre}
                            onChange={onChangeStateForInput}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl className='form-control'>
                        <InputLabel>Primer Apellido</InputLabel>
                        <Input
                            name='primer_apellido'
                            defaultValue={primer_apellido}
                            onChange={onChangeStateForInput}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl className='form-control'>
                        <InputLabel>Segundo Apellido</InputLabel>
                        <Input
                            name='segundo_apellido'
                            defaultValue={segundo_apellido}
                            onChange={onChangeStateForInput}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Tipo de documento</InputLabel>
                        <Select
                            name='tipoIdentificacion'
                            value={tipoIdentificacion}
                            onChange={onChangeStateForInput}
                        >
                            {
                                tiposIdentificacion.map((ti) => (
                                    <MenuItem key={ti.id} value={ti.id}> {ti.nombre} </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Número de Documento de Identidad</InputLabel>
                        <Input
                            name='numero_documento'
                            type="number"
                            defaultValue={numero_documento}
                            onChange={onChangeStateForInput}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils} >
                            <Grid container>
                                <KeyboardDatePicker
                                    required={true}
                                    style={{ marginTop: 3, width: '100%' }}
                                    label="Fecha de Nacimiento"
                                    margin="normal"
                                    maxDate={Date("YYYY/MM/dd")}
                                    format="yyyy/MM/dd"
                                    value={fecha_nacimiento}
                                    onChange={this.setFechaNacimiento}
                                    KeyboardButtonProps={{
                                        'aria-label': 'cambiar fecha',
                                    }}
                                    invalidDateMessage='Formato Invalido'
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Ciudad de Nacimiento</InputLabel>
                        <Input
                            name='ciudad_nacimiento'
                            defaultValue={ciudad_nacimiento}
                            onChange={onChangeStateForInput}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Ciudad de residencia</InputLabel>
                        <Input
                            name='ciudad_residencia'
                            defaultValue={ciudad_residencia}
                            onChange={onChangeStateForInput}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Dirección de residencia</InputLabel>
                        <Input
                            name='direccion_residencia'
                            defaultValue={direccion_residencia}
                            onChange={onChangeStateForInput}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Celular</InputLabel>
                        <Input
                            name='celular'
                            defaultValue={celular}
                            onChange={onChangeStateForInput}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Teléfono</InputLabel>
                        <Input
                            name='telefono'
                            defaultValue={telefono}
                            onChange={onChangeStateForInput}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl className='form-control'>
                        <InputLabel>Correo electrónico</InputLabel>
                        <Input
                            name='correo'
                            defaultValue={correo}
                            onChange={onChangeStateForInput}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl className='form-control'>
                        <InputLabel>Autorizo</InputLabel>
                        <Select
                            name='autorizo'
                            value={autorizo}
                            onChange={onChangeStateForInput}
                        >
                            <MenuItem key={1} value={1}> SI </MenuItem>
                            <MenuItem key={2} value={2}> NO </MenuItem>
                        </Select>
                        <FormHelperText>
                            Autorizo de manera expresa e inequívoca que mis datos personales
                            sean usados por la Corporación Universidad de la Costa “CUC”, dentro
                            de los términos previsto por la ley 1581 de 2012 y el decreto 1377
                            de 2013; para efectos que la Universidad considere pertinentes.
                        </FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
        );

    }

    validar_datos_persoanles = () => {
        const { validar_estado_no_null } = this
        const { actualizarMensaje } = this.props
        const array_name_state = [
            'primer_nombre',
            'primer_apellido',
            'tipoIdentificacion',
            'numero_documento',
            'fecha_nacimiento',
            'ciudad_nacimiento',
            'ciudad_residencia',
            'direccion_residencia',
            'celular',
            'telefono',
            'correo',
            'autorizo'
        ]
        if (validar_estado_no_null(array_name_state)) {
            actualizarMensaje({
                titulo: "Campos sin digitar.",
                tipo: "info",
                mostrar: true,
                tiempo: 6000
            });
            return true
        }
        return false;
    }

    Programa = () => {
        const {
            programa,
            tipo_estudio,
            tiposEstudio,
            programa_procedencia,
            institucion_procedencia,
            numero_creditos,
            programasAcad,
            modalidad_virtual,
            mensaje,
            isChecked1,
            isChecked2,
            link,
            

        } = this.state

        const { onChangeStateForInput,handleCheckboxChange } = this
        
        const { misDatos } = this.props

        return (
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Tipo de estudio</InputLabel>
                        <Select
                            name='tipo_estudio'
                            value={tipo_estudio}
                            onChange={onChangeStateForInput}
                        >
                            {
                                tiposEstudio.map((te) => (
                                    <MenuItem key={te.id} value={te.id}> {te.nombre} </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Programa</InputLabel>
                        <Select
                            name='programa'
                            value={programa}
                            onChange={onChangeStateForInput}
                        >
                            {
                                (tipo_estudio && (tiposEstudio.filter((d) => d.id == tipo_estudio )[0].codigo == 'Mat_Tran_Int' || tiposEstudio.filter((d) => d.id == tipo_estudio )[0].codigo == 'Mat_Tran_Ext' || tiposEstudio.filter((d) => d.id == tipo_estudio )[0].codigo == 'Mat_Dob_Tit') ) ? programasAcad.map((p) => ( <MenuItem key={p.id} value={p.id}> {p.nombre} </MenuItem> )) : programasAcad.map((p) => ( <MenuItem key={p.relacion.id} value={p.relacion.id}> {p.relacion.nombre} </MenuItem> ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Programa Procedencia</InputLabel>
                        <Input
                            name='programa_procedencia'
                            value={programa_procedencia}
                            onChange={onChangeStateForInput}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Institución Procedencia</InputLabel> 
                        <Input
                            name='institucion_procedencia'
                            value={institucion_procedencia}
                            onChange={onChangeStateForInput}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Numero de Créditos</InputLabel>
                        <Input
                            type='number'
                            name='numero_creditos'
                            value={numero_creditos}
                            onChange={onChangeStateForInput}
                            InputProps={{ inputProps: { min: 0, max: 10 } }}
                        />
                        <FormHelperText>
                            De 1 a 21 creditos
                        </FormHelperText>
                    </FormControl>
                </Grid>
                {(MODALIDAD_VIRTUAL.includes(modalidad_virtual.valorc)) && (modalidad_virtual.codigo != '13030') ?
                <>
                <Grid item xs={12}>
                    <FormControl className='form-control'>
                        <AlertasSimple 
                                tipo='info'
                                titulo={<p style={{ textAlign: 'justify', marginBottom: '-15px',marginTop:'-8px', fontSize:'.9rem'}}> {mensaje}</p>}
                                margin = '0px'
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} >
                     <InputLabel style={{ marginLeft: '5px' }}>Desea continuar con el proceso ?
                        {' '}
                        <span style={{ marginRight: '10px' }}>SI</span>
                        <Checkbox
                        name='checked1'
                        edge="start"
                        onChange={handleCheckboxChange}
                        checked={isChecked1}
                        color="primary"
                        />
                        <span style={{ marginRight: '10px' }}>NO</span>
                        <Checkbox
                        name='checked2'
                        edge="start"
                        onChange={handleCheckboxChange}
                        checked={isChecked2}
                        color="primary"
                        />
                    </InputLabel>
                </Grid> 
                {(isChecked1==  true) ?
                <>
                <Grid item xs={12} >
                    <Typography variant="body1">
                    <p style={{marginLeft:'5px', marginTop: '-2px' }}>Deberá inscribirse en el siguiente enlace <Button target="_blank" href={link} variant="contained" color="secondary" style={{color: '#FFFFFF'}} size="small">click aquí</Button> (Los documentos solicitados de inscripción son: copia del documento de identidad, copia de las pruebas saber 11 y copia del diploma de bachiller) </p>
                    </Typography>
                </Grid>
                {/* <Grid item xs={12}>
                    <Typography variant="body2">
                    (Los documentos solicitados de inscripción son:
                    copia del documento de identidad, copia de las
                    pruebas saber 11 y copia del diploma de bachiller)
                    </Typography>
                 </Grid> */}
                </>
                :''}
                </>
                :''}
            </Grid>

        )
    }

    validar_programa = () => {
        const { validar_estado_no_null } = this
        const { actualizarMensaje } = this.props
        const { numero_creditos } = this.state
        const array_name_state = [
            'programa',
            'tipo_estudio',
            'programa_procedencia',
            'institucion_procedencia',
            'numero_creditos'
        ]
        if (validar_estado_no_null(array_name_state)) {
            actualizarMensaje({
                titulo: "Campos sin digitar.",
                tipo: "info",
                mostrar: true,
                tiempo: 6000
            });
            return true
        }

        if (numero_creditos < 1 || numero_creditos > 21) {
            actualizarMensaje({
                titulo: "Numero de Creditos no es Valido",
                tipo: "info",
                mostrar: true,
                tiempo: 6000
            });
            return true
        }
        return false;
    }

    eliminarArchivo = (i) => {
        const { archivos } = this.state
        const { actualizarMensaje } = this.props
        archivos.splice(i, 1);
        this.setState({ archivos })
        actualizarMensaje({ titulo: `Archivo Retirado.`, tipo: 'success', mostrar: true, tiempo: 6000 });
    };

    agregarArchivos = (arc) => {
        let { archivos } = this.state
        archivos = archivos.concat(arc);
        this.setState({ archivos })
    };

    validar_adjunto = () =>{
        const { archivos, tipo_estudio, tiposEstudio } = this.state
        let found = tiposEstudio.find(element => element.id == tipo_estudio);
        const { codigo } = found;
        const { actualizarMensaje } = this.props
        if( archivos.length <= 0 && (codigo != 'Mat_Rein' && codigo != 'Mat_Tran_Int' && codigo != 'Mat_Dob_Tit')){
            actualizarMensaje({ 
                titulo: "Adjuntar documento.", 
                tipo: "info", 
                mostrar: true, 
                tiempo: 6000 
            });	
            return true
        }
        return false;
    }

    Documentos = () => {
        const { archivos } = this.state
        const {
            agregarArchivos,
            eliminarArchivo
        } = this
        let lista = [
            {
                item: `
            Si su solicitud es transferencia externa adjuntar certificados de notas 
            escaneado (obligatorio), certificado de buena conducta (opcional), si 
            cuenta con los contenidos programáticos enviar al siguiente correo: acharras1@cuc.edu.co
            `
            },
        ]
        return (
            <div>
                <AlertasSimple
                    tipo='info'
                    titulo='Estimado estudiante, subir los siguientes Documentos:'
                    sub_titulo='Si necesitas subir varios archivos puedes comprimirlos en cualquier formato(rar, zip) y adjuntarlos.'
                    lista={lista}
                />
                <CustomDropzone
                    archivos={archivos}
                    addFunction={agregarArchivos}
                    deleteFunction={eliminarArchivo}
                />
            </div>
        )
    }

    getStepContent(step) {
        const {
            DatosPersonales,
            Programa,
            Documentos
        } = this
        switch (step) {
            case 0:
                return <DatosPersonales />
            case 1:
                return <Programa />
            case 2:
                return <Documentos />
            default:
                return 'Unknown step';
        }
    }

    crear_solicitud = async () => {
        let { actualizarMensaje, tipo, setOpen } = this.props

        // Documentos
        let { archivos } = this.state

        // Datos Personales
        const {
            primer_nombre, segundo_nombre, primer_apellido, segundo_apellido,
            tipoIdentificacion, numero_documento, fecha_nacimiento, ciudad_nacimiento,
            ciudad_residencia, direccion_residencia, celular, telefono, correo, autorizo,
            ciudad_expedicion
        } = this.state

        // Programa
        const {
            programa, tipo_estudio, programa_procedencia, institucion_procedencia, numero_creditos
        } = this.state


        let auxData = {
            ciudad_expedicion: ciudad_expedicion ? ciudad_expedicion : '',
            correo,
            celular,
            telefono,
            curso: '',
            tipo: tipo.id,
            tipoCode: tipo.codigo,
            misCursos: [],

            primer_nombre,
            segundo_nombre,
            primer_apellido,
            segundo_apellido,

            numero_documento,
            ciudad_residencia,
            fecha_nacimiento: fecha_nacimiento ? moment(fecha_nacimiento).format("YYYY-MM-DD") : "",

            programa,
            tipoIdentificacion,
            autorizo,

            numero_creditos,
            direccion_residencia,
            ciudad_nacimiento,
            tipo_estudio,
            programa_procedencia,
            institucion_procedencia,
        };



        const data = await crear_form_data(auxData);
        data.append(`total`, archivos.length);
        archivos.map((file, index) => data.append(`archivo${index}`, file));
        this.setState({ cargando: true, cargando_text: 'Enviando, por favor espere' })
        formulario(`api/v1.0/matriculas/crear`, data, 'post', async (error, estado, resp) => {
            let title = 'Ha ocurrido un error, contacte con el administrador.';
            let icon = 'error';
            if (!error) {
                if (estado === 200) {
                    icon = 'success';
                    title = resp.titulo;
                    setOpen(false);
                    this.setState({ activeStep: 0 });
                } else if (estado === 302) {
                    icon = 'info';
                    title = resp.titulo;
                } else {
                    tipo = 'info';
                    title = resp.titulo ? resp.titulo : mostrarError(resp);
                }
            }
            actualizarMensaje({ titulo: title, tipo: icon, mostrar: true, tiempo: 6000 });
            this.setState({ cargando: false })
        });
    }

    render() {
        const { open, setOpen, tipo } = this.props
        const { steps, activeStep, cargando,isChecked2, cargando_text } = this.state
        return (
            <div>
                <Dialog
                    onClose={() => setOpen(false)}
                    aria-labelledby='customized-dialog-title'
                    open={open}
                    fullWidth={true}
                    maxWidth='md'
                    className='scroll'
                >
                    <AppBarModal
                        titulo={`${tipo && tipo.nombre}`}
                        mostrarModal={() => setOpen(false)}
                        titulo_accion='Cerrar'
                        accion={() => setOpen(false)}
                    />
                    <DialogContent>
                        {cargando ? (
                            <TareasTerminadas mensaje={cargando_text} marginTop='7%' imagen={emma_w} widthImg='7%' cargando={true}/>
                        ) : (
                            <div>
                                <PasosCrearSolicitud activeStep={activeStep} steps={steps} />
                                {this.getStepContent(activeStep)}
                            </div>
                        )}
                    </DialogContent>
                    <DialogActions>
                        {isChecked2 != true ? 
                        <Button
                            disabled={activeStep === 0}
                            onClick={cargando ? () => setOpen(false) : this.handleBack}
                        >
                            Atrás
                        </Button>
                        
                        : ''}
                        {!cargando && isChecked2 != true ?
                          (
                            <Button
                                color='primary'
                                onClick={this.handleNext}
                            >
                                {activeStep === steps.length - 1 ? 'Guardar' : 'Siguiente'}
                            </Button>
                        ) :
                        <Button
                            color='primary'
                            onClick={() => setOpen(false)}
                        >   Cerrar
                        </Button>
                        }
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

}