// React
import React from 'react';

// Redux
import { connect } from "react-redux";

// Material-UI components
import { Link, Dialog, DialogActions, DialogContent, Tabs, Tab, FormControl, TextField, Select, InputLabel, MenuItem, Grid } from '@material-ui/core/';
import Procesos from '@material-ui/icons/ImportContacts';
import CreateIcon from '@material-ui/icons/Create';
import SearchIcon from '@material-ui/icons/Search';
// Componentes
import { BtnEditar, BtnSeleccion, BtnCancelar, BtnDetalle, BtnForm, BtnGestionar } from '../../general/BotonesAccion';
import EnviarArchivo from '../../general/EnviarArchivo'
import EnviarMensaje from "../../general/EnviarMensaje";
import ConfirmarAccion from '../../general/ConfirmarAccion';
import AdjuntosListar from './AdjuntosListar'
import TareasTerminadas from "../../general/TareasTerminadas"
import AppBarModal from "../../general/AppBarModal"
import AlertasSimple from "../../general/AlertasSimple"
import ListarDatos from "../../general/ListarDatos"
import BuscarPersona from "../../general/BuscarPersona"
import { AdjuntoDetalle } from "../historial/GradoDetalle"
//imagenes
import emma_w from '../../../global/imagenes/emma_w.png'
// Funciones
import { obtenerAdjuntos } from '../funciones'
import { consulta, mostrarError, api, formulario, crear_form_data, rutaImg, obtenerValoresFiltros, coloresEmma } from "../../../global/js/funciones";

//ACCIONES DEL MODULO
import { actualizarMensaje } from "../../../redux/actions/actGlobal";
import { showModalAdjuntos, showModalNewAdjuntos, showModalEvalComite, showModalNewGestor, setAdjuntosEstado, setDataAdjunto } from '../../../redux/actions/actGrados';
import { mostrarModalAdd, mostrarModalDel, mostrarModalMod, mostrarModalAdmValores } from "../../../redux/actions/actGenericas";
import { mostrarModalCambiarPassword } from '../../../redux/actions/actPersonas';
import EnviarMultiplesArchivos from '../../general/EnviarMultiplesArchivos';


function AdjuntoMenu({ sele = 0, seleccionar = () => { } }) {
    const clase = (item, selec, tipo = '') => item === selec ? `item_selec${tipo}` : `item_modulo`;
    const pintar = (claseN = '') => {
        return (
            <div className={claseN}>
                <Tabs
                    value={sele}
                    variant="fullWidth"
                    scrollButtons="off"
                >
                    <Tab label={'DETALLE'} icon={< Procesos className='menu_icono' />} className={clase('detalle', sele)} value='detalle' onClick={() => seleccionar('detalle')} />
                    <Tab label={'GESTIÓN'} icon={<CreateIcon className='menu_icono' />} className={clase('gestion', sele)} value='gestion' onClick={() => seleccionar('gestion')} />
                </Tabs>
            </div>
        )
    }

    return pintar('menu_modal');
}

class Adjuntos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accion: 'detalle',
            evaluacion: -1,
            mensaje: '',
            modalBuscarTutor: false,
            tipo_tutor: 'tutor',
            tutor: { id: "", nombre_completo: 'Clic aquí para buscar un tutor', },
            cotutor: { id: "", nombre_completo: 'Clic aquí para buscar un cotutor', },
            tutores: [],
            cargando: true,
            cargando_envio: false,
            cargar: true,
            rubrica: [],
            archivos: []
        }
    }

    componentDidMount() {
        this.getAllAdjuntos();
    }

    async componentDidUpdate({ }, { evaluacion }) {
		let { evaluacion: evaluacionNew, cargar } = this.state;
		let { solicitud } = this.props;
		if (evaluacion !== evaluacionNew) {
            if((evaluacionNew == 6 || evaluacionNew == 8)){
                let rubrica = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'req_gr_rubrica_ap' }])
                this.setState({cargar: false, rubrica: [{ nombre: rubrica[0].nombre, descripcion: rubrica[0].descripcion, id: rubrica[0].id, archivo: rubrica[0].archivo }]})
            }else{
                this.setState({cargar: false, rubrica: []})
            }
		}
	}

    // Acciones Generales

    getAllAdjuntos = async () => {
        let { admin, setAdjuntosEstado, solicitud, getAdjFaltantes } = this.props;
        obtenerAdjuntos(solicitud.id, (resp) => {
            setAdjuntosEstado(resp);
            getAdjFaltantes()
            this.setState({ cargando: false })
        })
    }

    agregarAdjunto = async (archivo) => {
        let { solicitud, estado, setAdjuntosEstado, showModalNewAdjuntos, actualizarMensaje } = this.props;
        let data = await crear_form_data({ archivo });
        formulario(`api/v1.0/grados/${solicitud.id}/estado/${estado.id}/adjuntos/nuevo`, data, "post", (error, estado, resp) => {
            let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
            if (!error) {
                if (estado === 200) {
                    tipo = 'success';
                    titulo = resp.titulo;
                    this.getAllAdjuntos()
                } else {
                    tipo = 'info';
                    titulo = resp.titulo ? resp.titulo : mostrarError(resp);
                }
                showModalNewAdjuntos(false);
            }
            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
        })
    }

    remplazarAdjunto = async (adjunto, archivo) => {
        let { solicitud, setAdjuntosEstado, actualizarMensaje, mostrarModalMod } = this.props;
        let { id } = adjunto;
        let data = await crear_form_data({ archivo });
        this.setState({ cargando_envio: true })
        formulario(`api/v1.0/grados/adjunto/${id}/remplazar`, data, "post", (error, estado, resp) => {
            let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
            if (!error) {
                if (estado === 200) {
                    tipo = 'success';
                    titulo = resp.titulo;
                    this.getAllAdjuntos()
                } else {
                    tipo = 'info';
                    titulo = resp.titulo ? resp.titulo : mostrarError(resp);
                }
                mostrarModalMod(false);
            }
            this.setState({ cargando_envio: false })
            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
        })
    }

    validarAdjunto = async (adjunto, validacion, nota = '', evaluacion = null, tutor_propuesto2 = '', cotutor_propuesto2 = '', archivos = []) => {
        this.setState({ cargando: true })
        let total = archivos.length
        let data = await crear_form_data({ validacion, nota, evaluacion, tutor_propuesto2, cotutor_propuesto2, total, codigo_adj: this.props.adjunto.requisito.codigo })
        if (total != 0){
            archivos.map(({ archivo }, index) => {
                data.append(`archivo${index}`, archivo);
                data.append(`codigo${index}`, 'req_gr_rubrica_ap');
            })
        }
        let { mostrarModalAdd, mostrarModalDel, actualizarMensaje, showModalEvalComite } = this.props
        formulario(`api/v1.0/grados/adjunto/${adjunto}/validar`, data, "put",
            (error, estado, resp) => {
                let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
                if (!error) {
                    if (estado === 200) {
                        tipo = 'success';
                        titulo = resp.titulo;
                        this.getAllAdjuntos()
                        showModalEvalComite(false);
                        this.setState({ cargando: false })
                    } else {
                        tipo = 'info';
                        titulo = resp.titulo ? resp.titulo : mostrarError(resp);
                    }
                    mostrarModalAdd(false);
                    mostrarModalDel(false);
                }
                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
            })
    }

    // Handlers

    onChange = ({ target }) => {
        let { value, name } = target;
        this.setState({
            [name]: value
        });
    }

    onClickbuscarTutorCotutor = (tipo_tutor) => {
        this.setState({ tipo_tutor })
        // this.props.showModalNewGestor(true)
        this.setState({ modalBuscarTutor: true })
    }

    onClickSeleccionarTutor = (data) => {
        let { tipo_tutor } = this.state
        let { id, primer_nombre, primer_apellido, segundo_apellido } = data
        let nombre_completo = `${primer_nombre} ${primer_apellido} ${segundo_apellido}`
        if (tipo_tutor == "tutor") this.setState({ tutor: { id, nombre_completo }, modalBuscarTutor: false })
        if (tipo_tutor == "cotutor") this.setState({ cotutor: { id, nombre_completo }, modalBuscarTutor: false })
    }

     onclickEnviarEvalComite = (adjunto, validacion, evaluacion, mensaje, tutor, cotutor, archivos) => {
        let { actualizarMensaje } = this.props
        let nombre_tutor = tutor.id > 0 ? tutor.nombre_completo : ""
        let nombre_cotutor = cotutor.id > 0 ? cotutor.nombre_completo : ""
        if ((validacion == 6 || validacion == 8) && archivos.length == 0){
            actualizarMensaje({ titulo: 'Debe adjuntar el Documento', tipo: 'info', mostrar: true, tiempo: 6000 });
            return false
        }
        this.validarAdjunto(adjunto, validacion, mensaje, evaluacion, nombre_tutor, nombre_cotutor, archivos)
    }

    configurarAcciones = (adjunto) => {
        let { admin, usuario, solicitud, estado, setDataAdjunto, mostrarModalMod, mostrarModalDel, mostrarModalAdd, mostrarModalAdmValores, showModalEvalComite, accionesActivar } = this.props
        let { validacion, requisito } = adjunto

        const eventoDetalle = () => {
            setDataAdjunto(adjunto);
            mostrarModalAdmValores(true)
        }

        const eventoGestionar = () => {
            setDataAdjunto(adjunto)
            showModalEvalComite(true)
        }

        const eventoAprobar = () => {
            setDataAdjunto(adjunto);
            mostrarModalAdd(true);
        }

        const eventoNegar = () => {
            setDataAdjunto(adjunto);
            mostrarModalDel(true);
        }

        const eventoEditar = () => {
            setDataAdjunto(adjunto)
            mostrarModalMod(true)
        }

        let { codigo, valora } = estado //Codigo del estado seleccionado de la solicitudy
        let { codigo: codigo_s } = solicitud.estado_actual //Codigo del estado Actual de la solicitud
        // href={`${api}/media/${archivo}`}

        // let ver = <Link href={`${archivo}`} rel="noopener noreferrer" target="_blank"><BtnDetalle texto="Descargar Documento" callback={() => { }} /></Link>
        let ver = <BtnDetalle callback={() => eventoDetalle()} />;
        let editar = validacion === '1' || validacion === '6'  || validacion === '8'|| validacion === '9' ? <BtnEditar texto='Remplazar' callback={() => eventoEditar()} /> : ""
        let gestion = ['4', '5'].includes(validacion) ? <BtnGestionar texto='Gestionar' callback={() => eventoGestionar()} /> : ""
        let aprobar = ['0', '7'].includes(validacion) ? <BtnSeleccion texto='Aprobar' callback={() => eventoAprobar()} /> : '';
        let negar = ['0'].includes(validacion) ? <BtnCancelar texto='Negar' callback={() => eventoNegar()} /> : '';
        let botones = codigo !== 'gr_est_fin' ? <div>{ver} {editar}</div> : <div>{ver}</div>
        console.log(validacion)
        if (admin) {
            if (requisito && requisito.codigo == "req_gr_anteproye") {
              if (codigo_s == "gr_est_ini") {
                if (validacion != '7') {
                  if (usuario.perfil.codigo == "Per_Adm_Gra3" && (validacion=='4' || validacion=='5')) {
                    console.log("entre1")
                    botones = <div>{ver} {gestion}</div>;
                  } else {

                    botones = <div>{ver} {aprobar} {negar}</div>;
                  }
                  if (usuario.perfil.codigo == "Per_Adm") {

                    botones = <div>{ver} {gestion} {aprobar} {negar}</div>;
                  }
              } else {
              botones = <div>{ver} {aprobar} {negar}</div>;
            }
            if(validacion=='6'||validacion=='8'){
              botones = <div>{ver} {editar}</div>;

            }
              }
            } else {
          
                if (['0', '7'].includes(validacion)) {
                  botones = <div>{ver} {aprobar} {negar}</div>;

                } else {

                  botones =['1'].includes(validacion) ? <div>{ver} {editar}</div> : <div>{ver}</div>

                }
              
            }
          }
          
        if (!accionesActivar) botones = ver
        return botones
    }

    // Modales

    modalDetalleAdjunto = () => {
        let { adjunto, modalAdmValores, mostrarModalAdmValores } = this.props
        let { accion } = this.state
        return (
            <Dialog open={modalAdmValores} maxWidth='sm' fullWidth={true} onClose={() => mostrarModalAdmValores(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <AppBarModal titulo='¡ Detalle Requisito !' mostrarModal={mostrarModalAdmValores} titulo_accion='' />
                <AdjuntoMenu sele={accion} seleccionar={(accion) => this.setState({ accion })} />
                <DialogContent style={{ padding: 0 }} className='scroll' >
                    {accion == "detalle" && <a href={`${adjunto.archivo}`} target="_blank" className='sin_decorador'><TareasTerminadas mensaje={`El detalle es un archivo adjunto, puedes dar clic aquí para descargarlo.`} marginTop='7%' imagen={emma_w} widthImg="7%" /></a>}
                    {accion === 'gestion' && <AdjuntoDetalle adjunto={adjunto} />}
                </DialogContent>
                <DialogActions>
                    <BtnForm texto="Cerrar" callback={() => mostrarModalAdmValores(false)} />
                </DialogActions>
            </Dialog>
        )
    }

    modalNuevoAdjunto = () => {
        const { modalNewAdjuntos, showModalNewAdjuntos } = this.props;
        return (
            <EnviarArchivo enviarArchivo={(archivo) => this.agregarAdjunto(archivo)} modalAdd={modalNewAdjuntos} mostrarModalAdd={showModalNewAdjuntos} titulo='Cargar Archivo' />
        )
    };

    modalRemplazarAdjunto = () => {
        const { adjunto, modalMod, mostrarModalMod } = this.props;
        return (
            <EnviarArchivo enviarArchivo={(archivo) => !this.state.cargando_envio && this.remplazarAdjunto(adjunto, archivo)} modalAdd={modalMod} mostrarModalAdd={mostrarModalMod} titulo='Modificar Archivo' />
        )
    };

    modalAprobarAdjunto = () => {
        const { adjunto, modalAdd, mostrarModalAdd } = this.props;
        return (
            <ConfirmarAccion mensaje1={`El adjunto seleccionado será aprobado, `} ejecutarAccion={() => this.validarAdjunto(adjunto.id, 2)} mostrarModalConfAccion={mostrarModalAdd} modalConAccion={modalAdd} dato={adjunto} titulo={'¿ Aprobar Archivo ?'} />
        )
    }

    modalNegarAdjunto = () => {
        const { adjunto, modalDel, mostrarModalDel } = this.props;
        return (
            <EnviarMensaje modal={modalDel} mostrarModal={mostrarModalDel} titulo='¿ Negar Archivo ?' label='Observaciones' type='text' enviar={(mensaje) => this.validarAdjunto(adjunto.id, 1, mensaje)} />
        )
    }

    modalBuscarTutoresPropuestos = () => {
        const { modalBuscarTutor } = this.state
        return (
            <Dialog onClose={() => this.setState({ modalBuscarTutor: false })} open={modalBuscarTutor} fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600}>
                <AppBarModal titulo={'Buscar Persona'} mostrarModal={() => this.setState({ modalBuscarTutor: false })} />
                <DialogContent style={{ padding: '0' }} className='scroll'>
                    <BuscarPersona callback={(data) => this.onClickSeleccionarTutor(data)} />
                </DialogContent>
                <DialogActions></DialogActions>
            </Dialog>
        )
    }
    // spacing={1} alignItems="flex-end"
    modalGestionarAdjunto = () => {
        let { adjunto, modalEvalComite, showModalEvalComite } = this.props
        let { mensaje, evaluacion, tutor, cotutor, rubrica } = this.state
        return (
            <Dialog open={modalEvalComite} onClose={() => showModalEvalComite(false)} fullWidth={true} fullScreen={window.innerWidth < 600} maxWidth='sm' aria-labelledby="max-width-dialog-title">
                <AppBarModal titulo={'Gestionar Adjunto'} mostrarModal={() => showModalEvalComite(false)} />
                <DialogContent style={{ padding: '1' }} className='scroll'>
                    <Grid container spacing={2}>
                        <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                            <FormControl style={{ width: "100%" }}>
                                <InputLabel id="demo-controlled-open-select-label">Evaluación</InputLabel>
                                <Select value={evaluacion} onChange={(event) => this.setState({ evaluacion: event.target.value })} labelId="demo-controlled-open-select-label" id="demo-controlled-open-select">
                                    {adjunto.validacion == 4 && <MenuItem value={5}>Llevar a comité</MenuItem>}
                                    {adjunto.validacion == 4 && <MenuItem value={6}>No aprobado por el director</MenuItem>}
                                    {adjunto.validacion == 5 && <MenuItem value={7}>Aprobado por comité</MenuItem>}
                                    {adjunto.validacion == 5 && <MenuItem value={8}>No aprobado por comité</MenuItem>}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                            <TextField multiline value={mensaje} onChange={this.onChange} name="mensaje" id="mensaje" label="Ingrese una observación" fullWidth />
                        </Grid>
                        {evaluacion == 7 && <>
                            <Grid container spacing={1} alignItems="flex-end" onClick={() => this.onClickbuscarTutorCotutor("tutor")} className='pointer' style={{ marginBottom: '10px', marginTop: '10px' }}>
                                <Grid item xs={11} xl={11} sm={11} md={11} lg={11}>
                                    <TextField label="Tutor Propuesto" disabled={true} type="text" value={tutor.nombre_completo} style={{ width: '100%' }} />
                                </Grid>
                                <Grid item xs={1} xl={1} sm={1} md={1} lg={1}>
                                    <SearchIcon style={{ color: coloresEmma.serchover }} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} alignItems="flex-end" onClick={() => this.onClickbuscarTutorCotutor("cotutor")} className='pointer' style={{ marginBottom: '5px' }}>
                                <Grid item xs={11} xl={11} sm={11} md={11} lg={11}>
                                    <TextField label="Cotutor Propuesto" disabled={true} type="text" value={cotutor.nombre_completo} style={{ width: '100%' }} />
                                </Grid>
                                <Grid item xs={1} xl={1} sm={1} md={1} lg={1}>
                                    <SearchIcon style={{ color: coloresEmma.serchover }} />
                                </Grid>
                            </Grid></>
                        }
                            <Grid item xs={12} xl={12} sm={12} md={12} lg={12} className={rubrica.length === 0 ? "oculto" : ""}>
                                <EnviarMultiplesArchivos
                                    enModal={false}
                                    enviarArchivos={(archivos) => this.onclickEnviarEvalComite(adjunto.id, evaluacion, -21, mensaje, tutor, cotutor, archivos)}
                                    limite={2}
                                    mostrarMensaje={false}
                                    lista={rubrica}
                                    mostrarRender={true}
                                    mostrar={true}
                                    enLista={true}
                                />
                            </Grid>
                    </Grid>
                    {adjunto.validacion == 4 && <AlertasSimple tipo='info' titulo='Evaluación del coordinador' sub_titulo='En esta sección el coordinador de areá seleccionado podrá dar el veredicto del documento. Sí la evaluación es positiva, el documento debe presentarse en comité. Pero si es negativa, NO seguira a comité y se deberá ingresar una retroalimentación para el director y estudiante' />}
                    {adjunto.validacion == 5 && <AlertasSimple tipo='info' titulo='Evaluación del Comité' sub_titulo='En esta sección el coordinador dará el veredicto del comité' />}
                </DialogContent>
                <DialogActions>
                    <BtnForm texto="Cerrar" callback={() => showModalEvalComite(false)} color="primary" />
                    <BtnForm texto="Enviar" callback={() => document.getElementById('enviar_form_multi_archivo').click()} color="primary" />
                </DialogActions>
            </Dialog>
        )
    }

    render() {
        let { showModalNewAdjuntos, adjuntos = [] } = this.props;
        if (!this.state.cargando) {
            return (
                <React.Fragment>
                    <AdjuntosListar adjuntos={adjuntos} acciones={this.configurarAcciones} showModalNewAdjuntos={showModalNewAdjuntos} />
                    {this.modalDetalleAdjunto()}
                    {this.modalNuevoAdjunto()}
                    {this.modalRemplazarAdjunto()}
                    {this.modalAprobarAdjunto()}
                    {this.modalNegarAdjunto()}
                    {this.modalGestionarAdjunto()}
                    {this.modalBuscarTutoresPropuestos()}
                </React.Fragment>
            )
        } else return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />;
    }
}

const mapStateToProps = (state) => {
    let { usuario } = state.redGlobal;
    let { modalAdd, modalDel, modalMod, modalAdmValores } = state.redGenericas;
    let { solicitud, adjunto, estado, adjuntos, modalNewAdjuntos, modalAdjuntos, modalEvalComite } = state.redGrados;

    return {
        usuario,
        solicitud,
        adjunto,
        estado,
        adjuntos,
        modalAdd,
        modalDel,
        modalMod,
        modalAdmValores,
        modalAdjuntos,
        modalNewAdjuntos,
        modalEvalComite,
    }
};

//CARGO FUNCIONES DEL MODULO
const mapDispatchToProps = {
    setAdjuntosEstado,
    setDataAdjunto,
    mostrarModalAdd,
    mostrarModalDel,
    mostrarModalMod,
    mostrarModalAdmValores,
    showModalAdjuntos,
    showModalNewAdjuntos,
    showModalEvalComite,
    actualizarMensaje,
}

export default connect(mapStateToProps, mapDispatchToProps)(Adjuntos);

