
import React, { Component } from 'react';
import PropTypes from 'prop-types';

//COMPONENTES DEL MODULO
import PracticasEnviarHV from "./PracticasEnviarHV";
import PracticasListarHV from "./PracticasListarHV";
import PracticasHvFiltrar from "./PracticasHvFiltrar";

class PracticasHV extends Component {


  render() {
    let { practica_id,modalDetallePractica,mostrarModalDetallePractica,usuario,modalAddFiltrosHv, mostrarModalAddFiltrosHv, actualizarDataHV, estudianteshv, actualizarMensaje, modalEnviarHV, enviarHV, mostrarModalEnviarHV, gestionarHV, mostrarModalGestionar, configurarAccionConf, modalEmpresasHv, mostrarModalEmpresasHV } = this.props;
    return (
      <div>
        <PracticasListarHV mostrarModalAddFiltrosHv={mostrarModalAddFiltrosHv} refrescar={() => actualizarDataHV()} configurarAccionConf={configurarAccionConf} mostrarModalGestionar={mostrarModalGestionar} gestionarHV={gestionarHV} estudianteshv={estudianteshv} mostrarModalEnviarHV={mostrarModalEnviarHV} modalEmpresasHv={modalEmpresasHv} mostrarModalEmpresasHV={mostrarModalEmpresasHV}/>
        <PracticasHvFiltrar actualizarMensaje={actualizarMensaje} limpiar={() => actualizarDataHV()} modalAddFiltrosHv={modalAddFiltrosHv} mostrarModalAddFiltrosHv={mostrarModalAddFiltrosHv} filtrar={(filtros) => actualizarDataHV(filtros)} />
        <PracticasEnviarHV practica_id={practica_id} vistaDetalle='practica' modalDetallePractica={modalDetallePractica} mostrarModalDetallePractica={mostrarModalDetallePractica} usuario={usuario} adminuser={true} configurarAccionConf={configurarAccionConf} mostrarModalGestionar={mostrarModalGestionar} mostrarModalEnviarHV={mostrarModalEnviarHV} modalEnviarHV={modalEnviarHV} actualizarMensaje={actualizarMensaje} enviarHV={enviarHV} />
      </div >
    )
  }
}


//Props del componente
PracticasHV.propTypes = {
  //variables
  estudianteshv: PropTypes.array.isRequired,
  modalEnviarHV: PropTypes.bool.isRequired,
  //funciones
  mostrarModalEnviarHV: PropTypes.func.isRequired,
  mostrarModalGestionar: PropTypes.func.isRequired,
  enviarHV: PropTypes.func.isRequired,


}



export default PracticasHV;
