import React, { Component } from 'react'
import { CODIGO_CURSO_CORTO, CODIGO_CURSO_CORTO_PROFE, CODIGO_NO_APLICA_TIPO_SUBACTIVIDAD, SUBTIPOS_ACTIVIDADES_MOVILIDAD, SUBTIPO_ACTI_ACADEMICO, SUBTIPO_ACTI_EMPRESARIAL, TIPOS_ACTIVIDADES_MOVILIDAD, TIPOS_INFORMES, TIPOS_MODALIDADES, TIPOS_MOVILIDADES, TIPO_MOVILIDADES_PROFESOR } from './helper';
import { consulta, generarFiltros, obtenerPermisosValorAlt, obtenerValoresFiltros } from '../../../global/js/funciones';
import ListarTarjetas from '../../general/ListarTarjetas';
import TareasTerminadas from '../../general/TareasTerminadas';
import emma_w from "../../../global/imagenes/emma_w.png";
import { Checkbox, Dialog, DialogActions, DialogContent, FormControl, Grid, InputLabel, ListItemText, MenuItem, Select } from '@material-ui/core';
import AppBarModal from '../../general/AppBarModal';
import { BtnForm } from '../../general/BotonesAccion';
import BuscarSelect from '../../general/BuscarSelect';

export default class InformesRegistro extends Component {

  constructor(props) {
    super(props)
    this.state = {
      cargando: false,
      cargandoGen: false,
      tipos_informes: [],
      mostrar: 'filtros',
      accion: '',
      modalInforme: false,
      informe: {},
      maxWidth: 'sm',
      // CAMPOS FILTROS
      periodosMulti: [],
      tipo_actividad: '',
      subtipo_actividad: '',
      tipo_movilidad: '',
      tipo_modalidad: '',
      // CONSULTAS
      periodos: [],
      tipos_actividades: [],
      subtipos_actividades: [],
      tipos_movilidad: [],
      tipos_modalidad: []
    }
  }

  async componentDidMount() {
    this.setState({ cargando: true });
    let tipos_informes = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_INFORMES }, { 'llave': 'estado', 'valor': 1 }]);
    this.setState({ tipos_informes, cargando: false });
  }

  async componentDidUpdate({ }, { modalInforme, mostrar, accion, tipo_actividad, subtipo_actividad, tipo_movilidad }) {
    let { mostrar: mostrarNew, accion: accionNew, modalInforme: modalInformeNew, tipo_actividad: tipo_actividadNew, subtipo_actividad: subtipo_actividadNew, tipo_movilidad: tipo_movilidadNew } = this.state;
    if (modalInforme !== modalInformeNew) {
      if (!modalInformeNew) this.setState({ accion: '' })
    }
    if (accion !== accionNew) {
      if (accionNew === 'filtro') {
        this.setState({ cargandoGen: true })
        let periodos = await this.obtenerPeriodos();
        let tipos_actividades = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_ACTIVIDADES_MOVILIDAD }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valora', 'valor': '1' }]);
        console.log('periodos', periodos);
        this.setState({ periodos, tipos_actividades, cargandoGen: false })
      }
    }
    if (tipo_actividad !== tipo_actividadNew) {
      if (tipo_actividadNew) {
        this.setState({ cargandoGen: true, subtipo_actividad: '', tipo_movilidad: '', tipo_modalidad: '' })
        let subtipos_actividades = await obtenerPermisosValorAlt([{ 'llave': 'principal__codigo', 'valor': tipo_actividad }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'secundario__generica', 'valor': SUBTIPOS_ACTIVIDADES_MOVILIDAD }, { 'llave': 'secundario__valora', 'valor': '1' }]);
        console.log('subtipos_actividades', subtipos_actividades);
        const subtipo_actividad = subtipos_actividades.some(item => item.secundario.codigo === CODIGO_NO_APLICA_TIPO_SUBACTIVIDAD) ? CODIGO_NO_APLICA_TIPO_SUBACTIVIDAD : '';
        this.setState({ subtipos_actividades, subtipo_actividad, cargandoGen: false })
      }
    }
    if (subtipo_actividad !== subtipo_actividadNew && subtipo_actividadNew) {
      this.setState({ cargandoGen: true, tipo_movilidad: '', tipo_modalidad: '' });

      let modalidadActividad, modalidadSubActividad, tipo_movilidad_act, tipo_movilidad_subact;

      // ⚠️ SI EL TIPO DE ACTIVIDAD ES CURSO CORTO Y EL SUBTIPO ES EMPRESARIAL, SE DEBE MOSTRAR LAS MODALIDADES DE PROFESOR
      // SI NO, SE DEBEN MOSTRAR LAS MODALIDADES DE ACUERDO AL TIPO DE ACTIVIDAD Y SUBTIPO DE ACTIVIDAD
      if (tipo_actividad === CODIGO_CURSO_CORTO && subtipo_actividadNew === SUBTIPO_ACTI_EMPRESARIAL) {
        modalidadActividad = await obtenerPermisosValorAlt([{ 'llave': 'principal__codigo', 'valor': CODIGO_CURSO_CORTO_PROFE }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'secundario__generica', 'valor': TIPOS_MODALIDADES }]);
        tipo_movilidad_act = await obtenerPermisosValorAlt([{ 'llave': 'principal__codigo', 'valor': CODIGO_CURSO_CORTO_PROFE }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'secundario__generica', 'valor': TIPOS_MOVILIDADES }]);
      } else {
        // ⚠️ CONSULTA DE MODALIDADES DE ACUERDO AL TIPO DE ACTIVIDAD
        modalidadActividad = await obtenerPermisosValorAlt([{ 'llave': 'principal__codigo', 'valor': tipo_actividad }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'secundario__generica', 'valor': TIPOS_MODALIDADES }]);
        // ⚠️ CONSULTA DE TIPOS DE MOVILIDADES DE ACUERDO AL TIPO DE ACTIVIDAD
        tipo_movilidad_act = await obtenerPermisosValorAlt([{ 'llave': 'principal__codigo', 'valor': tipo_actividad }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'secundario__generica', 'valor': TIPOS_MOVILIDADES }]);
      }

      // ⚠️ CONSULTA DE MODALIDADES DE ACUERDO AL SUBTIPO DE ACTIVIDAD
      modalidadSubActividad = await obtenerPermisosValorAlt([{ 'llave': 'principal__codigo', 'valor': subtipo_actividadNew }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'secundario__generica', 'valor': TIPOS_MODALIDADES }]);
      // ⚠️ CONSULTA DE TIPOS DE MOVILIDADES DE ACUERDO AL SUBTIPO DE ACTIVIDAD
      tipo_movilidad_subact = await obtenerPermisosValorAlt([{ 'llave': 'principal__codigo', 'valor': subtipo_actividadNew }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'secundario__generica', 'valor': TIPOS_MOVILIDADES }]);

      // ⚠️ CRUCE ENTRE MODALIDAD DE ACTIVIDAD Y MODALIDAD DE SUBACTIVIDAD PARA OBTENER LAS MODALIDADES QUE SE DEBEN MOSTRAR
      const coincidencias_modalidad = modalidadActividad.filter(obj1 => modalidadSubActividad.some(obj2 => obj1.secundario.id === obj2.secundario.id));
      // ⚠️ CRUCE ENTRE TIPO DE MOVILIDAD DE ACTIVIDAD Y TIPO DE MOVILIDAD DE SUBACTIVIDAD PARA OBTENER LOS TIPOS DE MOVILIDADES QUE SE DEBEN MOSTRAR
      const coincidencias_movilidad = tipo_movilidad_act.filter(obj1 => tipo_movilidad_subact.some(obj2 => obj1.secundario.id === obj2.secundario.id));

      this.setState({ tipos_modalidades: coincidencias_modalidad, tipos_movilidades: coincidencias_movilidad, cargandoGen: false });
    }
    if (tipo_movilidad !== tipo_movilidadNew && tipo_movilidadNew) {
      let modalidadActividad, modalidadSubActividad;
      this.setState({ cargandoGen: true, tipo_modalidad: '' });
      if (tipo_actividad === CODIGO_CURSO_CORTO && subtipo_actividad === SUBTIPO_ACTI_ACADEMICO && TIPO_MOVILIDADES_PROFESOR.includes(tipo_movilidadNew)) {
        modalidadActividad = await obtenerPermisosValorAlt([{ 'llave': 'principal__codigo', 'valor': CODIGO_CURSO_CORTO }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'secundario__generica', 'valor': TIPOS_MODALIDADES }]);
        modalidadSubActividad = await obtenerPermisosValorAlt([{ 'llave': 'principal__codigo', 'valor': 'Academico_subtipo_actividad_profesor' }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'secundario__generica', 'valor': TIPOS_MODALIDADES }]);
      } else {
        modalidadActividad = await obtenerPermisosValorAlt([{ 'llave': 'principal__codigo', 'valor': tipo_actividad }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'secundario__generica', 'valor': TIPOS_MODALIDADES }]);
        modalidadSubActividad = await obtenerPermisosValorAlt([{ 'llave': 'principal__codigo', 'valor': subtipo_actividad }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'secundario__generica', 'valor': TIPOS_MODALIDADES }]);
      }
      const coincidencias = modalidadActividad.filter(obj1 => modalidadSubActividad.some(obj2 => obj1.secundario.id === obj2.secundario.id));
      this.setState({ tipos_modalidades: coincidencias, cargandoGen: false });
    }
  }

  onChange = ({ target }) => {
    let { value, name } = target
    this.setState({ [name]: value })
  }

  async obtenerPeriodos(id) {
    return new Promise(resolve => {
      consulta(`api/v1.0/internacionalizacion/solicitudes/periodos`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      });
    });
  }

  pintarNombresPeriodo = (selected, listado) => {
    let resp = [];

    let a = listado.filter((e) => selected.indexOf(e) != -1)
    a.map((e) => resp.push(e))

    return resp.join(", ")
  }
  pintarInforme = () => {
    let { tipos_informes, cargando } = this.state;
    const acciones = (data) => {
      this.setState({ informe: { 'nombre': data.nombre, 'codigo': data.codigo }, modalInforme: true, accion: 'filtro' })
    }
    return (
      <>
        {tipos_informes.length === 1 ? (
          <Grid container >
            <Grid item xs={6} md={6} lg={6}>
              <ListarTarjetas
                datos={tipos_informes}
                titulo="Informes Internacionalización"
                acciones={(data) => acciones(data)}
                avatar={({ valora }) => valora.toUpperCase()}
                color_avatar={({ valorb }) => ({
                  backgroundColor: valorb ? valorb : '#EEEEEE',
                  color: valorb ? 'white' : '#757575'
                })}
              />
            </Grid>
          </Grid>
        ) : (
          <ListarTarjetas
            datos={tipos_informes}
            titulo="Informes Internacionalización"
            acciones={(data) => acciones(data)}
            avatar={({ valora }) => valora.toUpperCase()}
            color_avatar={({ valorb }) => ({
              backgroundColor: valorb ? valorb : '#EEEEEE',
              color: valorb ? 'white' : '#757575'
            })}
          />
        )}
      </>
    )
  }

  modalInforme = () => {
    let { modalInforme, informe, mostrar, cargandoGen, maxWidth } = this.state;
    return (
      <>
        <Dialog fullWidth={true} maxWidth={maxWidth} open={modalInforme} onClose={() => this.setState({ modalInforme: false })} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <AppBarModal titulo={informe.nombre} mostrarModal={() => this.setState({ modalInforme: false })} titulo_accion={(mostrar === 'filtros') ? "Limpiar" : 'Volver'} /* accion={this.resetState} */ />
          <DialogContent className='scroll'>
            <Grid style={{ padding: "0% 4% 0% 0%" }} item xs={12} md={12}>
              {this.pintar()}
            </Grid>
          </DialogContent>
          <DialogActions>
            <BtnForm texto='Cerrar' callback={() => this.setState({ modalInforme: false })} />
            {!cargandoGen && mostrar === 'filtros' && <BtnForm texto="GENERAR" callback={this.onSubmit} />}
            {/* {!cargandoGen && mostrar === 'filtroDetalle' && <BtnForm texto="FILTRAR" callback={this.obtenerPracticas} />} */}
          </DialogActions>
        </Dialog>
      </>
    )
  }

  onSubmit = (e) => {
    let { periodosMulti, tipo_actividad, subtipo_actividad, tipo_movilidad, tipo_modalidad, informe } = this.state;
    let { actualizarMensaje } = this.props;
    let filtros = [], titulo = ''
    filtros.push({ 'llave': 'informe', 'valor': informe.codigo });
    if (periodosMulti.length === 0) {
      titulo = 'Por favor, seleccione al menos un periodo'
    }

    if (periodosMulti.length > 0) filtros.push({ 'llave': 'periodo_in', 'valor': periodosMulti })
    if (tipo_actividad) filtros.push({ 'llave': 'tipo_actividad', 'valor': tipo_actividad })
    if (subtipo_actividad) filtros.push({ 'llave': 'subtipo_actividad', 'valor': subtipo_actividad })
    if (tipo_movilidad) filtros.push({ 'llave': 'tipo_movilidad', 'valor': tipo_movilidad })
    if (tipo_modalidad) filtros.push({ 'llave': 'tipo_modalidad', 'valor': tipo_modalidad })

    if (titulo) actualizarMensaje({ titulo, tipo: 'info', mostrar: true, tiempo: 6000 });
    else this.obtenerInforme(filtros);

    e.preventDefault();
  }

  obtenerInforme = async (filtros = []) => {
    let { actualizarMensaje } = this.props
    let f = await generarFiltros(filtros);
    this.setState({ cargandoGen: true });
    consulta(`api/v1.0/internacionalizacion/informe?${f}`, null, null,
      (error, estado, resp) => {
        if (estado === 200) this.setState({ cargandoGen: false, data_informe: resp, mostrar: 'informe', maxWidth: 'lg' });
        else actualizarMensaje({ titulo: 'Ha ocurrido un error, contacte con el administrador.', tipo: 'info', mostrar: true, tiempo: 6000 });
      }
    );

  }

  obtenerPracticas = () => { }

  pintar = () => {
    let { cargandoGen, mostrar } = this.state;
    if (cargandoGen) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
    return (
      <>
        {mostrar === 'filtros' && this.pintarFiltros()}
        {mostrar === 'informe' && this.pintarDataInforme()}
      </>
    )
  }

  pintarFiltros = () => {
    let { periodos, periodosMulti, tipos_actividades, tipo_actividad, subtipos_actividades, subtipo_actividad, tipos_movilidades, tipo_movilidad, tipos_modalidades, tipo_modalidad } = this.state;

    return (
      <>
        <Grid container spacing={3} className='margin_cont'>
          <InputLabel>Campos a Filtrar</InputLabel>
          <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
            <FormControl className='form-control'>
              <InputLabel htmlFor="periodosMulti">Seleccione Periodo</InputLabel>
              <Select
                inputProps={{ name: 'periodosMulti', id: 'periodosMulti' }}
                multiple
                value={periodosMulti}
                onChange={this.onChange}
                renderValue={selected => { return this.pintarNombresPeriodo(selected, periodosMulti) }}
                required={true}>
                {periodos.map(({ periodo }, i) =>
                  <MenuItem key={i} value={periodo}>
                    <Checkbox checked={periodosMulti.indexOf(periodo) > -1} />
                    <ListItemText primary={periodo} />
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
            <FormControl className='form-control' required /* error={getError('tipo_actividad', errores).length > 0} */ size='small'>
              <InputLabel id='tipo_actividad_label'>Tipo de actividad</InputLabel>
              <Select
                labelId='tipo_actividad_label'
                id='tipo_actividad'
                name='tipo_actividad'
                value={tipo_actividad}
                label='Tipo de actividad'
                onChange={this.onChange}
                fullWidth
              >
                {
                  tipos_actividades.length > 0 ?
                    tipos_actividades
                      // .sort(({ valorh: previousID }, { valori: currentID }) => previousID - currentID)
                      .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                      .map(tipo => <MenuItem key={tipo.id} value={tipo.codigo}>{tipo.nombre}</MenuItem>)
                    : <MenuItem value=""><em>Seleccione tipo de movilidad</em></MenuItem>
                }
              </Select>
              {/* <FormHelperText>{getError('tipo_actividad', errores)}</FormHelperText> */}
            </FormControl>
          </Grid>
          {
            subtipos_actividades.length > 0 && subtipo_actividad !== CODIGO_NO_APLICA_TIPO_SUBACTIVIDAD &&
            <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
              <FormControl className='form-control' required /* error={getError('subtipo_actividad', errores).length > 0}  */ size='small'>
                <InputLabel id='subtipo_actividad_label'>Subtipo de actividad</InputLabel>
                <Select
                  labelId='subtipo_actividad_label'
                  id='subtipo_actividad'
                  name='subtipo_actividad'
                  value={subtipo_actividad}
                  label='Subtipo de actividad'
                  fullWidth
                  onChange={this.onChange}
                >
                  {subtipos_actividades.length > 0 ?
                    subtipos_actividades
                      .map(({ secundario: { id, codigo, nombre } }) => <MenuItem key={id} value={codigo}>{nombre}</MenuItem>)
                    : <MenuItem value=""><em>No hay subtipos</em></MenuItem>
                  }
                </Select>
                {/* <FormHelperText>{getError('subtipo_actividad', errores)}</FormHelperText> */}
              </FormControl>
            </Grid>
          }
          {
            tipos_movilidades && tipos_movilidades.length > 0 &&
            <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
              <FormControl className='form-control' required /* error={getError('tipo_movilidad', errores).length > 0} */ size='small'>
                <InputLabel id='tipo_movilidad_label'>Tipo de movilidad</InputLabel>
                <Select
                  labelId='tipo_movilidad_label'
                  id='tipo_movilidad'
                  name='tipo_movilidad'
                  value={tipo_movilidad}
                  label='Tipo de movilidad'
                  onChange={this.onChange}
                  fullWidth
                >
                  {
                    tipos_movilidades
                      .map(({ secundario: { id, nombre } }) => <MenuItem key={id} value={id}>{nombre}</MenuItem>)
                  }
                </Select>
                {/* <FormHelperText>{getError('tipo_movilidad', errores)}</FormHelperText> */}
              </FormControl>
            </Grid>
          }
          {
            tipos_modalidades && tipos_modalidades.length > 0 &&
            <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
              <FormControl className='form-control' required /* error={getError('tipo_modalidad', errores).length > 0} */ size='small'>
                <InputLabel id='modalidad_label'>Tipo de modalidad</InputLabel>
                <Select
                  labelId='modalidad_label'
                  id='tipo_modalidad'
                  name='tipo_modalidad'
                  value={tipo_modalidad}
                  label='tipo_modalidad'
                  onChange={this.onChange}
                  fullWidth
                  disabled={tipo_movilidad !== '' ? false : true}
                >
                  {tipos_modalidades.map(({ secundario: { id, codigo, nombre } }) => <MenuItem key={id} value={codigo}>{nombre}</MenuItem>)
                  }
                </Select>
                {/* <FormHelperText>{getError('tipo_modalidad', errores)}</FormHelperText> */}
              </FormControl>
            </Grid>
          }
        </Grid>
      </>
    )
  }

  pintarDataInforme = () => {
    return (
      <>
        <h1>Esto es un informe</h1>
      </>
    )
  }

  render() {
    let { tipos_informes, cargando, modalInforme } = this.state;

    return (
      <>
        {cargando && <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />}
        {!cargando && tipos_informes && tipos_informes.length > 0 && this.pintarInforme()}
        {
          !cargando && modalInforme && this.modalInforme()
        }
      </>
    )
  }
}
