import React, { Component } from 'react';
import { Dialog, DialogActions, DialogContent, Link } from '@material-ui/core'

//components
import { BtnForm } from '../../general/BotonesAccion';
import AppBarModal from '../../general/AppBarModal'
import ListarDatos from '../../general/ListarDatos'
import TareasTerminadas from "../../general/TareasTerminadas";
import { api, consulta, obtenerValoresFiltros } from '../../../global/js/funciones'
import { BtnDetalle } from '../../general/BotonesAccion'
import emma_w from '../../../global/imagenes/emma_w.png';
import moment from 'moment'
import { 
    mostrarModalDetallePostulante
} from "../../../redux/actions/actPortal";
import DetallePostulante from '../Empresa/DetallePostulante';
import PropTypes from 'prop-types';
import { connect } from "react-redux";


class ModalListaEstadistica extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cargando: true,
            datos: [],
            idDetallePostulante: 0,
            cod_prestador: '',
        }
    }

    async componentDidUpdate({ modalListaEstadistica }) {
        let { modalListaEstadistica: modalListaEstadisticaNew, tipo } = this.props;
        if (modalListaEstadistica !== modalListaEstadisticaNew && modalListaEstadisticaNew) this.obtenerDatosIniciales(tipo);
    }

    obtenerDatosIniciales = async estadistica => {
        this.setState({ cargando: true })
        let data = (estadistica == 7) ? 
            await this.obtenerOrientacion() : 
            await this.obtenerEstadisticas();
        let cod_prestador = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Cod_Pres' }]);
        this.setState({ data, cod_prestador: cod_prestador[0].nombre, cargando: false })
    }

    obtenerEstadisticas = () => {
        let { data_estadistica } = this.props
        return new Promise(resolve => {
            consulta(`api/v1.0/portal/estadistica`, data_estadistica, "post", (error, estado, resp) => {
                resolve(estado === 200 && !error ? resp.lista : null);
            })
        })
    }

    obtenerOrientacion = () => {
        return new Promise(resolve => {
            consulta(`api/v1.0/portal/orientacion_listar`, null, "get", async (error, estado, resp) => {
                resolve(estado === 200 && !error ? resp : null);
            })
        })
    }

    listaPrincipal = (tipo) => {
        switch (tipo) {
            case 1:
                return ({ Nombres }) => `Nombres: ${Nombres.toUpperCase()}`
            case 2:
                return ({ Nombre_Empresa }) => `Nombre Empresa: ${Nombre_Empresa.toUpperCase()}`
            case 3:
                return ({ Nombre_del_cargo }) => `Nombre del cargo: ${Nombre_del_cargo.toUpperCase()}`
            case 4:
                return ({ Titulo_practica }) => `Titulo de practica: ${Titulo_practica.toUpperCase()}`
            case 5:
                return ({ Nombre_de_Postulado }) => `Nombre del cargo: ${Nombre_de_Postulado.toUpperCase()}`
            case 6:
                return ({ Mes }) => `Mes: ${Mes.toUpperCase()}`
            case 7:
                return ({ nombre_archivo }) => `${nombre_archivo.toUpperCase()}`
            default:
                break;
        }

    }

    actions_adj = row => {
        return (
            <div>
                <Link href={`${api + row.archivo}`} target="_blank">
                    <BtnDetalle callback={() => { }} />
                </Link>
            </div>
        )
    }

    listarInformacionPostulante = (row) => {
        let idDetallePostulante = row.id
        let { mostrarModalDetallePostulante } = this.props
        this.setState({ idDetallePostulante })
        
        // consulta("api/v1.0/portal/obtener_detalle_postulante", { id }, "post", (error, estado, resp) => {
        //     this.setState({
        //         detalleCV: resp
        //     })
        // })
        mostrarModalDetallePostulante(true)
    }

    actions = row => {
        return (
            <div>
                <BtnDetalle 
                    callback={() => { this.listarInformacionPostulante(row) }}
                />
            </div>
        )
    }
    
    download = (data, nombre_archivo) => {
        let { tipo } = this.props;
        let { cod_prestador } = this.state;

        if (data.length > 0) {

            let keys = Object.keys(data[0]);

            let text = "";

            data.forEach(element => {
                let aux = []
                keys.forEach(key => aux.push(element[key]))
                text += aux.join("|$$|")+"\n"
            });

            var element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
            if (tipo == 3) element.setAttribute('download', `${cod_prestador}${moment().format('YYYYMMDD')}.txt`);
                else if (tipo == 4) element.setAttribute('download', `PL${data[data.length - 1].Codigo_prestador}${moment().format('YYYYMMDD')}.txt`);
                else element.setAttribute('download', `${nombre_archivo}-${moment().format('YYYY-MM-DD, h:mm a')}.txt`);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        }
    }

    datosModal = tipo => {
        let filas = []
        switch (tipo) {
            case 1:
                filas = [
                    { 'id': 'NO.', 'enLista': false },
                    { 'id': 'id', 'enLista': false },
                    { 'id': 'Identificación', 'enLista': false },
                    { 'id': 'Nombres', 'enLista': false },
                    { 'id': 'Apellidos', 'enLista': false },
                    { 'id': 'Correo_electrónico', 'enLista': false },
                    { 'id': 'Programa', 'enLista': false },
                    { 'id': 'Fecha_de_nacimiento', 'enLista': false },
                    { 'id': 'Fecha_de_registro_en_el_portal', 'enLista': false },
                    { 'id': 'Estado_civil', 'enLista': false },
                    { 'id': 'Genero', 'enLista': false },
                    { 'id': 'Telefono_fijo', 'enLista': false },
                    { 'id': 'Telefono_movil', 'enLista': false },
                    { 'id': 'Direccion_de_residencia', 'enLista': false },
                    { 'id': 'Estudiante', 'enLista': false },
                    { 'id': 'Graduado', 'enLista': false },
                    // { 'id': 'Estado', 'enLista': false },
                ]
                return {"titulo": "Registro Estudiantes/Graduados", "subtitulo":"Estudiantes/Graduados", "archivo": "Registro_Estudiantes_Graduados", "fila_principal": ({ Nombres }) => `Nombres: ${Nombres.toUpperCase()}`, "filas": filas}
            case 2:
                filas = [
                    { 'id': 'NO.', 'enLista': false },
                    { 'mostrar': ({ Nit }) => `Nit ${Nit}`,
                      'id': 'Nit', 
                      'enLista': true 
                    },
                    { 'id': 'Nombre_Empresa', 'enLista': false },
                    { 'mostrar': ({ Ciudad }) => `Ciudad ${Ciudad}`,
                      'id': 'Ciudad', 
                      'enLista': true 
                    },
                    { 'mostrar': ({ Barrio }) => `Barrio ${Barrio}`,
                      'id': 'Barrio', 
                      'enLista': true 
                    },
                    { 'id': 'Sector_Economico', 'enLista': false },
                    { 'id': 'Numero_de_empleados', 'enLista': false },
                    { 'mostrar': ({ Contacto }) => `Contacto ${Contacto}`,
                      'id': 'Contacto', 
                      'enLista': true 
                    },
                    { 'mostrar': ({ Cargo }) => `Cargo ${Cargo}`,
                      'id': 'Cargo', 
                      'enLista': 
                      true 
                    },
                    { 'id': 'Telefono', 'enLista': false },
                    { 'mostrar': ({ Celular }) => `Celular ${Celular}`,
                      'id': 'Celular', 
                      'enLista': true 
                    },
                    { 'mostrar': ({ Correo }) => `Correo ${Correo}`,
                      'id': 'Correo', 
                      'enLista': true 
                    },
                    { 'mostrar': ({ Direccion_de_la_Empresa }) => `Direccion ${Direccion_de_la_Empresa}`,
                      'id': 'Direccion_de_la_Empresa', 
                      'enLista': true
                    },
                    { 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD'),
                       'id': 'fecha_registro', 
                       'enLista': true 
                    },
                ]
                return {"titulo": "Registro Empresas", "subtitulo":"Empresas", "archivo": "Registro_Empresas", "fila_principal": ({ Nombre_Empresa }) => `Nombre Empresa: ${Nombre_Empresa.toUpperCase()}`,"filas": filas}
            case 3: // VACANTES LABORALES
                filas = [
                    { 'id': 'NO.', 'enLista': false },
                    { 'id': 'Codigo_prestador', 'enLista': false },
                    { 'id': 'Fecha_de_publicacion_de_la_oferta', 'enLista': false },
                    { 'id': 'Nombre_del_cargo', 'enLista': false },
                    { 'id': 'Numero_de_vacantes', 'enLista': false },
                    { 'id': 'Programas_academicos_solicitados', 'enLista': false },
                    { 'id': 'Empresa', 'enLista': false },
                    { 'id': 'Tipo_de_contrato', 'enLista': false },
                    { 'id': 'Ciudad', 'enLista': false },
                    { 'id': 'Salario', 'enLista': false },
                    { 'id': 'Nombre_de_contacto', 'enLista': false },
                    { 'id': 'Telefono', 'enLista': false },
                    { 'id': 'Correo_electronico', 'enLista': false },
                    { 'id': 'Numero_de_postulaciones_a_la_vacante', 'enLista': false },
                    { 'id': 'Numero_de_colocados_en_la_vacante', 'enLista': false },
                    { 'id': 'Nombre_del_colocado', 'enLista': false },
                ]
                return {"titulo": "Registro Vacantes", "subtitulo":"Vacantes Laborales", "archivo": "Registro_Vacantes", "fila_principal": ({ Nombre_del_cargo }) => `Nombre del cargo: ${Nombre_del_cargo.toUpperCase()}`, "filas": filas}
            case 4: // VACANTES DE PRACTICAS
                filas = [
                    { 'id': 'Codigo_prestador', 'enLista': false },
                    { 'id': 'Codigo_practica', 'enLista': false },
                    { 'id': 'Titulo_practica', 'enLista': false },
                    { 'id': 'Tiempo_experiencia', 'enLista': false },
                    { 'id': 'Modalidad_formacion', 'enLista': false },
                    { 'id': 'Valor_auxilio', 'enLista': false },
                    { 'id': 'Numero_plazas', 'enLista': false },
                    { 'id': 'Ocupacion_relacionada', 'enLista': false },
                    { 'id': 'Tipo_documento', 'enLista': false },
                    { 'id': 'Identificacion', 'enLista': false },
                    { 'id': 'Razon_social', 'enLista': false },
                    { 'id': 'Solicitud_excepcion', 'enLista': false },
                    { 'id': 'Fecha_de_publicacion_de_la_oferta', 'enLista': false },
                    { 'id': 'Fecha_vencimiento', 'enLista': false },
                    { 'id': 'Codigo_municipio', 'enLista': false },
                    { 'id': 'Sector_economico', 'enLista': false },
                    { 'id': 'Tipo_contrato', 'enLista': false },
                    { 'id': 'Teletrabajo', 'enLista': false },
                    { 'id': 'Discapacidad', 'enLista': false },
                ]
                return {"titulo": "Registro Vacantes", "subtitulo":"Vacantes de Prácticas", "archivo": "Registro_Vacantes", "fila_principal": ({ Titulo_practica }) => `Titulo de practica: ${Titulo_practica.toUpperCase()}`, "filas": filas}
            case 5:
                filas =[
                    { 'id': 'No.', 'enLista': false },
                    { 'id': 'Nombre_de_Postulado', 'enLista': false },
                    { 'id': 'Genero', 'enLista': false },
                    { 'id': 'Nombre_de_la_vacante', 'enLista': false },
                    { 'id': 'Colocado', 'enLista': false },
                ]
                return {"titulo": "Número de Remisiones", "subtitulo":"Remisiones", "archivo": "Número_de_remisiones", "fila_principal": ({ Nombre_de_Postulado }) => `Nombre del cargo: ${Nombre_de_Postulado.toUpperCase()}`, "filas": filas}
            case 6:
                filas = [
                    { 'id': 'Mes', 'enLista': false },
                    { 'id': 'Numero_de_PQRS_recibidas', 'enLista': false },
                    { 'id': 'Numero_de_PQRS_atendidas', 'enLista': false },
                ]
                return {"titulo": "PQRS", "subtitulo":"PQRS", "archivo": "PQRS", "fila_principal": ({ Mes }) => `Mes: ${Mes.toUpperCase()}`, "filas": filas}
            case 7:
                filas = [
                    // aqui datos a descargar
                    { 'id': 'usuario_registro', 'enLista': true , 'mostrar': ( {usuario_registro : { primer_nombre, primer_apellido }}) => `${primer_nombre} ${primer_apellido}`, nombre: 'Cargado Por: '},
                ]
                return {"titulo": "Orientacion impartida a oferentes", "subtitulo":"Orientacion", "archivo": "Orientacion_impartida_a_oferentes", "fila_principal": ({ nombre_archivo }) => `${nombre_archivo.toUpperCase()}`, "filas": filas}
            default:
                return {"titulo": "", "subtitulo":"", "archivo": "", "fila_principal": "", "filas": filas}
                break;
        }
    }

    render() {
        let { data, cargando,idDetallePostulante } = this.state
        let { modalListaEstadistica, mostrarModalListaEstadistica,mostrarModalDetallePostulante,modalDetallePostulante, tipo } = this.props
        let { titulo, subtitulo, archivo, fila_principal, filas } = this.datosModal(tipo)
        return (
            <>
                {tipo == 1 ?
                    <Dialog open={modalListaEstadistica} onClose={() => mostrarModalListaEstadistica(false)} fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600}>
                        <AppBarModal titulo={titulo} mostrarModal={() => mostrarModalListaEstadistica(false)} titulo_accion="" />
                        <DialogContent style={{ padding: 0 }} className="scroll">
                            {!cargando ?
                                <ListarDatos
                                    opcionAdicional={true}
                                    actDescargar={() => this.download(data, archivo)}
                                    id="tbl_lista_estadistica"
                                    datos={data}
                                    titulo={subtitulo}
                                    descargar={archivo}
                                    opciones={true}
                                    buscar={true}
                                    avatar={({ estado_solicitud }) => null}
                                    color_avatar={null}
                                    acciones={tipo == 7 ? this.actions_adj : row => this.actions(row)}
                                    fila_principal={fila_principal}
                                    filas={filas}
                                /> :
                                <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
                            }
                        </DialogContent>
                        <DialogActions>
                            <BtnForm texto="CERRAR" callback={() => mostrarModalListaEstadistica(false)} />
                        </DialogActions>
                    </Dialog> :
                    <Dialog open={modalListaEstadistica} onClose={() => mostrarModalListaEstadistica(false)} fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600}>
                        <AppBarModal titulo={titulo} mostrarModal={() => mostrarModalListaEstadistica(false)} titulo_accion="" />
                        <DialogContent style={{ padding: 0 }} className="scroll">
                            {!cargando ?
                                <ListarDatos
                                    opcionAdicional={true}
                                    actDescargar={() => this.download(data, archivo)}
                                    id="tbl_lista_estadistica"
                                    datos={data}
                                    titulo={subtitulo}
                                    descargar={archivo}
                                    opciones={true}
                                    buscar={true}
                                    avatar={({ estado_solicitud }) => null}
                                    color_avatar={null}
                                    acciones={tipo == 7 ? this.actions_adj : null}
                                    fila_principal={fila_principal}
                                    filas={filas}
                                /> :
                                <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
                            }
                        </DialogContent>
                        <DialogActions>
                            <BtnForm texto="CERRAR" callback={() => mostrarModalListaEstadistica(false)} />
                        </DialogActions>
                    </Dialog>
                }
                <DetallePostulante
                    // detallePostulante={detalleCV}
                    idDetallePostulante={idDetallePostulante}
                    mostrarModalDetallePostulante={mostrarModalDetallePostulante}
                    modalDetallePostulante={modalDetallePostulante}
                />
            </>
        );
    }
}

const mapStateToProps = state => {
    let {
        modalDetallePostulante, 
    } = state.redPortal
    return {
        modalDetallePostulante,
    }
}

const mapDispatchToProps = {
    mostrarModalDetallePostulante
}

ModalListaEstadistica.propTypes = {
    //funciones
    mostrarModalDetallePostulante: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalListaEstadistica);