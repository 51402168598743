import React from 'react';
import { BottomNavigation, BottomNavigationAction, Tabs, Tab, Hidden, Badge } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Procesos from '@material-ui/icons/ImportContacts';
import CreateIcon from '@material-ui/icons/Create';
import Notificaciones from '@material-ui/icons/NotificationImportant';
import GradosNotificaciones from './GradosNotificaciones';

export default function MenuGradosEstudiante
({ notificaciones, sele = 0, seleccionar = () => { } }) {

  const clase = (item, selec, tipo = '') => item === selec ? `item_selec${tipo}` : `item_modulo`;

  const pintar = (app, claseN = '') => {
    if (!app) {
      return (
        <div className={claseN}>
          <BottomNavigation showLabels className={clase('proceso', sele)} onClick={() => seleccionar('proceso')}>
            <BottomNavigationAction label={'Proceso'} icon={<LocationOnIcon />} />
          </BottomNavigation>
          <GradosNotificaciones notificaciones={notificaciones} render={(click) => <BottomNavigation showLabels onClick={click} className={clase('notificaciones', sele)} ><BottomNavigationAction label={'Notificación'} icon={<Badge badgeContent={notificaciones.length} color="secondary"><Notificaciones /></Badge>} />  </BottomNavigation>} />
          <BottomNavigation showLabels className={clase('nuevo', sele)} onClick={() => seleccionar('nuevo')}>
            <BottomNavigationAction label={'Nuevo'} icon={<CreateIcon />} />
          </BottomNavigation>
          <BottomNavigation showLabels className={clase('historial', sele)} onClick={() => seleccionar('historial')}>
            <BottomNavigationAction label={'Historial'} icon={<Procesos />} />
          </BottomNavigation>
        </div>
      )
    } else {
      return (
        <div className={claseN}>
          <Tabs value={sele} variant='scrollable' scrollButtons="off" >
            <Tab label={'Proceso'} icon={<LocationOnIcon className='menu_icono' />} className={clase('proceso', sele, '_app')} value='proceso' onClick={() => seleccionar('proceso')} />
            <GradosNotificaciones notificaciones={notificaciones} horizontal='left' render={(click) => <Tab onClick={click} label={'Notificación'} icon={<Badge badgeContent={notificaciones.length} color="secondary"><Notificaciones /></Badge>} className={clase('notificaciones', sele, '_app')} value='notificaciones' />} />
            <Tab label={'Nuevo'} icon={<CreateIcon className='menu_icono' />} className={clase('nuevo', sele, '_app')} value='nuevo' onClick={() => seleccionar('nuevo')} />
            <Tab label={'Historial'} icon={<Procesos className='menu_icono' />} className={clase('historial', sele, '_app')} value='historial' onClick={() => seleccionar('historial')} />
          </Tabs>
        </div>
      )
    }
  }



  return (
    <div>
      <div>
        <Hidden xsDown> {pintar(false, 'menu_modulo scroll')} </Hidden>
        <Hidden smUp> {pintar(true, 'menu_modulo_app')}</Hidden>
      </div>
    </div>
  );
}
