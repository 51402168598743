import React, { Component } from 'react'
import {
    Dialog,
    DialogActions,
    DialogContent,

    FormControl,
    Grid,
    Container,
    TextField,
} from '@material-ui/core'
import AppBarModal from '../../general/AppBarModal'
import { BtnForm } from '../../general/BotonesAccion';
import { ValidatorForm } from "react-material-ui-form-validator";

export default class AddPqrs extends Component {
    render() {
        let {
            modalEditPqrs,
            mostrarModalEditPqrs,
            enviarFormActual,
            onChangeState
        } = this.props
        return (
            <div>
                <ValidatorForm >
                    <Dialog open={modalEditPqrs} onClose={() => mostrarModalEditPqrs(false)} fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600}>
                        <AppBarModal titulo={"Responder PQRS"} mostrarModal={() => mostrarModalEditPqrs(false)} titulo_accion="" />
                        <DialogContent style={{ padding: 0, overflowX: 'hidden' }} >
                            <React.Fragment>
                                <FormControl className='form-control'>
                                    <Container maxWidth="sm">
                                        <Grid
                                            container
                                            spacing={3}
                                            direction='column'
                                            justify='center'
                                        >
                                            <Grid item xs={12}>
                                                <TextField
                                                    multiline
                                                    required
                                                    rows = { 5 }
                                                    id = 'body_pqrs'
                                                    name = 'body_pqrs'
                                                    label = 'PQRS'
                                                    fullWidth
                                                    onChange = { onChangeState }
                                                />
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </FormControl>
                            </React.Fragment>
                        </DialogContent>
                        <DialogActions>
                            <BtnForm texto="CERRAR" callback={() => mostrarModalEditPqrs(false)} />
                            <BtnForm texto="GUARDAR" callback={() => enviarFormActual()} />
                        </DialogActions>
                    </Dialog>
                    <button type="submit" className='oculto' id='btn_crear_pqrs_portal'></button>
                </ValidatorForm>
            </div>
        )
    }
}