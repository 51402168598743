import React, { useState, useEffect } from 'react';
import { consulta } from '../../../global/js/funciones';
import downloadSisef from '../../../global/imagenes/downloadSisef.png';
import ReactExport from 'react-data-export';
import TareasTerminadas from '../../general/TareasTerminadas';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import AppBarModal from '../../general/AppBarModal';
import { BtnForm } from '../../general/BotonesAccion';
import moment from "moment";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



export const ExcelComponent = ({ dataSet, nombre = "Syllabus" }) => {
  return (
    <ExcelFile fileExtension={'xlsx'} filename={nombre} element={<div style={{ cursor: "pointer" }}><TareasTerminadas mensaje={`¡ Click aquí para descargar el syllabus !`} marginTop='5%' imagen={downloadSisef} widthImg="20%" marginBottom='2%' /></div>}>
      <ExcelSheet dataSet={dataSet} name="Rubricas" />
    </ExcelFile>
  );
}