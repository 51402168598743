import React, { useEffect, useState } from 'react';
//COMPONENTS
import { Dialog, DialogContent, Grid, Typography, Button, Box, useMediaQuery, useTheme } from '@material-ui/core';
import { consulta, api } from '../../global/js/funciones';
import AppBarModal from '../general/AppBarModal';
import useStylesRubrica from './cssRubrica.js'
import profesor from '../../global/imagenes/profesor informacion general.svg';
import Informacion from './InformacionRubrica'
import RetroalimentacionRubrica from './RetroalimentacionRubrica'

const ListarRubrica = (props) => {
    let { modalRubrica, setModalRubrica, statePlan, dataPlan, indice } = props;
    const classes = useStylesRubrica();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [rubrica, setRubrica] = React.useState([]);
    const [retroalimentacion, setRetroalimentacion] = React.useState([]);
    const [cargando, setCargando] = React.useState(false);
    
    useEffect(() => {
        if (statePlan > 0) setDataRubrica(dataPlan.codigo, dataPlan.programa);
        if (statePlan > 0) setDataRetro(dataPlan.codigo, dataPlan.programa);
        
      }, [props.statePlan]);


    const setDataRubrica = async(materia, programa) =>{
        setCargando(true);
        let rubrica = await obtenerRubrica(materia, programa);
        if (!!rubrica.Response) rubrica= [];
        setRubrica(rubrica);
        setCargando(false);
    }
    const setDataRetro = async() =>{
        setCargando(true);
        let retro = await obtenerRetroalimentacion();
        if (!!retro.Response) retro= [];
        setRetroalimentacion(retro);
        setCargando(false);
    }

    const obtenerRubrica = (materia, programa) =>{
        return new Promise(resolve => {
            consulta(`api/v1.0/consultas/plan/rubrica?materia=${materia}&programa=${programa}`, null, null, (error, estado, resp) => {
                resolve(resp);
            })
        })
    }
    const obtenerRetroalimentacion = () =>{
        return new Promise(resolve => {
            consulta(`api/v1.0/consultas/plan/retroalimentacion`, null, null, (error, estado, resp) => {
                resolve(resp);
            })
        })
    }


    return (
        <Dialog open={modalRubrica} onClose={() => setModalRubrica(false)} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="l">
            {isMobile ? <></> : <AppBarModal  mostrarModal={() => setModalRubrica(false)} />} 
            <DialogContent style={{ padding: 0 }} className='scroll'></DialogContent>
            <Typography className={useStylesRubrica.instructions}></Typography>
            {
                cargando ? <h3>Cargando</h3> : <>
                {
                indice == 1 ? <Informacion rubrica={rubrica} dataPlan={dataPlan}/> : <RetroalimentacionRubrica retroalimentacion={retroalimentacion} dataPlan={dataPlan}/>
            }
                </>
            }
            
        </Dialog>
    )
}

export default ListarRubrica; 