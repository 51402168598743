import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardHeader, CardContent, Avatar, Grid, Typography, } from '@material-ui/core/';
import { coloresEmma } from '../../global/js/funciones';

const useStyles = makeStyles(theme => ({
   card: {
      maxWidth: "100%",
      minHeight: "80px",
      maxHeight: "340px",
      height: "320px",
   },
   image: {
      width: '100px',
      height: "100px",
      maxWidth: '100px',
      minWidth: '100px',
      objectFit: 'cover',
      objectPosition: 'center center',
   },
   content_img: {
      textAlign: 'center',
      height: "100px",
   },
   content_text: {
      paddingTop: '10px'
   }
}));

export default function ReportesOpciones({ nombre, descripcion, imagen }) {
   const classes = useStyles();


   return (
      <Card className={classes.card}>
         <CardHeader
            avatar={<Avatar aria-label="recipe" style={{ 'backgroundColor': coloresEmma.secondarycolor, 'color': '#FFF' }}> {nombre.charAt(0).toUpperCase()} </Avatar>}
            title={nombre}
         />
         <CardContent>
            <Grid container direction={'row'} justifyContent={'center'}>
               <Grid item xs={12} sm={12} md={12} className={classes.content_img}>
                  <img src={imagen} className={classes.image} alt='Imagen Tipo' />
                  <Typography variant="body2" color="textSecondary" component="p" align='justify' className={classes.content_text}>
                     {descripcion}
                  </Typography>
               </Grid>
            </Grid>
         </CardContent>
      </Card>
   );
}
