
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from "react-redux";

//FUNCIONES GENERALES
import { consulta, mostrarError, obtenerValores } from "../../../global/js/funciones";

//COMPONENTES DEL MODULO
import emma_w from '../../../global/imagenes/emma_w.png';
import ConfirmarAccion from '../../general/ConfirmarAccion';
import TareasTerminadas from '../../general/TareasTerminadas';
import GenericaAgregar from './GenericaAgregar';
import GenericaListar from './GenericaListar';
//ACCIONES DEL MODULO
import { actualizarDataGenerica, actualizarDataGenericas, actualizarRender, mostrarModalAdd, mostrarModalDel, mostrarModalMod } from "../../../redux/actions/actGenericas";
import { actualizarMensaje } from "../../../redux/actions/actGlobal";




class Generica extends Component {

  constructor(props) {
    super(props)
    this.state = {
      cargando: true,
    }
  }

  async componentDidMount() {
    let { consultoria,centro} = this.props
    if(consultoria){
      await this.obtenerGenericasConsultoria();
    }else if(centro){
      await this.obtenerGenericasCentro();
    }else{
      await this.obtenerGenericas();
    }
  }

  async componentDidUpdate({ tipo }) {
    let { tipo: tipoNew } = this.props;
    if (tipoNew !== tipo) {
      await this.obtenerGenericas();
    }
  }
  obtenerGenericasConsultoria = () => {
    this.setState({ cargando: true });
    let { actualizarDataGenericas, actualizarRender, tipo, persona } = this.props;
    consulta(`api/v1.0/consultoria/${persona.id}/genericas/${tipo}`, null, null,
      (error, estado, resp) => {
        if (estado === 200) {
          actualizarDataGenericas(resp);
          actualizarRender(true);
          this.setState({ cargando: false });

        }
      }
    );
  }

  obtenerGenericasCentro = () => {
    this.setState({ cargando: true });
    let { actualizarDataGenericas, actualizarRender, tipo, persona } = this.props;
    consulta(`api/v1.0/centro/${persona.id}/genericas/${tipo}`, null, null,
      (error, estado, resp) => {
        if (estado === 200) {
          actualizarDataGenericas(resp);
          actualizarRender(true);
          this.setState({ cargando: false });

        }
      }
    );
  }

  obtenerGenericas = () => {
    this.setState({ cargando: true });
    let { actualizarDataGenericas, actualizarRender, tipo, persona } = this.props;
    consulta(`api/v1.0/personas/${persona.id}/genericas/${tipo}`, null, null,
      (error, estado, resp) => {
        if (estado === 200) {
          actualizarDataGenericas(resp);
          actualizarRender(true);
          this.setState({ cargando: false });

        }
      }
    );

  }

  guardarGenerica = (relaciones, tipo) => {
    let { actualizarMensaje, mostrarModalAdd, persona, consultoria,centro } = this.props;
    consulta(`api/v1.0/personas/${persona.id}/genericas/crear`, { relaciones, tipo }, "post",
      (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            if(consultoria){
              this.obtenerGenericasConsultoria();
            }else if(centro){
              this.obtenerGenericasCentro();
            }else{
              this.obtenerGenericas();
            }
            mostrarModalAdd(false);
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  }


  eliminarGenerica = id => {
    let { actualizarMensaje, mostrarModalDel, consultoria,centro } = this.props;
    consulta(`api/v1.0/personas/generica/${id}/eliminar`, {}, "put",
      (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            if(consultoria){
              this.obtenerGenericasConsultoria();
            }else if(centro){
              this.obtenerGenericasCentro();
            }else{
              this.obtenerGenericas();
            }
            mostrarModalDel(false);
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  }

  activarNotificaciones = (id, data) => {
    let { actualizarMensaje } = this.props;
    consulta(`api/v1.0/personas/generica/${id}`, data, "patch",
      (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            this.obtenerGenericas(); 
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  }


  render() {
    let {
      genericas,
      actualizarDataGenerica,
      mostrarModalDel,
      modalDel,
      mostrarModalAdd,
      generica,
      modalAdd,
      genericaRelacionId,
      tituloEliminar,
      tipo,
      titulo,
      tituloInput,
      notificaciones,
      consultoria,
      dependenciasInter,
      centro
    } = this.props;
    return (
      <div>
        {!this.state.cargando ?
          <div>
            <ConfirmarAccion mensaje1={`El dato "${generica.nombre}" será eliminado, `} ejecutarAccion={this.eliminarGenerica} mostrarModalConfAccion={mostrarModalDel} modalConAccion={modalDel} dato={generica} titulo={tituloEliminar} />
            <GenericaListar notificaciones={notificaciones} activarNotificaciones={this.activarNotificaciones} mostrarModalAdd={mostrarModalAdd} mostrarModalDel={mostrarModalDel} actualizarDataGenerica={actualizarDataGenerica} genericas={genericas} />
            <GenericaAgregar titulo={titulo} tituloInput={tituloInput} obtenerValores={obtenerValores} modalAdd={modalAdd} mostrarModalAdd={mostrarModalAdd} guardarGenerica={this.guardarGenerica} genericaRelacionId={genericaRelacionId} tipo={tipo} consultoria={consultoria} dependenciasInter={dependenciasInter} centro={centro} />
          </div> : <TareasTerminadas imagen={emma_w} mensaje='Cargando..' widthImg='7%' marginTop='100px' />}
      </div>
    )
  }
}

//CARGO ESTADOS DEL MODULO
const mapStateToProps = (state) => {
  let { generica, render, genericas, modalMod, modalAdd, modalDel } = state.redGenericas;
  return {
    render,
    generica,
    genericas,
    modalMod,
    modalAdd,
    modalDel,
  }
};

//CARGO FUNCIONES DEL MODULO
const mapDispatchToProps = {
  actualizarMensaje,
  actualizarRender,
  actualizarDataGenericas,
  actualizarDataGenerica,
  mostrarModalAdd,
  mostrarModalMod,
  mostrarModalDel,
}

//Props del componente
Generica.propTypes = {
  //variables
  render: PropTypes.bool.isRequired,
  genericas: PropTypes.array.isRequired,
  modalMod: PropTypes.bool.isRequired,
  modalAdd: PropTypes.bool.isRequired,
  modalDel: PropTypes.bool.isRequired,
  generica: PropTypes.object.isRequired,
  tipo: PropTypes.number.isRequired,
  tituloInput: PropTypes.string.isRequired,
  titulo: PropTypes.string.isRequired,
  tituloEliminar: PropTypes.string.isRequired,
  persona: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),

  //funciones
  actualizarMensaje: PropTypes.func.isRequired,
  actualizarRender: PropTypes.func.isRequired,
  actualizarDataGenericas: PropTypes.func.isRequired,
  actualizarDataGenerica: PropTypes.func.isRequired,
  mostrarModalAdd: PropTypes.func.isRequired,
  mostrarModalMod: PropTypes.func.isRequired,
  mostrarModalDel: PropTypes.func.isRequired,
}

Generica.defaultProps = {
  notificaciones: false,
  consultoria:false,
  centro:false,
}


export default connect(mapStateToProps, mapDispatchToProps)(Generica);
