import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import AppBarModal from "../general/AppBarModal";
import PersonaDetalle from "./PersonaDetalle";
import Generica from "./generica/Generica";
import { BtnForm } from "../general/BotonesAccion";
import SchoolIcon from '@material-ui/icons/School';

class Persona extends Component {

  constructor(props) {
    super(props)
    this.state = {
      seleccion: 0,
      maxWidth: 'md',
      genericaRelacionId: 0,
    }
  }

  cambiarOpcion = (seleccion, maxWidth, genericaRelacionId = 0) => {
    this.cambiarEstado({ seleccion, maxWidth, genericaRelacionId })
  }
  cambiarEstado = (estados) => {
    this.setState(estados)
  }

  pintarDetalle = () => {
    let { persona } = this.props;
    return <PersonaDetalle persona={persona} />;
  }

  pintarRoles = () => {
    let { persona } = this.props;
    let { genericaRelacionId } = this.state;
    return <Generica tipo={2} persona={persona} genericaRelacionId={genericaRelacionId} titulo="Nuevo Rol" tituloInput="Roles" tituloEliminar='¿ Eliminar Rol ?' />;
  }

  pintarProgramas = () => {
    let { persona } = this.props;
    let { genericaRelacionId } = this.state;
    return <Generica tipo={3} persona={persona} genericaRelacionId={genericaRelacionId} titulo="Nuevo Programa" tituloInput="Programas" tituloEliminar='¿ Eliminar Programa ?' />;
  }

  pintarPerfiles = () => {
    let { persona } = this.props;
    let { genericaRelacionId } = this.state;
    return <Generica tipo={1} persona={persona} genericaRelacionId={genericaRelacionId} titulo="Nuevo Perfil" tituloInput="Perfiles" tituloEliminar='¿ Eliminar Perfil ?' />;
  }

  pintar = () => {
    let { seleccion } = this.state;
    let render = '';
    if (seleccion === 0) render = this.pintarDetalle();
    else if (seleccion === 1) render = this.pintarRoles();
    else if (seleccion === 2) render = this.pintarPerfiles();
    else if (seleccion === 3) render = this.pintarProgramas();
    return render;
  }

  render() {

    let { seleccion, maxWidth } = this.state;
    let { persona, mostrarModalDetallePersona, modalDetallePersona } = this.props;
    let { primer_nombre, primer_apellido } = persona;
    return (
      <Dialog open={modalDetallePersona} fullWidth={true} maxWidth='sm' onClose={mostrarModalDetallePersona.bind(this, false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <AppBarModal titulo={`${primer_nombre} ${primer_apellido}`} mostrarModal={() => mostrarModalDetallePersona(false)} titulo_accion="" accion="" />
        <Paper square elevation={0}>
          <Tabs
            value={seleccion}
            variant="fullWidth"
            indicatorColor="secondary"
            textColor="secondary"
            aria-label="icon label tabs example"
          >
            <Tab onClick={this.cambiarOpcion.bind(this, 0, 'md')} icon={<ImportContactsIcon />} label="DETALLE" />
            <Tab onClick={this.cambiarOpcion.bind(this, 1, 'md', 1)} icon={<DeviceHubIcon />} label="ROLES" />
            <Tab onClick={this.cambiarOpcion.bind(this, 2, 'md', 3)} icon={<BeenhereIcon />} label="PERFILES" />
            <Tab onClick={this.cambiarOpcion.bind(this, 3, 'md', 5)} icon={<SchoolIcon />} label="PROGRAMAS" />
          </Tabs>
        </Paper>
        <DialogContent style={{ padding: 0 }} className='scroll'>
          {this.pintar()}
        </DialogContent>
        <DialogActions>
          <BtnForm texto="Cerrar" callback={() => mostrarModalDetallePersona(false)} />
        </DialogActions>
      </Dialog>

    );
  }
}

Persona.propTypes = {
  //variables
  modalDetallePersona: PropTypes.bool.isRequired,
  persona: PropTypes.shape({
    id: PropTypes.number.isRequired,
    tipo_identificacion: PropTypes.object.isRequired,
    identificacion: PropTypes.number.isRequired,
    primer_nombre: PropTypes.string.isRequired,
    segundo_nombre: PropTypes.string,
    primer_apellido: PropTypes.string.isRequired,
    segundo_apellido: PropTypes.string,
    correo: PropTypes.string.isRequired,
    usuario: PropTypes.string.isRequired,
  }),
  //funciones
  mostrarModalDetallePersona: PropTypes.func.isRequired,
}

export default Persona;
