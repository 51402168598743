import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { Dialog, DialogContent, DialogActions, FormControl, FormLabel, Paper, Stepper, Step, StepLabel, Grid, TextField, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
// FUNCIONES GENERALES
import { obtenerPermisosValorAlt, obtenerValores } from "../../../global/js/funciones";
import AppBarModal from '../../general/AppBarModal';
import TareasTerminadas from '../../general/TareasTerminadas';
import { BtnForm } from '../../general/BotonesAccion';
// IMAGENES
import emma_w from '../../../global/imagenes/emma_w.png';
// HELPER
import { PREGUNTAS, RESPUESTAS } from "./../helper";


class AutodiagnosticoAgregar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cargando: true,
      preguntas: [],
      respuestas: [],
      activeStep: 0,
      seleccion: '',
      titulo_carg: 'Cargando ..'
    }
  }


  async componentDidUpdate({ modalAgregarAutodiag }, { activeStep }) {
    let { activeStep: activeStepNew } = this.state
    let { modalAgregarAutodiag: modalAgregarAutodiagNew } = this.props

    if (activeStep !== activeStepNew && activeStepNew != 0) this.obtenerRespuestas()

    if (modalAgregarAutodiag !== modalAgregarAutodiagNew) {
      if (modalAgregarAutodiagNew) {
        let preguntas = await obtenerValores(PREGUNTAS)
        preguntas = preguntas.sort((a, b) => a.valora - b.valora).map(e => ({ 'pregunta': e, 'respuestas': [], 'seleccion': '' }))
        if (preguntas.length != 0) {
          let respuestas = await obtenerPermisosValorAlt([{ 'llave': 'principal', 'valor': preguntas[0].pregunta.id }, { 'llave': 'secundario__generica', 'valor': RESPUESTAS }])
          preguntas[0].respuestas = respuestas.sort((a, b) => (b.secundario.valora > a.secundario.valora) ? -1 : (b.secundario.valora > a.secundario.valora) ? 1 : 0)
        }
        this.setState({ preguntas, cargando: false })
      }
      else {
        this.reiniciarEstado();
      }
    }
  }

  async obtenerRespuestas() {
    this.setState({ cargando: true })
    let { activeStep, preguntas } = this.state
    let pregunta = preguntas[activeStep]
    if (pregunta) {
      let respuestas = await obtenerPermisosValorAlt([{ 'llave': 'principal', 'valor': preguntas[activeStep].pregunta.id }, { 'llave': 'secundario__generica', 'valor': RESPUESTAS }])
      pregunta.respuestas = respuestas.sort((a, b) => (b.secundario.valora > a.secundario.valora) ? -1 : (b.secundario.valora > a.secundario.valora) ? 1 : 0)
    }
    this.setState({ preguntas, cargando: false })
  }

  reiniciarEstado() {
    this.setState({
      cargando: true,
      preguntas: [],
      respuestas: [],
      activeStep: 0,
      seleccion: ''
    })
  }

  pintarPregunta = () => {
    let { activeStep, preguntas } = this.state
    let { pregunta: { nombre, id, valorb }, respuestas } = preguntas[activeStep]

    return (
      <Grid item xs={12} sm={12} md={12} >
        <Paper elevation={0} square className="scroll" >
          <FormControl style={{ padding: "10px 0px 0px 10px", width: "95%" }} key={id}>
            <FormLabel style={{ textAlign: 'justify', fontSize: '20px', marginBottom: '15px' }} component="legend">{nombre}</FormLabel>
            {this.pintarRespuestas(valorb, id, respuestas)}
          </FormControl>
        </Paper>
      </Grid>
    )
  }

  pintarRespuestas(tipo, id_pregunta, respuestas) {
    let { seleccion } = this.state
    if (tipo == 'texto') return <TextField style={{ width: '100%', margin: '5px 0px 5px 0px' }} type={"number"} name='seleccion' value={seleccion} placeholder='Digite su respuesta' onChange={this.onChange} />
    else if (tipo == 'textoObserbaciones') return <TextField style={{ width: '100%', margin: '5px 0px 5px 0px' }} type={"text"} name='seleccion' value={seleccion} placeholder='Escriba su respuesta' onChange={this.onChange} />
    else {
      return (
        <RadioGroup name={`${id_pregunta}`} value={seleccion} >
          {respuestas.map(({ secundario }, i) => <FormControlLabel key={i} onClick={() => this.setState({ seleccion: secundario.id })} value={secundario.id} control={<Radio />} label={secundario.nombre} />)}
        </RadioGroup>
      )
    }
  }

  cambiarRespuestaPregunta(respuesta) {    
    let { actualizarMensaje } = this.props
    let { activeStep, preguntas } = this.state;
    let tipoPregunta = preguntas[activeStep].pregunta.valorb
    let tx = ''
    if(tipoPregunta == 'texto') tx = 'Digite'
    else tx = 'Seleccione'

    if (respuesta || (tipoPregunta == 'textoObserbaciones')) {
      let pregunta = preguntas[activeStep]
      if (pregunta) {
        pregunta.seleccion = respuesta;
        this.setState({ seleccion: ''})
      }
      if (activeStep + 1 >= preguntas.length) {
        this.submit()
      } else {
        activeStep = activeStep + 1
      }
      this.setState({ preguntas, activeStep })
    } else {
      actualizarMensaje({ titulo: `${tx} su respuesta`, tipo: 'info', mostrar: true, tiempo: 6000 })
    }
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    // if (value.length <= 10) {
      this.setState({
        [name]: value
      })
    // }
  }

  submit() {
    let { preguntas } = this.state
    let { guardarAutodiagnostico } = this.props
    this.setState({ cargando: true, titulo_carg: 'Enviando..' })
    guardarAutodiagnostico(preguntas, () => this.setState({ cargando: false, titulo_res: 'Cargando..' }));
  }

  render() {
    let { modalAgregarAutodiag, mostrarAgregarAutodiag } = this.props
    let { cargando, activeStep, preguntas, titulo_carg, seleccion } = this.state
    let textoBtn = (activeStep < preguntas.length - 1) ? 'SIGUIENTE' : 'FINALIZAR' 

    return (
      <Dialog fullWidth={true} maxWidth="sm" open={modalAgregarAutodiag} onClose={() => mostrarAgregarAutodiag(false)}>
        <AppBarModal titulo={"¡ Test Autodiagnóstico !"} mostrarModal={() => mostrarAgregarAutodiag(false)} titulo_accion="Cerrar" accion={() => mostrarAgregarAutodiag(false)} />
        <DialogContent className='scroll'>
          {
            !cargando ?
              <Grid container>
                <Grid item xs={12} md={12}>
                  <Stepper activeStep={activeStep}>
                    {
                      preguntas.map((label, i) => (
                        <Step key={i}>
                          <StepLabel></StepLabel>
                        </Step>
                      ))
                    }
                  </Stepper>
                </Grid>
                {this.pintarPregunta()}
              </Grid> :
              <TareasTerminadas mensaje={titulo_carg} marginTop='7%' imagen={emma_w} widthImg="7%" />
          }
        </DialogContent>
        <DialogActions>
          <BtnForm texto={textoBtn} callback={() => this.cambiarRespuestaPregunta(seleccion)} type="submit" />
        </DialogActions>
      </Dialog >

    )
  }
}

AutodiagnosticoAgregar.propTypes = {
  mostrarAgregarAutodiag: PropTypes.func.isRequired,
  modalAgregarAutodiag: PropTypes.bool.isRequired,
  actualizarMensaje: PropTypes.func.isRequired,
  guardarAutodiagnostico: PropTypes.func.isRequired
}

export default AutodiagnosticoAgregar
