// Producción
export const ESTADOS_INSCRIPCIONES = 43; 
export const GRUPOS_INSCRIPCIONES = 44; 
export const DISCAPACIDADES_INSCRIPCIONES = 45; 
export const VALIDACION_DOCUMENTOS_INSCRIPCIONES = 48; 
export const TIPO_INSCRIPCIONES = 99; 
export const LINEAEDU = 100; 
export const JORNADAPREINSCRIPCION = 101; 
export const GENEROS = 69; 
// export const OPCIONES_ESTUDIOS = 102// local2045
export const AUTORIZACION_PREINSCRIPCION = 103;  
export const COLEGIOS_PREUNIVERSITARIO = 104; 
export const CALIFICACION_CONTACTO = 105; 
export const METODO_PAGO = 106; 
export const RESULTADOS_PREUNIVERSITARIO = 107;
export const COSTEAR_ESTUDIOS = 102; 
export const EPS = 267; 
export const EPS_OTRO = 24723; 
export const TIPO_PRIMER_SEMESTRE = 16879 
export const TIPO_HOMOLOGACION = 29522 

// Local
// export const ESTADOS_INSCRIPCIONES = 43;
// export const GRUPOS_INSCRIPCIONES = 44;
// export const DISCAPACIDADES_INSCRIPCIONES = 45;
// export const VALIDACION_DOCUMENTOS_INSCRIPCIONES = 48;
// export const TIPO_INSCRIPCIONES = 99; 
// export const LINEAEDU = 100;
// export const JORNADAPREINSCRIPCION = 101;
// export const GENEROS = 69;
// // // export const OPCIONES_ESTUDIOS = 102// local2045
// export const AUTORIZACION_PREINSCRIPCION = 103; 
// export const COLEGIOS_PREUNIVERSITARIO = 104; 
// export const CALIFICACION_CONTACTO = 105;
// export const METODO_PAGO = 106;
// export const RESULTADOS_PREUNIVERSITARIO = 107; 
// export const COSTEAR_ESTUDIOS = 102;
// export const EPS = 1128; 
// export const EPS_OTRO = 6286;
// export const TIPO_PRIMER_SEMESTRE = 6213
// export const TIPO_HOMOLOGACION = 9531