import React from "react";
import {
  DialogContent,
  Dialog,
  Grid,
  DialogActions,
  TableContainer,
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
} from "@material-ui/core";
import AppBarModal from "../../general/AppBarModal";
import {
  BtnDetalle,
  BtnEditar,
  BtnEliminar,
  BtnForm,
} from "../../general/BotonesAccion";
import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";
import TareasTerminadas from "../../general/TareasTerminadas";
import ExportarRubricasExcel from "./ExportarRubricasExcel";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#c79e32",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

export default function TablasRubricas(props) {
  const Modificar = (fila) => {
    let criterio = {},
      avanzado = {},
      excelente = {},
      minimo = {},
      insuficiente = {},
      satisfactorio = {},
      id = { corte: fila.corte, id: fila.id };
    criterio.valor = fila.criterio;
    props.criterio(criterio);
    avanzado.valor = fila.avanzado;
    props.avanzado(avanzado);
    excelente.valor = fila.excelente;
    props.excelente(excelente);
    minimo.valor = fila.minimo;
    props.minimo(minimo);
    insuficiente.valor = fila.insuficiente;
    props.insuficiente(insuficiente);
    satisfactorio.valor = fila.satisfactorio;
    props.satisfactorio(satisfactorio);
    props.id_actual(id);
    props.EditarModal(true);
  };

  const Eliminar = (fila) => {
    let id = {};
    id.corte = fila.corte;
    id.id = fila.id;
    props.id_actual(id);
    props.Eliminar(true);
  };

  const Detalle = (fila) => {
    props.DetalleObjetivo(fila.id);
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={props.modal}
        onClose={() => props.cerrarModal(false)}
      >
        <AppBarModal
          titulo={props.Titulo}
          titulo_accion="U. Formación"
          mostrarModal={() => props.cerrarModal(false)}
        />
        <DialogContent className="scroll">
          <Grid container spacing={3}>
            <Paper style={{ width: "100%" }}>
              <TableContainer>
                <Toolbar>
                  <Typography
                    style={{ flex: "1 1 100%" , marginTop:"10px"}}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                  ></Typography>
                  {/* <Tooltip title="Nuevo">
                    <IconButton
                      aria-label="Nuevo"
                      color="primary"
                      onClick={() => {
                        props.modalAgregar(true);
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip> */}
                </Toolbar>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  style={{ width: "100%", marginTop:"10px" }}
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell key={1} align={"left"}>
                        Criterios de evaluación
                      </StyledTableCell>
                      <StyledTableCell key={1} align={"left"}>
                        Excelente
                      </StyledTableCell>
                      <StyledTableCell key={1} align={"left"}>
                        Avanzado
                      </StyledTableCell>
                      <StyledTableCell key={1} align={"left"}>
                        {" "}
                        Satisfactorio{" "}
                      </StyledTableCell>
                      <StyledTableCell key={1} align={"left"}>
                        Mínimo
                      </StyledTableCell>
                      <StyledTableCell key={1} align={"left"}>
                        Insuficiente
                      </StyledTableCell>
                      <StyledTableCell key={1} align={"left"}>
                        {" "}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.data_rubrica.length == 0 ? (
                      <TableCell key={1} align={"center"} colSpan={6}>
                        <div className="contenido_modulo">
                          <TareasTerminadas
                            mensaje={"Por el momento no ha sido agregada ninguna nota, agregar la notas correspondientes."}
                            marginTop="7%"
                            imagen={props.imagen}
                            widthImg="7%"
                          />
                        </div>
                      </TableCell>
                    ) : (
                      props.data_rubrica.map((column, item) => {
                        return (
                          <>
                            <TableRow>
                              <TableCell key={column.id} align={"left"}>
                                {column.criterio}
                              </TableCell>
                              <TableCell key={column.id} align={"left"}>
                                {column.excelente}
                              </TableCell>
                              <TableCell key={column.id} align={"left"}>
                                {column.avanzado}
                              </TableCell>
                              <TableCell key={column.id} align={"left"}>
                                {column.satisfactorio}
                              </TableCell>
                              <TableCell key={column.id} align={"left"}>
                                {column.minimo}
                              </TableCell>
                              <TableCell key={column.id} align={"left"}>
                                {column.insuficiente}
                              </TableCell>
                              <TableCell key={column.id} align={"left"}>
                                <BtnDetalle callback={() => Detalle(column)} />
                                <BtnEditar callback={() => Modificar(column)} />
                                <BtnEliminar
                                  callback={() => Eliminar(column)}
                                />
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </DialogContent>
        <DialogActions>
        {/* <Tooltip title="Nuevo" > */}
                    {/* <IconButton
                      aria-label="Nuevo"
                      backgroundColor="#c79c3e"
                      color="primary"
                      onClick={() => {
                        props.modalAgregar(true);
                        this.resetmodalAgregar();
                      }}
                    >
                      <AddIcon />
                    </IconButton> */}
                    {/* <IconButton
                    aria-label="Nuevo"
                    backgroundColor="#c79c3e"
                    color="primary"
                    onClick={() => {
                      props.modalAgregar(true);
                    }}
                  >
                  <AddIcon />
                </IconButton>
                </Tooltip>  */}
          <BtnForm texto="Nuevo" callback={() => props.modalAgregar(true)} />      
          <BtnForm texto="CANCELAR" callback={() => props.cerrarModal(false)} />
          <ExportarRubricasExcel data={props.data_rubrica} nombre={`Rubrica`} />
        </DialogActions>
        
      </Dialog>
    </div>
  );
}
