// Import React
import React, { Component } from 'react';
// Import Material UI
import { Dialog, DialogActions, DialogContent, FormControl, Grid,
  Step, StepLabel, Stepper, InputLabel, Select, MenuItem, TextField,
  List, Paper, FormHelperText, withStyles, IconButton, InputAdornment,
  FormControlLabel, Checkbox, TableCell, TableRow, TableBody, TableHead,
  Table, TableContainer, ListItemText, Link, ListItem } from '@material-ui/core';
  import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
// Json Monedas
import { CURRENCIES } from '../currencies';
// Import general
import AppBarModal from '../../general/AppBarModal';
import TareasTerminadas from '../../general/TareasTerminadas';
import InputFile from '../../general/InputFile';
import EnviarMultiplesArchivos from '../../general/EnviarMultiplesArchivos';
import ConfirmarAccion from '../../general/ConfirmarAccion';
import BuscarPersona from '../../general/BuscarPersona';
import Detalle from '../../general/Detalle';
import emma_w from '../../../global/imagenes/emma_w.png';
import ListarDatos from '../../general/ListarDatos';
// Botones Accion
import { BtnCancelar, BtnCargar, BtnDescargar, BtnDetalle, BtnEditar, BtnForm } from '../../general/BotonesAccion';
// HELPER
import { MODALIDADES, TIPOS_ACTIVIDADES, SUBTIPOS_ACTIVIDADES, DPTOS_ACADEMICOS, PROGRAMAS, UNIDADES_ADMINISTRATIVAS,
  PLATAFORMAS, TIPOS_FINANCIACIONES, FACULTADES, TIPOS_MOVILIDADES, TIPO_FINANCIACION_INSTITUCIONAL, UNIVERSIDADES,
  NIVELES_EDUCATIVOS, FACULTAD_POSGRADOS, DEPENDENCIAS_FINANCIACIONES, CODIGOS_MODALIDADES_VIRTUALES, CODIGO_TIPO_ACTIVIDAD_OTRO,
  CODIGO_PLATAFORMA_OTRO, NIVEL_EDUCATIVO_PREGRADO, FUENTES_FINANCIACIONES, TIPOS_IDENTICACION, TIPO_MOVILIDAD_ENTRANTE,
  NIVEL_EDUCATIVO_ESPECIALIZACION, NIVEL_EDUCATIVO_MAESTRIA, NIVEL_EDUCATIVO_DOCTORADO, TIPO_MOVILIDAD_ESTUDIANTES,
  TIPO_MOVILIDAD_ESTUDIANTE_SALIENTE, TIPOS_ACTIVIDADES_HORAS, VALORES_MODALIDAD_VIRSINCRONICA, VALORES_MODALIDAD_VIRASINCRONICA,
  VALORES_MODALIDAD_PRESENCIAL, CODIGOS_DURACION_ACTIVIDADES, CODIGO_SUBTIPO_ACTIVIDAD_OTRO, CODIGOS_SUBTIPOS_ACTIVIDADES,
  TIPOS_CONCEPTOS, VALORES_MODALIDAD_VIRTUAL, CODIGO_TIPO_CONCEPTO_OTRO, TIPOS_RESPONSABLES, TIPO_MOVILIDAD_SALIENTE } from '../administrar/helper';
// Funciones globales
import { api, crear_form_data, formulario, getError, mostrarError, obtenerMisDatos, obtenerPermisosValorAlt, obtenerValores, obtenerValoresFiltros, validateInputs } from '../../../global/js/funciones';
// --
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import ConvocatoriasBuscarConvenio from '../convocatorias/ConvocatoriasBuscarConvenio';
import ParticipanteExtCrear from './ParticipanteExtCrear';
// import ParticipanteExtMas from './ParticipanteExtMas';
import EnviarArchivo from "../../general/EnviarArchivo";
import CheckboxIndeterminado from "../../general/CheckboxIndeterminado";
import plantilla_agr_part_ext_entr from "../../../global/archivos/plantillas/agregar_participantes_externos_entrante_v3.xlsm";
import plantilla_agr_part_ext_sal from "../../../global/archivos/plantillas/agregar_participantes_externos_saliente_v2.xlsm";
// import { CheckBox } from '@material-ui/icons';


const configStep = [ 'Información inicial', 'Académica', 'Financiación', 'Participantes', 'Evidencias' ];

const useStyles = () => ({
  flexCenter: {
    textAlign: "center",
    marginTop: "5px"
  },
  colorEmmma: {
    color: "#c79e32",
    transition: 'all 0.3s',
    '&:hover': {
      color: "#fff",
      backgroundColor: "#c79e32",
      transform: "scale(1.3)",
    },
  },
  colorDelete: {
    color: "#d11a2a",
    transition: "all 0.3s",
    '&:hover': {
      color: "#fff",
      backgroundColor: "#d11a2a",
      transform: "scale(1.3)",
    },
  }
})

class RegistrosCrear extends Component {
  constructor(props) {
    super(props);
    this.state = {
      borrador_activo: false,
      buscar: false,
      cargando: false,
      check_masivo: false,
      datos_participante: null,
      fecha_final: null,
      fecha_inicio: null,
      modal_detalle_participante: false,
      modal_buscar_institucion: false,
      modal_editar_participante: false,
      modal_detalle_financiacion: false, //new
      modal_quitar_financiacion: false, //new
      modal_inicio_borrador: false,
      modal_quitar_institucion: false,
      modal_quitar_participante: false,
      modal_crear_participante: false,
      modal_agregar_participantes_masivo: false,
      participante_estudiante: false,
      participante_tipo: false,
      modalMasivo: false,
      sw_horas: false,
      archivo_resultados: '',
      certificados_participantes: '',
      tipo_financiacion: '',
      fuente_financiacion: '',
      entidad: '',
      dependencia: '',
      tipo_moneda: '',
      valor_cop: '',
      valor_ext: '',
      clasificacion_de_financiacion: '',
      tipo_de_concepto: '',
      tipos_financiaciones: [],
      fuentes_financiaciones: [],
      dependencias: [],
      monedas: [],
      clasificaciones_de_financiaciones: [],
      descripcion_actividad: '',
      enlace_grabacion: '',
      es_financiado: '',
      institucion_seleccionada: '',
      modalidad: '',
      otro_tipo_actividad: '',
      otra_plataforma_utilizada: '',
      nivel_educativo: '',
      plataforma_utilizada: '',
      subtipo_actividad: '',
      otro_subtipo_actividad: '',
      resultados_descripcion: '',
      info_complementaria: '',
      tipo_actividad: '',
      tipo_movilidad: '',
      tit_cargando: 'Cargando..',
      titulo_tema: '',
      tipo_participante: '',
      programa_participante: '',
      nivel_participante: '',
      dpto_participante: '',
      facultades_departamentos: [],
      errores: [],
      evidenciasAdjuntos: [],
      departamento_sel: [],
      facultades: [],
      dptos_academicos: [],
      institucion_participante: { id: 0, nombre: '', pais: '', ciudad: '' },
      instituciones: [],
      lista_evidencias: [],
      modalidad_escogida: {},
      modalidades: [],
      niveles_educativos: [],
      participante_externo: {
        primer_nombre: '',
        segundo_nombre: '',
        primer_apellido: '',
        segundo_apellido: '',
        tipo_identificacion: '',
        numero_identificacion: '',
        nacionalidad: '',
        correo: '',
        indicativo_celular: '',
        celular: ''
      },
      participante_seleccionado: {
        id: 0,
        nombre_completo: '',
        identificacion: '',
        academica: [0, 0, 0, 0],
        formulario: 0,
        tipo_participante: '',
        dpto_receptor: 0,
      },
      participantes: [],
      plataformas: [],
      facultad_sel: [],
      programas: [],
      programas_receptores: [],
      dependencias_receptor: [],
      solicitud_iniciada: {},
      tipos_actividades: [],
      subtipos_actividades: [],
      tipos_conceptos: [],
      tipos_movilidades: [],
      ud_administrativa_sel: [],
      uds_administrativas: [],
      activeStep: 0,
      cantidad_financiaciones: 0,
      horas: '',
      registro_iniciado: 0,
      programas_receptores_docente: [],
      financiaciones: [],
      arrayselelctipo:[],
      backtiporesponsable:[],
      comprobante_financiacion: '',
      financiacion_seleccionada:'',
      seleccionados: {}
    }
    this.fileInput = React.createRef();
  }

  async componentDidUpdate({ modalAddRegistro, cancelo_solicitud, modalAddFinanciaciones, modal_crear_participante, modal_agregar_participantes_masivo }, { modalidad, tipo_actividad,
    facultad_participante, es_financiado, nivel_participante, tipo_movilidad, participante_seleccionado, programa_participante, plataforma_utilizada,
    subtipo_actividad, tipo_financiacion, dpto_participante, participante_externo }) {
    let { modalAddRegistro: modalAddRegistroNew, obtenerListaEvidencias, cancelo_solicitud: cancelo_solicitud_new,
      modalAddFinanciaciones: modalAddFinanciaciones_new } = this.props;
    let { modalidad: modalidad_new, modalidades, nivel_participante: nivel_participante_new, es_financiado: es_financiado_new,
      cantidad_financiaciones, programa_participante: programa_participante_new, niveles_educativos, tipo_movilidad: tipo_movilidad_new,
      tipo_actividad: tipo_actividad_new, plataforma_utilizada: plataforma_utilizada_new, facultad_participante: facultad_participante_new,
      participante_seleccionado: participante_seleccionado_new, programas_receptores, subtipo_actividad: subtipo_actividad_new,
      dpto_participante: dpto_participante_new, tipo_financiacion: tipo_financiacion_new, modal_crear_participante: modal_crear_participante_new, modal_agregar_participantes_masivo: modal_agregar_participantes_masivo_new } = this.state;
    if (modalAddRegistro !== modalAddRegistroNew) {
      if (modalAddRegistroNew) {
        this.setState({ cargando: true });
        let facultades = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': FACULTADES },{ 'llave': 'estado', 'valor': 1 }]);
        let modalidades = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': MODALIDADES }]);
        let plataformas = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': PLATAFORMAS }]);
        let dptos_academicos = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': DPTOS_ACADEMICOS }, { 'llave': 'estado', 'valor': 1 }]);
        let backtiporesponsable = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_RESPONSABLES }, { 'llave': 'estado', 'valor': 1 }]);
        let tipos_financiaciones = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_FINANCIACIONES }]);
        let tipos_movilidades = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_MOVILIDADES }]);
        let uds_administrativas = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': UNIDADES_ADMINISTRATIVAS }, { 'llave': 'estado', 'valor': 1 }]);
        let instituciones_todas = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': UNIVERSIDADES }, { 'llave': 'estado', 'valor': 1 }]);
        let niveles_educativos = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': NIVELES_EDUCATIVOS }]);
        // let fuentes_financiaciones = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': FUENTES_FINANCIACIONES }]);
        let clasificaciones_de_financiaciones = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_CONCEPTOS }]);
        let dependencias = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': DEPENDENCIAS_FINANCIACIONES }]);
        let programas_pregrado = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': PROGRAMAS }, { 'llave': 'valorc', 'valor': 'pregrado' }, { 'llave': 'estado', 'valor': 1 }]);
        let programas_posgrado = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': PROGRAMAS }, { 'llave': 'valorc', 'valor': 'posgrado' }, { 'llave': 'estado', 'valor': 1 }]);
        let tipos_identificacion = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_IDENTICACION }]);
        let monedas = CURRENCIES;
        let facultades_departamentos = dptos_academicos.concat(facultades);
        let programas_receptores = programas_pregrado.concat(programas_posgrado);
        this.cargarEstados({ facultades, dptos_academicos, instituciones_todas, modalidades,
          plataformas, tipos_movilidades, niveles_educativos, tipos_identificacion,
          uds_administrativas, facultades_departamentos, programas_receptores,
          backtiporesponsable, tipos_financiaciones,
          clasificaciones_de_financiaciones, dependencias, monedas });
        this.obtenerDatosIniciales();
      }
    }
    if (modalAddFinanciaciones !== modalAddFinanciaciones_new){
      this.setState({
        tipo_financiacion: '',
        fuente_financiacion: '',
        entidad: '',
        dependencia: '',
        tipo_moneda: '',
        valor_cop: '',
        valor_ext: '',
        clasificacion_de_financiacion: '',
        tipo_de_concepto: '',
        comprobante_financiacion: ''
      })
    }
    if (es_financiado != es_financiado_new){
      if(es_financiado_new == "1"){
        this.props.mostrarModalAddFinanciaciones(true);
      }
    }
    if (tipo_financiacion_new !== tipo_financiacion) {
      if(tipo_financiacion_new){
        this.setState({ cargando: true });
        let fuentes_financiaciones = await obtenerPermisosValorAlt([{ 'llave': 'principal', 'valor': tipo_financiacion_new }, { 'llave': 'secundario__generica', 'valor': FUENTES_FINANCIACIONES }]);
        this.setState({fuentes_financiaciones, cargando: false})
      }
    }
    if (dpto_participante!=dpto_participante_new){
      let programas_receptores_docente = await obtenerPermisosValorAlt([{ 'llave': 'principal', 'valor': dpto_participante_new }, { 'llave': 'secundario__generica', 'valor': 5 }]);
      this.setState({programas_receptores_docente})
    }
    if (tipo_movilidad !== tipo_movilidad_new || modalidad !== modalidad_new) {
      if (tipo_movilidad_new && modalidad_new) {
        this.setState({ cargando: true });
        let sw = false;
        let actividades_movilidad = await obtenerPermisosValorAlt([{ 'llave': 'principal', 'valor': tipo_movilidad_new }, { 'llave': 'secundario__generica', 'valor': TIPOS_ACTIVIDADES }]);
        let clasificaciones_movilidad = await obtenerPermisosValorAlt([{ 'llave': 'principal', 'valor': tipo_movilidad_new }, { 'llave': 'secundario__generica', 'valor': TIPOS_CONCEPTOS }]);
        let modalidad_escogida = modalidades.find(mod => mod.id == modalidad_new);
        let lista_evidencias = await obtenerListaEvidencias(modalidad_escogida.codigo);
        let filtro = modalidad_escogida.codigo === 'Mod_Pre_Int' ? VALORES_MODALIDAD_PRESENCIAL : modalidad_escogida.codigo === 'Mod_Vir_Sin' ? VALORES_MODALIDAD_VIRSINCRONICA : VALORES_MODALIDAD_VIRASINCRONICA; // Array para comparar con el valord del tipo de actividades en la siguiente linea.
        let tipos_actividades = actividades_movilidad.filter(relacion => filtro.includes(relacion.secundario.valord)).map(relacion => relacion.secundario); // Trae tipos de actividades asociados al tipo de movilidad y a la modalidad seleccionada.
        if (tipo_actividad_new) tipos_actividades.forEach(tipo => { if(tipo.codigo === tipo_actividad_new) sw = true; }); // Verifica si el tipo de actividad seleccionada hace parte de los tipos de actividades seleccionables
        let filtro2 = modalidad_escogida.codigo === 'Mod_Pre_Int' ? VALORES_MODALIDAD_PRESENCIAL : modalidad_escogida.codigo === 'Mod_Vir_Sin' ? VALORES_MODALIDAD_VIRTUAL : VALORES_MODALIDAD_VIRTUAL;
        let tipos_conceptos = clasificaciones_movilidad.filter(relacion => filtro2.includes(relacion.secundario.valorg)).map(relacion => relacion.secundario);
        if (TIPO_MOVILIDAD_ESTUDIANTES.includes(tipo_movilidad_new)) {
          this.setState({ participante_tipo: true, tipo_participante: 'Estudiante' });
        } else {
          this.setState({ participante_tipo: false, tipo_participante: '', facultad_participante: '',
            nivel_participante: '', programa_participante: '' });
        }
        this.setState({ tipos_conceptos, tipos_actividades, actividades_movilidad, tipo_actividad: !sw ? '' : tipo_actividad_new, lista_evidencias,
          modalidad_escogida, cargando: false }); // Limpia campo de programa si no hace parte de la facultad o dept seleccionado.
      }
    }
    if (tipo_actividad_new !== tipo_actividad) {
      if (tipo_actividad_new) {
        this.setState({ cargando: true });
        let sw_horas = false;
        if (TIPOS_ACTIVIDADES_HORAS.includes(tipo_actividad_new)) {
          sw_horas = true;
          this.setState({ sw_horas });
        }
        let subtipos_actividades = await obtenerPermisosValorAlt([{ 'llave': 'principal__codigo', 'valor': tipo_actividad_new }, { 'llave': 'secundario__generica', 'valor': SUBTIPOS_ACTIVIDADES }]);
        // let subtipos_actividades = subtipos_tipos_actividades.filter(relacion => filtro.includes(relacion.secundario.valord)).map(relacion => relacion.secundario);
        this.setState({subtipos_actividades, cargando: false})
      }
    }
    if (facultad_participante !== facultad_participante_new || nivel_participante !== nivel_participante_new){
      if (facultad_participante_new && nivel_participante_new && TIPO_MOVILIDAD_ESTUDIANTES.includes(tipo_movilidad_new)) {
        let sw = false, programas_receptores = [];
        this.setState({ cargando: true });
        let permisos_programas = await obtenerPermisosValorAlt([{ 'llave': 'principal', 'valor': facultad_participante_new }, { 'llave': 'secundario__generica', 'valor': PROGRAMAS }]);
        if ((facultad_participante_new != FACULTAD_POSGRADOS && nivel_participante_new != NIVEL_EDUCATIVO_PREGRADO) ||
            (facultad_participante_new == FACULTAD_POSGRADOS && nivel_participante_new == NIVEL_EDUCATIVO_PREGRADO)) {
          this.setState({ nivel_participante: '', programas_receptores: [] });
        } else {
          let nivel = niveles_educativos.find(({ id }) => id == nivel_participante_new).nombre.toLowerCase();
          if (nivel && nivel == 'pregrado') programas_receptores = permisos_programas.filter(programa => programa.secundario.valorc == nivel).map(programa => programa.secundario); // Trae programas asociados a la facultad y al nivel educativo seleccionado (pregrado).
            else programas_receptores = permisos_programas.filter(programa => programa.secundario.valord == nivel).map(programa => programa.secundario); // Trae programas asociados a la facultad y al nivel educativo seleccionado (posgrados).
          if(programa_participante_new) programas_receptores.forEach(programa => { if(programa.id === programa_participante_new) sw = true; }); // Verifica si el programa seleccionado hace parte de la facultad seleccionada
          this.setState({ programas_receptores });
        }
        this.setState({ programa_participante_new: !sw ? '' : programa_participante_new, cargando: false }); // Limpia campo de programa si no hace parte de la facultad seleccionada.
      }
    }
    if (cancelo_solicitud !== cancelo_solicitud_new) {
      if (cancelo_solicitud_new) this.reiniciarEstados(true);
    }
    if (es_financiado !== es_financiado_new) {
      if (es_financiado_new) {
        if (es_financiado_new == '1' && cantidad_financiaciones == 0) this.setState({ cantidad_financiaciones: 1 });
          else if (es_financiado_new == '0') this.setState({ cantidad_financiaciones: 0 });
      }
    }
    if (participante_seleccionado.id !== participante_seleccionado_new.id) {
      if (participante_seleccionado_new && tipo_movilidad_new == TIPO_MOVILIDAD_ESTUDIANTE_SALIENTE){
        let datos_participante = await obtenerMisDatos(participante_seleccionado_new.id);
        if (datos_participante.persona.es_estudiante === "1") {
          let programas_receptores = datos_participante.programas.map(({ relacion }) => relacion);
          this.setState({ participante_estudiante: true, programas_receptores });
        } else {
          this.setState({ participante_estudiante: false, programas_receptores: [] });
        }
      }
      this.setState({ errores: [] });
    }
    if (programa_participante !== programa_participante_new) {
      if (programa_participante_new) {
        let programa = programas_receptores.filter(({ id }) => id == programa_participante_new);
        if (programa[0]) {
          if (programa[0].valorc == 'pregrado') nivel_participante = NIVEL_EDUCATIVO_PREGRADO;
            else if (programa[0].valord == 'especializacion') nivel_participante = NIVEL_EDUCATIVO_ESPECIALIZACION;
            else if (programa[0].valord == 'maestria') nivel_participante = NIVEL_EDUCATIVO_MAESTRIA;
            else if (programa[0].valord == 'doctorado') nivel_participante = NIVEL_EDUCATIVO_DOCTORADO;
        }
        this.setState({ nivel_participante });
      }
    }
    // El escoger la opcion Otra en el campo plataforma utilizada se borra la informacion que tenga
    if (plataforma_utilizada !== plataforma_utilizada_new) {
      if (CODIGO_PLATAFORMA_OTRO == plataforma_utilizada) {
        this.setState({ otra_plataforma_utilizada: '' });
      }
    }
    // El escoger la opcion Otra en el campo tipo de actividad se borra la informacion que tenga
    if (tipo_actividad !== tipo_actividad_new) {
      if (CODIGO_TIPO_ACTIVIDAD_OTRO == tipo_actividad) {
        this.setState({ otro_tipo_actividad: '' });
        this.setState({ otro_subtipo_actividad: '' });
      }
      if (CODIGOS_DURACION_ACTIVIDADES.includes(tipo_actividad)) {
        this.setState({ horas: '' });
      }
    }
    // El escoger la opcion Otra en el campo subtipo de actividad se borra la informacion que tenga
    if (subtipo_actividad !== subtipo_actividad_new) {
      if (CODIGO_SUBTIPO_ACTIVIDAD_OTRO == subtipo_actividad) {
        this.setState({ otro_subtipo_actividad: '' });
      }
    }
  }

  pintarTiposSeleccionado = (selected,back) => {
    let resp = [];
    // let { facultades_departamentos } = this.state;
    selected.map((element) => {
      back.map((e) => {
        if (element === e.id) return resp.push(e.nombre)
        return null;
      })
      return null;
    });
    return resp.join(", ")
  }
  rendertiposresponsable = (selected,back) => {
    let resp = [];
    // let { backtiporesponsable } = this.state;
    selected.map((element) => {
      back.map((e) => {
        if (element === e.codigo) return resp.push(e.nombre)
        return null;
      })
      return null;
    });
    return resp.join(", ")
  }
  async cargarDatosBorrador(datos) {
      let { descripcion_actividad, departamento_resp, fecha_final, fecha_inicio, horas, modalidad, paso_actual, plataforma_utilizada,
        resultados_descripcion, solicitud, tipo_movilidad, tipo_actividad, otro_tipo_actividad, titulo_tema,cantidad_financiaciones,
        unidades_adm_resp, id, otra_plataforma_utilizada, subtipo_actividad, otro_subtipo_actividad, es_financiado, facultad_resp,
        dpto_fac_uniadmin, instituciones_de_convenio } = datos;
    let { obtenerListaEvidencias, obtenerAdjuntos } = this.props;
    let lista_evidencias = modalidad ? await obtenerListaEvidencias(modalidad.codigo) : [];
    let evidenciasAdjuntos = solicitud ? await obtenerAdjuntos(solicitud.id) : [];
    let backtiporesponsable = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_RESPONSABLES }, { 'llave': 'estado', 'valor': 1 }]);
    let guardarid=[]
    backtiporesponsable.map(({codigo,id})=>{
      dpto_fac_uniadmin.map((idarray)=>{

        if(idarray === id){
          guardarid.push(codigo);
        }
      })

    })
    this.setState({
      borrador_activo: true,
      activeStep: paso_actual,
      cantidad_financiaciones,
      solicitud_iniciada: solicitud,
      registro_iniciado: id,
      tipo_movilidad: tipo_movilidad ? tipo_movilidad.id : '',
      instituciones: instituciones_de_convenio ? instituciones_de_convenio: [],
      tipo_actividad: tipo_actividad ? tipo_actividad.codigo : '',
      otro_tipo_actividad: otro_tipo_actividad ? otro_tipo_actividad : '',
      subtipo_actividad: subtipo_actividad ? subtipo_actividad.codigo : '',
      otro_subtipo_actividad: otro_subtipo_actividad ? otro_subtipo_actividad : '',
      horas: horas ? horas : '',
      modalidad: modalidad ? modalidad.id : '',
      modalidad_escogida: modalidad ? modalidad : '',
      plataforma_utilizada: plataforma_utilizada ? plataforma_utilizada.codigo : '',
      otra_plataforma_utilizada: otra_plataforma_utilizada ? otra_plataforma_utilizada : '',
      fecha_inicio: fecha_inicio ? moment(fecha_inicio, 'YYYY-MM-DD') : null,
      fecha_final: fecha_final ? moment(fecha_final, 'YYYY-MM-DD') : null,
      departamento_sel: departamento_resp ? departamento_resp : [],
      facultad_sel: facultad_resp ? facultad_resp : [],
      arrayselelctipo: guardarid ? guardarid : [],
      ud_administrativa_sel: unidades_adm_resp ? unidades_adm_resp : [],
      titulo_tema: titulo_tema ? titulo_tema : '',
      descripcion_actividad: descripcion_actividad ? descripcion_actividad : '',
      resultados_descripcion: resultados_descripcion ? resultados_descripcion : '',
      es_financiado: es_financiado ? es_financiado : '',
      lista_evidencias,
      evidenciasAdjuntos,
      participantes: await this.obtenerDataParticipantes(id),
      financiaciones: await this.obtenerDataFinanciaciones(id),
    });
  }

  // participante
  obtenerDataParticipantes = async registro => {
    let { obtenerParticipantesActividad } = this.props;
    let data = await obtenerParticipantesActividad(registro);
    let p = [];
    if(data) {
      data.map(({ id, participante, institucion_externa, facultad, nivel_estudio, programa, formulario, tipo_participante, dpto_academico }) => {
        p.push({ id,
          nombre_completo: `${participante.primer_nombre} ${participante.segundo_nombre} ${participante.primer_apellido} ${participante.segundo_apellido}`,
          identificacion: participante.identificacion,
          academica: [institucion_externa, facultad, nivel_estudio, programa], // informacion academica relacionada con el participante
          formulario,
          tipo_participante,
          dpto_receptor: dpto_academico });
      });
    }
    return p;
  }

  // Crea participante externo
  guardarDatosParticipanteExterno = async () => {
    let { guardarParticipanteExterno, actualizarMensaje } = this.props;
    let { participante_externo: { primer_nombre, segundo_nombre, primer_apellido, segundo_apellido, tipo_identificacion,
      numero_identificacion, nacionalidad, correo, indicativo_celular, celular }, registro_iniciado } = this.state;
    let errores = await validateInputs([
      { value: primer_nombre, name: 'primer_nombre', err: { empty: true } },
      { value: primer_apellido, name: 'primer_apellido', err: { empty: true } },
      // { value: segundo_apellido, name: 'segundo_apellido', err: { empty: true } },
      { value: tipo_identificacion, name: 'tipo_identificacion', err: { empty: true } },
      { value: numero_identificacion, name: 'numero_identificacion', err: { empty: true } },
      { value: nacionalidad, name: 'nacionalidad', err: { empty: true } },
      // { value: correo, name: 'correo', err: { email: true } },
      { value: indicativo_celular, name: 'indicativo_celular', err: { empty: false } },
      { value: celular, name: 'celular', err: { empty: false } },
    ]);
    const cambiarDatosParticipante = async () => this.setState({ participantes: await this.obtenerDataParticipantes(registro_iniciado) });
    this.setState({ errores });
    if (errores.length === 0) {
      this.setState({ cargando: true, tit_cargando: 'Guardando..' });
      correo = correo == "" || null ? 'participante_ext_'+numero_identificacion+'@cuc.edu.co' : correo;
      let data = { primer_nombre, segundo_nombre, primer_apellido, segundo_apellido, tipo_identificacion,
        identificacion: numero_identificacion, nacionalidad, correo, indicativo_celular, celular };
      guardarParticipanteExterno(registro_iniciado, data, () => cambiarDatosParticipante());
      this.setState({ modal_crear_participante: false, tit_cargando: 'Cargando..', cargando: false });
    } else actualizarMensaje({ titulo: 'Debes llenar todos los campos.', tipo: 'info', mostrar: true, tiempo: 6000 });
  }

  async guardarDatosParticipanteRegistro() {
    let { modificarParticipanteRegistro, actualizarMensaje } = this.props;
    let { participante_seleccionado: { id }, institucion_participante, programa_participante,
      facultad_participante, nivel_participante, participantes, check_masivo, dpto_participante,
      tipo_movilidad, tipo_participante, registro_iniciado } = this.state;
    // Acualiza la información de la lista de participantes mostrada y del participante modificado.
    const cambiarDatosParticipante = async () => this.setState({ participantes: await this.obtenerDataParticipantes(registro_iniciado) });
    let errores = []
    if(TIPO_MOVILIDAD_ESTUDIANTES.includes(tipo_movilidad)) {
      errores = await validateInputs([
        { value: institucion_participante.institucion, name: 'institucion_participante', err: { empty: true } },
        { value: tipo_participante, name: 'tipo_participante', err: { empty: true } },
        { value: facultad_participante, name: 'facultad_participante', err: { empty: true } },
        { value: nivel_participante, name: 'nivel_participante', err: { empty: true } },
        { value: programa_participante, name: 'programa_participante', err: { empty: true } },
      ]);
    } else {
      errores = await validateInputs([
        { value: institucion_participante.institucion, name: 'institucion_participante', err: { empty: true } },
        { value: tipo_participante, name: 'tipo_participante', err: { empty: true } },
        { value: dpto_participante, name: 'dpto_participante', err: { empty: true } },
        { value: programa_participante, name: 'programa_participante', err: { empty: true } },
      ]);
    }
    this.setState({ errores });
    if (errores.length === 0) {
      this.setState({ cargando: true, tit_cargando: 'Enviando..' });
      let data = {
        institucion_externa: institucion_participante ? institucion_participante.id : 0,
        tipo_participante,
        facultad: facultad_participante,
        dpto_academico: dpto_participante,
        nivel_estudio: nivel_participante,
        programa: programa_participante
      }
      if (check_masivo) {
        participantes.forEach(({ id }) => modificarParticipanteRegistro(id, data, () => cambiarDatosParticipante()));
      } else modificarParticipanteRegistro(id, data, () => cambiarDatosParticipante());
      this.setState({ modal_editar_participante: false, tit_cargando: 'Cargando..', cargando: false });
    } else actualizarMensaje({ titulo: 'Debes llenar todos los campos.', tipo: 'info', mostrar: true, tiempo: 6000 });
  }

  // Financiación
  obtenerDataFinanciaciones = async registro => {
    let { obtenerFinanciacionesActividad } = this.props;
    let data = await obtenerFinanciacionesActividad(registro);
    let p = [];
    if(data) {
      data.map((financiacion) => {
        p.push({
          id: financiacion.id,
          tipo_financiacion: financiacion.tipo_financiacion,
          fuente_financiacion: financiacion.fuente_financiacion,
          entidad: financiacion.entidad,
          dependencia: financiacion.dependencia,
          tipo_moneda: financiacion.tipo_moneda,
          valor_cop: financiacion.valor_cop,
          valor_ext: financiacion.valor_ext,
          clasificacion_de_financiacion: financiacion.clasificacion_de_financiacion,
          tipo_de_concepto: financiacion.tipo_de_concepto,
          comprobante_financiacion: financiacion.comprobante_financiacion,
          });
      });
    }
    return p;
  }

  // Crea financiacion
  guardarDatoFinanciacion = async (archivo) => {
    let { agregarFinanciacionRegistro, actualizarMensaje } = this.props;
    let {  tipo_financiacion, fuente_financiacion, entidad, dependencia,
      tipo_moneda, valor_cop, valor_ext, clasificacion_de_financiacion, tipo_de_concepto, registro_iniciado } = this.state;
    let registro=registro_iniciado
    let errores = await validateInputs([
      { value: tipo_financiacion, name: 'tipo_financiacion', err: { empty: true } },
      // { value: fuente_financiacion, name: 'fuente_financiacion', err: { empty: false } },
      // { value: entidad, name: 'entidad', err: { empty: false } },
      // { value: dependencia, name: 'dependencia', err: { empty: false } },
      // { value: tipo_moneda, name: 'tipo_moneda', err: { empty: false } },
      { value: valor_cop, name: 'valor_cop', err: { empty: true } },
      // { value: valor_ext, name: 'valor_ext', err: { email: false } },
      // { value: clasificacion_de_financiacion, name: 'clasificacion_de_financiacion', err: { empty: true } },
      // { value: tipo_de_concepto, name: 'tipo_de_concepto', err: { empty: false } },
    ]);
    if (tipo_financiacion.length == 0) errores.push({ llave: 'tipo_financiacion', 'mensaje': `Este campo es obligatorio` });
      else if (tipo_financiacion == TIPO_FINANCIACION_INSTITUCIONAL) {
        if (dependencia.length == 0) errores.push({ llave: 'dependencia', 'mensaje': `Este campo es obligatorio` });
      } else if (tipo_financiacion != TIPO_FINANCIACION_INSTITUCIONAL) {
        if (fuente_financiacion.length == 0) errores.push({ llave: 'fuente_financiacion', 'mensaje': `Este campo es obligatorio` });
        if (entidad.length == 0) errores.push({ llave: 'entidad', 'mensaje': `Este campo es obligatorio` });
      }
    if (clasificacion_de_financiacion == CODIGO_TIPO_CONCEPTO_OTRO) {
      if (tipo_de_concepto.length == 0) errores.push({ llave: 'tipo_de_concepto', 'mensaje': `Este campo es obligatorio` });
    }
    if (valor_cop.length == 0) errores.push({ llave: 'valor_cop', 'mensaje': `Este campo es obligatorio` });
    if (valor_cop < 0) errores.push({ llave: 'valor_cop', 'mensaje': `El valor está fuera del rango permitido` });
    if (valor_cop < 0) errores.push({ llave: 'valor_cop', 'mensaje': `El valor está fuera del rango permitido` });
    if (clasificacion_de_financiacion.length == 0) errores.push({ llave: 'clasificacion_de_financiacion', 'mensaje': `Este campo es obligatorio` });
    if (valor_ext && valor_ext < 1) errores.push({ llave: 'valor_ext', 'mensaje': `El valor está fuera del rango permitido` });
    if (valor_ext && tipo_moneda.length == 0) errores.push({ llave: 'tipo_moneda', 'mensaje': `Este campo es obligatorio si pone valor` });
    if (tipo_moneda && valor_ext.length == 0) errores.push({ llave: 'valor_ext', 'mensaje': `Este campo es obligatorio si escoge moneda` });

    const cambiarDatosFinanciacion = async () => {
      this.props.mostrarModalAddFinanciaciones(false);
      this.setState({ financiaciones: await this.obtenerDataFinanciaciones(registro_iniciado) })
  }
    this.setState({ errores });
    if (errores.length === 0) {
      this.setState({ cargando: true, tit_cargando: 'Guardando..' });
      // let archivoSend = comprobante_financiacion ? document.getElementById('comp_fin').files[0] : '';
      // console.log(archivoSend)
      let data = { tipo_financiacion, fuente_financiacion, entidad, dependencia,
        tipo_moneda, valor_cop, valor_ext, clasificacion_de_financiacion, tipo_de_concepto, registro, comprobante_financiacion: archivo };
      await agregarFinanciacionRegistro(registro_iniciado, data, ()=> cambiarDatosFinanciacion());
      // await agregarFinanciacionRegistro(registro_iniciado, data, ()=> cambiarDatosFinanciacion());
      this.setState({ tit_cargando: 'Cargando..', cargando: false });
    } else actualizarMensaje({ titulo: 'Debes llenar los campos obligatorios.', tipo: 'info', mostrar: true, tiempo: 6000 });
  }

  handleStep = async () => {
    let { activeStep, borrador_activo, instituciones, descripcion_actividad, horas, departamento_sel,
      fecha_final, fecha_inicio, modalidad, modalidad_escogida, plataforma_utilizada, resultados_descripcion, info_complementaria,
      solicitud_iniciada, tipo_actividad, otro_tipo_actividad, tipo_movilidad, titulo_tema, otra_plataforma_utilizada, subtipo_actividad,
      otro_subtipo_actividad, es_financiado, facultad_sel, arrayselelctipo, ud_administrativa_sel, financiaciones, cantidad_financiaciones, sw_horas  } = this.state;
    let errores = [];
    switch (activeStep) {
      case 0:
        errores = await validateInputs([
          { value: tipo_movilidad, name: 'tipo_movilidad', err: { empty: true } },
          { value: modalidad, name: 'modalidad', err: { empty: true } },
          { value: tipo_actividad, name: 'tipo_actividad', err: { empty: true } },
          { value: fecha_inicio, name: 'fecha_inicio', err: { not_null: true } },
          { value: fecha_final, name: 'fecha_final', err: { finish_date: true }/* , value2: fecha_inicio */ },
          { value: instituciones, name: 'instituciones', err: { empty: true } },
          { value: arrayselelctipo, name: 'arrayselelctipo', err: { empty: true } },
        ]);
        if(arrayselelctipo.includes('tipo_resp_dpto')){
          if(departamento_sel.length===0) errores.push({ llave: 'departamento_sel', 'mensaje': `Seleccione al menos un departamento` });
        }
        if(arrayselelctipo.includes('tipo_resp_facul')){
          if(facultad_sel.length===0) errores.push({ llave: 'facultad_sel', 'mensaje': `Seleccione al menos una facultad` });
        }
        if(arrayselelctipo.includes('tipo_resp_admin')){
          if(ud_administrativa_sel.length===0) errores.push({ llave: 'ud_administrativa_sel', 'mensaje': `Seleccione al menos una unidad administrativa` });
        }
        if  (CODIGOS_DURACION_ACTIVIDADES.includes(tipo_actividad)) {
          if (horas < 1 || horas > 9999) errores.push({ llave: 'horas', 'mensaje': `El valor está fuera del rango permitido` });
        }
        if (CODIGO_TIPO_ACTIVIDAD_OTRO == tipo_actividad) {
          if (otro_tipo_actividad.length == 0) errores.push({ llave: 'otro_tipo_actividad', 'mensaje': `Este campo es obligatorio` });
        }
        if (CODIGO_SUBTIPO_ACTIVIDAD_OTRO == subtipo_actividad) {
          if (otro_subtipo_actividad.length == 0) errores.push({ llave: 'otro_subtipo_actividad', 'mensaje': `Este campo es obligatorio` });
        }
        if (CODIGOS_MODALIDADES_VIRTUALES.includes(modalidad_escogida.codigo)) {
          if (plataforma_utilizada.length == 0) errores.push({ llave: 'plataforma_utilizada', 'mensaje': `Este campo es obligatorio` });
          if (CODIGO_PLATAFORMA_OTRO == plataforma_utilizada && CODIGOS_MODALIDADES_VIRTUALES.includes(modalidad_escogida.codigo)) {
            if (otra_plataforma_utilizada.length == 0) errores.push({ llave: 'otra_plataforma_utilizada', 'mensaje': `Este campo es obligatorio` });
          }
        }
        break;
      case 1:
        errores = await validateInputs([
          { value: titulo_tema, name: 'titulo_tema', err: { empty: true } },
          { value: descripcion_actividad, name: 'descripcion_actividad', err: { empty: true } },
          { value: resultados_descripcion, name: 'resultados_descripcion', err: { empty: true } }
        ]);
        let archivo_resultados = info_complementaria ? document.getElementById('info_complementaria').files[0] : '';
        this.setState({ archivo_resultados });
        break
      case 2:
        errores = await validateInputs([
          { value: es_financiado, name: 'es_financiado', err: { empty: true } },
          // { value: financiaciones, name: 'financiaciones', err: { empty: true } },
        ]);
        if (es_financiado == 1){
          if (financiaciones.length === 0) errores.push({ llave: 'es_financiado', 'mensaje': `Ingrese por lo menos una financiación` });
        }
        break
      default:
        break;
    }
    this.setState({ errores });
    if (errores.length === 0) {
      if (activeStep == 0 && !borrador_activo) this.setState({ modal_inicio_borrador: true });
        else this.modificarDatosBorradorRegistro(solicitud_iniciada.id);
    }
  }

  async obtenerDatosIniciales() {
    let { obtenerBorradorRegistro } = this.props;
    this.setState({ cargando: true });
    let registro = await obtenerBorradorRegistro(); // Obtiene solicitud de registro de movilidad en caso de haber.
    if (registro && registro.length == 1) this.cargarDatosBorrador(registro[0]); // Si hay registro en borrador, se cargan los datos.
      else this.reiniciarEstados(true);
    this.setState({ cargando: false });
  }

  // Cargar estados de valores genericas obtenidos en el component did update
  cargarEstados(datos) {
    let { dependencias, facultades, dptos_academicos, instituciones_todas, modalidades, plataformas, tipos_financiaciones,
      tipos_movilidades, niveles_educativos, fuentes_financiaciones, uds_administrativas, monedas, facultades_departamentos,
      programas_receptores, tipos_identificacion, clasificaciones_de_financiaciones, tipo_financiacion, fuente_financiacion,
      dependencia, clasificacion_de_financiacion, backtiporesponsable } = datos;
    this.setState({ dependencias, facultades, dptos_academicos, instituciones_todas, modalidades, plataformas, tipos_financiaciones,
      tipos_movilidades, niveles_educativos, fuentes_financiaciones, uds_administrativas, monedas, facultades_departamentos,
      programas_receptores, cargando: false, tipos_identificacion, clasificaciones_de_financiaciones, tipo_financiacion,
      fuente_financiacion, dependencia, clasificacion_de_financiacion, backtiporesponsable });
  }

  checked = ({ target }) => {
    this.setState({
      [target.name]: target.checked,
      carrera: ''
    })
  }

  configStepContent = () => {
    let { activeStep } = this.state;
    return (
      <>
        { activeStep === 0 && this.renderInformacionInicial() }
        { activeStep === 1 && this.renderInformacionAcademica() }
        { activeStep === 2 && this.renderFinanciacionActividad() }
        { activeStep === 3 && this.renderParticipantes() }
        { activeStep === 4 && this.renderEvidencias() }
      </>
    );
  }

  // Handle step especifico para el paso de agregar participantes
  handleStepParticipante = sw => {
    let { actualizarMensaje } = this.props;
    let { participantes, activeStep, solicitud_iniciada } = this.state;
    if (sw) {
      let cantidad_participantes = participantes.length;
      let participantes_digitados = cantidad_participantes > 0 ? participantes.map(({ formulario }) => parseInt(formulario)).reduce((a, b) => a + b) : 0;
      if (cantidad_participantes == 0) {
        actualizarMensaje({ titulo: 'Debe agregar por lo menos un participante.', tipo: 'info', mostrar: true, tiempo: 6000 });
      } else if(participantes_digitados < cantidad_participantes) {
        actualizarMensaje({ titulo: 'Debe llenar la información solicitada para cada participante.', tipo: 'info', mostrar: true, tiempo: 6000 });
      } else {
        this.modificarDatosBorradorRegistro(solicitud_iniciada.id);
      }
    } else {
      this.setState({ activeStep: activeStep - 1 });
    }
  }

    // Handle step especifico para el paso de agregar financiacion
    handleStepFinanciacion = () => {
      let {comprobante_financiacion} = this.state
      let archivoSend = comprobante_financiacion ? document.getElementById('comprobante_financiacion').files[0] : '';
      this.guardarDatoFinanciacion(archivoSend);
    }

  // Crea la solicitud para ir guardando los datos
  iniciarBorradorRegistro = () => {
    let { crearBorradorRegistro, obtenerAdjuntos, obtenerBorradorRegistro } = this.props;
    let { tipo_movilidad, instituciones, tipo_actividad, otro_tipo_actividad, horas, modalidad, plataforma_utilizada,
      otra_plataforma_utilizada, fecha_inicio, fecha_final, departamento_sel, facultad_sel,
      ud_administrativa_sel, lista_evidencias, subtipo_actividad, otro_subtipo_actividad,arrayselelctipo,backtiporesponsable } = this.state;
    let evidencias = lista_evidencias.map(evidencia => evidencia.id); // ERROR: al seleccionar modalidad virtual asincrona (cargar lista de actividades relacionada)
    const cargarDatosRegistro = async(resp, solicitud) => {
      let registro = resp ? await obtenerBorradorRegistro() : [{ id: 0 }];
      let evidenciasAdjuntos = resp ? await obtenerAdjuntos(solicitud.id) : [];
      this.setState({
        activeStep: resp ? 1 : 0,
        solicitud_iniciada: solicitud,
        modal_inicio_borrador: false,
        borrador_activo: true,
        registro_iniciado: registro[0].id,
        evidenciasAdjuntos,
        tit_cargando: 'Cargando..',
        cargando: false
      });
    }
    let guardarid=[]
    backtiporesponsable.map(({codigo,id})=>{
      arrayselelctipo.map((codigoarray)=>{
        if(codigoarray === codigo){
          guardarid.push(id);
        }
      })
    })
    if(arrayselelctipo.indexOf('tipo_resp_admin')==-1){
      ud_administrativa_sel=[]
      this.setState({ud_administrativa_sel:[]})
    }
    if(arrayselelctipo.indexOf('tipo_resp_dpto')==-1){
      departamento_sel=[]
      this.setState({departamento_sel:[]})
    }
    if(arrayselelctipo.indexOf('tipo_resp_facul')==-1){
      facultad_sel=[]
      this.setState({facultad_sel:[]})
    }
    this.setState({ cargando: true, tit_cargando: 'Iniciando..' });
    let data = { tipo_movilidad, tipo_actividad, otro_tipo_actividad, subtipo_actividad, otro_subtipo_actividad, horas,
      modalidad, plataforma_utilizada, otra_plataforma_utilizada, fecha_inicio: fecha_inicio && moment(fecha_inicio).format('YYYY-MM-DD'),
      fecha_final: fecha_final && moment(fecha_final).format('YYYY-MM-DD'), total_evidencias: evidencias.length,
      total_instituciones: instituciones.length, total_dep: departamento_sel.length,
      total_facultad: facultad_sel.length, total_unidades_adm: ud_administrativa_sel.length,total_arrayselect:arrayselelctipo.length }
    crearBorradorRegistro(data, (resp, solicitud) => cargarDatosRegistro(resp, solicitud), evidencias,
      instituciones, departamento_sel, facultad_sel, ud_administrativa_sel,guardarid);
  }

  // Actualiza los datos en cada paso
  modificarDatosBorradorRegistro = id => {
    let { modificarBorradorRegistro, obtenerAdjuntos } = this.props;
    let { activeStep, archivo_resultados, descripcion_actividad, es_financiado, departamento_sel, fecha_final, fecha_inicio,
      horas, otra_plataforma_utilizada, modalidad, plataforma_utilizada, facultad_sel, resultados_descripcion, tipo_movilidad,
      instituciones, tipo_actividad, otro_tipo_actividad, titulo_tema, cantidad_financiaciones, ud_administrativa_sel, lista_evidencias,
      solicitud_iniciada, subtipo_actividad, otro_subtipo_actividad, tipo_financiacion, dependencia, fuente_financiacion, entidad,
      valor_cop, valor_ext, tipo_moneda, clasificacion_de_financiacion, tipo_de_concepto, arrayselelctipo, backtiporesponsable } = this.state;
    const cargarDatosEvidencias = async resp => {
      let evidenciasAdjuntos = resp ? await obtenerAdjuntos(solicitud_iniciada.id) : [];
      this.setState({ activeStep: resp && activeStep + 1, modal_inicio_borrador: false, evidenciasAdjuntos,
        cargando: false, tit_cargando: 'Cargando..' })
    }
    let guardarid=[]
    backtiporesponsable.map(({codigo,id})=>{
      arrayselelctipo.map((codigoarray)=>{
        if(codigoarray === codigo){
          guardarid.push(id);
        }
      })
    })
    if(arrayselelctipo.indexOf('tipo_resp_admin')==-1){
      ud_administrativa_sel=[]
      this.setState({ud_administrativa_sel:[]})
    }
    if(arrayselelctipo.indexOf('tipo_resp_dpto')==-1){
      departamento_sel=[]
      this.setState({departamento_sel:[]})
    }
    if(arrayselelctipo.indexOf('tipo_resp_facul')==-1){
      facultad_sel=[]
      this.setState({facultad_sel:[]})
    }
    this.setState({ cargando: true, tit_cargando: 'Enviando..' });
    let evidencias = lista_evidencias.map(evidencia => evidencia.id);
    let data = { paso_actual: activeStep, tipo_movilidad, tipo_actividad, otro_tipo_actividad,
      horas, modalidad, plataforma_utilizada, otra_plataforma_utilizada, fecha_inicio: fecha_inicio && moment(fecha_inicio).format('YYYY-MM-DD'),
      fecha_final: fecha_final && moment(fecha_final).format('YYYY-MM-DD'), titulo_tema, descripcion_actividad, resultados_descripcion,
      info_complementaria: archivo_resultados, es_financiado, tipo_financiacion, dependencia, fuente_financiacion, entidad, valor_cop,
      valor_ext, tipo_moneda, clasificacion_de_financiacion, tipo_de_concepto, total_instituciones: instituciones.length, cantidad_financiaciones,
      total_evidencias: evidencias.length, total_dep: departamento_sel.length, total_facultad: facultad_sel.length,
      total_unidades_adm: ud_administrativa_sel.length, subtipo_actividad, otro_subtipo_actividad,total_arrayselect:arrayselelctipo.length }
    modificarBorradorRegistro(id, data, evidencias, instituciones, departamento_sel, facultad_sel,
      ud_administrativa_sel,guardarid,activeStep, resp => cargarDatosEvidencias(resp));
  }

  limpiarErrores = name => {
    let { errores } = this.state;
    if (errores.length > 0) this.setState({ errores: errores.filter(er => er.llave !== name) }); // Elimina error cuando se escribe en el campo
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.limpiarErrores(name);
    this.setState({ [name]: value });
  }

  onChangeDate = (name, date) => {
    this.limpiarErrores(name);
    this.setState({ [name]: date });
  }

  onChangeParticipanteExt = ({ target }) => {
    let { value, name } = target;
    let { participante_externo } = this.state;
    this.limpiarErrores(name);
    this.setState({ participante_externo: { ...participante_externo, [name]: value } });
  }

  onChangeParticipanteExtMas = ({ target }) => {
    let { value, name } = target;
    let { participante_externo } = this.state;
    this.limpiarErrores(name);
    this.setState({ participante_externo: { ...participante_externo, [name]: value } });
  }

  // Recibe id de institucion seleccionada y la quita de la lista de instituciones y reinicia institución seleccionada.
  quitarParticipante = id => {
    let { quitarParticipanteRegistro } = this.props;
    let { registro_iniciado } = this.state;
    if(id) {
      quitarParticipanteRegistro(id,
        async () => this.setState({ participantes: await this.obtenerDataParticipantes(registro_iniciado), modal_quitar_participante: false })); // Cambia el estado a 0 al registro del participante en la bd y cierra el modal.
    }
  }

  quitarFinanciacion = () => {
    let { quitarFinanciacionRegistro,obtenerFinanciacionesActividad } = this.props;
    let { registro_iniciado } = this.state;
    let id =this.state.financiacion_seleccionada.id
    if(id) {
      quitarFinanciacionRegistro(id,
        async () => this.setState({ financiaciones: await obtenerFinanciacionesActividad(registro_iniciado), modal_quitar_financiacion: false })); // Cambia el estado a 0 al registro de la financiacion en la bd y cierra el modal.
    }
  }

  reiniciarEstados(respuesta) {
    let { set_solicitud_cancelada } = this.props;
    if (respuesta) {
      this.setState({
        activeStep: 0,
        horas: '',
        cantidad_financiaciones: 0,
        errores: [],
        departamento_sel: [],
        instituciones: [],
        participantes: [],
        financiaciones: [],
        facultad_sel: [],
        arrayselelctipo:[],
        solicitud_iniciada: {},
        ud_administrativa_sel: [],
        certificados_participantes: '',
        descripcion_actividad: '',
        enlace_grabacion: '',
        institucion_seleccionada: '',
        modalidad: '',
        tipo_actividad: '',
        otro_tipo_actividad: '',
        plataforma_utilizada: '',
        otra_plataforma_utilizada: '',
        subtipo_actividad: '',
        otro_subtipo_actividad: '',
        resultados_descripcion: '',
        info_complementaria: '',
        tipo_movilidad: '',
        tit_cargando: 'Cargando..',
        titulo_tema: '',
        borrador_activo: false,
        cargando: false,
        check_masivo: false,
        fecha_final: null,
        fecha_inicio: null,
        es_financiado: '',
        tipo_financiacion: [],
        fuente_financiacion: [],
        entidad: '',
        dependencia: [],
        tipo_moneda: '',
        valor_cop: '',
        valor_ext: '',
        clasificacion_de_financiacion: [],
        tipo_de_concepto: '',
        modalidad_escogida: {},
        comprobante_financiacion: '',
      });
      set_solicitud_cancelada(false);
    } else this.setState({ cargando: false, tit_cargando: 'Cargando..' });
  }

  submit = archivos => {
    let { actualizarMensaje, modificarBorradorRegistro } = this.props;
    let { solicitud_iniciada: { id }, activeStep, enlace_grabacion, certificados_participantes } = this.state;
    let archivo_certificados = certificados_participantes ? document.getElementById('certificados_participantes').files[0] : '';
    if (archivos.length > 0) {
      this.setState({ cargando: true, tit_cargando: 'Enviando..' });
      let data = { paso_actual: activeStep, enlace_grabacion, certificados_participantes: archivo_certificados }
      modificarBorradorRegistro(id, data, [], [], [], [], [], [], activeStep, (resp) => this.reiniciarEstados(resp));
    } else actualizarMensaje({ titulo: 'Debe adjuntar por lo menos una evidencia.', tipo: 'info', mostrar: true, tiempo: 6000 });
  }

  enviarParticipantesMasivo = async (archivo, f) => {
		let { actualizarMensaje } = this.props;
		let { registro_iniciado } = this.state;
		if (archivo.length === 0) return true;
    let data = await crear_form_data({ archivo });
		formulario(`api/v1.0/internacionalizacion/${f}/obtener_datosParticipantes`, data, 'post', (error, estado, resp) => {
			const cambiarDatosParticipante = async () => this.setState({ participantes: await this.obtenerDataParticipantes(registro_iniciado) });

      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      cambiarDatosParticipante()
      this.setState({modalMasivo:false})
		});
	}

  enviarParticipantesMasivoEntrante = async (archivo, f) => {
		let { actualizarMensaje } = this.props;
		let { registro_iniciado } = this.state;
		if (archivo.length === 0) return true;
    let data = await crear_form_data({ archivo });
		formulario(`api/v1.0/internacionalizacion/${f}/obtener_datosParticipantes_entrante`, data, 'post', (error, estado, resp) => {
			const cambiarDatosParticipante = async () => this.setState({ participantes: await this.obtenerDataParticipantes(registro_iniciado) });

      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      cambiarDatosParticipante()
      this.setState({modalMasivo:false})
		});
	}

  renderInformacionInicial =  () => {
    let { errores, departamento_sel, fecha_final, fecha_inicio, horas, institucion_seleccionada,
      instituciones, modal_buscar_institucion, modal_inicio_borrador, modal_quitar_institucion,
      modalidad, modalidad_escogida, modalidades, plataforma_utilizada, plataformas, facultad_sel,
      tipo_actividad, otro_tipo_actividad, otra_plataforma_utilizada, uds_administrativas,
      tipo_movilidad, tipos_actividades, tipos_movilidades, ud_administrativa_sel, subtipo_actividad,
      otro_subtipo_actividad, subtipos_actividades, arrayselelctipo, backtiporesponsable, facultades,
      dptos_academicos } = this.state;
    let seleccion = institucion_seleccionada ? instituciones.find(inst => inst.id === institucion_seleccionada) : { id:0 };
    const { classes } = this.props;
    const onClickEliminar = data => data && this.setState({ modal_quitar_institucion: true });
    const agregarInstitucion = (id, institucion, codigo_convenio) => {
      this.setState({ instituciones: instituciones.concat({ id, institucion, codigo_convenio}),
        errores: errores.length > 0 ? errores.filter(er => er.llave !== 'instituciones') : errores });
    }
    const quitarInstitucionSeleccionada = id => {
      let { instituciones } = this.state;
      if(id) {
        this.setState({
          instituciones: instituciones.filter(inst => inst.id !== id),
          institucion_seleccionada: '',
          modal_quitar_institucion: false,
        });
      }
    }
    return (
      <>
        <Grid container spacing = { 3 } style = { { marginBottom: '40px' } }>
          <Grid item xs = { 6 } /* { CODIGOS_MODALIDADES_VIRTUALES.includes(modalidad_escogida.codigo) ? 3 : 6 } */>
            <FormControl className = 'form-control' required error = { getError('tipo_movilidad', errores).length > 0 }>
              <InputLabel id = 'tipo_movilidad_label'>Tipo de movilidad</InputLabel>
              <Select
                labelId = 'tipo_movilidad_label'
                id = 'tipo_movilidad'
                name = 'tipo_movilidad'
                value = { tipo_movilidad }
                label = 'Tipo de movilidad'
                fullWidth
                onChange = { this.onChange }
              >
                {
                  tipos_movilidades
                    .map(tipo => <MenuItem key = { tipo.id } value = { tipo.id }>{ tipo.nombre }</MenuItem>)
                }
              </Select>
              <FormHelperText>{ getError('tipo_movilidad', errores) }</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs = { 6 } /* { CODIGOS_MODALIDADES_VIRTUALES.includes(modalidad_escogida.codigo) ? 3 : 6 } */>
            <FormControl className = 'form-control' required error = { getError('modalidad', errores).length > 0 }>
              <InputLabel id = 'modalidad_label'>Tipo de modalidad</InputLabel>
              <Select
                labelId = 'modalidad_label'
                id = 'modalidad'
                name = 'modalidad'
                value = { modalidad }
                label = 'Modalidad'
                fullWidth
                onChange = { this.onChange }
              >
                { modalidades.map(modalidad => <MenuItem key = { modalidad.id } value = { modalidad.id }>{ modalidad.nombre }</MenuItem>) }
              </Select>
              <FormHelperText>{ getError('modalidad', errores) }</FormHelperText>
            </FormControl>
          </Grid>
          { CODIGOS_MODALIDADES_VIRTUALES.includes(modalidad_escogida.codigo) && //validacion campo plataforma a utilizar
              <Grid item xs = { plataforma_utilizada && CODIGO_PLATAFORMA_OTRO == plataforma_utilizada ? 6 : 6 }>
                <FormControl className = 'form-control' required error = { getError('plataforma_utilizada', errores).length > 0 }>
                  <InputLabel id = 'plataforma_utilizada_label'>Plataforma utilizada</InputLabel>
                  <Select
                    required
                    labelId = 'plataforma_utilizada_label'
                    id = 'plataforma_utilizada'
                    name = 'plataforma_utilizada'
                    value = { plataforma_utilizada }
                    label = 'Plataforma utilizada'
                    fullWidth
                    onChange = { this.onChange }
                  >
                    {
                      plataformas
                        .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                        .map(tipo => <MenuItem key = { tipo.id } value = { tipo.codigo }>{ tipo.nombre }</MenuItem>)
                    }
                  </Select>
                  <FormHelperText>{ getError('plataforma_utilizada', errores) }</FormHelperText>
                </FormControl>
              </Grid>
          }
          { plataforma_utilizada && CODIGO_PLATAFORMA_OTRO == plataforma_utilizada && CODIGOS_MODALIDADES_VIRTUALES.includes(modalidad_escogida.codigo) &&
              <Grid item xs = { 6 }>
              <TextField
                  required
                  value = { otra_plataforma_utilizada }
                  error = { getError('otra_plataforma_utilizada', errores).length > 0 }
                  helperText = { getError('otra_plataforma_utilizada', errores) }
                  id = 'otra_plataforma_utilizada'
                  name = 'otra_plataforma_utilizada'
                  label = 'Otra plataforma'
                  fullWidth
                  onChange = { this.onChange }
                />
              </Grid>
            }
          { tipo_movilidad && modalidad &&
            <Grid item xs = { CODIGO_TIPO_ACTIVIDAD_OTRO == tipo_actividad ? 6 : 6 }>
              <FormControl className = 'form-control' required error = { getError('tipo_actividad', errores).length > 0 }>
                <InputLabel id = 'tipo_actividad_label'>Tipo de actividad</InputLabel>
                <Select
                  labelId = 'tipo_actividad_label'
                  id = 'tipo_actividad'
                  name = 'tipo_actividad'
                  value = { tipo_actividad }
                  label = 'Tipo de actividad'
                  fullWidth
                  onChange = { this.onChange }
                >
                  {
                    tipos_actividades.length > 0 ?
                      tipos_actividades
                        .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                        .map(tipo => <MenuItem key = { tipo.id } value = { tipo.codigo }>{ tipo.nombre }</MenuItem>)
                      : <MenuItem value = ""><em>Seleccione tipo de movilidad y/o modalidad</em></MenuItem>
                  }
                </Select>
                <FormHelperText>{ getError('tipo_actividad', errores) }</FormHelperText>
              </FormControl>
            </Grid>
          }
          { CODIGO_TIPO_ACTIVIDAD_OTRO == tipo_actividad &&
            <Grid item xs = { 6 }>
            <TextField
                required
                value = { otro_tipo_actividad }
                error = { getError('otro_tipo_actividad', errores).length > 0 }
                helperText = { getError('otro_tipo_actividad', errores) }
                name = 'otro_tipo_actividad'
                label = 'Otro tipo de actividad'
                fullWidth
                onChange = { this.onChange }
              />
            </Grid>
          }
          { CODIGOS_SUBTIPOS_ACTIVIDADES.includes(tipo_actividad) && //validacion campo subtipos
            <Grid item xs = { 6 }>
            <FormControl className = 'form-control' required error = { getError('subtipo_actividad', errores).length > 0 }>
              <InputLabel id = 'subtipo_actividad_label'>Subtipo de actividad</InputLabel>
              <Select
                labelId = 'subtipo_actividad_label'
                id = 'subtipo_actividad'
                name = 'subtipo_actividad'
                value = { subtipo_actividad }
                label = 'Subtipo de actividad'
                fullWidth
                onChange = { this.onChange }
              >
                { subtipos_actividades.length > 0 ?
                    subtipos_actividades
                      .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                      .map(tipo => <MenuItem key = { tipo.secundario.id } value = { tipo.secundario.codigo }>{ tipo.secundario.nombre }</MenuItem>)
                    : <MenuItem value = ""><em>No hay subtipos</em></MenuItem>
                }
              </Select>
              <FormHelperText>{ getError('tipo_actividad', errores) }</FormHelperText>
            </FormControl>
          </Grid>
          }
          { CODIGOS_SUBTIPOS_ACTIVIDADES.includes(tipo_actividad) && CODIGO_SUBTIPO_ACTIVIDAD_OTRO == subtipo_actividad &&
            <Grid item xs = { 6 }>
            <TextField
                required
                value = { otro_subtipo_actividad }
                error = { getError('otro_subtipo_actividad', errores).length > 0 }
                helperText = { getError('otro_subtipo_actividad', errores) }
                name = 'otro_subtipo_actividad'
                label = 'Otro subtipo de actividad'
                fullWidth
                onChange = { this.onChange }
              />
            </Grid>
          }
          { CODIGOS_DURACION_ACTIVIDADES.includes(tipo_actividad) && //validacion campo duración
            <Grid item xs = { 6 }>
            <TextField
              value = { horas }
              required
              error = { getError('horas', errores).length > 0 }
              helperText = { getError('horas', errores) }
              type = 'number'
              id = 'horas'
              name = 'horas'
              label = 'Duración (horas)'
              fullWidth
              onChange = { this.onChange }
            />
          </Grid>
          }
          <Grid item xs = { 4 }>
            <FormControl className='form-control' required error = { getError('instituciones', errores).length > 0 }>
              <Select
                id = 'institucion_seleccionada'
                name = 'institucion_seleccionada'
                value = { institucion_seleccionada }
                fullWidth
                displayEmpty
                onChange = { this.onChange }
              >
                <MenuItem value = "">
                  <em>{ `${instituciones.length} institucion(es) seleccionada(s)` }</em>
                </MenuItem>
                { instituciones
                    .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                    .map((inst) => <MenuItem key={inst.id} value={inst.id}>{inst.codigo_convenio}{" " + inst.institucion}</MenuItem>)
                }
              </Select>
            </FormControl>
            <FormHelperText>{ getError('instituciones', errores) }</FormHelperText>
          </Grid>
          <Grid item xs = { 2 }>
            <ConvocatoriasBuscarConvenio
              seleccionar = { ({ id, institucion, codigo_convenio}) => agregarInstitucion(id, institucion, codigo_convenio) }
              quitar = { ({ id }) => this.setState({ instituciones: instituciones.filter(inst => inst.id !== id) }) }
              seleccionadas = { instituciones }
              modalBuscarUniversidad = { modal_buscar_institucion }
              mostrarModalBuscarUniversidad = { (modal_buscar_institucion) => this.setState({ modal_buscar_institucion }) }
            />
            <ConfirmarAccion
              mensaje1 = { institucion_seleccionada && `Se quitará ${seleccion.nombre} de la lista, ` }
              ejecutarAccion = { quitarInstitucionSeleccionada }
              mostrarModalConfAccion = { (modal_quitar_institucion) => this.setState({ modal_quitar_institucion }) }
              modalConAccion = { modal_quitar_institucion }
              dato = { institucion_seleccionada ? seleccion : { id: 0 } }
              titulo = { '¿ Quitar institución ?' }
            />
            <IconButton aria-label = "add"  className = { classes.colorEmmma } onClick = { () => this.setState({ modal_buscar_institucion: true }) }>
              <AddIcon />
            </IconButton>
            <IconButton aria-label = "delete" className = { classes.colorDelete } onClick = { () => onClickEliminar(institucion_seleccionada) }>
              <DeleteIcon />
            </IconButton>
          </Grid>
          <Grid item xs = { 6 }>
            <MuiPickersUtilsProvider utils = { DateFnsUtils }>
              <KeyboardDatePicker
                id = 'fecha_inicio'
                required
                error = { getError('fecha_inicio', errores).length > 0 }
                helperText = { getError('fecha_inicio', errores) }
                label = 'Fecha de inicio'
                name = 'fecha_inicio'
                format = 'dd/MM/yyyy'
                value = { fecha_inicio }
                onChange = { (date) => this.onChangeDate('fecha_inicio', date) }
                fullWidth
                KeyboardButtonProps = { { 'aria-label': 'cambiar fecha' } }
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs = { 6 }>
            <MuiPickersUtilsProvider utils = { DateFnsUtils }>
              <KeyboardDatePicker
                id = 'fecha_final'
                required
                error = { getError('fecha_final', errores).length > 0 }
                helperText = { getError('fecha_final', errores) }
                label = 'Fecha final'
                name = 'fecha_final'
                format = 'dd/MM/yyyy'
                value = { fecha_final }
                onChange = { (date) => this.onChangeDate('fecha_final', date) }
                fullWidth
                KeyboardButtonProps = { { 'aria-label': 'cambiar fecha' } }
              />
            </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs = { 12 }>
            <FormControl className = 'form-control' required error = { getError('arrayselelctipo', errores).length > 0 }>
              <InputLabel id = 'arrayselelctipo_label'>Seleccione responsable/s de la actividad</InputLabel>
              <Select
                required
                id = 'arrayselelctipo'
                name = 'arrayselelctipo'
                error = { getError('arrayselelctipo', errores).length > 0 }
                helperText = { getError('arrayselelctipo', errores) }
                value = { arrayselelctipo }
                fullWidth
                onChange = { this.onChange }
                multiple
                renderValue={selected => {
                  return this.rendertiposresponsable(selected,backtiporesponsable)
                }}
                >
                {
                  backtiporesponsable.map(({ nombre, id,codigo }) => {
                  return (
                  <MenuItem key={id} value={codigo}>
                    <Checkbox checked={arrayselelctipo.indexOf(codigo) > -1} />
                    <ListItemText primary={nombre} />
                  </MenuItem>
                  )})
                }
                {/* { facultades_departamentos.map(({ id, nombre }) => <MenuItem key = { id } value = { id }>{ nombre }</MenuItem>) } */}
              </Select>
              <FormHelperText>{ getError('arrayselelctipo', errores) }</FormHelperText>
            </FormControl>
          </Grid>
          { arrayselelctipo.includes('tipo_resp_dpto') &&
          <Grid item xs = { 6 }>
            <FormControl className = 'form-control' required error = { getError('departamento_sel', errores).length > 0 }>
              <InputLabel id = 'facultad_departamento_label'>Departamento responsable/s de la actividad</InputLabel>
              <Select
                required
                labelId = 'facultad_departamento_label'
                id = 'departamento_sel'
                name = 'departamento_sel'
                error = { getError('departamento_sel', errores).length > 0 }
                helperText = { getError('departamento_sel', errores) }
                value = { departamento_sel }
                fullWidth
                onChange = { this.onChange }
                multiple
                renderValue={selected => {
                  return this.pintarTiposSeleccionado(selected,dptos_academicos)
                }}
              >
              {
                dptos_academicos
                .filter(({ valorg }) => valorg == '1')
                .map(({ nombre, id }) => {
                  return (
                  <MenuItem key={id} value={id}>
                    <Checkbox checked={departamento_sel.indexOf(id) > -1} />
                    <ListItemText primary={nombre} />
                  </MenuItem>)
                  })
              }
                {/* { facultades_departamentos.map(({ id, nombre }) => <MenuItem key = { id } value = { id }>{ nombre }</MenuItem>) } */}
              </Select>
              <FormHelperText>{ getError('departamento_sel', errores) }</FormHelperText>
            </FormControl>
          </Grid>
          }
          { arrayselelctipo.includes('tipo_resp_facul') &&
            <Grid item xs = { 6 }>
            <FormControl className = 'form-control' required error = { getError('facultad_sel', errores).length > 0 }>
              <InputLabel id = 'facultad_responsable_label'>Facultad responsable/s de la actividad</InputLabel>
              <Select
                required
                labelId = 'facultad_sel'
                id = 'facultad_sel'
                name = 'facultad_sel'
                error = { getError('facultad_sel', errores).length > 0 }
                helperText = { getError('facultad_sel', errores) }
                value = { facultad_sel }
                fullWidth
                onChange = { this.onChange }
                multiple
                renderValue={selected => {
                  return this.pintarTiposSeleccionado(selected,facultades)
                }}
              >
                {
                facultades.map(({ nombre, id }) => {
                  return (
                  <MenuItem key={id} value={id}>
                    <Checkbox checked={facultad_sel.indexOf(id) > -1} />
                    <ListItemText primary={nombre} />
                  </MenuItem>
                  )})
                }
                {/* { programas_receptores.map(({ id, nombre }) => <MenuItem key = { id } value = { id }>{ nombre.toUpperCase() }</MenuItem>) } */}
              </Select>
              <FormHelperText>{ getError('facultad_sel', errores) }</FormHelperText>
            </FormControl>
          </Grid> }
          { arrayselelctipo.includes('tipo_resp_admin') &&
          <Grid item xs = { 6 }>
            <FormControl className = 'form-control' required error = { getError('ud_administrativa_sel', errores).length > 0 }>
              <InputLabel id = 'ud_administrativa_responsable_label'>Unidad administrativa responsable/s de la actividad</InputLabel>
              <Select
                required
                labelId = 'ud_administrativa_responsable_label'
                id = 'ud_administrativa_sel'
                name = 'ud_administrativa_sel'
                error = { getError('ud_administrativa_sel', errores).length > 0 }
                helperText = { getError('ud_administrativa_sel', errores) }
                value = { ud_administrativa_sel }
                fullWidth
                onChange = { this.onChange }
                multiple
                renderValue={selected => {
                  return this.pintarTiposSeleccionado(selected,uds_administrativas)
                }}
              >
                {
                uds_administrativas.map(({ nombre, id }) => {
                  return (<MenuItem key={id} value={id}>
                    <Checkbox checked={ud_administrativa_sel.indexOf(id) > -1} />
                    <ListItemText primary={nombre} />
                  </MenuItem>)
                  })
                }
                {/* { uds_administrativas.map(({ id, nombre }) => <MenuItem key = { id } value = { id }>{ nombre }</MenuItem>) } */}
              </Select>
              <FormHelperText>{ getError('ud_administrativa_sel', errores) }</FormHelperText>
            </FormControl>
          </Grid>
          }
        </Grid>
        <ConfirmarAccion
          mensaje1 = { `Usted está a punto de iniciar un registro en borrador, ` }
          ejecutarAccion = { () => this.iniciarBorradorRegistro() }
          mostrarModalConfAccion = { modal_inicio_borrador => this.setState({ modal_inicio_borrador }) }
          modalConAccion = { modal_inicio_borrador }
          dato = { { id: 0 } }
          titulo = { '¿ Desea continuar ?' }
        />
      </>
    )
  }

  renderInformacionAcademica = () => {
    let { descripcion_actividad, errores, resultados_descripcion, info_complementaria, titulo_tema } = this.state;
    const CHARACTER_LIMIT = 750;
    return (
      <Grid container spacing = { 3 } style = { { marginBottom: '40px' } }>
        <Grid item xs = { 12 }>
          <TextField
            value = { titulo_tema }
            required
            error = { getError('titulo_tema', errores).length > 0 }
            helperText = { getError('titulo_tema', errores) }
            id = 'titulo_tema'
            name = 'titulo_tema'
            label = 'Titulo del evento o tema tratado'
            fullWidth
            onChange = { this.onChange }
          />
        </Grid>
        <Grid item xs = { 12 }>
          <TextField
            value = { descripcion_actividad }
            multiline
            required
            error = { getError('descripcion_actividad', errores).length > 0 }
            helperText = { `${descripcion_actividad.length}/${CHARACTER_LIMIT}` }
            rows = { 4 }
            id = 'descripcion_actividad'
            name = 'descripcion_actividad'
            label = 'Descripción de la actividad'
            fullWidth
            onChange = { this.onChange }
            inputProps = { { maxLength: CHARACTER_LIMIT } }
          />
        </Grid>
        <Grid item xs = { 12 }>
          <TextField
            value = { resultados_descripcion }
            multiline
            required
            error = { getError('resultados_descripcion', errores).length > 0 }
            helperText = { `${resultados_descripcion.length}/${CHARACTER_LIMIT}` }
            rows = { 4 }
            id = 'resultados_descripcion'
            name = 'resultados_descripcion'
            label = 'Descripción de resultados y/o productos obtenidos'
            fullWidth
            onChange = { this.onChange }
            inputProps = { { maxLength: CHARACTER_LIMIT } }
          />
        </Grid>
        <Grid item xs = { 12 }>
          <TextField
						className = 'oculto'
            ref = { this.fileInput }
            type = "file"
            id = "info_complementaria"
            name = "info_complementaria"
            label = 'Anexar información complementaria'
            fullWidth
            autoComplete = "billing address-line1"
            onChange = { this.onChange }
          />
					<Grid item xs = { 12 }>
						<InputFile
              label = 'Anexar información complementaria'
              required = { false }
              id = 'info_complementaria'
              value = { info_complementaria }
            />
					</Grid>
        </Grid>
      </Grid>
    )
  }

  renderFinanciacionActividad = () => {
      let { errores, es_financiado, tipo_financiacion, dependencia, fuente_financiacion, entidad, valor_cop,
        valor_ext, tipo_moneda, clasificacion_de_financiacion, tipo_de_concepto, tipos_financiaciones,
        fuentes_financiaciones, dependencias, monedas, tipo_movilidad, financiaciones, tipos_conceptos,
        comprobante_financiacion, modal_quitar_financiacion } = this.state;
        const CHARACTER_LIMIT = 750;
        const accionesFinanciacionActividad = data => {
          // const onClickEditar = data => cargarDatosParticipante(data);
          const onClickDetalle = data => this.setState({ modal_detalle_financiacion: true, financiacion_seleccionada: data });
          const onClickQuitar = data => {
            this.setState({ modal_quitar_financiacion: true, financiacion_seleccionada: data })
          }
          // let editar = !check_masivo && <BtnEditar callback = { () => onClickEditar(data) } />
          let detalle = <BtnDetalle callback = { () => onClickDetalle(data) } color = '#01579b' texto = 'Abrir' />;
          let quitar = <BtnCancelar callback = { () => onClickQuitar(data) } texto = 'Quitar' />
          return <div>{ detalle }{ quitar }</div>;
        }
        const configDetailFinanciacion = data => {
          let { tipo_financiacion, dependencia, fuente_financiacion, entidad, valor_cop, tipo_moneda,
            valor_ext, clasificacion_de_financiacion, tipo_de_concepto, comprobante_financiacion } = data;
          return (
            [
              { 'label': 'Tipo de financiación:', 'value': tipo_financiacion ? tipo_financiacion.nombre : 'Sin diligenciar' },
              { 'label': 'Dependencia:', 'value': dependencia ? dependencia.nombre : 'Sin diligenciar' },
              { 'label': 'Fuente de financiación:', 'value': fuente_financiacion ? fuente_financiacion.nombre : 'Sin diligenciar' },
              { 'label': 'Entidad:', 'value': entidad ? entidad : 'Sin diligenciar' },
              { 'label': 'Valor COP:', 'value': valor_cop ? valor_cop : 'Sin diligenciar' },
              { 'label': 'Tipo de moneda:', 'value': tipo_moneda ? tipo_moneda : 'Sin diligenciar' },
              { 'label': 'Valor moneda extranjera', 'value': valor_ext ? valor_ext : 'Sin diligenciar' },
              { 'label': 'Clasificación de financiación:', 'value': clasificacion_de_financiacion ? clasificacion_de_financiacion.nombre : 'Sin diligenciar' },
              { 'label': 'Otro tipo de concepto:', 'value': tipo_de_concepto ? tipo_de_concepto : 'Sin diligenciar' },
              { 'label': 'Comprobante de financiación:', 'value': comprobante_financiacion ? <BtnDetalle callback={()=>{}} href={comprobante_financiacion} target="_blank" color='#01579b' texto='Abrir' /> : 'Sin diligenciar' },
            ]
          )
        }
    return (
      <Grid container spacing = { 3 } style = { { marginBottom: '40px' } }>
        <Grid item xs = { 12 }>
          <FormControl className = 'form-control' required error = { getError('es_financiado', errores).length > 0 }>
            <InputLabel id = 'es_financiado_label'>¿ Hubo financiación de la actividad ?</InputLabel>
            <Select
              labelId = 'es_financiado_label'
              id = 'es_financiado'
              name = 'es_financiado'
              value = { es_financiado }
              fullWidth
              onChange = { this.onChange }
            >
              <MenuItem value = "1">Sí</MenuItem>
              <MenuItem value = "0">No</MenuItem>
            </Select>
            <FormHelperText>{ getError('es_financiado', errores) }</FormHelperText>
          </FormControl>
        </Grid>
        {/* Modal Agregar Financiacion */}
        <Dialog open={this.props.modalAddFinanciaciones} onClose={()=>this.props.mostrarModalAddFinanciaciones(false)} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="sm">
          <AppBarModal titulo={'¡ Agregar Financiación !'} mostrarModal={this.props.mostrarModalAddFinanciaciones} titulo_accion="" accion="" />
          {/* <Buscar onChange={this.onChange} empresa={empresa} obtenerEmpresas={this.obtenerEmpresas} /> */}
          <DialogContent className='scroll' style={{ padding: 0 }}>
          <>
            <Grid container spacing = { 3 } style = { { marginBottom: '40px', padding: '20px' } }>
                <Grid item xs = { tipo_financiacion ? (tipo_financiacion == TIPO_FINANCIACION_INSTITUCIONAL ? 12 : 6) : 6 }>
                <FormControl className = 'form-control' required error = { getError('tipo_financiacion', errores).length > 0 }>
                    <InputLabel id = 'tipo_financiacion_label'>Tipo de financiación</InputLabel>
                    <Select
                    labelId = 'tipo_financiacion_label'
                    id = 'tipo_financiacion'
                    name = 'tipo_financiacion'
                    value = { tipo_financiacion }
                    label = 'Tipo de financiación'
                    fullWidth
                    onChange = { this.onChange }
                    >
                    { tipos_financiaciones
                      .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                      .map(tipo => <MenuItem key = { tipo.id } value = { tipo.id }>{ tipo.nombre }</MenuItem>)
                      }
                    </Select>
                    <FormHelperText>{ getError('tipo_financiacion', errores) }</FormHelperText>
                </FormControl>
                </Grid>{}
                {
                tipo_financiacion !== '' &&
                    <>
                    {
                        tipo_financiacion == TIPO_FINANCIACION_INSTITUCIONAL ?
                        <Grid item xs = { 6 }>
                            <FormControl className = 'form-control' required error = { getError('dependencia', errores).length > 0 }>
                            <InputLabel id = 'dependencia_label'>Dependencia</InputLabel>
                            <Select
                                labelId = 'dependencia_label'
                                id = 'dependencia'
                                name = 'dependencia'
                                value = { dependencia }
                                label = 'Dependencia'
                                fullWidth
                                onChange = { this.onChange }
                            >
                                { dependencias
                                  .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                                  .map(tipo => <MenuItem key = { tipo.id } value = { tipo.id }>{ tipo.nombre }</MenuItem>)
                                  }
                            </Select>
                            <FormHelperText>{ getError('dependencia', errores) }</FormHelperText>
                            </FormControl>
                        </Grid>
                        : <>
                            <Grid item xs = { 6 }>
                                <FormControl className = 'form-control' required error = { getError('fuente_financiacion', errores).length > 0 }>
                                <InputLabel id = 'fuente_financiacion_label'>Fuente de Financiación</InputLabel>
                                <Select
                                    labelId = 'fuente_financiacion_label'
                                    id = 'fuente_financiacion'
                                    name = 'fuente_financiacion'
                                    value = { fuente_financiacion }
                                    label = 'Fuente de Financiación'
                                    fullWidth
                                    onChange = { this.onChange }
                                >
                                  { fuentes_financiaciones ?
                                    fuentes_financiaciones
                                    // .filter(({ valora }) => fuentes_financiaciones == TIPO_FINANCIACION_INTERNACIONAL ? valora == 'internacional' : valora == 'nacional')
                                    // .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                                    .map((item,index) => <MenuItem key = { item.secundario.id } value = { item.secundario.id }>{ item.secundario.nombre }</MenuItem>)
                                    : <MenuItem value = ""><em>No hay Fuentes de financiación disponibles</em></MenuItem>
                                  }
                                </Select>
                                <FormHelperText>{ getError('fuente_financiacion', errores) }</FormHelperText>
                                </FormControl>
                            </Grid>
                            { fuente_financiacion &&
                              <Grid item xs = { 6 }>
                                  <TextField
                                  value = { entidad }
                                  required
                                  error = { getError('entidad', errores).length > 0 }
                                  helperText = { getError('entidad', errores) }
                                  id = 'entidad'
                                  name = 'entidad'
                                  label = 'Entidad'
                                  fullWidth
                                  onChange = { this.onChange }
                                  />
                              </Grid>
                            }
                            </>
                    }
                    </>
                }
                <Grid item xs = { 6 }>
                <TextField
                    value = { valor_cop }
                    required
                    type = 'number'
                    error = { getError('valor_cop', errores).length > 0 }
                    helperText = { getError('valor_cop', errores) }
                    id = 'valor_cop'
                    name = 'valor_cop'
                    label = { `Indique el valor en $COP` }
                    fullWidth
                    onChange = { this.onChange }
                    InputProps = { { startAdornment: <InputAdornment position = "start">COP$</InputAdornment>, } }
                />
                </Grid>
                <Grid item xs = { 6 }>
                <FormControl className = 'form-control' error = { getError('tipo_moneda', errores).length > 0 }>
                    <InputLabel id = 'tipo_moneda_label'>Moneda extranjera</InputLabel>
                    <Select
                    labelId = 'tipo_moneda_label'
                    id = 'tipo_moneda'
                    name = 'tipo_moneda'
                    value = { tipo_moneda }
                    label = 'Moneda extranjera'
                    fullWidth
                    onChange = { this.onChange }
                    >
                    { monedas
                      .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                      .map(({ code, name }) => <MenuItem key = { name } value = { code }>{ name }</MenuItem>)
                      }
                    </Select>
                    <FormHelperText>{ getError('tipo_moneda', errores) }</FormHelperText>
                </FormControl>
                </Grid>
                { tipo_moneda != '' &&
                  <>
                    <Grid item xs = { 6 }>
                    <TextField
                        value = { valor_ext }
                        type = 'number'
                        error = { getError('valor_ext', errores).length > 0 }
                        helperText = { getError('valor_ext', errores) }
                        id = 'valor_ext'
                        name = 'valor_ext'
                        label = { `Valor en moneda extranjera` }
                        fullWidth
                        onChange = { this.onChange }
                        InputProps = { { startAdornment: <InputAdornment position = "start">{ tipo_moneda ? tipo_moneda : '' }</InputAdornment>, } }
                    />
                    </Grid>
                  </>
                }
                { TIPO_MOVILIDAD_ESTUDIANTES.includes(tipo_movilidad) ?
                  // ESTUDIANTE
                  <Grid item xs = { 6 }>
                  <FormControl className = 'form-control' required error = { getError('clasificacion_de_financiacion', errores).length > 0 }>
                      <InputLabel id = 'clasificacion_de_financiacion_label'>Clasificación de financiación</InputLabel>
                      <Select
                      labelId = 'clasificacion_de_financiacion_label'
                      id = 'clasificacion_de_financiacion'
                      name = 'clasificacion_de_financiacion'
                      value = { clasificacion_de_financiacion }
                      label = 'Clasificación de financiación'
                      fullWidth
                      onChange = { this.onChange }
                      >
                        { tipos_conceptos.length > 0 ?
                            tipos_conceptos
                            // .filter(({ valorg }) => modalidad_escogida == MODALIDAD_VIRTUAL ? valorg == '1' || valorg == '7' : valorg == '1' || valorg == '2' )
                            // .filter(({ valorg }) => valorg == '1' || valorg == '2' )
                            .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                            .map(tipo => <MenuItem key = { tipo } value = { tipo.codigo }>{ tipo.nombre }</MenuItem>)
                          : <MenuItem value = ""><em>No hay clasificaciones disponibles</em></MenuItem>
                        }
                      </Select>
                      <FormHelperText>{ getError('clasificacion_de_financiacion', errores) }</FormHelperText>
                  </FormControl>
                  </Grid>
                :
                  <>
                    {/* DOCENTE */}
                    <Grid item xs = { 6 }>
                    <FormControl className = 'form-control' required error = { getError('clasificacion_de_financiacion', errores).length > 0 }>
                        <InputLabel id = 'clasificacion_de_financiacion_label'>Clasificación de financiación</InputLabel>
                        <Select
                        labelId = 'clasificacion_de_financiacion_label'
                        id = 'clasificacion_de_financiacion'
                        name = 'clasificacion_de_financiacion'
                        value = { clasificacion_de_financiacion }
                        label = 'Clasificación de financiación'
                        fullWidth
                        onChange = { this.onChange }
                        >
                          { tipos_conceptos.length > 0 ?
                            tipos_conceptos
                            .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                            .map(tipo => <MenuItem key = { tipo } value = { tipo.codigo }>{ tipo.nombre }</MenuItem>)
                            : <MenuItem value = ""><em>No hay clasificaciones disponibles</em></MenuItem>
                          }
                        </Select>
                        <FormHelperText>{ getError('clasificacion_de_financiacion', errores) }</FormHelperText>
                    </FormControl>
                    </Grid>
                  </>
                }
                { clasificacion_de_financiacion == CODIGO_TIPO_CONCEPTO_OTRO &&
                  <Grid item xs = { 12 }>
                  <TextField
                      value = { tipo_de_concepto }
                      error = { getError('tipo_de_concepto', errores).length > 0 }
                      helperText = { `${tipo_de_concepto.length}/${CHARACTER_LIMIT}` }
                      id = 'tipo_de_concepto'
                      name = 'tipo_de_concepto'
                      label = 'Indique el otro tipo de concepto'
                      fullWidth
                      required
                      onChange = { this.onChange }
                      inputProps = { { maxLength: CHARACTER_LIMIT } }
                  />
                  </Grid>
                }
                { TIPO_MOVILIDAD_ESTUDIANTES.includes(tipo_movilidad) &&
                  <Grid item xs={12} >
                    <TextField
                      className='oculto'
                      ref={this.fileInput}
                      type="file"
                      id="comprobante_financiacion"
                      name="comprobante_financiacion"
                      label={'Comprobante de financiación'}
                      fullWidth
                      autoComplete="billing address-line1"
                      onChange={this.onChange}
                    />
                    <Grid item xs={12}>
                      <InputFile required={false} label={'Comprobante de financiación'} id='comprobante_financiacion' value={comprobante_financiacion} />
                    </Grid>
                  </Grid>
                }
            </Grid>
        </>
          </DialogContent>
          <DialogActions>
              <BtnForm texto="GUARDAR" callback = { () => this.handleStepFinanciacion() }/>
              <BtnForm texto="CERRAR" callback={() => this.props.mostrarModalAddFinanciaciones(false)} />
          </DialogActions>
        </Dialog>
        {
          es_financiado == 1 &&
          <Grid item xs = { 12 }>
            { <BtnForm texto = "AGREGAR FINANCIACIÓN" callback={() => this.props.mostrarModalAddFinanciaciones(true)} /> }
          </Grid>
        }
        { es_financiado == 1 &&
          <Grid item xs = { 12 }>
            <ListarDatos
              id = "tbl_financiaciones"
              titulo = 'Lista de Financiaciones'
              datos = { financiaciones }
              // opciones = { true }
              sub_titulo = "Lista de financiaciones"
              acciones = { (row) => accionesFinanciacionActividad(row) }
              fila_principal = { ({ tipo_financiacion }) => "FINANCIACIÓN " + tipo_financiacion.nombre.toUpperCase( )}
              filas = { [
                { 'nombre': 'Tipo de financiacion: ', 'id': 'tipo_financiacion', 'mostrar': ({ tipo_financiacion }) => tipo_financiacion.nombre },
                // { 'nombre': 'Fuente de financiacion: ', 'id': 'fuente_financiacion', 'mostrar': ({ fuente_financiacion }) => fuente_financiacion },
                // { 'nombre': 'Entidad: ', 'id': 'entidad', 'mostrar': ({ entidad }) => entidad.nombre },
                // { 'nombre': 'Dependencia: ', 'id': 'dependencia', 'mostrar': ({ dependencia }) => dependencia },
                { 'nombre': 'Clasificacion de financiacion: ', 'id': 'clasificacion_de_financiacion', 'mostrar': ({ clasificacion_de_financiacion }) => clasificacion_de_financiacion.nombre },
                { 'nombre': 'Otra clasificacion de financiacion: ', 'id': 'tipo_de_concepto', 'mostrar': ({ tipo_de_concepto }) => tipo_de_concepto },
                { 'nombre': 'Valor Peso Colombiano: ', 'id': 'valor_cop', 'mostrar': ({ valor_cop }) => "$" + valor_cop },
              ] }
            />
            <ConfirmarAccion
              mensaje1 = { `Se quitará la financiacion de la lista ${this.state.financiacion_seleccionada ? this.state.financiacion_seleccionada.tipo_financiacion.nombre:''} ` }
              ejecutarAccion = { this.quitarFinanciacion }
              mostrarModalConfAccion = { modal_quitar_financiacion => this.setState({ modal_quitar_financiacion }) }
              modalConAccion = { modal_quitar_financiacion }
              dato = { financiaciones }
              titulo = { '¿ Quitar financiacion ?' }
            />
            <Detalle
              titulo = { '¡ Detalle financiacion !' }
              open = { this.state.modal_detalle_financiacion }
              config = { this.state.financiacion_seleccionada.id !== 0 ? configDetailFinanciacion(this.state.financiacion_seleccionada) : [] }
              cerrar = { () => this.setState({ modal_detalle_financiacion: false }) }
            />
          </Grid>
        }
      </Grid>
    )
  }

  renderParticipantes = () => {
    let { agregarParticipanteRegistro, classes } = this.props;
    let { participantes, buscar, modal_editar_participante, institucion_participante, participante_tipo, modal_buscar_institucion,
      modal_quitar_institucion, facultad_participante, niveles_educativos, programas_receptores, facultades, registro_iniciado,
      modal_quitar_participante, participante_seleccionado, dptos_academicos, errores, modal_detalle_participante, check_masivo,
      modal_crear_participante, participante_externo, tipos_identificacion, tipo_movilidad, participante_estudiante, tipo_participante,
      programa_participante, nivel_participante, dpto_participante, dependencias, dependencia_participante, modalMasivo,
      participante, modal_agregar_participantes_masivo } = this.state;
    let tipos_participante = participante_tipo ? [ 'Estudiante' ] : [ 'Docente', 'Administrativo' ];
    // Carga los datos en borrador del participante, si tiene
    const cargarDatosParticipante = data => {
      let { academica, dpto_receptor } = data;
      console.log(academica)
      this.setState({
        institucion_participante: academica[0] ? { id: academica[0].id, institucion: academica[0].institucion, pais: academica[0].pais_vinculante.nombre, ciudad: academica[0].valorb } : { id: 0, institucion: '', pais: '', ciudad: '' },
        facultad_participante: academica[1] ? academica[1].id : '',
        nivel_participante: academica[2] ? academica[2].id : '',
        programa_participante: academica[3] ? academica[3].id : '',
        dpto_participante: dpto_receptor ? dpto_receptor.id : '',
        modal_editar_participante: true,
        participante_seleccionado: data
      });
    }
    // const obtenerEstadoSeleccion = (seleccionados, id) => {
    //   if (seleccionados[id]) {
    //     return seleccionados[id];
    //   }
    //   return false; // Estado predeterminado: no seleccionado
    // };
    // const onClickCheckbox = (id) => {
    //   this.setState((prevState) => {
    //     const seleccionados = { ...prevState.seleccionados };
    //     if (seleccionados[id]) {
    //       // Si ya está seleccionado, lo deseleccionamos
    //       delete seleccionados[id];
    //     } else {
    //       // Si no está seleccionado, lo seleccionamos
    //       seleccionados[id] = true;
    //     }
    //     return { seleccionados };
    //   });
    // };
    const acciones = data => {
      const onClickEditar = data => cargarDatosParticipante(data);
      const onClickDetalle = data => this.setState({ modal_detalle_participante: true, participante_seleccionado: data });
      const onClickQuitar = data => this.setState({ modal_quitar_participante: true, participante_seleccionado: data });

      // const idParticipante = data.id; // Asegúrate de que la propiedad "id" exista en el objeto "data" y represente un identificador único para el participante.

      // const checkboxState = obtenerEstadoSeleccion(this.state.seleccionados, idParticipante);
      // const checkbox = (
      //   <CheckboxIndeterminado
      //     checked={checkboxState}
      //     onChange={() => onClickCheckbox(idParticipante)}
      //   />
      // );

      let editar = !check_masivo && <BtnEditar callback = { () => onClickEditar(data) } />
      let detalle = <BtnDetalle callback = { () => onClickDetalle(data) } color = '#01579b' texto = 'Abrir' />;
      let quitar = <BtnCancelar callback = { () => onClickQuitar(data) } texto = 'Quitar' />
      return <div>{ editar }{ detalle }{ quitar }</div>;
    }
    const onClickLimpiar = data => Object.keys(data).length > 0 && this.setState({ modal_quitar_institucion: true });
    const agregarInstitucionParticipante = (id, institucion, pais_vinculante, ciudad_vinculante ) => {
      this.setState({ institucion_participante: { id, institucion, pais_vinculante, ciudad_vinculante }, // Setea el campo institucion_participante con el valor seleccionado.
        errores: errores.length > 0 ? errores.filter(er => er.llave !== 'institucion_participante') : errores }); // Limpia los errores en caso de haber.
    }

    const configDetail = data => {
      let { nombre_completo, academica, dpto_receptor } = data;
      if (tipo_participante == 'Estudiante') {
        return (
          [
            { 'label': 'Nombre del participante', 'value': nombre_completo, },
            { 'label': 'Institución externa', 'value': academica[0] ? academica[0].institucion : 'Sin diligenciar' },
            { 'label': 'País de institución', 'value': academica[0] ? academica[0].pais_vinculante.nombre : 'Sin diligenciar', },
            { 'label': 'Ciudad de institución', 'value': academica[0] &&  academica[0].ciudad_vinculante? academica[0].ciudad_vinculante: 'No registra', },
            { 'label': 'Facultad receptora', 'value': academica[1] ? academica[1].nombre : 'Sin diligenciar' },
            { 'label': 'Nivel de estudio', 'value': academica[2] ? academica[2].nombre : 'Sin diligenciar' },
            { 'label': 'Programa receptor', 'value': academica[3] ? academica[3].nombre : 'Sin diligenciar' },
          ]
        )
      } else {
        return (
          [
            { 'label': 'Nombre del participante', 'value': nombre_completo, },
            { 'label': 'Institución externa', 'value': academica[0] ? academica[0].institucion: 'Sin diligenciar' },
            { 'label': 'País de institución', 'value': academica[0] ? academica[0].pais_vinculante.nombre: 'Sin diligenciar', },
            { 'label': 'Ciudad de institución', 'value': academica[0] &&  academica[0].ciudad_vinculante? academica[0].ciudad_vinculante: 'No registra', },
            { 'label': 'Dpto. académico receptor', 'value': dpto_receptor ? dpto_receptor.nombre : 'Sin diligenciar' },
            { 'label': 'Programa o Dependencia receptor', 'value': academica[3] ? academica[3].nombre : 'Sin diligenciar' },
          ]
        )
      }
    }
    return (
      <>
        <Grid container spacing = { 3 } style = { { marginBottom: '30px' } }>
          <Grid item xs = { 12 }>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer sx = {{ maxHeight: '300px' }}>
              { participantes.length > 0 ?
                <>
                  { tipo_participante == 'Estudiante' ?
                    <>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow key={0} style={{ padding: '5px' }} >
                            {/* <Checkbox checked = { check_masivo } onChange = { () => this.setState({ check_masivo: !check_masivo }) } /> */}
                            <TableCell style={{ minWidth: '150px', padding: '3px' }} >{/* Espacio para el checkbox */}</TableCell>
                            <TableCell style={{ minWidth: '150px', padding: '3px' }} >Nombre</TableCell>
                            <TableCell style={{ minWidth: '150px', padding: '3px' }} >Identificación</TableCell>
                            <TableCell style={{ minWidth: '150px', padding: '3px' }} >Institución externa</TableCell>
                            <TableCell style={{ minWidth: '150px', padding: '3px' }} >País de institución</TableCell>
                            <TableCell style={{ minWidth: '150px', padding: '3px' }} >Ciudad de institución</TableCell>
                            <TableCell style={{ minWidth: '150px', padding: '3px' }} >Facultad receptora</TableCell>
                            <TableCell style={{ minWidth: '150px', padding: '3px' }} >Nivel de estudio</TableCell>
                            <TableCell style={{ minWidth: '150px', padding: '3px' }} >Programa receptor</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {participantes.map((participante, i) => (
                              <TableRow key={i++} style={{ padding: '5px' }} >
                                {/* <Checkbox></Checkbox> */}
                                <TableCell style={{ minWidth: '170px' }} >{ acciones(participante) }</TableCell> {/* acciones */}
                                <TableCell style={{ minWidth: '150px', padding: '3px' }} >{ participante.nombre_completo.toUpperCase() }</TableCell>
                                <TableCell style={{ minWidth: '150px', padding: '3px' }} >{ participante.identificacion }</TableCell>
                                <TableCell style={{ minWidth: '150px', padding: '3px' }} >{ participante.academica[0] ? participante.academica[0].institucion : 'Sin diligenciar' }</TableCell>
                                <TableCell style={{ minWidth: '150px', padding: '3px' }} >{ participante.academica[0]? participante.academica[0].pais_vinculante.nombre : 'Sin diligenciar' }</TableCell>
                                <TableCell style={{ minWidth: '150px', padding: '3px' }} >{ participante.academica[0] &&  participante.academica[0].ciudad_vinculante? participante.academica[0].ciudad_vinculante: 'No registra'}</TableCell>
                                <TableCell style={{ minWidth: '150px', padding: '3px' }} >{ participante.academica[1] ? participante.academica[1].nombre : 'Sin diligenciar' }</TableCell>
                                <TableCell style={{ minWidth: '150px', padding: '3px' }} >{ participante.academica[2] ? participante.academica[2].nombre : 'Sin diligenciar' }</TableCell>
                                <TableCell style={{ minWidth: '150px', padding: '3px' }} >{ participante.academica[3] ? participante.academica[3].nombre : 'Sin diligenciar' }</TableCell>
                              </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </>
                  : <>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow key={0} style={{ padding: '5px' }} >
                          {/* <Checkbox checked = { check_masivo } onChange = { () => this.setState({ check_masivo: !check_masivo }) } /> */}
                          <TableCell style={{ minWidth: '150px', padding: '3px' }} >{/* Espacio para el checkbox */}</TableCell>
                          <TableCell style={{ minWidth: '150px', padding: '3px' }} >Nombre</TableCell>
                          <TableCell style={{ minWidth: '150px', padding: '3px' }} >Identificación</TableCell>
                          <TableCell style={{ minWidth: '150px', padding: '3px' }} >Institución externa</TableCell>
                          <TableCell style={{ minWidth: '150px', padding: '3px' }} >País de institución</TableCell>
                          <TableCell style={{ minWidth: '150px', padding: '3px' }} >Ciudad de institución</TableCell>
                          <TableCell style={{ minWidth: '150px', padding: '3px' }} >Dpto. académico receptor</TableCell>
                          <TableCell style={{ minWidth: '150px', padding: '3px' }} >Programa o Dependencia receptor</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {participantes.map((participante, i) => (
                            <TableRow key={i++} style={{ padding: '5px' }} >
                              {/* <Checkbox></Checkbox> */}
                              <TableCell style={{ minWidth: '170px' }} >{ acciones(participante) }</TableCell> {/* acciones */}
                              <TableCell style={{ minWidth: '150px', padding: '3px' }} >{ participante.nombre_completo.toUpperCase() }</TableCell>
                              <TableCell style={{ minWidth: '150px', padding: '3px' }} >{ participante.identificacion }</TableCell>
                              <TableCell style={{ minWidth: '150px', padding: '3px' }} >{ participante.academica[0] ? participante.academica[0].institucion  : 'Sin diligenciar' }</TableCell>
                              <TableCell style={{ minWidth: '150px', padding: '3px' }} >{  participante.academica[0] ? participante.academica[0].pais_vinculante.nombre  : 'Sin diligenciar' }</TableCell>
                              <TableCell style={{ minWidth: '150px', padding: '3px' }} >{ participante.academica[0] &&  participante.academica[0].ciudad_vinculante? participante.academica[0].ciudad_vinculante: 'No registra'}</TableCell>
                              <TableCell style={{ minWidth: '150px', padding: '3px' }} >{ participante.dpto_receptor ? participante.dpto_receptor.nombre : 'Sin diligenciar' }</TableCell>
                              <TableCell style={{ minWidth: '150px', padding: '3px' }} >{ participante.academica[3] ? participante.academica[3].nombre : 'Sin diligenciar' }</TableCell>
                            </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    </>
                  }
                </>
                :
                <>
                  <Grid item xs = { 12 }>
                    <p style={{ textAlign: 'center' }} >No hay participantes.</p>
                  </Grid>
                </>
              }
              </TableContainer>
            </Paper>
            <ConfirmarAccion
              mensaje1 = { `Se quitará ${participante_seleccionado.nombre_completo} de la lista, ` }
              ejecutarAccion = { this.quitarParticipante }
              mostrarModalConfAccion = { modal_quitar_participante => this.setState({ modal_quitar_participante }) }
              modalConAccion = { modal_quitar_participante }
              dato = { participante_seleccionado }
              titulo = { '¿ Quitar participante ?' }
            />
            <Detalle
              titulo = { '¡ Detalle Participante !' }
              open = { modal_detalle_participante }
              config = { participante_seleccionado.id !== 0 ? configDetail(participante_seleccionado) : [] }
              cerrar = { () => this.setState({ modal_detalle_participante: false }) }
            />
          </Grid>
          {
            participantes.length ?
              <Grid item xs = { 12 } style = { { padding : '0 12px' } }>
                <FormControlLabel
                  control = {
                    <Checkbox
                      checked = { check_masivo }
                      onChange = { this.checked }
                      id = "check_masivo"
                      name = "check_masivo"
                      color = "primary"
                    />
                  }
                  label = "¿Desea enviar la información academica de forma masiva?"
                />
              </Grid>
              : ""
          }
          <Dialog fullWidth = { true } maxWidth = "sm" open = { buscar } onClose = { () => this.setState({ buscar: false }) }>
            <AppBarModal titulo = {'¡ Buscar Persona !'} mostrarModal = { () => this.setState({ buscar: false }) } titulo_accion = "CERRAR" accion = { () => this.setState({ buscar: false }) } />
            <DialogContent style = { { padding: '0' } } className = 'scroll'>
              <BuscarPersona
                callback = { ({ id }) => {
                  agregarParticipanteRegistro(registro_iniciado, // id
                    { registro: registro_iniciado, participante: id }, // data
                    async () => this.setState({ participantes: await this.obtenerDataParticipantes(registro_iniciado) })); //Agregar el participante a la bd y a la lista en el callback
                } }
                seleccionarVarios = { true } // Poder seleccionar varios
                listaSeleccionados = { participantes } // Lista para ir emparejando con los que ya están seleccionados
                // buscarEstudiante = { TIPO_MOVILIDAD_ESTUDIANTES.includes(tipo_movilidad) ? '1' : '0' } // Filtro para buscar solo estudiantes o profesores
                // buscarTipo = { TIPO_MOVILIDAD_ENTRANTE.includes(tipo_movilidad) ? '2' : '1' } // Filtro para buscar solo entrantes o salientes
              />
            </DialogContent>
            <DialogActions>
              {/* { TIPO_MOVILIDAD_ENTRANTE.includes(tipo_movilidad) ? <BtnForm texto = "AGREGAR MAXIVO" callback = { () => this.setState({ modal_agregar_participantes_masivo: true }) } /> : '' } */}
              { TIPO_MOVILIDAD_ENTRANTE.includes(tipo_movilidad) ? <BtnForm texto = "AGREGAR PERSONA" callback = { () => this.setState({ modal_crear_participante: true }) } /> : '' }
              {/* <BtnForm texto = "CANCELAR" callback = { () => this.setState({ buscar: false }) } /> */}
              <BtnForm texto = "CANCELAR" callback = { () => this.setState({ buscar: false }) } />
            </DialogActions>
            {/* { TIPO_MOVILIDAD_ENTRANTE.includes(tipo_movilidad) ? <Agregar actAgregar = { () => this.setState({ modal_crear_participante: true }) } /> : '' } */}
          </Dialog>
        </Grid>
        <Dialog fullWidth = { true } maxWidth = "sm" open = { modal_editar_participante } onClose = { () => this.setState({ modal_editar_participante: false }) }>
          <AppBarModal titulo = {'¡ Buscar Persona !'} mostrarModal = { () => this.setState({ modal_editar_participante: false }) } titulo_accion = "CERRAR" accion = { () => this.setState({ modal_editar_participante: false }) } />
          <DialogContent className = 'scroll'>
            <Grid container spacing = { 3 }>
              <Grid item xs = { 9 } md = { 9 }>
                <TextField
                  value = { institucion_participante.institucion }
                  required
                  error = { getError('institucion_participante', errores).length > 0 }
                  helperText = { getError('institucion_participante', errores) }
                  id = 'institucion_participante'
                  name = 'institucion_participante'
                  label = 'Institución externa'
                  fullWidth
                  InputLabelProps = { {
                    shrink: true,
                    readOnly: true,
                  } }
                />
              </Grid>
              <Grid item xs = { 3 } md = { 3 } >
                <ConvocatoriasBuscarConvenio
                  seleccionar = { ({ id, institucion, pais_vinculante, ciudad_vinculante, codigo_convenio }) => agregarInstitucionParticipante(id, institucion, pais_vinculante, ciudad_vinculante, codigo_convenio) }
                  quitar = { () => this.setState({ institucion_participante: { id: 0, nombre: '', pais: '', ciudad: '', codigo_convenio: '' } }) }
                  seleccionadas = { [institucion_participante] }
                  modalBuscarUniversidad = { modal_buscar_institucion }
                  mostrarModalBuscarUniversidad = { (modal_buscar_institucion) => this.setState({ modal_buscar_institucion }) }
                />
                <ConfirmarAccion
                  mensaje1 = { `Se quitará ${institucion_participante.nombre}, ` }
                  ejecutarAccion = { () => this.setState({ institucion_participante: { id: 0, nombre: '', pais: '', ciudad: '' }, modal_quitar_institucion: false }) }
                  mostrarModalConfAccion = { (modal_quitar_institucion) => this.setState({ modal_quitar_institucion }) }
                  modalConAccion = { modal_quitar_institucion }
                  dato = { institucion_participante ? institucion_participante : { id: 0 } }
                  titulo = { '¿ Quitar institución ?' }
                />
                <IconButton aria-label = "add"  className = { classes.colorEmmma } onClick = { () => this.setState({ modal_buscar_institucion: true }) }>
                  <AddIcon />
                </IconButton>
                <IconButton aria-label = "clear" className = { classes.colorDelete } onClick = { () => onClickLimpiar(institucion_participante) }>
                  <ClearIcon />
                </IconButton>
              </Grid>
              <Grid item xs = { 6 } md = { 6 }>
                <TextField
                  value = { institucion_participante.pais_vinculante? institucion_participante.pais_vinculante.nombre:institucion_participante?institucion_participante.pais:""}
                  required
                  id = 'pais_movilidad'
                  name = 'pais_movilidad'
                  label = 'País de la institución'
                  fullWidth
                  InputLabelProps = { {
                    shrink: true,
                    readOnly: true,
                  } }
                />
              </Grid>
              <Grid item xs = { 6 } md = { 6 }>
                <TextField
                  value = { institucion_participante? institucion_participante.ciudad_vinculante: "No registra"}
                  required
                  disabled="true"
                  id = 'ciudad_movilidad'
                  name = 'ciudad_movilidad'
                  label = 'Ciudad de la institución'
                  fullWidth
                  InputLabelProps = { {
                    shrink: true,
                    readOnly: true,
                  } }
                />
              </Grid>
              <Grid item xs = { 12 } md = { 12 }>
                <FormControl className = 'form-control' required error = { getError('tipo_participante', errores).length > 0 }>
                  <InputLabel id = 'tipo_participante_label'>Tipo de participante</InputLabel>
                  <Select
                    labelId = 'tipo_participante_label'
                    id = 'tipo_participante'
                    disabled = { participante_tipo }
                    name = 'tipo_participante'
                    value = { tipo_participante }
                    fullWidth
                    onChange = { this.onChange }
                  >
                    { tipos_participante.map((tipo) => <MenuItem key = { tipo } value = { tipo }>{ tipo }</MenuItem>) }
                  </Select>
                  <FormHelperText>{ getError('tipo_participante', errores) }</FormHelperText>
                </FormControl>
              </Grid>
              {
                tipo_participante == 'Estudiante' &&
                <>
                  <Grid item xs = { 6 } md = { 6 }>
                    <FormControl className = 'form-control' required error = { getError('facultad_participante', errores).length > 0 }>
                      <InputLabel id = 'facultad_receptora_label'>Facultad receptora</InputLabel>
                      <Select
                        labelId = 'facultad_receptora_label'
                        id = 'facultad_participante'
                        name = 'facultad_participante'
                        value = { facultad_participante }
                        fullWidth
                        onChange = { this.onChange }
                      >
                        { facultades.map(({ id, nombre }) => <MenuItem key = { id } value = { id }>{ nombre }</MenuItem>) }
                      </Select>
                      <FormHelperText>{ getError('facultad_participante', errores) }</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs = { 6 } md = { 6 }>
                    <FormControl className = 'form-control' required error = { getError('nivel_participante', errores).length > 0 }>
                      <InputLabel id = 'nivel_educativo_label'>Nivel de estudio</InputLabel>
                      <Select
                        labelId = 'nivel_educativo_label'
                        id = 'nivel_participante'
                        name = 'nivel_participante'
                        value = { nivel_participante }
                        fullWidth
                        onChange = { this.onChange }
                      >
                        {
                          facultad_participante == FACULTAD_POSGRADOS ?
                            niveles_educativos.map(({ id, nombre, valora }) => valora == 'posgrado' && <MenuItem key = { id } value = { id }>{ nombre }</MenuItem>)
                            : niveles_educativos.map(({ id, nombre, valora }) => valora == 'pregrado' && <MenuItem key = { id } value = { id }>{ nombre }</MenuItem>)
                        }
                      </Select>
                      <FormHelperText>{ getError('nivel_participante', errores) }</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs = { 6 } md = { 6 }>
                    <FormControl className = 'form-control' required error = { getError('programa_participante', errores).length > 0 }>
                      <InputLabel id = 'programa_receptor_label'>Programa receptor</InputLabel>
                      <Select
                        labelId = 'programa_receptor_label'
                        id = 'programa_participante'
                        name = 'programa_participante'
                        value = { this.state.programa_participante }
                        fullWidth
                        onChange = { this.onChange }
                      >
                        {
                          programas_receptores.length > 0 ?
                          programas_receptores
                              .map(({ id, nombre }) => <MenuItem key = { id } value = { id }>{ nombre }</MenuItem>)
                            : <MenuItem value = ""><em>No hay programas académicos disponibles</em></MenuItem>
                        }
                      </Select>
                      <FormHelperText>{ getError('programa_participante', errores) }</FormHelperText>
                    </FormControl>
                  </Grid>
                </>
              }
              {
                tipo_participante == 'Docente' &&
                <>
                  <Grid item xs = { 6 } md = { 6 }>
                    <FormControl className = 'form-control' required error = { getError('dpto_participante', errores).length > 0 }>
                      <InputLabel id = 'dpto_participante_label'>Departamento receptor</InputLabel>
                      <Select
                        labelId = 'dpto_participante_label'
                        id = 'dpto_participante'
                        name = 'dpto_participante'
                        value = { dpto_participante }
                        fullWidth
                        onChange = { this.onChange }
                      >
                        {
                          dptos_academicos
                          .length > 0 ?
                          dptos_academicos
                          .filter(({ valorc }) => valorc == 'docente')
                          .map(({ id, nombre }) => <MenuItem key = { id } value = { id }>{ nombre }</MenuItem>)
                          : <MenuItem value = ""><em>No hay departamentos académicos disponibles</em></MenuItem>
                        }
                      </Select>
                      <FormHelperText>{ getError('dpto_participante', errores) }</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs = { 6 } md = { 6 }>
                    <FormControl className = 'form-control' required error = { getError('programa_participante', errores).length > 0 }>
                      <InputLabel id = 'programa_receptor_label'>Programa receptor</InputLabel>
                      <Select
                        labelId = 'programa_receptor_label'
                        id = 'programa_participante'
                        name = 'programa_participante'
                        value = { programa_participante }
                        fullWidth
                        onChange = { this.onChange }
                      >
                        {
                          this.state.programas_receptores_docente.length > 0 ?
                          this.state.programas_receptores_docente
                              // .map((item,index) => <MenuItem key = { index } value = { item.secundario.id }>{ item.secundario.nombre }</MenuItem>)
                              .map(({secundario},index) => <MenuItem key = { index } value = { secundario.id }>{ secundario.nombre }</MenuItem>)
                            : <MenuItem value = ""><em>No hay programas académicos disponibles</em></MenuItem>
                        }
                      </Select>
                      <FormHelperText>{ getError('programa_participante', errores) }</FormHelperText>
                    </FormControl>
                  </Grid>
                </>
              }
              {
                tipo_participante == 'Administrativo' &&
                <>
                  <Grid item xs = { 6 } md = { 6 }>
                    <FormControl className = 'form-control' required error = { getError('dpto_participante', errores).length > 0 }>
                      <InputLabel id = 'dpto_participante_label'>Departamento receptor</InputLabel>
                      <Select
                        labelId = 'dpto_participante_label'
                        id = 'dpto_participante'
                        name = 'dpto_participante'
                        value = { dpto_participante }
                        fullWidth
                        onChange = { this.onChange }
                      >
                        {
                          dptos_academicos
                          .length > 0 ?
                          dptos_academicos
                          .filter(({ valorc }) => valorc == 'administrativo')
                          .map(({ id, nombre }) => <MenuItem key = { id } value = { id }>{ nombre }</MenuItem>)
                          : <MenuItem value = ""><em>No hay departamentos académicos disponibles</em></MenuItem>
                        }
                      </Select>
                      <FormHelperText>{ getError('dpto_participante', errores) }</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs = { 6 } md = { 6 }>
                    <FormControl className = 'form-control' required error = { getError('dependencia_participante', errores).length > 0 }>
                      <InputLabel id = 'dependencia_receptora_label'>Dependencia receptora</InputLabel>
                      <Select
                        labelId = 'dependencia_receptora_label'
                        id = 'programa_participante'
                        name = 'programa_participante'
                        value = { programa_participante }
                        fullWidth
                        onChange = { this.onChange }
                      >
                        {
                          dependencias.length > 0 ?
                          dependencias.map(({ id, nombre }) => <MenuItem key = { id } value = { id }>{ nombre }</MenuItem>)
                            : <MenuItem value = ""><em>No hay dependencias disponibles</em></MenuItem>
                        }
                      </Select>
                      <FormHelperText>{ getError('programa_participante', errores) }</FormHelperText>
                    </FormControl>
                  </Grid>
                </>
              }
            </Grid>
          </DialogContent>
          <DialogActions>
            <BtnForm texto = "GUARDAR" callback = { () => this.guardarDatosParticipanteRegistro() } />
          </DialogActions>
        </Dialog>
        { TIPO_MOVILIDAD_ENTRANTE.includes(tipo_movilidad) ?
        <EnviarArchivo
          modalAdd={modalMasivo}
          mostrarModalAdd={() => this.setState({ modalMasivo: false })}
          enviarArchivo={(file) => this.enviarParticipantesMasivoEntrante(file, registro_iniciado)}
          titulo="Cargar Plantilla Entrantes"
          formatos='.xlsm'
          mensaje={
          <Grid>
            Tenga en cuenta las siguientes indicaciones:
            <List dense={true}>
              <ListItem>
                <ListItemText primary={<Link href={plantilla_agr_part_ext_entr} target="_blank" underline='none'>Descargue esta plantilla y anexe los datos.</Link>}/>
              </ListItem>
              <ListItem>
                <ListItemText primary='No realice cambios en el formato de la plantilla.'/>
              </ListItem>
            </List>
          </Grid>
          }
        />: '' }
        { TIPO_MOVILIDAD_SALIENTE.includes(tipo_movilidad) ?
        <EnviarArchivo
          modalAdd={modalMasivo}
          mostrarModalAdd={() => this.setState({ modalMasivo: false })}
          enviarArchivo={(file) => this.enviarParticipantesMasivo(file, registro_iniciado)}
          titulo="Cargar Plantilla Salientes"
          formatos='.xlsm'
          mensaje={
          <Grid>
            Tenga en cuenta las siguientes indicaciones:
            <List dense={true}>
              <ListItem>
                <ListItemText primary={<Link href={plantilla_agr_part_ext_sal} target="_blank" underline='none'>Descargue esta plantilla y anexe los datos.</Link>}/>
              </ListItem>
              <ListItem>
                <ListItemText primary='No realice cambios en el formato de la plantilla.'/>
              </ListItem>
            </List>
          </Grid>
          }
        />: '' }
        <ParticipanteExtCrear { ...{
          modal: modal_crear_participante,
          mostrar_modal: resp => this.setState({ modal_crear_participante: resp }),
          participante_externo,
          onChange: this.onChangeParticipanteExt,
          guardarDatosParticipanteExterno: this.guardarDatosParticipanteExterno,
          errores,
          tipos_identificacion
        } } />
        {/* <ParticipanteExtMas { ...{
          modal: modal_agregar_participantes_masivo,
          mostrar_modal: resp => this.setState({ modal_agregar_participantes_masivo: resp }),
          participante_externo,
          onChange: this.onChangeParticipanteExtMas,
          guardarDatosParticipanteExterno: this.guardarDatosParticipanteExterno,
          errores,
          tipos_identificacion
        } } /> */}
      </>
    )
  }

  renderEvidencias = () => {
    let { modificarEvidencia } = this.props;
    let { certificados_participantes, enlace_grabacion, modalidad_escogida, evidenciasAdjuntos } = this.state;
    const transformarDataAdj = data => {
      let e = [];
      data.map(evid => e.push({ codigo: evid.id, nombre: evid.requisito.nombre, descripcion: evid.requisito.descripcion,
        formatos: evid.requisito.valorg, archivo: evid.requisito.archivo, adjunto: evid.archivo,
        nombre_adjunto: evid.nombre_archivo }));
      return e;
    }
    const acciones = (data, callback) => {
      const onClickCargar = data => callback(data);
      let descargar = data.archivo && <BtnDescargar callback = { () => window.open(`${data.archivo}`, '_blank') } color = '#01579b' />
      let cargar = <BtnCargar callback = { () => onClickCargar(data) } color = '#00bfa5' />
      return <div>{ descargar } { cargar }</div>;
    }
    return (
      <React.Fragment>
        <Grid container spacing = { 3 }>
          <Grid item xs = { 12 }>
            <Paper elevation = { 0 } square>
              <List component = 'nav' arial-label = 'main'>
                <EnviarMultiplesArchivos
                  acciones = { acciones }
                  enModal = { false }
                  enviarArchivos = { (archivos) => this.submit(archivos) }
                  limite = { 2 }
                  mostrarMensaje = { false }
                  enLista = { true }
                  lista = { transformarDataAdj(evidenciasAdjuntos) }
                  formatos = { true }
                  cargadoAutomatico = { true }
                  cargarArchivo = { modificarEvidencia }
                />
              </List>
            </Paper>
          </Grid>
          {
            CODIGOS_MODALIDADES_VIRTUALES.includes(modalidad_escogida.codigo) &&
              <Grid item xs = { 6 }>
                <TextField
                  value = { enlace_grabacion }
                  id = 'enlace_grabacion'
                  name = 'enlace_grabacion'
                  label = 'Enlace de grabación'
                  fullWidth
                  onChange = { this.onChange }
                />
              </Grid>
          }
          <Grid item xs = { CODIGOS_MODALIDADES_VIRTUALES.includes(modalidad_escogida.codigo) ? 6 : 12 }>
            <TextField
              className = 'oculto'
              ref = { this.fileInput }
              type = "file"
              id = "certificados_participantes"
              name = "certificados_participantes"
              label = 'Certificados de participantes'
              fullWidth
              autoComplete = "billing address-line1"
              onChange = { this.onChange }
            />
            <Grid item xs = { 12 }>
              <InputFile label = 'Certificados de participantes' id = 'certificados_participantes' required = { false } value = { certificados_participantes }/>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }

  render() {
    let { modalAddRegistro, mostrarModalAddRegistro, mostrarModalCancelarSolicitud, set_datos_solicitud } = this.props;
    let { borrador_activo, cargando, tit_cargando, activeStep, solicitud_iniciada, check_masivo } = this.state;
    // let titulo = "¡ Agregar Registro de Movilidad !";
    const onClickDescartar = data => {
      mostrarModalCancelarSolicitud(true);
      set_datos_solicitud(data);
    }
    return (
      <>

        <Dialog fullWidth = { true } maxWidth = 'md' open = { modalAddRegistro } onClose = { () => mostrarModalAddRegistro(false) }>
          <AppBarModal
            titulo = { '¡ Agregar Registro de Movilidad !' }
            // titulo = { `${titulo} ${titulo_movilidad ? titulo_movilidad : ''}` }
            // titulo = { `${titulo}` }
            mostrarModal = { () => mostrarModalAddRegistro(false) }
            titulo_accion = { borrador_activo ? 'Descartar' : 'Cerrar' }
            accion = { borrador_activo ? () => onClickDescartar(solicitud_iniciada) : () => mostrarModalAddRegistro(false) }
          />
          {
            !cargando ?
              <>
                <DialogContent className = 'scroll'>
                  <Grid container>
                    <Grid item xs = { 12 } md = { 12 }>
                      <Stepper activeStep = { activeStep }>
                        {
                          configStep.map(label => (
                            <Step key = { label }>
                              <StepLabel><p>{ label }</p></StepLabel>
                            </Step>
                          ))
                        }
                      </Stepper>
                    </Grid>
                    <Grid item xs = { 12 }>
                      { this.configStepContent() }
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  {
                    activeStep === 0 &&
                      <div>
                        <BtnForm texto = "CERRAR" callback = { () => mostrarModalAddRegistro(false) } />
                        <BtnForm texto = "CONTINUAR" callback = { () => this.handleStep() } />
                      </div>
                  }
                  {
                    (activeStep === 1 || activeStep === 2) &&
                      <div>
                        <BtnForm texto = "REGRESAR" callback = { () => this.setState({ activeStep: activeStep - 1 }) } />
                        <BtnForm texto = "SIGUIENTE" callback = { () => this.handleStep() } />
                      </div>
                  }
                  {
                    activeStep === 3 &&
                      <div>
                        { check_masivo && <BtnForm texto = "EDITAR" callback = { () => this.setState({ modal_editar_participante: true }) } /> }
                        <BtnForm texto = "PARTICIPANTES MASIVO" callback = { () => this.setState({ modalMasivo: true }) } />
                        <BtnForm texto = "AGREGAR PARTICIPANTE" callback = { () => this.setState({ buscar: true }) } />
                        <BtnForm texto = "REGRESAR" callback = { () => this.handleStepParticipante(false) } />
                        <BtnForm texto = "SIGUIENTE" callback = { () => this.handleStepParticipante(true) } />
                      </div>
                  }
                  {
                    activeStep === 4 &&
                      <div>
                        <BtnForm texto = "REGRESAR" callback = { () => this.setState({ activeStep: activeStep - 1 }) } />
                        <BtnForm texto = "FINALIZAR" callback = { () => document.getElementById('enviar_form_multi_archivo').click() } />
                      </div>
                  }
                </DialogActions>
              </>
            : <TareasTerminadas mensaje = { tit_cargando } marginTop = '7%' imagen = { emma_w } widthImg = '7%' cargando={true} />
          }
        </Dialog>
      </>
    )
  }
}

export default withStyles(useStyles)(RegistrosCrear);