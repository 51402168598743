import React, { Component } from 'react'

// FUNCIONES GLOBLAES - GENERALES
import ListarDatos from '../../general/ListarDatos';
import { consulta, rutaImg } from "../../../global/js/funciones";
import TareasTerminadas from "../../general/TareasTerminadas";
import { BtnDetalle, BtnPermiso, BtnForm } from '../../general/BotonesAccion';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import Generica from "./../../personas/generica/Generica";
import AppBarModal from "./../../general/AppBarModal";

// IMAGENES
import emma_w from '../../../global/imagenes/emma_w.png';

// HELPERTS
import {ESTADOS} from '../Helpers'

class ValidacionesPermisos extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cargando: true,
            personas: [],
            generica: {
                tipo: 0,
                id: 0,
              },
            modal: false,
            id_persona: null,

        }
    }

    async componentDidMount() {
        let personas = await this.obtenerGestores()
        this.setState({ personas, cargando: false })
    }


    async obtenerGestores() {
        return new Promise(resolve => {
          consulta(`api/v1.0/validaciones/encargados`, null, null, (error, estado, resp) => {
            resolve(estado === 200 && !error ? resp : null);
          })
        })
    }

    acciones(data) {
        const onClickTipo = (data) => this.setState({ id_persona: data.id, modal: true, generica: { tipo: 19, id: 5 } });
        const onClickEstado = (data) => this.setState({ id_persona: data.id, modal: true, generica: { tipo: 20, id: ESTADOS } });
        let tipos = <BtnDetalle callback={() => onClickTipo(data)} texto='Programas' color='#2196f3' />;
        let estados = <BtnPermiso callback={() => onClickEstado(data)} texto='Estados' />;
        return <div>{tipos} {estados}</div>
      }

    render() {
        let { modal, id_persona, generica, cargando, personas } = this.state;
        if (cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />;
        return(
            <div>
            <ListarDatos
                id="tbl_becas_permisos"
                titulo='Lista Gestores'
                descargar="EMMA - GESTORES BECAS"
                datos={personas}
                opciones={true}
                buscar={true}
                acciones={row => this.acciones(row)}
                avatarImg={true}
                avatar={({ identificacion }) => `${rutaImg}${identificacion}.jpg`}
                fila_principal={row => `${row.primer_nombre} ${row.segundo_nombre} ${row.primer_apellido} ${row.segundo_apellido}`.toUpperCase()}
                filas={[
                { 'mostrar': ({ correo }) => `${correo}`, 'id': 'correo', 'enLista': true },
                { 'mostrar': ({ identificacion }) => `${identificacion}`, 'id': 'identificacion', 'enLista': true }
                ]}
            />


            <Dialog open={modal} fullWidth={true} maxWidth='sm' onClose={() => this.setState({ modal: false })}>
                <AppBarModal titulo='¡ Lista Permisos !' mostrarModal={() => this.setState({ modal: false })} accion={() => this.setState({ modal: false })} titulo_accion='CERRAR' />
                    <DialogContent style={{ padding: 0 }} className='scroll'>
                        <Generica notificaciones={(generica.tipo == 20)} tipo={generica.tipo} persona={{ id: id_persona }} genericaRelacionId={generica.id} titulo="¡ Nuevo Permiso !" tituloInput="Permisos" tituloEliminar='¿ Eliminar Permiso ?' />
                    </DialogContent>
                    <DialogActions>
                        <BtnForm texto="Cerrar" callback={() => this.setState({ modal: false })} />
                    </DialogActions>
            </Dialog>
            </div>
        )
    }
}


export default ValidacionesPermisos
