import React, { useRef, useState, useEffect } from "react";

// Material UI
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";

// Global Functions
import formatoMaestriaEducacion from "../../global/archivos/formatoMaestriaEducacion.docx";

// General Components
import InputFile from "../general/InputFile";
import { BtnDescargar } from "../general/BotonesAccion";
import { Alert } from "@material-ui/lab";
import { MAESTRIA_EDUCACION } from "./helper";

const InscripcionPosgradoCarrera = ({ propsDatos }) => {
  const fileInput = useRef(null);
  const [nivelFormacion, setNivelFormacion] = useState("");
  const [nivelesFormacion, setNivelesFormacion] = useState([
    { value: "maestría", label: "MAESTRÍA" },
    { value: "doctorado", label: "DOCTORADO" },
    { value: "especialización", label: "ESPECIALIZACIÓN" },
  ]);
  const [carreras, setCarreras] = useState([]);

  useEffect(() => {
    if (nivelFormacion) {
      setCarreras(
        propsDatos.carreras.filter((item) => item.valord === nivelFormacion)
      );
    } else {
      setCarreras(propsDatos.carreras);
    }
  }, [nivelFormacion]);

  const descargarFormato = () => {
    window.open(formatoMaestriaEducacion, "_blank");
  };

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Datos finales
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} className="oculto">
          <TextField
            ref={fileInput}
            required
            type="file"
            id="adjunto_ensayo"
            name="adjunto_ensayo"
            fullWidth
            autoComplete="billing adders-line1"
            onChange={(e) => propsDatos.setAdjuntoEnsayo(e.target.value)}
          />
          <TextField
            ref={fileInput}
            required
            type="file"
            id="adjunto_formato_maestria_educacion"
            name="adjunto_formato_maestria_educacion"
            fullWidth
            autoComplete="billing adders-line1"
            onChange={(e) =>
              propsDatos.setFormatoMaestriaEducacion(e.target.value)
            }
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl className="form-control">
            <InputLabel>Nivel de formación al que aspira cursar</InputLabel>
            <Select
              value={nivelFormacion}
              id="select_nivel_formacion"
              name="nivelFormacion"
              onChange={(e) => setNivelFormacion(e.target.value)}
              placeholder="Seleccione una opción"
            >
              {nivelesFormacion.map((item, index) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl
            className="form-control"
            error={Boolean(propsDatos.error.carrera)}
          >
            <InputLabel>Seleccione el programa al que desea ingresar *</InputLabel>
            <Select
              value={propsDatos.carrera}
              id="select-carreras"
              name="carrera"
              onChange={(e) => propsDatos.setCarrera(e.target.value)}
              required
            >
              {carreras.length > 0 ? (
                carreras.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item.label}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled value="">
                  No hay carreras disponibles
                </MenuItem>
              )}
            </Select>
            <FormHelperText>{propsDatos.error.carrera}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            className="form-control"
            error={Boolean(propsDatos.error.opcionConocisteInstitucion)}
          >
            <InputLabel>¿Como nos conociste? *</InputLabel>
            <Select
              value={propsDatos.opcionConocisteInstitucion}
              id="select-carreras"
              name="carrera"
              onChange={(e) =>
                propsDatos.setOpcionConocisteInstitucion(e.target.value)
              }
              required
            >
              {propsDatos.opcionesConocisteInstitucion.map((item, index) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {propsDatos.error.opcionConocisteInstitucion}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={propsDatos.checkEstudioHomologacion}
                onChange={(e) =>
                  propsDatos.setCheckEstudioHomologacion(e.target.checked)
                }
                id="cehck-estudio-homologacion"
                name="checkEstudioHomologacion"
                color="primary"
              />
            }
            label="¿Desea realizar un estudio de homologación?"
          />
        </Grid>
        {propsDatos.checkEstudioHomologacion && (
          <Grid item xs={12}>
            <Alert severity="info">
              Señor aspirante, se le informa que su solicitud de homologación se
              encuentra sujeta a estudio.
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={propsDatos.checkTrabaja}
                onChange={(e) => propsDatos.setCheckTrabaja(e.target.checked)}
                id="cehck-trabaja"
                name="checkTrabaja"
                color="primary"
              />
            }
            label="¿Trabaja este período mientras estudia?"
          />
        </Grid>
        {propsDatos.checkTrabaja && (
          <>
            <Grid item xs={12}>
              <TextField
                value={propsDatos.nombreEmpresa}
                required
                type="text"
                id="nombre_empresa"
                name="nombreEmpresa"
                label="Nombre de la empresa"
                fullWidth
                autoComplete="ename"
                onChange={(e) => propsDatos.setNombreEmpresa(e.target.value)}
                error={Boolean(propsDatos.error.nombreEmpresa)}
                helperText={propsDatos.error.nombreEmpresa}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                value={propsDatos.actividadEmpresa}
                type="text"
                id="actividad_empresa"
                name="actividadEmpresa"
                label="Actividad de la empresa"
                fullWidth
                autoComplete="actem"
                onChange={(e) => propsDatos.setActividadEmpresa(e.target.value)}
                error={Boolean(propsDatos.error.actividadEmpresa)}
                helperText={propsDatos.error.actividadEmpresa}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                value={propsDatos.direccionEmpresa}
                required
                type="text"
                id="direccion_empresa"
                name="direccionEmpresa"
                label="Dirección de la empresa"
                fullWidth
                autoComplete="eaddress"
                onChange={(e) => propsDatos.setDireccionEmpresa(e.target.value)}
                error={Boolean(propsDatos.error.direccionEmpresa)}
                helperText={propsDatos.error.direccionEmpresa}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                value={propsDatos.telefonoEmpresa}
                required
                type="number"
                id="telefono_empresa"
                name="telefonoEmpresa"
                label="Teléfono de la empresa"
                InputProps={{ inputProps: { min: 0, maxLength: 10 } }}
                fullWidth
                autoComplete="ephone"
                onChange={(e) => propsDatos.setTelefonoEmpresa(e.target.value)}
                error={Boolean(propsDatos.error.telefonoEmpresa)}
                helperText={propsDatos.error.telefonoEmpresa}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                value={propsDatos.cargoEmpresa}
                required
                type="text"
                id="cargo_empresa"
                name="cargoEmpresa"
                label="Cargo en la empresa"
                fullWidth
                autoComplete="ecargo"
                onChange={(e) => propsDatos.setCargoEmpresa(e.target.value)}
                error={Boolean(propsDatos.error.cargoEmpresa)}
                helperText={propsDatos.error.cargoEmpresa}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                className="form-control"
                error={Boolean(propsDatos.error.tiempoTrabajo)}
              >
                <InputLabel>Jornada laboral *</InputLabel>
                <Select
                  value={propsDatos.tiempoTrabajo}
                  id="select-tiempo-trabajo"
                  name="tiempoTrabajo"
                  onChange={(e) => propsDatos.setTiempoTrabajo(e.target.value)}
                  placeholder="Seleccione una opción"
                  required
                >
                  {propsDatos.tiemposTrabajo.map((item, index) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {propsDatos.error.tiempoTrabajo}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                className="form-control"
                error={Boolean(propsDatos.error.rangoIngresos)}
              >
                <InputLabel>Rango de ingresos "SMLV" *</InputLabel>
                <Select
                  value={propsDatos.rangoIngresos}
                  id="select-rango-ingresos"
                  name="rangosIngresos"
                  onChange={(e) => propsDatos.setRangoIngresos(e.target.value)}
                  placeholder="Seleccione una opción"
                  required
                >
                  {propsDatos.rangosIngresos.map((item, index) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {propsDatos.error.rangoIngresos}
                </FormHelperText>
              </FormControl>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <TextField
            value={propsDatos.observaciones}
            type="text"
            id="observaciones"
            name="observaciones"
            label="Observaciones (Opcional)"
            inputProps={{ maxLength: 254 }}
            multiline
            fullWidth
            onChange={(e) => propsDatos.setObservaciones(e.target.value)}
          />
        </Grid>
        {(propsDatos.carrera?.valord === "doctorado" ||
          propsDatos.carrera?.valord === "maestría") && (
          <>
            <Grid item xs={12}>
              <InputFile
                id="adjunto_ensayo"
                value={propsDatos.adjuntoEnsayo}
                label="Adjuntar ensayo"
                error={Boolean(propsDatos.error.adjuntoEnsayo)}
                // helperText={propsDatos.error.adjuntoEnsayo}
                required={
                  propsDatos.validacionDocumento[1].valora === "1"
                    ? true
                    : false
                }
              />
            </Grid>
            {propsDatos.carrera?.value === MAESTRIA_EDUCACION && (
              <Grid item xs={12}>
                <Box sx={{ display: "flex" }}>
                  <InputFile
                    id="adjunto_formato_maestria_educacion"
                    value={propsDatos.formatoMaestriaEducacion}
                    label="Adjuntar formato maestría en educación"
                    error={Boolean(propsDatos.error.formatoMaestriaEducacion)}
                    // helperText={propsDatos.error.formatoMaestriaEducacion}
                    required={
                      propsDatos.validacionDocumento[2].valora === "1"
                        ? true
                        : false
                    }
                  />
                  <BtnDescargar
                    callback={descargarFormato}
                    titulo="Descargar formato"
                  />
                </Box>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </>
  );
};

export default InscripcionPosgradoCarrera;
