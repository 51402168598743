import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardHeader, CardContent, Avatar, Grid, Typography, Tooltip, IconButton, } from "@material-ui/core/";
import BlockIcon from "@material-ui/icons/Block";
import { red } from "@material-ui/core/colors";
import libroAbierto from "../../../global/imagenes/libro-abierto.png";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: "100%",
    minHeight: "50px",
    height: "320px",
  },
  image: {
    width: "100px",
    maxWidth: "100px",
    minWidth: "100px",
    height: "100px",
  },
  content_text: {
    padding: "0px 0px 0px 10px",
  },
  content_img: {
    textAlign: "center",
    height: "150px",
  },
  hover_delete_icon: {
    "&:hover": {
      color: red[800],
    },
  },
}));

export default function CardSolicitud(
    // { fecha, descripcion, estado, tipo, id, handleClickDelete,}
    ) {
//   let fecha_r = moment(fecha).format("YYYY-MM-DD, h:mm a");
//   const classes = useStyles();

  return (
    <Card className={"Prueba"}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" 
        //   style={{ backgroundColor: `${estado.valorc}` }} 
          >
            {/* {" "} {estado.nombre.charAt(0).toUpperCase()}{" "} */}
          </Avatar>
        }
        title={"prueba"}
        // subheader={fecha_r}
        // action={
        //   habilitar_btn && (
        //     <Tooltip title="Cancelar Solicitud">
        //       <IconButton className={"texto de prueba"} aria-label="cancel" onClick={(e) => handleClickDelete(e, id)} >
        //         <BlockIcon />
        //       </IconButton>
        //     </Tooltip>
        //   )
        // }
      />
      <CardContent>
        <Grid container direction={"row"} justify={"center"}>
          <Grid item xs={12} sm={12} md={12} className={"prueba"}>
            <img  src={libroAbierto} className={"imagen de prueba"} alt="Imagen Tipo" />
            <Typography variant="body2" color="textSecondary" component="p" align="justify" >
              {"texto de prueba"}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
