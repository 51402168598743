import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import AppBarModal from "./../../general/AppBarModal";
import { BtnRegresar} from '../../general/BotonesAccion'
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import Generica from "./../../personas/generica/Generica";
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from '../../../global/imagenes/emma_w.png';
//FUNCIONES GENERALES
import {obtenerValoresFiltros,consulta,rutaImg } from "../../../global/js/funciones";
//COMPONENTES DEL MODULO

//ACCIONES DEL MODULO
import {    mostrarModalInforme } from "../../../redux/actions/actPracticas";
import {  actualizarMensaje, mostrarModulo } from "../../../redux/actions/actGlobal";
import ListarTarjetas from '../../general/ListarTarjetas';
import { TIPO_INFORME_SEMILLEROS } from '../Hijos/Helper';
import emma_s1 from '../../../global/imagenes/emma_s1.png';

import SemillerosInforme from "./SemillerosInforme";


class Admininforme extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cargando: true,
      personas: [],
      generica: {
        tipo: 0,
        id: 0,
      },
      accion: 'procesos',
      informes : [],
      informe: {'nombre' : '', 'codigo' : ''},
      modal: false,
      id_persona: null,
    }
  }

  async componentDidMount() {
    await this.obtenerInformes();
    this.esAdministrador()
  }

  esAdministrador() {
    let { usuario } = this.props;
    if (usuario && usuario.perfil && (usuario.perfil.codigo === "Per_Adm" || usuario.perfil.codigo === "Per_Pra_n1" || usuario.perfil.codigo === "Per_Pra_Adm")) {
      this.setState({ administrador: true })
    }
  }

  obtenerInformes = async () => {
    let { usuario } = this.props
    let informes = []
    this.setState({cargando : true})
    if(usuario.perfil.codigo == 'Per_Adm' || usuario.perfil.codigo == 'Per_Semi_Ges') {
      let informes1 = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPO_INFORME_SEMILLEROS }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valora', 'valor': 'admin'}]);
      let informes2 = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPO_INFORME_SEMILLEROS }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valora', 'valor': 'todos'}]);
      informes = informes1.concat(informes2);
      
    }else if(usuario.perfil.codigo == 'Per_Doc'){
      informes = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPO_INFORME_SEMILLEROS }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valora', 'valor': 'tutor'}]);
    }else{
      informes = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPO_INFORME_SEMILLEROS }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valora', 'valor': 'todos'}]);
    }
    console.log(informes);
    this.setState({informes, cargando : false})
  }

pintarInformes() {

    let { informes, informe,cargando }  = this.state
    let {handleClicki, modalInforme, mostrarModalInforme, actualizarMensaje }  = this.props
    console.log(informes);
    const acciones = (data) => {      
      this.setState({ informe: {'nombre': data.nombre, 'codigo': data.codigo} }) 
      mostrarModalInforme(true)
    }
    if (cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />;
    return (
      <>
        <div className={'regresar'}>
          <BtnRegresar callback={handleClicki}  />
        </div>
        <ListarTarjetas
          datos={informes}
          titulo={'Informes Semilleros'}
          acciones={(data) => acciones(data)}
          avatar={({ nombre }) => nombre.toUpperCase().charAt(0)}
          color_avatar={({valorb}) => ({'backgroundColor': ((valorb) ? valorb : '#EEEEEE'), 'color': ((valorb) ? 'white' : '#757575') })}
          orden={'valorc'}
        />
         <SemillerosInforme
          informe={informe}
          modalInforme={modalInforme}
          mostrarModalInforme={mostrarModalInforme}
          actualizarMensaje={actualizarMensaje}
        /> 
      </>
    )
  } 

  render() {

    return (
      <div>
        {this.pintarInformes()}
      </div>
    )
  }

}

const mapStateToProps = (state) => {
  let {  modalInforme} = state.redPracticas;
    let {  usuario } = state.redGlobal;
  
    return {
     usuario,
     modalInforme
    }
  }
  
  const mapDispatchToProps = {
    mostrarModalInforme,
    actualizarMensaje,

  }
  
  Admininforme.propTypes = {
    //variables
    actualizarMensaje: PropTypes.func.isRequired,
    
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(Admininforme)
  
