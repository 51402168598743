import React from 'react'
import PropTypes from 'prop-types';
import { BtnDetalle, BtnPermiso, BtnGestionar } from "../../general/BotonesAccion";
import ListarDatos from "../../general/ListarDatos";
import { rutaImg } from "../../../global/js/funciones";

class PracticasCoordinadores extends React.Component {

  render() {
    let { personas, callback, mostrarModalProParticipante, mostrarModalPermisoEstado, mostrarModalPermisoModalidad } = this.props;

    const acciones = (data) => {
      data.identificacion = parseInt(data.identificacion);
      data.login_directorio = parseInt(data.login_directorio);

      const onClickSeleccionar = (data) => {
        callback(data);
        mostrarModalProParticipante(true);
      }

      const onClickPermiso = (data) => {
        callback(data);
        mostrarModalPermisoEstado(true);
      }

      const onClickModalidad = (data) => {
        callback(data);
        mostrarModalPermisoModalidad(true);
      }

      let programas = <BtnDetalle callback={() => onClickSeleccionar(data)} texto='Programas' color='#2196f3' />;
      let estados = <BtnPermiso callback={() => onClickPermiso(data)} texto='Estados' />;
      let modalidad = <BtnGestionar callback={() => onClickModalidad(data)} texto='Modalidades' />;

      return <div>{modalidad} {programas} {estados}</div>
    }

    return (
      <ListarDatos
        datos={personas}
        titulo='Lista Gestores'
        buscar={true}
        agregar={false}
        opciones={true}
        sub_titulo='Coordinadores'
        descargar='EMMA - COORDINADORES'
        id='tbl_coordinadores'
        acciones={row => acciones(row)}
        avatarImg={true}
        avatar={({ identificacion }) => `${rutaImg}${identificacion}.jpg`}
        fila_principal={({ primer_nombre, primer_apellido, segundo_apellido }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`}
        filas={[
          { 'id': 'correo' },
          { 'id': 'identificacion' },
        ]}
      />
    );
  }

}
//Props del componente
PracticasCoordinadores.propTypes = {
  //variables
  personas: PropTypes.array.isRequired,
  //funciones
  callback: PropTypes.func.isRequired,
}


export default PracticasCoordinadores;


