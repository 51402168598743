import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { BtnForm } from "../../general/BotonesAccion";
import AppBarModal from '../../general/AppBarModal'
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { obtenerValoresFiltros, transformar, consulta } from "../../../global/js/funciones";
import BuscarSelect from '../../general/BuscarSelect';
import BuscarPersona from '../../general/BuscarPersona';
import InputBuscar from '../../general/InputBuscar';
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from '../../../global/imagenes/emma_w.png';
import { ESTADOS_CENTRO_CONCILIACION } from '../helper';


class CentroFiltrar extends Component {

    constructor(props) {
        super(props)
        this.state = {
            Estados: [],
            periodos: [],
            estad: null,
            periodo: null,
            cargando: true,
            cargar: true,
        }
    }

    obtenerPeriodos = () => {
        return new Promise(resolve => {
            consulta(`api/v1.0/centro/periodos`, null, null,
                (error, estado, resp) => {
                    resolve(resp)
                }
            );
        })
    }

    async componentDidUpdate({ ModalAddFiltrosCentro, accionProceso }) {
        let { ModalAddFiltrosCentro: modalAddFiltrosCentroNew, accionProceso: accionProcesoNew } = this.props;
        if (ModalAddFiltrosCentro !== modalAddFiltrosCentroNew && this.state.cargar) {
            this.setState({ cargando: true })
            let Estados = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': ESTADOS_CENTRO_CONCILIACION }]);
            let periodos = await this.obtenerPeriodos();
            this.setState({
                'Estados': transformar(Estados),
                'periodos': transformar(periodos, 'periodo', 'periodo'),
                cargando: false,
                cargar: false
            })
        }

        if (accionProceso != accionProcesoNew && accionProceso != 'inicio') {
            this.setState({
                estad: null,
                periodo: null,
            })
        }
    }

    resetState = () => {
        let { limpiar, mostrarModalAddFiltrosCentro } = this.props;
        this.setState({
            estad: null,
            periodo: null,
        })
        limpiar();
        mostrarModalAddFiltrosCentro(false)
    }

    onSubmit = e => {
        let { filtrar, actualizarMensaje, accionProceso, mostrarTodos, mostrarModalAddFiltrosCentro } = this.props;
        let {
            periodo,
            estad,
        } = this.state;

        let filtros = [];
        if (periodo !== null) filtros.push({ 'llave': 'periodo', 'valor': periodo.value });
        if (estad !== null) filtros.push({ 'llave': 'estado_actual', 'valor': estad.value });
        if (filtros.length > 0) {
            if (accionProceso == 'inicio') mostrarTodos('Tod_Centro')
            mostrarModalAddFiltrosCentro(false)
            filtrar(filtros);
        } else actualizarMensaje({ titulo: 'Seleccione Algún filtro', area: 'info', mostrar: true, tiempo: 6000 });
        e.preventDefault();
    }


    render() {
        let { ModalAddFiltrosCentro, mostrarModalAddFiltrosCentro, accionProceso } = this.props;
        let {
            periodo,
            estad,
            Estados,
            periodos,
            cargando
        } = this.state;
        let habilitar_limpiar = accionProceso !== 'Tod_Centro' && accionProceso !== 'inicio'
        return (
            <>
                <Dialog fullWidth={true} maxWidth="sm" open={ModalAddFiltrosCentro} onClose={() => mostrarModalAddFiltrosCentro(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <AppBarModal titulo='¡ Aplicar Filtros !' mostrarModal={mostrarModalAddFiltrosCentro} titulo_accion={habilitar_limpiar ? "Limpiar" : ''} accion={habilitar_limpiar ? this.resetState : ''} />
                    <DialogContent className='scroll'>
                        {cargando ? <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" cargando={true} /> :
                            <Grid container spacing={3} className='margin_cont' >
                                <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                                    <BuscarSelect datos={periodos} cambiarEstado={(value) => this.setState({ periodo: value })} valor={periodo} id='select-periodo' nombre='Periodo' placeholder='Seleccione Periodo' />
                                </Grid>
                                <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                                    <BuscarSelect datos={Estados} cambiarEstado={(value) => this.setState({ estad: value })} valor={estad} id='select-estado' nombre='Estado' placeholder='Seleccione Estado' />
                                </Grid>
                            </Grid>
                        }
                    </DialogContent>
                    <DialogActions>
                        <BtnForm texto="CANCELAR" callback={() => mostrarModalAddFiltrosCentro(false)} />
                        {!cargando && <BtnForm texto="FILTRAR" callback={this.onSubmit} />}
                    </DialogActions>
                </Dialog >
            </>
        )
    }
}

//Props del componente
CentroFiltrar.propTypes = {
    //variables
    ModalAddFiltrosCentro: PropTypes.bool.isRequired,
    //funciones
    mostrarModalAddFiltrosCentro: PropTypes.func.isRequired,
    filtrar: PropTypes.func.isRequired,
    limpiar: PropTypes.func.isRequired,
}
export default CentroFiltrar;