import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { obtenerColorAvatar, obtenerTituloEstadoAdjunto } from "../funciones"
import ListarDatos from "../../general/ListarDatos";
import moment from 'moment'

class AdjuntosListar extends Component {

  constructor(props) {
    super(props)
  }


  render() {
    let { btnAdd, adjuntos, acciones, showModalNewAdjuntos } = this.props;
    return (
      <ListarDatos
        datos={adjuntos}
        titulo={''}
        css={{}}
        avatar={({ nombre_archivo, requisito }) => requisito? requisito.nombre.toUpperCase().charAt(0):`${nombre_archivo.toUpperCase().charAt(0)}`}
        color_avatar={({ validacion }) => obtenerColorAvatar(validacion)}
        id='tbl_adjuntos_grado'
        acciones={row => acciones(row)}
        // agregar={btnAdd}
        buscar={false}
        // actAgregar={()=>showModalNewAdjuntos(true)}
        fila_principal={({ nombre_archivo, requisito}) => requisito? requisito.nombre: nombre_archivo }
        filas={[
          {
            'mostrar': ({ fecha_registro }) => `${moment(fecha_registro).format('YYYY-MM-DD, h:mm a')}`,
            'id': 'fecha_registro',
          },
          {
            'mostrar': ({ validacion }) => obtenerTituloEstadoAdjunto(validacion),
            'id': 'validacion'
          },
          {
            'nombre': 'Agregado por : ',
            'mostrar': ({ usuario_registro }) => `${usuario_registro.primer_nombre} ${usuario_registro.primer_apellido} ${usuario_registro.segundo_apellido}`,
            'id': 'nombre_completo',
            'enLista': false,
          },
        
          {
            'nombre': 'Revisado por : ',
            'mostrar': ({ usuario_valida }) => usuario_valida && `${usuario_valida.primer_nombre} ${usuario_valida.primer_apellido} ${usuario_valida.segundo_apellido}`,
            'id': 'usuario_valida',
            'enLista': false,
          },
          {
            'nombre': 'Nota : ',
            'id': 'nota',
            'enLista': false,
          },
          {
            'nombre': 'Nota : ',
            'id': 'nota2',
            'enLista': false,
          },
        ]}
      />
    );

  }
}

//Props del componente
AdjuntosListar.propTypes = {
  adjuntos: PropTypes.array.isRequired,
  // //funciones
  acciones: PropTypes.func.isRequired,
  showModalNewAdjuntos: PropTypes.func.isRequired,
}

export default AdjuntosListar;