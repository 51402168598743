import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, Paper, BottomNavigation, BottomNavigationAction} from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/People';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import Candidatos from './candidatos/Candidatos'
import AppBarModal from "../general/AppBarModal";
import EleccionDetalle from "./EleccionDetalle";
import Votantes from "./votantes/Votantes";
import { BtnForm } from "../general/BotonesAccion";
import ExportarCandidatosExcel from "./candidatos/ExportarCandidatosExcel";

function Menu({ sele = "detalle", seleccionar = () => { } }) {
  const clase = (item, selec, tipo = '') => item === selec ? `item_selec${tipo}` : `item_modulo`;

  const pintar = (claseN = '') => {
    return (
      <div className={claseN}>
        <BottomNavigation showLabels>
          <BottomNavigationAction label={'Detalle'} icon={<ImportContactsIcon className='menu_icono' />} className={clase(0, sele)} onClick={() => seleccionar(0)} />
          <BottomNavigationAction label={'Candidatos'} icon={<PeopleIcon className='menu_icono' />} className={clase(1, sele)} onClick={() => seleccionar(1)} />
          <BottomNavigationAction label={'Votantes'} icon={<HowToRegIcon className='menu_icono' />} className={clase(2, sele)} onClick={() => seleccionar(2)} />
        </BottomNavigation>
      </div>
    )
  }

  return (
    <div>
      {pintar('menu_modal')}
    </div>
  );
}

class Eleccion extends Component {

  constructor(props) {
    super(props)
    this.state = {
      seleccion: 0,
      maxWidth: 'sm',
    }
  }

  cambiarOpcion = (seleccion, maxWidth) => {
    this.cambiarEstado({ seleccion, maxWidth })
  }
  cambiarEstado = (estados) => {
    this.setState(estados)
  }

  pintarDetalle = () => {
    let { eleccion } = this.props;
    return <EleccionDetalle eleccion={eleccion} />;
  }

  pintarCandidatos = () => {
    let { desactivar_acciones, eleccion } = this.props;
    return (
      <Candidatos eleccion={eleccion} desactivar_acciones={desactivar_acciones} />
    );
  }

  pintarVotantes = () => {
    let { desactivar_acciones, eleccion } = this.props;
    return <Votantes eleccion={eleccion} desactivar_acciones={desactivar_acciones} />;
  }

  pintar = () => {
    // let { seleccion } = this.state;
    let { seleccion } = this.props
    let render = '';
    if (seleccion === 0) render = this.pintarDetalle();
    else if (seleccion === 1) render = this.pintarCandidatos();
    else if (seleccion === 2) render = this.pintarVotantes();
    return render;
  }

  render() {

    // let { seleccion, maxWidth } = this.state;
    let { eleccion, seleccion, seleccionar, mostrarModalAdmCandidatos, modalAdmCandidatos, candidatos } = this.props;
    let { nombre, estado } = eleccion;
    return (
      <Dialog open={modalAdmCandidatos} onClose={mostrarModalAdmCandidatos.bind(this, false)} fullWidth={true} fullScreen={window.innerWidth < 600} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <AppBarModal titulo="Detalle Estamento" mostrarModal={() => mostrarModalAdmCandidatos(false)} titulo_accion="" />
        <DialogContent style={{ padding: 0 }} className='scroll'>
          <Menu sele={seleccion} seleccionar={seleccion => seleccionar(seleccion)} />
          {this.pintar()}
        </DialogContent>
        <DialogActions>
          <BtnForm texto="Cerrar" callback={() => mostrarModalAdmCandidatos(false)} />
          {seleccion === 1 ? <ExportarCandidatosExcel data={candidatos} nombre={`${eleccion.nombre}`} /> : ''}
        </DialogActions>
      </Dialog>

    );
  }
}

Eleccion.propTypes = {
  //variables
  modalAdmCandidatos: PropTypes.bool.isRequired,
  eleccion: PropTypes.shape({
    id: PropTypes.number.isRequired,
    nombre: PropTypes.string.isRequired,
    fecha_inicio: PropTypes.string.isRequired,
    fecha_fin: PropTypes.string.isRequired,
  }),
  //funciones
  mostrarModalAdmCandidatos: PropTypes.func.isRequired,
  desactivar_acciones: PropTypes.func.isRequired,
}

export default Eleccion;
