import { MODAL_ADD_FILTROS_CENTRO, 
         MODAL_MOD_PERIODO_CENTRO, 
         MODAL_DET_CENTRO, 
         MODAL_INFORME_CENTRO,
         MODAL_GES_CENTRO } from '../actions/actCentro'



const initialState = {
  ModalAddFiltrosCentro: false,
  modalModPeriodoCentro: false,
  modalDetalleCentro:false,
  modalGestionarCentro:false,
  modalInformeCentro:false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case MODAL_ADD_FILTROS_CENTRO:
      return Object.assign({}, state, {
        ModalAddFiltrosCentro: action.show
      })
    case MODAL_MOD_PERIODO_CENTRO:
      return Object.assign({}, state, {
        modalModPeriodoCentro: action.show
      })
    case MODAL_DET_CENTRO:
      return Object.assign({}, state, {
        modalDetalleCentro: action.show
      })
    case MODAL_GES_CENTRO:
      return Object.assign({}, state, {
        modalGestionarCentro: action.show
      })
    case MODAL_INFORME_CENTRO:
      return Object.assign({}, state, {
        modalInformeCentro: action.show
    })
    default:
      return state;
  }
}

export default reducer;