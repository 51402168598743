import React, { Component } from 'react';
import { Dialog, DialogContent, DialogActions, Grid } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { BtnForm } from "../../general/BotonesAccion";
import AppBarModal from '../../general/AppBarModal'
import InputBuscar from '../../general/InputBuscar'
import BuscarPersona from '../../general/BuscarPersona'
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from '../../../global/imagenes/emma_w.png';
import moment from 'moment'


class AutodiagnosticoFiltrar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cargando: false,
      fecha_inicio: null,
      fecha_fin: null,
      estudiante: {
        id: 0,
        nombre: ''
      },
      buscarEstudiante: false
    }
  }

  async componentDidUpdate({ accionProceso }) {
    let { accionProceso: accionProcesoNew } = this.props
    if (accionProceso != accionProcesoNew && accionProceso != 'inicio') {
      this.setState({
        fecha_inicio: null,
        fecha_fin: null,
        estudiante: {
          id: 0,
          nombre: ''
        }
      })
    }
  }

  setFechaInicio = (fecha_inicio) => {
    this.setState({
      fecha_inicio
    })
  }

  setFechaFin = (fecha_fin) => {
    this.setState({
      fecha_fin
    })
  }

  resetState = () => {
    let { mostrarModalFiltrosAutodiagnostico, limpiar } = this.props;
    this.setState({
      fecha_inicio: null,
      fecha_fin: null,
      estudiante: {
        id: 0,
        nombre: ''
      }
    })
    limpiar()
    mostrarModalFiltrosAutodiagnostico(false)
  }

  onSubmit = e => {
    e.preventDefault();
    let { mostrarModalFiltrosAutodiagnostico, actualizarMensaje, filtrar, accionProceso, mostrarTodos } = this.props;
    let { fecha_inicio, fecha_fin, estudiante } = this.state;
    fecha_inicio = fecha_inicio ? moment(fecha_inicio).format('YYYY-MM-DD') : null;
    fecha_fin = fecha_fin ? moment(fecha_fin).format('YYYY-MM-DD') : null;

    let filtros = [];
    if (fecha_inicio) filtros.push({ 'llave': 'fecha_inicio', 'valor': fecha_inicio });
    if (fecha_fin) filtros.push({ 'llave': 'fecha_fin', 'valor': fecha_fin });
    if (estudiante.id) filtros.push({ 'llave': 'usuario_registro', 'valor': estudiante.id });

    if (filtros.length > 0) {
      if (accionProceso == 'inicio') mostrarTodos('Tod_Aut')
      filtrar(filtros)
      mostrarModalFiltrosAutodiagnostico(false)
    } else actualizarMensaje({ titulo: 'Seleccione Algún filtro', tipo: 'info', mostrar: true, tiempo: 6000 });
  }

  render() {
    let { fecha_inicio, fecha_fin, cargando, buscarEstudiante, estudiante } = this.state
    let { modalFiltrosAutodiagnostico, mostrarModalFiltrosAutodiagnostico, accionProceso } = this.props
    let habilitar_limpiar = accionProceso !== 'Tod_Aut' && accionProceso !== 'inicio'

    return (
      <>
        <Dialog fullWidth={true} maxWidth="sm" open={modalFiltrosAutodiagnostico} onClose={() => mostrarModalFiltrosAutodiagnostico(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <AppBarModal titulo='¡ Aplicar Filtros !' mostrarModal={mostrarModalFiltrosAutodiagnostico} titulo_accion={ habilitar_limpiar ? "Limpiar" : ''} accion={habilitar_limpiar ? this.resetState : ''} />
          <DialogContent className='scroll'>
            {!cargando ?
              <Grid container spacing={3} className='margin_cont' >
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                  <InputBuscar
                    id='estudiante'
                    label='Estudiante'
                    placeholder='Clic aquí para buscar'
                    value={estudiante.nombre}
                    callback={() => this.setState({ buscarEstudiante: true })} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      id="date-picker-dialog"
                      label="Fecha Inicio"
                      format="dd-MM-yyyy"
                      value={fecha_inicio}
                      onChange={this.setFechaInicio}
                      style={{ width: '100%' }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      invalidDateMessage='Formato Invalido'
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      id="date-picker-dialog"
                      label="Fecha Fin"
                      format="dd-MM-yyyy"
                      value={fecha_fin}
                      onChange={this.setFechaFin}
                      style={{ width: '100%' }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      invalidDateMessage='Formato Invalido'
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              : <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />}
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => mostrarModalFiltrosAutodiagnostico(false)} />
            <BtnForm texto="FILTRAR" callback={this.onSubmit} />
          </DialogActions>
        </Dialog>

        <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={buscarEstudiante} onClose={() => this.setState({ buscarEstudiante: false })}>
          <AppBarModal titulo={'¡ Buscar Estudiante !'} mostrarModal={() => this.setState({ buscarEstudiante: false })} titulo_accion="CERRAR" accion={() => this.setState({ buscarEstudiante: false })} />
          <DialogContent style={{ padding: '0' }} className='scroll'>
            <BuscarPersona callback={({ id, primer_nombre, primer_apellido, segundo_apellido }) => this.setState({
              estudiante: {
                id,
                nombre: `${primer_nombre} ${primer_apellido} ${segundo_apellido}`,
              }, buscarEstudiante: false
            })} />
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => this.setState({ buscarEstudiante: false })} />
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default AutodiagnosticoFiltrar