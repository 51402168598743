import React, { Component } from 'react'

import PropTypes from 'prop-types';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Dialog, DialogContent, DialogActions, Grid } from '@material-ui/core';
import AppBarModal from '../../general/AppBarModal'

// FUNCIONES GLOBALES
import { BtnForm } from '../../general/BotonesAccion';


class AsignaturasRetos extends Component {
  constructor(props) {
    super(props)
    this.state = {
      codigo_asignatura: ''
    }
  }


  componentDidUpdate({ modal }) {
    let { modal: modalAddNew } = this.props;
    if (modalAddNew !== modal) {
      this.setState({ codigo_asignatura: '' });
    }
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }


  onSubmit = e => {
    let { id_persona, mostrarModal} = this.props; 
    let { codigo_asignatura } = this.state;
    this.props.enviar(codigo_asignatura);
    e.preventDefault();
    mostrarModal(false)
  }


  render() {
    let { modal, mostrarModal, label, type } = this.props;
    let { codigo_asignatura } = this.state;

    return (
      <>
        <Dialog open={modal} fullWidth={true} maxWidth="xs" onClose={mostrarModal.bind(this, false)}
          aria-labelledby="form-dialog-title">
          <AppBarModal titulo="¡ Nuevo Codigo Asignatura !" mostrarModal={mostrarModal} titulo_accion="" accion="" />
          <DialogContent className='scroll'>
            <ValidatorForm onSubmit={this.onSubmit}>
              <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                <TextValidator
                  autoFocus
                  margin="dense"
                  label={label}
                  type={type}
                  fullWidth
                  name="codigo_asignatura"
                  value={codigo_asignatura}
                  validators={["required"]}
                  errorMessages={["El campo es requerido"]}
                  onChange={this.onChange}
                />
              </Grid>
            </ValidatorForm>
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => mostrarModal(false)} />
            <BtnForm texto="GUARDAR" callback={this.onSubmit} />
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

AsignaturasRetos.propTypes = {
  //variables
  modal: PropTypes.bool.isRequired,
  titulo: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  label_fecha: PropTypes.string.isRequired,
  //funciones
  enviar: PropTypes.func.isRequired,
  mostrarModal: PropTypes.func.isRequired,
}

export default AsignaturasRetos