import React from 'react';
import { BottomNavigation, Hidden, BottomNavigationAction, Tabs, Tab, Badge, List } from '@material-ui/core';
import SchoolIcon from '@material-ui/icons/School';
import Permisos from '@material-ui/icons/PeopleAlt';
import Candado from '@material-ui/icons/Lock';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import GradosNotificaciones from '../GradosNotificaciones'
import Notificaciones from '@material-ui/icons/NotificationImportant';


export default function GradosMenu({ perfil: { tipo, administrador }, notificaciones = [], sele = 0, seleccionar = () => { } }) {

  const clase = (item, selec, tipo = '') => item === selec ? `item_selec${tipo}` : `item_modulo`;

  const pintar = (app, claseN = '') => {
    if (!app) {
      return (
        <div className={claseN}>
          <List >
            <BottomNavigation showLabels className={clase('procesos', sele)} onClick={() => seleccionar('procesos')}>
              <BottomNavigationAction label={'Procesos'} icon={<SchoolIcon className='menu_icono' />} />
            </BottomNavigation>
            <GradosNotificaciones notificaciones={notificaciones} render={(click) => (<BottomNavigation showLabels className={clase('notificacion', sele)} onClick={(e) => { click(e); seleccionar('notificacion'); }}> <BottomNavigationAction label={'Notificación'} icon={<Badge badgeContent={notificaciones.length} color="secondary"><Notificaciones /></Badge>} /></BottomNavigation>)} />

            {(tipo == "Per_Adm_Gra2" || administrador) &&
              <BottomNavigation showLabels className={clase('externos', sele)} onClick={() => seleccionar('externos')}>
                <BottomNavigationAction label={'Externos'} icon={<Permisos />} />
              </BottomNavigation>
            }
            {(tipo == "Per_Adm_Gra2" || administrador) &&
              <BottomNavigation showLabels className={clase('personal', sele)} onClick={() => seleccionar('personal')}>
                <BottomNavigationAction label={'Personal'} icon={<PersonIcon />} />
              </BottomNavigation>}
            {
              administrador &&
              <BottomNavigation showLabels className={clase('administrar', sele)} onClick={() => seleccionar('administrar')}>
                <BottomNavigationAction label={'Administrar'} icon={<SettingsIcon className='menu_icono' />} />
              </BottomNavigation>
            }
          </List>
        </div>
      )
    } else {
      return (
        <div className={claseN}>
          <Tabs value={sele} variant='scrollable' scrollButtons="off" >
            <Tab label='Procesos' icon={<SchoolIcon className='menu_icono' />} className={clase('procesos', sele, '_app')} value='procesos' onClick={() => seleccionar('procesos')} />
            <GradosNotificaciones notificaciones={notificaciones} horizontal='left' render={(click) => <Tab onClick={(e) => { click(e); seleccionar('notificacion') }} label={'Notificación'} icon={<Badge badgeContent={notificaciones.length} color="secondary"><Notificaciones /></Badge>} className={clase('notificacion', sele, '_app')} value='notificacion' />} />
            {tipo == "Per_Adm_Gra2" && <Tab label='Externos' icon={<Permisos className='menu_icono' />} className={clase('externos', sele, '_app')} value='externos' onClick={() => seleccionar('externos')} />}
            {tipo == "Per_Adm_Gra2" && <Tab label='Personal' icon={<PersonIcon className='menu_icono' />} className={clase('personal', sele, '_app')} value='personal' onClick={() => seleccionar('personal')} />}
            {administrador && <Tab label='Administrar' icon={<SettingsIcon className='menu_icono' />} className={clase('administrar', sele, '_app')} value='administrar' onClick={() => seleccionar('administrar')} />}
          </Tabs>
        </div>
      )
    }
  }

  return (
    <div>
      <Hidden xsDown> {pintar(false, 'menu_modulo scroll')} </Hidden>
      <Hidden smUp> {pintar(true, 'menu_modulo_app')}</Hidden>
    </div>
  )
}
