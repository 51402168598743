import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, Grid, IconButton, Paper, Typography } from '@material-ui/core'
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import SettingsIcon from '@material-ui/icons/Settings';
import { BottomNavigation, BottomNavigationAction, Hidden } from '@material-ui/core';
import { actualizarMensaje, mostrarModulo } from "../../redux/actions/actGlobal";
import { consulta, api, obtenerValoresFiltros } from "../../global/js/funciones";
import { Buscar } from "./../general/app/Buscar";
import TituloAccion from "../general/TituloAccion";
import Reporte from "./Reporte";
import ReportesOpciones from "./ReportesOpciones";
import ReportesFiltrar from "./ReportesFiltrar";
import ReportePermisos from "./ReportePermisos";
import TareasTerminadas from "../general/TareasTerminadas";
import emma_w from '../../global/imagenes/emma_w.png';
import emma_s from '../../global/imagenes/emma_s1.png';
import { Grupos_reporte, Tipos_reporte } from './Helper';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Alert } from 'react-bootstrap';


const clase = (item, selec, tipo = '') => item === selec ? `item_selec${tipo}` : `item_modulo`;

export function MenuReportes({ administrador, sele = 0, seleccionar = () => { } }) {
  const pintar = (app, claseN = '') => {
    if (!app) {
      return (
        <div className={claseN}>
          <BottomNavigation showLabels className={clase('grupos', sele)} onClick={() => seleccionar('grupos')}>
            <BottomNavigationAction label={'Reportes'} icon={<LibraryBooks />} />
          </BottomNavigation>
          {administrador &&
            <BottomNavigation showLabels className={clase('administrar', sele)} onClick={() => seleccionar('administrar')}>
              <BottomNavigationAction label={'Administrar'} icon={<SettingsIcon />} />
            </BottomNavigation>
          }
        </div>
      )
    } else {
      return (
        <div className={claseN}>
          <BottomNavigation showLabels >
          <BottomNavigationAction label={'Reportes'} icon={<LibraryBooks />} className={clase('grupos', sele, '_app')} onClick={() => seleccionar('reportes')} />
            {
              administrador && <BottomNavigationAction label={'Administrar'} icon={<SettingsIcon />} className={clase('administrar', sele, '_app')} onClick={() => seleccionar('administrar')} />
            }
          </BottomNavigation>
        </div>
      )
    }
  }
  return (
    <div>
      <div>
        <Hidden xsDown> {pintar(false, 'menu_modulo')} </Hidden>
        <Hidden smUp> {pintar(true, 'menu_modulo_app')}</Hidden>
      </div>
    </div>
  );
}

class Reportes extends Component {
   constructor(props) {
      super(props)
      this.state = {
         cargando: true,
         seleccion: {codigo : '', valorb: ''},
         tipo: '',
         tipos: [],
         reporte: [],
         filtros: {},
         modal_reporte : false,
         modal_filtro : false,
         menu : 'grupos',
         administrador: false,
         dato_buscar: '',
         GrupoReport:[],
         codigo_gruporeporte:''
      }
   }

   componentDidMount() {
      let { mostrarModulo } = this.props;
      mostrarModulo({ ruta: '/reportes', nombre: 'Reportes' })
      this.obtenerDatosIniciales();
      this.esAdministrador();
   }

   async obtenerDatosIniciales() {
     
      this.setState({ cargando: true })
      let grupos = await obtenerValoresFiltros([{ llave: "generica", valor: Grupos_reporte },{ llave: "estado", valor: "1" },]);
      console.log(grupos)
      // let tipos = await this.obtenerTiposReportes()
      this.setState({cargando: false, GrupoReport: grupos })
   }

   async obtenerTiposReportes(dato) {
      // return new Promise(resolve => {
      //    consulta(`api/v1.0/reportes/listar`, null, null, (error, estado, resp) => {
      //       resolve(estado === 200 && !error ? resp : null);
      //    })
      // })
      let data = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': Tipos_reporte }, { 'llave': 'valorc', 'valor': dato }])
      this.setState({ menu:"reportes", tipos:data})
       
   }

   esAdministrador() {
      let { usuario } = this.props;
      if (usuario && usuario.perfil && (usuario.perfil.codigo === "Per_Adm" )) {
         this.setState({ administrador: true })
      }
   }

   setFiltros = filtros => {
   this.setState({filtros})
   }

   buscar = () => {
      let { dato_buscar, tipos } = this.state;
      let datos = [];
      tipos.map((data) => {
         let existe = data.nombre.toLowerCase().includes((dato_buscar).toLowerCase());
         if (existe) datos.push(data)
      });
      return datos;
   }

   pintarOpciones() {
      let { tipos, dato_buscar, GrupoReport } = this.state;
      console.log(tipos)
      let mostrar = tipos

      if (dato_buscar) mostrar = this.buscar()
      else mostrar = tipos
   // reporte regreso
      // if(GrupoReport)mostrar = this.Reportes()

      const handleClick = (reporte) => {
         console.log(reporte)
			this.setState({ modal_filtro: true, seleccion: {codigo: reporte.codigo, valorb: reporte.valorb, valorc:reporte.valorc }, tipo: reporte.nombre });
      };

   
   
           
      return (
         

         <div style={{ padding: '3% 2% 0% 2%' }}>


            <Grid container direction={'row'} spacing={3}>

               {tipos.length > 0 ?
                  <>
                   <Button onClick={() => this.setState({ menu: "grupos" })} style={{ padding: "0px 3px 0px 3px", marginTop: '1%' }} color="secondary" > <ArrowBackIcon /> <Typography variant="body2" style={{ color: '#9e9e9e' }} component="p" align='justify'> Atras </Typography> </Button>

                    {/* <Buscar dato_buscar={this.state.dato_buscar} setBuscar={(dato_buscar) => this.setState({ dato_buscar })} className='' StyleExt={{ marginTop: 20, marginBottom: 10 }} /> */}
                     {
                        mostrar.length > 0 ?
                           <>
                              <TituloAccion principal="" alterno="¡ Estas son las opciones de reportes ! para buscar el periodo digite en el siguiente formato 20221, 20222" style={{ 'marginTop': '10px' }} />
                              {mostrar.map((reporte, i) => {
                                 return (
                                    <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
                                       <Paper elevation={1} onClick={() => handleClick(reporte)} className="pointer">
                                          <ReportesOpciones
                                             nombre={reporte.nombre}
                                             descripcion={reporte.descripcion}
                                             imagen={reporte.archivo}

                                          />
                                       </Paper>

                                    </Grid>
                                 );
                              })}
                           </>
                           : <TareasTerminadas mensaje="No se encontraron datos" marginTop="7%" imagen={emma_s} widthImg="7%" />
                     }
                  </>
                  : <TareasTerminadas mensaje="No tienes opciones de Reporte" marginTop="7%" imagen={emma_s} widthImg="7%" />
               }
            </Grid>
         </div>
      );
   }

   pintarGrupo(){
      let {GrupoReport } = this.state;
      
      const handleClick = (reporte) => {
         this.setState({codigo_gruporeporte:reporte.codigo})
			this.obtenerTiposReportes(reporte.codigo);
      };

   
      
      return (
         <div style={{ padding: '3% 2% 0% 2%' }}>
            <Grid container direction={'row'} spacing={3}>
               {
                        GrupoReport.length > 0 ?
                           <>
                              <TituloAccion principal="" alterno="¡ Estos son los Grupos de reportes !" style={{ 'marginTop': '10px' }} />
                              {GrupoReport.map((reporte, i) => {
                                 return (
                                    <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
                                       <Paper elevation={1} onClick={() => handleClick(reporte)} className="pointer">
                                          <ReportesOpciones
                                             nombre={reporte.nombre}
                                             descripcion={reporte.descripcion}
                                             imagen={reporte.archivo}
                                          />
                                       </Paper>
                                    </Grid>
                                 );
                              })}
                           </>
                           : <TareasTerminadas mensaje="No se encontraron datos" marginTop="7%" imagen={emma_s} widthImg="7%" />
                     }
            </Grid>
         </div>
      );
   }
   render() {
      let { cargando, modal_reporte, seleccion, tipo, modal_filtro, filtros, menu, administrador, codigo_gruporeporte } = this.state
      let { actualizarMensaje } = this.props

      return (
         <div>
            <MenuReportes administrador={administrador} sele={menu} seleccionar={(menu) => this.setState({menu})} />
            <div className='contenido_modulo'>
               <Reporte
                  tipo={tipo}
                  filtros={filtros}
                  seleccion={seleccion.codigo}
                  actualizarMensaje={actualizarMensaje}
                  modalReporte={modal_reporte}
                  mostrarModalReporte={(estado) => this.setState({ modal_reporte: estado })}
               />
               <ReportesFiltrar
                  tipoReporte={seleccion}
                  setFiltros={this.setFiltros}
                  modalFiltro={modal_filtro}
                  actualizarMensaje={actualizarMensaje}
                  mostrarModalFiltro={(estado) => this.setState({ modal_filtro: estado })}
                  mostrarModalReporte={(estado) => this.setState({ modal_reporte: estado })}
                  codigo_gruporeporte={codigo_gruporeporte}

               />
               {!cargando ?
                  <div>
                     {menu === 'reportes' && this.pintarOpciones()}
                     {menu === 'grupos' && this.pintarGrupo()} 
                     {menu === 'administrar' && <ReportePermisos />}
                  </div>
                  : <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" cargando={true}/>
               }
            </div>
         </div>
      )
   }
}

const mapStateToProps = (state) => {
   let { usuario } = state.redGlobal;
   return { usuario }
};

const mapDispatchToProps = {
   actualizarMensaje,
   mostrarModulo
}

export default connect(mapStateToProps, mapDispatchToProps)(Reportes);
