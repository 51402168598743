import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import emma_w from "../../../global/imagenes/emma_w.png";
import {
  consulta,
  crear_form_data,
  formulario,
  generarFiltros,
  getError,
  mostrarError,
  obtenerMisDatos,
  obtenerValoresFiltros,
  validateInputs,
} from "../../../global/js/funciones";
import { actualizarMensaje } from "../../../redux/actions/actGlobal";
import { mostrarModalEncargadoInter } from "../../../redux/actions/actInternacionalizacion";
import AppBarModal from "../../general/AppBarModal";
import { BtnForm } from "../../general/BotonesAccion";
import BuscarPersona from "../../general/BuscarPersona";
import InputBuscar from "../../general/InputBuscar";
import InputFile from "../../general/InputFile";
import ListarDatos from "../../general/ListarDatos";
import TareasTerminadas from "../../general/TareasTerminadas";
import {
  ACREDITACIONES,
  CATEGORIAS_CONVENIOS,
  CODIGO_ACRE_INTERNACIONAL,
  DPTOS_ACADEMICOS,
  ESTADOS_CONVENIOS,
  FACULTADES_DEPENDENCIAS,
  FUENTES_FINANCIACION,
  IDIOMAS,
  PAISES,
  SUBTIPOS_CONVENIOS,
  TIPOS_CONVENIOS,
  TIPOS_SOLICITUD_CONVENIOS,
  TIPO_ACTIVIDADES_CONVENIOS,
  UNIDADES_ADMINISTRATIVAS
} from "../administrar/helper";
import ConveniosBuscarRanking from "./ConveniosBuscarRanking";
import ConveniosBuscarUniversidad from "./ConveniosBuscarUniversidad";
// import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
// import DateFnsUtils from '@date-io/date-fns';
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { es } from 'date-fns/locale'
import moment from "moment";
// HELPER
import {
  CODIGO_SUBTIPO_CONVENIO_OTRO,
  CODIGO_TIPO_CONVENIO_OTRO,
  CODIGO_TIPO_FUENTE_OTRO,
  CODIGO_TIPO_IDIOMA_OTRO,
} from "../administrar/helper";

const configStep = [
  "Principal",
  "Información del Solicitante",
  "Información de la Institución Vinculante",
  "Información del Convenio",
  "Cumplimiento de Criterios",
  "Plan de Trabajo",
];

function Principal(component_props) {
  let { 
    check_opcion_renovacion, 
    check_opcion_suscripcion, 
    checked, 
    id_renovacion, 
    cambiarEstado, 
    modalBuscarUniversidad
  } = component_props;
  return (
    <>

      {/* SI NO HAY NADA SELECCIONADO */}
      {!check_opcion_suscripcion && !check_opcion_renovacion ?
        <Grid container spacing={3} style={{ marginTop: "18px", textAlign: 'center' }}>
          <Grid item xs={6} style={{ padding: "0 12px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={check_opcion_suscripcion}
                  onChange={checked}
                  id="check_opcion_suscripcion"
                  name="check_opcion_suscripcion"
                  color="primary"
                />
              }
              label="Opción de Suscripción"
            />
          </Grid>
          <Grid item xs={6} style={{ padding: "0 12px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={check_opcion_renovacion}
                  onChange={checked}
                  id="check_opcion_renovacion"
                  name="check_opcion_renovacion"
                  color="primary"
                />
              }
              label="Opción de Renovación"
            />
          </Grid>

        </Grid>

        /* SI SE SELECCIONA LA OPCION DE SUSCRIPCION */
        : check_opcion_suscripcion && !check_opcion_renovacion ?
          <Grid container spacing={3} style={{ marginTop: "18px", textAlign: 'center' }}>
            <Grid item xs={6} style={{ padding: "0 12px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={check_opcion_suscripcion}
                    onChange={checked}
                    id="check_opcion_suscripcion"
                    name="check_opcion_suscripcion"
                    color="primary"

                  />
                }
                label="Opción de Suscripción"
              />
            </Grid>
            <Grid item xs={6} style={{ padding: "0 12px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={check_opcion_renovacion}
                    onChange={checked}
                    id="check_opcion_renovacion"
                    name="check_opcion_renovacion"
                    color="primary"
                    disabled
                  />
                }
                label="Opción de Renovación"
              />
            </Grid>
          </Grid>

          /* SI SE SELECCIONA LA OPCION DE RENOVACION */
          : check_opcion_renovacion && !check_opcion_suscripcion ?
            <Grid container spacing={3} style={{ marginTop: "18px", justifyContent: 'center' }}>
              <Grid item xs={12} style={{ padding: "0", marginBottom: '10px', textAlign: 'center' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={check_opcion_renovacion}
                      onChange={checked}
                      id="check_opcion_renovacion"
                      name="check_opcion_renovacion"
                      color="primary"
                    />
                  }
                  label="Opción de Renovación"
                />
              </Grid>

              {/* SI TIENEN CONVENIOS SUCRITOS */}
              {/* {convenios_registrados.length > 0 ?
                <Grid item xs={10} style={{ padding: "0 12px", textAlign: 'center' }}>
                  <FormControl
                    className="form-control"
                    required
                  // error={getError("tipo_convenio", errores).length > 0}
                  >
                    <InputLabel id="convenio_registrado">Convenios</InputLabel>
                    <Select
                      labelId="convenio_registrado"
                      id="id_renovacion"
                      name="id_renovacion"
                      value={id_renovacion}
                      fullWidth
                      onChange={onChange}
                      required
                    >
                      {convenios_registrados.map((item) => (
                        <MenuItem key={item.id} value={item.solicitud.id}>
                          {item.institucion.toUpperCase()}
                        </MenuItem>
                      ))
                      }
                    </Select>
                    <FormHelperText> */}
              {/* {getError("tipo_convenio", errores)} */}
              {/* </FormHelperText>
                  </FormControl>
                </Grid> :
                <Grid item xs={12}>
                  <Alert severity="info">
                    NO EXISTEN CONVENIOS SUSCRITOS
                  </Alert>
                </Grid>
              } */}
              <Grid item xs={10} style={{ padding: "0 12px", textAlign: 'center' }}>
                <ConveniosBuscarUniversidad
                  seleccionar={({ solicitud: { id }, institucion }) => {
                    cambiarEstado({
                      id_renovacion: {
                        id: id,
                        nombre: institucion,
                      },
                      // nombre_conv_ren: institucion,
                    });
                  }}

                  quitar={({ solicitud: { id } }) => {
                    cambiarEstado({
                      id_renovacion: {
                        id: id_renovacion != id ? id_renovacion : { id: null }
                      }
                    });
                  }}
                  seleccionadas={modalBuscarUniversidad ? id_renovacion.id : null}
                  modalBuscarUniversidad={modalBuscarUniversidad}
                  mostrarModalBuscarUniversidad={(modalBuscarUniversidad) =>
                    cambiarEstado({ modalBuscarUniversidad })
                  }

                />
                <InputBuscar
                  placeholder="Seleccione el convenio"
                  label={"Convenios"}
                  id="id_renovacion"
                  value={id_renovacion.id == null ? '' : id_renovacion.nombre}
                  callback={() => cambiarEstado({ modalBuscarUniversidad: true })}
                  icon_default={false}
                />
              </Grid>
            </Grid>
            : ''
      }
    </>
  )
}

function InformacionDelSolicitante(component_props) {
  let {
    celular,
    correo_personal,
    dependencias,
    dependencia,
    errores,
    nombre_completo,
    onChange,
    gestor,
    personas,
    gestorDependencia,
    onChangeDependencias
  } = component_props;

  return (
    <>
      <Grid container spacing={3} style={{ marginBottom: "40px" }}>
        <Grid item xs={12}>
          <TextField
            value={nombre_completo}
            disabled
            id="nombre_completo"
            name="nombre_completo"
            label="Nombre completo"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl
            className="form-control"
            required
            error={getError("dependencia", errores).length > 0}
          >
            <InputLabel id="dependencia_label">
              Departamento académico o unidad administrativa
            </InputLabel>
            <Select
              labelId="dependencia_label"
              id="dependencia"
              name="dependencia"
              value={dependencia}
              fullWidth
              onChange={onChange}
            >
              {dependencias.map(({ id, nombre }) => (
                <MenuItem key={id} value={id}>
                  {nombre.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{getError("dependencia", errores)}</FormHelperText>
          </FormControl>
        </Grid>
        {dependencia.length !== 0 && personas.length !== 0 && (
          <Grid item xs={12}>
            <Alert severity="info">
              Gestor de tu solicitud: {personas.primer_nombre + " " + personas.segundo_nombre + " " + personas.primer_apellido + " " + personas.segundo_apellido}
            </Alert>
          </Grid>)
        }
        <Grid item xs={6}>
          <TextField
            value={celular}
            required
            error={getError("celular", errores).length > 0}
            helperText={getError("celular", errores)}
            type="number"
            id="celular"
            name="celular"
            label="Celular"
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={correo_personal}
            required
            error={getError("correo_personal", errores).length > 0}
            helperText={getError("correo_personal", errores)}
            id="correo_personal"
            name="correo_personal"
            label="Correo electrónico institucional"
            fullWidth
            onChange={onChange}
          />
        </Grid>
      </Grid>
    </>
  );
}

function InformacionDelConvenio(component_props) {
  let {
    categoria_convenio,
    categorias,
    errores,
    fileInput,
    financiaciones,
    fuente_financiacion,
    idioma_minuta,
    idiomas,
    institucion,
    instituciones,
    objeto_del_convenio,
    onChange,
    otra_financiacion,
    otro_idioma,
    otro_subtipo,
    modelo_convenio,
    modelo_convenio_renovacion,
    subtipo_convenio,
    subtipos,
    tipo_convenio,
    tipos,
    vigencia,
    otro_tipo_convenio,
    otro_subtipo_convenio,
    otra_fuente_financiacion,
    otro_idioma_minuta,
    renovacion,
    codigo_conve,
    opcion,
    fechaInicio_conv,
    fechaFin_conv,
    estados_convenio,
    estado_convenio,
  } = component_props;
  console.log(fuente_financiacion)
  console.log(estados_convenio)
  return (
    <>
      <Grid container spacing={3} style={{ marginBottom: "40px" }}>

        <Grid item xs={6}>
          <FormControl
            className="form-control"
            required
            error={getError("tipo_convenio", errores).length > 0}
          >
            <InputLabel id="tipo_convenio_label">Tipo de convenio</InputLabel>
            <Select
              labelId="tipo_convenio_label"
              id="tipo_convenio"
              name="tipo_convenio"
              value={tipo_convenio}
              fullWidth
              multiple
              onChange={onChange}
            >
              {tipos.map((tipo) => (
                <MenuItem key={tipo.id} value={tipo.id}>
                  {tipo.nombre}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {getError("tipo_convenio", errores)}
            </FormHelperText>
          </FormControl>
        </Grid>
        {tipo_convenio.includes(CODIGO_TIPO_CONVENIO_OTRO) && (
          <Grid item xs={6}>
            <TextField
              value={otro_tipo_convenio}
              id="otro_tipo_convenio"
              name="otro_tipo_convenio"
              required
              error={getError("otro_tipo_convenio", errores).length > 0}
              helperText={getError("otro_tipo_convenio", errores)}
              label="Otro tipo de convenio"
              fullWidth
              onChange={onChange}
            />
          </Grid>
        )}

        <Grid item xs={6}>
          <FormControl
            className="form-control"
            required
            error={getError("subtipo_convenio", errores).length > 0}
          >
            <InputLabel id="subtipo_convenio_label">
              Funciones misionales que impacta
            </InputLabel>
            <Select
              labelId="subtipo_convenio_label"
              id="subtipo_convenio"
              name="subtipo_convenio"
              value={subtipo_convenio}
              fullWidth
              multiple
              onChange={onChange}
            >
              {subtipos
                .sort(
                  ({ id: previousID }, { id: currentID }) =>
                    previousID - currentID
                )
                .map((tipo) => (
                  <MenuItem key={tipo.id} value={tipo.id}>
                    {tipo.nombre}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>
              {getError("subtipo_convenio", errores)}
            </FormHelperText>
          </FormControl>
        </Grid>
        {subtipo_convenio.includes(CODIGO_SUBTIPO_CONVENIO_OTRO) && (
          <Grid item xs={6}>
            <TextField
              value={otro_subtipo_convenio}
              id="otro_subtipo_convenio"
              required
              error={getError("otro_subtipo_convenio", errores).length > 0}
              helperText={getError("otro_subtipo_convenio", errores)}
              name="otro_subtipo_convenio"
              label="Otra subtipo de convenio"
              fullWidth
              onChange={onChange}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <FormControl
            className="form-control"
            required
            error={getError("fuente_financiacion", errores).length > 0}
          >
            <InputLabel id="fuente_financiacion_label">
              Fuentes de financiación
            </InputLabel>
            <Select
              labelId="fuente_financiacion_label"
              id="fuente_financiacion"
              name="fuente_financiacion"
              value={fuente_financiacion}
              fullWidth
              multiple
              onChange={onChange}
            >
              {financiaciones
                .sort(
                  ({ id: previousID }, { id: currentID }) =>
                    previousID - currentID
                )
                .map((financiacion) => (
                  <MenuItem key={financiacion.id} value={financiacion.id}>
                    {financiacion.nombre}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>
              {getError("fuente_financiacion", errores)}
            </FormHelperText>
          </FormControl>
        </Grid>
        {fuente_financiacion.includes(CODIGO_TIPO_FUENTE_OTRO) && (
          <Grid item xs={6}>
            <TextField
              value={otra_fuente_financiacion}
              id="otra_fuente_financiacion"
              required
              error={getError("otra_fuente_financiacion", errores).length > 0}
              helperText={getError("otra_fuente_financiacion", errores)}
              name="otra_fuente_financiacion"
              label="Otra fuente de financiación"
              fullWidth
              onChange={onChange}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          {/* <Grid item xs={6}> */}
          <FormControl
            className="form-control"
            required
            error={getError("idioma_minuta", errores).length > 0}
          >
            <InputLabel id="idioma_minuta_label">Idioma del documento</InputLabel>
            <Select
              labelId="idioma_minuta_label"
              id="idioma_minuta"
              name="idioma_minuta"
              value={idioma_minuta}
              fullWidth
              multiple
              onChange={onChange}
            >
              {idiomas
                .sort(
                  ({ id: previousID }, { id: currentID }) =>
                    previousID - currentID
                )
                .map((idioma) => (
                  <MenuItem key={idioma.id} value={idioma.id}>
                    {idioma.nombre}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText></FormHelperText>
          </FormControl>
        </Grid>
        {idioma_minuta && idioma_minuta.includes(CODIGO_TIPO_IDIOMA_OTRO) && (
          <Grid item xs={6}>
            <TextField
              value={otro_idioma_minuta}
              id="otro_idioma_minuta"
              required
              error={getError("otro_idioma_minuta", errores).length > 0}
              helperText={getError("otro_idioma_minuta", errores)}
              name="otro_idioma_minuta"
              label="Otro idioma minutan"
              fullWidth
              onChange={onChange}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            value={objeto_del_convenio}
            required
            error={getError("objeto_del_convenio", errores).length > 0}
            helperText={getError("objeto_del_convenio", errores)}
            multiline
            rows={4}
            id="objeto_del_convenio"
            name="objeto_del_convenio"
            label="Objeto"
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={vigencia}
            required
            error={getError("vigencia", errores).length > 0}
            helperText={getError("vigencia", errores)}
            type="number"
            id="vigencia"
            name="vigencia"
            label="Vigencia (años)"
            fullWidth
            onChange={onChange}
          />
        </Grid>

        {renovacion == 'Si' ? (
          <Grid item xs={12}>
            <TextField
              className="oculto"
              ref={fileInput}
              type="file"
              id="modelo_convenio_renovacion"
              name="modelo_convenio_renovacion"
              label="MODELO DE RENOVACIÓN."
              fullWidth
              autoComplete="billing address-line1"
              onChange={onChange}
            />
            <Grid item xs={12}>
              <InputFile
                label="MODELO DE RENOVACION."
                id="modelo_convenio_renovacion"
                value={modelo_convenio_renovacion ? modelo_convenio_renovacion : "Adjuntar modelo de renovación de convenios"}
                required={false}
              />
            </Grid>
          </Grid>
        ) : (<Grid item xs={12}>
          <TextField
            className="oculto"
            ref={fileInput}
            type="file"
            id="modelo_convenio"
            name="modelo_convenio"
            label="En caso de que la Institución Vinculante tenga un modelo de convenio por favor adjuntar aquí."
            fullWidth
            autoComplete="billing address-line1"
            onChange={onChange}
          />
          <Grid item xs={12}>
            <InputFile
              label="En caso de que la Institución Vinculante tenga un modelo de convenio por favor adjuntar aquí."
              id="modelo_convenio"
              value={modelo_convenio ? modelo_convenio : "Adjuntar modelo de convenios"}
              required={false}
            />
          </Grid>
        </Grid>)}

        {/* CAMPOS PARA CUANDO SE VA A EDITAR */}
        {opcion == 'editar' ?
          (<Grid container spacing={3} style={{ margin: "5px 0 5px 0" }} >
            <Grid item xs={6} >
              <FormControl className="form-control" >
                <TextField
                  value={codigo_conve.toUpperCase()}
                  id="txtcodigo_conve"
                  label="Código convenio"
                  name="codigo_conve"
                  placeholder="Digite el código del convenio. Ejemplo: ABC-123"
                  multiline
                  onChange={onChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                className="form-control"
                required
                error={getError("estado_convenio", errores).length > 0}
              >
                <InputLabel id="estado_convenio_label">Tipo de convenio</InputLabel>
                <Select
                  labelId="estado_convenio_label"
                  id="estado_convenio"
                  name="estado_convenio"
                  value={estado_convenio}
                  fullWidth
                  // multiple
                  onChange={onChange}
                >
                  {estados_convenio.map((tipo) => (
                    <MenuItem key={tipo.id} value={tipo.id}>
                      {tipo.nombre}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {getError("estado_convenio", errores)}
                </FormHelperText>
              </FormControl>
            </Grid>
            {/* </Grid> */}
            {/* <Grid container spacing={3} style={{ padding: "10px 0 0 0" }}>  */}
            <Grid item xs={6} >
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                <Grid container>
                  <KeyboardDatePicker
                    required={true}
                    id="fechaInicio_conv"
                    name="fechaInicio_conv"
                    style={{ marginTop: 3, width: "100%" }}
                    // minDate={Date("YYYY-MM-dd")}
                    margin="normal"
                    label="Fecha Inicio Convenio"
                    format="yyyy-MM-dd"
                    value={fechaInicio_conv}
                    // onChange={this.setFechaRealizacion}
                    onChange={(e) =>
                      onChange({
                        target: {
                          value: e,
                          name: "fechaInicio_conv",
                        },
                      })
                    }
                  // invalidDateMessage="Formato Invalido"
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                <Grid container>
                  <KeyboardDatePicker
                    required={true}
                    id="fechaFin_conv"
                    name="fechaFin_conv"
                    style={{ marginTop: 3, width: "100%" }}
                    // minDate={Date("YYYY-MM-dd")}
                    margin="normal"
                    label="Fecha Fin Convenio"
                    format="yyyy-MM-dd"
                    value={fechaFin_conv}
                    // onChange={this.setFechaRealizacion}
                    onChange={(e) =>
                      onChange({
                        target: {
                          value: e,
                          name: "fechaFin_conv",
                        },
                      })
                    }
                  // invalidDateMessage="Formato Invalido"
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            {/* </Grid> */}
          </Grid>) : ''
        }
      </Grid>
    </>
  );
}

function Info_institucion_vinculante(component_props) {
  let {
    institucion_vinculante,
    paises_select,
    rector,
    paises,
    direccion_vinculante,
    direccion_Web,
    encargado_interna,
    correo_interna,
    representacional_legal,
    buscar_interna_encargado,
    ciudad,
    cargo_interna,
    telefono_interna,
    errores,
    onChange,
    mostrarModalEncargadoInter,
    modalEncargadoInter,
    cargos,
    cambiarencargado,
  } = component_props;

  return (
    <>
      <Grid container spacing={3} style={{ marginBottom: "40px" }}>
        <Grid item xs={12}>
          <Alert severity="info">
            En esta sección debe incluir solamente la información de la
            institución vinculante, es decir, de la institución con la que se
            solicita suscribir el convenio.
          </Alert>
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={institucion_vinculante}
            required
            error={getError("institucion_vinculante", errores).length > 0}
            helperText={getError("institucion_vinculante", errores)}
            type="text"
            id="institucion_vinculante"
            name="institucion_vinculante"
            label="Nombre de la institución vinculante"
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={rector}
            required
            error={getError("rector", errores).length > 0}
            helperText={getError("rector", errores)}
            type="text"
            id="rector"
            name="rector"
            label="Nombre del rector"
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={representacional_legal}
            required
            error={getError("representacional_legal", errores).length > 0}
            helperText={getError("representacional_legal", errores)}
            type="text"
            id="representacional_legal"
            name="representacional_legal"
            label="Nombre del representante legal"
            fullWidth
            onChange={onChange}
          />
        </Grid>

        <Grid item xs={6}>
          <InputLabel id="paises_select">
            País
          </InputLabel>
          <Select
            labelId="paises_select"
            id="paises_select"
            name="paises_select"
            value={paises_select}
            required
            error={getError("paises_select", errores).length > 0}
            helperText={getError("paises_select", errores)}
            fullWidth
            onChange={onChange}
          >
            {paises.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.nombre}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={ciudad}
            required
            error={getError("ciudad", errores).length > 0}
            helperText={getError("ciudad", errores)}
            type="text"
            id="ciudad"
            name="ciudad"
            label="Ciudad"
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={direccion_vinculante}
            required
            error={getError("direccion_vinculante", errores).length > 0}
            helperText={getError("direccion_vinculante", errores)}
            type="text"
            id="direccion_vinculante"
            name="direccion_vinculante"
            label="Dirección de correspondencia"
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={direccion_Web}
            required
            error={getError("direccion_Web", errores).length > 0}
            helperText={getError("direccion_Web", errores)}
            type="text"
            id="direccion_Web"
            name="direccion_Web"
            label="Dirección web"
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={encargado_interna}
            required
            error={getError("encargado_interna", errores).length > 0}
            helperText={getError("encargado_interna", errores)}
            type="text"
            id="encargado_interna"
            name="encargado_interna"
            label="Nombre del encargado de internacionalización"
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={telefono_interna}
            required
            error={getError("telefono_interna", errores).length > 0}
            helperText={getError("telefono_interna", errores)}
            type="number"
            id="telefono_interna"
            name="telefono_interna"
            label="Teléfono del encargado de internacionalización"
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={correo_interna}
            required
            error={getError("correo_interna", errores).length > 0}
            helperText={getError("correo_interna", errores)}
            type="email"
            id="correo_interna"
            name="correo_interna"
            label="Email del encargado de internacionalización"
            fullWidth
            onChange={onChange}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            value={cargo_interna}
            required
            error={getError("cargo_interna", errores).length > 0}
            helperText={getError("cargo_interna", errores)}
            type="text"
            id="cargo_interna"
            name="cargo_interna"
            label="Cargo del encargado de internacionalización"
            fullWidth
            onChange={onChange}
          />
        </Grid>
      </Grid>

      <Dialog
        fullWidth={true}
        maxWidth="sm"
        fullScreen={window.innerWidth < 600}
        open={modalEncargadoInter}
        onClose={() => mostrarModalEncargadoInter(false)}
      >
        <AppBarModal
          titulo={"¡ Buscar Encargado !"}
          mostrarModal={() => mostrarModalEncargadoInter(false)}
          titulo_accion="CERRAR"
          accion={() => mostrarModalEncargadoInter(false)}
        />
        <DialogContent style={{ padding: "0" }} className="scroll">
          <BuscarPersona
            callback={({
              id,
              primer_nombre,
              primer_apellido,
              segundo_apellido,
            }) => {
              cambiarencargado(
                id,
                primer_nombre,
                primer_apellido,
                segundo_apellido
              );
              mostrarModalEncargadoInter(false);
            }}
          />
        </DialogContent>
        <DialogActions>
          <BtnForm
            texto="CANCELAR"
            callback={() => mostrarModalEncargadoInter(false)}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

function CumplimientoDeCriterios(component_props) {
  let {
    acreditacion_institucional,
    acreditacion_internacional_inst,
    acreditacion_internacional_prog,
    acreditacion_programa,
    acreditaciones,
    cambiarEstado,
    check_acreditacion,
    checked,
    errores,
    modal_buscar_ranking,
    modal_add_posicion,
    onChange,
    programa_facultad,
    ranking,
    solicitud_excepcion,
  } = component_props;

  return (
    <>
      <Grid container spacing={3} style={{ marginBottom: "40px" }}>
        {!check_acreditacion && (
          <>
            {/* Andrés 2187 --> se refiere al codigo de acreditacion internaciona */}
            <Grid item xs={12}>
              <FormControl className="form-control" required>
                <InputLabel id="acreditacion_institucional_label">
                  Acreditación institucional
                </InputLabel>
                <Select
                  labelId="acreditacion_institucional_label"
                  id="acreditacion_institucional"
                  multiple
                  error={
                    getError("acreditacion_institucional", errores).length > 0
                  }
                  helperText={getError("acreditacion_institucional", errores)}
                  name="acreditacion_institucional"
                  value={acreditacion_institucional}
                  fullWidth
                  onChange={onChange}
                >
                  {acreditaciones
                    .sort(
                      ({ id: previousID }, { id: currentID }) =>
                        previousID - currentID
                    )
                    .map((acreditacion) => (
                      <MenuItem key={acreditacion.id} value={acreditacion.id}>
                        {acreditacion.nombre}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            {acreditacion_institucional.includes(CODIGO_ACRE_INTERNACIONAL) && (
              <Grid item xs={7}>
                <TextField
                  value={acreditacion_internacional_inst}
                  id="acreditacion_internacional_inst"
                  required
                  error={
                    getError("acreditacion_internacional_inst", errores)
                      .length > 0
                  }
                  helperText={getError(
                    "acreditacion_internacional_inst",
                    errores
                  )}
                  name="acreditacion_internacional_inst"
                  label="Acreditación internacional institucional"
                  fullWidth
                  onChange={onChange}

                />
              </Grid>
            )}
            <Grid item xs={12}>
              <ConveniosBuscarRanking
                seleccionar={({ id, nombre, valora }) => {
                  cambiarEstado({
                    ranking: ranking.concat({
                      id: id,
                      nombre: nombre,
                      posicion: "0",
                      valora: valora
                    }),
                  });
                }}
                quitar={({ id }) => {
                  cambiarEstado({
                    ranking: ranking.filter((ran) => ran.id != id),
                  });
                }}
                seleccionadas={ranking}
                modalBuscarRanking={modal_buscar_ranking}
                mostrarModalBuscarRanking={(modal_buscar_ranking) =>
                  cambiarEstado({ modal_buscar_ranking })
                }
                modalAddPosicion={modal_add_posicion}
                mostrarModalAddPosicion={(modal_add_posicion) =>
                  cambiarEstado({ modal_add_posicion })
                }
              />
              <InputBuscar
                placeholder="Seleccione los Rankings"
                label={"Posición en Rankings"}
                id="ranking"
                value={
                  ranking.length > 0
                    ? ranking
                      .map((r) => `${r.nombre} [pos: ${r.posicion}] `)
                      .join(", ")
                    : ""
                }
                callback={() => cambiarEstado({ modal_buscar_ranking: true })}
                icon_default={false}
              />
            </Grid>

            <Grid item xs={acreditacion_programa.includes(CODIGO_ACRE_INTERNACIONAL) ? 5 : 12}>
              <FormControl className="form-control" required>
                <InputLabel id="acreditacion_programa_label">
                  Acreditación del programa
                </InputLabel>
                <Select
                  labelId="acreditacion_programa_label"
                  id="acreditacion_programa"
                  error={getError("acreditacion_programa", errores).length > 0}
                  helperText={getError("acreditacion_programa", errores)}
                  multiple
                  name="acreditacion_programa"
                  value={acreditacion_programa}
                  fullWidth
                  onChange={onChange}
                >
                  {acreditaciones
                    .sort(
                      ({ id: previousID }, { id: currentID }) =>
                        previousID - currentID
                    )
                    .map((acreditacion) => (
                      <MenuItem key={acreditacion.id} value={acreditacion.id}>
                        {acreditacion.nombre}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            {acreditacion_programa.includes(CODIGO_ACRE_INTERNACIONAL) && (
              <Grid item xs={7}>
                <TextField
                  value={acreditacion_internacional_prog}
                  id="acreditacion_internacional_prog"
                  required
                  error={
                    getError("acreditacion_internacional_prog", errores)
                      .length > 0
                  }
                  helperText={getError(
                    "acreditacion_internacional_prog",
                    errores
                  )}
                  name="acreditacion_internacional_prog"
                  label="Acreditación internacional programa"
                  fullWidth
                  onChange={onChange}
                />
              </Grid>
            )}
            {acreditacion_programa.length > 0 && (
              <Grid item xs={12}>
                <TextField
                  value={programa_facultad}
                  required
                  error={getError("programa_facultad", errores).length > 0}
                  helperText={getError("programa_facultad", errores)}
                  id="programa_facultad"
                  name="programa_facultad"
                  label="Programa o facultad"
                  fullWidth
                  onChange={onChange}
                />
              </Grid>
            )}

          </>
        )}

        {check_acreditacion && (
          <>
            <Grid item xs={12}>
              <Alert severity="info">
                Mencionar y sustentar los argumentos por los cuales el convenio
                con la institución vinculante es importante para su unidad
                (estos argumentos deben evidenciar la trayectoria académica, de
                investigación, extensión, proyección social o una propuesta de
                internacionalización solidaria).
              </Alert>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={solicitud_excepcion}
                required
                error={getError("solicitud_excepcion", errores).length > 0}
                helperText={getError("solicitud_excepcion", errores)}
                multiline
                rows={4}
                id="solicitud_excepcion"
                name="solicitud_excepcion"
                label="Solicitud de excepción"
                fullWidth
                onChange={onChange}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} style={{ padding: "0 12px" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={check_acreditacion}
                onChange={checked}
                id="check_acreditacion"
                name="check_acreditacion"
                color="primary"
              />
            }
            label="Clic aquí si la institución vinculante no cumple con los criterios de acreditación y/o rankings"
          />
        </Grid>
      </Grid>
    </>
  );
}

// Modal para agregar posicion cuando se selecciona un ranking
function AgregarPosicion({
  open,
  show,
  posicion,
  onChange,
  guardarPosicion,
  guardarPosicionRango,
  check_rango_ranking,
  checked,
  posicion_una,
  posicion_dos,
  errores,
}) {
  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth={true}
      onClose={() => show(false)}
    >
      <AppBarModal
        titulo="Agregar Posición"
        mostrarModal={() => show(false)}
        titulo_accion=""
      />
      <DialogContent className="scroll">
        <Grid item xs={12} md={12}>
          <FormControl fullWidth style={{ marginTop: "10px" }}>
            {posicion.length > 0 ?
              <Grid item xs={12} style={{ padding: "0 12px" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={check_rango_ranking}
                      onChange={checked}
                      id="check_rango_ranking"
                      name="check_rango_ranking"
                      color="primary"
                      disabled
                    />
                  }
                  label="¿Rango?"
                />
              </Grid> : <Grid item xs={12} style={{ padding: "0 12px" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={check_rango_ranking}
                      onChange={checked}
                      id="check_rango_ranking"
                      name="check_rango_ranking"
                      color="primary"
                    />
                  }
                  label="¿Rango?"
                />
              </Grid>
            }
            {check_rango_ranking ?
              <Grid container spacing={1} style={{ justifyContent: "space-around" }}>
                <Grid item xs={5}>
                  <TextField
                    value={posicion_una}
                    required
                    error={getError("posicion_una", errores).length > 0}
                    helperText={getError("posicion_una", errores)}
                    type="number"
                    id="posicion_una"
                    name="posicion_una"
                    label="Posición 1"
                    fullWidth
                    onChange={onChange}
                  />
                </Grid>
                <Grid item xs={1} style={{ marginTop: "23px", textAlign: "center" }}>
                  -
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    value={posicion_dos}
                    required
                    error={getError("posicion_dos", errores).length > 0}
                    helperText={getError("posicion_dos", errores)}
                    type="number"
                    id="posicion_dos"
                    name="posicion_dos"
                    label="Posición 2"
                    fullWidth
                    onChange={onChange}
                  />
                </Grid>
              </Grid> : <TextField
                value={posicion}
                required
                error={getError("posicion", errores).length > 0}
                helperText={getError("posicion", errores)}
                type="number"
                id="posicion"
                name="posicion"
                label="Posición"
                fullWidth
                onChange={onChange}
              />
            }
          </FormControl>
        </Grid>
      </DialogContent>
      <DialogActions>
        <BtnForm texto="Cerrar" callback={() => show(false)} />
        {check_rango_ranking ? <BtnForm texto="Guardar" callback={() => guardarPosicionRango(posicion_una, posicion_dos)} /> : <BtnForm texto="Guardar" callback={() => guardarPosicion(posicion)} />}
      </DialogActions>
    </Dialog>
  );
}

function PlanDeTrabajo({
  cambiarEstado,
  Tipos_actividades_asignadas,
  nombre_enlace_vin,
  unidad_enlace_vin,
  telefono_enlace_vin,
  cargo_enlace_vin,
  email_enlace_vin,
  errores,
  onChange,
  nombre_enlace_Uni,
  unidad_enlace_Uni,
  telefono_enlace_Uni,
  email_enlace_Uni,
  cargo_enlace_Uni,
  nombre_completo,
  dependencia_enlace_Uni,
  dependencias
}) {
  console.log(Tipos_actividades_asignadas)
  return (
    <>
      <Grid container spacing={1} style={{ marginBottom: "40px" }}>
        <Grid item xs={9}>
          <Alert severity="info">
            En esta sección se debe agregar la información correspondiente al
            plan de trabajo que se realizará en el marco del convenio. Hacer
            clic en "Agregar actividad".
          </Alert>
        </Grid>

        <Grid item xs={3}>
          <BtnForm
            texto="Agregar actividad"
            callback={() => cambiarEstado(true)}
          />
        </Grid>
        <Grid></Grid>

        <Grid item xs={12}>
          {Tipos_actividades_asignadas.length > 0 ?
            (<ListarDatos
              datos={Tipos_actividades_asignadas}
              titulo="Tipos de actividades agregadas"
              id="tbl_historial_asistencia"
              opciones={true}
              // acciones={(data) => acciones(data)}
              buscar={true}
              // avatar={({ grupo }) => grupo}
              // color_avatar={() => obtenerColor()}
              fila_principal={({ tipo_actividad }) => tipo_actividad ? tipo_actividad.nombre : ""}
              filas={[
                {
                  id: "fecha_registro", mostrar: ({ fecha_realizacion }) => fecha_realizacion ? moment(fecha_realizacion).format("YYYY-MM-DD") : "", nombre: "Fecha Realizacion : ",
                },
                {
                  id: "presupuesto_Unicosta",
                  mostrar: ({ presupuesto_Unicosta }) => presupuesto_Unicosta ? presupuesto_Unicosta : "",
                  nombre: "Presupuesto Unicosta: $",
                },
                {
                  id: "presupuesto_Vinculante",
                  mostrar: ({ presupuesto_Vinculante }) => presupuesto_Vinculante ? presupuesto_Vinculante : "",
                  nombre: "Presupuesto Vinculante: $",
                },
              ]}
            />) : (<TareasTerminadas
              imagen={emma_w}
              mensaje="Esta bandeja está vacía"
              widthImg="8%"
              marginTop="8%"
            />)}
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{ fontWeight: 600 }}
            variant="subtitle1"
            //  color="textSecondary"
            component="h3"
            gutterBottom
          >
            Enlace operativo de la Institución Vinculante
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Alert severity="info">
            (i) A continuación debes incluir la información del enlace operativo de la Institución Vinculante
          </Alert>
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={nombre_enlace_vin}
            required
            error={getError("nombre_enlace_vin", errores).length > 0}
            helperText={getError("nombre_enlace_vin", errores)}
            type="text"
            id="nombre_enlace_vin"
            name="nombre_enlace_vin"
            label="Nombre"
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={unidad_enlace_vin}
            required
            error={getError("unidad_enlace_vin", errores).length > 0}
            helperText={getError("unidad_enlace_vin", errores)}
            type="text"
            id="unidad_enlace_vin"
            name="unidad_enlace_vin"
            label="Unidad"
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={telefono_enlace_vin}
            required
            error={getError("telefono_enlace_vin", errores).length > 0}
            helperText={getError("telefono_enlace_vin", errores)}
            type="number"
            id="telefono_enlace_vin"
            name="telefono_enlace_vin"
            label="Teléfono"
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={cargo_enlace_vin}
            required
            error={getError("cargo_enlace_vin", errores).length > 0}
            helperText={getError("cargo_enlace_vin", errores)}
            type="text"
            id="cargo_enlace_vin"
            name="cargo_enlace_vin"
            label="Cargo"
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={email_enlace_vin}
            required
            error={getError("email_enlace_vin", errores).length > 0}
            helperText={getError("email_enlace_vin", errores)}
            type="text"
            id="email_enlace_vin"
            name="email_enlace_vin"
            label="Email"
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{ fontWeight: 600 }}
            variant="subtitle1"
            //  color="textSecondary"
            component="h3"
            gutterBottom
          >
            Enlace operativo UNICOSTA
          </Typography>
          <Grid item xs={12}>
            <Alert severity="info">
              (i) A continuación debes incluir la información del enlace operativo UNICOSTA
            </Alert>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={nombre_enlace_Uni}
            required
            error={getError("nombre_enlace_Uni", errores).length > 0}
            helperText={getError("nombre_enlace_Uni", errores)}
            type="text"
            id="nombre_enlace_Uni"
            name="nombre_enlace_Uni"
            label="Nombre"
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={unidad_enlace_Uni}
            required
            error={getError("unidad_enlace_Uni", errores).length > 0}
            helperText={getError("unidad_enlace_Uni", errores)}
            type="text"
            id="unidad_enlace_Uni"
            name="unidad_enlace_Uni"
            label="Unidad"
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl
            className="form-control"
            required
            error={getError("dependencia_enlace_Uni", errores).length > 0}
          >
            <InputLabel id="dependencia_enlace_Uni_label">
              Departamento académico o unidad administrativa
            </InputLabel>
            <Select
              labelId="dependencia_enlace_Uni_label"
              id="dependencia_enlace_Uni"
              name="dependencia_enlace_Uni"
              value={dependencia_enlace_Uni}
              fullWidth
              onChange={onChange}
            >
              {dependencias.map(({ id, nombre }) => (
                <MenuItem key={id} value={id}>
                  {nombre.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{getError("dependencia_enlace_Uni", errores)}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={telefono_enlace_Uni}
            required
            error={getError("telefono_enlace_Uni", errores).length > 0}
            helperText={getError("telefono_enlace_Uni", errores)}
            type="number"
            id="telefono_enlace_Uni"
            name="telefono_enlace_Uni"
            label="Teléfono"
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={cargo_enlace_Uni}
            required
            error={getError("cargo_enlace_Uni", errores).length > 0}
            helperText={getError("cargo_enlace_Uni", errores)}
            type="text"
            id="cargo_enlace_Uni"
            name="cargo_enlace_Uni"
            label="Cargo"
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={email_enlace_Uni}
            required
            error={getError("email_enlace_Uni", errores).length > 0}
            helperText={getError("email_enlace_Uni", errores)}
            type="text"
            id="email_enlace_Uni"
            name="email_enlace_Uni"
            label="Email"
            fullWidth
            onChange={onChange}
          />
        </Grid>
      </Grid>
    </>
  );
}

class ConveniosCrear extends Component {
  constructor(props) {
    super(props);
    this.state = {
      acreditacion_internacional_inst: "",
      acreditacion_internacional_prog: "",
      archivoSend: "",
      categoria_convenio: "",
      celular: "",
      correo_personal: "",
      dependencia: "",
      dependencia_enlace_Uni: "",
      idioma_minuta: [],
      institucion: "",
      nombre_completo: "",
      modelo_convenio: "",
      modelo_convenio_renovacion: "",
      objeto_del_convenio: "",
      otra_financiacion: "",
      otro_idioma: "",
      otro_subtipo: "",
      programa_facultad: "",
      posicion: "",
      solicitud_excepcion: "",
      subtipo_convenio: [],
      tipo_convenio: [],
      otro_tipo_convenio: "",
      otro_subtipo_convenio: "",
      otra_fuente_financiacion: "",
      otro_idioma_minuta: "",
      tit_cargando: "Cargando...",
      vigencia: "",
      activeStep: 0,
      acreditacion_institucional: [],
      acreditacion_programa: [],
      acreditaciones: [],
      categorias: [],
      dependencias: [],
      errores: [],
      erroresAct: [],
      financiaciones: [],
      fuente_financiacion: [],
      idiomas: [],
      instituciones: [],
      ranking: [],
      subtipos: [],
      tipos: [],
      cargando: true,
      check_acreditacion: false,
      modal_add_posicion: false,
      modal_buscar_ranking: false,
      modalBuscarUniversidad: false,
      institucion_vinculante: "",
      paises_select: 0,
      rector: "",
      paises: [],
      direccion_vinculante: "",
      direccion_Web: "",
      encargado_interna: "",
      correo_interna: "",
      cargo_interna: "",
      telefono_interna: "",
      ciudad: "",
      representacional_legal: "",
      buscar_interna_encargado: false,
      id_solicitud: 0,
      modal_add_act: false,
      back_tipoActividades: [],
      select_tipoActividad: [],
      descripcion_tipoActividad: "",
      fechaRealizacion: null,
      fechaInicio_conv: null,
      fechaFin_conv: null,
      presupuesto_unicosta: 0,
      presupuesto_vinculante: 0,
      Tipos_actividades_asignadas: [],
      id_convenio: 0,
      nombre_enlace_vin: "",
      unidad_enlace_vin: "",
      telefono_enlace_vin: "",
      cargo_enlace_vin: "",
      email_enlace_vin: "",
      nombre_enlace_Uni: "",
      unidad_enlace_Uni: "",
      telefono_enlace_Uni: "",
      email_enlace_Uni: "",
      cargo_enlace_Uni: "",
      persona: "",
      check_rango_ranking: false,
      posicion_una: '',
      posicion_dos: '',
      check_opcion_suscripcion: false,
      check_opcion_renovacion: false,
      convenios_registrados: [],
      id_renovacion: null,
      renovacion: '',
      convenio_sele: [],
      aux_opcion: '',
      codigo_conve: '',
      estados_convenio: [],
      estado_convenio: '',
      nombre_conv_ren: '',
      personas: [],
      gestorDependencia: {
        id: 0,
      },
    };
    this.fileInput = React.createRef();
  }
  async obtenerBorradorconvenio() {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/internacionalizacion/convenios/obtener_borrador`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }
  async obtenerAdjuntos(id) {
    let f = await generarFiltros([{ 'llave': 'requisito__codigo', 'valor': "Mod_con_otra_uni" }, { 'llave': 'solicitud', 'valor': id }]);
    return new Promise(resolve => {
      consulta(`api/v1.0/internacionalizacion/solicitud/adjuntos?${f}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      });
    });
  }

  async obtenerAdjuntosRenovacion(id) {
    let f = await generarFiltros([{ 'llave': 'requisito__codigo', 'valor': "mod_conv_ren" }, { 'llave': 'solicitud', 'valor': id }]);
    return new Promise(resolve => {
      consulta(`api/v1.0/internacionalizacion/solicitud/adjuntos?${f}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      });
    });
  }
  async cargarDatosBorrador(datos, cumplimiento, inf_conevnio) {
    console.log(datos)
    // this.setState({ cargando: true })
    let {
      paso_actual,
      programa_facultad,
      usuario_registro,
      id,
      institucion,
      rector,
      direccion_vinculante,
      direccion_Web,
      encargado_interna,
      correo_interna,
      telefono_interna,
      representacion_legal,
      cargo_interna,
      pais_vinculante,
      ciudad_vinculante,
      solicitud,
      tipo_convenio,
      subtipo_convenio,
      fuente_financiacion,
      idioma_minuta,
      objeto_del_convenio,
      vigencia,
      otro_tipo_convenio,
      otro_subtipo_convenio,
      otra_fuente_financiacion,
      otro_idioma_minuta,
      modelo_convenio,
      nombre_enlace_vin,
      telefono_enlace_Uni,
      telefono_enlace_vin,
      unidad_enlace_Uni,
      unidad_enlace_vin,
      cargo_enlace_Uni,
      cargo_enlace_vin,
      email_enlace_vin,
      email_enlace_Uni,
      nombre_enlace_Uni,
      programa_facultad_3,
      solicitud_excepcion,
      acreditacion_internacional_inst,
      acreditacion_internacional_prog,
      solicitud_renovacion,
      codigo_convenio,
      fecha_inicio,
      fecha_finalizacion,
      estado_convenio,
      dpto_academico_enlaceUni,
      gestor_dependencia
    } = datos;
    console.log(solicitud_renovacion)
    let { persona, renovacion } = this.state
    let { Acreditacion_inst, Acreditacion_prog, Ranking } = cumplimiento;
    let {
      TipoConvenio,
      Subtipo_convenio,
      fuenteFinanciacion_convenio,
      Idioma_minuta,
    } = inf_conevnio;
    let helpAcreditacionInst = [];
    let helpAcreditacionProg = [];
    let helpRanking = [];
    let helpTipoConvenio = [];
    let helpIdiomaMinuta = [];
    let helpSubtipo_convenio = [];
    let helpfuenteFinanciacion_convenio = [];
    if (solicitud_renovacion == 'Si' || renovacion == 'Si') {
      console.log('entré en si');
      if (paso_actual == 0) {
        console.log('entré en 0');
        paso_actual = paso_actual + 1
        console.log(paso_actual);
      } else if (paso_actual == 5) {
        console.log('paso actual 5');
        paso_actual = 1
        console.log(paso_actual);
      } else {
        paso_actual = paso_actual
        console.log(paso_actual);
      }
    } else {
      console.log('sinoooo');
      paso_actual = paso_actual
      console.log(paso_actual);
    }

    console.log(paso_actual);

    /* else if(this.props.opcion == 'editar') {
     if (paso_actual == 0)paso_actual =  1
     else if (paso_actual == 5) {
       paso_actual = 1
     }
   } */

    this.setState({
      gestorDependencia: {
        id: gestor_dependencia,
      },
      id_solicitud: solicitud.id,
      id_convenio: id ? id : 0,
      id_renovacion: {
        id: solicitud ? solicitud.id : solicitud.id,
      },
      activeStep: paso_actual,
      dependencia: programa_facultad ? programa_facultad.id : 0,
      nombre_completo:
        usuario_registro.primer_nombre +
        " " +
        usuario_registro.primer_apellido +
        " " +
        usuario_registro.segundo_apellido,
      correo_personal: usuario_registro.correo,
      celular: usuario_registro.celular,
      institucion_vinculante: institucion ? institucion : "",
      rector: rector ? rector : "",
      direccion_vinculante: direccion_vinculante ? direccion_vinculante : "",
      direccion_Web: direccion_Web ? direccion_Web : "",
      encargado_interna: encargado_interna ? encargado_interna : "",
      correo_interna: correo_interna ? correo_interna : "",
      telefono_interna: telefono_interna ? telefono_interna : "",
      representacional_legal: representacion_legal ? representacion_legal : "",
      cargo_interna: cargo_interna ? cargo_interna : "",
      paises_select: pais_vinculante ? pais_vinculante.id : "",
      ciudad: ciudad_vinculante ? ciudad_vinculante : "",
      tipo_convenio: tipo_convenio ? tipo_convenio.id : [],
      subtipo_convenio: subtipo_convenio ? subtipo_convenio.id : [],
      fuente_financiacion: fuente_financiacion ? fuente_financiacion.id : [],
      // idioma_minuta: idioma_minuta ? [idioma_minuta.id] : [],
      objeto_del_convenio: objeto_del_convenio ? objeto_del_convenio : "",
      programa_facultad: programa_facultad_3 ? programa_facultad_3 : "",
      check_acreditacion: solicitud_excepcion ? true : false,
      vigencia: vigencia ? vigencia : "",
      solicitud_excepcion: solicitud_excepcion ? solicitud_excepcion : "",
      otro_tipo_convenio: otro_tipo_convenio ? otro_tipo_convenio : "",
      otro_subtipo_convenio: otro_subtipo_convenio ? otro_subtipo_convenio : "",
      otra_fuente_financiacion: otra_fuente_financiacion
        ? otra_fuente_financiacion
        : "",
      otro_idioma_minuta: otro_idioma_minuta ? otro_idioma_minuta : "",
      // modelo_convenio: modelo_convenio ? modelo_convenio : "",
      nombre_enlace_vin: nombre_enlace_vin ? nombre_enlace_vin : "",
      nombre_enlace_vin: nombre_enlace_vin ? nombre_enlace_vin : "",
      telefono_enlace_Uni: telefono_enlace_Uni ? telefono_enlace_Uni : "",
      unidad_enlace_Uni: unidad_enlace_Uni ? unidad_enlace_Uni : "",
      telefono_enlace_vin: telefono_enlace_vin ? telefono_enlace_vin : "",
      unidad_enlace_vin: unidad_enlace_vin ? unidad_enlace_vin : "",
      cargo_enlace_Uni: cargo_enlace_Uni ? cargo_enlace_Uni : "",
      cargo_enlace_vin: cargo_enlace_vin ? cargo_enlace_vin : "",
      email_enlace_vin: email_enlace_vin ? email_enlace_vin : "",
      email_enlace_Uni: email_enlace_Uni ? email_enlace_Uni : "",
      nombre_enlace_Uni: nombre_enlace_Uni ? nombre_enlace_Uni : "",
      acreditacion_internacional_inst: acreditacion_internacional_inst ? acreditacion_internacional_inst : "",
      acreditacion_internacional_prog: acreditacion_internacional_prog ? acreditacion_internacional_prog : "",
      check_opcion_suscripcion: solicitud_renovacion == 'No' ? false : true,
      check_opcion_renovacion: solicitud_renovacion == 'Si' ? true : false,
      codigo_conve: codigo_convenio ? codigo_convenio : '',
      fechaInicio_conv: fecha_inicio ? fecha_inicio : null,
      fechaFin_conv: fecha_finalizacion ? fecha_finalizacion : null,
      estado_convenio: estado_convenio ? estado_convenio.id : null,
      // renovacion: solicitud_renovacion == 'Si' ? solicitud_renovacion : 'No',
      dependencia_enlace_Uni: dpto_academico_enlaceUni ? dpto_academico_enlaceUni.id : null
    });
    if (!this.state.nombre_enlace_Uni) {
      this.setState({
        nombre_enlace_Uni:
          persona.primer_nombre +
          " " +
          persona.segundo_nombre +
          " " +
          persona.primer_apellido +
          " " +
          persona.segundo_apellido,
        telefono_enlace_Uni: persona.telefono ? persona.telefono : "",
        email_enlace_Uni: persona.correo ? persona.correo : "",
      })
    }
    if (Idioma_minuta.length > 0) {
      Idioma_minuta.map((item) => {
        helpIdiomaMinuta.push(item.idioma_minuta_convenio.id);
      });

      this.setState({
        idioma_minuta: helpIdiomaMinuta,
        activeStep: TipoConvenio[0].paso_actual,
      });
    }

    if (TipoConvenio.length > 0) {
      TipoConvenio.map((item) => {
        helpTipoConvenio.push(item.tipo_convenio_convenio.id);
      });
      this.setState({
        tipo_convenio: helpTipoConvenio,
        activeStep: TipoConvenio[0].paso_actual,
      });
    }
    if (Subtipo_convenio.length > 0) {
      Subtipo_convenio.map((item) => {
        helpSubtipo_convenio.push(item.sub_tipo_convenio.id);
      });
      this.setState({
        subtipo_convenio: helpSubtipo_convenio,
        activeStep: Subtipo_convenio[0].paso_actual,
      });
    }
    if (fuenteFinanciacion_convenio.length > 0) {
      fuenteFinanciacion_convenio.map((item) => {
        helpfuenteFinanciacion_convenio.push(
          item.fuentefinanciacion_convenio.id
        );
      });
      this.setState({
        fuente_financiacion: helpfuenteFinanciacion_convenio,
        activeStep: fuenteFinanciacion_convenio[0].paso_actual,
      });
    }
    if (Acreditacion_inst.length > 0) {
      Acreditacion_inst.map((item) => {
        helpAcreditacionInst.push(item.acreditacion_ins.id);
      });
      this.setState({
        acreditacion_institucional: helpAcreditacionInst,
        activeStep: Acreditacion_inst[0].paso_actual,
      });
    }
    if (Acreditacion_prog.length > 0) {
      Acreditacion_prog.map((item) => {
        helpAcreditacionProg.push(item.acreditacion_prog.id);
      });
      this.setState({
        acreditacion_programa: helpAcreditacionProg,
        activeStep: Acreditacion_prog[0].paso_actual,
      });
    }
    if (Ranking.length > 0) {
      Ranking.map((item) => {
        if (item.posicion_dos) {
          helpRanking.push({
            id: item.ranking.id,
            nombre: item.ranking.nombre,
            posicion: item.posicion + ' - ' + item.posicion_dos,
            // posicion_dos: item.posicion_dos,
          });
        } else {
          helpRanking.push({
            id: item.ranking.id,
            nombre: item.ranking.nombre,
            posicion: item.posicion,
          });
        }
      });
      this.setState({
        ranking: helpRanking,
        activeStep: Ranking[0].paso_actual,
      });
    }
    if (this.props.opcion == 'editar') {
      this.setState({ activeStep: 1 })
    } else {
      this.setState({ activeStep: paso_actual })
    }
    // this.setState({ cargando: false })
  }

  async obtenerDatosIniciales() {
    let { Tipos_actividades_asignadas } = this.state;
    let { opcion, convenio_adm } = this.props;
    if (opcion !== 'editar') this.setState({ aux_opcion: 'editar' })
    let convenio = []
    // this.setState({ cargando: true });
    if (opcion !== "editar") {

      convenio = await this.obtenerBorradorconvenio()
    }
    console.log(convenio_adm)


    let cumplimiento = [];
    let inf_conevnio = [];
    let adjunto

    if (convenio.length > 0 && (opcion !== "editar" || this.state.aux_opcion !== 'editar') && convenio[0].solicitud_renovacion !== 'Si') {
      console.log('aqui - 1')

      Tipos_actividades_asignadas = this.obtenerBorradorTipoActividad(convenio[0].id);
      adjunto = await this.obtenerAdjuntos(convenio[0].solicitud.id)
      cumplimiento = await this.obtenerBorradorCumplimiento(convenio[0].id);
      inf_conevnio = await this.obtenerBorradorInfConvenio(convenio[0].id);
      console.log(Tipos_actividades_asignadas)
      this.setState({ Tipos_actividades_asignadas, modelo_convenio: adjunto[0] ? adjunto[0].nombre_archivo : "" });

    } else if (convenio_adm && (opcion == 'editar' || this.state.aux_opcion == 'editar')) {
      console.log('aqui - 2')

      Tipos_actividades_asignadas = await this.obtenerBorradorTipoActividad(
        convenio_adm.id
      );
      adjunto = await this.obtenerAdjuntos(convenio_adm.solicitud.id)
      cumplimiento = await this.obtenerBorradorCumplimiento(convenio_adm.id);
      inf_conevnio = await this.obtenerBorradorInfConvenio(convenio_adm.id);
      this.setState({ Tipos_actividades_asignadas, modelo_convenio: adjunto[0] ? adjunto[0].nombre_archivo : "" });

    } else if (convenio.length > 0 && convenio[0].solicitud_renovacion === 'Si' && (opcion !== "editar" || this.state.aux_opcion !== 'editar')) {
      console.log('aqui - 3')

      Tipos_actividades_asignadas = await this.obtenerBorradorTipoActividad(convenio[0].id);
      // adjunto = await this.obtenerAdjuntos(convenio[0].solicitud.id)
      adjunto = await this.obtenerAdjuntosRenovacion(convenio[0].solicitud.id)
      cumplimiento = await this.obtenerBorradorCumplimiento(convenio[0].id);
      inf_conevnio = await this.obtenerBorradorInfConvenio(convenio[0].id);
      console.log(Tipos_actividades_asignadas)
      this.setState({ Tipos_actividades_asignadas, modelo_convenio_renovacion: adjunto[0] ? adjunto[0].nombre_archivo : "", id_renovacion: { id: convenio[0].solicitud.id }});
    }
    console.log(convenio[0])
    // Obtiene solicitud de convenio de movilidad en caso de haber.
    if (convenio && convenio.length == 1 && (opcion !== "editar" || this.state.aux_opcion !== 'editar')) {
      this.cargarDatosBorrador(convenio[0], cumplimiento, inf_conevnio); // Si hay convenio en borrador, se cargan los datos.
    } else if (convenio_adm && (opcion == 'editar' || this.state.aux_opcion == 'editar')) {
      this.cargarDatosBorrador(convenio_adm, cumplimiento, inf_conevnio); // Si hay convenio en borrador, se cargan los datos.
    }
    this.setState({ cargando: false });

  }
  // async componentDidMount(){
  //   let convenio2 = [];
  //   convenio2 = await this.obtenerBorradorconvenio()
  //   if(convenio2){
  //   this.setState({id_convenio : convenio2[0].id})}
  // }

  async componentDidUpdate(
    { modalAddConvenio, listaSolicitudes, periodo, modalEdiConvenio },
    {
      modal_add_act,
      id_convenio,
      tipo_convenio,
      subtipo_convenio,
      fuente_financiacion,
      idioma_minuta,
      check_opcion_renovacion,
      activeStep,
      renovacion,
      id_renovacion,
      dependencia
    }
  ) {
    let { modalAddConvenio: modalAddConvenioNew, modalEdiConvenio: modalEdiConvenioNew } = this.props;
    let {
      modal_add_act: modal_add_actNew,
      tipo_convenio: tipo_convenio_new,
      subtipo_convenio: subtipo_convenio_new,
      fuente_financiacion: fuente_financiacion_new,
      idioma_minuta: idioma_minuta_new,
      check_opcion_renovacion: check_opcion_renovacionNew,
      activeStep: activeStepNew,
      renovacion: renovacionNew,
      id_renovacion: id_renovacionNew,
      dependencia: dependenciaNew,
    } = this.state;
    if (modalAddConvenio !== modalAddConvenioNew || modalEdiConvenio !== modalEdiConvenioNew) {
      if (modalAddConvenioNew || modalEdiConvenioNew) {
        this.setState({ cargando: true });
        let datos_sesion = await obtenerMisDatos();
        // let dptos_academicos = await obtenerValoresFiltros([
        //   { llave: "generica", valor: DPTOS_ACADEMICOS },
        //   { llave: "estado", valor: 1 }]);
        // let uds_administrativas = await obtenerValoresFiltros([
        //   { llave: "generica", valor: UNIDADES_ADMINISTRATIVAS },
        //   { llave: "estado", valor: 1 }]);
        let dptos_academicos = await obtenerValoresFiltros([
          { llave: "generica", valor: DPTOS_ACADEMICOS },
          { llave: "estado", valor: 1 }, , {'llave': 'valorf', 'valor': 'dependencias'}
        ]);
        let uds_administrativas = await obtenerValoresFiltros([
          { llave: "generica", valor: UNIDADES_ADMINISTRATIVAS },
          { llave: "estado", valor: 1 }, , {'llave': 'valorf', 'valor': 'dependencias'}
        ]);
        // let instituciones = await obtenerValoresFiltros([
        //   { llave: "generica", valor: UNIVERSIDADES },
        //   { llave: "estado", valor: 1 },
        // ]);
        let tipos = await obtenerValoresFiltros([
          { llave: "generica", valor: TIPOS_CONVENIOS },
        ]);
        let subtipos = await obtenerValoresFiltros([
          { llave: "generica", valor: SUBTIPOS_CONVENIOS },
        ]);
        let categorias = await obtenerValoresFiltros([
          { llave: "generica", valor: CATEGORIAS_CONVENIOS },
        ]);
        let idiomas = await obtenerValoresFiltros([
          { llave: "generica", valor: IDIOMAS },
        ]);
        let financiaciones = await obtenerValoresFiltros([
          { llave: "generica", valor: FUENTES_FINANCIACION },
        ]);
        let acreditaciones = await obtenerValoresFiltros([
          { llave: "generica", valor: ACREDITACIONES },
        ]);
        let paises = await obtenerValoresFiltros([
          { llave: "generica", valor: PAISES },
        ]);
        // let cargos = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': CARGO }])
        let back_tipoActividades = await obtenerValoresFiltros([
          { llave: "generica", valor: TIPO_ACTIVIDADES_CONVENIOS },
        ]);
        let estados_convenio = await obtenerValoresFiltros([
          { llave: "generica", valor: ESTADOS_CONVENIOS },
        ]);
        let facultades = await obtenerValoresFiltros([{llave: "generica", valor: FACULTADES_DEPENDENCIAS},{ llave: "estado", valor: 1 }, {'llave': 'valorf', 'valor': 'dependencias'}]);
        // let facultades = await obtenerValoresFiltros([{ llave: "generica", valor: FACULTADES_DEPENDENCIAS }, { llave: "estado", valor: 1 }]);
        let data = uds_administrativas.concat(dptos_academicos, facultades);
        let dependencias = data.sort((a, b) => a.nombre.localeCompare(b.nombre));
        let persona = datos_sesion.persona;
        this.setState({ persona })
        // let dependencias = dptos_academicos.concat(uds_administrativas);
        this.actualizarEstados({
          estados_convenio,
          back_tipoActividades,
          persona,
          dependencias,
          // instituciones,
          tipos,
          subtipos,
          categorias,
          idiomas,
          financiaciones,
          acreditaciones,
          paises,
        });
        this.obtenerDatosIniciales();

        // this.setState({ cargando: false });
      }
      if (!modalAddConvenioNew || !modalEdiConvenioNew) {
        this.setState({ check_opcion_renovacion: false, check_opcion_suscripcion: false, id_renovacion: { id: null, nombre: '' }, cargando: true })
      }
    }

    if (check_opcion_renovacion !== check_opcion_renovacionNew) {
      if (check_opcion_renovacionNew) {
        this.setState({ cargando: true })
        let convenios_registrados = await this.obtenerSolicitudes([{ 'llave': 'solicitud__tipo_solicitud__codigo', 'valor': 'Con_Int' }, { 'llave': 'solicitud__estado_actual__codigo', 'valor': 'Con_regis' }])
        this.setState({ convenios_registrados, cargando: false })
        check_opcion_renovacionNew = false
      } else if (!check_opcion_renovacionNew) {
        this.setState({ id_renovacion: { id: null, nombre: '' } })
      }
    }

    if (renovacion !== renovacionNew) {
      console.log('distinto');
      if (renovacionNew == 'Si') {
        // this.setState({renovacion: 'Si'})
        this.obtenerDatosInicialesRenovacion()
      }
    }

    if (modal_add_act !== modal_add_actNew) {
      let convenio2 = [];

      if (modal_add_actNew) convenio2 = await this.obtenerBorradorconvenio();
      if (!modal_add_actNew) {
        let Tipos_actividades_asignadas = await this.obtenerBorradorTipoActividad(this.state.id_convenio);
        this.setState({ Tipos_actividades_asignadas, cargando: false, select_tipoActividad: [], descripcion_tipoActividad: '', fechaRealizacion: null, presupuesto_unicosta: 0, presupuesto_vinculante: 0 });
      }
    }

    if (dependenciaNew !== dependencia) {
      if (dependenciaNew) {
        this.setState({ cargando: true })
        let personas = await this.obtenerGestores();
        console.log(personas)
        if (personas[0]) {
          this.setState({ personas: personas[0], cargando: false, gestorDependencia: personas ? { id: personas[0].id } : null })
        } else {
          this.setState({ personas: [], cargando: false, gestorDependencia: { id: 0 } })

        }
        // if (personas.length === 1){} this.setState({ gestorDependencia: { id: personas[0].id }, cargando: false})

      }
      // this.setState({ gestorDependencia: { id: '' }, cargando:false})
    }

  }

  async obtenerGestores() {
    let { dependencia } = this.state
    let f = await generarFiltros([{ 'llave': 'dependencia', 'valor': dependencia }]);
    return new Promise(resolve => {
      consulta(`api/v1.0/internacionalizacion/encargados_dependencias/convenio?${f}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }



  crearBorradorSolicitud = async (data, callback) => {
    let { actualizarMensaje } = this.props;
    let form = await crear_form_data(data);
    formulario(
      `api/v1.0/internacionalizacion/solicitudes/crear_borrador_convenio`,
      form,
      "post",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error",
          solicitud = {};
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            this.setState({
              id_solicitud: resp.solicitud.id
            });
            // callback(true, solicitud);
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
            callback(false);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  };

  async obtenerSolicitudes(filtros = []) {
    let f = await generarFiltros(filtros)
    return new Promise(resolve => {
      consulta(`api/v1.0/internacionalizacion/convenios/obtener_convenios/0?${f}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null)
      })
    })
  }

  async obtenerBorradorTipoActividad(id_convenio) {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/internacionalizacion/solicitudes/${id_convenio}/obtener_tipoActividad`,
        null,
        null,
        (error, estado, resp) => {
          console.log(resp)
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }
  async obtenerBorradorCumplimiento(id_convenio) {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/internacionalizacion/solicitudes/${id_convenio}/obtener_pasoCumplimiento`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }
  async obtenerBorradorInfConvenio(id_convenio) {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/internacionalizacion/solicitudes/${id_convenio}/obtener_pasoInfConvenio`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  async obtenerDatosInicialesRenovacion() {
    let { Tipos_actividades_asignadas, id_renovacion } = this.state;
    this.setState({ cargando: true });
    let convenio = await this.obtenerConvenio(id_renovacion.id);
    let cumplimiento = [];
    let inf_conevnio = [];
    let adjunto
    if (convenio.length > 0) {
      Tipos_actividades_asignadas = await this.obtenerBorradorTipoActividad(
        convenio[0].id
      );
      adjunto = await this.obtenerAdjuntos(convenio[0].solicitud.id)
      cumplimiento = await this.obtenerBorradorCumplimiento(convenio[0].id);
      inf_conevnio = await this.obtenerBorradorInfConvenio(convenio[0].id);
      this.setState({ Tipos_actividades_asignadas, modelo_convenio: adjunto[0] ? adjunto[0].nombre_archivo : "" });
    }
    // Obtiene solicitud de convenio de movilidad en caso de haber.
    if (convenio && convenio.length == 1)
      this.cargarDatosBorrador(convenio[0], cumplimiento, inf_conevnio); // Si hay convenio en borrador, se cargan los datos.
    // this.cargarDatosBorrador(convenio[0]); // Si hay convenio en borrador, se cargan los datos.

    this.setState({ cargando: false });
  }

  async obtenerConvenio(id) {
    let f = await generarFiltros([{ 'llave': 'solicitud', 'valor': id }]);
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/internacionalizacion/convenios/obtener_convenio_renovacion?${f}`,
        null,
        null,
        (error, estado, resp) => {
          this.setState({ convenio_sele: resp[0] })
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  ModificarBorradorSolicitud = async (id, data, callback) => {
    let { actualizarMensaje, mostrarModalAddConvenio, mostrarModalEdiConvenio } = this.props;
    let { activeStep } = this.state;
    let form = await crear_form_data(data);
    this.setState({cargando: true})
    formulario(
      `api/v1.0/internacionalizacion/solicitudes/${id}/modificar_borrador_convenio`,
      form,
      "post",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error",
          solicitud = {};
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            solicitud = resp.solicitud;
            // callback(true, solicitud);
            
            if (activeStep == 5 && this.props.opcion !== 'editar') {
              
              mostrarModalAddConvenio(false)
              this.reiniciarEstados()
              // this.setState({cargando: true})
              
            } else if (activeStep == 5 && this.props.opcion == 'editar') {
              mostrarModalEdiConvenio(false)
              // this.reiniciarEstados()
            }
            this.setState({cargando: false})

          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
            callback(false);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  };
  guardarTipoActividad = async (id, data) => {
    let { actualizarMensaje } = this.props;
    let form = await crear_form_data(data);
    formulario(
      `api/v1.0/internacionalizacion/solicitudes/${id}/agg_actividad`,
      form,
      "post",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            // callback();
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
            // callback(false);
          }
        }
        this.setState({ modal_add_act: false });
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  };

  handleStep = async (direccion) => {
    let {
      acreditacion_institucional,
      acreditacion_programa,
      activeStep,
      categoria_convenio,
      celular,
      check_acreditacion,
      correo_personal,
      dependencia,
      fuente_financiacion,
      idioma_minuta,
      institucion,
      modelo_convenio,
      modelo_convenio_renovacion,
      objeto_del_convenio,
      otra_financiacion,
      otro_idioma,
      otro_subtipo,
      programa_facultad,
      ranking,
      solicitud_excepcion,
      subtipo_convenio,
      tipo_convenio,
      otro_tipo_convenio,
      otro_subtipo_convenio,
      otra_fuente_financiacion,
      otro_idioma_minuta,
      vigencia,
      institucion_vinculante,
      paises_select,
      rector,
      direccion_vinculante,
      direccion_Web,
      encargado_interna,
      correo_interna,
      representacional_legal,
      buscar_interna_encargado,
      ciudad,
      cargo_interna,
      telefono_interna,
      modalEncargadoInter,
      mostrarModalEncargadoInter,
      nombre_enlace_vin,
      unidad_enlace_vin,
      telefono_enlace_vin,
      cargo_enlace_vin,
      email_enlace_vin,
      nombre_enlace_Uni,
      unidad_enlace_Uni,
      telefono_enlace_Uni,
      email_enlace_Uni,
      cargo_enlace_Uni,
      Tipos_actividades_asignadas,
      acreditacion_internacional_inst,
      acreditacion_internacional_prog,
      check_opcion_renovacion,
      id_convenio,
      id_renovacion,
      id_solicitud,
      codigo_conve,
      fechaInicio_conv,
      fechaFin_conv,
      estado_convenio,
      check_opcion_suscripcion,
      gestorDependencia,
      dependencia_enlace_Uni
    } = this.state;
    let { opcion } = this.props;
    let renovacion = ''
    if (check_opcion_renovacion == false) {
      renovacion = 'No'
    } else {
      renovacion = 'Si'
      this.setState({renovacion: 'Si'})
    }
    let titulo = "";
    let fecha_inicio_con = fechaInicio_conv ? moment(fechaInicio_conv).format("YYYY-MM-DD") : null;
    let fecha_fin_con = fechaFin_conv ? moment(fechaFin_conv).format("YYYY-MM-DD") : null;
    if (direccion) {
      let errores = [];
      switch (activeStep) {
        case 0:

          if (renovacion == 'Si') {
            errores = await validateInputs([
              { value: id_renovacion.id, name: "id_renovacion", err: { not_null: true } },
            ]);
          }

          if (!check_opcion_renovacion && !check_opcion_suscripcion) {
            /* VALIDAR QUE SE ESCOJA UNA OPCIÓN */
            titulo = 'Debe seleccionar una opción para continuar.'
          } else if (errores.length != 0 && check_opcion_renovacion) {
            /* VALIDA QUE SE SELECCIONE UN CONVENIO */
            titulo = 'Seleccione el convenio a renovar.'
          } else if (this.state.id_solicitud == 0) {
            console.log('renovacion - 1');
            if (renovacion == 'Si') {
              this.state.id_solicitud = this.state.id_renovacion.id
              console.log('renovacion - 2');
            }
            console.log('cero');
            let id = this.state.id_solicitud
            this.crearBorradorSolicitud({
              id,
              activeStep,
              tipo_solicitud: TIPOS_SOLICITUD_CONVENIOS,
              renovacion,
            });
            this.setState({ renovacion: renovacion })
          } else {
            console.log('renovacion - 3');
            if (renovacion == 'Si' && errores.length == 0) {
              this.state.id_solicitud = this.state.id_renovacion.id
              console.log('renovacion - 4');
            }
            this.ModificarBorradorSolicitud(this.state.id_solicitud, {
              // dependencia,
              activeStep,
              renovacion
            });
            this.setState({ renovacion: renovacion })
          }
          
          if (titulo) {
            this.props.actualizarMensaje({
              titulo,
              tipo: "info",
              mostrar: true,
              tiempo: 3000,
            });
          }
          break;
        case 1:
          errores = await validateInputs([
            { value: dependencia, name: "dependencia", err: { empty: true } },
            { value: gestorDependencia.id, name: "gestorDependencia", err: { empty: true } },
            {
              value: celular,
              name: "celular",
              err: { empty: true, not_decimal: true, numeric: true },
            },
            {
              value: correo_personal,
              name: "correo_personal",
              err: { empty: true },
            },
          ]);
          if (errores.length == 0) {
            /* if (this.state.id_solicitud == 0) {
              this.crearBorradorSolicitud({
                dependencia,
                activeStep,
                tipo_solicitud: TIPOS_SOLICITUD_CONVENIOS,
              });
            } else { */
            this.ModificarBorradorSolicitud(this.state.id_solicitud, {
              dependencia,
              activeStep,
              gestorDependencia: gestorDependencia.id !== 0 ? gestorDependencia.id : '',
            });

          }
          break;
        case 2:
          errores = await validateInputs([
            {
              value: institucion_vinculante,
              name: "institucion_vinculante",
              err: { empty: true },
            },
            { value: rector, name: "rector", err: { empty: true } },
            {
              value: direccion_vinculante,
              name: "direccion_vinculante",
              err: { empty: true },
            },
            {
              value: direccion_Web,
              name: "direccion_Web",
              err: { empty: true },
            },
            {
              value: encargado_interna,
              name: "encargado_interna",
              err: { empty: true },
            },
            {
              value: representacional_legal,
              name: "representacional_legal",
              err: { empty: true },
            },
            { value: ciudad, name: "ciudad", err: { empty: true } },
            {
              value: cargo_interna,
              name: "cargo_interna",
              err: { empty: true },
            },
            {
              value: paises_select,
              name: "paises_select",
              err: { empty: true },
            },
            {
              value: telefono_interna,
              name: "telefono_interna",
              err: { empty: true },
            },
            {
              value: correo_interna,
              name: "correo_interna",
              err: { empty: true },
            },
          ]);
          if (renovacion == 'Si' && this.props.opcion !== 'editar') {
            let convenio2 = await this.obtenerConvenio(this.state.id_renovacion.id)
            let id_solicitud = convenio2[0].solicitud.id
            this.setState({ id_convenio: convenio2[0].id, id_solicitud: convenio2[0].solicitud.id })
            let adjunto = await this.obtenerAdjuntos(id_solicitud)
            this.setState({ modelo_convenio: adjunto[0] ? adjunto[0].nombre_archivo : "" })

          }
          else if (renovacion == 'No' && this.props.opcion !== 'editar') {
            let convenio2 = await this.obtenerBorradorconvenio()
            let id_solicitud = convenio2[0].solicitud.id
            this.setState({ id_convenio: convenio2[0].id, id_solicitud: convenio2[0].solicitud.id })
            let adjunto = await this.obtenerAdjuntos(id_solicitud)
            this.setState({ modelo_convenio: adjunto[0] ? adjunto[0].nombre_archivo : "" })

          } else if (this.props.opcion == 'editar') {
            let convenio2 = await this.obtenerConvenio(this.props.convenio_adm.solicitud.id)
            let id_solicitud = convenio2[0].solicitud.id
            this.setState({ id_convenio: convenio2[0].id, id_solicitud: convenio2[0].solicitud.id })
            let adjunto = await this.obtenerAdjuntos(id_solicitud)
            this.setState({ modelo_convenio: adjunto[0] ? adjunto[0].nombre_archivo : "" })
          }
          if (!paises_select)
            errores.push({
              llave: "paises_select",
              mensaje: `Este campo es obligatorio`,
            });
          // if(!encargado_interna) errores.push({ llave: 'encargado_interna', 'mensaje': `Este campo es obligatorio` })
          if (errores.length == 0)
            this.ModificarBorradorSolicitud(this.state.id_solicitud, {
              activeStep,
              institucion_vinculante,
              rector,
              direccion_vinculante,
              direccion_Web,
              encargado_interna,
              representacional_legal,
              ciudad,
              cargo_interna,
              paises_select,
              telefono_interna,
              correo_interna,
            });
          break;
        case 3:
          errores = await validateInputs([
            // { value: institucion, name: 'institucion', err: { empty: true } },
            {
              value: tipo_convenio,
              name: "tipo_convenio",
              err: { empty: true },
            },
            {
              value: subtipo_convenio,
              name: "subtipo_convenio",
              err: { empty: true },
            },
            // { value: categoria_convenio, name: 'categoria_convenio', err: { empty: true } },
            {
              value: fuente_financiacion,
              name: "fuente_financiacion",
              err: { empty: true },
            },
            {
              value: idioma_minuta,
              name: "idioma_minuta",
              err: { empty: true },
            },
            {
              value: objeto_del_convenio,
              name: "objeto_del_convenio",
              err: { empty: true },
            },
            {
              value: vigencia,
              name: "vigencia",
              err: {
                empty: true,
                not_decimal: true,
                numeric: true,
                range: true,
              },
              value2: 15,
            },
          ]);
          if (tipo_convenio.includes(CODIGO_TIPO_CONVENIO_OTRO)) {
            if (!otro_tipo_convenio) {
              errores.push({
                llave: "otro_tipo_convenio",
                mensaje: `Este campo es obligatorio`,
              });
            }
          }
          if (subtipo_convenio.includes(CODIGO_SUBTIPO_CONVENIO_OTRO)) {
            if (!otro_subtipo_convenio) {
              errores.push({
                llave: "otro_subtipo_convenio",
                mensaje: `Este campo es obligatorio`,
              });
            }
          }
          if (fuente_financiacion.includes(CODIGO_TIPO_FUENTE_OTRO)) {
            if (!otra_fuente_financiacion) {
              errores.push({
                llave: "otra_fuente_financiacion",
                mensaje: `Este campo es obligatorio`,
              });
            }
          }
          if (idioma_minuta.includes(CODIGO_TIPO_IDIOMA_OTRO)) {
            if (!otro_idioma_minuta) {
              errores.push({
                llave: "otro_idioma_minuta",
                mensaje: `Este campo es obligatorio`,
              });
            }
          }

          // if (subtipo_convenio === 3200) errores.push({ llave: 'otro_subtipo', 'mensaje': `Este campo es obligatorio` });
          // if (fuente_financiacion.includes(2185)) errores.push({ llave: 'otra_financiacion', 'mensaje': `Este campo es obligatorio` });
          // if (idioma_minuta === 2180) errores.push({ llave: 'otro_idioma', 'mensaje': `Este campo es obligatorio` });
          let archivoSend = ''
          if (renovacion == 'Si') {
            archivoSend = modelo_convenio_renovacion.includes("fakepath") ? document.getElementById('modelo_convenio_renovacion').files[0] : '';
          } else {
            archivoSend = modelo_convenio.includes("fakepath") ? document.getElementById('modelo_convenio').files[0] : '';
          }

          // this.setState({ archivoSend });
          if (!tipo_convenio.includes(CODIGO_TIPO_CONVENIO_OTRO)) otro_tipo_convenio = ""
          if (!subtipo_convenio.includes(CODIGO_SUBTIPO_CONVENIO_OTRO)) otro_subtipo_convenio = ""
          if (!fuente_financiacion.includes(CODIGO_TIPO_FUENTE_OTRO)) otra_fuente_financiacion = ""
          if (!idioma_minuta.includes(CODIGO_TIPO_IDIOMA_OTRO)) otro_idioma_minuta = ""
          // let archivoSend = archivo
          // ? document.getElementById("archivo_generica").files[0]
          // : "";
          if (errores.length == 0) {
            this.ModificarBorradorSolicitud(this.state.id_solicitud, {
              activeStep,
              tipo_convenio,
              subtipo_convenio,
              fuente_financiacion,
              idioma_minuta,
              otro_tipo_convenio,
              otro_subtipo_convenio,
              otra_fuente_financiacion,
              otro_idioma_minuta,
              objeto_del_convenio,
              vigencia,
              modelo_convenio: renovacion == 'No' ? archivoSend : null,
              modelo_convenio_renovacion: renovacion == 'Si' ? archivoSend : archivoSend,
              acreditacion_internacional_inst,
              acreditacion_internacional_prog,
              codigo_conve,
              fecha_inicio_con,
              fecha_fin_con,
              estado_convenio,
              opcion,
            });
          }

          break;
        case 4:
          if (!check_acreditacion) {
            if (
              acreditacion_institucional.length === 0 &&
              acreditacion_programa.length === 0
            ) {
              if (ranking.length === 0) {
                titulo = "Seleccione un ranking o al menos una acreditacion";
              }
            }
            if (acreditacion_programa.length > 0) {
              if (!programa_facultad) titulo = "Digite al menos un programa"
            }
            // if(acreditacion_programa.length>0){
            //   if()
            // }

            //  if(acreditacion_institucional.length==0)errores.push({llave: 'acreditacion_institucional', 'mensaje': `Este campo es obligatorio` })
            //  if(ranking.length==0){errores.push({llave: 'acreditacion_institucional', 'mensaje': `Este campo es obligatorio` })
            //  this.props.actualizarMensaje({ titulo:'Seleccione al menos un ranking', tipo:'info', mostrar: true, tiempo: 6000 });
            // }
            // if(!programa_facultad)errores.push({llave: 'programa_facultad', 'mensaje': `Este campo es obligatorio` })
            // if(acreditacion_programa.length==0)errores.push({llave: 'acreditacion_programa', 'mensaje': `Este campo es obligatorio` })

            let help = [];
            ranking.map((item) => {
              if (item.posicion_una && item.posicion_dos) {
                help.push([item.id, item.posicion_una, item.posicion_dos]);
              } else {

                help.push([item.id, item.posicion]);
              }
            });
            help = JSON.stringify(help);
            // ranking=JSON.stringify(ranking)

            if (titulo) {
              this.props.actualizarMensaje({
                titulo,
                tipo: "info",
                mostrar: true,
                tiempo: 6000,
              });
            }

            if (errores.length == 0 && !titulo)
              this.ModificarBorradorSolicitud(this.state.id_solicitud, {
                activeStep,
                acreditacion_institucional,
                help,
                programa_facultad,
                acreditacion_programa,
                check: "true",
                acreditacion_internacional_inst,
                acreditacion_internacional_prog,
              });
          } else {
            errores = await validateInputs([
              {
                value: solicitud_excepcion,
                name: "solicitud_excepcion",
                err: { empty: true },
              }
            ])

            if (errores.length == 0)
              console.log('mdofi')
            this.ModificarBorradorSolicitud(this.state.id_solicitud, {
              activeStep,
              solicitud_excepcion,
              check: false,
            });
          }
          break;
        case 5:
          errores = await validateInputs([
            {
              value: dependencia_enlace_Uni,
              name: "dependencia_enlace_Uni",
              err: { empty: true },
            },
            {
              value: nombre_enlace_vin,
              name: "nombre_enlace_vin",
              err: { empty: true },
            },
            {
              value: unidad_enlace_vin,
              name: "unidad_enlace_vin",
              err: { empty: true },
            },
            {
              value: telefono_enlace_vin,
              name: "telefono_enlace_vin",
              err: { empty: true },
            },
            {
              value: cargo_enlace_vin,
              name: "cargo_enlace_vin",
              err: { empty: true },
            },
            {
              value: email_enlace_vin,
              name: "email_enlace_vin",
              err: { empty: true },
            },
            {
              value: nombre_enlace_Uni,
              name: "nombre_enlace_Uni",
              err: { empty: true },
            },
            {
              value: unidad_enlace_Uni,
              name: "unidad_enlace_Uni",
              err: { empty: true },
            },
            {
              value: telefono_enlace_Uni,
              name: "telefono_enlace_Uni",
              err: { empty: true },
            },
            {
              value: email_enlace_Uni,
              name: "email_enlace_Uni",
              err: { empty: true },
            },
            {
              value: cargo_enlace_Uni,
              name: "cargo_enlace_Uni",
              err: { empty: true },
            },
          ]);
          // if (Tipos_actividades_asignadas.length == 0) {
          //   titulo = "Agregue al menos una actividad";
          // }
          if (titulo) {
            this.props.actualizarMensaje({
              titulo,
              tipo: "info",
              mostrar: true,
              tiempo: 6000,
            });
          }
          if (errores.length == 0 && !titulo) {
            this.ModificarBorradorSolicitud(this.state.id_solicitud, {
              activeStep,
              nombre_enlace_vin,
              unidad_enlace_vin,
              telefono_enlace_vin,
              cargo_enlace_vin,
              email_enlace_vin,
              nombre_enlace_Uni,
              unidad_enlace_Uni,
              telefono_enlace_Uni,
              email_enlace_Uni,
              cargo_enlace_Uni,
              opcion,
              dependencia_enlace_Uni,
            });
          }
          break;
      }
      this.setState({ errores });
      console.log(activeStep)
      if (errores.length === 0 && !titulo) {
        activeStep < 5
          ? this.setState({ activeStep: activeStep + 1 })
          : console.log("nara");
      } else {
        return false;
      }
    } else {
      this.setState({ activeStep: activeStep - 1 });
    }
  };

  actualizarEstados(datos) {
    let {
      acreditaciones,
      categorias,
      dependencias,
      financiaciones,
      idiomas,
      instituciones,
      persona,
      tipos,
      subtipos,
      paises,
      back_tipoActividades,
      estados_convenio,
    } = datos;

    this.setState({
      nombre_completo:
        persona.primer_nombre +
        " " +
        persona.segundo_nombre +
        " " +
        persona.primer_apellido +
        " " +
        persona.segundo_apellido,
      nombre_enlace_Uni:
        persona.primer_nombre +
        " " +
        persona.segundo_nombre +
        " " +
        persona.primer_apellido +
        " " +
        persona.segundo_apellido,
      telefono_enlace_Uni: persona.telefono ? persona.telefono : "",
      email_enlace_Uni: persona.correo ? persona.correo : "",
      dependencia: persona.dependencia ? persona.dependencia : "",
      celular: persona.celular ? persona.celular : "",
      correo_personal: persona.correo_personal ? persona.correo_personal : "",
      acreditaciones,
      categorias,
      dependencias,
      financiaciones,
      idiomas,
      instituciones,
      tipos,
      subtipos,
      cargando: false,
      paises: paises ? paises : [],
      back_tipoActividades: back_tipoActividades ? back_tipoActividades : [],
      estados_convenio,
    });
  }

  checked = ({ target }) => {
    this.setState({
      [target.name]: target.checked,
      // acreditacion_institucional: [],
      // acreditacion_internacional_inst: "",
      // ranking: [],
      // programa_facultad: "",
      // acreditacion_programa: [],
      // acreditacion_internacional_prog: "",
      // solicitud_excepcion: "",
    });
  };

  cambiarencargado = (id, primer_nombre, primer_apellido, segundo_apellido) => {
    this.setState({
      encargado_interna: {
        id,
        nombre: `${primer_nombre} ${primer_apellido} ${segundo_apellido}`,
      },
    });
  };
  cambiarEstadoModal = (estado) => {
    this.setState({ modal_add_act: estado });
  };

  configStepContent = () => {
    let {
      acreditacion_institucional,
      acreditacion_internacional_inst,
      acreditacion_internacional_prog,
      acreditacion_programa,
      acreditaciones,
      activeStep,
      categoria_convenio,
      categorias,
      celular,
      check_acreditacion,
      correo_personal,
      dependencia,
      dependencias,
      errores,
      fileInput,
      financiaciones,
      fuente_financiacion,
      idioma_minuta,
      idiomas,
      institucion,
      instituciones,
      modal_buscar_ranking,
      modalBuscarUniversidad,
      modal_add_posicion,
      modelo_convenio,
      modelo_convenio_renovacion,
      nombre_completo,
      objeto_del_convenio,
      otra_financiacion,
      otro_idioma,
      otro_subtipo,
      programa_facultad,
      posicion,
      ranking,
      subtipo_convenio,
      subtipos,
      solicitud_excepcion,
      tipo_convenio,
      otro_tipo_convenio,
      otro_subtipo_convenio,
      otra_fuente_financiacion,
      otro_idioma_minuta,
      tipos,
      vigencia,
      institucion_vinculante,
      paises_select,
      rector,
      paises,
      direccion_vinculante,
      direccion_Web,
      encargado_interna,
      correo_interna,
      representacional_legal,
      buscar_interna_encargado,
      ciudad,
      cargo_interna,
      telefono_interna,
      cargos,
      modal_add_act,
      Tipos_actividades_asignadas,
      nombre_enlace_vin,
      unidad_enlace_vin,
      telefono_enlace_vin,
      cargo_enlace_vin,
      email_enlace_vin,
      nombre_enlace_Uni,
      unidad_enlace_Uni,
      telefono_enlace_Uni,
      email_enlace_Uni,
      cargo_enlace_Uni,
      check_rango_ranking,
      posicion_una,
      posicion_dos,
      check_opcion_suscripcion,
      check_opcion_renovacion,
      convenios_registrados,
      id_renovacion,
      renovacion,
      codigo_conve,
      fechaInicio_conv,
      fechaFin_conv,
      estados_convenio,
      estado_convenio,
      nombre_conv_ren,
      gestor,
      personas,
      gestorDependencia,
      dependencia_enlace_Uni
    } = this.state;
    let { modalEncargadoInter, mostrarModalEncargadoInter, opcion } = this.props;

    switch (activeStep) {
      case 0:
        return (
          <Principal
            {...{
              id_renovacion,
              convenios_registrados,
              check_opcion_suscripcion,
              check_opcion_renovacion,
              checked: this.checked,
              errores,
              onChange: this.onChange,
              cambiarEstado: (estados) => this.setState(estados),
              modalBuscarUniversidad,
              nombre_conv_ren,
            }}
          />
        );
      case 1:
        return (
          <InformacionDelSolicitante
            {...{
              nombre_completo,
              dependencias,
              dependencia,
              celular,
              correo_personal,
              errores,
              onChange: this.onChange,
              gestor,
              personas,
              gestorDependencia,
              onChangeDependencias: this.onChangeDependencias
            }}
          />
        );
      case 2:
        return (
          <Info_institucion_vinculante
            {...{
              institucion_vinculante,
              paises_select,
              rector,
              paises,
              direccion_vinculante,
              direccion_Web,
              encargado_interna,
              correo_interna,
              representacional_legal,
              buscar_interna_encargado,
              encargado_interna,
              ciudad,
              cargo_interna,
              telefono_interna,
              errores,
              onChange: this.onChange,
              modalEncargadoInter,
              mostrarModalEncargadoInter,
              cargos,
              cambiarencargado: this.cambiarencargado,
            }}
          />
        );
      case 3:
        return (
          <InformacionDelConvenio
            {...{
              instituciones,
              institucion,
              tipos,
              tipo_convenio,
              subtipo_convenio,
              otro_tipo_convenio,
              otro_subtipo_convenio,
              otra_fuente_financiacion,
              otro_idioma_minuta,
              subtipos,
              categorias,
              categoria_convenio,
              objeto_del_convenio,
              vigencia,
              idiomas,
              idioma_minuta,
              financiaciones,
              fuente_financiacion,
              modelo_convenio,
              modelo_convenio_renovacion,
              fileInput,
              otro_subtipo,
              otra_financiacion,
              otro_idioma,
              errores,
              onChange: this.onChange,
              renovacion,
              codigo_conve,
              opcion,
              fechaInicio_conv,
              fechaFin_conv,
              estados_convenio,
              estado_convenio,
            }}
          />
        );

      case 4:
        return (
          <>
            <CumplimientoDeCriterios
              {...{
                acreditaciones,
                acreditacion_institucional,
                ranking,
                modal_buscar_ranking,
                modal_add_posicion,
                programa_facultad,
                acreditacion_programa,
                solicitud_excepcion,
                acreditacion_internacional_inst,
                acreditacion_internacional_prog,
                check_acreditacion,
                errores,
                cambiarEstado: (estados) => this.setState(estados),
                onChange: this.onChange,
                checked: this.checked,
              }}
            />
            <AgregarPosicion
              open={modal_add_posicion}
              show={(modal_add_posicion) =>
                this.onCloseAddPosicion(modal_add_posicion)
              }
              posicion={posicion}
              onChange={this.onChange}
              guardarPosicion={this.onAddPosicion}
              guardarPosicionRango={this.onAddPosicionRango}
              errores={errores}
              check_rango_ranking={check_rango_ranking}
              checked={this.checked}
              posicion_una={posicion_una}
              posicion_dos={posicion_dos}
            />
          </>
        );
      case 5:
        console.log('hola')
        return (
          <>
            <PlanDeTrabajo
              {...{
                modal_add_act,
                Tipos_actividades_asignadas,
                cambiarEstado: this.cambiarEstadoModal,
                nombre_enlace_vin,
                unidad_enlace_vin,
                telefono_enlace_vin,
                cargo_enlace_vin,
                email_enlace_vin,
                errores,
                onChange: this.onChange,
                nombre_enlace_Uni,
                unidad_enlace_Uni,
                telefono_enlace_Uni,
                email_enlace_Uni,
                cargo_enlace_Uni,
                nombre_completo,
                dependencia_enlace_Uni,
                dependencias
              }}
            />
          </>
        );
      default:
        return (
          <TareasTerminadas
            imagen={emma_w}
            mensaje="Esta bandeja está vacía"
            widthImg="10%"
            marginTop="10%"
          />
        );
    }
  };

  enviarDatosConvenios = () => {
    let {
      acreditacion_institucional,
      acreditacion_internacional_inst,
      acreditacion_internacional_prog,
      acreditacion_programa,
      archivoSend,
      categoria_convenio,
      celular,
      correo_personal,
      dependencia,
      fuente_financiacion,
      idioma_minuta,
      institucion,
      modelo_convenio,
      objeto_del_convenio,
      otra_financiacion,
      otro_idioma,
      otro_subtipo,
      programa_facultad,
      ranking,
      solicitud_excepcion,
      subtipo_convenio,
      tipo_convenio,
      vigencia,
      renovacion,
      dependencia_enlace_Uni
    } = this.state;
    this.setState({ cargando: true, tit_cargando: "Enviando.." });
    let data = {
      acreditacion_institucional,
      acreditacion_internacional_inst,
      acreditacion_internacional_prog,
      acreditacion_programa,
      categoria_convenio,
      celular,
      correo_personal,
      dependencia,
      fuente_financiacion,
      idioma_minuta,
      institucion,
      modelo_convenio: renovacion == 'No' ? archivoSend : null,
      modelo_convenio_renovacion: renovacion == 'Si' ? archivoSend : null,
      objeto_del_convenio,
      otra_financiacion,
      otro_idioma,
      // otro_subtipo_convenio: otro_subtipo,
      programa_facultad,
      solicitud_excepcion,
      subtipo_convenio,
      tipo_convenio,
      vigencia,
      total: ranking.length,
      opcion: this.props.opcion,
      dependencia_enlace_Uni
    };
    this.props.guardarConvenio(ranking, data, () => this.reiniciarEstados());
  };

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value,
    });
  };

  onChangeDependencias = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: {id: value},
    });
  };

  // Agregar posición al ranking seleccionado
  onAddPosicion = async (valor) => {
    let { ranking, posicion } = this.state;
    let helpvalidator = parseInt(ranking[ranking.length - 1].valora)
    let errores = await validateInputs([
      { value: parseInt(posicion), name: "posicion", err: { empty: true, range: true }, value2: helpvalidator },
    ]);
    this.setState({ errores });

    if (errores.length === 0) {
      let rankings = [...ranking];
      let ultimo_ranking = { ...ranking[ranking.length - 1] };
      ultimo_ranking.posicion = valor;
      rankings[ranking.length - 1] = ultimo_ranking;
      this.setState({
        ranking: rankings,
        posicion: "",
        modal_add_posicion: false,
      });
    } else return false;
  };

  onAddPosicionRango = async (valor1, valor2) => {
    let { ranking, posicion, posicion_una, posicion_dos, check_rango_ranking } = this.state;
    let { actualizarMensaje } = this.props;
    let helpvalidator = parseInt(ranking[ranking.length - 1].valora)
    let errores = await validateInputs([
      { value: parseInt(posicion_una), name: "posicion_una", err: { empty: true, range: true }, value2: helpvalidator },
      { value: parseInt(posicion_dos), name: "posicion_dos", err: { empty: true, range: true }, value2: helpvalidator },
    ]);
    this.setState({ errores });

    if (errores.length === 0) {
      if (valor1 < valor2) {
        let rankings = [...ranking];
        rankings.map((id) => {
          id['posicion_una'] = posicion_una
          id['posicion_dos'] = posicion_dos
        })

        let ultimo_ranking = { ...ranking[ranking.length - 1] };
        let valor = valor1 + '-' + valor2;
        ultimo_ranking.posicion = valor;
        ultimo_ranking.posicion_una = valor1;
        ultimo_ranking.posicion_dos = valor2;
        rankings[ranking.length - 1] = ultimo_ranking;
        this.setState({
          ranking: rankings,
          posicion: "",
          modal_add_posicion: false,
          posicion_dos: '',
          posicion_una: '',
          check_rango_ranking: false,
        });

      } else {
        errores.push({ llave: 'posicion_una', 'mensaje': `Error` });
        errores.push({ llave: 'posicion_dos', 'mensaje': `Error` });
        this.setState({ errores });
      }
    }
  };

  // Función para cuando se cierre el modal sin agregar una posición al ranking seleccionado
  onCloseAddPosicion = (modal) => {
    let { ranking } = this.state;
    ranking.splice(-1, 1); // Eliminar último ranking seleccionado de la lista de seleccionados si no se agrega posición
    this.setState({ modal_add_posicion: modal, posicion: "", ranking });
  };

  setFechaRealizacion = (fechaRealizacion) => {
    this.setState({
      fechaRealizacion,
    });
  };

  reiniciarEstados() {
    let { mostrarModalAddConvenio } = this.props;

    this.setState({
      // archivoSend: "",
      // categoria_convenio: "",
      // celular: "",
      // check_acreditacion: false,
      // correo_personal: "",
      // dependencia: "",
      // idioma_minuta: [],
      // institucion: "",
      // modelo_convenio: "",
      // nombre_completo: "",
      // objeto_del_convenio: "",
      // otra_financiacion: "",
      // otro_idioma: "",
      // otro_subtipo: "",
      // programa_facultad: "",
      // solicitud_excepcion: "",
      // subtipo_convenio: "",
      // tipo_convenio: [],
      // otro_tipo_convenio: "",
      // otro_subtipo_convenio: "",
      // otra_fuente_financiacion: "",
      // otro_idioma_minuta: "",
      // tit_cargando: "Cargando...",
      // vigencia: "",
      // activeStep: 0,
      // acreditacion_institucional: [],
      // acreditacion_internacional_inst: "",
      // acreditacion_programa: [],
      // acreditacion_internacional_prog: "",
      // fuente_financiacion: [],
      // ranking: [],
      // cargando: false,
      acreditacion_internacional_inst: "",
      acreditacion_internacional_prog: "",
      archivoSend: "",
      categoria_convenio: "",
      celular: "",
      correo_personal: "",
      dependencia: "",
      dependencia_enlace_Uni: "",
      idioma_minuta: [],
      institucion: "",
      nombre_completo: "",
      modelo_convenio: "",
      modelo_convenio_renovacion: "",
      objeto_del_convenio: "",
      otra_financiacion: "",
      otro_idioma: "",
      otro_subtipo: "",
      programa_facultad: "",
      posicion: "",
      solicitud_excepcion: "",
      subtipo_convenio: [],
      tipo_convenio: [],
      otro_tipo_convenio: "",
      otro_subtipo_convenio: "",
      otra_fuente_financiacion: "",
      otro_idioma_minuta: "",
      tit_cargando: "Cargando...",
      vigencia: "",
      activeStep: 0,
      acreditacion_institucional: [],
      acreditacion_programa: [],
      acreditaciones: [],
      categorias: [],
      dependencias: [],
      errores: [],
      financiaciones: [],
      fuente_financiacion: [],
      idiomas: [],
      instituciones: [],
      ranking: [],
      subtipos: [],
      tipos: [],
      // cargando: false,
      check_acreditacion: false,
      modal_add_posicion: false,
      modal_buscar_ranking: false,
      modalBuscarUniversidad: false,
      institucion_vinculante: "",
      paises_select: 0,
      rector: "",
      paises: [],
      direccion_vinculante: "",
      direccion_Web: "",
      encargado_interna: "",
      correo_interna: "",
      cargo_interna: "",
      telefono_interna: "",
      ciudad: "",
      representacional_legal: "",
      buscar_interna_encargado: false,
      id_solicitud: 0,
      modal_add_act: false,
      back_tipoActividades: [],
      select_tipoActividad: [],
      descripcion_tipoActividad: "",
      fechaRealizacion: null,
      presupuesto_unicosta: 0,
      presupuesto_vinculante: 0,
      Tipos_actividades_asignadas: [],
      id_convenio: 0,
      nombre_enlace_vin: "",
      unidad_enlace_vin: "",
      telefono_enlace_vin: "",
      cargo_enlace_vin: "",
      email_enlace_vin: "",
      nombre_enlace_Uni: "",
      unidad_enlace_Uni: "",
      telefono_enlace_Uni: "",
      email_enlace_Uni: "",
      cargo_enlace_Uni: "",
      check_opcion_renovacion: false,
      check_opcion_suscripcion: false,
    });

    mostrarModalAddConvenio(false);
  }
  guardarActividad = async () => {
    let {
      select_tipoActividad,
      descripcion_tipoActividad,
      fechaRealizacion,
      presupuesto_unicosta,
      presupuesto_vinculante,
      id_solicitud,
    } = this.state;
    let errores = await validateInputs([
      { value: select_tipoActividad, name: "select_tipoActividad", err: { empty: true } },
      { value: descripcion_tipoActividad, name: "descripcion_tipoActividad", err: { empty: true } },
    ]);
    if (fechaRealizacion === null) errores.push({ llave: "fechaRealizacion", mensaje: "Este campo es obligatorio" });
    if (presupuesto_unicosta == 0) errores.push({ llave: "presupuesto_unicosta", mensaje: "Este campo es obligatorio" });
    if (presupuesto_vinculante == 0)errores.push({ llave: "presupuesto_vinculante", mensaje: "Este campo es obligatorio" });
    if (errores.length == 0) { 
      fechaRealizacion = moment(fechaRealizacion).format("YYYY-MM-DD");
      this.guardarTipoActividad(id_solicitud, {
        select_tipoActividad,
        descripcion_tipoActividad,
        fechaRealizacion,
        presupuesto_unicosta,
        presupuesto_vinculante,
      });
    } else {
      this.setState({ erroresAct: errores });
    }
  };
  render() {
    let {
      modalAddConvenio,
      mostrarModalAddConvenio,
      errores,
      descripcion_tipoActividad,
      presupuesto_unicosta,
      presupuesto_vinculante,
      modalEdiConvenio, mostrarModalEdiConvenio,
    } = this.props;
    let {
      activeStep,
      cargando,
      tit_cargando,
      back_tipoActividades,
      select_tipoActividad,
      fechaRealizacion,
      convenios_registrados,
      check_opcion_renovacion,
      erroresAct
    } = this.state;
    let configStep_2 = []
    // if(this.props.opcion == 'editar'){
    //   configStep_2 = [...configStep]
    //   configStep_2.shift()
    //   // activeStep = [...activeStep]-1
    // }
    return (
      <>
        <Dialog
          open={this.state.modal_add_act}
          maxWidth="sm"
          fullWidth={true}
          onClose={() => this.setState({ modal_add_act: false })}
        >
          <AppBarModal
            titulo="Agregue una actividad"
            mostrarModal={() => this.setState({ modal_add_act: false })}
            titulo_accion=""
          />
          <DialogContent className="scroll">
            <Grid container spacing={3} style={{ marginBottom: "40px" }}>
              <Grid item xs={12}>
                <FormControl className="form-control" error = { getError('select_tipoActividad', erroresAct).length > 0 } required>
                  <InputLabel id="select_tipoActividad_label">
                    Tipos de actividad
                  </InputLabel>
                  <Select
                    labelId="select_tipoActividad_label"
                    id="select_tipoActividad"
                    name="select_tipoActividad"
                    value={select_tipoActividad}
                    fullWidth
                    onChange={this.onChange}
                  >
                    {back_tipoActividades.map((tipoActividad) => (
                      <MenuItem key={tipoActividad.id} value={tipoActividad.id}>
                        {tipoActividad.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{getError("select_tipoActividad", erroresAct)}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={descripcion_tipoActividad}
                  required
                  error = { getError('descripcion_tipoActividad', erroresAct).length > 0 }
                  helperText = { getError('descripcion_tipoActividad', erroresAct) }
                  multiline
                  rows={4}
                  id="descripcion_tipoActividad"
                  name="descripcion_tipoActividad"
                  label="Descripción "
                  fullWidth
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                  <Grid container>
                    <KeyboardDatePicker
                      required={true}
                      id="fechaRealizacion"
                      name="fechaRealizacion"
                      style={{ marginTop: 3, width: "100%" }}
                      // minDate={Date("YYYY-MM-dd")}
                      margin="normal"
                      label="Fecha de realización"
                      format="yyyy-MM-dd"
                      value={fechaRealizacion}
                      // onChange={this.setFechaRealizacion}
                      onChange={(e) =>
                        this.onChange({
                          target: {
                            value: e,
                            name: "fechaRealizacion",
                          },
                        })
                      }
                      invalidDateMessage="Formato Invalido"
                      error={getError('fechaRealizacion', erroresAct).length > 0}
                      helperText={getError('fechaRealizacion', erroresAct)}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  value={presupuesto_unicosta}
                  required
                  error = { getError('presupuesto_unicosta', erroresAct).length > 0 }
                  helperText = { getError('presupuesto_unicosta', erroresAct) }
                  type="number"
                  id="presupuesto_unicosta"
                  name="presupuesto_unicosta"
                  label="Presupuesto de UNICOSTA"
                  fullWidth
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={presupuesto_vinculante}
                  required
                  error = { getError('presupuesto_vinculante', erroresAct).length > 0 }
                  helperText = { getError('presupuesto_vinculante', erroresAct) }
                  type="number"
                  id="presupuesto_vinculante"
                  name="presupuesto_vinculante"
                  label="Presupuesto de institución vinculante"
                  fullWidth
                  onChange={this.onChange}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <BtnForm
              texto="Cerrar"
              callback={() => this.setState({ modal_add_act: false })}
            />
            <BtnForm
              texto="Guardar"
              callback={() => {
                this.guardarActividad();
              }}
            />
          </DialogActions>
        </Dialog>
        <Dialog
          fullWidth={true}
          maxWidth={"md"}
          open={modalAddConvenio}
          onClose={() => mostrarModalAddConvenio(false)}
        >
          <AppBarModal
            titulo={"¡ Nuevo Convenio !"}
            mostrarModal={() => mostrarModalAddConvenio(false)}
            titulo_accion="Cerrar"
            accion={() => mostrarModalAddConvenio(false)}
          />
          <DialogContent className="scroll">
            {!cargando ? (
              <Grid container>
                <Grid item xs={12} md={12}>
                  <Stepper activeStep={activeStep}>
                    {configStep.map((label) => (
                      <Step key={label}>
                        <StepLabel>
                          <p>{label}</p>
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Grid>
                <Grid item xs={12}>
                  {this.configStepContent()}
                </Grid>
              </Grid>
            ) : (
              <TareasTerminadas
                mensaje={tit_cargando}
                marginTop="7%"
                imagen={emma_w}
                widthImg="7%"
                cargando={true}
              />
            )}
          </DialogContent>
          <DialogActions>
            {
              activeStep == 0 && !convenios_registrados.length > 0 && check_opcion_renovacion ?
                (
                  <div>
                    <BtnForm
                      texto="SIGUIENTE"
                      callback={() => this.handleStep(true)}
                      type="submit"
                    />
                  </div>
                ) :
                activeStep == 0 ? (
                  <div>
                    <BtnForm
                      texto="SIGUIENTE"
                      callback={() => this.handleStep(true)}
                      type="submit"
                    />
                  </div>
                ) : activeStep == 5 && this.props.opcion !== 'editar' ? (
                  <div>
                    <BtnForm
                      texto="ATRAS"
                      callback={() => this.handleStep(false)}
                    />
                    <BtnForm
                      texto="FINALIZAR"
                      callback={() => this.handleStep(true)}
                      type="submit"
                    />
                  </div>
                ) : (
                  <div>
                    <BtnForm
                      texto="ATRAS"
                      callback={() => this.handleStep(false)}
                    />
                    <BtnForm
                      texto="SIGUIENTE"
                      callback={() => this.handleStep(true)}
                      type="submit"
                    />
                  </div>
                )}
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth={true}
          maxWidth={"md"}
          open={modalEdiConvenio}
          onClose={() => mostrarModalEdiConvenio(false)}
        >
          <AppBarModal
            titulo={"¡ Editar Convenio !"}
            mostrarModal={() => mostrarModalEdiConvenio(false)}
            titulo_accion="Cerrar"
            accion={() => mostrarModalEdiConvenio(false)}
          />

          <DialogContent className="scroll">
            {!cargando ? (
              <Grid container>
                <Grid item xs={12} md={12}>
                  <Stepper activeStep={activeStep}>
                    {configStep.map((label) => (
                      <Step key={label}>
                        <StepLabel>
                          <p>{label}</p>
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Grid>
                <Grid item xs={12}>
                  {this.configStepContent()}
                </Grid>
              </Grid>
            ) : (
              <TareasTerminadas
                mensaje={tit_cargando}
                marginTop="7%"
                imagen={emma_w}
                widthImg="7%"
                cargando={true}
                  
              />
            )}
          </DialogContent>
          <DialogActions>
            {
              activeStep == 0 && !convenios_registrados.length > 0 && check_opcion_renovacion ?
                (
                  <div>

                  </div>
                ) :

                activeStep == 0 ? (
                  <div>
                    <BtnForm
                      texto="SIGUIENTE"
                      callback={() => this.handleStep(true)}
                      type="submit"
                    />
                  </div>
                ) : activeStep == 5 && this.props.opcion == 'editar' ? (
                  <div>
                    <BtnForm
                      texto="ATRAS"
                      callback={() => this.handleStep(false)}
                    />
                    <BtnForm
                      texto="FINALIZAR"
                      callback={() => this.handleStep(true)}
                      type="submit"
                    />
                  </div>
                ) : activeStep == 1 && this.props.opcion == 'editar' ? (
                  <div>
                    <BtnForm
                      texto="SIGUIENTE"
                      callback={() => this.handleStep(true)}
                      type="submit"
                    />
                  </div>
                ) : (
                  <div>
                    <BtnForm
                      texto="ATRAS"
                      callback={() => this.handleStep(false)}
                    />
                    <BtnForm
                      texto="SIGUIENTE"
                      callback={() => this.handleStep(true)}
                      type="submit"
                    />
                  </div>
                )}
          </DialogActions>
        </Dialog>

      </>
    );
  }
}

//CARGO ESTADOS DEL MODULO
const mapStateToProps = (state) => {
  let { modalEncargadoInter } = state.redInternacionalizacion;
  return {
    modalEncargadoInter
  };
};

//CARGO FUNCIONES DEL MODULO
const mapDispatchToProps = {
  mostrarModalEncargadoInter,
  actualizarMensaje,
  // mostrarModalEdiConvenio,
};
ConveniosCrear.propTypes = {
  modalAddConvenio: PropTypes.bool.isRequired,
  modalEncargadoInter: PropTypes.bool.isRequired,
  modalEdiConvenio: PropTypes.bool.isRequired,
  mostrarModalEncargadoInter: PropTypes.func.isRequired,
  // mostrarModalEdiConvenio: PropTypes.func.isRequired,
};

// export default ConveniosCrear;
export default connect(mapStateToProps, mapDispatchToProps)(ConveniosCrear);