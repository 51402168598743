import PropTypes from 'prop-types';
import React from 'react';
import { obtenerValores, obtenerValoresFiltros } from "../../global/js/funciones";
import { DPTOS_ACADEMICOS, FACULTADES_DEPENDENCIAS, UNIDADES_ADMINISTRATIVAS } from '../internacionalizacion/administrar/helper';
import { BtnCancelar, BtnSeleccion } from "./BotonesAccion";
import ListarDatos from './ListarDatos';


class BuscarValorGenerica extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      cargando: true,
      cargar: true,
      dato_buscar: '',
      datos: [],
    }
  }

  async componentDidMount() {
    let { generica, consultoria, dependenciasInter,centro } = this.props;
    this.setState({ cargando: true });
    let datos = []
    if(consultoria){
      datos  = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': generica }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valora', 'valor': 'consultoria'}]);
    }else if(centro){
      datos  = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': generica }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valora', 'valor': 'centro_conciliacion'}]);
    }else if (dependenciasInter) {
      let dptos_academicos = await obtenerValoresFiltros([{llave: "generica", valor: DPTOS_ACADEMICOS }, { llave: "estado", valor: 1 }, {'llave': 'valorf', 'valor': 'dependencias'}]);
      let uds_administrativas = await obtenerValoresFiltros([{llave: "generica", valor: UNIDADES_ADMINISTRATIVAS},{ llave: "estado", valor: 1 }, {'llave': 'valorf', 'valor': 'dependencias'}]);
      let facultades = await obtenerValoresFiltros([{llave: "generica", valor: FACULTADES_DEPENDENCIAS},{ llave: "estado", valor: 1 }, {'llave': 'valorf', 'valor': 'dependencias'}]);
      let data = uds_administrativas.concat(dptos_academicos, facultades);
      datos = data.sort((a, b) => a.nombre.localeCompare(b.nombre));

    }
    else datos = await obtenerValores(generica)
    this.setState({ datos, cargando: false, cargar: false });
  }


  render() {
    let { datos, cargando } = this.state;
    let { callbackAdd, callbackDel, listaSeleccionados } = this.props;

    const actions = (rowData) => {
      const seleccionar = (data) => {
        callbackAdd(data);
      }

      const deseleccionar = (data) => {
        callbackDel(data);
      }

      let resp = (!listaSeleccionados.some(({ id }) => id == rowData.id)) ? <BtnSeleccion callback={() => seleccionar(rowData)} /> : <BtnCancelar callback={() => deseleccionar(rowData)} />;
      return resp
    }

    return (
      <div>
        <ListarDatos
          id="tbl_listar_valores_add"
          titulo={"Lista de Valores"}
          datos={datos}
          opciones={true}
          buscar={true}
          cargando={cargando}
          mensajeVacio={'Esta bandeja esta vacía.'}
          acciones={(row) => actions(row)}
          fila_principal={({ nombre }) => nombre.toUpperCase()}
          filas={[
          ]}

        />
      </div>
    );
  }

}

// Props del componente
BuscarValorGenerica.propTypes = {
  callbackAdd: PropTypes.func.isRequired,
  callbackDel: PropTypes.func.isRequired,
  listaSeleccionados: PropTypes.array.isRequired,
  generica: PropTypes.number.isRequired
}


export default BuscarValorGenerica;


