import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
// ACCIONES MODULO
import { consulta } from "../../global/js/funciones";
import { mostrarModulo, actualizarMensaje } from "../../redux/actions/actGlobal";
import { mostrarModalVotarCandidatos, actualizarDataEleccion, actualizarDataElecciones } from "../../redux/actions/actElecciones";
// COMPONENTES
import CandidatosVotar from "./candidatos/CandidatosVotar";
import TareasTerminadas from "../general/TareasTerminadas";
import { BtnDetalle, BtnRegresar } from "../general/BotonesAccion";
import ListarDatos from "../general/ListarDatos"
// IMAGENES
import emma_w from '../../global/imagenes/emma_w.png';


class EleccionesVotar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      renderModulo: false,
      seleccion: "elecciones"
    }
  }

  async componentDidMount() {
    let { mostrarModulo, } = this.props;
    mostrarModulo({ ruta: '/mis_votaciones', nombre: 'VOTACIONES' })
    this.actualizar();
  }

  obtenerElecciones = () => {
    return new Promise(resolve => {
      consulta(`api/v1.0/votaciones`, null, null,
        (error, estado, resp) => {
          resolve(resp)
        }
      );
    })
  }

  actualizar = async () => {
    let { actualizarDataElecciones } = this.props;
    let elecciones = await this.obtenerElecciones();
    actualizarDataElecciones(elecciones);
    this.setState({ renderModulo: true, seleccion: 'elecciones' })
  }

  pintar = () => {
    let { seleccion } = this.state
    let { renderModulo } = this.state;
    let { modalVotarCandidatos, mostrarModalVotarCandidatos, actualizarMensaje, eleccion, actualizarDataEleccion, elecciones } = this.props;
    return (
      <div>
        {renderModulo ?
          <>
            {seleccion == 'elecciones' && this.pintarEleciones()}
            {seleccion == 'candidatos' && <div className='regresar' ><BtnRegresar callback={() => this.setState({ seleccion: 'elecciones' })} /></div>}
            {seleccion == 'candidatos' && <CandidatosVotar actualizar={this.actualizar} modalVotarCandidatos={modalVotarCandidatos} mostrarModalVotarCandidatos={mostrarModalVotarCandidatos} id={eleccion.id} actualizarMensaje={actualizarMensaje} />}
          </> :
          <TareasTerminadas mensaje='Cargando..' cargando={true} marginTop='7%' imagen={emma_w} widthImg="7%" />
        }
      </div>
    )
  }

  pintarEleciones = () => {
    let { eleccion, elecciones, mostrarModalVotarCandidatos, actualizarDataEleccion } = this.props
    const abrir = (row) => {
      actualizarDataEleccion(row);
      mostrarModalVotarCandidatos(true)
      this.setState({ seleccion: 'candidatos' })
    }

    return (
      <ListarDatos
        datos={elecciones}
        titulo={'Lista de elecciones'}
        id='tbl_elecciones_votar'
        acciones={(row) => <BtnDetalle callback={() => abrir(row)} texto='Abrir' />}
        avatar={({ nombre }) => nombre.toUpperCase().charAt(0)}
        color_avatar={{ 'backgroundColor': '#009688', 'color': 'white' }}
        mensajeVacio='Hola, no tienes votaciones pendientes'
        fila_principal={({ nombre }) => nombre.toUpperCase()}
        filas={[
          { 'mostrar': ({ fecha_fin }) => `Disponible Hasta : ${fecha_fin} ` }
        ]}
      />
    );
  }

  render() {
    return (
      <div>
        {this.pintar()}
      </div>
    )
  }
}


//CARGO ESTADOS DEL MODULO
const mapStateToProps = (state) => {
  let { modalVotarCandidatos, eleccion, elecciones } = state.redElecciones;
  return {
    modalVotarCandidatos,
    eleccion,
    elecciones
  }
};

//CARGO FUNCIONES DEL MODULO
const mapDispatchToProps = {
  mostrarModulo,
  mostrarModalVotarCandidatos,
  actualizarMensaje,
  actualizarDataEleccion,
  actualizarDataElecciones,
}

//Props del componente
EleccionesVotar.propTypes = {
  eleccion: PropTypes.shape({
    id: PropTypes.number.isRequired,
    nombre: PropTypes.string.isRequired,
    fecha_inicio: PropTypes.string.isRequired,
    fecha_fin: PropTypes.string.isRequired,
  }),
  elecciones: PropTypes.array.isRequired,
  modalVotarCandidatos: PropTypes.bool.isRequired,
  mostrarModulo: PropTypes.func.isRequired,
  mostrarModalVotarCandidatos: PropTypes.func.isRequired,
  actualizarMensaje: PropTypes.func.isRequired,
  actualizarDataEleccion: PropTypes.func.isRequired,
  actualizarDataElecciones: PropTypes.func.isRequired,
}


export default connect(mapStateToProps, mapDispatchToProps)(EleccionesVotar);

