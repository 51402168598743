
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { ValidatorForm } from "react-material-ui-form-validator";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import AppBarModal from './AppBarModal'
import { BtnForm } from "./BotonesAccion";
import InputFile from "./InputFile";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';

class EnviarArchivo extends Component {

  constructor(props) {
    super(props)
    this.state = {
      adj_archivo: '',
    }
    this.fileInput = React.createRef();
  }

  componentDidUpdate({ modalAdd }) {
    let { modalAdd: modalAddNew } = this.props;
    if (modalAddNew !== modalAdd) {
      this.setState({ adj_archivo: '' });
    }
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }


  onSubmit = e => {
    let { id } = this.props;
    let archivo = document.getElementById(id).files[0];
    console.log(archivo)
    this.props.enviarArchivo(archivo);
    e.preventDefault();
  }

  render() {
    let { modalAdd, mostrarModalAdd, titulo, id, name, label, formatos, mensaje } = this.props;
    let { adj_archivo } = this.state;
    let textos = []
    if(formatos){
      textos.push(<Typography variant="body2" component="p">Formatos de archivo validos : {formatos}</Typography>)
    }
    if(mensaje){
      textos.push(<Typography variant="body2" component="p">{mensaje}</Typography>)
    }
    return (
      <div>
        <Dialog open={modalAdd} fullWidth={true} maxWidth="xs" onClose={mostrarModalAdd.bind(this, false)} aria-labelledby="form-dialog-title">
          <AppBarModal titulo={titulo} mostrarModal={mostrarModalAdd} titulo_accion="" accion="" />
          <DialogContent className='scroll'>
            {(formatos || mensaje) &&
              <Grid container >
                <Grid item xs={12} md={12} className={`alert_info_titulo`}>
                  <Button variant="text" size="small" color="inherit" >
                    <NotificationImportantIcon style={{ height: 15 }} /> Nota</Button>
                </Grid>
                <Grid item xs={12} md={12} className={`alert_info`}>
                  {textos}
                </Grid>
              </Grid>
            }
            <ValidatorForm onSubmit={this.onSubmit} style={{marginTop: 15}}>
              <Grid item xs={12}>
                <InputFile label='Adjuntar Archivo' id='adj_archivo' value={adj_archivo} />
              </Grid>
              <Grid item xs={12} className='oculto'>
                <TextField
                  ref={this.fileInput}
                  type="file"
                  id={id}
                  name={name}
                  label={label}
                  fullWidth
                  autoComplete="billing address-line1"
                  onChange={this.onChange}
                  inputProps={
                    {
                      accept: formatos ? formatos : '*'
                    }
                  }
                />

              </Grid>
              <Button color="primary" type="submit" id='enviar_form_add_archivo' className="oculto"> Guardar </Button>
            </ValidatorForm>
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => mostrarModalAdd(false)} />
            <BtnForm texto="ENVIAR" callback={() => { document.getElementById('enviar_form_add_archivo').click() }} />
          </DialogActions>
        </Dialog>

      </div>
    );
  }
}

EnviarArchivo.propTypes = {
  //variables
  modalAdd: PropTypes.bool.isRequired,
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  titulo: PropTypes.string.isRequired,
  formatos: PropTypes.string,
  //funciones
  enviarArchivo: PropTypes.func.isRequired,
  mostrarModalAdd: PropTypes.func.isRequired,
}

EnviarArchivo.defaultProps = {
  id: 'adj_archivo',
  name: 'adj_archivo',
  label: 'Adjuntar Archivo',
  formatos: '',
  mensaje: ''
};

export default EnviarArchivo;
