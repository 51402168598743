import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardHeader, CardContent, Avatar, Grid, Typography, Tooltip, IconButton } from '@material-ui/core/';
import BlockIcon from '@material-ui/icons/Block';
import { red } from '@material-ui/core/colors';
import education from '../../../global/imagenes/education.png';
import libroAbierto from '../../../global/imagenes/libro-abierto.png'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: "100%",
    minHeight: "50px",
    height: "320px",
  },
  image: {
    width: '100px',
    maxWidth: '100px',
    minWidth: '100px',
    height: "100px",
  },
  content_text: {
    padding: "0px 0px 0px 10px",
  },
  content_img: {
    textAlign: 'center',
    height: "150px",
  },
  hover_delete_icon: {
		'&:hover': {
			color: red[800]
		}
	}
}));

export default function SemilleroCardSolicitud({ fecha, descripcion, estado, tipo, id, handleClickDelete }) {
  let fecha_r = moment(fecha).format('YYYY-MM-DD, h:mm a');
  let habilitar_btn = estado.codigo === 'Est_bec_env'
  const classes = useStyles();

  const obtenerColor = (codigo) => {
    let color = 'white';
    const colores = {
        'Semi_env': { 'backgroundColor': '#68FF4A', 'color': '#000000' },
        'Est_bec_rev': { 'backgroundColor': '#ffc107', color },
        'Est_bec_apr': { 'backgroundColor': '#01519B', color },
        'Est_bec_apl': { 'backgroundColor': '#009688', color },
        'Est_bec_neg': { 'backgroundColor': '#b71c1c', color },
        'Est_bec_can': { 'backgroundColor': '#b71c1c', color }
    }
    return (colores[codigo])
  }

  
  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={<Avatar aria-label="recipe" style={{ backgroundColor: `${estado.valorc}` }}> {estado.nombre.charAt(0).toUpperCase()} </Avatar>}
        title={tipo.nombre}
        subheader={fecha_r}
        action={habilitar_btn && 
          <Tooltip title='Cancelar Solicitud'>
            <IconButton className={classes.hover_delete_icon} aria-label="cancel"
              onClick={(e) => handleClickDelete(e, id)}>
              <BlockIcon />
            </IconButton>
          </Tooltip>
        }
      />
      <CardContent>
        <Grid container direction={'row'} justify={'center'}>
          <Grid item xs={12} sm={12} md={12} className={classes.content_img}>
            <img src={libroAbierto} className={classes.image} alt='Imagen Tipo' />
            <Typography variant="body2" color="textSecondary" component="p" align='justify'>
              {descripcion}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
