import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {BtnForm} from '../../../general/BotonesAccion';
import { ID_JF } from '../helper';
import { Dialog, DialogContent, DialogActions } from '@material-ui/core';
import AppBarModal from '../../../general/AppBarModal';
import { DetalleInstituciones } from '../../DetalleSolicitud';
import { consulta } from '../../../../global/js/funciones';
import TareasTerminadas from '../../../general/TareasTerminadas';
import emma_w from '../../../../global/imagenes/emma_w.png';

export class InstitucionesDetalle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cargando: true,
      tipo_paso_infoconveni: [],
      pasocumplimientorequisito: [],
    };
  }

  async componentDidMount() {
    let { institucionActual } = this.props
    let tipo_paso_infoconveni = [], pasocumplimientorequisito = []
    this.setState({ cargando: true })
    tipo_paso_infoconveni = await this.obtenerBorradorInfConvenio(institucionActual.id)
    pasocumplimientorequisito = await this.obtenerBorradorCumplimiento(institucionActual.id)
    this.setState({ tipo_paso_infoconveni, pasocumplimientorequisito, cargando: false })
  }
  
  async componentDidUpdate({modalDetInstituciones }) {
    let { modalDetInstituciones: modalDetInstitucionesNew, institucionActual } = this.props
    if (modalDetInstituciones !== modalDetInstitucionesNew) {
      if (modalDetInstitucionesNew) {
        let tipo_paso_infoconveni = [], pasocumplimientorequisito = []
        this.setState({ cargando: true })
        tipo_paso_infoconveni = await this.obtenerBorradorInfConvenio(institucionActual.id)
        pasocumplimientorequisito = await this.obtenerBorradorCumplimiento(institucionActual.id)
        this.setState({ tipo_paso_infoconveni, pasocumplimientorequisito, cargando: false })
      }
    }
  }

  
  async obtenerBorradorInfConvenio(id_convenio) {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/internacionalizacion/solicitudes/${id_convenio}/obtener_pasoInfConvenio`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  async obtenerBorradorCumplimiento(id_convenio) {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/internacionalizacion/solicitudes/${id_convenio}/obtener_pasoCumplimiento`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  pintarDetalle = () => {
    let { institucionActual } = this.props
    let {tipo_paso_infoconveni, pasocumplimientorequisito, cargando} = this.state
    if (cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />;
    if (institucionActual && !cargando) {
      return <DetalleInstituciones datos={institucionActual} tipo_paso_infoconveni={tipo_paso_infoconveni } pasocumplimientorequisito={pasocumplimientorequisito} />
    }
  }

  render() {
    let { mostrarModalDetInstituciones, modalDetInstituciones } = this.props
    return (
      <>
        <Dialog open={modalDetInstituciones} maxWidth='md' fullWidth={true} onClose={() => mostrarModalDetInstituciones(false)}>
          <AppBarModal titulo='¡ Detalle de la institución !' mostrarModal={mostrarModalDetInstituciones} titulo_accion='' />
          <DialogContent style={{ padding: 0 }} className='scroll' >
            {this.pintarDetalle()}
          </DialogContent>
          <DialogActions>
            <BtnForm texto="Cerrar" callback={() => mostrarModalDetInstituciones(false)} />
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

// Props del componente
InstitucionesDetalle.propTypes = {
  //variables
  // instituciones: PropTypes.array.isRequired,
  modalDetInstituciones: PropTypes.bool.isRequired,
  institucionActual: PropTypes.array.isRequired,
  //funciones
  // actualizarDataValor: PropTypes.func.isRequired,
  mostrarModalDetInstituciones: PropTypes.func.isRequired,
  usuario: PropTypes.object.isRequired,
};