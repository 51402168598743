import React, { Component } from 'react';
import { Typography, Grid, Fab, Toolbar, AppBar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import { api } from '../../../global/js/funciones';

//css
import '../../../global/css/stylePortal.css'
import emma_s1 from '../../../global/imagenes/emma_s1.png';
import TareasTerminadas from "../../general/TareasTerminadas";


//Style
const useStyles = makeStyles((theme) => ({
    // appBar: {
    //     top: 'auto',
    //     bottom: 0,
    //     backgroundColor: '#f5f5f5',
    //     zIndex: 1,
    //     border: '1px solid #eeeeee ',
    // },
    fabButton: {
        position: 'fixed',
        // bottom: "70px",
        left: "45%",
        zIndex: 1000,
        top: '80%',
        [theme.breakpoints.up('sm')]: {
            top: '85%',
            left: "50%",
        },
    },
}));

function BtnActualizar({ abrirOpcionesPerfil }) {
    let classes = useStyles();
    return (
        // <AppBar position="absolute" className={classes.appBar} elevation={0} id='appBar_listar_datos'>
        // <Toolbar>
        <Fab color="secondary" aria-label="add" className={classes.fabButton} onClick={() => abrirOpcionesPerfil()}>
            <EditIcon />
        </Fab>
        // </Toolbar>
        // </AppBar> 
    )
}

class DetalleEmpresa extends Component {
    render() {
        let { abrirOpcionesPerfil, getDatosPrincipales, getResponsable } = this.props
        // let { nombre, direccion, ciudad, nit, web_side } = getDatosPrincipales[0]
        // let { apellido, cargo, telefono, email } = getResponsable[0]
        return (
            <Grid container spacing={2} style={{ padding: "20px 40px 160px" }}>
                {getDatosPrincipales.length == 0 && getResponsable.length == 0 ?
                    <TareasTerminadas mensaje='Aún no ha diligenciado la información de su empresa.' marginTop='3%' imagen={emma_s1} widthImg="8%" /> :
                    <>
                        <Grid item xs={12} md={12} className="informacion_principal">
                            {
                                (getDatosPrincipales.length > 0 && getDatosPrincipales[0]) ?
                                    <>
                                        <img src={api + getDatosPrincipales[0].archivo} className="imagen_perfil"></img>
                                        <br></br>
                                        <Grid>
                                            <Typography variant="h6">
                                                {getDatosPrincipales[0].nombre}
                                            </Typography>
                                            <Typography variant="h6">
                                                {`${getDatosPrincipales[0].direccion} - ${getDatosPrincipales[0].ciudad}`}
                                            </Typography>
                                            <Typography className="telefono_and_email">
                                                {`NIT: ${getDatosPrincipales[0].nit}`}
                                            </Typography>
                                            <Typography className="telefono_and_email">
                                                {getDatosPrincipales[0].web_side}
                                            </Typography>
                                        </Grid>
                                    </>
                                    : <></>
                            }
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Grid className="informacion_principal">
                                {
                                    (getResponsable[0]) ?
                                        <>
                                            <Typography className="ttitulos">
                                                Responsable
                                            </Typography>
                                            <br></br>
                                            <Typography variant="h4">
                                                {`${getResponsable[0].nombre} ${getResponsable[0].apellido}`}
                                            </Typography>
                                            <Typography variant="h4">
                                                {getResponsable[0].cargo}
                                            </Typography>
                                            <Typography variant="h4" className="">
                                                {getResponsable[0].telefono}
                                            </Typography>
                                            <Typography variant="h4" className="">
                                                {getResponsable[0].email}
                                            </Typography>
                                        </>
                                        : <></>
                                }
                            </Grid>
                        </Grid>
                    </>
                }
                <BtnActualizar
                    abrirOpcionesPerfil={abrirOpcionesPerfil}
                />
            </Grid>
        );
    }
}

export default DetalleEmpresa;