import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DialogActions, Grid, Dialog, DialogContent, ListSubheader, List, ListItem, ListItemIcon, ListItemText, Checkbox, TextField, MenuItem } from '@material-ui/core';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { BtnForm } from "../../general/BotonesAccion";
import AppBarModal from '../../general/AppBarModal';
import { obtenerEstadosProceso, obtenerValoresFiltros } from "../../../global/js/funciones";
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from '../../../global/imagenes/emma_w.png';

import { ESTADOS_VACANTE_PORTAL, ESTADOS_CIERRE_VACANTE_PORTAL } from '../helper';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		backgroundColor: theme.palette.background.paper,
	},
}));

function RenderEstados({ estados_siguiente, estado, cambiarSeleccion }) {
	const classes = useStyles();
	return (
		<List className={classes.root} subheader={
			<ListSubheader component="div" >
				Seleccione Estado
			</ListSubheader>
		}>
			{estados_siguiente.map(({ secundario }) => {
				let { codigo, nombre } = secundario;
				return (
					<ListItem key={codigo} role={undefined} dense button onClick={() => cambiarSeleccion(codigo)} >
						<ListItemIcon>
							<Checkbox edge="start" checked={estado === codigo} tabIndex={-1} disableRipple />
						</ListItemIcon>
						<ListItemText id={codigo} primary={nombre} />
					</ListItem>
				);
			})}
		</List>
	);
}

class GestionOferta extends Component {
	constructor(props) {
		super(props)
		this.state = {
			cargando: true,
			titulo_res: 'Cargando..',
			estado: '',
			motivo_cierre: '',
			estados_siguiente: [],
			seleccion: 'gestion',
			mensaje: {
				value: ''
			},
			motivos_cierre: []
		}
	}

	// obtenerEstadosSiguientes = async () => {
	// 	// let { estado_solicitud } = this.props.oferta
	// 	// let { id } = estado_solicitud;
	// 	let estados_siguiente = await obtenerEstadosProceso(id, [{ 'llave': 'principal', 'valor': estado_solicitud.id }, { 'llave': 'secundario__generica', 'valor': ESTADOS_VACANTE_PORTAL }]);
	// 	this.setState({ estado: '', estados_siguiente, cargando: false, mensaje: { value: '' } })
	// }

	// componentDidMount() {
	// 	this.obtenerEstadosSiguientes();
	// 	obtenerValores(ESTADOS_CIERRE_VACANTE_PORTAL).then((resp) => {
	// 		this.setState({
	// 			motivos_cierre: resp
	// 		})
	// 	})
	// }

	// componentDidUpdate(prevProps) {
	// 	let { estado_solicitud } = prevProps.oferta;
	// 	if (estado_solicitud !== this.props.oferta.estado_solicitud) {
	// 		this.obtenerEstadosSiguientes();
	// 	}
	// }

	async componentDidUpdate({ modalGestionOferta }, { estado }) {
    let { estado: estadoNew } = this.state;
		let { modalGestionOferta: modalGestionOfertaNew, oferta: { estado_solicitud } } = this.props;
		let { id } = estado_solicitud

		if (estadoNew !== estado && estadoNew === 'Por_Fin') {
      this.setState({ cargando: true, titulo_res: 'Cargando..' });
      let motivos_cierre = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': ESTADOS_CIERRE_VACANTE_PORTAL }, { 'llave': 'estado', 'valor': 1 }]);
      this.setState({ motivos_cierre, motivo_cierre: '', cargando: false });
    }

		if (modalGestionOferta !== modalGestionOfertaNew && modalGestionOfertaNew) {
			this.reiniciarEstado();
			let estados_siguiente = await obtenerEstadosProceso(id, [{ 'llave': 'principal', 'valor': estado_solicitud.id }, { 'llave': 'secundario__generica', 'valor': ESTADOS_VACANTE_PORTAL }]);
			this.setState({ estado: '', estados_siguiente, cargando: false, mensaje: { value: '' } })
		}
	}

	reiniciarEstado() {
		this.setState({
			cargando: true,
			titulo_res: "Cargando..",
			estados_siguiente: [],
			seleccion: "gestion",
			estado: '',
			mensaje: { value: '' },
			motivo_cierre: '',
			motivos_cierre: []
		});
	}

	onSubmit = e => {
		e.preventDefault();
		let { oferta } = this.props;
		let { estado, mensaje, motivo_cierre } = this.state;
		this.setState({ cargando: true, titulo_res: 'Enviando...' })
		let data = {
			estado,
			mensaje: mensaje.value,
			motivo_cierre,
		}
		this.props.gestionarOferta(oferta.id, data, () => this.setState({ cargando: false, titulo_res: 'Cargando..', mensaje: { value: '' } }));
	}

	onChange = ({ target }) => {
		let { value } = target
		let llave = 'mensaje'
		this.setState(prevState => ({
			[llave]: {
				...prevState[llave],
				value
			}
		})
		)
	}

	rendermensaje = () => {
		let { mensaje } = this.state
		return (
			<Grid>
				<ValidatorForm>
					<TextValidator
						margin="dense"
						id="inf_mensaje"
						label="Escriba el mensaje de la revision*"
						type="text"
						fullWidth
						value={mensaje.value}
						onChange={this.onChange}
						name="mensaje"
						validators={["required"]}
						errorMessages={["El campo es requerido"]}
					/>
				</ValidatorForm>
			</Grid>
		)
	}

	pintarEstados() {
		let { estado, estados_siguiente, motivos_cierre, motivo_cierre } = this.state;
		return (
			<Grid container >
				<Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
					<RenderEstados cambiarSeleccion={(estado) => this.setState({ estado })} estado={estado} estados_siguiente={estados_siguiente} />
					{
						estado === "Por_Rec" ?
							this.rendermensaje() : <></>
					}
					{
						estado === 'Por_Fin' && (
							<TextField
								fullWidth
								select
								label="Motivo de cierre"
								value={motivo_cierre}
								onChange={(event)=>{
									this.setState({
										motivo_cierre: event.target.value
									})
								}}
							>
								{
									motivos_cierre.map(m => (
										<MenuItem key={m.id} value={m.id}>
											{m.nombre}
										</MenuItem>
									))
								}
							</TextField>
						)
					}
				</Grid>
			</Grid >
		)
	}

	pintar() {
		let { seleccion } = this.state;
		return (<div>
			{seleccion === 'gestion' && this.pintarEstados()}
		</div>)
	}

	render() {
		let { modalGestionOferta, mostrarModalGestionOferta, oferta } = this.props;
		let { cargando, titulo_res } = this.state;
		return (
			<Dialog fullWidth={true} maxWidth="sm" open={modalGestionOferta} onClose={mostrarModalGestionOferta.bind(this, false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
				<AppBarModal titulo={'¿ Gestionar Oferta ?'} mostrarModal={mostrarModalGestionOferta} titulo_accion="" accion="" />
				<DialogContent style={{ padding: '1' }} className='scroll'>
					{cargando ? <TareasTerminadas mensaje={titulo_res} marginTop='7%' imagen={emma_w} widthImg="7%" /> : <div style={{ padding: "5px 0 5px 0" }}>{this.pintar()}</div>}
				</DialogContent>
				<DialogActions>
					<BtnForm texto="CANCELAR" callback={() => mostrarModalGestionOferta(false)} />
					{!cargando && <BtnForm texto="ACEPTAR" callback={this.onSubmit} />}
				</DialogActions>
			</Dialog>
		)
	}
}

export default GestionOferta;