
import { Button, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormLabel, Grid, Paper, Radio, RadioGroup, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from "react-redux";
import BarraProceso from "../general/BarraProceso";
import TareasTerminadas from "../general/TareasTerminadas";
import { PracticaDetalleSimple } from "./administrar/PracticaDetalle";
//FUNCIONES GENERALES
import { api, consulta, crear_form_data, formulario, generarFiltros, mostrarError, obtenerMisDatos, obtenerPermisosValorAlt, obtenerValores, obtenerValoresFiltros, validarNIT } from "../../global/js/funciones";
//ACCIONES DEL MODULO
import { Packer } from "docx";
import { saveAs } from "file-saver";
import moment from 'moment';
import book from '../../global/imagenes/bookInvestigacion.png';
import fecha from '../../global/imagenes/calendar.png';
import trabajarseguir from '../../global/imagenes/clipboardInvestigacion.png';
import education from '../../global/imagenes/education.png';
import emma_s from '../../global/imagenes/emma_s1.png';
import emma_w from '../../global/imagenes/emma_w.png';
import folder from '../../global/imagenes/folder.png';
import medalla from '../../global/imagenes/medalla.png';
import tap from '../../global/imagenes/noti4.png';
import obrero from '../../global/imagenes/obrero.png';
import revisar from '../../global/imagenes/revisar.png';
import study from '../../global/imagenes/study.png';
import { actualizarMensaje, mostrarModalAdjFaltantes, mostrarModulo } from "../../redux/actions/actGlobal";
import { actualizarDataAdjuntosPractica, actualizarDataEstado, actualizarDataEstados, actualizarDataPractica, actualizarDataPracticas, actualizarModalidadPractica, actualizarModalidadesParcticas, actualizarRenderPracticas, actualizarRequisitosModalidad, mostraModalModalidadesPracticas, mostrarModalAddAdjunto, mostrarModalAddEmpresaProceso, mostrarModalAddPracticas, mostrarModalAddValoresSucursal, mostrarModalAdmAjuntosPractica, mostrarModalCanPracticas, mostrarModalDelPracticas, mostrarModalDetallePractica, mostrarModalTestPracticas, mostrarPrueba } from "../../redux/actions/actPracticas";
import { mostrarModalAddValores } from "../../redux/actions/actValores";
import AccionesRapidas from '../general/AccionesRapidas';
import AppBarModal from '../general/AppBarModal';
import { BtnCargar, BtnDescargar, BtnEditar, BtnForm, BtnVer } from "../general/BotonesAccion";
import EnviarMensaje from "../general/EnviarMensaje";
import EnviarMultiplesArchivos from "../general/EnviarMultiplesArchivos";
import ListarDatos from '../general/ListarDatos';
import TituloAccion from "../general/TituloAccion";
import MenuEstudiantePracticas from "./MenuEstudiantePracticas";
import PracticaEmpresa from "./PracticaEmpresa";
import PracticasAdjuntos from "./PracticasAdjuntos";
import PracticasModalidades from './PracticasModalidades';
import PracticasPendientes from "./PracticasPendientes";
import PracticasTipos from './PracticasTipos';
import Practica from "./administrar/Practica";
import PracticasListar from "./administrar/PracticasListar";
import { GENEROS, MODALIDADES, TEMAS_PRACTICAS_CLINICAS, TIPOS_PRACTICAS } from './helper';
import { crearDocumento } from "./practicasCarta";


class Practicas extends Component {

  constructor(props) {
    super(props)
    this.state = {
      seleccion: 'proceso',
      linea_estados: [],
      adj_faltantes: [],
      notificaciones: [],
      cambio: false,
      telefono: '',
      celular: '',
      correo : '',
      lugar_expedicion: '',
      fecha_nacimiento: null,
      fecha_expedicion: null,
      programas: [],
      tiposPracticas: '',
      render: false,
      matricula: false,
      volante: false,
      conceptos: true,
      mensajeFaltantes: false,
      agrega: false,
      periodo: false,
      addEmpresa: false,
      datos_acuerdos: [],
      instructivo : [],
      cargandoPendientes: true,
      modPracticas: [],
      opcionesPC: [],
      imagenes: {
        obrero,
        medalla,
        education,
        book
      },
      preguntafinal:'',
      filtros_practica:[],
      seguimiento:[],
      inducciones:[],
      modalDetalleEst:false,
      estados: [],
      idestados:0,
      generos: []

    }
  }

  async componentDidMount() {
    let {preguntafinal,estados,idestados}=this.state
    let { mostrarModulo } = this.props;
    mostrarModulo({ ruta: '/mis_practicas', nombre: 'Mis practicas' })
    this.actualizarDataPractica(true);
    let instructivo = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Pra_Inst' }]);
    let datos = await this.obtenerDatosAcuerdos();
    let datos_persona = await obtenerMisDatos();
    this.setState({ datos_acuerdos: datos.acuerdos, instructivo })
    await this.obtenerModPracticas(datos_persona.programas[0]);
    if(this.props.practica){
   estados=await this.obtenerEstados(this.props.practica.id)
   this.setState({estados})
   if(estados){
    estados.map((item,index)=>{
      if(this.props.practica.estado_actual.id==item.tipo_estado.id){
        idestados=item.id
        console.log('entreeee')
      }

    })}
    this.setState({idestados})
  }
    if(this.props.practica){
      console.log(this.state)
      console.log(this.props)
      
    if(this.props.practica.empresa && this.props.practica.sucursal && this.props.practica.estado_actual.codigo==='Est_Pra_Ini' && !this.props.practica.fecha_inicio) {
      this.props.mostrarModalAddEmpresaProceso(true)
    }
  }
  }

  obtenerModPracticas = async (programa) => {
    let modPracticas = []
    this.setState({ cargando: true });
    if (programa.relacion.valorc === 'ubarrio') modPracticas = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': MODALIDADES }, { 'llave': 'valorc', 'valor': 'activo' }, { 'llave': 'estado', 'valor': '1' }, {'llave': 'valore', 'valor': 'ubarrio'}]);
    else modPracticas = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': MODALIDADES }, { 'llave': 'valorc', 'valor': 'activo' }, { 'llave': 'estado', 'valor': '1' }, { 'llave': 'valorf', 'valor': 'cuc' }]);
    console.log(modPracticas);
    this.setState({
      modPracticas,
      cargando: false,
    });
  }
  async obtenerInducciones(id){
    let f = await generarFiltros([{ 'llave': 'estados', 'valor': id }]);
    return new Promise((resolve) => {
    consulta(`api/v1.0/practicas/induccion?${f}`, null, null, (error, estado, resp) => {
      resolve(estado === 200 && !error ? resp : null);
    })
  })}


  async obtenerEstados(id) {
    // this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'practica', 'valor': id }]);
    return new Promise((resolve) => {
    consulta(`api/v1.0/practicas/estados?${f}`, null, null, (error, estado, resp) => {
      resolve(estado === 200 && !error ? resp : null);
    }
    )
  })
}
  obtenerOpcionesPclinicas = async () => {
    this.setState({ cargando: true });
    const opcionesPcDB = await obtenerValores(TEMAS_PRACTICAS_CLINICAS);
    const generos = await obtenerValores(GENEROS);
    this.setState({
      opcionesPC: opcionesPcDB,
      generos,
      cargando: false,
    });
  }

  obtenerDatosAcuerdos = () => {
    return new Promise(resolve => {
      consulta(`api/v1.0/practica/acuerdos`, null, null, (error, estado, resp) => {
        resolve(resp)
      })
    })
  }

  async obtenerDatosAgregar() {
    this.setState({ render: false })
    let { resp } = await this.obtenerPractica();
    let data_periodo = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Pra' }]);
    let agrega = false;
    let mat = false;
    let vol = false;
    let periodo = false;
    let conceptos = false;
    let fecha1 = moment();
    let fecha2 = moment(data_periodo[0].valora);
    let dias = fecha2.diff(fecha1, 'days');


    if (dias >= 0) {
      periodo = true;
      if (resp.length === 0) {
        agrega = true;
        let { matricula, volante } = await this.matriculaEstudiante();
        let { conceptos: conceptosm } = await this.conceptosEstudiante();
        conceptos = conceptosm
        mat = matricula;
        vol = volante;
        await this.obtenerModalidades();
        let datos_persona = await obtenerMisDatos();
        if (datos_persona.programas) this.setState({ programas: datos_persona.programas, telefono: datos_persona.persona.telefono, celular: datos_persona.persona.celular, correo: datos_persona.persona.correo_personal, lugar_expedicion: datos_persona.persona.lugar_expedicion, fecha_nacimiento: datos_persona.persona.fecha_nacimiento, fecha_expedicion: datos_persona.persona.fecha_expedicion, });
      }
    }
    this.setState({ render: true, agrega, matricula: mat, periodo, conceptos, volante: vol })
  }

  async componentDidUpdate({}, { seleccion,actualizarRenderPracticas,modalDetalleEst }) {
    let { seleccion: seleccionNew, inducciones,idestados} = this.state;
    let {actualizarRenderPracticas:actualizarRenderPracticasNew,} = this.props;
    let {modalDetalleEst:modalDetalleEstNew}=this.state
    if (seleccionNew !== seleccion) {
      this.setState({ cambio: false, tiposPracticas: '', render: false, practica: { id: 0 } });
      if (seleccionNew === 'historial') await this.obtenerPracticas();
      else if (seleccionNew === 'proceso') await this.actualizarDataPractica();
      else if (seleccionNew === 'agregar') await this.obtenerDatosAgregar();
    }
    if(modalDetalleEst!==modalDetalleEstNew){
      if(modalDetalleEstNew==true){
        console.log(this.state)
         inducciones= await this.obtenerInducciones(idestados)
         this.setState({inducciones})

      }
    }

  }

  matriculaEstudiante = () => {
    return new Promise(resolve => {
      consulta(`api/v1.0/practica/estudiante/matricula`, null, null, (error, estado, resp) => {
        resolve(resp);
      })
    })
  }

  conceptosEstudiante = () => {
    return new Promise(resolve => {
      consulta(`api/v1.0/practica/estudiante/conceptos`, null, null, (error, estado, resp) => {
        resolve(resp);
      })
    })
  }
  
  obtenerModalidades = async () => {
    let { actualizarModalidadesParcticas } = this.props
    // const modalidadesDB = await obtenerValores(11);
    const modalidadesDB = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_PRACTICAS }, { 'llave': 'valorc', 'valor': 'activo' }, { 'llave': 'estado', 'valor': '1' }]);
    modalidadesDB.sort(this.organizarModalidades);
    actualizarModalidadesParcticas(modalidadesDB)
  }

  organizarModalidades = (a, b) => {
    const dato1 = a.valorb
    const dato2 = b.valorb
    let comparison = 0;
    if (dato1 > dato2) comparison = 1;
    else if (dato1 < dato2) comparison = -1;
    return comparison;
  };


  guardarComentario = (data) => {
    let { actualizarMensaje, mostrarModalCanPracticas } = this.props;
    consulta("api/v1.0/practicas/comentario/crear", data, "post",
      (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            mostrarModalCanPracticas(false);
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp)
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  }

  guardarPractica = (archivos, programa, busca_empresa, telefono, celular, correo, segundo_idioma,trabaja, en_area, eps, afiliacion, opcionPraCli, fecha_nacimiento, fecha_expedicion, lugar_expedicion, genero,areainteres,area_pro_mejora, aprendizaje,promedioacumulado,idioma_habla, tiposSolPracticas, callback) => { //
    let { actualizarMensaje, mostrarModalAddPracticas, modalidad } = this.props;
    console.log(tiposSolPracticas);
    const data = new FormData();
    archivos.map(({ archivo, codigo }, index) => {
      data.append(`archivo${index}`, archivo);
      data.append(`codigo${index}`, codigo);
      return true;
    });
    data.append('total', archivos.length);
    data.append('tipo', modalidad.id);
    data.append('programa', programa);
    data.append('busca_empresa', busca_empresa);
    data.append('telefono', telefono);
    data.append('celular', celular);
    data.append('correo', correo);
    data.append('trabaja', trabaja);
    data.append('en_area', en_area);
    data.append('fecha_nacimiento', fecha_nacimiento);
    data.append('fecha_expedicion', fecha_expedicion);
    data.append('lugar_expedicion', lugar_expedicion);
    data.append('genero', genero);
    data.append('eps', eps);
    data.append('afiliacion', afiliacion);
    data.append('opcion_materia', opcionPraCli);
    data.append('segundo_idioma', segundo_idioma);
    data.append('areainteres', areainteres);
    data.append('area_pro_mejora', area_pro_mejora);
    data.append('aprendizaje', aprendizaje);
    data.append('promedioacumulado', promedioacumulado);
    data.append('idioma_habla', idioma_habla);
    data.append('tiposSolPracticas', tiposSolPracticas);
    formulario(`api/v1.0/practicas/crear`, data, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          this.setState({ seleccion: 'proceso' })
          mostrarModalAddPracticas(false);
          if ((trabaja === 1 && en_area === 1) || modalidad.codigo === 'Mod_Rce_Pra' || modalidad.codigo === 'Mod_Epr_Pra' || modalidad.codigo === 'Mod_Dip_Pra') this.setState({ addEmpresa: true })
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      callback();
    })
  }

  guardarAdjuntosNuevos = (archivos, callback) => {
    let { actualizarMensaje, practica } = this.props;
    const data = new FormData();
    archivos.map(({ archivo, codigo }, index) => {
      data.append(`archivo${index}`, archivo);
      data.append(`codigo${index}`, codigo);
      return true;
    });
    data.append('total', archivos.length);
    formulario(`api/v1.0/practicas/${practica.id}/adjuntos/nuevos`, data, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          this.obtenerAdjuntos();
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      callback();
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    })
  }

  obtenerAdjuntos = async (validacion = null) => {
    let { actualizarDataAdjuntosPractica, practica } = this.props;
    let f = [{ 'llave': 'estado_pr__practica__id', 'valor': practica.id }];
    if (validacion !== null) f.push({ 'llave': 'validacion', 'valor': validacion });
    let filtros = await generarFiltros(f);
    this.setState({ cargandoPendientes: true })
    consulta(`api/v1.0/practicas/adjuntos?${filtros}`, null, null,
      (error, estado, resp) => {
        if (estado === 200) {
          actualizarDataAdjuntosPractica(resp);
          this.obtenerAdjFaltantes();
        }
      }
    );
  }

  ObtenerSeguimiento = async (id) => {
    let f = await generarFiltros([{ 'llave': 'practica', 'valor': id }]);
    return new Promise(resolve => {
      consulta(`api/v1.0/practicas/obtener/seguimiento?${f}`, null, null, (error, estado, resp) => {
        resolve(resp);
      })
    })
  }

  modificarPractica = (data, callback = () => { }) => {
    let { actualizarMensaje, practica } = this.props;
    consulta(`api/v1.0/practicas/${practica.id}/empresa`, data, "patch", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          callback();
          
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);;
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    })
  }


  guardarEmpresa = async (codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, archivo, callback) => {
    let { actualizarMensaje, mostrarModalAddValores } = this.props;
    let nitValido = validarNIT(codigo);
    if (nitValido) {
      let data = await crear_form_data({ codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, archivo });
      formulario(`api/v1.0/practicas/empresa/crear`, data, "post",
        (error, estado, resp) => {
          let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
          if (!error) {
            if (estado === 200) {
              tipo = 'success';
              titulo = resp.titulo;
              mostrarModalAddValores(false);
              callback(resp.empresa, resp.sucursal);
            } else {
              tipo = 'info';
              titulo = resp.titulo ? resp.titulo : mostrarError(resp)
            }
          }
          actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
        });
    } else actualizarMensaje({ titulo: 'NIT invalido, debe tener el siguientes formato 890104530-9', tipo: 'info', mostrar: true, tiempo: 6000 });

  }


  obtenerPractica = () => {
    return new Promise(resolve => {
      consulta(`api/v1.0/practicas/${0}/completa`, null, null, (error, estado, resp) => {
        resolve({ error, estado, resp });
      })
    })
  }

  actualizarDataPractica = async (iniciar = false) => {
    let { estado, resp } = await this.obtenerPractica();
        
    let { actualizarDataPractica, actualizarRenderPracticas, actualizarDataEstados } = this.props;
    if (estado === 200) {
      // let seguimiento = await this.ObtenerSeguimiento(resp.practica.id)
      actualizarDataEstados(resp.estados);
      actualizarDataPractica(resp.practica);
      this.notificacionBuscaEmpresa(resp.practica);
      this.notificacionDatosEmpresa(resp.practica);
      this.notificacionInduciones(resp.practica);
      this.obtenerAdjFaltantes();
      this.setState({ linea_estados: resp.linea_estados, render: true });
      actualizarRenderPracticas(true);
    }
  }

  obtenerAdjFaltantes = () => {
    let { practica } = this.props;
    if (practica && practica.id > 0) {
      consulta(`api/v1.0/practicas/${practica.id}/adjuntos/faltantes`, null, null,
        (error, estado, resp) => {
          if (estado === 200) {
            this.trasnformarDataAdj(resp);
            this.notificacionRequistosPendientes(resp);
            this.setState({ cargandoPendientes: false })
          }else{
            this.setState({ adj_faltantes : [] });
          }
        }
      );
    }
  }

  trasnformarDataAdj = (data) => {
    let adj_faltantes = [];
    data.map(({ secundario: { nombre, descripcion, id, archivo, valord, valorc, valorg } }) => {
      adj_faltantes.push({ nombre, descripcion, codigo: id, archivo, valord, tipo: valorc, formatos: valorg });
      return true;
    })
    this.setState({ adj_faltantes });
  }

  obtenerPracticas = () => {
    let { actualizarDataPracticas } = this.props;
    consulta("api/v1.0/practicas/listar/2", null, null,
      (error, estado, resp) => {
        if (estado === 200) {
          actualizarDataPracticas(resp);
          this.setState({ render: true });
        }
      }
    );
  }

  obtenerEstadosPractica = (callback = () => { }) => {
    let { actualizarDataEstados, practica } = this.props;
    consulta(`api/v1.0/practicas/estados?practica=${practica.id}`, null, null,
      (error, estado, resp) => {
        if (estado === 200) {
          actualizarDataEstados(resp);
          callback();
        }
      }
    );
  }

  pintarContinuarAddEmpresa = () => {
    return (
      <Dialog open={this.state.addEmpresa} fullWidth={true} maxWidth='sm' onClose={() => this.setState({ addEmpresa: false })} aria-labelledby="form-dialog-title">
        <AppBarModal titulo='¡ Fuí Seleccionado !' mostrarModal={() => this.setState({ addEmpresa: false })} titulo_accion="" accion="" />
        <DialogContent className='scroll'>
          <TareasTerminadas mensaje='Hola, si ya fuiste seleccionado o laboras en alguna empresa puedes diligenciar el siguiente formulario para continuar con tu proceso de practicas' marginTop='7%' imagen={emma_s} widthImg="7%" />
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CANCELAR" callback={() => this.setState({ addEmpresa: false })} />
          <BtnForm texto="CONTINUAR" callback={() => {
            this.setState({ addEmpresa: false });
            this.props.mostrarModalAddEmpresaProceso(true);
          }} />
        </DialogActions>
      </Dialog>
    );
  }

  notificacionBuscaEmpresa(practica) {
    this.limpiarNotificaciones('fecha_limite');
    let { notificaciones } = this.state;
    if (practica && practica.id > 0 && practica.fecha_limite && !practica.empresa && practica.busca_empresa === '0') {
      let fecha1 = moment();
      let fecha2 = moment(practica.fecha_limite);
      let dias = fecha2.diff(fecha1, 'days');
      let mensaje = dias <= 0 ? `El tiempo limite para encontrar tus practicas a terminado, pronto el coordinador de practicas de tu programa iniciara con la búsqueda  de la empresa donde realizaras tu proceso de practicas.` : `Dispones de ${dias} días para encontrar tus practicas, una vez el tiempo se acabe tu hoja de vida sera rotada en las diferentes empresas del sector externo.`
      let notificacion = {
        icono: fecha,
        titulo: '¿ Ya tienes tus practicas ?',
        mensaje,
        tipo: 'fecha_limite',
        acciones: () => <BtnEditar texto='Encontre mis practicas' callback={() => this.props.mostrarModalAddEmpresaProceso(true)} color='#01579b' />,
      }
      notificaciones.push(notificacion)
      this.setState({ notificaciones })
    }
  }

  notificacionInduciones(practica) {
    this.limpiarNotificaciones('datos');
    let { notificaciones } = this.state;
    if (practica && practica.id > 0 && (practica.tipo.codigo=='Mod_Cli_Pre' &&(practica.estado_actual.codigo=='Est_Pra_Ia' || practica.estado_actual.codigo=='Est_Pra_Ie' || practica.estado_actual.codigo=='Est_Pra_Ig' ))) {
      let mensaje = "Aqui puedes ver las inducciones asignadas"
      let notificacion = {
        icono: fecha,
        titulo: `¡ Ya tienes tus inducciones ${practica.estado_actual.nombre} !`,
        mensaje,
        tipo: 'datos',
        acciones: () => <BtnVer texto='Ver inducciones' callback={() => this.setState({modalDetalleEst:true})} color='#01579b' />,
      }
      notificaciones.push(notificacion)
      this.setState({ notificaciones })
    }
  }
  notificacionDatosEmpresa(practica) {
    this.limpiarNotificaciones('datos');
    let { notificaciones } = this.state;
    if (practica && practica.id > 0 && ((!practica.empresa && ((practica.trabaja === '1' && practica.en_area === '1') || practica.tipo.codigo === 'Mod_Rce_Pra' || practica.tipo.codigo === 'Mod_Epr_Pra' || practica.tipo.codigo === 'Mod_Dip_Pra')) || (practica.empresa && !practica.fecha_inicio) || ((!practica.empresa || !practica.fecha_inicio) && practica.estado_actual.codigo === 'Est_Pra_Ini'))) {
      let mensaje = "Hacen falta algunos datos para continuar con tu proceso, si laboras en una empresa o fuiste seleccionado puedes agregar la información."
      let notificacion = {
        icono: fecha,
        titulo: '¡ Ya tienes tus practicas !',
        mensaje,
        tipo: 'datos',
        acciones: () => <BtnEditar texto='Agregar Datos Empresa' callback={() => this.props.mostrarModalAddEmpresaProceso(true)} color='#01579b' />,
      }
      notificaciones.push(notificacion)
      this.setState({ notificaciones })
    }
  }
  notificacionRequistosPendientes(faltantes) {
    this.limpiarNotificaciones('requisitos');
    let { notificaciones } = this.state;
    if (faltantes.length > 0) {
      let mensaje = "Tienes algunos requisitos pendientes, cuando tengas la información requerida puedes cargarla para continuar con tu proceso."
      let notificacion = {
        icono: folder,
        titulo: `¡ ${faltantes.length} Requisitos Pendientes !`,
        mensaje,
        tipo: 'requisitos',
        acciones: () => <BtnVer texto='Cargar Requisitos' callback={() => this.props.mostrarModalAdjFaltantes(true)} color='#01579b' />,
      }
      notificaciones.push(notificacion)
      this.setState({ notificaciones })
    }
  }

  limpiarNotificaciones(tipo) {
    let { notificaciones } = this.state;
    let item = -1;
    notificaciones.map((noti, i) => {
      if (noti.tipo === tipo) {
        item = i;
        return true;
      }
    })

    if (item > -1) {
      notificaciones.splice(item, 1);
      this.setState({ notificaciones })
    }
  }


  pintarModalidades = (tipo = 'modalidad', lg = 3) => {
    let {
      mostrarModalAddPracticas,
      modalAddPractica,
      modalidad,
      requisitos,
      modalidades,
      actualizarModalidadPractica,
      actualizarRequisitosModalidad,
      actualizarMensaje
    } = this.props;
    let { programas, cambio, telefono, celular, correo, opcionesPC, lugar_expedicion, fecha_nacimiento, fecha_expedicion, generos } = this.state
    return (
      <div>
        <Button onClick={() => this.cambiarEstado({ tiposPracticas: '' })} style={{ padding: "0px 3px 0px 3px", marginTop: '1%' }} color="secondary" > <ArrowBackIcon /> <Typography variant="body2" style={{ color: '#9e9e9e' }} component="p" align='justify'> Atras </Typography> </Button>
        <div style={{ margin: "10px 0px 10px 0px" }} >
          {<PracticasModalidades
            programas={programas}
            telefono={telefono}
            celular={celular}
            correo={correo}
            lugar_expedicion={lugar_expedicion}
            fecha_nacimiento={fecha_nacimiento}
            fecha_expedicion={fecha_expedicion}
            actualizarMensaje={actualizarMensaje}
            api={api}
            mostrarModalAddPracticas={mostrarModalAddPracticas}
            modalAddPractica={modalAddPractica}
            requisitos={requisitos}
            modalidad={modalidad}
            guardarPractica={!cambio ? this.guardarPractica : () => alert("editar")}
            actualizarModalidadPractica={actualizarModalidadPractica}
            actualizarRequisitosModalidad={actualizarRequisitosModalidad}
            modalidades={modalidades}
            tipo={tipo}
            opcionesPraCli={opcionesPC}
            lg={lg}
            generos={generos}
          />}
        </div>
      </div>
    )
  }


  cambiarEstado = (estados) => {
    this.setState(estados)
    if(estados.tiposPracticas=='clinicas'){
      this.obtenerOpcionesPclinicas();
    }
  }

  pintarAgregar = () => {
    let { tiposPracticas, matricula, agrega, conceptos, render, periodo, volante } = this.state;
    if (render) {
      if (periodo) {
        if (agrega) {
          let total = this.contarTipos(tiposPracticas);
          if (!tiposPracticas) return this.pintarTipos();
          else if (tiposPracticas === 'reconocimiento' && conceptos) return <div onClick={() => this.setState({ tiposPracticas: '' })} className='pointer'><TareasTerminadas mensaje='Hola, no puedes aplicar a este tipo de practica, ya que cuentas con alguna beca aplicada. Clic aquí para ver otras opciones' marginTop='7%' imagen={emma_s} widthImg="7%" /></div >
          else if (tiposPracticas === 'reconocimiento' && !volante) return <div onClick={() => this.setState({ tiposPracticas: '' })} className='pointer'><TareasTerminadas mensaje='Hola, no puedes aplicar a este tipo de practica, ya que debes realizar el pago de tu volante por reconocimiento. Clic aquí para ver otras opciones' marginTop='7%' imagen={emma_s} widthImg="7%" /></div >
          else if (tiposPracticas === 'modalidad' && !matricula) return <div onClick={() => this.setState({ tiposPracticas: '' })} className='pointer'><TareasTerminadas mensaje='Hola, no puedes aplicar a este tipo de practica, ya que debes realizar tu matricula academica en SICUC. Clic aquí para ver otras opciones' marginTop='7%' imagen={emma_s} widthImg="7%" /></div >
          else return this.pintarModalidades(tiposPracticas, total > 2 ? 3 : 4);
        } else return <div onClick={() => this.setState({ seleccion: 'proceso' })} className='pointer'><TareasTerminadas mensaje='Hola, solo puedes tener un proceso activo en el periodo. Clic aquí para ver tu proceso actual' marginTop='7%' imagen={emma_s} widthImg="7%" /></div >
      } else return <TareasTerminadas mensaje='Hola, el periodo para realizar solicitudes no se encuentra disponible, contacta con tu coordinador de practicas para más información' marginTop='7%' imagen={emma_s} widthImg="7%" />;
    } else return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" cargando={true}/>;

  }
  
 
  modificarPracticaotravez = (id, data, callback = () => { }) => {
  
    let { practica,actualizarMensaje } = this.props;
    this.setState({ cargando: true });
    consulta(`api/v1.0/practicas/modificar/respuestafinal/${id}`, data, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          callback();
          this.actualizarDataPractica();
          this.setState({ cargando: false });
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);;
        }
        
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      
    })
  }

  onSubmit = e => {
    let { preguntafinal } = this.state;
    let {practica,mostrarPrueba}=this.props;
    this.modificarPracticaotravez(practica ? practica.id : 0,{preguntafinal},()=>mostrarPrueba(false));
    this.setState({preguntafinal:''})
    e.preventDefault();
  }
  pintar = () => {
    let {
      practica,
      modalAdmAdjuntosPractica,
      mostrarModalAdmAjuntosPractica,
      mostrarModalAdjFaltantes,
      mostrarModalAddAdjunto,
      estado,
      modalAdjFaltantes,
      mostrarModalAddValores,
      modalAddValores,
      actualizarMensaje,
      modalAddEmpresaProceso,
      mostrarModalAddEmpresaProceso,
      modalprueba,
      mostrarPrueba,
      mostrarModalAddValoresSucursal,
      modalAddValoresSucursal
    } = this.props;

    let { render, adj_faltantes, seleccion, notificaciones, datos_acuerdos, instructivo, cargandoPendientes,preguntafinal,finaltext,modalDetalleEst,inducciones,cargando } = this.state;
    const changeState = (estados) => {
      this.setState(estados)
    }
 
    return (
      <div>
        <MenuEstudiantePracticas
          notificaciones={notificaciones}
          seleccionar={(seleccion) => seleccion !== 'notificaciones' && this.cambiarEstado({ seleccion })}
          sele={seleccion}
          instructivo={instructivo[0] ? instructivo[0].archivo : ''}
        />
        <div className='contenido_modulo'>
          {
            render ?
              <div>
                {seleccion === 'agregar' && this.pintarAgregar()}
                {seleccion === 'proceso' && this.pintarProcesoActual()}
                {seleccion === 'historial' && this.pintarHistorial()}
                <PracticasAdjuntos
                  practica={practica}
                  adj_faltantes={adj_faltantes}
                  obtenerAdjFaltantes={this.obtenerAdjFaltantes}
                  estado={estado}
                  mostrarModalAddAdjunto={mostrarModalAddAdjunto}
                  mostrarModalAdjFaltantes={mostrarModalAdjFaltantes}
                  modalAdmAdjuntosPractica={modalAdmAdjuntosPractica}
                  mostrarModalAdmAjuntosPractica={mostrarModalAdmAjuntosPractica}
                  config={false} 
                  admin={false}
                  />

                <PracticasPendientes
                  cargando={cargandoPendientes}
                  practica={practica}
                  modalAdjFaltantes={modalAdjFaltantes}
                  mostrarModalAdjFaltantes={mostrarModalAdjFaltantes}
                  guardarAdjuntosNuevos={this.guardarAdjuntosNuevos}
                  adj_faltantes={adj_faltantes}
                  admin={false}
                />

                <EnviarMultiplesArchivos
                  acciones={this.accionesAdjFaltantes}
                  modalMulArchivos={modalAdjFaltantes}
                  titulo='¡ Requisitos Pendientes !'
                  enviarArchivos={(archivos, callback) => this.guardarAdjuntosNuevos(archivos, callback)}
                  mostrarModalMulArchivos={() => mostrarModalAdjFaltantes(false)}
                  limite={2}
                  mostrarRender={true}
                  mostrar={true}
                  enLista={true}
                  lista={adj_faltantes}
                />

                <PracticaEmpresa
                  actualizarMensaje={actualizarMensaje}
                  guardarEmpresa={this.guardarEmpresa}
                  obtenerValoresFiltros={obtenerValoresFiltros}
                  obtenerValores={obtenerValores}
                  modificarPractica={this.modificarPractica}
                  practica_id={practica ? practica.id : 0}
                  modalAddValores={modalAddValores}
                  mostrarModalAddValores={mostrarModalAddValores}
                  modalAddEmpresaProceso={modalAddEmpresaProceso}
                  mostrarModalAddEmpresaProceso={mostrarModalAddEmpresaProceso}
                  acuerdos={datos_acuerdos}
                  cambiar_empresa={(practica && practica.empresa) ? false : true }
                  mostrarModalAddValoresSucursal={mostrarModalAddValoresSucursal}
                  modalAddValoresSucursal={modalAddValoresSucursal}
                />
                {this.pintarContinuarAddEmpresa()}
                {this.props.practica &&
                  this.props.practica.contratadoempresa === '-1' ?
                <Dialog open={modalprueba} maxWidth='sm' fullWidth={true} onClose={()=>mostrarPrueba(false)} >
                    <AppBarModal titulo='¡ Datos Finalizado !' mostrarModal={mostrarPrueba} titulo_accion='' />
                    <DialogContent style={{ padding: 0 }} className='scroll' >
                      {
                        <Grid  style={{ spacing:"0" ,margin: "0 auto", padding: '1em'}} item xs={12} xl={12} md={12}>
                          <FormControl  component="fieldset">
                          <FormLabel  component="legend">Al finalizar tu proceso de practicas. ¿quedaste contratado en la empresa?</FormLabel>
                       <RadioGroup name={preguntafinal} value={preguntafinal} onChange={(e) => changeState({ preguntafinal: e.target.value })}>
    
                    <FormControlLabel onClick={() => this.setState({ preguntafinal:'1'})} value='1' control={<Radio />} label='Si' size="small" />
                    <FormControlLabel onClick={() => this.setState({ preguntafinal:'0'})} value='0' control={<Radio />} label='No' size="small" />
              </RadioGroup>
              </FormControl>
                     </Grid>
                      }
                    </DialogContent>
                    <DialogActions>
                      <BtnForm texto="Cerrar" callback={() => mostrarPrueba(false)} />
                      <BtnForm texto="Terminar" callback={(e) => this.onSubmit(e)} />
                    </DialogActions>
                  </Dialog>
                  : this.props.practica &&
                  <Dialog open={modalprueba} maxWidth='sm' fullWidth={true} onClose={()=>mostrarPrueba(false)} >
                    <AppBarModal titulo='¡ Datos Finalizado !' mostrarModal={mostrarPrueba} titulo_accion='' />
                    <DialogContent style={{ padding: 0 }} className='scroll' >
                      {
                      <Table>
                         <TableBody >
                         <TableRow key={-1}>
                         <TableCell component="th" scope="row">¿ Fuiste contratado en la empresa ?</TableCell>
                            <TableCell align="left">{`${this.props.practica.contratadoempresa === '1'?'SI':'NO'}`}</TableCell>
                          </TableRow>
                         </TableBody>
                        </Table>

                      }
                      </DialogContent>
                      <DialogActions>
                      <BtnForm texto="Cerrar" callback={() => mostrarPrueba(false)} />
                      {/* <BtnForm texto="Modificar" callback={this.habilitarPreguntaFinal()} /> */}
                    </DialogActions>
                    </Dialog>
                
                       }
              
        <Dialog fullWidth={true} maxWidth="xs" open={modalDetalleEst} onClose={() => this.setState({modalDetalleEst : false})} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <AppBarModal titulo={`Detalle ${this.props.practica ? this.props.practica.estado_actual.nombre:''}` } mostrarModal={() => this.setState({modalDetalleEst : false})} titulo_accion="" accion="" />
          <DialogContent className='scroll'>
            { (cargando) ? <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" cargando={true} /> :
              <ListarDatos
                datos={inducciones}
                id='tbl_inducciones'
                css={{}}
                buscar={false}
                fila_principal={({ fecha }) => 'Fecha de inducción: '+fecha }
                filas={[
                  { 'id': 'horario', 'mostrar': ({ hora_inicio, hora_final }) => hora_inicio ? `Desde ${hora_inicio} hasta ${hora_final}` : '' },
                  { 'id': 'enlace', 'mostrar': ({ enlace }) => enlace ? `Enlace: ${enlace}` : ''},
                  { 'id': 'observaciones', 'nombre': 'Observaciones : ' }
                ]}
              />  
            }

          </DialogContent>
          <DialogActions>
            <BtnForm texto="CERRAR" callback={() => this.setState({modalDetalleEst : false})} />
          </DialogActions>
        </Dialog >
                       
              </div>
              : <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" cargando={true}/>
          }
        </div>
      </div>


    )
  }

  contarTipos = (tipo) => {
    let { modalidades } = this.props;
    let total = 0;
    modalidades.map(({ valora }) => total = total + (valora === tipo ? 1 : 0))
    return total;
  }

  pintarTipos = () => {
    let { cambio, modPracticas, programas } = this.state;

    return (
      <div>
        {cambio ?
        <Button onClick={() => this.cambiarEstado({ cambio: false })} style={{ padding: "0px 3px 0px 3px", marginTop: '1%' }} color="secondary" > <ArrowBackIcon /> <Typography variant="body2" style={{ color: '#9e9e9e' }} component="p" align='justify'> Atras </Typography> </Button> : ''}
        <Grid container direction={'row'} justify={'center'}  >
          <Grid item xs={12} sm={12} md={9} lg={6}>
            <Grid container direction={'row'} justify={'center'}>
              {modPracticas.map((item, key) => {  
                  if(!item.valord){
                    return (
                      <Grid key={key} item xs={12} sm={12} md={12} style={{ padding: '2% 2% 0% 2%' }} onClick={() => this.cambiarEstado({ tiposPracticas: item.valora })}>
                        <Paper elevation={8} square>
                          <PracticasTipos image={this.obtenerImagen(item.valorb)} data={{ opciones: `${this.contarTipos(item.valora)} Modalidades`, nombre: item.nombre, descripcion: item.descripcion }} />
                        </Paper>
                      </Grid>
                    )
                  }else{
                    let validarPrograma = programas.filter( ({relacion}) => relacion.codigo==item.valord )
                    
                    if(validarPrograma.length>0){
                      return (
                        <Grid key={key} item xs={12} sm={12} md={12} style={{ padding: '2% 2% 0% 2%' }} onClick={() => this.cambiarEstado({ tiposPracticas: item.valora })}>
                          <Paper elevation={8} square>
                            <PracticasTipos image={this.obtenerImagen(item.valorb)} data={{ opciones: `${this.contarTipos(item.valora)} Modalidades`, nombre: item.nombre, descripcion: item.descripcion }} />
                          </Paper>
                        </Grid>
                      )
                    }
                  } 
                })    
              }
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }

  pintarProcesoActual = () => {
    let {
      practica,
      mostrarModalAdmAjuntosPractica,
      actualizarDataEstado,
      mostrarModalDetallePractica,
      modalCanPractica,
      mostrarModalCanPracticas,
      mostrarModalAddEmpresaProceso,
      modalDetallePractica,
      mostrarPrueba,
      
    } = this.props;


    const adjuntarArchivos = () => {
      actualizarDataEstado(practica.estado_actual);
      mostrarModalAdmAjuntosPractica(true);
    }



    const habilitarEmpresa = () => {
      // console.log(practica.empresa)
      if (practica && practica.id > 0) {
        let { estado_actual, tipo } = practica;
        if (
          (estado_actual.codigo === 'Est_Pra_Rev') || 
          (estado_actual.codigo === 'Est_Pra_Ini' && tipo.codigo != 'Mod_Dip_Pra_Uab') || 
          (estado_actual.codigo === 'Est_Pra_Env' && (tipo.codigo === 'Mod_Rce_Pra' || tipo.codigo === 'Mod_Epr_Pra' || tipo.codigo === 'Mod_Dip_Pra')) || 
          (estado_actual.codigo === 'Est_Pra_Env' && practica.trabaja === '1' && practica.en_area === '1')
          ) {
          return (
            <Grid item xs={12} sm={12} md={12} >
              <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => mostrarModalAddEmpresaProceso(true)}>
                <AccionesRapidas imagen={medalla} titulo={'¡ Datos Empresa !'} sub_titulo='Excelente' des_sub_titulo={`, ${!practica.empresa ? 'aquí puedes agregar los datos de la empresa...' : `Verifica aquí todos los datos de la empresa...`}`} />
              </Paper>
            </Grid>
          );
        } else return '';
      }
    }
    const habilitarPreguntaFinal = () => {
      let  {seguimiento}= this.state
      console.log(seguimiento)
      if (practica && practica.id > 0 && seguimiento) {
        let { estado_actual, tipo,busca_empresa } = practica;
        if (estado_actual.codigo === 'Est_Pra_Vif'&& tipo.codigo === 'Mod_Emp_Pra' && busca_empresa==='1' && seguimiento.length>=1) {
          return (
            <Grid item xs={12} sm={12} md={12} >
              <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => mostrarPrueba(true)}>
                <AccionesRapidas imagen={trabajarseguir} titulo={'¿ Fuiste contratado ?'} sub_titulo='Excelente' des_sub_titulo={`, ${practica.contratadoempresa=== '-1'? 'aquí puedes agregar si quedaste contratado en la empresa':`Aqui puedes ver lo que agregaste`}`}/>
              </Paper>
            </Grid>
          );
        } else return '';
     }
    }

    const descargarCarta = async () => {
      let { practica } = this.props
      console.log(practica.estado_actual.codigo)
      let coord = await obtenerPermisosValorAlt([{ 'llave': 'principal', 'valor': practica.programa.id }, { 'llave': 'secundario__generica', 'valor': 61 }])
      if (coord && coord[0] ) {
        const doc = await crearDocumento(coord[0], practica)
        Packer.toBlob(doc).then(blob => {
          saveAs(blob, `CARTA DE PRESENTACION - ${practica.usuario_registro.primer_nombre} ${practica.usuario_registro.primer_apellido}.docx`);
        });
      }
    }

    if (practica) {
      return (
        <div className='' >
          <div style={{ padding: '2% 2% 0% 2%' }} >
            <Grid container direction={'row'} spacing={1} >
              <Grid item xs={12} sm={12} md={6} >
                <TituloAccion principal='' alterno='¡ Información Básica !' />
                <Paper elevation={0} square className="scroll">
                  <EnviarMensaje
                    modal={modalCanPractica}
                    titulo={'¡ Enviar Mensaje !'}
                    type='text'
                    label='Redactar'
                    enviar={(mensaje) => {
                      this.guardarComentario({ mensaje, practica: practica.id })
                    }}
                    mostrarModal={mostrarModalCanPracticas}
                  />


                  {(practica && practica.id > 0) && <PracticaDetalleSimple practica={practica} />}
                  <Practica
                    practica_id={practica.id}
                    vistaDetalle='practica'
                    modalDetallePractica={modalDetallePractica}
                    mostrarModalDetallePractica={mostrarModalDetallePractica} 
                    admin={false}
                    adminuser={false}/>

                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TituloAccion principal='' alterno='¿ Que deseas hacer ?' />
                <Paper elevation={0} square className="scroll">
                  <Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
                    <Grid item xs={12} sm={12} md={12} >
                      <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => mostrarModalDetallePractica(true)}>
                          <AccionesRapidas imagen={revisar} titulo='¡ Más Información !' sub_titulo='Bien' des_sub_titulo=', Aquí puedes ver toda la información actual.' />
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} >
                      <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => adjuntarArchivos()}>
                        <AccionesRapidas imagen={study} titulo='¡ Mis Requisitos !' sub_titulo='Listo' des_sub_titulo=', Aquí puedes administrar tus documentos y formatos.' />
                      </Paper>
                    </Grid>
                    {habilitarEmpresa()}
                    {/* {habilitarDescargar()} */}
                    
                  {  practica.estado_actual.codigo !== 'Est_Pra_Env' &&
                  
                  <Grid item xs={12} sm={12} md={12} >
                    <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => descargarCarta()}>
                        <AccionesRapidas imagen={tap} titulo='¡ Carta de Presentación !' sub_titulo='Clic aqui' des_sub_titulo=', para descargar tu carta de presentación.' />
                      </Paper>
                    </Grid>}
                    {/* {habilitarPreguntaFinal()} */}
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={12} >
                <TituloAccion principal='' alterno='¿ Como va mi proceso ?' />
                {this.renderTiempo()}
              </Grid>
            </Grid>
            
          </div >
        </div>
      )
    } else return <div onClick={() => this.setState({ seleccion: 'agregar' })} className='pointer'><TareasTerminadas mensaje='Hola, no cuentas con un proceso activo, clic aquí para iniciar un nuevo proceso' imagen={emma_s} widthImg='7%' marginTop='7%' /></div>


  }

  renderTiempo = () => {
    let { render, linea_estados } = this.state;
    let { practica } = this.props;
    if (render) {
      if (practica && practica.estado_actual && linea_estados) {
        let activeStep = 0;
        let steps = [];
        linea_estados.map(({ secundario }, i) => {
          if (secundario.id === practica.estado_actual.id) activeStep = i;
          steps.push({ label: secundario.nombre })
        })
        activeStep = practica.estado_actual.codigo === 'Est_Pra_Fin' ? linea_estados.length + 1 : activeStep;
        return <BarraProceso steps={steps} activeStep={activeStep} />;
      }
      return '';
    }
  }


  pintarHistorial = () => {
    let {
      practicas,
      mostrarModalAdmAjuntosPractica,
      actualizarDataEstado,
      mostrarModalDetallePractica,
      actualizarDataPractica,
      actualizarDataAdjuntosPractica,
      practica,
      modalDetallePractica,
    } = this.props;

    let { render } = this.state;

    if (render) {
      return (<div>
        <PracticasListar
          administra={false}
          mostrarModalDetallePractica={mostrarModalDetallePractica}
          practicas={practicas}
          mostrarModalAdmAjuntosPractica={mostrarModalAdmAjuntosPractica}
          actualizarDataEstado={actualizarDataEstado}
          actualizarDataPractica={actualizarDataPractica}
          actualizarDataAdjuntosPractica={actualizarDataAdjuntosPractica} />

        <Practica
          practica_id={practica ? practica.id : 0}
          vistaDetalle='practica'
          modalDetallePractica={modalDetallePractica}
          mostrarModalDetallePractica={mostrarModalDetallePractica} 
          adminuser={false}/>

      </div>
      )
    } return <TareasTerminadas imagen={emma_w} mensaje='Cargando...' widthImg='7%' marginTop='7%' cargando={true}/>

  }

  accionesAdjFaltantes = (data, callback) => {
    const onClickCargar = (data) => callback(data);
    const onClickDescargar = ({ archivo }) => archivo && window.open(`${api + archivo}`, '_blank')
    let descargar = data.archivo && <BtnDescargar callback={() => onClickDescargar(data)} color='#01579b' texto='Abrir' />
    let cargar = <BtnCargar callback={() => onClickCargar(data)} color='#00bfa5' texto='Gestionar' />
    return data.valord === '1' && data.tipo !== '1' && <div>{descargar} {cargar}</div>;
  }

  render() {
    return this.pintar();
  }

  obtenerImagen = (key) => {
    let { imagenes } = this.state
    return imagenes[key]
  }
}


//CARGO ESTADOS DEL MODULO
const mapStateToProps = (state) => {
  let { modalAddValoresSucursal,modalAddEmpresaProceso, modalCanPractica, modalDetallePractica, estado, practicas, estados, modalAdmAdjuntosPractica, renderPracticas, practica, modalAddPractica, modalDelPractica, adjunto, adjuntos, renderAdjuntosPracticas, modalidades, modalidad, requisitos, modalModalidadPractica, modalTestPracticas,modalprueba } = state.redPracticas;
  let { modalAddValores } = state.redValores;
  let { modalAdjFaltantes } = state.redGlobal;
  return {
    practicas,
    renderPracticas,
    practica,
    modalAddPractica,
    modalDelPractica,
    modalTestPracticas,
    adjunto,
    adjuntos,
    modalAdmAdjuntosPractica,
    renderAdjuntosPracticas,
    modalidades,
    modalidad,
    modalModalidadPractica,
    requisitos,
    estados,
    estado,
    modalDetallePractica,
    modalCanPractica,
    modalAddValores,
    modalAddEmpresaProceso,
    modalAdjFaltantes,
    modalprueba,
    modalAddValoresSucursal
  }
};

//CARGO FUNCIONES DEL MODULO
const mapDispatchToProps = {
  actualizarMensaje,
  actualizarRenderPracticas,
  actualizarDataPractica,
  actualizarModalidadesParcticas,
  actualizarModalidadPractica,
  actualizarRequisitosModalidad,
  mostrarModalAddPracticas,
  mostrarModalDelPracticas,
  mostrarModulo,
  mostrarModalAdmAjuntosPractica,
  mostraModalModalidadesPracticas,
  mostrarModalTestPracticas,
  actualizarDataEstados,
  actualizarDataEstado,
  mostrarModalAddAdjunto,
  mostrarModalDetallePractica,
  actualizarDataAdjuntosPractica,
  actualizarDataPracticas,
  mostrarModalCanPracticas,
  mostrarModalAddValores,
  mostrarModalAddEmpresaProceso,
  mostrarModalAdjFaltantes,
  mostrarPrueba,
  mostrarModalAddValoresSucursal
}

//Props del componente
Practicas.propTypes = {
  //variables
  renderPracticas: PropTypes.bool.isRequired,
  modalAdjFaltantes: PropTypes.bool.isRequired,
  modalAddEmpresaProceso: PropTypes.bool.isRequired,
  modalAddValores: PropTypes.bool.isRequired,
  renderAdjuntosPracticas: PropTypes.bool.isRequired,
  modalCanPractica: PropTypes.bool.isRequired,
  modalAdmAdjuntosPractica: PropTypes.bool.isRequired,
  modalDetallePractica: PropTypes.bool.isRequired,
  practicas: PropTypes.array.isRequired,
  adjuntos: PropTypes.array.isRequired,
  modalAddPractica: PropTypes.bool.isRequired,
  modalTestPracticas: PropTypes.bool.isRequired,
  modalDelPractica: PropTypes.bool.isRequired,
  modalprueba: PropTypes.bool.isRequired,
  practica: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  adjunto: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  modalidades: PropTypes.array.isRequired,
  modalidad: PropTypes.object.isRequired,
  estado: PropTypes.object.isRequired,
  requisitos: PropTypes.array.isRequired,
  modalModalidadPractica: PropTypes.bool.isRequired,
  modalAddValoresSucursal: PropTypes.bool.isRequired,
  //funciones
  actualizarMensaje: PropTypes.func.isRequired,
  actualizarDataPractica: PropTypes.func.isRequired,
  actualizarModalidadesParcticas: PropTypes.func.isRequired,
  actualizarModalidadPractica: PropTypes.func.isRequired,
  actualizarRequisitosModalidad: PropTypes.func.isRequired,
  mostrarModalDelPracticas: PropTypes.func.isRequired,
  mostrarModalAddPracticas: PropTypes.func.isRequired,
  mostraModalModalidadesPracticas: PropTypes.func.isRequired,
  mostrarModulo: PropTypes.func.isRequired,
  mostrarModalAdmAjuntosPractica: PropTypes.func.isRequired,
  mostrarModalTestPracticas: PropTypes.func.isRequired,
  actualizarDataEstados: PropTypes.func.isRequired,
  actualizarDataEstado: PropTypes.func.isRequired,
  mostrarModalAddAdjunto: PropTypes.func.isRequired,
  actualizarDataAdjuntosPractica: PropTypes.func.isRequired,
  mostrarModalDetallePractica: PropTypes.func.isRequired,
  actualizarRenderPracticas: PropTypes.func.isRequired,
  actualizarDataPracticas: PropTypes.func.isRequired,
  mostrarModalCanPracticas: PropTypes.func.isRequired,
  mostrarModalAddValores: PropTypes.func.isRequired,
  mostrarModalAddEmpresaProceso: PropTypes.func.isRequired,
  mostrarModalAdjFaltantes: PropTypes.func.isRequired,
  mostrarPrueba: PropTypes.func.isRequired,
  mostrarModalAddValoresSucursal: PropTypes.func.isRequired
}


export default connect(mapStateToProps, mapDispatchToProps)(Practicas);
