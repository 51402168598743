import React, { Component, useState } from 'react'
import { Dialog, DialogContent, DialogActions, Link, FormControl, TextField, Grid } from '@material-ui/core';
import AppBarModal from '../../general/AppBarModal';
import { BtnForm, BtnDetalle, BtnCancelar } from '../../general/BotonesAccion';
import ListarDatos from '../../general/ListarDatos';
import { consulta, generarFiltros, formulario, mostrarError } from '../../../global/js/funciones'
import  { ValidacionesMenu } from './MenuValidaciones';
import { DetalleSolicitud } from "./ValidacionDetalle"
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from '../../../global/imagenes/emma_w.png';
import moment from 'moment'


class ValidacionesDetalle extends Component {
  constructor(props) {
    super(props)
    this.state = {
      estados: [],
      datos: [],
      documentos: [],
      cargando: false,
      seleccion: '',
      nota : '',
      seleccion: '',
    };
  }
    
    
  async componentDidUpdate({ modalDetValidaciones }, { seleccion }) {
    let { seleccion: seleccionNew } = this.state;
    let { validacionActual, modalDetValidaciones: modalDetValidacionesNew, vistaDetalle } = this.props;

    if (seleccionNew !== seleccion) {
      
      if (seleccionNew === 'detalle') this.obtenerInfoValidaciones(validacionActual);
      else if (seleccionNew == 'documentos') this.obtenerAdjuntos(validacionActual)
      else if (seleccionNew === 'estados') this.obtenerEstadosValidaciones(validacionActual);

    }

    if (modalDetValidaciones !== modalDetValidacionesNew) this.setState({ seleccion: modalDetValidacionesNew ? vistaDetalle : '' })
  }

  async obtenerInfoValidaciones(id) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'id', 'valor': id }]);
    consulta(`api/v1.0/validacion?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, datos: resp });
    })
  }

  async obtenerEstadosValidaciones(id) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'validacion', 'valor': id }]);
    consulta(`api/v1.0/validaciones/estados?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, estados: resp });
    })
  }

  async obtenerAdjuntos(id) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'validacion', 'valor': id }]);
    consulta(`api/v1.0/validaciones/adjuntos?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, documentos: resp });
    })
  }


  pintarEstados = () => {

    let { estados } = this.state;

    const obtenerColor = (codigo) => {
      let color = 'white';
      const colores = {
        'Val_Rec': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
        'Val_Rev': { 'backgroundColor': '#ffc107', color },
        'Val_Apr': { 'backgroundColor': '#01519B', color },
        'Val_Adm': { 'backgroundColor': '#18F270', color },
        'Val_Pag': { 'backgroundColor': '#A90AE2', color },
        'Val_Env': { 'backgroundColor': '#009688', color },
        'Val_Asi': { 'backgroundColor': '#636060', color },
        'Val_Not': { 'backgroundColor': '#BB0061', color },
        'Val_Aex': { 'backgroundColor': '#FF5551', color },
        'Val_Ecr': { 'backgroundColor': '#47408F', color },
        'Val_Ate': { 'backgroundColor': '#2e86de', color },
        'Val_Rep': { 'backgroundColor': '#4A235A', color },
        'Val_Neg': { 'backgroundColor': '#b71c1c', color },
        'Val_Can': { 'backgroundColor': '#b71c1c', color },
        'Val_Con': { 'backgroundColor': '#b71c1c', color },
      }
      return (colores[codigo])
    }
    

    return (
      <ListarDatos
        id="tbl_abjuntos_Validacion"
        datos={estados}
        avatar={({ tipo_estado }) => tipo_estado.nombre.charAt(0).toUpperCase()}
        color_avatar={({ tipo_estado: { codigo } }) => obtenerColor(codigo)}
        fila_principal={({ tipo_estado }) => tipo_estado.nombre.toUpperCase()}
        filas={[
          { 'id': 'usurio_registro', 'enLista': true, 'mostrar': ({ usuario_registro }) => `${usuario_registro.primer_nombre} ${usuario_registro.segundo_nombre} ${usuario_registro.primer_apellido} ${usuario_registro.segundo_apellido}`.toUpperCase() },
          { 'id': 'fecha_registro', 'enLista': true, 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a') },
          { 'id': 'mensaje', 'enLista': true, 'mostrar': ({ mensaje }) => mensaje },
          { 'id': 'lugar examen', 'enLista': true,  'mostrar': ({lugar_examen}) => lugar_examen, 'nombre': ' Lugar Examen: '},
          { 'id': 'hora_examen', 'enLista': true, 'mostrar': ({hora_examen}) => hora_examen, 'nombre': ' Hora Examen: '},
          { 'id': 'duracion_examen', 'enLista': true, 'mostrar':({duracion_examen}) => duracion_examen, 'nombre': ' Duración examen: '},
          { 'id': 'url', 'enLista': true, 'mostrar': ({url}) => url, 'nombre': ' Url Examen: '},
        ]}
      />
    )
  };


  pintarValidacion = () => {
    let { datos } = this.state;
    if (datos[0]) {
      return <DetalleSolicitud validacion={datos[0]}/>;
    }
  }


  pintarAdjuntos = () => {
    let { documentos } = this.state;

    const obtenerColor = ({validacion}) => {
      let color = 'white';
      const colores = {
        '1': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
        '2': { 'backgroundColor': '#01519B', color },
        '3': { 'backgroundColor': '#b71c1c', color },
      }
      return (colores[validacion])
    }

    const acciones = (row) => {
      // const onClickReemplazar = () => {
      //   this.setState({ adjunto: row })
      //   mostrarModalAddAdjValidacion(true)
      // }
     
      let detalle = <Link href={`${row.archivo}`} target="_blank"><BtnDetalle callback={() => { }} /></Link>
      // let reemplazar = (infoValidacion.estado_actual.codigo === 'Val_Env' && row.validacion != '2') && <BtnEditar callback={() => onClickReemplazar()} color='#00bfa5' texto='Reemplazar' />;
      let reemplazar = ''
      return <div>{detalle} {reemplazar}</div>;
    }

    return (
      <ListarDatos
        id="tbl_mis_adjuntos"
        descargar="DOCUMENTOS VALIDAICONES - EMMA"
        datos={documentos}
        buscar={false}
        avatar={({ validacion_solicitud_text }) => validacion_solicitud_text.charAt(0).toUpperCase()}
        color_avatar={row => obtenerColor(row)}
        acciones={row => acciones(row)}
        fila_principal={({ requisito }) => requisito.nombre.toUpperCase()}
        filas={[
          { 'id': 'nombre_archivo', 'enLista': false },
          { 'id': 'validacion_solicitud_text', 'enLista': true, 'mostrar': ({ validacion_solicitud_text }) => validacion_solicitud_text.toUpperCase() },
          { 'id': 'fecha_registro', 'enLista': true, 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a') },
          { 'id': 'valido', 'enLista': true, 'mostrar': ({ usuario_valida }) => `${  usuario_valida ? `VALIDADO: ${usuario_valida.primer_nombre} ${usuario_valida.primer_apellido} ${usuario_valida.segundo_apellido} - ${usuario_valida.correo}` : ''}` },
          { 'id': 'nota', 'enLista': true, 'mostrar': ({ validacion, nota }) => `${validacion == 3 ? `${nota}` : ''}` },
        ]}
      />
    )
  };
    


    
  pintar = () => {
    let { cargando, seleccion } = this.state;
    if (cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />;
    return (
      <div>
        {seleccion === 'detalle' && this.pintarValidacion()}
        {seleccion == 'documentos' && this.pintarAdjuntos()}
        {seleccion === 'estados' && this.pintarEstados()}
      </div>
    )

  }
    
  render() {
    let { seleccion } = this.state;
    let { modalDetValidaciones, mostrarModalDetalleValidaciones} = this.props

    return (
    <>
      <Dialog open={modalDetValidaciones} maxWidth="sm" fullWidth={true} onClose={ () => mostrarModalDetalleValidaciones(false)}>
        <AppBarModal titulo='¡ Detalle Validaciones !' mostrarModal={mostrarModalDetalleValidaciones} titulo_accion='' />
        <ValidacionesMenu sele={seleccion} seleccionar={seleccion => this.setState({ seleccion })} />
        <DialogContent style={{ padding: 0 }} className='scroll' >
          {this.pintar()}
        </DialogContent>
        <DialogActions>
          {/* {datos[0] && datos[0].volante ? <Link href={`${datos[0].volante}`} target="_blank"><BtnForm texto="DESCARGAR VOLANTE" callback={() => { }} color="#01579b" /></Link> : ''} */}
          <BtnForm texto="Cerrar" callback={() => mostrarModalDetalleValidaciones(false)} />
        </DialogActions>
      </Dialog>
    </>
    );
  }
}

export default ValidacionesDetalle