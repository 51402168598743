import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogActions, Grid, FormControl, 
  InputLabel, Select, MenuItem, Fab, makeStyles } from '@material-ui/core';
import { mostrarModalAddInstituciones } from '../../../redux/actions/actInternacionalizacion';
import { actualizarMensaje } from '../../../redux/actions/actGlobal';
import AppBarModal from '../../general/AppBarModal';
import TareasTerminadas from '../../general/TareasTerminadas';
import emma_w from '../../../global/imagenes/emma_w.png';
import ListarDatos from '../../general/ListarDatos';
import { BtnCancelar, BtnForm, BtnSeleccion } from '../../general/BotonesAccion';
import { consulta, generarFiltros, guardarValorGenerica, mostrarError, obtenerValores, obtenerValoresFiltros } from '../../../global/js/funciones';
import { TIPOS_INSTITUCIONES, UNIVERSIDADES } from '../administrar/helper';
import ValoresAgregar from '../../genericas/valores/ValoresAgregar';
import AddIcon from '@material-ui/icons/Add';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
  fabButton: {
    position: 'fixed',
    left: "45%",
    zIndex: 1000,
    top: '83%',
    [theme.breakpoints.up('sm')]: {
      top: '89%',
      left: "50%",
    },
  }
}));

function Agregar({ actAgregar }) {
  const classes = useStyles();
  return (
    <Fab color = "secondary" aria-label = "add" className = { classes.fabButton } onClick = { () => actAgregar() }>
      <AddIcon />
    </Fab>
  )
}

class ConvocatoriasBuscarUniversidad extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dato_buscar: '',
      pais_filtro: '',
      paises: [],
      universidades: [],
      tipos_instituciones: [],
      cargando: false,
      cargando_pais: false,
      modal_filtro_pais: false,
    }
  }

  async componentDidUpdate({ modalBuscarUniversidad }) {
    let { modalBuscarUniversidad: modalBuscarUniversidadNew } = this.props;
    if (modalBuscarUniversidadNew !== modalBuscarUniversidad) {
      this.setState({ cargando: true });
      await this.obtenerconvenios();
      let tipos_instituciones = await obtenerValores(TIPOS_INSTITUCIONES);
      this.setState({ tipos_instituciones });
    }
  }

  guardarInstitucion = async (codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, archivo) => {
    let { actualizarMensaje, mostrarModalAddInstituciones } = this.props;
    let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
    let { error, estado, resp } = await guardarValorGenerica(UNIVERSIDADES, codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, archivo);
    if (!error) {
      if (estado === 200) {
        tipo = 'success';
        titulo = resp.titulo;
        mostrarModalAddInstituciones(false);
        this.obtenerUniversidades();
      } else {
        tipo = 'info';
        titulo = resp.titulo ? resp.titulo : mostrarError(resp);
      }
    } 
    actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
  }
   obtenerconvenios = async ()=>{
    let f = await generarFiltros([{ 'llave': 'solicitud__estado_actual__codigo', 'valor': "Con_regis" },{ 'llave': 'solicitud__estado_actual__codigo', 'valor': "sin_conv_uni" }]);
    await consulta(`api/v1.0/internacionalizacion/solicitud/convenio?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ universidades: resp ,cargando:false});
    });
  }
  obtenerUniversidades = async(pais = '') => {
    let universidades = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': UNIVERSIDADES }, { 'llave': 'estado', 'valor': 1 }]);

    let paises = universidades.map(universidad => universidad.valora );
    let universidades_filtradas = universidades.filter(({ valora }) => valora == pais );
    this.setState({ paises: [...new Set(paises)], universidades: universidades_filtradas.length ? universidades_filtradas : universidades });
  }

  acciones = (data) => {
    let { seleccionadas } = this.props;
    const onClickSeleccionar = (data) => {
      this.props.seleccionar(data)
    }
    const onClickQuitar = (data) => {
      this.props.quitar(data)
    }

    return (
      seleccionadas.map(u => u.id).includes(data.id) ? <BtnCancelar callback = { () => onClickQuitar(data) } />
        : <BtnSeleccion callback = { () => onClickSeleccionar(data) } />
    );
  }

  filtrarPaisesInstituciones = () => {
    let { pais_filtro } = this.state;
    this.setState({ modal_filtro_pais: false, cargando: true });
    this.obtenerUniversidades(pais_filtro);
    this.setState({ cargando: false });
  }
  
  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({ [name]: value });
  }

  render () {
    let { modalBuscarUniversidad, mostrarModalBuscarUniversidad, modalAddInstituciones, mostrarModalAddInstituciones } = this.props;
    let { universidades, cargando, modal_filtro_pais, cargando_pais, pais_filtro, 
      paises, tipos_instituciones } = this.state;
    const config = () => {
      return {
        inputCodigo: { 'label': 'Siglas', 'mostrar': true, 'tipo': 'text', 'requerido': true, 'mayusculas': true },
        inputNombre: { 'label': 'Nombre de la institución', 'mostrar': true, 'tipo': 'text', 'requerido': true, 'mayusculas': true },
        inputDescripcion: { 'label': 'Descripción', 'mostrar': false, 'tipo': 'text', 'requerido': true, 'mayusculas': true },
        inputValora: { 'label': 'País', 'mostrar': true, 'tipo': 'text', 'requerido': true, 'mayusculas': false },
        inputValorb: { 'label': 'Ciudad', 'mostrar': true, 'tipo': 'text', 'requerido': true, 'mayusculas': false },
        inputValorc: { 'label': 'Dirección', 'mostrar': false, 'tipo': 'text', 'requerido': false, 'mayusculas': false },
        inputValord: { 'label': 'Idioma', 'mostrar': true, 'tipo': 'text', 'requerido': false, 'mayusculas': false },
        inputValore: { 'label': 'Sede', 'mostrar': true, 'tipo': 'text', 'requerido': false, 'mayusculas': true },
        inputValorf: { 'label': 'Rector / representante legal', 'mostrar': false, 'tipo': 'text', 'requerido': false, 'mayusculas': false },
        inputValorg: { 'label': 'Tipo de institución', 'mostrar': true, 'tipo': 'text', 'requerido': true, 'mayusculas': false, 'select': true, 'lista': tipos_instituciones },
        inputArchivo: { 'label': 'Adjuntar certificado (Cámara de comercio)', 'mostrar': false, 'tipo': 'file', 'requerido': false },
      }
    }
    return (
      <>
        <Dialog open = { modalBuscarUniversidad } onClose = { mostrarModalBuscarUniversidad.bind(this, false) } aria-labelledby = "form-dialog-title" fullWidth = { true } maxWidth = "sm">
          <AppBarModal titulo = { '¡ Buscar Universidad !' } mostrarModal = { mostrarModalBuscarUniversidad } titulo_accion = "" accion = "" />
          <DialogContent className = 'scroll' style = { { padding: 0 } }>
            {
              cargando ? <TareasTerminadas mensaje = 'Cargando..' marginTop = '7%' imagen = { emma_w } widthImg = "7%" /> :
                <ListarDatos
                  datos = { universidades }
                  titulo = 'Lista de Universidades'
                  opciones = { true }
                  buscar = { true }
                  filtrar = { true }
                  actfiltrar = { () => this.setState({ modal_filtro_pais: true }) }
                  sub_titulo = 'Universidades'
                  id = 'tbl_universidades_seleccion'
                  acciones = { row => this.acciones(row) }
                  fila_principal = { ({ institucion }) => institucion.toUpperCase() }
                  filas = { [
                    {'nombre': 'Código del convenio: ', 'id': 'codigo_inter', 'mostrar': ({ codigo_convenio }) => codigo_convenio ? codigo_convenio :"No registra"},
                    {'nombre': 'País: ', 'id': 'pais', 'mostrar': ({ pais_vinculante }) => pais_vinculante ? pais_vinculante.nombre :"No registra"},
                    // {'nombre': 'Ciudad: ', 'id': 'ciudad', 'mostrar': ({ ciudad_vinculante }) => ciudad_vinculante ? ciudad_vinculante :"No registra"},
                  ] }
                />
            }
          </DialogContent>
          <DialogActions>
            <BtnForm texto = "CERRAR" callback = { () => mostrarModalBuscarUniversidad(false) } />
          </DialogActions>
          {/* <Agregar actAgregar = { () => mostrarModalAddInstituciones(true) } /> */}
          <ValoresAgregar
            mostrarABC = { true }
            modalAddValores = { modalAddInstituciones }
            mostrarModalAddValores = { mostrarModalAddInstituciones }
            guardarValor = { this.guardarInstitucion }
            inputs = { config() }
            titulo = '¡ Nueva Institución !'
          />
        </Dialog>

        <Dialog fullWidth = { true } maxWidth = "xs" open = { modal_filtro_pais } onClose = { () => this.setState({ modal_filtro_pais: false })} aria-labelledby = "alert-dialog-title" aria-describedby = "alert-dialog-description">
          <AppBarModal titulo = '¡ Aplicar Filtros !' mostrarModal = { () => this.setState({ modal_filtro_pais : false }) } titulo_accion = "Limpiar" accion = { () => this.setState({ pais_filtro: '' }) } />
          <DialogContent className = 'scroll'>
            {
              !cargando_pais ?
                <Grid container spacing = { 3 } className = 'margin_cont'>
                  <Grid item xs = { 12 } xl = { 12 } sm = { 12 } md = { 12 } lg = { 12 }>
                    <FormControl className = 'form-control'>
                      <InputLabel htmlFor = "pais_filtro">Seleccione País</InputLabel>
                      <Select inputProps = { { name: 'pais_filtro', id: 'pais_filtro' } } value = { pais_filtro } onChange = { this.onChange } required = { true }>
                        { paises.map((pais, i) => <MenuItem key = { i } value = { pais }>{ pais }</MenuItem>) }
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                : <TareasTerminadas imagen = { emma_w } mensaje = 'Cargando...' widthImg = '5%' marginTop = '5%' />
            }
          </DialogContent>
          <DialogActions>
            <BtnForm texto = "CANCELAR" callback = { () => this.setState({ modal_filtro_pais: false }) } />
            <BtnForm texto = "FILTRAR" callback = { () => this.filtrarPaisesInstituciones() } />
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

const mapStateToProps = state => {
  let { modalAddInstituciones } = state.redInternacionalizacion;
  return { modalAddInstituciones }
}

const mapDispatchToProps = { mostrarModalAddInstituciones, actualizarMensaje }

ConvocatoriasBuscarUniversidad.propTypes = {
  actualizarMensaje: PropTypes.func.isRequired,
  modalBuscarUniversidad: PropTypes.bool.isRequired,
  modalAddInstituciones: PropTypes.bool.isRequired,
  mostrarModalBuscarUniversidad: PropTypes.func.isRequired,
  mostrarModalAddInstituciones: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(ConvocatoriasBuscarUniversidad);
