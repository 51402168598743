import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import React from 'react'


function convertirARomano(numero) {
    const romanos = [
        { valor: 10, simbolo: "X" },
        { valor: 9, simbolo: "IX" },
        { valor: 5, simbolo: "V" },
        { valor: 4, simbolo: "IV" },
        { valor: 1, simbolo: "I" },
    ];

    let resultado = "";
    for (const { valor, simbolo } of romanos) {
        while (numero >= valor) {
            resultado += simbolo;
            numero -= valor;
        }
    }

    return resultado;
}


export default function SolicitudTables({ data }) {
    let {
        tipo: { nombre },
        semestre,
        estado_actual,
        sigla,
        usuario_registro: { primer_nombre, segundo_nombre, primer_apellido, segundo_apellido },
    } = data;
    const semestreRomano = convertirARomano(semestre);

    return (
        <Table>
            <TableBody>
                <TableRow key={1}>
                <TableCell component="th" scope="row">Solicitante</TableCell>
                    <TableCell align="left">{`${primer_nombre} ${segundo_nombre} ${primer_apellido} ${segundo_apellido}`}</TableCell>
                </TableRow>
                <TableRow key={2}>
                    <TableCell component="th" scope="row">Tipo de solicitud</TableCell>
                    <TableCell align="left">{nombre}</TableCell>
                </TableRow>
                <TableRow key={3}>
                    <TableCell component="th" scope="row">Semestre</TableCell>
                    <TableCell align="left">{semestreRomano}</TableCell>
                </TableRow>
                <TableRow key={4}>
                    <TableCell component="th" scope="row">Estado Actual</TableCell>
                    <TableCell align="left">{estado_actual.nombre}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}

export function DetalleCurso({ datos }) {
    // const classes = useStyles();
    console.log(datos)
    let {
        solicitud: { tipo, periodo },
        usuario_registro: { primer_nombre, segundo_nombre, primer_apellido, segundo_apellido },
        semestre,
        horario,
        programa,
        semillero
    } = datos[0]
    const semestreRomano = convertirARomano(semestre);

    return (
        <Table>
            <TableBody>
                <TableRow key={1}>
                    <TableCell component="th" scope="row">Tipo de solicitud</TableCell>
                    <TableCell align="left">{tipo.nombre}</TableCell>
                </TableRow>
                <TableRow key={2}>
                    <TableCell component="th" scope="row">Solicitante</TableCell>
                    <TableCell align="left">{`${primer_nombre} ${segundo_nombre} ${primer_apellido} ${segundo_apellido}`}</TableCell>
                </TableRow>
                <TableRow key={3}>
                    <TableCell component="th" scope="row">Semestre</TableCell>
                    <TableCell align="left">{semestreRomano}</TableCell>
                </TableRow>
                <TableRow key={4}>
                    <TableCell component="th" scope="row">Periodo</TableCell>
                    <TableCell align="left">{periodo}</TableCell>
                </TableRow>
                <TableRow key={5}>
                    <TableCell component="th" scope="row">Horario</TableCell>
                    <TableCell align="left">{horario}</TableCell>
                </TableRow>
                <TableRow key={6}>
                    <TableCell component="th" scope="row">Programa</TableCell>
                    <TableCell align="left">{programa.nombre}</TableCell>
                </TableRow>
                {
                    semillero !== null &&
                    <TableRow key={7}>
                        <TableCell component="th" scope="row">Semillero al que se encuentra vinculado</TableCell>
                        <TableCell align="left">{semillero.nombre}</TableCell>
                    </TableRow>
                }
                {/* <TableRow key={7}>
                    <TableCell component="th" scope="row">
                        Semillero al que pertenece
                    </TableCell>
                    <TableCell align="left">
                        <TableRow scope="row">{"Nombre: " + semillero.nombre}</TableRow>
                        <TableRow scope="row">{"Sigla: " + semillero.sigla}</TableRow>
                    </TableCell>
                </TableRow> */}
            </TableBody>
        </Table>
    )
}
