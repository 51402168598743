// HELPERS
export const TIPOS_DE_CASOS = 255
export const ESTADO_ESTUDIANTE = 256
export const TIPO_INCIDENTE_PARAM = 258
export const TIPO_ATENCION_ID = 260
export const PROGRAMAS_ATENCION = 5
export const FACULTADES_ATENCION = 150

export const ESTADOS = 257
export const REQUISITOS = 34

export const PERMISO_TIPO = 46
export const PERMISO_ESTADO_ESTUDIANTE = 47
export const PERMISO_INCIDENTE = 48
export const PERMISO_ESTADOS = 49

// HELPERS LOCAL
// export const TIPOS_DE_CASOS = 117
// export const ESTADO_ESTUDIANTE = 118
// export const TIPO_INCIDENTE_PARAM = 120
// export const TIPO_ATENCION_ID = 122
// export const PROGRAMAS_ATENCION = 5
// export const FACULTADES_ATENCION = 1002

// export const ESTADOS = 119
// export const REQUISITOS = 34

// export const PERMISO_TIPO = 46
// export const PERMISO_ESTADO_ESTUDIANTE = 47
// export const PERMISO_INCIDENTE = 48
// export const PERMISO_ESTADOS = 49