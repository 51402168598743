
import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { ValidatorForm } from "react-material-ui-form-validator";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import AppBarModal from './AppBarModal'
import { BtnForm } from "./BotonesAccion";
import CustomDropzone from "./CustomDropzone";
import ListarDatos from "./ListarDatos";
import { actualizarMensaje } from "../../redux/actions/actGlobal";
import { BtnCargar } from "./BotonesAccion";
import TareasTerminadas from './TareasTerminadas';
import EnviarArchivo from './EnviarArchivo';
import emma_s from '../../global/imagenes/emma_s1.png';
import DoneIcon from '@material-ui/icons/Done';
import RemoveIcon from '@material-ui/icons/Remove';

class EnviarMultiplesArchivos extends Component {

  constructor(props) {
    super(props)
    this.state = {
      modalAddAdjunto: false,
      codigoAdj: '',
      mostrar: false,
      archivos: [],
      formatoAdj: ''
    }
  }

  componentDidMount() {
    let { cargadoAutomatico, lista } = this.props;
    if(cargadoAutomatico) { // Carga los archivos guardados cuando se abra el modal nuevamente
      let aux = lista.filter(({ adjunto }) => adjunto).map(({ codigo, adjunto, nombre_adjunto }) => ({ codigo, archivo: new File([adjunto], nombre_adjunto) }) );
      if (aux) this.setState({ archivos: aux });
    }
  }

  componentDidUpdate({ modalMulArchivos }) {
    let { modalMulArchivos: modalAddNew, mostrarRender } = this.props;
    if (modalAddNew !== modalMulArchivos) {
      this.setState({
        modalAddAdjunto: false,
        codigoAdj: '',
        mostrar: mostrarRender,
        archivos: [],
      });
    }
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }


  onSubmit = e => {
    let { archivos } = this.state;
    this.props.enviarArchivos(archivos, () => {
      this.setState({
        codigoAdj: '',
        archivos: [],
      });
    });
    e.preventDefault();
  }

  eliminarArchivoArray = (i) => {
    let { actualizarMensaje } = this.props;
    let { archivos } = this.state;
    archivos.splice(i, 1)
    this.setState({ archivos })
    actualizarMensaje({ titulo: `Archivo Retirado.`, tipo: 'success', mostrar: true, tiempo: 6000 })
  }

  agregarArchivoArray = (archivo) => {
    let { limite, actualizarMensaje, lista, enLista, cargadoAutomatico, cargarArchivo } = this.props;
    let titulo = 'Archivo Agregado';
    let total = enLista ? lista.length : limite;
    let tipo = 'success';
    let sw = true;
    let archivos = [];
    if (archivo.codigo) {
      archivos = this.state.archivos;
      archivos.map((row) => {
        if (row.codigo === archivo.codigo) {
          if (cargadoAutomatico) cargarArchivo(archivo.codigo, archivo); // Carga el archivo a la base de datos si cargadoAutomatico es true.
          row.archivo = archivo.archivo;
          titulo = `Archivo Remplazado.`
          sw = false;
        }
      })
    }

    if (sw) {
      if (cargadoAutomatico) cargarArchivo(archivo.codigo, archivo); // Carga el archivo a la base de datos si cargadoAutomatico es true.
      archivos = this.state.archivos.concat(archivo);
      if (archivos.length <= total) this.setState({ archivos })
      else {
        tipo = 'info';
        titulo = `Se ha excedido el número máximo permitido de archivos ${total}.`
      }
    } else this.setState({ archivos });
    this.setState({ modalAddAdjunto: false });
    actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 3000 });
  }

  obtenerColor = (codigo) => {
    let color = 'white';
    const colores = {
      'no': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
      'si': { 'backgroundColor': '#009688', color },
    }
    return (colores[codigo])
  }

  cargado = buscar => {
    let { archivos } = this.state;
    let existe = archivos.find(({ codigo }) => codigo === buscar)
    return (existe)
  }

  arrastrarElemento = (e) => {
    console.log("en area");
    e.preventDefault();
  }

  soltarElemento = (e) => {
    console.log("solto");
    // let dt = e.dataTransfer;
    // let files = dt.files;
    // this.handleFiles(files)
    e.preventDefault();
  }

  handleFiles(files) {
    ([...files]).forEach(this.uploadFile)
  }

  uploadFile(file) {
    console.log(file);
  }

  contenido = () => {
    let { lista, mensaje, mostrarRender, enLista, acciones, formatos } = this.props;
    let { archivos, mostrar } = this.state;
    const callback = (row) => this.setState({ modalAddAdjunto: true, codigoAdj: row.codigo, formatoAdj: formatos && row.formatos ? row.formatos : '' })
    return (
      <ValidatorForm onSubmit={this.onSubmit} style={{ padding: 5, width: "100%" }}>
        {
          enLista ?
            <div id='dropzone_emma' /*onDragEnter={this.arrastrarElemento} onDrop={this.soltarElemento}*/>
              <ListarDatos
                datos={lista}
                css={{}}
                mensajeVacio={'No tienes documentos pendientes'}
                titulo=''
                sub_titulo=''
                buscar={false}
                descargar=''
                id='tbl_adj_faltantes'
                acciones={(row) => acciones(row, callback)}
                avatar={({ codigo }) => this.cargado(codigo) ? <DoneIcon /> : <RemoveIcon />}
                color_avatar={({ codigo }) => this.obtenerColor(this.cargado(codigo) ? 'si' : 'no')}
                fila_principal='nombre'
                filas={[
                  { 'id': 'descripcion' },
                  {
                    'id': 'cargado', 'nombre': 'Cargado : ', 'mostrar': ({ codigo }) => {
                      let existe = this.cargado(codigo);
                      return existe ? existe.archivo.name : '';
                    }
                  }
                ]}
              />
            </div>
            :
            <CustomDropzone
              addFunction={(archivos) => this.agregarArchivoArray(archivos)}
              deleteFunction={(i) => this.eliminarArchivoArray(i)}
              acceptedFiles=''
              actualizarMensaje={actualizarMensaje}
              archivos={archivos}
              mensaje={mensaje}
            />
        }

        <Button color="primary" type="submit" id='enviar_form_multi_archivo' className="oculto"> Guardar </Button>
      </ValidatorForm>
    )
  }

  render() {
    let { modalMulArchivos, mostrarModalMulArchivos, titulo, enModal, mensaje_emma, actualizarMensaje } = this.props;
    let { mostrar, modalAddAdjunto, formatoAdj } = this.state;
    return (
      <div>
        <EnviarArchivo enviarArchivo={(archivo) => archivo ? this.agregarArchivoArray({ codigo: this.state.codigoAdj, archivo }) : actualizarMensaje({ titulo: 'Seleccione Archivo', tipo: 'info', mostrar: true, tiempo: 6000 })} modalAdd={modalAddAdjunto} mostrarModalAdd={() => this.setState({ modalAddAdjunto: false })} titulo='Cargar Archivo' formatos={formatoAdj}/>
        {
          enModal ? <Dialog open={modalMulArchivos} fullWidth={true} maxWidth="sm" onClose={mostrarModalMulArchivos.bind(this, false)} aria-labelledby="form-dialog-title">
            <AppBarModal titulo={titulo} mostrarModal={mostrarModalMulArchivos} titulo_accion="" accion="" />
            <DialogContent className='scroll' style={{ padding: 0 }}>
              {
                mostrar ? this.contenido() : <TareasTerminadas imagen={emma_s} mensaje={mensaje_emma} widthImg='7%' marginTop='7%' />
              }
            </DialogContent>
            <DialogActions>
              <BtnForm texto="CERRAR" callback={() => mostrarModalMulArchivos(false)} />
              <BtnForm texto={mostrar ? 'TERMINAR' : 'CONTINUAR'} callback={() => mostrar ? document.getElementById('enviar_form_multi_archivo').click() : this.setState({ mostrar: true })} />
            </DialogActions>
          </Dialog> : this.contenido()
        }


      </div>
    );
  }
}

const acciones = (data, callback) => <BtnCargar callback={() => callback(data)} />;

EnviarMultiplesArchivos.propTypes = {
  //variables
  modalMulArchivos: PropTypes.bool,
  titulo: PropTypes.string,
  mensaje_emma: PropTypes.string,
  mensaje: PropTypes.string,
  limite: PropTypes.number,
  cargadoAutomatico: PropTypes.bool,
  //funciones
  enviarArchivos: PropTypes.func.isRequired,
  mostrarModalMulArchivos: PropTypes.func,
  actualizarMensaje: PropTypes.func.isRequired,
  cargarArchivo: PropTypes.func
}

EnviarMultiplesArchivos.defaultProps = {
  mensaje: 'Arrastre y suelte los archivos aquí o haga clic',
  mensaje_emma: 'Hola, hacen falta algunos requisitos para continuar con tu proceso. Puedes cargarlos presionado la opción de continuar',
  lista: [],
  limite: 5,
  enLista: false,
  mostrarRender: false,
  enModal: true,
  acciones: (data, callback) => acciones(data, callback),
  cargadoAutomatico: false,
};

//CARGO ESTADOS DEL MODULO
const mapStateToProps = (state) => {
  return {
  }
};

//CARGO FUNCIONES DEL MODULO
const mapDispatchToProps = {
  actualizarMensaje,
}

export default connect(mapStateToProps, mapDispatchToProps)(EnviarMultiplesArchivos);
