import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment'

export function Tutoria({ tutoria }) {
  
  let {
    asignatura,
    codigo_asignatura,
    estado_actual,
    fecha_registro,
    programa,
    interesado: { primer_nombre, primer_apellido, segundo_apellido },
  } = tutoria;
  
  let fecha = moment(fecha_registro).format('YYYY-MM-DD, h:mm a');
  return (
    <Table>
      <TableBody>
        <TableRow key={1}>
          <TableCell component="th" scope="row">Interesado</TableCell>
          <TableCell align="left">{`${primer_nombre} ${primer_apellido} ${segundo_apellido}`}</TableCell>
        </TableRow>
        <TableRow key={2}>
          <TableCell component="th" scope="row">Programa</TableCell>
          <TableCell align="left">{programa.nombre}</TableCell>
        </TableRow>
        <TableRow key={3}>
          <TableCell component="th" scope="row">Asignatura</TableCell>
          <TableCell align="left">{asignatura}</TableCell>
        </TableRow>
        <TableRow key={5}>
          <TableCell component="th" scope="row">Codigo asignatura</TableCell>
          <TableCell align="left">{codigo_asignatura}</TableCell>
        </TableRow>
        <TableRow key={4}>
          <TableCell component="th" scope="row">Estado Actual</TableCell>
          <TableCell align="left">{estado_actual.nombre}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export function DetalleSolicitud({ datos, gestor }){
  let {
    asignatura,
    programa,
    fecha_registro,
    interesado: { primer_nombre, primer_apellido, segundo_apellido, identificacion, correo }
  } = datos;
  let fecha = moment(fecha_registro).format('YYYY-MM-DD, h:mm a');
  return (
    <Table>
      <TableBody>
        <TableRow key={1}>
          <TableCell component="th" scope="row">Solicitante</TableCell>
          <TableCell align="left">{primer_nombre} {primer_apellido} {segundo_apellido}</TableCell>
        </TableRow>
        <TableRow key={2}>
          <TableCell component="th" scope="row">Identificacion</TableCell>
          <TableCell align="left">{identificacion}</TableCell>
        </TableRow>
        <TableRow key={3}>
          <TableCell component="th" scope="row">Correo</TableCell>
          <TableCell align="left">{correo}</TableCell>
        </TableRow>
        <TableRow key={4}>
          <TableCell component="th" scope="row">Programa</TableCell>
          <TableCell align="left">{programa.nombre}</TableCell>
        </TableRow>
        <TableRow key={5}>
          <TableCell component="th" scope="row">Asignatura</TableCell>
          <TableCell align="left">{asignatura}</TableCell>
        </TableRow>
        <TableRow key={13}>
          <TableCell component="th" scope="row">Fecha Registro</TableCell>
          <TableCell align="left">{fecha}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}