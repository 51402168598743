
//CORE
import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
//COMPONENTS
import AccountBalanceRoundedIcon from '@material-ui/icons/AccountBalanceRounded';
import AllInboxRoundedIcon from '@material-ui/icons/AllInboxRounded';
import MenuBookRoundedIcon from '@material-ui/icons/MenuBookRounded';
import InsertInvitationRoundedIcon from '@material-ui/icons/InsertInvitationRounded';
import CreditCardRoundedIcon from '@material-ui/icons/CreditCardRounded';
import TrafficRoundedIcon from '@material-ui/icons/TrafficRounded';
import LibraryBooksRoundedIcon from '@material-ui/icons/LibraryBooksRounded';
import SettingsIcon from '@material-ui/icons/Settings';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import { Container,InfoBadge, Banner } from './ui';
import InfoBadgeConfi from './ui/InfoBagdeconfi';
import InfoBadgePromedio from './ui/InfoBadgePromedio';
import ListarHorario from './ListarHorario';
import Semaforo from './Semaforo';
import ListarNotas from './ListarNotas';
import ListarSyllabus from "./ListarSyllabus";
import ListarRubrica from "./ListarRubrica";
import ListarModalidades from './ListarModalidades';
// import ListarPlanEstudio from './ListarPlanEstudio'
import SetupPrograma from './SetupPrograma';
//FUNCTIONS
import { consulta, validarUsuarioApp, obtenerValoresFiltros, coloresEmma } from '../../global/js/funciones';
import { mostrarModulo } from '../../redux/actions/actGlobal';


class Consultas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cargando: true,
            resumen: {},
            rubrica: {},
            modalRubrica: false,
            dialogHorario: false,
            dialogSemaforo: false,
            dialogNotas: false,
            dialogPlan: false,
            dialogSyllabus: false,
            setupPrograma: false,
            horario: [],
            syllabus: [],
            semaforo: [],
            age: "",
            current_user: {},
            programas: [],
            programa: '',
            loading: false,
            dataModal: '',
            dataPlan: {},
            statePlan: 0,
            indice:1,
            modalidades: [],
        };

        this.toggleDialog = this.toggleDialog.bind(this)
        this.obtenerPlan = this.obtenerPlan.bind(this)
        this.obtenerSemaforo = this.obtenerSemaforo.bind(this)
        this.obtenerSyllabus = this.obtenerSyllabus.bind(this)
        this.obtenerHorario = this.obtenerHorario.bind(this)
        this.obtenerNotas = this.obtenerNotas.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    async componentDidMount() {
        let { mostrarModulo } = this.props;
        mostrarModulo({ ruta: '/consultas', nombre: 'consultas' });
        let validarUsuario = await validarUsuarioApp();
        if (validarUsuario.estado === 200) this.setState({ current_user: validarUsuario.resp })
        await this.obtenerProgramas();
        await this.obtenerInfo();
    }

    obtenerInfo() {
        const { programa } = this.state;
        this.setState({ loading: true })
        return new Promise(resolve => {
            consulta(`api/v1.0/consultas/obtenerInfo`, { programa: programa.codigo }, "post", (error, estado, resp) => {
                this.setState({ resumen: resp })
                this.setState({ loading: false })
                resolve(true);
            })
        })
    }
    obtenerHorario() {
        this.setState({ loading: true })
        return new Promise(resolve => {
            consulta(`api/v1.0/consultas/horario`, null, null, (error, estado, resp) => {
                this.setState({ horario: resp.horario })
                this.toggleDialog({ modal: 'dialogHorario', value: true })
                this.setState({ loading: false })
                resolve(true)
            })
        })
    }

    obtenerSemaforo() {
        this.setState({ loading: true })
        return new Promise(resolve => {
            consulta(`api/v1.0/consultas/semaforo`, null, null, (error, estado, resp) => {
                this.setState({ semaforo: resp.semaforo })
                this.toggleDialog({ modal: 'dialogSemaforo', value: true })
                this.setState({ loading: false })
                resolve(true)
            })
        })
    }

    async obtenerNotas() {
        this.setState({ loading: true })
        return new Promise(resolve => {
            consulta(`api/v1.0/consultas/modalidades`, null, null, (error, estado, resp) => {
                this.setState({ modalidades: resp.modalidades })
                this.toggleDialog({ modal: 'dialogNotas', value: true })
                this.setState({ loading: false })
                resolve(true)
            })
        })  
    }
    //trabajando
    obtenerSyllabus() {
        this.setState({ loading: true })
        return new Promise(resolve => {
            consulta(`api/v1.0/consultas/syllabus`, null, null, (error, estado, resp) => {
                this.setState({ Syllabus: " " })
                this.toggleDialog({ modal: 'dialogSyllabus', value: true })
                this.setState({ loading: false })
                resolve(true)
            })
        })
    }

    obtenerProgramas() {
        const { current_user } = this.state

        return new Promise(resolve => {
            consulta(`api/v1.0/personas/${current_user.id}/genericas/${3}`, null, null, (error, estado, resp) => {
                let programas = resp.map(item => item.relacion);
                this.setState({ programas })
                this.setState({ programa: programas[0] })
                resolve(true)
            })
        })
    }

    obtenerPlan() {
        this.setState({ loading: true })
        return new Promise(resolve => {
            consulta(`api/v1.0/consultas/horario`, null, null, (error, estado, resp) => {
                this.setState({ horario: resp.horario })
                this.toggleDialog({ modal: 'dialogPlan', value: true })
                this.setState({ loading: false })
                resolve(true)
            })
        })
    }
    // toggleDialog(toggle) {
    //     let { modal, value } = toggle
    //     this.setState({ [modal]: value })
    // }

    toggleDialog(toggle) {
        let { modal, value, data } = toggle
        // console.log(data)
        this.setState({ [modal]: value, dataModal: data })
    }


    async handleChange({ target }) {
        const { programas } = this.state;
        const newPrograma = await programas.find(({ codigo }) => codigo === target.value)
        await this.setState({ programa: newPrograma })
        this.obtenerInfo();
    }

    render() {
    const { resumen, dialogSemaforo, semaforo, current_user, programas, programa, dialogNotas, loading, dialogHorario, horario, setupPrograma, modalidades, dialogSyllabus, syllabus, dataModal, notas, dialogPlan, indice } = this.state;

    return (
        <Container loading={loading}>
            <div style={{ marginTop: '70px', width: '70%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {/* Banner y ProgressCircle */}
                <div style={{ marginBottom: '20px', width: '120%', textAlign: 'center', marginRight: '120px' }}>
                    <Banner nombre={current_user.primer_nombre} programa={programa.nombre} percent={resumen.porcentaje} loading={loading} />
                    {/*<ProgressCircle percent={resumen.porcentaje} text="TU PROGRESO" color="#7c4dff" loading={loading} />*/}
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px', flexWrap: 'wrap' }}>
                    {/* Botón Horario */}
                    <div style={{ backgroundColor: coloresEmma.primarycolor, borderRadius: '15px', minWidth: '200px', height: '65px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '15px', margin: '10px', cursor: 'pointer' }} onClick={this.obtenerHorario}>
                        <InsertInvitationRoundedIcon style={{ marginLeft: '10px', fontSize: '60px', color: coloresEmma.colorblanco }} />
                        <span style={{ color: coloresEmma.colorblanco, marginTop: '5px', fontWeight: 'bold', marginRight: '20px',  fontSize: '20px' }}>Horario</span>
                    </div>

                    {/* Botón Semaforo */}
                    <div style={{ backgroundColor: coloresEmma.primarycolor, borderRadius: '15px', minWidth: '200px', height: '65px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '15px', margin: '10px', cursor: 'pointer' }} onClick={this.obtenerSemaforo}>
                        <TrafficRoundedIcon style={{ marginLeft: '10px', fontSize: '60px', color: coloresEmma.colorblanco }} />
                        <span style={{ color: coloresEmma.colorblanco, marginTop: '5px', fontWeight: 'bold', marginRight: '20px',  fontSize: '20px' }}>Semaforo</span>
                    </div>

                    {/* Botón Notas */}
                    <div style={{ backgroundColor: coloresEmma.primarycolor, borderRadius: '15px', minWidth: '200px', height: '65px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '15px', margin: '10px', cursor: 'pointer' }} onClick={this.obtenerNotas}>
                        <LibraryBooksRoundedIcon style={{ marginLeft: '10px', fontSize: '60px', color: coloresEmma.colorblanco }} />
                        <span style={{ color: coloresEmma.colorblanco, marginTop: '5px', fontWeight: 'bold', marginRight: '20px',  fontSize: '20px' }}>Notas</span>
                    </div>
                </div>

                {/* Tarjetas de promedio y resumen */}
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '70px', gap: '10px', width: '100%', boxSizing: 'border-box', padding: '0 20px', flexWrap: 'wrap'}}>
                <div style={{ backgroundColor: '#F2F8F8', maxWidth: '300px', borderRadius: '20px', padding: '10px', margin: '20px', flex: '1 1 300px', fontSize: '18px' }}>
                        <InfoBadgePromedio
                            title='Promedio Acumulado'
                            text={resumen.promedio_acumulado}
                            Icon={AllInboxRoundedIcon}
                            color={coloresEmma.primarycolor}
                            iconColor={coloresEmma.colorblanco}
                        />
                    </div>
                    <div style={{ backgroundColor: '#F2F8F8', maxWidth: '300px', borderRadius: '20px', padding: '10px', margin: '20px', flex: '1 1 300px', fontSize: '18px' }}>
                        <InfoBadge
                            title='Promedio Nivel'
                            text={resumen.promedio_nivel}
                            color={coloresEmma.primarycolor}
                            iconColor={coloresEmma.colorblanco}
                        />
                        <InfoBadge
                            title="Semestre"
                            text={resumen.semestre_creditos ? (resumen.semestre_creditos.length !== 0 ? resumen.semestre_creditos[2] : 0) : null}
                            color={coloresEmma.primarycolor}
                            iconColor={coloresEmma.colorblanco}
                        />
                        <InfoBadge
                            title="Créditos Matriculados"
                            text={resumen.semestre_creditos ? (resumen.semestre_creditos.length !== 0 ? resumen.semestre_creditos[3] : 0) : null}
                            color={coloresEmma.primarycolor}
                            iconColor={coloresEmma.colorblanco}
                        />
                    </div>
                    <div style={{ backgroundColor: '#F2F8F8', maxWidth: '300px', borderRadius: '20px', padding: '10px', margin: '20px', flex: '1 1 300px', fontSize: '18px' }}>
                        <h3 style={{ color: '#62727b', marginLeft: '5px' }}>Preferencias</h3>
                        <InfoBadgeConfi
                            title="Programa"
                            text="Haz click aquí para cambiar el programa"
                            Icon={SettingsIcon}
                            color="#b0bec5"
                            iconColor="#ffffff"
                            functional={true}
                            action={() => this.toggleDialog({ modal: 'setupPrograma', value: true })}
                        />
                    </div>
                </div>
            </div>

            {/* Renderización de componentes modales */}
            <Semaforo status={dialogSemaforo} toggle={this.toggleDialog} data={semaforo} />
            <ListarModalidades status={dialogNotas} toggle={this.toggleDialog} data={modalidades} current_user={current_user}/>
            <SetupPrograma status={setupPrograma} toggle={this.toggleDialog} programas={programas} programa={programa.codigo} handleChange={this.handleChange} />
            <ListarHorario setModalRubrica={(modalRubrica)=>this.setState({modalRubrica})} status={dialogHorario} toggle={this.toggleDialog} data={horario} setDataPlan={(dataPlan) => this.setState({ dataPlan, statePlan: this.state.statePlan + 1 })} setIndice={(indice)=>this.setState({indice})} />
            <ListarSyllabus statePlan={this.state.statePlan} dataPlan={this.state.dataPlan} status={dialogSyllabus} toggle={this.toggleDialog} data={syllabus} Modal={dataModal} />
            <ListarRubrica statePlan={this.state.statePlan} dataPlan={this.state.dataPlan} setModalRubrica={(modalRubrica)=>this.setState({modalRubrica})} modalRubrica={this.state.modalRubrica} indice={indice}/>
        </Container>
        )
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = {
    mostrarModulo,
};

//Props del componente
Consultas.propTypes = {
    mostrarModulo: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Consultas);
