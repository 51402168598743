import React, { Component } from 'react'
import { consulta, rutaImg } from "../../../global/js/funciones";
import ListarDatos from '../../general/ListarDatos';
import AppBarModal from "./../../general/AppBarModal";
import { BtnDetalle, BtnPermiso, BtnForm } from '../../general/BotonesAccion'
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import Generica from "./../../personas/generica/Generica";
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from '../../../global/imagenes/emma_w.png';
import {TIPOS_GRADOS,ESTADOS_GRADOS} from "../helper"


class GradosPermisos2 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cargando: true,
      personas: [],
      generica: {
        tipo: 0,
        id: 0,
      },
      modal: false,
      id_persona: null,
    }
  }

  async componentDidMount() {
    let personas = await this.obtenerGestores();
    this.setState({ personas, cargando: false })
  }

  async obtenerGestores() {
    return new Promise(resolve => {
      consulta(`api/v1.0/grados/gestores`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  acciones(data) {
    const onClickTipo = (data) => this.setState({ id_persona: data.id, modal: true, generica: { tipo: 38, id: TIPOS_GRADOS } });
    const onClickEstado = (data) => this.setState({ id_persona: data.id, modal: true, generica: { tipo: 35, id: ESTADOS_GRADOS } });
    const onClickPrograma = (data) => this.setState({ id_persona: data.id, modal: true, generica: { tipo: 34, id: 5 } });
    let tipos = <BtnDetalle callback={() => onClickTipo(data)} texto='Tipos' color='#2196f3' />;
    let estados = <BtnPermiso callback={() => onClickEstado(data)} texto='Estados' />;
    let programa = <BtnPermiso callback={() => onClickPrograma(data)} texto='Programa' color='#2196f3'/>;
    return <div>{programa}{tipos} {estados}</div>
  }

  render(){
    let { modal, id_persona, generica, cargando, personas } = this.state;
    if (cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />;
    return (
      <div>
      <ListarDatos
        id="tbl_becas_permisos"
        titulo='Lista Gestores'
        descargar="EMMA - GESTORES GRADOS"
        datos={personas}
        opciones={true}
        buscar={true}
        acciones={row => this.acciones(row)}
        avatarImg={true}
        avatar={({ identificacion }) => `${rutaImg}${identificacion}.jpg`}
        fila_principal={row => `${row.primer_nombre} ${row.segundo_nombre} ${row.primer_apellido} ${row.segundo_apellido}`.toUpperCase()}
        filas={[
          { 'mostrar': ({ correo }) => `${correo}`, 'id': 'correo', 'enLista': true },
          { 'mostrar': ({ identificacion }) => `${identificacion}`, 'id': 'identificacion', 'enLista': true }
        ]}
      />


        <Dialog open={modal} fullWidth={true} maxWidth='sm' onClose={() => this.setState({ modal: false })}>
          <AppBarModal titulo='¡ Lista Permisos !' mostrarModal={() => this.setState({ modal: false })} accion={() => this.setState({ modal: false })} titulo_accion='CERRAR' />
          <DialogContent style={{ padding: 0 }} className='scroll'>
            <Generica notificaciones={(generica.tipo == 14)} tipo={generica.tipo} persona={{ id: id_persona }} genericaRelacionId={generica.id} titulo="¡ Nuevo Permiso !" tituloInput="Permisos" tituloEliminar='¿ Eliminar Permiso ?' />
          </DialogContent>
          <DialogActions>
            <BtnForm texto="Cerrar" callback={() => this.setState({ modal: false })} />
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}


export default GradosPermisos2