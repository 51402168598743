//Importaciones necesaria para poder utilizar react
import React, { useState }  from 'react'

//Importaciones necesarias para que el modal del formulario de creacion de semilleros se pueda abrir, este es para que se habra el modal.
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button'
//Importe de los text faild para los formularios de los campos.
import TextField from '@material-ui/core/TextField';
//Importe de los grid para los textfield de los formularios entre otros aspecto.
import Grid from '@material-ui/core/Grid';

import { makeStyles } from '@material-ui/core/styles';

import AppBarModal from '../../general/AppBarModal'

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

//MenuItem
import MenuItem from '@material-ui/core/MenuItem';

//Importo las funciones para hacer peticiones
import * as Funciones from '../../../global/js/funciones';

//Constantes del Helper
import * as Helper from './Helper';

import FormHelperText from '@material-ui/core/FormHelperText';

import TareasTerminadas from '../../general/TareasTerminadas';
import emma_w from '../../../global/imagenes/emma_w.png';

//Modal del agregar Objetivos General del Semillero

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up('xs')]: {
            minWidth: "400px"
          },
      },
      flexGrow: {
          flexGrow: 1,
      },
      formControl: {
        margin: theme.spacing(1),
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      noneScroll:{
        overflowY: 'hidden',
        [theme.breakpoints.up('sm')]: {
          minWidth: "600px"
        },
    }
}));

export default function SemillerosAddGrupos(props) {
    const classes = useStyles();
    const [grupo, setGrupo] = React.useState([]);
    const [cargando, setCargando] = React.useState(true)

    React.useEffect( () => {
        obtenerGrupos()
    }, [])

    const obtenerGrupos = async () => {
        try{
          
          const data = await Funciones.obtenerValores(Helper.SEMI_GRUPOS_INVES)
          setGrupo(data)
          setCargando(false)
        }catch(err){
          console.log(err)
        } 
        
    }

    //Funcion para asginar Departamentos.
    const handleChangeGrupo = (event) => {
        props.setGrupoSelect(event.target.value);
        if(props.errores.length > 0){
            props.setErrores(props.errores.filter(er => er.llave != "grupoInvesModal"));
            // Elimina error cuando se escribe en el campo
        }
    };

    return (
        <div>
            <Dialog style={{minWidth: "100vw"}} open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">

                <AppBarModal titulo='Elija un grupo de Investigación' mostrarModal={props.handleClose} titulo_accion="" accion="" />
                {cargando == false ? 
                <DialogContent className={classes.noneScroll}>
                    <Grid item container>
                        <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">Grupos Investigación</InputLabel>
                        <FormControl className={classes.formControl} fullWidth error = { props.getError('grupoInvesModal', props.errores).length > 0}>
                            <Select
                            value={props.grupoSelect}
                            displayEmpty
                            className={classes.selectEmpty}
                            inputProps={{ 'aria-label': 'Without label' }}
                            onChange={handleChangeGrupo}
                            >
                            <MenuItem value="">
                                <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${grupo.length} Grupos Disponibles` }</em>
                            </MenuItem>
                            {grupo.map((item, index) => (
                            <MenuItem key= { index } value = { item }>{ item.nombre }</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormHelperText>{ props.getError('grupoInvesModal', props.errores) }</FormHelperText>
                        </Grid>
                        
                    </Grid>
                </DialogContent>
                : <TareasTerminadas imagen={emma_w} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' />}
                <DialogActions>
                        <Button variant="contained" color="primary" style={{color: "#fff"}} onClick={props.onClickChangeGrupo}>
                            Agregar
                        </Button>
                </DialogActions>

            </Dialog>

        </div>
    );
}