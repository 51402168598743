import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { BtnForm } from "../general/BotonesAccion";
import AppBarModal from '../general/AppBarModal'
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TareasTerminadas from '../general/TareasTerminadas';
import emma_w from '../../global/imagenes/emma_w.png'


class EleccionesFiltrar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      estados: [],
      estado: "",
      cargando: true,
    }
  }

  async componentDidUpdate({ modalFiltrarElecciones },) {
    let { modalFiltrarElecciones: modalFiltrarEleccionesNew } = this.props;
    if (modalFiltrarElecciones !== modalFiltrarEleccionesNew && modalFiltrarEleccionesNew) {
      let estados = [{id: 1, nombre: 'Activo'}, {id: 2, nombre: 'Cancelado'}, {id: 3, nombre: 'Finalizado'}]
      this.setState({
        estados,
        cargando: false,
      })
    }
    if (modalFiltrarElecciones !== modalFiltrarEleccionesNew && !modalFiltrarEleccionesNew) {
      this.setState({ periodo: null, programa: null, tipo: null, estado: null })
    }
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  changeState = (estados) => {
    this.setState(estados)
  }

  resetState = () => {
    let { limpiar } = this.props;
    this.setState({
      estado: '',
    })
    limpiar();
  }


  onSubmit = e => {
    let { filtrar, actualizarMensaje } = this.props;
    let {  estado } = this.state;

    let filtros = [];
    if (estado) filtros.push({ 'llave': 'estado', 'valor': estado });
    if (filtros.length > 0) filtrar(filtros);
    else actualizarMensaje({ titulo: 'Seleccione Algún filtro', tipo: 'info', mostrar: true, tiempo: 6000 });
    e.preventDefault();
  }

  render() {
    let { modalFiltrarElecciones, mostrarModalFiltrarEleccion } = this.props;
    let { cargando, estado, estados } = this.state;

    return (
      <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} style={{ width: 480, margin: "auto" }} open={modalFiltrarElecciones} onClose={() => mostrarModalFiltrarEleccion(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <AppBarModal titulo='¡ Aplicar Filtros !' mostrarModal={mostrarModalFiltrarEleccion} titulo_accion="Limpiar" accion={this.resetState} />
        <DialogContent className='scroll'>
          {!cargando ?
            <Grid container  className='margin_cont' >
              <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                <FormControl className='form-control'>
                  <InputLabel>Seleccione Estado</InputLabel>
                  <Select
                    inputProps={{
                      name: 'estado',
                      id: 'estado',
                    }}
                    value={estado}
                    onChange={this.onChange}>
                    {
                      estados.map(({ id, nombre }) => <MenuItem key={id} value={id}>{nombre}</MenuItem>)
                    }
                  </Select>
                </FormControl>
              </Grid>
              
            </Grid> :
            <TareasTerminadas mensaje="Cargando" imagen={emma_w} />
          }
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CANCELAR" callback={() => mostrarModalFiltrarEleccion(false)} />
          <BtnForm texto="FILTRAR" callback={this.onSubmit} />
        </DialogActions>
      </Dialog >
    )
  }
}

//Props del componente
EleccionesFiltrar.propTypes = {
  //variables
  modalFiltrarElecciones: PropTypes.bool.isRequired,
  //funciones
  mostrarModalFiltrarEleccion: PropTypes.func.isRequired,
  filtrar: PropTypes.func.isRequired,
  limpiar: PropTypes.func.isRequired,
}
export default EleccionesFiltrar;