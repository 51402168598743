import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AppBarModal from '../../general/AppBarModal';
import { TableRow, TableCell, TableBody, Table,Dialog, DialogContent, Grid, Paper} from '@material-ui/core';
import moment from 'moment'
import TareasTerminadas from '../../general/TareasTerminadas'
import emma_w from '../../../global/imagenes/emma_w.png';
import { ReaMenu } from "./ReaMenu";
import { consulta, obtenerValoresFiltros} from "../../../global/js/funciones";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {VALORES_PREGUNTAS } from './helper';
import AccionesRapidas from '../../general/AccionesRapidas'
import revisar from '../../../global/imagenes/revisar.png';
import patentar from '../../../global/imagenes/patentar.png';
import accept from '../../../global/imagenes/accept.png';
import habla from '../../../global/imagenes/habla.png';
import AlertasSimple from '../../general/AlertasSimple';


class DetalleRea extends Component {
 
  constructor(props) {
    super(props)
    this.state = {
      cargando: true,
      seleccion: 'detalle',
      resultadosEstudiante:[],
      valoresPreguntas: [],
      respuestasEst:[],
      resultadoEstadistica:[]
    }
  }

  
  async  componentDidUpdate({modalDetalleRea },{ seleccion }) {
    let { modalDetalleRea: modalDetalleReaNew, cargar } = this.props;
    if (modalDetalleRea !== modalDetalleReaNew && cargar) {
      this.setState({ cargando: true })
      let valoresPreguntas = await obtenerValoresFiltros([{"llave" : 'generica', 'valor' : VALORES_PREGUNTAS}]);
      let resultadosEstudiante = await this.obtenerResultadoEncuestas();
      let resultadoEstadistica = await this.obtenerResultadoEstadistica();
      this.setState({valoresPreguntas, resultadosEstudiante, resultadoEstadistica, cargando: false, cargar: false })
      this.obtenerRespuestasEstudiante()
      let { seleccion: seleccionNew } = this.state;
      if (seleccion !== seleccionNew) {
        if (seleccionNew === 'detalle') this.pintarDetalleEstudiante();
        else if (seleccionNew === 'respuesta') this.pintarDetalleRespuesta();
        else if (seleccionNew === 'resultado') this.pintarDetalleResultado();
      }
    } 
  }
  
  cambiarEstado = (estados) => {
    this.setState(estados)
  }
   


 obtenerResultadoEncuestas() {
    let {proceso} = this.props
    let f = proceso.usuario_registro.id
    return new Promise(resolve => {
       consulta(`api/v1.0/rea/detalleEstudiante/${f}?`, null, null, (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  obtenerResultadoEstadistica() {
    let {proceso} = this.props
    let f = proceso.usuario_registro.id
    return new Promise(resolve => {
       consulta(`api/v1.0/rea/detalleEstudiante/estadistica/${f}?`, null, null, (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

 obtenerRespuestasEstudiante() {
    let {  valoresPreguntas, respuestasEst, resultadosEstudiante} = this.state
    let arr=[]
    valoresPreguntas.forEach(function(elemento,j ) {
      resultadosEstudiante.forEach(function(datoRespuesta ) {
        if (datoRespuesta.pregunta.id === elemento.id) {
           arr.push(datoRespuesta)
        }
        
      })
      respuestasEst[j]=arr
      arr=[]
    })  
  this.cambiarEstado(respuestasEst)
  }

  pintarDetalleEstudiante =() =>{
    let {proceso} = this.props
    let {
      estado_actual,
      programa,
      fecha_registro,
      usuario_registro: { primer_nombre, primer_apellido, segundo_apellido, identificacion, correo },
    } = proceso;
    let fecha = moment(fecha_registro).format('YYYY-MM-DD, h:mm a');
    return (
      <Table>
        <TableBody>
          <TableRow key={1}>
            <TableCell component="th" scope="row">Estudiante</TableCell>
            <TableCell align="left">{`${primer_nombre} ${primer_apellido} ${segundo_apellido}`}</TableCell>
          </TableRow>
          <TableRow key={2}>
            <TableCell component="th" scope="row">Identificacion</TableCell>
            <TableCell align="left">{identificacion}</TableCell>
          </TableRow>
          <TableRow key={3}>
            <TableCell component="th" scope="row">Correo</TableCell>
            <TableCell align="left">{correo}</TableCell>
          </TableRow>
          {programa ?
            <TableRow key={4}>
              <TableCell component="th" scope="row">Programa</TableCell>
              <TableCell align="left">{programa.nombre}</TableCell>
            </TableRow>
            : <TableRow key={4}></TableRow>
          }
          {estado_actual ?
          <TableRow key={5}>
            <TableCell component="th" scope="row">Estado Actual</TableCell>
            <TableCell align="left">{estado_actual.nombre}</TableCell>
          </TableRow>
            : <TableRow key={5}></TableRow>
          }
          
          <TableRow key={6}>
            <TableCell component="th" scope="row">Fecha Registro</TableCell>
            <TableCell align="left">{fecha}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
        )
  }

  pintarDetalleResultado = () =>{
    let {resultadoEstadistica} = this.state;
    return(
      <>
       <AlertasSimple
              tipo='success'
              titulo={`Estilo de Aprendizaje Dominante : ${resultadoEstadistica[0].tipo_aprendizaje.nombre} `}
              margin = '0px'
            />
       <Grid item xs={12} sm={12} md={12} >
            <Paper elevation={3} style={{ border: '1px solid #e0e0e0' }} onClick={() => {  }}>
              <AccionesRapidas imagen={accept} titulo={`CONCEPTUALIZACION ABSTRACTA : ${resultadoEstadistica[0].conceptualizacion_abstracta}`} sub_titulo='' des_sub_titulo='' />
            </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} >
            <Paper elevation={3} style={{ border: '1px solid #e0e0e0' }} onClick={() => {  }}>
              <AccionesRapidas imagen={patentar} titulo={`EXPERIENCIA CONCRETA : ${resultadoEstadistica[0].experiencia_concreta}`} sub_titulo='' des_sub_titulo='' />
            </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} >
            <Paper elevation={3} style={{ border: '1px solid #e0e0e0' }} onClick={() => {  }}>
              <AccionesRapidas imagen={revisar} titulo={`EXPERIMENTACION ACTIVA : ${resultadoEstadistica[0].experimentacion_activa}`} sub_titulo='' des_sub_titulo='' />
            </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} >
            <Paper elevation={3} style={{ border: '1px solid #e0e0e0' }} onClick={() => {  }}>
              <AccionesRapidas imagen={habla} titulo={`OBSERVACION REFLEXIVA : ${resultadoEstadistica[0].observacion_reflexiva}`} sub_titulo='' des_sub_titulo='' />
            </Paper>
        </Grid>
      </>
    )
  }

  pintarDetalleRespuesta =() =>{
    let { respuestasEst, valoresPreguntas } = this.state;
  
    return(
     <>
    {valoresPreguntas.map(( relacion, i ) => (
       
         <Accordion key={i}>
         <AccordionSummary
           expandIcon={<ExpandMoreIcon />}
           aria-controls="panel1a-content"
           id="panel1a-header"
         >
           <Typography ><b>Pregunta {i+1}:</b> { relacion.nombre}</Typography>
         </AccordionSummary>
         {respuestasEst[i].map(( dato, j ) => (
         <AccordionDetails key={j}>
           <Typography>
             {respuestasEst[i][j].respuesta.nombre}
           </Typography>
         </AccordionDetails>
         ))
        }
        
       </Accordion>
    ))
    }
    
   
   </>
    )
  }

  pintar = () => {
    let { cargando, seleccion } = this.state;
    if (cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />;
    return (
      <>
        {seleccion === 'detalle' && this.pintarDetalleEstudiante()}
        {seleccion === 'respuesta'  && this.pintarDetalleRespuesta() }
        {seleccion === 'resultado'  && this.pintarDetalleResultado() }
      </>
    )

  }


  render() {
    let { modalDetalleRea, mostrarModalDetalleRea} = this.props
    let { seleccion } = this.state;
    return (
      <>
         
         <Dialog fullWidth={true} maxWidth="sm" open={modalDetalleRea} onClose={() => mostrarModalDetalleRea(false)}>
         <AppBarModal titulo={" ¡ Detalle Encuesta ! "} mostrarModal={() => mostrarModalDetalleRea(false)} titulo_accion="Cerrar" accion={() => mostrarModalDetalleRea(false)}/>
         <ReaMenu sele={seleccion} seleccionar={seleccion => this.setState({ seleccion })} />
         <DialogContent className='scroll'>
            <Grid container>
              <Grid item xs={12}  md={12}> 
                {this.pintar()}
              </Grid>
           </Grid>
             
         </DialogContent>
        
       </Dialog > 
      </>
    )
    }
  
}

DetalleRea.propTypes = {
  actualizarMensaje: PropTypes.func.isRequired,
  modalDetalleRea: PropTypes.bool.isRequired,
}



export default DetalleRea