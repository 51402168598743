import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BtnSeleccion, BtnCancelar } from '../../general/BotonesAccion';
import ListarDatos from '../../general/ListarDatos';
import moment from 'moment';

class ValoresListarPermisos extends Component {
	render() {
		let { permisos = [], eliminarPermiso, guardarPermiso, mostrarModalAddValorPermiso, agregarValor } = this.props;

		const actions = (rowData) => {
			return rowData.permiso !== 0 ? (
				<BtnCancelar callback={() => eliminarPermiso(rowData)} />
			) : (
				<BtnSeleccion callback={() => guardarPermiso(rowData)} />
			);
		};


		return (
			<div>
				<ListarDatos
					id="tbl_listar_permisos"
					titulo={'Lista permisos'} 
					enModal={true}
					agregar={agregarValor}
					actAgregar={() => mostrarModalAddValorPermiso(true)}
					datos={permisos}
					opciones={true}
					buscar={true}
					acciones={(row) => actions(row)}
					sub_titulo={'Lista de permisos'}
					fila_principal={({ nombre }) => nombre.toUpperCase()}
					filas={[
						{
							mostrar: ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a'),
							id: 'fecha_registro'
						},
						{ id: 'descripcion' }
					]}
				/>
			</div>
		);
	}
}

//Props del componente
ValoresListarPermisos.propTypes = {
	//variables
	permisos: PropTypes.array.isRequired
	//funciones
};

export default ValoresListarPermisos;
