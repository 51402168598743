import React, { useState, useEffect } from 'react';
// Material-UI
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Dialog,
    Grid,
    FormControl,
    Input,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    FormHelperText
} from '@material-ui/core/';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import 'date-fns';
// Components
import AppBarModal from './../../general/AppBarModal';
import {
    formulario,
    obtenerValores,
    mostrarError,
    crear_form_data,
} from '../../../global/js/funciones';
import { PasosCrearSolicitud } from './MatriculasMenu';
import {
    DIPLOMADOS,
    FECHASGRADO,
    TIPO_IDENTIFICACION,
    ASPIRANTE,
    OPCION_GRADO2,
    ESTRATOS,
    SEXO,
    EGRESADO,
    TRABAJA,
    ACTIVIDAD_LABORAL,
    RANGO_SALARIAL,
    ACEPTAR_SOLICITUD
} from './helper';
import AlertasSimple from '../../general/AlertasSimple';
import TareasTerminadas from '../../general/TareasTerminadas';
import emma_w from '../../../global/imagenes/emma_w.png';

import { consulta, obtenerValoresFiltros } from "../../../global/js/funciones";


// import CustomDropzone from '../../general/CustomDropzone';
import MatriculasRequisitos from './MatriculasRequisitos';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { es } from 'date-fns/locale'
import moment from "moment";

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1)
    }
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        width: '100%',
        marginTop: 15,
        '& > *': {
            margin: theme.spacing(0.5)
        }
    },
    alert: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2)
        }
    },
    list: {
        marginTop: 20,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300
    },
    backButton: {
        marginRight: theme.spacing(1)
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    inline: {
        display: 'inline'
    }
}));

var primer_nombre = ''
var primer_apellido = ''
var segundo_nombre = ''
var segundo_apellido = ''

var numero_documento = ''
var ciudad_expedicion = ''
var periodo = ''
var trabajo_grado = ''
var telefono_principal = ''
var telefono_alterno = ''
var correo_personal = ''
var direccion_residencia = ''
var ciudad_residencia = ''
var pais = ''
var ano_graduo = ''
var institucion_educativa = ''
var nombre_programa = ''
var empresa_labora = ''
var direccion = ''
var cargo_desempena = ''
var correo_empresarial = ''
var telefono_empresarial = ''

export default function ModalCrearSolicitud({ open, setOpen, tipo, misDatos, actualizarMensaje, modalAdjuntarVolante, mostrarAdjuntarVolante }) {

    const [fechasGrado, setFechasGrado] = useState([]);
    const [fechaGrado, setFechaGrado] = useState('');
    const [tiposAspirante, setTiposAspirante] = useState([]);
    const [aspirante, setAspirante] = useState('');
    const [programa, setPrograma] = useState('');
    const [tiposIdentificacion, setTiposIdentificacion] = useState([]);
    const [tipoIdentificacion, setTipoIdentificacion] = useState('');
    const [autorizo, setAutorizo] = useState('');

    const [diplomados, setDiplomados] = useState([]);
    const [opcionesGradoTipo2, setOpcionesGradoTipo2] = useState([]);
    const [opcionGrado1, setOpcionGrado1] = useState('');
    const [opcionGrado2, setOpcionGrado2] = useState('');

    const [estratos, setEstratos] = useState([]);
    const [estrato, setEstrato] = useState('');
    const [sexos, setSexos] = useState([]);
    const [sexo, setSexo] = useState('');

    const [egresados, setEgresados] = useState([]);
    const [egresado, setEgresado] = useState('');

    const [opcionesTrabaja, setOpcionesTrabaja] = useState([]);
    const [trabaja, setTrabaja] = useState('');

    const [actividadesLaborales, setActividadesLaborales] = useState([]);
    const [actividadLaboral, setActividadLaboral] = useState('');

    const [rangosSalariales, setRangosSalariales] = useState([]);
    const [rango, setRango] = useState('');

    const [aceptarSolicitud, setAceptarSolicitud] = useState([]);
    const [acepto, setAcepto] = useState('');
    
    var [archivos, setArchivos] = useState([]);
    var [archivos2, setArchivos2] = useState([]);

    const [activeStep, setActiveStep] = useState(0);
    const [cargando, setCargando] = useState(false);
    const [cargandoText, setCargandoText] = useState('Cargando..');
    const [estadoAdjuntos, setTerminarAdjuntos] = useState(false);
    const [showInput_programa, setshowInput_programa] = useState(false);
    const [showInputTrabaja, setshowInputTrabaja] = useState(false);
    const [fechaNacimiento, setFechaNacimiento] = useState('');
    const [fechaExpedicion, setFechaExpedicion] = useState(null);
    const [listaProgramas, setProgramas] = useState([]);

    const [validarEncuesta, setValidarEncuesta] = useState(false);

    useEffect(() => {
        if (open) {
            setCargando(true);
            setCargandoText('Cargando ..');

            async function fechasgradosEstudiantes() {
                return new Promise((resolve) => {
                    consulta(`api/v1.0/matriculas/fechasgrados_estudiantes`, null, null, (error, estado, resp) => {
                        resolve(estado === 200 && !error ? resp : null);
                    });
                });
            }
            async function fetchData() {
                await fechasgradosEstudiantes().then(data => setFechasGrado(data))
            }
            obtenerValores(TIPO_IDENTIFICACION).then(data => setTiposIdentificacion(data));
            obtenerValores(ASPIRANTE).then(data => setTiposAspirante(data));
            obtenerValores(DIPLOMADOS).then(data => setDiplomados(data));
            obtenerValores(OPCION_GRADO2).then(data => setOpcionesGradoTipo2(data));
            obtenerValores(ESTRATOS).then(data => setEstratos(data));
            obtenerValores(SEXO).then(data => setSexos(data));
            obtenerValores(EGRESADO).then(data => setEgresados(data));
            obtenerValores(TRABAJA).then(data => setOpcionesTrabaja(data));
            obtenerValores(ACTIVIDAD_LABORAL).then(data => setActividadesLaborales(data));
            obtenerValores(RANGO_SALARIAL).then(data => setRangosSalariales(data));
            obtenerValores(ACEPTAR_SOLICITUD).then(data => setAceptarSolicitud(data));
            fetchData();

            if (misDatos.persona && primer_nombre == '') {
                primer_nombre = `${misDatos.persona.primer_nombre}`;
                segundo_nombre = `${misDatos.persona.segundo_nombre}`;
                primer_apellido = `${misDatos.persona.primer_apellido}`;
                segundo_apellido = `${misDatos.persona.segundo_apellido}`;
                numero_documento = misDatos.persona.identificacion;
                telefono_principal = misDatos.persona.celular;
                telefono_alterno = misDatos.persona.telefono;
                correo_personal = misDatos.persona.correo_personal;
                setTipoIdentificacion(misDatos.persona.tipo_identificacion)
            }

            if(programa){
                validarEncuestaGraduados().then(data=>setValidarEncuesta(data.completado ? true : false));
            }

            setCargando(false);
        }
    }, [open, misDatos])


    const steps = ['Datos personales', 'Programa', 'Adicional', 'Laboral', 'Documentos'];
    const classes = useStyles();


    const handleNext = async () => {
        let sw = true;

        if (activeStep == 0) {
            sw = validar_rsg()
        } else if (activeStep == 1) {
            sw = validar_programa()
            if (!sw) {
                const resp = await validarEncuestaGraduados()
                setValidarEncuesta(resp?.completado ? true : false)
            }
        } else if (activeStep == 2) {
            sw = validar_adicional()
        } else if (activeStep == 3) {
            sw = validar_laboral()
        } else if (activeStep == 4) {
            sw = validar_adjunto()
        }

        if (!sw) {
            if (activeStep == steps.length - 1) {
                guardar_solicitud()
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        }
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    async function validarEncuestaGraduados() {
        return new Promise((resolve) => {
            consulta(`api/v1.0/matriculas/validar_encuesta_graduados/${programa}`, null, null, (error, estado, resp) => {
                resolve(resp);
            });
        });
    }

            

    
    async function obtenerPrograma(tipo) {
        return new Promise((resolve) => {
            consulta(`api/v1.0/matriculas/obtenerPrograma/${tipo}`, null, 'get', (error, estado, resp) => {
                resolve(estado === 200 && !error ? resp : null);
            });
        });
    }

    const validar_rsg = () => {
        let mensaje = ''

        if (!fechaGrado) mensaje = 'Por favor completar el campo Fecha de Grado'
        else if (!aspirante) mensaje = 'Por favor completar el campo Aspirante'
        else if (!(primer_nombre).trim()) mensaje = 'Por favor completar el campo Primer Nombre'
        else if (!(primer_apellido).trim()) mensaje = 'Por favor completar el campo Primer Apellido'
        else if (!(fechaExpedicion)) mensaje = 'Por favor completar el campo Fecha Expedición'
        else if (!tipoIdentificacion) mensaje = 'Por favor completar el campo Tipo Identificacion'
        else if (!numero_documento) mensaje = 'Por favor completar el campo Numero Documento'
        else if (!(ciudad_expedicion.trim())) mensaje = 'Por favor completar el campo Ciudad Expedicion'
        else if (!autorizo) mensaje = 'Por favor completar el campo Autorizo'

        if (mensaje) {
            actualizarMensaje({
                titulo: mensaje,
                tipo: "info",
                mostrar: true,
                tiempo: 6000
            });
            return true
        } else {
            return false;
        }
    }

    const cambiarAspirante = async (e) => {
        let programa = tiposAspirante.filter((d) => d.id == e.target.value )[0]
        setAspirante(e.target.value); 
        // let programas = await obtenerPrograma(programa.nombre);
        await obtenerPrograma(programa.nombre).then(data=>setProgramas(data.sort((a,b) => a.nombre.localeCompare(b.nombre))));
    }

    const RSG = () => {
        return (
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Fecha de Grado *</InputLabel>
                        <Select
                            value={fechaGrado}
                            onChange={(e) => setFechaGrado(e.target.value)}
                        >
                            {   fechasGrado.length > 0 ? 
                                    fechasGrado.map((fg) => (
                                        <MenuItem key={fg.id} value={fg.id}> {fg.nombre} </MenuItem>
                                    ))
                                : 
                                (
                                    <MenuItem disabled value={0}>
                                    No hay fechas disponibles
                                    </MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Aspirante a Grado de *</InputLabel>
                        <Select
                            value={aspirante}
                            onChange={(e) => {cambiarAspirante(e)}}
                        >
                            {
                                tiposAspirante.map((a) => (
                                    <MenuItem key={a.id} value={a.id}> {a.nombre} </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl className='form-control'>
                        <InputLabel>Primer Nombre *</InputLabel>
                        <Input
                            defaultValue={primer_nombre}
                            onChange={(e) => { primer_nombre = e.target.value }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl className='form-control'>
                        <InputLabel>Segundo Nombre</InputLabel>
                        <Input
                            defaultValue={segundo_nombre}
                            onChange={(e) => { segundo_nombre = e.target.value }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl className='form-control'>
                        <InputLabel>Primer Apellido *</InputLabel>
                        <Input
                            defaultValue={primer_apellido}
                            onChange={(e) => primer_apellido = e.target.value}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl className='form-control'>
                        <InputLabel>Segundo Apellido</InputLabel>
                        <Input
                            defaultValue={segundo_apellido}
                            onChange={(e) => segundo_apellido = e.target.value}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Tipo de documento *</InputLabel>
                        <Select
                            value={tipoIdentificacion}
                            onChange={(e) => setTipoIdentificacion(e.target.value)}
                        >
                            {
                                tiposIdentificacion.map((ti) => (
                                    <MenuItem key={ti.id} value={ti.id}> {ti.nombre} </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Número de Documento de Identidad *</InputLabel>
                        <Input
                            type="number"
                            defaultValue={numero_documento}
                            onChange={(e) => numero_documento = e.target.value}
                            disabled="true"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils} >
                            <Grid container>
                                <KeyboardDatePicker
                                    required={true}
                                    style={{ marginTop: 3, width: '100%' }}
                                    margin="normal"
                                    label="Fecha de Expedición"
                                    format="yyyy/MM/dd"
                                    value={fechaExpedicion}
                                    onChange={setFechaExpedicion}
                                    KeyboardButtonProps={{
                                        'aria-label': 'Cambiar fecha',
                                    }}
                                    maxDate={Date("yyyy/MM/dd")}
                                    maxDateMessage={`La fecha debe ser menor que la fecha actual.`}
                                    invalidDateMessage='Formato Invalido'
                                    cancelLabel="Cancelar"
                                    okLabel="Aceptar"
                                    todayLabel="Hoy"
                                    clearLabel="Limpiar"
                                    dayText="Día"
                                    monthText="Mes"
                                    yearText="Año"
                                    formatDay="EE"
                                    formatMonth="MMMM"
                                    formatYear="yyyy"
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Ciudad de Expedición del Documento de Identidad *</InputLabel>
                        <Input
                            defaultValue={ciudad_expedicion}
                            onChange={(e) => ciudad_expedicion = e.target.value}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl className='form-control'>
                        <InputLabel>Autorizo *</InputLabel>
                        <Select
                            value={autorizo}
                            onChange={(e) => setAutorizo(e.target.value)}
                        >
                            <MenuItem key={1} value={1}> SI </MenuItem>
                            <MenuItem key={2} value={2}> NO </MenuItem>
                        </Select>
                        <FormHelperText>
                            En cumplimiento de la Ley 1581 de 2012 y sus decretos reglamentarios en calidad
                            de titular(es) de la información de manera libre, expresa e informada, autorizo
                            a CORPORACIÓN UNIVERSIDAD DE LA COSTA - CUC  y/o  a la persona natural o jurídica
                            a quién este encargue, a recolectar, almacenar, utilizar, circular, suprimir y
                            en general, a usar mis datos personales para el cumplimiento de las siguientes
                            finalidades: (i) Gestión de PQR, (ii) publicidad y prospección comercial, (iii)
                            Enseñanza universitaria o superior. Declaro que he conocido la Política de
                            tratamiento de datos personales publicada en www.cuc.edu.co
                        </FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
        );
    }

    const validar_programa = () => {
        let mensaje = ''
        let cod_opcionGrado2 = '';

        opcionesGradoTipo2.map(({ id, codigo }) => {
            if (id == opcionGrado2) cod_opcionGrado2 = codigo;
        })

        if (!programa) mensaje = 'Por favor completar el campo Programa'
        else if (!(periodo.trim())) mensaje = 'Por favor completar el campo Periodo'
        else if (!opcionGrado1) mensaje = 'Por favor completar el campo Opcion de Grado Tipo 1'
        else if (!opcionGrado2) mensaje = 'Por favor completar el campo Opcion de Grado Tipo 2'
        else if (cod_opcionGrado2 == 'Mat_Opc_Tra_Gra_Otro' && !(trabajo_grado.trim())) mensaje = 'Por favor completar el campo Trabajo de Grado'

        if (mensaje) {
            actualizarMensaje({
                titulo: mensaje,
                tipo: "info",
                mostrar: true,
                tiempo: 6000
            });
            return true
        } else {
            return false;
        }
    }

    const Programa = () => {
        return (
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Nombre del programa a graduar *</InputLabel>
                        <Select
                            value={programa}
                            onChange={(e) => setPrograma(e.target.value)}
                        >
                            {
                                listaProgramas.map((fg) => (
                                    <MenuItem key={fg.id} value={fg.id}> {fg.nombre} </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Periodo en que terminó académicamente *</InputLabel>
                        <Input
                            defaultValue={periodo}
                            onChange={(e) => periodo = e.target.value}
                        />
                        <FormHelperText>
                            Ejemplo: 2017-2
                        </FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Nombre de la Opción de Grado Tipo 1 *</InputLabel>
                        <Select
                            value={opcionGrado1}
                            onChange={(e) => setOpcionGrado1(e.target.value)}
                        >
                            {
                                diplomados.map((d) => (
                                    <MenuItem key={d.id} value={d.id}> {d.nombre} </MenuItem>
                                ))
                            }
                        </Select>
                        <FormHelperText>
                            Seleccione el Diplomado Cursado
                        </FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Nombre de la Opción de Grado Tipo 2 *</InputLabel>
                        <Select
                            value={opcionGrado2}
                            onChange={(e) => setOpcionGrado2(e.target.value)}
                        >
                            {
                                opcionesGradoTipo2.map((og) => (
                                    <MenuItem key={og.id} value={og.id}> {og.nombre} </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl className='form-control'>
                        <InputLabel>Nombre opción de Grado tipo 2</InputLabel>
                        <Input
                            defaultValue={trabajo_grado}
                            onChange={(e) => trabajo_grado = e.target.value}
                        />
                        <FormHelperText>
                            Ingrese el nombre de su Opción de grado tipo 2.
                        </FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
        )
    }

    const validar_adicional = () => {
        let mensaje = ''
        let cod_egresado = '';
        egresados.map(({ id, codigo }) => {
            if (id == egresado){
                 cod_egresado = codigo;
            }
        })
        let resp = egresado ? egresados.find(({ id }) => egresado === id) : '';

        if (!(telefono_principal.toString().trim())) mensaje = 'Por favor completar el campo Telefono Principal'
        else if (!(telefono_alterno.toString().trim())) mensaje = 'Por favor completar el Telefono Alterno'
        else if (!(correo_personal.trim())) mensaje = 'Por favor completar el campo Correo Personal'
        else if (!(direccion_residencia.trim())) mensaje = 'Por favor completar el campo Direccion Residencia'
        else if (!(ciudad_residencia.trim())) mensaje = 'Por favor completar el campo Ciudad Residencia'
        else if (!(pais.trim())) mensaje = 'Por favor completar el campo Pais'
        else if (!estrato) mensaje = 'Por favor completar el campo Estrato'
        else if (!fechaNacimiento) mensaje = 'Por favor completar el campo Fecha Nacimiento'
        else if (!sexo) mensaje = 'Por favor completar el campo Sexo'
        else if (!egresado) mensaje = 'Por favor completar el campo Egresado'
        else if (resp.codigo !== "Egr_No_Apl" || !cod_egresado){
            if(!(nombre_programa.trim())) mensaje = 'Por favor completar el campo Nombre Programa'
            else if(!(ano_graduo.trim())) mensaje = 'Por favor completar el campo Año Graduo'
            else if(!(institucion_educativa.trim())) mensaje = 'Por favor completar el campo Institucion Educativa'
        }

        if (mensaje) {
            actualizarMensaje({
                titulo: mensaje,
                tipo: "info",
                mostrar: true,
                tiempo: 6000
            });
            return true
        } else {
            return false;
        }
    }

    const egresado_programa = (value) => {
        setEgresado(value);
        let resp = egresados.find(({ id }) => value === id);
        if(resp.codigo !== "Egr_No_Apl"){
            setshowInput_programa(true);
        }else if(resp.codigo === "Egr_No_Apl"){
            setshowInput_programa(false);
        }
    }

    const trabaja_act = (value) => {
        setTrabaja(value)
        let resp = opcionesTrabaja.find(({ id }) => value === id);
        if(resp.codigo === "Tra_Si"){
            setshowInputTrabaja(true);
        }else if(resp.codigo === "Tra_No"){
            setshowInputTrabaja(false);
        }
    }

    const Adicional = () => {
        return (
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Teléfono Principal *</InputLabel>
                        <Input
                            defaultValue={telefono_principal}
                            onChange={(e) => telefono_principal = e.target.value}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Teléfono Alterno *</InputLabel>
                        <Input
                            defaultValue={telefono_alterno}
                            onChange={(e) => telefono_alterno = e.target.value}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Correo Electrónico Personal *</InputLabel>
                        <Input
                            defaultValue={correo_personal}
                            onChange={(e) => correo_personal = e.target.value}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Dirección de Residencia *</InputLabel>
                        <Input
                            defaultValue={direccion_residencia}
                            onChange={(e) => direccion_residencia = e.target.value}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Ciudad de Residencia *</InputLabel>
                        <Input
                            defaultValue={ciudad_residencia}
                            onChange={(e) => ciudad_residencia = e.target.value}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>País *</InputLabel>
                        <Input
                            defaultValue={pais}
                            onChange={(e) => pais = e.target.value}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Estrato Socio Económico *</InputLabel>
                        <Select
                            value={estrato}
                            onChange={(e) => setEstrato(e.target.value)}
                        >
                            {
                                estratos.map((e) => (
                                    <MenuItem key={e.id} value={e.id}> {e.nombre} </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils} >
                            <Grid container>
                                <KeyboardDatePicker
                                    required={true}
                                    style={{ marginTop: 3, width: '100%' }}
                                    margin="normal"
                                    label="Fecha de Nacimiento"
                                    format="yyyy/MM/dd"
                                    value={fechaNacimiento}
                                    onChange={setFechaNacimiento}
                                    KeyboardButtonProps={{
                                        'aria-label': 'Cambiar fecha',
                                    }}
                                    maxDate={Date("yyyy/MM/dd")}
                                    maxDateMessage={`La fecha debe ser menor que la fecha actual.`}
                                    invalidDateMessage='Formato Invalido'
                                    cancelLabel="Cancelar"
                                    okLabel="Aceptar"
                                    todayLabel="Hoy"
                                    clearLabel="Limpiar"
                                    dayText="Día"
                                    monthText="Mes"
                                    yearText="Año"
                                    formatDay="EE"
                                    formatMonth="MMMM"
                                    formatYear="yyyy"
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Sexo *</InputLabel>
                        <Select
                            value={sexo}
                            onChange={(e) => setSexo(e.target.value)}
                        >
                            {
                                sexos.map((s) => (
                                    <MenuItem key={s.id} value={s.id}> {s.nombre} </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Egresado de otro programa *</InputLabel>
                        <Select
                            value={egresado}
                            onChange={(e) => egresado_programa(e.target.value)}
                        >
                            {
                                egresados.map((e) => (
                                    <MenuItem key={e.id} value={e.id}> {e.nombre} </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                {showInput_programa && (
                    <>
                        <Grid item xs={6}>
                            <FormControl className='form-control'>
                                <InputLabel>Nombre del Programa</InputLabel>
                                <Input
                                    defaultValue={nombre_programa}
                                    onChange={(e) => nombre_programa = e.target.value}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl className='form-control'>
                                <InputLabel>Año en que Graduó</InputLabel>
                                <Input
                                    type="number"
                                    defaultValue={ano_graduo}
                                    onChange={(e) => ano_graduo = e.target.value}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl className='form-control'>
                                <InputLabel>Institución Educativa</InputLabel>
                                <Input
                                    defaultValue={institucion_educativa}
                                    onChange={(e) => institucion_educativa = e.target.value}
                                />
                            </FormControl>
                        </Grid>
                    </>
                )}
                    
                
            </Grid>
        )
    }

    const validar_laboral = () => {
        let mensaje = ''
        let cod_trabaja = '';
        opcionesTrabaja.map(({ id, codigo }) => {
            if (id == trabaja){
                cod_trabaja = codigo;
            }
        })

        if (!trabaja) mensaje = 'Por favor completar el campo Trabaja'
        else if (!actividadLaboral) mensaje = 'Por favor completar el campo Actividad'
        else if (!acepto) mensaje = 'Por favor completar el campo Acepto'
        else if (cod_trabaja == "Tra_Si"){
            if(!(empresa_labora.trim())) mensaje = 'Por favor completar el campo Empresa'
            else if(!(direccion.trim())) mensaje = 'Por favor completar el campo Direccion'
            else if(!(cargo_desempena.trim())) mensaje = 'Por favor completar el campo Cargo'
            else if(!(telefono_empresarial.trim())) mensaje = 'Por favor completar el campo Telefonos'
            else if(!(correo_empresarial.trim())) mensaje = 'Por favor completar el campo Correo Empresarial'
            else if(!rango) mensaje = 'Por favor completar el campo Rango'
        }

        if (mensaje) {
            actualizarMensaje({
                titulo: mensaje,
                tipo: "info",
                mostrar: true,
                tiempo: 6000
            });
            return true
        } else {
            return false;
        }
    }

    const Laboral = () => {
        return (
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Trabaja Actualmente *</InputLabel>
                        <Select
                            value={trabaja}
                            onChange={(e) => trabaja_act(e.target.value)}
                        >
                            {
                                opcionesTrabaja.map((op) => (
                                    <MenuItem key={op.id} value={op.id}> {op.nombre} </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Actividad Laboral *</InputLabel>
                        <Select
                            value={actividadLaboral}
                            onChange={(e) => setActividadLaboral(e.target.value)}
                        >
                            {
                                actividadesLaborales.map((al) => (
                                    <MenuItem key={al.id} value={al.id}> {al.nombre} </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                {showInputTrabaja && (
                    <>
                        <Grid item xs={6}>
                            <FormControl className='form-control'>
                                <InputLabel>Empresa Donde Labora</InputLabel>
                                <Input
                                    defaultValue={empresa_labora}
                                    onChange={(e) => empresa_labora = e.target.value}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl className='form-control'>
                                <InputLabel>Dirección</InputLabel>
                                <Input
                                    defaultValue={direccion}
                                    onChange={(e) => direccion = e.target.value}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl className='form-control'>
                                <InputLabel>Cargo que Desempeña</InputLabel>
                                <Input
                                    defaultValue={cargo_desempena}
                                    onChange={(e) => cargo_desempena = e.target.value}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl className='form-control'>
                                <InputLabel>Teléfonos</InputLabel>
                                <Input
                                    defaultValue={telefono_empresarial}
                                    onChange={(e) => telefono_empresarial = e.target.value}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl className='form-control'>
                                <InputLabel>Correo Electrónico Empresarial</InputLabel>
                                <Input
                                    defaultValue={correo_empresarial}
                                    onChange={(e) => correo_empresarial = e.target.value}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl className='form-control'>
                                <InputLabel>Rango Salarial</InputLabel>
                                <Select
                                    value={rango}
                                    onChange={(e) => setRango(e.target.value)}
                                >
                                    {
                                        rangosSalariales.map((r)=>(
                                            <MenuItem key={r.id} value={r.id}> {r.nombre} </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    </>
                ) }
                <Grid item xs={12}>
                    <FormControl className='form-control'>
                        <InputLabel>Acepto *</InputLabel>
                        <Select
                            value={acepto}
                            onChange={(e) => setAcepto(e.target.value)}
                        >
                            {
                                aceptarSolicitud.map((as)=>(
                                    <MenuItem key={as.id} value={as.id}> {as.nombre} </MenuItem>
                                ))
                            }
                        </Select>
                        <FormHelperText>
                            Aspirantes a GRADO. Esta solicitud está sujeta a previa verificación de paz y salvo de las áreas académicas,
                            financiera y recursos educativos. Su recibido, No implica su aprobación.
                        </FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
        )
    }

    // const eliminarArchivo = (i) => {
	// 	archivos.splice(i, 1);
	// 	setArchivos(archivos)
	// 	actualizarMensaje({ titulo: `Archivo Retirado.`, tipo: 'success', mostrar: true, tiempo: 6000 });
	// };

	// const agregarArchivos = (arc) => {
	// 	archivos = archivos.concat(arc);
	// 	setArchivos( archivos );
	// };

    const validar_adjunto = (val, archivos_adjuntos) =>{    
        setTerminarAdjuntos(true);  
        if (val === 1) setArchivos(archivos_adjuntos);     
        if (val === 2) setArchivos2(archivos_adjuntos);     
        return false;
    }

    const Adjunto = () => {
        return (
            <div>
                <MatriculasRequisitos
					estado={'Mat_En_Est'}
					validar_adjunto={!estadoAdjuntos && activeStep === steps.length - 1 ? validar_adjunto : setTerminarAdjuntos(true)}
                    programa={programa}
                    trabajoGrado={opcionGrado2}
                    tipo={aspirante}
                    estadoAdjuntos={estadoAdjuntos}
                    validarEncuesta={validarEncuesta}
                    setValidarEncuesta={setValidarEncuesta}
                    actualizarMensaje={actualizarMensaje}
				/>
            </div>
        )
    }

    function getStepContent(step) {
        switch (step) {
            case 0:
                return <RSG />
            case 1:
                return <Programa />
            case 2:
                return <Adicional />
            case 3:
                return <Laboral />
            case 4:
                return <Adjunto />
            default:
                return 'Unknown step';
        }
    }

    const guardar_solicitud = async () => {
		let auxData = {
			correo:correo_personal,
			celular:telefono_principal,
			telefono:telefono_alterno,
			curso: '',
			tipo: tipo.id,
			tipoCode: tipo.codigo,
			misCursos:[],
            
            primer_nombre,
            segundo_nombre,
            primer_apellido,
            segundo_apellido,

            numero_documento,
            ciudad_expedicion,
            periodo,
            trabajo_grado,
            telefono_principal,
            telefono_alterno: telefono_alterno ? telefono_alterno : '',
            correo_personal,
            direccion_residencia,
            ciudad_residencia,
            fecha_nacimiento: fechaNacimiento ? moment(fechaNacimiento).format("DD/MM/YYYY") : "",
            fecha_expedicion: fechaExpedicion ? moment(fechaExpedicion).format("YYYY-MM-DD") : "",
            pais,
            ano_graduo,
            institucion_educativa,
            nombre_programa,
            empresa_labora,
            direccion,
            cargo_desempena,
            correo_empresarial,
            telefono_empresarial: telefono_empresarial ? telefono_empresarial : '',
            fechaGrado,
            aspirante,
            programa,
            tipoIdentificacion,
            autorizo,
            opcionGrado1,
            opcionGrado2,
            estrato,
            sexo,
            egresado,
            trabaja,
            actividadLaboral,
            rango,
            acepto,
		};
        const data =  await crear_form_data(auxData);
		data.append(`total`, archivos.length);
		data.append(`total2`, archivos2.length);
        archivos.map(({ archivo, codigo }, index) => {
            data.append(`archivo${index}`, archivo)
            data.append(`codigo${index}`, codigo)
            return true;
        })
        archivos2.map((archivo2, index) => data.append(`o_archivo${index}`, archivo2));
		setCargando(true);
        setCargandoText('Enviando, por favor espere')
		formulario(`api/v1.0/matriculas/crear`, data, 'post', async (error, estado, resp) => {
			let title = 'Ha ocurrido un error, contacte con el administrador.';
			let icon = 'warning';
			if (!error) {
				if (estado === 200) {
					icon = 'success';
					title = resp.titulo;
					setOpen(false);
					setActiveStep(0);
				} else if (estado === 302) {
					icon = 'info';
					title = resp.titulo;
                    if (!resp.sw) {
                        setValidarEncuesta(false);
                    }
				} else {
					tipo = 'info';
					title = resp.titulo ? resp.titulo : mostrarError(resp);
				}
			}
			actualizarMensaje({ titulo: title, tipo: icon, mostrar: true, tiempo: 6000 });			
			setCargando(false);
		});
	};

    return (
        <div>
            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby='customized-dialog-title'
                open={open}
                fullWidth={true}
                maxWidth='md'
                className='scroll'
            >
                <AppBarModal
                    titulo={`${tipo && tipo.nombre}`}
                    mostrarModal={() => setOpen(false)}
                    titulo_accion='Cerrar'
                    accion={() => setOpen(false)}
                />
                <DialogContent>
                    {cargando ? (
                        <TareasTerminadas mensaje={cargandoText} marginTop='7%' imagen={emma_w} widthImg='7%' cargando={true}/>
                    ) : (
                        <div>
                            <PasosCrearSolicitud activeStep={activeStep} steps={steps} />
                            {getStepContent(activeStep)}
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={activeStep === 0}
                        onClick={cargando ? () => setOpen(false) : handleBack}
                        className={classes.button}
                    >
                        Atrás
                    </Button>
                    {!cargando && activeStep != steps.length - 1 && (
                        <Button
                            color='primary'
                            onClick={handleNext}
                            className={classes.button}
                        >
                            {'Siguiente'}
                        </Button>
                    )}
                     {!cargando && estadoAdjuntos &&  activeStep === steps.length - 1 && (
                        <Button
                            disabled={!validarEncuesta}
                            color='primary'
                            onClick={handleNext}
                            className={classes.button}
                        >
                            {'Guardar'}
                        </Button>
                    )}

                </DialogActions>
            </Dialog>
        </div>
    );
}
