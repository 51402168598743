
import React, { Component } from 'react';
import { connect } from "react-redux";
import { Paper, Grid, Checkbox } from '@material-ui/core'
import { consulta, rutaImg, mostrarError } from "../../global/js/funciones";
import { actualizarMensaje, mostrarModulo } from "../../redux/actions/actGlobal";
import { mostrarModalIniciarAsitencia } from "../../redux/actions/actAsistencia";
import { Menu } from "./asistencia/AsistenciaMenu";
import AsistenciaAsignaturas from "./asistencia/AsistenciaAsignaturas";
import AsistenciaEstudiantes from "./asistencia/AsistenciaEstudiantes";
import TareasTerminadas from "../general/TareasTerminadas";
import TituloAccion from "../general/TituloAccion";
import ConfirmarAccion from "../general/ConfirmarAccion";
import { BtnRegresar, BtnDetalle } from "../general/BotonesAccion";
import ListarDatos from "../general/ListarDatos";
import emma_w from '../../global/imagenes/emma_w.png';
import emma_s from '../../global/imagenes/emma_s1.png';
import TelegramIcon from '@material-ui/icons/Telegram';
import moment from 'moment'

class Asistencia extends Component {

  constructor(props) {
    super(props)
    this.state = {
      seleccion: 'marcar',
      cargando: true,
      enListado: false,
      modalLista: false,
      id_asistencia: 0,
      grupo: {
        id_grupo: 0
      },
      grupos: [],
      historial: [],
      estudiantes: [],
      estudiantesSele: [],
      asistencias: [],
    }
  }

  async componentDidMount() {
    let { mostrarModulo } = this.props;
    mostrarModulo({ ruta: '/asistencia', nombre: 'Asistencia' })
    this.actualizarDataGrupos();
  }


  async componentDidUpdate({ }, { seleccion, grupo }) {
    let { seleccion: seleccionNew, grupo: grupoNew } = this.state;
    if (grupo.id_grupo !== grupoNew.id_grupo) {
      this.setState({ cargando: true })
      let { estudiantes } = await this.obtenerEstudiantesGrupos(grupoNew.id_grupo);
      this.setState({ estudiantes, cargando: false });
    }

    if (seleccionNew !== seleccion) {
      if (seleccionNew === 'marcar') this.actualizarDataGrupos();
      else if (seleccionNew === 'historial') this.actualizarDataAsistencias();
    }
  }

  async actualizarDataGrupos() {
    this.setState({ cargando: true })
    let { grupos } = await this.obtenerGrupos();
    this.setState({ grupos, cargando: false })
  }

  async actualizarDataAsistencias() {
    this.setState({ cargando: true })
    let asistencias = await this.obtenerAsistencias();
    this.setState({ asistencias, cargando: false })
  }

  async obtenerGrupos() {
    return new Promise(resolve => {
      consulta(`api/v1.0/asistencia/grupos`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  async obtenerEstudiantesGrupos(id_grupo) {
    return new Promise(resolve => {
      consulta(`api/v1.0/asistencia/grupo/${id_grupo}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  async obtenerAsistencias() {
    return new Promise(resolve => {
      consulta(`api/v1.0/asistencia`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  guardarAsistencia = () => {
    let { actualizarMensaje, mostrarModalIniciarAsitencia } = this.props;
    let { grupo, estudiantesSele, estudiantes: estudiantes_mat } = this.state;
    let estudiantes = [];
    estudiantesSele.map((i) => {
      let estudiante = estudiantes_mat.find(({ identificacion }) => i == identificacion);
      if (estudiante) estudiantes.push(estudiante);
    });

    let data = {
      asignatura: grupo.asignatura,
      grupo: grupo.grupo,
      cod_grupo: grupo.id_grupo,
      estudiantes,
    }
    consulta(`api/v1.0/asistencia/crear`, data, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          this.setState({ estudiantesSele: [], enListado: false });
          mostrarModalIniciarAsitencia(false);
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp)
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    }
    );
  }

  seleccionar = value => {
    let { estudiantesSele } = this.state;
    let sele = estudiantesSele;
    const seleccionado = sele.indexOf(value);
    if (seleccionado === -1) sele.push(value);
    else sele.splice(seleccionado, 1);
    this.setState({ estudiantesSele: sele })
  }

  pintarGrupos() {
    let { grupos } = this.state;
    if (grupos.length === 0) return <div className='pointer' onClick={() => this.setState({ seleccion: 'agregar' })}><TareasTerminadas mensaje='No tienes grupos cargados' marginTop='7%' imagen={emma_s} widthImg="7%" /></div>;
    return (
      <div style={{ padding: '2% 2% 0% 2%' }} >
        <Grid container direction={'row'} spacing={1} >
          <TituloAccion principal='' alterno='¡ Estos son tus grupos para este periodo !' />
          {
            grupos.map((grupo, i) => {
              return (
                <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
                  <Paper elevation={1} onClick={() => this.setState({ enListado: true, grupo })} className='pointer'>
                    <AsistenciaAsignaturas categoria={1} titulo={`${grupo.asignatura}`} horario={grupo.horario} grupo={grupo.grupo} descripcion={`Tu solicitud de crédito para el programa de `} estado={'lista'} />
                  </Paper>
                </Grid>
              )
            })
          }
        </Grid>
      </div>
    )
  }

  pintarMarcar() {
    let { enListado } = this.state;
    return !enListado ? this.pintarGrupos() : this.pintarEstudiantes()
  }


  pintarEstudiantes() {
    let { estudiantes, grupo, estudiantesSele } = this.state;
    let { modalIniciarAsistencia, mostrarModalIniciarAsitencia } = this.props;
    return (
      <div className='' >
        <div className='regresar' ><BtnRegresar callback={() => this.setState({ enListado: false })} /></div>
        <div >
          <ConfirmarAccion
            dato={{ id: 0 }}
            titulo={' ¿ Enviar Datos ?'}
            ejecutarAccion={() => this.guardarAsistencia()}
            modalConAccion={modalIniciarAsistencia}
            mostrarModalConfAccion={mostrarModalIniciarAsitencia}
          />
          <ListarDatos
            datos={estudiantes}
            titulo={`${grupo.asignatura} - ${grupo.grupo}`}
            id='tbl_estudiantes_grupo'
            opciones={true}
            agregar={true}
            accionApp={false}
            iconoFab={() => <TelegramIcon />}
            actAgregar={() => mostrarModalIniciarAsitencia(true)}
            accion_general={row => this.seleccionar(row.identificacion)}
            acciones={row => <Checkbox edge="end" onChange={() => this.seleccionar(row.identificacion)} checked={estudiantesSele.indexOf(row.identificacion) !== -1} inputProps={{ 'aria-labelledby': row.identificacion }} />}
            buscar={true}
            avatarImg={true}
            avatar={({ identificacion }) => `${rutaImg}${identificacion}.jpg`}
            fila_principal={({ nombre }) => nombre}
            filas={[
              { 'id': 'identificacion' },
            ]}
          />
        </div>
      </div>
    )
  }


  pintarHistorial() {
    let { asistencias, modalLista, id_asistencia } = this.state;

    const obtenerColor = (codigo = 0) => {
      const colores = {
        '0': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
      }
      return (colores[codigo])
    }

    const acciones = (data) => {
      const onClickDetalle = ({ id }) => {
        this.setState({ id_asistencia: id })
        this.setState({ modalLista: true })
      }

      let detalle = <BtnDetalle callback={() => onClickDetalle(data)} color='#01579b' texto='Lista' />;
      return detalle;
    }

    return (
      <div>
        <ListarDatos
          datos={asistencias}
          titulo='Historial Asistencia'
          id='tbl_historial_asistencia'
          opciones={true}
          acciones={(data) => acciones(data)}
          buscar={true}
          avatar={({ grupo }) => grupo}
          color_avatar={() => obtenerColor()}
          fila_principal={({ asignatura }) => asignatura}
          filas={[
            { 'id': 'fecha_registro', 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a'), 'nombre': 'Fecha Registro : ' },
            { 'id': 'asignatura', enLista: false },
          ]}
        />
        <AsistenciaEstudiantes id_asistencia={id_asistencia} modalListaAsistencia={modalLista} mostrarModalListaAsistencia={modalLista => this.setState({ modalLista })} />
      </div>
    )
  }

  render() {
    let { seleccion, cargando } = this.state;
    return (
      <div>
        <Menu
          seleccionar={(seleccion) => this.setState({ seleccion })}
          sele={seleccion}
        />
        <div className='contenido_modulo'>
          {
            !cargando
              ?
              <div>
                {seleccion === 'marcar' && this.pintarMarcar()}
                {seleccion === 'historial' && this.pintarHistorial()}
              </div>
              : <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
          }
        </div>
      </div>
    )
  }
}


//CARGO ESTADOS DEL MODULO
const mapStateToProps = (state) => {
  let { modalIniciarAsistencia } = state.redAsistencia;
  return {
    modalIniciarAsistencia
  }
};

//CARGO FUNCIONES DEL MODULO
const mapDispatchToProps = {
  mostrarModalIniciarAsitencia,
  actualizarMensaje,
  mostrarModulo
}


export default connect(mapStateToProps, mapDispatchToProps)(Asistencia);


