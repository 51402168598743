import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import SolicitudesAgregarMisDatos from './SolicitudesAgregarMisDatos';
import SolicitudesAgregarHechos from './SolicitudesAgergarHechos';
import SolicitudesAgregarAdjuntos from './SolicitudesAgergarAdjuntos';
import Copyright from '../general/app/Copyright';
import emma_logo from "../../global/imagenes/logo_agrupado_sinfondo.png";
import Hidden from '@material-ui/core/Hidden';
import { coloresEmma } from '../../global/js/funciones'
const useStyles = makeStyles(theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 650,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    borderRadius: "50px",
    background: 'linear-gradient(0deg, rgba(245,245,245,0.5410539215686274) 100%, rgba(230,230,230,0.6587009803921569) 100%)',
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: theme.spacing(3),
    },
  },
  stepper: {
    backgroundColor: "transparent",
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',

  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    backgroundColor: "white",
    color: coloresEmma.primarycolor,
  },
  logo_emma: {
    minWidth: '200px',
    width: "10%",
  },
  div_logo_cuc: {
    width: "100%",
    textAlign: "center"
  },
  titulo: {
    borderRadius: "50px",
    backgroundColor: coloresEmma.secondarycolor,
    color: "white",
    padding: "2%"
  },
  div_imagen: {
    position: "fixed",
    width: '90%',
    height: '400px',
    textAlign: 'left',
    zIndex: '-1'
  },
  div_imagen_fin: {
    width: '90%',
    textAlign: "center",

  },
  emma_fin: {
    width: "90%",
    maxWidth: '400px',
  },
  emma_info: {
    margin: theme.spacing(5, 0, 0, 1),
    width: "30%",
    maxWidth: '300px',
  },
  emma_info2: {
    margin: theme.spacing(5, 0, 0, 1),
    width: "23%",
    maxWidth: '300px',
  },
  btn_salir: {
    marginTop: theme.spacing(4),
    minWidth: '300px',
    width: "25%",
    borderRadius: "50px",
    color: 'white',
  },
  div_message: {
    zIndex: '1',
    position: "fixed",
    textDecoration: 'none',
    top: "0%",
    left: "75%",
  },
  div_message2: {
    marginBottom: '10px'
  },
  logo: {
    minWidth: '300px',
    width: "28%",
  },
  div_logo: {
    padding: theme.spacing(8, 0, 0, 0),
    textAlign: "center"
  },
  logo_cuc: {
    minWidth: '240px',
    width: "20%",
    marginTop: "20px"
  },
  mensaje_final: {
      margin: 'auto',
      marginTop: '15px',
      width: '300px',
  }
}));

const steps = ['Datos del Solicitante', 'Hechos','Requisitos'];

function getStepContent(step, props_c) {
  switch (step) {
    case 0:
      return <SolicitudesAgregarMisDatos props_c={props_c} />;
    case 1:
      return  <SolicitudesAgregarHechos props_c={props_c} />;
    case 2:
      return <SolicitudesAgregarAdjuntos props_c={props_c}/>;
    default:
      throw new Error('Unknown step');
  }
}


export default function SolicitudesForm({ props_c }) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const anterior = () => {
    setActiveStep(activeStep - 1);
  };
  const siguiente = () => {
    let { validarDatos, actualizarMensaje } = props_c;
    let titulo = validarDatos(activeStep);
    if (titulo) actualizarMensaje({ titulo, tipo: 'info', mostrar: true, tiempo: 6000 });
    if(!titulo) setActiveStep(activeStep + 1);
  };

  const terminar = () => {
    document.getElementById('enviar_form_multi_archivo').click()
    setActiveStep(activeStep - 2);
  };
  

  const renderContenido = (guardado) => {
      return (
        <Paper className={classes.paper}>
          <Typography className={classes.titulo} component="h1" variant="h5" align="center">
            <Hidden mdUp> SOLICITUDES</Hidden>
            <Hidden smDown>CONSULTORIA UNICOSTA</Hidden>
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {getStepContent(activeStep, props_c)}
              <div className={classes.buttons}>
                {activeStep === 0 && (
                <Button onClick={siguiente} className={classes.button}>
                  Siguiente
                </Button>
              )}
              {activeStep === 1 && (
              <><Button onClick={anterior} className={classes.button}>
                 Regresar
               </Button>
              <Button onClick={siguiente} className={classes.button}>
                  Siguiente
              </Button>
              </>
              )}
              {activeStep === 2 && (
              <><Button onClick={anterior} className={classes.button}>
                 Regresar
               </Button>
              <Button onClick={terminar} className={classes.button}>
                  terminar
              </Button>
              </>
              )}
              </div>
          </React.Fragment>
        </Paper>
      )
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.layout}>
        <div className={classes.div_logo_cuc}>
          <img src={emma_logo} alt="Logo emma_logo" className={classes.logo_emma} />
        </div>
        {renderContenido()}
      </main>
      <Copyright />

    </React.Fragment>
  );
}
