import {
    MODAL_ADD_RETOS,
    MODAL_ADD_GRUPO_RETOS,
    MODAL_DET_RETOS,
    MODAL_ADJ_RETOS,
    MODAL_FILTRO_RETOS,
    MODAL_UPDATE_PERIODO,
    MODAL_GES_RETOS,
    MODAL_INFORME,
    MODAL_PERM_ASIGNATURAS,
    MODAL_DESECHAR_GRUPO,
    MODAL_UPDATE_EVIDENCIA
} from "../actions/actRetoscuc"

const initialState = {
    modalAddRetos: false,
    modalAddGrupoRetos: false,
    modalDetRetos: false,
    modalAdjuntoRetos: false,
    modalFiltroRetos: false,
    modalModificarPeriodo: false,
    modalGestionRetos: false,
    modalInformeRetos: false,
    modalPermisoAsignatura: false,
    modalDesecharGrupo: false,
    modalactualizarEvidencia: false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case MODAL_ADD_RETOS:
            return Object.assign({}, state, {
                modalAddRetos: action.show
            })
        case MODAL_ADD_GRUPO_RETOS:
            return Object.assign({}, state, {
                modalAddGrupoRetos: action.show
            })
        case MODAL_DET_RETOS:
            return Object.assign({}, state, {
                modalDetRetos: action.show
            })
        case MODAL_ADJ_RETOS:
            return Object.assign({}, state, {
                modalAdjuntoRetos: action.show
            })
        case MODAL_FILTRO_RETOS:
            return Object.assign({}, state, {
                modalFiltroRetos: action.show
            })
        case MODAL_UPDATE_PERIODO:
            return Object.assign({}, state, {
                modalModificarPeriodo: action.show
            })
        case MODAL_GES_RETOS:
            return Object.assign({}, state, {
                modalGestionRetos: action.show
            })
        case MODAL_INFORME:
            return Object.assign({}, state, {
                modalInformeRetos: action.show
            })
        case MODAL_PERM_ASIGNATURAS:
            return Object.assign({}, state, {
                modalPermisoAsignatura: action.show
            })
        case MODAL_DESECHAR_GRUPO:
            return Object.assign({}, state, {
                modalDesecharGrupo: action.show
            })
        case MODAL_UPDATE_EVIDENCIA:
            return Object.assign({}, state, {
                modalactualizarEvidencia: action.show
            })  
        default:
            return state;
    }
}

export default reducer;