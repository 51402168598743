/* HELPER LOCAL */
// export const Id_Tipo_colegio = 234
// export const Id_Barrio_colegio = 235
// export const Id_Departamento_colegio = 7
// export const Id_Estado_visita = 1234
// export const LUGAR_VISITA = 1258
// export const TIPO_INFORME = 1259

/* HELPER PRODUCCION */
export const Id_Tipo_colegio = 234
export const Id_Barrio_colegio = 235
export const Id_Departamento_colegio = 7
export const Id_Estado_visita = 236 
export const LUGAR_VISITA = 237
export const TIPO_INFORME = 238