import React, { useState, useEffect } from "react";
// Material-UI
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  Dialog,
  Grid,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core/";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import "date-fns";
// Components
import AppBarModal from "./../../general/AppBarModal";
import {
  consulta,
  obtenerValores,
  valor_peso,
  obtenerPermisosValorAlt,
  obtenerMisDatos,
  obtenerValoresFiltros,
  validarCorreo,
  mostrarError,
} from "../../../global/js/funciones";
import { PasosCrearSolicitud } from "./MatriculasMenu";
import { Call, PhoneAndroid, AlternateEmail } from "@material-ui/icons";
import {
  DIPLOMADOS,
  MATERIAS,
  CURSOS_LIBRES,
  CURSOS_LIBRES_PRE,
  PROGRAMAS,
  NOTAS,
} from "./helper";
import AlertasSimple from "../../general/AlertasSimple";
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from "../../../global/imagenes/emma_w.png";
import emma_s from "../../../global/imagenes/emma_s1.png";
import ListarDatos from "../../general/ListarDatos";
import ModalAgregarGrupo from "./ModalAgregarGrupo";

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    width: "100%",
    marginTop: 15,
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  alert: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  list: {
    marginTop: 20,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 300,
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  inline: {
    display: "inline",
  },
}));

const auxMateria = [
  { id: 1, nombre: "materia1", valora: "3", grupo: null },
  { id: 2, nombre: "materia2", valora: "2", grupo: null },
  { id: 3, nombre: "materia3", valora: "1", grupo: null },
  { id: 4, nombre: "materia4", valora: "4", grupo: null },
];

export default function ModalCrearSolicitud({
  open,
  setOpen,
  tipo,
  misCursos,
  setMisCursos,
  misDatos,
  setMisDatos,
  actualizarMensaje,
}) {
  const [programa, setPrograma] = useState("");
  const [programas, setProgramas] = useState([]);
  const [valor, setValor] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [celular, setCelular] = useState("");
  const [telefono, setTelefono] = useState("");
  const [correo, setCorreo] = useState("");
  const [cursos, setCursos] = useState([]);
  const [checked, setChecked] = useState([]);
  const [tipoCurso, setTipoCurso] = useState("");
  const [limiteCreditos, setLimiteCreditos] = useState("");
  const [cargando, setCargando] = useState(true);
  const [cargandoText, setCargandoText] = useState("Cargando..");
  const [notas, setNotas] = useState([]);
  const [limitePregrado, setLimitePregrado] = useState("");
  const [modalAgregarGrupo, setModalAgregarGrupo] = useState(false);
  const [cursoGrupo, setCursoGrupo] = useState();
  const [identificacion, setIdentificacion] = useState("");

  const steps = ["Mis Datos", "Elegir Curso", "Información"];
  const classes = useStyles();

  useEffect(() => {
    obtenerValoresFiltros([{ llave: "codigo", valor: "Lim_Int" }]).then(
      (limite) => setLimiteCreditos(parseInt(limite[0].nombre))
    );
    obtenerValoresFiltros([{ llave: "codigo", valor: "Lim_Pre" }]).then(
      (limite) => setLimitePregrado(parseInt(limite[0].nombre))
    );
    const { correo_personal, celular, identificacion } = misDatos.persona;
    setCorreo(correo_personal);
    setCelular(celular);
    setCargando(false);
    setIdentificacion(identificacion);
  }, [misDatos]);

  useEffect(() => {
    if (tipo) {
      obtenerPermisosValorAlt([
        { llave: "principal", valor: tipo.id },
        { llave: "secundario__generica", valor: NOTAS },
      ]).then((notas) => setNotas(notas));
      if (tipo.codigo === "Mat_Pro_Pos") {
        obtenerValoresFiltros([
          { llave: "generica", valor: PROGRAMAS },
          { llave: "valorc", valor: "posgrado" },
        ]).then((programas) => setProgramas(programas));
        setTipoCurso("Curso Libre");
        setPrograma("");
      } else if (tipo.codigo === "Mat_Pro_Dip") {
        setTipoCurso("Diplomado");
        obtenerValoresFiltros([
          { 'llave': 'generica', 'valor': DIPLOMADOS }, 
          { 'llave': 'estado', 'valor': '1' }, 
          { 'llave': 'valore', 'valor': 'mostrar' }
        ]).then((diplomados) => setCursos(diplomados));
        setPrograma("");
      } else if (tipo.codigo === "Mat_Pro_Cre_ext") {
        setTipoCurso("Creditos extras");
        setPrograma("");
      } else {
        setPrograma("");
        if (misDatos.programas.length === 1) {
          handleChangePrograma(misDatos.programas[0].relacion.id);
        }
        tipo.codigo === "Mat_Pro_Pre"
          ? setTipoCurso("Curso Libre")
          : setTipoCurso("Intersemestral");
      }
    }
    setValor("");
    setActiveStep(0);
    setMisCursos([]);
    setChecked([]);
  }, [tipo, setTipoCurso, setMisCursos]);

  const handleNext = () => {
    let sw = true;
    let title = `Por favor agregue al menos un ${tipoCurso}`;
    // Si es el ultimo paso se guarda la solicitud
    if (activeStep === steps.length - 1) {
      if (misCursos.length) guardar_solicitud();
      else sw = false;
    } else {
      if (activeStep === 0) {
        // Validar si el correo es válido
        if (!validarCorreo(correo)) {
          sw = false;
          title = "Por favor digite un correo válido.";
        }
        // Validar si hay un celular
        if (sw && !celular) {
          sw = false;
          title = "Por favor digite su celular";
        }
        // Si no es una solicitud de Diplomado verifico si seleccionó un programa
        if (sw && tipo.codigo !== "Mat_Pro_Dip") {
          if (!programa) {
            sw = false;
            title = `Por favor seleccione un programa`;
          }
        }
      } else if (activeStep === 1) {
        if (checked.length <= 0) sw = false;
      }
      if (sw) setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    if (!sw)
      actualizarMensaje({
        titulo: title,
        tipo: "info",
        mostrar: true,
        tiempo: 6000,
      });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  var handleAgregar = (nameGrupo, creditosExtra) => {
    if (!nameGrupo || !nameGrupo.trim()) {
      return;
    }

    let newChecked = [...checked];
    let selectedCourses = [...misCursos];

    setCursos(
      cursos.map((c) => {
        if (cursoGrupo.id == c.id)
          return {
            ...c,
            grupo: nameGrupo,
            creditosExtra: creditosExtra,
          };
        return c;
      })
    );
    newChecked.push(cursoGrupo.id);
    selectedCourses.push({
      ...cursoGrupo,
      grupo: nameGrupo,
      creditosExtra: creditosExtra,
    });

    setMisCursos(selectedCourses);
    setChecked(newChecked);
    setModalAgregarGrupo(false);
  };

  const handleToggle = (curso) => async () => {
    const currentIndex = checked.indexOf(curso.id);
    let newChecked = [...checked];
    let selectedCourses = [...misCursos];

    switch (tipo.codigo) {
      case "Mat_Pro_Cre_ext":
        if (currentIndex === -1) {
          setModalAgregarGrupo(true);
          setCursoGrupo(curso);
        } else {
          newChecked = checked.filter((c) => c != curso.id);
          selectedCourses = selectedCourses.filter((c) => c.id != curso.id);
        }
        break;
      case "Mat_Pro_Dip":
        if (currentIndex === -1) {
          newChecked = [curso.id];
          selectedCourses = [curso];
        } else {
          newChecked = [];
          selectedCourses = [];
        }
        break;

      case "Mat_Pro_Int":
      case "Mat_Pro_Pre":
        if (currentIndex === -1) {
          const countCreditos = selectedCourses.length
            ? await misCursos.reduce(
                (acum, currentValue) =>
                  parseInt(acum) + parseInt(currentValue.valora),
                0
              )
            : 0;
          let lim =
            tipo.codigo === "Mat_Pro_Int" ? limiteCreditos : limitePregrado;
          if (countCreditos + parseInt(curso.valora) <= lim) {
            newChecked.push(curso.id);
            selectedCourses.push(curso);
          } else
            actualizarMensaje({
              titulo: `El máximo de créditos a matrícular es ${lim}.`,
              tipo: "info",
              mostrar: true,
              tiempo: 6000,
            });
        } else {
          const course = misCursos.filter(
            (course) => course.id === curso.id
          )[0];
          const index = misCursos.indexOf(course);
          selectedCourses.splice(index, 1);
          newChecked.splice(currentIndex, 1);
        }
        break;

      default:
        if (currentIndex === -1) {
          newChecked.push(curso.id);
          selectedCourses.push(curso);
        } else {
          const course = misCursos.filter(
            (course) => course.id === curso.id
          )[0];
          const index = misCursos.indexOf(course);
          selectedCourses.splice(index, 1);
          newChecked.splice(currentIndex, 1);
        }
        break;
    }
    setMisCursos(selectedCourses);
    setChecked(newChecked);
  };

  const guardar_solicitud = () => {
    const data = {
      programa,
      correo,
      celular,
      telefono,
      curso: valor,
      tipo: tipo.id,
      tipoCode: tipo.codigo,
      misCursos,
      numero_documento: identificacion,
      ciudad_expedicion: misDatos.persona.lugar_expedicion,
      tipoIdentificacion: misDatos.persona.tipo_identificacion,
      primer_nombre: misDatos.persona.primer_nombre,
      segundo_nombre: misDatos.persona.segundo_nombre,
      primer_apellido: misDatos.persona.primer_apellido,
      segundo_apellido: misDatos.persona.segundo_apellido,
    };
    setCargando(true);
    setCargandoText("Enviando, por favor espere.")
    consulta(
      `api/v1.0/matriculas/crear`,
      data,
      "post",
      async (error, estado, resp) => {
        let title = "Ha ocurrido un error, contacte con el administrador.";
        let icon = "error";
        if (!error) {
          if (estado === 200) {
            icon = "success";
            title = resp.titulo;
            if (
              celular !== misDatos.persona.celular ||
              telefono !== misDatos.persona.telefono ||
              correo !== misDatos.persona.correo_personal
            ) {
              const misDatos = await obtenerMisDatos();
              setMisDatos(misDatos);
            }
            setOpen(false);
            setPrograma("");
            setValor("");
            setActiveStep(0);
            setMisCursos([]);
            setChecked([]);
          } else if (estado === 302) {
            icon = "info";
            title = resp.titulo;
          } else {
            tipo = "info";
            title = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({
          titulo: title,
          tipo: icon,
          mostrar: true,
          tiempo: 6000,
        });
        setCargando(false);
        setCargandoText("Cargando..")
      }
    );
  };

  const obtenerMaterias = programa => {
    let codigo = misDatos.programas.find((data) => data.relacion.id == programa)
    .relacion.codigo;
    return new Promise(resolve => {
      consulta(`api/v1.0/matriculas/mismaterias/${codigo}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  const parametroSec = (tipo) => {
    const parametro = {
      Mat_Pro_Int: MATERIAS,
      Mat_Pro_Dip: null,
      Mat_Pro_Pos: CURSOS_LIBRES,
      Mat_Pro_Pre: CURSOS_LIBRES_PRE,
    };
    return parametro[tipo];
  };

  const handleChangePrograma = async (programa) => {
    setCargando(true)
    setCargandoText("Cargando..")
    setPrograma(programa);
    if (tipo.codigo == "Mat_Pro_Cre_ext") {
      setCursos([]);
      let mat = await obtenerMaterias(programa)
      if (mat) {
        setCursos(
          mat.asignaturas.map((data) => ({
            id: data.value,
            nombre: data.label,
            valora: data.creditos,
            grupo: null,
          }))
        );

      }
    }
    let idparametro = parametroSec(tipo.codigo);
    if (tipo.codigo !== "Mat_Pro_Dip" && idparametro != null) {
      let cursos = [];
      const newCursos = await obtenerPermisosValorAlt([
        { llave: "principal", valor: programa },
        { llave: "secundario__generica", valor: idparametro },
      ]);
      newCursos.map(({ secundario }) => cursos.push(secundario));
      setCursos(cursos);
    }
    setCargando(false)
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormControl className="form-control">
                <Input
                  startAdornment={
                    <InputAdornment position="start">
                      <PhoneAndroid />
                    </InputAdornment>
                  }
                  placeholder="Celular"
                  defaultValue={misDatos && misDatos.persona.celular}
                  onChange={(e) => setCelular(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl className="form-control">
                <Input
                  startAdornment={
                    <InputAdornment position="start">
                      <Call />
                    </InputAdornment>
                  }
                  defaultValue={misDatos && misDatos.persona.telefono}
                  placeholder="Teléfono"
                  onChange={(e) => setTelefono(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className="form-control">
                <Input
                  startAdornment={
                    <InputAdornment position="start">
                      <AlternateEmail />
                    </InputAdornment>
                  }
                  defaultValue={misDatos && misDatos.persona.correo_personal}
                  placeholder="Correo electrónico"
                  onChange={(e) => setCorreo(e.target.value)}
                />
              </FormControl>
            </Grid>
            {tipo && tipo.codigo !== "Mat_Pro_Dip" && (
              <Grid item xs={12}>
                <FormControl className="form-control">
                  <InputLabel>Seleccione Programa</InputLabel>
                  <Select
                    id="programa"
                    value={programa}
                    onChange={(e) => handleChangePrograma(e.target.value)}
                    name="programa"
                  >
                    {tipo &&
                      (tipo.codigo === "Mat_Pro_Dip" ||
                        tipo.codigo === "Mat_Pro_Int" ||
                        tipo.codigo === "Mat_Pro_Pre" ||
                        tipo.codigo === "Mat_Pro_Cre_ext") &&
                      misDatos &&
                      misDatos.programas.map(({ relacion: { nombre, id } }) => (
                        <MenuItem key={id} value={id}>
                          {nombre}
                        </MenuItem>
                      ))}
                    {tipo &&
                      tipo.codigo === "Mat_Pro_Pos" &&
                      programas.map(({ nombre, id }) => (
                        <MenuItem key={id} value={id}>
                          {nombre}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
        );
      case 1:
        const acciones = (data) => {
          const detalle = (
            <Checkbox
              edge="end"
              onChange={handleToggle(data)}
              checked={checked.indexOf(data.id) !== -1}
            />
          );
          return detalle;
        };
        return (
          <div>
            {cursos.length > 0 ? (
              <ListarDatos
                datos={cursos}
                id="tbl_cursos"
                avatar={({ nombre }) => nombre.charAt(0)}
                buscar={true}
                opciones={true}
                titulo={`Lista de ${tipo.nombre}`}
                acciones={(data) => acciones(data)}
                fila_principal={({ nombre }) => nombre}
                filas={[
                  {
                    id: "creditos",
                    mostrar: ({ valora }) =>
                      tipo.codigo !== "Mat_Pro_Dip"
                        ? valora
                        : valor_peso(valora),
                    nombre:
                      tipo.codigo !== "Mat_Pro_Dip" ? "Creditos: " : "Valor: ",
                  },
                  {
                    mostrar: ({ valorc }) =>
                      valorc == null || valorc == "null" ? null : valorc,
                    nombre: "Opción a grado de: ",
                  },
                  {
                    mostrar: ({ grupo }) => grupo,
                    nombre: "grupo: ",
                  },
                  {
                    mostrar: ({ creditosExtra }) => creditosExtra,
                    nombre: "Creditos Adicionales Solicitados: ",
                  },
                  {
                    mostrar: ({ valord }) =>
                      valord == null || valord == "null" ? null : valord,
                    nombre: "Horario: ",
                  },
                  {
                    mostrar: ({ valore }) =>
                      (valore == null || valore == "null") || tipo.codigo == "Mat_Pro_Dip" ? null : valore,
                    nombre: tipo.codigo === "Mat_Pro_Dip" ? "" : "Hora: ",
                  },
                  {
                    mostrar: ({ valorf }) =>
                      valorf == null || valorf == "null" ? null : valorf,
                    nombre: "Profesor:",
                  },
                  {
                    mostrar: ({ valorg }) =>
                      valorg == null || valorg == "null" ? null : valorg,
                    nombre: "Pensum: ",
                  },
                ]}
              />
            ) : (
              <TareasTerminadas
                mensaje="No hay cursos libres disponibles para este programa"
                marginTop="7%"
                imagen={emma_s}
                widthImg="7%"
              />
            )}
          </div>
        );
      case 2:
        let lista = [];
        notas.map(({ secundario }) => lista.push({ item: secundario.nombre }));

        return misCursos.length ? (
          <div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant={"subtitle2"} component={"span"}>
                      {tipoCurso}
                    </Typography>
                  </TableCell>
                  <TableCell align={"right"}>
                    <Typography variant={"subtitle2"} component={"span"}>
                      {tipo.codigo === "Mat_Pro_Int" ||
                      tipo.codigo === "Mat_Pro_Pre" ||
                      tipo.codigo === "Mat_Pro_Cre_ext"
                        ? "Créditos"
                        : "Valor"}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {misCursos.map((curso) => (
                  <TableRow key={curso.id}>
                    <TableCell>
                      {curso.grupo
                        ? `${curso.nombre} - Grupo: ${curso.grupo}`
                        : `${curso.nombre}`}
                    </TableCell>
                    <TableCell align={"right"}>
                      {tipo.codigo === "Mat_Pro_Dip" &&
                        valor_peso(parseInt(curso.valorb))}
                      {(tipo.codigo === "Mat_Pro_Int" ||
                        tipo.codigo === "Mat_Pro_Pre" ||
                        tipo.codigo === "Mat_Pro_Cre_ext") &&
                        curso.valora}
                      {tipo.codigo === "Mat_Pro_Pos" &&
                        valor_peso(
                          parseInt(curso.valorb) * parseInt(curso.valora)
                        )}
                    </TableCell>
                  </TableRow>
                ))}

                {misCursos.length > 1 &&
                  !(
                    tipo.codigo === "Mat_Pro_Int" ||
                    tipo.codigo === "Mat_Pro_Pre"
                  ) && (
                    <TableRow>
                      <TableCell colSpan={2} align={"right"}>
                        <Typography variant={"body1"} component={"span"}>
                          {tipo.codigo === "Mat_Pro_Pos" &&
                            `Total:${valor_peso(
                              misCursos.reduce(
                                (cont, curso) =>
                                  cont +
                                  parseInt(curso.valora) *
                                    parseInt(curso.valorb),
                                0
                              )
                            )}`}

                          {tipo.codigo === "Mat_Pro_Cre_ext" &&
                            `Creditos: ${misCursos.reduce(
                              (a, { valora }) => a + parseInt(valora),
                              0
                            )}`}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
              </TableBody>
            </Table>
            { tipo.codigo !== "Mat_Pro_Cre_ext" &&
              <AlertasSimple
                tipo="info"
                titulo="Estimado estudiante, por favor tener en cuenta:"
                lista={lista}
              />
            }
          </div>
        ) : (
          <FormControl className="form-control">
            <Typography align={"center"} variant={"h6"} component={"span"}>
              {`Por favor agregue al menos un ${tipoCurso}`}
            </Typography>
          </FormControl>
        );
      default:
        return "Unknown step";
    }
  }

  return (
    <div>
      {cursoGrupo && (
        <ModalAgregarGrupo
          modalProgramas={modalAgregarGrupo}
          handleCloseModal={() => setModalAgregarGrupo(false)}
          handleAgregar={handleAgregar}
          checked={checked}
          cursoGrupo={cursoGrupo}
        />
      )}
      {/* <ModalAgregarGrupo
        modalProgramas={modalAgregarGrupo}
        handleCloseModal={() => setModalAgregarGrupo(false)}
        handleAgregar={handleAgregar}
        checked={checked}
        cursoGrupo={cursoGrupo}
      /> */}
      <Dialog
        onClose={() => setOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="sm"
        className="scroll"
      >
        <AppBarModal
          titulo={`Solicitar ${tipo && tipo.nombre}`}
          mostrarModal={() => setOpen(false)}
          titulo_accion="Cerrar"
          accion={() => setOpen(false)}
        />
        <DialogContent>
          {cargando ? (
            <TareasTerminadas
              mensaje={cargandoText}
              marginTop="7%"
              imagen={emma_w}
              widthImg="7%"
              cargando={true}
            />
          ) : (
            <div>
              <PasosCrearSolicitud activeStep={activeStep} steps={steps} />
              {getStepContent(activeStep)}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={activeStep === 0}
            onClick={cargando ? () => setOpen(false) : handleBack}
            className={classes.button}
          >
            Atrás
          </Button>
          {!cargando && (
            <Button
              disabled={
                misCursos.length <= 0 && activeStep === steps.length - 1
              }
              color="primary"
              onClick={handleNext}
              className={classes.button}
            >
              {activeStep === steps.length - 1 ? "Guardar" : "Siguiente"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
