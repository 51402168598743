import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogContent, DialogActions, Grid, TextField, Input, MenuItem, Select,
  InputLabel, FormControl, makeStyles, Button, Checkbox, FormControlLabel
} from '@material-ui/core';
import {
  consulta, generarFiltros, obtenerValoresFiltros, transformar, transformarEnviado,
  obtenerPermisosValor, obtenerValores, crear_form_data
} from "./../../../global/js/funciones";
import AppBarModal from "./../../general/AppBarModal";
import { BtnForm, BtnDetalle } from "../../general/BotonesAccion";
import ListarDatos from "./../../general/ListarDatos";
import { InscripcionMenuEditar, InscripcionMenuEditarPreUniversitario } from "./InscripcionesMenu";
import { InscripcionDetalleContacto, InscripcionDetalleDatos } from "./InscripcionDetallePreUniversitario";
import {
  GRUPOS_INSCRIPCIONES, VALIDACION_DOCUMENTOS_INSCRIPCIONES, METODO_PAGO,
  CALIFICACION_CONTACTO, COLEGIOS_PREUNIVERSITARIO, RESULTADOS_PREUNIVERSITARIO, EPS, EPS_OTRO
} from '../helper';
import InputFile from "../../general/InputFile";
import BuscarSelect from "../../general/BuscarSelect";
import moment from 'moment';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import { TIPOS_IDENTIFICACION, DEPARTAMENTOS, COMUNIDAD, GENERO, REQUISITOS_CONSULTORIA, AREA_DERECHO } from '../../consultoria/helper';
import { JORNADAPREINSCRIPCION, LINEAEDU, COSTEAR_ESTUDIOS, DISCAPACIDADES_INSCRIPCIONES } from '../helper';
import emma_w from './../../../global/imagenes/emma_w.png';
import TareasTerminadas from "./../../general/TareasTerminadas";


class InscripcionEditar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      cargando: true,
      seleccion: '',
      datos: [],
      documentos: [],
      contacto: [],
      carrera: [],
      estados: [],
      maxWidth: 'sm',
      celular: { 'valor': '', 'error': false },
      id_estudiante: '',
      tipos_identificacion: [],
      tipo_identificacion: '',
      grupo: { 'label': '', 'value': '' },
      identificacion: { 'valor': '', 'error': false },
      primer_nombre: { 'valor': '', 'error': false },
      segundo_nombre: { 'valor': '' },
      primer_apellido: { 'valor': '', 'error': false },
      segundo_apellido: { 'valor': '', 'error': false },
      correo: { 'valor': '', 'error': false },
      telefono: { 'valor': '', 'error': false },
      codigo_pruebas: { 'valor': '', 'error': false },
      estudiante_11: { 'valor': '', 'error': false },
      nombre_colegio: { 'valor': '', 'error': false },
      // linea_edu: { 'valor': '', 'error': false },
      lineas_edu_co: { 'valor': '', 'error': false },
      carrera_pre_universitario: { 'valor': '', 'error': false },
      carrera_pre_universitario_2: { 'valor': '', 'error': false },
      edad: { 'valor': '', 'error': false },
      puntaje_pruebas: { 'valor': '', 'error': false },
      puntaje_sisben: { 'valor': '', 'error': false },
      tiene_sisben: '',
      opciones_sisben: [
        {
          value: 'si',
          label: 'SI',
        },
        {
          value: 'no',
          label: 'NO',
        }
      ],
      grupos: [],
      barrio: { 'valor': '', 'error': false },
      direccion: { 'valor': '', 'error': false },
      estrato: { 'label': '', 'value': '' },
      estratos: [],
      programas: [],
      opcion_conociste: [],
      departamentos: [],
      departamento: { 'label': '', 'value': '' },
      municipios: [],
      validacion_doc: [],
      municipio: { 'label': '', 'value': '' },
      programa: { 'label': '', 'value': '' },
      conocio: { 'label': '', 'value': '' },
      celular_padre: { 'valor': '' },
      celular_madre: { 'valor': '' },
      nombre_madre: { 'valor': '' },
      nombre_padre: { 'valor': '' },
      adj_diploma: '',
      adj_docu_notas: '',
      adj_identificacion: '',
      adj_certificado: '',
      adj_saber: '',
      checkedIdentificacion: false,
      checkedCelular: false,
      checkedTelefono: false,
      checkedCorreo: false,
      checkedCelular_madre: false,
      checkedCelular_padre: false,
      colegio_asignado: { 'label': '', 'value': '' },
      colegios_asignados: [],
      calificacion_contacto: { 'label': '', 'value': '' },
      calificaciones_contacto: [],
      nombre_financiero: { 'valor': '', 'error': false },
      telefono_financiero: { 'valor': '', 'error': false },
      metodo_pago: { 'label': '', 'value': '' },
      metodos_pago: [],
      generaciones_e: [
        {
          value: 'si',
          label: 'SI',
        },
        {
          value: 'no',
          label: 'NO',
        },
        {
          value: 'otro',
          label: 'ESPERANDO RESULTADO',
        }
      ],
      generacion_e: { 'label': '', 'value': '' },
      nombre_banco: { 'valor': '', 'error': false },
      resultado_preuniversitario: { 'label': '', 'value': '' },
      resultados_preuni: [],
      genero: { 'label': '', 'value': '' },
      opciones_estudiante: [
        {
          value: 'si',
          label: 'SI',
        },
        {
          value: 'no',
          label: 'NO',
        }
      ],
      niveles_educacion: [
        {
          value: 'tecnico',
          label: 'TECNICO',
        },
        {
          value: 'tecnologo',
          label: 'TECNOLOGO',
        },
        {
          value: 'profesional',
          label: 'PROFESIONAL',
        }
      ],
      costear_estudios: [],
      nivel_educacion: { 'label': '', 'value': '' },
      jornada_otro: { 'valor': '', 'error': false },
      costear_estudio_otro: { 'valor': '', 'error': false },
      contactoEmergencia: '',
      celularEmergencia: '',
      titulo_res: 'Cargando..',
      nombreEps : '',
      nombreEpsOtro : '',
      opcionesEps : [],
      poseeEps : "",
      opcionesPoseeEps: [
        {
          value: 'si',
          label: 'SI',
        },
        {
          value: 'no',
          label: 'NO',
        }
      ],
    }
    this.fileInput = React.createRef();
  }


  // componentDidMount() {
  //   this.cargarTipos();
  // }

  async componentDidUpdate({ modalEditarInscripcion }, { seleccion }) {
    let { seleccion: seleccionNew } = this.state;
    let { inscripcion, modalEditarInscripcion: modalEditarInscripcionNew, vistaDetalle, accionVistaDetalle } = this.props;
    if (seleccionNew !== seleccion) {
      if ((vistaDetalle === 'Tipo_Ins' || vistaDetalle === 'Tipo_Homo') && accionVistaDetalle) {
        if (seleccionNew === 'Tipo_Ins' || seleccionNew === 'Tipo_Homo') this.obtenerInscripcion(inscripcion.id);
        else if (seleccionNew === 'documentos') this.obtenerAdjuntos(inscripcion.id);
        else if (seleccionNew === 'contacto') this.obtenerContacto(inscripcion.id);
        else if (seleccionNew === 'carrera') this.obtenerCarrera(inscripcion.id);
      } else {
        if (seleccionNew === 'Tipo_PreUni' || (seleccionNew === 'Tipo_Ins'|| seleccionNew === 'Tipo_Homo')) this.obtenerInscripcionPreUniversitario(inscripcion.id);
        else if (seleccionNew === 'documentos') this.obtenerAdjuntosPreUniversitario(inscripcion.id);
        else if (seleccionNew === 'Contacto') this.obtenerContactoPreUniversitario(inscripcion.id);
        else if (seleccionNew === 'verificado') this.obtenerInscripcionPreUniversitarioGestion(inscripcion.id);
        else if (seleccionNew === 'gestion_matricula') this.obtenerInscripcionPreUniversitarioGestion(inscripcion.id);
      }
    }
    if (modalEditarInscripcion !== modalEditarInscripcionNew) this.setState({ seleccion: modalEditarInscripcionNew ? vistaDetalle : '' })
  }
  async obtenerInscripcionPreUniversitario(id) {
    this.setState({ cargando: true });
    let programas = await obtenerValoresFiltros([{ "llave": 'generica', 'valor': 5 }])
    let grupos = await obtenerValoresFiltros([{ "llave": 'generica', 'valor': GRUPOS_INSCRIPCIONES }]);
    let generos = await obtenerValores(GENERO);
    let jornada_preinscripcion = await obtenerValores(JORNADAPREINSCRIPCION);
    let lineas_edu_co = await obtenerValores(LINEAEDU);
    let costear_estudios = await obtenerValores(COSTEAR_ESTUDIOS);
    let discapacidades = await obtenerValores(DISCAPACIDADES_INSCRIPCIONES);
    let f = await generarFiltros([{ 'llave': 'id', 'valor': id }]);
    consulta(`api/v1.0/inscripcion/datos/preuniversitario?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, datos: resp });
      let { datos } = this.state
      this.setState({
        id_estudiante: datos[0].estudiante.id,
        primer_nombre: datos[0].estudiante ? { 'valor': datos[0].estudiante.primer_nombre } : { 'valor': '' },
        segundo_nombre: datos[0].estudiante ? { 'valor': datos[0].estudiante.segundo_nombre } : { 'valor': '' },
        primer_apellido: datos[0].estudiante ? { 'valor': datos[0].estudiante.primer_apellido } : { 'valor': '' },
        segundo_apellido: datos[0].estudiante ? { 'valor': datos[0].estudiante.segundo_apellido } : { 'valor': '' },
        genero: datos[0].estudiante &&  datos[0].estudiante.genero ? { 'value': datos[0].estudiante.genero.id, 'label': datos[0].estudiante.genero.nombre } : { 'label': '', 'value': '' },
        codigo_pruebas: datos[0].codigo_pruebas ? { 'valor': datos[0].codigo_pruebas } : { 'valor': '' },
        estudiante_11: datos[0].estudiante_11 ? { value: datos[0].estudiante_11, label: datos[0].estudiante_11.toUpperCase() } : { 'valor': '' },
        nombre_colegio: datos[0].nombre_colegio ? { 'valor': datos[0].nombre_colegio } : { 'valor': '' },
        linea_edu: datos[0].linea_edu ? { value: datos[0].linea_edu.id, label: datos[0].linea_edu.nombre, codigo: datos[0].linea_edu.generica } : { 'valor': '' },
        // lineas_edu_co: datos[0].lineas_edu_co ? { 'valor': datos[0].lineas_edu_co } : { 'valor': '' },
        carrera_pre_universitario: datos[0].carrera_pre_universitario ? { 'valor': datos[0].carrera_pre_universitario } : { 'valor': '' },
        carrera_pre_universitario_2: datos[0].carrera_pre_universitario_2 ? { 'valor': datos[0].carrera_pre_universitario_2 } : { 'valor': '' },
        edad: datos[0].edad ? { 'valor': datos[0].edad } : { 'valor': '' },
        costear_estudio: datos[0].costear_estudio ? { value: datos[0].costear_estudio.id, label: datos[0].costear_estudio.nombre } : { 'valor': '' },
        otro_costear: datos[0].otro_costear ? { 'valor': datos[0].otro_costear } : { 'valor': '' },
        nivel_educacion: datos[0].nivel_educacion ? { 'label': datos[0].nivel_educacion.toUpperCase(), 'value': datos[0].nivel_educacion } : { 'valor': '' },
        jornada: datos[0].jornada ? { value: datos[0].jornada.id, label: datos[0].jornada.nombre } : { 'valor': '' },
        otro_jornada: datos[0].otro_jornada ? { 'valor': datos[0].otro_jornada } : { 'valor': '' },
        puntaje_pruebas: datos[0].puntaje_pruebas ? { 'valor': datos[0].puntaje_pruebas } : { 'valor': '' },
        correo: datos[0].estudiante.correo ? { 'valor': datos[0].estudiante.correo } : { 'valor': '' },
        telefono: datos[0].telefono1 ? { 'valor': datos[0].telefono1 } : { 'valor': '' },
        identificacion: datos[0].estudiante.identificacion ? { 'valor': datos[0].estudiante.identificacion } : { 'valor': '' },
        celular: datos[0].celular1 ? { 'valor': datos[0].celular1 } : { 'valor': '' },
        celular_padre: datos[0].celular_padre ? { 'valor': datos[0].celular_padre } : { 'valor': '' },
        celular_madre: datos[0].celular_madre ? { 'valor': datos[0].celular_madre } : { 'valor': '' },
        'programas': transformar(programas),
        'grupos': transformar(grupos),
        discapacidad: datos[0].discapacidad ? { value: datos[0].discapacidad.id, label: datos[0].discapacidad.nombre } : { 'valor': '' },
        programa: datos[0].carrera ? { 'label': datos[0].carrera.nombre, 'value': datos[0].carrera.id } : { 'label': '', 'value': '' },
        grupo: datos[0].grupo ? { 'label': datos[0].grupo.nombre, 'value': datos[0].grupo.id, 'codigo': datos[0].grupo.codigo} : { 'label': '', 'valor': '' },
        costear_estudio_otro: datos[0].otro_costear ? { 'valor': datos[0].otro_costear } : { 'valor': '' },
        jornada_otro: datos[0].otro_jornada ? { 'valor': datos[0].otro_jornada } : { 'valor': '' },
        generos: transformar(generos),
        jornada_preinscripcion: transformar(jornada_preinscripcion),
        lineas_edu_co: transformarEnviado(lineas_edu_co),
        costear_estudios: transformar(costear_estudios),
        discapacidades: transformar(discapacidades),
      })
    })
  }

  async obtenerContactoPreUniversitario(id) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'id', 'valor': id }]);
    let departamentos = await obtenerValoresFiltros([{ "llave": 'generica', 'valor': 7 }])
    let estratos = await obtenerValoresFiltros([{ "llave": 'generica', 'valor': 9 }])
    let eps = await obtenerValores(EPS);
    consulta(`api/v1.0/inscripcion/contacto/preuniversitario?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, contacto: resp })
      let { contacto } = this.state
      console.log(contacto)
      this.setState({
        barrio: contacto[0].barrio ? { 'valor': contacto[0].barrio } : { 'valor': '' },
        direccion: contacto[0].direccion ? { 'valor': contacto[0].direccion } : { 'valor': '' },
        departamento: { 'label': contacto[0].departamento.nombre, 'value': contacto[0].departamento.id },
        municipio: { 'label': contacto[0].ciudad.nombre, 'value': contacto[0].ciudad.id },
        'departamentos': transformar(departamentos),
        'estratos': transformar(estratos),
        estrato: { 'label': contacto[0].estrato.nombre, 'value': contacto[0].estrato.id },
        nombre_madre: contacto[0].nombre_madre ? { 'valor': contacto[0].nombre_madre } : { 'valor': '' },
        nombre_padre: contacto[0].nombre_padre ? { 'valor': contacto[0].nombre_padre } : { 'valor': '' },
        celular_madre: contacto[0].celular_madre ? { 'valor': contacto[0].celular_madre } : { 'valor': '' },
        celular_padre: contacto[0].celular_padre ? { 'valor': contacto[0].celular_padre } : { 'valor': '' },
        contactoEmergencia: contacto[0].contacto_emergencia || "",
        celularEmergencia: contacto[0].celular_emergencia || "",
        poseeEps: contacto[0].tiene_eps && { value: contacto[0].tiene_eps, label: contacto[0].tiene_eps.toUpperCase() } || { value: '' },
        nombreEps: contacto[0].nombre_eps && { value: contacto[0].nombre_eps.id, label: contacto[0].nombre_eps.nombre } || { value: '' },
        nombreEpsOtro: contacto[0].nombre_otro_eps || "",
        opcionesEps: transformar(eps),
      })
    });
  }

  async obtenerInscripcionPreUniversitarioGestion(id) {
    this.setState({ cargando: true });
    let colegios_asignados = await obtenerValoresFiltros([{ "llave": 'generica', 'valor': COLEGIOS_PREUNIVERSITARIO }])
    let metodos_pago = await obtenerValoresFiltros([{ "llave": 'generica', 'valor': METODO_PAGO }]);
    let calificaciones_contacto = await obtenerValoresFiltros([{ "llave": 'generica', 'valor': CALIFICACION_CONTACTO }]);
    let resultados_preuni = await obtenerValoresFiltros([{ "llave": 'generica', 'valor': RESULTADOS_PREUNIVERSITARIO }]);
    consulta(`api/v1.0/inscripcion/datos/preuniversitario/gestion/${id}?`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, gestion: resp });
      let { gestion } = this.state
      const transformarData = data => {
        return data.map(e => ({
          value: e.id,
          label: e.nombre,
          codigo: e.codigo,
        }));
      }
      this.setState({
        id_estudiante:gestion[0]?.inscripcion.estudiante.id,
        correo:gestion[0]?.inscripcion.estudiante.correo ? { 'valor':gestion[0]?.inscripcion.estudiante.correo } : { 'valor': '' },
        telefono:gestion[0]?.inscripcion.telefono1 ? { 'valor':gestion[0]?.inscripcion.telefono1 } : { 'valor': '' },
        identificacion:gestion[0]?.inscripcion.estudiante.identificacion ? { 'valor':gestion[0]?.inscripcion.estudiante.identificacion } : { 'valor': '' },
        celular:gestion[0]?.inscripcion.celular1 ? { 'valor':gestion[0]?.inscripcion.celular1 } : { 'valor': '' },
        celular_padre:gestion[0]?.inscripcion.celular_padre ? { 'valor':gestion[0]?.inscripcion.celular_padre } : { 'valor': '' },
        celular_madre:gestion[0]?.inscripcion.celular_madre ? { 'valor':gestion[0]?.inscripcion.celular_madre } : { 'valor': '' },
        colegio_asignado:gestion[0]?.colegio_asignado ? { 'label':gestion[0]?.colegio_asignado.nombre, 'value':gestion[0]?.colegio_asignado.id } : { 'label': '', 'value': '' },
        calificacion_contacto:gestion[0]?.calificacion_contacto ? { 'label':gestion[0]?.calificacion_contacto.nombre, 'value':gestion[0]?.calificacion_contacto.id } : { 'label': '', 'value': '' },
        metodo_pago:gestion[0]?.metodo_pago ? { 'label':gestion[0]?.metodo_pago.nombre, 'value':gestion[0]?.metodo_pago.id, 'codigo':gestion[0]?.metodo_pago.codigo } : { 'label': '', 'value': '', 'codigo': '' },
        'colegios_asignados': transformar(colegios_asignados),
        'resultados_preuni': transformar(resultados_preuni),
        'metodos_pago': transformarData(metodos_pago),
        'calificaciones_contacto': transformar(calificaciones_contacto),
        checkedIdentificacion:gestion[0]?.checkedIdentificacion,
        checkedCelular:gestion[0]?.checkedCelular,
        checkedTelefono:gestion[0]?.checkedTelefono,
        checkedCorreo:gestion[0]?.checkedCorreo,
        checkedCelular_madre:gestion[0]?.checkedCelular_madre,
        checkedCelular_padre:gestion[0]?.checkedCelular_padre,
        generacion_e:gestion[0]?.inscripcion.generacion ? { 'label':gestion[0]?.inscripcion.generacion.toUpperCase(), 'value':gestion[0]?.inscripcion.generacion } : { 'label': '', 'value': '' },
        nombre_financiero:gestion[0]?.nombre_financiero ? { 'valor':gestion[0]?.nombre_financiero } : { 'valor': '' },
        telefono_financiero:gestion[0]?.telefono_financiero ? { 'valor':gestion[0]?.telefono_financiero } : { 'valor': '' },
        nombre_banco:gestion[0]?.nombre_banco ? { 'valor':gestion[0]?.nombre_banco } : { 'valor': '' },
        resultado_preuniversitario:gestion[0]?.resultado_preuniversitario ? { 'label':gestion[0]?.resultado_preuniversitario.nombre, 'value':gestion[0]?.resultado_preuniversitario.id } : { 'label': '', 'value': '' },
      })
    })

  }

  async obtenerInscripcion(id) {
    this.setState({ cargando: true });
    let tipos_identificacion = await obtenerValoresFiltros([{ "llave": 'generica', 'valor': 2 }]);
    let grupos = await obtenerValoresFiltros([{ "llave": 'generica', 'valor': GRUPOS_INSCRIPCIONES }]);
    let f = await generarFiltros([{ 'llave': 'id', 'valor': id }]);
    consulta(`api/v1.0/inscripcion/datos?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, datos: resp });
      let { datos } = this.state
      this.setState({
        celular: datos[0].celular1 ? { 'valor': datos[0].celular1 } : { 'valor': '' },
        primer_nombre: datos[0].estudiante ? { 'valor': datos[0].estudiante.primer_nombre } : { 'valor': '' },
        segundo_nombre: datos[0].estudiante ? { 'valor': datos[0].estudiante.segundo_nombre } : { 'valor': '' },
        primer_apellido: datos[0].estudiante ? { 'valor': datos[0].estudiante.primer_apellido } : { 'valor': '' },
        segundo_apellido: datos[0].estudiante ? { 'valor': datos[0].estudiante.segundo_apellido } : { 'valor': '' },
        identificacion: datos[0].estudiante ? { 'valor': datos[0].estudiante.identificacion } : { 'valor': '' },
        correo: datos[0].estudiante ? { 'valor': datos[0].estudiante.correo } : { 'valor': '' },
        telefono: datos[0].telefono1 ? { 'valor': datos[0].telefono1 } : { 'valor': '' },
        tiene_sisben: datos[0].sisben,
        codigo_pruebas: datos[0].codigo_pruebas ? { 'valor': datos[0].codigo_pruebas } : { 'valor': '' },
        puntaje_pruebas: datos[0].puntaje_pruebas ? { 'valor': datos[0].puntaje_pruebas } : { 'valor': '' },
        puntaje_sisben: datos[0].puntaje_sisben ? { 'valor': datos[0].puntaje_sisben } : { 'valor': '' },
        id_estudiante: datos[0].estudiante.id,
        'grupos': transformar(grupos),
        grupo: datos[0].grupo ? { 'label': datos[0].grupo.nombre, 'value': datos[0].grupo.id } : { 'label': '', 'valor': '' },
        tipos_identificacion: tipos_identificacion,
        tipo_identificacion: datos[0].estudiante.tipo_identificacion.id

      })
    })
  }

  async obtenerAdjuntos(id) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'id', 'valor': id }]);
    let validacionDocs = await obtenerValores(VALIDACION_DOCUMENTOS_INSCRIPCIONES);
    consulta(`api/v1.0/inscripcion/adjuntos?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, documentos: resp, validacion_doc: validacionDocs.sort((a, b) => a.valorb - b.valorb).map(e => ({ id: e.id, nombre: e.nombre, valora: e.valora, estado: e.estado }))});
    })
  }

  async obtenerAdjuntosPreUniversitario(id) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'id', 'valor': id }]);
    let validacionDocs = await obtenerValores(VALIDACION_DOCUMENTOS_INSCRIPCIONES);//
    consulta(`api/v1.0/inscripcion/adjuntos?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, documentos: resp, validacion_doc: validacionDocs.sort((a, b) => a.valorb - b.valorb).map(e => ({ id: e.id, nombre: e.nombre, valora: e.valora, estado: e.estado })), });
    })
  }

  async obtenerContacto(id) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'id', 'valor': id }]);
    let departamentos = await obtenerValoresFiltros([{ "llave": 'generica', 'valor': 7 }])
    let estratos = await obtenerValoresFiltros([{ "llave": 'generica', 'valor': 9 }])
    consulta(`api/v1.0/inscripcion/contacto?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, contacto: resp });
      let { contacto } = this.state
      this.setState({
        barrio: { 'valor': contacto[0].barrio },
        direccion: { 'valor': contacto[0].direccion },
        departamento: { 'label': contacto[0].departamento.nombre, 'value': contacto[0].departamento.id },
        municipio: { 'label': contacto[0].ciudad.nombre, 'value': contacto[0].ciudad.id },
        'departamentos': transformar(departamentos),
        'estratos': transformar(estratos),
        estrato: { 'label': contacto[0].estrato.nombre, 'value': contacto[0].estrato.id },
      })
    })
  }

  async obtenerCarrera(id) {
    this.setState({ cargando: true });
    let programas = await obtenerValoresFiltros([{ "llave": 'generica', 'valor': 5 }])
    let opcion_conociste = await obtenerValoresFiltros([{ "llave": 'generica', 'valor': 6 }])
    let f = await generarFiltros([{ 'llave': 'id', 'valor': id }]);
    consulta(`api/v1.0/inscripcion/carrera?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, carrera: resp });
      let { carrera } = this.state
      this.setState({
        'programas': transformar(programas),
        'opcion_conociste': transformar(opcion_conociste),
        programa: { 'label': carrera[0].carrera.nombre, 'value': carrera[0].carrera.id },
        conocio: { 'label': carrera[0].conocio.nombre, 'value': carrera[0].conocio.id },
      })
    })
  }


  async cargarMunicipios(id) {

    let municipios = await obtenerPermisosValor(id);
    municipios = municipios.map(({ secundario }) => ({
      value: secundario.id,
      label: secundario.nombre,
    }));
    this.changeState('municipios', municipios);
    this.setState({ municipio: '' })

  }


  // cargarTipos = async () => {
  //   const tipos_identificacion = await obtenerValores(TIPOS_IDENTIFICACION);
  //   const departamentos = await obtenerValores(DEPARTAMENTOS);
  //   const comunidades = await obtenerValores(COMUNIDAD);
  //   const generos = await obtenerValores(GENERO);
  //   const requisitos = await obtenerValores(REQUISITOS_CONSULTORIA);
  //   const programas = await obtenerValores(5);
  //   const area_derecho = await obtenerValores(AREA_DERECHO);
  //   const validacionDocs = await obtenerValores(VALIDACION_DOCUMENTOS_INSCRIPCIONES);
  //   const jornada_preinscripcion = await obtenerValores(JORNADAPREINSCRIPCION);
  //   const lineas_edu_co = await obtenerValores(LINEAEDU);
  //   const costear_estudios = await obtenerValores(COSTEAR_ESTUDIOS);
  //   const discapacidades = await obtenerValores(DISCAPACIDADES_INSCRIPCIONES);
  //   this.setState({
  //     validacion_doc: validacionDocs.sort((a, b) => a.valorb - b.valorb).map(e => ({ id: e.id, nombre: e.nombre, valora: e.valora, estado: e.estado })),
  //     requisitos,
  //     tipos_identificacion: transformar(tipos_identificacion),
  //     departamentos: transformar(departamentos),
  //     comunidades: transformar(comunidades),
  //     generos: transformar(generos),
  //     programas: transformar(programas),
  //     area_derecho: transformar(area_derecho),
  //     jornada_preinscripcion: transformar(jornada_preinscripcion),
  //     lineas_edu_co: transformarEnviado(lineas_edu_co),
  //     costear_estudios: transformar(costear_estudios),
  //     discapacidades: transformar(discapacidades),
  //   })
  // }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: { 'valor': value }
    })
  }

  changeState = (name, value) => {
    this.setState({
      [name]: value
    })
  }

  onChangeSelect = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  handleChange = (event) => {
    let name = event.target.name
    let value = event.target.checked
    this.setState({
      [name]: value
    })
  }


  setDepartamento(e) {
    this.setState({
      departamento: { 'label': e.departamento.label, 'value': e.departamento.value }
    })
    this.cargarMunicipios(e.departamento.value)
  }


  conError(valor) {
    return valor ? true : false;
  }

  error(valor) {
    this.setState({
      [`${valor}.error`]: true
    })
  }

  pintarPreuniversitarioDatos() {
    let { datos,
      celular,
      programa,
      grupo,
      grupos,
      programas,
      identificacion,
      telefono,
      codigo_pruebas,
      puntaje_pruebas,
      correo,
      celular_madre,
      celular_padre,
      primer_nombre,
      segundo_nombre,
      primer_apellido,
      segundo_apellido,
      generos,
      genero,
      setGenero,
      opciones_estudiante,
      setOpcion_estudiante,
      estudiante_11,
      nombre_colegio,
      linea_edu,
      lineas_edu_co,
      setTipo_linea,
      carrera_pre_universitario,
      carrera_pre_universitario_2,
      edad,
      nivel_educacion,
      setTipo_nivel_educacion,
      setTipo_jornada,
      jornada_preinscripcion,
      jornada,
      otro_jornada,
      costear_estudio,
      otro_costear,
      setTipo_costear_estudio,
      niveles_educacion,
      costear_estudios,
      jornada_otro,
      costear_estudio_otro,
      discapacidad,
      discapacidades,
      adj_certificado,
      validacion_doc,
    } = this.state;

    if (datos[0]) {
      if (!codigo_pruebas.valor.includes('AC')) codigo_pruebas.valor = 'AC' + codigo_pruebas.valor; 
      return (
        <form style={{ margin: '15px' }} noValidate autoComplete="off">
          <Grid container spacing={3} style={{ marginBottom: '40px' }}>
            <Grid item md={6} xs={12} >
              <TextField
                value={primer_nombre.valor}
                type="text"
                id="primer_nombre"
                name="primer_nombre"
                label="Primer Nombre"
                error={this.conError(primer_nombre.error)}
                helperText={primer_nombre.error}
                fullWidth
                onChange={this.onChange}
              />
            </Grid>
            <Grid item md={6} xs={12} >
              <TextField
                value={segundo_nombre.valor}
                type="text"
                id="segundo_nombre"
                name="segundo_nombre"
                label="Segundo Nombre"
                error={this.conError(segundo_nombre.error)}
                helperText={segundo_nombre.error}
                fullWidth
                onChange={this.onChange}
              />
            </Grid>
            <Grid item md={6} xs={12} >
              <TextField
                value={primer_apellido.valor}
                type="text"
                id="primer_apellido"
                name="primer_apellido"
                label="Primer Apellido"
                error={this.conError(primer_apellido.error)}
                helperText={primer_apellido.error}
                fullWidth
                onChange={this.onChange}
              />
            </Grid>
            <Grid item md={6} xs={12} >
              <TextField
                value={segundo_apellido.valor}
                type="text"
                id="segundo_apellido"
                name="segundo_apellido"
                label="Segundo Apellido"
                error={this.conError(segundo_apellido.error)}
                helperText={segundo_apellido.error}
                fullWidth
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <BuscarSelect
                datos={generos}
                cambiarEstado={(value) => this.setState({ genero: value })}
                valor={genero}
                id='select-genero'
                nombre='Genero'
                placeholder='Selecciona Tu Genero'
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={identificacion.valor}
                type="number"
                id="identificacion"
                name="identificacion"
                label="Identificacion"
                error={this.conError(identificacion.error)}
                helperText={identificacion.error}
                fullWidth
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={edad.valor}
                type="number"
                id="edad"
                name="edad"
                label="edad"
                error={this.conError(edad.error)}
                helperText={edad.error}
                fullWidth
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={celular.valor}
                required
                type="number"
                id="celular"
                name="celular"
                label="Celular"
                error={this.conError(celular.error)}
                helperText={celular.error}
                fullWidth
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={telefono.valor}
                required
                type="number"
                id="telefono"
                name="telefono"
                label="Telefono"
                error={this.conError(telefono.error)}
                helperText={telefono.error}
                fullWidth
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={correo.valor}
                required
                type="text"
                id="correo"
                name="correo"
                label="Correo"
                error={this.conError(correo.error)}
                helperText={correo.error}
                fullWidth
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={12} >
              <TextField
                value={codigo_pruebas.valor}
                required
                type="text"
                id="codigo_pruebas"
                name="codigo_pruebas"
                label="Codigo Registro Pruebas Saber 11"
                error={this.conError(codigo_pruebas.error)}
                helperText={codigo_pruebas.error}
                inputProps={
                  {
                    maxLength: '14'
                  }
                }
                fullWidth
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={puntaje_pruebas.valor}
                required
                type="number"
                id="puntaje_pruebas"
                name="puntaje_pruebas"
                label="Puntaje Pruebas Saber 11"
                inputProps={
                  {
                    min: "1",
                    max: "500"
                  }
                }
                error={this.conError(puntaje_pruebas.error)}
                helperText={puntaje_pruebas.error}
                fullWidth
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <BuscarSelect
                datos={programas}
                cambiarEstado={(value) => this.setState({ programa: value })}
                valor={programa}
                id='select-carrera'
                nombre='Programa'
                placeholder='Seleccione Programa'
              />
            </Grid>
            <Grid item xs={12}>
              <BuscarSelect
                datos={grupos}
                cambiarEstado={(value) => this.setState({ grupo: value })}
                valor={grupo}
                id='select-grupo'
                nombre='Grupo'
                placeholder='Seleccione Comunidad Especial'
              />
            </Grid>
            {grupo.codigo === 'GRU_DIS' &&
              <Grid item xs={12}>
                <BuscarSelect
                  datos={discapacidades}
                  cambiarEstado={(value) => this.setState({ discapacidad: value })}
                  valor={discapacidad}
                  id='select-tipo-discapacidad'
                  nombre='¿Que tipo de discapacidad tienes?'
                  placeholder='Seleccione Discapacidad'
                />
              </Grid>
            }
            {/* {(grupo !== '' && grupo.codigo !== 'GRU_NIN') &&
              <Grid item xs={12}>
                <InputFile
                  label='Adjuntar Certificado medico de Discapacidad o Comunidad Especial'
                  id='adj_certificado'
                  value={adj_certificado}
                  required={(validacion_doc[3].valora === '1') ? true : false}
                />
              </Grid>
            } */}
            {/* <Grid item xs={12} >
              <TextField
                value={celular_madre.valor}
                required
                type="text"
                id="celular_madre"
                name="celular_madre"
                label="¿Cuál es el número de celular de su Madre?"
                fullWidth
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={12} >
              <TextField
                value={celular_padre.valor}
                required
                type="text"
                id="celular_padre"
                name="celular_padre"
                label="¿Cuál es el número de celular de su Padre?"
                fullWidth
                onChange={this.onChange}
              />
            </Grid> */}
            <Grid item xs={12}>
              <BuscarSelect
                datos={opciones_estudiante}
                cambiarEstado={(value) => this.setState({ estudiante_11: value })}
                valor={estudiante_11}
                id='select-tipo-estudiante_11'
                nombre='¿ Estás cursando 11° actualmente ?'
                placeholder='Seleccione Opción'
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={nombre_colegio.valor}
                type="text"
                id="nombre_colegio"
                name="nombre_colegio"
                label="Nombre Colegio"
                error={this.conError(nombre_colegio.error)}
                helperText={nombre_colegio.error}
                fullWidth
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <BuscarSelect
                datos={niveles_educacion}
                cambiarEstado={(value) => this.setState({ nivel_educacion: value })}
                valor={nivel_educacion}
                id='select-tipo-niveles_educacion'
                nombre='¿A que nivel de la educación superior planeas ingresar ?'
                placeholder='Seleccione Opción'
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={carrera_pre_universitario.valor}
                type="text"
                id="carrera_pre_universitario"
                name="carrera_pre_universitario"
                label="Carrera Pre-Seleccionada"
                error={this.conError(carrera_pre_universitario.error)}
                helperText={carrera_pre_universitario.error}
                fullWidth
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={carrera_pre_universitario_2.valor}
                type="text"
                id="carrera_pre_universitario_2"
                name="carrera_pre_universitario_2"
                label="Carrera Pre-Seleccionada-2"
                error={this.conError(carrera_pre_universitario_2.error)}
                helperText={carrera_pre_universitario_2.error}
                fullWidth
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <BuscarSelect
                datos={lineas_edu_co}
                cambiarEstado={(value) => this.setState({ linea_edu: value })}
                valor={linea_edu}
                id='select-tipo-EDU.co'
                nombre='¿En cuál linea EDU.co te quieres inscribir?'
                placeholder='Seleccione linea'
              />
            </Grid>
            <Grid item xs={12}>
              <BuscarSelect
                datos={jornada_preinscripcion}
                cambiarEstado={(value) => this.setState({ jornada: value })}
                valor={jornada}
                id='select-tipo-jornada'
                nombre='¿Qué disponilidad tienes para realizar este entrenamiento?'
                placeholder='Seleccione disponibilidad'
              />
            </Grid>
            {
              jornada && jornada.label == 'OTRO' &&
              <Grid item xs={12}>
                <TextField
                  value={jornada_otro.valor}
                  type="text"
                  id="jornada_otro"
                  name="jornada_otro"
                  label="otro"
                  error={this.conError(jornada_otro.error)}
                  helperText={jornada_otro.error}
                  fullWidth
                  onChange={this.onChange}
                />
              </Grid>
            }
            <Grid item xs={12}>
              <BuscarSelect
                datos={costear_estudios}
                cambiarEstado={(value) => this.setState({ costear_estudio: value })}
                valor={costear_estudio}
                id='select-tipo-costear_estudio'
                nombre='¿Cómo tienes pensado costear tus estudios de educación superior ?'
                placeholder='Seleccione Opción'
              />
            </Grid>
            {
              costear_estudio && costear_estudio.label == 'OTRO' &&
              <Grid item xs={12}>
                <TextField
                  value={costear_estudio_otro.valor}
                  type="text"
                  id="costear_estudio_otro"
                  name="costear_estudio_otro"
                  label="Ingrese Otra Forma de Costear sus Estudios"
                  error={this.conError(costear_estudio_otro.error)}
                  helperText={costear_estudio_otro.error}
                  fullWidth
                  onChange={this.onChange}
                />
              </Grid>
            }
          </Grid>
          <Button size="large" variant="contained" style={{ color: 'white' }}
            color="primary" onClick={this.onSubmitDatosPreuniversitario} disableElevation>
            ACTUALIZAR
          </Button>
        </form>
      )
    };
  }

  pintarContactoPreuniversitario = () => {
    let { contacto } = this.state;
    if (contacto[0]) return this.InscripcionDetalleContactoPreuniversitario(contacto[0])
  }
  InscripcionDetalleContactoPreuniversitario = (inscripcion) => {

    let {
      departamentos,
      departamento,
      municipios,
      municipio,
      estratos,
      estrato,
      barrio,
      direccion,
      nombre_madre,
      celular_madre,
      nombre_padre,
      celular_padre,
      opcionesPoseeEps,
      poseeEps,
      opcionesEps,
      nombreEps,
      nombreEpsOtro,
      contactoEmergencia,
      celularEmergencia,
    } = this.state

    return (
      <form style={{ margin: '15px' }} noValidate autoComplete="off">
        <Grid container spacing={3} style={{ marginBottom: '40px' }}>
          <Grid item xs={12}>
            <BuscarSelect
              datos={departamentos}
              cambiarEstado={(value) => this.setDepartamento({ departamento: value })}
              valor={departamento}
              id='select-departamento'
              nombre='¿En que Departamento vives?'
              placeholder='Seleccione Opción'
            />
          </Grid>
          <Grid item xs={12}>
            <BuscarSelect
              datos={municipios}
              cambiarEstado={(value) => this.setState({ municipio: value })}
              valor={municipio}
              id='select-municipio'
              nombre='¿En que Ciudad o Municipio vives?'
              placeholder='Seleccione una Opción'
            />
          </Grid>
          <Grid item md={6} xs={12} >
            <TextField
              value={barrio.valor}
              required
              type="text"
              id="barrio"
              name="barrio"
              label="¿En que Barrio vives?"
              fullWidth
              onChange={this.onChange}
            />
          </Grid>
          <Grid item md={6} xs={12} >
            <TextField
              value={direccion.valor}
              required
              type="text"
              id="direccion"
              name="direccion"
              label="¿Cuál es tu dirección de residencia?"
              fullWidth
              onChange={this.onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <BuscarSelect
              datos={estratos}
              cambiarEstado={(value) => this.setState({ estrato: value })}
              valor={estrato}
              id='select-estrato'
              nombre='¿Cual es el Estrato de tu vivienda?'
              placeholder='Seleccione una Opción'
            />
          </Grid>
          <Grid item xs={12}>
          <BuscarSelect datos={opcionesPoseeEps} cambiarEstado={(value) => this.setState({poseeEps: value})} valor={poseeEps} id='select-eps' nombre='¿Posee EPS?' placeholder='Seleccione Opción' />
        </Grid>
        {poseeEps && poseeEps.value === 'si' && 
        <Grid item xs={12}>
          <BuscarSelect datos={opcionesEps} cambiarEstado={(value) => this.setState({nombreEps: value})} valor={nombreEps} id='select-nombre-eps' nombre='¿Cual es el nombre de la EPS?' placeholder='Seleccione Opción' />
        </Grid>
        }

        {poseeEps && poseeEps.value === 'si' && nombreEps.value === EPS_OTRO && 
        <Grid item xs={12}>
            <TextField
              value={nombreEpsOtro}
              required
              id="nombre_eps"
              name="nombreEpsOtro"
              label="Escriba nombre de la EPS"
              fullWidth
              autoComplete="fname"
              onChange={(e) => this.setState({ nombreEpsOtro: e.target.value })}
            />
        </Grid>
        }
          <Grid item md={12} xs={12} >
            <TextField
              value={nombre_madre.valor}
              required
              type="text"
              id="nombre_madre"
              name="nombre_madre"
              label="¿Cuál es el nombre de la Madre?"
              fullWidth
              onChange={this.onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type='number'
              value={celular_madre.valor}
              required
              id="celular_madre"
              name="celular_madre"
              label="¿Cuál es el número de celular de tu madre?"
              fullWidth
              autoComplete="fname"
              onChange={this.onChange}
            />
          </Grid>
          <Grid item md={12} xs={12} >
            <TextField
              value={nombre_padre.valor}
              required
              type="text"
              id="nombre_padre"
              name="nombre_padre"
              label="¿Cuál es el nombre del Padre?"
              fullWidth
              onChange={this.onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={celular_padre.valor}
              required
              type='number'
              id="celular_padre"
              name="celular_padre"
              label="¿Cuál es el número de celular de su Padre?"
              fullWidth
              autoComplete="fname"
              onChange={this.onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={contactoEmergencia}
              required
              id="contacto_emergencia"
              name="contactoEmergencia"
              label="¿Nombre de contacto de emergencia?"
              fullWidth
              autoComplete="fname"
              onChange={(e) => this.setState({ contactoEmergencia: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={celularEmergencia}
              required
              type='number'
              id="celular_emergencia"
              name="celularEmergencia"
              label="¿Cuál es el número de celular de emergencia?"
              fullWidth
              autoComplete="fname"
              onChange={(e) => this.setState({ celularEmergencia: e.target.value })}
            />
          </Grid>
        </Grid>
        <Button size="large" variant="contained" style={{ color: 'white' }}
          color="primary" disableElevation onClick={this.onSubmitContacto}>
          ACTUALIZAR
        </Button>
      </form>
    )
  }

  pintarInscripcion() {
    let { datos, celular, primer_nombre, primer_apellido, segundo_nombre, segundo_apellido, identificacion, telefono,
      codigo_pruebas, puntaje_pruebas, puntaje_sisben, correo, tipo_identificacion, tipos_identificacion,
      grupo, grupos, tiene_sisben, opciones_sisben } = this.state;
    if (datos[0]) {
      return (
        <form style={{ margin: '15px' }} noValidate autoComplete="off">
          <Grid container spacing={3} style={{ marginBottom: '40px' }}>
            <Grid item md={6} xs={12} >
              <TextField
                value={primer_nombre.valor}
                required
                type="text"
                id="primer_nombre"
                name="primer_nombre"
                label="Primer Nombre"
                error={this.conError(primer_nombre.error)}
                helperText={primer_nombre.error}
                fullWidth
                onChange={this.onChange}
              />
            </Grid>
            <Grid item md={6} xs={12} >
              <TextField
                value={segundo_nombre.valor}
                type="text"
                id="segundo_nombre"
                name="segundo_nombre"
                label="Segundo Nombre"
                fullWidth
                onChange={this.onChange}
              />
            </Grid>
            <Grid item md={6} xs={12} >
              <TextField
                value={primer_apellido.valor}
                required
                type="text"
                id="primer_apellido"
                name="primer_apellido"
                label="Primer Apellido"
                error={this.conError(primer_apellido.error)}
                helperText={primer_apellido.error}
                fullWidth
                onChange={this.onChange}
              />
            </Grid>
            <Grid item md={6} xs={12} >
              <TextField
                value={segundo_apellido.valor}
                type="text"
                id="segundo_apellido"
                name="segundo_apellido"
                label="Segundo Apellido"
                error={this.conError(segundo_apellido.error)}
                helperText={segundo_apellido.error}
                fullWidth
                onChange={this.onChange}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl className='form-control'>
                <InputLabel>Tipo Identificacion</InputLabel>
                <Select
                  id='tipos_identificacion'
                  value={tipo_identificacion}
                  onChange={this.onChangeSelect}
                  name='tipo_identificacion'
                >
                  {
                    tipos_identificacion.map((value) => (

                      <MenuItem key={value.id} value={value.id}>
                        {value.nombre}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12} >
              <TextField
                value={identificacion.valor}
                type="number"
                id="identificacion"
                name="identificacion"
                label="Identificacion"
                error={this.conError(identificacion.error)}
                helperText={identificacion.error}
                fullWidth
                onChange={this.onChange}
              />
            </Grid>
            <Grid item md={6} xs={12} >
              <TextField
                value={codigo_pruebas.valor}
                required
                type="text"
                id="codigo_pruebas"
                name="codigo_pruebas"
                label="Codigo Registro Pruebas Saber 11"
                error={this.conError(codigo_pruebas.error)}
                helperText={codigo_pruebas.error}
                inputProps={
                  {
                    maxLength: '14'
                  }
                }
                fullWidth
                onChange={this.onChange}
              />
            </Grid>
            <Grid item md={6} xs={12} >
              <TextField
                value={correo.valor}
                required
                type="text"
                id="correo"
                name="correo"
                label="Correo"
                error={this.conError(correo.error)}
                helperText={correo.error}
                fullWidth
                onChange={this.onChange}
              />
            </Grid>
            <Grid item md={6} xs={12} >
              <TextField
                value={celular.valor}
                required
                type="number"
                id="celular"
                name="celular"
                label="Celular"
                error={this.conError(celular.error)}
                helperText={celular.error}
                fullWidth
                onChange={this.onChange}
              />
            </Grid>
            <Grid item md={6} xs={12} >
              <TextField
                value={telefono.valor}
                required
                type="number"
                id="telefono"
                name="telefono"
                label="Telefono"
                error={this.conError(telefono.error)}
                helperText={telefono.error}
                fullWidth
                onChange={this.onChange}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <BuscarSelect datos={grupos} cambiarEstado={(value) => this.setState({ grupo: value })} valor={grupo} id='select-grupo' nombre='Grupo' placeholder='Seleccione Grupo' />
            </Grid>
            <Grid item md={6} xs={12} >
              <TextField
                value={puntaje_pruebas.valor}
                required
                type="number"
                id="puntaje_pruebas"
                name="puntaje_pruebas"
                label="Puntaje Pruebas Saber 11"
                inputProps={
                  {
                    min: "1",
                    max: "500"
                  }
                }
                error={this.conError(puntaje_pruebas.error)}
                helperText={puntaje_pruebas.error}
                fullWidth
                onChange={this.onChange}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl className='form-control'>
                <InputLabel>Tiene Sisben</InputLabel>
                <Select
                  id='tiene_sisben'
                  value={tiene_sisben}
                  onChange={this.onChangeSelect}
                  required
                  name='tiene_sisben'
                  defaultValue={'si'}
                >
                  {
                    opciones_sisben.map((value) => (

                      <MenuItem key={value.value} value={value.value}>
                        {value.label}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            {tiene_sisben === 'si' &&
              <Grid item md={6} xs={12} >
                <TextField
                  value={puntaje_sisben.valor}
                  type="text"
                  id="puntaje_sisben"
                  name="puntaje_sisben"
                  label="Puntaje Sisben"
                  error={this.conError(puntaje_sisben.error)}
                  helperText={puntaje_sisben.error}
                  fullWidth
                  onChange={this.onChange}
                />
              </Grid>
            }
          </Grid>
          <Button size="large" variant="contained" style={{ color: 'white' }}
            color="primary" onClick={this.onSubmitDatos} disableElevation>
            ACTUALIZAR
          </Button>
        </form>
      )
    };
  }

  pintarCarrera = () => {
    let { programa, programas, carrera, conocio, opcion_conociste } = this.state;
    if (carrera[0]) {
      return (
        <form style={{ margin: '15px' }} noValidate autoComplete="off">
          <Grid container spacing={3} style={{ marginBottom: '40px' }}>
            <Grid item xs={12}>
              <BuscarSelect datos={programas} cambiarEstado={(value) => this.setState({ programa: value })} valor={programa} id='select-carrera' nombre='Programa' placeholder='Seleccione Programa' />
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginBottom: '40px' }}>
            <Grid item xs={12}>
              <BuscarSelect datos={opcion_conociste} cambiarEstado={(value) => this.setState({ conocio: value })} valor={conocio} id='select-conociste' nombre='Conociste' placeholder='Seleccione opcion Conociste' />
            </Grid>
          </Grid>
          <Button size="large" variant="contained" style={{ color: 'white' }}
            color="primary" disableElevation onClick={this.enviarCarrera}>
            ACTUALIZAR
          </Button>
        </form>
      )
    }
  }

  pintarVerificar = () => {
    let { datos, celular, identificacion, telefono, correo, checkedIdentificacion,
      checkedCelular, checkedTelefono, checkedCorreo, celular_madre, celular_padre,
      checkedCelular_madre, checkedCelular_padre } = this.state;
    if (datos[0]) {
      return (
        <form style={{ margin: '15px' }} noValidate autoComplete="off">
          <Grid container spacing={3} style={{ marginBottom: '40px' }}>
            <Grid item xs={10} >
              <TextField
                value={identificacion.valor}
                type="number"
                disabled
                id="identificacion"
                name="identificacion"
                label="Escribe tu número de documento sin puntos ni comas"
                fullWidth

              />
            </Grid>
            <Grid item xs={2} >
              <Checkbox
                checked={checkedIdentificacion}
                label="Verificado"
                name="checkedIdentificacion"
                onChange={this.handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
            <Grid item xs={10} >
              <TextField
                value={celular.valor}
                disabled
                type="number"
                id="celular"
                name="celular"
                label="Regálanos un teléfono donde contactarte "
                fullWidth
              />
            </Grid>
            <Grid item xs={2} >
              <Checkbox
                checked={checkedCelular}
                label="Verificado"
                name="checkedCelular"
                onChange={this.handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
            <Grid item xs={10} >
              <TextField
                value={telefono.valor}
                disabled
                type="number"
                id="telefono"
                name="telefono"
                label="Regálanos otro número de teléfono donde contactarte "
                fullWidth
              />
            </Grid>
            <Grid item xs={2} >
              <Checkbox
                checked={checkedTelefono}
                label="Verificado"
                name="checkedTelefono"
                onChange={this.handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
            <Grid item xs={10} >
              <TextField
                value={correo.valor}
                disabled
                type="text"
                id="correo"
                name="correo"
                label="Escribe tu correo electrónico personal "
                fullWidth
              />
            </Grid>
            <Grid item xs={2} >
              <Checkbox
                checked={checkedCorreo}
                label="Verificado"
                name="checkedCorreo"
                onChange={this.handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
            <Grid item xs={10} >
              <TextField
                value={celular_madre.valor}
                disabled
                type="text"
                id="celular_madre"
                name="celular_madre"
                label="¿Cuál es el número de celular de tu madre?  "
                fullWidth
              />
            </Grid>
            <Grid item xs={2} >
              <Checkbox
                checked={checkedCelular_madre}
                label="Verificado"
                name="checkedCelular_madre"
                onChange={this.handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
            <Grid item xs={10} >
              <TextField
                value={celular_padre.valor}
                disabled
                type="text"
                id="celular_padre"
                name="celular_padre"
                label="¿Cuál es el número de celular de tu padre?  "
                fullWidth
              />
            </Grid>
            <Grid item xs={2} >
              <Checkbox
                checked={checkedCelular_padre}
                label="Verificado"
                name="checkedCelular_padre"
                onChange={this.handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>

          </Grid>
          <Button size="large" variant="contained" style={{ color: 'white' }}
            color="primary" onClick={this.enviarVerificadoPreUniversitario} disableElevation>
            ACTUALIZAR
          </Button>
        </form>
      )
    };

  }

  pintarGestionarMatricula = () => {
    let { colegio_asignado, colegios_asignados, calificacion_contacto, calificaciones_contacto, nombre_banco,
      nombre_financiero, telefono_financiero, metodo_pago, metodos_pago, generaciones_e, generacion_e,
      resultado_preuniversitario, resultados_preuni } = this.state;
    if (generacion_e.value === 'otro') generacion_e = { 'label': 'ESPERANDO RESULTADO', 'value': generacion_e.value }
    return (
      <form style={{ margin: '15px' }} noValidate autoComplete="off">
        <Grid container spacing={3} style={{ marginBottom: '40px' }}>
          <Grid item xs={12}>
            <BuscarSelect datos={colegios_asignados} cambiarEstado={(value) => this.setState({ colegio_asignado: value })} valor={colegio_asignado} id='select-colegioAsignado' nombre='Colegio Asignado' placeholder='Seleccione Colegio Asignado' />
          </Grid>
          <Grid item xs={12}>
            <BuscarSelect datos={calificaciones_contacto} cambiarEstado={(value) => this.setState({ calificacion_contacto: value })} valor={calificacion_contacto} id='select-calificacion' nombre='Calificacion Contacto' placeholder='Seleccione Calificacion' />
          </Grid>
          <Grid item xs={12}>
            <BuscarSelect datos={generaciones_e} cambiarEstado={(value) => this.setState({ generacion_e: value })} valor={generacion_e} id='select-calificacion' nombre='¿ Es generacion E?' placeholder='Seleccione dato' />
          </Grid>
          <Grid item xs={12} >
            <TextField
              value={nombre_financiero.valor}
              required
              type="text"
              id="nombre_financiero"
              name="nombre_financiero"
              label="¿Cuál es el nombre del Responsable Financiero? "
              error={this.conError(nombre_financiero.error)}
              helperText={nombre_financiero.error}
              onChange={this.onChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} >
            <TextField
              value={telefono_financiero.valor}
              required
              type="number"
              id="telefono_financiero"
              name="telefono_financiero"
              label="¿Cuál es el número del Responsable Financiero? "
              error={this.conError(telefono_financiero.error)}
              helperText={telefono_financiero.error}
              onChange={this.onChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <BuscarSelect datos={metodos_pago} cambiarEstado={(value) => this.setState({ metodo_pago: value })} valor={metodo_pago} id='select-metodo' nombre='Metodo Pago' placeholder='Seleccione Metodo Pago' />
          </Grid>
          {metodo_pago.codigo === 'Cre_Corto_Plazo' &&
            <Grid item xs={12} >
              <TextField
                value={nombre_banco.valor}
                type="text"
                id="nombre_banco"
                name="nombre_banco"
                label="Nombre Banco"
                fullWidth
                onChange={this.onChange}
              />
            </Grid>
          }
          <Grid item xs={12}>
            <BuscarSelect datos={resultados_preuni} cambiarEstado={(value) => this.setState({ resultado_preuniversitario: value })} valor={resultado_preuniversitario} id='select-metodo' nombre='Resultado PreUniversitario' placeholder='Seleccione Resultado PreUniversitario' />
          </Grid>

        </Grid>
        <Button size="large" variant="contained" style={{ color: 'white' }}
          color="primary" onClick={this.onSubmitGestionMatricula} disableElevation>
          ACTUALIZAR
        </Button>
      </form>
    )

  }

  pintarContacto = () => {
    let { contacto, barrio, direccion, estrato, estratos, municipio, municipios, departamento, departamentos } = this.state;
    if (contacto[0]) {
      return (
        <form style={{ margin: '15px' }} noValidate autoComplete="off">
          <Grid container spacing={3} style={{ marginBottom: '40px' }}>
            <Grid item xs={12}>
              <BuscarSelect datos={departamentos} cambiarEstado={(value) => this.setDepartamento({ departamento: value })} valor={departamento} id='select-departamento' nombre='En que Departamento' placeholder='Seleccione Departamento' />
            </Grid>
            <Grid item xs={12}>
              <BuscarSelect datos={municipios} cambiarEstado={(value) => this.setState({ municipio: value })} valor={municipio} id='select-municipio' nombre='Municipio' placeholder='Seleccione Municipio' />
            </Grid>
            <Grid item md={6} xs={12} >
              <TextField
                value={barrio.valor}
                required
                type="text"
                id="barrio"
                name="barrio"
                label="Barrio"
                error={this.conError(barrio.error)}
                helperText={barrio.error}
                fullWidth
                onChange={this.onChange}
              />
            </Grid>
            <Grid item md={6} xs={12} >
              <TextField
                value={direccion.valor}
                type="text"
                id="direccion"
                name="direccion"
                label="Direccion"
                fullWidth
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <BuscarSelect datos={estratos} cambiarEstado={(value) => this.setState({ estrato: value })} valor={estrato} id='select-departamento' nombre='Estratos' placeholder='Seleccione Estratos' />
            </Grid>
          </Grid>
          <Button size="large" variant="contained" style={{ color: 'white' }}
            color="primary" disableElevation onClick={this.onSubmitContacto}>
            ACTUALIZAR
          </Button>
        </form>
      )
    };
  }

  pintarDocs = () => {
    let { documentos, adj_diploma, adj_identificacion, adj_saber, adj_docu_notas, adj_certificado, validacion_doc } = this.state;
    let { vistaDetalle } = this.props
    let docs = []
    if (documentos[0]) {
      let {
        adj_identificacion,
        adj_saber,
        adj_volante,
        adj_diploma,
        adj_docu_notas,
        adj_certificado,
        adj_idpadres,
        adj_estudio_homologacion,
        fecha_registro,
        fecha_envio
      } = documentos[0];

      if (adj_identificacion) docs.push({ nombre_doc: 'Identificación', adj: adj_identificacion, fecha: fecha_registro })
      if (adj_saber) docs.push({ nombre_doc: 'Pruebas Saber', adj: adj_saber, fecha: fecha_registro })
      if (adj_diploma) docs.push({ nombre_doc: 'Diploma', adj: adj_diploma, fecha: fecha_registro })
      if (true) docs.push({ nombre_doc: 'Certificado Medico de Discapacidad o Comunidad Especial', adj: adj_certificado, fecha: fecha_registro })
      if (adj_idpadres) docs.push({ nombre_doc: 'Identificacion Padres', adj: adj_idpadres, fecha: fecha_registro })
      if (adj_volante) docs.push({ nombre_doc: 'Volante', adj: adj_volante, fecha: fecha_envio || fecha_registro })
      if (adj_estudio_homologacion) docs.push({ nombre_doc: 'Estudio de Homologación', adj: adj_estudio_homologacion, fecha: fecha_envio || fecha_registro })
      if (adj_docu_notas) docs.push({ nombre_doc: 'Notas', adj: adj_docu_notas, fecha: fecha_envio || fecha_registro })

    }

    const acciones = (adj) => {
      let detalle = <a href={adj} rel="noopener noreferrer" target="_blank" className='sin_decorador'><BtnDetalle callback={() => { }} color='#01579b' texto='Abrir' /></a>;
      return <div>{detalle}</div>;
    }

    return (
      <>
        <ListarDatos
          datos={docs}
          id='tbl_docs_inscripcion'
          avatar={({ nombre_doc }) => nombre_doc.charAt(0)}
          titulo=''
          acciones={({ adj }) => acciones(adj)}
          fila_principal={({ nombre_doc }) => nombre_doc}
          filas={[
            { 'id': 'fecha', 'mostrar': ({ fecha }) => moment(fecha).format('YYYY-MM-DD, h:mm a'), 'nombre': 'Fecha: ' },
          ]}
        />
        <form style={{ margin: '15px' }} noValidate autoComplete="off">
          <Grid container spacing={3} style={{ marginBottom: '40px' }}>
            <Grid item xs={12} className='oculto'>
              <TextField
                ref={this.fileInput}
                required
                type="file"
                id="adj_diploma"
                name="adj_diploma"
                label="Adjuntar Diploma"
                fullWidth
                autoComplete="billing address-line1"
                onChange={this.onChangeSelect}
              />
              <TextField
                ref={this.fileInput}
                required
                type="file"
                id="adj_identificacion"
                name="adj_identificacion"
                label="Adjuntar Identificacion Ambas Caras"
                fullWidth
                autoComplete="billing address-line1"
                onChange={this.onChangeSelect}
              />
              <TextField
                ref={this.fileInput}
                required
                type="file"
                id="adj_certificado"
                name="adj_certificado"
                label="Adjuntar Certificado"
                fullWidth
                autoComplete="billing address-line1"
                onChange={this.onChangeSelect}
              />
              <TextField
                ref={this.fileInput}
                required
                type="file"
                id="adj_docu_notas"
                name="adj_docu_notas"
                label="Adjuntar Notas"
                fullWidth
                autoComplete="billing address-line1"
                onChange={this.onChangeSelect}
              />
              <TextField
                ref={this.fileInput}
                required
                type="file"
                id="adj_saber"
                name="adj_saber"
                label="Adjuntar Saber"
                fullWidth
                autoComplete="billing address-line1"
                onChange={this.onChangeSelect}
              />
            </Grid>
            <Grid item xs={12}>
              <InputFile label='Adjuntar Identificacion Ambas Caras' id='adj_identificacion' value={adj_identificacion} required={( validacion_doc[0] && validacion_doc[0].valora === '1') ? true : false} />
            </Grid>
            <Grid item xs={12}>
              <InputFile label='Adjuntar Diploma o Acta de Grado' id='adj_diploma' value={adj_diploma} required={(validacion_doc[2] && validacion_doc[2].valora === '1') ? true : false} />
            </Grid>
            <Grid item xs={12}>
              <InputFile label='Adjuntar Certificado medico de Discapacidad o Comunidad Especial' id='adj_certificado' value={adj_certificado} required={(validacion_doc[3] && validacion_doc[3].valora === '1') ? true : false} />
            </Grid>
            {vistaDetalle === 'Tipo_Homo' && (<Grid item xs={12}>
              <InputFile label='Adjuntar Certificado de Notas' id='adj_docu_notas' value={adj_docu_notas} required={(validacion_doc[4] && validacion_doc[4].valora === '1') ? true : false} />
            </Grid>
            )}
            {vistaDetalle === 'Tipo_PreUni' && (<Grid item xs={12}>
              <InputFile label='Adjuntar Pruebas Saber' id='adj_saber' value={adj_saber} required={(validacion_doc[3] && validacion_doc[3].valora === '1') ? true : false} />
            </Grid>
            )}
            <Grid item xs={12}>
              <Button size="large" variant="contained" style={{ color: 'white' }}
                color="primary" disableElevation onClick={this.onSubmitDocumento}>
                ACTUALIZAR
              </Button>
            </Grid>
          </Grid>
        </form>
      </>
    )
  }

  pintar = () => {
    let { seleccion } = this.state;
    return (
      <div>
        {(seleccion === 'Tipo_Ins' || seleccion === 'Tipo_Homo') && this.pintarInscripcion()}
        {seleccion === 'documentos' && this.pintarDocs()}
        {seleccion === 'contacto' && this.pintarContacto()}
        {seleccion === 'carrera' && this.pintarCarrera()}
      </div>
    )
  }

  pintarPreuniversitario = () => {
    let { seleccion } = this.state;
    return (
      <div>
        {seleccion === 'Tipo_PreUni' && this.pintarPreuniversitarioDatos()}
        {seleccion === 'documentos' && this.pintarDocs()}
        {seleccion === 'Contacto' && this.pintarContactoPreuniversitario()}
        {seleccion === 'verificado' && this.pintarVerificar()}
        {seleccion === 'gestion_matricula' && this.pintarGestionarMatricula()}
      </div>
    )
  }


  onSubmitDocumento = (e) => {
    let { adj_diploma, adj_identificacion, adj_certificado, adj_saber, adj_docu_notas } = this.state;
    let { actualizarMensaje, vistaDetalle } = this.props
    let error = false
    let vacio = false
    let adj_di = document.getElementById('adj_diploma').files[0];
    let adj_ident = document.getElementById('adj_identificacion').files[0];
    let adj_cert = document.getElementById('adj_certificado').files[0];
    let adj_sa = document.getElementById('adj_saber').files[0];
    let adj_no = document.getElementById('adj_docu_notas').files[0];

    if (vistaDetalle === 'Tipo_Ins' || vistaDetalle === 'Tipo_Homo') {
      if (adj_diploma && !adj_di) { error = true }
      if (adj_identificacion && !adj_ident) { error = true }
      if (adj_certificado && !adj_cert) { error = true }
      if (adj_docu_notas && !adj_no) { error = true }
      if (!adj_diploma && !adj_identificacion && !adj_certificado && !adj_docu_notas) { vacio = true }
      if (!error && !vacio) { this.enviarDocumento(adj_di, adj_ident, adj_cert, adj_sa, adj_no); }
      if (vacio) { actualizarMensaje({ titulo: 'No se ha cargado ningun documento', tipo: 'info', mostrar: true, tiempo: 6000 }); }
      if (error) { actualizarMensaje({ titulo: 'Los Documentos no subieron correctamente vuelva a subirlos', tipo: 'info', mostrar: true, tiempo: 6000 }); }
    } else {
      if (adj_diploma && !adj_di) { error = true }
      if (adj_identificacion && !adj_ident) { error = true }
      if (adj_saber && !adj_sa) { error = true }
      if (adj_docu_notas && !adj_no) { error = true }
      if (!adj_diploma && !adj_identificacion && !adj_saber && !adj_docu_notas) { vacio = true }
      if (!error && !vacio) this.enviarDocumento(adj_di, adj_ident, adj_cert, adj_sa, adj_no)
      if (vacio) { actualizarMensaje({ titulo: 'No se ha cargado ningun documento', tipo: 'info', mostrar: true, tiempo: 6000 }); }
      if (error) { actualizarMensaje({ titulo: 'Los Documentos no subieron correctamente vuelva a subirlos', tipo: 'info', mostrar: true, tiempo: 6000 }); }
    }

  }


  enviarDocumento = async (adj_di, adj_ident, adj_cert, adj_sa, adj_no) => {
    let { id_estudiante, adj_diploma, adj_identificacion, adj_certificado, adj_saber, adj_docu_notas } = this.state;
    this.setState({ cargando: true, titulo_res: 'Enviando..' })
    let data = await crear_form_data({
      adj_diploma,
      adj_identificacion,
      adj_certificado,
      adj_ident,
      adj_docu_notas,
      adj_di,
      adj_cert,
      adj_sa,
      adj_no,
      adj_saber,
      id_estudiante,
    })
    this.props.guardarDatosDocumentos(data, () => this.setState({ cargando: false, titulo_res: 'Cargando..' }));
    this.reiniciarEstado()

  }


  onSubmitDatos = (e) => {
    let { celular, primer_nombre, primer_apellido, identificacion, telefono,
      codigo_pruebas, puntaje_pruebas, correo } = this.state;
    let { actualizarMensaje } = this.props
    let obligatorio = 'Este campo es Obligatorio';
    let numero = 'Este campo debe ser numerico';
    let error = false;
    if (!telefono.valor) { telefono.error = obligatorio; error = true; this.error(telefono) }
    else if (!Number(telefono.valor)) { telefono.error = numero; error = true; this.error(telefono) }
    if (!celular.valor) { celular.error = obligatorio; error = true; this.error(celular) }
    else if (!Number(celular.valor)) { celular.error = numero; error = true; this.error(celular) }
    if (!identificacion.valor) { identificacion.error = obligatorio; error = true; this.error(identificacion) }
    else if (!Number(identificacion.valor)) { identificacion.error = numero; error = true; this.error(identificacion) }
    if (!puntaje_pruebas.valor) { puntaje_pruebas.error = obligatorio; error = true; this.error(puntaje_pruebas) }
    else if (!Number(puntaje_pruebas.valor)) { puntaje_pruebas.error = numero; error = true; this.error(puntaje_pruebas) }
    if (!primer_nombre.valor) { primer_nombre.error = obligatorio; error = true; this.error(primer_nombre) }
    if (!primer_apellido.valor) { primer_apellido.error = obligatorio; error = true; this.error(primer_apellido) }
    if (!correo.valor) { correo.error = obligatorio; error = true; this.error(correo) }
    if (!codigo_pruebas.valor) { codigo_pruebas.error = obligatorio; error = true; this.error(codigo_pruebas) }
    if (!error) this.enviarDatos();
    else actualizarMensaje({ titulo: 'Revise el Formulario', tipo: 'info', mostrar: true, tiempo: 6000 });
  }

  onSubmitDatosPreuniversitario = (e) => {
    let { celular, identificacion, telefono, codigo_pruebas, puntaje_pruebas, correo, celular_madre, celular_padre, grupo } = this.state;
    let { actualizarMensaje } = this.props
    let obligatorio = 'Este campo es Obligatorio';
    let numero = 'Este campo debe ser numerico';
    let error = false;
    if (!telefono.valor) { telefono.error = obligatorio; error = true; this.error(telefono) }
    else if (!Number(telefono.valor)) { telefono.error = numero; error = true; this.error(telefono) }
    if (!celular.valor) { celular.error = obligatorio; error = true; this.error(celular) }
    // else if (!Number(celular.valor)) { celular.error = numero; error = true; this.error(celular) }
    // if (!celular_madre.valor) { celular_madre.error = obligatorio; error = true; this.error(celular_madre) }
    // else if (!Number(celular_madre.valor)) { celular_madre.error = numero; error = true; this.error(celular_madre) }
    // if (!celular_padre.valor) { celular_padre.error = obligatorio; error = true; this.error(celular_padre) }
    // else if (!Number(celular_padre.valor)) { celular_padre.error = numero; error = true; this.error(celular_padre) }
    if (!identificacion.valor) { identificacion.error = obligatorio; error = true; this.error(identificacion) }
    else if (!Number(identificacion.valor)) { identificacion.error = numero; error = true; this.error(identificacion) }
    // if (!puntaje_pruebas.valor) { puntaje_pruebas.error = obligatorio; error = true; this.error(puntaje_pruebas) }
    // else
    // if (!Number(puntaje_pruebas.valor) && puntaje_pruebas.valor != '') { puntaje_pruebas.error = numero; error = true; this.error(puntaje_pruebas) }
    if (!correo.valor) { correo.error = obligatorio; error = true; this.error(correo) }
    if (!codigo_pruebas.valor) { codigo_pruebas.error = obligatorio; error = true; this.error(codigo_pruebas) }
    if (grupo.valor === '') { error = true }
    if (!error) error = this.enviarDatosPreUniversitario();
    else actualizarMensaje({ titulo: 'Revise el Formulario', tipo: 'info', mostrar: true, tiempo: 6000 });
  }



  onSubmitGestionMatricula = (e) => {
    let { colegio_asignado, calificacion_contacto, telefono_financiero, nombre_financiero, metodo_pago, generacion_e } = this.state;
    let { actualizarMensaje } = this.props
    let obligatorio = 'Este campo es Obligatorio';
    let error = false;
    if (!nombre_financiero.valor) { nombre_financiero.error = obligatorio; error = true; this.error(nombre_financiero) }
    if (!telefono_financiero.valor) { telefono_financiero.error = obligatorio; error = true; this.error(telefono_financiero) }
    if (!colegio_asignado.value) { error = true; }
    if (!generacion_e.value) { error = true; }
    if (!calificacion_contacto.value) { error = true; }
    if (!metodo_pago.value) { error = true; }
    if (!error) this.enviarGestionMatricula();
    else actualizarMensaje({ titulo: 'Revise el Formulario', tipo: 'info', mostrar: true, tiempo: 6000 });
  }

  // onSubmitContactoPreuniversitario = (e) => {
  //   let { barrio, direccion } = this.state;
  //   let { actualizarMensaje } = this.props
  //   let obligatorio = 'Este campo es Obligatorio';
  //   let error = false;
  //   if (!barrio.valor) { barrio.error = obligatorio; error = true; this.error(barrio) }
  //   if (!direccion.valor) { direccion.error = obligatorio; error = true; this.error(direccion) }

  //   if (!error) this.enviarContactoPreuniversitario();
  //   else actualizarMensaje({ titulo: 'Revise el Formulario', tipo: 'info', mostrar: true, tiempo: 6000 });
  // }


  enviarGestionMatricula() {
    let { id_estudiante, colegio_asignado, calificacion_contacto, telefono_financiero,
      nombre_financiero, metodo_pago, generacion_e, nombre_banco, resultado_preuniversitario } = this.state;
    this.setState({ cargando: true, titulo_res: 'Enviando..' })
    let data = {
      colegio_asignado: colegio_asignado.value,
      calificacion_contacto: calificacion_contacto.value,
      generacion_e: generacion_e.value,
      telefono_financiero: telefono_financiero.valor,
      nombre_financiero: nombre_financiero.valor,
      metodo_pago: metodo_pago.value,
      nombre_banco: nombre_banco.valor,
      resultado_preuniversitario: resultado_preuniversitario.value,
      id_estudiante,
      tipo: 'gestion_matricula',
    }
    this.props.guardarDatosPreUniversitario(data, () => this.setState({ cargando: false, titulo_res: 'Cargando..' }));
    this.reiniciarEstado()

  }
  // enviarContactoPreuniversitario() {
  //   let { departamento, municipio, barrio, direccion, estrato, id_estudiante } = this.state;
  //   this.setState({ cargando: true, titulo_res: 'Enviando..' })
  //   let data = {
  //     departamento: departamento.value,
  //     municipio: municipio.value,
  //     barrio: barrio.valor,
  //     direccion: direccion.valor,
  //     estrato: estrato.value,
  //     id_estudiante,
  //     tipo: 'contacto',
  //   }
  //   this.props.guardarDatos(data, () => this.setState({ cargando: false, titulo_res: 'Cargando..' }));
  //   this.reiniciarEstado()

  // }

  enviarContacto() {
    let {
      departamento,
      municipio,
      barrio,
      direccion,
      estrato,
      nombre_madre,
      celular_madre,
      nombre_padre,
      celular_padre,
      id_estudiante,
      contactoEmergencia,
      celularEmergencia,
      poseeEps,
      nombreEps,
      nombreEpsOtro,
    } = this.state;
    this.setState({ cargando: true, titulo_res: 'Enviando..' })
    let data = {
      departamento: departamento.value,
      municipio: municipio.value,
      barrio: barrio.valor,
      direccion: direccion.valor,
      estrato: estrato.value,
      nombre_madre: nombre_madre.valor,
      celular_madre: celular_madre.valor,
      nombre_padre: nombre_padre.valor,
      celular_padre: celular_padre.valor,
      contacto_emergencia: contactoEmergencia,
      celular_emergencia: celularEmergencia,
      posee_eps: poseeEps.value,
      nombre_eps: nombreEps.value,
      nombre_otro_eps: nombreEpsOtro,
      id_estudiante,
      tipo: 'contacto',
    }
    this.props.guardarDatos(data, () => this.setState({ cargando: false, titulo_res: 'Cargando..' }));
    this.reiniciarEstado()
  }

  enviarDatos() {
    let { celular, primer_nombre, primer_apellido, segundo_nombre, segundo_apellido, identificacion, telefono,
      codigo_pruebas, puntaje_pruebas, puntaje_sisben, correo, tipo_identificacion, grupo, tiene_sisben, id_estudiante } = this.state;

    let puntaje = puntaje_sisben
    if (tiene_sisben === 'no') {
      puntaje = { 'valor': '' }
    }
    this.setState({ cargando: true, titulo_res: 'Enviando..' })
    let data = {
      celular: celular.valor,
      primer_nombre: primer_nombre.valor,
      primer_apellido: primer_apellido.valor,
      segundo_nombre: segundo_nombre.valor,
      segundo_apellido: segundo_apellido.valor,
      identificacion: identificacion.valor,
      telefono: telefono.valor,
      codigo_pruebas: codigo_pruebas.valor,
      puntaje_pruebas: puntaje_pruebas.valor,
      puntaje_sisben: puntaje.valor,
      correo: correo.valor,
      tipo_identificacion,
      grupo: grupo.value,
      id_estudiante,
      tiene_sisben,
      tipo: 'datos',
    }
    this.props.guardarDatos(data, () => this.setState({ cargando: false, titulo_res: 'Cargando..' }));
    this.reiniciarEstado()

  }

  enviarDatosPreUniversitario() {
    let {
      celular,
      identificacion,
      telefono,
      correo,
      id_estudiante,
      celular_madre,
      celular_padre,
      primer_nombre,
      segundo_nombre,
      primer_apellido,
      segundo_apellido,
      genero,
      estudiante_11,
      nivel_educacion,
      nombre_colegio,
      carrera_pre_universitario,
      carrera_pre_universitario_2,
      edad,
      linea_edu,
      jornada,
      jornada_otro,
      costear_estudio,
      costear_estudio_otro,
      programa,
      puntaje_pruebas,
      codigo_pruebas,
      grupo,
      discapacidad,
    } = this.state;

    this.setState({ cargando: true, titulo_res: 'Enviando..' })
    let data = {
      celular: celular && celular.valor,
      celular_madre:  celular_madre && celular_madre.valor,
      celular_padre: celular_padre && celular_padre.valor,
      identificacion: identificacion && identificacion.valor,
      telefono: telefono && telefono.valor,
      correo: correo && correo.valor,
      primer_nombre: primer_nombre && primer_nombre.valor,
      segundo_nombre: segundo_nombre && segundo_nombre.valor,
      primer_apellido: primer_apellido && primer_apellido.valor,
      segundo_apellido: segundo_apellido && segundo_apellido.valor,
      genero: genero && genero.value,
      jornada: jornada && jornada.value,
      costear_estudio: costear_estudio && costear_estudio.value,
      estudiante_11: estudiante_11 && estudiante_11.value,
      nivel_educacion: nivel_educacion && nivel_educacion.value,
      nombre_colegio:  nombre_colegio && nombre_colegio.valor,
      carrera_pre_universitario: carrera_pre_universitario && carrera_pre_universitario.valor,
      carrera_pre_universitario_2: carrera_pre_universitario_2 && carrera_pre_universitario_2.valor,
      edad: edad && edad.valor,
      linea_edu: linea_edu && linea_edu.value,
      jornada_otro: jornada_otro && jornada_otro.valor,
      costear_estudio_otro: costear_estudio_otro && costear_estudio_otro.valor,
      carrera: programa && programa.value,
      puntaje_pruebas: puntaje_pruebas && puntaje_pruebas.valor,
      codigo_pruebas: codigo_pruebas && codigo_pruebas.valor,
      grupo: grupo && grupo.value,
      discapacidad: discapacidad && discapacidad.value,
      id_estudiante,
      tipo: 'datos',
    }
    this.props.guardarDatosPreUniversitario(data, () => this.setState({ cargando: false, titulo_res: 'Cargando..' }));
    this.reiniciarEstado()
  }

  enviarVerificadoPreUniversitario = (e) => {
    let { id_estudiante, checkedIdentificacion, checkedCelular, checkedTelefono,
      checkedCorreo, checkedCelular_madre, checkedCelular_padre } = this.state;
    this.setState({ cargando: true, titulo_res: 'Enviando..' })
    let data = {
      checkedIdentificacion,
      checkedCelular,
      checkedTelefono,
      checkedCorreo,
      checkedCelular_madre,
      checkedCelular_padre,
      id_estudiante,
      tipo: 'verificados',
    }

    this.props.guardarDatosPreUniversitario(data, () => this.setState({ cargando: false, titulo_res: 'Cargando..' }));
    this.reiniciarEstado()

  }


  onSubmitContacto = (e) => {
    let { barrio, direccion } = this.state;
    let { actualizarMensaje } = this.props
    let obligatorio = 'Este campo es Obligatorio';
    let error = false;
    if (!barrio.valor) { barrio.error = obligatorio; error = true; this.error(barrio) }
    if (!direccion.valor) { direccion.error = obligatorio; error = true; this.error(direccion) }

    if (!error) this.enviarContacto();
    else actualizarMensaje({ titulo: 'Revise el Formulario', tipo: 'info', mostrar: true, tiempo: 6000 });
  }

  enviarCarrera = (e) => {
    let { conocio, programa, id_estudiante } = this.state;
    this.setState({ cargando: true, titulo_res: 'Enviando..' })
    let data = {
      programa: programa.value,
      conocio: conocio.value,
      id_estudiante,
      tipo: 'carrera',
    }
    this.props.guardarDatos(data, () => this.setState({ cargando: false, titulo_res: 'Cargando..' }));
    this.reiniciarEstado()

  }

  reiniciarEstado() {
    this.setState(
      {
        cargando: true,
        seleccion: '',
        datos: [],
        documentos: [],
        contacto: [],
        carrera: [],
        estados: [],
        celular: { 'valor': '', 'error': false },
        id_estudiante: '',
        tipos_identificacion: [],
        tipo_identificacion: '',
        grupo: '',
        identificacion: { 'valor': '', 'error': false },
        primer_nombre: { 'valor': '', 'error': false },
        segundo_nombre: { 'valor': '' },
        primer_apellido: { 'valor': '', 'error': false },
        segundo_apellido: { 'valor': '', 'error': false },
        correo: { 'valor': '', 'error': false },
        telefono: { 'valor': '', 'error': false },
        codigo_pruebas: { 'valor': '', 'error': false },
        puntaje_pruebas: { 'valor': '', 'error': false },
        puntaje_sisben: { 'valor': '', 'error': false },
        tiene_sisben: '',
        grupos: [],
        barrio: { 'valor': '', 'error': false },
        direccion: { 'valor': '', 'error': false },
        departamento: { 'label': '', 'value': '' },
        departamentos: [],
        municipio: { 'label': '', 'value': '' },
        municipios: [],
        estrato: { 'label': '', 'value': '' },
        estratos: [],
        conocio: { 'label': '', 'value': '' },
        programa: { 'label': '', 'value': '' },
        adj_diploma: '',
        adj_identificacion: '',
        adj_certificado: '',
        adj_saber: '',
        adj_docu_notas: '',
      }
    )
  }

  render() {
    let { seleccion, maxWidth, cargando, titulo_res } = this.state;
    let { modalEditarInscripcion, mostrarModalEditarInscripcion, vistaDetalle, accionVistaDetalle } = this.props;
    if ((vistaDetalle === 'Tipo_Ins' || vistaDetalle === 'Tipo_Homo') && accionVistaDetalle) {
      return (
        <Dialog open={modalEditarInscripcion} maxWidth={maxWidth} fullWidth={true} onClose={mostrarModalEditarInscripcion.bind(this, false)}>
          <AppBarModal titulo='¡ Editar Inscripcion !' mostrarModal={mostrarModalEditarInscripcion} titulo_accion='' />
          <InscripcionMenuEditar sele={seleccion} seleccionar={seleccion => this.setState({ seleccion })} />
          <DialogContent style={{ padding: 0 }} className='scroll' >
            { !cargando ? this.pintar() : <TareasTerminadas imagen={emma_w} mensaje={titulo_res} widthImg='5%' marginTop='5%' cargando={true}/>}
          </DialogContent>
          <DialogActions>
            <BtnForm texto="Cerrar" callback={() => mostrarModalEditarInscripcion(false)} />
          </DialogActions>
        </Dialog>
      );
    } else {
      return (
        <Dialog open={modalEditarInscripcion} maxWidth={maxWidth} fullWidth={true} onClose={mostrarModalEditarInscripcion.bind(this, false)}>
          <AppBarModal titulo='¡ Editar PreUniversitario !' mostrarModal={mostrarModalEditarInscripcion} titulo_accion='' />
          <InscripcionMenuEditarPreUniversitario sele={seleccion} seleccionar={seleccion => this.setState({ seleccion })} />
          <DialogContent style={{ padding: 0 }} className='scroll' >
            { !cargando ? this.pintarPreuniversitario(): <TareasTerminadas imagen={emma_w} mensaje={titulo_res} widthImg='5%' marginTop='5%' cargando={true}/>}
          </DialogContent>
          <DialogActions>
            <BtnForm texto="Cerrar" callback={() => mostrarModalEditarInscripcion(false)} />
          </DialogActions>
        </Dialog>
      );
    }
  }
}

InscripcionEditar.propTypes = {
  //variables
  actualizarMensaje: PropTypes.func.isRequired,
  inscripcion: PropTypes.object.isRequired,
  modalEditarInscripcion: PropTypes.bool.isRequired,
  mostrarModalEditarInscripcion: PropTypes.func.isRequired,
  vistaDetalle: PropTypes.string.isRequired,
  guardarDatos: PropTypes.func.isRequired,
  guardarDatosPreUniversitario: PropTypes.func.isRequired,
  guardarDatosDocumentos: PropTypes.func.isRequired,
}


export default InscripcionEditar;