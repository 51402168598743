import React, { useState, useEffect } from "react";
import ListarDatos from "../../general/ListarDatos";
import moment from "moment";
import emma_w from "../../../global/imagenes/emma_w.png";
import {
  BtnDetalle,
  BtnGestionar,
  BtnEditar,
  BtnPermiso,
  BtnDescargar,
  BtnSeleccion,
  BtnFolder
} from "../../general/BotonesAccion";
import TareasTerminadas from "../../general/TareasTerminadas";
import { consulta } from "../../../global/js/funciones";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  usePDF
} from "@react-pdf/renderer";
import ReactPDF from "@react-pdf/renderer";
import MatriculaEditar from './MatriculaEditar';

const obtenerColor = (codigo) => {
  let color = "white";
  const colores = {
    Mat_Env: { backgroundColor: "#f5f5f5", color: "#757575" },
    Mat_Rev: { backgroundColor: "#ffc107", color },
    Mat_Rec: { backgroundColor: "#b71c1c", color },
    Mat_Ter: { backgroundColor: "#009688", color },
    Mat_Sol_Exo: { backgroundColor: "#009688", color },
    Mat_Pro_Sol_Cam_Fec: { backgroundColor: "#009688", color },
    Mat_Pro: { backgroundColor: "#ffc107", color },
    Mat_Com_Env: { backgroundColor: '#DBB62C', color },
    Mat_Pag: { backgroundColor: "#3498db", color },
    Mat_En_Est: { backgroundColor: "#AFF9DB", color: "#000"  },
    Mat_Aval_Fac_Rein_Tran: { backgroundColor: '#5cb85c', color },
    Mat_Act_Pen: { backgroundColor: "#ffc107", color },
    Mat_Act_Rev_Are: { backgroundColor: '#3498db', color },
    Mat_Rein_Tran_Doc_Ok: { backgroundColor: '#2950a6', color },
    Mat_Rein_Tran_Est: { backgroundColor: '#2950a6', color },
    Mat_Rein_Tran_Apr: { backgroundColor: '#ec9c06', color }
  };
  return colores[codigo];
};

const MyDocument = () => {
  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "#E4E4E4",
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
  });
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text>Section #1</Text>
        </View>
        <View style={styles.section}>
          <Text>Section #2</Text>
        </View>
      </Page>
    </Document>
  );
};

let estadosComprobado = false;

export function Historial({historial,showModalDetalle,cambiarEstado,resetOpcion, mostrarEditarMatricula, modalEditarMatricula, actualizarMensaje, listarHistorial, filtros}) {

  const [matriculaActual, setprocesoActual] = useState([]);
  const [codigoMatricula, setCodigoMatricula] = useState('');
  const [nombreMatricula, setNombreMatricula] = useState('');

  const acciones = (data) => {    
    let { estado_solicitud: { codigo }, tipo, estado_facultad, estado_biblioteca, estado_credito, exoneracion } = data;
    if(estado_facultad || estado_biblioteca || estado_credito || exoneracion){
       estadosComprobado = true;
    }else{
       estadosComprobado = false;
    }

    const onClickDetalle = async (matricula) => {
      resetOpcion();
      const procesoActual = await getDetalleMatricula(matricula.id);
      cambiarEstado({ procesoActual });
      showModalDetalle(true);
    };

    const onClickEditar =  async (matricula) => {
      resetOpcion();
      const procesoActual = await getDetalleMatricula(matricula.id);
      cambiarEstado({ procesoActual });
      setprocesoActual(procesoActual);
      setCodigoMatricula(procesoActual.tipo.codigo);
      setNombreMatricula(procesoActual.tipo.nombre);
      mostrarEditarMatricula(true);
    }

    const detalle = (
      <BtnDetalle
        callback={() => onClickDetalle(data)}
        color="#01579b"
        texto="Abrir"
      />
    );

    const editar = () => {
      if(!estadosComprobado && (codigo === 'Mat_En_Est' || codigo === 'Mat_Env') && (tipo.codigo == 'Mat_Pro_Sol_Gra')){
         return (<BtnFolder callback={() => onClickEditar(data)} color='#ffc107' texto='Modificar'/>)
      }
    }
    return <div>{detalle}{editar}</div>;
  };

  const getDetalleActualizacion = async (id) => {
    const data = new Promise((resolve) => {
      consulta(`api/v1.0/matriculas/actualizar/${id}`,null,null,(error, estado, resp) => {
            resolve(resp);
          }
      );
    });
    return data;
  }

  const getDetalleMatricula = (id) => {
    const detalle = new Promise((resolve) => {
      consulta(`api/v1.0/matriculas/${id}`,null,null,(error, estado, resp) => {
          resolve(resp);
        }
      );
    });
    return detalle;
  };

  const getEstadosMatricula = (id) => {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/matriculas/${id}/estados`,
        null,
        null,
        (error, estado, resp) => {
          resolve(resp);
        }
      );
    });
  };

  return (
    <div>
      <ListarDatos
        datos={historial}
        id="tbl_historial_matriculas"
        avatar={({ estado_solicitud: { nombre } }) => nombre.charAt(0)}
        color_avatar={({ estado_solicitud: { codigo } }) =>
          obtenerColor(codigo)
        }
        buscar={true}
        opciones={true}
        titulo="Lista Solicitudes de Matricula"
        acciones={(data) => acciones(data)}
        fila_principal={({ tipo }) => tipo.nombre}
        filas={[
          {
            id: "fecha_registro",
            mostrar: ({ fecha_registro }) =>
              moment(fecha_registro).format("YYYY-MM-DD, h:mm a"),
            nombre: "Fecha Registro : ",
          },
        ]}
      />

      <MatriculaEditar
        matricula={matriculaActual}
        mostrarEditarMatricula={mostrarEditarMatricula}
        modalEditarMatricula={modalEditarMatricula}
        codigoMatricula={codigoMatricula}
        nombreMatricula={nombreMatricula}
        actualizarMensaje={actualizarMensaje}
        actRefrescar={() => listarHistorial(filtros)}
      />

    </div>
  );
}

export function HistorialAdmin({
  showModalDetalle,
  showModalGestionar,
  showModalFiltros,
  historial,
  listarHistorial,
  cambiarEstado,
  filtros,
  filtrosReintegro,
  resetOpcion,
  habilitarGestion,
  handlerSurvey,
  showModalCambiarFecha,
  showModalMenciones,
  showModalPasarAGraduado,
  misDatos,
  actualizarMensaje,
  mostrarEditarMatricula, 
  modalEditarMatricula, 
}) {

  const [instance, updateInstance] = usePDF({ document: MyDocument });
  const [matriculaActual, setprocesoActual] = useState([]);
  const [codigoMatricula, setCodigoMatricula] = useState('');
  const [nombreMatricula, setNombreMatricula] = useState('');
  const [idMatricula, setidMatricula] = useState(0);

  const perfilesUsuario = misDatos.perfiles.map(
    ({ relacion }) => relacion.codigo
  );
  const acciones = (data) => {
    let {
      estado_solicitud: { codigo },
      permiso,
      tipo: { codigo: codigoTipo },
      estados,
    } = data;
    const onClickGestionar = (matricula) => {
      cambiarEstado({ procesoActual: matricula });
      if (matricula.estado_solicitud.codigo === "Mat_Env") {
        habilitarGestion(1);
        if (codigoTipo === "Mat_Pro_Sol_Gra" || matricula.tipo.codigo === "Mat_Pro_Sol_Rein_Tran" || matricula.tipo.codigo === "Mat_Pro_Cor_Nota" ) {
          resetOpcion("detalle");
        } else if (matricula.tipo.codigo === "Mat_Pro_Act_Datos"){
          resetOpcion("actualizacion");
        } else {
          resetOpcion("materias");
        }
        showModalDetalle(true);
      } else {
        showModalGestionar(true);
      }
    };

    const onClickDetalle = (matricula) => {
      resetOpcion();
      habilitarGestion(0);
      cambiarEstado({ procesoActual: matricula });
      if(matricula.tipo.codigo === "Mat_Pro_Act_Datos") resetOpcion("actualizacion");
      showModalDetalle(true);
    };

    const onClickEditar = (matricula) => {
      cambiarEstado({ procesoActual: matricula });
      showModalCambiarFecha();
    };

    const onClickAgregarMenciones = (matricula) => {
      cambiarEstado({ procesoActual: matricula });
      showModalMenciones();
    };

    const onClickGraduado = (matricula) => {
      cambiarEstado({ procesoActual: matricula });
      showModalPasarAGraduado();
    };

    const onClickDescargar = (matricula) => {
      cambiarEstado({ procesoActual: matricula });
    };

    const onClickModificar =  async (matricula) => {
      resetOpcion();
      // const procesoActual = await getDetalleMatricula(matricula.id);
      // cambiarEstado({ procesoActual });
      // setprocesoActual(procesoActual);
      // setCodigoMatricula(procesoActual.tipo.codigo);
      // setNombreMatricula(procesoActual.tipo.nombre);
      setidMatricula(matricula.id)
      mostrarEditarMatricula(true);
    }

    const detalle = (
      <BtnDetalle
        callback={() => onClickDetalle(data)}
        color="#01579b"
        texto="Abrir"
      />
    );

    let admin_or_mod = (perfilesUsuario.includes("Per_Adm_Mat_Mod") ||  perfilesUsuario.includes("Per_Adm"))
    let cantidad = estados.reduce((contador, e) => {if(e.usuario_registro === misDatos.persona.id) {return contador + 1;}else{return contador;}}, 0);
    var sw = true;
    if(codigoTipo === "Mat_Pro_Sol_Gra"){
      if(admin_or_mod){
        sw = true;
      }else if(!admin_or_mod && cantidad === 0){
        sw = true;
      }else{
        sw = false;
      }
    }

    if(codigoTipo == "Mat_Pro_Act_Datos"){
      let checks = data.estados_solicitudes.filter(est => (est.estado_matricula.codigo == "Mat_Act_Dat_Apr_Tec" || est.estado_matricula.codigo == "Mat_Act_Dat_Apro_Cre"))
      let cantidad = checks.reduce((contador, e) => {if(e.usuario_registro.id === misDatos.persona.id) {return contador + 1;}else{return contador;}}, 0);

      if(!admin_or_mod && cantidad > 0){
        sw = false;
      }
    }

    const gestionar = ((codigoTipo === "Mat_Pro_Sol_Gra" && (codigo === "Mat_Ter" || codigo === "Mat_Pro_Sol_Cam_Fec" || codigo === "Mat_Pro_Ter_Exo")) ||
      (codigoTipo === "Mat_Pro_Sol_Rein_Tran" && (codigo === "Mat_Pag" || codigo === "Mat_Com_Env" || codigo === "Mat_Aval_Fac_Rein_Tran" || codigo === "Mat_Rein_Tran_Apr_Fac" || codigo === "Mat_Rein_Tran_En_Valid_Fac" || codigo === "Mat_Rein_Tran_Est_Hom_Apro" || codigo === "Mat_Rein_Tran_Est_Hom_Recha")) ||
      (codigoTipo === "Mat_Pro_Certi" && (codigo === "Mat_cer_val_soli" || codigo === "Mat_Com_Env")) ||
      (codigo === "Mat_Act_Dat_Apro_Reg" || codigo === "Mat_Act_Dat_Rev_Reg" || codigo === "Mat_Sol_Gra_Apr_Tec" || codigo === "Mat_Act_Dat_Apro_Cre" || codigo === "Mat_Act_Pen" || codigo === "Mat_Act_Rev_Are")  ||
      (codigo === "Mat_Rein_Tran_Doc_Ok" || codigo === "Mat_Rein_Tran_Est" || codigo === "Mat_Rein_Tran_Apr") ||
      codigo === "Mat_Env" ||
      codigo === "Mat_cor_nota" ||
      codigo === "Mat_Pro" ||
      codigo === "Mat_En_Est") &&
      permiso === "1" && sw && (
        <BtnGestionar
          callback={() => onClickGestionar(data)}
          color="#00bfa5"
          texto="Gestionar"
        />
      );

    // && codigo !== 'Mat_Rec'
    const editarFecha = codigoTipo === "Mat_Pro_Sol_Gra" &&
      (codigo === "Mat_Ter" || codigo === "Mat_Pro_Sol_Cam_Fec") &&
      admin_or_mod && (
        <BtnEditar
          callback={() => onClickEditar(data)}
          texto="Cambiar Fecha de grado"
        />
      );

		const agregarMencion = (codigoTipo === "Mat_Pro_Sol_Gra" && codigo !== 'Mat_Rec' && codigo !== 'Mat_Ter' && codigo !== 'Mat_Gra' && codigo !== 'Mat_Pro_Ter_Exo' && permiso === '1') && (
			<BtnSeleccion callback={() => onClickAgregarMenciones(data)} texto="Agregar Menciones" />
		);

    const graduado = codigoTipo === "Mat_Pro_Sol_Gra" &&
      (codigo === "Mat_Ter") &&
      permiso === "1" && admin_or_mod && (
        <BtnGestionar
          callback={() => onClickGraduado(data)}
          color="#00bfa5"
          texto="Pasar a Graduado"
        />
      );

    const descargar = codigoTipo === "Mat_Pro_Sol_Gra" &&
      codigo === "Mat_Gra" &&
      permiso === "1" && (
        <PDFDownloadLink document={<MyDocument />} fileName="somename.pdf">
          {({ blob, url, loading, error }) =>
            loading ? (
              "Loading document..."
            ) : (
              <BtnDescargar
                callback={() => onClickDescargar(data)}
                texto="Descargar"
              />
            )
          }
        </PDFDownloadLink>
      );
      
      let mod = perfilesUsuario.includes("Per_Adm_Mat_Mod") ? true : false;
      const modificar = (mod && (codigo === 'Mat_En_Est' || codigo === 'Mat_Env' || codigo === 'Mat_Pro') && (codigoTipo == 'Mat_Pro_Sol_Gra')) && <BtnFolder callback={() => onClickModificar(data)} color='#ffc107' texto='Modificar'/>; 
      
      const modificar_Programa = (mod && (codigo === 'Mat_Env' || codigo === 'Mat_Pro' || codigo === 'Mat_Com_Env' || codigo === 'Mat_Pag') && (codigoTipo == 'Mat_Pro_Sol_Rein_Tran')) && <BtnFolder callback={() => onClickModificar(data)} color='#ffc107' texto='Modificar'/>; 

      const getDetalleMatricula = (id) => {
        return new Promise((resolve) => {
          consulta(`api/v1.0/matriculas/${id}`,null,null,(error, estado, resp) => {
              resolve(resp);
            }
          );
        });
      };

    return (
      <div>
        {perfilesUsuario.includes("Per_Pos_Mat") && agregarMencion}
        {modificar}
        {modificar_Programa}
        {editarFecha}
        {detalle}
        {gestionar}
        {graduado}
        {descargar}
      </div>
    );
  };

  return (
    <div>
      <ListarDatos
        // nueva características
        opcionAdicional={true}
        actDescargar={() => handlerSurvey(true)}
        datos={historial}
        id="tbl_historial_matriculas"
        avatar={({ estado_solicitud: { nombre } }) => nombre.charAt(0)}
        color_avatar={({ estado_solicitud: { codigo } }) =>
          obtenerColor(codigo)
        }
        buscar={true}
        opciones={true}
        titulo="Lista Solicitudes de Procesos Académicos"
        descargar="MATRÍCULAS - EMMA"
        acciones={(data) => acciones(data)}
        fila_principal={({
          usuario_registro: {
            primer_nombre,
            primer_apellido,
            segundo_apellido,
          },
        }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`}
        filtrar={true}
        actfiltrar={() => showModalFiltros(true)}
        refrescar={true}
        actRefrescar={() => listarHistorial(filtros)}
        filas={[
          {
            id: "identificacion",
            mostrar: ({ usuario_registro: { identificacion } }) =>
              identificacion,
          },
          {
            id: "programa",
            // mostrar: ({ programa: { nombre } }) => nombre,
            mostrar: ({ programa }) =>{
              return  programa ? programa.nombre : "";
            },
            
          },
          {
            id: "exoneracion",
            mostrar: ({ exoneracion }) =>
              exoneracion ? exoneracion.nombre : null,
            nombre: "Exoneración: ",
          },
          {
            id: "tipo",
            mostrar: ({ tipo }) => tipo.nombre,
          },
          {
            id: "fecha_registro",
            mostrar: ({ fecha_registro }) =>
              moment(fecha_registro).format("YYYY-MM-DD, h:mm a"),
            nombre: "Fecha Registro: ",
          },
          {
            id: "estado",
            mostrar: ({ estado_solicitud: { nombre }, gestor_nombre }) =>
              `${nombre} ${gestor_nombre ? " - " + gestor_nombre : ""}`,
            nombre: "Estado: ",
          },
          {
            id: "gestor",
            mostrar: ({ gestor_nombre }) => gestor_nombre,
            nombre: "Gestor: ",
            enLista: false,
          },
          {
            id: 'fecha_grado',
            mostrar: ({ fecha_solicitud_grado }) => (fecha_solicitud_grado && fecha_solicitud_grado.fechaGrado) ? fecha_solicitud_grado.fechaGrado.nombre : '',
            enLista: true,

          }
        ]}
      />
      <MatriculaEditar
        // matricula={matriculaActual}
        mostrarEditarMatricula={mostrarEditarMatricula}
        modalEditarMatricula={modalEditarMatricula}
        // codigoMatricula={codigoMatricula}
        // nombreMatricula={nombreMatricula}
        idMatricula={idMatricula}
        actualizarMensaje={actualizarMensaje}
        actRefrescar={(tipo_sol, periodo, estado_sol) => listarHistorial(filtros)}
      />
    </div>
  );
}
