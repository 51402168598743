//Importaciones necesaria para poder utilizar react
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { BtnRegresar } from "../../general/BotonesAccion";
import {
    Paper,
    Grid,
    Tooltip,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    TableRow,
    TableCell
} from '@material-ui/core'
import permisosIcon from '../../../global/imagenes/lightbulb.png';
import AdminSemiPermisos from './AdminSemiPermisos';
import AccionesRapidas from '../../general/AccionesRapidas';
import TituloAccion from '../../general/TituloAccion';
import pencil from '../../../global/imagenes/pencil.png';
import ListarDatos from '../../general/ListarDatos';
import * as Funciones from '../../../global/js/funciones';
import TareasTerminadas from '../../general/TareasTerminadas';
import emma_s from '../../../global/imagenes/emma_s1.png';
import InputBuscar from '../../general/InputBuscar';
import BuscarPersona from '../../general/BuscarPersona';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import AppBarModal from '../../general/AppBarModal';
import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';
import * as Helper from '../Hijos/Helper';
import { useDispatch } from 'react-redux';
import { actualizarMensaje } from '../../../redux/actions/actGlobal';
import IconButton from '@material-ui/core/IconButton';
import reportIcon from '../../../global/imagenes/report.png';
import ClearIcon from '@material-ui/icons/Clear';
import fechaIcon from '../../../global/imagenes/calendar.png';
import { Typography } from '@material-ui/core'
import AutorenewIcon from '@material-ui/icons/Autorenew';
import FormHelperText from '@material-ui/core/FormHelperText';
import emma_w from '../../../global/imagenes/emma_w.png';
import emma_s1 from '../../../global/imagenes/emma_s1.png';
import { BtnForm } from "../../general/BotonesAccion";
import Admininforme from "../HijosAdmin/AdminInforme"
import { coloresEmma } from '../../../global/js/funciones';
//ACCIONES DEL MODULO
import ListarTarjetas from '../../general/ListarTarjetas';
import { TIPO_INFORME_SEMILLEROS } from '../Hijos/Helper';
import BookIcon from "@material-ui/icons/Book";
import {Book, MenuBook} from "@material-ui/icons";
import {CreditoMenu} from "../../creditos/creditos/CreditoMenu";
import moment from "moment/moment";
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    contenidoModulo: {
        [theme.breakpoints.up('sm')]: {
            paddingLeft: "90px !important"
        },
    },
    formControl: {
        margin: "0",
      },
    selectEmpty: {
        marginTop: theme.spacing(2),
      },
    colorEmmma: {
        color: coloresEmma.secondarycolor,
        transition: 'all 0.3s',
        '&:hover': {
          color: "#fff",
          backgroundColor: coloresEmma.secondarycolor,
          transform: "scale(1.3)",
        }
    },
    color:{
        transition: 'all 0.3s',
        '&:hover': {
            transform: "scale(1.05)",
        },
    },
    center:{
        textAlign: 'center'
    },
    colorEdit: {
        color: "#0074C5",
        transition: "all 0.3s",
        '&:hover': {
          color: "#fff",
          backgroundColor: "#0074C5",
          transform: "scale(1.3)",
        },
    },
      colorDelete: {
        color: "#d11a2a",
        transition: "all 0.3s",
        '&:hover': {
          color: "#fff",
          backgroundColor: "#d11a2a",
          transform: "scale(1.3)",
        },
      },
      colorCheck: {
        color: "#2ECC71",
        transition: "all 0.3s",
        '&:hover': {
          color: "#fff",
          backgroundColor: "#2ECC71",
          transform: "scale(1.2)",
        },
      },
}));

const AdminAdministrar = (props) => {

    const [seleccion, setSeleccion] = useState('inicio')
    const [modalCambiarPeriodo, setModalCambiarPeriodo] = React.useState(false);
    const [periodoactual, setPeriodoActual] = React.useState('');
    const isInitialMount = React.useRef(true);
    React.useEffect( () => {
        //Obtengo los prceosos activos de la persona activa.
        if(isInitialMount.current){
            isInitialMount.current = false
            obtenerPeriodo()
        }
    })

    const obtenerPeriodo = async () => {
        let periodo = await Funciones.obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Semi' }]);
        setPeriodoActual(periodo)
        setPeriodoActual(periodo[0].nombre)
        
    }


    const PintarInicio = (props) =>{
        return(
            <div style={{ padding: '2% 2% 0% 2%' }} >
                <Grid container direction={'row'} spacing={1} justify={'center'} >
                    <Grid item xs={12} sm={12} md={10} lg={8} >
                        <TituloAccion principal='' alterno='¿ Que deseas hacer ?' />
                        <Paper elevation={0} square className="scroll contenido_fijo">
                            <Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
                                <Grid item xs={12} sm={12} md={6} lg={6} >
                                    <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => setSeleccion('permisos')}>
                                        <AccionesRapidas imagen={permisosIcon} titulo='¡ Controlar Permisos !' sub_titulo='' des_sub_titulo='Verifica, asigna y retira permisos a los gestores.' />
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} >
                                    <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => setSeleccion('categoria')}>
                                        <AccionesRapidas imagen={pencil} titulo={`¡ ,Cambiar Categorias !`} sub_titulo='' des_sub_titulo='Asigna y retira las categorias de los Usuarios.' />
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} >
                                    <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => setSeleccion('informe')}>
                                        <AccionesRapidas imagen={reportIcon} titulo={`¡ Informes !`} sub_titulo='' des_sub_titulo='Generar reporte de los procesos de semilleros.' />
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} >
                                    <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() =>props.setModalCambiarPeriodo(true)}>
                                        <AccionesRapidas imagen={fechaIcon} titulo={`¡ Actualizar Período !`} sub_titulo='' des_sub_titulo={`El periodo actual ${periodoactual}`} />
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        )
    }

    const pintar = () =>{
        if(seleccion == 'inicio'){
            return (
                <>
                    <PintarInicio 
                    modalCambiarPeriodo={modalCambiarPeriodo}
                    setModalCambiarPeriodo={setModalCambiarPeriodo}
                    /> 
                    <ModalChangePeriodo 
                    modalCambiarPeriodo={modalCambiarPeriodo}
                    setModalCambiarPeriodo={setModalCambiarPeriodo}
                    obtenerPeriodo={obtenerPeriodo}

                    />
                    
                </>
            )
        }else if(seleccion == 'permisos'){
            return (
                <AdminSemiPermisos 
                handleClicki={handleClicki} />
            )
        }else if(seleccion == 'categoria'){
            return (
                <AdminCategoria 
                seleccion={seleccion}
                setSeleccion={setSeleccion}
                />
            )
        }else if (seleccion == 'informe'){
            return(
                <Admininforme
                    handleClicki={handleClicki}
                />
            )
        }
    }

    const handleClicki = () => {
        setSeleccion('inicio')
    }

    const ModalChangePeriodo = function (props) {
        const dispatch = useDispatch();
    const [periodoact, setperiodoact] = useState("")

     const modalChangePeriodoClose = () =>{
         props.setModalCambiarPeriodo(false)
     }
     const onInputChange = (e) => {
        setperiodoact(e.target.value);
      };
     
    const handleOnchange = async() =>{
    
    if(periodoact.length!=4){
        let titulo = "Digite Un Periodo correcto"
        let tipo = "info"

        dispatch(actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 }));
    }else{
        let p = await Funciones.obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Semi' }]);
        if (p.length > 0) {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.'
        let tipo="error"
        let { error, estado, resp } = await Funciones.modificarValorGenericaAlter(p[0].id, { 'nombre': periodoact})
        if (!error) {
            if (estado === 200) {
            let titulo = 'Periodo Actualizado Corretamente';
            let tipo = 'success';
              props.setModalCambiarPeriodo(false)
              dispatch(actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 }));
              props.obtenerPeriodo()
            } else {
              tipo = 'info';
              titulo = resp.titulo ? resp.titulo : Funciones.mostrarError(resp)
            }
          }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });

        }
    } 

    }
   
     return (
         <div>
               <Dialog fullWidth={true} maxWidth='xs' fullScreen={window.innerWidth < 600} open={props.modalCambiarPeriodo} onClose={()=>{modalChangePeriodoClose()}} aria-labelledby="form-dialog-title">
     
                   <AppBarModal mostrarModal={()=>{modalChangePeriodoClose()}} titulo='Nuevo Periodo' titulo_accion="" accion="" />
                   {/* Contenido del modal del formulario de */}
                   <DialogContent>
                     <Grid item xs={12}>
                         <FormControl fullWidth >
                             <TextField 
                                 value={periodoact}
                                 fullWidth
                                 inputProps={{ 'aria-label': 'Without label' }}
                                 onChange={onInputChange}
                                 label='Periodo'
                                 required={true}
                             >
                             </TextField>
                         </FormControl>
                         <FormHelperText></FormHelperText>
                     </Grid>
                   </DialogContent>
     
                   <DialogActions>
                     <Button  onClick={()=>{handleOnchange()}} > Aceptar </Button>
                     <Button  onClick={()=>{modalChangePeriodoClose()}} > Cerrar </Button>
                   </DialogActions>
     
               </Dialog>
           </div>
     )
     }
    return(
        <div>
            {pintar()}
            {/* {seleccion != 'inicio' && <div className={'regresar'} ><BtnRegresar callback={handleClicki} /></div>} */}
        </div>    
    )
}

const AdminCategoria = (props) =>{

    const isInitialMount = React.useRef(true);
    const [personasCat, serPersonasCat] = React.useState([]);
    const [cargando, setCargando] = React.useState(true);
    const [modalAdd, setModalAdd] = React.useState(false);
    const [profesor, setProfesor] = React.useState('')
    const [accion, setAccion] = React.useState('Procesos');
    const [openModalChoicen, setOpenModalChoicen] = React.useState(false)
    const [lineaSeleccion, setLineaSeleccion] = React.useState("")
    const [dataPersonaSelected, setDataPersonaSelected] = React.useState([])
    const [solicitudes, setSolicitudes] = React.useState([]);
    const [openModalBuscarPersona, setOpenModalBuscarPersona] = React.useState(false)

    const [openModal, setOpenModal] = React.useState(false)
    const [cargandoAddPersona, setCargandoAddPersona] = React.useState(false)

    const [openModalDelete, setOpenModalDelete] = React.useState(false)
    const [dataDelete, setDataDelete] = React.useState([])
    const [cargandoDeletePer, setCargandoDeletePer] = React.useState(false)

    const [openModalViewHistory, setOpenModalViewHistory] = React.useState(false)
    const [dataViewHistory, setDataViewHistory] = React.useState([])
    const [cargandoViewHistory, setCargandoViewHistory] = React.useState(false)

    const [openModalChange, setOpenModalChange] = React.useState(false)
    const [dataChange, setDataChange] = React.useState([])
    const [cargandoChangePer, setCargandoChangePer] = React.useState(true)
    const [categoriaSelected, setCategoriaSelected] = React.useState("")

    const [codigoActually, setCodigoActually] = React.useState('')
    const [categorias, setCategorias] = useState([])
    const [persona, setpersona] = useState([])

    const [historial, setHistorial] = React.useState([]);

    const classes = useStyles()
    const dispatch = useDispatch();

    //Errores
    const [errores, setErrores] = React.useState([]);

    React.useEffect( () => {
        //Obtengo los prceosos activos de la persona activa.
        if(isInitialMount.current){
            isInitialMount.current = false
            const obtenerProcesos = async () => {
                await obtenerEstadoSolicitudes();
                obtenerAmbitoModalidad()
                obtenerSemillerosSolicitudes()
            }
            obtenerProcesos()
        }
    })

    const handleClickInicio = () => {
        props.setSeleccion('inicio')
    }
    const obtenerAmbitoModalidad = async () => {
        try{
          const data = await Funciones.obtenerValores(Helper.CATEGORIA_INVESTIGACION)
          setCategorias(data)
          setCargando(false)
        }catch(err){
          console.log(err)
        } 
        
      }

    //Obtener semilleros solicitudes
    const obtenerPersonasCategoria = async (f) => {
        return new Promise(resolve => {
            Funciones.consulta(`api/v1.0/investigacion/categoriaPersona?${f}`, null, null, (error, estado, resp) => {
              resolve(estado === 200 && !error ? resp : null);
            })
        })
    }

    const obtenerParticipantesCategoria = async (f) => {
        return new Promise(resolve => {
            Funciones.consulta(`api/v1.0/investigacion/participantescategoria?${f}`, null, null, (error, estado, resp) => {
              resolve(estado === 200 && !error ? resp : null);
            })
        })
    }

    const obtenerHistorialDeCategorias = async (id) => {
        return new Promise(resolve => {
            Funciones.consulta(`api/v1.0/investigacion/listarHistorial/${id}`, null, null, (error, estado, resp) => {
              resolve(estado === 200 && !error ? resp : null);
            })
        })
    }

  
    const obtenerSemillerosSolicitudes = async () => {
        return new Promise(resolve => {
            Funciones.consulta(`api/v1.0/investigacion/semillerosList`, null, null, (error, estado, resp) => {
              resolve(estado === 200 && !error ? resp : null);
            })
        })
    }

    const obtenerEstadoSolicitudes = () => {
        Funciones.consulta(`api/v1.0/investigacion/categoriasExistentes`, null, null, (error, estado, resp) => {
          if (estado === 200) {
            let { general, extra } = resp
            if (extra.length > 0) {
              extra.map(({ solicitudes, nombre, codigo, orden }) => {
                let reporte = {
                  'categoria_investigacion__codigo': codigo,
                  'categoria_investigacion__nombre': nombre,
                  'num_sol': solicitudes,
                  'orden': orden
                }
                
                general.push(reporte);
              })
            }
            setSolicitudes(general.sort((a, b) => a.orden - b.orden ))
            
          }
        })
      }

    //Errores

    const getError = (llave, errores) => {
        let existe = errores.find(e => e.llave === llave);
        return existe ? existe.mensaje : '';
    }

    const validateInputs = (campos = []) => {
        return new Promise((resolve) => {
            let errores = [];
            let date_now = new Date()

            function isObjEmpty(obj) {

                if(typeof obj === 'object'){
                    for (var prop in obj) {
                        if (obj.hasOwnProperty(prop)) return false;
                    }
                    
                    return true;
                }else if(typeof obj === 'string'){
                    return true
                }else{
                    return false;
                }
                
            }

            campos.map(({ value, name, err, value2 }) => {
                
                if (err.empty && value.replace(/\s+/g, '') == "") errores.push({ llave: name, 'mensaje': `Este campo es obligatorio` });
                if (err.empty == false && value.length == 0) errores.push({ llave: name, 'mensaje': `Este campo es obligatorio` })
                });
            
            resolve(errores);
        });
    }

    const handleCloseModalChoicen = () =>{
        setLineaSeleccion("")
        setOpenModalChoicen(false)
    }

    const handleCloseModalAddPar = () =>{
        setProfesor('')
        setModalAdd(false)
    }

    const sendDataToApi = async () =>{

        let array = await validateInputs([
            { value: lineaSeleccion, name: 'lineaSubInves', err: { empty: false } },
        ])

        setErrores(array)

        if(lineaSeleccion == ""){
           
        }else{
            let data = {
                persona: dataPersonaSelected,
                categoria: lineaSeleccion
            }
    
            let url = `api/v1.0/investigacion/insertarCategoria?up=${false}`
    
            handleCloseModalChoicen()
            setOpenModalBuscarPersona(false)
            setCargandoAddPersona(true)
    
            const callback = async(error, estado, resp) => {
                let titulo = "Ha ocurrido un error, contacte con el administrador."
                let tipo = "info"
                
                if(!error){
                    if(estado === 201){
                        tipo = "success"
                        titulo = resp.titulo
                    }else if(estado === 200){
                        tipo = "info"
                        titulo = resp.titulo
                    }
                }
    
                if(estado == 201){
                    dispatch(
                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                    );
                    setModalAdd(false)
                    setCargandoAddPersona(false)
                }else{
                    dispatch(
                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                    );
                    setCargandoAddPersona(false)
                }
                
            }
    
            const msg = await Funciones.consulta(url, data, 'POST', callback)
        }
    }

    const modalViewHistory = (usuario) => {
        obtenerHistorialDeCategorias(usuario.id).then((data) => {
            console.log(data);
            setHistorial(data)
            console.log(historial);
            setDataViewHistory(data);
            setOpenModalViewHistory(true);
        });
    }

    const modalDelete = (data) =>{
        setDataDelete(data)
        setOpenModalDelete(true)
    }

    const modalChangeCategoria = (data) =>{
        setDataChange(data)
        setOpenModalChange(true)
    }

    const modalChangeCategoriaClose = () =>{
        setOpenModalChange(false)
        setCategoriaSelected("")
        setCargandoChangePer(true)
    }

    const modalChangeHistorialClose = () =>{
        setOpenModalViewHistory(false)
        setCargandoViewHistory(true)
        setCargandoViewHistory(false);
    }

    const sendToApiDelete = async () => {

        setCargandoDeletePer(true)

        let data ={
            persona: dataDelete
        }

        let url = "api/v1.0/investigacion/eliminarCategoria"

        const callback = async(error, estado, resp) => {
            let titulo = "Ha ocurrido un error, contacte con el administrador."
            let tipo = "info"
            let filtro = ''
            if(!error){
                if(estado === 201){
                    tipo = "success"
                    titulo = resp.titulo
                }
            }

            dispatch(
                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
            );
            if(codigoActually != 'Tod_Bec'){
                filtro = `categoria_investigacion__codigo=${codigoActually}`
                let data = await obtenerPersonasCategoria(filtro);
                serPersonasCat(data)
            
            }else {
                
                filtro = `participante__categoria_investigacion__isnull=True`
                let data = await obtenerParticipantesCategoria(filtro);
                serPersonasCat(data)
                console.log(data);
                

            }

            // filtro = `participante__categoria_investigacion__codigo=${codigoActually}`
            // let data = await obtenerParticipantesCategoria(filtro);
            // serPersonasCat(data)
            obtenerEstadoSolicitudes()
            setCargandoDeletePer(false)
            setOpenModalDelete(false)
        }

        const msg = await Funciones.consulta(url, data, 'POST', callback)
    }

    const sendToApiChange = async () =>{

        let array = await validateInputs([
            { value: categoriaSelected, name: 'categorias', err: { empty: false } },
        ])

        setErrores(array)

        if(categoriaSelected == ""){
            
        }else if(codigoActually != 'Tod_Bec'){
            setCargandoChangePer(true)

            let data ={
                persona: dataChange,
                categoria: categoriaSelected
            }
            console.log(data);

            let url = `api/v1.0/investigacion/insertarCategoria?up=${true}`
           
    
            const callback = async(error, estado, resp) => {
                let titulo = "Ha ocurrido un error, contacte con el administrador."
                let tipo = "info"
                let filtro = ''
                
                if(!error){
                    if(estado === 201){
                        tipo = "success"
                        titulo = resp.titulo
                    }
                }
    
                if(estado == 201){
                    dispatch(
                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                    );
                    console.log(codigoActually);
                    if(codigoActually != 'Tod_Bec'){
                        filtro = `categoria_investigacion__codigo=${codigoActually}`
                        let data = await obtenerPersonasCategoria(filtro);
                        serPersonasCat(data)
                    
                    }
                    setCategoriaSelected("")
                    setOpenModalChange(false)
                    obtenerEstadoSolicitudes(true)

                }else{
                    setCategoriaSelected("")
                    setOpenModalChange(false)
                }
                
            }
    
            const msg = await Funciones.consulta(url, data, 'POST', callback)
         
        }else if(codigoActually == 'Tod_Bec' || openModalBuscarPersona==true){

            setCargandoChangePer(true)

            let data ={
                persona: dataChange,
                categoria: categoriaSelected
            }
            console.log(data);
            let url = `api/v1.0/investigacion/insertarCategoria?up=${true}`
            
    
            const callback = async(error, estado, resp) => {
                let titulo = "Ha ocurrido un error, contacte con el administrador."
                let tipo = "info"
                let filtro = ''
                
                if(!error){
                    if(estado === 201){
                        tipo = "success"
                        titulo = resp.titulo
                    }
                }
    
                if(estado == 201){
                    dispatch(
                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                    );
                    console.log(codigoActually);
                    if(codigoActually == 'Tod_Bec'){
                         
                        filtro = `participante__categoria_investigacion__isnull=True`
                        console.log(filtro);
                        let data = await obtenerParticipantesCategoria(filtro);
                        console.log(data);
                        serPersonasCat(data)
                        
        
                    }
                    setCategoriaSelected("")
                    setOpenModalChange(false)
                    obtenerEstadoSolicitudes(true)

                    
                }else{
                    setCategoriaSelected("")
                    setOpenModalChange(false)
                }
                
            }
    
            const msg = await Funciones.consulta(url, data, 'POST', callback)
        
        }else if(openModalBuscarPersona==false){
            setCargandoChangePer(true)
            
            let data ={
                persona: persona,
                categoria: categoriaSelected
            }
            
            let url = `api/v1.0/investigacion/insertarCategoria?up=${true}`
            
    
            const callback = async(error, estado, resp) => {
                let titulo = "Ha ocurrido un error, contacte con el administrador."
                let tipo = "info"
                let filtro = ''
                
                if(!error){
                    if(estado === 201){
                        tipo = "success"
                        titulo = resp.titulo
                    }
                }
    
                if(estado == 201){
                    dispatch(
                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                    );
                    
                    if(openModalBuscarPersona == false){
                         
                        filtro = `categoria_investigacion__codigo=${codigoActually}`
                        await obtenerPersonasCategoria(filtro);

                    }
                    setCategoriaSelected("")
                    setOpenModalChange(false)
                    obtenerEstadoSolicitudes(true)

                    
                }else{
                    setCategoriaSelected("")
                    setOpenModalChange(false)
                }
                
            }
    
            const msg = await Funciones.consulta(url, data, 'POST', callback)
        }

    }

    const acciones = (data) => {

        const detalle = (
            <div>
                <IconButton aria-label="done" onClick={() => modalViewHistory(data)}>
                    <MenuBook/>
                </IconButton>
                <IconButton aria-label="done" className={classes.colorDelete} onClick={() => {modalDelete(data)}}>
                    <ClearIcon/>
                </IconButton>
                <IconButton aria-label="done" className={classes.colorEdit} onClick={() => {modalChangeCategoria(data)}}>
                    <AutorenewIcon/>
                </IconButton>
            </div>
        );

        return detalle;
    };

    const accionesCategoria = (data) => {
        const detalle = (
            <div>
                <IconButton aria-label="done" className={classes.colorEdit} onClick={() => { modalChangeCategoria(data) }}>
                    <AutorenewIcon />
                </IconButton>
            </div>
        );
        return detalle;

    }

    const PintarLista = (props) => {
       
        return (
        <div>
          <ListarDatos
                id="tbl_becas_permisos"
                titulo='Personas con Categoría'
                descargar="EMMA - PERSONAS CON CATEGORÍA"
                datos={personasCat}
                opciones={true}
                buscar={true}
                agregar={false}
            
                acciones={row => acciones(row)}
                avatarImg={true}
                avatar={({ identificacion}) => `${Funciones.rutaImg}${identificacion}.jpg`}
                fila_principal={row => `${row.primer_nombre} ${row.segundo_nombre} ${row.primer_apellido} ${row.segundo_apellido}`.toUpperCase()}
                filas={[
                    
                     { 'id': 'correo', 'mostrar': ( { correo } ) => correo },
                     { 'id': 'identificacion', 'mostrar': ( { identificacion } ) => identificacion },
                ]}
            />

            {modalAdd == true && 
            <ModalAddPersonaCategoria 
                openModalAddIntegrante={modalAdd}
                setOpenModalAddIntegrante={setModalAdd}

                profesor={profesor}
                setProfesor={setProfesor}

                handleCloseModalAddPar={handleCloseModalAddPar}

                handleCloseModalChoicen={handleCloseModalChoicen}
                sendDataToApi={sendDataToApi}

                openModalChoicen={openModalChoicen}
                setOpenModalChoicen={setOpenModalChoicen}

                lineaSeleccion={lineaSeleccion}
                setLineaSeleccion={setLineaSeleccion}

                dataPersonaSelected={dataPersonaSelected}
                setDataPersonaSelected={setDataPersonaSelected}

                openModalBuscarPersona={openModalBuscarPersona}
                setOpenModalBuscarPersona={setOpenModalBuscarPersona}

                cargandoAddPersona={cargandoAddPersona}
                setCargandoAddPersona={setCargandoAddPersona}

                //Errores
                getError={getError}
                errores={errores}
                setErrores={setErrores}
            />}

            {openModalDelete == true && 
            <ModalDeletePersonaCategoria 
                openModalDelete={openModalDelete}
                setOpenModalDelete={setOpenModalDelete}

                sendToApiDelete={sendToApiDelete}

                cargandoDeletePer={cargandoDeletePer}
                setCargandoDeletePer={setCargandoDeletePer}
            />}

            {openModalChange == true && 
            <ModalChangePersonaCategoria 
                openModalChange={openModalChange}
                setOpenModalChange={setOpenModalChange}

                dataChange={dataChange}
                setDataChange={setDataChange}

                cargandoChangePer={cargandoChangePer}
                setCargandoChangePer={setCargandoChangePer}
                
                categoriaSelected={categoriaSelected}
                setCategoriaSelected={setCategoriaSelected}
                codigoActually={codigoActually}
                setCodigoActually={setCodigoActually}

                modalChangeCategoriaClose={modalChangeCategoriaClose}

                sendToApiChange={sendToApiChange}

                categorias={categorias}
                setCategorias={setCategorias}

                //Errores
                getError={getError}
                errores={errores}
                setErrores={setErrores}
            />}
            { openModalViewHistory == true &&
                <ModalViewHistory
                    openModalViewHistory={openModalViewHistory}
                    setOpenModalViewHistory={setOpenModalViewHistory}
                    pintarHistorialCategorias={pintarHistorialCategorias}
                    dataViewHistory={dataViewHistory}
                    setHistorial={setHistorial}
                    historial={historial}
                    modalChangeHistorialClose={modalChangeHistorialClose}

                ></ModalViewHistory>
            }

      </div>
      )
    }

    const pintarHistorialCategorias = (props) => {
        return (
            <>

            </>
        )
    }

    const PintarCategoriasTodos = (props) => {
        const [openModal, setOpenModal] = React.useState(false)
       
     const   handleClickSeleccionarPersona = (data) => {
            setpersona(data)
            setOpenModalChange(true)
          }
        
       

        return (
            <div>
                <div>
                    <Grid container direction={'row'} spacing={2} justify={'center'} >
                        <Grid item xs={12} sm={12} md={12} ld={12} >
                            <Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
                                <Grid container spacing={4} className='margin_cont' >
                                    <Grid item xs={12}>
                                        <InputBuscar
                                            id='profesor'
                                            label='Usuario'
                                            placeholder='Clic aquí para buscar'
                                            value={profesor.nombre}
                                            callback={() => setOpenModal(true)} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {openModal == true ?
                        <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={openModal} onClose={() => (false)}>
                            <AppBarModal titulo={'¡ Buscar Participante !'} mostrarModal={() => setOpenModal(false)} titulo_accion="CERRAR" accion={() => setOpenModal(false)} />
                            <DialogContent style={{ padding: '0' }} className='scroll'>
                                <BuscarPersona callback={(data) => handleClickSeleccionarPersona(data)} />
                                {/* setOpenModal(false) */}
                            </DialogContent>
                            <DialogActions>
                                <Button variant="contained" color="primary" style={{ color: "#fff" }} onClick={() => setOpenModal(false)}>
                                    Cancelar
                                </Button>
                            </DialogActions>
                        </Dialog>
                        : ''}

                </div>
                <div>
                    <ListarDatos
                        id="tbl_becas_permisos"
                        titulo='Personas sin Categoría'
                        descargar="EMMA - PERSONAS SIN CATEGORÍA"
                        datos={personasCat}
                        opciones={true}
                        buscar={true}
                        agregar={false}
                        acciones={row => accionesCategoria(row)}
                        avatarImg={true}
                        avatar={({ identificacion}) => `${Funciones.rutaImg}${identificacion}.jpg`}
                        fila_principal={row => `${row.primer_nombre} ${row.segundo_nombre} ${row.primer_apellido} ${row.segundo_apellido}`.toUpperCase()}
                        filas={[
                    
                             { 'id': 'correo', 'mostrar': ( { correo } ) => correo },
                                { 'id': 'identificacion', 'mostrar': ( { identificacion } ) => identificacion },
                            ]}
                    />
                    {openModalChange == true &&
                        <ModalChangePersonaCategoria
                            openModalChange={openModalChange}
                            setOpenModalChange={setOpenModalChange}

                            dataChange={dataChange}
                            setDataChange={setDataChange}

                            cargandoChangePer={cargandoChangePer}
                            setCargandoChangePer={setCargandoChangePer}

                            categoriaSelected={categoriaSelected}
                            setCategoriaSelected={setCategoriaSelected}
                            PersonasCat={serPersonasCat}
                            modalChangeCategoriaClose={modalChangeCategoriaClose}

                            codigoActually={codigoActually}
                            setCodigoActually={setCodigoActually}

                            sendToApiChange={sendToApiChange}

                            categorias={categorias}
                            setCategorias={setCategorias}

                            //Errores
                            getError={getError}
                            errores={errores}
                            setErrores={setErrores}
                        />}

                </div>
            </div>
        )
        }


    const PintarInicioProcesos = (props) => {


        const cambiarEstadoProceso = async (codigo) => {
            //console.log(codigo);
            let filtro = '';
            let filtro1='';
            
            if (codigo != 'Tod_Bec') {
                setCargando(true)
                setCodigoActually(codigo)
                filtro = `categoria_investigacion__codigo=${codigo}`
                setAccion('Listar')
                let data = await obtenerPersonasCategoria(filtro);
                setCargando(false)
                serPersonasCat(data)
                // console.log(data);
            } else  {
                setCargando(true)
                setCodigoActually(codigo)
                setOpenModal(true)
                setAccion('Cate')
                filtro = `participante__categoria_investigacion__isnull=True`
                let data = await obtenerParticipantesCategoria(filtro);
                setCargando(false)
                serPersonasCat(data)
             
            }
             
           

        }

        const obtenerColor = (codigo) => {
          let color = 'white';
          const colores = {
            'Semi_activo': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
            'Inves_cate_adjunto': { 'backgroundColor': '#ffc107', color },
            'Inves_cate_titular': { 'backgroundColor': '#01519B', color },
            'Inves_cate_asistente': { 'backgroundColor': '#009688', color },
            'Est_bec_neg': { 'backgroundColor': '#b71c1c', color },
            'Est_bec_can': { 'backgroundColor': '#b71c1c', color },
            'Est_bec_Apr_th': { 'backgroundColor': '#bdbdbd', color },
            'Est_bec_Apr_Rev': { 'backgroundColor': '#bdbdbd', color },
            'Tod_Bec': { 'backgroundColor': '#0d47a1', color }
        }
          return (colores[codigo])
        }
    
        return (
        <div>
                <div>
                 <BtnRegresar className={'regresar'} callback={handleClickInicio}  />
                </div>
                    <div style={{ padding: '2% 2% 0% 2%' }} >
                    <Grid container direction={'row'} spacing={1} justify={'center'} >
                        <Grid item xs={12} sm={12} md={12} lg={10} >
                        <Grid container direction={'row'} alignItems="center" spacing={1} style={{ width: "100%" }}>
                            <Grid item xs={6} sm={6} md={6} lg={6} >
                            <TituloAccion principal='' alterno='Seleccione el estado a gestionar: ' />
                            </Grid>
                        </Grid>
                        <Paper elevation={0} square className="scroll contenido_fijo">
                            { (solicitudes.length === 0) ?
                            <TareasTerminadas mensaje='Aún no hay solicitudes para este periodo.' marginTop='7%' imagen={emma_s1} widthImg="7%" /> :
                            <Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
                            {solicitudes.map(({ categoria_investigacion__codigo: codigo, categoria_investigacion__nombre: nombre, num_sol}, i) => {
                                return (
                                  <Grid item xs={12} sm={12} md={6} lg={6} key={i}>
                                    <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => cambiarEstadoProceso(codigo)}>
                                      <AccionesRapidas imagen='' titulo={`¡ ${nombre} !`} sub_titulo='' des_sub_titulo='' avatar={obtenerColor(codigo)} avatar_name= {nombre.charAt(0).toUpperCase()} list_desc={Number.isInteger(num_sol) ? [`Numero de Solicitudes : ${num_sol}`] :  ['Debe aplicar los filtros.']}/>
                                    </Paper>
                                  </Grid>
                                )
                              })}
                              </Grid>
                              }
                        </Paper>
                        </Grid>
                    </Grid>
                </div>

            </div>
        )
      }

    const handleClick = () => {
        setAccion('Procesos')
        serPersonasCat([])
    };

    return (
        <div>
        {
            !cargando ?
            <div>
            <div>
                {accion == 'Procesos' && <PintarInicioProcesos seleccion={props.setSeleccion} />}
                        {accion == 'Listar' && <PintarLista />}
                        {accion == 'Cate' &&
                            <PintarCategoriasTodos
                                openModalChange={openModal}
                                setOpenModal={setOpenModal}
                                modalChangeCategoriaClose={modalChangeCategoriaClose}
                                modalAllCategoriaFilter={props.modalAllCategoriaFilter}
                                personasCat={personasCat}
                                setPersonasCat={serPersonasCat}

                                profesor={profesor}
                                setProfesor={setProfesor}
                            />}

                        {accion != 'Procesos' &&
                        <div className={'regresar'} > <BtnRegresar callback={handleClick} /></div>}



                    </div>
                    </div>: <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
            }
        </div>
    )
}


const ModalViewHistory = (props) => {
    const handleOnchange = (event) => {
        props.setCategoriaSelected(event.target.value);
        if(props.errores.length > 0){
            props.setErrores(props.errores.filter(er => er.llave != "categorias"));
            // Elimina error cuando se escribe en el campo
        }
    };
    console.log('Open modal view history', props.openModalViewHistory);
    console.log('Data view history', props.dataViewHistory);

    console.log('Historial: ', props.historial);


    const obtenerColor = (codigo) => {
        let color = 'white';
        const colores = {
            'Semi_activo': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
            'Inves_cate_adjunto': { 'backgroundColor': '#ffc107', color },
            'Inves_cate_titular': { 'backgroundColor': '#01519B', color },
            'Inves_cate_asistente': { 'backgroundColor': '#009688', color },
            'Est_bec_neg': { 'backgroundColor': '#b71c1c', color },
            'Est_bec_can': { 'backgroundColor': '#b71c1c', color },
            'Est_bec_Apr_th': { 'backgroundColor': '#bdbdbd', color },
            'Est_bec_Apr_Rev': { 'backgroundColor': '#bdbdbd', color },
            'Tod_Bec': { 'backgroundColor': '#0d47a1', color }
        }
        return (colores[codigo])
    }

    return (
        <div>
            <Dialog open={props.openModalViewHistory} maxWidth='sm' fullWidth={true} onClose={()=>{props.modalChangeHistorialClose()}}>
                <AppBarModal titulo='¡ Detalle Proceso !' onClose={()=>{props.modalChangeHistorialClose()}} mostrarModal={() => props.setOpenModalViewHistory(false)} titulo_accion='' />
                <DialogContent style={{ padding: 0 }} className='scroll' >
                    {props.historial.length > 0 &&
                        <ListarDatos
                            datos={props.historial}
                            id='tbl_historial_categorias'
                            avatar={({ tipo_estado }) => 'C'}
                            color_avatar={({ estado_actual }) => obtenerColor(estado_actual.codigo)}
                            fila_principal={({ estado_actual }) => estado_actual.nombre}
                            filas={[
                                { 'id': 'usuario_registro', 'mostrar': ({usuario_registro: { primer_nombre, primer_apellido, segundo_apellido }}) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}` },
                                { 'id': 'fecha_registro', 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a'), 'nombre': 'Fecha Registro : ' },
                            ]}
                        />
                    }

                </DialogContent>
                <DialogActions>
                    <BtnForm texto="Cerrar" callback={() => props.modalChangeHistorialClose()} />
                </DialogActions>
            </Dialog>
        </div>
    )
}

const ModalChangePersonaCategoria = (props) =>{

    //Ayuda para cambiar el valor del select
    const handleOnchange = (event) => {
        props.setCategoriaSelected(event.target.value);
        if(props.errores.length > 0){
            props.setErrores(props.errores.filter(er => er.llave != "categorias"));
            // Elimina error cuando se escribe en el campo
        }
    };

    return (
        <div>
              <Dialog open={props.openModalChange} onClose={()=>{props.modalChangeCategoriaClose()}} aria-labelledby="form-dialog-title">
  
                  <AppBarModal titulo='Cambiar Categoría del Usuario' mostrarModal={()=>{props.modalChangeCategoriaClose()}} titulo_accion="" accion="" />
                  {/* Contenido del modal del formulario de */}
                  <DialogContent>
                    <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">Categoría</InputLabel>
                        <FormControl fullWidth error = { props.getError('categorias', props.errores).length > 0}>
                            <Select
                                value={props.categoriaSelected}
                                displayEmpty
                                fullWidth
                                inputProps={{ 'aria-label': 'Without label' }}
                                onChange={handleOnchange}
                                name="categorias"
                            >
                                <MenuItem value="">
                                    <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.categorias.length} Categorías Seleccionadas` }</em>
                                </MenuItem>
                                {props.categorias.map((item, index) => (
                                <MenuItem key= { index } value = { item }>{`${item.nombre}`}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormHelperText>{ props.getError('categorias', props.errores) }</FormHelperText>
                    </Grid>
                  </DialogContent>
  
                  <DialogActions>
                    <Button disabled={props.cargandoDeletePer == true} onClick={()=>{props.sendToApiChange()}} >
                        Aceptar
                    </Button>
                  </DialogActions>
  
              </Dialog>
          </div>
    )
}

const ModalDeletePersonaCategoria = (props) =>{
    return (
        <div>
              <Dialog open={props.openModalDelete} onClose={()=>{props.setOpenModalDelete(false)}} aria-labelledby="form-dialog-title">
  
                  <AppBarModal titulo='Eliminar Categoria Estudiante' mostrarModal={()=>{props.setOpenModalDelete(false)}} titulo_accion="" accion="" />
                  {/* Contenido del modal del formulario de */}
                  <DialogContent>
                    {props.cargandoDeletePer == false ? <Box>
                        <Typography variant="body2"> ¿De verdad desea eliminar la Categoría del Usuario? </Typography>
                    </Box> : <TareasTerminadas imagen={emma_s} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' />}
                  </DialogContent>
  
                  <DialogActions>
                    <Button disabled={props.cargandoDeletePer == true} onClick={()=>{props.setOpenModalDelete(false)}} >
                        Cancelar
                    </Button>
                    <Button disabled={props.cargandoDeletePer == true} onClick={()=>{props.sendToApiDelete()}} >
                        Aceptar
                    </Button>
                  </DialogActions>
  
              </Dialog>
          </div>
    )
}

const ModalAddPersonaCategoria = (props) =>{
    
    const classes = useStyles()

    return(
        <div>
              <Dialog open={props.openModalAddIntegrante} onClose={()=>{props.handleCloseModalAddPar()}} aria-labelledby="form-dialog-title">
  
                  <AppBarModal titulo='Agregar Integrante al Semillero' mostrarModal={()=>{props.handleCloseModalAddPar()}} titulo_accion="" accion="" />
                  {/* Contenido del modal del formulario de */}
                  <DialogContent>
                    <Box>
                        {props.cargandoAddPersona == false ? 
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                            <FormControl className={classes.formControl} fullWidth /*error = { props.getError('profesor', props.errores).length > 0}*/>
                                <InputBuscar
                                    id='Persona'
                                    label='Persona'
                                    name="Persona"
                                    fullWidth
                                    placeholder='Clic aquí para buscar'
                                    value={props.profesor.nombre == undefined ? '' : props.profesor.nombre}
                                    callback={() => {
                                        props.setOpenModalBuscarPersona(true)
                                    }} />
                                </FormControl>
                            {/*<FormHelperText style={{color: "#FF2D00"}}>{ props.getError('profesor', props.errores) }</FormHelperText>*/}
                            </Grid>
                        </Grid>: <TareasTerminadas imagen={emma_s} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' />}
                    </Box>
                  </DialogContent>
  
                  <DialogActions>
                  </DialogActions>
  
              </Dialog>

              {props.openModalBuscarPersona == true ? 
                <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={props.openModalBuscarPersona} onClose={() => props.setOpenModalBuscarPersona(false)}>
                    <AppBarModal titulo={'¡ Buscar Estudiante !'} mostrarModal={() => props.setOpenModalBuscarPersona(false)} titulo_accion="CERRAR" accion={() => props.setOpenModalBuscarPersona(false)} />
                    <DialogContent style={{ padding: '0' }} className='scroll'>
                        <BuscarPersona callback={(data) => {
                            props.setOpenModalChoicen(true)
                            
                            props.setDataPersonaSelected(data)
                            
                            props.setProfesor({id: data.id, nombre:  `${data.primer_nombre} ${data.segundo_nombre} ${data.primer_apellido} ${data.segundo_apellido}`})

                        }} />
                    </DialogContent>
                    <DialogActions>
                            <Button variant="contained" color="primary" style={{color: "#fff"}} onClick={() => props.setOpenModalBuscarPersona(false)}>
                                Cancelar
                            </Button>
                    </DialogActions>
                </Dialog>
                : ''}

                {props.openModalChoicen == true ?
                <ChoicenTheCategory
                openModalChoicen={props.openModalChoicen}
                setOpenModalChoicen={props.setOpenModalChoicen}

                dataPersonaSelected={props.dataPersonaSelected}
                setDataPersonaSelected={props.setDataPersonaSelected}
                
                lineaSeleccion={props.lineaSeleccion}
                setLineaSeleccion={props.setLineaSeleccion}

                handleCloseModalChoicen={props.handleCloseModalChoicen}
                sendDataToApi={props.sendDataToApi}

                //Errores
                getError={props.getError}
                errores={props.errores}
                setErrores={props.setErrores}
                />: ''}
          </div>
    )
}

const ChoicenTheCategory = (props) =>{

    const [categorias, setCategorias] = React.useState([])
    const [cargandoChoicens, setCargandoChoicens] = React.useState(true)
    
    const isInitialMount = React.useRef(true);
    const classes = useStyles()
    React.useEffect( () => {
        //Obtengo los prceosos activos de la persona activa.
        if(isInitialMount.current){
                obtenerCategorias()
            
        }
    })

    //Obtengo las categorias
    const obtenerCategorias = async () => {
        try{
          const data = await Funciones.obtenerValores(Helper.CATEGORIA_INVESTIGACION)
          setCategorias(data)
          setCargandoChoicens(false)
        }catch(err){
          console.log(err)
        } 
    }

    //Funcipan para cambiar el seleccionado
    const handleChangeLinea = (event) => {
        props.setLineaSeleccion(event.target.value);
        if(props.errores.length > 0){
            props.setErrores(props.errores.filter(er => er.llave != "lineaSubInves"));
            // Elimina error cuando se escribe en el campo
        }
    };

    return (
        <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={props.openModalChoicen} onClose={() => props.handleCloseModalChoicen()}>
            <AppBarModal titulo='Elija lo que desea realizar' mostrarModal={()=>{props.handleCloseModalChoicen()}} titulo_accion="" accion="" />
            <DialogContent>
                {cargandoChoicens == false ? 
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">Categorías</InputLabel>
                        <FormControl fullWidth error = { props.getError('lineaSubInves', props.errores).length > 0}>
                        <Select
                            value={props.lineaSeleccion}
                            displayEmpty
                            fullWidth
                            inputProps={{ 'aria-label': 'Without label' }}
                            onChange={handleChangeLinea}
                        >
                            <MenuItem value="">
                                <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${categorias.length} Estados Disponibles` }</em>
                            </MenuItem>
                            {categorias.map((item, index) => (
                            <MenuItem key= { index } value = { item }>{`${item.nombre}`}</MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                        <FormHelperText>{ props.getError('lineaSubInves', props.errores) }</FormHelperText>
                    </Grid>
              </Grid> : <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_s} widthImg="7%" />}
            </DialogContent>
            <DialogActions>
                    <Button variant="contained" color="primary" style={{color: "#fff"}} onClick={() => props.sendDataToApi()}>
                        Aceptar
                    </Button>
            </DialogActions>
        </Dialog>
    ) 
}

export default AdminAdministrar 