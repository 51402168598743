import React from 'react';
import { List, ListItem, ListItemText, ListItemAvatar, Avatar } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const TIPOS = {
	info: { backgroundcolor: '#e3f2fd', color: '#607d8b', icon: () => <HelpOutlineIcon style={{ color: '#64b5f6' }} /> },
	warnig: { backgroundcolor: '#fff8e1', color: '#ffab00', icon: () => <WarningIcon style={{ color: '#ffab00' }} /> },
	error: { backgroundcolor: '#ffebee', color: '#b71c1c', icon: () => <ErrorOutlineIcon style={{ color: '#b71c1c' }} /> },
	danger: { backgroundcolor: '#F07257', color: '#b71c1c', icon: () => <ErrorOutlineIcon style={{ color: '#b71c1c' }} /> },
	success: { backgroundcolor: '#e8f5e9', color: '#1b5e20', icon: () => <CheckCircleOutlineIcon style={{ color: '#1b5e20' }} /> }
};

const useStyles = (tipo, conCallback, margin) => ({
	root: {
		paddingRight: 20,
		paddingBottom: 20,
		margin,
		width: '100%',
		backgroundColor: TIPOS[tipo].backgroundcolor,
		borderRadius: '2%',
		cursor: conCallback && 'pointer'
	},
	avatar: {
		backgroundColor: TIPOS[tipo].backgroundcolor
	},
	icon: {
		color: TIPOS[tipo].colorIcon
	},
	item: {
		fontWeight: 'lighter',
		opacity: 0.8
	}
});

export default function AlertasSimple({
	tipo = 'info',
	titulo,
	sub_titulo,
	conCallback = true,
	callback = () => { },
	margin = '12px 0px 0px 0px',
	lista
}) {
	const classes = useStyles(tipo, conCallback, margin);
	return (
		<List style={classes.root} onClick={() => callback()}>
			<ListItem style={{ padding: 3 }}>
				<ListItemAvatar>
					<Avatar style={classes.avatar}>{TIPOS[tipo].icon()}</Avatar>
				</ListItemAvatar>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<ListItemText primary={titulo} secondary={sub_titulo} />
					{lista && 
						<ul style={classes.item}>
							{
								lista.map(({ item, subitem }, i) => {
									let aux = []
									if (subitem) {
										subitem.map(i => {
											aux.push(<><span style={{ marginLeft: '5px' }}>{i}</span><br /></>)
										})

									}
									return <><li key={i}>{item}</li>{aux}</>
								}
								)
							}
						</ul>
					}
				</div>
			</ListItem>
		</List>
	);
}
