import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppBarModal from '../../general/AppBarModal'
import {DialogActions, DialogContent, Dialog, Button} from '@material-ui/core';
import ListarDatos from "../../general/ListarDatos";
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Image} from '@react-pdf/renderer';
import {CONDICIONALES_TIPO_REA } from './helper';
import {obtenerValoresFiltros } from "../../../global/js/funciones";
import logocuc2 from '../../../global/imagenes/logo_cuc2.png'; 

const styles = StyleSheet.create({

  titlePrincipal:{
    width:'50%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom:30,
    textAlign:'center',
    color:'#8C222D',
    fontSize:18,
  },
  imagen:{
    width:'30%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom:30,
    textAlign:'center',
    color:'#B22507',
  },
  title: {
    width:'70%',
    marginRight:30,
    marginBottom:30,
    fontSize: 12,
    textAlign: 'left',
    backgroundColor: '#e4e4e4',
    textTransform: 'uppercase',
  },
  title3: {
    width:'100%',
    marginRight:30,
    marginBottom:10,
    fontSize: 12,
    textAlign: 'left',
    backgroundColor: '#e4e4e4',
    textTransform: 'uppercase',
  },
  title2: {
    width:'30%',
    marginRight:30,
    marginBottom:30,
    fontSize: 12,
    textAlign: 'center',
    backgroundColor: '#e4e4e4',
    textTransform: 'uppercase',
  },
  body: {
    margin:30,
    flexGrow: 1,
  },
  row: {
    flexGrow:0,
    flexDirection: 'row',
   
  },
  block: {
    flexGrow: 1,
  },
  text: {
    width:'30%',
    fontSize: 12,
    marginLeft: 2,
    marginTop:3,
    textAlign: 'justify',
  },
  textEstilos: {
    width:'23%',
    fontSize: 12,
    marginLeft: 2,
    marginTop:30,
    textAlign: 'justify',
    backgroundColor: '#e4e4e4',
  },
  estilos: {
    width:'5%',
    marginTop:3,
    fontSize: 12,
    marginLeft: 2,
    textAlign: 'justify',
  },
  text2: {
    width:'30%',
    fontSize: 10,
    marginLeft: 2,
    marginTop:3,
    textAlign: 'justify',
  },
  estilos2: {
    width:'5%',
    marginTop:3,
    fontSize: 10,
    marginLeft: 2,
    textAlign: 'justify',
  },
  nombreCondicionales: {
    width:'22%',
    fontSize: 12,
    marginLeft: 2,
    marginTop: 20,
    marginBottom: 7,
    textAlign: 'justify',
  },
  condicionalesTipo: {
    width:'78%',
    fontSize: 12,
    marginTop: 20,
    marginBottom: 7,
    marginLeft: 2,
    textAlign: 'justify',
  },
  caracteristicas: {
    width:'50%',
    backgroundColor: '#BDFAE9',
    fontSize: 12,
    marginBottom: 10,
    marginLeft: 2,
    padding:10,
    textAlign: 'justify',
  },
  estrategias: {
    width:'50%',
    backgroundColor: '#DFBAC3',
    fontSize: 12,
    padding:10,
    marginBottom: 10,
    marginLeft: 2,
    textAlign: 'justify',
  },
});

// Create Document Component

class detalleGrupo extends Component {

  constructor(props) {
    super(props)
    this.state = {
      cargando: false,
      condicionalesTipo:[],
      fechaActual:'',
    }
  }
  
  async componentDidMount() {
    this.setState({ cargando: true })
    let condicionalesTipo = await obtenerValoresFiltros([{"llave" : 'generica', 'valor' : CONDICIONALES_TIPO_REA}]);
    const f = new Date();
    const dia=f.getDate()
    const mes=f.getMonth() + 1
    const anho=f.getFullYear()
    this.setState({ condicionalesTipo, cargando: false, fechaActual:dia+'/'+mes+'/'+anho})
  }

  HelloWorldPDF(estadistica){
    let {condicionalesTipo} = this.state
    let {grupo, nombreAsignatura, asimilador, acomodador, divergente, convergente,
       nombrePrograma, nombreFacultad} = this.props
      return(
        <Document>
        <Page size="A4">
          <View style={styles.body}>
            <View style={styles.row}>
              <Image src={logocuc2}  style={styles.imagen}/>
              <Text style={styles.titlePrincipal}>Vicerrectoría Académica Centro de Excelencia Docente REA</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.title3}>FACULTAD: {nombreFacultad}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.title3}>PROGRAMA: {nombrePrograma}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.title}>ASIGNATURA: {nombreAsignatura}</Text>
              <Text style={styles.title2}>ID GRUPO: {grupo}</Text>
            </View>
              <View style={styles.row}>
                <Text style={styles.text}>NOMBRE ESTUDIANTE</Text>
                <Text style={styles.text}>IDENTIFICACION</Text>
                <Text style={styles.estilos}>EC</Text>
                <Text style={styles.estilos}>OR</Text>
                <Text style={styles.estilos}>CA</Text>
                <Text style={styles.estilos}>EA</Text>
                <Text style={styles.text}>ESTILO</Text>
              </View>
              {
            estadistica.map(( elemento, i ) => (
              <View key={i} style={styles.row}>
                <Text style={styles.text2}>{`${elemento.usuario_registro.primer_nombre} ${elemento.usuario_registro.primer_apellido} ${elemento.usuario_registro.segundo_apellido}`}</Text>
                <Text style={styles.text2}>{elemento.usuario_registro.identificacion}</Text>
                <Text style={styles.estilos2}>{elemento.experiencia_concreta}</Text>
                <Text style={styles.estilos2}>{elemento.observacion_reflexiva}</Text>
                <Text style={styles.estilos2}>{elemento.conceptualizacion_abstracta}</Text>
                <Text style={styles.estilos2}>{elemento.experimentacion_activa}</Text>
                <Text style={styles.text2}>{elemento.tipo_aprendizaje.nombre}</Text>
              </View>
              ))
          }
              <View style={styles.row}>
                <Text style={styles.textEstilos}>ACOMODADOR: {acomodador}</Text>
                <Text style={styles.textEstilos}>DIVERGENTE: {divergente}</Text>
                <Text style={styles.textEstilos}>ASIMILADOR: {asimilador}</Text>
                <Text style={styles.textEstilos}>CONVERGENTE: {convergente}</Text>
              </View>
          </View>
        </Page>
        <Page size="A4">
        <View style={styles.body}>
          {
            condicionalesTipo.map(( elemento, i ) => (
              <>
              <View key={'0'+i} style={styles.row}>
                <Text style={styles.nombreCondicionales}>{elemento.nombre}</Text>
                <Text style={styles.condicionalesTipo}>{elemento.descripcion}</Text>
              </View>
              <View key={'1'+i} style={styles.row}>
                <Text style={styles.caracteristicas}>CARACTERISTICAS</Text>
                <Text style={styles.estrategias}>ESTRATEGIAS METODOLÓGICAS</Text>
              </View>
              <View key={'2'+i} style={styles.row}>
                <Text style={styles.caracteristicas}>{elemento.valora}</Text>
                <Text style={styles.estrategias}>{elemento.valorb}</Text>
              </View>
            </>
            ))
        }
        </View>
        </Page>
      </Document>
      )
    };
    
  render() {
    let { modalDetalleGrupo, mostrarModalDetalleGrupo, estadistica,descargar} = this.props;
    let {fechaActual}= this.state
    return (
      <>
      <Dialog fullWidth={true} maxWidth="md" open={modalDetalleGrupo} onClose={() => mostrarModalDetalleGrupo(false)}>
        <AppBarModal titulo={"¡ Estilos de Aprendizaje !"} mostrarModal={() => mostrarModalDetalleGrupo(false)} titulo_accion="Cerrar" accion={() => mostrarModalDetalleGrupo(false)}/>
        <DialogContent >
        <ListarDatos
          datos={estadistica}
          titulo='Lista Aplicacion Prueba'
          id='tbl_rea_listar'
          opciones={descargar} 
          descargar='REA'
          filtrar={false}
          refrescar={false}
          buscar={true}
          avatar={({  usuario_registro: { primer_nombre } }) => primer_nombre.toUpperCase().charAt(0)}
          fila_principal={({ usuario_registro: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`}
          filas={[
            { 'id': 'Nombre', 'mostrar': ({ usuario_registro: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}` },
            { 'id': 'ID', 'mostrar': ({ usuario_registro : { identificacion } }) => identificacion },
            { 'id': 'EC', 'mostrar': ({ experiencia_concreta}) => experiencia_concreta,  'enLista': false },
            { 'id': 'OR', 'mostrar': ({ observacion_reflexiva}) => observacion_reflexiva, 'enLista': false },
            { 'id': 'CA', 'mostrar': ({ conceptualizacion_abstracta}) => conceptualizacion_abstracta, 'enLista': false },
            { 'id': 'EA', 'mostrar': ({ experimentacion_activa}) => experimentacion_activa, 'enLista': false },
            { 'id': 'Estilo', 'mostrar': ({ tipo_aprendizaje : { nombre } }) => nombre }
          ]}
        />
        </DialogContent>
        <DialogActions>
        <PDFDownloadLink style={{textDecoration:'none'}} document={this.HelloWorldPDF(estadistica)} fileName={`${fechaActual}- REA.pdf`}>
          {
            ({loading}) => (loading ? 'Cargando...' : <Button variant="contained" color='primary' size="large" style={{color:'white', textDecoration:'none'}}>Descargar PDF</Button>)
          }     
        </PDFDownloadLink>
        </DialogActions>
      </Dialog >
      </>
    )
  }
}

//Props del componente
detalleGrupo.propTypes = {
  modalDetalleGrupo: PropTypes.bool.isRequired,
  
}

export default detalleGrupo;