import React, {Component} from 'react'
import PropTypes from 'prop-types';
import AppBarModal from "../../general/AppBarModal";
import { BtnForm } from "../../general/BotonesAccion";
import { consulta} from "../../../global/js/funciones";
import { Dialog, DialogContent, DialogActions, Grid, TextField } from '@material-ui/core';
import ListarDatos from "./../../general/ListarDatos";
import moment from 'moment'

class InscripcionesObservacionPreUniversitario extends Component {
    constructor(props) {
        super(props)
        this.state = {
          cargando: true,
          observaciones:[],
          mensaje:''
        }
    }
    async componentDidUpdate({ modalObservacionPreUniversitario }) {
        let {modalObservacionPreUniversitario: modalObservacionPreUniversitarioNew } = this.props;
        if (modalObservacionPreUniversitario !== modalObservacionPreUniversitarioNew) {
            let observaciones = await this.obtenerDatosObservaciones()
            this.setState({observaciones})
        }
      }
    async obtenerDatosObservaciones(){
        let { inscripcion } = this.props
        let f= inscripcion.id
        return new Promise(resolve => {
            consulta(`api/v1.0/inscripciones/observaciones/preuniversitario/${f}`, null, null, (error, estado, resp) => {
                resolve(estado === 200 && !error ? resp : null);
            })
        })
    }

    pintarEstados = () => {
        let { observaciones } = this.state
        let estados_new = []
        if (observaciones.length > 0) {
            observaciones.map((dato) => {
              let {
                usuario_registro,
                fecha_registro,
                mensaje,
              } = dato;
    
              if (usuario_registro && fecha_registro) estados_new.push({ usuario_registro, fecha_registro, tipo_estado: { nombre: 'Registrada', codigo: 'Ins_Reg' }, mensaje: mensaje })
            })
          }
          const obtenerColor = (estado) => {
            const colores = {
              'Ins_Reg': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
            }
            return (colores[estado])
          }
      
          return (
            <ListarDatos
              datos={estados_new}
              titulo=''
              id='tbl_estados_inscripciones'
              avatar={({ tipo_estado }) =>  tipo_estado.nombre.toUpperCase().charAt(0)}
              color_avatar={({ tipo_estado }) => obtenerColor(tipo_estado.codigo)}
              fila_principal={({ tipo_estado }) => tipo_estado.nombre}
              filas={[
                { 'id': 'mensaje', 'mostrar': ({ mensaje }) => mensaje },
                { 'id': 'fecha', 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a'), 'nombre': 'Fecha: ' },
                { 'id': 'usuario', 'mostrar': ({ usuario_registro: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}` },
      
              ]}
            />
          )
        }

    onChange = ({ target }) => {
        let { value, name } = target;
        this.setState({
            [name]: value
        })
        }
    
    enviar = (e) =>{
        let {mensaje}= this.state  
        let { actualizarMensaje } = this.props
        let error = false;
        if (!mensaje) { error = true;}
        if (!error) this.enviarObservacion();
        else actualizarMensaje({ titulo: 'El campo Observacion es Obligatorio', tipo: 'info', mostrar: true, tiempo: 6000 });
    }

    enviarObservacion= (info) => { 
        let { inscripcion, guardarDatosPreUniversitario } = this.props;
        let { mensaje } = this.state
          let data = {
            id_estudiante:inscripcion.id,
            mensaje,
            tipo:'observacion',
          }
          guardarDatosPreUniversitario(data, () => this.setState({ cargando: false, titulo_res: 'Cargando..'}));
          this.setState({mensaje : ''})
        }
    

    render(){
        let { mostrarModalObservacionPreUniversitario, modalObservacionPreUniversitario } = this.props;
        let { mensaje} = this.state
        return(
            <>
                <Dialog fullWidth={true} maxWidth="sm" open={modalObservacionPreUniversitario} onClose={() => mostrarModalObservacionPreUniversitario(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <AppBarModal titulo='¡ Observaciones !' mostrarModal={mostrarModalObservacionPreUniversitario} titulo_accion="" />
                    <DialogContent className='scroll'>
                        <Grid container spacing={3} style={{ marginBottom: '40px' }}>
                            <Grid item xs={12} >
                                <TextField
                                    value={mensaje}
                                    type="text"
                                    id="mensaje"
                                    name="mensaje"
                                    label="Observaciones"
                                    fullWidth
                                    onChange={this.onChange}
                                />
                            </Grid>
                        </Grid>
                        {this.pintarEstados()}
                    </DialogContent>
                    <DialogActions>
                        <BtnForm texto="Agregar" callback={() => this.enviar()} />
                        <BtnForm texto="Cerrar" callback={() => mostrarModalObservacionPreUniversitario(false)} />
                    </DialogActions>
                </Dialog>
            </>
        )   
    }
}

InscripcionesObservacionPreUniversitario.propTypes = {
    modalObservacionPreUniversitario: PropTypes.bool.isRequired,
    mostrarModalObservacionPreUniversitario: PropTypes.func.isRequired,
    guardarDatosPreUniversitario: PropTypes.func.isRequired,
    
}

export default InscripcionesObservacionPreUniversitario;