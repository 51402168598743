import React, { Component } from "react";
import { PropTypes } from "prop-types";
import AppBarModal from "../../general/AppBarModal";
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from "../../../global/imagenes/emma_w.png";
import { BtnFormPage } from "../../general/BotonesAccion";
import BuscarSelect from '../../general/BuscarSelect';
import { Id_Tipo_colegio, Id_Barrio_colegio, Id_Departamento_colegio } from '../helper';
import { Dialog, DialogContent, Grid, TextField, DialogActions, InputLabel, FormControl, Select, MenuItem } from "@material-ui/core";
import { obtenerValores, obtenerValoresFiltros, obtenerPermisosValor } from "../../../global/js/funciones";

function Datos(component_props) {
  let {
    onChange,
    nombreColegio,
    direccion,
    nombreRector,
    fax,
    nombreContacto,
    telefonoContacto,
    correo,
    telefonos,
    paginaWeb,
    cargoContacto,
    Tipo,
    tipos,
    barrio,
    barrios,
    departamento,
    departamentos,
    setDepartamento,
    ciudad,
    ciudades,
    setCiudad
  } = component_props;
  return (
    <>
      <Grid container spacing={3} style={{ marginBottom: "40px" }}>
        <Grid item xs={6}>
          <TextField
            autoFocus
            value={nombreColegio}
            required
            type="text"
            id="nombreColegio"
            name="nombreColegio"
            label="Nombre del colegio"
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <BuscarSelect datos={departamentos} cambiarEstado={(value) => setDepartamento(value)} valor={departamento} id='departamento' nombre='¿En que departamento se encuentra?' placeholder='Seleccione Opción' />
        </Grid>
        <Grid item xs={6}>
          <BuscarSelect datos={ciudades} cambiarEstado={(value) => setCiudad(value)} valor={ciudad} id='ciudad' nombre='¿En que ciudad o municipio se encuentra?' placeholder='Seleccione Opción' />
        </Grid>
        <Grid item xs={6}>
          <FormControl className="form-control" required>
            <InputLabel id="demo-simple-select-label">Seleccione el tipo de Colegio</InputLabel>
            <Select id="Tipo" value={Tipo} name='Tipo' onChange={onChange} >
              {tipos.map(tipo => <MenuItem key={tipo.id} value={tipo.id}>{tipo.nombre}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl className="form-control" required>
            <InputLabel id="demo-simple-select-label">Seleccione el Barrio</InputLabel>
            <Select id="barrio" value={barrio} name='barrio' onChange={onChange} >
              {barrios.map(Barrio => <MenuItem key={Barrio.id} value={Barrio.id}>{Barrio.nombre}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={direccion}
            required
            type="text"
            id="direccion"
            name="direccion"
            label="Dirección"
            fullWidth
            onChange={onChange}
          ></TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={correo}
            type="email"
            id="correo"
            name="correo"
            label="E-mail"
            fullWidth
            onChange={onChange}
          ></TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={nombreRector}
            type="text"
            id="nombreRector"
            name="nombreRector"
            label="Nombre Rector"
            fullWidth
            onChange={onChange}
          ></TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            value={telefonos}
            type="text"
            id="telefonos"
            name="telefonos"
            label="Teléfonos"
            fullWidth
            onChange={onChange}
          ></TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={fax}
            type="text"
            id="fax"
            name="fax"
            label="Fax"
            fullWidth
            onChange={onChange}
          ></TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={paginaWeb}
            type="text"
            id="paginaWeb"
            name="paginaWeb"
            label="Página Web"
            fullWidth
            onChange={onChange}
          ></TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={nombreContacto}
            type="text"
            id="nombreContacto"
            name="nombreContacto"
            label="Nombre del contacto"
            fullWidth
            onChange={onChange}
          ></TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={cargoContacto}
            type="text"
            id="cargoContacto"
            name="cargoContacto"
            label="Cargo del contacto"
            fullWidth
            onChange={onChange}
          ></TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={telefonoContacto}
            type="text"
            id="telefonoContacto"
            name="telefonoContacto"
            label="Teléfonos del contacto"
            fullWidth
            onChange={onChange}
          ></TextField>
        </Grid>
      </Grid>
    </>
  );
}

class PromocionCrear extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cargando: true,
      titulo_carg: '',
      activeStep: 0,
      nombreColegio: "",
      direccion: "",
      nombreRector: "",
      fax: "",
      nombreContacto: "",
      telefonoContacto: "",
      correo: "",
      telefonos: "",
      paginaWeb: "",
      cargoContacto: "",
      Tipo: "",
      tipos: [],
      barrio: "",
      barrios: [],
      departamento: "",
      departamentos: [],
      ciudad: "",
      ciudades: []
    };
  }

  componentDidMount() {
    this.setState({ cargando: true })
    let { nombreColegio, direccion, Tipo, barrio, telefonos, tipos, barrios, departamentos, departamento, ciudad } = this.props
    this.setState({ nombreColegio, direccion, Tipo, barrio, telefonos, cargando: false, tipos, barrios, departamentos, departamento, ciudad })
    
  }

  async componentDidUpdate({modalSolPromocion }, { departamento }) {
    let { modalSolPromocion : modalSolPromocionNew} = this.props;
    let { departamento: departamentoNew } = this.state;
      if(modalSolPromocionNew !== modalSolPromocion){
        this.obtenerGenericas()
        this.cargarTipos()
      }
      if (departamentoNew.value !== departamento.value) {
        let ciudades = await obtenerPermisosValor(departamentoNew.value);
        ciudades = ciudades.map(({ secundario }) => ({
          value: secundario.id,
          label: secundario.nombre,
        }));
        this.changestate('ciudades', ciudades);
        this.changestate('ciudad', '');
      }
    // }
  }

  changestate = (name, value) => {
    this.setState({
      [name]: value
    })
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value,
    });
  };


  submitPromocion = async () => {
    let {
      nombreColegio,
      ciudad,
      direccion,
      nombreRector,
      fax,
      nombreContacto,
      telefonoContacto,
      barrio,
      Tipo,
      correo,
      telefonos,
      paginaWeb,
      cargoContacto,
      departamento,
    } = this.state;
    let titulo = '';
    let { actualizarMensaje } = this.props
    this.setState({ cargando: true, titulo_carg: 'Enviando...' })
    let data = {
      nombreColegio,
      ciudad: ciudad.value,
      direccion,
      nombreRector,
      fax,
      nombreContacto,
      telefonoContacto,
      barrio,
      Tipo,
      correo,
      telefonos,
      paginaWeb,
      cargoContacto,
      departamento: departamento.value,
    };
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!nombreColegio) titulo = 'Ingrese el nombre del colegio.'
    else if (!ciudad) titulo = 'Ingrese la ciudad.'
    else if (!direccion) titulo = 'Ingrese la direccion.'
    else if (!Tipo) titulo = 'Escoja el tipo.'
    else if (!barrio) titulo = 'Ingrese el barrio.'
    else if (!telefonos) titulo = 'Ingrese el telefono.'
    // else if (!Number(telefonos)) titulo = 'Ingrese un número telefonico válido.'
    // else if(telefonoContacto && (!Number(telefonoContacto))) titulo = 'Ingrese un número telefonico válido.'
    else if (correo && (!re.test(correo))) titulo = 'Ingrese una dirección de correo válida.'

    if (titulo) {
      actualizarMensaje({ titulo, tipo: 'info', mostrar: true, tiempo: 6000 })
    } else {
      this.props.guardarPromocion(data, () => this.reiniciarEstado())
      this.setState({ cargando: true, titulo_carg: 'Enviando...' })
    }
  }

  reiniciarEstado = () => {
    this.setState({
      cargando: false,
      activeStep: 0,
      nombreColegio: "",
      direccion: "",
      nombreRector: "",
      fax: "",
      nombreContacto: "",
      Tipo: "",
      telefonoContacto: "",
      barrio: "",
      correo: "",
      telefonos: "",
      paginaWeb: "",
      cargoContacto: "",
      ciudad: '',
      departamento: ''
    });
  };

  configStepContent = () => {
    let {
      activeStep,
      nombreColegio,
      ciudad,
      direccion,
      nombreRector,
      fax,
      nombreContacto,
      telefonoContacto,
      Tipo,
      barrio,
      correo,
      telefonos,
      paginaWeb,
      cargoContacto,
      tipos,
      barrios,
      departamentos,
      departamento,
      ciudades,
    } = this.state;
    switch (activeStep) {
      case 0:
        return (
          <Datos
            {...{
              nombreColegio,
              ciudad,
              direccion,
              nombreRector,
              fax,
              nombreContacto,
              telefonoContacto,
              Tipo,
              barrio,
              correo,
              telefonos,
              paginaWeb,
              cargoContacto,
              onChange: this.onChange,
              tipos,
              barrios,
              departamentos,
              departamento,
              ciudades,
              setDepartamento: (value) => {
                this.changestate('departamento', value)
              },
              setCiudad: (value) => {
                this.changestate('ciudad', value)
              },
            }}
          />
        );

      default:
        return (
          <TareasTerminadas
            imagen={emma_w}
            mensaje="Est bandeja esta  vacía"
            widthImg="10%"
            marginTop="10%"
          />
        );
    }
  };

  async obtenerGenericas() {
    let tipo_C = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': Id_Tipo_colegio }]);
    let barrio_C = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': Id_Barrio_colegio }]);
    this.setState({ tipos: tipo_C, barrios: barrio_C });
  }

  cargarTipos = async () => {
    const transformar = data => {
      return data.map(e => ({
        value: e.id,
        label: e.nombre,
        codigo: e.codigo,
      }))
    }
    const departamentos = await obtenerValores(Id_Departamento_colegio);
    this.setState({
      departamentos: transformar(departamentos)
    })
  }

  render() {
    let { modalSolPromocion, mostrarModalCrearPromocion } = this.props;
    return (
      <div>
        {
          <Dialog
            fullWidth={true}
            maxWidth="md"
            open={modalSolPromocion}
            onClose={() => mostrarModalCrearPromocion(false)}
          >
            <AppBarModal
              titulo={"¡ Crear Colegio !"}
              mostrarModal={() => mostrarModalCrearPromocion(false)}
              titulo_accion="Cerrar"
              accion={() => mostrarModalCrearPromocion(false)}
            />
            <DialogContent className="scroll">
              <Grid container>
                {
                  <Grid style={{ padding: "2% 2% 0% 2%" }} item xs={12} md={12}>
                    {this.configStepContent()}
                  </Grid>
                }
              </Grid>
            </DialogContent>
            <DialogActions>
              <div>
                <BtnFormPage
                  texto="CERRAR"
                  callback={() => mostrarModalCrearPromocion(false)}
                />
              </div>
              <BtnFormPage
                texto="GUARDAR"
                callback={this.submitPromocion}
                type="submit"
              />
            </DialogActions>
          </Dialog>
        }
      </div>
    );
  }
}

PromocionCrear.prototypes = {
  modalSolPromocion: PropTypes.bool.isRequired,
  actualizarMensaje: PropTypes.func.isRequired,
  mostrarModalCrearPromocion: PropTypes.func.isRequired,
  guardarPromocion: PropTypes.func.isRequired,
};

export default PromocionCrear;
