import React, { Component } from 'react';
import AppBarModal from "../../general/AppBarModal";
import { BtnForm } from "../../general/BotonesAccion";
import TareasTerminadas from '../../general/TareasTerminadas';
import { DialogContent, DialogActions, Dialog } from '@material-ui/core';
import emma_w from '../../../global/imagenes/emma_w.png';
import { consulta, rutaImg } from "../../../global/js/funciones";
import ListarDatos from "../../general/ListarDatos";
import moment from 'moment'

class AsistenciaEstudiantes extends Component {

  constructor(props) {
    super(props)
    this.state = {
      cargando: false,
      estudiantes: [],
    }
  }

  componentDidUpdate({ modalListaAsistencia }) {
    let { modalListaAsistencia: modalListaAsistenciaNew, id_asistencia } = this.props;
    if (modalListaAsistencia !== modalListaAsistenciaNew && modalListaAsistenciaNew) {
      this.obtenerEstudiantesAsistencia(id_asistencia);
    }
  }

  obtenerEstudiantesAsistencia = (id_asistencia) => {
    consulta(`api/v1.0/asistencia/${id_asistencia}/estudiantes`, null, null,
      (error, estado, resp) => {
        if (estado === 200) {
          this.setState({ cargando: false, estudiantes: resp })
        }
      }
    )
  }


  render() {
    let { modalListaAsistencia, mostrarModalListaAsistencia } = this.props;
    let { estudiantes, cargando } = this.state;
    return (
      <Dialog open={modalListaAsistencia} maxWidth='sm' fullWidth={true} onClose={mostrarModalListaAsistencia.bind(this, false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <AppBarModal titulo='¡ Lista Estudiantes !' mostrarModal={mostrarModalListaAsistencia} titulo_accion='' />
        <DialogContent style={{ padding: 0 }} className='scroll' >
          {
            cargando ? <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" /> :
              <ListarDatos
                datos={estudiantes}
                titulo='Listado'
                id='tbl_estudiantes_lista'
                opciones={true}
                buscar={true}
                avatarImg={true}
                avatar={({ identificacion }) => `${rutaImg}${identificacion}.jpg`}
                fila_principal={({ nombre }) => nombre}
                filas={[
                  { 'id': 'fecha_registro', 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a'), 'nombre': 'Fecha Registro : ' },
                  { 'id': 'identificacion' },
                ]}
              />
          }
        </DialogContent>
        <DialogActions>
          <BtnForm texto="Cerrar" callback={() => mostrarModalListaAsistencia(false)} />
        </DialogActions>
      </Dialog>

    );
  }
}



export default AsistenciaEstudiantes;
