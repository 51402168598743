import React, { Component } from 'react'
import PropTypes from 'prop-types';

// FUNCIONE GENERALES - GLOBALES
import { Dialog, DialogActions, DialogContent, Paper, List, Grid} from '@material-ui/core'
import AppBarModal from '../../general/AppBarModal';
import { BtnForm, BtnCargar } from '../../general/BotonesAccion';
import EnviarMultiplesArchivos from "../../general/EnviarMultiplesArchivos";
import TareasTerminadas from "../../general/TareasTerminadas";
import { obtenerPermisosValorAlt,obtenerValoresFiltros, generarFiltros, consulta } from "../../../global/js/funciones";
import AlertasSimple from '../../general/AlertasSimple';

// IMAGENES
import emma_w from '../../../global/imagenes/emma_w.png';
import { Gif } from '@material-ui/icons';



export default class ModalConfirmarHomologacion extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cargando : true,
            titulo_res: 'Cargando..',
            documentos: [],
            adjunto : [],
        }
    }
    
    async componentDidUpdate({ modalConfirmar }, {}) {
        let { modalConfirmar: modalConfirmarNew, matricula }  = this.props;
        if (modalConfirmar !== modalConfirmarNew){
            if (modalConfirmarNew) {
                this.obtenerDatos();
                this.setState({ cargando: false });
            }
        }
    }

    async obtenerDatos (){
        let acuerdo_matriculas = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Mat_Rein_Tran_Est' }]);
        this.setState({ cargando: false, acuerdo_matriculas : acuerdo_matriculas.length > 0 ? acuerdo_matriculas[0].valorg : '' });
        
      }
      
      obtenerObservaciones = async () => {
          this.setState({ cargando: true });
        let {acuerdo_matriculas} = this.state
        let obs = await obtenerPermisosValorAlt([{ 'llave': 'codigo', 'valor': 'Mat_Pro'}])
        let acu_matriculas = [{item : acuerdo_matriculas}]
        obs.map(({ secundario }) => acu_matriculas.push({item : secundario.nombre}));
        this.setState({ acu_matriculas });
    }

    submit = (dato) => {
        let { matricula, guardarConfirmacion} = this.props;
        guardarConfirmacion(matricula.id,dato,() => this.setState({ cargando: false, titulo_res: 'Cargando..' }));
    }

    
    render() {
        let { modalConfirmar, mostrarConfirmacion } = this.props
        let { cargando, titulo_res, acuerdo_matriculas } = this.state
        let mensaje_matriculas = [{item: acuerdo_matriculas}]
        
            
            return (
            <div>
                <Dialog open={modalConfirmar} onClose={() => mostrarConfirmacion(false)} fullWidth={true} maxWidth="sm">
                    <AppBarModal titulo="¡ Confirmar Estudio Homologación !" mostrarModal={() => mostrarConfirmacion(false)} titulo_accion="" />
                    <DialogContent  className="scroll">
                      {
                          !cargando ?
                          <Paper elevation={0} square>
                            <Grid container>
                                <Grid item xs={12}>
                                    {/* {this.configStepContent()} */}
                                    <AlertasSimple
                                    tipo='info'
                                    titulo={'Estimado Estudiante, Es crucial que tomes en cuenta lo siguiente: '}
                                    lista={mensaje_matriculas}
                                    margin = '0px'
                                    />
                                </Grid>
                            </Grid>
                        </Paper> :
                        <TareasTerminadas mensaje={titulo_res} marginTop='7%' imagen={emma_w} widthImg="7%" />
                      }
                    </DialogContent>
                    {
                        !cargando &&
                        <>
                            <DialogActions>
                            <BtnForm texto="No" callback={() => this.submit(0)} />
                            <BtnForm texto="Si" callback={() => this.submit(1)} />
                            <BtnForm texto="CERRAR" callback={() => mostrarConfirmacion(false)} />
                            </DialogActions>
                        </>
                    }
                </Dialog>
            </div>
        )
    }

}