import React, { Component, forwardRef } from 'react'
import { Dialog, DialogContent, DialogActions, Grid, InputLabel, Select, MenuItem, FormControl,Checkbox,ListItemText, IconButton, Tooltip } from '@material-ui/core';
import AppBarModal from '../../general/AppBarModal';
import { BtnForm } from '../../general/BotonesAccion';
import { consulta, obtenerValoresFiltros, transformar, generarFiltros } from '../../../global/js/funciones'
import TareasTerminadas from "./../../general/TareasTerminadas";
import emma_w from './../../../global/imagenes/emma_w.png';
import emma_s from './../../../global/imagenes/emma_s1.png';
import BuscarSelect from '../../general/BuscarSelect';
import ListarDatos from "../../general/ListarDatos";
import {ESTADOS_CONSULTORIA, TIPOS_CONSULTORIA} from '../helper';
import ListarTabla from '../../general/ListarTabla';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import moment from 'moment';

class ConsultoriaInforme extends Component {
   constructor(props) {
      super(props)
      this.state = {
         columnas: [],
         data_informe: [],
         cargando: false,
         periodo: '',
         tipo_consultoria: '',
         periodos: [],
         tipo_consultorias: [],
         estados_consultoria:'',
         areas:'',
         estados_consultorias:[],
         mostrar : 'filtros',
         maxWidth : 'sm',
         fechaActual:'',
         fecha:'',
         cargar: true,
         mes: ''
      };
   }

   async componentDidUpdate({ modalInformeConsultoria }, { mostrar }) {
      let { mostrar: mostrarNew, cargar } = this.state;
      let { modalInformeConsultoria: modalInformeConsultoriaNew, informe } = this.props;
      if (modalInformeConsultoria !== modalInformeConsultoriaNew) {
         if (modalInformeConsultoriaNew && cargar) {
            this.setState({ cargando: true })
            const periodos = await this.obtenerPeriodos();
            const tipo_consultorias = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_CONSULTORIA}, { 'llave': 'estado', 'valor': 1 }]);
            const estados_consultorias = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': ESTADOS_CONSULTORIA }, { 'llave': 'estado', 'valor': 1 }]);
            this.setState({ periodos, cargando: false, cargar: false,'tipo_consultorias': transformar(tipo_consultorias),'estados_consultorias': transformar(estados_consultorias)})
         }
         else this.resetState()
      }
   }

   obtenerPeriodos = () => {
      return new Promise(resolve => {
          consulta(`api/v1.0/consultoria/periodos`, null, null,
              (error, estado, resp) => {
                  resolve(resp)
              }
          );
      })
    }

   onChange = ({ target }) => {
      let { value, name } = target
      this.setState({ [name]: value })
   }

   cambiarEstado = (estados) => {
      this.setState(estados)
    }

   resetState = () => {
      let { mostrar } = this.state

      if(mostrar==='filtros') this.setState({ data_informe: [], columnas: [], mostrar: 'filtros', maxWidth: 'sm', periodo: '', tipo_consultoria: '', practicas: [], mes: '', cargando: false })
      else if(mostrar === 'informe') this.setState({ mostrar:'filtros', maxWidth: 'sm', columnas: [], cargando: false })
   }

   onSubmit = (e) => {
      let { periodo} = this.state
      let { actualizarMensaje, informe } = this.props
      let filtros = [], titulo='';
     
      if(informe.codigo =="Inf_Tip" || informe.codigo =="Inf_Est" || informe.codigo =="inf_in_cons"){
         if(periodo !== ''){
            filtros.push({ 'llave': 'informe', 'valor': informe.codigo }); 
            filtros.push({ 'llave': 'periodo', 'valor': periodo });
         }else titulo = 'Por favor, seleccione el periodo.'
      }else{
         filtros.push({ 'llave': 'informe', 'valor': informe.codigo });
      }

      if(titulo) actualizarMensaje({ titulo, tipo: 'info', mostrar: true, tiempo: 6000 });
      else this.obtenerInforme(filtros)
      e.preventDefault();
   }
   
   obtenerInforme = async (filtros = []) => {
      let { actualizarMensaje } = this.props
      let f = await generarFiltros(filtros);
      this.setState({ cargando: true });
      consulta(`api/v1.0/consultoria/informe?${f}`, null, null,
        (error, estado, resp) => {
          if (estado === 200) this.setState({ cargando: false, data_informe: resp, mostrar: 'informe', maxWidth: 'lg' });
          else actualizarMensaje({ titulo: 'Ha ocurrido un error, contacte con el administrador.', tipo: 'info', mostrar: true, tiempo: 6000 });
        }
      );
   }

   pintarNombres = (selected, listado) => {
      let resp = [];

      let a = listado.filter(({id}) => selected.indexOf(id)!=-1)
      a.map(({nombre}) =>resp.push(nombre))

      return resp.join(", ")
   }

   pintarNombresPeriodo = (selected, listado) => {
      let resp = [];

      let a = listado.filter((e) => selected.indexOf(e)!=-1)
      a.map((e)=>resp.push(e))

      return resp.join(", ")
   }

   pintar = () => {
      let { cargando, mostrar } = this.state;
      if (cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" cargando={true}/>;
      return (
         <div>
            {mostrar === 'filtros' && this.pintarFiltros()}
            {mostrar === 'informe' && this.pintarInforme()}
         </div>
      )
   }

   pintarFiltros = () => {
      let { informe,descargarInforme,informed} = this.props
      let { periodo, periodos} = this.state
      if(informe.codigo =="Inf_Tip" || informe.codigo =="Inf_Est" || informe.codigo =="inf_in_cons"){
         return(
            <Grid container spacing={3} className='margin_cont'>
               <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                  <FormControl className='form-control'>
                     <InputLabel htmlFor="periodo">Seleccione Periodo</InputLabel>
                     <Select
                        inputProps={{
                           name: 'periodo',
                           id: 'periodo',
                        }}
                        value={periodo}
                        onChange={this.onChange}
                        required={true}>
                        {periodos.map(({ periodo }, i) => <MenuItem key={i} value={periodo}>{periodo}</MenuItem>)}
                     </Select>
                  </FormControl>
               </Grid>
            </Grid> 
         )
      }
   }

   pintarInforme = () => {
      let { data_informe, periodo, columnas } = this.state
      let data_informe2 = []; 
      data_informe.map(informe =>{
         data_informe2.push({
            periodos:informe.periodos,
            estados_consultoria:informe.estados_consultoria,
            nombre_estudiante:informe.nombre_estudiante,
            identificacion_estudiante:informe.identificacion_estudiante,
            radicado:informe.radicado,
            radicado_asociado:informe.radicado_asociado,
            area_derecho:informe.area_derecho,
            fechaRegistro:moment(informe.fechaRegistro).format('YYYY-MM-DD'),
            usuario_asignado:informe.usuario_asignado

         });
      });
      let { informe } = this.props
      let ordenar = false;
      let Total = true;
      if(data_informe.length>0){
         if(columnas.length==0){
            let codigo = informe.codigo

            let campos={}

            if (codigo === 'Inf_Est'){
               columnas = [
                  { title: 'Estados Consultoria', field: 'estados_consultoria' },
               ]
               columnas.push(
                  { title: 'Pendientes por Finalizar', field: 'pendiente_fin' },
                  { title: 'Finalizados', field: 'finalizados' },  
                  { title: 'Enero', field: 'Enero' },
                  { title: 'Febrero', field: 'Febrero' },
                  { title: 'Marzo', field: 'Marzo' },
                  { title: 'Abril', field: 'Abril' },
                  { title: 'Mayo', field: 'Mayo' },
                  { title: 'Junio', field: 'Junio' },
                  { title: 'Julio', field: 'Julio' },
                  { title: 'Agosto', field: 'Agosto' },
                  { title: 'Septiembre', field: 'Septiembre' },
                  { title: 'Octubre', field: 'Octubre' },
                  { title: 'Noviembre', field: 'Noviembre' },
                  { title: 'Diciembre', field: 'Diciembre'  },
               )
      
            }else if(codigo === 'Inf_Tip'){
               columnas = [
                  { title: 'Areas', field: 'areas' },
               ]
               columnas.push(
                  { title: 'Enero', field: 'Enero' },
                  { title: 'Febrero', field: 'Febrero' },
                  { title: 'Marzo', field: 'Marzo' },
                  { title: 'Abril', field: 'Abril' },
                  { title: 'Mayo', field: 'Mayo' },
                  { title: 'Junio', field: 'Junio' },
                  { title: 'Julio', field: 'Julio' },
                  { title: 'Agosto', field: 'Agosto' },
                  { title: 'Septiembre', field: 'Septiembre' },
                  { title: 'Octubre', field: 'Octubre' },
                  { title: 'Noviembre', field: 'Noviembre' },
                  { title: 'Diciembre', field: 'Diciembre'  },
               )
      
            }else if(codigo === 'inf_in_cons'){
               columnas = [
                  { title: 'Periodo', field: 'periodos' },
               ]
               columnas.push(
                  { title: 'Estado', field: 'estados_consultoria' },
                  { title: 'Nombre del solicitante', field: 'nombre_estudiante' },
                  { title: 'Identificacion del solicitante', field: 'identificacion_estudiante' },
                  { title: 'Radicado', field: 'radicado' },
                  { title: 'Radicado asociado', field: 'radicado_asociado' },
                  { title: 'Area de derecho', field: 'area_derecho' },
                  { title: 'Fecha de registro', field: 'fechaRegistro' },
                  { title: 'Usuario asignado', field: 'usuario_asignado' },
               )
      
            }
         }
         const titulo_informe = (periodo) ? `${informe.nombre} - ${periodo}` : informe.nombre
         
         return (
            informe.codigo != 'inf_in_cons' ? <ListarTabla titulo={titulo_informe} columnas={columnas} datos={data_informe} orden={ordenar} total={Total} />:<ListarTabla titulo={titulo_informe} columnas={columnas} datos={data_informe2} orden={ordenar} />
         )
      }else{
         return(
            <TareasTerminadas mensaje='No se encontraron resultados' marginTop='7%' imagen={emma_s} widthImg="7%" />
         )
      }
   }

   render() {
      let { cargando, maxWidth, mostrar } = this.state;
      let { modalInformeConsultoria, mostrarModalInformeConsultoria, informe } = this.props
      return (
         <Dialog fullWidth={true} maxWidth={maxWidth} PaperProps={ (informe.codigo === 'Inf_Est' || informe.codigo === 'Inf_Tip' || informe.codigo === 'inf_in_cons') ? {style: {overflowY: 'visible'}} : {} } open={modalInformeConsultoria} onClose={() => mostrarModalInformeConsultoria(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <AppBarModal titulo={informe.nombre} mostrarModal={() => mostrarModalInformeConsultoria(false)} titulo_accion={  (mostrar === 'filtros') ? "Limpiar" : 'Volver'} accion={ this.resetState } />
            <DialogContent className='scroll' style={mostrar === 'informe' ? { padding: 0 } : ((informe.codigo === 'Inf_Est' || informe.codigo === 'Inf_Tip' || informe.codigo === 'inf_in_cons') ? { overflowY: 'visible' }:{})}>
               {!cargando ? this.pintar() : <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" cargando={true}/>}
            </DialogContent>
            <DialogActions>
               <BtnForm texto="Cerrar" callback={() => mostrarModalInformeConsultoria(false)} />
               {!cargando && mostrar === 'filtros' &&<BtnForm texto="GENERAR" callback={this.onSubmit} />}
            </DialogActions>
         </Dialog>
      );
   }
}

export default ConsultoriaInforme