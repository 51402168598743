import React, { Component } from 'react'
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import { ValidatorForm } from "react-material-ui-form-validator";

import AppBarModal from '../../general/AppBarModal'

import { MenuProps, consulta } from "../../../global/js/funciones";
import { BtnForm } from "../../general/BotonesAccion";

class GenericaAgregar extends Component {

    constructor(props) {
        super(props)
        this.state = {
        relaciones: [],
        tipoRelaciones: [],
        }
    }

    componentDidMount() {
        this.cargarTipos();
    }

    onSubmit = e => {
        let { relaciones } = this.state;
        let { mostrarModalAdd, obtenerPlanAcompanamiento, inscripcion } = this.props;
        let data = {planes: relaciones, id_estudiante: inscripcion.estudiante.id}
        this.guardarPlan(data);
        mostrarModalAdd(false);
        obtenerPlanAcompanamiento(inscripcion.estudiante.id);
        e.preventDefault();
    }

    guardarPlan = (data) => {
        let { actualizarMensaje } = this.props;
        let tipo = "";
        let titulo = "";
        return new Promise((resolve) => {
            consulta(`api/v1.0/inscripcion/guardarPlanAcompanamiento`, data, "post", (error, estado, resp) => {
                resolve(resp);
                if (!error && estado === 200) {
                  tipo = "success";
                  titulo = resp.titulo;
                } else {
                  tipo = "info";
                  titulo = resp.titulo || "Ha ocurrido un error";
                }
                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
                
            });
        });
    }

    onChange = ({ target }) => {
        let { value, name } = target;
        this.setState({
        [name]: value
        })
    }


    setRol = ({ target }) => {
        this.setState({
        relaciones: target.value
        })
    }

    obtenerPlanAcompanamiento = () => {
        return new Promise((resolve) => {
          consulta(`api/v1.0/inscripcion/planAcompanamiento`, null, null, (error, estado, resp) => {
            resolve(resp);
          });
        });
    }

    cargarTipos = async () => {
        let tipoRelaciones
        tipoRelaciones = await this.obtenerPlanAcompanamiento();
        this.setState({ tipoRelaciones})
    }

    pintarTiposVacantes = () => {
        let { relaciones, tipoRelaciones } = this.state;
        if (Array.isArray(tipoRelaciones)) {
        return tipoRelaciones.map(({ nombre, id }) => (
            <MenuItem key={id} value={id}>
                <Checkbox checked={relaciones.indexOf(id) > -1} />
                <ListItemText primary={nombre} />
            </MenuItem>
        ))
        }
        return [];
    }

    pintarNombresPlan = (selected) => {
        let resp = [];
        let { tipoRelaciones } = this.state;
        selected.map((element) => {
        tipoRelaciones.map((e) => {
            if (element === e.id) return resp.push(e.nombre)
            return null;
        })
        return null;
        });
        return resp.join(", ")
    }

    render() {
        let { relaciones } = this.state;
        let { modalAdd, mostrarModalAdd } = this.props;

        return (
        <Dialog fullWidth={true} maxWidth='xs' open={modalAdd} onClose={mostrarModalAdd.bind(this, false)} aria-labelledby="form-dialog-title">
            <AppBarModal titulo='Agregar Plan' mostrarModal={mostrarModalAdd} titulo_accion="" accion="" />
            <DialogContent className='scroll'>
            <ValidatorForm onSubmit={this.onSubmit}>
                <FormControl variant="outlined" className='form-control' >
                <InputLabel htmlFor="select-multiple-checkbox">Seleccionar Plan</InputLabel>
                <Select
                    multiple
                    value={relaciones}
                    input={<OutlinedInput labelWidth={70} name="select-multiple-checkbox" id="select-multiple-checkbox" />}
                    onChange={this.setRol}
                    renderValue={selected => {
                    return this.pintarNombresPlan(selected)
                    }}
                    MenuProps={MenuProps}
                >
                    {this.pintarTiposVacantes()}
                </Select>
                </FormControl>
                <Button color="primary" type="submit" id='enviar_form_add_plan' className="oculto"> Guardar </Button>
            </ValidatorForm>
            </DialogContent>
            <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => mostrarModalAdd(false)} />
            <BtnForm texto="GUARDAR" callback={() => { document.getElementById('enviar_form_add_plan').click() }} />

            </DialogActions>
        </Dialog>
        )
    }
}

//Props del componente
GenericaAgregar.propTypes = {
  //variables
  modalAdd: PropTypes.bool.isRequired,
  //funciones
  mostrarModalAdd: PropTypes.func.isRequired,
}


export default GenericaAgregar;

