import React from 'react';
import { Dialog, DialogActions, DialogContent, Paper, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import AppBarModal from '../../general/AppBarModal';
import { BtnDetalle, BtnForm, BtnPermiso } from '../../general/BotonesAccion';
import AlertasSimple from "../../general/AlertasSimple";

export function ConvocatoriaDetalle({ convocatoria, archivos, titulo, modalDetConvocatorias, mostrarModalDetConvocatorias, mostrarModalListaUniversidades, mostrarModalListaRequisitos, mostrarModalListaArchivos }) {
  const configDetailConvocatoria = () => {
    let { nombre_convocatoria, descripcion, fecha_inicio, fecha_final, tipo_mov_estudiantil, modalidad, semestre_minimo, promedio_requerido } = convocatoria;
    let universidades = <BtnDetalle texto = 'Universidades' callback = { () => mostrarModalListaUniversidades(true) } />
    let requisitos = <BtnPermiso texto = 'Requisitos' callback = { () => mostrarModalListaRequisitos(true) } />
    return (
      [
        { 'label': 'Universidades y Requisitos', 'value': <>{ universidades } | { requisitos }</> },
        { 'label': 'Nombre convocatoria', 'value': nombre_convocatoria },
        { 'label': 'Descripción', 'value': descripcion ? descripcion : 'No cuenta con descripción.' },
        { 'label': 'Fecha de inicio', 'value': fecha_inicio },
        { 'label': 'Fecha de finalización', 'value': fecha_final },
        { 'label': 'Tipo de movilidad', 'value': tipo_mov_estudiantil.nombre },
        { 'label': 'Modalidad', 'value': modalidad.nombre },
        { 'label': 'Semestre mínimo', 'value': semestre_minimo.nombre },
        { 'label': 'Promedio requerido', 'value': promedio_requerido },
      ]
    )
  }
  return (
    <>
      <Dialog open = { modalDetConvocatorias } onClose = { mostrarModalDetConvocatorias.bind(this, false) } fullWidth = { true } maxWidth = "sm" aria-labelledby = "alert-dialog-title" aria-describedby = "alert-dialog-description">
        <AppBarModal titulo = { titulo } mostrarModal = { mostrarModalDetConvocatorias } titulo_accion = "" accion = { () => {  } } />
        <DialogContent style = { { padding: 0 } } className='scroll'>
          <Paper square elevation = { 0 }>
            <Table>
              <TableBody>
              {
                archivos.length !== 0 &&
                  <TableRow key = 'archivo'>
                    <TableCell colSpan = { 2 }>
                      <AlertasSimple 
                        tipo = 'info' 
                        titulo = ''
                        sub_titulo = 'Clic aquí si desea ver información detallada de esta convocatoria.' 
                        callback = { archivos.length > 1 ? () => mostrarModalListaArchivos(true) : () => window.open(`${archivos[0].adjunto}`, '_blank') }
                      />
                    </TableCell>
                  </TableRow>
              }
              {
                (convocatoria && convocatoria.id !== 0) &&
                  configDetailConvocatoria().map(campo => {
                    return (
                      <TableRow key = { campo.label }>
                        <TableCell component = "th" scope = "row">{ campo.label }</TableCell>
                        <TableCell align = "left" style = { { whiteSpace: 'pre-wrap', wordWrap: 'break-word' } }>{ campo.value }</TableCell>
                      </TableRow>
                    )
                  })
              }
              </TableBody>
            </Table>
          </Paper>
        </DialogContent>
        <DialogActions>
          <BtnForm texto = "CERRAR" callback = { () => mostrarModalDetConvocatorias(false) } />
        </DialogActions>
      </Dialog>
    </>
  )
}
