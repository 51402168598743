import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import AppBarModal from "../../general/AppBarModal";
import { BtnForm } from "../../general/BotonesAccion";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  consulta,
  crear_form_data,
  formulario,
  mostrarError,
  obtenerValoresFiltros,
} from "../../../global/js/funciones";
import moment from "moment/moment";
// import {
//   actualizarMensaje,
//   mostrarModulo,
// } from "../../../redux/actions/actGlobal";
import { ESTRATEGIA } from "../helper";
import { connect } from "react-redux";

class CrearActividades extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actividad: {},
      estrategia: {},
      tipo: {},
      asignatura: {},
      unidad: {},
      grupo: {},
      indicador: {},
      fecha: null,
      error_fecha: "",
      tipos_estrategias: [],
      unidades: [],
      indicadores: [],
      grupos: [],
    };
  }

  async componentDidMount() {
    this.DatosIniciales();
  }

  async DatosIniciales() {
    let estrategias = await obtenerValoresFiltros([
      { llave: "generica", valor: ESTRATEGIA },
      { llave: "estado", valor: "1" },
      { llave: "valorb", valor: "1" },
    ]);
    this.setState({ cargando: false, tipos_estrategias: estrategias });
  }

  async BuscarUnidadesXAsignatura(asignatura, periodo) {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/sisef/unidades_formacion_all/${asignatura}/${periodo}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }
  async BuscarIndicadores(id) {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/sisef/listar_indicadores/${id}/ind_dsp`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  async obtenerGrupos(codigo, periodo) {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/sisef/grupos/${codigo}/${periodo}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  async CargarGrupos(id) {
    let { periodo_actual, actualizarMensaje } = this.props;
    let extra = await obtenerValoresFiltros([
      { llave: "codigo", valor: "sisef_todos" },  { llave: 'estado', valor: '1' }
    ]);
    let todos = {
      codigo: extra[0].codigo,
      nombre: extra[0].nombre,
    };
    let data = await this.obtenerGrupos(id, periodo_actual);
    data = data.grupos;
    if (data.length != 0) {
      if (data.length > 1) {
        data.push(todos);
      }
      this.setState({ grupos: data, grupo: {} });
    } else {
      let titulo =
        "Contactar administrador, ya que esta asignatura no cuenta con grupos asignados";
      let tipo = "info";
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      this.setState({ grupos: [], grupo: {} });
    }
  }

  async cargarUnidades(id) {
    let { periodo_actual, actualizarMensaje } = this.props;
    let unidades = await this.BuscarUnidadesXAsignatura(id, periodo_actual);
    if (unidades.length != 0) {
      console.log(unidades);
      this.setState({ unidades: unidades, indicador: {}, indicadores: [] });
    } else {
      let titulo = "Esta Asignatura no cuenta con Unidades";
      let tipo = "info";
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      this.setState({
        unidades: [],
        unidad: {},
        indicador: {},
        indicadores: [],
      });
    }
  }

  async cargarIndicadores(id) {
    let { actualizarMensaje } = this.props;
    let ind = await this.BuscarIndicadores(id);
    if (ind.length != 0) {
      this.setState({ indicadores: ind, indicador: {} });
    } else {
      let titulo = "Esta Unidad no cuenta con indicadores";
      let tipo = "info";
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      this.setState({ indicadores: [], indicador: {} });
    }
  }

  onSubmit = (e) => {
    let {
      actividad,
      fecha,
      error_fecha,
      estrategia,
      tipo,
      asignatura,
      indicador,
      unidad,
      grupo,
    } = this.state;
    let { asignaturas } = this.props;
    let obligatorio = "Este campo es obligatorio";
    let error = false;
    if (Object.entries(actividad).length == 0 || actividad.valor == "") {
      actividad.error = obligatorio;
      error = true;
    }
    if (Object.entries(estrategia).length == 0 || estrategia.valor == "") {
      estrategia.error = obligatorio;
      error = true;
    }
    if (Object.entries(tipo).length == 0 || tipo.valor == "") {
      tipo.error = obligatorio;
      error = true;
    }
    if (Object.entries(asignatura).length == 0 || asignatura.valor == "") {
      asignatura.error = obligatorio;
      error = true;
    } else {
      asignatura.nombre = asignaturas.filter(
        (asig) => asig.codigo_asignatura == asignatura.valor
      )[0].nombre_asignatura;
    }
    if (Object.entries(grupo).length == 0 || grupo.valor == "") {
      grupo.error = obligatorio;
      error = true;
    }
    if (Object.entries(unidad).length == 0 || unidad.valor == "") {
      unidad.error = obligatorio;
      error = true;
    }
    if (Object.entries(indicador).length == 0 || indicador.valor == "") {
      indicador.error = obligatorio;
      error = true;
    }
    if (fecha == null) {
      error_fecha = obligatorio;
      error = true;
    }
    if (
      error ||
      actividad.error == "" ||
      estrategia.error == "" ||
      tipo.error == "" ||
      unidad.error == "" ||
      indicador.error == "" ||
      grupo.error == "" ||
      asignatura.error == ""
    ) {
      this.setState({
        actividad,
        error_fecha,
        estrategia,
        tipo,
        unidad,
        indicador,
        asignatura,
        grupo,
      });
    } else this.enviar();
  };

  enviar() {
    let { actividad, fecha, tipo, estrategia, indicador, asignatura, grupo } =
      this.state;
    let { periodo_actual } = this.props;
    this.setState({ cargando: true, titulo_res: "Enviando.." });
    let data = {
      actividad: actividad.valor,
      fecha: moment(fecha).format("YYYY-MM-DD"),
      indicador: indicador.valor,
      grupo: grupo.valor,
      periodo: periodo_actual,
      tipo_estrategia: tipo.valor,
      estrategia: estrategia.valor,
      asignatura_codigo: asignatura.valor,
      asignatura_nombre: asignatura.nombre,
      estrategia: estrategia.valor,
    };
    this.Guardar(data);
  }

  Guardar = async (data) => {
    let { SetMCrearActividad, actualizarMensaje, seleccion } = this.props;
    let form = await crear_form_data(data);
    formulario(
      `api/v1.0/sisef/crear_actividad`,
      form,
      "post",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            SetMCrearActividad(false);
            seleccion("miproceso");
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  };

  getError(valor) {
    return valor ? true : false;
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    if (name == "asignatura") {
      this.CargarGrupos(value);
      this.cargarUnidades(value);
    }
    if (name == "unidad") {
      this.cargarIndicadores(value);
    }
    this.setState({
      [name]: { valor: value },
    });
  };

  render() {
    let {
      actividad,
      fecha,
      error_fecha,
      estrategia,
      tipo,
      tipos_estrategias,
      asignatura,
      unidades,
      unidad,
      indicadores,
      indicador,
      grupo,
      grupos,
    } = this.state;
    let { MCrearActividad, SetMCrearActividad, asignaturas } = this.props;
    return (
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={MCrearActividad}
        onClose={() => SetMCrearActividad(false)}
        aria-labelledby="form-dialog-title"
      >
        <AppBarModal
          titulo="Nueva Actividad"
          mostrarModal={() => SetMCrearActividad(false)}
          titulo_accion="Nueva Actividad"
          accion=""
        />
        <DialogContent className="scroll">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl
                className="form-control"
                error={this.getError(asignatura.error)}
              >
                <InputLabel htmlFor="parametro">Asignatura</InputLabel>
                <Select
                  id="asignatura"
                  onChange={this.onChange}
                  name="asignatura"
                >
                  {asignaturas?.map(
                    ({ nombre_asignatura, codigo_asignatura }) => (
                      <MenuItem
                        key={codigo_asignatura}
                        value={codigo_asignatura}
                      >
                        {`${nombre_asignatura.toUpperCase()}`}
                      </MenuItem>
                    )
                  )}
                </Select>
                {asignatura.error && (
                  <FormHelperText>{asignatura.error}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl
                className="form-control"
                error={this.getError(grupo.error)}
              >
                <InputLabel htmlFor="parametro">Grupo</InputLabel>
                <Select id="grupo" onChange={this.onChange} name="grupo">
                  {grupos?.map(({ nombre, codigo }) => (
                    <MenuItem key={codigo} value={codigo}>
                      {`${nombre.toUpperCase()}`}
                    </MenuItem>
                  ))}
                </Select>
                {grupo.error && <FormHelperText>{grupo.error}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl
                className="form-control"
                error={this.getError(unidad.error)}
              >
                <InputLabel htmlFor="parametro">Unidad</InputLabel>
                <Select id="unidad" onChange={this.onChange} name="unidad">
                  {unidades?.map(({ nombre, id }) => (
                    <MenuItem key={id} value={id}>
                      {`${nombre}`}
                    </MenuItem>
                  ))}
                </Select>
                {unidad.error && (
                  <FormHelperText>{unidad.error}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl
                className="form-control"
                error={this.getError(indicador.error)}
              >
                <InputLabel htmlFor="parametro">Indicador</InputLabel>
                <Select
                  id="indicador"
                  onChange={this.onChange}
                  name="indicador"
                >
                  {indicadores?.map(({ info, id }) => (
                    <MenuItem key={id} value={id}>
                      {`${info}`}
                    </MenuItem>
                  ))}
                </Select>
                {indicador.error && (
                  <FormHelperText>{indicador.error}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl className="form-control">
                <TextField
                  id="actividad"
                  type="text"
                  name="actividad"
                  label="actividad "
                  valor={actividad.valor}
                  defaultValue=""
                  onChange={this.onChange}
                  error={this.getError(actividad.error)}
                  helperText={actividad.error}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container>
                  <KeyboardDatePicker
                    required={true}
                    disableToolbar
                    format="dd-MM-yyyy"
                    margin="normal"
                    id="fecha"
                    label="Fecha"
                    value={fecha}
                    style={{ width: "100%" }}
                    onChange={(fecha) =>
                      this.setState({ fecha, error_fecha: "" })
                    }
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    invalidDateMessage="Formato Invalido"
                  />
                </Grid>
              </MuiPickersUtilsProvider>
              {error_fecha && (
                <FormHelperText error>{error_fecha}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl
                className="form-control"
                error={this.getError(tipo.error)}
              >
                <InputLabel htmlFor="parametro">Tipo de estrategias</InputLabel>
                <Select id="tipo" onChange={this.onChange} name="tipo">
                  {tipos_estrategias?.map(({ nombre, id }) => (
                    <MenuItem key={id} value={id}>
                      {`${nombre}`}
                    </MenuItem>
                  ))}
                </Select>
                {tipo.error && <FormHelperText>{tipo.error}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl className="form-control">
                <TextField
                  id="estrategia"
                  type="text"
                  name="estrategia"
                  label="Descripción de la actividad  "
                  valor={estrategia.valor}
                  defaultValue=""
                  onChange={this.onChange}
                  error={this.getError(estrategia.error)}
                  helperText={estrategia.error}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <BtnForm
            texto="CANCELAR"
            callback={() => SetMCrearActividad(false)}
          />
          <BtnForm texto="GUARDAR" callback={this.onSubmit} />
        </DialogActions>
      </Dialog>
    );
  }
}

// const mapStateToProps = (state) => {
//   let { usuario } = state.redGlobal;
//   return { usuario };
// };

// const mapDispatchToProps = {
//   mostrarModulo,
//   actualizarMensaje,
// };

// CrearActividades.propTypes = {
//   //Funciones
//   mostrarModulo: PropTypes.func.isRequired,
//   actualizarMensaje: PropTypes.func.isRequired,
// };

export default CrearActividades;
