
import React, { Component } from 'react';
import { connect } from "react-redux";

//FUNCIONES GLOBALES
import TareasTerminadas from "../general/TareasTerminadas";
import { actualizarMensaje, mostrarModulo } from "../../redux/actions/actGlobal";

import { consulta, generarFiltros, mostrarError, obtenerValoresFiltros, transformar } from "../../global/js/funciones";
import ListarDatos from "../general/ListarDatos";
import { BtnDetalle, BtnFormPage, BtnRegresar } from "../general/BotonesAccion";
import AccionesRapidas from '../general/AccionesRapidas';
import TituloAccion from '../general/TituloAccion';
import { TextField, Paper, Grid } from '@material-ui/core';
import ListarTarjetas from '../general/ListarTarjetas';
import moment from 'moment'

// ACTIVATE - REDUCER 
import { mostrarModalFiltroReaDescarga, mostrarModalDetalleRea, mostrarModalReportRea } from "../../redux/actions/actRea";

// FUNCIONES - COMPONENTES DEL MODULO
import { MenuAdministrar } from "./rea/ReaMenu";
import ReaFiltroDescarga from './rea/reaFiltroDescarga';
import DetalleRea from './rea/detalleRea'
import ReaPermisos from "./rea/reaPermisos";
import ReaInforme from "./rea/ReaInforme";

// IMAGENES
import emma_w from '../../global/imagenes/emma_w.png';
import emma_s1 from '../../global/imagenes/emma_s1.png';
import permisosIcon from '../../global/imagenes/lightbulb.png';
import reportIcon from '../../global/imagenes/report.png';

// HELPERS
import { DEPARTAMENTOS_FACULTADES, PROGRAMAS, INFORMESREA } from './rea/helper';




class ReaAdministrar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      menu: true,
      accionProceso: 'inicio',
      accionAdm: 'inicio',
      seleccion: 'proceso',
      cargando: false,
      resultadosEncuesta: [],
      administrador: false,
      procesos: [],
      proceso: null,
      programas: [],
      filtros: [],
      facultades: [],
      correo: "",
      identificacion: "",
      dato_buscar: '',
      informes: [],
      data_informe: [],
      informe: {'nombre' : '', 'codigo' : ''},
    }
  }

  async componentDidMount() {
    let { mostrarModulo } = this.props;
    mostrarModulo({ ruta: '/gestion_rea', nombre: 'REA AdministraciÓn' })
    let respuestaProgramas = await obtenerValoresFiltros([{ "llave": 'generica', 'valor': PROGRAMAS }]);
    let programas = this.transformar(respuestaProgramas)
    this.setState({ programas })
    this.esAdministrador();
    let departamentosFacultades = await obtenerValoresFiltros([{ "llave": 'generica', 'valor': DEPARTAMENTOS_FACULTADES }]);
    let facultades = transformar(departamentosFacultades)
    this.setState({ facultades })
    
  }

  componentDidUpdate({ }, { seleccion, dato_buscar, accionAdm,  }) {
    let { seleccion: seleccionNew, dato_buscar: dato_buscarNew, accionAdm: accionAdmNew, accionProceso, administrador } = this.state;
    if (dato_buscar !== dato_buscarNew) {
      this.obtenerEncuestas(this.state.dato_buscar)
    }
    if (accionAdm !== accionAdmNew) {
      this.setState({ cargando: true })
      if (accionAdmNew === 'informes') this.obtenerTipoInformes()
      if (accionAdmNew === 'inicio') this.setState({ cargando: false });
    }

  }



  esAdministrador() {
    let { usuario } = this.props;

    if (usuario && usuario.perfil && (usuario.perfil.codigo === "Per_Adm" || usuario.perfil.codigo === "Per_Adm_Rea")) {
      this.setState({ administrador: true })
    }
  }


  async obtenerEncuestas(dato) {
    let tipo = 1
    if (dato.length > 4) {
      consulta("api/v1.0/rea/list/1?", { dato, tipo }, "post",
        (error, estado, resp) => {
          if (estado === 200) {
            this.setState({ cargando: false, procesos: resp })
          }
        }
      );
    } else {
      this.setState({ cargando: false });
    }
  }


  transformar = (data, id = 'id', nombre = 'nombre', codigo = 'codigo') => {
    return data.map((e) => ({
      value: e[id],
      label: e[nombre],
      codigo: e[codigo]
    }));
  };


  onChange = ({ target }) => {// evento que escucha los cambios del formulario y asigna el valor al estado de la variable
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }



  pintarHabilitar() {
    let { correo, identificacion } = this.state
    return (
      <Grid container direction={'row'} justifyContent={'center'} style={{ paddingTop: 70 }} >
        <Paper elevation={5} style={{ padding: "20px" }}>
          <Grid container spacing={3} style={{ marginBottom: '40px' }}>
            <Grid item xs={12}>
              <TextField
                value={identificacion}
                required
                type="number"
                id="identificacion"
                name="identificacion"
                label="Documento Identidad"
                fullWidth
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={correo}
                required
                type="text"
                id="correo"
                name="correo"
                label="Correo Electronico"
                fullWidth
                onChange={this.onChange}
              />
            </Grid>
          </Grid>
          <BtnFormPage texto="HABILITAR" callback={this.onSubmit} />
        </Paper>
      </Grid>

    )
  }

  onSubmit = (e) => {
    let { identificacion, correo } = this.state;
    let { actualizarMensaje } = this.props
    if (identificacion == "" || correo == "") {
      actualizarMensaje({ titulo: 'Los campos deben ser Diligenciados', tipo: 'info', mostrar: true, tiempo: 4000 });
    }
    else {
      this.setState({ identificacion, correo })
      let data = {
        correo: correo,
        identificacion: identificacion
      }
      this.guardarHabilitar(data)
    }
  }
  guardarHabilitar = async (data) => {
    let { actualizarMensaje } = this.props;
    return new Promise(resolve => {
      consulta(`api/v1.0/rea/habilitar`, data, "post", (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            this.setState({ seleccion: 'proceso' });

          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
        resolve(estado === 200 && !error ? resp : null);

      })
    })
  }

  pintarAllInformation() {
    let { procesos, cargando, dato_buscar } = this.state;
    let { mostrarModalFiltroReaDescarga, mostrarModalDetalleRea } = this.props;
    const obtenerColor = (codigo) => {
      let color = 'white';
      const colores = {
        'Est_Rea_Env': { 'backgroundColor': '#009688', color },
      }
      return (colores[codigo])
    }
    const acciones = (data) => {
      const onClickDetalle = proceso => {
        this.setState({ proceso })

        mostrarModalDetalleRea(true)
      }

      let detalle = <BtnDetalle callback={() => onClickDetalle(data)} color='#01579b' texto='Abrir' />;

      return <div>{detalle}</div>;
    }
    return (
      <>
        <ListarDatos
          titulo='Lista Aplicacion Encuesta'
          id='tbl_rea_listar'
          descargar="EMMA - REA"
          datos={procesos}
          opciones={true}
          // descargar=''
          filtrar={false}
          refrescar={false}
          opcionAdicional={true}
          actDescargar={() => mostrarModalFiltroReaDescarga(true)}
          buscar={true}
          actfiltrar={false}
          actRefrescar={false}
          acciones={(data) => acciones(data)}
          cargando={cargando}
          avatarImg={true}
          css={{
            list: { padding: '0px 0px 20px 0px' },
            appBar: { padding: '0px 0px 0px 0px' },
          }}
          actBuscar={(dato_buscar) => this.setState({ dato_buscar })}
          datoBuscarAlt={dato_buscar}
          avatar={({ estado_actual }) => estado_actual.nombre.toUpperCase().charAt(0)}
          color_avatar={({ estado_actual }) => obtenerColor(estado_actual.codigo)}
          fila_principal={({ usuario_registro: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`}
          filas={[
            { 'id': 'identificacion', 'mostrar': ({ usuario_registro: { identificacion } }) => identificacion },
            { 'id': 'programa', 'mostrar': ({ programa }) => `${programa.nombre}` },
            { 'id': 'fecha_registro', 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a') },

          ]}

        />
      </>
    )
  }


  pintarInicioAdm = () => {
    let { periodo_mod, fecha_periodo } = this.state;
    return (
      <div style={{ padding: '2% 2% 0% 2%' }}>
        <Grid container direction={'row'} spacing={1} justifyContent={'center'}>
          <Grid item xs={12} sm={12} md={10} lg={8}>
            <TituloAccion principal='' alterno='¿ Qué deseas hacer ?' />
            <Paper elevation={0} square className='scroll contenido_fijo'>
              <Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
                <Grid item xs={12} sm={12} md={6} lg={6} >
                  <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.setState({ accionAdm: 'informes' })}>
                    <AccionesRapidas imagen={reportIcon} titulo={`¡ Informes !`} sub_titulo='' des_sub_titulo={`Genera reportes generales REA.`} />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} >
                  <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.setState({ accionAdm: 'permisos' })}>
                    <AccionesRapidas imagen={permisosIcon} titulo='¡ Controlar Permisos !' sub_titulo='' des_sub_titulo='Verifica los permisos a los gestores REA.' />
                  </Paper>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }

  pintarPermisos = () =>{
    return (<ReaPermisos setCargandoFalse={() => this.setState({cargando: false})}/>)
  }

  obtenerTipoInformes = async () => {
    let { usuario } = this.props
    let informes = []
    this.setState({cargando : true})
    if(usuario.perfil.codigo == 'Per_Adm' || usuario.perfil.codigo == 'rea_adm_act') {
      informes = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': INFORMESREA }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valora', 'valor': 'admin'}]);
    }else{
      informes = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': INFORMESREA }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valora', 'valor': 'todos'}]);
    }
    this.setState({informes, cargando : false})
  }

  pintarInformes() {
    let { informes, informe }  = this.state
    let { modalRepInfoRea, mostrarModalReportRea, actualizarMensaje }  = this.props
    const acciones = (data) => {      
      this.setState({ informe: {'nombre': data.nombre, 'codigo': data.codigo} }) 
      mostrarModalReportRea(true)
    }

    return (
      <>
        <ListarTarjetas
          datos={informes}
          titulo={'Informes REA'}
          acciones={(data) => acciones(data)}
          avatar={({ nombre }) => nombre.toUpperCase().charAt(0)}
          color_avatar={({valorc}) => ({'backgroundColor': ((valorc) ? valorc : '#EEEEEE'), 'color': ((valorc) ? 'white' : '#757575') })}
          orden={'valorb'}
        />
        <ReaInforme
          informe={informe}
          modalRepInfoRea={modalRepInfoRea}
          mostrarModalReportRea={mostrarModalReportRea}
          actualizarMensaje={actualizarMensaje}
        />
      </>
    )
  }

  pintarAdministrar() {
    let { accionAdm, administrador} = this.state;
    if (!administrador) return <TareasTerminadas imagen={emma_s1} mensaje='No tiene los permisos asignados' widthImg='7%' marginTop='7%' />
    return (
      <>
        {accionAdm === 'inicio' && this.pintarInicioAdm()}
        {accionAdm !== 'inicio' && <div className={'regresar'}><BtnRegresar callback={() => this.setState({ accionAdm: 'inicio' })} onClick /></div>}
        {accionAdm === 'administrar' && this.pintarInicioAdm()}
        {accionAdm === 'informes' && this.pintarInformes()}
        {accionAdm === 'permisos' && this.pintarPermisos()}
      </>
    )

  }

  render() {
    let { seleccion, cargando, proceso, administrador, programas, facultades } = this.state;
    let { actualizarMensaje, modalFiltroDescarga, modalDetalleRea, mostrarModalDetalleRea, mostrarModalFiltroRea, mostrarModalFiltroReaDescarga } = this.props;
    if (!cargando) {
      return (
        <>
          <MenuAdministrar
            seleccionar={(seleccion) => this.setState({ seleccion })}
            sele={seleccion}
            administrador={administrador}
          />

          <ReaFiltroDescarga
            actualizarMensaje={actualizarMensaje}
            modalFiltroDescarga={modalFiltroDescarga}
            mostrarModalFiltroReaDescarga={mostrarModalFiltroReaDescarga}
            programas={programas}
            facultades={facultades}

          />
          <DetalleRea
            proceso={proceso ? proceso : 0}
            actualizarMensaje={actualizarMensaje}
            modalDetalleRea={modalDetalleRea}
            mostrarModalDetalleRea={mostrarModalDetalleRea}
            cargar="true"

          />

          <div className='contenido_modulo'>
            {
              !cargando
                ?
                <div>
                  {seleccion === 'proceso' && this.pintarAllInformation()}
                  {seleccion === 'habilitar' && this.pintarHabilitar()}
                  {seleccion === 'administrar' && this.pintarAdministrar()}
                </div>
                : <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
            }
          </div>
        </>
      )
    }
    return (
      <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
    )
  }
}

//CARGO ESTADOS DEL MODULO
const mapStateToProps = (state) => {
  let { modalFiltroDescarga, modalDetalleRea, modalRepInfoRea } = state.redRea
  let { usuario } = state.redGlobal;
  return {
    usuario,
    modalFiltroDescarga,
    modalDetalleRea,
    modalRepInfoRea,
  }
};

//CARGO FUNCIONES DEL MODULO
const mapDispatchToProps = {
  actualizarMensaje,
  mostrarModulo,
  mostrarModalFiltroReaDescarga,
  mostrarModalDetalleRea,
  mostrarModalReportRea,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReaAdministrar);