import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/core/styles";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Button, Dialog, DialogActions, DialogContent, InputLabel, MenuItem, FormControl, Select, ListItemText, FormControlLabel, Checkbox, Grid, FormHelperText, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import { getError, validateInputs, consulta, coloresEmma } from '../../global/js/funciones'
import AppBarModal from '../general/AppBarModal'
import { BtnForm } from "../general/BotonesAccion";
import BuscarValorGenerica from "./../general/BuscarValorGenerica2";
import TareasTerminadas from '../general/TareasTerminadas';
import emma_w from '../../global/imagenes/emma_w.png';


const estilos = theme => ({
  colorEmmma: {
    color: coloresEmma.secondarycolor,
    transition: 'all 0.3s',
    '&:hover': {
      color: "#fff",
      backgroundColor: coloresEmma.secondarycolor,
    },
  },
  colorDelete: {
    color: "#d11a2a",
    transition: "all 0.3s",
    '&:hover': {
      color: "#fff",
      backgroundColor: "#d11a2a",
    },
  }
});

class PersonasAgregar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      tipo: '',
      tipo_identificacion: '',
      identificacion: '',
      primer_nombre: '',
      segundo_nombre: '',
      primer_apellido: '',
      segundo_apellido: '',
      correo: '',
      usuario: '',
      showtipo: false,
      login_directorio: 1,
      showtipoIdentificacion: false,
      tipos_persona: [],
      roles: [],
      errores: [],
      modal_seleccionar_vg: false,
      cargando: false,
      titulo_carg: 'Cargando..',
      perfiles: [],
      programas: [],
      tipos_persona: [
        {
          id: '1',
          nombre: 'PERSONA INTERNA'
        },
        {
          id: '2',
          nombre: 'PERSONA EXTERNA'
        }
      ],
      resp_identidades: [],
      genericaVg: 0,
      array_seleccionados: [],
      callbackAdd: () => { },
      callbackDel: () => { }
    }
  }


  componentDidUpdate({ modalAddPersona }) {
    let { modalAddPersona: modalAddNew } = this.props;
    if (modalAddNew !== modalAddPersona) {
      this.changeState();
    }
  }


  changeState = () => {
    this.setState({
      tipo: '',
      tipo_identificacion: '',
      identificacion: '',
      primer_nombre: '',
      segundo_nombre: '',
      primer_apellido: '',
      segundo_apellido: '',
      correo: '',
      usuario: '',
      showtipo: false,
      login_directorio: 1,
      showtipoIdentificacion: false,
      roles: [],
      errores: [],
      modal_seleccionar_vg: false,
      cargando: false,
      titulo_carg: 'Cargando..',
      perfiles: [],
      programas: [],
      genericaVg: 0,
      array_seleccionados: [],
      callbackAdd: () => { },
      callbackDel: () => { },
      nombreModal: ''
    })
  }

  onSubmit = async e => {
    let { tipo, tipo_identificacion, identificacion, primer_nombre, segundo_nombre, primer_apellido, segundo_apellido, correo, usuario, roles, login_directorio, perfiles, programas } = this.state;
    let errores = await validateInputs([
      { value: tipo_identificacion, name: 'tipo_identificacion', err: { empty: true } },
      { value: roles, name: 'roles', err: { empty: true } },
      { value: tipo, name: 'tipo', err: { empty: true } },
      { value: perfiles, name: 'perfiles', err: { empty: true } },
    ]);
    this.setState({ errores });
    if (errores.length === 0) {
      roles = roles.map(({ id }) => id)
      perfiles = perfiles.map(({ id }) => id)
      programas = programas.map(({ id }) => id)
      let data = { tipo, tipo_identificacion, identificacion, primer_nombre: primer_nombre.toUpperCase(), segundo_nombre: segundo_nombre.toUpperCase(), primer_apellido: primer_apellido.toUpperCase(), segundo_apellido: segundo_apellido.toUpperCase(), correo, usuario, roles, login_directorio, perfiles, programas }
      this.setState({ cargando: true, titulo_carg: 'Guardando..' })
      this.props.guardarPersona(data, () => this.setState({ cargando: false, titulo_carg: 'Cargando..' }));
    }
    e.preventDefault();
  }

  limpiarErrores = name => {
    let { errores } = this.state;
    if (errores.length > 0) this.setState({ errores: errores.filter(er => er.llave !== name) }); // Elimina error cuando se escribe en el campo
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.limpiarErrores(name);
    this.setState({
      [name]: value
    })
  }

  setSelecT = (name, show) => {
    this.setState({
      [name]: show
    })
  }

  setValorGenerica = (valor, array, tipo) => {
    this.limpiarErrores(tipo);
    array.push(valor)
    if(tipo == 'perfiles') this.setState({ perfiles: array })
    else if(tipo == 'roles') this.setState({ roles: array })
    else if(tipo == 'programas') this.setState({ programas: array })
  }

  unsetValorGenerica = (valorEliminar, array, tipo) => {
    let encontrado = array.findIndex(({ id }) => id == valorEliminar.id)
    if (encontrado > -1) {
      array.splice(encontrado, 1);
      if (tipo == 'roles') this.setState({ roles: array })
      else if (tipo == 'perfiles') this.setState({ perfiles: array })
      else if (tipo == 'programas') this.setState({ programas: array })
    }
  }

  pintarMenuItem = (lista) => {
    return Array.isArray(lista) ? lista.map(({ id, nombre }) => <MenuItem key={id} value={id}>{nombre}</MenuItem>) : [];
  }

  abrirBuscarValor = (nombreModal, genericaVg, array_seleccionados, callbackAdd, callbackDel) => {
    this.setState({ nombreModal, modal_seleccionar_vg: true, genericaVg, array_seleccionados, callbackAdd, callbackDel })
  }

  async buscarPersona() {
    let {identificacion} = this.state;
    return new Promise(resolve => {
      consulta(`api/v1.0/personas/buscar_persona`, { identificacion }, "post", (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
        if(estado===200){
          this.setState({ resp_identidades: resp, cargando: false})
        }
      })
    })
  }

  buscarIdentidades = async () => {
    this.setState({cargando:true, resp_identidades: []})
    let resultado = await this.buscarPersona()
    if(resultado){
      let {resp_identidades} = this.state
      let {primer_apellido, segundo_apellido, correo, usuario, nombres} = resp_identidades;
      if(resp_identidades){
        let nombres_div = (nombres) ? nombres.split(' ') : ''; 
        let segundo_nombre = (nombres_div.length === 2) ? nombres_div[1] : ''
        this.setState({primer_apellido, segundo_apellido, correo, usuario, primer_nombre: nombres_div[0], segundo_nombre })
      }
    }
  }

  render() {
    let {
      tipo,
      tipo_identificacion,
      identificacion,
      primer_nombre,
      segundo_nombre,
      primer_apellido,
      segundo_apellido,
      correo,
      usuario,
      roles,
      login_directorio,
      errores,
      modal_seleccionar_vg,
      cargando,
      titulo_carg,
      tipos_persona,
      perfiles,
      programas,
      genericaVg,
      array_seleccionados,
      callbackAdd,
      callbackDel,
      nombreModal
    } = this.state;

    let { modalAddPersona, mostrarModalAddPersona, tipos_identificacion, classes } = this.props;
    const ROLES = 1;
    const PERFILES = 3;
    const PROGRAMAS = 5;

    return (
      <>
        <Dialog open={modalAddPersona} onClose={mostrarModalAddPersona.bind(this, false)} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="sm">
          <AppBarModal titulo="¡ Nueva Persona !" mostrarModal={mostrarModalAddPersona} titulo_accion="" accion="" />
          <DialogContent className='scroll'>
            {!cargando ?

              <ValidatorForm onSubmit={this.onSubmit} name="ENCUESTA_PV" id="ENCUESTA_PV">
                <Grid container spacing={1} style={{ marginTop: 15 }}>
                  <Grid item xs={12} md={6} style={{ marginTop: 5 }}>
                    <FormControl className='form-control' error={getError('tipo_identificacion', errores).length > 0}>
                      <InputLabel htmlFor="select-tipo-identificacion" id='tipo-identificacion_label'>Tipo de Identificación</InputLabel>
                      <Select
                        labelId='tipo-identificacion_label'
                        id='select-tipo-identificacion'
                        name='tipo_identificacion'
                        value={tipo_identificacion}
                        label='Tipo de Identificación'
                        fullWidth
                        onChange={this.onChange}
                      >
                        {this.pintarMenuItem(tipos_identificacion)}
                      </Select>
                      <FormHelperText>{getError('tipo_identificacion', errores)}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} style={{ marginTop: 5 }}>
                    <FormControl className='form-control' error={getError('tipo', errores).length > 0}>
                      <InputLabel htmlFor="select-tipo-identificacion" id='tipo_persona_label'>Tipo de Persona</InputLabel>
                      <Select
                        labelId='tipo_persona_label'
                        id='select-tipo-persona'
                        name='tipo'
                        value={tipo}
                        label='Tipo de Persona'
                        fullWidth
                        onChange={this.onChange}
                      >
                        {this.pintarMenuItem(tipos_persona)}
                      </Select>
                      <FormHelperText>{getError('tipo', errores)}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={10} style={{ marginTop: 10 }}>
                    <TextValidator
                      variant="standard"
                      margin="dense"
                      id="identificacion"
                      label="Identificación"
                      type="number"
                      fullWidth
                      name="identificacion"
                      value={identificacion}
                      validators={["required", 'minNumber:0']}
                      errorMessages={["El campo es requerido", "El campo no puede ser menor a 0"]}
                      onChange={this.onChange}
                    />
                  </Grid>
                  <Grid item xs={2} style={{ marginTop: 25 }}>
                    <IconButton className={classes.colorEmmma} onClick={() => this.buscarIdentidades()}>
                      <SearchIcon/>
                    </IconButton>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      variant="standard"
                      margin="dense"
                      id="primer_nombre"
                      label="Primer Nombre"
                      type="text"
                      fullWidth
                      name="primer_nombre"
                      value={primer_nombre}
                      validators={["required"]}
                      errorMessages={["El campo es requerido"]}
                      onChange={this.onChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      variant="standard"
                      margin="dense"
                      id="segundo_nombre"
                      label="Segundo Nombre"
                      type="text"
                      fullWidth
                      name="segundo_nombre"
                      value={segundo_nombre}
                      onChange={this.onChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      variant="standard"
                      margin="dense"
                      id="primer_apellido"
                      label="Primer Apellido"
                      type="text"
                      fullWidth
                      name="primer_apellido"
                      value={primer_apellido}
                      validators={["required"]}
                      errorMessages={["El campo es requerido"]}
                      onChange={this.onChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      variant="standard"
                      margin="dense"
                      id="segundo_apellido"
                      label="Segundo Apellido"
                      type="text"
                      fullWidth
                      name="segundo_apellido"
                      value={segundo_apellido}
                      onChange={this.onChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      variant="standard"
                      margin="dense"
                      id="correo"
                      label="Correo"
                      type="text"
                      fullWidth
                      name="correo"
                      value={correo}
                      validators={['required', 'isEmail']}
                      errorMessages={["El campo es requerido", "El correo no es valido"]}
                      onChange={this.onChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      variant="standard"
                      margin="dense"
                      id="usuario"
                      label="Usuario"
                      type="text"
                      fullWidth
                      name="usuario"
                      validators={['required']}
                      errorMessages={["El campo es requerido"]}
                      value={usuario}
                      onChange={this.onChange}
                    />
                  </Grid>
                  <Grid item xs={10} style={{ marginTop: 10 }}>
                    <FormControl className='form-control' error={getError('roles', errores).length > 0}>
                      <Select
                        id='roles'
                        value={''}
                        fullWidth
                        displayEmpty
                      >
                        <MenuItem value="">
                          <em>{`${roles.length} rol(es) seleccionado(s)`}</em>
                        </MenuItem>
                        {roles.sort((a, b) => a.nombre.localeCompare(b.nombre)).map((r) => <MenuItem key={r.id} value={r.id}>{r.nombre.toUpperCase()}</MenuItem>)}
                      </Select>
                    </FormControl>
                    <FormHelperText>{getError('roles', errores)}</FormHelperText>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton aria-label="add" className={classes.colorEmmma} onClick={() => this.abrirBuscarValor('Rol', ROLES, roles, (rolSeleccionado) => this.setValorGenerica(rolSeleccionado, roles, 'roles'), (rolSeleccionado) => this.unsetValorGenerica(rolSeleccionado, roles, 'roles'))}>
                      <AddIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={10} style={{ marginTop: 10 }}>
                    <FormControl className='form-control' error={getError('perfiles', errores).length > 0}>
                      <Select
                        id='perfiles'
                        value={''}
                        fullWidth
                        displayEmpty
                      >
                        <MenuItem value="">
                          <em>{`${perfiles.length} perfil(es) seleccionado(s)`}</em>
                        </MenuItem>
                        {perfiles.sort((a, b) => a.nombre.localeCompare(b.nombre)).map((r) => <MenuItem key={r.id} value={r.id}>{r.nombre.toUpperCase()}</MenuItem>)}
                      </Select>
                    </FormControl>
                    <FormHelperText>{getError('perfiles', errores)}</FormHelperText>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton aria-label="add" className={classes.colorEmmma} onClick={() => this.abrirBuscarValor('Perfil', PERFILES, perfiles, (perfilSeleccionado) => this.setValorGenerica(perfilSeleccionado, perfiles, 'perfiles'), (perfilSeleccionado) => this.unsetValorGenerica(perfilSeleccionado, perfiles, 'perfiles'))}>
                      <AddIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={10} style={{ marginTop: 10 }}>
                    <FormControl className='form-control'>
                      <Select
                        id='programas'
                        value={''}
                        fullWidth
                        displayEmpty
                      >
                        <MenuItem value="">
                          <em>{`${programas.length} programa(s) seleccionado(s)`}</em>
                        </MenuItem>
                        {programas.sort((a, b) => a.nombre.localeCompare(b.nombre)).map((r) => <MenuItem key={r.id} value={r.id}>{r.nombre.toUpperCase()}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton aria-label="add" className={classes.colorEmmma} onClick={() => this.abrirBuscarValor('Programa', PROGRAMAS, programas, (programaSeleccionado) => this.setValorGenerica(programaSeleccionado, programas, 'programas'), (programaSeleccionado) => this.unsetValorGenerica(programaSeleccionado, programas, 'programas'))}>
                      <AddIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={login_directorio ? true : false}
                          onChange={this.setSelecT.bind(this, "login_directorio", login_directorio ? 0 : 1)}
                          value="login_directorio"
                          color="primary"
                        />
                      }
                      label="Autenticación con directorio activo"
                    />
                  </Grid>
                </Grid>
                <Button color="primary" type="submit" id='enviar_form_add_persona' className="oculto"> Guardar </Button>
              </ValidatorForm>
              :
              <TareasTerminadas mensaje={titulo_carg} marginTop='7%' imagen={emma_w} widthImg="7%" />
            }
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => mostrarModalAddPersona(false)} />
            <BtnForm texto="GUARDAR" callback={() => { document.getElementById('enviar_form_add_persona').click() }} />
          </DialogActions>
        </Dialog>

        <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={modal_seleccionar_vg} onClose={() => this.setState({ modal_seleccionar_vg: false })}>
          <AppBarModal titulo={`¡ Seleccionar ${nombreModal} !`} mostrarModal={() => this.setState({ modal_seleccionar_vg: false })} titulo_accion="" accion="" />
          <DialogContent style={{ padding: '0' }} className='scroll'>
            <BuscarValorGenerica
              generica={genericaVg}
              listaSeleccionados={array_seleccionados}
              callbackAdd={callbackAdd}
              callbackDel={callbackDel}
            />
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CERRAR" callback={() => this.setState({ modal_seleccionar_vg: false })} />
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

//Props del componente
PersonasAgregar.propTypes = {
  //variables
  modalAddPersona: PropTypes.bool.isRequired,
  //funciones
  mostrarModalAddPersona: PropTypes.func.isRequired,
}


export default withStyles(estilos)(PersonasAgregar)

