import {
    MODAL_ADD_PERFIL_PORTAL,
    MODAL_ACCIONES_RAPIDAS,
    MODAL_LISTAR_INFORMACION_PERFIL,
    MODAL_CREAR_OFERTA,
    MODAL_DETALLE_VACANTE,
    MODAL_GESTION_OFERTA,
    MODAL_LISTAR_POSTULANTES,
    MODAL_GESTION_PROCESO_EMPRESA,
    MODAL_ESTADO_PROCESO,
    MODAL_ADD_FILTROS,
    MODAL_DETALLE_POSTULANTE,
    MODAL_DETALLE_ESTADOS,
    MODAL_DETALLE_ESTADOS_GESTION,
    MODAl_PQRS_AGREGAR,
    MODAl_PQRS_EDITAR,
    MODAl_PQRS_DETALLE,
    MODAl_LISTA_ESTADISTICA,
    MODAL_DESCARGAR_HOJA_DE_VIDA,
    MODAL_GESTIONAR_ESTUDIANTE,
    MODAL_MOSTRAR_CORREO,
    MODAL_GESTIONAR_EMPRESA,
    MODAL_MOSTRAR_DATOS_EMPRESA
} from '../actions/actPortal'

const initialState = {
    modalListaEstadistica: false,
    modalAddPerfilPortal: false,
    modalAccionesRapidas: false,
    modalListarInformacionPerfil: false,
    modalCrearOferta: false,
    modalDetalleVacante: false,
    modalGestionOferta: false,
    modalListarPostulantes: false,
    modalGestionProcesoEmpresa: false,
    modalEstadoProceso: false,
    modalAddFiltros: false,
    modalDetallePostulante: false,
    modalDescargarHojaDeVida:false,
    modalDetalleEstados: false,
    modalAddPqrs: false,
    modalEditPqrs: false,
    modalDetallePqrs: false,
    modalGestionarEstudiante:false,
    modalMostrarCorreo:false,
    modalGestionarEmpresa:false,
    modalMostrarDatosEmpresa:false,
    modalDetalleEstadosGestion:false
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case MODAl_LISTA_ESTADISTICA:
            return Object.assign({}, state, {
                modalListaEstadistica: action.show
            })
        case MODAl_PQRS_DETALLE:
            return Object.assign({}, state, {
                modalDetallePqrs: action.show
            })
        case MODAl_PQRS_EDITAR:
            return Object.assign({}, state, {
                modalEditPqrs: action.show
            })
        case MODAl_PQRS_AGREGAR:
            return Object.assign({}, state, {
                modalAddPqrs: action.show
            })
        case MODAL_ADD_PERFIL_PORTAL:
            return Object.assign({}, state, {
                modalAddPerfilPortal: action.show
            })
        case MODAL_ACCIONES_RAPIDAS:
            return Object.assign({}, state, {
                modalAccionesRapidas: action.show
            })
        case MODAL_LISTAR_INFORMACION_PERFIL:
            return Object.assign({}, state, {
                modalListarInformacionPerfil: action.show
            })
        case MODAL_CREAR_OFERTA:
            return Object.assign({}, state, {
                modalCrearOferta: action.show
            })
        case MODAL_DETALLE_VACANTE:
            return Object.assign({}, state, {
                modalDetalleVacante: action.show
            })
        case MODAL_GESTION_OFERTA:
            return Object.assign({}, state, {
                modalGestionOferta: action.show
            })
        case MODAL_LISTAR_POSTULANTES:
            return Object.assign({}, state, {
                modalListarPostulantes: action.show
            })
        case MODAL_GESTION_PROCESO_EMPRESA:
            return Object.assign({}, state, {
                modalGestionProcesoEmpresa: action.show
            })
        case MODAL_ESTADO_PROCESO:
            return Object.assign({}, state, {
                modalEstadoProceso: action.show
            })
        case MODAL_ADD_FILTROS:
            return Object.assign({}, state, {
                modalAddFiltros: action.show
            })
        case MODAL_DETALLE_POSTULANTE:
            return Object.assign({}, state, {
                modalDetallePostulante: action.show
            })
        case MODAL_DESCARGAR_HOJA_DE_VIDA:
            return Object.assign({}, state, {
                modalDescargarHojaDeVida: action.show
            })
        case MODAL_DETALLE_ESTADOS:
            return Object.assign({}, state, {
                modalDetalleEstados: action.show
            })
        case MODAL_GESTIONAR_ESTUDIANTE:
            return Object.assign({}, state, {
                modalGestionarEstudiante: action.show
            })
        case MODAL_MOSTRAR_CORREO:
            return Object.assign({}, state, {
                modalMostrarCorreo: action.show
            })
        case MODAL_GESTIONAR_EMPRESA:
            return Object.assign({}, state, {
                modalGestionarEmpresa: action.show
            })
        case MODAL_MOSTRAR_DATOS_EMPRESA:
            return Object.assign({}, state, {
                modalMostrarDatosEmpresa: action.show
            })
        case MODAL_DETALLE_ESTADOS_GESTION:
            return Object.assign({}, state, {
                modalDetalleEstadosGestion: action.show
            })
        default:
            return state
    }
}

export default reducer;