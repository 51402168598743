const MODAL_ADD_CREDITO = 'MODAL_ADD_CREDITO'
const ACT_DATA_CREDITO = 'ACT_DATA_CREDITO'
const MODAL_DET_CREDITO = 'MODAL_DET_CREDITO'
const MODAL_GES_CREDITO = 'MODAL_GES_CREDITO'
const MODAL_ADD_FILTRO_CREDITO = 'MODAL_ADD_FILTRO_CREDITO'
const MODAL_ADD_ADJUNTO_CREDITO = 'MODAL_ADD_ADJUNTO_CREDITO'
const MODAL_FORMU_CREDITO = 'MODAL_FORMU_CREDITO'
const MODAL_ENCUESTA_CREDITO = 'MODAL_ENCUESTA_CREDITO'
const mostrarModalAddFiltrosCredito = show => {
  return {
    type: MODAL_ADD_FILTRO_CREDITO,
    show,
  }
}

const mostrarModalAdjuntoCredito = show => {
  return {
    type: MODAL_ADD_ADJUNTO_CREDITO,
    show,
  }
}

const mostrarModalGestionarCredito = show => {
  return {
    type: MODAL_GES_CREDITO,
    show,
  }
}

const mostrarModalEncuestaCredito = show => {
  return {
    type: MODAL_ENCUESTA_CREDITO,
    show,
  }
}

const mostrarModalDetalleCredito = show => {
  return {
    type: MODAL_DET_CREDITO,
    show,
  }
}

const mostrarModalAddCredito = show => {
  return {
    type: MODAL_ADD_CREDITO,
    show,
  }
}

const actualizarDataCredito = credito => {
  return {
    type: ACT_DATA_CREDITO,
    credito,
  }
}

const mostrarModalFormuDirecto = show => {
  return {
    type: MODAL_FORMU_CREDITO,
    show,
  }
}

module.exports = {
  MODAL_ADD_CREDITO,
  ACT_DATA_CREDITO,
  MODAL_DET_CREDITO,
  MODAL_GES_CREDITO,
  MODAL_ADD_ADJUNTO_CREDITO,
  MODAL_ADD_FILTRO_CREDITO,
  MODAL_FORMU_CREDITO,
  MODAL_ENCUESTA_CREDITO,
  mostrarModalAddCredito,
  actualizarDataCredito,
  mostrarModalDetalleCredito,
  mostrarModalEncuestaCredito,
  mostrarModalGestionarCredito,
  mostrarModalAdjuntoCredito,
  mostrarModalAddFiltrosCredito,
  mostrarModalFormuDirecto
}
