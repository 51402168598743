import React, { Component } from 'react';
import { api } from '../../../global/js/funciones'
import { BtnDetalle } from '../../general/BotonesAccion'
import { Link } from '@material-ui/core'
import ListarDatos from '../../general/ListarDatos'

class DetalleAdj extends Component {
    

    actions_adj = row => {
        let { } = this.props 
        return (
          <div>
            <Link href={`${api + row.archivo}`} target="_blank">
                <BtnDetalle callback={() => {}} />
            </Link>
          </div>
        )
    }

    renderAdjuntos = () => {
        let { datos } = this.props
        return (
            <ListarDatos 
                id="tbl_inf_anexos"
                datos={datos}
                descargar={''}
                opciones={true}
                buscar={false}
                sub_titulo={"Lista de mis documentos"}
                acciones={row => this.actions_adj(row)}
                avatar={({ id }) => id}
                fila_principal={({ nombre_archivo }) => nombre_archivo}
                filas={[
                    {
                        'mostrar': ({ nombre_archivo }) => nombre_archivo,
                        'id': 'estado_actual',
                        'enLista': true
                    }
                ]}
            />
        )
    }

    render() {
        return (
            <div>
                {this.renderAdjuntos()}
            </div>
        );
    }
}

export default DetalleAdj;