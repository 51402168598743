
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import AdjuntosListar from "./AdjuntosListar";
import EnviarMensaje from "../../general/EnviarMensaje";
import ConfirmarAccion from '../../general/ConfirmarAccion';
import EnviarArchivo from '../../general/EnviarArchivo';
import Adjunto from './Adjunto';
//FUNCIONES GENERALES
import { consulta, mostrarError, formulario, crear_form_data, generarFiltros } from "../../../global/js/funciones";
import { BtnSeleccion, BtnCancelar, BtnEditar, BtnDetalle } from "../../general/BotonesAccion";
import emma_w from '../../../global/imagenes/emma_w.png';
import TareasTerminadas from '../../general/TareasTerminadas';
//ACCIONES DEL MODULO
import { mostrarModalAprobarRequisito, mostrarModalDetalleAdjunto, mostrarModalAddAdjunto, actualizarDataAdjuntoPractica, actualizarDataAdjuntosPractica, actualizarRenderAdjuntosPracticas, mostrarModalAddPracticas, mostrarModalGestionarPractica } from "../../../redux/actions/actPracticas";
import { mostrarModalAdd, mostrarModalDel, mostrarModalMod } from "../../../redux/actions/actGenericas";
import { actualizarMensaje } from "../../../redux/actions/actGlobal";

class Adjuntos extends Component {

  constructor(props) {
    super(props)
    this.state = {
      render: false,
      validacion: null,
      acta: [],
    }
  }


  componentDidMount() {
    this.obtenerAdjuntos();
  }

  componentDidUpdate({ validacion }) {
    let { validacion: validacionNew } = this.props;
    if (validacion !== validacionNew) this.apliacarFiltro(validacionNew);
  }


  obtenerAdjuntos = async (validacion = null) => {
    let { actualizarDataAdjuntosPractica, practica, obtenerAdjFaltantes } = this.props;
    this.setState({ render: false });
    let f = [{ 'llave': 'estado_pr__practica__id', 'valor': practica.id }];
    if (validacion !== null) f.push({ 'llave': 'validacion', 'valor': validacion });
    let filtros = await generarFiltros(f);
    consulta(`api/v1.0/practicas/adjuntos?${filtros}`, null, null,
      (error, estado, resp) => {
        if (estado === 200) {
          actualizarDataAdjuntosPractica(resp);
          obtenerAdjFaltantes();
          this.setState({ render: true });
        }
      }
    );
  }

  remplazarAdjunto = async (adjunto, archivo) => {
    let { actualizarMensaje, mostrarModalMod } = this.props;
    let { id } = adjunto;
    let data = await crear_form_data({ archivo });
    this.setState({ render: false })
    mostrarModalMod(false);
    formulario(`api/v1.0/practicas/adjunto/${id}/remplazar`, data, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      this.obtenerAdjuntos();
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    })
  }

  apliacarFiltro = validacion => this.obtenerAdjuntos(validacion);

  nuevoAdjunto = async (archivo) => {
    let { actualizarMensaje, mostrarModalAddAdjunto, estado: estado_prac, practica } = this.props;
    let data = await crear_form_data({ archivo });
    formulario(`api/v1.0/practicas/${practica.id}/estado/${estado_prac.id}/adjuntos/nuevo`, data, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
        this.obtenerAdjuntos();
        mostrarModalAddAdjunto(false);
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    })
  }

  validarAdjunto = (id, validacion, nota = '', calificacion = '') => {
    let { mostrarModalAdd, mostrarModalDel, actualizarMensaje, mostrarModalAprobarRequisito } = this.props
    this.setState({render : false})
    consulta(`api/v1.0/adjuntos/${id}/validar`, { validacion, nota, calificacion }, "put",
      (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
          mostrarModalAdd(false);
          mostrarModalDel(false);
          mostrarModalAprobarRequisito(false);
          this.obtenerAdjuntos();
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      })
  }


  configurarAcciones = (adjunto) => {
    let { mostrarModalAprobarRequisito, config, mostrarModalAdd, actualizarDataAdjuntoPractica, mostrarModalDel, mostrarModalMod, accionesAct, mostrarModalDetalleAdjunto } = this.props;
    let { validacion, tipo, requisito : {codigo} } = adjunto

    const eventoAprobar = () => {
      let { requisito: { valore } } = adjunto;
      actualizarDataAdjuntoPractica(adjunto);
      if (valore === '1') mostrarModalAprobarRequisito(true)
      else mostrarModalDel(true);
    }

    const eventoNegar = () => {
      actualizarDataAdjuntoPractica(adjunto);
      mostrarModalAdd(true);
    }

    const eventoEditar = () => {
      actualizarDataAdjuntoPractica(adjunto);
      mostrarModalMod(true);
    }

    const eventoDetalle = () => {
      actualizarDataAdjuntoPractica(adjunto);
      mostrarModalDetalleAdjunto(true)
    }

    let detalle = <BtnDetalle callback={() => eventoDetalle()} />;
    let negar = validacion === '0' || validacion === '4' || (tipo === '2' && validacion === '2') ? <BtnCancelar texto='Negar' callback={() => eventoNegar()} /> : '';
    let editar = (validacion === '1' || validacion === '0' || (validacion === '2' && config)) && tipo === '1' ? <BtnEditar texto='Editar' callback={() => eventoEditar()} /> : '';
    let aprobar = validacion === '0' ? <BtnSeleccion texto='Aprobar' callback={() => eventoAprobar()} /> : '';
    let resp = <div>{detalle} {editar}</div>;
    if (config) resp = <div>{detalle} {aprobar} {negar} {editar}</div>
    if (!accionesAct) resp = detalle
    return resp

  }

  render() {
    let { modalAprobarRequisito, mostrarModalAprobarRequisito, modalDetalleAdjunto, mostrarModalDetalleAdjunto, tipo, adjSeleccionados, actualizarDataAdjSeleccionados, accionesM, actualizarMensaje, estado, accionesAct, mostrarModalAdd, modalAdd, adjuntos, config, adjunto, mostrarModalDel, modalDel, modalMod, mostrarModalMod, mostrarModalAddAdjunto, modalAddAdjunto, usuario, admin, practica } = this.props;
    let { render } = this.state;
    if (render) return (
      <div>
        <Adjunto requisito={adjunto} modalDetalleAdjunto={modalDetalleAdjunto} mostrarModalDetalleAdjunto={mostrarModalDetalleAdjunto} usuario={usuario}/>
        <EnviarArchivo enviarArchivo={(archivo) => this.remplazarAdjunto(adjunto, archivo)} modalAdd={modalMod} mostrarModalAdd={mostrarModalMod} titulo='Cargar Archivo' formatos={adjunto.requisito ? adjunto.requisito.valorg : ''}/>
        <EnviarArchivo enviarArchivo={(archivo) => this.nuevoAdjunto(archivo)} modalAdd={modalAddAdjunto} mostrarModalAdd={mostrarModalAddAdjunto} titulo='Nuevo Archivo' />
        <AdjuntosListar id_sol={practica.id} admin={admin} tipo={tipo} adjSeleccionados={adjSeleccionados} accionesM={accionesM} actualizarDataAdjSeleccionados={actualizarDataAdjSeleccionados} actualizarMensaje={actualizarMensaje} apliacarFiltro={this.apliacarFiltro} estado={estado} accionesAct={accionesAct} mostrarModalAddAdjunto={mostrarModalAddAdjunto} adjuntos={adjuntos} config={config} acciones={this.configurarAcciones} nombreZip={practica.usuario_registro ? `${practica.usuario_registro.primer_apellido}_${practica.usuario_registro.primer_nombre}` : ''}/>
        <EnviarMensaje modal={modalAdd} mostrarModal={mostrarModalAdd} titulo='¿ Negar Requisito ?' label='Observaciones' type='text' enviar={(mensaje) => this.validarAdjunto(adjunto.id, 1, mensaje)} />
        <EnviarMensaje
          modal={modalAprobarRequisito}
          mostrarModal={mostrarModalAprobarRequisito}
          titulo='¿ Aprobar Requisito ?'
          label='Nota' type='text'
          validaciones={["required", 'matchRegexp:^[0-9]+([,][0-9]+)?$']}
          errorMensajes={["El campo es requerido", "El campo debe ser un numero entre 0 - 5",]}
          enviar={
            (calificacion) => {
              calificacion = `${calificacion}`.replace(',', '.');
              calificacion >= 0 && calificacion <= 5 ? this.validarAdjunto(adjunto.id, 2, '', calificacion) : actualizarMensaje({ titulo: 'La nota debe ser un numero entre 0 y 5', tipo: 'info', mostrar: true, tiempo: 6000 })
            }
          }

        />
        <ConfirmarAccion mensaje1={`El adjunto seleccionado será aprobado, `} ejecutarAccion={() => this.validarAdjunto(adjunto.id, 2)} mostrarModalConfAccion={mostrarModalDel} modalConAccion={modalDel} dato={adjunto} titulo={'¿ Aprobar Archivo ?'} />
      </div>
    )
    else return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />;
  }
}

//CARGO ESTADOS DEL MODULO
const mapStateToProps = (state) => {
  let { modalAdd, modalDel, modalMod } = state.redGenericas;
  let { modalAprobarRequisito, modalDetalleAdjunto, adjunto, adjuntos, renderAdjuntosPracticas, estado, practica, modalAddAdjunto } = state.redPracticas;

  return {
    adjunto,
    adjuntos,
    renderAdjuntosPracticas,
    modalAdd,
    modalDel,
    modalMod,
    estado,
    practica,
    modalAddAdjunto,
    modalDetalleAdjunto,
    modalAprobarRequisito
  }
};

//CARGO FUNCIONES DEL MODULO
const mapDispatchToProps = {
  actualizarDataAdjuntoPractica,
  actualizarDataAdjuntosPractica,
  actualizarRenderAdjuntosPracticas,
  mostrarModalAddPracticas,
  mostrarModalGestionarPractica,
  mostrarModalAdd,
  mostrarModalDel,
  actualizarMensaje,
  mostrarModalMod,
  mostrarModalAddAdjunto,
  mostrarModalDetalleAdjunto,
  mostrarModalAprobarRequisito
}

//Props del componente
Adjuntos.propTypes = {
  //variables
  renderAdjuntosPracticas: PropTypes.bool.isRequired,
  modalDetalleAdjunto: PropTypes.bool.isRequired,
  accionesAct: PropTypes.bool.isRequired,
  modalAddAdjunto: PropTypes.bool.isRequired,
  modalDel: PropTypes.bool.isRequired,
  modalMod: PropTypes.bool.isRequired,
  modalAdd: PropTypes.bool.isRequired,
  estado: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  //funciones
  actualizarDataAdjuntoPractica: PropTypes.func.isRequired,
  actualizarDataAdjuntosPractica: PropTypes.func.isRequired,
  actualizarRenderAdjuntosPracticas: PropTypes.func.isRequired,
  mostrarModalAddPracticas: PropTypes.func.isRequired,
  mostrarModalAdd: PropTypes.func.isRequired,
  mostrarModalGestionarPractica: PropTypes.func.isRequired,
  mostrarModalDel: PropTypes.func.isRequired,
  mostrarModalMod: PropTypes.func.isRequired,
  actualizarMensaje: PropTypes.func.isRequired,
  mostrarModalAddAdjunto: PropTypes.func.isRequired,
  mostrarModalDetalleAdjunto: PropTypes.func.isRequired,
}

Adjuntos.defaultProps = {
  adj_faltantes: [],
  obtenerAdjFaltantes: () => { },
};


export default connect(mapStateToProps, mapDispatchToProps)(Adjuntos);


