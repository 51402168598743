import React from "react"
import { Route, Switch } from "react-router-dom"
import { ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core/"
import { Link } from "react-router-dom"

//Components
import Genericas from '../../genericas/Genericas'
import Elecciones from '../../elecciones/Elecciones'
import EleccionesVotar from '../../elecciones/EleccionesVotar'
import Inicio from '../../general/Inicio'
import Personas from '../../personas/Personas'
import Practicas from '../../practicas/Practicas'
import PracticasAgregarActa from '../../practicas/PracticasAgregarActa'
import PracticasAgregarActaAlt from '../../practicas/PracticasAgregarActaAlt'
import PracticasAdministrar from '../../practicas/administrar/PracticasAdministrar'
import InscripcionAgregar from '../../inscripciones/InscripcionAgregar'
import MatriculasRT from '../../matriculas/MatriculasReintTrans'
import Ingresar from './Ingresar'
import Inscripciones from '../../inscripciones/administrar/Inscripciones'
import Not_Found from '../app/Not_Found'
import { api } from "../../../global/js/funciones"
import home from "../../../global/imagenes/home.png"
import signout from "../../../global/imagenes/signout.png"
import Becas from "../../becas/Becas"
import BecasAdministrar from "../../becas/administrar/BecasAdministrar"
import Matriculas from "../../matriculas/Matriculas"
import MatriculasAdm from "../../matriculas/MatriculasAdm"
import CreditosAdministrar from "./../../creditos/CreditosAdministrar"
import CreditosEstudiantes from "./../../creditos/CreditosEstudiantes"
import Asistencia from "./../../asistencia/Asistencia"
import MatriculasConf from "../../matriculas/MatriculasConf"
import ValidacionesEstudiantes from "../../validaciones/ValidacionesEstudiantes"
import ValidacionesAdministrar from "../../validaciones/ValidacionesAdministrar"
import SolicitudesAgregar from "../../consultoria/SolicitudesAgregar"
import Preguntas from "../../preguntas/Preguntas"
import Encuesta from "../../encuesta/Encuesta"
import EncuestaGeneral from "../../encuesta/EncuestaGeneral"
import SesionTerminada from "./SesionTerminada"
import Invitados from "../../invitados/Invitados"
import InvitadosGestion from "../../invitados/InvitadosGestion"
import Reportes from "../../reportes/Reportes"
import ReaEstudiantes from "../../rea/ReaEstudiantes"
import ReaAdministrar from "../../rea/ReaAdministrar"
import ReaDocente from "../../rea/ReaDocente"
import Consultoria from "../../consultoria/administrar/ConsultoriaAdministrar"
import TutoriaEstudiantes from "../../tutorias/TutoriasEstudiantes"
import TutoriasAdministrar from "../../tutorias/TutoriasAdministrar"
import Internacionalizacion from "../../internacionalizacion/Internacionalizacion"
import AdministrarInternacionalizacion from "../../internacionalizacion/administrar/AdministrarInternacionalizacion"
import AutodiagnosticoAdministrar from "../../autodiagnostico/AutodiagnosticoAdministrar"
import AutodiagnosticoEstudiantes from "../../autodiagnostico/AutodiagnosticoEstudiantes"
import Consultas from "../../consultas/Consultas.js"

import Portal from "./../../portal/Portal"
import PortalEmpresa from "../../portal/Empresa"
import PortalGestion from "../../portal/PortalGestion"

import AdminEncuesta from "../../encuesta/AdminEncuestas"

//import RetosAdministrar from "../../retos/RetosAdministrar";
// import RetosAdministrar from "../../retos/RetosAdministrar";
import ReporteVacuna from "../../reporteVac/ReporteVacuna"

//import Semileros de Investigacion
import SemilleroPrincipal from "../../semilleros/SemilleroPrincipal";
import SemilleroAdministrador from "../../semilleros/SemilleroAdministrador"
import SemilleroEstudiante from "../../semilleros/SemilleroEstudiante"
import Semilleros from "../../semilleros/Semilleros"
import PreInscripcionAgregar from "../../inscripciones/preinscripciones/PreInscripcionAgregar"
import Ova from "../../ova/Ova"
import Entrevistas from "../../entrevistas/Entrevistas"
import PromocionAdministrar from "../../promocion/PromocionAdministrar"
import Grados from '../../grados/Grados'
import GradosAdm from './../../grados/GradosAdm'
import InvitadosVirtualEstudiante from "../../invitadosVirtual/InvitadosVirtualEstudiantes"
import InvitadosVirtualGestion from "../../invitadosVirtual/InvitadosVirtualAdministrar"
import RetosEstudiantes from '../../retoscuc/RetosEstudiantes'
import RetosAdministrar from '../../retoscuc/RetosAdministrar'
import Bloques from '../../bloques/bloques'
import AdminSalas from '../../bloques/AdminSalas/AdminSalas'
import CursoEstudiante from '../../cursodeformacion/CursoEstudiante'
import GestionarCurso from "../../cursodeformacion/Cursos/gestionar/GestionarCurso"
import InscripcionPosgradoAgregar from "../../inscripciones_posgrado/InscripcionPosgradoAgregar"
import InscripcionesPosgrados from "../../inscripciones_posgrado/InscripcionesPosgradoAdministrar"
import AtencionEstudiantes from '../../atencion/AtencionEstudiantes'
import AtencionAdministrar from '../../atencion/AtencionAdministrar'
import CentroConciliacion from '../../centro/administrar/CentroConciliacionAdministrar'
import Sisef from "../../sisef/Sisef";
import SisefEstudiante from "../../sisef/SisefEstudiante"
import EncuestaGraduados from "../../matriculas/matriculas/grados/encuesta_graduados/EncuestaGraduados.js"
import AutogestionEstudiante from '../../autogestion_estudiantil/AutogestionEstudiante.js'




const obtenerActividades = () => {
  return [
    {
      codigo: "personas_act",
      nombre: "personas",
      componente: () => Personas,
    },
    {
      codigo: "genericas_act",
      nombre: "genericas",
      componente: () => Genericas,
    },
    {
      codigo: "votaciones_act",
      nombre: "mis_votaciones",
      componente: () => EleccionesVotar,
    },
    {
      codigo: "elecciones_act",
      nombre: "votaciones",
      componente: () => Elecciones,
    },
    {
      codigo: "practicas_act",
      nombre: "mis_practicas",
      componente: () => Practicas,
    },
    {
      codigo: "practicasAdm_act",
      nombre: "practicas",
      componente: () => PracticasAdministrar,
    },
    {
      codigo: "inscripciones_act",
      nombre: "inscripciones",
      componente: () => Inscripciones,
    },
    {
      codigo: "becas_act",
      nombre: "becas",
      componente: () => Becas,
    },
    {
      codigo: "becasAdm_act",
      nombre: "becas_adm",
      componente: () => BecasAdministrar,
    },
    {
      codigo: "matriculas_act",
      nombre: "procesos_academicos",
      componente: () => Matriculas,
    },
    {
      codigo: "matriculasAdm_act",
      nombre: "gestion_procesos_academicos",
      componente: () => MatriculasAdm,
    },
    {
      codigo: "matriculasConf_act",
      nombre: "matriculasConf",
      componente: () => MatriculasConf,
    },
    {
      codigo: "creditos_act",
      nombre: "procesos_financieros",
      componente: () => CreditosEstudiantes,
    },
    {
      codigo: "creditos_adm_act",
      nombre: "gestion_procesos_financieros",
      componente: () => CreditosAdministrar,
    },
    {
      codigo: "consultas_act",
      nombre: "consultas",
      componente: () => Consultas,
    },
    {
      codigo: "asistencia_adm_act",
      nombre: "gestion_asistencia",
      componente: () => Asistencia,
    },
    {
      codigo: "invitados_act",
      nombre: "estudiantes_invitados",
      componente: () => Invitados,
    },
    {
      codigo: "invitados_adm_act",
      nombre: "gestion_estudiantes_invitados",
      componente: () => InvitadosGestion,
    },
    {
      codigo: "reportes_act",
      nombre: "reportes",
      componente: () => Reportes,
    },
    {
      codigo: "validaciones_act",
      nombre: "validaciones",
      componente: () => ValidacionesEstudiantes,
    },
    {
      codigo: "validaciones_adm_act",
      nombre: "gestion_validaciones",
      componente: () => ValidacionesAdministrar,
    },
    {
      codigo: "rea_act",
      nombre: "rea",
      componente: () => ReaEstudiantes,
    },
    {
      codigo: "rea_adm_act",
      nombre: "gestion_rea",
      componente: () => ReaAdministrar,
    },
    {
      codigo: "rea_doce_act",
      nombre: "docente_rea",
      componente: () => ReaDocente,
    },
    {
      codigo: "tutorias_act",
      nombre: "tutoria",
      componente: () => TutoriaEstudiantes,
    },
    {
      codigo: "tutorias_adm_act",
      nombre: "gestionTutoria_adm",
      componente: () => TutoriasAdministrar,
    },
    {
      codigo: "consultoria_admin_act",
      nombre: "consultoria_administrar",
      componente: () => Consultoria,
    },
    {
      codigo: "centro_conciliacion_admin_act",
      nombre: "centro_conciliacion_administrar",
      componente: () => CentroConciliacion,
    },
    {
      codigo: "ova_act",
      nombre: "ova",
      componente: () => Ova,
    },
    {
      codigo: "autodiagnostico_est_act",
      nombre: "autodiagnostico",
      componente: () => AutodiagnosticoEstudiantes,
    },
    {
      codigo: "autodiagnostico_act",
      nombre: "gestion_autodiagnostico",
      componente: () => AutodiagnosticoAdministrar,
    },
    {
      codigo: "portal_act",
      nombre: "portal",
      componente: () => Portal,
    },
    {
      codigo: "empresa_act",
      nombre: "empresa",
      componente: () => PortalEmpresa,
    },
    {
      codigo: "admportal_act",
      nombre: "portal_gestion",
      componente: () => PortalGestion,
    },
    {
      codigo: "admencuesta_act",
      nombre: "encuesta_admin",
      componente: () => AdminEncuesta,
    },
    {
      codigo: "reportevac_act",
      nombre: "reporte_vacuna",
      componente: () => ReporteVacuna,
    },
    {
      codigo: "internacionalizacion_act",
      nombre: "internacionalizacion",
      componente: () => Internacionalizacion,
    },
    {
      codigo: "internacionalizacion_adm_act",
      nombre: "internacionalizacion_adm",
      componente: () => AdministrarInternacionalizacion,
    },
    {
      codigo: "semilleros_principal",
      nombre: "semilleros",
      componente: () => SemilleroPrincipal,
    },
    {
      codigo: "semi_est",
      nombre: "semilleros_estudiante",
      componente: () => SemilleroEstudiante,
    },
    {
      codigo: "semi_act",
      nombre: "gestion_semilleros",
      componente: () => SemilleroAdministrador,
    },
    {
      codigo: "semi_pro",
      nombre: "semilleros_profesor",
      componente: () => Semilleros,
    },
    {
      codigo: "entrevistas_act",
      nombre: "historico_entrevistas",
      componente: () => Entrevistas
    },
    {
      codigo: "opcGrados_act",
      nombre: "grados",
      componente: () => Grados,
    },
    {
      codigo: "gradosAdm_act",
      nombre: "gestion_grados",
      componente: () => GradosAdm,
    },
    {
      codigo: "invitados_virt",
      nombre: "invitadosVirtual",
      componente: () => InvitadosVirtualEstudiante,
    },
    {
      codigo: "invitados_virt_adm",
      nombre: "InvitadosVirtualGestion",
      componente: () => InvitadosVirtualGestion,
    },
    {
      codigo: "inscripcionesPos_act",
      nombre: "gestion_inscripciones_posgrados",
      componente: () => InscripcionesPosgrados,
    },
    {
      codigo: "iretos_act",
      nombre: "retos",
      componente: () => RetosEstudiantes,
    },
    {
      codigo: "retos_act",
      nombre: "gestion_retos",
      componente: () => RetosAdministrar,
    },
    {
      codigo: "promocion_act",
      nombre: "promocion",
      componente: () => PromocionAdministrar,
    },
    {
      codigo: "salas_act",
      nombre: "salas",
      componente: () => Bloques,
    },
    {
      codigo: "admin_salas",
      nombre: "adminsalas",
      componente: () => AdminSalas,
    },
    {
      codigo: "curso_formacion_estudiante",
      nombre: "curso_estudiante",
      componente: () => CursoEstudiante,
    },
    {
      codigo: "gestion_curso_formacion",
      nombre: "gestion_curso",
      componente: () => GestionarCurso,
    },
    {
      codigo: "atencion_reporte",
      nombre: "atencion",
      componente: () => AtencionEstudiantes
    },
    {
      codigo: "atencion_reporte_adm",
      nombre: "atencion_adm",
      componente: () => AtencionAdministrar
    },
    {
      codigo: "sisef_act",
      nombre: "mentora",
      componente: () => Sisef,
    },
    {
      codigo: "sisef_est_act",
      nombre: "mi_mentora",
      componente: () => SisefEstudiante,
    },
    {
      codigo: "autogestion_estudiantil_act",
      nombre: "autogestion_estudiantil",
      componente: () => AutogestionEstudiante,
    },
  ]
}

export const actividadesDisponibles = (actividades, path) => {
  let actividades_app = [];
  obtenerActividades().forEach((a1) =>
    actividades.forEach(({ secundario: { codigo, nombre, archivo, valora } }) => {
      if (a1.codigo === codigo) {
        a1.titulo = nombre;
        a1.imagen = api + archivo;
        if (valora !== "submodulo" || path !== "/") {
          actividades_app.push(a1);
        }
      }
    })
  )
  return actividades_app
}

export function RutasPrincipales({ actividades }) {
  const currentPath = window.location.pathname;

  return (
    <>
      <Switch>
        <Route
          exact
          path="/facecuc"
          render={() => <Inicio actividades={actividades} />}
        ></Route>
        <Route
          exact
          path="/"
          render={() => (
            <Inicio actividades={actividadesDisponibles(actividades, currentPath)} />
          )}
        ></Route>
        {actividadesDisponibles(actividades, currentPath).map((item, key) => {
          return (
            <Route
              key={key}
              exact
              path={`/${item.nombre}`}
              component={item.componente()}
            ></Route>
          )
        })}
        <Route exact path="/Preguntas" component={Preguntas} />
        <Route exact path="/encuesta/:tokenEcuesta" component={Encuesta} />
        <Route
          exact
          path="/encuesta_general/:tokenEcuesta"
          component={EncuestaGeneral}
        />
        <Route path="/practicas" component={Not_Found}>
          <Route
            exact
            path="/practicas/:id_requisito"
            component={PracticasAgregarActa}
          />
        </Route>
        <Route path="/encuesta_graduados" component={Not_Found}>
          <Route
            exact
            path="/encuesta_graduados"
            component={EncuestaGraduados}
          />
        </Route>
        <Route component={Not_Found}></Route>
      </Switch>
    </>
  )
}

export function RutasInicio() {
  return (
    <div>
      <Switch>
        <Route exact path='/inscripcion' component={InscripcionAgregar}></Route>
        <Route exact path="/inscripcion_posgrados" component={InscripcionPosgradoAgregar}></Route>
        <Route exact path='/matriculas_externa' component={MatriculasRT}></Route>
        <Route exact path='/consultoria' component={SolicitudesAgregar}></Route>
        <Route exact path="/sin_sesion" component={SesionTerminada} />
        <Route exact path="/Preguntas" component={Preguntas} />
        <Route exact path="/practicas" component={Ingresar} />
        <Route
          exact
          path="/preuniversitario/edu"
          component={PreInscripcionAgregar}
        ></Route>
        <Route path="/practicas" render={() => <></>}>
          <Route
            exact
            path="/practicas/:id_requisito"
            component={PracticasAgregarActaAlt}
          />
        </Route>
        <Route component={Ingresar} />
      </Switch>
    </div>
  )
}

export const menuPrincipal = (modulo, actividades) => (
  <div>
    <ListItem button component={Link} to="/" selected={modulo === "/"}>
      <ListItemIcon>
        <img
          src={home}
          style={{ width: "25px", maxHeight: "25px" }}
          alt="Inicio"
        />
      </ListItemIcon>
      <ListItemText primary={<Typography style={{ fontWeight: 600, color: '#62727b', textTransform: 'capitalize' }}>
        Inicio
      </Typography>} />
    </ListItem>
    {actividadesDisponibles(actividades, '/').map(
      ({ nombre, titulo, imagen }, key) => {
        return (
          <ListItem
            button
            component={Link}
            to={`/${nombre}`}
            selected={modulo === `/${nombre}`}
            key={key}
          >
            <ListItemIcon>
              <img
                src={imagen}
                alt={titulo}
                style={{ width: "25px", maxHeight: "25px" }}
              />
            </ListItemIcon>
            <ListItemText primary={
              <Typography style={{ fontWeight: 600, color: '#62727b', textTransform: 'capitalize' }}>
                {titulo}
              </Typography>
            } />
          </ListItem>
        )
      }
    )}
  </div>
)

export const menuSecundario = (CerrarApp) => (
  <div>
    {/* <ListItem button>
      <ListItemIcon>
        <img src={account} style={{ width: '25px', maxHeight: "25px" }} alt="Mi Cuenta" />
      </ListItemIcon>
      <ListItemText primary="Mi cuenta" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <img src={dictionary} style={{ width: '25px', maxHeight: "25px" }} alt="Manuales" />
      </ListItemIcon>
      <ListItemText primary="Manuales" />
    </ListItem> */}
    <ListItem button onClick={CerrarApp.bind(this, true)}>
      <ListItemIcon>
        <img
          src={signout}
          style={{ width: "25px", maxHeight: "25px" }}
          alt="Cerrar Sesión"
        />
      </ListItemIcon>
      <ListItemText primary={<Typography style={{ fontWeight: 600, color: '#62727b', textTransform: 'capitalize' }}>
        Cerrar Sesión
      </Typography>}/>
    </ListItem>
  </div>
)
