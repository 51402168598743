import React from 'react'
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import AppBarModal from "../../general/AppBarModal";
import CustomDropzone from "../../general/CustomDropzone";
import { BtnForm } from "../../general/BotonesAccion";

class CreditoAdjuntarArchivo extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      archivos: [],
    }
  }

  eliminarArchivo = (i) => {
    let { actualizarMensaje } = this.props;
    let { archivos } = this.state;
    archivos.splice(i, 1)
    this.setState({ archivos })
    actualizarMensaje({ titulo: `Archivo Retirado.`, tipo: 'success', mostrar: true, tiempo: 6000 })
  }

  agregarArchivos = (arc) => {
    let { archivos } = this.state;
    archivos = archivos.concat(arc);
    this.setState({ archivos })
  }

  cargar = () => {
    let { actualizarMensaje } = this.props;
    let { archivos } = this.state;
    if (archivos.length === 0) actualizarMensaje({ titulo: `No hay documentos cargados.`, tipo: 'info', mostrar: true, tiempo: 6000 })
    this.props.cargarArchivos(archivos);
  }


  render() {
    let { modalAddAdjuntosCredito, mostrarModalAdjuntoCredito } = this.props;
    return (
      <Dialog open={modalAddAdjuntosCredito} fullWidth={true} maxWidth='sm' onClose={() => mostrarModalAdjuntoCredito(false)}>
        <AppBarModal titulo='¡ Cargar Documentos !' mostrarModal={() => mostrarModalAdjuntoCredito(false)} titulo_accion='' />
        <DialogContent className='scroll'>
          <CustomDropzone archivos={this.state.archivos} addFunction={this.agregarArchivos} deleteFunction={this.eliminarArchivo} actualizarMensaje={this.props.actualizarMensaje} />
        </DialogContent>
        <DialogActions>
          <BtnForm texto="Cerrar" callback={() => mostrarModalAdjuntoCredito(false)} />
          <BtnForm texto="ENVIAR" callback={this.cargar} />
        </DialogActions>
      </Dialog>
    );
  }

}

export default CreditoAdjuntarArchivo;


