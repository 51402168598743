const MODAL_ADD = 'MODAL_ADD'
const MODAL_MOD = 'MODAL_MOD'
const MODAL_DEL = 'MODAL_DEL'
const ACT_DATA_GENERICA = 'ACT_DATA_GENERICA'
const ACT_DATA_GENERICAS = 'ACT_DATA_GENERICAS'
const ACT_RENDER = 'ACT_RENDER'
const MODAL_ADM_VALORES = 'MODAL_ADM_VALORES'

const mostrarModalAdd = show => {
  return {
    type: MODAL_ADD,
    show,
  }
}

const mostrarModalMod = show => {
  return {
    type: MODAL_MOD,
    show,
  }
}

const mostrarModalDel = show => {
  return {
    type: MODAL_DEL,
    show,
  }
}

const actualizarDataGenerica = generica => {
  return {
    type: ACT_DATA_GENERICA,
    generica,
  }
}


const actualizarDataGenericas = genericas => {
  return {
    type: ACT_DATA_GENERICAS,
    genericas,
  }
}

const actualizarRender = render => {
  return {
    type: ACT_RENDER,
    render,
  }
}

const mostrarModalAdmValores = show => {
  return {
    type: MODAL_ADM_VALORES,
    show,
  }
}


module.exports = {
  mostrarModalAdd,
  mostrarModalMod,
  mostrarModalDel,
  actualizarDataGenerica,
  actualizarDataGenericas,
  actualizarRender,
  mostrarModalAdmValores,
  MODAL_ADD,
  MODAL_MOD,
  MODAL_DEL,
  ACT_DATA_GENERICA,
  ACT_DATA_GENERICAS,
  ACT_RENDER,
  MODAL_ADM_VALORES,
}
