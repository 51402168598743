import React from 'react'
import { ScheduleComponent, Inject, Day, WorkWeek, ViewDirective, ViewsDirective, Week } from '@syncfusion/ej2-react-schedule'
import { L10n, loadCldr } from "@syncfusion/ej2-base";

// CONFIGURANDO IDIOMA DEL COMPONENTE
let numberingSystems = {
  "supplemental": {
    "version": {
      "_number": "$Revision: 13459 $",
      "_unicodeVersion": "10.0.0",
      "_cldrVersion": "32"
    },
    "numberingSystems": {
      "latn": {
        "_digits": "0123456789",
        "_type": "numeric"
      }
    }
  }
}
let gregorian = {
  "main": {
    "es": {
      "identity": {
        "version": {
          "_number": "$Revision: 13722 $",
          "_cldrVersion": "32"
        },
        "language": "es"
      },
      "dates": {
        "calendars": {
          "gregorian": {
            "months": {
              "format": {
                "abbreviated": {
                  "1": "ene.",
                  "2": "feb.",
                  "3": "mar.",
                  "4": "abr.",
                  "5": "may.",
                  "6": "jun.",
                  "7": "jul.",
                  "8": "ago.",
                  "9": "sept.",
                  "10": "oct.",
                  "11": "nov.",
                  "12": "dic."
                },
                "narrow": {
                  "1": "E",
                  "2": "F",
                  "3": "M",
                  "4": "A",
                  "5": "M",
                  "6": "J",
                  "7": "J",
                  "8": "A",
                  "9": "S",
                  "10": "O",
                  "11": "N",
                  "12": "D"
                },
                "wide": {
                  "1": "enero",
                  "2": "febrero",
                  "3": "marzo",
                  "4": "abril",
                  "5": "mayo",
                  "6": "junio",
                  "7": "julio",
                  "8": "agosto",
                  "9": "septiembre",
                  "10": "octubre",
                  "11": "noviembre",
                  "12": "diciembre"
                }
              },
              "stand-alone": {
                "abbreviated": {
                  "1": "ene.",
                  "2": "feb.",
                  "3": "mar.",
                  "4": "abr.",
                  "5": "may.",
                  "6": "jun.",
                  "7": "jul.",
                  "8": "ago.",
                  "9": "sept.",
                  "10": "oct.",
                  "11": "nov.",
                  "12": "dic."
                },
                "narrow": {
                  "1": "E",
                  "2": "F",
                  "3": "M",
                  "4": "A",
                  "5": "M",
                  "6": "J",
                  "7": "J",
                  "8": "A",
                  "9": "S",
                  "10": "O",
                  "11": "N",
                  "12": "D"
                },
                "wide": {
                  "1": "enero",
                  "2": "febrero",
                  "3": "marzo",
                  "4": "abril",
                  "5": "mayo",
                  "6": "junio",
                  "7": "julio",
                  "8": "agosto",
                  "9": "septiembre",
                  "10": "octubre",
                  "11": "noviembre",
                  "12": "diciembre"
                }
              }
            },
            "days": {
              "format": {
                "abbreviated": {
                  "sun": "dom.",
                  "mon": "lun.",
                  "tue": "mar.",
                  "wed": "mié.",
                  "thu": "jue.",
                  "fri": "vie.",
                  "sat": "sáb."
                },
                "narrow": {
                  "sun": "D",
                  "mon": "L",
                  "tue": "M",
                  "wed": "X",
                  "thu": "J",
                  "fri": "V",
                  "sat": "S"
                },
                "short": {
                  "sun": "DO",
                  "mon": "LU",
                  "tue": "MA",
                  "wed": "MI",
                  "thu": "JU",
                  "fri": "VI",
                  "sat": "SA"
                },
                "wide": {
                  "sun": "domingo",
                  "mon": "lunes",
                  "tue": "martes",
                  "wed": "miércoles",
                  "thu": "jueves",
                  "fri": "viernes",
                  "sat": "sábado"
                }
              },
              "stand-alone": {
                "abbreviated": {
                  "sun": "dom.",
                  "mon": "lun.",
                  "tue": "mar.",
                  "wed": "mié.",
                  "thu": "jue.",
                  "fri": "vie.",
                  "sat": "sáb."
                },
                "narrow": {
                  "sun": "D",
                  "mon": "L",
                  "tue": "M",
                  "wed": "X",
                  "thu": "J",
                  "fri": "V",
                  "sat": "S"
                },
                "short": {
                  "sun": "DO",
                  "mon": "LU",
                  "tue": "MA",
                  "wed": "MI",
                  "thu": "JU",
                  "fri": "VI",
                  "sat": "SA"
                },
                "wide": {
                  "sun": "domingo",
                  "mon": "lunes",
                  "tue": "martes",
                  "wed": "miércoles",
                  "thu": "jueves",
                  "fri": "viernes",
                  "sat": "sábado"
                }
              }
            },
            "dateFormats": {
              "full": "EEEE, d 'de' MMMM 'de' y",
              "long": "d 'de' MMMM 'de' y",
              "medium": "d MMM y",
              "short": "d/M/yy"
            },
            "timeFormats": {
              "full": "H:mm:ss (zzzz)",
              "long": "H:mm:ss z",
              "medium": "H:mm:ss",
              "short": "H:mm"
            },
            "dateTimeFormats": {
              "full": "{1}, {0}",
              "long": "{1}, {0}",
              "medium": "{1} {0}",
              "short": "{1} {0}",
              "availableFormats": {
                "MMM": "LLL",
                "y": "y",
                "yMMMM": "MMMM 'de' y",
              }
            }
          }
        }
      }
    }
  }
}
let numbers = {
  "main": {
    "es": {
      "identity": {
        "version": {
          "_number": "$Revision: 13722 $",
          "_cldrVersion": "32"
        },
        "language": "es"
      },
      "numbers": {
        "defaultNumberingSystem": "latn",
        "minimumGroupingDigits": "2",
        "symbols-numberSystem-latn": {
          "decimal": ",",
          "group": ".",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "-",
          "exponential": "E",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "NaN",
          "timeSeparator": ":"
        }
      }
    }
  }
}
let timeZoneNames = {
  "main": {
    "es": {
      "identity": {
        "version": {
          "_number": "$Revision: 13722 $",
          "_cldrVersion": "32"
        },
        "language": "es"
      },
      "dates": {
        "timeZoneNames": {
          "hourFormat": "+HH:mm;-HH:mm",
          "gmtFormat": "GMT{-5}",
          "gmtZeroFormat": "GMT",
          "regionFormat": "hora de {0}",
          "regionFormat-type-daylight": "horario de verano de {0}",
          "regionFormat-type-standard": "horario estándar de {0}",
          "fallbackFormat": "{1} ({0})",

        }
      }
    }
  }
}
loadCldr(numberingSystems, gregorian, numbers, timeZoneNames)
L10n.load({
  es: {
    schedule: {
      day: "Día",
      week: "Semana",
      today: "Hoy",
      noEvents: "No hay eventos",
      allDay: "Todo el dia",
      start: "comienzo",
      end: "Final",
      more: "más",
      cancel: "Cancelar",
      noTitle: "(Sin título)",
      moreDetails: "Más detalles",
      title: "Título",
      location: "Ubicación",
      description: "Descripción",
      timezone: "Zona horaria",
      startTimezone: "Zona horaria de inicio",
      endTimezone: "Zona horaria final",
      cancelButton: "Cancelar",
      ok: "Ok",
      yes: "Si",
      no: "No",
      previous: "Anterior",
      next: "Siguiente",
    },
    calendar: {
      today: "Hoy"
    }
  }
});

export default function PracticaCalendarioVisitas({ datos }) {

  return (
    <ScheduleComponent currentView="Week" readonly={true} allowDragAndDrop={false} firstDayOfWeek={1} maxDate={new Date(2030, 11, 31)} minDate={new Date(2020, 0, 1)} showWeekend={false} workHours={{ highlight: true, start: '08:00', end: '18:00' }} allowResizing={true} eventSettings={{ dataSource: datos }} locale="es">
      <ViewsDirective>
        <ViewDirective option="Day" startHour="06:00" endHour="18:00" />
        <ViewDirective option="Week" startHour="06:00" endHour="18:00" />
      </ViewsDirective>
      <Inject services={[Day, WorkWeek, Week]} />
    </ScheduleComponent>
  )

}