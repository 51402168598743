import React from 'react';
import { List, ListItem, ListItemText, Table, TableBody, TableCell, TableRow, ExpansionPanelSummary, ExpansionPanelDetails, Typography, ExpansionPanel } from '@material-ui/core';
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TareasTerminadas from '../../general/TareasTerminadas';
import emma_w from '../../../global/imagenes/emma_s1.png';
import DescargarArchivo from '../../general/DescargarArchivo';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    width: '100%',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function PracticaDetalle({ practica ,interes}) {
  let {
    tipo,
    fecha_registro,
    estado_actual,
    usuario_registro: { primer_nombre, primer_apellido, segundo_apellido, identificacion, telefono, correo, celular, correo_personal },
    programa,
    busca_empresa,
    fecha_inicio,
    duracion,
    empresa,
    area_desarrollo,
    auxilio,
    jefe,
    cargo_jefe,
    correo_jefe,
    telefono_jefe,
    objetivo_practica,
    funciones,
    horario_salida,
    horario_entrada,
    nota,
    trabaja,
    en_area,
    periodo,
    sucursal,
    tutor,
    fecha_limite,
    contrato,
    segundo_idioma,
    promedioacumulado,
    aprendizaje,
    idioma_habla,
    area_pro_mejora
    

  } = practica;



console.log(interes)
let interesnombre=[]
interes.map(({areainteres},i)=>{
 
  interesnombre.push(areainteres.nombre)
  
})
console.log(interesnombre)


  let fecha = moment(fecha_registro).format('YYYY-MM-DD, h:mm a');
  let fecha_fin = moment(fecha_inicio).add(duracion, 'M').format('YYYY-MM-DD');
  let fecha_lim = moment(fecha_limite).format('YYYY-MM-DD');
  let segundoidiomaconver = segundo_idioma == 1 ? 'Si' : 'No';
  let aprendizajeconver = aprendizaje == 1 ? 'Si' : 'No';
  return (
    <Table>
      <TableBody>
        <TableRow key={-1}>
          <TableCell component="th" scope="row">Nombre</TableCell>
          <TableCell align="left">{`${primer_nombre} ${primer_apellido} ${segundo_apellido}`}</TableCell>
        </TableRow>
        <TableRow key={1}>
          <TableCell component="th" scope="row">Identificación</TableCell>
          <TableCell align="left">{identificacion}</TableCell>
        </TableRow>
        <TableRow key={-2}>
          <TableCell component="th" scope="row">Correo Institucional</TableCell>
          <TableCell align="left">{correo}</TableCell>
        </TableRow>
        <TableRow key={36}>
          <TableCell component="th" scope="row">Correo Personal</TableCell>
          <TableCell align="left">{correo_personal}</TableCell>
        </TableRow>
        <TableRow key={20}>
          <TableCell component="th" scope="row">Fijo/Celular</TableCell>
          <TableCell align="left">{telefono}/{celular}</TableCell>
        </TableRow>
        <TableRow key={24}>
          <TableCell component="th" scope="row">Periodo</TableCell>
          <TableCell align="left">{periodo}</TableCell>
        </TableRow>
        <TableRow key={2}>
          <TableCell component="th" scope="row">Programa</TableCell>
          <TableCell align="left">{programa.nombre}</TableCell>
        </TableRow>
        {promedioacumulado ? 
        <TableRow key={2}>
          <TableCell component="th" scope="row">Promedio acumulado</TableCell>
          <TableCell align="left">{promedioacumulado}</TableCell>
        </TableRow>: <TableRow key={2}></TableRow>}
        <TableRow key={2}>
          <TableCell component="th" scope="row">Segundo idioma</TableCell>
          <TableCell align="left">{segundoidiomaconver}</TableCell>
        </TableRow>
       { 
          segundoidiomaconver==='Si' &&   
       <TableRow key={2}>
          <TableCell component="th" scope="row">Idioma que habla</TableCell>
          <TableCell align="left">{idioma_habla}</TableCell>
        </TableRow>}
      
       { interesnombre.length != 0 ?
        <TableRow key={4}>
          <TableCell component="th" scope="row">Area de interes</TableCell>
            <TableCell align="left">
              { interesnombre.map((index,i)=>(<TableRow scope="row">{index}</TableRow>)) }
           </TableCell>
         </TableRow>:
         <TableRow key={4}></TableRow>
      }
        <TableRow key={2}>
          <TableCell component="th" scope="row">Contrato de aprendizaje</TableCell>
          <TableCell align="left">{aprendizajeconver}</TableCell>
        </TableRow>
        { area_pro_mejora &&
             <TableRow key={2}>
             <TableCell component="th" scope="row">Área de desarrollo de proyecto de mejora</TableCell>
             <TableCell align="left">{area_pro_mejora}</TableCell>
           </TableRow>
        }
        {nota || nota === 0 ?
          <TableRow key={21}>
            <TableCell component="th" scope="row">Nota Final</TableCell>
            <TableCell align="left">{nota}</TableCell>
          </TableRow> : <TableRow key={21}></TableRow>}
        <TableRow key={3}>
          <TableCell component="th" scope="row">Modalidad</TableCell>
          <TableCell align="left">{tipo.nombre}</TableCell>
        </TableRow>
        { (tipo.valora!='clinicas') ?
        <React.Fragment>
        <TableRow key={32}>
          <TableCell component="th" scope="row">Tutor</TableCell>
          <TableCell align="left">{tutor ? `${tutor.primer_nombre} ${tutor.primer_apellido} ${tutor.segundo_apellido}` : 'No registra'}</TableCell>
        </TableRow>
        <TableRow key={33}>
          <TableCell component="th" scope="row">Correo Tutor</TableCell>
          <TableCell align="left">{tutor ? `${tutor.correo}` : 'No registra'}</TableCell>
        </TableRow>
        </React.Fragment>
        :<></>}
        <TableRow key={4}>
          <TableCell component="th" scope="row">Trabaja</TableCell>
          <TableCell align="left">{trabaja === '-1' ? 'No Aplica' : trabaja === '1' ? 'SI' : 'NO'}</TableCell>
        </TableRow>
        <TableRow key={22}>
          <TableCell component="th" scope="row">Trabaja En su área</TableCell>
          <TableCell align="left">{en_area === '-1' ? 'No Aplica' : en_area === '1' ? 'SI' : 'NO'}</TableCell>
        </TableRow>
        <TableRow key={23}>
          <TableCell component="th" scope="row">Buscar Empresa</TableCell>
          <TableCell align="left">{busca_empresa === '-1' ? 'No Aplica' : busca_empresa === '1' ? 'SI' : 'NO'}</TableCell>
        </TableRow>
        {
          fecha_limite ?
            <TableRow key={31}>
              <TableCell component="th" scope="row">Fecha Limite</TableCell>
              <TableCell align="left">{fecha_lim}</TableCell>
            </TableRow> : <TableRow key={31}></TableRow>
        }
      </TableBody>

      {empresa ? <TableBody>
        <TableRow key={5}>
          <TableCell component="th" scope="row">{ (tipo.valora!='clinicas') ? 'Empresa':'Escenario'}</TableCell>
          <TableCell align="left">{empresa.nombre}</TableCell>
        </TableRow>
        <TableRow key={25}>
          <TableCell component="th" scope="row">NIT</TableCell>
          <TableCell align="left">{empresa.codigo}</TableCell>
        </TableRow>
        <TableRow key={26}>
          <TableCell component="th" scope="row">Actividad Económica</TableCell>
          <TableCell align="left">{empresa.descripcion}</TableCell>
        </TableRow>
        <TableRow key={27}>
          <TableCell component="th" scope="row">Sucursal</TableCell>
          <TableCell align="left">{sucursal.nombre}</TableCell>
        </TableRow>
        <TableRow key={28}>
          <TableCell component="th" scope="row">Ubicación</TableCell>
          <TableCell align="left">{sucursal.valora}</TableCell>
        </TableRow>
        <TableRow key={35}>
          <TableCell component="th" scope="row">Dirección</TableCell>
          <TableCell align="left">{sucursal.valorg}</TableCell>
        </TableRow>
        <TableRow key={29}>
          <TableCell component="th" scope="row">Barrio</TableCell>
          <TableCell align="left">{sucursal.valorb}</TableCell>
        </TableRow>
        <TableRow key={30}>
          <TableCell component="th" scope="row">Telefono</TableCell>
          <TableCell align="left">{sucursal.valorc}</TableCell>
        </TableRow>
        { (tipo.valora!='clinicas') && <TableRow key={7}>
          <TableCell component="th" scope="row">Duración</TableCell>
          <TableCell align="left">{duracion && `${duracion} Meses`}</TableCell>
        </TableRow>
        }
        {contrato ?
          <TableRow key={34}>
            <TableCell component="th" scope="row">Tipo de Contrato</TableCell>
            <TableCell align="left">{contrato.nombre}</TableCell>
          </TableRow>
          : <TableRow key={34}></TableRow>
        }
        <TableRow key={6}>
          <TableCell component="th" scope="row">Fecha Inicio</TableCell>
          <TableCell align="left">{duracion && fecha_inicio}</TableCell>
        </TableRow>
        { (tipo.valora!='clinicas') ?
        <React.Fragment>
        <TableRow key={21}>
          <TableCell component="th" scope="row">Fecha Fin</TableCell>
          <TableCell align="left">{duracion && fecha_fin}</TableCell>
        </TableRow>
        <TableRow key={8}>
          <TableCell component="th" scope="row">Objetivo Practica</TableCell>
          <TableCell align="left">{objetivo_practica}</TableCell>
        </TableRow>
        <TableRow key={9}>
          <TableCell component="th" scope="row">Horario Entrada</TableCell>
          <TableCell align="left">{horario_entrada}</TableCell>
        </TableRow>
        <TableRow key={10}>
          <TableCell component="th" scope="row">Horario Salida</TableCell>
          <TableCell align="left">{horario_salida}</TableCell>
        </TableRow>
        <TableRow key={11}>
          <TableCell component="th" scope="row">$Auxilio/Salario</TableCell>
          <TableCell align="left">{auxilio}</TableCell>
        </TableRow>
        <TableRow key={12}>
          <TableCell component="th" scope="row">Area Desarrollo</TableCell>
          <TableCell align="left">{area_desarrollo}</TableCell>
        </TableRow>
        <TableRow key={13}>
          <TableCell component="th" scope="row">Funciones</TableCell>
          <TableCell align="left">{funciones}</TableCell>
        </TableRow>
        </React.Fragment>
        :<></>}
        <TableRow key={14}>
          <TableCell component="th" scope="row">{(tipo.valora!='clinicas') ? 'Jefe':'Tutor'} Inmediato</TableCell>
          <TableCell align="left">{jefe}</TableCell>
        </TableRow>
        <TableRow key={15}>
          <TableCell component="th" scope="row">Cargo {(tipo.valora!='clinicas') ? 'Jefe':'Tutor'}</TableCell>
          <TableCell align="left">{cargo_jefe}</TableCell>
        </TableRow>
        <TableRow key={16}>
          <TableCell component="th" scope="row">Correo {(tipo.valora!='clinicas') ? 'Jefe':'Tutor'}</TableCell>
          <TableCell align="left">{correo_jefe}</TableCell>
        </TableRow>
        <TableRow key={17}>
          <TableCell component="th" scope="row">Teléfono {(tipo.valora!='clinicas') ? 'Jefe':'Tutor'}</TableCell>
          <TableCell align="left">{telefono_jefe}</TableCell>
        </TableRow>
        <TableRow key={18}>
          <TableCell component="th" scope="row">Estado Actual</TableCell>
          <TableCell align="left">{estado_actual.nombre}</TableCell>
        </TableRow>
        <TableRow key={19}>
          <TableCell component="th" scope="row">Fecha Registro</TableCell>
          <TableCell align="left">{fecha}</TableCell>
        </TableRow>
      </TableBody>
        : <TableBody>
          <TableRow key={18}>
            <TableCell component="th" scope="row">Estado Actual</TableCell>
            <TableCell align="left">{estado_actual.nombre}</TableCell>
          </TableRow>
          <TableRow key={19}>
            <TableCell component="th" scope="row">Fecha Registro</TableCell>
            <TableCell align="left">{fecha}</TableCell>
          </TableRow>
        </TableBody>
      }
    </Table>
  )
}
export function PracticaDetalleSimple({ practica }) {
  let {
    tipo,
    fecha_registro,
    estado_actual,
    programa,
    fecha_inicio,
    duracion,
    usuario_registro: { primer_nombre, primer_apellido, segundo_apellido, identificacion },
  } = practica;
  let fecha = moment(fecha_registro).format('YYYY-MM-DD, h:mm a');
  // let fecha_fin = moment(fecha_inicio).add(duracion, 'M').format('YYYY-MM-DD');
  return (

    <Table>
      <TableBody>
        <TableRow key={20}>
          <TableCell component="th" scope="row">Nombre</TableCell>
          <TableCell align="left">{`${primer_nombre} ${primer_apellido} ${segundo_apellido}`}</TableCell>
        </TableRow>
        <TableRow key={1}>
          <TableCell component="th" scope="row">Identificación</TableCell>
          <TableCell align="left">{identificacion}</TableCell>
        </TableRow>
        <TableRow key={2}>
          <TableCell component="th" scope="row">Programa</TableCell>
          <TableCell align="left">{programa.nombre}</TableCell>
        </TableRow>
        <TableRow key={3}>
          <TableCell component="th" scope="row">Modalidad</TableCell>
          <TableCell align="left">{tipo.nombre}</TableCell>
        </TableRow>
      </TableBody>
      <TableBody>
        <TableRow key={18}>
          <TableCell component="th" scope="row">Estado Actual</TableCell>
          <TableCell align="left">{estado_actual.nombre}</TableCell>
        </TableRow>
        <TableRow key={19}>
          <TableCell component="th" scope="row">Fecha Registro</TableCell>
          <TableCell align="left">{fecha}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export function RequisitoDetalle({ requisito }) {
  let {
    tipo,
    validacion,
    requisito: { nombre: nombre_requisito },
    fecha_registro,
    fecha_valida,
    nota,
    nota2,
    usuario_registro: { primer_nombre, primer_apellido, segundo_apellido },
    usuario_valida,
    calificacion
  } = requisito;
  let fecha = moment(fecha_registro).format('YYYY-MM-DD, h:mm a');
  let fechav = moment(fecha_valida).format('YYYY-MM-DD, h:mm a');

  const obtenerEstado = (codigo) => {
    const titulos = {
      '0': 'Enviado',
      '1': 'Negado',
      '2': 'Aprobado',
      '3': 'Remplazado',
      '4': 'Pendiente',
    }
    return (titulos[codigo])
  }

  return (
    <Table>
      <TableBody>
        <TableRow key={1}>
          <TableCell component="th" scope="row">Tipo</TableCell>
          <TableCell align="left">{tipo === '1' ? 'Adjunto' : 'Formulario'}</TableCell>
        </TableRow>
        <TableRow key={2}>
          <TableCell component="th" scope="row">Requisito</TableCell>
          <TableCell align="left">{nombre_requisito}</TableCell>
        </TableRow>
      </TableBody>
      <TableBody>
        <TableRow key={9}>
          <TableCell component="th" scope="row">Estado</TableCell>
          <TableCell align="left">{obtenerEstado(validacion)}</TableCell>
        </TableRow>
        <TableRow key={10}>
          <TableCell component="th" scope="row">Registrado por</TableCell>
          <TableCell align="left">{`${primer_nombre} ${primer_apellido} ${segundo_apellido}`}</TableCell>
        </TableRow>
        <TableRow key={11}>
          <TableCell component="th" scope="row">Registrado el</TableCell>
          <TableCell align="left">{fecha}</TableCell>
        </TableRow>
        {
          usuario_valida ?
            <TableRow key={12}>
              <TableCell component="th" scope="row">Verificado por</TableCell>
              <TableCell align="left">{`${usuario_valida.primer_nombre} ${usuario_valida.primer_apellido} ${usuario_valida.segundo_apellido}`}</TableCell>
            </TableRow> : <TableRow key={12}></TableRow>
        }
        {
          fecha_valida ?
            <TableRow key={13}>
              <TableCell component="th" scope="row">Verificado el</TableCell>
              <TableCell align="left">{fechav}</TableCell>
            </TableRow> : <TableRow key={13}></TableRow>
        }

        {
          calificacion ?
            <TableRow key={14}>
              <TableCell component="th" scope="row">Nota</TableCell>
              <TableCell align="left">{calificacion}</TableCell>
            </TableRow> : <TableRow key={14}></TableRow>
        }
        {
          nota ?
            <TableRow key={15}>
              <TableCell component="th" scope="row">Observacion</TableCell>
              <TableCell align="left">{nota}</TableCell>
            </TableRow> : <TableRow key={15}></TableRow>
        }
        {
          nota2 ?
            <TableRow key={16}>
              <TableCell component="th" scope="row">Observacion</TableCell>
              <TableCell align="left">{nota2}</TableCell>
            </TableRow> : <TableRow key={16}></TableRow>
        }
      </TableBody>
    </Table>
  )
}

export function ActaDetalle({ requisito, respuestas, usuario }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  let {
    tipo,
    nombre_archivo,
    cargo_encuesta,
    correo_encuesta,
    fecha_fin_acta,
    observaciones,
    observaciones_acta,
    archivo,
    calificacion
  } = requisito;

  let fechad = moment(fecha_fin_acta).format('YYYY-MM-DD, h:mm a');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const GuardarPasos = () => {
    let pasos = [];
    respuestas.map(({ paso }) => {
      let { id } = paso;
      let existe = pasos.find(({ id: idc }) => idc === id)
      if (!existe) pasos.push(paso);
      return true;
    })
    return pasos;
  }
  const pasos = GuardarPasos();
  return (
    <>
      {
        tipo === '2' ? <>
          <Table>

            <TableBody>
              <TableRow key={3}>
                <TableCell component="th" scope="row">Nombre</TableCell>
                <TableCell align="left">{nombre_archivo}</TableCell>
              </TableRow>
              <TableRow key={4}>
                <TableCell component="th" scope="row">Cargo</TableCell>
                <TableCell align="left">{cargo_encuesta}</TableCell>
              </TableRow>
              <TableRow key={5}>
                <TableCell component="th" scope="row">Correo</TableCell>
                <TableCell align="left">{correo_encuesta}</TableCell>
              </TableRow>
              {
                calificacion ?
                  <TableRow key={14}>
                    <TableCell component="th" scope="row">Nota</TableCell>
                    <TableCell align="left">{calificacion}</TableCell>
                  </TableRow> : <TableRow key={14}></TableRow>
              }
              <TableRow key={6}>
                <TableCell component="th" scope="row">observaciones Inicio</TableCell>
                <TableCell align="left">{observaciones}</TableCell>
              </TableRow>
              <TableRow key={7}>
                <TableCell component="th" scope="row">observaciones Fin</TableCell>
                <TableCell align="left">{observaciones_acta}</TableCell>
              </TableRow>
              <TableRow key={8}>
                <TableCell component="th" scope="row">Diligenciado el</TableCell>
                <TableCell align="left">{fecha_fin_acta && fechad}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <div className={classes.root}>
            { !((usuario.codigo != 'Per_Adm' && usuario.codigo != 'Per_Pra_Adm' && usuario.codigo != 'Estudiante_Pra') && requisito.requisito.codigo == 'req_eval_coord') &&
              pasos.map(({ nombre, descripcion, id }, i) => (
                <ExpansionPanel expanded={expanded === `panel${i}`} onChange={handleChange(`panel${i}`)} key={i}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}  >
                    <Typography className={classes.heading}><span className='step_pers' >{i + 1}</span> {nombre}</Typography>
                    {/* <Typography className={classes.secondaryHeading}>{descripcion}</Typography> */}
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails >
                    <List className={classes.root} style={{ backgroundColor: 'white', borderBottom: '1px solid #eeeeee' }}>
                      {
                        respuestas.map(({ pregunta, respuesta, paso }, j) => paso.id === id && (
                          <ListItem alignItems="flex-start" key={j}>
                            <ListItemText
                              primary={`${j + 1}) ${pregunta.nombre} `}
                              secondary={
                                <React.Fragment>
                                  Respuesta : {respuesta}
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                        ))
                      }
                    </List>

                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))
            }
          </div>
        </> : 
        <DescargarArchivo url={`${archivo}`} nombre_archivo={`${requisito.requisito.nombre}`} calificacion={`${requisito.calificacion}`} />
      }

    </>
  )
}
