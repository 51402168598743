import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { consulta, guardarValorGenerica, modificarValorGenerica, mostrarError, obtenerValoresFiltros, obtenerValoresFiltrosContenido, api, obtenerValores } from "../../../../global/js/funciones";
import { mostrarModalAddRequisitos, mostrarModalModRequisitos, mostrarModalDelRequisitos, mostrarModalDetRequisitos, actualizarDataRequisitos } from '../../../../redux/actions/actInternacionalizacion';
import ValoresAgregar from '../../../genericas/valores/ValoresAgregar';
import RequisitosListar from './RequisitosListar';
import Detalle from '../../../general/Detalle';
import ValoresModificar from '../../../genericas/valores/ValoresModificar';
import ConfirmarAccion from '../../../general/ConfirmarAccion';
import { FORMATOS_REQUISITOS, REQUISITOS } from '../helper';
import { BtnDetalle } from '../../../general/BotonesAccion';

class InternacionalizacionRequisitos extends Component {
  constructor(props){
    super(props);
    this.state = {
      input_requisito: '',
      cargando: true,
      lista_formatos: []
    }
  }

  async componentDidMount() {
    this.obtenerRequisitos()
    let lista_formatos = await obtenerValores(FORMATOS_REQUISITOS);
    this.setState({ lista_formatos });
  }

  guardarRequisito = async (codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, archivo) => {
    let { actualizarMensaje, mostrarModalAddRequisitos } = this.props;
    let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
    let { error, estado, resp } = await guardarValorGenerica(REQUISITOS, codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, archivo)
    if (!error) {
      if (estado === 200) {
        tipo = 'success';
        titulo = resp.titulo;
        mostrarModalAddRequisitos(false);
        this.obtenerRequisitos();
      } else {
        tipo = 'info';
        titulo = resp.titulo ? resp.titulo : mostrarError(resp)
      }
    }
    actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
  }

  modificarRequisito = async (id, codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, archivo) => {
    let { actualizarMensaje, mostrarModalModRequisitos } = this.props;
    let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
    let { error, estado, resp } = await modificarValorGenerica(id, codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, archivo);
    if (!error) {
      if (estado === 200) {
        tipo = 'success';
        titulo = resp.titulo;
        this.obtenerRequisitos();
        mostrarModalModRequisitos(false);
      } else {
        tipo = 'info';
        titulo = resp.titulo ? resp.titulo : mostrarError(resp)
      }
    }
    actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
  }

  obtenerRequisitos = async () => {
    this.setState({ cargando: true });
    let requisitos = []
    requisitos = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': REQUISITOS }, { 'llave': 'estado', 'valor': 1 }]);
    this.props.actualizarDataRequisitos(requisitos);
    this.setState({ cargando: false });
  }

  eliminarRequisito = id => {
    let { actualizarMensaje, mostrarModalDelRequisitos } = this.props;
    consulta(`api/v1.0/valores/${id}/eliminar`, {}, "put",
      (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            this.obtenerRequisitos();
            mostrarModalDelRequisitos(false);
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp)
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  render () {
    let {
      mostrarModalAddRequisitos,
      mostrarModalDetRequisitos,
      mostrarModalModRequisitos,
      mostrarModalDelRequisitos,
      modalAddRequisitos,
      modalDetRequisitos,
      modalModRequisitos,
      modalDelRequisitos,
      actualizarDataRequisito,
      requisitos,
      requisito
    } = this.props;
    let { lista_formatos } = this.state;

    const config = () => {
      return {
        inputCodigo: { 'label': 'Código', 'mostrar': false, 'tipo': 'text', 'requerido': false, 'mayusculas': true },
        inputNombre: { 'label': 'Requisito', 'mostrar': true, 'tipo': 'text', 'requerido': true, 'mayusculas': true },
        inputDescripcion: { 'label': 'Info', 'mostrar': true, 'tipo': 'text', 'requerido': false, 'mayusculas': false },
        inputValora: { 'label': 'Valora', 'mostrar': false, 'tipo': 'text', 'requerido': false, 'mayusculas': false },
        inputValorb: { 'label': 'Valorb', 'mostrar': false, 'tipo': 'text', 'requerido': false, 'mayusculas': false },
        inputValorc: { 'label': 'Valorc', 'mostrar': false, 'tipo': 'text', 'requerido': false, 'mayusculas': false },
        inputValord: { 'label': 'Valord', 'mostrar': false, 'tipo': 'text', 'requerido': false, 'mayusculas': false },
        inputValore: { 'label': 'Valore', 'mostrar': false, 'tipo': 'text', 'requerido': false, 'mayusculas': false },
        inputValorf: { 'label': 'Valorf', 'mostrar': false, 'tipo': 'text', 'requerido': false, 'mayusculas': false },
        inputValorg: { 'label': 'Formatos', 'mostrar': true, 'tipo': 'text', 'requerido': true, 'mayusculas': false, 'select': true, 'lista': lista_formatos },
        inputArchivo: { 'label': 'Archivo', 'mostrar': true, 'tipo': 'file', 'requerido': false },
      }
    }

    const configDetail = data => {
      let { nombre, descripcion, valorg, archivo } = data;
      let ver = archivo && <BtnDetalle callback = { () => window.open(`${archivo}`, '_blank') } color = '#01579b' />
      return (
        [
          { 'label': 'Requisito', 'value': nombre },
          { 'label': 'Info', 'value': descripcion ? descripcion : 'Sin información' },
          { 'label': 'Formatos', 'value': valorg ? valorg : 'Sin formatos' },
          { 'label': 'Adjunto', 'value': archivo ? ver : 'Sin Adjunto' },
        ]
      )
    }

    return (
      <div>
        {/* <InputBuscarText
          onChange = { this.onChange }
          dato_buscar = { input_requisito }
          obtenerData = { this.obtenerRequisitos }
          placeholder = 'Buscar requisito'
          name = 'input_requisito'
        /> */}
        <RequisitosListar
          mostrarModalAddValores = { mostrarModalAddRequisitos }
          mostrarModalDetValores = { mostrarModalDetRequisitos }
          mostrarModalModValores = { mostrarModalModRequisitos }
          mostrarModalDelValores = { mostrarModalDelRequisitos }
          actualizarDataValor = { actualizarDataRequisito }
          requisitos = { requisitos }
        />
        <ValoresAgregar
          mostrarABC = { true }
          modalAddValores = { modalAddRequisitos }
          mostrarModalAddValores = { mostrarModalAddRequisitos }
          guardarValor = { this.guardarRequisito }
          inputs = { config() }
          titulo = '¡ Nuevo Requisito !'
        />
        <Detalle
          titulo = { '¡ Detalle Requisito !' }
          open = { modalDetRequisitos }
          config = { requisito.id !== 0 ? configDetail(requisito) : [] }
          cerrar={ () => mostrarModalDetRequisitos(false) }
        />
        <ValoresModificar
          mostrarABC = { true }
          modalModValores = { modalModRequisitos }
          mostrarModalModValores = { mostrarModalModRequisitos }
          valor = { requisito }
          modificarValor = { this.modificarRequisito }
          inputs = { config() }
          titulo = '¡ Modificar Requisito !'
        />
        <ConfirmarAccion
          mensaje1 = { `El requisito "${requisito.nombre}" será eliminado, ` }
          ejecutarAccion = { this.eliminarRequisito }
          mostrarModalConfAccion = { mostrarModalDelRequisitos }
          modalConAccion = { modalDelRequisitos }
          dato = { requisito }
          titulo = { '¿ Eliminar Requisitos ?' }
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  let { modalAddRequisitos, modalModRequisitos, modalDelRequisitos, modalDetRequisitos, requisitos } = state.redInternacionalizacion;
  return { modalAddRequisitos, modalModRequisitos, modalDelRequisitos, modalDetRequisitos, requisitos }
}

const mapDispatchToProps = { actualizarDataRequisitos, mostrarModalAddRequisitos, mostrarModalModRequisitos, mostrarModalDelRequisitos, mostrarModalDetRequisitos }

InternacionalizacionRequisitos.propTypes = {
  // variables
  requisitos: PropTypes.array.isRequired,
  modalAddRequisitos: PropTypes.bool.isRequired,
  modalModRequisitos: PropTypes.bool.isRequired,
  modalDelRequisitos: PropTypes.bool.isRequired,
  modalDetRequisitos: PropTypes.bool.isRequired,
  // funciones
  actualizarDataRequisitos: PropTypes.func.isRequired,
  mostrarModalAddRequisitos: PropTypes.func.isRequired,
  mostrarModalModRequisitos: PropTypes.func.isRequired,
  mostrarModalDelRequisitos: PropTypes.func.isRequired,
  mostrarModalDetRequisitos: PropTypes.func.isRequired,
}

export default connect (mapStateToProps, mapDispatchToProps)(InternacionalizacionRequisitos);