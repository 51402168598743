import React, { Component } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, Paper, InputBase, Divider, IconButton } from '@material-ui/core';
import AppBarModal from '../general/AppBarModal'
import { BtnForm } from "../general/BotonesAccion";
import { obtenerValoresFiltrosContenido, obtenerPermisosValorAltContenido } from "../../global/js/funciones";
import SearchIcon from '@material-ui/icons/Search';
import { BtnSeleccion } from "../general/BotonesAccion";
import ListarDatos from "../general/ListarDatos";
import TareasTerminadas from "../general/TareasTerminadas";
import emma_w from '../../global/imagenes/emma_w.png';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: "100%",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));


function Buscar({ onChange, empresa, obtenerEmpresas }) {
  const classes = useStyles();

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      obtenerEmpresas(empresa);
      e.preventDefault();
    }
  }

  return (
    <Paper component="form" className={classes.root}>
      <IconButton className={classes.iconButton} >
        <SearchIcon />
      </IconButton>
      <InputBase
        name='empresa'
        value={empresa}
        onChange={onChange}
        className={classes.input}
        placeholder="Ingrese nombre de la empresa"
        inputProps={{ 'aria-label': 'search google maps' }}
        onKeyPress={handleKeyPress}
      />
      <Divider className={classes.divider} orientation="vertical" />
      <Button variant="contained" color="primary" style={{ color: 'white' }} onClick={() => obtenerEmpresas(empresa)}>Buscar</Button>
    </Paper>
  );
}

class PracticasBuscarEmpresa extends Component {

  constructor(props) {
    super(props)
    this.state = {
      cargando: false,
      empresa: '',
      empresas: [],
    }
  }

  componentDidUpdate({ modalBuscarEmpresa }) {
    let { modalBuscarEmpresa: modalIniActaNew } = this.props;
    if (modalIniActaNew !== modalBuscarEmpresa) this.setState({ empresa: '', empresas: [], })

  }

  obtenerEmpresas = async nombre => {
    this.setState({ cargando: true });
    let empresas = [];
    if (this.props.busqueda === 1) empresas = await obtenerValoresFiltrosContenido(nombre, [{ 'llave': 'generica', 'valor': 14 }]);
    else empresas = await obtenerPermisosValorAltContenido(nombre, [{ 'llave': 'principal__generica', 'valor': 14 }]);
    this.setState({ empresas, cargando: false });

  }

  onSubmit = e => {
    let { nombre, cargo, correo, observaciones } = this.state;
    this.props.enviarActa({ nombre_archivo: nombre, cargo_encuesta: cargo, correo_encuesta: correo, observaciones });
    e.preventDefault();
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }


  acciones = (data) => {
    const onClickSeleccionar = (data) => {
      this.props.seleccionar(data)
    }
    return <BtnSeleccion callback={() => onClickSeleccionar(data)} />;
  }


  render() {
    let {
      empresas,
      empresa,
      cargando
    } = this.state;

    let { modalBuscarEmpresa, mostrarModalBuscarEmpresa } = this.props;

    const filas = () => {
      if (this.props.busqueda === 1) {
        return [
          { 'id': 'codigo' },
          { 'id': 'descripcion', 'nombre': 'actividad económica : ', 'enLista': false },
        ]
      } else {
        return [
          { 'id': 'codigo', 'mostrar': ({ principal }) => `${principal.codigo}`, 'nombre': 'NIT : ' },
          { 'id': 'sucursal', 'mostrar': ({ secundario }) => `${secundario.nombre}`, 'nombre': 'Sucursal : ' },
          { 'id': 'correo', 'mostrar': ({ secundario }) => `${secundario.valorf}`, 'nombre': 'Correo Surcusal : ' },
        ]
      }
    }

    return (
      <Dialog open={modalBuscarEmpresa} onClose={mostrarModalBuscarEmpresa.bind(this, false)} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="sm">
        <AppBarModal titulo={'¡ Buscar Empresa !'} mostrarModal={mostrarModalBuscarEmpresa} titulo_accion="" accion="" />
        <Buscar onChange={this.onChange} empresa={empresa} obtenerEmpresas={this.obtenerEmpresas} />
        <DialogContent className='scroll' style={{ padding: 0 }}>
          {
            cargando ? <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" /> :
              <ListarDatos
                datos={empresas}
                titulo='Lista Empresas'
                opciones={true}
                buscar={false}
                sub_titulo='Empresas'
                id='tbl_empresas_seleccion'
                acciones={row => this.acciones(row)}
                fila_principal={this.props.busqueda === 1 ? 'nombre' : ({ principal }) => `${principal.nombre}`}
                filas={filas()}
              />
          }
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CERRAR" callback={() => mostrarModalBuscarEmpresa(false)} />
        </DialogActions>
      </Dialog>
    )
  }
}

//Props del componente
PracticasBuscarEmpresa.propTypes = {
  //variables
  modalBuscarEmpresa: PropTypes.bool.isRequired,
  mostrarModalBuscarEmpresa: PropTypes.func.isRequired,
}

PracticasBuscarEmpresa.defaultProps = {
  busqueda: 1,
};



export default PracticasBuscarEmpresa;

