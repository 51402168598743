import React from 'react'
import PropTypes from 'prop-types';
import { BtnSeleccion } from "./BotonesAccion";
import ListarDatos from './ListarDatos';
import { rutaImg, consulta } from "../../global/js/funciones";

class BuscarPersona extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      dato_buscar: '',
      cargando: false,
      personas: [],
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.dato_buscar !== prevState.dato_buscar) {
      if (this.props.persona_inter) {
        this.obtenerPersonasInter(this.state.dato_buscar);
      } else {
        this.obtenerPersonas(this.state.dato_buscar, this.props.buscarEstudiante, this.props.buscarTipo);
      }
    }
  }
  
  obtenerPersonas = (dato, es_estudiante, tipo) => {
    this.setState({ cargando: true })
    if (dato.length > 5) {
      consulta("api/v1.0/personas/buscar", { dato, es_estudiante, tipo }, "post",
        (error, estado, resp) => {
          if (estado === 200) {
            this.setState({ cargando: false, personas: resp })
          }
        }
      );
    } this.setState({ cargando: false, personas: [] });
  }

  obtenerPersonasInter = (dato) => {
    this.setState({ cargando: true })
    if (dato.length > 5) {
      consulta("api/v1.0/internacionalizacion/buscar/persona", { dato }, "post",
        (error, estado, resp) => {
          if (estado === 200) {
            this.setState({ cargando: false, personas: resp })
          }
        }
      );
    } this.setState({ cargando: false, personas: [] });
  }

  render() {
    const actions = (rowData) => {
      let { callback, seleccionarVarios, listaSeleccionados } = this.props;
      let mostrarCheck = true;
      const seleccionar = (data) => {
        callback(data);
      }
      if (seleccionarVarios) mostrarCheck = !listaSeleccionados.some(({ identificacion }) => identificacion == rowData.identificacion); // Retorna false si encuentra la identificación en la lista
      let resp = mostrarCheck ? <BtnSeleccion callback={() => seleccionar(rowData)} /> : '';
      return resp;
    }

    let { dato_buscar, personas, cargando } = this.state;
    let { mensajeVacio } = this.props;
    return (
      <div>
        <ListarDatos
          id="tbl_listar_personas_b"
          titulo={"Lista Personas"}
          datos={personas}
          opciones={true}
          buscar={true}
          cargando={cargando}
          mensajeVacio={mensajeVacio ? mensajeVacio : 'Esta bandeja esta vacía, ingrese dato a buscar'}
          avatarImg={true}
          css={{
            list: { padding: '0px 0px 20px 0px' },
            appBar: { padding: '0px 0px 0px 0px' },
          }}
          actBuscar={(dato_buscar) => this.setState({ dato_buscar })}
          datoBuscarAlt={dato_buscar}
          avatar={({ identificacion }) => `${rutaImg}${identificacion}.jpg`}
          acciones={(row) => actions(row)}
          fila_principal={({ primer_nombre, segundo_nombre, primer_apellido, segundo_apellido }) => `${primer_nombre} ${segundo_nombre} ${primer_apellido} ${segundo_apellido}`.toUpperCase()}
          filas={[
            { 'id': 'correo', 'enLista': true },
            { 'id': 'identificacion', 'enLista': true },
          ]}

        />
      </div>
    );
  }

}

//Props del componente
BuscarPersona.propTypes = {
  callback: PropTypes.func.isRequired,
  seleccionarVarios: PropTypes.bool,
  buscarEstudiante: PropTypes.string,
  buscarTipo: PropTypes.string
}

BuscarPersona.defaultProps = {
  seleccionarVarios: false,
  buscarEstudiante: '',
  buscarTipo: ''
}


export default BuscarPersona;


