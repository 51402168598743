import React, { Component } from "react";
import ListarDatos from "../../general/ListarDatos";
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import AppBarModal from "../../general/AppBarModal";
import {
  BtnEditar,
  BtnEliminar,
  BtnForm,
  BtnSeleccion,
} from "../../general/BotonesAccion";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  consulta,
  crear_form_data,
  formulario,
  mostrarError,
  obtenerValoresFiltros,
} from "../../../global/js/funciones";
import moment from "moment/moment";
import ConfirmarAccion from "../../general/ConfirmarAccion";
import { ESTRATEGIA } from "../helper";
import emma_w from "./../../../global/imagenes/emma_w.png";
import TareasTerminadas from "../../general/TareasTerminadas";

class ListarActividades extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seleccion: "inicio",
      actividades: [],
      tipos_estrategias: [],
      actividad_selec: {},
      actividad: {},
      estrategia: {},
      tipo: {},
      fecha: null,
      agregrar: false,
      eliminar: false,
      modificar: false,
      cargando:false, 
    };
  }

  async componentDidMount() {
    this.DatosIniciales();
  }

  async componentDidUpdate({}) {}

  async DatosIniciales() {
    let estrategias = await obtenerValoresFiltros([
      { llave: "generica", valor: ESTRATEGIA },
      { llave: "estado", valor: "1" },
      { llave: "valorb", valor: "1" },
    ]);
    this.setState({ cargando: false, tipos_estrategias: estrategias });
  }

  async obtenerActividadesFiltro(grupo, periodo, indicador) {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/sisef/listar_actividades/${grupo}/${indicador}/${periodo}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: { valor: value },
    });
  };

  Errores = (name, error) => {
    this.setState({
      [name]: { valor: error },
    });
  };

  eliminarActividad = () => {
    let { actividad_selec } = this.state;
    let { actualizarMensaje, periodo, CargarActividades } = this.props;
    let id = actividad_selec.id;
    consulta(
      `api/v1.0/sisef/${id}/eliminar_actividad`,
      {},
      "put",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            CargarActividades(periodo, actividad_selec.asignatura_codigo);
            this.setState({ eliminar: false });
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  };

  acciones(rowData) {
    let { listar_estudiantes, asignatura, id_corte } = this.props;
    const Editar = (row) => {
      let { actividad, fecha, tipo, estrategia } = this.state;
      actividad.valor = row.actividad;
      tipo.valor = row.tipo_estrategia.id;
      estrategia.valor = row.estrategia;
      fecha = row.fecha;
      this.setState({
        actividad_selec: row,
        actividad,
        fecha,
        tipo,
        estrategia,
        modificar: true,
      });
    };

    const Eliminar = (row) => {
      this.setState({ eliminar: true, actividad_selec: row });
    };
    return (
      <div>
        {" "}
        <BtnSeleccion
          texto="Valorar"
          callback={() => listar_estudiantes(asignatura, id_corte, rowData)}
        />
        <BtnEditar callback={() => Editar(rowData)} />
        <BtnEliminar callback={() => Eliminar(rowData)} />
      </div>
    );
  }

  render() {
    let {
      eliminar,
      tipos_estrategias,
      modificar,
      actividad_selec,
      actividad,
      estrategia,
      tipo,
      fecha,
    } = this.state;
    let {
      periodo,
      actualizarMensaje,
      actividades_data,
      SetModalFiltro,
      RefrescarActividades,
      asignatura,
      CargarActividades,
    } = this.props;
    return (
      <>
      
           
        <ListarDatos
          id="tbl_actividades"
          datos={actividades_data}
          opciones={true}
          titulo={"Actividades"}
          filtrar={true}
          refrescar={true}
          actRefrescar={() => RefrescarActividades()}
          actfiltrar={() => SetModalFiltro(true)}
          acciones={(row) => this.acciones(row)}
          buscar={true}
          avatar={({ actividad }) => actividad.toUpperCase().charAt(0)}
          color_avatar={() => ({
            backgroundColor: "#6200ea",
            color: "white",
          })}
          fila_principal={({ actividad }) => `${actividad}`}
          filas={[
            { mostrar: ({ fecha }) => `${fecha}`, id: "fecha", enLista: true },
            {
              mostrar: ({ tipo_estrategia }) => `${tipo_estrategia.nombre}`,
              id: "fecha",
              enLista: true,
            },
            {
              mostrar: ({ estrategia }) => `${estrategia}`,
              id: "fecha",
              enLista: true,
            },
          ]} 
        />
        {/* } */}
        {modificar ? (
          <ModificarActividad
            Modal={modificar}
            CerrarModal={(resp) => this.setState({ modificar: resp })}
            onChange={this.onChange}
            Errores={this.Errores}
            actividad={actividad}
            estrategia={estrategia}
            tipo={tipo}
            asignatura={asignatura}
            periodo={periodo}
            tipos_estrategias={tipos_estrategias}
            fecha={fecha}
            newFecha={(resp) => this.setState({ fecha: resp })}
            actividad_selec={actividad_selec}
            actualizarMensaje={actualizarMensaje}
            CargarActividades={CargarActividades}
          />
        ) : (
          ""
        )}
        <ConfirmarAccion
          titulo="¿ Eliminar Actividad ?"
          dato={{ id: 0 }}
          mensaje1="¿Estás seguro(a) que deseas eliminar la actividad?"
          modalConAccion={eliminar}
          mostrarModalConfAccion={() => this.setState({ eliminar: false })}
          ejecutarAccion={this.eliminarActividad}
        />
      </>
    );
  }
}

class ModificarActividad extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errores: {},
    };
  }

  onSubmit = (e) => {
    let { actividad, fecha, estrategia, tipo } = this.props;
    let { errores, cargando } = this.state;
    let obligatorio = "Este campo es obligatorio";
    let error = false;
    if (Object.entries(actividad).length == 0 || actividad.valor == "") {
      errores.actividad = obligatorio;
      error = true;
    }
    if (Object.entries(estrategia).length == 0 || estrategia.valor == "") {
      errores.estrategia = obligatorio;
      error = true;
    }
    if (Object.entries(tipo).length == 0 || tipo.valor == "") {
      errores.tipo = obligatorio;
      error = true;
    }
    if (fecha == null) {
      errores.fecha = obligatorio;
      error = true;
    }
    if (error) {
      this.setState({ errores });
    } else this.enviar();
    this.setState({ cargando: true, titulo_res: "Enviando.." });

  };

  enviar() {
    let { actividad, fecha, tipo, estrategia, actividad_selec } = this.props;
    this.setState({ cargando: true, titulo_res: "Enviando.." });
    let data = {
      actividad: actividad.valor,
      fecha: moment(fecha).format("YYYY-MM-DD"),
      tipo_estrategia: tipo.valor,
      estrategia: estrategia.valor,
    };
    this.Guardar(data, actividad_selec.id);
  }

  Guardar = async (data, id) => {
    let {
      CerrarModal,
      actualizarMensaje,
      asignatura,
      periodo,
      CargarActividades,
      actividad_selec,
    } = this.props;
    let form = await crear_form_data(data);
    formulario(
      `api/v1.0/sisef/${id}/modificar_actividad`,
      form,
      "patch",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            CargarActividades(periodo, actividad_selec.asignatura_codigo);
            CerrarModal(false);
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  };

  getError(valor) {
    return valor ? true : false;
  }

  setFecha = (fecha) => {
    let { newFecha } = this.props;
    newFecha(fecha);
    this.setState({ error_fecha: "" });
  };

  render() {
    let { errores, cargando } = this.state;
    let {
      Modal,
      CerrarModal,
      tipos_estrategias,
      tipo,
      estrategia,
      fecha,
      actividad,
      onChange,
    } = this.props;
    return (
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={Modal}
        onClose={() => CerrarModal(false)}
        aria-labelledby="form-dialog-title"
      >
        <AppBarModal
          titulo="Modificar Actividad"
          mostrarModal={() => CerrarModal(false)}
          titulo_accion="Modificar Actividad"
          accion=""
        />
        <DialogContent className="scroll">
        {!cargando?
            
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl className="form-control">
                <TextField
                  id="actividad"
                  type="text"
                  name="actividad"
                  label="Actividad"
                  valor={actividad.valor}
                  defaultValue={actividad.valor}
                  onChange={onChange}
                  error={this.getError(errores.actividad)}
                  helperText={errores.actividad}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container>
                  <KeyboardDatePicker
                    required={true}
                    disableToolbar
                    format="dd-MM-yyyy"
                    margin="normal"
                    id="fecha"
                    label="Fecha"
                    value={fecha}
                    style={{ width: "100%" }}
                    onChange={this.setFecha}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    invalidDateMessage="Formato Invalido"
                  />
                </Grid>
              </MuiPickersUtilsProvider>
              {errores.fecha && (
                <FormHelperText error>{errores.fecha}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl
                className="form-control"
                error={this.getError(errores.tipo)}
              >
                <InputLabel htmlFor="parametro">Tipo de estrategia</InputLabel>
                <Select
                  id="tipo"
                  value={tipo.valor}
                  onChange={onChange}
                  name="tipo"
                >
                  {tipos_estrategias?.map(({ nombre, id }) => (
                    <MenuItem key={id} value={id}>
                      {`${nombre}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl className="form-control">
                <TextField
                  id="estrategia"
                  type="text"
                  name="estrategia"
                  label="Descripción de la actividad  "
                  valor={estrategia.valor}
                  defaultValue={estrategia.valor}
                  onChange={onChange}
                  error={this.getError(errores.estrategia)}
                  helperText={errores.estrategia}
                />
              </FormControl>
            </Grid>
          </Grid>
          : <TareasTerminadas
          mensaje='Cargando..'
          marginTop='7%' 
          imagen={emma_w} 
          widthImg="7%" />
                    }
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CANCELAR" callback={() => CerrarModal(false)} />
          <BtnForm texto="GUARDAR" callback={this.onSubmit} />
        </DialogActions>
      </Dialog>
    );
  }
}

export default ListarActividades;
