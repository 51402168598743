import React, { Component } from 'react'
import { Dialog, DialogContent, DialogActions } from '@material-ui/core';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import GetAppIcon from '@material-ui/icons/GetApp';
import AppBarModal from '../general/AppBarModal';
import { BtnForm } from '../general/BotonesAccion';
import { crear_form_data, obtenerIdiomaTabla, formulario } from '../../global/js/funciones'
import MaterialDatatable from "material-datatable";
import TareasTerminadas from "./../general/TareasTerminadas";
import emma_w from './../../global/imagenes/emma_w.png';
import moment from 'moment'
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


function ExportarReporte({ data, nombre, columnas }) {
   return (
      <ExcelFile filename={`${nombre}-${moment().format('YYYY-MM-DD, h:mm a')}`} element={<Tooltip title='Descargar Reporte' aria-label="add" ><IconButton edge="end" color="secondary"><GetAppIcon/></IconButton></Tooltip>}>
         <ExcelSheet data={data} name="Datos">
            {columnas.map(({ field, name }, i) => <ExcelColumn key={i} label={name} value={field} />)}
         </ExcelSheet>
      </ExcelFile>
   )
}

class Reporte extends Component {
   constructor(props) {
      super(props)
      this.state = {
         reporte: [],
         cargando: false,
         
      };
   }

   async componentDidUpdate({ modalReporte }) {
      let { modalReporte : modalReporteNew} = this.props;
      if (modalReporte !== modalReporteNew) {
         if (modalReporteNew) this.obtenerReporte(); 
         else this.setState({reporte: []})
      }
    }

    async obtenerReporte() {
      let { filtros, seleccion, actualizarMensaje } = this.props
      filtros.codigo = seleccion
      let data = await crear_form_data(filtros)

      this.setState({ cargando: true });
      formulario(`api/v1.0/reportes/obtener`, data, "post", (error, estado, resp) => {
         if (estado === 200) this.setState({ cargando: false, reporte: resp });
         else actualizarMensaje({ titulo: 'Ha ocurrido un error, contacte con el administrador.', tipo: 'info', mostrar: true, tiempo: 6000 });
      })
   }

   pintar = () => {
      let { reporte } = this.state
      let { tipo, seleccion } = this.props
      let columnas = []

      let llaves_reporte = reporte[0] ? Object.keys(reporte[0]) : []
      llaves_reporte.map((nombre) =>
         columnas.push({
            field: nombre,
            name: nombre,
            options: {
               filter: false,
               headerNoWrap: true
            }
         })
      )
 
      const options = {
         search: false,
         filter: false,
         print: false,
         download: false,
         viewColumns: false,
         responsive: "scroll",
         usePaperPlaceholder: false,
         selectableRows: false,
         sort: false,
         customToolbar: () => {
            return (
               <ExportarReporte data={reporte} columnas={columnas} nombre={tipo} />
            )
         }
      };

      return (
         <MaterialDatatable
            title={tipo}
            data={reporte}
            columns={columnas}
            options={Object.assign(options, obtenerIdiomaTabla())}
         />
      );
   }

   render() {
      let { cargando } = this.state;
      let { modalReporte, mostrarModalReporte } = this.props

      return (
            <Dialog fullWidth={true} maxWidth="lg" open={modalReporte} onClose={() => mostrarModalReporte(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <AppBarModal titulo={'Reporte'} mostrarModal={() => mostrarModalReporte(false)} titulo_accion="" accion="" />
            <DialogContent style={{ padding: 0 }} className='scroll' >
               {!cargando ? this.pintar() : <TareasTerminadas mensaje='Cargando, esto puede tomar unos minutos. Por favor no recargar la pagina hasta obtener el resultado.' marginTop='7%' imagen={emma_w} widthImg="7%" cargando={true}/>} 
            </DialogContent>
            <DialogActions>
               <BtnForm texto="Cerrar" callback={() => mostrarModalReporte(false)} />
            </DialogActions>
         </Dialog>

      );
   }
}

export default Reporte