import React, { Component } from 'react';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import Generica from '../../personas/generica/Generica';
import AppBarModal from '../../general/AppBarModal';
import { BtnDetalle, BtnPermiso, BtnForm, BtnDepartamento, BtnGestionar } from '../../general/BotonesAccion';
import ListarDatos from '../../general/ListarDatos';
import TareasTerminadas from '../../general/TareasTerminadas';
import emma_w from '../../../global/imagenes/emma_w.png';
import { consulta, rutaImg } from '../../../global/js/funciones';
import { PROCESOS, ESTADOS_ID, PROGRAMAS } from './helper';
import ModalProgramas from './ModalProgramas';
class MatriculaPermisos extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cargando: true,
			personas: [],
			generica: {
				tipo: 0,
				id: 0
			},
			modal: false,
			id_persona: null,
			seleccion: 'gestores',
			materias: [],
			programas: [],
			programa: null,
			modalMaterias: false
		};
	}

	async componentDidMount() {
		let personas = await this.obtenerGestores();
		this.setState({ personas, cargando: false });
	}

	acciones(data) {
		const onClickTipo = (data) => this.setState({ id_persona: data.id, modal: true, generica: { tipo: 9, id: PROCESOS } });
		const onClickEstado = (data) => this.setState({ id_persona: data.id, modal: true, generica: { tipo: 8, id: ESTADOS_ID } });
		const onClickEstadoSiguiente = (data) => this.setState({ id_persona: data.id, modal: true, generica: { tipo: 21, id: ESTADOS_ID } });
		const onClickPrograma = (data) => this.setState({ id_persona: data.id, modal: true, generica: { tipo: 13, id: PROGRAMAS } });

		let tipos = <BtnGestionar callback={() => onClickTipo(data)} texto='Tipo Solicitud' color="343434" />;
		let programas = <BtnDetalle callback={() => onClickPrograma(data)} texto='Programas' color='#2196F3' />;
		let estado_siguiente = <BtnDetalle callback={() => onClickEstadoSiguiente(data)} texto='Estado siguieente' color='#000' />;
		let estados = <BtnPermiso callback={() => onClickEstado(data)} texto='Estados' />;
		return <div>{tipos} {programas} {estados} {estado_siguiente}</div>
	  }

	obtenerGestores() {
		return new Promise((resolve) => {
			consulta(`api/v1.0/matriculas/gestores`, null, null, (error, estado, resp) => {
				resolve(estado === 200 && !error ? resp : null);
			});
		});
	}

	cambiarEstado = (estado) => {
		this.setState(estado);
	};

	render() {
		const { modal, id_persona, generica, cargando, personas, seleccion } = this.state;
		if (cargando) return <TareasTerminadas mensaje="Cargando.." marginTop="7%" imagen={emma_w} widthImg="7%" />;
		return (
			<>
				{seleccion === 'gestores' && (
					<ListarDatos
						datos={personas}
						titulo="Lista Gestores"
						buscar={true}
						agregar={false}
						opciones={true}
						descargar="EMMA - GESTORES"
						id="tbl_coordinadores"
						acciones={(row) => this.acciones(row)}
						avatarImg={true}
						avatar={({ identificacion }) => `${rutaImg}${identificacion}.jpg`}
						fila_principal={({ primer_nombre, primer_apellido, segundo_apellido }) =>
							`${primer_nombre} ${primer_apellido} ${segundo_apellido}`}
						filas={[ { id: 'correo' }, { id: 'identificacion' } ]}
					/>
				)}

				<Dialog open={modal} fullWidth={true} maxWidth="sm" onClose={() => this.setState({ modal: false })}>
					<AppBarModal
						titulo="¡ Lista Permisos !"
						mostrarModal={() => this.setState({ modal: false })}
						accion={() => this.setState({ modal: false })}
						titulo_accion="CERRAR"
					/>
					<DialogContent style={{ padding: 0 }} className="scroll">
						<Generica
							notificaciones={(generica.tipo == 8 || generica.tipo == 13) ? true : false }
							tipo={generica.tipo}
							persona={{ id: id_persona }}
							genericaRelacionId={generica.id}
							titulo="¡ Nuevo Permiso !"
							tituloInput="Permisos"
							tituloEliminar="¿ Eliminar Permiso ?"
						/>
					</DialogContent>
					<DialogActions>
						<BtnForm texto="Cerrar" callback={() => this.setState({ modal: false })} />
					</DialogActions>
				</Dialog>
				<ModalProgramas persona={id_persona} />
			</>
		);
	}
}

export default MatriculaPermisos;
