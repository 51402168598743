import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


export default function PersonaDetalle({ persona }) {
  let { usuario, login_directorio, identificacion, tipo_identificacion, primer_nombre, segundo_nombre, primer_apellido, segundo_apellido, correo, correo_personal, telefono, indicativo_celular, celular, tipo } = persona;
  console.log(indicativo_celular);
  return (
    <Paper square>
      <Table >
        <TableBody>
          <TableRow key={1}>
            <TableCell component="th" scope="row">Tipo Persona</TableCell>
            <TableCell align="left">{tipo == "1" ? 'PERSONA INTERNA' : 'PERSONA EXTERNA'}</TableCell>
          </TableRow>
          <TableRow key={2}>
            <TableCell component="th" scope="row">Nombre Completo</TableCell>
            <TableCell align="left">{`${primer_nombre} ${segundo_nombre} ${primer_apellido} ${segundo_apellido}`}</TableCell>
          </TableRow>
          <TableRow key={3}>
            <TableCell component="th" scope="row">Correo</TableCell>
            <TableCell align="left">{correo}</TableCell>
          </TableRow>
          <TableRow key={4}>
            <TableCell component="th" scope="row">Tipo Identificación</TableCell>
            <TableCell align="left">{tipo_identificacion.nombre}</TableCell>
          </TableRow>
          <TableRow key={5}>
            <TableCell component="th" scope="row">Identificación</TableCell>
            <TableCell align="left">{identificacion}</TableCell>
          </TableRow>
          <TableRow key={6}>
            <TableCell component="th" scope="row">Usuario</TableCell>
            <TableCell align="left">{usuario}</TableCell>
          </TableRow>
          { celular === null ? null : /* only shows if celular is different from null */
          <TableRow key={7}>
            <TableCell component="th" scope="row">Celular</TableCell>
            { indicativo_celular === null ?
              <TableCell align="left">{celular}</TableCell> :
              <TableCell align="left">+{indicativo_celular} {celular}</TableCell>
            }
          </TableRow>
          }
          { telefono === null ? null : /* only shows if telefono is different from null */
          <TableRow key={8}>
            <TableCell component="th" scope="row">Telefono</TableCell>
            <TableCell align="left">{telefono}</TableCell>
          </TableRow>
          }
          { correo_personal === null ? null : /* only shows if correo personal is different from null */
          <TableRow key={9}>
            <TableCell component="th" scope="row">Correo Personal</TableCell>
            <TableCell align="left">{correo_personal}</TableCell>
          </TableRow>
          }
          <TableRow key={10}>
            <TableCell component="th" scope="row">Tipo Autenticación</TableCell>
            <TableCell align="left">{login_directorio ? 'Directorio Activo' : 'Aplicación'}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
}

PersonaDetalle.propTypes = {
  //variables
  persona: PropTypes.shape({
    id: PropTypes.number.isRequired,
    tipo_identificacion: PropTypes.object.isRequired,
    identificacion: PropTypes.number.isRequired,
    primer_nombre: PropTypes.string.isRequired,
    segundo_nombre: PropTypes.string,
    primer_apellido: PropTypes.string.isRequired,
    segundo_apellido: PropTypes.string,
    correo: PropTypes.string.isRequired,
    usuario: PropTypes.string.isRequired,
  }),
}
