
//Importaciones necesaria para poder utilizar react
import React, { useState } from 'react'
import { Paper, Grid, LinearProgress } from '@material-ui/core'
import AccionesRapidas from '../../general/AccionesRapidas';
import TituloAccion from '../../general/TituloAccion';
import reportIcon from '../../../global/imagenes/report.png';
import Admininforme from "../HijosAdmin/AdminInforme"

const InformeSemillerosTutores = (props) => {
    const [seleccion, setSeleccion] = useState('inicio')

    const PintarInicio = (props) =>{
        return(
            <div style={{ padding: '2% 2% 0% 2%' }} >
                <Grid container direction={'row'} spacing={1} justify={'center'} >
                    <Grid item xs={12} sm={12} md={10} lg={8} >
                        <TituloAccion principal='' alterno='Aqui Puede Descargar la informacion de sus Semilleros' />
                        <Paper elevation={0} square className="scroll contenido_fijo">
                            <Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
                                <Grid item xs={12} sm={12} md={6} lg={6} >
                                    <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => setSeleccion('informe')}>
                                        <AccionesRapidas imagen={reportIcon} titulo={`¡ Informes !`} sub_titulo='' des_sub_titulo='Descargar Informacion de los semillero' />
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        )
    }

    const pintar = () =>{
        if(seleccion == 'inicio'){
            return (
                <PintarInicio/> 
            )
        }else if(seleccion == 'informe'){
            return (
                <Admininforme
                handleClicki={handleClicki}
                />
            )
        }
    }

    const handleClicki = () => {
        setSeleccion('inicio')
    }

    return(
        <div>
            
            {pintar()}
        
        </div>    
    )
}

export default InformeSemillerosTutores 