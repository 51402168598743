//Importaciones necesaria para poder utilizar react
import React, { Fragment, useState }  from 'react'
//Importaciones necesarias para que el modal del formulario de creacion de semilleros se pueda abrir, este es el stepper.
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

//Importe de los text faild para los formularios de los campos.
import TextField from '@material-ui/core/TextField';

//Importe de los grid para los textfield de los formularios entre otros aspecto.
import Grid from '@material-ui/core/Grid';

//Iconos:
import IconButton from '@material-ui/core/IconButton';
//Añadir
import AddIcon from '@material-ui/icons/Add';
//Editar
import EditIcon from '@material-ui/icons/Edit';
//Eliminar
import DeleteIcon from '@material-ui/icons/Delete';

//AppBarModal header de los modales
import SemillerosAddObj from '../Hijos/SemillerosAddObj'
import SemillerosAddDepPro from '../Hijos/SemillerosAddDepPro'
import SemillerosAddLinea from '../Hijos/SemillerosAddLinea'

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { createMuiTheme } from '@material-ui/core/styles';
//MenuItem
import MenuItem from '@material-ui/core/MenuItem';

//Importo las funciones para hacer peticiones
import * as Funciones from '../../../global/js/funciones';
import TareasTerminadas from '../../general/TareasTerminadas';
import emma_w from '../../../global/imagenes/emma_w.png';
//Constantes del Helper
import * as Helper from './Helper';

//Listar datos para el Form3
import ListarDatos from '../../general/ListarDatos';
//Importo el modal para agregar responsables
import SemillerosAddRes from '../Hijos/SemillerosAddRes';
import { BtnEliminar } from "../../general/BotonesAccion";

import FormHelperText from '@material-ui/core/FormHelperText';

import SemillerosEditObj from '../Hijos/SemillerosEditObj';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment'
import { useDispatch } from 'react-redux';
import { actualizarMensaje } from '../../../redux/actions/actGlobal';
import SemillerosAddGrupos from './SemillerosAddGrupos';
//Breackpoints
const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 380,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
})

//Estilos para el Stepper necesarios para funcionar.
const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    colorWhite : {
        color: '#fff'
    },
    flexGrow: {
        flexGrow: 1,
    },
    flexCenter:{
        textAlign: "center",
        marginTop: "5px"
    },
    formControl: {
      margin: "0",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    colorEmmma: {
      color: "#900807",
      transition: 'all 0.3s',
      '&:hover': {
        color: "#fff",
        backgroundColor: "#900807",
        transform: "scale(1.3)",
      },
    },
    colorDelete: {
      color: "#d11a2a",
      transition: "all 0.3s",
      '&:hover': {
        color: "#fff",
        backgroundColor: "#d11a2a",
        transform: "scale(1.3)",
      },
    },
    colorEdit: {
      color: "#0074C5",
      transition: "all 0.3s",
      '&:hover': {
        color: "#fff",
        backgroundColor: "#0074C5",
        transform: "scale(1.3)",
      },
    }
}));
//Codigo del Stepper del MaterialUI
export default function SemilleroActualizarForms(props) {

    const classes = useStyles();

    //useEffect para consumir las apis cuando cargue el componente.
    React.useEffect( () => {
      // obtenerGrupos()
      const getData = async () => {
        await obtenerAreas()
        await obtenerRoles()
        await getDataSemillero()
      }
      getData()
    }, [])

    //Obtengo los departamentos con la funcion obtenerValores de funciones.js
    const obtenerGrupos = async () => {
      try{
        
        const data = await Funciones.obtenerValores(Helper.SEMI_GRUPOS_INVES)
        props.setGrupoInve(data)

      }catch(err){
        console.log(err)
      } 
      
    }

    //Obtengo las areas con la funcion obtenerValores de funciones.js
    const obtenerAreas = async () => {
      try{
        const data2 = await Funciones.obtenerValores(Helper.SEMI_AREAS_INVES)
        props.setAreas(data2)

      }catch(err){
        console.log(err)
      } 
      
    }

    //Obtengo los roles de disponibles para los lideres
    const obtenerRoles = async () => {
      const data = await Funciones.obtenerValores(Helper.SEMI_ROLES)
      props.setRol(data)
    }
    
    //Obtengo toda la informacion para el formulario del semillero.
    const getDataSemillero = async () => {
        const data = await obtenerSemillerosSolicitudes()
        console.log(data.semillero)
        //Form1
        if(data.semillero[0].nombre != null){
            props.setNameSemi(data.semillero[0].nombre) 
        }
        if(data.semillero[0].mision != null){
            props.setMisionSemi(data.semillero[0].mision)
        }
        if(data.semillero[0].vision != null){
            props.setVisionSemi(data.semillero[0].vision)
        }
        if(data.semillero[0].sigla != null){
            props.setSiglaSemi(data.semillero[0].sigla)
        }
        if(data.semillero[0].objetivo_general != null){
            props.setObjGenSemi(data.semillero[0].objetivo_general)
        }
        if(data.objetivos_especificos.length != 0){
          if(data.objetivos_especificos.length == 1){
              props.addObjEsp.push({
                  id: data.objetivos_especificos[0].id,
                  nombre: data.objetivos_especificos[0].nombre
              })
          }else{
              data.objetivos_especificos.map((item) => {
                  props.addObjEsp.push({
                      id: item.id,
                      nombre: item.nombre
                  })
              })
          }
        }
        if(data.grupos.length != 0){
          if(data.grupos.length == 1){
            props.gruInves.push({
                id: data.grupos[0].id,
                nombre: data.grupos[0].nombreGrupo
            })
          }else{
              data.grupos.map((item) => {
                  props.gruInves.push({
                      id: item.id,
                      nombre: item.nombreGrupo
                  })
              })
          }
        }
        console.log(props.gruInves)
        if(data.semillero[0].fecha_creacion != null){
          console.log(data.semillero[0].fecha_creacion)
          let nuevaFecha = new Date(moment(data.semillero[0].fecha_creacion).format('YYYY'), moment(data.semillero[0].fecha_creacion).format('MM')-1, moment(data.semillero[0].fecha_creacion).format('DD'))
          props.setFechaDeCracion(nuevaFecha)
        }

        //Form2
        if(data.programa_departamento.length != 0){
            if(data.programa_departamento.length == 1){
                props.addDepaPro.push({
                    id: data.programa_departamento[0].id,
                    nombre: data.programa_departamento[0].nombre
                })
            }else{
                data.programa_departamento.map((item) => {
                    props.addDepaPro.push({
                        id: item.id,
                        nombre: item.nombre
                    })
                })
            }
        }
        if(data.linea_sublinea.length != 0){
            if(data.linea_sublinea.length == 1){
                props.lineaSubInves.push({
                    id: data.linea_sublinea[0].id,
                    nombre: data.linea_sublinea[0].nombre
                })
            }else{
                data.linea_sublinea.map((item) => {
                    props.lineaSubInves.push({
                        id: item.id,
                        nombre: item.nombre
                    })
                })
            }
        }
        if(data.semillero[0].area_investigacion.codigo != null){
            props.setAreaInves(data.semillero[0].area_investigacion.codigo)
        }

        //Form3
        if(data.lideres.length != 0){
            if(data.lideres.length == 1){
                props.responsables.push({
                    id: data.lideres[0].encargado_id,
                    nombre: data.lideres[0].nombre,
                    rol: data.lideres[0].rol
                })
            }else{
                data.lideres.map((item) => {
                    props.responsables.push({
                        id: item.encargado_id,
                        nombre: item.nombre,
                        rol: item.rol
                    })
                })
            }
        }
        props.setCargando(false)

    }

    //Obtener semilleros solicitudes
    const obtenerSemillerosSolicitudes = async () => {
        return new Promise(resolve => {
            Funciones.consulta(`api/v1.0/investigacion/listaSemilleros/${props.semiActual.id}`, null, null, (error, estado, resp) => {
              resolve(estado === 200 && !error ? resp : null);
            })
        })
    }

    if(props.cargando == true){
      return(
        <TareasTerminadas imagen={emma_w} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' />
      )
    }else{
      return (
        <div className={classes.root}>
          <Stepper activeStep={props.hola} alternativeLabel>
            {props.steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
          {/* Aqui es cuando el Stepper llega al final.*/}
            {props.hola === props.steps.length ? (
              <div>
                <Typography className={classes.instructions}>{props.hola}</Typography>
                <Button onClick={props.handleReset}>Reset</Button>
              </div>
            ) : (
              <div>
                {getStepContent(props.hola, props)}
              </div>
            )}
          </div>
        </div>
      );
    }
}


//Aqui es donde se retorna el contenido de esa opcion del Stepper, osea los formularios.
function getStepContent(stepIndex, props) {
    switch (stepIndex) {
      case 0:
        return <Form1 
        //Textfields
        nameSemi={props.nameSemi} 
        setNameSemi={props.setNameSemi}
        
        objGenSemi={props.objGenSemi}
        setObjGenSemi={props.setObjGenSemi}

        misionSemi={props.misionSemi}
        setMisionSemi={props.setMisionSemi}

        visionSemi={props.visionSemi}
        setVisionSemi={props.setVisionSemi}
        //Para el select
        objEspSemi={props.objEspSemi}
        setObjEspSemi={props.setObjEspSemi}

        addObjEsp={props.addObjEsp}
        setAddObjEsp={props.setAddObjEsp}

        siglaSemi={props.siglaSemi}
        setSiglaSemi={props.setSiglaSemi}

        getError={props.getError}
        errores={props.errores}
        setErrores={props.setErrores}

        validateInputs={props.validateInputs}
        
        fechaDeCreacion={props.fechaDeCreacion}
        setFechaDeCracion={props.setFechaDeCracion}
        />;
      case 1:
        return <Form2 
        //Para el select
        addDepaPro={props.addDepaPro}
        setAddDepaPro={props.setAddDepaPro}

        gruInves={props.gruInves}
        setGruInves={props.setGruInves}

        areaInves={props.areaInves}
        setAreaInves={props.setAreaInves}

        lineaSubInves={props.lineaSubInves}
        setLineaSubInves={props.setLineaSubInves}

        grupoInve={props.grupoInve}
        setGrupoInve={props.setGrupoInve}

        areas={props.areas}
        setAreas={props.setAreas}

        getError={props.getError}
        errores={props.errores}
        setErrores={props.setErrores}

        validateInputs={props.validateInputs}
        />;
      case 2:
        return <Form3 
        //Para el listar
        responsables={props.responsables}
        setResponsables={props.setResponsables}

        rol={props.rol}
        setRol={props.setRol}
        />;
      default:
        return 'Unknown stepIndex';
    }
}

//Formularios para el retorno cuando se llega al switch que pertenece.

const Form1 = function (props) {

    const classes = useStyles();
    const dispatch = useDispatch();
    //Estados para abrir y cerrar los modal de Delete y Add en el formulario 1
    const [openModalAdd, setOpenModalAdd] = React.useState(false);
    const [openModalEdit, setOpenModalEdit] = React.useState(false);
    const [objEspecEdit, setObjEspecEdit] = React.useState('')
    //Funciones para que abra y cierre el modal de add.
    const handleClickOpenAdd = () => {
      setOpenModalAdd(true);
    };

    //Funcion para el cierre del modal de add.
    const handleCloseAdd = () => {
      setOpenModalAdd(false);
      
    };

    //Metodos OnChange para la escritura de los inputs.
    const handleOnchange = (e) =>{
      if(e.target.name == "nameSemi"){
        props.setNameSemi(e.target.value)
        if(props.errores.length > 0){
          props.setErrores(props.errores.filter(er => er.llave != "nameSemi"));
          // Elimina error cuando se escribe en el campo
        }
      }else if(e.target.name == "objGenSemi"){
        props.setObjGenSemi(e.target.value)
        if(props.errores.length > 0){
          props.setErrores(props.errores.filter(er => er.llave != "objGenSemi"));
        }
      }else if(e.target.name == "misionSemi"){
        props.setMisionSemi(e.target.value)
        if(props.errores.length > 0){
          props.setErrores(props.errores.filter(er => er.llave != "misionSemi"));
        }
      }else if(e.target.name == "visionSemi"){
        props.setVisionSemi(e.target.value)
        if(props.errores.length > 0){
          props.setErrores(props.errores.filter(er => er.llave != "visionSemi"));
        }
      }else if(e.target.name == "objEspSemi"){
        props.setObjEspSemi(e.target.value)
        if(props.errores.length > 0){
          props.setErrores(props.errores.filter(er => er.llave != "objEspSemi" && er.llave != "addObjEsp"));
        }
      }else if(e.target.name == "sigla"){
        props.setSiglaSemi(e.target.value)
        if(props.errores.length > 0){
          props.setErrores(props.errores.filter(er => er.llave != "siglaSemi"));
        }
      }else if(e.target.name == "objEspSemiEdit"){
        setObjEspecEdit(e.target.value)
      }
      
    };

    //Funcion para agregar Objetivos especificos al textfield
    const addObjEspFunction = async () =>{
      if(props.objEspSemi == ""){
        props.setErrores(await props.validateInputs([
          { value: props.objEspSemi, name: 'objEspSemi', err: { empty: true } },
        ]))
      }else{
        props.addObjEsp.push({
            id: null,
            nombre: props.objEspSemi
        })
        props.setObjEspSemi("")
        handleCloseAdd()
      }
    };

    //Valor del select
    const [objEspSelect, setObjEspSelect] = React.useState('');
    const [departamentos, setDepartamentos] = React.useState(["depa1", "depa2", "depa3"]);
    const [programas, setProgramas] = React.useState(["pro1", "pro2", "pro3"])
    //Ayuda para cambiar el valor del select
    const handleChangeSelectObj = (event) => {
      setObjEspSelect(event.target.value);
    };

    //Funcion para eliminar un elemento del array creado.
    const handleClickDelete = (event) =>{
        
        props.addObjEsp.map((item, index) => {
            let i = item.nombre.indexOf(objEspSelect)
            if ( i !== -1 ) {
                console.log(props.addObjEsp.splice(index, 1));
            }
        })
        setObjEspSelect("")
    }
    //Funcion para editar un elemento del array creado.
    
    const handleClickEdit = (event) =>{
        let pos = 0

        props.addObjEsp.map((item, index) => {
            let i = item.nombre.indexOf(objEspSelect)
            if ( i !== -1 ) {
                pos = index
            }
        })

      if(objEspSelect.length == 0){
        dispatch(
          actualizarMensaje({ titulo: "Seleccione un objetivo.", tipo: 'info', mostrar: true, tiempo: 6000 })
        );
      }else{

        if(openModalEdit == true){

          if(objEspecEdit.replace(/\s+/g, '') == ""){
            dispatch(
              actualizarMensaje({ titulo: "No puede estar vacio.", tipo: 'info', mostrar: true, tiempo: 6000 })
            );
          }else{
            props.addObjEsp[pos].nombre = objEspecEdit
            setObjEspSelect("")
            setOpenModalEdit(false)
          }

        }else{
          setObjEspecEdit(objEspSelect) 
          setOpenModalEdit(true)
        }

      }
    }

    return (
        <div className={classes.flexGrow}>
        <Grid container spacing={3}>
          <Grid item xs={10}>
            <TextField 
            fullWidth 
            label="Nombre del Semillero" 
            id = 'nameSemi'
            error = { props.getError('nameSemi', props.errores).length > 0}
            helperText = { props.getError('nameSemi', props.errores) }
            value={props.nameSemi} 
            onChange={handleOnchange}
            name="nameSemi"/>
          </Grid>
          <Grid item xs={2}>
            <TextField 
            fullWidth 
            label="SIGLA" 
            value={props.siglaSemi} 
            onChange={handleOnchange}
            name="sigla"/>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="standard-multiline-flexible"
              label="Objetivo General del Semillero"
              multiline
              error = { props.getError('objGenSemi', props.errores).length > 0}
              helperText = { props.getError('objGenSemi', props.errores) }
              rowsMax={4}
              fullWidth
              value={props.objGenSemi} 
              onChange={handleOnchange}
              name="objGenSemi"
            />
          </Grid>
          {/* <Grid item xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} > 
              <Fragment>
              <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  label="Fecha de Creación"
                  value={props.fechaDeCreacion}
                  onChange={props.setFechaDeCracion}
                  fullWidth 
                  KeyboardButtonProps={{
                      'aria-label': 'change date',
                  }}
              />
              </Fragment> 
            </MuiPickersUtilsProvider>
          </Grid> */}
          <Grid item xs={6} sm={8}>
              <FormControl className={classes.formControl} fullWidth  required error = { props.getError('addObjEsp', props.errores).length > 0}>
                <Select
                    value={objEspSelect}
                    displayEmpty
                    fullWidth
                    className={classes.selectEmpty}
                    inputProps={{ 'aria-label': 'Without label' }}
                    onChange={handleChangeSelectObj}
                  >
                  <MenuItem value="">
                      <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.addObjEsp.length} Objetivos Especificos Agregados` }</em>
                  </MenuItem>
                    {props.addObjEsp.map((item, index) => (
                      <MenuItem key= { index } value = { item.nombre }>{ `${item.nombre.slice(0, 40)}...` }</MenuItem>
                    ))}
                 </Select>
            </FormControl>
            <FormHelperText>{ props.getError('addObjEsp', props.errores) }</FormHelperText>
          </Grid>
          <Grid item xs={6} sm={4} className={classes.flexCenter}>
              <IconButton aria-label="add" className={classes.colorEmmma} onClick={handleClickOpenAdd}>
                  <AddIcon />
              </IconButton>
              <IconButton aria-label="delete" className={classes.colorDelete} onClick={handleClickDelete}>
                  <DeleteIcon />
                    </IconButton>
               <IconButton aria-label="delete" className={classes.colorEdit} onClick={handleClickEdit}>
                  <EditIcon />
               </IconButton>
          </Grid>
          <Grid item xs={12} sm={6}>
          <TextField
              id="standard-multiline-flexible"
              label="Misión"
              multiline
              error = { props.getError('misionSemi', props.errores).length > 0}
              helperText = { props.getError('misionSemi', props.errores) }
              rowsMax={4}
              fullWidth
              value={props.misionSemi} 
              onChange={handleOnchange}
              name="misionSemi"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="standard-multiline-flexible"
              label="Visión"
              multiline
              error = { props.getError('visionSemi', props.errores).length > 0}
              helperText = { props.getError('visionSemi', props.errores) }
              rowsMax={4}
              fullWidth
              value={props.visionSemi} 
              onChange={handleOnchange}
              name="visionSemi"
            />
          </Grid>
        </Grid>

        <SemillerosAddObj 
          open={openModalAdd}
          setOpen={setOpenModalAdd}
          handleClose={handleCloseAdd}

          //TextFields
          objEspSemi={props.objEspSemi}
          setObjEspSemi={props.setObjEspSemi}
          //OnChange
          handleOnchange={handleOnchange}
          //Mando la funcion para añadir objeto en especifico al array
          addObjEsp={addObjEspFunction}
          //Errores
          getError={props.getError}
          errores={props.errores}
          setErrores={props.setErrores}
        />

        <SemillerosEditObj 
          openModelEdit={openModalEdit}
          setOpenModalEdit={setOpenModalEdit}

          objEspSelect={objEspecEdit}
          //OnChange
          handleOnchange={handleOnchange}

          //HandleClick
          handleClickEdit={handleClickEdit}
        />
      </div>
    )
}

const Form2 = function (props) {
  const classes = useStyles();

  //Valor del select Departamentos y Programas
  const [depaProSelect, setDepaProSelect] = React.useState('');
  //Estados para abrir y cerrar los modal de Departamentos y programas.
  const [openModalAdd, setOpenModalAdd] = React.useState(false);
  //Estados para abrir y cerrar los modal de Lineas y sublineas.
  const [openModalLinea, setOpenModalLinea] = React.useState(false);
  //Estados para el select de departamentos y programas.
  const [depaSelect, setDepaSelect] = React.useState('');
  const [proSelect, setProSelect] = React.useState('');

  //Estados para el select de lineas y sublineas.
  const [lineaSelect, setLineaSelect] = React.useState('');
  const [subLineaSelect, setSubLineaSelect] = React.useState('');
  const [lineaSeleccion, setLineaSeleccion] = React.useState('');
  const [grupoSeleccion, setGrupoSeleccion] = React.useState('');
  const[openModalGrupo, setOpenModalGrupo] = React.useState(false)
  const [grupoSelect, setGrupoSelect] = React.useState('');
  //Funcion para asignar Grupos de investigacion al select.
  const handleOnchangeGruInv = (event) =>{
    props.setGruInves(event.target.value);
    if(props.errores.length > 0){
      props.setErrores(props.errores.filter(er => er.llave != "gruInves"));
      // Elimina error cuando se escribe en el campo
    }
  }

  //Funcion para asignar Area de investigacion al select.
  const handleOnchangeArea = (event) =>{
    props.setAreaInves(event.target.value);
    if(props.errores.length > 0){
      props.setErrores(props.errores.filter(er => er.llave != "areaInves"));
      // Elimina error cuando se escribe en el campo
    }
  }

  //Funcion para asginar Departamentos y Programas al select.
  const handleChangeDepaPro = (event) => {
    setDepaProSelect(event.target.value);
    
  };
    
  //Funciones para que abra y cierre el modal de add Departamentos y Programas.
  const handleClickOpenAdd = () => {
    setOpenModalAdd(true);
  };

  //Funcion para el cierre del modal de add Departamentos y Programas.
  const handleCloseAdd = () => {
    setOpenModalAdd(false);
    setDepaSelect('')
    setProSelect('')
  };

  //Funcion para agregar un programa y departamento.
  const onClickChange = async () =>{
    if(proSelect == "" || depaSelect == ""){
      props.setErrores(await props.validateInputs([
        { value: depaSelect, name: 'depaSelect', err: { empty: false } },
        { value: proSelect, name: 'proSelect', err: { empty: false } },
      ]))
    }else{
      let obj = {
          id: null,
          nombre: `${proSelect.secundario.nombre}/${depaSelect.nombre}`
      }

      props.addDepaPro.push(obj)
      handleCloseAdd()
    }
  }

  //Funcion para eliminar un elemento del Departamento Programa.
  const handleClickDeleteDepa = (event) =>{
    
    props.addDepaPro.map((item, index) => {
        let i = item.nombre.indexOf(depaProSelect)
        if ( i !== -1 ) {
            console.log(props.addDepaPro.splice(index, 1));
        }
    })
    setDepaProSelect("")
  }

  //Funciones para que abra y cierre el modal de Linea y Sublineas.
  const handleClickOpenLinea = () => {
    setOpenModalLinea(true);
  };

  //Funcipan para cambiar el seleccionado de linea y Sublineas.
  const handleChangeLinea = (event) => {
    setLineaSeleccion(event.target.value);
  };


  //Funcion para el cierre del modal de Linea y Sublineas.
  const handleCloseLinea = () => {
    setOpenModalLinea(false);
    setLineaSelect('')
    setSubLineaSelect('')
  };

  //Funcion para agregar una linea y sublinea.
  const onClickChangeLinea = async () =>{
    if(lineaSelect == "" || subLineaSelect == ""){
      props.setErrores(await props.validateInputs([
        { value: lineaSelect, name: 'lineaSelect', err: { empty: false } },
        { value: subLineaSelect, name: 'subLineaSelect', err: { empty: false } },
      ]))
    }else{
        let obj = {
            id: null,
            nombre: `${lineaSelect.nombre}/${subLineaSelect.secundario.nombre}`
        }
      props.lineaSubInves.push(obj)
      handleCloseLinea()
    }
  }

  //Funcion para eliminar un elemento de linea y sublinea.
  const handleClickDeleteLinea = (event) =>{

    props.lineaSubInves.map((item, index) => {
        let i = item.nombre.indexOf(lineaSeleccion)
        if ( i !== -1 ) {
            console.log(props.lineaSubInves.splice(index, 1));
        }
    })

    setLineaSeleccion("")

  }

  //Funcipan para cambiar el seleccionado de grupo.
  const handleClickOpenGrupo = (event) => {
    setOpenModalGrupo(true);
  };

  //Funcion para eliminar un elemento de linea y sublinea.
  const handleClickDeleteGrupo = (event) =>{

    props.gruInves.map((item, index) => {
        let i = item.nombre.indexOf(grupoSeleccion)
        if ( i !== -1 ) {
            console.log(props.gruInves.splice(index, 1));
        }
    })

    setGrupoSeleccion("")

  }

  //Funcipan para cambiar el seleccionado de grupo.
  const handleChangeGrupo = (event) => {
    setGrupoSeleccion(event.target.value);
  };

  //Funcion para el cierre del modal de grupo.
  const handleCloseGrupo = () => {
    setOpenModalGrupo(false);
    setGrupoSelect('')
  };

  //Funcion para agregar un grupo
  const onClickChangeGrupo = async () =>{
    if(grupoSelect == ""){
      props.setErrores(await props.validateInputs([
        { value: grupoSelect, name: 'grupoInvesModal', err: { empty: false } },
      ]))
    }else{
      let obj = {
        id: null,
        nombre: `${grupoSelect.nombre}`
      }
      props.gruInves.push(obj)
      handleCloseGrupo()
    }
  }

    return (
      <div className={classes.flexGrow}>
        <Grid container spacing={3}>
          <Grid item xs={7} sm={9}>
            <InputLabel htmlFor="age-native-simple">Departamento y programa</InputLabel>
            <FormControl className={classes.formControl} fullWidth error = { props.getError('addDepaPro', props.errores).length > 0}>
              <Select
                 value={depaProSelect}
                displayEmpty
                className={classes.selectEmpty}
                inputProps={{ 'aria-label': 'Without label' }}
                onChange={handleChangeDepaPro}
              >
                <MenuItem value="">
                    <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.addDepaPro.length} Depar. y Progra. Agregados` }</em>
                </MenuItem>
                {props.addDepaPro.map((item, index) => (
                  <MenuItem key= { index } value = { item.nombre }>{`${item.nombre}`}</MenuItem>
                ))}
                </Select>
            </FormControl>
            <FormHelperText>{ props.getError('addDepaPro', props.errores) }</FormHelperText>
          </Grid>
          <Grid item xs={5} sm={3} style={{marginTop: '20px'}} className={classes.flexCenter}>
               <IconButton aria-label="add"  className={classes.colorEmmma} onClick={handleClickOpenAdd}>
                   <AddIcon />
              </IconButton>
              <IconButton aria-label="delete" className={classes.colorDelete} onClick={handleClickDeleteDepa}>
                     <DeleteIcon />
              </IconButton>
          </Grid>
          <Grid item xs={7} sm={9}>
            <InputLabel htmlFor="age-native-simple">Linea y sublinea de investigación</InputLabel>
            <FormControl className={classes.formControl} fullWidth error = { props.getError('lineaSubInves', props.errores).length > 0}>
              <Select
                value={lineaSeleccion}
                displayEmpty
                className={classes.selectEmpty}
                inputProps={{ 'aria-label': 'Without label' }}
                onChange={handleChangeLinea}
              >
                <MenuItem value="">
                     <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.lineaSubInves.length} Linea y Sublinea Agregados` }</em>
                </MenuItem>
                {props.lineaSubInves.map((item, index) => (
                   <MenuItem key= { index } value = { item.nombre }>{`${item.nombre}`}</MenuItem>
                ))}
                </Select>
            </FormControl>
            <FormHelperText>{ props.getError('lineaSubInves', props.errores) }</FormHelperText>
          </Grid>
          <Grid item xs={5} sm={3} style={{marginTop: '20px'}} className={classes.flexCenter}>
               <IconButton aria-label="add" className={classes.colorEmmma} onClick={handleClickOpenLinea}>
                  <AddIcon />
              </IconButton>
              <IconButton aria-label="delete"  className={classes.colorDelete} onClick={handleClickDeleteLinea}>
                    <DeleteIcon />
              </IconButton>
          </Grid>
          <Grid item xs={7} sm={9}>
              <InputLabel htmlFor="age-native-simple">Grupo de investigación</InputLabel>
              <FormControl className={classes.formControl} fullWidth error = { props.getError('gruInves', props.errores).length > 0}>
                <Select
                  value={grupoSeleccion}
                  displayEmpty
                  className={classes.selectEmpty}
                  inputProps={{ 'aria-label': 'Without label' }}
                  onChange={handleChangeGrupo}
                >
                  
                  <MenuItem value="">
                      <em style={{color: "rgba(0, 0, 0, 0.54)"}} >{ `${props.gruInves.length} Grupos Agregados` }</em>
                  </MenuItem>
                  {props.gruInves.map((item, index) => (
                    <MenuItem key= { index } value = { item.nombre }>{ item.nombre }</MenuItem>
                  ))}
                  </Select>
                  </FormControl>
                  <FormHelperText>{ props.getError('gruInves', props.errores) }</FormHelperText>
            </Grid>
            <Grid item xs={5} sm={3} style={{marginTop: '20px'}} className={classes.flexCenter}>
               <IconButton aria-label="add" className={classes.colorEmmma} onClick={handleClickOpenGrupo}>
                  <AddIcon />
              </IconButton>
              <IconButton aria-label="delete"  className={classes.colorDelete} onClick={handleClickDeleteGrupo}>
                    <DeleteIcon />
              </IconButton>
          </Grid>
          {/* <Grid item xs={12}>
              <InputLabel htmlFor="age-native-simple">Grupo de investigación</InputLabel>
              <FormControl className={classes.formControl} fullWidth error = { props.getError('gruInves', props.errores).length > 0}>
                <Select
                  value={props.gruInves}
                  displayEmpty
                  className={classes.selectEmpty}
                  inputProps={{ 'aria-label': 'Without label' }}
                  onChange={handleOnchangeGruInv}
                >
                  
                  <MenuItem value="">
                      <em style={{color: "rgba(0, 0, 0, 0.54)"}} >{ `${props.grupoInve.length} Grupos Disponibles` }</em>
                  </MenuItem>
                  {props.grupoInve.map((item, index) => (
                    <MenuItem key= { item.id } value = {item.codigo}>{item.nombre}</MenuItem>
                  ))}
                  </Select>
                  </FormControl>
                  <FormHelperText>{ props.getError('gruInves', props.errores) }</FormHelperText>
            </Grid> */}
            {/* <Grid item xs={12}>
              <InputLabel htmlFor="age-native-simple">Areas de investigación</InputLabel>
              <FormControl className={classes.formControl} fullWidth error = { props.getError('areaInves', props.errores).length > 0}>
                <Select
                  value={props.areaInves}
                  displayEmpty
                  className={classes.selectEmpty}
                  inputProps={{ 'aria-label': 'Without label' }}
                  onChange={handleOnchangeArea}
                >
                  <MenuItem value="">
                      <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.areas.length} Areas Disponibles` }</em>
                  </MenuItem>
                  {props.areas.map((item, index) => (
                    <MenuItem key= { item.id } value = { item.codigo }>{item.nombre}</MenuItem>
                  ))}
                  </Select>
              </FormControl>
              <FormHelperText>{ props.getError('areaInves', props.errores) }</FormHelperText>
            </Grid> */}
        </Grid>
        {openModalAdd == true ? <SemillerosAddDepPro 
              open={openModalAdd}
              setOpen={setOpenModalAdd}
              handleClose={handleCloseAdd}
  
              depaSelect={depaSelect}
              setDepaSelect={setDepaSelect}
  
              proSelect={proSelect}
              setProSelect={setProSelect}
  
              onClickChange={onClickChange}
              
              getError={props.getError}
              errores={props.errores}
              setErrores={props.setErrores}
            /> : ''}
          {openModalLinea == true ? <SemillerosAddLinea 
              open={openModalLinea}
              setOpen={setOpenModalLinea}
              handleClose={handleCloseLinea}
  
              lineaSelect={lineaSelect}
              setLineaSelect={setLineaSelect}
  
              subLineaSelect={subLineaSelect}
              setSubLineaSelect={setSubLineaSelect}
  
              onClickChangeLinea={onClickChangeLinea}

              getError={props.getError}
              errores={props.errores}
              setErrores={props.setErrores}
            />: ''}

            {openModalGrupo == true ? <SemillerosAddGrupos 
              open={openModalGrupo}
              setOpen={setOpenModalLinea}
              handleClose={handleCloseGrupo}
  
              grupoSelect={grupoSelect}
              setGrupoSelect={setGrupoSelect}
  
              onClickChangeGrupo={onClickChangeGrupo}

              getError={props.getError}
              errores={props.errores}
              setErrores={props.setErrores}
            />: ''}
      </div>
      
    )

}

const Form3 = function (props) {

  const [openModalAddEnc, setOpenModalAddEnc] = React.useState(false);
  const [row, setRow] = React.useState({})
  const [arrai, setArrai] = React.useState([])
  const [errores, setErrores] = React.useState([]);
  const actions = (requisito) => {

    const eventoDetalle = (requisito) => {
      let i = props.responsables.indexOf(requisito)
      let filtredData = ''
      let filtredDataArrai = ''
      if ( i !== -1 ) {
        filtredData = props.responsables.filter(item => item.id !== requisito.id);
        filtredDataArrai = arrai.filter(item => item.id != requisito.id);
      }
      props.setResponsables(filtredData)
      setArrai(filtredDataArrai)
    }

    let detalle = <BtnEliminar callback={() => eventoDetalle(requisito)} />;
    
    return detalle

  }
  const getError = (llave, errores) => {
    let existe = errores.find(e => e.llave === llave);
    return existe ? existe.mensaje : '';
  }

  const validateInputs = (campos = []) => {
      return new Promise((resolve) => {
        let errores = [];
        let date_now = new Date()
        campos.map(({ value, name, err, value2 }) => {
          if (err.empty && value.replace(/\s+/g, '') == "") errores.push({ llave: name, 'mensaje': `Este campo es obligatorio` });
          if (err.empty == false && value.length == 0) errores.push({ llave: name, 'mensaje': `Este campo es obligatorio` })
          });
        
        resolve(errores);
      });
  }

  return (
    <div>
      <ListarDatos
        id="tbl_listar_encargados"
        descargar="EMMA - SEMILLEROS"
        datos={props.responsables}
        opciones={true}
        agregar={true}
        buscar={true}
        actAgregar={() => setOpenModalAddEnc(true)}
        css={{
          list: { padding: '0px 0px 20px 0px' },
          appBar: { padding: '0px 0px 0px 0px' },
        }}
        acciones={(row) => actions(row)}
        titulo={"Lista de encargados"}
        fila_principal={({ nombre }) => `${nombre}`}
        filas={[
          {
            'mostrar': ({ id }) => `${id}`,
            'id': 'id', 'enLista': false
          },
          {
            'mostrar': ({ nombre }) => `${nombre}`,
            'id': 'nombre', 'enLista': false
          },
          {
            'mostrar': ({ rol }) => `${rol.nombre}`,
            'id': 'rol', 'enLista': true
          },
          { 'id': 'descripcion', 'enLista': false },
        ]}
      />
      
      <SemillerosAddRes 
        openModalAddEnc={openModalAddEnc}
        setOpenModalAddEnc={setOpenModalAddEnc}

        row={row}
        setRow={setRow}

        responsables={props.responsables}
        setResponsables={props.setResponsables}

        rol={props.rol}
        setRol={props.setRol}

        array={arrai}
        setArray={setArrai}

        getError={getError}
        errores={errores}
        setErrores={setErrores}

        validateInputs={validateInputs}
      />
    </div>
    

    
  )
}