import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { BtnForm } from "../../general/BotonesAccion";
import AppBarModal from '../../general/AppBarModal'
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputBuscar from '../../general/InputBuscar';
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from '../../../global/imagenes/emma_w.png';
import BuscarSelect from '../../general/BuscarSelect';
import BuscarPersona from '../../general/BuscarPersona';
import BuscarColegio from "../../general/BuscarColegio";
import { obtenerValores, obtenerValoresFiltros, obtenerPermisosValor } from "../../../global/js/funciones";
import { Id_Tipo_colegio, Id_Barrio_colegio, Id_Departamento_colegio } from '../helper';
import Input from '@material-ui/core/Input';

class PromocionFiltrar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cargando: true,
      buscarColegio: false,
      Colegio: {
        id: 0,
        nombre: '',
      },
      departamentos: [],
      departamento: '',
      ciudad: '',
      ciudades: [],
      tipos: [],
      Tipo: '',
    }
  }

  // componentDidMount() {
  //   this.setState({ cargando: true })
  //   this.cargarTipos()
  //   // this.obtenerGenericas()
  // }

  async componentDidUpdate({ modalAddFiltros }, { departamento }) {
    let { departamento: departamentoNew } = this.state;
    let { modalAddFiltros: modalAddFiltrosNew } = this.props;
    if(modalAddFiltros !== modalAddFiltrosNew) {
      if(modalAddFiltrosNew){
      await this.obtenerGenericas()
      await this.cargarTipos()
      }
    };
    if (departamentoNew !== departamento) {
      let ciudades = await obtenerPermisosValor(departamentoNew);
      ciudades = ciudades.map(({ secundario }) => ({
        value: secundario.id,
        label: secundario.nombre,
      }));
      console.log(ciudades)
      this.changestate('ciudades', ciudades);
      this.changestate('ciudad', '');

    }

  }


  async obtenerGenericas() {
    console.log(this.props.seleccion)
    let tipo_C = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': Id_Tipo_colegio }]);
    this.setState({ tipos: tipo_C });
  }


  cargarTipos = async () => {
    const transformar = data => {
      return data.map(e => ({
        value: e.id,
        label: e.nombre,
        codigo: e.codigo,
      }))
    }
    const departamentos = await obtenerValores(Id_Departamento_colegio);
    this.setState({
      departamentos: transformar(departamentos)
    })
  }

  pintarMenuItemm = (lista) => {
    return Array.isArray(lista) ? lista.map(({ value, label }) => <MenuItem key={value} value={value}>{label}</MenuItem>) : [];
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    });
  };

  changestate = (name, value) => {
    this.setState({
      [name]: value
    })
  }

  onSubmit = (e) => {
    let { Tipo, departamento, ciudad } = this.state
    let { filtrar, mostrarModalFiltrosColegio, actualizarMensaje } = this.props;
    let filtros = [];
    if (Tipo !== '') filtros.push({ 'llave': 'Tipo', 'valor': Tipo })
    if (departamento !== '') filtros.push({ 'llave': 'departamento', 'valor': departamento })
    if (ciudad !== '') filtros.push({ 'llave': 'ciudad', 'valor': ciudad })
    if (filtros.length > 0) {
      mostrarModalFiltrosColegio(false)
      filtrar(filtros);
      this.reiniciarEstado()
    } else actualizarMensaje({ titulo: 'Seleccione Algún filtro', tipo: 'info', mostrar: true, tiempo: 6000 });
    e.preventDefault();
  }

  reiniciarEstado = () => {
    this.setState({
      Tipo: '',
      departamento: '',
      ciudades: [],
      ciudad: ''
    })
  }

  render() {
    let { modalAddFiltros, mostrarModalFiltrosColegio } = this.props;
    let { departamentos, departamento, ciudad, ciudades, tipos, Tipo } = this.state;
    return (
      <>
        <Dialog fullWidth={true} maxWidth="sm" open={modalAddFiltros} onClose={() => mostrarModalFiltrosColegio(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <AppBarModal titulo='¡ Aplicar Filtros !' mostrarModal={mostrarModalFiltrosColegio} titulo_accion="Cerrar"
            accion={() => mostrarModalFiltrosColegio(false)} />
          <DialogContent className='scroll'>
            <Grid container spacing={0} className='margin_cont' >
              <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                <FormControl className='form-control' style={{ marginTop: 2 }}>
                  <InputLabel id='departamento' ref={235} htmlFor="departamento">Seleccione el departamento</InputLabel>
                  <Select
                    id='departamento'
                    value={departamento}
                    name='departamento'
                    onChange={this.onChange}
                  // input={<Input labelWidth={235} name="departamento" id="departamento" />}
                  // // open={showDepColegio}
                  // // onClose={this.setSelecT.bind(this, "showDepColegio", false)}
                  // // onOpen={this.setSelecT.bind(this, "showDepColegio", true)}
                  >
                    {this.pintarMenuItemm(departamentos)}
                  </Select>
                </FormControl>
                {/* <BuscarSelect datos={departamentos} cambiarEstado={(value) => setDepartamento(value)} valor={departamento} id='departamento' nombre='¿En que departamento vives?' placeholder='Seleccione Opción' /> */}
              </Grid>
              <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                <FormControl className='form-control' style={{ marginTop: 2 }}>
                  <InputLabel id='ciudad' ref={300} htmlFor="ciudad">Seleccione la ciudad</InputLabel>
                  {departamento === '' ?
                    <Select
                      disabled
                      input={<Input labelWidth={300} name="ciudad" id="ciudad" />}
                      // open={showCiuColegio}
                      // onClose={this.setSelecT.bind(this, "showCiuColegio", false)}
                      // onOpen={this.setSelecT.bind(this, "showCiuColegio", true)}
                      value={ciudad}
                      onChange={this.onChange}
                    >
                      {this.pintarMenuItemm(ciudades)}
                    </Select> :

                    <Select
                      id='ciudad'
                      value={ciudad}
                      name='ciudad'
                      onChange={this.onChange}
                    // input={<Input labelWidth={300} name="ciudad" id="ciudad" />}
                    // // open={showCiuColegio}
                    // // onClose={this.setSelecT.bind(this, "showCiuColegio", false)}
                    // // onOpen={this.setSelecT.bind(this, "showCiuColegio", true)}
                    // value={ciudad}
                    // onChange={this.onChange}
                    >
                      {this.pintarMenuItemm(ciudades)}
                    </Select>
                  }

                </FormControl>
              </Grid>
              <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                <FormControl className="form-control" required>
                  <InputLabel id="demo-simple-select-label">Seleccione el tipo de Colegio</InputLabel>
                  <Select id="Tipo" value={Tipo} name='Tipo' onChange={this.onChange} >
                    {tipos.map(tipo => <MenuItem key={tipo.id} value={tipo.id}>{tipo.nombre}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => mostrarModalFiltrosColegio(false)} />
            <BtnForm texto="FILTRAR" callback={this.onSubmit} />
          </DialogActions>
        </Dialog >
      </>
    )
  }
}

PromocionFiltrar.propTypes = {
  //variables
  modalAddFiltros: PropTypes.bool.isRequired,
  //funciones
  mostrarModalFiltrosColegio: PropTypes.func.isRequired,
  filtrar: PropTypes.func.isRequired,
}
export default PromocionFiltrar;
