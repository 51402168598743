import React from 'react';
//COMPONENTS
import { Dialog, DialogContent, FormControl, InputLabel, Select, MenuItem, DialogActions } from '@material-ui/core';
import AppBarModal from '../general/AppBarModal';
import { BtnForm } from '../general/BotonesAccion';
//UTILS

const SetupPrograma = ({ status, toggle, programas, programa, handleChange }) => {


  return (
    <Dialog open={status} onClose={() => toggle({ modal: 'setupPrograma', value: false })} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="xs">
      <AppBarModal titulo="Elige tu programa" mostrarModal={() => toggle({ modal: 'setupPrograma', value: false })} titulo_accion="" accion="" />
      <DialogContent >
        <FormControl className="form-control">
          <InputLabel id="demo-simple-select-outlined-label">Programa</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={programa}
            onChange={handleChange}
            label="Programa"
          >
            {programas?.map(({ codigo, nombre }, index) =>
              <MenuItem key={index} value={codigo}>{nombre}</MenuItem>
            )}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <BtnForm texto="Cerrar" callback={() => toggle({ modal: 'setupPrograma', value: false })} />
      </DialogActions>
    </Dialog>
  )
}

export default SetupPrograma
