import React from 'react';
import { Typography } from '@material-ui/core';
import { coloresEmma } from '../../../global/js/funciones';
import ProgressCircle from './ProgressCircle';

const Banner = ({ nombre, programa, percent }) => {
  return (
    <div style={{
      alignSelf: 'flex-start',
      margin: '10px 70px',
      display: 'flex',
      flexDirection: window.innerWidth < 600 ? 'column' : 'row', 
      padding: '30px 20px',
      backgroundColor: '#eaefef',
      borderRadius: '10px',
      width: '95%', 
      maxWidth: '1200px', 
      boxSizing: 'border-box', 
      justifyContent: 'space-between', 
      alignItems: 'center', 
      flexWrap: 'wrap', // Permitir que los elementos se envuelvan en pantallas pequeñas
      color: coloresEmma.gray,
      fontSize: '20px'
    }}>
      <div style={{
        marginBottom: window.innerWidth < 600 ? '20px' : '0',
        flexBasis: '50%', 
        textAlign: window.innerWidth < 600 ? 'center' : 'left', 
      }}>
        <Typography variant="h4" gutterBottom>Hola, {nombre}!</Typography>
        <span>Bienvenido al resumen y estado de tu programa de {programa}</span>
      </div>
      <div style={{
        flexBasis: '50%', 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        
      }}>
        <ProgressCircle percent={percent} text="TU PROGRESO" color={coloresEmma.colornegro} />
      </div>
    </div>
  );
};

export default Banner;
