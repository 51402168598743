
import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { actualizarMensaje } from "../../redux/actions/actGlobal";
import { Paper, Grid, Button, Toolbar, AppBar, RadioGroup, Radio, FormControlLabel, FormControl, FormLabel, TextField, Typography } from '@material-ui/core'
import TareasTerminadas from "../general/TareasTerminadas";
import BarraProceso from "../general/BarraProceso";
import EnviarMensajeNota from "./PracticasTerminarActa";
import { obtenerPermisosValorAlt, consulta, mostrarError, coloresEmma } from "../../global/js/funciones";
import emma_w from '../../global/imagenes/emma_w.png';
import emma_s from '../../global/imagenes/emma_s1.png';
import { PASOS_ENCUETAS, PREGUNTAS_ENCUESTA } from './helper';


const useStyles = makeStyles((theme) => ({

  appBar: {
    top: 'auto',
    bottom: 0,
    backgroundColor: 'white',
  }
}));


function BottomAppBar({ paso_actual, pasos, callback }) {
  const classes = useStyles();

  return (
    <AppBar position="fixed" color="primary" className={classes.appBar}>
      <Toolbar>
        <Button disabled={paso_actual === 0} onClick={() => callback(-1)} >  REGRESAR </Button>
        <Button color="primary" onClick={() => callback(1)} style={{ marginLeft: 10 }}> {paso_actual === pasos.length - 1 ? 'TERMINAR' : 'SIGUIENTE'} </Button>
      </Toolbar>
    </AppBar>
  );
}

class PracticasAgregarActa extends Component {

  constructor(props) {
    super(props)
    this.state = {
      cargando: true,
      accion: 'principal',
      pasos: [],
      id_paso: 0,
      paso_actual: 0,
      PreObtenidas: 1,
      preguntas: [],
      practica: '',
      encuesta: '',
      id_requisito: '',
      terminada: false,
      encontrada: false,
      confirmar: false,
      calificacion: false,
      iniciar: false,
      descripcion_req: ''
    }
  }

  componentDidMount() {
    let { tipo, id_requisito } = this.props;
    if (tipo === 1) {
      const { match: { params } } = this.props;
      id_requisito = params.id_requisito;
    }
    this.obtenerDatos(id_requisito);
  }

  async obtenerDatos(id_requisito) {
    let requisito = await this.obtenerRequisito(id_requisito);
    if (requisito.length > 0) {
      let pasos = await obtenerPermisosValorAlt([{ 'llave': 'principal', 'valor': requisito[0].requisito.id }, { 'llave': 'secundario__generica', 'valor': PASOS_ENCUETAS }]);
      if (pasos.length > 0) {
        pasos.sort(this.organizarPasos);
        let id_paso = pasos[0].secundario.id;
        let nuevas_pre = await obtenerPermisosValorAlt([{ 'llave': 'principal', 'valor': id_paso }, { 'llave': 'secundario__generica', 'valor': PREGUNTAS_ENCUESTA }]);
        nuevas_pre = this.trasnformarPreguntas(nuevas_pre, id_paso);
        this.setState({ cargando: false, pasos, preguntas: [nuevas_pre], id_requisito, encontrada: true, calificacion: requisito[0].requisito.valore === '1', descripcion_req: requisito[0].requisito.descripcion, nombre_req: requisito[0].requisito.nombre });
        return true;
      }
    }
    this.setState({ cargando: false, encontrada: false });
  }

  obtenerRequisito = (id) => {
    return new Promise(resolve => {
      consulta(`api/v1.0/practicas/acta?id=${id}`, null, null,
        (error, estado, resp) => {
          if (estado === 200) {
            resolve(resp);
          }
        }, false
      );
    })

  }

  async cambiarPaso(paso_nuevo) {
    let { pasos, paso_actual, PreObtenidas, preguntas } = this.state;
    let { sw, items } = this.validar_respuestas();
    if (sw) {
      paso_actual = paso_actual + paso_nuevo;
      if (paso_actual < pasos.length) {
        this.setState({ cargando: true })
        if (PreObtenidas < pasos.length) {
          let id_paso = pasos.find((item, i) => i === paso_actual)
          let nuevas_pre = await obtenerPermisosValorAlt([{ 'llave': 'principal', 'valor': id_paso.secundario.id }, { 'llave': 'secundario__generica', 'valor': PREGUNTAS_ENCUESTA }]);
          nuevas_pre = this.trasnformarPreguntas(nuevas_pre, id_paso.secundario.id);
          preguntas.push(nuevas_pre)
          this.setState({ preguntas, PreObtenidas: PreObtenidas + 1 });

        }
        this.setState({ cargando: false, paso_actual })
      } else this.setState({ confirmar: true });
    } else this.props.actualizarMensaje({ titulo: `Seleccione respuestas en las siguientes preguntas : ${items}`, tipo: 'info', mostrar: true, tiempo: 6000 });
  }

  validar_respuestas() {
    let { paso_actual, preguntas } = this.state;
    let respuestas = preguntas[paso_actual];
    let items = [];
    respuestas.map(({ valor, obligatorio }, i) => (!valor && obligatorio === '1') && items.push(i + 1))
    return { sw: items.length > 0 ? false : true, items };
  }

  trasnformarPreguntas(datos, paso) {
    let preguntas = [];
    datos.map(({ secundario }) => {
      let { id, nombre, valora, valorb } = secundario;
      preguntas.push({
        'id': `${id}`,
        'nombre': `${nombre}`,
        'tipo': `${valora}`,
        'valor': '',
        'obligatorio': valorb,
        paso
      })
      return true;
    })
    return preguntas;
  }

  cambiarRespuestaPregunta(id, valor) {
    let { preguntas, paso_actual } = this.state;
    let pregunta = preguntas[paso_actual].find(({ id: idR }) => idR === id)
    if (pregunta) {
      pregunta.valor = valor;
      this.setState({ preguntas })
    }

  }

  organizarPasos = (a, b) => {
    const dato1 = a.secundario.valora
    const dato2 = b.secundario.valora
    let comparison = 0;
    if (dato1 > dato2) comparison = 1;
    else if (dato1 < dato2) comparison = -1;
    return comparison;
  };


  guardarActa = (observaciones, calificacion) => {
    this.setState({ cargando: true, confirmar : false })
    let { id_requisito, preguntas } = this.state;
    let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
    consulta(`api/v1.0/practica/acta/${id_requisito}/crear`, { preguntas, observaciones, calificacion }, 'post',
      (error, estado, resp) => {
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            this.setState({ terminada: true, confirmar: false, })
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        this.props.actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
        this.setState({ cargando: false })
      }, false
    );

  }

  tiposRespuestas(tipo) {
    if (parseInt(tipo) === 1) {
      return [
        'EXCELENTE',
        'BUENO',
        'REGULAR',
        'DEFICIENTE',
      ]
    } else if (parseInt(tipo) === 2) {
      return [
        'SI',
        'NO',
      ]
    } else if (parseInt(tipo) === 3) {
      return [
        '0',
        '1',
        '2',
        '3',
        '4',
      ]
    }
    return [];
  }

  pintarRespuestas(tipo, name, valor) {
    if (tipo !== '4') {
      let respuestas = this.tiposRespuestas(tipo);
      return (
        <RadioGroup name={`${name}`} value={valor} >
          {respuestas.map((t, i) => <FormControlLabel key={i} onClick={() => this.cambiarRespuestaPregunta(name, t)} value={t} control={<Radio />} label={t} />)}
        </RadioGroup>
      )
    } else return <TextField style={{ width: '100%', margin: '5px 0px 5px 0px' }} value={valor} placeholder='Ingrese Respuesta' onChange={({ target: { value } }) => this.cambiarRespuestaPregunta(name, value)} />

  }

  getPasos = () => {
    let { pasos } = this.state;
    let steps = [];
    pasos.map(({ secundario }, i) => steps.push({ label: secundario.nombre }))
    return steps;
  }


  pintar = () => {
    let { preguntas, paso_actual, pasos, iniciar, descripcion_req, nombre_req } = this.state;
    let { tipo } = this.props;
    let descripciones = descripcion_req.split(';')
    let descripcion = (descripciones.length == 2) ? descripciones[1] : ''

    if (!iniciar) return (
      <div style={{ width: "100%", textAlign: "center", margin: "0 auto", padding: "10px 0px 30px 0px", height: "100hv", backgroundColor: 'white', }}>
        { parseInt(tipo) === 2 &&
          <AppBar position="fixed" elevation={0}>
            <Toolbar>
              <div style={{ padding: "5px 0px 5px 3px", color: 'white', textAlign: 'left' }}>
                <p><span style={{ marginLeft: "10px" }}>{nombre_req.toUpperCase()}</span> </p>
              </div>
            </Toolbar>
          </AppBar>
        }
        <TareasTerminadas marginBottom={"3px"} mensaje={'Hola, soy EMMA. Te invito a responder una serie de preguntas solo te tomara unos minutos'} marginTop='7%' imagen={emma_s} widthImg="7%" />
        <Typography variant="subtitle1" color="textSecondary" component="p">{descripcion}</Typography>
        <div style={{ width: "100%", textAlign: 'left', }}>
          <BarraProceso steps={this.getPasos()} activeStep={-1} />
        </div>
        <Button variant="contained" color="primary" style={{ width: "30%", minWidth: 200, color: 'white' }} onClick={() => this.setState({ iniciar: true })}>Iniciar</Button>
      </div>
    )
    return (
      <Grid container direction={'row'} spacing={0} style={{ width: "100%", textAlign: "center", margin: "0 auto", padding: "3px 0px 30px 0px", height: "100hv", backgroundColor: 'white', }}>
        <Grid item xs={12} sm={12} md={12}>
          {this.renderTiempo()}
        </Grid>
        <Grid item xs={12} sm={12} md={12} className='contenido_encuesta'>
          <Paper elevation={0} square className="scroll" style={{ width: "100%", padding: "0px 0px 30px 0px" }}>
            {
              preguntas[paso_actual].map(({ nombre, id, tipo, valor, obligatorio }, i) => {
                return (
                  <FormControl style={{ padding: "10px 0px 0px 10px", width: "95%" }} key={id}>
                    <FormLabel style={{ textAlign: 'justify' }} component="legend">{`${i + 1}) ${nombre} ${obligatorio && '*'}`}</FormLabel>
                    {this.pintarRespuestas(tipo, id, valor)}
                  </FormControl>
                )
              })
            }
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} >
          <BottomAppBar paso_actual={paso_actual} pasos={pasos} callback={(n) => this.cambiarPaso(n)} />
        </Grid>
      </Grid >
    )
  }

  renderTiempo = () => {
    let { pasos, paso_actual } = this.state;
    let { tipo } = this.props;
    if (parseInt(tipo) === 1) return (
      <AppBar position="fixed" elevation={0} style={{ backgroundColor: 'white', top: 55 }}>
        <Toolbar>
          <div style={{ padding: "5px 0px 5px 3px", color: 'black' }}>
            <p><span className='step_pers' style={{ color: 'white' }}>{paso_actual + 1}</span> <span style={{ marginLeft: "10px" }} > {pasos[paso_actual].secundario.nombre}</span> </p>
          </div>
        </Toolbar>
      </AppBar>
    )
    if (parseInt(tipo) === 2) return (
      <AppBar position="fixed" elevation={0}>
        <Toolbar>
          <div style={{ padding: "5px 0px 5px 3px", color: 'white', textAlign: 'left' }}>
            <p><span className='step_pers' style={{ backgroundColor: 'white', color: coloresEmma.primarycolor }}>{paso_actual + 1}</span> <span style={{ marginLeft: "10px" }} > {pasos[paso_actual].secundario.nombre}</span> </p>
          </div>
        </Toolbar>
      </AppBar>
    )
  }

  render() {
    let { cargando, terminada, encontrada, confirmar, pasos, calificacion } = this.state;
    return (
      <div >
        <EnviarMensajeNota calificacion={calificacion} modal={confirmar} titulo='¿ Enviar Datos ?' enviar={(observaciones, calificacion) => calificacion >= 0 && calificacion <= 5 ? this.guardarActa(observaciones, calificacion) : this.props.actualizarMensaje({ titulo: 'La nota debe ser un numero entre 0 y 5', tipo: 'info', mostrar: true, tiempo: 6000 })} mostrarModal={confirmar => this.setState({ confirmar })} />
        {
          !cargando ?
            <div style={{ width: "100%", height: "100hv", }}>
              {(!terminada && encontrada) && this.pintar()}
              {(terminada && encontrada) && <div>
                <TareasTerminadas mensaje='Hola, los datos fueron enviados con éxito. Muchas gracias por tu tiempo' marginTop='7%' imagen={emma_s} widthImg="7%" marginBottom='3px' />
                <div style={{ width: "100%", height: "100hv", }}>
                  <BarraProceso steps={this.getPasos()} activeStep={pasos.length} />
                </div>
              </div>}

              {(!terminada && !encontrada) && <TareasTerminadas mensaje='Hola, este proceso no existe o fue finalizado anteriormente' marginTop='7%' imagen={emma_s} widthImg="7%" />}
            </div>
            : <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />

        }

      </div>
    )

  };
}

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  actualizarMensaje,
}

PracticasAgregarActa.defaultProps = {
  tipo: 1,
  id_requisito: '',
};


export default connect(mapStateToProps, mapDispatchToProps)(PracticasAgregarActa)


