import React from 'react';
import { BottomNavigation, BottomNavigationAction, Hidden } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CreateIcon from '@material-ui/icons/Create';
import SettingsIcon from '@material-ui/icons/Settings';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import Procesos from '@material-ui/icons/ImportContacts';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import LoopIcon from '@material-ui/icons/Loop';



const clase = (item, selec, tipo = '') => item === selec ? `item_selec${tipo}` : `item_modulo`;

export function MenuAdministrar({administrador, sele = 0, seleccionar = () => { } }) {
  const pintar = (app, claseN = '') => {
    if (!app) {
      return (
        <div className={claseN}>
          <BottomNavigation showLabels className={clase('inicio', sele)} onClick={() => seleccionar('inicio')}>
            <BottomNavigationAction label={'Procesos'} icon={<LibraryBooks />} />
          </BottomNavigation>
          
          {
            administrador &&
            <BottomNavigation showLabels className={clase('administrar', sele)} onClick={() => seleccionar('administrar')}>
              <BottomNavigationAction label={'Administrar'} icon={<SettingsIcon className='menu_icono' />} />
            </BottomNavigation>
          }
        </div>
      )
    } else {
      return (
        <div className={claseN}>
          <BottomNavigation showLabels >
          <BottomNavigationAction label={'Procesos'} icon={<LibraryBooks />} className={clase('inicio', sele, '_app')} onClick={() => seleccionar('inicio')} />
          
            {
              administrador &&
              <BottomNavigationAction label={'Administrar'} icon={<SettingsIcon />} className={clase('administrar', sele, '_app')} onClick={() => seleccionar('administrar')} />
            }
          </BottomNavigation>
        </div>
      )
    }
  }

  return (
    <div>
      <div>
        <Hidden xsDown> {pintar(false, 'menu_modulo')} </Hidden>
        <Hidden smUp> {pintar(true, 'menu_modulo_app')}</Hidden>
      </div>
    </div>
  );
}

export function MenuEstudiante({ sele = 0, seleccionar = () => { } }) {
  const pintar = (app, claseN = '') => {
    if (!app) {
      return (
        <div className={claseN}>
          <BottomNavigation showLabels className={clase('proceso', sele)} onClick={() => seleccionar('proceso')}>
            <BottomNavigationAction label={'Proceso'} icon={<LocationOnIcon />} />
          </BottomNavigation>
          <BottomNavigation showLabels className={clase('agregar', sele)} onClick={() => seleccionar('agregar')}>
            <BottomNavigationAction label={'Nuevo'} icon={<CreateIcon />} />
          </BottomNavigation>
          <BottomNavigation showLabels className={clase('historial', sele)} onClick={() => seleccionar('historial')}>
            <BottomNavigationAction label={'Historial'} icon={<Procesos />} />
          </BottomNavigation>
        </div>
      )
    } else {
      return (
        <div className={claseN}>
          <BottomNavigation showLabels >
            <BottomNavigationAction label={'Procesos'} icon={<LocationOnIcon className='menu_icono' />} className={clase('proceso', sele, '_app')} value='proceso' onClick={() => seleccionar('proceso')} />
            <BottomNavigationAction label={'Nuevo'} icon={<CreateIcon />} className={clase('agregar', sele, '_app')} onClick={() => seleccionar('agregar')} />
            <BottomNavigationAction label={'Historial'} icon={<Procesos className='menu_icono' />} className={clase('historial', sele, '_app')} value='historial' onClick={() => seleccionar('historial')} /> 
          </BottomNavigation>
        </div>
      )
    }
  }

  return (
    <div>
      <div>
        <Hidden xsDown> {pintar(false, 'menu_modulo')} </Hidden>
        <Hidden smUp> {pintar(true, 'menu_modulo_app')}</Hidden>
      </div>
    </div>
  );
}

export function TutoriasMenu({ sele = 0, seleccionar = () => { } }) {
  const pintar = (claseN = '') => {
    return (
      <div className={claseN}>
        <BottomNavigation showLabels >
          <BottomNavigationAction label={'Detalle'} icon={<ImportContactsIcon />} className={clase('detalle', sele)} onClick={() => seleccionar('detalle')} />
          
          <BottomNavigationAction label={'Estados'} icon={<LoopIcon />} className={clase('estados', sele)} onClick={() => seleccionar('estados')} />
        </BottomNavigation>
      </div>
    )
  }

  return pintar('menu_modal');
}


export function MenuGestionar({ sele = 0, seleccionar = () => { } }) {
  const pintar = (claseN = '') => {
    return (
      <div className={claseN}>
        <BottomNavigation showLabels >
          <BottomNavigationAction label={'Gestionar'} icon={<LoopIcon />} className={clase('gestion', sele)} onClick={() => seleccionar('gestion')} />
        </BottomNavigation>
      </div>
    )
  }

  return (
    <div>
      {pintar('menu_modal')}
    </div>
  );
}