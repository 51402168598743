import React from 'react'
import * as Funciones from '../../../../global/js/funciones';
import { Button, Dialog, DialogActions, DialogContent, FormControl, FormHelperText, Grid, Paper, TextField } from '@material-ui/core';
import TituloAccion from '../../../general/TituloAccion';
import AccionesRapidas from '../../../general/AccionesRapidas';
import permisosIcon from '../../../../global/imagenes/lightbulb.png';
import fechaIcon from '../../../../global/imagenes/calendar.png';
import { useDispatch } from 'react-redux';
import { actualizarMensaje } from '../../../../redux/actions/actGlobal';
import AppBarModal from '../../../general/AppBarModal';
import { BtnForm } from '../../../general/BotonesAccion';

const AdminAdministrar = () => {

    const [seleccion, setSeleccion] = React.useState('inicio')
    const [modalCambiarPeriodo, setModalCambiarPeriodo] = React.useState(false);
    const [periodoactual, setPeriodoActual] = React.useState('');
    const isInitialMount = React.useRef(true);
    React.useEffect(() => {
        //Obtengo los prceosos activos de la persona activa.
        if (isInitialMount.current) {
            isInitialMount.current = false
            obtenerPeriodo()
        }
    })

    const obtenerPeriodo = async () => {
        let periodo = await Funciones.obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Cur' }]);
        setPeriodoActual(periodo)
        setPeriodoActual(periodo[0].nombre)

    }

    const PintarInicio = (props) => {
        return (
            <div style={{ padding: '2% 2% 0% 2%' }} >
                <Grid container direction={'row'} spacing={1} justify={'center'} >
                    <Grid item xs={12} sm={12} md={10} lg={8} >
                        <TituloAccion principal='' alterno='¿ Que deseas hacer ?' />
                        <Paper elevation={0} square className="scroll contenido_fijo">
                            <Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
                                <Grid item xs={12} sm={12} md={6} lg={6} >
                                    <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => setSeleccion('permisos')}>
                                        <AccionesRapidas imagen={permisosIcon} titulo='¡ Controlar Permisos !' sub_titulo='' des_sub_titulo='Verifica, asigna y retira permisos a los gestores.' />
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} >
                                    <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => props.setModalCambiarPeriodo(true)}>
                                        <AccionesRapidas imagen={fechaIcon} titulo={`¡ Actualizar Período !`} sub_titulo='' des_sub_titulo={`El periodo actual ${periodoactual}`} />
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        )
    }


    const pintar = () => {
        if (seleccion == 'inicio') {
            return (
                <>
                    <PintarInicio
                        modalCambiarPeriodo={modalCambiarPeriodo}
                        setModalCambiarPeriodo={setModalCambiarPeriodo}
                    />
                    <ModalChangePeriodo
                        modalCambiarPeriodo={modalCambiarPeriodo}
                        setModalCambiarPeriodo={setModalCambiarPeriodo}
                        obtenerPeriodo={obtenerPeriodo}
                    />
                </>
            )
        }
    }


    const ModalChangePeriodo = function (props) {
        const dispatch = useDispatch();
        const [periodoact, setperiodoact] = React.useState("")

        const modalChangePeriodoClose = () => {
            props.setModalCambiarPeriodo(false)
        }
        const onInputChange = (e) => {
            setperiodoact(e.target.value);
        };

        const handleOnchange = async () => {

            if (periodoact.length != 5) {
                let titulo = "Digite Un Periodo correcto"
                let tipo = "info"

                dispatch(actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 }));
            } else {
                let p = await Funciones.obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Cur' }]);
                if (p.length > 0) {
                    let titulo = 'Ha ocurrido un error, contacte con el administrador.'
                    let tipo = "error"
                    let { error, estado, resp } = await Funciones.modificarValorGenericaAlter(p[0].id, { 'nombre': periodoact })
                    if (!error) {
                        if (estado === 200) {
                            let titulo = 'Periodo Actualizado Corretamente';
                            let tipo = 'success';
                            props.setModalCambiarPeriodo(false)
                            dispatch(actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 }));
                            props.obtenerPeriodo()
                        } else {
                            tipo = 'info';
                            titulo = resp.titulo ? resp.titulo : Funciones.mostrarError(resp)
                        }
                    }
                    actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });

                }
            }

        }

        return (
            <div>
                <Dialog fullWidth={true} maxWidth='xs' fullScreen={window.innerWidth < 600} open={props.modalCambiarPeriodo} onClose={() => { modalChangePeriodoClose() }} aria-labelledby="form-dialog-title">

                    <AppBarModal mostrarModal={() => { modalChangePeriodoClose() }} titulo='Nuevo Periodo' titulo_accion="" accion="" />
                    {/* Contenido del modal del formulario de */}
                    <DialogContent>
                        <Grid item xs={12}>
                            <FormControl fullWidth >
                                <TextField
                                    value={periodoact}
                                    fullWidth
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    onChange={onInputChange}
                                    label='Periodo'
                                    required={true}
                                >
                                </TextField>
                            </FormControl>
                            <FormHelperText></FormHelperText>
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <BtnForm texto="Cerrar" callback={() => { modalChangePeriodoClose() }} />
                        <BtnForm texto="Aceptar" callback={() => { handleOnchange() }} />
                    </DialogActions>

                </Dialog>
            </div>
        )
    }

    return (
        <div>
            {pintar()}
        </div>
    )
}

export default AdminAdministrar
