
import React, { Component } from 'react';
import { connect } from "react-redux";
import { Grid, Paper, Typography, Card, CardHeader, CardContent, Avatar } from '@material-ui/core'
import { actualizarMensaje, mostrarModulo } from "../../redux/actions/actGlobal";
import { mostrarModalAddRea, mostrarModalDetalleRea } from "../../redux/actions/actRea";
import { MenuEstudiante } from "./rea/ReaMenu";
import { orange } from '@material-ui/core/colors';
import assigment from '../../global/imagenes/assigment.png';
import ReaEncuesta from './rea/reaEncuesta'
import DetalleRea from './rea/detalleRea'
import revisar from '../../global/imagenes/revisar.png';
import {TrafficRounded, LibraryBooksRounded, Description} from '@material-ui/icons'
import { Container, ActionBadge, ProgressCircle, Banner } from './ui';
import { obtenerMisDatos, consulta, mostrarError, obtenerPermisosValorAlt} from "../../global/js/funciones";
import TareasTerminadas from "../general/TareasTerminadas";
import emma_w from '../../global/imagenes/emma_w.png';
import emma_s from '../../global/imagenes/emma_s1.png'
import AccionesRapidas from '../general/AccionesRapidas'
import AlertasSimple from '../general/AlertasSimple';
import DescriptionEstilo from './rea/DescriptionEstilo';
import RecomendacionesEstilo from './rea/RecomendacionesEstilo';
import { LISTARECOMENDACIONES } from './rea/helper';



class ReaEstudiantes extends Component {

  constructor(props) {
    super(props)
    this.state = {
      menu: true,
      seleccion: 'proceso',
      cargando: true,
      datos_sesion: [],
      programas: [],
      procesos : [],
      telefono:'',
      celular:'',
      correo_personal:'',
      habilitado:[],
      info:false,
      administrador:false,
      loading: false,
      tipo_aprendizaje:'',
      descripcion_tipo_aprendizaje:'',
      recomendaciones:[],
      dialogDescripcion: false,
      dialogRecomendaciones: false,
      imagen:'',
    }
    this.toggleDialog = this.toggleDialog.bind(this)
    this.obtenerDescripcion = this.obtenerDescripcion.bind(this)
    this.obtenerRecomendaciones = this.obtenerRecomendaciones.bind(this)
  }

  async componentDidMount() {
    let { mostrarModulo } = this.props;
    let {habilitado} = this.state;
    mostrarModulo({ ruta: '/rea', nombre: 'Estilos de Aprendizaje' })
    let datos_sesion = await obtenerMisDatos();
    this.cambiarEstado({datos_sesion})
    this.esAdministrador();
    habilitado  = await this.obtenerDatoHabilitado();
    let { administrador } = this.state;
    if(habilitado.length !== 0 || administrador){
      this.setState({info: true })
      this.obtenerProgramas()
    }else{
      this.setState({cargando:false})
    }
    
  }

  async componentDidUpdate({}, { seleccion}) {
  }

  cambiarEstado = (estados) => {
    this.setState(estados)
  }

  esAdministrador() {
    let { usuario } = this.props;
    if (usuario && usuario.perfil && usuario.perfil.codigo === "Per_Adm") {
      this.setState({ administrador: true })
     
    }
  }

  obtenerProgramas = () => {
    let { datos_sesion } = this.state
    let programas = datos_sesion.programas
    this.cambiarEstado({ programas }) 
    if(programas.length > 0){
      this.obtenerDatosIniciales()
    }else{
      this.setState({cargando:false})
    }
  }
 
  async obtenerDatosIniciales() {
    this.setState({ loading:true })
    let { datos_sesion } = this.state
    let telefono = datos_sesion.persona.telefono
    let celular = datos_sesion.persona.celular
    let correo_personal=datos_sesion.persona.correo_personal
    let procesos = await this.obtenerEncuestas();
    this.setState({ procesos, telefono, celular, correo_personal, cargando:false })
    if(procesos.length !== 0){
      this.obtenerDatosEstadistica();
    }
  }

  async obtenerDatosEstadistica(){
    this.setState({ cargando: true })
    let datosEstadisticas = await this.obtenerEstadistica();
    let recomendaciones=[]
    this.setState({tipo_aprendizaje :datosEstadisticas[0].tipo_aprendizaje.nombre, descripcion_tipo_aprendizaje :datosEstadisticas[0].tipo_aprendizaje.descripcion,
      recomendaciones:datosEstadisticas[0].tipo_aprendizaje.valorc, imagen:datosEstadisticas[0].tipo_aprendizaje.archivo, cargando: false})
      if(datosEstadisticas.length > 0){
        let listaRecomendaciones = await obtenerPermisosValorAlt([{ 'llave': 'secundario__generica', 'valor': LISTARECOMENDACIONES}]);
        listaRecomendaciones.forEach(function(datoRespuesta, h ) {
          if(datoRespuesta.principal.id === datosEstadisticas[0].tipo_aprendizaje.id) {
             recomendaciones.push(datoRespuesta.secundario)
          }
        })
      }
     this.setState({recomendaciones, loading:false})
  }
  
  async obtenerEncuestas(filtros = []) {
    return new Promise(resolve => {
       consulta(`api/v1.0/rea/list/0?`, null, "post", (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  async obtenerEstadistica(filtros = []) {
    return new Promise(resolve => {
       consulta(`api/v1.0/rea/list/estadistica`, null, null, (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  async obtenerDatoHabilitado() {
    let {datos_sesion} = this.state
   let f =datos_sesion.persona.id
    return new Promise(resolve => {
       consulta(`api/v1.0/rea/habilitado/${f}?`, null, null, (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  guardarRea = async (data) => { 
    let { mostrarModalAddRea, actualizarMensaje } = this.props;
    return new Promise(resolve => {
      consulta(`api/v1.0/rea/create`, data, "post", (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) { 
            tipo = 'success';
            titulo = resp.titulo;
            mostrarModalAddRea(false);
            this.obtenerProgramas()
            this.setState({ seleccion: 'proceso' });

          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
        resolve(estado === 200 && !error ? resp : null);
        
      })
    })
  }  

  toggleDialog(toggle) {
    let { modal, value } = toggle
    this.setState({ [modal]: value })
}

  obtenerDescripcion() {
    this.setState({ loading: true })
    this.toggleDialog({ modal: 'dialogDescripcion', value: true })
    this.setState({ loading: false })
       
  }

  obtenerRecomendaciones() {
  this.setState({ loading: true })
  this.toggleDialog({ modal: 'dialogRecomendaciones', value: true })
  this.setState({ loading: false })
     
  }


  pintarDetalleProceso = () => {
    let {modalDetalleRea, mostrarModalDetalleRea, actualizarMensaje} = this.props
    let { procesos}=this.state
    return (
      <>
          <Grid item xs={12} md={11} >
            <Paper elevation={3} style={{ border: '1px solid #e0e0e0' }} onClick={() => { mostrarModalDetalleRea(true) }}>
              <AccionesRapidas imagen={revisar} titulo='¡ Más Información !' sub_titulo='Bien' des_sub_titulo=', Aquí puedes ver toda la información.' />
            </Paper>
          </Grid>
          <DetalleRea
            proceso={procesos[0]}
            actualizarMensaje={actualizarMensaje}
            modalDetalleRea={modalDetalleRea}
            mostrarModalDetalleRea={mostrarModalDetalleRea}
            cargar="true"
           />
      </>
    );
  };

  pintarInformacionBasica = () => {
  let { procesos, tipo_aprendizaje, descripcion_tipo_aprendizaje, recomendaciones, loading, 
    dialogDescripcion, dialogRecomendaciones, imagen}=this.state
    return (
      <>
      <Container loading={loading} >
        <div style={{ flex: '1 1 70%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}></div>
          <Banner nombre={procesos[0].usuario_registro.primer_nombre} programa={procesos[0].programa.nombre} />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ActionBadge color="#f57c00" reverse={true} Icon={TrafficRounded} text="Descripción" action={this.obtenerDescripcion} />
            <div style={{ margin: '20px', display: 'grid', placeItems: 'center' }}>
              <ProgressCircle percent={tipo_aprendizaje} text="TU ESTILO PREDOMINANTE" color="#900807" loading={loading} />
            </div>
            <ActionBadge color="#00c853" Icon={LibraryBooksRounded} text="Recomendaciones" action={this.obtenerRecomendaciones}/>
          </div>
          <DescriptionEstilo status={dialogDescripcion} toggle={this.toggleDialog} data={descripcion_tipo_aprendizaje} img={imagen} />
          <RecomendacionesEstilo status={dialogRecomendaciones} toggle={this.toggleDialog} data={recomendaciones} img={imagen} />
      </Container>
      </>
    )
  }


  render() {
    let {modalAddRea, mostrarModalAddRea, actualizarMensaje} = this.props
    let { info, programas, telefono, celular, correo_personal, procesos, cargando } = this.state;
    if (!cargando){
      if (info){
        if(programas.length !== 0){ 
          if (procesos.length === 0) {
            return (
            <>
              <Grid container direction={'row'} justifyContent={'center'} style={{ paddingTop: 40 }}>
                  <Grid item xs={12} sm={6} md={4} lg={4} style={{ padding: '2% 2% 0% 2%' }} onClick={() => { mostrarModalAddRea(true) }}>
                    <Paper elevation={8} square>
                      <Card style={{ maxWidth: "100%", height: '380px', cursor: 'pointer' }} elevation={1}>
                        <CardHeader
                          avatar={<Avatar aria-label="recipe" style={{ backgroundColor: orange[500] }}>R</Avatar>}
                          title={`Reconocimientos de Estilos de Aprendizaje (REA)`}
                        />
                        <CardContent >
                          <Grid container direction={'row'} justifyContent={'center'}>
                            <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center', height: "150px" }}>
                              <img src={assigment} style={{ width: '150px', maxWidth: '200px', minWidth: '100px', height: "150px" }} alt='Imagen Tipo' />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} style={{ padding: "15px 10px 0px 10px", }}>
                              <Typography variant="body2" color="textSecondary" component="p" align='justify'>
                                Para iniciar la prueba sobre sus estilos de aprendizaje Dar click aqui!
                                  </Typography>
                            </Grid>
                          </Grid>
                        
                        </CardContent>
                      </Card>
                    </Paper>
                  </Grid> 
                </Grid>
                <ReaEncuesta
                  actualizarMensaje={actualizarMensaje}
                  modalAddRea={modalAddRea}
                  mostrarModalAddRea={mostrarModalAddRea} 
                  celular={celular}
                  telefono={telefono}
                  correo_personal={correo_personal}
                  programas={programas}
                  guardarRea={this.guardarRea}
                />
            </>
          );
          } else {
            return(
              <>
                  <Grid container>
                      <Grid item xs={12}  md={8}>
                        {this.pintarInformacionBasica()}
                      </Grid>
                      <Grid item xs={12}  md={4} style={{ margin: '2% 0 0' }} >
                          {this.pintarDetalleProceso()}
                      </Grid>
                  </Grid>
              </>
            )
          }
      }else{
        return(
          <TareasTerminadas mensaje='Lo sentimos usted no tiene un programa asignado, comuniquese al siguiente correo tecnologia@cuc.edu.co' marginTop='7%' imagen={emma_w} widthImg="7%" />
        )
      }
    }else{
      return(
        <TareasTerminadas mensaje='Lo sentimos usted no esta Habilitado para realizar la encuesta, comuniquese con su Director de programa' marginTop='7%' imagen={emma_w} widthImg="7%" />
      )
      }
  }else{
    return(
    <TareasTerminadas mensaje='Cargando...' marginTop='7%' imagen={emma_w} widthImg="7%" />
    )
  }
}
}


//CARGO ESTADOS DEL MODULO
const mapStateToProps = (state) => { 
  let { modalAddRea,  modalDetalleRea } = state.redRea
  let { usuario } = state.redGlobal;
  return {
    usuario,
    modalAddRea,
    modalDetalleRea,
  }
};

//CARGO FUNCIONES DEL MODULO
const mapDispatchToProps = {
  actualizarMensaje,
  mostrarModulo,
  mostrarModalAddRea,
  mostrarModalDetalleRea,

}



export default connect(mapStateToProps, mapDispatchToProps)(ReaEstudiantes);


