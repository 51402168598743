import React, { Component } from 'react'
import { Dialog, DialogContent, DialogActions } from '@material-ui/core';
import AppBarModal from '../general/AppBarModal';
import { BtnForm, BtnDetalle, } from '../general/BotonesAccion';
import ListarDatos from '../general/ListarDatos';
import ListarNotas from './ListarNotas';



class ListarModalidades extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openNotas: false,
      modalidad: {}
    };
  }

  async componentDidUpdate({ data, status }, { }) {
    let { data: dataNew, status: statusNew } = this.props;
    if (statusNew !== status) {
      let modalidades = [];
      dataNew.forEach(modalidad => {
        // const index = modalidades.findIndex(data => data.cod_programa === modalidad[1])
        // if (index !== -1) {
          // modalidades[index].periodo.push(modalidad[0])
        // } else {
          modalidades.push({
            // periodo: [modalidad[0]],
            cod_programa: modalidad[3],
            nombre: modalidad[8],
            // modalidad: modalidad[9],
            tipo: modalidad[9],
            cod_pensum: modalidad[4],
            id_alum: modalidad[10]
          })
        // }
      })
      this.setState({ modalidades: modalidades.sort((a, b) => a.modalidad - b.modalidad) })
    }
  }

  render() {
    let { modalidades, openNotas, modalidad } = this.state;
    let { status, toggle, current_user } = this.props

    const acciones = (data) => {
      const onClickNotas = () => {
        this.setState({ openNotas: true, modalidad: data })
      }
      let detalle = <BtnDetalle callback={() => onClickNotas(data)} color='#01579b' texto='Ver Notas' />;
      return <div>{detalle}</div>;
    }

    return (
      <>
        <Dialog open={status} onClose={() => toggle({ modal: 'dialogNotas', value: false })} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="sm">
          <AppBarModal titulo="Modalidades" mostrarModal={() => toggle({ modal: 'dialogNotas', value: false })} titulo_accion="" accion="" />
          <DialogContent style={{ padding: 0 }} className='scroll'>
            <ListarDatos
              id="tbl_listar_modalidades"
              descargar="EMMA - MODALIDADES"
              datos={modalidades}
              opciones={false}
              agregar={false}
              buscar={false}
              acciones={(data) => acciones(data)}
              avatar={({ nombre }) => nombre.charAt(0).toUpperCase()}
              color_avatar={() => ({ 'backgroundColor': '#fbc02d', 'color': '#ffffff' })}
              titulo={"Modalidades"}
              fila_principal={({ nombre }) => nombre}
              filas={[
                // { 'id': 'periodo', 'mostrar': ({ periodo }) => periodo.toString()},
                // { 'id': 'cod_programa', 'mostrar': ({ cod_programa }) => cod_programa},
                { 'id': 'tipo', 'mostrar': ({ tipo }) => tipo },
              ]}
            />
          </DialogContent>
          <DialogActions>
            <BtnForm texto="Cerrar" callback={() => toggle({ modal: 'dialogNotas', value: false })} />
          </DialogActions>
        </Dialog>
        <ListarNotas
          status={openNotas}
          toggle={(value) => this.setState({ openNotas: value })}
          modalidad={modalidad}
          current_user={current_user}
        />
      </>

    );
  }
}

export default ListarModalidades