import React from 'react';
import PropTypes from 'prop-types';
import trophy from "../../global/imagenes/trophy.png";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    margin: '0 auto',
    width: '200px',
    '& > * + *': {
      marginTop: theme.spacing(2),
      
    },
  },
}));

const TareasTerminadas = ({ mensaje, width = "100%", marginTop = '20%', imagen = trophy, widthImg = "5%", marginBottom = '0%', cargando = false }) => {
  return (
    <div className="text-center" style={{ width,  marginBottom}}>
      <img src={imagen} alt="Tareas Terminadas" style={{ width: widthImg, minWidth: "80px", marginTop }} />
      <Typography variant="subtitle1" color="textSecondary" component="p">
        {mensaje}.
      </Typography>
      {cargando && <Cargando />}
    </div>
  );
}

function Cargando() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <LinearProgress color="secondary" />
    </div>
  );
}

//Props del componente
TareasTerminadas.propTypes = {
  mensaje: PropTypes.string.isRequired,

}


export default TareasTerminadas;
