import React, { Component } from "react";
import { Dialog, DialogActions, DialogContent, Link } from "@material-ui/core";
import AppBarModal from "../general/AppBarModal";
import ListarDatos from "../general/ListarDatos";
import { BtnForm, BtnDetalle, BtnEditar } from "../general/BotonesAccion";
import {
  generarFiltros,
  consulta,
  mostrarError,
  formulario,
} from "../../global/js/funciones";
import EnviarArchivo from "../general/EnviarArchivo";
import TareasTerminadas from "../general/TareasTerminadas";
import emma_w from "./../../global/imagenes/emma_w.png";
import moment from "moment";

export default class BecaAdjuntos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cargando: true,
      documentos: [],
      adjunto: [],
    };
  }

  async componentDidUpdate({ modalAdjuntos }, {}) {
    let { modalAdjuntos: modalDetBecaNew, beca } = this.props;
    if (modalAdjuntos !== modalDetBecaNew) this.obtenerAdjuntos(beca);
  }

  async obtenerAdjuntos(id) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ llave: "beca", valor: id }]);
    consulta(
      `api/v1.0/beca/adjuntos?${f}`,
      null,
      null,
      (error, estado, resp) => {
        if (estado === 200)
          this.setState({ cargando: false, documentos: resp });
      }
    );
  }

  enviarAdjunto = (archivo) => {
    let { beca, actualizarMensaje } = this.props;
    let { adjunto } = this.state;
    const data = new FormData();
    data.append(`archivo`, archivo);
    data.append(`codigo`, adjunto.requisito.id);
    data.append(`adjunto`, adjunto.id);
    this.setState({ cargando: true });
    formulario(
      `api/v1.0/becas/${beca}/subirAdjunto`,
      data,
      "post",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            this.obtenerAdjuntos(beca);
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 9000 });
      }
    );
  };

  render() {
    let {
      modalAdjuntos,
      mostrarModalAdjuntos,
      infoBeca,
      actualizarMensaje,
      mostrarModalAddAdjBeca,
      modalAddAdj,
    } = this.props;
    let { documentos, cargando } = this.state;

    const acciones = (row) => {
      const onClickReemplazar = () => {
        this.setState({ adjunto: row });
        mostrarModalAddAdjBeca(true);
      };

      let detalle = (
        <Link href={`${row.archivo}`} target="_blank">
          <BtnDetalle callback={() => {}} />
        </Link>
      );
      let reemplazar = infoBeca.estado_actual.codigo === "Est_bec_env" &&
        row.validacion != "2" && (
          <BtnEditar
            callback={() => onClickReemplazar()}
            color="#00bfa5"
            texto="Reemplazar"
          />
        );
      return (
        <div>
          {detalle} {reemplazar}
        </div>
      );
    };

    const obtenerColor = ({ validacion }) => {
      let color = "white";
      const colores = {
        1: { backgroundColor: "#f5f5f5", color: "#757575" },
        2: { backgroundColor: "#01519B", color },
        3: { backgroundColor: "#b71c1c", color },
      };
      return colores[validacion];
    };

    return (
      <div>
        <EnviarArchivo
          modalAdd={modalAddAdj}
          enviarArchivo={(archivo) => {
            if (archivo) {
              this.enviarAdjunto(archivo);
              mostrarModalAddAdjBeca(false);
            } else
              actualizarMensaje({
                titulo: "Seleccione Archivo",
                tipo: "info",
                mostrar: true,
                tiempo: 6000,
              });
          }}
          mostrarModalAdd={mostrarModalAddAdjBeca}
          titulo="Reemplazar Documento"
        />

        <Dialog
          open={modalAdjuntos}
          onClose={() => mostrarModalAdjuntos(false)}
          fullWidth={true}
          maxWidth="sm"
        >
          <AppBarModal
            titulo="¡ Mis Documentos !"
            mostrarModal={() => mostrarModalAdjuntos(false)}
            titulo_accion=""
          />
          <DialogContent style={{ padding: 0 }} className="scroll">
            {!cargando ? (
              <ListarDatos
                id="tbl_mis_adjuntos"
                descargar="DOCUMENTOS BECAS - EMMA"
                datos={documentos}
                buscar={false}
                avatar={({ validacion_text }) =>
                  validacion_text.charAt(0).toUpperCase()
                }
                color_avatar={(row) => obtenerColor(row)}
                acciones={(row) => acciones(row)}
                fila_principal={({ requisito }) =>
                  requisito.nombre.toUpperCase()
                }
                filas={[
                  { id: "nombre_archivo", enLista: false },
                  {
                    id: "validacion_text",
                    enLista: true,
                    mostrar: ({ validacion_text }) =>
                      validacion_text.toUpperCase(),
                  },
                  {
                    id: "fecha_registro",
                    enLista: true,
                    mostrar: ({ fecha_registro }) =>
                      moment(fecha_registro).format("YYYY-MM-DD, h:mm a"),
                  },
                  {
                    id: "valido",
                    enLista: true,
                    mostrar: ({ usuario_valida }) =>
                      `${
                        usuario_valida
                          ? `VALIDADO: ${usuario_valida.primer_nombre} ${usuario_valida.primer_apellido} ${usuario_valida.segundo_apellido} - ${usuario_valida.correo}`
                          : ""
                      }`,
                  },
                  {
                    id: "nota",
                    enLista: true,
                    mostrar: ({ validacion, nota }) =>
                      `${validacion == 3 ? `${nota}` : ""}`,
                  },
                ]}
              />
            ) : (
              <TareasTerminadas
                mensaje="Cargando.."
                marginTop="7%"
                imagen={emma_w}
                widthImg="7%"
              />
            )}
          </DialogContent>
          <DialogActions>
            <BtnForm
              texto="CERRAR"
              callback={() => mostrarModalAdjuntos(false)}
            />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
