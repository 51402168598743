// React
import React, { Component } from 'react';
// Components
import ValoresAgregar from '../../genericas/valores/ValoresAgregar'
import ListarDatos from '../../general/ListarDatos'
import ModalAyuda from '../../general/ModalAyuda'

// funciones
import { validarCorreo } from '../../../global/js/funciones'

class PersonasExternas extends Component {
	constructor(props) {
		super(props);
		this.state = {
			seleccion: 0,
			modalAyuda: false,
		};
	}

	submit = (codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, archivo) => {
		if (validarCorreo(valora)) this.props.guardarPersonaExterna(codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, archivo)
		else this.props.actualizarMensaje({ titulo: "Ingrese un correo válido", tipo: "info", mostrar: true, tiempo: 2000 })
	}

	modalAddExterno = () => {
		let { modalAddPersona, mostrarModalAddPersona } = this.props
		return (
			<ValoresAgregar
				mostrarABC={true}
				modalAddValores={modalAddPersona}
				mostrarModalAddValores={(show) => mostrarModalAddPersona(show)}
				guardarValor={(codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, archivo) => this.submit(codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, archivo)}
				inputs={{
					inputNombre: { 'label': 'Nombre Completo', 'mostrar': true, 'tipo': 'text', 'requerido': true },
					inputValora: { 'label': 'Correo', 'mostrar': true, 'tipo': 'email', 'requerido': true },
					inputValorb: { 'label': 'Ocupación(Ejemplo : Abogado, Ingeniero, etc)', 'mostrar': true, 'tipo': 'text', 'requerido': true },
					inputValorc: { 'label': 'Celular', 'mostrar': true, 'tipo': 'number', 'requerido': true },
					inputCodigo: "",
					inputDescripcion: "",
					inputValord: "",
					inputValore: "",
					inputValorf: "",
					inputValorg: "",
					inputArchivo: "",
				}}
				titulo='Nueva Persona Externa'
			/>
		)
	}

	render() {
		let { personas, mostrarModalAddPersona } = this.props
		let { modalAyuda } = this.state
		return (
			<div>
				<ListarDatos
					id="tbl_externos"
					datos={personas}
					titulo="Lista Personas externas"
					opciones={true}
					agregar={true}
					ayuda={true}
					avatarImg={true}
					actAgregar={() => mostrarModalAddPersona(true)}
					actAyuda={() => this.setState({ modalAyuda: true })}
					avatar={({ nombre }) => nombre && nombre.toUpperCase().charAt(0)}
					fila_principal={({ nombre }) => nombre && nombre.toUpperCase()}
					filas={[
						{ 'mostrar': ({ valora }) => valora, 'id': 'correo', 'nombre': 'Email: ' },
						{ 'mostrar': ({ valorc }) => valorc && valorc, 'id': 'celular', 'nombre': 'Cel: ' },
						{ 'mostrar': ({ valorb }) => valorb && valorb, 'id': 'ocupacion', 'nombre': 'Cargo: ' },
					]}
				/>

				{/* Modales */}
				{this.modalAddExterno()}
				<ModalAyuda
					modalAyuda={modalAyuda}
					mostrarModalAyuda={() => this.setState({ modalAyuda: false })}
					titulo="¿ Personas Externas ?"
					mensaje1="Las personas externas, son personas que no pertenecen a la Universidad 
					que pueden ser expertos en un área de conocimiento en especifico. las cuales podrás asignar 
					como jurados en las sustentaciones de los trabajos de grado de los estudiantes. 
					Sí desear agregar una persona externa, por favor de click en el botón con el signo (+) que se se encuentra abajo.
					"
				/>
			</div>
		);
	}
}


export default PersonasExternas;
