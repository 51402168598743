import React, { useState, useEffect } from "react";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../redux/actions/actInscripcionesPosgrado";
import { actualizarDataValor } from "../../../redux/actions/actValores";
import { actualizarMensaje } from "../../../redux/actions/actGlobal";

// Material UI
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

// General Components
import ListarDatos from "../../general/ListarDatos";
import AppBarModal from "../../general/AppBarModal";
import {
  BtnAprobar,
  BtnCancelar,
  BtnDetalle,
  BtnEditar,
  BtnForm,
  BtnSeleccion,
} from "../../general/BotonesAccion";
import TareasTerminadas from "../../general/TareasTerminadas";
import BuscarSelect from "../../general/BuscarSelect";

// Global functions
import {
  guardarValorGenerica,
  modificarValorGenericaAlter,
  mostrarError,
  obtenerValoresFiltros,
} from "../../../global/js/funciones";
import moment from "moment";

// Assets
import emma_w from "../../../global/imagenes/emma_w.png";

// Constants
import { CARRERAS } from "../helper";

const Detalle = ({ titulo, open, config, cerrar, cargando = false }) => {
  return (
    <Dialog
      open={open}
      onClose={cerrar}
      fullWidth={true}
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <AppBarModal
        titulo={titulo}
        mostrarModal={cerrar}
        titulo_accion=""
        accion={() => {}}
      />
      <DialogContent style={{ padding: 0 }} className="scroll">
        {cargando ? (
          <TareasTerminadas
            mensaje="Cargando..."
            marginTop="7%"
            imagen={emma_w}
            widthImg="7%"
          />
        ) : (
          <Paper square elevation={0}>
            <Table>
              <TableBody>
                {config.map((campo) => {
                  return (
                    <TableRow key={campo.label}>
                      <TableCell component="th" scope="row">
                        {campo.label}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                        }}
                      >
                        {campo.value}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
        )}
      </DialogContent>
      <DialogActions>
        <BtnForm texto="CERRAR" callback={cerrar} />
      </DialogActions>
    </Dialog>
  );
};

const ModificarPrograma = ({
  titulo,
  open,
  cerrar,
  obtenerProgramas,
  valor,
}) => {
  const [carreraId, setCarreraId] = useState("");
  const [codigo, setCodigo] = useState("");
  const [nombre, setNombre] = useState("");
  const [estadoOfertado, setEstadoOfertado] = useState(false);
  const [nivelFormacion, setNivelFormacion] = useState("");
  const [nivelesFormacion, setNivelesFormacion] = useState([
    { value: "maestría", label: "MAESTRÍA" },
    { value: "doctorado", label: "DOCTORADO" },
    { value: "especialización", label: "ESPECIALIZACIÓN" },
  ]);
  const [cargando, setCargando] = useState(false);

  const dispatch = useDispatch();

  const limpiar = () => {
    setCodigo("");
    setNombre("");
    setEstadoOfertado(false);
    setNivelFormacion("");
  };

  useEffect(() => {
    if (valor) {
      changeState(valor);
    }
  }, [valor]);

  const actualizarPrograma = async (e) => {
    setCargando(true);
    let { error, estado, resp } = await modificarValorGenericaAlter(carreraId, {
      codigo: codigo ?? "",
      nombre: nombre.toUpperCase(),
      valora: estadoOfertado ? "ofertado" : "",
      valord: nivelFormacion.value ?? "",
    });
    let titulo = "Ha ocurrido un error, contacte con el administrador.",
      tipo = "error";
    if (!error) {
      if (estado === 200) {
        tipo = "success";
        titulo = resp.titulo;
        cerrar();
        limpiar();
        obtenerProgramas();
        setCargando(false);
      } else {
        tipo = "info";
        titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        setCargando(false);
      }
    }
    dispatch(actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 }));
    e.preventDefault();
  };

  const changeState = ({ id, codigo, nombre, valora, valord }) => {
    setCodigo(codigo);
    setNombre(nombre);
    setEstadoOfertado(valora === "ofertado" ? true : false);
    setNivelFormacion({ value: valord, label: `${valord}`.toUpperCase() });
    setCarreraId(id);
  };

  return (
    <Dialog
      open={open}
      onClose={cerrar}
      fullWidth={true}
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <AppBarModal
        titulo={titulo}
        mostrarModal={cerrar}
        titulo_accion=""
        accion={() => {}}
      />
      <DialogContent className="scroll">
        {cargando ? (
          <TareasTerminadas
            mensaje="Cargando..."
            marginTop="7%"
            imagen={emma_w}
            widthImg="7%"
          />
        ) : (
          <ValidatorForm onSubmit={actualizarPrograma}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <TextValidator
                  id="nombre"
                  label="Nombre"
                  type="text"
                  fullWidth
                  autoFocus
                  name="nombre"
                  value={nombre}
                  validators={["required"]}
                  errorMessages={["Campo requerido"]}
                  onChange={(e) => setNombre(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  id="codigo_inscripciones_posgrado"
                  label="Codigo"
                  type="text"
                  fullWidth
                  autoFocus
                  name="codigo_inscripciones_posgrado"
                  value={codigo}
                  onChange={(e) => setCodigo(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <BuscarSelect
                  datos={nivelesFormacion}
                  cambiarEstado={(value) => setNivelFormacion(value)}
                  valor={nivelFormacion}
                  id="select-nivel-formacion"
                  // nombre="¿Cuál es el nivel de formación?"
                  placeholder="Seleccione nivel de formación"
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={estadoOfertado}
                      onChange={(e) => setEstadoOfertado(e.target.checked)}
                      id="check_ofertar"
                      name="checkOfertar"
                      color="primary"
                    />
                  }
                  label="¿Ofertar programa?"
                />
              </Grid>
            </Grid>
            <Button
              color="primary"
              type="submit"
              id="enviar_form_add_programas"
              className="oculto"
            >
              Guardar
            </Button>
          </ValidatorForm>
        )}
      </DialogContent>
      <DialogActions>
        <BtnForm texto="CERRAR" callback={cerrar} />
        <BtnForm
          texto="GUARDAR"
          callback={() => {
            document.getElementById("enviar_form_add_programas").click();
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

const AgregarPrograma = ({ titulo, open, cerrar, obtenerProgramas }) => {
  const [codigo, setCodigo] = useState("");
  const [nombre, setNombre] = useState("");
  const [estadoOfertado, setEstadoOfertado] = useState(false);
  const [nivelFormacion, setNivelFormacion] = useState("");
  const [nivelesFormacion, setNivelesFormacion] = useState([
    { value: "maestría", label: "MAESTRÍA" },
    { value: "doctorado", label: "DOCTORADO" },
    { value: "especialización", label: "ESPECIALIZACIÓN" },
  ]);
  const [cargando, setCargando] = useState(false);
  const dispatch = useDispatch();

  const limpiar = () => {
    setCodigo("");
    setNombre("");
    setEstadoOfertado(false);
    setNivelFormacion("");
  };

  const guardarPrograma = async (e) => {
    setCargando(true);
    let { error, estado, resp } = await guardarValorGenerica(
      CARRERAS,
      codigo ?? "",
      nombre.toUpperCase(),
      "",
      estadoOfertado ? "ofertado" : "",
      "",
      "posgrado",
      nivelFormacion.value ?? "",
      "",
      "",
      "",
      ""
    );
    let titulo = "Ha ocurrido un error, contacte con el administrador.";
    let tipo = "warning";
    if (!error) {
      if (estado === 200) {
        tipo = "success";
        titulo = resp.titulo;
        cerrar(limpiar);
        obtenerProgramas();
        setCargando(false);
      } else {
        tipo = "info";
        titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        setCargando(false);
      }
    }
    dispatch(actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 }));
    e.preventDefault();
  };

  return (
    <Dialog
      open={open}
      onClose={() => cerrar(limpiar)}
      fullWidth={true}
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <AppBarModal titulo={titulo} mostrarModal={() => cerrar(limpiar)} />
      <DialogContent className="scroll">
        {cargando ? (
          <TareasTerminadas
            mensaje="Cargando..."
            marginTop="7%"
            imagen={emma_w}
            widthImg="7%"
          />
        ) : (
          <ValidatorForm onSubmit={guardarPrograma}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <TextValidator
                  id="nombre"
                  label="Nombre"
                  type="text"
                  fullWidth
                  autoFocus
                  name="nombre"
                  value={nombre}
                  validators={["required"]}
                  errorMessages={["Campo requerido"]}
                  onChange={(e) => setNombre(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  id="codigo_inscripciones_posgrado"
                  label="Codigo"
                  type="text"
                  fullWidth
                  autoFocus
                  name="codigo_inscripciones_posgrado"
                  value={codigo}
                  onChange={(e) => setCodigo(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <BuscarSelect
                  datos={nivelesFormacion}
                  cambiarEstado={(value) => setNivelFormacion(value)}
                  valor={nivelFormacion}
                  id="select-nivel-formacion"
                  // nombre="¿Cuál es el nivel de formación?"
                  placeholder="Seleccione nivel de formación"
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={estadoOfertado}
                      onChange={(e) => setEstadoOfertado(e.target.checked)}
                      id="check_ofertar"
                      name="checkOfertar"
                      color="primary"
                    />
                  }
                  label="¿Ofertar programa?"
                />
              </Grid>
            </Grid>
            <Button
              color="primary"
              type="submit"
              id="enviar_form_add_programas"
              className="oculto"
            >
              Guardar
            </Button>
          </ValidatorForm>
        )}
      </DialogContent>
      <DialogActions>
        <BtnForm texto="CANCELAR" callback={() => cerrar(limpiar)} />
        <BtnForm
          texto="GUARDAR"
          callback={() => {
            document.getElementById("enviar_form_add_programas").click();
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

const InscripcionGestionarPorgramas = () => {
  const [programas, setProgramas] = useState([]);
  const [cargando, setCargando] = useState(false);
  const [ofertado, setOfertado] = useState(false);
  const {
    modalGestionarProgramas,
    modalDetProgramas,
    modalAddProgramas,
    modalModProgramas,
  } = useSelector((state) => state.redInscripcionesPosgrado);
  const { valor } = useSelector((state) => state.redValores);

  const dispatch = useDispatch();
  const {
    mostrarModalGestionarProgramas,
    mostrarModalDetProgramas,
    mostrarModalAddProgramas,
    mostrarModalModProgramas,
  } = bindActionCreators(actionCreators, dispatch);

  useEffect(() => {
    obtenerProgramas();
  }, [ofertado]);

  const obtenerProgramas = async () => {
    setCargando(true);
    const programas = await obtenerValoresFiltros([
      { llave: "generica", valor: CARRERAS },
      { llave: "valorc", valor: "posgrado" },
    ]);
    setProgramas(programas);
    setCargando(false);
  };

  const ofertarPrograma = async (carreraId, ofertar) => {
    setCargando(true);
    let { error, estado, resp } = await modificarValorGenericaAlter(carreraId, {
      valora: ofertar,
    });
    let titulo = "Ha ocurrido un error, contacte con el administrador.",
      tipo = "error";
    if (!error) {
      if (estado === 200) {
        tipo = "success";
        titulo = resp.titulo;
        setOfertado(!ofertado);
        setCargando(false);
      } else {
        tipo = "info";
        titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        setCargando(false);
      }
    }
    dispatch(actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 }));
  };

  const actions = (rowData) => {
    const onClickEditar = (data) => {
      dispatch(actualizarDataValor(data));
      dispatch(mostrarModalModProgramas(true));
    };

    const onClickDetalle = (data) => {
      dispatch(actualizarDataValor(data));
      dispatch(mostrarModalDetProgramas(true));
    };

    const onClickOfertar = (data) => {
      ofertarPrograma(data.id, "ofertado");
    };

    const onClickCancelar = (data) => {
      ofertarPrograma(data.id, "");
    };

    return (
      <div>
        <BtnDetalle callback={() => onClickDetalle(rowData)} />
        <BtnEditar callback={() => onClickEditar(rowData)} />
        {rowData.valora ? (
          <BtnCancelar
            texto="No ofertar"
            callback={() => onClickCancelar(rowData)}
          />
        ) : (
          <BtnSeleccion
            texto="Ofertar"
            callback={() => onClickOfertar(rowData)}
          />
        )}
      </div>
    );
  };

  const configDetail = (data) => {
    let { codigo, valora, valorc, valord, nombre } = data;
    return [
      { label: "Nombre", value: nombre },
      { label: "Codigo", value: codigo },
      { label: "Estado", value: valora ? valora : "No ofertado" },
      { label: "Tipo", value: valorc },
      { label: "Nivel de formación", value: valord },
    ];
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={modalGestionarProgramas}
        onClose={() => dispatch(mostrarModalGestionarProgramas(false))}
      >
        <AppBarModal
          titulo="¡ POSGRADOS !"
          mostrarModal={mostrarModalGestionarProgramas}
          // titulo_accion="CERRAR"
          // accion={() => dispatch(mostrarModalGestionarProgramas(false))}
        />
        <DialogContent style={{ padding: 0 }} className="scroll">
          {cargando ? (
            <TareasTerminadas
              mensaje="Cargando..."
              marginTop="7%"
              imagen={emma_w}
              widthImg="7%"
              cargando={true}
            />
          ) : (
            <ListarDatos
              id="tbl_listar_valores"
              descargar="EMMA - PROGRAMAS DE POSGRADO"
              datos={programas}
              opciones={true}
              agregar={true}
              buscar={true}
              actAgregar={() => dispatch(mostrarModalAddProgramas(true))}
              acciones={(row) => actions(row)}
              titulo={"Lista de programas"}
              fila_principal={({ nombre, id }) => `${id} - ${nombre}`}
              filas={[
                {
                  mostrar: ({ fecha_registro }) =>
                    moment(fecha_registro).format("YYYY-MM-DD, h:mm a"),
                  id: "fecha_registro",
                  enLista: false,
                },
                { id: "id", enLista: false },
                { id: "nombre", enLista: false },
                { id: "descripcion", enLista: false },
                { id: "codigo" },
                { id: "archivo", enLista: false },
                { id: "id", enLista: false },
                { id: "estado", enLista: false },
                { id: "fecha_elimino", enLista: false },
                { id: "generica", enLista: false },
                { id: "valora" },
                { id: "valorb", enLista: false },
                { id: "valorc", enLista: false },
                { id: "valord" },
                { id: "valore", enLista: false },
                { id: "valorf", enLista: false },
                { id: "valorg", enLista: false },
              ]}
            />
          )}
        </DialogContent>
        <DialogActions>
          <BtnForm
            texto="CERRAR"
            callback={() => dispatch(mostrarModalGestionarProgramas(false))}
          />
        </DialogActions>
      </Dialog>
      <Detalle
        cargando={cargando}
        titulo={"¡ Detalle !"}
        open={modalDetProgramas}
        config={valor ? configDetail(valor) : []}
        cerrar={() => dispatch(mostrarModalDetProgramas(false))}
      />
      <AgregarPrograma
        titulo={"¡ Agregar programa !"}
        open={modalAddProgramas}
        cerrar={(funcions) => {
          funcions();
          dispatch(mostrarModalAddProgramas(false));
        }}
        obtenerProgramas={() => obtenerProgramas()}
      />
      <ModificarPrograma
        titulo={"¡ Modificar programa !"}
        open={modalModProgramas}
        valor={valor}
        cerrar={() => dispatch(mostrarModalModProgramas(false))}
        obtenerProgramas={() => obtenerProgramas()}
      />
    </div>
  );
};

export default InscripcionGestionarPorgramas;
