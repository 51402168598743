import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { BtnForm } from "../../general/BotonesAccion";
import AppBarModal from '../../general/AppBarModal'
import Grid from '@material-ui/core/Grid';
import { obtenerValoresFiltros, transformar, consulta } from "../../../global/js/funciones";
import BuscarSelect from '../../general/BuscarSelect';
import InputBuscar from '../../general/InputBuscar';
import BuscarPersona from '../../general/BuscarPersona';
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from '../../../global/imagenes/emma_w.png';
import { ESTADO_ESTUDIANTE, TIPO_ATENCION_ID } from '../Helpers';


class ValidacionesFiltro extends Component {

    constructor(props) {
      super(props)
      this.state = {
        cargando: true,
        cargar: true,
        programas: [],
        tipos: [],
        programa: null,
        tipo: '',
        buscarEstudiante: false,
        estudiante: {
          id: 0,
          nombre: ''
        }
      }
    }
  
    async componentDidUpdate({ modalFiltroValidacion, accionProceso }) {
      let { modalFiltroValidacion: modalFiltroNew, accionProceso: accionProcesoNew } = this.props;
      if (modalFiltroValidacion !== modalFiltroNew && this.state.cargar) {
        this.setState({ cargando: true })
        let programas = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': ESTADO_ESTUDIANTE }, { 'llave': 'estado', 'valor': 1 }]);
        let tipos = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPO_ATENCION_ID }, { 'llave': 'estado', 'valor': 1 }]);
        this.setState({
          'programas': transformar(programas),
          tipos: transformar(tipos),
          cargando: false,
          cargar: false,
        })
      }
  
      if (accionProceso != accionProcesoNew && accionProceso != 'inicio') {
        this.setState({
          programa: null,
          tipo: '',
          estudiante: {
            id: 0,
            nombre: ''
          }
        })
      }
    }
  
    onChange = ({ target }) => {
      let { value, name } = target;
      this.setState({
        [name]: value
      })
    }
  
    resetState = () => {
      let { limpiar, mostrarModalFiltroAtencion } = this.props;
      this.setState({
        programa: null,
        tipo: '',
        estudiante: {
          id: 0,
          nombre: ''
        }
      })
      limpiar();
      mostrarModalFiltroAtencion(false)
    }
  
    onSubmit = e => {
      let { filtrar, actualizarMensaje, accionProceso, mostrarTodos, mostrarModalFiltroAtencion } = this.props;
      let { tipo, programa, estudiante } = this.state;
  
      let filtros = [];
      if (programa !== null) filtros.push({ 'llave': 'programa', 'valor': programa.value });
      if (tipo !== null) filtros.push({ 'llave': 'tipo_atencion', 'valor': tipo.value });
      if (estudiante.id) filtros.push({ 'llave': 'usuario_registro', 'valor': estudiante.id });
      if (filtros.length > 0){
        if (accionProceso == 'inicio') mostrarTodos('Tod_Ate')
        mostrarModalFiltroAtencion(false)
        filtrar(filtros);
      } else actualizarMensaje({ titulo: 'Seleccione Algún filtro', tipo: 'info', mostrar: true, tiempo: 6000 });
      e.preventDefault();
    }
  
    render() {
      let { modalFiltroValidacion, mostrarModalFiltroAtencion, accionProceso } = this.props;
      let { tipo, tipos, programa, programas, cargando, buscarEstudiante } = this.state;
      let habilitar_limpiar = accionProceso !== 'Tod_Ate' && accionProceso !== 'inicio'
      return (
        <>
        <Dialog fullWidth={true} maxWidth="sm" open={modalFiltroValidacion} onClose={() => mostrarModalFiltroAtencion(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <AppBarModal titulo='¡ Aplicar Filtros !' mostrarModal={mostrarModalFiltroAtencion} titulo_accion={ habilitar_limpiar ? "Limpiar" : ''} accion={habilitar_limpiar ? this.resetState : ''} />
          <DialogContent className='scroll'>
            {
                cargando
                  ? <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
                  : <Grid container spacing={3} style={{ marginBottom: '40px' }}>
                    <Grid item xs={12}>
                      <BuscarSelect datos={tipos} cambiarEstado={(value) => this.setState({ tipo: value })} valor={tipo} id='select-tipo' nombre='tipo' placeholder='Seleccione un tipo de atencion' />
                    </Grid>
                    <Grid item xs={12}>
                      <BuscarSelect datos={programas} cambiarEstado={(value) => this.setState({ programa: value })} valor={programa} id='select-programa' nombre='Programa' placeholder='Seleccione un programa' />
                    </Grid>
                  </Grid>
            }
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => mostrarModalFiltroAtencion(false)} />
            {!cargando && <BtnForm texto="FILTRAR" callback={this.onSubmit} />}
          </DialogActions>
        </Dialog >
  
          <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={buscarEstudiante} onClose={() => this.setState({ buscarEstudiante: false })}>
            <AppBarModal titulo={'¡ Buscar Estudiante !'} mostrarModal={() => this.setState({ buscarEstudiante: false })} titulo_accion="CERRAR" accion={() => this.setState({ buscarEstudiante: false })} />
            <DialogContent style={{ padding: '0' }} className='scroll'>
              <BuscarPersona callback={({ id, primer_nombre, primer_apellido, segundo_apellido }) => this.setState({
                estudiante: {
                  id,
                  nombre: `${primer_nombre} ${primer_apellido} ${segundo_apellido}`,
                }, buscarEstudiante: false
              })} />
            </DialogContent>
            <DialogActions>
              <BtnForm texto="CANCELAR" callback={() => this.setState({ buscarEstudiante: false })} />
            </DialogActions>
          </Dialog>
        </>
      )
    }
  }
  
  //Props del componente
  ValidacionesFiltro.propTypes = {
    modalFiltroValidacion: PropTypes.bool.isRequired,
    mostrarModalFiltroAtencion: PropTypes.func.isRequired,
    filtrar: PropTypes.func.isRequired,
    limpiar: PropTypes.func.isRequired,
  }
  
  export default ValidacionesFiltro;