import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import emma_w from '../../../global/imagenes/emma_w.png';
import { consulta, obtenerValoresFiltros, transformar } from "../../../global/js/funciones";
import AppBarModal from '../../general/AppBarModal';
import { BtnForm } from "../../general/BotonesAccion";
import BuscarPersona from '../../general/BuscarPersona';
import BuscarSelect from '../../general/BuscarSelect';
import InputBuscar from '../../general/InputBuscar';
import TareasTerminadas from '../../general/TareasTerminadas';


class GradosFiltrar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      programas: [],
      tipos: [],
      programa: null,
      tipo: '',
      condecoracion: '',
      cargando: true,
      estudiante: {
        id: 0,
        nombre: ''
      },
      buscarEstudiante: false,
    }
  }

  async componentDidUpdate({ modalFiltros },) {
    let { modalFiltros: modalFiltrosNew } = this.props;
    if (modalFiltros != modalFiltrosNew && modalFiltrosNew) {
      let programas = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': 5 }, { 'llave': 'estado', 'valor': 1 }]);
      let tipos = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': 22 }, { 'llave': 'estado', 'valor': 1 }]);
      this.setState({
        'programas': transformar(programas),
        tipos,
        cargando: false,
      })
    }
    if (modalFiltros != modalFiltrosNew && !modalFiltrosNew) {
      this.setState({ periodo: null, programa: null, tipo: null, estado_actual: null })
    }
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  changeState = (estados) => {
    this.setState(estados)
  }

  resetState = () => {
    let { limpiar } = this.props;
    this.setState({
      programa: null,
      tipo: '',
      condecoracion: '',
    })
    limpiar();
    // showModalFiltros(false)
  }

  getPeriodos = () => {
    return new Promise(resolve => {
      consulta(`api/v1.0/grados/periodos`, null, null,
        (error, estado, resp) => {
          resolve(resp)
        }
      );
    })
  }

  onSubmit = e => {
    let { filtrar, actualizarMensaje, accionProceso, mostrarTodos, showModalFiltros } = this.props;
    let { tipo, programa, condecoracion, estudiante } = this.state;

    let filtros = [];
    if (tipo) filtros.push({ 'llave': 'tipo', 'valor': tipo });
    if (programa) filtros.push({ 'llave': 'programa', 'valor': programa.value });
    if (condecoracion) filtros.push({ 'llave': 'condecoracion', 'valor': condecoracion });
    if (estudiante.id) filtros.push({ 'llave': 'usuario_registro', 'valor': estudiante.id });
    if (accionProceso == 'inicio') mostrarTodos('Tod_Gra')
    showModalFiltros(false)
    if (filtros.length > 0) filtrar(filtros);
    else actualizarMensaje({ titulo: 'Seleccione Algún filtro', tipo: 'info', mostrar: true, tiempo: 6000 });
    e.preventDefault();
  }

  render() {
    let { modalFiltros, showModalFiltros } = this.props;
    let { cargando, tipo, programa,  condecoracion, programas, tipos, estudiante, buscarEstudiante } = this.state;

    return (
      <>
      <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={modalFiltros} onClose={() => showModalFiltros(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <AppBarModal titulo='¡ Aplicar Filtros !' mostrarModal={showModalFiltros} titulo_accion="Limpiar" accion={this.resetState} />
        <DialogContent className='scroll'>
          <Grid container>
            {!cargando ?
              <Grid item xs={12} md={12} spacing={4} className='margin_cont' >
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12} style={{marginBottom: '3%'}}>
                  <InputBuscar
                    id='estudiante'
                    label='Estudiante'
                    placeholder='Clic aquí para buscar'
                    value={estudiante.nombre}
                    callback={() => this.setState({ buscarEstudiante: true })} />
                </Grid>
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                  <BuscarSelect datos={programas} cambiarEstado={(value) => this.setState({ programa: value })} valor={programa} id='select-programa' nombre='Programa' placeholder='Seleccione Programa' />
                </Grid>
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                  <FormControl className='form-control'>
                    <InputLabel>Seleccione Tipo</InputLabel>
                    <Select
                      inputProps={{
                        name: 'tipo',
                        id: 'tipo',
                      }}
                      value={tipo}
                      onChange={this.onChange}>
                      {
                        tipos.map(({ id, nombre }) => <MenuItem key={id} value={id}>{nombre}</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                  <FormControl className='form-control'>
                    <InputLabel>Condecoración</InputLabel>
                    <Select
                      inputProps={{
                        name: 'condecoracion',
                        id: 'condecoracion',
                      }}
                      value={condecoracion}
                      onChange={this.onChange}>
                      <MenuItem key={1} value={1}>No aprobado</MenuItem>
                      <MenuItem key={2} value={2}>Aprobado</MenuItem>
                      <MenuItem key={3} value={3}>Aprobado Meritorio</MenuItem>
                      <MenuItem key={4} value={4}>Aprobado Laureado</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid> :
              <TareasTerminadas mensaje="Cargando" imagen={emma_w} />
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CANCELAR" callback={() => showModalFiltros(false)} />
          <BtnForm texto="FILTRAR" callback={this.onSubmit} />
        </DialogActions>
      </Dialog >

        <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={buscarEstudiante} onClose={() => this.setState({ buscarEstudiante: false })}>
          <AppBarModal titulo={'¡ Buscar Estudiante !'} mostrarModal={() => this.setState({ buscarEstudiante: false })} titulo_accion="CERRAR" accion={() => this.setState({ buscarEstudiante: false })} />
          <DialogContent style={{ padding: '0' }} className='scroll'>
            <BuscarPersona callback={({ id, primer_nombre, primer_apellido, segundo_apellido }) => this.setState({
              estudiante: {
                id,
                nombre: `${primer_nombre} ${primer_apellido} ${segundo_apellido}`,
              }, buscarEstudiante: false
            })} />
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => this.setState({ buscarEstudiante: false })} />
          </DialogActions>
        </Dialog>      
      </>
    )
  }
}

//Props del componente
GradosFiltrar.propTypes = {
  //variables
  modalFiltros: PropTypes.bool.isRequired,
  //funciones
  showModalFiltros: PropTypes.func.isRequired,
  filtrar: PropTypes.func.isRequired,
  limpiar: PropTypes.func.isRequired,
}
export default GradosFiltrar;
