import React, { Component } from 'react';
import { connect } from "react-redux";
import { actualizarMensaje, mostrarModulo } from "../../redux/actions/actGlobal";
import TareasTerminadas from "../general/TareasTerminadas";
import emma_w from '../../global/imagenes/emma_w.png';
import { MenuEstudiante } from "./tutoria/TutoriaMenu";
import { Grid, Paper, Typography, Card, CardHeader, CardContent, Avatar,Button } from '@material-ui/core'
import { red } from '@material-ui/core/colors';
import asignatura4 from '../../global/imagenes/asignatura4.png';
import TutoriasCrear from './tutoria/TutoriasCrear'
import {mostrarModalSolicitarTutoria,mostrarModalDetalleTutoria} from "../../redux/actions/actTutorias"
import {obtenerMisDatos,  consulta, mostrarError,crear_form_data,formulario,generarFiltros} from "../../global/js/funciones"
import emma_s from '../../global/imagenes/emma_s1.png';
import TituloAccion from '../general/TituloAccion';
import TutoriasActiva from './tutoria/TutoriasActiva';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AccionesRapidas from '../general/AccionesRapidas';
import revisar from '../../global/imagenes/revisar.png';
import { Tutoria } from '../tutorias/tutoria/Tutoria';
import TutoriaDetalle from './tutoria/TutoriaDetalle'
import { BtnDetalle } from '../general/BotonesAccion';
import ListarDatos from '../general/ListarDatos';
import moment from 'moment';
import ConfirmarAccion from "../general/ConfirmarAccion";


class TutoriaEstudiantes extends Component {

  constructor(props) {
    super(props)
    this.state = {
      menu: true,
      seleccion: 'proceso',
      cargando: true,
      datos_sesion: [],
      programas: [],
      asignaturas:[],
      historial: [],
      tutorias: [],
      TutoriasActivas:[],
      telefono:'',
      celular:'',
      correo:'',
      carrera:'',
      asignatura:'',
      tutoriaActual:{},
      procesosRecientes: [],
      procesoActual: {},
      enListado: true,
      tutoriaActual : 0,
      correo_personal:'',
      openModalEliminar: false,
      tutoriaCancelar:0
    }
  }

  async componentDidMount() {
    let { mostrarModulo } = this.props;
    mostrarModulo({ ruta: '/tutoria', nombre: 'Tutorias' })
    this.getProcesosRecientes();
    let datos_sesion = await obtenerMisDatos();
    let tutorias = await this.obtenerTutorias();
    console.log(tutorias)
    this.setState({cargando:false, datos_sesion, tutorias})
    this.obtenerDatosBasicos()
    
  }

  async componentDidUpdate({ }, { seleccion }) {
    let { seleccion: seleccionNew } = this.state;
    if (seleccion !== seleccionNew) {
      if (seleccionNew === 'proceso')this.getProcesosRecientes();
      else if (seleccionNew === 'agregar'){
        let TutoriasActivas = await this.ObtenerTutoriasActivas()
        this.setState({ menu: true, TutoriasActivas })
      } 
      else if (seleccionNew === 'historial') await this.obtenerHistorial();
    }
  }


  async obtenerTutorias(filtros = []) {
    let f = await generarFiltros(filtros);
    return new Promise(resolve => {
      consulta(`api/v1.0/tutoria/listar/0?${f}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  async ObtenerTutoriasActivas(filtros = []) {
    let f = await generarFiltros(filtros);
    return new Promise(resolve => {
      consulta(`api/v1.0/tutoria/listaractivas`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }


  async obtenerHistorial() {
    this.setState({ cargando: true })
    let historial = await this.obtenerTutorias();
    this.setState({ historial, cargando: false })
  }




  obtenerDatosBasicos =() => {
    let {datos_sesion} = this.state
    let telefono = datos_sesion.persona.telefono
    let celular = datos_sesion.persona.celular
    let correo = datos_sesion.persona.correo_personal
    let programas=datos_sesion.programas
    this.setState({telefono,celular,correo,programas})

  }

  ObtenerTutoria = (id) => {
		return new Promise((resolve) => {
			consulta(`api/v1.0/tutorias/${id}`, null, null, (error, estado, resp) => {
				resolve(resp);
			});
		});
	};

  async obtenerDataProcesoActual(id = 0) {
    this.setState({ cargando: true })
    let data = await this.ObtenerTutoria(id);
    if (data) this.setState({ lineaEstados: data.linea, procesoActual: data.tutoria, id_estudiante: data.tutoria.interesado.id });
    this.setState({ cargando: false });
  }


  pintarSolicitudSeleccionada() {
    let { mostrarModalDetalleTutoria , modalDetTutoria} = this.props
    
    let {procesoActual,id_estudiante}= this.state
    

    if (procesoActual) {
      return (
        <div className='' >
          <Button onClick={() => this.setState({ enListado: true, tutoriaActual: 0 })} style={{ padding: "0px 3px 0px 3px", marginTop: '1%' }} color="secondary" > <ArrowBackIcon /> <Typography variant="body2" style={{ color: '#9e9e9e' }} component="p" align='justify'> Atras </Typography> </Button>
          <div style={{ padding: '0% 2% 0% 2%' }} >
            <Grid container direction={'row'} spacing={1} >
              <Grid item xs={12} sm={12} md={6} >
                <TituloAccion principal='' alterno='¡ Información Básica !' />
                <Paper elevation={0} square className="scroll">
                  <Tutoria tutoria={procesoActual} />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TituloAccion principal='' alterno='¿ Que deseas hacer ?' />
                <Grid container direction={'row'} spacing={2} style={{ width: "100%" }}>
                  <Grid item xs={12} sm={12} md={12} >
                    <Paper elevation={3} style={{ border: '1px solid #e0e0e0' }} onClick={() => mostrarModalDetalleTutoria(true)}>
                      <AccionesRapidas imagen={revisar} titulo='¡ Más Información !' sub_titulo='Bien' des_sub_titulo=', Aquí puedes ver toda la información actual.' />
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div >
           
    <TutoriaDetalle
    tutoria = {procesoActual}
    id_estudiante = {id_estudiante}
    mostrarModalDetalleTutoria = {mostrarModalDetalleTutoria}
    modalDetTutoria = {modalDetTutoria}
    vistaDetalle='detalle'
    admin = {false}
    />
        </div>
      )
    }
  }


  getProcesosRecientes = () => {
		this.setState({ render: false })
		consulta(`api/v1.0/tutorias/recientes`, null, null, async (error, estado, procesosRecientes) => {
			this.setState({ procesosRecientes, render: true });
		});
	};

  pintarprocesosRecientes() {
    let { procesosRecientes,openModalEliminar } = this.state;
		if (procesosRecientes.length === 0)
			return (
				<div className="pointer" onClick={() => this.setState({ seleccion: 'agregar' })}>
					<TareasTerminadas
						mensaje="No tienes solicitudes activas, clic aquí para solicitar Tutorias"
						marginTop="7%"
						imagen={emma_s}
						widthImg="7%"
					/>
				</div>
			);

		const handleClick = (asignatura) => {
			this.setState({ enListado: false, procesoActual: asignatura });
		};

    const handleClickDelete = (e, id) => {
      this.setState({ tutoriaCancelar: id, openModalEliminar: true }); 
      e.stopPropagation();
		};

		return (
			<div style={{ padding: '2% 2% 0% 2%' }}>
				<Grid container direction={'row'} spacing={1}>
					<TituloAccion principal="" alterno="¡ Estos son tus solicitudes de tutorias para este periodo !" />
					{procesosRecientes.map((asignatura, i) => {
						return (
							<Grid item xs={12} sm={6} md={4} lg={3} key={i}>
								<Paper elevation={1} onClick={() => handleClick(asignatura)} className="pointer">
                <TutoriasActiva
                    id={asignatura.id}
										fecha={asignatura.fecha_registro}
										descripcion={`Tu solicitud de tutoria para ${asignatura.asignatura} se encuentra en estado ${asignatura.estado_actual
											.nombre.toLowerCase()}.`}
										estado={asignatura.estado_actual}
                    handleClickDelete={handleClickDelete}
									/>
								</Paper>
							</Grid>
						);
					})}
				</Grid>
        <ConfirmarAccion
            titulo='¿ Cancelar Solicitud ?'
            dato={{ id: 0 }}
            mensaje1='¿Estás seguro(a) que deseas cancelar la solicitud de tutoria?'
            modalConAccion={openModalEliminar}
            mostrarModalConfAccion={() => this.setState({ openModalEliminar: false, tutoriaCancelar: 0 })}
            ejecutarAccion={this.cancelarSolicitud}
          />
			</div>
		);
  }




  
  guardarTutorias = async (datos, callback) => {
    let { actualizarMensaje, mostrarModalSolicitarTutoria } = this.props
    let data = await crear_form_data(datos);
    formulario(`api/v1.0/tutorias/crear`, data, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          mostrarModalSolicitarTutoria(false);
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 9000 });
      callback();
    })
  }


  cancelarSolicitud = () => {
    let { tutoriaCancelar } = this.state
    let { actualizarMensaje } = this.props

    consulta(`api/v1.0/tutorias/${tutoriaCancelar}/cancelar`, {}, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          //this.obtenerDatosIniciales()
          this.setState({ openModalEliminar: false, tutoriaCancelar: 0 })
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 9000 });
    })
  }

 

  pintarProcesosNuevos(){
    let {modalSolTutoria, mostrarModalSolicitarTutoria, actualizarMensaje} = this.props
    let { telefono, celular, correo,programas,asignaturas, TutoriasActivas}= this.state
    return(
      <>
      <Grid container direction={'row'} justifyContent={'center'} style={{ paddingTop: 40 }}>
      <Grid item xs={12} sm={6} md={4} lg={4} style={{ padding: '2% 2% 0% 2%' }} onClick={() => { mostrarModalSolicitarTutoria(true) }}>
        <Paper elevation={8} square>
          <Card style={{ maxWidth: "100%", height: '380px', cursor: 'pointer' }} elevation={1}>
            <CardHeader
              avatar={<Avatar aria-label="recipe" style={{ backgroundColor: red[500] }}>T</Avatar>}
              title={`Inscripcion a Nueva Tutoria`}
            />
            <CardContent >
              <Grid container direction={'row'} justifyContent={'center'}>
                <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center', height: "150px" }}>
                  <img src={asignatura4} style={{ width: '150px', maxWidth: '200px', minWidth: '100px', height: "150px" }} alt='Imagen Tipo' />
                </Grid>
                <Grid item xs={12} sm={12} md={12} style={{ padding: "15px 10px 0px 10px", }}>
                  <Typography variant="body2" color="textSecondary" component="p" align='justify'>
                    Para inscribirse en una nueva turoria Dar click aqui!
                      </Typography>
                </Grid>
              </Grid>
             
            </CardContent>
          </Card>
        </Paper>
      </Grid> 
    </Grid>

    <TutoriasCrear
    guardarTutorias={this.guardarTutorias}
    actualizarMensaje={actualizarMensaje}
    modalSolTutoria={modalSolTutoria}
    mostrarModalSolicitarTutoria={mostrarModalSolicitarTutoria}
    telefono={telefono}
    celular={celular}
    correo={correo}
    programas={programas}
    asignaturas={asignaturas}
    TutoriasActivas={TutoriasActivas}
    />



    </>
  );
  }
  
  pintarHistorial(){
    

    let { historial,procesoActual,id_estudiante} = this.state;
    let { mostrarModalDetalleTutoria,modalDetTutoria } = this.props;
    
    const obtenerColor = (codigo) => {
      let color = 'white';
      const colores = {
        'Est_Tut_Sol': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
        'Est_Tut_Ace': { 'backgroundColor': '#ffc107', color },
        'Est_Tut_Neg': { 'backgroundColor': '#b71c1c', color },
        'Est_Tut_Can': { 'backgroundColor': '#b71c1c', color },
        'Est_Tut_Fin': { 'backgroundColor': '#b71c1c', color },
        //'Est_tut_can': { 'backgroundColor': '#b71c1c', color }
      }
      return (colores[codigo])
    }

    const acciones = (data) => {
      const onClickDetalle = (data) => {
        
        this.setState({ procesoActual: data, id_estudiante: data.interesado.id })
        mostrarModalDetalleTutoria(true)
      }
      let detalle = <BtnDetalle callback={() => onClickDetalle(data)} color='#01579b' texto='Abrir' />;
      return detalle;
    }

    return (
      <div>
        <ListarDatos
          id="tbl_mis_adjuntos"
          titulo='Lista de Tutorias'
          datos={historial}
          buscar={true}
          opciones={true}
          sub_titulo="Lista de adjuntos"
          avatar={({ estado_actual }) => estado_actual.nombre.charAt(0).toUpperCase()}
          color_avatar={({ estado_actual: { codigo } }) => obtenerColor(codigo)}
          acciones={(row) => acciones(row)}
          fila_principal={({ asignatura }) => asignatura.toUpperCase()}
          filas={[
            { 'id': 'tipo_estado', 'enLista': true, 'mostrar': ({ estado_actual }) => estado_actual.nombre.toUpperCase() },
            { 'id': 'asignatura', 'enLista': false, 'mostrar': ({ asignatura }) => asignatura.toUpperCase() },
            { 'id': 'fecha_registro', 'enLista': true, 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a'), nombre: 'Fecha Registro: ' }
          ]}
        />
        <TutoriaDetalle
    tutoria = {procesoActual}
    id_estudiante = {id_estudiante}
    mostrarModalDetalleTutoria = {mostrarModalDetalleTutoria}
    modalDetTutoria = {modalDetTutoria}
    vistaDetalle='detalle'
    admin = {false}
    />
      </div>
    )





  }


  render() {
   let{seleccion,cargando,enListado}= this.state;

    return (
      <> 
        <MenuEstudiante
          seleccionar={(seleccion) => this.setState({ seleccion })}
          sele={seleccion} 
        />
        <div className='contenido_modulo'>
        {
            !cargando
              ?
              <div>
                {seleccion === 'proceso' && (enListado ? this.pintarprocesosRecientes() : this.pintarSolicitudSeleccionada())}
                {seleccion === 'agregar' && this.pintarProcesosNuevos()}
                {seleccion === 'historial' && this.pintarHistorial()}
                
              </div>
              : <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
          }
        </div>  
      </>
      )
    }
    
  
}

//CARGO ESTADOS DEL MODULO
const mapStateToProps = (state) => { 
  let {modalSolTutoria,modalDetTutoria} = state.redTutoria;
    let { usuario } = state.redGlobal;
    return {
      usuario,
     modalSolTutoria,
     modalDetTutoria,
    }
  };
  
  //CARGO FUNCIONES DEL MODULO
  const mapDispatchToProps = {
    actualizarMensaje,
    mostrarModulo,
    mostrarModalSolicitarTutoria,
    mostrarModalDetalleTutoria,
    
  
  }




export default connect(mapStateToProps, mapDispatchToProps)(TutoriaEstudiantes);