import React, { Component } from 'react';
import { obtenerValoresFiltros,obtenerPermisosValorAlt,obtenerMisDatos } from "../../../global/js/funciones";
import { Paper, Grid, Typography, Button } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import dinero_marron from '../../../global/imagenes/dinero_marron.png';
import dinero_rojo from '../../../global/imagenes/dinero_rojo.png';
import dinero_renovacion from '../../../global/imagenes/dinero_renovacion.png';
import dinero from '../../../global/imagenes/dinero.png';
import CreditoFormAgregar from "./CreditoFormAgregar";
import CreditoOpcionesTipo from "./CreditoOpcionesTipo";
import CreditoFormuDirecto from "../creditos/CreditoFormuDirecto";
import { mostrarModalAddCredito, mostrarModalFormuDirecto} from "../../.././redux/actions/actCreditos";
import { connect } from "react-redux";
import { actualizarMensaje, mostrarModulo } from "../../.././redux/actions/actGlobal";
import { REQUISITOS_CREDITOS,CREDITO_DIRECTO_POARA,CREDITO_DIRECTO_OCUPACION } from "./../helper";


class CreditoAgregar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      seleccion: 'inicio',
      titulo: 'inicio',
      categoria: '',
      tipo: '',
      cargando: true,
      mensaje_icetex: '',
      mensaje_otras: '',
      mensajes_nuevo: '',
      mensajes_renovacion: '',
      requisitos: [],
      credito_para:[],
      datos_sesion: [],
      telefono_contacto:'',
      direccion_per:'',
      correo_personal:'',
      lugar_expedicion:'',
      estado_civil:'',
      lugar_residencia:'',
      programas: [],
      Ocupacion:[]
    }
  }

  async componentDidMount() {
    this.obtenerInformacion();
    this.obtenerRequisitosCreditos();
    this.obtenerCredito_para();
    this.obtenerOcupacion();
    let datos_sesion = await obtenerMisDatos();
    this.setState({cargando:false, datos_sesion});
    this.obtenerDatosBasicos();
  }

  async componentDidUpdate({ }, { seleccion }) {
    let { seleccion: seleccionNew } = this.state;
    if (seleccionNew !== seleccion) {
    }
  }

  async obtenerInformacion() {
    let icetex = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Ice_Cre' }]);
    let otras = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Ent_Cre' }]);
    let directo = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Direc_Cre' }]); 
    if (icetex.length > 0 && otras.length > 0 && directo.length > 0) this.setState({ mensaje_icetex: icetex[0].descripcion, mensaje_otras: otras[0].descripcion, mensaje_directo: directo[0].descripcion, mensajes_nuevo: [icetex[0].valora, otras[0].valora], mensajes_renovacion: [icetex[0].valorb, otras[0].valorb] })
  }

  obtenerRequisitosCreditos = async () => {
    this.setState({ cargando: true });
    const requisitosCR = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': REQUISITOS_CREDITOS }, { 'llave': 'estado', 'valor': 1 }])
    this.setState({
      requisitos: requisitosCR,
      cargando: false,
    });
  }

  obtenerCredito_para = async () => {
    this.setState({ cargando: true });
    const creditoPara = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': CREDITO_DIRECTO_POARA }, { 'llave': 'estado', 'valor': 1 }])
    this.setState({
      credito_para: creditoPara,
      cargando: false,
    });
  }

  obtenerOcupacion = async () => {
    this.setState({ cargando: true });
    const ocupacion = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': CREDITO_DIRECTO_OCUPACION }, { 'llave': 'estado', 'valor': 1 }])
    this.setState({
      Ocupacion: ocupacion,
      cargando: false,
    });
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  obtenerDatosBasicos =() => {
    let {datos_sesion} = this.state
    if (datos_sesion && datos_sesion.persona){
      let telefono_contacto = datos_sesion.persona.celular
      let direccion_per = datos_sesion.persona.direccion
      let correo_personal = datos_sesion.persona.correo_personal
      let lugar_expedicion = datos_sesion.persona.lugar_expedicion
      let estado_civil = datos_sesion.persona.estado_civil
      let lugar_residencia = datos_sesion.persona.lugar_residencia
      let programas=datos_sesion.programas
      this.setState({telefono_contacto,direccion_per,correo_personal,lugar_expedicion,estado_civil,lugar_residencia,programas});
    }
  }

  renderInicio() {
    let { setMenu,tipoSolicitud } = this.props;
    return (
      <div className=''>
        <Button onClick={() => setMenu()} style={{ padding: "0px 3px 0px 3px", marginTop: '1%' }} color="secondary" > <ArrowBackIcon /> <Typography variant="body2" style={{ color: '#9e9e9e' }} component="p" align='justify'> Atras </Typography> </Button>
        <div>
          <Grid container direction={'row'} justifyContent={'center'} style={{ paddingTop: 30 }} >
            <Grid item xs={12} sm={12} md={10} lg={8}>
              <Grid container direction={'row'} justify={'center'}>
                { tipoSolicitud.codigo === 'sol_cred' &&
                  <Grid item xs={12} sm={12} md={5} lg={5} style={{ padding: '2% 2% 0% 2%' }} onClick={() => this.setState({ tipo: 'icetex' })}>
                  <Paper elevation={8} square>
                    <CreditoOpcionesTipo avatarcss={{ background: '#f44336' }} cardcss={{ maxWidth: "100%", height: '420px', cursor: 'pointer' }} image={dinero_rojo} data={{ opciones: `Crédito nuevo o renovación`, nombre: 'CRÉDITO ICETEX', descripcion: this.state.mensaje_icetex }} />
                  </Paper>
                  </Grid>
                }
                {
                 tipoSolicitud.codigo === 'sol_cred' &&
                 <Grid item xs={12} sm={12} md={5} lg={5} style={{ padding: '2% 2% 0% 2%' }} onClick={() => this.setState({ tipo: 'entidades' })}>
                 <Paper elevation={8} square>
                     <CreditoOpcionesTipo avatarcss={{ background: '#f57f17' }} cardcss={{ maxWidth: "100%", height: '420px', cursor: 'pointer' }} image={dinero_marron} data={{ opciones: `Crédito nuevo o renovación`, nombre: 'OTRAS ENTIDADES', descripcion: this.state.mensaje_otras }} />
                   </Paper>
                   </Grid>
                }
                { tipoSolicitud.codigo === 'sol_directo' &&
                  <Grid item xs={12} sm={12} md={5} lg={5} style={{ padding: '2% 2% 0% 2%' }} onClick={() => this.props.mostrarModalFormuDirecto(true)}>
                  <Paper elevation={8} square>
                    <CreditoOpcionesTipo avatarcss={{ background: '#f57f17' }} cardcss={{ maxWidth: "100%", height: '420px', cursor: 'pointer' }} image={dinero_marron} data={{ opciones: `Crédito directo`, nombre: 'CREDITO DIRECTO', descripcion: this.state.mensaje_directo }} />
                  </Paper>
                  </Grid>
                }
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }

  abrirTipo(categoria, titulo) {
    this.setState({ categoria, titulo })
    this.props.mostrarModalAddCredito(true);
  }

  renderNuevoRenovacion() {
    let { tipo, mensajes_nuevo, mensajes_renovacion } = this.state
    return (
      <>
        <Button onClick={() => this.setState({ categoria: '', titulo: '', tipo: '' })} style={{ padding: "0px 3px 0px 3px", marginTop: '1%' }} color="secondary" > <ArrowBackIcon /> <Typography variant="body2" style={{ color: '#9e9e9e' }} component="p" align='justify'> Atras </Typography> </Button>
        <Grid container direction={'row'} justifyContent={'center'} style={{ paddingTop: 30 }}>
          <Grid item xs={12} sm={12} md={10} lg={8}>
            <Grid container direction={'row'} justifyContent={'center'}>
              <Grid item xs={12} sm={12} md={5} lg={5} style={{ padding: '2% 2% 0% 2%' }} onClick={() => this.abrirTipo('nuevo', '¡ Nuevo crédito !')}>
                <Paper elevation={8} square>
                  <CreditoOpcionesTipo avatarcss={{ background: '#01579b' }} cardcss={{ maxWidth: "100%", height: '420px', cursor: 'pointer' }} image={dinero} data={{ opciones: ``, nombre: 'CRÉDITO NUEVO', descripcion: (tipo === 'icetex') ? mensajes_nuevo[0] : mensajes_nuevo[1] }} />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={5} style={{ padding: '2% 2% 0% 2%' }} onClick={() => this.abrirTipo('renovacion', '¡ Renovación de crédito !')}>
                <Paper elevation={8} square>
                  <CreditoOpcionesTipo avatarcss={{ background: '#f44336' }} cardcss={{ maxWidth: "100%", height: '420px', cursor: 'pointer' }} image={dinero_renovacion} data={{ opciones: ``, nombre: 'RENOVACIÓN DE CREDITO', descripcion: (tipo === 'icetex') ? mensajes_renovacion[0] : mensajes_renovacion[1] }} />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid >
      </>
    )
  }

  render() {
    let { seleccion, categoria, tipo, titulo,requisitos,credito_para,programas,Ocupacion,telefono_contacto,direccion_per,correo_personal,lugar_expedicion,estado_civil,lugar_residencia } = this.state;
    let { mostrarModalAddCredito, modalAddCredito, guardarCredito, guardarCreditodirecto, actualizarMensaje, tipoSolicitud,mostrarModalFormuDirecto,ModalFormuDirecto} = this.props;

    return (
      <div>
        {(seleccion === 'inicio' && !tipo) && this.renderInicio()}
        {tipo && this.renderNuevoRenovacion()}
        <CreditoFormAgregar 
          actualizarMensaje={actualizarMensaje} 
          guardarCredito={guardarCredito} 
          categoria={categoria} tipo={tipo} 
          titulo={titulo} 
          modalAddCredito={modalAddCredito} 
          mostrarModalAddCredito={mostrarModalAddCredito} 
          tipoSolicitud={tipoSolicitud}
          />
          <CreditoFormuDirecto
            guardarCreditodirecto={guardarCreditodirecto}
            ModalFormuDirecto = {ModalFormuDirecto}
            mostrarModalFormuDirecto={mostrarModalFormuDirecto}
            requisitos={requisitos}
            credito_para={credito_para}
            telefono_contacto={telefono_contacto}
            direccion_per = {direccion_per}
            correo_personal = {correo_personal}
            lugar_expedicion = {lugar_expedicion}
            estado_civil = {estado_civil}
            lugar_residencia = {lugar_residencia}
            programas={programas}
            Ocupacion={Ocupacion}
            tipoSolicitud={tipoSolicitud}
            onChange= {this.onChange}

          />
      </div>
    )
  }
}



//CARGO ESTADOS DEL MODULO
const mapStateToProps = (state) => {
  let { modalAddCredito, ModalFormuDirecto } = state.redCreditos;
  return {
    modalAddCredito,
    ModalFormuDirecto
  }
};

//CARGO FUNCIONES DEL MODULO
const mapDispatchToProps = {
  actualizarMensaje,
  mostrarModulo,
  mostrarModalAddCredito,
  mostrarModalFormuDirecto
}





export default connect(mapStateToProps, mapDispatchToProps)(CreditoAgregar);
