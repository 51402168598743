import React from 'react'
import PropTypes from 'prop-types';
import { BtnSeleccion } from "../general/BotonesAccion";
import ListarDatos from '../general/ListarDatos';
import { rutaImg, consulta } from "../../global/js/funciones";

class PersonasBuscarGrado extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      dato_buscar: '',
      cargando: false,
      personas: [],
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let { tipo } = this.props
    if (this.state.dato_buscar !== prevState.dato_buscar) {
      if (tipo == "personal") this.obtenerPersonal(this.state.dato_buscar)
      if (tipo == "estudiantes") this.obtenerEstudiantes(this.state.dato_buscar)
    }
  }


  obtenerPersonal = (dato) => {
    this.setState({ cargando: true })
    if (dato.length > 5) {
      consulta(`api/v1.0/grados/personal/buscar`, { dato }, "post",
        (error, estado, resp) => {
          if (estado === 200) this.setState({ cargando: false, personas: resp })
        }
      );
    } this.setState({ cargando: false, personas: [] });
  }

  obtenerEstudiantes = (dato) => {
    this.setState({ cargando: true })
    if (dato.length > 5) {
      consulta(`api/v1.0/personas/generica/${2}/buscar`, { dato, relacion: "rol_est" }, "post",
        (error, estado, resp) => {
          if (estado === 200) this.setState({ cargando: false, personas: resp })
        }
      );
    } this.setState({ cargando: false, personas: [] });
    this.setState({ cargando: false, personas: [] });
  }

  render() {
    const actions = (rowData) => {
      let { callback } = this.props;
      const seleccionar = (data) => {
        callback(data);
      }

      let resp = <BtnSeleccion callback={() => seleccionar(rowData)} />
      return resp;
    }

    let { dato_buscar, personas, cargando } = this.state;

    return (
      <div>
        <ListarDatos
          id="tbl_listar_personas_gr"
          titulo={"Lista Personas"}
          datos={personas}
          opciones={true}
          buscar={true}
          cargando={cargando}
          mensajeVacio='Esta bandeja esta vacía, ingrese dato a buscar'
          avatarImg={true}
          css={{
            list: { padding: '0px 0px 20px 0px' },
            appBar: { padding: '0px 0px 0px 0px' },
          }}
          actBuscar={(dato_buscar) => this.setState({ dato_buscar })}
          datoBuscarAlt={dato_buscar}
          avatar={({ identificacion }) => `${rutaImg}${identificacion}.jpg`}
          acciones={(row) => actions(row)}
          fila_principal={({ primer_nombre, segundo_nombre, primer_apellido, segundo_apellido }) => `${primer_nombre} ${segundo_nombre} ${primer_apellido} ${segundo_apellido}`.toUpperCase()}
          filas={[
            { 'id': 'correo', 'enLista': true },
            { 'id': 'identificacion', 'enLista': true },
          ]}

        />
      </div>
    );
  }

}

//Props del componente
PersonasBuscarGrado.propTypes = {
  callback: PropTypes.func.isRequired,
}


export default PersonasBuscarGrado;


