import {
  MODAL_GES_ACTIVIDAD,
  MODAL_GES_SUBACTIVIDAD,
  MODAL_FILTROS_ACTIVIDAD,
  MODAL_AGREGAR_ACTIVIDAD,
  MODAL_AGREGAR_SUBACTIVIDAD,
} from "../actions/actRetos";

const initialState = {
  modalFiltrosActividad: false,
  modalGestionarActividad: false,
  modalGestionarSubactividad: false,
  modalAgregarActividad: false,
  modalAgregarSubactividad: false,
  actividad: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MODAL_FILTROS_ACTIVIDAD:
      return Object.assign({}, state, {
        modalFiltrosActividad: action.show,
      });

    case MODAL_GES_ACTIVIDAD:
      return Object.assign({}, state, {
        modalGestionActividad: action.show,
      });

    case MODAL_GES_SUBACTIVIDAD:
      return Object.assign({}, state, {
        modalGestionSubactividad: action.show,
      });

    case MODAL_AGREGAR_ACTIVIDAD:
      return Object.assign({}, state, {
        modalAgregarActividad: action.show,
      });
    case MODAL_AGREGAR_SUBACTIVIDAD:
      return Object.assign({}, state, {
        modalAgregarSubactividad: action.show,
      });
    default:
      return state;
  }
};

export default reducer;
