import React from 'react'
import PropTypes from 'prop-types';
import { BtnLocalizacionOff, BtnLocalizacionOn, BtnSeleccion } from '../../general/BotonesAccion';
import ListarDatos from '../../general/ListarDatos';
import moment from 'moment'


export default function PracticasEmpresasHV(props) {
   let { hojas, admin, configurarAccionConf, mostrarModalGestionar, gestionarHV, id_estudiante, obtenerEmpresasHV, setCargando } = props

   const obtenerNombreContratado = (contratado) => {
      const nombres = {
         '0': { 'nombre': '', prefijo: 'E' },
         '1': { 'nombre': 'No', prefijo: 'NC' },
         '2': { 'nombre': 'Si', prefijo: 'C' },
         '3': { 'nombre': 'Contrato anulado', prefijo: 'CA' },
      }
      return (nombres[contratado] ? nombres[contratado] : 'N/A')
   }
   
   const obtenerColor = (codigo) => {
      let color = 'white';
      const colores = {
         '0': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
         '1': { 'backgroundColor': '#b71c1c', color },
         '2': { 'backgroundColor': '#009688', color },
         '3': { 'backgroundColor': '#b71c1c', color },
      }
      return (colores[codigo])
   }

   const obtenerNombreEstado = (estado_actual) => {
      let color = 'white';
      const nombres = {
         '0': { 'nombre': 'No Llamaron', 'prefijo': 'Nl', 'css': { 'backgroundColor': '#f5f5f5', 'color': '#757575' } },
         '1': { 'nombre': 'Asistió', 'prefijo': 'As', 'css': { 'backgroundColor': '#0d47a1', color }, },
         '2': { 'nombre': 'No Asistió', 'prefijo': 'Na', 'css': { 'backgroundColor': '#b71c1c', color }, },
      }
      return (nombres[estado_actual])
   }

   const enviarInfo = (valor) => {
      setCargando(valor)
      mostrarModalGestionar(false)
   }

   const acciones = (data) => {
      const onClickAsistio = ({ id }) => {
         let conf = { titulo: '¿ Asistió ?', ejecutarAccion: () => gestionarHV(id, { estado_actual: '1', contratado: '' }, enviarInfo, () => obtenerEmpresasHV(id_estudiante)) }
         configurarAccionConf(conf);
         mostrarModalGestionar(true);

      }

      const onClickNoAsistio = ({ id }) => {
         let conf = { titulo: '¿ No asistió ?', ejecutarAccion: () => gestionarHV(id, { estado_actual: '2', contratado: '' }, enviarInfo, () => obtenerEmpresasHV(id_estudiante) ) }
         configurarAccionConf(conf);
         mostrarModalGestionar(true);

      }

      const onClickContratado = ({ id }) => {
         let conf = { titulo: '¿ Contratado ?', ejecutarAccion: () => gestionarHV(id, { estado_actual: '', contratado: '2' }, enviarInfo, () => obtenerEmpresasHV(id_estudiante)) }
         configurarAccionConf(conf);
         mostrarModalGestionar(true);

      }

      let asistio = <BtnLocalizacionOn color='#0d47a1' callback={() => onClickAsistio(data)} texto='Asistió' />;
      let noAsistio = <BtnLocalizacionOff color='#b71c1c' callback={() => onClickNoAsistio(data)} texto='No asistió' />;
      let seleccionado = <BtnSeleccion color='#009688' callback={() => onClickContratado(data)} texto='Contratado' />;
      if (data.estado_actual === '0' && data.contratado === '0') return <div>  {asistio} {noAsistio} </div>
      if (data.estado_actual === '1' && data.contratado === '0') return <div>  {seleccionado} </div>
      return '';

   }
   
   return (
      <ListarDatos
         datos={hojas}
         titulo={admin ? 'Lista Empresas HV Enviadas' : ''}
         buscar={admin}
         opciones={admin}
         sub_titulo='HV enviadas'
         descargar='EMMA - HV'
         id='tbl_empresas_hv_enviadas'
         acciones={(row) => admin && acciones(row)}
         color_avatar={({ contratado }) => obtenerColor(contratado)}
         avatar={({ contratado }) => obtenerNombreContratado(contratado).prefijo}
         fila_principal={({ empresa, sucursal }) => `${empresa.nombre} - ${sucursal.nombre}`}
         filas={[
            { 'id': 'estudiante', 'mostrar': ({ practica }) => `${practica.usuario_registro.primer_apellido} ${practica.usuario_registro.segundo_apellido} ${practica.usuario_registro.primer_nombre}` },
            { 'id': 'empresa', 'mostrar': ({ empresa, sucursal }) => `${empresa.nombre} - ${sucursal.nombre}`, 'enLista': admin },
            { 'id': 'correos_sucursal', 'mostrar': ({ sucursal }) => sucursal.valorf },
            { 'id': 'fecha_registro', 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a'), 'enLista': false },
            { 'id': 'fecha_gestion', 'mostrar': ({ fecha_gestion }) => fecha_gestion && moment(fecha_gestion).format('YYYY-MM-DD, h:mm a'), 'enLista': false },
            { 'id': 'estado_actual', 'mostrar': ({ estado_actual }) => obtenerNombreEstado(estado_actual).nombre, 'nombre': 'Entrevista : ' },
            { 'id': 'contratado', 'mostrar': ({ contratado }) => obtenerNombreContratado(contratado).nombre, 'nombre': 'Contratado : ' },
            { 'id': 'fecha_contrato', 'mostrar': ({ fecha_contrato }) => fecha_contrato && moment(fecha_contrato).format('YYYY-MM-DD, h:mm a'), 'nombre': 'Fecha Contrato : ' }
         ]}
      />

   );
}

PracticasEmpresasHV.propTypes = {
   hojas: PropTypes.array.isRequired,
}

PracticasEmpresasHV.defaultProps = {
   admin: true,
};