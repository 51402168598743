import { consulta, generarFiltros } from '../../../global/js/funciones'
import { createApi } from "../hooks/createAPI"

const ATENCION_API_URL = 'api/v1.0/atencion'
const ATENCION_ACCEPTED_RESOURCES = ['solicitudes', 'crear', 'listar', 'coordinador']
export const atencionApi = createApi(ATENCION_API_URL, ATENCION_ACCEPTED_RESOURCES)

export const getCoordinador = async (codigo) => {
  const response = new Promise(resolve => {
    consulta(`api/v1.0/atencion/coordinador`, { relacion: codigo }, 'post',
      (error, estado, resp) => {
        resolve({ error, estado, resp })
      }
    )
  })

  return response
}

export const obtenerEstadosValidaciones = async (id) => {
  let f = await generarFiltros([{ 'llave': 'atencion', 'valor': id }]);
  const response = new Promise(resolve => {
    consulta(`api/v1.0/atencion/estados?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) resolve(resp)
    })
  })

  return response
}