
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import BuscarPersona from "../../general/BuscarPersona";
import AppBarModal from '../../general/AppBarModal';
import { BtnForm } from "../../general/BotonesAccion";

class CandidatosAgregar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      candidato: 0,
      foto: '',
      descripcion: '',
    }
    this.fileInput = React.createRef();
  }

  componentDidUpdate({ modalAddCandidatosFin }) {
    let { modalAddCandidatosFin: modalAddNew } = this.props;
    if (modalAddNew !== modalAddCandidatosFin) {
      this.setState({
        foto: '',
        descripcion: '',
      })
    }
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }
  changeState = (name, value) => {
    this.setState({
      [name]: value
    })
  }

  onSubmit = e => {
    let { candidato, descripcion } = this.state;
    let imagedata = document.getElementById('foto').files[0];
    this.props.guardarCandidato(candidato.id, imagedata, descripcion);
    e.preventDefault();
  }

  continuarAddCandidato = (persona) => {
    let nombre = `${persona.primer_nombre} ${persona.primer_apellido} ${persona.segundo_apellido}`
    this.changeState("candidato", {id: persona.id, nombre});
    let { mostrarModalAddCandidatosFin } = this.props;
    mostrarModalAddCandidatosFin(true)
  }


  render() {
    let { modalAddCandidatos, mostrarModalAddCandidatos, modalAddCandidatosFin, mostrarModalAddCandidatosFin } = this.props;
    let { foto, descripcion, candidato } = this.state;
    return (
      <div>
        <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={modalAddCandidatos} onClose={mostrarModalAddCandidatos.bind(this, false)}>
          <AppBarModal titulo={'Nuevo Candidato'} mostrarModal={() => mostrarModalAddCandidatos(false)} titulo_accion="CERRAR" accion={() => mostrarModalAddCandidatos(false)} />
          <DialogContent style={{ padding: 0 }} className='scroll'>
            <BuscarPersona callback={(resp) => { this.continuarAddCandidato(resp) }} />
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => mostrarModalAddCandidatos(false)} />
          </DialogActions>
        </Dialog>

        <Dialog open={modalAddCandidatosFin} onClose={mostrarModalAddCandidatosFin.bind(this, false)} aria-labelledby="form-dialog-title">
          <AppBarModal titulo="Buscar Candidato" mostrarModal={mostrarModalAddCandidatosFin} titulo_accion="" accion="" />
          <DialogContent className='scroll'>
            <ValidatorForm onSubmit={this.onSubmit}>
            <TextValidator
                variant="outlined"
                margin="dense"
                id="candidato"
                label="Candidato"
                type="text"
                fullWidth
                name="candidato"
                value={candidato.nombre}
                disabled={true}
                validators={["required"]}
                errorMessages={["El campo es requerido"]}
                onChange={this.onChange}
              />
              <TextValidator
                variant="outlined"
                autoFocus
                margin="dense"
                id="foto"
                label="Foto"
                type="file"
                fullWidth
                name="foto"
                value={foto}
                ref={this.fileInput}
                validators={["required"]}
                errorMessages={["El campo es requerido"]}
                onChange={this.onChange}
              />
              <TextValidator
                variant="outlined"
                margin="dense"
                id="descripcion"
                label="Descripción"
                type="text"
                fullWidth
                name="descripcion"
                value={descripcion}
                validators={["required"]}
                errorMessages={["El campo es requerido"]}
                onChange={this.onChange}
              />
              <Button color="primary" type="submit" id='enviar_form_add_candidato' className="oculto"> Guardar </Button>
            </ValidatorForm>
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => mostrarModalAddCandidatosFin(false)} />
            <BtnForm texto="GUARDAR" callback={() => { document.getElementById('enviar_form_add_candidato').click() }} />
          </DialogActions>
        </Dialog>

      </div>
    );
  }
}

CandidatosAgregar.propTypes = {
  //variables
  modalAddCandidatos: PropTypes.bool.isRequired,
  //funciones
  guardarCandidato: PropTypes.func.isRequired,
  mostrarModalAddCandidatos: PropTypes.func.isRequired,
}


export default CandidatosAgregar;
