import React, { Component } from 'react';
import { TextField, Button, FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox, RadioGroup, Radio, Typography } from '@material-ui/core';
import { formulario } from '../../../global/js/funciones'; 
import PropTypes from 'prop-types';


class EncuestaSatisfaccion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        experiencia: '',
        aspectosMejorar: [],
        comentario: ''
      },
      error: ''
    };
  }

  handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: type === 'checkbox' ? checked : value
      }
    }));
  };

  handleAspectosChange = (event) => {
    const { value, checked } = event.target;
    this.setState((prevState) => {
      const aspectosMejorar = checked
        ? [...prevState.formData.aspectosMejorar, value]
        : prevState.formData.aspectosMejorar.filter((item) => item !== value);

      return {
        formData: {
          ...prevState.formData,
          aspectosMejorar
        }
      };
    });
  };

  guardarEncuesta = async (data, callback) => {
    let form = new FormData();
    form.append('experiencia', data.experiencia);
    form.append('aspectos_a_mejorar', data.aspectosMejorar.join(', '));
    form.append('comentario', data.comentario);
    console.log('Datos enviados:', { experiencia: data.experiencia, aspectos_a_mejorar: data.aspectosMejorar.join(', '), comentario: data.comentario });

    formulario('api/v1.0/guardar-encuesta', form, 'post', (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.';
      let tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo ? resp.message : 'Encuesta enviada';
        } else {
          tipo = 'info';
          titulo = resp.message ? resp.message : 'Error al guardar la encuesta';
        }
      } else {
        titulo = 'Error al enviar los datos. Por favor, inténtelo de nuevo.';
      }
      this.props.actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });

      console.log('Datos enviados:',titulo, tipo)
      callback();
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { formData } = this.state;
    if (!formData.experiencia) {
      this.setState({ error: 'Por favor, califique su experiencia.' });
      return;
    }
    if (formData.aspectosMejorar.length === 0) {
      this.setState({ error: 'Por favor, seleccione al menos un aspecto a mejorar.' });
      return;
    }
    this.guardarEncuesta(formData, () => {
      this.setState({
        formData: {
          experiencia: '',
          aspectosMejorar: [],
          comentario: ''
        },
        error: ''
      });
      // mostrarModalEncuestaCredito(false);
    });
  };

  render() {
    const { formData, error } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <FormControl component="fieldset" fullWidth margin="normal">
          <FormLabel component="legend">1. ¿Cómo calificaría su experiencia en general con el servicio prestado desde Emma? *</FormLabel>
          <RadioGroup
            aria-label="rating"
            name="experiencia"
            value={formData.experiencia}
            onChange={this.handleChange}
            row
          >
            {[...Array(5)].map((_, index) => (
              <FormControlLabel
                key={index}
                value={index + 1}
                control={<Radio style={{ display: 'none' }} />}
                label={<span style={{ fontSize: '2rem', cursor: 'pointer', color: formData.experiencia >= index + 1 ? 'gold' : 'grey' }}>★</span>}
              />
            ))}
          </RadioGroup>
        </FormControl>

        <FormControl component="fieldset" fullWidth margin="normal">
          <FormLabel component="legend">2. ¿Cuál es el aspecto o aspectos que mejoraría en su experiencia con el servicio prestado? *</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox value="Información recibida con respecto al proceso" checked={formData.aspectosMejorar.includes('Información recibida con respecto al proceso')} onChange={this.handleAspectosChange} />}
              label="Información recibida con respecto al proceso"
            />
            <FormControlLabel
              control={<Checkbox value="Tiempo de Respuesta" checked={formData.aspectosMejorar.includes('Tiempo de Respuesta')} onChange={this.handleAspectosChange} />}
              label="Tiempo de Respuesta"
            />
            <FormControlLabel
              control={<Checkbox value="Medio por el cual realizo la solicitud" checked={formData.aspectosMejorar.includes('Medio por el cual realizo la solicitud')} onChange={this.handleAspectosChange} />}
              label="Medio por el cual realizo la solicitud"
            />
            <FormControlLabel
              control={<Checkbox value="Atención prestada por el funcionario que gestionó su solicitud" checked={formData.aspectosMejorar.includes('Atención prestada por el funcionario que gestionó su solicitud')} onChange={this.handleAspectosChange} />}
              label="Atención prestada por el funcionario que gestionó su solicitud"
            />
            <FormControlLabel
              control={<Checkbox value="Ninguna de las anteriores" checked={formData.aspectosMejorar.includes('Ninguna de las anteriores')} onChange={this.handleAspectosChange} />}
              label="Ninguna de las anteriores"
            />
          </FormGroup>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <FormLabel component="legend">3. ¿Tiene alguna sugerencia o comentario adicional que nos ayude a mejorar su experiencia con el servicio prestado?</FormLabel>
          <TextField
            name="comentario"
            value={formData.comentario}
            onChange={this.handleChange}
            multiline
            rows={4}
            variant="outlined"
          />
        </FormControl>

        {error && (
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        )}

        <Button type="submit" variant="contained" color="primary">
          Enviar
        </Button>
      </form>
    );
  }
}

EncuestaSatisfaccion.propTypes = {
  actualizarMensaje: PropTypes.func.isRequired,
};

export default EncuestaSatisfaccion;