import {
    ADJUNTOS,
    TUTORES,
    ESTADOS_SOLICITUD,
    ESTADO_SOLICITUD,
    EVENTOS,
    MODAL_ADJUNTOS,
    MODAL_NEW_ADJUNTOS,
    MODAL_MODIFICAR_SOLICITUD,
    MODAL_DETALLE_GRADO,
    MODAL_NEW_TUTOR,
    MODAL_GESTIONAR_SOLICITUD,
    MODAL_NUEVO_GRADO,
    MODAL_PROGRAMA_GESTOR,
    MODAL_PERMISO_ESTADO_GESTOR,
    MODAL_EVALUACION_COMITE,
    MODAL_FILTROS,
    MODAL_EVENTOS,
    MODAL_NEW_EVENTO,
    SOLICITUDES_GRADOS,
    ULTIMA_SOLICITUD,
    DATA_ADJUNTO_SOLICITUD
} from './../actions/actGrados';

const initialState = {
    modalAdjuntos: false,
    modalNewAdjuntos: false,
    modalModSolicitud: false,
    modalDetalleGrado: false,
    modalNewGestor: false,
    modalNuevoGrado: false,
    modalGestionarSolicitud: false,
    modalProgramaGestor: false,
    modalPermisoEstadoGestor: false,
    modalEvalComite: false,
    modalFiltros: false,
    modalEventos: false,
    modalNewEvento: false,
    solicitud: {},
    adjunto: {},
    estado: {},
    coordinador: {},
    grados: [],
    estados: [],
    adjuntos: [],
    eventos: [],
    tutores: [],
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case MODAL_NEW_TUTOR:
            return Object.assign({}, state, {
                modalNewGestor: action.show
            })
        case MODAL_NUEVO_GRADO:
            return Object.assign({}, state, {
                modalNuevoGrado: action.show
            })
        case MODAL_GESTIONAR_SOLICITUD:
            return Object.assign({}, state, {
                modalGestionarSolicitud: action.show
            })
        case SOLICITUDES_GRADOS:
            return Object.assign({}, state, {
                grados: action.grados
            })
        case MODAL_DETALLE_GRADO:
            return Object.assign({}, state, {
                modalDetalleGrado: action.show
            })
        case ESTADOS_SOLICITUD:
            return Object.assign({}, state, {
                estados: action.estados
            })
        case ESTADO_SOLICITUD:
            return Object.assign({}, state, {
                estado: action.estado
            })
        case MODAL_ADJUNTOS:
            return Object.assign({}, state, {
                modalAdjuntos: action.show
            })
        case MODAL_NEW_ADJUNTOS:
            return Object.assign({}, state, {
                modalNewAdjuntos: action.show
            })
        case ADJUNTOS:
            return Object.assign({}, state, {
                adjuntos: action.adjuntos
            })
        case ULTIMA_SOLICITUD:
            return Object.assign({}, state, {
                solicitud: action.solicitud
            })
        case MODAL_MODIFICAR_SOLICITUD:
            return Object.assign({}, state, {
                modalModSolicitud: action.show
            })
        case TUTORES:
            return Object.assign({}, state, {
                tutores: action.tutores
            })
        case EVENTOS:
            return Object.assign({}, state, {
                eventos: action.eventos
            })
        case DATA_ADJUNTO_SOLICITUD:
            return Object.assign({}, state, {
                adjunto: action.adjunto
            })
        case MODAL_PROGRAMA_GESTOR:
            return Object.assign({}, state, {
                modalProgramaGestor: action.show
            })
        case MODAL_PERMISO_ESTADO_GESTOR:
            return Object.assign({}, state, {
                modalPermisoEstadoGestor: action.show
            })
        case MODAL_EVALUACION_COMITE:
            return Object.assign({}, state, {
                modalEvalComite: action.show
            })
        case MODAL_FILTROS:
            return Object.assign({}, state, {
                modalFiltros: action.show
            })
        case MODAL_EVENTOS:
            return Object.assign({}, state, {
                modalEventos: action.show
            })
        case MODAL_NEW_EVENTO:
            return Object.assign({}, state, {
                modalNewEvento: action.show
            })
        default:
            return state;
    }
}

export default reducer;