import React from 'react'
import PropTypes from 'prop-types';
import { BtnSeleccion } from "../../general/BotonesAccion";
import ListarDatos from '../../general/ListarDatos';
import { consulta } from "../../../global/js/funciones";

class BuscarPersonaExterna extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      dato_buscar: '',
      cargando: false,
      personas: [],
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.dato_buscar !== prevState.dato_buscar) {
      this.obtenerPersonas(this.state.dato_buscar)
    }
  }


  obtenerPersonas = (dato) => {
    this.setState({ cargando: true })
    if (dato.length > 3) {
      consulta("api/v1.0/grados/externos/buscar", { dato }, "post",
        (error, estado, resp) => {
          if (estado === 200) {
            this.setState({ cargando: false, personas: resp })
          }
        }
      );
    } this.setState({ cargando: false, personas: [] });
  }



  render() {
    const actions = (rowData) => {
      let { callback } = this.props;
      const seleccionar = (data) => {
        callback(data);
      }

      let resp = <BtnSeleccion callback={() => seleccionar(rowData)} />
      return resp;
    }

    let { dato_buscar, personas, cargando } = this.state;

    return (
      <div>
        <ListarDatos
          id="tbl_listar_personas_externas"
          titulo={"Lista Personas Externas"}
          datos={personas}
          opciones={true}
          buscar={true}
          cargando={cargando}
          mensajeVacio="Ingrese nombre o correo de la persona externa a buscar"
          avatarImg={true}
          css={{
            list: { padding: '0px 0px 20px 0px' },
            appBar: { padding: '0px 0px 0px 0px' },
          }}
          actBuscar={(dato_buscar) => this.setState({ dato_buscar })}
          datoBuscarAlt={dato_buscar}
          avatar={({ nombre }) => nombre}
          acciones={(row) => actions(row)}
          fila_principal={({ nombre }) => nombre.toUpperCase()}
          filas={[
            {'mostrar': ({ valora }) => valora, 'id': 'correo'},
          ]}

        />
      </div>
    );
  }

}

//Props del componente
BuscarPersonaExterna.propTypes = {
  callback: PropTypes.func.isRequired,
}


export default BuscarPersonaExterna;


