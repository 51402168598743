import React, { Component } from 'react';
import moment from 'moment'

//components
import ListarDatos from '../../general/ListarDatos'
import { BtnEditar, BtnDetalle, BtnGestionar, BtnEliminar } from '../../general/BotonesAccion'

class Postulantes extends Component {

    actions = row => {
        let { listarPostulantes, editar_oferta, obtenerEstados, cerrar_oferta } = this.props
        return (
            <div>
                {
                    row.estado_solicitud.codigo != "Por_Fin" && (
                        <BtnDetalle
                            callback={() => {
                                listarPostulantes(row)
                            }}
                        />
                    )
                }

                {
                    row.estado_solicitud.codigo != "Por_Fin" && (
                        <BtnEditar
                            callback={() => {
                                editar_oferta(row)
                            }}
                        />
                    )
                }

                {
                    row.estado_solicitud.codigo != "Por_Fin" && (
                        <BtnGestionar
                            callback={() => {
                                obtenerEstados(row.id)
                            }}
                        />
                    )
                }

                {
                    row.estado_solicitud.codigo == "Por_Ace" && (
                        <BtnEliminar
                            texto="Cerrar oferta"
                            callback={() => {
                                cerrar_oferta(row)
                            }}
                        />
                    )
                }
            </div>
        )
    }

    obtenerColor = (codigo) => {
        let color = '#ffffff';
        const colores = {
            'Por_Sol': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
            'Por_Rev': { 'backgroundColor': '#ffc107', color },
            'Por_Ace': { 'backgroundColor': '#0d47a1', color },
            'Por_Fin': { 'backgroundColor': '#81c784', color },
            'Por_Rec': { 'backgroundColor': '#e57373', color },
        }
        return (colores[codigo])
    }

    renderListarOfertas = () => {
        let { getOfertas } = this.props
        return (
            <ListarDatos
                id="tbl_inf_ofertas"
                datos={getOfertas.filter(vacante => vacante.estado_solicitud.codigo != "Por_Fin" && vacante.estado_solicitud.codigo != "Por_Rec")}
                titulo={"Lista de ofertas"}
                agregar={false}
                descargar={''}
                actAgregar={() => console.log('Hola')}
                opciones={true}
                buscar={true}
                sub_titulo={"Lista de mis Ofertas"}
                avatar={({ estado_solicitud }) => null}
                color_avatar={({ estado_solicitud }) => this.obtenerColor(estado_solicitud.codigo)}
                acciones={row => this.actions(row)}
                fila_principal={({ nombre_cargo }) => `Cargo: ${nombre_cargo.toUpperCase()}`}
                filas={[
                    {
                        'mostrar': ({ id, tipo_oferta }) => tipo_oferta.codigo == 'Vac_Pra' ? `Código de la práctica laboral: ${id}` : '',
                        'id': 'codigo_practica',
                        'enLista': true
                    },
                    {
                        'mostrar': ({ id, tipo_oferta }) => tipo_oferta.codigo == 'Vac_Lab' ? `Código de la vacante laboral: ${id}` : '',
                        'id': 'codigo_vacante',
                        'enLista': true
                    },
                    {
                        'mostrar': ({ tipo_oferta }) => `Tipo: ${tipo_oferta.nombre}`,
                        'id': 'tipo_oferta',
                        'enLista': true
                    },
                    {
                        'mostrar': ({ n_vacantes, numero_plazas, tipo_oferta }) => tipo_oferta.codigo == 'Vac_Lab' ? `Cantidad de vacantes: ${n_vacantes}` : `Número de plazas: ${numero_plazas}`,
                        'id': 'n_vacantes',
                        'enLista': true
                    },
                    {
                        'mostrar': ({ salario, valor_auxilio, tipo_oferta }) => tipo_oferta.codigo == 'Vac_Lab' ? `Salario: ${salario}` : `Valor del auxilio de práctica: ${valor_auxilio}`,
                        'id': 'salario',
                        'enLista': true
                    },
                    {
                        'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD'),
                        'id': 'fecha_inicio',
                        'enLista': true
                    }
                ]}
            />
        )
    }

    render() {
        return (
            <div>
                {this.renderListarOfertas()}
            </div>
        )
    }
}

export default Postulantes;
