import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}% De hoja de vida completada`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

export default function LinearWithValueLabel({ validarPerfilEstudiante}) {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(validarPerfilEstudiante);

  React.useEffect(() => {
    setProgress((prevProgress) => (prevProgress >= 100 ? validarPerfilEstudiante : prevProgress));
    //const timer = setInterval(() => {
      //setProgress((prevProgress) => (prevProgress >= 100 ? validarPerfilEstudiante : prevProgress + 20));
    //}, 100000000000000);
    //return () => {
      //clearInterval(timer);
    //};
  }, []);

  return (
    <div className={classes.root}>
      <LinearProgressWithLabel value={validarPerfilEstudiante} />
    </div>
  );
}