import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import React, { Component } from 'react';
import emma_w from '../../../global/imagenes/emma_w.png';
import { consulta, rutaImg } from '../../../global/js/funciones';
import AppBarModal from '../../general/AppBarModal';
import { BtnFolder, BtnForm } from '../../general/BotonesAccion';
import ListarDatos from '../../general/ListarDatos';
import TareasTerminadas from '../../general/TareasTerminadas';
import Generica from '../../personas/generica/Generica';
import { PERMISO_DEPENDENCIAS } from './helper';

class InternacionalizacionPermisosDepen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      personas: [],
      cargando: true,
      modal: false,
      generica: {
        tipo: 0,
        id: 0,
      },
      dependenciasInter: false,
    }
  }

  async componentDidMount() {
    let personas = await this.obtenerGestores();
    this.setState({ personas, cargando: false })
  }

  async obtenerGestores() {
    let { generica } = this.state
    return new Promise(resolve => {
      consulta(`api/v1.0/internacionalizacion/encargados_dependencias/convenio`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  acciones(data) {
    const onClickProgramas = (data) => this.setState({ id_persona: data.id, modal: true, generica: { tipo: PERMISO_DEPENDENCIAS }, dependenciasInter: true });
    let programas = <BtnFolder callback={() => onClickProgramas(data)} texto='Programas' color='#CFC915' />;
    return <div>{programas}</div>
  }

  render() {
    let { personas, cargando, modal, generica, id_persona,dependenciasInter } = this.state;
    let { mostrarModalAddPermisosDep, modalAddPermisosDep } = this.props

    if (cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />;
    return (
      <div>
        <ListarDatos
          id='tbl_internacional_permisos'
          titulo='Lista Gestores por Dependencia'
          descargar='EMMA - GESTORES INTERNACIONALIZACIÓN'
          datos={personas}
          // agregar={true}
          // actAgregar={() => mostrarModalAddPermisosDep(true)}  
          opciones={true}
          buscar={true}
          acciones={row => this.acciones(row)}
          avatarImg={true}
          avatar={({ identificacion }) => `${rutaImg}${identificacion}.jpg`}
          fila_principal={row => `${row.primer_nombre} ${row.segundo_nombre} ${row.primer_apellido} ${row.segundo_apellido}`.toUpperCase()}
          filas={[
            { 'mostrar': ({ correo }) => `${correo}`, 'id': 'correo', 'enLista': true },
            { 'mostrar': ({ identificacion }) => `${identificacion}`, 'id': 'identificacion', 'enLista': true }
          ]}
        />
        <Dialog open={modal} fullWidth={true} maxWidth='sm' onClose={() => this.setState({ modal: false })}>
          <AppBarModal titulo='¡ Lista Permisos !' mostrarModal={() => this.setState({ modal: false })} accion={() => this.setState({ modal: false })} titulo_accion='CERRAR' />
          <DialogContent style={{ padding: 0 }} className='scroll'>
            <Generica
              // notificaciones={generica.tipo == PERMISO_DEPENDENCIAS ? true : false}
              tipo={generica.tipo}
              persona={{ id: id_persona }}
              // genericaRelacionId={generica.id}
              titulo="¡ Nuevo Permiso !"
              tituloInput="Permisos"
              tituloEliminar='¿ Eliminar Permiso ?'
              dependenciasInter={dependenciasInter}
            />
          </DialogContent>
          <DialogActions>
            <BtnForm texto="Cerrar" callback={() => this.setState({ modal: false })} />
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default InternacionalizacionPermisosDepen;
