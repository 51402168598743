import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { BtnForm } from "../../general/BotonesAccion";
import AppBarModal from '../../general/AppBarModal'

class ModalPasarAGraduado extends Component {

  constructor(props) {
    super(props)
    this.state = {
      
    }
  }


  render() {
    let { open, onClose, titulo, matricula : procesoActual, onClick } = this.props;
    return (
      <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <AppBarModal titulo={titulo} mostrarModal={onClose} titulo_accion="" accion="" />
        <DialogContent className='scroll'>
          <DialogContentText id="alert-dialog-description">
            {`Al ESTUDIANTE "${procesoActual.usuario_registro && procesoActual.usuario_registro.primer_nombre +" "+ procesoActual.usuario_registro.primer_apellido}" CON IDENTIFICACIÓN "${ procesoActual.usuario_registro && procesoActual.usuario_registro.identificacion }", El ESTADO SERA ACTUALIZADO A GRADUADO   `}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CANCELAR" callback={onClose} />
          <BtnForm texto="ACEPTAR" callback={onClick} />
        </DialogActions>
      </Dialog>
    )
  }
}


export default ModalPasarAGraduado;

