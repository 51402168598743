import React, { Component } from 'react';
import EnviarMultiplesArchivos from "../general/EnviarMultiplesArchivos";
import AlertasSimple from '../general/AlertasSimple';
import {BtnCargar} from "../general/BotonesAccion";
import AppBarModal from '../general/AppBarModal'
import CustomDropzone from "../general/CustomDropzone";
import { Paper, Grid,List, DialogActions, DialogContent, Dialog } from '@material-ui/core'
import {BtnForm} from "../general/BotonesAccion";


export default class SolicitudesAgregarAdjuntos extends Component {
  

  constructor(props) {
    super(props)
    this.state = {
      cargando: true,
      
    }
  }

  render() {
    let {submit, agregarArchivos,eliminarArchivo, cambiarEstado, requisitos, adjuntoOpcionales} = this.props.props_c;

    let obligatorio=[]
     requisitos.map((info)=> {
      if(info.valora === '1'){
        obligatorio.push({item :info.nombre})
        }
      })
  const trasnformarDataAdj = (data) => {
    let r = [];
    data.map(( info ) => r.push({ nombre: info.nombre, /*descripcion: info.descripcion,*/ codigo: info.id, archivo: info.archivo, formatos : info.valorg }))
    return r;
  }

  const acciones = (data, callback) => {
    const onClickCargar = (data) => callback(data);

    let cargar = <BtnCargar callback={() => onClickCargar(data)} color='#00bfa5' texto='Gestionar' />

    return <div>{cargar}</div>;
  }

  return (
    <React.Fragment>
      <Grid container spacing={3}>
      <AlertasSimple
              tipo='info'
              titulo={'Estimado, tener en cuenta que estos documentos son obligatorios: '}
              lista={obligatorio}
              margin = '0px'
            />
        <Grid item xs={12}>
          <Paper elevation={0} square>
            <List component="nav" arial-label="main">
              <EnviarMultiplesArchivos
                acciones={acciones}
                enModal={false}
                enviarArchivos={(archivos) => submit(archivos)}
                limite={2}
                mostrarMensaje={false}
                enLista={true}
                lista={trasnformarDataAdj(requisitos)}
                formatos={true}
              />
            </List>
          </Paper>
        </Grid>
        <AlertasSimple
              tipo='success'
              titulo={'¡Para cargar otros documentos que usted considere necesario clic acá!'}
              margin = '0px'
              callback={() =>cambiarEstado({adjuntoOpcionales : true})}
            />
      </Grid>
      <Dialog open={adjuntoOpcionales} fullWidth={true} maxWidth='sm' onClose={() => cambiarEstado({adjuntoOpcionales : false})}>
        <AppBarModal titulo='¡ Cargar Documentos !' mostrarModal={() => cambiarEstado({adjuntoOpcionales : false})} titulo_accion='' />
        <DialogContent className='scroll'>
          <CustomDropzone archivos={this.props.props_c.archivos2} addFunction={agregarArchivos} deleteFunction={eliminarArchivo} actualizarMensaje={this.props.props_c.actualizarMensaje} />
        </DialogContent>
        <DialogActions>
          <BtnForm texto="Cerrar" callback={() =>cambiarEstado({adjuntoOpcionales : false})} />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
  }
}
