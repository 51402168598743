import React, { Component } from 'react';
import { connect } from "react-redux";
import { actualizarMensaje, mostrarModulo } from "../../../redux/actions/actGlobal";
import { MenuAdministrar } from "./CentroConciliacionMenu";
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from '../../../global/imagenes/emma_w.png';
import { BtnForm, BtnDetalle, BtnGestionar, BtnRegresar, BtnDuplicar, BtnEditar } from "../../general/BotonesAccion";
import {
    consulta, crear_form_data, mostrarError, obtenerValoresFiltros,
    generarFiltros, formulario, modificarValorGenericaAlter
} from "../../../global/js/funciones";
import { mostrarModalAddFiltrosCentro, mostrarModalModPeriodoCentro, mostrarModalDetalleCentro, mostrarModalGestionarCentro } from "../../../redux/actions/actCentro";
import { Paper, Grid, Tooltip, IconButton, InputLabel, MenuItem, FormControl, DialogActions, DialogContent, Select, Dialog } from '@material-ui/core'
import TituloAccion from '../../general/TituloAccion';
import FilterListIcon from '@material-ui/icons/FilterList';
import emma_s1 from '../../../global/imagenes/emma_s1.png';
import AccionesRapidas from '../../general/AccionesRapidas';
import CentroPerfilesAdministrar from './CentroPerfilesAdministrar';
import AppBarModal from '../../general/AppBarModal'
import CentroFiltrar from './CentroFiltrar';
import ListarDatos from "../../general/ListarDatos";
import moment from 'moment';
import DetalleCentro from './DetalleCentro';
import CentroGestionar from './CentroGestionar';


class CentroConciliacionAdministrar extends Component {

    constructor(props) {
        super(props)
        this.state = {
            menu: true,
            seleccion: 'proceso',
            cargando: true,
            accionProceso: 'inicio',
            periodo_filtro: null,
            administrador: false,
            periodo: '',
            filtros: [],
            filtros_centro: [],
            estados_procesos: [],
            filtrarPeriodo: false,
            periodos: [],
            descargarInforme: false,
            informe: [],
            cargarFiltroP: true,
            seleccionMasiva: [],
            procesos: [],
        }
    }

    async componentDidMount() {
        let { mostrarModulo } = this.props;
        mostrarModulo({ ruta: '/centro_conciliacion_administrar', nombre: 'Centro de Conciliación Administración' })
        this.esAdministrador()
        this.obtenerDatosIniciales()
    }

    async componentDidUpdate({ }, { seleccion, accionProceso }) {
        let { seleccion: seleccionNew, accionProceso: accionProcesoNew, periodo_filtro } = this.state;
        if (seleccion !== seleccionNew) {
            if (seleccionNew === 'proceso') this.actualizarDataCentro();
        }


        if (accionProceso !== accionProcesoNew) {
            if (accionProcesoNew != 'Tod_Centro') {
                this.cambiarEstado({ cargando: true })
                if (accionProcesoNew == 'inicio') this.obtenerEstadoSolicitudes(periodo_filtro)
                else this.actualizarDataCentro([{ 'llave': 'estado_actual__codigo', 'valor': accionProcesoNew }, { 'llave': 'periodo', 'valor': periodo_filtro }]);
            }
        }
    }

    async actualizarDataCentro(filtros = [{ 'llave': 'estado_actual__valorb', 'valor': 'si' }, { 'llave': 'periodo', 'valor': this.state.periodo }]) {
        this.setState({ cargando: true })
        let procesos = await this.obtenerSolicitudes(filtros);
        this.obtenerDatosIniciales()
        this.setState({ procesos, cargando: false, filtros_centro: filtros })
    }


    esAdministrador() {
        let { usuario } = this.props;
        if (usuario && usuario.perfil && (usuario.perfil.codigo === "Per_Adm" || usuario.perfil.codigo === "Per_CentroCon_Adm")) {
            this.setState({ administrador: true })
        }
    }

    async obtenerDatosIniciales() {
        this.setState({ cargando: true });
        let periodo = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_CentroCon_Sol' }, { 'llave': 'estado', 'valor': '1' }]);
        let filtros = periodo.length > 0 ? [{ 'llave': 'estado_actual__valorb', 'valor': 'si' }, { 'llave': 'periodo', 'valor': periodo[0].nombre }] : []
        this.obtenerEstadoSolicitudes(periodo[0].nombre);
        this.setState({ periodo: periodo.length > 0 ? periodo[0].nombre : '', filtros, periodo_filtro: periodo.length > 0 ? periodo[0].nombre : '', periodo_filtros: periodo.length > 0 ? periodo[0].nombre : '' });
    }

    obtenerEstadoSolicitudes = (periodo) => {
        consulta(`api/v1.0/centro/estados/${periodo}`, null, null, (error, estado, resp) => {
            if (estado === 200) {
                let { general, extra } = resp
                if (extra.length > 0) {
                    extra.map(({ solicitudes, nombre, codigo, orden }) => {
                        let reporte = {
                            'estado_actual__codigo': codigo,
                            'estado_actual__nombre': nombre,
                            'num_sol': solicitudes,
                            'orden': orden
                        }
                        general.push(reporte);
                    })
                }
                this.cambiarEstado({ estados_procesos: general.sort((a, b) => a.orden - b.orden), cargando: false });
            }
        })
    }


    async obtenerSolicitudes(filtros = []) {
        let f = await generarFiltros(filtros);
        return new Promise(resolve => {
            consulta(`api/v1.0/centro/listar/1?${f}`, null, null, (error, estado, resp) => {
                resolve(estado === 200 && !error ? resp : null);
            })
        })
    }

    obtenerPeriodos = () => {
        return new Promise(resolve => {
            consulta(`api/v1.0/centro/periodos`, null, null,
                (error, estado, resp) => {
                    resolve(resp)
                }
            );
        })
    }

    gestionarCentro = async (id, data, callback) => {
        let { mostrarModalGestionarCentro, actualizarMensaje } = this.props;
        actualizarMensaje({ titulo: 'Enviado datos, por favor espere...', tipo: 'info', mostrar: true, tiempo: 4000 });
        let form = await crear_form_data(data);
        data.archivos.map((file, index) => form.append(`archivo${index}`, file));
        formulario(`api/v1.0/centro/${id}/gestionar`, form, "post", (error, estado, resp) => {
          let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
          if (!error) {
            if (estado === 200) {
              tipo = 'success';
              titulo = resp.titulo;
              mostrarModalGestionarCentro(false);
              this.actualizarDataCentro(this.state.filtros_consultoria);
            } else {
              tipo = 'info';
              titulo = resp.titulo ? resp.titulo : mostrarError(resp);
            }
          }
          actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
          callback();
        })
      }


    filtrarEstadoSolicitudes = () => {
        let { periodo_filtro } = this.state
        this.cambiarEstado({ filtrarPeriodo: false, cargando: true })
        this.obtenerEstadoSolicitudes(periodo_filtro)
    }

    ModalfiltroPeriodo = async () => {
        let { cargarFiltroP } = this.state
        this.cambiarEstado({ filtrarPeriodo: true })
        if (cargarFiltroP) {
            this.cambiarEstado({ cargandoPeriodo: true })
            let periodos = await this.obtenerPeriodos();
            this.cambiarEstado({ periodos, cargandoPeriodo: false, cargarFiltroP: false })
        }
    }

    modificarPeriodo = async (periodo) => {
        let { actualizarMensaje, mostrarModalModPeriodoCentro } = this.props;
        if (periodo) {
            let p = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_CentroCon_Sol' }]);
            if (p.length > 0) {
                let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
                let { error, estado, resp } = await modificarValorGenericaAlter(p[0].id, { 'nombre': periodo })
                if (!error) {
                    if (estado === 200) {
                        tipo = 'success';
                        titulo = resp.titulo;
                        mostrarModalModPeriodoCentro(false);
                        this.cambiarEstado({ periodo, periodo_filtro: periodo });
                        this.setState({ cargando: true })
                        this.obtenerEstadoSolicitudes(periodo)
                    } else {
                        tipo = 'info';
                        titulo = resp.titulo ? resp.titulo : mostrarError(resp)
                    }
                }
                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
            } else actualizarMensaje({ titulo: 'error al cargar el periodo actual', tipo: 'error', mostrar: true, tiempo: 6000 });
        } else actualizarMensaje({ titulo: 'Todos los campos son obligatorios', tipo: 'info', mostrar: true, tiempo: 6000 });
    }

    configFiltros = (accion_actual) => {
        let { periodo_filtro } = this.state
        this.setState({ seleccionMasiva: [] })
        switch (accion_actual) {
            case 'Tod_Centro':
                return [{ 'llave': 'periodo', 'valor': periodo_filtro }]
            case 'inicio':
                return [{ 'llave': 'periodo', 'valor': periodo_filtro }]
            default:
                return [{ 'llave': 'estado_actual__codigo', 'valor': accion_actual }, { 'llave': 'periodo', 'valor': periodo_filtro }]
        }
    }


    cambiarEstado = (estados) => {
        this.setState(estados)
    }

    renderProcesos() {
        let { accionProceso } = this.state;
        return (
            <>
                {accionProceso === 'inicio' ? this.pintarInicioProcesos() : this.pintarProcesos()}
                {accionProceso !== 'inicio' && <div className={'regresar'} ><BtnRegresar callback={() => this.setState({ filtros_centro: [], accionProceso: 'inicio' })} /></div>}
            </>
        )
    }


    pintarInicioProcesos = () => {
        let { estados_procesos, filtrarPeriodo, periodo_filtro, periodos, cargandoPeriodo, descargarInforme, informe } = this.state;
        let { mostrarModalAddFiltrosCentro } = this.props

        const obtenerColor = (codigo) => {
            let color = 'white';
            const colores = {
                'Est_Centro_Asig': { 'backgroundColor': '#9315b8', color },
                'Est_Centro_Aprob': { 'backgroundColor': '#009688', color },
                'Est_Centro_AsigCar': { 'backgroundColor': '#n76c1c', color },
                'Est_Centro_Nega': { 'backgroundColor': '#b71c1c', color },
                'Est_Centro_Agen_audiencia_con': { 'backgroundColor': '#FFFF00', color },
                'Est_Centro_Red_Citaci': { 'backgroundColor': '#800080', color },
                'Est_Centro_Aprob_citaci': { 'backgroundColor': '#008000', color },
                'Est_Centro_Negar_Citacio': { 'backgroundColor': '#8B0000', color },
                'Est_Centro_Entre_Citaci': { 'backgroundColor': '#00FF00', color },
                'Est_Centro_CartaFir': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
                'Est_Centro_Asiste_Lugar': { 'backgroundColor': '#00008B', color },
                'Est_Centro_Llegan_Acuerdo': { 'backgroundColor': '#33FFA5', color },
                'Est_Centro_No_llegan_Acuerdo': { 'backgroundColor': '#51254C', color },
                'Est_Centro_Suspender_Audiencia': { 'backgroundColor': '#EAEBBA', color },
                'Est_Centro_Uno_No_Asiste_Audiencia': { 'backgroundColor': '#6C3206', color },
                'Est_Centro_Ninguno_Asiste_Audiencia': { 'backgroundColor': '#F00BA4', color },
                'Est_Centro_Redactar_Acta_Concili': { 'backgroundColor': '#33FFA5', color },
                'Est_Centro_Redactar_Cons_No_Acuerdo': { 'backgroundColor': '#51254C', color },
                'Est_Centro_Entr_Info_Suspen': { 'backgroundColor': '#EAEBBA', color },
                'Est_Centro_Agendar_Conti_Audien': { 'backgroundColor': '#EAEBBA', color },
                'Est_Centro_Despues_dili_continu_audi': { 'backgroundColor': '#EAEBBA', color },
                'Est_Centro_Verificar_Notifi_Partes': { 'backgroundColor': '#6C3206', color },
                'Est_Centro_Esperar_Excusas': { 'backgroundColor': '#6C3206', color },
                'Est_Centro_Usu_Cons_Inasis': { 'backgroundColor': '#6C3206', color },
                'Est_Centro_Agendar_Nuev_Audi': { 'backgroundColor': '#6C3206', color },
                'Est_Centro_Eperar_Dias_Reci_Excu': { 'backgroundColor': '#F00BA4', color },
                'Est_Centro_Soli_nueva_Audiencia': { 'backgroundColor': '#F00BA4', color },
                'Est_Centro_Aprobar_Resul_Conciliato': { 'backgroundColor': '#0CE3BC', color },
                'Est_Centro_Recoger_Firmas_Requi': { 'backgroundColor': '#4D4C04', color },
                'Est_Centro_Carpeta_Orga': { 'backgroundColor': '#2B021E', color },
                'Est_Centro_Revision_Final_Carp': { 'backgroundColor': '#3C663F', color },
                'Est_Centro_Asig_Num_Radica': { 'backgroundColor': '#B87A07', color },
                'Est_Centro_Digitalizar_Car_Fisi': { 'backgroundColor': '#6A552E', color },
                'Est_Centro_Resul_Conci_Regis_SICACC': { 'backgroundColor': '#92F76D', color },
                'Est_Centro_Imprimir_Adjuntar': { 'backgroundColor': '#5FB63E', color },
                'Est_Centro_Proyec_Const_Desisti':{ 'backgroundColor': '#5FB63E', color },
                'Tod_Centro': { 'backgroundColor': '#0d47a1', color },
            }
            return (colores[codigo])
        }

        const handleClick = (codigo) => {
            if (codigo != 'Tod_Centro') this.setState({ accionProceso: codigo })
            else {
                mostrarModalAddFiltrosCentro(true);
            }
        };

        return (

            <>
                <div style={{ padding: '2% 2% 0% 2%' }} >
                    <Grid container direction={'row'} spacing={1} justify={'center'} >
                        <Grid item xs={12} sm={12} md={12} lg={10} >
                            <Grid container direction={'row'} alignItems="center" spacing={1} style={{ width: "100%" }}>
                                <Grid item xs={6} sm={6} md={6} lg={6} >
                                    <TituloAccion principal='' alterno='Seleccione el estado a gestionar: ' />
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} >
                                    <Tooltip title='Filtrar' aria-label="add" onClick={() => this.ModalfiltroPeriodo()} >
                                        <IconButton edge={false} color="secondary" style={{ float: 'right' }}><FilterListIcon /></IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Paper elevation={0} square className="scroll contenido_fijo">
                                {(estados_procesos.length === 0) ?
                                    <TareasTerminadas mensaje='Aún no hay solicitudes para este periodo.' marginTop='7%' imagen={emma_s1} widthImg="7%" /> :
                                    <Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
                                        {estados_procesos.map(({ estado_actual__codigo: codigo, estado_actual__nombre: nombre, num_sol }, i) => {
                                            return (
                                                <Grid item xs={12} sm={12} md={6} lg={6} key={i}>
                                                    <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => handleClick(codigo)}>
                                                        <AccionesRapidas imagen='' titulo={`¡ ${nombre} !`} sub_titulo='' des_sub_titulo='' avatar={obtenerColor(codigo)} avatar_name={nombre.charAt(0).toUpperCase()} list_desc={Number.isInteger(num_sol) ? [`Numero de Solicitudes : ${num_sol}`] : ['Debe aplicar los filtros.']} />
                                                    </Paper>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                }
                            </Paper>
                        </Grid>
                    </Grid>
                </div>

                <Dialog fullWidth={true} maxWidth="xs" open={filtrarPeriodo} onClose={() => this.cambiarEstado({ filtrarPeriodo: false })} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <AppBarModal titulo='¡ Aplicar Filtros !' mostrarModal={() => this.cambiarEstado({ filtrarPeriodo: false })} titulo_accion="" accion="" />
                    <DialogContent className='scroll'>
                        {!cargandoPeriodo ?
                            <Grid container spacing={3} className='margin_cont' >
                                <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                                    <FormControl className='form-control'>
                                        <InputLabel htmlFor="periodo_filtro">Seleccione Periodo</InputLabel>
                                        <Select
                                            inputProps={{
                                                name: 'periodo_filtro',
                                                id: 'periodo_filtro',
                                            }}
                                            value={periodo_filtro}
                                            onChange={this.onChange}
                                            required={true}>
                                            {periodos.map(({ periodo }, i) => <MenuItem key={i} value={periodo}>{periodo}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            : <TareasTerminadas imagen={emma_w} mensaje='Cargando...' widthImg='5%' marginTop='5%' cargando={true} />
                        }
                    </DialogContent>
                    <DialogActions>
                        <BtnForm texto="CANCELAR" callback={() => this.cambiarEstado({ filtrarPeriodo: false })} />
                        <BtnForm texto="FILTRAR" callback={() => this.filtrarEstadoSolicitudes()} />
                    </DialogActions>
                </Dialog >
            </>
        )
    }

    pintarProcesos() {
        let { usuario } = this.props;
        let { procesos, administrador } = this.state;
        let { mostrarModalDetalleCentro, mostrarModalGestionarCentro } = this.props;
        const acciones = (data) => {
            let { estado_actual: { codigo }, permiso } = data;

            const onClickDetalle = proceso => {
                this.setState({ proceso })
                mostrarModalDetalleCentro(true)
            }
            const onClickGestionar = proceso => {
                this.setState({ proceso })
                mostrarModalGestionarCentro(true)
            }

            let detalle = <BtnDetalle callback={() => onClickDetalle(data)} color='#01579b' texto='Abrir' />;
            let gestionar = (permiso === '1' || administrador || usuario.perfil.codigo === "Per_Centro_Est") && <BtnGestionar callback={() => onClickGestionar(data)} color='#00bfa5' texto='Gestionar' />;
            return <div>{detalle}{gestionar}</div>;
        }

        return (
            <>
                <ListarDatos
                    datos={procesos}
                    titulo='Lista Solicitudes'
                    id='tbl_consultoria_listar'
                    opciones={true}
                    descargar=''
                    filtrar={false}
                    refrescar={false}
                    actfiltrar={() => this.actualizarDataCentro(this.state.filtros_centro)}
                    actRefrescar={() => { }}
                    acciones={(data) => acciones(data)}
                    buscar={true}
                    avatar={({ solicitante: { primer_nombre } }) => primer_nombre.toUpperCase().charAt(0)}
                    fila_principal={({ solicitante: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`}
                    filas={[
                        { 'id': 'usuario_registro', 'mostrar': ({ solicitante: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}` },
                        { 'id': 'identificacion', 'mostrar': ({ solicitante: { identificacion } }) => identificacion },
                        { 'id': 'radicado ID', 'mostrar': ({ id }) => `Radicado  CC-${id}` },
                        { 'id': 'radicado consultas juridicas', 'mostrar': ({ id_consultas_juridicas }) => `Asociado a consultas juridicas con Radicado  SC-${id_consultas_juridicas}` },
                        { 'id': 'radicado asociado', 'mostrar': ({ id_asociado }) => `Asociado a solicitud con radicado ${id_asociado}` },
                        { 'id': 'area', 'mostrar': ({ area }) => `${area != null ? `${`${area.nombre}`}` : ''}` },
                        { 'id': 'fecha_registro', 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a') },
                        { 'id': 'estado_actual', 'mostrar': ({ estado_actual, gestor_nombre }) => `${estado_actual.nombre} ${gestor_nombre ? `${` - ${gestor_nombre}`}` : ''}` },
                    ]}
                />
            </>
        )
    }

    pintarAdministrar() {
        let { periodo, } = this.state;
        let { actualizarMensaje, modalModPeriodoCentro, mostrarModalModPeriodoCentro } = this.props;
        return <CentroPerfilesAdministrar
            periodo={periodo}
            modificarPeriodo={this.modificarPeriodo}
            actualizarMensaje={actualizarMensaje}
            modalModPeriodoCentro={modalModPeriodoCentro}
            mostrarModalModPeriodoCentro={mostrarModalModPeriodoCentro}
        />
    }

    render() {
        let { seleccion, administrador, cargando, accionProceso, proceso } = this.state;
        let { ModalAddFiltrosCentro, mostrarModalAddFiltrosCentro, actualizarMensaje, modalDetalleCentro, mostrarModalDetalleCentro,modalGestionarCentro,mostrarModalGestionarCentro,usuario } = this.props;
        return (
            <>
                <MenuAdministrar
                    seleccionar={(seleccion) => this.setState({ seleccion })}
                    sele={seleccion}
                    administrador={administrador}
                />
                <DetalleCentro
                    proceso={proceso ? proceso : 0}
                    actualizarMensaje={actualizarMensaje}
                    modalDetalleCentro={modalDetalleCentro}
                    mostrarModalDetalleCentro={mostrarModalDetalleCentro}
                    cargar="true"
                />
                <CentroGestionar
                    usuario={usuario}
                    proceso={proceso ? proceso : 0}
                    actualizarMensaje={actualizarMensaje}
                    modalGestionarCentro={modalGestionarCentro}
                    mostrarModalGestionarCentro={mostrarModalGestionarCentro}
                    gestionarCentro={this.gestionarCentro}
                />
                <CentroFiltrar
                    ModalAddFiltrosCentro={ModalAddFiltrosCentro}
                    mostrarModalAddFiltrosCentro={mostrarModalAddFiltrosCentro}
                    actualizarMensaje={actualizarMensaje}
                    limpiar={() => this.actualizarDataCentro(this.configFiltros(accionProceso))}
                    filtrar={(filtros) => { this.actualizarDataCentro(this.configFiltros(accionProceso).concat(filtros)) }}
                    accionProceso={accionProceso}
                    mostrarTodos={(seleccion) => this.setState({ accionProceso: seleccion })}
                />
                <div className='contenido_modulo'>
                    {
                        !cargando
                            ?
                            <div>
                                {seleccion === 'proceso' && this.renderProcesos()}
                                {seleccion === 'administrar' && this.pintarAdministrar()}
                            </div>
                            : <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" cargando={true} />
                    }
                </div>
            </>

        )
    }

}



//CARGO ESTADOS DEL MODULO
const mapStateToProps = (state) => {
    let { usuario } = state.redGlobal;
    let { ModalAddFiltrosCentro, modalModPeriodoCentro, modalDetalleCentro, modalGestionarCentro } = state.redCentro;

    return {
        usuario,
        ModalAddFiltrosCentro,
        modalModPeriodoCentro,
        modalDetalleCentro,
        modalGestionarCentro,
    }
};


//CARGO FUNCIONES DEL MODULO
const mapDispatchToProps = {
    actualizarMensaje,
    mostrarModulo,
    mostrarModalAddFiltrosCentro,
    mostrarModalModPeriodoCentro,
    mostrarModalDetalleCentro,
    mostrarModalGestionarCentro,
}



export default connect(mapStateToProps, mapDispatchToProps)(CentroConciliacionAdministrar);