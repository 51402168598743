import React, { Component } from 'react'
import { StyleSheet } from "@react-pdf/renderer";
import { COD_NIVEL_AVANZADO, COD_NIVEL_EXCELENTE, COD_NIVEL_INSUFICIENTE, COD_NIVEL_MINIMO, COD_NIVEL_SATISFACTORIO } from '../helper';
import { TablaValoracion } from './components/TablaValoracion';
import { consulta, mostrarError } from '../../../global/js/funciones';

const classes = StyleSheet.create({
  gridContainer: {
    width: "100%",
    padding: "0.7% 0 0 0",
  },
  tableContainer: {
    borderRadius: '10px',
    overflow: 'hidden',
  },
  table: {
    width: '100%',
  },
  tableRow: {
    backgroundColor: '#f9f5eb',
    borderBottom: '5px solid #C79e32',
    borderRadius: '10px',
  },
  columnaNivel: {
    padding: '0.8%',
    backgroundColor: '#f9f5eb',
    width: '10%',
    fontSize: "12px",
  },
  containerRadio: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  smallRadio: {
    transform: "scale(0.6)",
    // border: "1px solid #000",
    padding: "0 0 0 0",
    marginTop: "-5px",
  }
});

class SisefCalificarVarios extends Component {
  constructor(props) {
    super(props)
    this.state = {
      i: 0,
      siguiente: false,
      selectedRows: {},
      selectedCols: {},
      selectedCells: {},
      selecciones: [],
      seleccionAll: [],
      cargando: false,
    }
  }

  limpiarCeldas = (selectedCells, subindex) => {
    return Object.keys(selectedCells).reduce((newSelectedCells, key) => {
      const [col] = key.split("-").map(Number);
      if (col === subindex) {
        newSelectedCells[key] = false;
      }
      return newSelectedCells;
    }, { ...selectedCells });
  };

  obtenerDataCriterio = (dataHead, nivel, valor) => {
    const nivelCodigoMap = {
      'excelente': COD_NIVEL_EXCELENTE,
      'avanzado': COD_NIVEL_AVANZADO,
      'satisfactorio': COD_NIVEL_SATISFACTORIO,
      'minimo': COD_NIVEL_MINIMO,
      'insuficiente': COD_NIVEL_INSUFICIENTE,
    };

    if (nivel in nivelCodigoMap) dataHead.codigo_nivel = nivelCodigoMap[nivel]

    return {
      id_rubrica: dataHead.id,
      detalle: valor,
      codigo_nivel: dataHead.codigo_nivel,
    };
  };

  actualizarSelecciones(selecciones, data_criterio) {
    const existingIndex = selecciones.findIndex(crit => crit.id_rubrica === data_criterio.id_rubrica);
    if (existingIndex === -1) return [...selecciones, data_criterio]
    else {
      selecciones.splice(existingIndex, 1);
      return [...selecciones, data_criterio];
    }
  }

  onClick = (subindex, index, valor, nivel, dataHead) => {
    const { selecciones } = this.state;
    const { selectedCells } = this.state;

    const updatedSelectedCells = this.limpiarCeldas(selectedCells, subindex);
    updatedSelectedCells[`${subindex}-${index}`] = true;

    const data_criterio = this.obtenerDataCriterio(dataHead, nivel, valor);
    const updatedSelecciones = this.actualizarSelecciones(selecciones, data_criterio);

    this.setState({ selectedCells: updatedSelectedCells, selecciones: updatedSelecciones });
  };

  siguiente = async (e) => {
    e.preventDefault();
    const { i, selecciones, seleccionAll } = this.state;
    const newSelecciones = [...selecciones];
    const seleccionAlls = [...seleccionAll, newSelecciones];

    if (this.validar()) return

    this.setState({ siguiente: true });

    if (i === this.props.nombre_estudiantes.length - 1) await this.finalizar(seleccionAlls)
    else {
      setTimeout(() => {
        this.avanzar(i + 1, seleccionAlls);
      }, 2000);
    }
  };

  validar = () => {
    const { selecciones } = this.state;
    const { criterios, actualizarMensaje } = this.props;

    if (selecciones.length === 0) {
      actualizarMensaje({ titulo: 'Debe seleccionar al menos un criterio', tipo: 'info', mostrar: true, tiempo: 6000 });
      return true;
    }

    if (selecciones.length !== criterios.length) {
      actualizarMensaje({ titulo: 'Faltan criterios por evaluar', tipo: 'info', mostrar: true, tiempo: 6000 });
      return true;
    }

    return false;
  };

  finalizar = async (seleccionAlls) => {
    this.setState({ siguiente: false, selecciones: [], seleccionAll: seleccionAlls, selectedCells: {} });
    await this.enviar(seleccionAlls);
  };

  avanzar = (nextIndex, seleccionAlls) => {
    this.setState({
      i: nextIndex,
      selecciones: [],
      seleccionAll: seleccionAlls,
      siguiente: false,
      selectedCells: {},
    });
  };

  enviar = async (seleccionAlls) => {
    const { actividad, estudiantes_selec } = this.props;
    this.setState({ cargando: true });

    const datos = seleccionAlls.map((seleccion, index) => ({
      actividad: actividad.id,
      estudiante: estudiantes_selec[index],
      criterio: seleccion,
    }));

    let data = { data: datos }
    await this.Guardar(data);
  };


  Guardar = async (data) => {
    let { cerrarModal, actualizarMensaje, actividad, listar_estudiantes, corte, asignatura, resetData } = this.props;
    this.setState({ siguiente: true });
    consulta(`api/v1.0/sisef/calificar_varios`, data, "post",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            cerrarModal(false);
            listar_estudiantes(asignatura, corte, actividad);
            resetData();
            this.setState({ siguiente: false });
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  };

  render() {
    let { Modal, cerrarModal, nombre_estudiantes, criterios } = this.props
    let { i, siguiente, selectedCells, cargando } = this.state
    return (
      <>
        <TablaValoracion
          onClose={() => cerrarModal(false)}
          open={Modal}
          titulo={`Estudiante: ${nombre_estudiantes[i]}`}
          cargando={siguiente}
          criterios={criterios}
          selectedCells={selectedCells}
          classes={classes}
          onClick={this.onClick}
          siguiente={this.siguiente}
          mostrarBtn={i == nombre_estudiantes.length - 1 ? true : false}
          calificar_varios={true}
        />
      </>
    )
  }
}

export default SisefCalificarVarios
