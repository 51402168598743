import React from 'react';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

// COMPONETES
import ValidacionesNotificaciones from './ValidacionesNotificaciones';

// ICONOS MENU
import { Hidden, Badge } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import CrearBeca from '@material-ui/icons/Edit';
import LoopIcon from '@material-ui/icons/Loop';
import Notificaciones from '@material-ui/icons/NotificationImportant'

const clase = (item, selec, tipo = '') => item === selec ? `item_selec${tipo}` : `item_modulo`;

// MENU VALIDACIONES - ESTUDIANTES

export function MenuEstudiantes({ notificaciones, sele, seleccionar, anchorEl, setanchorEl }) {

	const pintar = (app, claseN = '') => {
	  if (!app) {
		return (
		  <div className={claseN}>
			<BottomNavigation showLabels className={clase('miproceso', sele)} onClick={() => seleccionar('miproceso')}>
			  <BottomNavigationAction label={'Procesos'} icon={<LocationOnIcon />} />
			</BottomNavigation>

			<ValidacionesNotificaciones notificaciones={notificaciones} render={(click) => <BottomNavigation showLabels onClick={click} className={clase('notificaciones', sele)} ><BottomNavigationAction label={'Notificación'} icon={<Badge badgeContent={notificaciones.length} color="secondary"><Notificaciones /></Badge>} />  </BottomNavigation>} anchorEl={anchorEl} setanchorEl={setanchorEl} />
  
			<BottomNavigation showLabels className={clase('nuevo', sele)} onClick={() => seleccionar('nuevo')}>
			  <BottomNavigationAction label={'Nuevo'} icon={<CrearBeca />} />
			</BottomNavigation>
  
			<BottomNavigation showLabels className={clase('historial', sele)} onClick={() => seleccionar('historial')}>
			  <BottomNavigationAction label={'Historial'} icon={<ImportContactsIcon />} />
			</BottomNavigation>
		  </div>
		)
	  } else {
		return (
		  <div className={claseN}>
			<BottomNavigation showLabels >
			  <BottomNavigationAction label={'Procesos'} icon={<LocationOnIcon />} className={clase('miproceso', sele, '_app')} onClick={() => seleccionar('miproceso')} />
  
			  <BottomNavigationAction label={'Nuevo'} icon={<CrearBeca />} className={clase('nuevo', sele, '_app')} onClick={() => seleccionar('nuevo')} />
  
			  <BottomNavigationAction label={'Historial'} icon={<ImportContactsIcon />} className={clase('historial', sele, '_app')} onClick={() => seleccionar('historial')} />
  
			</BottomNavigation>
		  </div>
		)
	  }
	}
  
	return (
	  <div>
		<div>
		  <Hidden xsDown> {pintar(false, 'menu_modulo')} </Hidden>
		  <Hidden smUp> {pintar(true, 'menu_modulo_app')}</Hidden>
		</div>
	  </div>
	);

}

export function ValidacionesMenu({ sele = 0, seleccionar = () => { } }) {
	const pintar = (claseN = '') => {
	  return (
		<div className={claseN}>
		  <BottomNavigation showLabels >
			<BottomNavigationAction label={'Detalle'} icon={<ImportContactsIcon />} className={clase('detalle', sele)} onClick={() => seleccionar('detalle')} />
			<BottomNavigationAction label={'Documentos'} icon={<MenuBookIcon />} className={clase('documentos', sele)} onClick={() => seleccionar('documentos')} />
			<BottomNavigationAction label={'Estados'} icon={<LoopIcon />} className={clase('estados', sele)} onClick={() => seleccionar('estados')} />
		  </BottomNavigation>
		</div>
	  )
	}
  
	return pintar('menu_modal');
}


//  MENU VALIDACIONES - GESTION ADMINISTRATIVA

export function MenuValidaciones({ administrador, sele = 0, seleccionar = () => { } }) {
	const pintar = (app, claseN = '') => {
		if (!app) {
		return (
			<div className={claseN}>
			<BottomNavigation showLabels className={clase('Procesos', sele)} onClick={() => seleccionar('Procesos')}>
				<BottomNavigationAction label={'Procesos'} icon={<LocationOnIcon />} />
			</BottomNavigation>
			{administrador &&
				<BottomNavigation showLabels className={clase('Permisos', sele)} onClick={() => seleccionar('Permisos')}>
				<BottomNavigationAction label={'Administrar'} icon={<SettingsIcon />} />
				</BottomNavigation>
			}
			</div>
		)
		} else {
		return (
			<div className={claseN}>
			<BottomNavigation showLabels >
			<BottomNavigationAction label={'Procesos'} icon={<LocationOnIcon />} className={clase('Procesos', sele, '_app')} onClick={() => seleccionar('Procesos')} />
				{
				administrador && <BottomNavigationAction label={'Administrar'} icon={<SettingsIcon />} className={clase('Permisos', sele, '_app')} onClick={() => seleccionar('Permisos')} />
				}
			</BottomNavigation>
			</div>
		)
		}
	}

	return (
		<div>
		<div>
			<Hidden xsDown> {pintar(false, 'menu_modulo')} </Hidden>
			<Hidden smUp> {pintar(true, 'menu_modulo_app')}</Hidden>
		</div>
		</div>
	);
}

export function MenuGestionarValidaciones({ sele = 0, seleccionar = () => { } }) {
	const pintar = (claseN = '') => {
		return (
		<div className={claseN}>
			<BottomNavigation showLabels >
			<BottomNavigationAction label={'Gestionar'} icon={<LoopIcon />} className={clase('gestion', sele)} onClick={() => seleccionar('gestion')} />
			</BottomNavigation>
		</div>
		)
	}

	return (
		<div>
		{pintar('menu_modal')}
		</div>
	);
}