import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import { consulta, generarFiltros } from "./../../../global/js/funciones";
import AppBarModal from "./../../general/AppBarModal";
import { BtnForm, BtnDetalle } from "../../general/BotonesAccion";
import ListarDatos from "./../../general/ListarDatos";
import { InscripcionDetalleCarrera, InscripcionDetalleContacto, InscripcionDetalleDatos } from "./InscripcionDetalle";
import { InscripcionMenu } from "./InscripcionesMenu";

import TareasTerminadas from "./../../general/TareasTerminadas";
import emma_w from './../../../global/imagenes/emma_w.png';
import moment from 'moment'
import { Adjust } from '@material-ui/icons';
import { coloresEmma } from './../../../global/js/funciones';

class Inscripcion extends Component {

  constructor(props) {
    super(props)
    this.state = {
      cargando: true,
      seleccion: '',
      datos : [],
      documentos: [],
      contacto: [],
      carrera: [],
      estados: [],
      maxWidth: 'sm',
    }
  }

  async componentDidUpdate({ modalDetalleInscripcion }, { seleccion }) {
    let { seleccion: seleccionNew } = this.state;
    let { inscripcion, modalDetalleInscripcion: modalDetalleInscripcionNew, vistaDetalle } = this.props;
    if (seleccionNew !== seleccion) {
      if (seleccionNew === 'inscripcion') this.obtenerInscripcion(inscripcion.id);
      else if (seleccionNew === 'documentos') this.obtenerAdjuntos(inscripcion.id);
      else if (seleccionNew === 'contacto') this.obtenerContacto(inscripcion.id);
      else if (seleccionNew === 'carrera') this.obtenerCarrera(inscripcion.id);
      else if (seleccionNew === 'estados') this.obtenerEstados(inscripcion.id, inscripcion.tipo);
    }
    if (modalDetalleInscripcion !== modalDetalleInscripcionNew) this.setState({ seleccion: modalDetalleInscripcionNew ? vistaDetalle : '' })
  }

  async obtenerInscripcion(id) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'id', 'valor': id }]);
    consulta(`api/v1.0/inscripcion/datos?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, datos: resp });
    })
  }

  async obtenerAdjuntos(id) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'id', 'valor': id }]);
    consulta(`api/v1.0/inscripcion/adjuntos?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, documentos: resp });
    })
  }

  async obtenerContacto(id) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'id', 'valor': id }]);
    consulta(`api/v1.0/inscripcion/contacto?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, contacto: resp });
    })
  }

  async obtenerCarrera(id) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'id', 'valor': id }]);
    consulta(`api/v1.0/inscripcion/carrera?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, carrera: resp });
    })
  }

  async obtenerEstados(id, tipo) {
    this.setState({ cargando: true });
    let t = tipo === 'new' ? 1 : 0
    consulta(`api/v1.0/inscripcion/estados/${t}/${id}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, estados: resp });
    })
  }

  pintarInscripcion = () => {
    let { datos } = this.state;
    if (datos[0]) return <InscripcionDetalleDatos inscripcion={datos[0]} />;
  }

  pintarCarrera = () => {
    let {carrera} = this.state
    if (carrera[0]) return <InscripcionDetalleCarrera inscripcion={carrera[0]} />;
  }

  pintarContacto = () => {
    let { contacto } = this.state;
    if(contacto[0]) return <InscripcionDetalleContacto inscripcion={contacto[0]} />;
  }

  pintarEstados = () => {
    let { estados } = this.state
    let { inscripcion: { tipo } } = this.props
    let estados_new = []
    if (estados.length > 0) {
      if (tipo === 'new' && estados[0].tipo_estado !== undefined) estados_new = estados
      else {
        if (estados[0]) {
          let {
            usuario_registro,
            usuario_proceso,
            usuario_pendiente,
            usuario_envio,
            fecha_registro,
            fecha_proceso,
            fecha_pendiente,
            fecha_envio,
            mensaje,
            estado
          } = estados[0];

          if (usuario_registro && fecha_registro) estados_new.push({ usuario_registro, fecha_registro, tipo_estado: { nombre: 'Registrada', codigo: 'Ins_Reg' }, mensaje: '' })
          if (usuario_proceso && fecha_proceso) estados_new.push({ usuario_registro: usuario_proceso, fecha_registro: fecha_proceso, tipo_estado: { nombre: 'En Proceso', codigo: 'Ins_Pro' }, mensaje: '' })
          if (usuario_pendiente && fecha_pendiente) estados_new.push({ usuario_registro: usuario_pendiente, fecha_registro: fecha_pendiente, tipo_estado: { nombre: 'Pendiente', codigo: 'Ins_Pen' }, mensaje: '' })
          if (usuario_envio && fecha_envio) estados_new.push({ usuario_registro: usuario_envio, fecha_registro: fecha_envio, tipo_estado: estado === 4 ? { nombre: 'Enviada', codigo: 'Ins_Env' } : { nombre: 'Cancelada', codigo: 'Ins_Can' }, mensaje })
        }
      }
    }
    const obtenerColor = (estado) => {
      let color = 'white';
      const colores = {
        'Ins_Reg': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
        'Ins_Pro': { 'backgroundColor': '#009688', color },
        'Ins_Pen': { 'backgroundColor': '#ffc107', color },
        'Ins_Env': { 'backgroundColor': '#0d47a1', color },
        'Ins_Can': { 'backgroundColor': '#b71c1c', color },
        'Ins_Estu_Rea': { 'backgroundColor': coloresEmma.iconscolor, color },
        'Ins_Pag': { 'backgroundColor': '#40c03f', color },
      }
      return colores[estado] || { 'backgroundColor': 'gray', 'color': 'black' };
    }

    return (
      <ListarDatos
        datos={estados_new}
        titulo=''
        id='tbl_estados_inscripciones'
        avatar={({ tipo_estado }) => (tipo==='new') ? tipo_estado.valora : tipo_estado.nombre.toUpperCase().charAt(0)}
        color_avatar={({ tipo_estado }) => obtenerColor(tipo_estado.codigo)}
        fila_principal={({ tipo_estado }) => tipo_estado.nombre}
        filas={[
          { 'id': 'fecha', 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a'), 'nombre': 'Fecha: ' },
          { 'id': 'usuario', 'mostrar': ({ usuario_registro: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}` },
          { 'id': 'mensaje', 'mostrar': ({ mensaje }) => mensaje },

        ]}
      />
    )
  }

  pintarDocs = () => {
    let { documentos } = this.state;
    let docs = []
    if (documentos[0]) {

      let {
        adj_identificacion,
        adj_saber,
        adj_volante,
        adj_diploma,
        adj_certificado,
        adj_docu_notas,
        adj_idpadres,
        adj_estudio_homologacion,
        fecha_registro,
        fecha_envio
      } = documentos[0];

      if (adj_identificacion) docs.push({ nombre_doc: 'Identificación', adj: adj_identificacion, fecha: fecha_registro })
      if (adj_saber) docs.push({ nombre_doc: 'Pruebas Saber', adj: adj_saber, fecha: fecha_registro })
      if (adj_diploma) docs.push({ nombre_doc: 'Diploma', adj: adj_diploma, fecha: fecha_registro })
      if (adj_certificado) docs.push({ nombre_doc: 'Certificado Medico de Discapacidad o Comunidad Especial', adj: adj_certificado, fecha: fecha_registro })
      if (adj_idpadres) docs.push({ nombre_doc: 'Identificacion Padres', adj: adj_idpadres, fecha: fecha_registro })
      if (adj_volante) docs.push({ nombre_doc: 'Volante', adj: adj_volante, fecha: fecha_envio || fecha_registro })
      if (adj_estudio_homologacion) docs.push({ nombre_doc: 'Estudio de Homologación', adj: adj_estudio_homologacion, fecha: fecha_envio || fecha_registro })
      if (adj_docu_notas) docs.push({ nombre_doc: 'Notas', adj: adj_docu_notas, fecha: fecha_envio || fecha_registro })

    }

    const acciones = (adj) => {
      let detalle = <a href={adj} rel="noopener noreferrer" target="_blank" className='sin_decorador'><BtnDetalle callback={() => { }} color='#01579b' texto='Abrir' /></a>;
      return <div>{detalle}</div>;
    }

    return (
        <ListarDatos
          datos={docs}
          id='tbl_docs_inscripcion'
          avatar={({ nombre_doc }) => nombre_doc.charAt(0)}
          titulo=''
          acciones={({adj}) => acciones(adj)}
          fila_principal={({nombre_doc}) => nombre_doc}
          filas={[
            { 'id': 'fecha', 'mostrar': ({ fecha }) => moment(fecha).format('YYYY-MM-DD, h:mm a'), 'nombre': 'Fecha: ' },
          ]}
        />
    )
  }

  pintar = () => {
    let { seleccion, cargando } = this.state;

    if (cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" cargando={true}/>;
    return (
      <div>
        {seleccion === 'inscripcion' && this.pintarInscripcion()}
        {seleccion === 'documentos' && this.pintarDocs()}
        {seleccion === 'contacto' && this.pintarContacto()}
        {seleccion === 'carrera' && this.pintarCarrera()}
        {seleccion === 'estados' && this.pintarEstados()}
      </div>
    )
  }

  render() {
    let { seleccion, maxWidth } = this.state;
    let { modalDetalleInscripcion, mostrarModalDetalleInscripcion } = this.props;
    return (
      <Dialog open={modalDetalleInscripcion} maxWidth={maxWidth} fullWidth={true} onClose={mostrarModalDetalleInscripcion.bind(this, false)}>
        <AppBarModal titulo='¡ Detalle Inscripcion !' mostrarModal={mostrarModalDetalleInscripcion} titulo_accion='' />
        <InscripcionMenu sele={seleccion} seleccionar={seleccion => this.setState({ seleccion })} />
        <DialogContent style={{ padding: 0 }} className='scroll' >
          {this.pintar()}
        </DialogContent>
        <DialogActions>
          <BtnForm texto="Cerrar" callback={() => mostrarModalDetalleInscripcion(false)} />
        </DialogActions>
      </Dialog>
    );
  }
}

Inscripcion.propTypes = {
  //variables
  inscripcion: PropTypes.object.isRequired,
  modalDetalleInscripcion: PropTypes.bool.isRequired,
  mostrarModalDetalleInscripcion: PropTypes.func.isRequired,
  vistaDetalle: PropTypes.string.isRequired,
}


export default Inscripcion;