import React, { useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import useWindowSize from '../../general/hooks/useWindowSize';

const BorderCircularProgress = withStyles(theme => ({
  circle: {
    strokeLinecap: 'round',
    strokeWidth: '2'
  },
}))(CircularProgress);

const ProgressCircle = ({ percent, color, text, loading }) => {

  const windowSize = useWindowSize();

  return (
    <Box position="relative" display="inline-flex">
      <BorderCircularProgress
        variant={percent && !loading ? 'determinate' : 'indeterminate'}
        value={Math.round(percent)}
        style={{
          width: `${windowSize < 600 ? '200px' : '350px'}`,
          height: `${windowSize < 600 ? '200px' : '350px'}`,
          color: `${color}`
        }}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '70%',
          height: '70%',
          borderRadius: '50%',
          justifyContent: 'center',
          background: 'linear-gradient(145deg, #ffffff, #dadfdf)',
          boxShadow: '5px 5px 40px #c2c6c6, -5px -5px 40px #ffffff'
        }}>
          <span
            style={{
              color: '#b5b3bc',
              fontWeight: 'bold',
              fontSize: `${windowSize < 600 ? '10px' : '15px'}`
            }}
          >
            {text}
          </span>
          <span
            style={{
              fontSize: `${windowSize < 600 ? '20px' : '30px'}`,
              color: '#3F3665',
              display: 'flex',
              fontWeight: 'bold'
            }}
          >
            {percent ? `${percent}` : null}
            <span style={{ fontSize: '12px', alignSelf: 'center' }}></span>
          </span>
        </div>
      </Box>
    </Box>
  )
}

export default ProgressCircle;
