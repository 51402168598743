import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardHeader, CardContent, Avatar, Grid, Typography} from '@material-ui/core/';
import { red,orange, grey, blue } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: "100%",
    minHeight: "50px",
    height: "330px",
  },
  image: {
    width: '100px',
    maxWidth: '100px',
    minWidth: '100px',
    height: "100px",
  },
  content_text: {
    padding: "0px 0px 0px 10px",
  },
  content_img: {
    textAlign: 'center',
    height: "150px",
  },
  hover_delete_icon: {
		'&:hover': {
			color: red[800]
		}
	},
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  root_avatar: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  emma: {
    color: "#fffff",
    backgroundColor: "#c79e32",
    fontWeight: '500',
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: 13
  },
  gris: {
    color: "#fffff",
    backgroundColor: grey[500],
    fontWeight: '500',
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: 13
  },
}));


export default function SisefCardProcesos({procesos, nombre, descripcion, imagen}) {
  
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={<Avatar aria-label="recipe" style={{ backgroundColor: blue[800] }}> {nombre.charAt(0).toUpperCase()} </Avatar>}
        title={nombre}
        subheader={procesos}
      />
      <CardContent>
        <Grid container direction={'row'} justify={'center'}>
          <Grid item xs={12} sm={12} md={12} className={classes.content_img}>
            <img src={imagen} className={classes.image} alt='Imagen Tipo' />
            <Typography variant="body2" color="textSecondary" component="p" align='justify'>
              {descripcion}
            </Typography>
          </Grid> 
        </Grid>
      </CardContent>
    </Card>
  );
}